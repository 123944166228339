import { AnimatePresence } from "framer-motion";
import { Box, Stack } from "knack-ui";
import { lazy } from "react";
import PageSkeleton from "../../components/skeletons/PageSkeleton";

import { withErrorBoundary } from "../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../lib/HOCs/withSuspense";
import { loadComponent } from "../../lib/utils";

import { SVGProps } from "../../components/SVG/SVG";
import useOnboardingCards from "../../features/Onboarding/hooks/useOnboardingCards";

export type OnboardingStepOptions = {
  key?: string;
  title: string;
  icon: SVGProps["name"];
  handleSkip: VoidFunction;
  ctaText: string;
  ctaCb: VoidFunction;
};
interface ISideExpertDashboardProps {
  handleToggleInvitations: VoidFunction;
  handleToggleOnboardingDialog: VoidFunction;
}

const LazyDashboardUpcomingSessions = lazy(() =>
  loadComponent(
    () => import("../UserDashboard/SideUserDashboard/DashboardUpcomingSessions")
  )
);

const DashboardUpcomingSessions = withSuspense(
  withErrorBoundary(LazyDashboardUpcomingSessions),
  {
    fallBackComponent: <PageSkeleton />
  }
);
const SideExpertDashboard = ({
  handleToggleInvitations,
  handleToggleOnboardingDialog
}: ISideExpertDashboardProps) => {
  const { activeCard, cards } = useOnboardingCards({
    handleToggleInvitations,
    handleToggleOnboardingDialog
  });

  return (
    <Box
      className="max-h-full overflow-x-hidden overflow-y-auto"
      as={Stack}
      direction="column"
      gap={4}
      style={{ flexBasis: "30%", maxWidth: "400px" }}
      paddingPreset="card"
      variant="outlined"
    >
      {/* Onboarding cards */}
      <AnimatePresence exitBeforeEnter>{cards[activeCard]}</AnimatePresence>
      <DashboardUpcomingSessions />
    </Box>
  );
};

export default SideExpertDashboard;
