
interface IProps {
    status: 'success' | 'danger' | 'warning' | 'neutral',
    label: string
}

const statusGroup = {
    warning: {
        background: 'bg-yellow-200',
        textColor: 'text-yellow-600'
    },
    danger: {
        background: 'bg-red-200',
        textColor: 'text-red-600'
    },
    success: {
        background: 'bg-success',
        textColor: 'text-success'
    },
    neutral: {
        background: 'bg-blue-400',
        textColor: 'test-blue-600'
    }
}

export const SessionIndicator = ({ status, label }: IProps) => {
    const _status = statusGroup[status]
    return (
        <div
            style={{ width: 'fit-content' }}
            className={`${_status.background} ${_status.textColor} bg-opacity-20 rounded px-2 py-1 text-xs whitespace-nowrap font-bold`}>
            {label}
        </div>
    )
}
