import { Radio } from "knack-ui";

function AnswerOption(props) {
  return (
    <li className="px-2 answerOption hover:bg-gray-100 hover:rounded-lg">
      <label
        className="flex items-center w-full py-4 cursor-pointer"
        htmlFor={props.answerType}
      >
        <Radio
          // className="w-4 h-4 mt-0 removeInputRing"
          name="radioGroup"
          checked={parseInt(props.answerType) === parseInt(props.answer)}
          id={props.answerType}
          value={props.answerType}
          // disabled={props.answer}
          onChange={() => {}}
          onClick={props.onAnswerSelected}
        />
        <span className="block text-xl ms-4 me-3">{props.answerContent}</span>
      </label>
    </li>
  );
}


export default AnswerOption;
