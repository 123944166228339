import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { CompanyMetrics, IEmployeeProgress } from '../AnalyticsReports/analyticsReportTypes';
import { IUser, IUserProfile } from "../User/UserTypes";

export const hrAnalyticsQueries = {
  all: [{ scope: "analytics" }] as const,

  employeesProgress: ({ platform, coachingMode }: ICoachingProgressOptions) =>
    [
      {
        ...hrAnalyticsQueries.all[0],
        entity: "employees progress",
        platform,
        coachingMode
      }
    ] as const,
  userSessionsTimeline: ({ time, type, user }: SessionsTimelineOptions) =>
    [
      {
        ...hrAnalyticsQueries.all[0],
        entity: "user sessions timeline",
        time,
        user,
        type
      }
    ] as const,
  dashboardSessionsTimeline: ({ time, type, external, program }: DashboardSessionsTimelineOptions) =>
    [
      {
        ...hrAnalyticsQueries.all[0],
        entity: "dashboard sessions timeline",
        time,
        external,
        program,
        type
      }
    ] as const,
  companyMetrics: () =>
    [
      {
        ...hrAnalyticsQueries.all[0],
        entity: "company metrics"
      }
    ] as const
};

export type ExternalCoachee = Omit<IUser, "profile"> & {
  bio_data: IUserProfile;
};

async function fetchEmployeesProgress({
  queryKey: [{ coachingMode, platform }]
}: QueryFunctionContext<ReturnType<typeof hrAnalyticsQueries["employeesProgress"]>>) {
  const res = await apiHttp.get<ServerResponse<IEmployeeProgress[]>>("v1/auth/mentoring-progress", {
    params: {
      platform,
      ...(coachingMode === "external" && { external: true }),
      completion_status: "Completed"
    }
  });
  return res.data.data;
}

async function fetchSessionsTimeline({
  queryKey: [{ type, time, user }]
}: QueryFunctionContext<ReturnType<typeof hrAnalyticsQueries["userSessionsTimeline"]>>) {
  const res = await apiHttp.get<ServerResponse<any[]>>("sessions/user/timeline", {
    params: {
      type,
      time,
      user
    }
  });
  return res.data.data;
}
async function fetchDashboardSessionsTimeline({
  queryKey: [{ type, time, external, program }]
}: QueryFunctionContext<ReturnType<typeof hrAnalyticsQueries["dashboardSessionsTimeline"]>>) {
  const res = await apiHttp.get<ServerResponse<any[]>>("sessions/dashboard/timeline", {
    params: {
      type,
      time,
      program,
      ...(external === true && { external: true })
    }
  });
  return res.data.data;
}
async function fetchCompanyMetrics({ queryKey: [{}] }: QueryFunctionContext<ReturnType<typeof hrAnalyticsQueries["companyMetrics"]>>) {
  const res = await apiHttp.get<ServerResponse<{ aggregates: CompanyMetrics }>>("v1/companies/metrics");
  return res.data.data.aggregates;
}
interface ICoachingProgressOptions {
  platform: "Coaching" | "Mentoring";
  coachingMode?: "internal" | "external";
}

export const useGetEmployeesProgress = (
  { platform, coachingMode }: ICoachingProgressOptions,
  options?: UseQueryOptions<IEmployeeProgress[], Error, IEmployeeProgress[], ReturnType<typeof hrAnalyticsQueries["employeesProgress"]>>
) => {
  return useQuery<IEmployeeProgress[], Error, IEmployeeProgress[], ReturnType<typeof hrAnalyticsQueries["employeesProgress"]>>(
    hrAnalyticsQueries.employeesProgress({ platform, coachingMode }),
    fetchEmployeesProgress,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};

interface SessionsTimelineOptions {
  type?: "mentoring" | "coaching";
  time: "month" | "week";
  user?: string;
}

export const useGetUserSessionsTimeline = <SelectData = any[], Error = unknown>(
  { type, time, user }: SessionsTimelineOptions,
  options?: UseQueryOptions<any[], Error, SelectData, ReturnType<typeof hrAnalyticsQueries["userSessionsTimeline"]>>
) => {
  return useQuery<any[], Error, SelectData, ReturnType<typeof hrAnalyticsQueries["userSessionsTimeline"]>>(
    hrAnalyticsQueries.userSessionsTimeline({ type, time, user }),
    fetchSessionsTimeline,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true,
      ...options
    }
  );
};
interface DashboardSessionsTimelineOptions {
  type?: "mentoring" | "coaching";
  time: "month" | "week";
  program?: string;
  external?: boolean;
}
export const useGetDashboardSessionTimeline = <SelectData = any[], Error = unknown>(
  { type, time, external, program }: DashboardSessionsTimelineOptions,
  options?: UseQueryOptions<any[], Error, SelectData, ReturnType<typeof hrAnalyticsQueries["dashboardSessionsTimeline"]>>
) => {
  return useQuery<any[], Error, SelectData, ReturnType<typeof hrAnalyticsQueries["dashboardSessionsTimeline"]>>(
    hrAnalyticsQueries.dashboardSessionsTimeline({
      type,
      time,
      external,
      program
    }),
    fetchDashboardSessionsTimeline,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true,
      ...options
    }
  );
};

export const useGetCompanyMetrics = <SelectData = CompanyMetrics, Error = unknown>(
  options?: UseQueryOptions<CompanyMetrics, Error, SelectData, ReturnType<typeof hrAnalyticsQueries["companyMetrics"]>>
) => {
  return useQuery<CompanyMetrics, Error, SelectData, ReturnType<typeof hrAnalyticsQueries["companyMetrics"]>>(
    hrAnalyticsQueries.companyMetrics(),
    fetchCompanyMetrics,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true,
      ...options
    }
  );
};
