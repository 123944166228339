import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const state = {
  options: {
    chart: {
      height: 400,
      type: "radar",
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1
      },
      toolbar: {
        show: false,
        tools: {
          download: false
        }
      }
    },
    stroke: {
      width: 2
    },
    fill: {
      opacity: 0.1
    },
    dataLabels: {
      enabled: true
    },
    plotOptions: {
      radar: {
        size: 160,
        polygons: {
          strokeColors: "#e9e9e9",
          fill: {
            colors: ["#f8f8f8", "#fff"]
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200
          },
          plotOptions: {
            radar: {
              size: 70
            }
          }
        }
      }
    ],
    colors: [],
    markers: {
      size: 4
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          wordBreack: "break-all",
          whitespace: "pre-wrap"
          // fontWeight: 600,
          // cssClass: "react-chart-label-wrap",
          // fontSize: '9px',
          // colors: labelColors,
        },
        rotateAlways: true
        // formatter: (value) => {
        //     var len = value.length
        //     return len > 12 ? value.substring(0, 12) + '...' : value
        // }
      }
    },
    yaxis: {
      tickAmount: 10,
      max: 5,
      min: 0,
      labels: {
        formatter: function (val, i) {
          if (val === 0) return 0;
          if (i % 2 === 0) {
            return val.toFixed();
          } else {
            return "";
          }
        }
      }
    }
  }
};

export const SelfAssessmentResultBreakdownByState = ({
  categories,
  series,
  colors,
  user,
  title = "Self-Assessment Results Breakdown by State"
}) => {
  const { t } = useTranslation(["assessments"]);
  const [selectedReport, setSelectReport] = useState(null);

  useEffect(() => {
    if (
      categories &&
      series &&
      colors &&
      categories.length > 0 &&
      series.length > 0
    ) {
      const _selectedReport = {
        index: 0,
        options: { ...state.options },
        series: series
      };
      _selectedReport.options.colors = colors;
      _selectedReport.options.xaxis.categories = categories?.map((c) => t(c));
      setSelectReport(_selectedReport);
    }
  }, [categories, series, colors]);

  return (
    <div className="relative flex flex-col w-full p-4 bg-white rounded-lg shadow xl:justify-center xl:items-center">
      <div className="w-full h-full">
        <div className="text-base font-bold">{title}</div>
        <div className="pb-2 text-xs text-gray-500">Scores out of 5</div>

        {/* Radar Chart */}
        <div className="pt-4">
          <div className="flex items-center justify-center">
            {!user && !categories && !series && !categories && !colors && (
              <div className="mt-8 text-xs font-semibold text-center text-skin-muted">
                {" "}
                <span className="opacity-50">⚡</span> Assessment report will
                appear here when it is available{" "}
              </div>
            )}
            {user && !selectedReport && (
              <div className="mt-8 text-xs font-semibold text-center text-skin-muted">
                {" "}
                <span className="opacity-50">⚡</span> {user?.first_name}{" "}
                Assessment report will appear here when it is available{" "}
              </div>
            )}
          </div>

          <div className="w-full sm:h-96">
            {selectedReport && (
              <ReactApexChart
                options={selectedReport.options}
                series={selectedReport.series}
                type="radar"
                height="100%"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
