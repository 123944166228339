import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useQueryClient } from "react-query";
import { useLogout, userQueryKeys } from "../features/User/userQueries";
import { apiHttp } from "../lib/appConfig";
import { resetLocalStorage } from "../services/auth.service";
import { useDebouncedCallback } from "use-debounce";

export type ServerResponse<T> = {
  message: string;
  data: T;
  status: boolean;
};
export type ServerResponseV2<T> = {
  data: T;
};

export type ServerError = AxiosError<ServerResponse<any> & { error?: any }>;
const useAxiosConfig = () => {
  const { mutate: logout } = useLogout();
  const queryClient = useQueryClient();

  const debouncedLogout = useDebouncedCallback(async () => {
    // TODO: Find a better way to logout, seems like it doesn't debounce correctly
    resetLocalStorage();
    await queryClient.resetQueries(userQueryKeys.all);
    logout();
  }, 1000);

  apiHttp.interceptors.response.use(
    (axios) => {
      return axios;
    },
    function (error) {
      if (axios.isAxiosError(error)) {
        const errorObj = error.response?.data as ServerResponse<any>;
        // If its coming from useUser then don't logout.. Its not gonna even let the user inside.
        // if (error.response?.config.url === "v1/auth/users/me") {
        //   resetLocalStorage();
        //   queryClient.resetQueries(userQueryKeys.all);
        //   return Promise.reject(error);
        // }
        //🔐 Logout if token is invalid
        if (
          errorObj &&
          (errorObj.data === "Invalid token" ||
            errorObj.data === "Invalid token found." ||
            errorObj.message === "Token Error" ||
            errorObj.message === "Invalid token found." ||
            errorObj.message === "Token not found")
        ) {
          // Adding debounced logout to prevent multiple logout calls.
          debouncedLogout();
        }
      }
      console.log("[Intercepted Response]", JSON.stringify(error.message));
      return Promise.reject(error);
    }
  );
  apiHttp.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const user = JSON.parse(localStorage.getItem("user") as string);
      if (user && config.headers && user.data.authToken) {
        config.headers["x-access-token"] = user.data.authToken;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

export default useAxiosConfig;
