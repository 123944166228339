import { Stack, Typography, Avatar, Button, AvatarGroup, Radio, Chip } from "knack-ui";
import { Link, useHistory } from "react-router-dom";
import { IProgram, IProgramInvite } from "../../features/Programs/ProgramTypes";
import { useUserRoles } from "../../features/User/userQueries";
import KnackImage from "../KnackImage/KnackImage";
import SVG from "../SVG/SVG";
import { programHasExpert } from "../../lib/utils";
import styled, { css } from "styled-components";

interface IProgramCardProps {
  program: IProgram;
  invitationId?: string;
  invitationType?: IProgramInvite["type"];
  onSelect?: (p: IProgram) => void;
  selected?: boolean;
}

const ProgramCard = ({ program, invitationId, invitationType, onSelect, selected }: IProgramCardProps) => {
  const history = useHistory();
  const { roleState } = useUserRoles();
  const selectable = typeof onSelect !== "undefined" && typeof selected !== "undefined";
  return (
    <Wrapper
      onClick={() => {
        if (selectable) {
          onSelect(program);
        }
      }}
      selectable={selectable}
    >
      <ImageWrapper
        onClick={() => {
          if (selectable) return;
          if (invitationId) {
            history.push(`/program-application/${invitationId}`);
          } else {
            history.push(`/programs/${program._id}/${program.program_type}`);
          }
        }}
      >
        <KnackImage
          style={{ aspectRatio: "1/1" }}
          className="object-contain rounded-lg "
          src={program?.image_url}
          fallbackProps={{
            className: "w-full  object-contain",
            style: { aspectRatio: "1/1" }
          }}
          alt={`${program?.name} cover`}
        />
      </ImageWrapper>
      <ContentWrapper>
        <Stack alignItems="center" justifyContent="space-between">
          <Typography
            title={program.name}
            as={Link}
            to={invitationId ? `/program-application/${invitationId}` : `/programs/${program._id}/${program.program_type}`}
            clamp={1}
            variant="body2"
            fontWeight="bold"
            className="flex-1 hover:underline"
          >
            {program.name}
          </Typography>
          {selectable && (
            <Radio
              checked={selected}
              onChange={() => {
                onSelect(program);
              }}
            />
          )}
        </Stack>
        <Chip shape='rounded' size='small' className="self-start" variant='tertiary'>
          {program.coach_type === "knack_coaching" ? (
            <Stack gap={1} alignItems="center">
              <SVG size={13} name="KnackMiniLogo" /> <span>Knack {program.program_type}</span>
            </Stack>
          ) : (
            program.program_type
          )}
        </Chip>
        {/* <div className="self-start px-2 py-1 text-xs rounded bg-opacity-20 text-knackPrimary bg-knackPrimary">
          {program.coach_type === "knack_coaching" ? (
            <Stack gap={1} alignItems="center">
              <SVG size={13} name="KnackMiniLogo" /> <span>Knack {program.program_type}</span>
            </Stack>
          ) : (
            <p>{program.program_type}</p>
          )}
        </div> */}

        {roleState?.activeRole === "User" && (
          <Stack gap={2} alignItems="center" justifyContent="space-between">
            {invitationId && invitationType && (
              <Typography color="primary" variant="caption" fontWeight="semibold">
                You have been invited to join as a {invitationType}
              </Typography>
            )}
            {!invitationId && (
              <div>
                <Typography className="mb-2" fontWeight="semibold" variant="caption">
                  Expert:
                </Typography>
                <Stack gap={2} alignItems="center">
                  {program.expert && (
                    <Avatar
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/connections/${program.expert?.user._id}`);
                      }}
                      text={program.expert.first_name}
                      className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                      image={program.expert.user.profile_image}
                    />
                  )}

                  <Stack className="flex-1" gap={3} alignItems="flex-end" justifyContent="space-between">
                    {!programHasExpert(program) && (
                      <Typography variant="caption" color="muted" fontWeight="medium">
                        No Expert Assigned
                      </Typography>
                    )}

                    {program.expert && (
                      <div className="mt-1">
                        <Typography variant="body2" fontWeight="bold">
                          {`${program.expert?.first_name} ${program.expert?.last_name}`}
                        </Typography>
                      </div>
                    )}
                  </Stack>
                </Stack>
              </div>
            )}
            {invitationId && (
              <Button
                variant="small"
                kind="primaryOutline"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  history.push(`/program-application/${invitationId}`);
                }}
              >
                Register
              </Button>
            )}
          </Stack>
        )}
        {roleState?.activeRole === "Expert" && (
          <Stack gap={2} alignItems="center">
            {invitationId && invitationType && (
              <Typography color="primary" variant="caption" fontWeight="semibold">
                You have been invited to join as a {invitationType}
              </Typography>
            )}
            {program.connections && program.connections?.length > 0 && (
              <div>
                <Typography className="mb-2" fontWeight="semibold" variant="caption">
                  Clients :
                </Typography>
                <div className="flex justify-start">
                  <AvatarGroup max={3}>
                    {program.connections?.map((c) => (
                      <Avatar
                        key={c._id}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`/connections/${c.user._id}`);
                        }}
                        size="small"
                        className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                        text={c.first_name}
                        image={c.user.profile_image}
                      />
                    ))}
                  </AvatarGroup>
                </div>
              </div>
            )}
            {program.connections?.length === 0 && (
              <Typography className="mb-2" fontWeight="semibold" color="muted" variant="caption">
                No clients yet
              </Typography>
            )}

            {invitationId && (
              <Button
                variant="small"
                kind="primaryOutline"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  history.push(`/program-application/${invitationId}`);
                }}
              >
                Register
              </Button>
            )}
          </Stack>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ProgramCard;

const Wrapper = styled.div<{ selectable?: boolean }>`
  display: flex;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--color-border);
  background-color: var(--color-gray-100);
  ${(p) =>
    p.selectable &&
    css`
      cursor: pointer;
      transition: background 150ms ease-in-out;
      @media ${(p) => p.theme.queries.hoverPointerDevices} {
        &:hover {
          background-color: var(--color-gray-200);
        }
      }
    `}
`;

const ImageWrapper = styled.div`
  padding: 16px;
  width: 25%;
  /* align-self: start; */
  cursor: pointer;
  max-height: 100%;
`;

const ContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 1;
`;
