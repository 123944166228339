import React, { useEffect, useState } from "react";

export const CollapsibleWrapperWithLineBar = ({
  children,
  Icon,
  SectionName,
  ItemsCounts,
  id,
  SectionFallbackName,
  isOpen = true
}) => {
  const localOpenState = localStorage.getItem(
    typeof SectionName === "string" ? SectionName : SectionFallbackName
  );
  const [open, setOpen] = useState(
    localOpenState ? JSON.parse(localOpenState) : isOpen
  );

  useEffect(() => {
    setOpen(isOpen);
  }, []);

  return (
    <div>
      {/* Header Bar */}
      <div
        className="flex flex-row text-sm items-center cursor-pointer pb-6"
        onClick={() => {
          localStorage.setItem(
            typeof SectionName === "string" ? SectionName : SectionFallbackName,
            !open
          );
          setOpen(!open);
        }}
      >
        {/* Droplet Arrow */}
        <div
          className={`translate transform delay-300 duration-300 ${
            open ? "" : "-rotate-90"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3"
            viewBox="0 0 18 9"
            fill="#ddd"
          >
            <path
              id="Icon_ionic-md-arrow-dropdown"
              data-name="Icon ionic-md-arrow-dropdown"
              d="M9,13.5l9,9,9-9Z"
              transform="translate(-9 -13.5)"
            />
          </svg>
        </div>
        {/* Item Icon */}
        <div className="mx-2"> {Icon} </div>
        {/* SectionName */}
        <div className="text-skin-base font-bold whitespace-nowrap text-xl">
          {SectionName}
        </div>
        {/* Optional Item Counter */}
        <div className="text-gray-500 ml-2">
          {ItemsCounts ? "(" + ItemsCounts + ")" : ""}
        </div>
        {/* Line */}
        <div className="w-full h-0.5 bg-gray-100 ml-4"></div>
      </div>

      {/* Content */}
      <div
        className={`transition	duration-500 ease-in-out  ${
          open ? "h-auto opacity-100" : "h-0 opacity-10 overflow-hidden"
        }`}
      >
        {children}
      </div>
    </div>
  );
};
