import { CircularProgress, Stack, Typography } from "knack-ui";
import { IUtilization } from "../../../../features/People/PeopleTypes";

interface IProps {
  utilization: IUtilization;
}

export const Utilization = ({ utilization }: IProps) => {
  const exclude = [0, "N/A"]
  return (
    <>
      {utilization &&
        !exclude.includes(utilization?.capacity) ? (
        <Stack gap={4} alignItems="center" className="bg-transparent bg-none">
          <CircularProgress
            size={40}
            strokeWidth={2}
            percentage={Math.floor(
              (+utilization?.totalUserConnections /
                +utilization?.capacity || 0) *
              100
            )}
            showText
          />

          <Typography variant="body2" fontWeight="medium">
            {utilization?.totalUserConnections}/
            {utilization?.capacity || 0}
          </Typography>
        </Stack>
      ) : (
        "N/A"
      )}
    </>
  );
};
