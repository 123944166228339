import { Avatar, Box, Button, Stack, Typography } from "knack-ui";
import { useMemo } from "react";
import { IConnectionRequest } from "../../features/Connections/ConnectionsTypes";
import { useEndRelationship, useRemoveUserFromProgram } from "../../features/Programs/programMutations";
import { programsQueryKeys, useGetParticipantRequests } from "../../features/Programs/programQueries";
import { IProgram } from "../../features/Programs/ProgramTypes";
import { IUserProfile } from "../../features/User/UserTypes";
import { queryClient } from "../../lib/appConfig";
import ConfirmationDialog from "../ConfirmationDialog";
import { Failure } from "../ToastNotification/Failure";

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  userProfile: IUserProfile;
  program: IProgram;
  userType: "user" | "expert";
}

export const RemoveUserFromProgram = ({ setOpen, open, userProfile, program, userType }: IProps) => {
  const { data: requestList } = useGetParticipantRequests(
    {
      participantId: userProfile.user._id,
      programId: program._id,
      userType
    },
    {
      select: (res) => res.filter((r) => r.status === "approved")
    }
  );
  const { mutateAsync: removeUser } = useRemoveUserFromProgram();

  const willRemoveUserFromProgram = async () => {
    try {
      await removeUser({ userId: userProfile.user._id, programId: program._id });
      setOpen(false);
      queryClient.invalidateQueries(programsQueryKeys.all);
    } catch (error: any) {
      Failure(error?.response?.data?.data);
    }
  };

  const requests = useMemo(() => {
    if (!requestList) return [];
    return requestList;
  }, [requestList]);

  return (
    <ConfirmationDialog
      isOpen={open}
      onClose={() => setOpen(false)}
      headerText={`Remove ${userProfile.first_name || userProfile?.user?.email || ""} ${userProfile.last_name || ""}`}
      footerComponent={() => {
        return (
          <Stack justifyContent="flex-end" gap={2}>
            <Button disabled={requests.length > 0} kind="ghost" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button disabled={requests.length > 0} onClick={() => willRemoveUserFromProgram()}>
              Remove from this program
            </Button>
          </Stack>
        );
      }}
    >
      {requests.length === 0 && (
        <>
          <Typography className="mb-4" fontWeight="semibold">
            This action will end all {userProfile?.first_name || userProfile?.user?.email || ""}'s engagements in this program
          </Typography>
          <ul className="grid grid-cols-1 gap-1 my-4 list-disc ms-4">
            <Typography as="li" variant="subtitle2">
              {userProfile?.first_name || userProfile?.user?.email || ""} will no longer be able to log / schedule a session
            </Typography>
            <Typography as="li" variant="subtitle2">
              {userProfile?.first_name || userProfile?.user?.email || ""} will not be able to view their connection(s)
            </Typography>
          </ul>
        </>
      )}

      {requests.length > 0 && (
        <>
          <Typography className="mb-4" fontWeight="semibold">
            {userProfile?.first_name || userProfile?.user?.email || ""} has active connection(s). please review and end their pairing before
            removing this {userType} from the program.
          </Typography>

          <Stack direction="column" gap={4}>
            {requests.map((r, i) => (
              <ActiveRelationships key={i} request={r} />
            ))}
          </Stack>
        </>
      )}
    </ConfirmationDialog>
  );
};

const ActiveRelationships = ({ request }: { request: IConnectionRequest }) => {
  const { mutateAsync: endRelationship, isLoading } = useEndRelationship();

  const onEndRelationship = async () => {
    try {
      await endRelationship(request._id);
      queryClient.invalidateQueries(programsQueryKeys.all);
    } catch (error: any) {
      Failure(error?.response?.data?.data);
    }
  };

  return (
    <Box className="grid w-full grid-cols-3 border-bottom-1" style={{ boxShadow: "none" }}>
      <Stack gap={2} alignItems="center">
        <Avatar size="small" image={request?.mentorProfile?.profile_image} text={request?.mentorProfile?.first_name} />
        <Typography variant="subtitle2">
          {request?.mentorProfile?.first_name} {request?.mentorProfile?.last_name}
        </Typography>
      </Stack>
      <Stack gap={2} alignItems="center">
        <Avatar size="small" image={request?.menteeProfile?.profile_image} text={request?.menteeProfile?.first_name} />
        <Typography variant="subtitle2">
          {request.menteeProfile?.first_name} {request?.menteeProfile?.last_name}
        </Typography>
      </Stack>

      <Box className="flex w-full items" style={{ justifyContent: "end", boxShadow: "none" }}>
        <Button variant="xsmall" kind="warning" isLoading={isLoading} onClick={() => onEndRelationship()}>
          End pairing
        </Button>
      </Box>
    </Box>
  );
};
