import { Button, Chip, Input, Popover, Typography } from "knack-ui";
import { matchSorter } from "match-sorter";
import { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import AutoCompleteInput from "../AutoCompleteInput/AutoCompleteInput";
import EmptyState from "../EmptyState/EmptyState";
import SearchInput from "../SearchInput/SearchInput";
import SVG from "../SVG/SVG";

interface IControlledChipListProps<T> {
  onItemSelect?: (item: T) => void;
  items: T[];
  selectedItems?: T[];
  searchBarProps?: {
    placeholder: string;
    searchFn: (item: T, search: string) => void;
  };
  hideSearchBar?: boolean;
  addItemProps?: {
    inputLabel: string;
    buttonText: string;
    onAddItem: (item: string) => void;
    validationFn?: (inputValue: string, allItems: T[]) => boolean;
  };
}

function ControlledChipList<T>({
  onItemSelect,
  addItemProps,
  items,
  hideSearchBar,
  searchBarProps,
  selectedItems
}: IControlledChipListProps<T>) {
  const [search, setSearch] = useState("");

  const [customInputValue, setCustomInputValue] = useState("");

  const [customInputError, setCustomInputError] = useState("");

  const [newOpen, setNewOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const onAddCustomValue = (value: string) => {
    setCustomInputError("");
    if (addItemProps?.validationFn?.(value, [...items, ...(selectedItems ?? [])])) {
      addItemProps?.onAddItem(value);
      setCustomInputValue("");
      setNewOpen(false);
      setSearch("");
    } else {
      setCustomInputError("Value already exists");
    }
  };

  const filteredItems = useMemo(() => {
    const allItems = [...items, ...(selectedItems ?? [])];
    if (!searchBarProps) return { allItems, items };
    return {
      allItems: allItems.filter((item) => searchBarProps?.searchFn(item, search)),
      selectedItems: [...(selectedItems ?? [])].filter((item) => searchBarProps?.searchFn(item, search)),
      items: items.filter((item) => searchBarProps?.searchFn(item, search))
    };
  }, [items, selectedItems, searchBarProps, search]);

  const suggestions = useMemo(() => {
    const allTopics = [...items, ...(selectedItems ?? [])] as string[];
    if (!search) return allTopics;
    const sortedArr = matchSorter(allTopics, search);
    return sortedArr;
  }, [items, search, selectedItems]);

  return (
    <>
      {!hideSearchBar && (
        <SearchInputWrapper>
          <AutoCompleteInput
            onCustomValueSelection={onAddCustomValue}
            onExistingSuggestionSelect={(value) => {
              onItemSelect?.(value as T);
              setSearch("");
            }}
            suggestions={suggestions}
            inputProps={{
              placeholder: searchBarProps?.placeholder,
              inputPrefix: <SVG name="Search" />,
              inputSuffix: search ? (
                <SVG
                  className="cursor-pointer"
                  name="Close"
                  onClick={() => {
                    setSearch("");
                  }}
                />
              ) : (
                <></>
              )
            }}
            value={search}
            onChange={setSearch}
          />
          {/* <SearchInput placeholder={searchBarProps?.placeholder} value={search} onChange={setSearch} /> */}
        </SearchInputWrapper>
      )}
      <List>
        {/* {search === "" && addItemProps && (
          <Button
            onClick={() => setNewOpen(true)}
            ref={buttonRef}
            startIcon={<SVG name="Add" />}
            kind="success"
            variant="xsmall"
            shape="rounded"
          >
            {addItemProps?.buttonText}
            <Popover
              offset={{ bottom: 0, left: 0 }}
              popoverProps={{
                className: "overflow-hidden",
                style: { zIndex: 9999 },
                variant: "elevated",
                elevation: 6
              }}
              animationType="fade-up"
              isOpen={newOpen}
              onClose={() => setNewOpen(false)}
              parentRef={buttonRef}
            >
              <PopoverContents
                onSubmit={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onAddCustomValue(customInputValue);
                }}
                id="custom"
              >
                <Typography variant="subtitle2">{addItemProps?.inputLabel}</Typography>
                <div>
                  <Input
                    value={customInputValue}
                    onChange={(e) => {
                      if (customInputError) {
                        setCustomInputError("");
                      }
                      setCustomInputValue(e.target.value);
                    }}
                    error={customInputError}
                  />
                </div>
                <Button type="submit" form="custom" startIcon={<SVG name="Add" />} fullWidth kind="tertiary">
                  Add
                </Button>
              </PopoverContents>
            </Popover>
          </Button>
        )} */}
        {selectedItems?.map((s, i) => (
          <Chip
            style={{ cursor: onItemSelect ? "pointer" : "auto" }}
            onClick={() => {
              onItemSelect?.(s);
            }}
            onDelete={() => {
              onItemSelect?.(s);
            }}
            shape="rounded"
            variant="primary"
            key={i}
          >
            {s}
          </Chip>
        ))}
        {items.length === 0 && <EmptyState title="We Couldn't find any topics" />}
        {items.map((s, i) => (
          <Chip
            style={{ cursor: onItemSelect ? "pointer" : "auto" }}
            onClick={() => {
              onItemSelect?.(s);
            }}
            shape="rounded"
            variant="primaryOutline"
            key={i + 100}
          >
            {s}
          </Chip>
        ))}
      </List>
      {/* {!filteredItems.allItems.length && search && (
        <EmptyState
          icon="SearchStop"
          iconSize={60}
          title={`No search results for "${search}"`}
          subtitle="Try using a different spelling"
          {...(typeof addItemProps !== "undefined" && {
            buttonText: `Add ${search}`,
            buttonCb: () => {
              onAddCustomValue(search);
            }
          })}
        />
      )} */}
    </>
  );
}

export default ControlledChipList;

const SearchInputWrapper = styled.div`
  margin-bottom: 24px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

const PopoverContents = styled.form`
  display: flex;
  gap: 16px;
  padding: 16px;
  flex-direction: column;
`;
