import { AnimatePresence, Variants } from "framer-motion";
import { Box, Button, Stack, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { VscClose } from "react-icons/vsc";
import { NewSessionModalHeight } from "../../../Goals/ui/NewGoalModal";
import ScheduleSessionProgramSelection from "./SessionModal/SessionProgramSelection";
import SessionMenteeSelection from "./SessionModal/SessionMenteeSelection";
import { useUser, useUserCompany, useUserRoles } from "../../../../../features/User/userQueries";

export const NewSessionPageTransitions: Variants = {
  hidden: { x: "100%", opacity: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  },
  exit: {
    x: "-100%",
    opacity: 0,
    transition: {
      ease: "easeOut",
      duration: 0.3
    }
  }
};

interface IScheduleSessionModal {
  handleClose: VoidFunction;
}

const ScheduleSessionModal = ({ handleClose }: IScheduleSessionModal) => {
  const { roleState } = useUserRoles();
  const { data: user } = useUser();
  const { i18n } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handlePreviousStep = () => {
    if (currentStep === 0) return handleClose();
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <Box dir={i18n.dir(i18n.resolvedLanguage)} elevation={0} paddingPreset="card">
      <Button className="z-10 end-6 top-6" style={{ position: "absolute" }} onClick={handleClose} kind="ghost" variant="small" iconOnly>
        <VscClose size={25} />
      </Button>
      <Typography className="mb-6" variant="h5" as="h2">
        {user?.account_type === "Coach" || roleState?.activeRole === "Expert" ? "Schedule upcoming session" : "Schedule Session"}
      </Typography>
      <div style={{ height: NewSessionModalHeight }}>
        <AnimatePresence exitBeforeEnter>
          {currentStep === 0 && (
            <ScheduleSessionProgramSelection key={"Program"} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
          )}
          {currentStep === 1 && (
            <SessionMenteeSelection key={"Mentee"} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />
          )}
        </AnimatePresence>
      </div>
    </Box>
  );
};

export default ScheduleSessionModal;
