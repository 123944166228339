import { Button, Input, Stack, Textarea, Typography } from "knack-ui";
import { Dispatch, SetStateAction } from "react";
import DatePicker from "react-datepicker";
import { Controller, SubmitHandler, useForm, useFormContext } from "react-hook-form";

import { INewGoalFormProps } from "../../../../../features/Goals/goalsTypes";
import { format } from "date-fns/esm";
import SVG from "../../../../../components/SVG/SVG";

type GoalInfoProps = Pick<INewGoalFormProps, "title" | "outcome" | "due_date" | "visible">;

const GoalInfo = ({
  setActiveStep,
  setCompletedSteps,
  admin
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  setCompletedSteps: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  admin: boolean;
}) => {
  const { setValue, getValues } = useFormContext<INewGoalFormProps>();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit
  } = useForm<GoalInfoProps>({
    defaultValues: {
      due_date: getValues("due_date") || new Date(),
      title: getValues("title"),
      outcome: getValues("outcome")
    }
  });
  const onSubmit: SubmitHandler<GoalInfoProps> = async (data) => {
    setActiveStep((prev) => prev + 1);
    setCompletedSteps((prev) => ({ ...prev, 0: true }));
    Object.keys(data).forEach((key) => {
      // @ts-ignore
      setValue(key, data[key]);
    });
  };

  return (
    <>
      <form id="info" className="p-6 dialog-content-max-h scrollhost2" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" className="mb-4" gap={5}>
          <div>
            <Typography htmlFor="title" className="block mb-4" variant="body1" fontWeight="bold" as="label">
              What do you want to accomplish?
            </Typography>

            <Input
              size="large"
              id="title"
              placeholder="Enter goal name"
              {...register("title", { required: "Required" })}
              error={errors?.title?.message}
            />
          </div>
          <div>
            <Typography htmlFor="outcome" className="block" variant="body1" fontWeight="bold" as="label">
              Goal outcomes
            </Typography>
            <Typography className="mb-4" variant="subtitle2" color="muted">
              How would the outcome look if you achieved your goal?
            </Typography>
            <Textarea
              rows={6}
              id="outcome"
              {...register("outcome", { required: "Required" })}
              error={errors?.outcome?.message}
              placeholder="Enter goal outcomes"
            />
          </div>
          {!admin && (
            <div>
              <Typography className="block mb-4" variant="body1" fontWeight="bold" as="label">
                When do you want to accomplish it by?
              </Typography>
              <Controller
                control={control}
                name="due_date"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    shouldCloseOnSelect={true}
                    dateFormat="MMMM d, yyyy"
                    minDate={new Date()}
                    onChange={(date) => {
                      onChange(date);
                    }}
                    customInput={<Button startIcon={<SVG name="Edit" />}>{format(value, "dd/MM/yyyy")}</Button>}
                    selected={value}
                    placeholderText="Select due date"
                    // className="w-full border-gray-300 rounded-lg text-skin-base"
                  />
                )}
              />
            </div>
          )}
        </Stack>
      </form>
      <Stack className="p-6 border-t" justifyContent="flex-end" gap={2}>
        <Button form="info" type="submit">
          Next
        </Button>
      </Stack>
    </>
  );
};

export default GoalInfo;
