import React, { useEffect, useState } from "react";
import * as CronofyElements from "cronofy-elements";

export const SetAvailabilityRules = ({ options }) => {
  const [element, setElement] = useState(null);
 
  useEffect(() => {
    if (!element) {
      setElement(CronofyElements.AvailabilityRules({ ...options }));
    }
  }, [element, options]);

  useEffect(() => {
    if (element) {
      element.update({ ...options });
    }
  }, [element, options]);

  return <div id="availability-rules" />;
};
