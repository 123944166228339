import { Suspense } from "react";
import styled from "styled-components";
import create from "zustand";
import { SidebarWidthCollapsed, SidebarWidthExtended } from "../../lib/constants";
import PageSkeleton from "../skeletons/PageSkeleton";
import Sidebar from "./Sidebar";

export const SidebarState = create<{
  expanded: boolean;
  setExpanded: (e: boolean) => void;
}>((set) => ({
  expanded: true,
  setExpanded: (expanded) => set(() => ({ expanded }))
}));

const AppLayout: React.FC = ({ children }) => {
  const { expanded } = SidebarState();

  return (
    <div
      id="app-layout"
      style={{
        gridTemplateColumns: expanded ? `${SidebarWidthExtended}px 1fr` : `${SidebarWidthCollapsed}px 1fr`
      }}
      className="grid min-h-full transition-all duration-150"
    >
      <Sidebar />
      <MainWrapper className="scrollhost2">
        <Suspense fallback={<PageSkeleton />}>{children}</Suspense>
      </MainWrapper>
    </div>
  );
};

export default AppLayout;

const MainWrapper = styled.main`
  max-width: 100%;
  min-width: 0;
  padding: 24px;
`;
