import { Dispatch, SetStateAction } from "react";
import { IUserProfile } from "../User/UserTypes";

export const DefaultSectionTitle = "Untitled section";
export const DefaultQuestionTitle = "Untitled question";
export interface ISurvey {
  _id: string;
  title: string;
  subtitle: string;
  organization: string;
  type: typeof SurveyTypes[number];
  status: "pending" | "published";
  program: string;
  createdAt: string;
  updatedAt: string;
  components: SurveySection[];
  due_date?: string;
  recurring?: {
    sendUntil: Date;
    isActive: boolean;
  };
  /**
   * Interval
   */
  frequency?: number; // e.g daily=1, weekly=7, monthly=28/29/30/31
}

export interface ISurveyResponse {
  answers: SurveyResponseSection[];
}
export type SurveyResponseSection = {
  question: SectionQuestion;
  question_answer: QuestionAnswer;
}[];

export type QuestionAnswer = SingleValueAnswer | MultiValueAnswer;

export type SingleValueAnswer = string;
export type MultiValueAnswer = string[];
// components means list of sections basically
export type SurveySection = {
  section_title: string;
  section_description: string;
  section_questions: SectionQuestion[];
};
export type SectionQuestion = BaseQuestion & (TextQuestion | QuestionWithOptions | LinearQuestion);
interface BaseQuestion {
  required: boolean;
  title: string;
}

interface TextQuestion {
  question_type: "text";
  options: never[];
}
interface QuestionWithOptions {
  question_type: "multiple_choice" | "checkbox";
  options: { option_label: string }[];
}
interface LinearQuestion {
  question_type: "linear";
  options: { option_label: string }[];
  from: number;
  to: number;
}

export const SectionQuestionTypes = ["multiple_choice", "checkbox", "text", "linear"] as const;

export type NewSurvey = Pick<ISurvey, "title" | "subtitle" | "program" | "type" | "components" | "status" | 'recurring' | 'frequency'> & { due_date?: string };

export const SurveyTypes = ["user_eval", "program_eval", "expert_eval"] as const;

export interface ISurveyStepProps {
  setStep: Dispatch<SetStateAction<number>>;
  setCompletedSteps: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

export const SurveyDefaultQuestion: SectionQuestion = {
  question_type: "text",
  required: false,
  title: DefaultQuestionTitle,
  options: []
};
export const SurveyDefaultSection: SurveySection = {
  section_description: "",
  section_title: "",
  section_questions: []
};
export const SurveyComponentsDefaultValue: SurveySection[] = [
  {
    section_title: "Untitled survey",
    section_description: "",
    section_questions: [SurveyDefaultQuestion]
  }
];
export interface ISurveyAnswerComponentProps {
  questionIndex: number;
  sectionIndex: number;
  index: number;
  question: SectionQuestion;
  value: QuestionAnswer;
  onChange: (answer: QuestionAnswer) => void;
}
export interface SurveyStoreProps {
  focusedSection: {
    componentType: "section" | "question";
    questionIndex: number;
    sectionIndex: number;
  } | null;
  widgetTop: number;
  setWidgetTop: (value: number) => void;
  onElementFocus: (arg: { componentType: "section" | "question"; questionIndex: number; sectionIndex: number }) => void;
}
export const surveyTypes = [
  {
    title: "Expert feedback",
    type: "user_eval",
    subtitle:
      "This survey is ideal if you want to gather feedback from experts about their clients' progress.  This survey will be sent to the expert for each of their clients separately."
  },
  {
    title: "User feedback",
    type: "expert_eval",
    subtitle:
      "This survey type is ideal if you want to gather feedback from the users about your experts (i.e., internal mentors or coaches)"
  },
  {
    title: "Program evaluation",
    type: "program_eval",
    subtitle: "This survey type is ideal if you want to gather general feedback on the program from all participants."
  }
];

export interface ISurveyInvitation {
  createdAt: string;
  organization: string;
  program: string;
  survey: ISurvey;
  survey_completed: boolean;
  updatedAt: string;
  user: string;
  _id: string;
  recipient: string;
  recipientProfile?: IUserProfile;
}
export interface ISurveyResponse {
  _id: string;
  user: string;
  organization: string;
  survey: string;
  surveyInvite: string;
  program: string;
  createdAt: string;
  answers: SurveyResponseSection[];
  userProfile: IUserProfile;
  recipientProfile?: IUserProfile;
  recipient?: string;
}
