import { Button, Input, Stack, Typography } from "knack-ui";
import { MEETING_DURATIONS } from "../../../App.constant";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useUserCompany } from "../../../features/User/userQueries";
import { useMemo } from "react";
import styled from "styled-components";

interface IProps {
  duration: number;
  setDuration: (value: number) => void;
}

export const DurationSelector = ({ duration, setDuration }: IProps) => {
  const { data: company } = useUserCompany();

  const meetingDuration = useMemo(() => {
    if (company?.meetingDurations && company?.meetingDurations?.length > 0) {
      const computed = [...company.meetingDurations].filter((d) => d !== null).map((d) => ({ value: d, title: `${d} mins` }));
      if (computed.length > 0) {
        setTimeout(() => setDuration(computed[0].value), 2000);
        return { durations: computed, isComputed: true };
      }
    }
    return { durations: MEETING_DURATIONS, isComputed: false };
  }, [company]);

  return (
    <Stack direction="column" gap={2} className="w-72">
      <div className="mb-2 font-medium">Meeting Duration</div>
      {!meetingDuration?.isComputed && (
        <Stack>
          <Input
            style={{ height: 50, fontSize: "1.1rem", fontWeight: 600 }}
            value={duration}
            min={10}
            type="number"
            inputMode="numeric"
            onChange={(e) => {
              setDuration(+e.target.value);
            }}
            inputClassName="pl-4"
            inputPrefix={
              <Stack direction="column" className="pl-4 bg-ghost">
                <Button
                  style={{ height: 20, padding: 0, margin: 0 }}
                  className="p-0 m-0"
                  kind="ghost"
                  onClick={() => setDuration(+duration + 5)}
                >
                  <BiChevronUp size={20} />
                </Button>
                <Button
                  style={{ height: 20, padding: 0, margin: 0 }}
                  kind="ghost"
                  disabled={duration === 10 || duration === 0}
                  onClick={() => setDuration(+duration - 5)}
                >
                  <BiChevronDown size={20} />
                </Button>
              </Stack>
            }
            inputSuffix={
              <Typography fontWeight="medium" className="pr-4">
                mins
              </Typography>
            }
          />
        </Stack>
      )}
      <DurationsWrapper>
        {meetingDuration.durations.map((m, i) => (
          <Button
            kind={m.value === duration ? "primary" : "defaultOutline"}
            variant="xsmall"
            key={i}
            onClick={() => {
              setDuration(m.value);
            }}
          >
            {m.title}
          </Button>
        ))}
      </DurationsWrapper>
    </Stack>
  );
};

const DurationsWrapper = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;
