import Color from "color";

/**TODO: - Revamp to use query. Line 24 - 25 breaks if user logged in from SSO */
export const InitCompanySkin = () => {
  // get company default body/document background
  // document.body.style.background = "var(--color-body-fill-light)";
  // document.body.style.background = "#f7f9fd";

  const company = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))?.data?.organization
    : null;

  if (company) {
    if (!company.dark_color) {
      company.dark_color = "#1F40E6";
    }
    var aRgbHex = company.dark_color.replace("#", "").match(/.{1,2}/g);
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16)
    ];
    const primaryColor = aRgb;
    const primaryColorDark = Color(aRgb).darken(0.2).rgb().array().join(",");
    const primaryTextColor = Color(company.dark_color).darken(0.2).isDark()
      ? "#fff"
      : "#252525";

    const tertiaryColor = Color(primaryColor)
      .alpha(0.2)
      .rgb()
      .array()
      .join(",");
    const tertiaryTextColor = company.dark_color;

    const primaryHSLArr = Color(primaryColor).hsl().array();
    const primaryHSL = `hsl(${primaryHSLArr[0].toFixed(0)}deg, ${
      primaryHSLArr[1].toFixed(0)
    }%, ${primaryHSLArr[2].toFixed(0)}%)`;

    const onPrimaryHSLArr = Color(primaryTextColor).hsl().array();
    const onPrimaryHSL = `hsl(${onPrimaryHSLArr[0].toFixed(0)}deg, ${
      onPrimaryHSLArr[1].toFixed(0)
    }%, ${onPrimaryHSLArr[2].toFixed(0)}%)`;


    const tertiaryHSLArr = Color(primaryColor).alpha(0.1).hsl().array();
    const tertiaryHSL = `hsla(${tertiaryHSLArr[0].toFixed(0)}deg, ${
      tertiaryHSLArr[1].toFixed(0)
    }%, ${tertiaryHSLArr[2].toFixed(0)}%, ${tertiaryHSLArr[3]})`;

    document.documentElement.style.setProperty(
      "--color-accent",
      company.dark_color
    );
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--primary-color-hex",
      company.dark_color
    );
    document.documentElement.style.setProperty(
      "--tertiary-color",
      tertiaryColor
    );

    document.documentElement.style.setProperty(
      "--color-primary",
      primaryHSL
    );
    document.documentElement.style.setProperty(
      "--color-on-primary",
      onPrimaryHSL
    );
    document.documentElement.style.setProperty(
      "--color-primary-background",
      tertiaryHSL
    );
    document.documentElement.style.setProperty(
      "--primary-dark-color",
      primaryColorDark
    );
    document.documentElement.style.setProperty(
      "--primary-text-color",
      primaryTextColor
    );
    document.documentElement.style.setProperty(
      "--tertiary-text-color",
      tertiaryTextColor
    );
    document.documentElement.style.setProperty(
      "--color-button-accent",
      company.dark_color
    );
    document.documentElement.style.setProperty(
      "--color-text-inverted",
      company.dark_color
    );

    document.documentElement.style.setProperty("--color-fill", aRgb.toString());
  }
};
