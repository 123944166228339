import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

const getEmployees = () => {
  return axios.get(API_URL + "v1/auth/employees", { headers: authHeader() });
};

const getEmployeesByCompanyId = (companyId) => {
  return axios.get(API_URL + "v1/auth/employees?organization=" + companyId, {
    headers: authHeader()
  });
};

const getMenteesByCompanyId = (companyId) => {
  return axios.get(API_URL + "v1/auth/get-mentees?organization=" + companyId, {
    headers: authHeader()
  });
};

const getCoaches = () => {
  return axios.get(API_URL + "v1/auth/coaches", { headers: authHeader() });
};

const getCoachesRating = () => {
  return axios.get(API_URL + "sessions/coaches/ratings", {
    headers: authHeader()
  });
};

const getHR = () => {
  return axios.get(API_URL + "v1/auth/users?account_type=Hr ", {
    headers: authHeader()
  });
};

const getUsersByType = (type) => {
  return axios.get(API_URL + "v1/auth/users?account_type=" + type, {
    headers: authHeader()
  });
};

/**
 * Create new company
 * @param {Company Name} name
 * @returns
 */
const createCompany = (company) => {
  return axios.post(API_URL + "v1/companies", company, {
    headers: authHeader()
  });
};

const updateCompany = (company, companyId) => {
  return axios.post(API_URL + `v1/companies/${companyId}/update`, company, {
    headers: authHeader()
  });
};

const getCompanies = () => {
  return axios.get(API_URL + "v1/companies", { headers: authHeader() });
};

const getConnectedCalendars = () => {
  return axios.get(API_URL + "v1/events/calendarsprofiles", {
    headers: authHeader()
  });
};

export const getCompanyById = (companyId) => {
  return axios.get(API_URL + "v1/companies/" + companyId, {
    headers: authHeader()
  });
};

export const setCompanyPreferredCoaches = (companyId, coaches) => {
  return axios.post(
    API_URL + "v1/auth/add-company-coach/" + companyId,
    { coaches },
    { headers: authHeader() }
  );
};

export const getCompanyPreferredCoaches = (companyId) => {
  return axios.get(API_URL + "v1/auth/company-coach/" + companyId, {
    headers: authHeader()
  });
};

export const deleteCompanyPreferredCoach = (company, coach) => {
  return axios.get(
    API_URL + "v1/auth/company-coach/delete/" + company + "/" + coach,
    { headers: authHeader() }
  );
};

/**
 * Create new Team
 * @param {Company Name} name
 * @returns
 */
const createTeam = (name, organization) => {
  return axios.post(
    API_URL + "v1/teams",
    { name, organization },
    { headers: authHeader() }
  );
};

const getTeams = () => {
  return axios.get(API_URL + "v1/teams", { headers: authHeader() });
};

const createAssessmentCollection = (framework) => {
  return axios.post(API_URL + "assessments/frameworks/custom", framework, {
    headers: authHeader()
  });
};

/**
 * Update Custom Framework for company
 * POST Level three mapping for custom framework
 * @returns
 */
const moveAssessmentTagToCollection = (framework) => {
  return axios.post(
    API_URL + "assessments/frameworks/custom/?mapping=true",
    framework,
    { headers: authHeader() }
  );
};

const getAssessmentCollections = () => {
  return axios.get(API_URL + "assessments/frameworks?type=level_two", {
    headers: authHeader()
  });
};

const getDefaultAssessmentTags = () => {
  return axios.get(API_URL + "assessments/frameworks", {
    headers: authHeader()
  });
};

const getCustomAssessmentCollections = (companyId) => {
  return axios.get(
    API_URL + `assessments/frameworks/custom?company_id=${companyId}`,
    { headers: authHeader() }
  );
};

const getCustomLevelTwo = (companyId) => {
  return axios.get(
    API_URL + `assessments/frameworks/custom?company_id=${companyId}`,
    { headers: authHeader() }
  );
};

const getMappedLevelThree = (companyId) => {
  return axios.get(
    API_URL + `assessments/custom-framework-definition?company_id=${companyId}`,
    { headers: authHeader() }
  );
};

/**
 * Create new user Employee, HR, Coach,Mentor, Mentee
 * @param {*} email
 * @param {*} password
 * @param {*} organization
 * @param {*} kind
 * @param {*} subscription
 * @param {*} team_id
 * @param {*} team_leader
 * @returns
 */
const createUser = ({
  email,
  password,
  organization,
  kind,
  subscription,
  team_id,
  team_leader,
  platform_type,
  position
}) => {
  return axios.post(
    API_URL + "v1/auth/create",
    {
      email,
      password,
      organization,
      kind,
      subscription,
      team_id,
      team_leader,
      platform_type,
      position
    },
    { headers: authHeader() }
  );
};

export const createNewUser = (user) => {
  return axios.post(API_URL + "v1/auth/create", user, {
    headers: authHeader()
  });
};

const updateUser = (user) => {
  return axios.post(API_URL + "v1/profile/update", user, {
    headers: authHeader()
  });
};

export const updateNewUser = (user) => {
  return axios.post(API_URL + "v1/profile/update", user, {
    headers: authHeader()
  });
};

/**
 * Toggle Paid to Unpaid and vice-versa
 * @param {*} reference_number
 * @param {*} status [Paid, Unpaid]
 * @returns
 */
const updatePaymentStatus = (reference_number, status) => {
  return axios.post(
    API_URL + "sessions/coach/earnings/status",
    { reference_number, status },
    { headers: authHeader() }
  );
};

/**
 * Create Campaign
 * @param {*} recipients
 * @param {*} subject
 * @param {*} body
 * @returns
 */
const createCampaign = ({ recipients, subject, body }) => {
  return axios.post(
    API_URL + "v1/auth/campaigns",
    { recipients, subject, body },
    { headers: authHeader() }
  );
};

const createSurveyCampaign = ({ recipients, subject, body }) => {
  return axios.post(
    API_URL + "v1/auth/sendSurvey",
    { recipients, subject, body },
    { headers: authHeader() }
  );
};

const getCampaigns = () => {
  return axios.get(API_URL + "v1/auth/campaigns", { headers: authHeader() });
};

const addCoachMatchToEmployee = (coach, employee) => {
  return axios.post(
    API_URL + "v1/auth/match-coaches",
    { coach, employee },
    { headers: authHeader() }
  );
};

const deleteCoachMatch = (coach, employee) => {
  return axios.post(
    API_URL + "v1/auth/match-coaches/_delete",
    { coach, employee },
    { headers: authHeader() }
  );
};

const getCoachMatches = () => {
  return axios.get(API_URL + "v1/auth/match-coaches", {
    headers: authHeader()
  });
};

const getUserCoachMatches = (userId) => {
  return axios.get(API_URL + "v1/auth/match-coaches?user=" + userId, {
    headers: authHeader()
  });
};

/**
 * POSTDelete user (Employee, HR, Coach,Mentor, Mentee) Copy
 * @param {*} userId
 * @returns
 */
const deleteUser = (userId) => {
  return axios.post(
    API_URL + "v1/profile/delete",
    { user: userId },
    { headers: authHeader() }
  );
};

export const getPendingUpdateRequests = (userId) => {
  return axios.get(API_URL + "v1/profile/update/pending", {
    headers: authHeader()
  });
};

/**
 * Create Coach Payment Configuration
 * @param { }   
 * @returns 
// {
//   "coach":"pass coach id",
//   "session_type":"1:1 session",
//   "amount": 0,
//   "subscription_plan":"Hi-Potential"
// }
*/
export const createPaymentConfiguration = (
  coach,
  session_type,
  amount,
  subscription_plan
) => {
  return axios.post(
    API_URL + "sessions/coach/payment-configurations",
    { coach, session_type, amount, subscription_plan },
    { headers: authHeader() }
  );
};

export const getPaymentConfiguration = () => {
  return axios.get(API_URL + "sessions/coach/payment-configurations", {
    headers: authHeader()
  });
};

const assignOrUpdateCoach = (userId, assigned_coach) => {
  return axios.post(
    API_URL + "v1/auth/users/_update",
    { user: userId, assigned_coach: assigned_coach },
    { headers: authHeader() }
  );
};

const removeAssignedCoach = (userId, assigned_coach) => {
  return axios.post(
    API_URL + "v1/auth/users/remove-assigned-coach",
    { user: userId, assigned_coach: assigned_coach },
    { headers: authHeader() }
  );
};

export const AdminServices = {
  getCompanyPreferredCoaches,
  deleteCompanyPreferredCoach,
  setCompanyPreferredCoaches,
  removeAssignedCoach,
  getMappedLevelThree,
  getCustomLevelTwo,
  getHR,
  getUsersByType,
  moveAssessmentTagToCollection,
  createAssessmentCollection,
  getDefaultAssessmentTags,
  getCustomAssessmentCollections,
  getAssessmentCollections,
  createPaymentConfiguration,
  getEmployees,
  updatePaymentStatus,
  createCompany,
  updateCompany,
  getCompanies,
  createTeam,
  getTeams,
  createUser,
  getCoaches,
  getCoachesRating,
  getEmployeesByCompanyId,
  createCampaign,
  addCoachMatchToEmployee,
  deleteUser,
  updateUser,
  getCampaigns,
  getCoachMatches,
  deleteCoachMatch,
  getCompanyById,
  getUserCoachMatches,
  getConnectedCalendars,
  getPendingUpdateRequests,
  getMenteesByCompanyId,
  createSurveyCampaign,
  assignOrUpdateCoach
};
