import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CollapsibleWrapperWithLineBar } from "../../../../../components/Collapsible/CollapsibleWrapperWithLineBar";
import { ConfirmationModal } from "../../../../../components/modal/ConfirmationModal";
import { BackButton } from "../../../../../components/nav/BackButton";
import { Assessment } from "../../../../../components/Profile/ProfileTabPages/Assessment";
import { AvatarPlaceholderV2 } from "../../../../../components/SVG/AvatarPlaceholderV2";
import { MentoringSessionTimeline } from "../../../../../components/Timeline/MentoringSessionTimeline";
import { Failure } from "../../../../../components/ToastNotification/Failure";
import { Information } from "../../../../../components/ToastNotification/Information";
import { UserStore } from "../../../../../RoleManagement/Discriminator";
import {
  getInvitations,
  getRequestById
} from "../../../../../services/dashboard/hrDashboard.services";
import mentoringService, {
  getSessionsByQuery,
  updateMentoringRequests
} from "../../../../../services/mentoring/mentoring.service";
import Feedback360 from "../../../../dashboard/userDashboard/userReports/feedback360/Feedback360";
import { AddTaskToMentee } from "../../AddTaskToMentee";
import { MenteeTaskList } from "../../MenteeTaskList";

/**
 * A list of mentees for this particular program
 */
export const Mentoring = (props) => {
  const { currentRole } = UserStore();
  const { programId, requestId, userId } = props.match.params;
  const history = useHistory();
  const [program, setProgram] = useState();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")).data
  );
  const [mentee, setMentee] = useState();
  const [request, setRequest] = useState();
  const [registeredTopics, setRegisteredTopics] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [tab, setTab] = useState(1);
  const [sessions, setSessions] = useState([]);
  const [invitation, setInvitation] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menteeTasks, setMenteeTasks] = useState([]);

  useEffect(() => {
    if (!programId && requestId) {
      history.push("/");
    }
    fetchRequest();
    getUserInvitation();

    if (!["Hr"].includes(user.account_type)) {
      getMenteeTasks(userId);
    }
  }, [setProgram, setRequest, setSessions]);

  function fetchRequest() {
    getRequestById(requestId)
      .then((res) => {
        if (res.data && res.data.status === true) {
          setRequest(res.data.data);
          setMentee(res.data.data.menteeProfile);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getUserInvitation() {
    getInvitations(`?program=${programId}&user=${userId}`)
      .then((res) => {
        if (res.data && res.data.status === true) {
          const invitation = res.data.data[0];
          setInvitation(invitation);
          setRegisteredTopics(invitation.selectedSkills);
          onGetSessions(programId, invitation.user);
          setProgram(invitation.program);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function onEndRelation() {
    setOpenConfirm(true);
  }

  function willEndRelation() {
    updateMentoringRequests("cancelled", request._id)
      .then((res) => {
        Information(
          "You have ended the pairing status with " +
            mentee.first_name +
            " " +
            mentee.last_name
        );
        setOpenConfirm(false);
        history.goBack();
      })
      .catch((e) => {
        console.log(e);
        setOpenConfirm(false);
        Failure(
          "Error occured ending the pairing status, Please contact support"
        );
      });
  }

  const onGetSessions = (programId, userId) => {
    if (userId) {
      getSessionsByQuery(`program=${programId}&user=${userId}`)
        .then((response) => {
          if (response.status) {
            let data = response.data.data;
            const sorted = data?.sort(
              (a, b) =>
                new Date(b.start_time).getTime() -
                new Date(a.start_time).getTime()
            );
            setSessions(sorted);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  function getMenteeTasks() {
    mentoringService
      .getTasks(`/filter?user=${userId}&program=${programId}`)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          const sorted = data.sort(
            (a, b) =>
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
          );
          const filteredUndeleted = sorted.filter((s) => !s.deleted);
          setMenteeTasks(filteredUndeleted);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <ConfirmationModal
        title={"End pairing?"}
        header={`Are you sure you want to end the pairing status with ${mentee?.last_name}`}
        showDefaultButton={true}
        buttonFunction={() => willEndRelation()}
        open={openConfirm}
        setOpen={setOpenConfirm}
        defaultButtonText="End pairing"
      />

      <BackButton />

      <div className="mt-4 max-w-screen-lg mx-auto bg-white p-4 rounded-lg shadow">
        {/* Mentee */}
        <div className="flex gap-4 flex-col sm:flex-row items-center justify-between">
          <div className="flex flex-row gap-4 items-center relative w-full">
            <div className="block relative">
              <AvatarPlaceholderV2
                imageUrl={mentee?.user?.profile_image}
                height="h-24"
                width="w-24"
                nameFallback={`${mentee?.first_name} ${mentee?.last_name}`?.trim()}
                classes="border-2 rounded-full overflow-hidden"
              />
            </div>
            {/* Name and mini details */}
            <div className="relative">
              <div className="text-lg sm:text-2xl tracking-wider font-bold">
                {mentee?.first_name} {mentee?.last_name}
              </div>
              <div className="text-sm text-skin-muted">{mentee?.email}</div>
              <div className="text-sm text-skin-muted">
                {mentee?.job_function} <span>&#183;</span> {mentee?.position}
              </div>

              {/* End relationship CTA */}
              <div className="block sm:hidden mt-4">
                <button
                  onClick={() => onEndRelation()}
                  className="text-left text-xs font-bold flex gap-4 flex-row items-center justify-center px-4 py-2 hover:bg-gray-100 border rounded-lg cursor-pointer text-skin-muted"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="var(--color-muted)"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                  <div>End pairing</div>
                </button>
              </div>
            </div>
          </div>

          {/* End relationship CTA */}
          <div className="hidden sm:block">
            <button
              onClick={() => onEndRelation()}
              className="whitespace-nowrap text-xs font-bold flex gap-4 flex-row items-center justify-center px-4 py-2 hover:bg-gray-100 border rounded-lg cursor-pointer text-skin-muted"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="var(--color-muted)"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                />
              </svg>
              <div>End pairing</div>
            </button>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 gap-4 mt-4 border-t pt-4">
          {mentee && mentee?.job_function && (
            <div className="">
              <div className="font-medium text-sm text-skin-muted">
                Department
              </div>
              <div className="font-bold text-base">{mentee?.job_function}</div>
            </div>
          )}
          {mentee && mentee?.position && (
            <div className="">
              <div className="font-medium text-sm text-skin-muted">
                Position
              </div>
              <div className="font-bold text-base">{mentee?.position}</div>
            </div>
          )}
          {mentee && mentee?.management_level && (
            <div className="">
              <div className="font-medium text-sm text-skin-muted">
                Management Level
              </div>
              <div className="font-bold text-base">
                {mentee?.management_level}
              </div>
            </div>
          )}

          {mentee && mentee?.entity && (
            <div className="">
              <div className="font-medium text-sm text-skin-muted">Entity</div>
              <div className="font-bold text-base">{mentee?.entity}</div>
            </div>
          )}

          {mentee && mentee?.whatsapp_number && (
            <div className="">
              <div className="font-medium text-sm text-skin-muted">
                Phone No.
              </div>
              <div className="font-bold text-base">
                {mentee?.whatsapp_number}
              </div>
            </div>
          )}

          {mentee && mentee?.email && (
            <div className="">
              <div className="font-medium text-sm text-skin-muted">Email</div>
              <div className="font-bold text-base">{mentee?.email}</div>
            </div>
          )}
        </div>

        {/* Registered Topics */}
        <div className="grid gap-4 mt-4">
          <CollapsibleWrapperWithLineBar
            ItemsCounts={registeredTopics?.length}
            SectionName={`${
              program?.program_type === "Mentoring" ? "Mentoring" : "Coaching"
            } Topics`}
            Icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="var(--color-green)"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                />
              </svg>
            }
          >
            <div className="grid gap-4 pl-4 evenOddBorder pb-4">
              {registeredTopics?.map((s, i) => (
                <div
                  key={i}
                  className="text-sm text-skin-muted w-full"
                  style={{ borderColor: "#eeeeee4f" }}
                >
                  {i + 1}. {s}{" "}
                </div>
              ))}
            </div>
          </CollapsibleWrapperWithLineBar>
        </div>

        {/* Tabs */}
        <div className="flex flex-row w-full text-sm justify-between sm:justify-start border-b border-gray-200 overflow-x-auto">
          <div
            onClick={() => setTab(1)}
            className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
              tab == 1 && "border-b-2 border-skin-base text-skin-accent"
            } `}
          >
            Sessions
          </div>
          {mentee && mentee?.assessment_report_visible_to.includes(user._id) && (
            <div
              onClick={() => setTab(2)}
              className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                tab == 2 && "border-b-2 border-skin-base text-skin-accent"
              } `}
            >
              Assessment
            </div>
          )}
          {mentee && mentee?.assessment_report_visible_to.includes(user._id) && (
            <div
              onClick={() => setTab(3)}
              className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                tab == 3 && "border-b-2 border-skin-base text-skin-accent"
              } `}
            >
              360 Feedback
            </div>
          )}
          {mentee && currentRole == "Expert" && (
            <div
              onClick={() => setTab(4)}
              className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                tab == 4 && "border-b-2 border-skin-base text-skin-accent"
              } `}
            >
              Tasks
            </div>
          )}
        </div>

        {/* Tab Content */}
        {/* Completed Sessions */}
        {tab === 1 && (
          <div
            className="mt-4 grid gap-4 overflow-y-auto scrollhost2"
            style={{ maxHeight: 700 }}
          >
            <div className="">
              <MentoringSessionTimeline sessionList={sessions} />
            </div>
          </div>
        )}

        <div className="mt-4">
          {tab === 2 && (
            <Assessment userId={mentee.user._id || mentee.user} user={mentee} />
          )}
        </div>

        <div className="mt-4">
          {tab === 3 && (
            <Feedback360
              userId={mentee.user}
              user={mentee}
              canRequest={false}
            />
          )}
        </div>

        <div className="mt-4">
          {tab === 4 && (
            <MenteeTaskList
              mentee_id={mentee.user}
              taskList={menteeTasks}
              setOpenDrawer={setOpenDrawer}
            />
          )}
        </div>
      </div>

      {openDrawer && (
        <AddTaskToMentee
          mentee={mentee}
          setOpenCloseDrawer={setOpenDrawer}
          refereshCallback={getMenteeTasks}
        />
      )}
    </>
  );
};
