import { Box, Button, Input, Stack, Textarea, Typography } from "knack-ui";
import { useForm, SubmitHandler } from "react-hook-form";
import SVG from "../../components/SVG/SVG";
import { IClientTestimonial } from "../../features/User/UserTypes";

interface IProps {
  handleClose: VoidFunction;
  onAddTestimonial: (t: IClientTestimonial) => void;
}

const NewTestimontialDialog = ({ handleClose, onAddTestimonial }: IProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IClientTestimonial>();

  const onSubmit: SubmitHandler<IClientTestimonial> = (data) => {
    onAddTestimonial(data);
    handleClose();
  };
  return (
    <Box
      dir="ltr"
      paddingPreset="card"
      style={{ maxBlockSize: "min(80vh, 100%)" }}
    >
      <Button
        className="z-10 end-6 top-6"
        style={{ position: "absolute" }}
        onClick={handleClose}
        kind="ghost"
        variant="small"
        iconOnly
      >
        <SVG name="Close" />
      </Button>
      <Typography variant="h5" as="label" htmlFor="name">
        Add new testimonial
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid max-w-2xl gap-8 mx-auto mt-6"
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Typography
              className="block mb-2"
              variant="subtitle2"
              fontWeight="medium"
              as="label"
              htmlFor="name"
            >
              Name
            </Typography>

            <Input
              error={errors.name?.message}
              {...register("name", { required: "Required field" })}
              id="name"
            />
          </div>
          <div>
            <Typography
              className="block mb-2"
              variant="subtitle2"
              fontWeight="medium"
              as="label"
              htmlFor="position"
            >
              Position
            </Typography>
            <Input {...register("position")} id="position" />
          </div>
        </div>
        <div>
          <Typography
            className="block mb-2"
            variant="subtitle2"
            fontWeight="medium"
            as="label"
            htmlFor="comment"
          >
            Testimontial
          </Typography>
          <Textarea
            error={errors.comment?.message}
            {...register("comment", { required: "Required field" })}
            rows={8}
            id="comment"
          />
        </div>
        <Stack gap={3} justifyContent="flex-end">
          <Button kind="ghost" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Stack>
      </form>
    </Box>
  );
};

export default NewTestimontialDialog;
