import React from "react";

export const ScheduleIcon = () => {
  return (
    <svg
      id="calendar"
      xmlns="http://www.w3.org/2000/svg"
      width="20.344"
      height="21.187"
      viewBox="0 0 20.344 21.187"
    >
      <path
        id="Path_55"
        data-name="Path 55"
        d="M23.831,26.972H8.414a2.463,2.463,0,0,0-2.463,2.463v14.35a2.463,2.463,0,0,0,2.463,2.463H23.831a2.463,2.463,0,0,0,2.463-2.463V29.435A2.463,2.463,0,0,0,23.831,26.972Z"
        transform="translate(-5.951 -25.061)"
        fill="#ebf0f3"
      />
      <path
        id="Path_56"
        data-name="Path 56"
        d="M26.295,20.85V17.865A2.463,2.463,0,0,0,23.831,15.4H8.414a2.463,2.463,0,0,0-2.463,2.463V20.85Z"
        transform="translate(-5.951 -14.311)"
        fill="#ce412d"
      />
      <path
        id="Path_57"
        data-name="Path 57"
        d="M26.295,32.157V29.435a2.463,2.463,0,0,0-2.463-2.463H8.414a2.463,2.463,0,0,0-2.463,2.463v2.722Z"
        transform="translate(-5.951 -25.061)"
        fill="#e56353"
      />
      <path
        id="Path_58"
        data-name="Path 58"
        d="M51.627,45.645a.86.86,0,1,0,.86.86A.86.86,0,0,0,51.627,45.645Z"
        transform="translate(-47.592 -42.411)"
        fill="#d15241"
      />
      <path
        id="Path_59"
        data-name="Path 59"
        d="M56.87,0a.46.46,0,0,0-.46.46V4.043a.46.46,0,1,0,.92,0V.46A.46.46,0,0,0,56.87,0Z"
        transform="translate(-52.836)"
        fill="#4d5c7d"
      />
      <path
        id="Path_60"
        data-name="Path 60"
        d="M109.38,45.645a.86.86,0,1,0,.86.86A.86.86,0,0,0,109.38,45.645Z"
        transform="translate(-101.254 -42.411)"
        fill="#d15241"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M114.623,0a.46.46,0,0,0-.46.46V4.043a.46.46,0,1,0,.92,0V.46a.46.46,0,0,0-.46-.46Z"
        transform="translate(-106.497)"
        fill="#4d5c7d"
      />
      <path
        id="Path_62"
        data-name="Path 62"
        d="M167.133,45.645a.86.86,0,1,0,.86.86A.86.86,0,0,0,167.133,45.645Z"
        transform="translate(-154.915 -42.411)"
        fill="#d15241"
      />
      <path
        id="Path_63"
        data-name="Path 63"
        d="M172.376,0a.46.46,0,0,0-.46.46V4.043a.46.46,0,1,0,.92,0V.46a.46.46,0,0,0-.46-.46Z"
        transform="translate(-160.159)"
        fill="#4d5c7d"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M224.886,45.645a.86.86,0,1,0,.86.86A.86.86,0,0,0,224.886,45.645Z"
        transform="translate(-208.577 -42.411)"
        fill="#d15241"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M230.128,0a.46.46,0,0,0-.46.46V4.043a.46.46,0,1,0,.92,0V.46a.46.46,0,0,0-.46-.46Z"
        transform="translate(-213.819)"
        fill="#4d5c7d"
      />
      <g id="Group_33" data-name="Group 33" transform="translate(4.034 10.499)">
        <path
          id="Path_66"
          data-name="Path 66"
          d="M111.907,148.2h1.859v1.859h-1.859Z"
          transform="translate(-108.435 -148.197)"
          fill="#e56353"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M160.911,148.2h1.859v1.859h-1.859Z"
          transform="translate(-153.968 -148.197)"
          fill="#e56353"
        />
        <path
          id="Path_68"
          data-name="Path 68"
          d="M209.916,148.2h1.859v1.859h-1.859Z"
          transform="translate(-199.501 -148.197)"
          fill="#e56353"
        />
        <path
          id="Path_69"
          data-name="Path 69"
          d="M62.9,189.34h1.859V191.2H62.9Z"
          transform="translate(-62.902 -186.425)"
          fill="#e56353"
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M111.907,189.34h1.859V191.2h-1.859Z"
          transform="translate(-108.435 -186.425)"
          fill="#e56353"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M160.911,189.34h1.859V191.2h-1.859Z"
          transform="translate(-153.968 -186.425)"
          fill="#e56353"
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M209.916,189.34h1.859V191.2h-1.859Z"
          transform="translate(-199.501 -186.425)"
          fill="#e56353"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M62.9,230.483h1.859v1.859H62.9Z"
          transform="translate(-62.902 -224.654)"
          fill="#e56353"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M111.907,230.483h1.859v1.859h-1.859Z"
          transform="translate(-108.435 -224.654)"
          fill="#e56353"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M160.911,230.483h1.859v1.859h-1.859Z"
          transform="translate(-153.968 -224.654)"
          fill="#e56353"
        />
      </g>
    </svg>
  );
};
