import { Box, Button, LinearProgress, Stack, Typography } from "knack-ui";
import SVG from "../../../components/SVG/SVG";
import { motion, Variants } from "framer-motion";
import { OnboardingStepOptions } from "../../../pages/ExpertDashboard/SideExpertDashboard";
interface IOnboardingCardProps extends OnboardingStepOptions {
  progress: number;

  success?: boolean;
  remainingSteps: number;
}
const CardTransitions: Variants = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  exit: {
    x: -100,
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3
    }
  }
};

const OnboardingCard = ({
  ctaCb,
  ctaText,
  icon,
  progress,
  title,
  handleSkip,

  remainingSteps,
  success
}: IOnboardingCardProps) => {
  return (
    <Box
      as={motion.div}
      variants={CardTransitions}
      exit="exit"
      initial="hidden"
      animate="visible"
      variant="outlined"
      paddingPreset="card"
    >
      <Stack
        gap={5}
        className="mb-3"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="flex-1">
          <Typography
            className="mb-3"
            variant="body1"
            fontWeight="bold"
            as="h6"
          >
            {title}
          </Typography>
          <LinearProgress percentage={progress} color="dynamic" size={8} />
        </div>
        {!success && remainingSteps !== 1 && (
          <Button
            kind="tertiary"
            onClick={handleSkip}
            className="self-start"
            variant="xsmall"
          >
            Skip
          </Button>
        )}
        {success && (
          <Button
            kind="ghost"
            onClick={handleSkip}
            className="self-start"
            variant="small"
            iconOnly
          >
            <SVG name="Close" />
          </Button>
        )}
      </Stack>

      <Stack
        className="mt-1 mb-3"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        {!success && (
          <div>
            <Typography variant="caption">
              Please complete your profile
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              to have the best experience.
            </Typography>
          </div>
        )}
        {success && (
          <Typography color="success" variant="subtitle2" fontWeight="bold">
            Profile is complete
          </Typography>
        )}
        <SVG name={icon} size={90} />
      </Stack>
      {!success && (
        <Button kind="secondaryOutline" onClick={ctaCb} fullWidth>
          {ctaText}
        </Button>
      )}
      {success && (
        <Typography textAlign="center" variant="subtitle2" color="success">
          Congratulations! You have completed your profile
        </Typography>
      )}
    </Box>
  );
};

export default OnboardingCard;
