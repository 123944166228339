import {
  PageHeightWithoutHeader,
} from "../../../../lib/constants";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AdminCoaches from "./AdminCoaches";
import ExpertList from "./ExpertList";
import { Box, Stack, Typography } from "knack-ui";
import { onSearchPeople } from "../../hrDashboard/hrPeople/PeopleList";
import { SearchInput } from "../../../../components/Inputs/SearchInput";

export const Expert = () => {
  const { onChange } = onSearchPeople();

  return (
    <div>
      <div className="overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <div className="flex-1">
            <Typography as="h5" variant="h6">
              All Experts
            </Typography>
          </div>
          <Stack gap={3}>
            <SearchInput placeholder="Search email" onChange={onChange} />
          </Stack>
        </Box>
        <Tabs style={{ height: PageHeightWithoutHeader, overflowY: "auto" }}>
          <TabList className="px-4 border-b">
            <Tab
              className="inline-block px-4 py-3 text-sm cursor-pointer"
              selectedClassName="border-b-2 border-skin-base"
            >
              All
            </Tab>
            <Tab
              className="inline-block px-4 py-3 text-sm cursor-pointer"
              selectedClassName="border-b-2 border-skin-base"
            >
              Knack Coaches
            </Tab>

            <div className="flex items-center justify-center float-right"></div>
          </TabList>
          <TabPanel
            key="past"
            style={{
              height: "calc(100% - 48px)",
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <ExpertList />
          </TabPanel>

          <TabPanel
            key="upcoming"
            style={{
              height: "calc(100% - 48px)",
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <AdminCoaches />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
