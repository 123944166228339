import { useMutation } from "react-query";
import { ServerResponse } from "../../../hooks/useAxiosConfig";
import { apiHttp } from "../../../lib/appConfig";
import { IGenerateReportParams } from '../../AnalyticsReports/analyticsReportMutations';
import { PairingRecord, PairingReportsQuery } from '../../AnalyticsReports/analyticsReportTypes';


async function generatePairingCSVReport(request: IGenerateReportParams<PairingReportsQuery, PairingRecord>) {
  const { department, entities, management_level, program, position, status } = request.query;

  const params: Record<string, any> = {
    department: department?.join(","),
    entity: entities?.join(","),
    management_level: management_level?.join(","),
    program: program?.join(","),
    position: position?.join(","),
    status: status?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>(
    "v2/analytics/pairings/generate-report",
    { attributes: request.exportAttributes },
    { params }
  );
  return res.data.data;
}

export const useGenerateCSVPairingReport = () => {
  return useMutation(generatePairingCSVReport);
};
