import { Avatar, AvatarGroup, Box, Breadcrumbs, Button, Chip, CircularProgress, Input, Popover, Stack, Typography } from "knack-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EmptyState from "../../../components/EmptyState/EmptyState";
import FiltersBar from "../../../components/FiltersBar";
import Table from "../../../components/Table";
import { useGetGoals, useGetGoalsAnalyticsTopTopics } from "../analyticsReportQueries";
import { GoalsReportsQuery, IGoalsAnalytics, IGoalsAnalyticsMetrics } from "../analyticsReportsTypes";
import { AiOutlineSearch } from "react-icons/ai";
import SVG from "../../../components/SVG/SVG";
import MetricCard from "../../../components/Metrics/MetricCard";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import { PaginationState } from "@tanstack/react-table";
import { DURATIONS } from "../../../lib/constants";
import { useGoalsFilters } from "../hooks/GoalsReports.hooks";
import { calculateGoalAnalyticsPercentage, resizeObserver } from "../../../lib/utils";
import Chart from "../../../components/Charts/Chart";
import { TopGoalTopics } from "../components/TopGoalTopics";
import { useGenerateGoalsCSVReport } from "../analyticsReportMutations";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { Information } from "../../../components/ToastNotification/Information";
import AttributeSelector from "../../../components/Selector/AttributeSelector";
import ShowMoreList from "../../../components/Collapsible/ShowMoreList";
import { useExportChart } from "../chartExportMutations";
import { TChartType } from "../../../lib/interfaces/generalTypes";

const GoalsReportsPage = () => {
  const { mutateAsync: onExportChart } = useExportChart();

  const exportChart = async (chartType: TChartType) => {
    await onExportChart(chartType);
    Information("An email with chart export will be sent to your email");
  };

  const [searchValue, setSearchValue] = useState<string>("");
  const [query, setQuery] = useState<GoalsReportsQuery>({
    organization: [],
    user: [],
    status: [],
    department: [],
    position: [],
    userType: [],
    topics: []
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const { data, hasNextPage, fetchNextPage, fetchPreviousPage, isFetchingNextPage } = useGetGoals(
    {
      ...query,
      search_by_email_or_name: searchValue
    },
    { suspense: true, keepPreviousData: true, staleTime: DURATIONS.twoMins }
  );

  const metrics = useMemo(() => {
    const _metrics: IGoalsAnalyticsMetrics = {
      personsWithGoals: 0,
      personsWithoutGoals: 0,
      goalsCompleted: 0,
      goalsInProgress: 0,
      overdueGoals: 0
    };

    if (data) {
      const metrics_response = data.pages[0].metrics;
      _metrics.personsWithGoals = metrics_response.personsWithGoals;
      _metrics.personsWithoutGoals = metrics_response.personsWithoutGoals;
      _metrics.goalsCompleted = metrics_response.goalsCompleted;
      _metrics.goalsInProgress = metrics_response.goalsInProgress;
      _metrics.overdueGoals = metrics_response.overdueGoals;
    }

    return _metrics;
  }, [data]);

  const filters = useGoalsFilters({
    query,
    onQueryChange: setQuery
  });

  /** Reset page index to 0 whenever we change the query to support fresh queries */
  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
  }, [filters, pageSize]);

  useEffect(() => {
    if (data && !data.pages[pageIndex] && !isFetchingNextPage) {
      setPagination({ pageIndex: 0, pageSize });
    }
  }, [isFetchingNextPage, pageIndex, pageSize, data]);

  const goalsStatusesChartOptions = useMemo(() => {
    if (!data) return null;

    const metrics_response = data.pages[0].metrics;

    return {
      tooltip: {
        trigger: "item"
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        left: "left"
      },

      series: [
        {
          type: "pie",
          itemStyle: {
            borderRadius: 8,
            borderColor: "#fff",
            borderWidth: 2
          },
          // radius: "50%",
          radius: ["40%", "60%"],
          data: [
            {
              value: metrics_response.goalsCompleted,
              name: "Completed"
            },
            {
              value: metrics_response.goalsInProgress,
              name: "In Progress"
            },
            {
              value: metrics_response.overdueGoals,
              name: "Overdue"
            }
          ]
        }
      ],
      color: ["#5470c6", "#91cc75", "#fac858"]
    };
  }, [data]);

  const { data: topTopicsData } = useGetGoalsAnalyticsTopTopics(query, {
    keepPreviousData: true
  });

  const topTopics = useMemo(() => {
    if (topTopicsData) {
      const topics = Object.entries(topTopicsData?.topicAggregate).map(([key, value]) => ({
        value,
        name: key
      }));

      return topics;
    }
    return [];
  }, [topTopicsData]);

  const [exportAttributes, setExportAttribute] = useState<Array<keyof IGoalsAnalytics>>();
  const { mutateAsync: generateReport } = useGenerateGoalsCSVReport();
  const { setLoading } = useLoadingSpinner();
  const popoverRefEl = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  async function onExportCSV(exportAttributes: Array<keyof IGoalsAnalytics>) {
    setLoading(true);
    try {
      await generateReport({ exportAttributes, query });
      setMenuOpen(false);
      setLoading(false);
      Information("A CSV report has been sent to your email");
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Goals report</Typography>]}</Breadcrumbs>
      </Box>

      <Box variant="outlined" className="grid grid-cols-1 gap-6 px-6 py-4">
        <div className="pb-6 border-b">
          <Typography fontWeight="semibold" className="mb-4">
            Filters
          </Typography>
          <FiltersBar query={query} filters={filters} />
        </div>
        <div>
          <Typography className="mb-4" fontWeight="semibold">
            Summary
          </Typography>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <MetricCard
              icon="GoalsCompleted"
              title="Users with Goals"
              value={`${metrics.personsWithGoals}/${metrics.personsWithGoals + metrics.personsWithoutGoals}`}
            />
            <MetricCard icon="Completed" title="Completed Goals" value={metrics.goalsCompleted} />
            <MetricCard icon="GoalsInProgress" title="In Progress" value={metrics.goalsInProgress} />
            <MetricCard icon="GoalsOverdue" title="Overdue Goals" value={metrics.overdueGoals} />
          </div>
        </div>

        <Stack direction="column" gap={4}>
          <Typography fontWeight="semibold">Analysis</Typography>
          <div className="relative grid grid-cols-1 gap-6 md:grid-cols-2">
            <TitledDisplayBox title="Top Topics" subtitle="Most tagged topics to goals" onExport={() => exportChart("TOP_TOPICS_IN_GOALS")}>
              <TopGoalTopics topics={topTopics} />
            </TitledDisplayBox>
            <TitledDisplayBox
              title="Goal statuses"
              subtitle={`Total goals: ${data?.pages[0].totalRecords}`}
              onExport={() => exportChart("GOALS_STATUSES")}
            >
              {!goalsStatusesChartOptions && <EmptyState iconSize={70} title="No data found" icon="Goals" />}
              {data && data?.pages[0]?.goals.length > 0 && (
                <Chart options={goalsStatusesChartOptions as any} resizeObserver={resizeObserver} />
              )}
            </TitledDisplayBox>
          </div>

          <Stack justifyContent="space-between" alignItems="center">
            <Typography fontWeight="semibold">Users</Typography>
            <Stack gap={4} direction="row" alignItems="center">
              <Input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="w-72"
                inputPrefix={<AiOutlineSearch size={20} />}
                inputSuffix={
                  searchValue !== "" ? (
                    <Button onClick={() => setSearchValue("")} iconOnly kind="ghost" variant="small">
                      <SVG name="Close" />
                    </Button>
                  ) : (
                    <></>
                  )
                }
                placeholder="Search by name or email"
              />
              <Button ref={popoverRefEl} onClick={() => setMenuOpen(true)}>
                Export
              </Button>
            </Stack>
          </Stack>

          {data?.pages[pageIndex]?.goals?.length === 0 && <EmptyState iconSize={70} title="No goals found" icon="SessionsEmptyState" />}
          {data && data?.pages[pageIndex]?.goals?.length > 0 && (
            <Table
              pagination={{ pageIndex, pageSize }}
              setPagination={setPagination}
              paginationProps={{
                totalPages: data.pages[pageIndex].totalPages,
                canGetNextPage: hasNextPage,
                onNextPage: async (newPage) => {
                  if (!data?.pages[newPage - 1]) {
                    fetchNextPage();
                  }
                },
                onPreviousPage: async (newPage) => {
                  if (!data?.pages[newPage - 1]) {
                    fetchPreviousPage();
                  }
                }
              }}
              data={data.pages.flatMap((p) => p.goals)}
              columns={(helper) => [
                helper.accessor((row) => `${row.user_first_name || row.user_email || ""} ${row.user_last_name || ""}`, {
                  header: "User",
                  sortingFn: "text",
                  cell: ({ row, getValue }) => {
                    const name = getValue();
                    return (
                      <div className="flex items-center gap-2">
                        <Avatar className="flex-shrink-0" text={name} image={row.original?.user_profile_image || ""} />
                        {name}
                      </div>
                    );
                  }
                }),
                helper.accessor("share_with", {
                  header: "Shared with",
                  cell: ({ row }) => {
                    const experts = row.original.share_with;
                    return (
                      <>
                        {experts.length > 1 ? (
                          <div className="flex justify-start">
                            <AvatarGroup max={3}>
                              {experts?.map((c) => (
                                <Avatar
                                  key={c._id}
                                  className="flex-shrink-0"
                                  text={c?.first_name || ""}
                                  image={c?.profile_image || ""}
                                  title={c?.first_name || ""}
                                />
                              ))}
                            </AvatarGroup>
                          </div>
                        ) : (
                          <>
                            {experts.length > 0 && (
                              <div className="flex items-center gap-2">
                                <Avatar
                                  className="flex-shrink-0"
                                  text={experts[0]?.first_name || ""}
                                  image={experts[0]?.profile_image || ""}
                                />
                                {experts[0]?.first_name} {experts[0]?.last_name}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    );
                  }
                }),
                helper.accessor("goal_topics", {
                  header: "Topics",
                  cell: ({ row }) => {
                    const topics = row.original.goal_topics;
                    const { slice, onChangeSlice, label } = ShowMoreList({ max: 2, totalList: topics.length });
                    return (
                      <Stack direction="row" style={{ maxWidth: 400 }} className="flex-wrap gap-2" alignItems="center">
                        {topics.slice(0, slice).map((m, i) => (
                          <Chip key={i} variant="tertiary" shape="rounded">
                            {m}
                          </Chip>
                        ))}
                        {topics.length > 2 && (
                          <Button kind="ghost" variant="xsmall" onClick={() => onChangeSlice()} shape="rounded">
                            {label}
                          </Button>
                        )}
                      </Stack>
                    );
                  }
                }),
                helper.accessor("goal_status", {
                  header: "Status",
                  cell: ({ row }) => {
                    return (
                      <CircularProgress
                        percentage={calculateGoalAnalyticsPercentage(row.original)}
                        showText
                        color="dynamic"
                        size={40}
                        strokeWidth={4}
                      />
                    );
                  }
                })
              ]}
            />
          )}
        </Stack>
      </Box>

      <Popover
        animationType="fade"
        parentRef={popoverRefEl}
        offset={{ bottom: 10, left: 0 }}
        popoverProps={{
          // style: { maxWidth: 500 },
          variant: "elevated",
          elevation: 6
        }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <AttributeSelector<IGoalsAnalytics>
          attributeList={[
            {
              accessorKey: "goal_creation_date",
              label: "Date created"
            },
            {
              accessorKey: "user_email",
              label: "Email"
            },
            {
              accessorKey: "user_first_name",
              label: "First name"
            },
            {
              accessorKey: "user_last_name",
              label: "Last Name"
            },
            {
              accessorKey: "user_department",
              label: "Department"
            },
            {
              accessorKey: "user_entity",
              label: "Entity"
            },
            {
              accessorKey: "user_management_level",
              label: "Management Level"
            },
            {
              accessorKey: "user_position",
              label: "Position"
            },
            {
              accessorKey: "user_roles",
              label: "Roles"
            },
            {
              accessorKey: "goal_topics",
              label: "Topics"
            },
            {
              accessorKey: "goal_total_tasks",
              label: "Total Tasks"
            },
            {
              accessorKey: "goal_completed_tasks",
              label: "Completed Tasks"
            },
            {
              accessorKey: "goal_total_challenges",
              label: "Total Challenges"
            },
            {
              accessorKey: "goal_completed_challenges",
              label: "Completed Challenges"
            }
          ]}
          onChange={(attributes) => {
            setExportAttribute(attributes);
          }}
          onExportCSV={onExportCSV}
        />
      </Popover>
    </div>
  );
};

export default GoalsReportsPage;
