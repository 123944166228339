
const UserEmptyStateIcon = ({size}:{size?:number}) => {
  return (
    <svg
      width={size || "130"}
      height={size || "130"}
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.0896 39.515C82.0896 31.8233 79.3813 24.7275 74.8855 19.1483C77.1605 18.39 79.5438 17.8483 82.0896 17.8483C94.0605 17.8483 103.756 27.5441 103.756 39.515C103.756 51.4858 94.0605 61.1816 82.0896 61.1816C81.7646 61.1816 81.4396 61.1816 81.1146 61.1816L76.9438 57.0108C80.1938 51.9191 82.0896 45.9066 82.0896 39.515ZM124.502 104.515H125.423V88.265C125.423 76.4566 106.031 69.4691 91.0813 67.3025C97.0396 71.365 101.644 76.565 103.16 83.2275L124.502 104.515ZM49.5896 28.6816C49.2646 28.6816 48.9938 28.6816 48.723 28.7358L40.0563 20.0691C42.9271 18.6608 46.1772 17.8483 49.5896 17.8483C61.5605 17.8483 71.2563 27.5441 71.2563 39.515C71.2563 42.9275 70.4438 46.1775 69.0355 49.0483L60.3688 40.3816C60.423 40.1108 60.423 39.84 60.423 39.515C60.423 33.5566 55.548 28.6816 49.5896 28.6816ZM50.5105 61.1816C50.1855 61.1816 49.9146 61.1816 49.5896 61.1816C37.6188 61.1816 27.923 51.4858 27.923 39.515C27.923 39.19 27.923 38.9191 27.923 38.5941L4.57715 15.1941L12.2146 7.55664L119.465 114.807L111.827 122.444L92.923 103.594V104.515H6.25632V88.265C6.25632 73.8566 35.1271 66.5983 49.5896 66.5983C51.5938 66.5983 53.923 66.7608 56.3605 67.0316L50.5105 61.1816ZM49.5896 77.4316C34.9646 77.4316 18.173 84.4191 17.0896 88.3191V93.6816H82.0896V92.7608L70.6605 81.3316C64.5396 79.1108 56.7938 77.4316 49.5896 77.4316Z"
        fill="#EAEBEE"
      />
    </svg>
  );
};

export default UserEmptyStateIcon;
