import { AnimatePresence } from "framer-motion";
import { Stack, Button, Box, Typography, BaseDialog } from "knack-ui";
import { useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import { useGetGoals } from "../../../../features/Goals/goalQueries";
import { PageTitleHeight } from "../../../../lib/constants";
import GoalCard from "../../../../V3/Pages/Goals/ui/GoalCard";
import { GoalsGrid } from "../../../../V3/Pages/Goals/ui/GoalsList";
import NewGoalModal from "../../../../V3/Pages/Goals/ui/NewGoalModal";
import svg from "../../../../components/Asset/goalsEmptyState.svg";
import { TGoal } from "../../../../features/Goals/goalsTypes";

const AdminGoals = () => {
  const [newGoalOpen, setNewGoalOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<TGoal | null>();

  const onGoalClick = (goal: TGoal) => {
    setSelectedGoal(goal);
    setNewGoalOpen(true);
  };

  const { data } = useGetGoals({ select: (all) => all.defaultGoals });
  return (
    <Stack className="h-full" gap={6}>
      <div className="flex-1 overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            height: PageTitleHeight
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <Typography as="h5" variant="h6">
            Goals
          </Typography>
          <Button onClick={() => setNewGoalOpen(true)}>Create Goal</Button>
        </Box>

        <div className="h-full p-4 overflow-y-auto">
          {data?.length === 0 && (
            <EmptyState
              img={svg}
              buttonCb={() => setNewGoalOpen(true)}
              buttonText="Create new goal"
              title="No goals created yet!"
              subtitle="Create a new goal now"
            />
          )}
          {data?.length !== 0 && data?.length === 0 && (
            <EmptyState
              img={svg}
              buttonCb={() => setNewGoalOpen(true)}
              buttonText="Create new goal"
              title="You have no goals in progress!"
              subtitle="Create a new goal now"
            />
          )}

          <GoalsGrid>
            {data && data.length > 0 && (
              <Box
                as={Stack}
                onClick={() => {
                  setNewGoalOpen(true);
                }}
                elevation={0}
                direction="column"
                gap={3}
                justifyContent="center"
                alignItems="center"
                paddingPreset="card"
                className="transition-colors duration-100 border-2 border-dotted cursor-pointer hover:bg-gray-100"
              >
                <IoMdAddCircle size={30} className="text-skin-accent" />

                <Typography
                  textAlign="center"
                  variant="body1"
                  fontWeight="bold"
                  as="h6"
                >
                  Create New Goal
                </Typography>
              </Box>
            )}
            <AnimatePresence>
              {data?.map((goal) => (
                <GoalCard
                  admin
                  goal={goal}
                  key={goal._id}
                  onGoalClick={onGoalClick}
                />
              ))}
            </AnimatePresence>
          </GoalsGrid>
        </div>
        <BaseDialog
          dialogClassName="max-w-3xl"
          allowPinchZoom
          isOpen={newGoalOpen}
          onClose={() => {
            setSelectedGoal(null);
            setNewGoalOpen(false);
          }}
        >
          <NewGoalModal
            admin
            selectedGoal={selectedGoal!}
            onClose={() => {
              setSelectedGoal(null);
              setNewGoalOpen(false);
            }}
          />
        </BaseDialog>
      </div>
    </Stack>
  );
};

export default AdminGoals;
