import { useEffect, useState } from "react";
import { SquareImage } from "../../../components/Image/SquareImage";
import { ParsedHTML } from "../../../components/Parser/ParsedHTML";
import { AvatarPlaceholderV2 } from "../../../components/SVG/AvatarPlaceholderV2";
import { Failure } from "../../../components/ToastNotification/Failure";
import { getInvitations } from "../../dashboard/hrDashboard.services";
import { getTaskBySessionId } from "../../dashboard/userDashboard.services";
import "react-datepicker/dist/react-datepicker.css";
import { Information } from "../../../components/ToastNotification/Information";
import { createNewSession } from "../../dashboard/coachDashboard.services";
import { KnackSpinner } from "../../../components/Asset/KnackSpinner";
import { WarningInformation } from "../../../components/Information/WarningInformation";
import { openManualSessionLogging } from "./SessionState";
import { HeadlessMenuDropdown } from "../../../components/Popper/HeadlessMenuDropdown";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { sessionQueryKeys } from "../../../features/Sessions/sessionsQueries";
import { useUser } from "../../../features/User/userQueries";
import { BaseDialog, Button } from "knack-ui";
import RatingQuestionModal from "../../../features/Sessions/ui/SessionRating/SessionRatingSliders";
import moment from "moment";
import { DateTimeSelector } from "./DateTimeSelector";
import { dateIsValid } from "../../../lib/constants";
import { useGetProgram } from "../../../features/Programs/programQueries";

export const ManualSessionLoggingUser = () => {
  const { data: user } = useUser();
  const queryClient = useQueryClient();
  const [section, setSection] = useState(0);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const { open, setOpen, callbackFn, isScheduler, setScheduler, connectionPrograms } = openManualSessionLogging();
  const [openModal, setOpenModal] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [filteredInvitations, setFilteredInvitations] = useState([]);
  const [selectedInvitation, setSelectedInvitation] = useState();
  const [expert, setExpert] = useState(null);
  const [matches, setMatches] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState();
  const [tapState, setTapState] = useState(false);
  const [startTime, setStartTime] = useState(moment().format("HH:mm"));
  const [endTime, setEndTime] = useState(moment().add(30, "minutes").format("HH:mm"));
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [duration, setDuration] = useState(30);

  const [selectedTopics, setSelectedTopics] = useState([]);

  const [task, setTask] = useState(null);
  const [ratingIsOpen, setRatingIsOpen] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  const [programId, setProgramId] = useState();
  const { data: program, isLoading } = useGetProgram({ programId }, { enabled: Boolean(programId), suspense: true });

  useEffect(() => {
    setSection(0);
    setOpenModal(true);
    onGetInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInvitations, open, setOpen, callbackFn, setSection, isScheduler, setScheduler, connectionPrograms]);

  useEffect(() => {
    setExpert(null);
    if (isLoading) {
      setSpinnerOpen(true);
    }
    if (program && program?.expert) {
      setSpinnerOpen(false);
      setExpert(program.expert);
    }
  }, [program, isLoading, section]);

  function onGetInvitations() {
    setSpinnerOpen(true);
    // if is from connections page
    if (connectionPrograms?.length > 0) {
      const mutatedProgram = connectionPrograms.map((p) => ({ program: p }));
      setInvitations(mutatedProgram);
      setFilteredInvitations(mutatedProgram);
    } else {
      getInvitations(`?user=${user._id}&confirmed=${true}`)
        .then((res) => {
          if (res?.data && res?.data.status === true) {
            let { data } = res.data;
            if (isScheduler) {
              data = data.filter((d) => ["mentee", "coachee", "employee"].includes(d.type));
            } else {
              data = data.filter((d) => ["mentee", "coachee", "employee"].includes(d.type) && d?.program?.coach_type !== "knack_coaching");
            }
            const sorted = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setInvitations(sorted);
            setFilteredInvitations(sorted);
          }
        })
        .catch((e) => console.log(e));
    }
    setSpinnerOpen(false);
  }

  function onSearchProgram(value = "") {
    if (value !== "") {
      const filtered = invitations.filter(
        (p) =>
          p?.program.name?.toLowerCase()?.includes(value.toLowerCase()) || p?.program.overview?.toLowerCase()?.includes(value.toLowerCase())
      );
      setFilteredInvitations(filtered);
    } else {
      setFilteredInvitations([...invitations]);
    }
  }

  function onCloseModal(closeModal = true) {
    setOpen(false);
    setScheduler(false);
    setSection(null);
    setMatches([]);
    setSelectedTopics([]);
    setSelectedInvitation(null);
    setOpenModal(false);
    setExpert(null);
    setError(null);
  }

  function onSelectProgram(invitation) {
    if (isScheduler) {
      history.push({ pathname: `/scheduler/${invitation.program._id}` });
      setOpen(false);
      setScheduler(false);
    }
    setExpert(null);
    if (invitation && invitation.program) {
      setProgramId(invitation.program._id);
    }
    setSelectedInvitation(invitation);
    setSection(1);
  }

  function onLogSessionAsUser() {
    if (expert && selectedTopics.length === 0) {
      setError("Please select Topics discussed in the session");
      return;
    }
    setSpinnerOpen(true);
    try {
      const session = {
        completion_status: "Completed",
        start_time: moment(date).set({
          hours: moment(startTime, "HH:mm").get("hours"),
          minutes: moment(startTime, "HH:mm").get("minutes")
        }),
        end_time: moment(date).set({
          hours: moment(endTime, "HH:mm").get("hours"),
          minutes: moment(endTime, "HH:mm").get("minutes")
        }),
        type: expert ? "1:1 session" : "Chemistry Call",
        program: selectedInvitation.program._id,
        isLoggedSession: true
      };
      if (selectedInvitation.program.program_type === "Mentoring") {
        session.mentor = expert ? expert.user._id : selectedMatch.mentor._id;
        session.mentee = user._id;
        session.mentoring_topics = selectedTopics;
      } else {
        session.coach = expert ? expert.user._id : selectedMatch.mentor._id;
        if (selectedInvitation.program?.coach_type === "knack_coaching") {
          session.employee = user._id;
        } else {
          session.coachee = user._id;
        }
        session.mentoring_topics = selectedTopics;
      }

      session.formattedDateTime = moment(date).set({
        hours: moment(startTime, "HH:mm").get("hours"),
        minutes: moment(startTime, "HH:mm").get("minutes")
      });

      createNewSession(session)
        .then((res) => {
          if (res.data && res.data.status === true) {
            queryClient.invalidateQueries(sessionQueryKeys["all"]);
            const { data } = res.data;
            callbackFn();
            setSpinnerOpen(false);
            if (expert) {
              setError(null);
              Information(
                `You have logged a ${session.type} session you had with ${program?.expert?.first_name} ${program?.expert?.last_name}`
              );
            } else {
              onCloseModal(false);
              Information(
                `You have logged a ${session.type} session you had with ${selectedMatch?.profile?.first_name} ${selectedMatch?.profile?.last_name}`
              );
            }
            if (session.type !== "Chemistry Call") {
              getRatingTaskForSession(data._id, session.coach, session.mentor);
            }
            setSelectedTopics([]);
          } else {
            setSpinnerOpen(false);
            Failure("Unable to Log previous session", "Please contact support");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      setSpinnerOpen(false);
      console.log(error);
    }
  }

  function getRatingTaskForSession(sessionId, coach, mentor) {
    getTaskBySessionId(sessionId)
      .then((res) => {
        if (res.data.status && res.data.data) {
          setTask(res.data.data);
          setRatingIsOpen(true);
          queryClient.invalidateQueries(sessionQueryKeys.ratingTasks);

          // showRatingModal(res.data.data, coach, mentor);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <div>
      <BaseDialog animationType="fade" dialogClassName="max-w-2xl" isOpen={openModal} onClose={() => onCloseModal()}>
        {error && <WarningInformation message={error} />}
        {spinnerOpen && <KnackSpinner loadingText="Please Wait" />}
        {!ratingIsOpen && (
          <div>
            {/* Select program */}
            {
              <div className={`${section === 0 ? "block" : "hidden"}`}>
                <div className="flex items-center justify-start font-bold border-b">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute w-5 h-5 left-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                  <input
                    placeholder="Search program ..."
                    className="p-4 pl-12 m-0 border-none rounded-lg outline-none"
                    onChange={(e) => onSearchProgram(e.target.value)}
                  />
                </div>
                <div className="p-4">Select Program</div>
                <div className="grid gap-4 px-4 pb-4 overflow-y-auto sm:grid-cols-2 scrollhost2" style={{ maxHeight: 500 }}>
                  {filteredInvitations?.map((p, i) => (
                    <button
                      onClick={() => onSelectProgram(p)}
                      key={i}
                      className={`flex  rounded-lg overflow-hidden shadow bg-white relative text-left`}
                    >
                      <div className="w-full h-full cursor-pointer hover:bg-skin-light">
                        {/* Image */}
                        <div>
                          <SquareImage image_url={p?.program?.image_url} index={i} />
                        </div>
                        {/* Progran type */}
                        <div className="flex flex-row px-2 my-4">
                          <p
                            className={`px-2 py-1 text-xs rounded-full font-medium text-white bg-skin-accent`}
                            style={{ width: "fit-content" }}
                          >
                            {" "}
                            {p?.program?.tag ? p?.program.tag : p?.program?.type === "1:1" ? "One-on-One Mentoring" : p?.program?.type}
                          </p>
                        </div>
                        {/* Program Name */}
                        <div className="px-2 mb-4">
                          <h1 className="mb-3 text-base font-bold text-gray-800 dark:text-white text-skin-base clampText">
                            {" "}
                            {p?.program?.name}
                          </h1>
                          <div className="mt-2 text-sm text-gray-600 dark:text-gray-300 font-reguler restrict-grid-text text-skin-base clampText">
                            <ParsedHTML htmlString={p?.program?.overview} />{" "}
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            }

            {/* Session Title */}
            <div className={`p-4 border-b ${section === 1 ? "block" : "hidden"}`}>
              <div className="text-lg font-bold">{expert ? "Log New Session" : matches.length > 0 ? "Log Chemistry Call" : ""}</div>
              <div className="text-sm font-medium text-skin-muted">{selectedInvitation?.program?.name}</div>
            </div>
            {/* Select Match or Show Expert */}
            <div className={`${section === 1 ? "block" : "hidden"}`}>
              {expert && <div className="p-4">Your {selectedInvitation?.program?.program_type === "Mentoring" ? "Mentor" : "Coach"}</div>}
              {/* Coach/Mentor */}
              {expert && (
                <div className="flex flex-col gap-4 p-4 border-b sm:flex-row sm:items-center bg-skin-light">
                  <div className="relative block">
                    <AvatarPlaceholderV2
                      imageUrl={program?.expert?.profile_image || null}
                      height="h-20"
                      width="w-20"
                      nameFallback={`${program?.expert?.first_name} ${program?.expert?.last_name}`?.trim()}
                      classes="border-2 rounded-full overflow-hidden"
                    />
                  </div>
                  <div className="relative">
                    <div className="text-lg font-bold capitalize sm:text-2xl">
                      {program?.expert?.first_name} {program?.expert?.last_name}{" "}
                      <span className="text-xs text-skin-muted">
                        {" "}
                        {selectedInvitation?.program?.program_type === "Mentoring" ? "Mentor" : "Coach"}
                      </span>
                    </div>
                    <div className="text-sm text-skin-muted">
                      {program?.expert?.job_function} <span>&#183;</span> {program?.expert?.position}
                    </div>
                  </div>
                </div>
              )}
              {/* If no expert show matches */}
              {!expert && (
                <div className="grid">
                  <div className="p-4">Select a {selectedInvitation?.program?.program_type === "Mentoring" ? "Mentor" : "Coach"}</div>
                  {/* Matches List */}
                  {!expert && matches.length > 0 && (
                    <div className="relative px-4">
                      <HeadlessMenuDropdown
                        trigger={
                          <div
                            className="flex flex-row items-center justify-between w-full text-left border rounded-lg cursor-pointer bg-skin-lighter"
                            onClick={() => setTapState(!tapState)}
                          >
                            <div className="flex flex-col gap-4 p-4 sm:flex-row sm:items-center">
                              <div className="relative block">
                                <AvatarPlaceholderV2
                                  imageUrl={selectedMatch?.mentor?.profile_image || program?.expert?.profile_image || null}
                                  height="h-20"
                                  width="w-20"
                                  nameFallback={`${selectedMatch?.profile?.first_name} ${selectedMatch?.profile?.last_name}`?.trim()}
                                  classes="border-2 rounded-full overflow-hidden"
                                />
                              </div>
                              <div className="relative">
                                <div className="text-lg font-bold capitalize sm:text-xl">
                                  {selectedMatch?.profile?.first_name} {selectedMatch?.profile?.last_name}{" "}
                                  <span className="text-xs text-skin-muted">
                                    {selectedInvitation?.program?.program_type === "Mentoring" ? "Mentor" : "Coach"}
                                  </span>
                                </div>
                                <div className="text-sm text-skin-muted">
                                  {selectedMatch?.profile?.job_function} <span>&#183;</span> {selectedMatch?.profile?.position}
                                </div>
                              </div>
                            </div>

                            {/* Droplet Arrow */}
                            <div className={`translate transform delay-300 duration-300 p-4 ${tapState ? "" : "-rotate-180"}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="var(--color-accent)">
                                <path
                                  id="Icon_ionic-md-arrow-dropdown"
                                  data-name="Icon ionic-md-arrow-dropdown"
                                  d="M9,13.5l9,9,9-9Z"
                                  transform="translate(-9 -13.5)"
                                />
                              </svg>
                            </div>
                          </div>
                        }
                        popComponent={
                          <div className="absolute right-0 z-50 w-full pt-4 -mt-3 overflow-y-auto scrollhost2" style={{ maxHeight: 400 }}>
                            <div className="overflow-hidden bg-white border rounded-lg shadow">
                              {matches.map((m, i) => (
                                <button
                                  key={i}
                                  className={`w-full text-left hover:bg-skin-light ${selectedMatch?._id === m?._id ? "hidden" : ""}`}
                                  onClick={() => {
                                    setSelectedMatch(m);
                                    setTapState(false);
                                  }}
                                >
                                  <div className="flex flex-col gap-4 p-4 border-b sm:flex-row sm:items-center">
                                    <div className="relative block">
                                      <AvatarPlaceholderV2
                                        imageUrl={m?.mentor?.profile_image || null}
                                        height="h-20"
                                        width="w-20"
                                        nameFallback={`${m?.profile?.first_name} ${m?.profile?.last_name}`?.trim()}
                                        classes="border-2 rounded-full overflow-hidden"
                                      />
                                    </div>
                                    <div className="relative">
                                      <div className="text-lg font-bold capitalize sm:text-xl">
                                        {m?.profile?.first_name} {m?.profile?.last_name}{" "}
                                        <span className="text-xs text-skin-muted">Mentor</span>
                                      </div>
                                      <div className="text-sm text-skin-muted">
                                        {m?.profile?.job_function} <span>&#183;</span> {m?.profile?.position}
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  )}
                  {!spinnerOpen && !expert && matches.length === 0 && (
                    <WarningInformation
                      message={`You do not have a ${
                        selectedInvitation?.program?.program_type === "Mentoring" ? "Mentor" : "Coach"
                      } or matches for this program`}
                    />
                  )}
                </div>
              )}
            </div>

            {/* Select DateTime */}
            {
              <div className={`p-4 ${section === 1 && (expert || matches.length > 0) ? "block" : "hidden"}`}>
                <DateTimeSelector
                  setDuration={setDuration}
                  duration={duration}
                  setDate={setDate}
                  setEndTime={setEndTime}
                  setStartTime={setStartTime}
                  date={date}
                  startTime={startTime}
                  endTime={endTime}
                  max={moment().format("YYYY-MM-DD")}
                  min={undefined}
                />
              </div>
            }
            {/* Select Topics */}
            {expert && (
              <div className={`${section === 2 ? "block" : "hidden"}`}>
                <div className="p-4 border-b">
                  <div className="text-xl font-bold sm:text-xl">Select {selectedInvitation?.program.program_type} Topics</div>
                  <div className="text-sm text-skin-muted"> Please select one or more topics discussed during this session </div>
                </div>
                <div className="">
                  {/* Topics */}
                  <div className="grid gap-4 p-4 overflow-y-auto sm:grid-cols-2 max-h-96 scrollhost2">
                    {selectedInvitation?.program?.skills?.map((s, i) => (
                      <label
                        key={i}
                        className={` border p-4 text-sm flex flex-row items-center justify-between rounded-lg ${
                          selectedTopics.includes(s) ? "bg-skin-light" : ""
                        }`}
                        onClick={() => {
                          const filtered = selectedTopics.length > 0 ? [...selectedTopics] : [];
                          if (!filtered?.includes(s)) {
                            filtered.push(s);
                            setSelectedTopics(filtered);
                          } else {
                            const _filtered = filtered.filter((t) => t !== s);
                            setSelectedTopics(_filtered);
                          }
                          setError(null);
                        }}
                      >
                        <span>{s}</span>
                        {selectedTopics && selectedTopics.length > 0 && selectedTopics.includes(s) && (
                          <div className="flex items-center justify-center w-6 h-6 text-white rounded-full bg-skin-accent">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="#fff">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                        )}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {open && (
              <div className="flex flex-row justify-between p-4">
                <Button
                  kind="ghost"
                  onClick={() => {
                    if (section === 0) {
                      setOpenModal(false);
                      setMatches([]);
                      setExpert(null);
                    } else {
                      setSection(section - 1);
                    }
                  }}
                >
                  {section === 0 ? "Cancel" : "Back"}
                </Button>
                {section === 1 && expert && (
                  <Button kind="primary" disabled={!dateIsValid(date) || !startTime || !endTime} onClick={() => setSection(2)}>
                    Select Topics
                  </Button>
                )}
                {section === 2 && expert && (
                  <Button kind="primary" onClick={() => onLogSessionAsUser()}>
                    Log previous session
                  </Button>
                )}
                {section === 1 && !expert && matches.length > 0 && (
                  <Button
                    kind="primary"
                    onClick={() => onLogSessionAsUser()}
                    className="px-4 py-2 text-base text-white rounded-lg bg-skin-accent"
                  >
                    Log previous session
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
        {/* Session Rating Modal */}
      </BaseDialog>
      <BaseDialog
        dialogClassName="max-w-3xl"
        isOpen={Boolean(task)}
        onClose={() => {
          setTask(null);
          onCloseModal();
        }}
      >
        <RatingQuestionModal
          onClose={() => {
            setTask(null);
            onCloseModal();
          }}
          task={task}
        />
      </BaseDialog>
    </div>
  );
};
