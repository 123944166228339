import { motion } from "framer-motion";
import { Box, Button, Input, Stack, Textarea, Typography } from "knack-ui";
import { Controller, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import { useGetLanguagesList } from "../../../../features/Company/companyQueries";
import { INewUserProfile } from "../../../../features/User/UserTypes";
import { ageGroupOptions, genderOptions } from "../../../../lib/constants";
import { ProfileCreationTransitions } from "./NewUserProfile";
import { OnboardingStepCounter, useOnboardingCounterStore } from "./OnboardingStepCounter";

interface IProfileBasicInfoProps {
  handleNextStep: VoidFunction;
  handlePreviousStep: VoidFunction;
}

type FormProps = Pick<
  INewUserProfile,
  "first_name" | "last_name" | "about" | "gender" | "age_group" | "linkedin_profile_url" | "spoken_languages"
>;

const ProfileBasicInfo = ({ handleNextStep, handlePreviousStep }: IProfileBasicInfoProps) => {
  const { data: languages, isLoading: languagesLoading } = useGetLanguagesList();
  const { getValues: getGlobalFormValues, setValue } = useFormContext<INewUserProfile>();
  const { hasPrevious } = useOnboardingCounterStore();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormProps>({
    defaultValues: {
      age_group: getGlobalFormValues("age_group"),
      first_name: getGlobalFormValues("first_name"),
      last_name: getGlobalFormValues("last_name"),
      linkedin_profile_url: getGlobalFormValues("linkedin_profile_url"),
      about: getGlobalFormValues("about"),
      spoken_languages: getGlobalFormValues("spoken_languages"),
      gender: getGlobalFormValues("gender")
    }
  });
  const onSubmit: SubmitHandler<FormProps> = (data) => {
    handleNextStep();
    // @ts-ignore
    Object.keys(data).forEach((k) => setValue(k, data[k]));
  };
  return (
    <Box paddingPreset="card" as={motion.div} variants={ProfileCreationTransitions} exit="exit" initial="hidden" animate="visible">
      <OnboardingStepCounter />
      <form onSubmit={handleSubmit(onSubmit)} id="basicInfo" className="my-8">
        <Typography variant="h5" textAlign="center">
          Let us know your basic information
        </Typography>
        <div className="grid grid-cols-1 gap-6 mt-6 lg:grid-cols-2">
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="firstName">
              First Name
            </Typography>

            <Input {...register("first_name", { required: "Required field" })} error={errors.first_name?.message} id="firstName" />
          </div>
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="lastName">
              Last Name
            </Typography>

            <Input {...register("last_name", { required: "Required field" })} error={errors.last_name?.message} id="lastName" />
          </div>

          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="gender">
              Gender
            </Typography>
            <Controller
              rules={{ required: "Required field" }}
              name="gender"
              control={control}
              render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                <>
                  <KnackSelect
                    ref={ref}
                    id="gender"
                    onChange={(o: any) => onChange(o.value)}
                    value={genderOptions.find((o) => o.value === value)}
                    options={genderOptions}
                  />
                  {error?.message && (
                    <Typography fontWeight="normal" className="mt-2" variant="caption" color="warning">
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </div>
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="age">
              Age Group
            </Typography>
            <Controller
              rules={{ required: "Required field" }}
              name="age_group"
              control={control}
              render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                <>
                  <KnackSelect
                    ref={ref}
                    isSearchable
                    id="age"
                    options={ageGroupOptions}
                    onChange={(o: any) => onChange(o.value)}
                    value={ageGroupOptions.find((o) => o.value === value)}
                  />
                  {error?.message && (
                    <Typography fontWeight="normal" className="mt-2" variant="caption" color="warning">
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </div>
          <div className="col-span-2">
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="lang">
              Spoken languages
            </Typography>
            <Controller
              rules={{ required: true, validate: (value) => value?.length > 0 }}
              name="spoken_languages"
              control={control}
              render={({ field: { onChange, ref, value } }) => (
                <KnackSelect
                  isSearchable
                  ref={ref}
                  placeholder={`Select your language preference`}
                  className="text-sm border-none rounded-none outline-none"
                  isClearable={false}
                  id="lang"
                  isMulti
                  value={value?.map((l) => ({
                    value: l,
                    label: l
                  }))}
                  onChange={(o) => {
                    //@ts-ignore
                    if (o.length === 0) return;
                    //@ts-ignore
                    onChange(o.map((l) => l.value));
                  }}
                  isLoading={languagesLoading}
                  options={languages?.map((i) => ({
                    label: i,
                    value: i
                  }))}
                />
              )}
            />
          </div>
          <div className="col-span-2">
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="linkedin">
              LinkedIn profile (optional)
            </Typography>

            <Input placeholder="Optional" {...register("linkedin_profile_url")} id="linkedin" />
          </div>
          <div className="col-span-2">
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="bio">
              Bio
            </Typography>

            <Textarea {...register("about", { required: "Required field" })} error={errors.about?.message} rows={6} id="bio" />
          </div>
        </div>
      </form>
      <Stack gap={4} justifyContent="flex-end">
        {hasPrevious() && (
          <Button onClick={handlePreviousStep} kind="ghost">
            Back
          </Button>
        )}
        <Button type="submit" form="basicInfo">
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default ProfileBasicInfo;
