import SVG from "../SVG/SVG";
import { Button, Popover, Stack, Typography } from "knack-ui";
import { useRef, useState } from "react";

export const InputSelect = ({ w, onChange, options = [], selected, name }) => {
  const buttonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div>
        <div className="mt-1 relative InputSelectDropdown">
          <Button
            ref={buttonRef}
            onClick={() => setMenuOpen(true)}
            kind="tertiary"
            endIcon={
              <div
                onClick={() => {
                  onChange("");
                }}
                className="cursor-pointer ml-4"
              >
                {selected !== "" && <SVG name="Canceloutline" fill="gray" />}
              </div>
            }
          >
            <Stack direction="row">
              <Typography>
                Filter by {name} {selected}
              </Typography>
            </Stack>

            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2">
              {selected === "" && (
                <svg
                  className="h-5 w-5 text-skin-muted"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  id="InputSelectDropdownIcon"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
            </span>
          </Button>
        </div>
      </div>

      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        parentRef={buttonRef}
      >
        {options?.map((o, i) => (
          <Button
            style={{ justifyContent: "left", textAlign: "left", borderBottom: "1px solid #eee" }}
            key={i}
            className="w-full"
            variant="small"
            kind="ghost"
            shape="square"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onChange(o);
              setMenuOpen(false);
            }}
          >
            {o}
          </Button>
        ))}
      </Popover>
    </>
  );
};
