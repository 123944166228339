import { Box, Button, Stack, Typography } from "knack-ui";
import { motion } from "framer-motion";
import { NewSessionPageTransitions } from "../ScheduleSessionModal";
interface ISessionProgramSelectionProps {
  handleNextStep: VoidFunction;
  handlePreviousStep: VoidFunction;
}

const SessionProgramSelection = ({
  handleNextStep,
  handlePreviousStep
}: ISessionProgramSelectionProps) => {
  return (
    <motion.div
      variants={NewSessionPageTransitions}
      exit="exit"
      initial="hidden"
      animate="visible"
      style={{ height: "100%" }}
    >
      <Typography className="mb-6" variant="body1" fontWeight="bold">
        Select which program you want to schedule a session for
      </Typography>
      <Stack gap={4} direction="column">
        <Box
          as={Stack}
          gap={4}
          alignItems="center"
          className="p-4"
          variant="outlined"
        >
          <img
            className="object-cover h-full rounded-lg"
            style={{ width: 75, height: 75 }}
            src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
            alt="A Duck"
          />
          <Stack
            className="flex-1"
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            gap={1}
          >
            <Typography clamp={1} variant="body1" fontWeight="semibold">
              Internal coaching program
            </Typography>
            <Typography variant="caption">Internal Coaching</Typography>
          </Stack>
        </Box>
        <Box
          as={Stack}
          gap={4}
          alignItems="center"
          className="p-4"
          variant="outlined"
        >
          <img
            className="object-cover h-full rounded-lg"
            style={{ width: 75, height: 75 }}
            src="https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
            alt="A Duck"
          />
          <Stack
            className="flex-1"
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            gap={1}
          >
            <Typography clamp={1} variant="body1" fontWeight="semibold">
              Internal coaching program
            </Typography>
            <Typography variant="caption">Internal Coaching</Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack gap={2} direction="column">
        <Button onClick={handleNextStep}>Next</Button>
        <Button onClick={handlePreviousStep} kind="tertiary">
          Cancel
        </Button>
      </Stack>
    </motion.div>
  );
};

export default SessionProgramSelection;
