import React from "react";
import RequestFeedback from "../feedback360/requestFeedback";
import { CustomDialog } from "react-st-modal";
import { useTranslation } from "react-i18next";

export default function StrengthOrWeakness({
  title,
  subTitle,
  stateData,
  isHr
}) {
  const { t } = useTranslation(["assessments"]);
  return (
    <div>
      <h2 className="text-xl text-center text-black ">{title}</h2>
      <p className="pb-4 text-xs text-center text-gray-500">{subTitle}</p>
      {stateData.length > 0 ? (
        stateData.map((trait, i) => {
          return (
            <div key={i} className="flex py-2 border-b border-gray-200">
              <span className="text-xs text-gray-500">
                {t(trait.key, { ns: "assessments" })}
              </span>
              <span className="ml-auto text-xs font-bold text-gray-900">
                {trait.avg_score_before.value}
              </span>
            </div>
          );
        })
      ) : (
        <div className="py-10 text-center">
          <div className="mt-8 text-xs font-semibold text-center text-skin-muted">
            {" "}
            <span className="opacity-50">⚡</span> Assessment report will appear
            here when it is available{" "}
          </div>
          {!isHr && (
            <>
              <button
                className="text-sm underline text-skin-accent"
                onClick={async () => {
                  const result = await CustomDialog(<RequestFeedback />, {
                    title: "Request 360 Feedback",
                    showCloseIcon: true,
                    defaultBodyOverflow: "hidden",
                    className: "request-feedback-modal rounded-lg"
                  });
                }}
              >
                Request feedback
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
