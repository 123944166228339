import { Avatar, Box, Button, Radio, Stack, Typography } from "knack-ui";
import { useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import { Failure } from "../../../../components/ToastNotification/Failure";
import SVG from "../../../../components/SVG/SVG";

import { useGetProgramParticipants, useGetPrograms } from "../../../Programs/programQueries";
import { IProgram } from "../../../Programs/ProgramTypes";
import { ICampaignFormRecipient, INewCampaignFormProps } from "../../CampaignTypes";
import { getUniqueArrayOfObjects } from "../../../../lib/utils";
import { useUser } from "../../../User/userQueries";
import { useGetMembers, useGetOrganizations } from "../../../Company/companyQueries";
import { IUserProfile, IOrganization } from "../../../User/UserTypes";

const filterOptions = [
  { label: "Coachee/Mentee", value: "user" },
  { label: "Coach/Mentor", value: "expert" },
  { label: "All", value: "all" }
];
const adminFilterOptions = [
  { label: "Hrs", value: "hr" },
  { label: "Coachee/Mentee", value: "user" },
  { label: "Coach/Mentor", value: "expert" },
  { label: "All", value: "all" }
];

const NewCampaignParticipants = () => {
  const { data: user } = useUser();

  const { setValue, getValues, watch } = useFormContext<INewCampaignFormProps>();

  const [selectedProgram, setSelectedProgram] = useState<string | null>(null);

  const [selectedCompany, setSelectedCompany] = useState<IOrganization | null>(null);

  const [filterBy, setFilterBy] = useState<"all" | "expert" | "user" | "hr">("all");

  const { data: programs, isLoading } = useGetPrograms({
    select: useCallback((data: IProgram[]) => {
      return data.map((p) => ({ label: p.name, value: p._id }));
    }, []),
    enabled: Boolean(user && user?.account_type === "Hr")
  });
  const { data: companies } = useGetOrganizations({
    enabled: Boolean(user && ["Admin", "Subadmin"].includes(user?.account_type as string))
  });

  const { data: programParticipants } = useGetProgramParticipants(
    {
      programId: selectedProgram ?? undefined
    },
    {
      enabled: Boolean(selectedProgram),
      select: (data) => {
        if (filterBy === "all") {
          return data;
        }
        if (filterBy === "expert") {
          return data.filter((p) => ["coach", "mentor"].includes(p.type));
        }
        if (filterBy === "user") {
          return data.filter((p) => ["coachee", "mentee"].includes(p.type));
        }
      }
    }
  );
  const { data: members } = useGetMembers(
    {
      id: selectedCompany?._id as string
    },
    {
      enabled: Boolean(selectedCompany),
      suspense: false,
      select: useCallback(
        (members: IUserProfile[]) => {
          if (filterBy === "all") {
            return members;
          }
          if (filterBy === "expert") {
            return members.filter(
              (p) =>
                p.user.roles?.includes("mentor") ||
                p.user.roles?.includes("coach") ||
                ["Coach", "Mentor"].includes(p.user.account_type as any)
            );
          }
          if (filterBy === "user") {
            return members.filter(
              (p) => p.user.roles?.includes("mentee") || p.user.roles?.includes("coachee") || p.user.account_type === "Employee"
            );
          }
          if (filterBy === "hr") {
            return members.filter((p) => p.user.account_type === "Hr");
          }
        },
        [filterBy]
      )
    }
  );
  // const inputRef = useRef<HTMLInputElement | null>(null);

  const recipients = watch("recipients");

  // const [inputError, setInputError] = useState("");

  const onAddParticipant = (p: ICampaignFormRecipient | ICampaignFormRecipient[]) => {
    const oldRecepients = getValues("recipients");
    if (!Array.isArray(p)) {
      if (oldRecepients.some((old) => old._id === p._id)) {
        Failure("Email already added");
        return;
      }
      setValue("recipients", [...oldRecepients, p]);
    } else {
      // Emails already unique, no need to appending old values
      setValue("recipients", p);
    }
  };

  const onSelectAllParticipants = () => {
    if (!programParticipants) return;
    const participantsIds = programParticipants.filter((i) => Boolean(i.profile.user._id)).map((p) => p.profile.user._id);
    if (isAllListEmailsAdded) {
      const diff = recipients.filter((r) => !participantsIds.includes(r._id));
      onAddParticipant(diff);
    } else {
      const oldRecepients = getValues("recipients");
      // Merge recipients if they already exists
      const unique = getUniqueArrayOfObjects(
        oldRecepients,
        programParticipants.map((p) => ({
          email: p.email,
          _id: p.profile.user._id,
          first_name: p.profile.first_name,
          last_name: p.profile.last_name,
          profile_image: p.profile.user.profile_image
        })),
        "_id"
      );
      onAddParticipant(unique);
    }
  };
  const onSelectAllAdminParticipants = () => {
    if (!members) return;
    const participantsIds = members.filter((i) => Boolean(i.user._id)).map((p) => p.user._id);
    if (isAllAdminListEmailsAdded) {
      const diff = recipients.filter((r) => !participantsIds.includes(r._id));
      onAddParticipant(diff);
    } else {
      const oldRecepients = getValues("recipients");
      // Merge recipients if they already exists
      const unique = getUniqueArrayOfObjects(
        oldRecepients,
        members.map((p) => ({
          email: p.user.email,
          _id: p.user._id,
          first_name: p.first_name,
          last_name: p.last_name,
          profile_image: p.user.profile_image
        })),
        "_id"
      );
      onAddParticipant(unique);
    }
  };
  // const onEmailFormSubmit = (e: FormEvent) => {
  //   setInputError("");

  //   e.preventDefault();

  //   if (!inputRef.current) return;

  //   const inputValue = inputRef.current?.value as string;
  //   if (!inputValue) {
  //     setInputError("Please enter an email address");
  //     return;
  //   }
  //   if (inputValue && !inputValue.match(emailRegex)) {
  //     setInputError("Please provide a valid email");
  //     return;
  //   }

  //   onAddParticipant(inputValue);
  //   inputRef.current.value = "";
  // };
  const onParticipantClick = (p: ICampaignFormRecipient) => {
    const recipientsIds = recipients.map((i) => i._id);
    if (recipientsIds.includes(p._id)) {
      setValue(
        "recipients",
        recipients.filter((i) => i._id !== p._id)
      );
    } else {
      onAddParticipant(p);
    }
  };

  const isAllListEmailsAdded = useMemo(() => {
    if (!programParticipants) return false;
    const pIds = programParticipants.map((p) => p.profile.user._id);
    const recipientsIds = recipients.map((i) => i._id);
    return pIds.every((i) => recipientsIds.includes(i));
  }, [recipients, programParticipants]);

  const isAllAdminListEmailsAdded = useMemo(() => {
    if (!members) return false;
    const pIds = members.map((p) => p.user._id);
    const recipientsIds = recipients.map((i) => i._id);
    return pIds.every((i) => recipientsIds.includes(i));
  }, [recipients, members]);

  return (
    <Box
      id="cparticipants"
      // To make tailwind rtl plugin work
      dir="ltr"
      variant="outlined"
      className="h-full px-8 py-6 overflow-y-auto w-96"
    >
      <Typography variant="h6" className="mb-6">
        Add Participants
      </Typography>

      <div>
        {/* <Typography variant="body1" fontWeight="bold" className="mb-2">
          Add by Email
        </Typography>
        <form onSubmit={onEmailFormSubmit}>
          <Input
            id="participant"
            placeholder="Enter participant email"
            ref={inputRef}
            error={inputError ? inputError : undefined}
          />
        </form>
        <DividerWithText text="OR" className="my-2" /> */}
        <div>
          <Typography variant="body1" fontWeight="bold" className="mb-2">
            {user?.account_type === "Hr" ? "Add from a program" : "Select from a company"}
          </Typography>
          <div>
            {user?.account_type === "Hr" && (
              <KnackSelect
                isLoading={isLoading}
                id="program"
                placeholder="Select a program"
                onChange={(e: any) => setSelectedProgram(e ? e.value : null)}
                options={programs}
              />
            )}
            {["Admin", "Subadmin"].includes(user?.account_type as string) && (
              <KnackSelect
                placeholder="Select a Company"
                onChange={(e: any) => {
                  setSelectedCompany(e);
                }}
                className="text-sm border-none rounded-none outline-none"
                classNamePrefix="Select Company"
                isClearable={true}
                isSearchable={true}
                options={companies}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.name}
              />
            )}
          </div>
        </div>
        {selectedProgram && typeof programParticipants !== "undefined" && (
          <>
            <KnackSelect
              isLoading={isLoading}
              id="filter"
              placeholder="Filter by"
              className="my-4"
              onChange={(e: any) => setFilterBy(e.value)}
              value={filterOptions.find((i) => i.value === filterBy)}
              options={filterOptions}
            />

            <Box className="p-4 mt-4" variant="outlined">
              {programParticipants?.length === 0 && (
                <EmptyState
                  title={`No ${
                    filterBy === "all" ? "participants" : filterBy === "expert" ? "coaches/mentors" : "coachees/mentees"
                  } found for this program`}
                />
              )}
              {programParticipants?.length > 0 && (
                <>
                  <Button
                    kind={isAllListEmailsAdded ? "warning" : "success"}
                    variant="xsmall"
                    className="ms-auto"
                    onClick={user?.account_type === "Hr" ? onSelectAllParticipants : onSelectAllAdminParticipants}
                    startIcon={<SVG size={15} name={isAllListEmailsAdded ? "Close" : "ColoredCheckmark"} />}
                  >
                    {isAllListEmailsAdded ? "Clear all" : "Add all"}
                  </Button>

                  <div className="grid gap-4 mt-2 overflow-y-auto" style={{ maxHeight: 300 }}>
                    {programParticipants.map((p) => (
                      <Stack
                        onClick={() =>
                          onParticipantClick({
                            email: p.email,
                            _id: p.profile.user._id,
                            first_name: p.profile.first_name,
                            last_name: p.profile.last_name,
                            profile_image: p.profile.user.profile_image
                          })
                        }
                        className={`${
                          recipients.some((r) => r._id === p.profile.user._id) ? "bg-success bg-opacity-10" : "hover:bg-tertiary"
                        } p-2 transition duration-75 rounded-lg cursor-pointer self-start `}
                        alignItems="center"
                        gap={2}
                      >
                        <Radio color="secondary" checked={recipients.some((r) => r._id === p.profile.user._id)} />
                        <Avatar size="medium" text={p.profile.first_name || p.email} image={p.profile.user.profile_image} />
                        <div>
                          <Typography variant="caption" fontWeight="semibold">
                            {p.profile.first_name} {p.profile.last_name}
                          </Typography>
                          <Typography clamp={1} color="muted" variant="caption">
                            {p.email}
                          </Typography>
                        </div>
                      </Stack>
                    ))}
                  </div>
                </>
              )}
            </Box>
          </>
        )}
        {selectedCompany && typeof members !== "undefined" && (
          <>
            <KnackSelect
              isLoading={isLoading}
              id="filter"
              placeholder="Filter by"
              className="my-4"
              onChange={(e: any) => setFilterBy(e.value)}
              value={adminFilterOptions.find((i) => i.value === filterBy)}
              options={adminFilterOptions}
            />

            <Box className="p-4 mt-4" variant="outlined">
              {members?.length === 0 && (
                <EmptyState
                  title={`No ${
                    filterBy === "all"
                      ? "participants"
                      : filterBy === "expert"
                      ? "coaches/mentors"
                      : filterBy === "user"
                      ? "coachees/mentees"
                      : "Hrs"
                  } found for this company`}
                />
              )}
              {members?.length > 0 && (
                <>
                  <Button
                    kind={isAllAdminListEmailsAdded ? "warning" : "success"}
                    variant="xsmall"
                    className="ms-auto"
                    onClick={onSelectAllAdminParticipants}
                    startIcon={<SVG size={15} name={isAllAdminListEmailsAdded ? "Close" : "ColoredCheckmark"} />}
                  >
                    {isAllAdminListEmailsAdded ? "Clear all" : "Add all"}
                  </Button>

                  <div className="grid grid-cols-1 gap-4 mt-2 overflow-y-auto" style={{ maxHeight: 400 }}>
                    {members.map((p) => (
                      <Stack
                        onClick={() =>
                          onParticipantClick({
                            email: p.user.email,
                            _id: p.user._id,
                            first_name: p.first_name,
                            last_name: p.last_name,
                            profile_image: p.user.profile_image
                          })
                        }
                        className={`${
                          recipients.some((r) => r._id === p.user._id) ? "bg-success bg-opacity-10" : "hover:bg-tertiary"
                        } p-2 transition duration-75 rounded-lg cursor-pointer self-start `}
                        alignItems="center"
                        gap={2}
                      >
                        <div className="flex flex-col justify-center flex-shrink-0">
                          <Radio color="secondary" checked={recipients.some((r) => r._id === p.user._id)} />
                        </div>
                        <Avatar className="flex-shrink-0" size="medium" text={p.first_name || p.user.email} image={p.user.profile_image} />
                        <div>
                          <Typography clamp={1} variant="caption" fontWeight="semibold">
                            {p.first_name} {p.last_name}
                          </Typography>
                          <Typography clamp={1} color="muted" variant="caption">
                            {p.user.email}
                          </Typography>
                        </div>
                      </Stack>
                    ))}
                  </div>
                </>
              )}
            </Box>
          </>
        )}
      </div>
    </Box>
  );
};

export default NewCampaignParticipants;
