interface IProps {
  /**
   * Use tailwind classnames
   * @example md:grid-cols-2
   */
  gridTemplateColumns?: string;
  /**
   * Use tailwind classnames
   * @example gap-4
   */
  gap?: string;
  className?: string;
}

const Grid: React.FC<IProps> = ({ gridTemplateColumns, children, gap, className }) => {
  return (
    <div style={{ isolation: "isolate" }} className={`grid ${gridTemplateColumns} ${gap} ${className}`}>
      {children}
    </div>
  );
};

export default Grid;


