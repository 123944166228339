import { useTranslation } from "react-i18next";
import Heading from "../../components/heading/heading";

export default function Disclaimer(props) {
  const { t } = useTranslation(["assessments", "360"]);

  return (
    <div className="px-4 py-4 my-4 bg-white sm:shadow sm:rounded-lg">
      <Heading title={props.title} />
      <ul className="px-6 mx-auto text-sm leading-relaxed text-gray-600 list-disc">
        <li className="py-2">{t("ASSESSMENT.DISCLAIMER.P1")}</li>
        <li className="py-2">{t("ASSESSMENT.DISCLAIMER.P2")}</li>
        <li className="py-2">{t("ASSESSMENT.DISCLAIMER.P3")}.</li>
        <li className="py-2">{t("ASSESSMENT.DISCLAIMER.P4")}</li>
        <li className="py-2">{t("ASSESSMENT.DISCLAIMER.P5")}</li>
        {/* <li className="py-2">{t('ASSESSMENT.DISCLAIMER.ACCEPT_EULA_PRE_TEXT')}{" "} */}{" "}
        {t("ASSESSMENT.DISCLAIMER.ACCEPT_EULA_PRE_TEXT")}
        <a
          className="text-skin-accent"
          href="https://knacklab.co/terms/"
          target="_blank"
          rel="noreferrer"
        >
          {t`ASSESSMENT.DISCLAIMER.TERMS_AND_CONDITIONS`}{" "}
        </a>{" "}
        {t("ASSESSMENT.DISCLAIMER.ACCEPT_EULA_POST_TEXT")}{" "}
        <a
          className="text-skin-accent"
          href="https://knacklab.co/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          {t("ASSESSMENT.DISCLAIMER.PRIVACY_POLICY")}{" "}
        </a>{" "}
        .{/* </li> */}
      </ul>
      <button
        className="flex items-center justify-center w-full px-8 py-3 mt-8 text-center text-white border-0 rounded-lg bg-skin-accent focus:outline-none hover:bg-skin-accent"
        onClick={props.onStartButtonClick}
      >
        {props.buttonText}
      </button>
    </div>
  );
}
