import { BaseDialog, Box, Breadcrumbs, Button, Popover, Stack, Typography } from "knack-ui";
import { useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import ProgramOverview from "../../../../V3/Pages/Programs/ui/ProgramOverview";
import { useGetHRProgram } from "../../programQueries";
import { IUserProgram } from "../../ProgramTypes";
import HrProgramDashboardAnalytics from "./HrProgramDashboardAnalytics";
import HrProgramDashboardParticipants from "./Participants/HrProgramDashboardParticipants";
import { Success } from "../../../../components/ToastNotification/Success";
import { useLaunchMatching, useSendAutoEnrollmentReminder } from "../../programMutations";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import { FiMoreVertical } from 'react-icons/fi';
import { useLoadingSpinner } from '../../../../hooks/useLoadingSpinner';

const HrProgramDashboard = () => {
  const { mutateAsync: launchPairing } = useLaunchMatching();
  const [confirmLaunch, setConfirmLaunch] = useState(false);
  const { programId, tab } = useParams<{ programId?: string; tab?: string }>();
  const { data: program } = useGetHRProgram({ programId });
  const history = useHistory();
  const [showMoreMenu, setShowMoreMenu] = useState(false)
  const parentRef = useRef<HTMLButtonElement | null>(null);
  const { mutateAsync: sendAutoEnrollmentReminder, isLoading } = useSendAutoEnrollmentReminder()
  const { setLoading } = useLoadingSpinner();
  const [open, setOpen] = useState(false)
  async function onLaunchPairing() {
    if (program?.status === "Published") {
      try {
        await launchPairing({ programId: program._id });
        Success(
          `You have successfully launch matching`,
          `${program?.program_type === "Mentoring" ? "Mentees" : "Coachees"} will receive matching recommendation on their dashboard.`
        );
        setConfirmLaunch(false);
      } catch (error) {
        console.log(error);
      }
    }
  }

  if (!program) return null;

  const onSendAutoEnrollmentReminder = async () => {
    try {
      setLoading(true);
      await sendAutoEnrollmentReminder(program._id);
      Success("Reminder sent successfully.");
      setLoading(false);
      setOpen(false)
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="h-full">
        <ConfirmationDialog
          isOpen={confirmLaunch}
          onClose={() => setConfirmLaunch(false)}
          headerText="Are you sure you want to launch matching?"
          footerComponent={() => {
            return (
              <Stack justifyContent="flex-end" gap={2}>
                <Button kind="ghost" onClick={() => setConfirmLaunch(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onLaunchPairing();
                  }}
                >
                  Launch matching
                </Button>
              </Stack>
            );
          }}
        >
          <Typography>
            {`Launching matching will enable ${program?.program_type === "Mentoring" ? "mentees" : "coachees"
              } to receive and view their recommended expert matches`}
            .
          </Typography>
          <Typography variant="subtitle2" color="muted" className="mt-4">
            Note: You can keep adding and inviting participants after launching matching.
          </Typography>
        </ConfirmationDialog>

        <Box variant="outlined" className="p-6 mb-4">
          <Breadcrumbs>{[<Link to="/programs">Programs</Link>, <Typography clamp={1}>{program?.name}</Typography>]}</Breadcrumbs>
          {!program?.archived && (
            <Stack justifyContent="flex-end" gap={4}>
              <Stack gap={4}>
                <Button
                  kind="ghost"
                  onClick={() =>
                    history.push({
                      pathname: `/edit-program/${program?._id}`
                    })
                  }
                >
                  Edit program
                </Button>
                <div className="flex flex-row items-center justify-end">
                  {program && !program?.matchingLaunched && (
                    <div className="hidden sm:block">
                      <Button onClick={() => setConfirmLaunch(true)}>Launch matching</Button>
                    </div>
                  )}
                  {
                    program.isAutoEnrollment &&
                    (program.participantsYetToCompleteAutoEnrollment > 0) &&
                    <div className='ml-1 h-full'>
                      <Button variant='xsmall' kind='ghost' className='h-full' ref={parentRef}
                        onClick={() => setShowMoreMenu(true)}>
                        <FiMoreVertical />
                      </Button>
                      <Popover
                        animationType="fade-up"
                        offset={{ bottom: 10, left: 0 }}
                        isOpen={showMoreMenu}
                        onClose={() => setShowMoreMenu(false)}
                        parentRef={parentRef}
                      >
                        <div className='flex flex-col'>
                          <Button kind='ghost'
                            onClick={() => setOpen(true)}>
                            Send Reminder
                          </Button>
                        </div>
                      </Popover>
                    </div>
                  }
                </div>
              </Stack>
            </Stack>
          )}
        </Box>

        <div>
          <Box className="h-full px-6 py-4 overflow-y-auto" variant="outlined">
            <Tabs defaultIndex={tab ? Number(tab) : 0} style={{ height: "100%", overflowY: "auto" }}>
              <TabList className="px-4 border-b">
                <Tab
                  className="inline-block px-4 py-3 text-sm cursor-pointer text-primaryDark"
                  selectedClassName="border-b-2 border-skin-base"
                >
                  Analytics
                </Tab>
                <Tab
                  className="inline-block px-4 py-3 text-sm cursor-pointer text-primaryDark"
                  selectedClassName="border-b-2 border-skin-base"
                >
                  Participants
                </Tab>
                <Tab
                  className="inline-block px-4 py-3 text-sm cursor-pointer text-primaryDark"
                  selectedClassName="border-b-2 border-skin-base"
                >
                  About program
                </Tab>
              </TabList>
              <TabPanel
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem"
                }}
              >
                <HrProgramDashboardAnalytics program={program} />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem"
                }}
              >
                <HrProgramDashboardParticipants program={program} />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem"
                }}
              >
                <ProgramOverview program={program as IUserProgram} showDetails />
              </TabPanel>
            </Tabs>
          </Box>
        </div>
      </div>


      <BaseDialog animationType="fade" dialogClassName="max-w-lg" isOpen={open} onClose={() => setOpen(false)}>
        <div className='p-6'>
          <Typography variant="h6" className="font-normal" as="h2">
            You are about to send a reminder to participants who have not yet completed their auto-enrollment onboarding.
          </Typography>
        </div>

        <Stack className="p-6 border-t" justifyContent="flex-end" gap={4}>
          <Button kind="ghost" onClick={() => setOpen(false)}>
            Dismiss
          </Button>
          <Button isLoading={isLoading} onClick={() => onSendAutoEnrollmentReminder()}>
            Send Reminder
          </Button>
        </Stack>
      </BaseDialog>
    </>
  );
};

export default HrProgramDashboard;
