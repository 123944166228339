import { format } from "date-fns/esm";
import { Box, Breadcrumbs, Button, Stack, Typography } from "knack-ui";
import { Link, useHistory, useParams } from "react-router-dom";
import { useGetOneCampaign } from "../campaignQueries";

const ViewCampaign = () => {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { data: campaign } = useGetOneCampaign({ id });
  return (
    <div>
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[
          <Link key="link" to="/campaigns">
            Campaigns
          </Link>,
          <Typography key="name" clamp={1}>
            {campaign?.subject}
          </Typography>
        ]}
      </Box>

      <div>
        <Box
          // To make tailwind rtl plugin work
          dir="ltr"
          variant="outlined"
          className="flex-1 h-full px-8 py-6 overflow-y-auto"
        >
          <Stack
            alignItems="center"
            justifyContent="space-between"
            className="mb-6"
          >
            <div>
              <Typography variant="h5">{campaign?.subject}</Typography>
              <Typography color="muted" variant="subtitle2">
                Created on{" "}
                {format(new Date(campaign?.created_at as string), "dd/MM/yyyy")}
              </Typography>
            </div>
            <Button
              kind="success"
              onClick={() => {
                history.push({
                  pathname: "/new-campaign",
                  state: { subject: campaign?.subject, body: campaign?.body }
                });
              }}
            >
              Make a copy
            </Button>
          </Stack>
          <div className="mb-6">
            <Typography variant="body1" fontWeight="semibold">
              Participants
            </Typography>

            <div className="my-4 overflow-y-auto max-h-40 scrollhost">
              <div className="flex flex-wrap gap-2">
                {campaign?.recipients.map((r) => (
                  <Stack
                    className="items-center px-2 py-2 text-xs rounded-full bg-tertiary"
                    key={r.user._id}
                    gap={2}
                  >
                    <span>{`${r.first_name} ${r.last_name} (${r.user.email})`}</span>
                  </Stack>
                ))}
              </div>
            </div>
          </div>
          <div>
            <Typography variant="body1" fontWeight="semibold" className="mb-4">
              Campaign email contents:
            </Typography>
            <Box
              variant="outlined"
              className="p-4 "
              dangerouslySetInnerHTML={{ __html: campaign?.body as string }}
            />
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ViewCampaign;
