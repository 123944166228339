import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { InputSelect } from "../../../../../components/Inputs/InputSelect";
import { InviteParticipantsCard } from "../../../../../components/Cards/InviteParticipantsCard";
import { SearchInput } from "../../../../../components/Inputs/SearchInput";
import { IProgram, IProgramInvite } from "../../../ProgramTypes";
import { useFilter } from "./utils/Filter";
import { onSearchPeople } from "../../../../../pages/dashboard/hrDashboard/hrPeople/PeopleList";
import HrProgramUserList from "./HrProgramUserList";
import { BaseDialog, Box, Button, Chip, Popover, Stack, Typography } from "knack-ui";
import HrProgramParticipantInvitationByEmail from "./components/HrProgramParticipantInvitation";
import { useSendExpertSelectionReminder, useSendRegistrationReminder } from "../../../programMutations";
import { useGetProgramParticipants } from "../../../programQueries";
import { Success } from "../../../../../components/ToastNotification/Success";
import { queryClient } from "../../../../../lib/appConfig";
import { programsQueryKeys } from "../../../programQueries";

interface IProps {
  program: IProgram;
}
const HRProgramUsers = ({ program }: IProps) => {
  const globalCheckboxRef = useRef<HTMLInputElement>(null);
  const [emailModal, setEmailModal] = useState(false);
  const { value: filterBy } = useFilter();
  const [selections, setSelections] = useState<IProgramInvite[]>([]);
  const { value } = onSearchPeople();

  const userType = useMemo(() => {
    return program.program_type === "Coaching" ? "coachee" : "mentee";
  }, [program]);

  const { data: membersList } = useGetProgramParticipants(
    { programId: program?._id, type: userType },
    {
      enabled: Boolean(program)
    }
  );

  const members = useMemo(() => {
    setSelections([]);
    if (globalCheckboxRef.current) {
      globalCheckboxRef.current.checked = false;
    }

    if (!membersList) return [];
    else if (value) {
      setSelections([]);
      return membersList.filter(
        (m) =>
          m?.profile?.user?.email?.includes(value.toLowerCase()) ||
          `${m?.profile?.first_name} ${m?.profile?.last_name}`.toLowerCase().includes(value.toLowerCase())
      );
    } else if (filterBy === "Paired") {
      return membersList?.filter((s) => Boolean(s?.connection));
    } else if (filterBy === "Unpaired") {
      return membersList?.filter((s) => !Boolean(s?.connection) && s?.confirmed);
    } else if (filterBy === "Registered") {
      return membersList?.filter((s) => s?.confirmed);
    } else if (filterBy === "Unregistered") {
      return membersList?.filter((s) => !Boolean(s?.confirmed));
    }

    return membersList;
  }, [filterBy, membersList, value]);

  function onAddRemoveItemSelection(item: IProgramInvite) {
    if (selections.find(({ _id }) => _id === item._id)) {
      const selected = [...selections].filter((f) => f._id !== item._id);
      setSelections(selected);
    } else {
      const selected = selections.length > 0 ? [...selections] : [];
      selected.push(item);
      setSelections(selected);
    }
  }

  const onGlobalSelectionChange = (checked: boolean) => {
    if (!checked) {
      setSelections([]);
    } else {
      setSelections(members);
    }
  };

  return (
    <>
      <HrProgramParticipantInvitationByEmail
        open={emailModal}
        program={program}
        type={program.program_type === "Coaching" ? "coachee" : "mentee"}
        onClose={() => setEmailModal(false)}
      />

      <div>
        <FilterComponent program={program} selections={selections} filterBy={filterBy} setEmailModal={setEmailModal} />
        {membersList && membersList?.length > 0 ? (
          <HrProgramUserList
            program={program}
            members={members}
            userType={program.program_type === "Coaching" ? "coachee" : "mentee"}
            filterBy={filterBy}
            selections={selections}
            onAddRemoveItemSelection={onAddRemoveItemSelection}
            onGlobalSelectionChange={onGlobalSelectionChange}
            globalCheckboxRef={globalCheckboxRef}
          />
        ) : (
          <InviteParticipantsCard program={program} type={userType} openAddByEmailModal={() => setEmailModal(true)} />
        )}
      </div>
    </>
  );
};

interface IFilterProps {
  program: IProgram;
  selections: IProgramInvite[];
  filterBy: string;
  setEmailModal: Dispatch<SetStateAction<boolean>>;
}
const FilterComponent = ({ program, selections, filterBy, setEmailModal }: IFilterProps) => {
  const buttonRef = useRef(null);
  const [showHoverCard, setShowHoverCard] = useState(false);
  const { onFilterChange, value } = useFilter();
  const { mutateAsync: sendRegistrationReminder } = useSendRegistrationReminder();
  const { mutateAsync: sendExpertSelectionReminder } = useSendExpertSelectionReminder();
  const [openConfirmSendPairingReminder, setOpenConfirmPairingReminder] = useState(false);
  const [openConfirmSendRegistrationReminder, setOpenConfirmSendReminder] = useState(false);
  const { onChange } = onSearchPeople();

  const onSendRegistrationReminder = () => {
    setOpenConfirmSendReminder(false);
    sendRegistrationReminder({
      userIDS: selections.map((i) => i.profile.user._id),
      programId: program._id
    }).then(() => {
      Success(`Reminder sent successfully`);
      queryClient.invalidateQueries(programsQueryKeys.all);
    });
  };

  const onSendExpertSelectionReminder = () => {
    setOpenConfirmPairingReminder(false);
    sendExpertSelectionReminder({
      userIDS: selections.map((i) => i.profile.user._id),
      programId: program._id
    }).then(() => {
      Success(`Reminder sent successfully`);
    });
  };

  return (
    <>
      <BaseDialog
        animationType="fade"
        dialogClassName="max-w-xl"
        isOpen={openConfirmSendRegistrationReminder}
        onClose={() => setOpenConfirmSendReminder(false)}
      >
        <Box dir="ltr" elevation={0} paddingPreset="card" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
          <Typography variant="h6" className="mb-6" as="h2">
            {`Are you sure you want to send a reminder to the selected participant${selections.length > 1 ? "s" : ""}?`}
          </Typography>
          <Stack justifyContent="flex-end" gap={3}>
            <Button kind="ghost" onClick={() => setOpenConfirmSendReminder(false)}>
              Cancel
            </Button>
            <Button onClick={() => onSendRegistrationReminder()}>{selections.length > 1 ? "Send Reminder to all" : "Send Reminder"}</Button>
          </Stack>
        </Box>
      </BaseDialog>

      <BaseDialog
        animationType="fade"
        dialogClassName="max-w-xl"
        isOpen={openConfirmSendPairingReminder}
        onClose={() => setOpenConfirmPairingReminder(false)}
      >
        <Box dir="ltr" elevation={0} paddingPreset="card" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
          <Typography variant="h6" className="mb-6" as="h2">
            {`Are you sure you want to send a reminder to the selected participant${selections.length > 1 ? "s" : ""}?`}
          </Typography>
          <Stack justifyContent="flex-end" gap={3}>
            <Button kind="ghost" onClick={() => setOpenConfirmPairingReminder(false)}>
              Cancel
            </Button>
            <Button onClick={() => onSendExpertSelectionReminder()}>
              {selections.length > 1 ? "Send Reminder to all" : "Send Reminder"}
            </Button>
          </Stack>
        </Box>
      </BaseDialog>

      <div className="flex-row items-center hidden w-full gap-4 modify-program-select sm:flex">
        <div className="mr-4">
          <InputSelect
            options={["Registered", "Unregistered", "Paired", "Unpaired"]}
            w={"w-40"}
            onChange={onFilterChange}
            selected={value}
            name={undefined}
          />
        </div>

        <div className="w-full inline-block">
          <SearchInput placeholder="Search email, name..." onChange={onChange} />
        </div>

        {program && program._id && !program?.archived && (
          <>
            {selections.length > 0 && filterBy === "Unpaired" && (
              <Button ref={buttonRef} onClick={() => setOpenConfirmPairingReminder(true)} kind="defaultOutline">
                <Chip size="small" variant="secondary">
                  {selections.length}
                </Chip>{" "}
                <span className="ml-4">Send reminder</span>
              </Button>
            )}
          </>
        )}

        {program && program._id && !program?.archived && (
          <>
            {selections.length > 0 && filterBy === "Unregistered" && (
              <Button ref={buttonRef} onClick={() => setOpenConfirmSendReminder(true)} kind="defaultOutline">
                <Chip size="small" variant="secondary">
                  {selections.length}
                </Chip>{" "}
                <span className="ml-4">Send reminder</span>
              </Button>
            )}
          </>
        )}

        {/* Invite CTA */}
        {program && program._id && !program?.archived && (
          <>
            <Button ref={buttonRef} onClick={() => setShowHoverCard(true)} kind="defaultOutline">
              {program.program_type === "Coaching" ? "Add Coachees" : "Add Mentees"}
            </Button>
            <Popover
              animationType="fade-up"
              offset={{ bottom: 10, left: 0 }}
              isOpen={showHoverCard}
              onClose={() => setShowHoverCard(false)}
              parentRef={buttonRef}
            >
              <InviteParticipantsCard
                program={program}
                type={program.program_type === "Coaching" ? "coachee" : "mentee"}
                openAddByEmailModal={() => setEmailModal(true)}
              />
            </Popover>
          </>
        )}
      </div>
    </>
  );
};

export default HRProgramUsers;
