import { useRef, useState } from "react";
import { AvatarPlaceholderV2 } from "../../../components/SVG/AvatarPlaceholderV2";
import { Popover } from "knack-ui";

export const AssignedExpertOrMatches = ({ connectionUserId, expert, selectedMatch, matches, setSelectedMatch }) => {
  const [matchesTapState, setMatchesTapState] = useState(false);
  const buttonRef = useRef(null);

  return (
    <>
      {expert && (
        <div className="flex flex-row gap-4 items-center z-20">
          <AvatarPlaceholderV2
            imageUrl={expert?.user?.profile_image || null}
            height="h-12"
            width="w-12"
            nameFallback={`${expert?.first_name} ${expert?.last_name}`?.trim()}
            classes="border-2 rounded-full overflow-hidden"
          />
          <div className="text-lg capitalize font-medium">
            {expert?.first_name} {expert?.last_name}{" "}
          </div>
        </div>
      )}
      {!expert && (
        <div className="w-72 relative z-20" ref={buttonRef}>
          <div
            style={{ height: 80 }}
            className="relative gap-4 pl-4 flex flex-row items-center text-left rounded-lg cursor-pointer hover:bg-skin-lighter border"
            onClick={() => {
              setMatchesTapState(true);
            }}
          >
            <div className="flex flex-row gap-4 items-center">
              <AvatarPlaceholderV2
                imageUrl={selectedMatch?.expert?.user?.profile_image}
                height="h-12"
                width="w-12"
                nameFallback={`${selectedMatch?.expert?.first_name} ${selectedMatch?.expert?.last_name}`?.trim()}
                classes="border-2 rounded-full overflow-hidden"
              />
              <div className="text-lg capitalize font-medium">
                {selectedMatch?.expert?.first_name} {selectedMatch?.expert?.last_name}{" "}
              </div>
            </div>
            {/* Droplet Arrow */}
            <div className={`absolute right-0 translate transform delay-300 duration-300 p-4 ${matchesTapState ? "" : "-rotate-180"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="var(--color-accent)">
                <path
                  id="Icon_ionic-md-arrow-dropdown"
                  data-name="Icon ionic-md-arrow-dropdown"
                  d="M9,13.5l9,9,9-9Z"
                  transform="translate(-9 -13.5)"
                />
              </svg>
            </div>
          </div>
        </div>
      )}

      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={matchesTapState}
        onClose={() => setMatchesTapState(false)}
        parentRef={buttonRef}
        popoverProps={{
          style: {
            width: "100%",
            maxWidth: "270px"
          }
        }}
      >
        <div className="z-auto w-full rounded-lg overflow-x-none overflow-y-auto scrollhost2 bg-white" style={{ maxHeight: 400 }}>
          {!connectionUserId &&
            matches
              ?.filter((f) => f?.expert.user?._id !== selectedMatch?.expert?.user?._id)
              .map((m, i) => (
                <div
                  key={i}
                  className={`cursor-pointer w-full text-left hover:bg-skin-light`}
                  onClick={() => {
                    setMatchesTapState(false);

                    setTimeout(() => {
                      setSelectedMatch(m);
                    }, 200);
                  }}
                >
                  <div className="flex flex-row gap-4 items-center border-b p-4">
                    <AvatarPlaceholderV2
                      imageUrl={m?.expert?.profile_image}
                      height="h-12"
                      width="w-12"
                      nameFallback={`${m?.expert?.first_name} ${m?.expert?.last_name}`?.trim()}
                      classes="border-2 rounded-full overflow-hidden"
                    />
                    <div className="text-lg capitalize font-medium">
                      {m?.expert?.first_name} {m?.expert?.last_name}{" "}
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </Popover>
    </>
  );
};
