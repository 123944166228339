import { useEffect, useState } from "react";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";

export const EmploymentManagement = () => {
  const [selections, setSelections] = useState([]);
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    getEmployees();
  }, [setEmployees]);

  function getEmployees() {
    AdminServices.getEmployees()
      .then((res) => {
        const { data } = res.data;
        if (res.data.status === true) {
          setEmployees(data);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <div className="relative h-full">
      <div
        style={{ minHeight: 100 }}
        className="bg-white rounded shadow lg:block"
      >
        {/* table header */}
        <div className="grid-cols-5 p-4 text-sm font-bold border-b lg:grid xl:text-base">
          <div>
            {selections.length > 0 && (
              <input
                type="checkbox"
                className="mt-0 mr-4 removeInputRing"
                defaultChecked={selections.length > 0}
                onClick={() => setSelections([])}
              />
            )}
            <span>Employee</span>
          </div>
          <div>Email</div>
          <div>Coach</div>
          <div>Company</div>
          <div>Sessions</div>
        </div>
        {/* table body large screens*/}
        {employees?.map((e, i) => (
          <label
            key={i}
            className={`${
              selections.find(({ _id }) => _id === e._id) !== undefined
                ? "bg-blue-100 "
                : "hover:bg-gray-50"
            } block w-full cursor-pointer`}
          >
            <div className="hidden grid-cols-5 p-4 text-sm border-b lg:grid ">
              <div>
                {e?.employee?.first_name} {e?.employee?.last_name}
              </div>
              <div>{e?.email}</div>
              <div>
                {e?.coach?.first_name} {e?.coach?.last_name}{" "}
              </div>
              <div>{e?.organization?.name}</div>
              <div>{e?.sessions_completed} </div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};
