import React from "react";

const KnackLogoSVG = () => {
  return (
    <svg
      width="61"
      height="13"
      viewBox="0 0 61 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3144 2.20001C29.6494 2.20001 29.0715 2.34765 28.5806 2.6405C28.0898 2.93334 27.715 3.32784 27.4562 3.82399L27.287 2.40331H25.3767V10.7967H27.5409V6.46448C27.5409 5.69726 27.7343 5.09946 28.1236 4.67108C28.5129 4.24269 29.011 4.02729 29.6204 4.02729C30.2394 4.02729 30.6964 4.21849 30.989 4.60331C31.2816 4.98571 31.4291 5.53994 31.4291 6.26118V10.7967H33.5932V6.05788C33.5932 4.79451 33.3055 3.83609 32.73 3.18263C32.1569 2.52916 31.3517 2.20001 30.3144 2.20001Z"
        fill="#1F40E6"
      />
      <path
        d="M24.2063 2.40331H21.6384L18.6617 5.80375V2.20001H16.5V10.7967H18.6617V6.58307L22.0615 10.7967H24.7649L20.7751 6.22729L24.2063 2.40331Z"
        fill="#1F40E6"
      />
      <path
        d="M59.6233 2.40331H57.0529L54.0787 5.80375V2.20001H51.9146V10.7967H54.0787V6.58307L57.4761 10.7967H60.1819L56.1921 6.22729L59.6233 2.40331Z"
        fill="#1F40E6"
      />
      <path
        d="M47.3136 4.04424C47.7633 4.04424 48.1551 4.16041 48.4888 4.39033C48.82 4.62267 49.0425 4.93973 49.1561 5.34633H51.4388C51.2357 4.36613 50.769 3.59407 50.0363 3.03742C49.3036 2.47834 48.4017 2.20001 47.3305 2.20001C46.4745 2.20001 45.7177 2.38637 45.0648 2.75909C44.4119 3.1318 43.8945 3.64974 43.5172 4.3153C43.14 4.98087 42.9514 5.74325 42.9514 6.60001C42.9514 7.45678 43.14 8.21915 43.5172 8.88472C43.8945 9.55029 44.4095 10.0682 45.0648 10.4409C45.7177 10.8137 46.4745 11 47.3305 11C48.4017 11 49.3036 10.712 50.0363 10.136C50.769 9.55997 51.2357 8.80001 51.4388 7.85128H49.1561C49.0425 8.26756 48.82 8.58945 48.4888 8.81453C48.1551 9.03962 47.7633 9.15337 47.3136 9.15337C46.7163 9.15337 46.2085 8.92828 45.7926 8.4757C45.3767 8.02553 45.1664 7.39869 45.1664 6.59759C45.1664 5.79649 45.3743 5.16965 45.7926 4.71948C46.2085 4.27174 46.7139 4.04424 47.3136 4.04424Z"
        fill="#1F40E6"
      />
      <path
        d="M41.2801 3.09792C40.5934 2.50012 39.6503 2.20001 38.4558 2.20001C37.757 2.20001 37.1259 2.32102 36.5625 2.56305C35.9991 2.80507 35.5397 3.15359 35.1842 3.60375C34.8287 4.05392 34.6232 4.58395 34.5676 5.19385H36.681C36.7608 4.78725 36.9615 4.47988 37.2903 4.27174C37.6168 4.0636 38.0012 3.95953 38.4389 3.95953C38.9225 3.95953 39.3287 4.09506 39.6552 4.36613C39.9816 4.63719 40.146 5.06074 40.146 5.63433V5.83763H38.0157C36.7995 5.83763 35.8854 6.07966 35.2761 6.56613C34.6667 7.05018 34.3621 7.68186 34.3621 8.46118C34.3621 8.92344 34.4757 9.34698 34.7006 9.72938C34.9255 10.1142 35.2688 10.4216 35.7307 10.6515C36.1925 10.8814 36.785 10.9976 37.5055 10.9976C38.2043 10.9976 38.775 10.85 39.2224 10.5571C39.6673 10.2643 40.0131 9.89154 40.2621 9.44137L40.4652 10.7943H42.3078V5.63675C42.3126 4.54281 41.9693 3.69572 41.2801 3.09792ZM39.4472 8.69836C39.1039 9.10496 38.6227 9.30826 38.0012 9.30826C37.595 9.30826 37.2686 9.21871 37.0219 9.03719C36.7729 8.8581 36.6495 8.60397 36.6495 8.27482C36.6495 7.96987 36.7729 7.71332 37.0219 7.50518C37.2686 7.29704 37.682 7.19297 38.2551 7.19297H40.0977C40.0082 7.79077 39.7906 8.29176 39.4472 8.69836Z"
        fill="#1F40E6"
      />
      <path
        opacity="0.75"
        d="M6.96767 10.9269H6.96524C6.64404 10.9731 6.28391 10.9999 5.90187 10.9999C5.20108 10.9999 4.56841 10.9123 4.12068 10.7712C1.91851 10.2139 -0.714355 8.35972 0.178678 2.51241C0.271145 1.90408 0.862444 1.51231 1.44401 1.67291C2.03531 1.83595 2.80424 2.14255 3.57318 2.71681C2.67041 4.58075 2.33461 7.08222 4.57084 9.65912C4.95288 9.58612 5.41034 9.54232 5.90187 9.54232C6.43964 9.54232 6.93847 9.59342 7.34241 9.68102C9.60784 7.08952 9.26231 4.57101 8.35224 2.69978C9.11631 2.13038 9.87794 1.82621 10.4668 1.66318C11.0484 1.50015 11.6397 1.89435 11.7321 2.50268C12.7395 9.10432 9.25257 10.6227 6.96767 10.9269Z"
        fill="#1F40E6"
      />
      <path
        d="M7.30005 10.2743C7.16379 10.4228 7.01779 10.5736 6.86449 10.7221C6.64062 10.9459 6.29995 10.9995 5.96659 10.9995C5.63322 10.9995 5.29255 10.9459 5.07112 10.7221C4.91539 10.5712 4.76939 10.4203 4.63312 10.2719C3.18772 8.69025 2.74242 7.13535 2.82272 5.73375C2.75459 4.62171 3.08309 3.56808 3.53325 2.68721C4.43602 3.32475 5.32662 4.33215 5.95442 5.83838C6.58709 4.32242 7.48255 3.30771 8.39262 2.67018C8.84765 3.55348 9.17859 4.61441 9.11289 5.73131C9.19075 7.13535 8.74545 8.69025 7.30005 10.2743Z"
        fill="#1F40E6"
      />
      <path
        opacity="0.75"
        d="M8.4064 2.69978C7.49877 3.36408 6.5887 4.38365 5.95603 5.95802C5.32823 4.39582 4.42546 3.37868 3.52026 2.71682C4.02396 1.69725 4.70043 0.869916 5.21387 0.32485C5.62267 -0.108283 6.3113 -0.108283 6.7201 0.32485C7.2311 0.867483 7.90513 1.68995 8.4064 2.69978Z"
        fill="#1F40E6"
      />
    </svg>
  );
};

export default KnackLogoSVG;
