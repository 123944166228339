import ContentLoader from "react-content-loader";
import Slider from "../../../components/Slider/Slider";
import DashboardGoalCardSkeleton from "./DashboardGoalCardSkeleton";

const DashboardGoalsSkeleton = () => {
  return (
    <div className="p-6 border rounded-lg bg-paper">
      <ContentLoader
        backgroundColor="#ececec"
        foregroundColor="#fafafa"
        viewBox="0 0 303 48"
        height={48}
        width="303"
      >
        <rect x="0" y="0" rx="5" ry="5" width="110" height="17" />
      </ContentLoader>
      <Slider>
        {[0, 1, 2].map((i) => (
          <DashboardGoalCardSkeleton key={i} />
        ))}
      </Slider>
    </div>
  );
};

export default DashboardGoalsSkeleton;
