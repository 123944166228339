import React, { useState, useEffect, useRef } from "react";

export const PopperWrapper = ({ trigger, popComponent }) => {
  const ref = useRef();
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current?.contains(event.target)) {
        setMenuActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setMenuActive]);

  return (
    <>
      <span ref={ref} onClick={() => setMenuActive(true)}>
        {trigger}
      </span>
      {menuActive && (
        <span className="absolute bg-white block max-w-xs sm:w-80 top-0 right-0 z-50 transform sm:translate-y-16 xl:translate-y-12 -translate-x-5 sm:-translate-x-0">
          {popComponent}
        </span>
      )}
    </>
  );
};
