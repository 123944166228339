import { AnimatePresence } from "framer-motion";
import { Button, Stack, Typography } from "knack-ui";
import { Link } from "react-router-dom";
import { ListLoadingSpinner } from "../../../../components/Spinner/ListLoadingSpinner";
import { useGetExperts } from "../../../../features/People/peopleQueries";
import { onSearchPeople } from "../../hrDashboard/hrPeople/PeopleList";
import { CronofyIntegrationStatus } from "./CronofyIntegrationStatus";

const ExpertList = () => {
  const { value } = onSearchPeople();
  const {
    data: experts,
    fetchNextPage,
    hasNextPage,
    isLoading
  } = useGetExperts(
    { search: value },
    {
      enabled: Boolean(value),
      suspense: true
    }
  );

  return (
    <div className="">
      <div className="grid grid-cols-4 gap-4 px-4 py-2 font-medium bg-gray-100 border-b">
        <Typography variant="subtitle2">Name / Email</Typography>
        <Typography variant="subtitle2">Cronofy</Typography>
        <Typography variant="subtitle2">Availability</Typography>
        <Typography variant="subtitle2">Conferencing</Typography>
      </div>
      {experts?.pages?.map((page) => {
        return page.profiles.map((e, index) => (
          <AnimatePresence key={index}>
            <Link
              className="grid grid-cols-4 gap-4 p-4 border-b hover:bg-tertiary"
              to={`connections/${e.user._id}`}
            >
              <Stack direction="column">
                {e?.first_name && (
                  <Typography variant="body1" fontWeight="medium">
                    {e?.first_name} {e?.last_name}
                  </Typography>
                )}
                <Typography variant="subtitle2" className="text-sm">
                  {e?.user?.email}
                </Typography>
              </Stack>
              <CronofyIntegrationStatus profile={e} />
            </Link>
          </AnimatePresence>
        ));
      })}
      {hasNextPage && (
        <div className="flex justify-center my-8">
          {isLoading ? (
            <ListLoadingSpinner text={"Loading more"} isLoading={isLoading} />
          ) : (
            <Button kind="tertiary" onClick={() => fetchNextPage()}>
              Load more
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
export default ExpertList;
