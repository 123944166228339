import { motion } from "framer-motion";
import { Box, Button, Stack, Typography } from "knack-ui";
import { useFormContext } from "react-hook-form";
import ProfileImage from "../../../../components/ProfileImage/ProfileImage";
import { useUserCompany } from "../../../../features/User/userQueries";
import { INewUserProfile } from "../../../../features/User/UserTypes";

import { supportedImageFormats } from "../../../../lib/utils";
import { ProfileCreationTransitions } from "./NewUserProfile";
import { OnboardingStepCounter } from "./OnboardingStepCounter";

interface IProfileImageProps {
  handleNextStep: VoidFunction;
}
const NewUserProfileImage = ({ handleNextStep }: IProfileImageProps) => {
  const { data: company } = useUserCompany();
  const { watch, setValue } = useFormContext<INewUserProfile>();

  return (
    <Box
      paddingPreset="card"
      as={motion.div}
      variants={ProfileCreationTransitions}
      exit="exit"
      initial="hidden"
      animate="visible"
    >
      <OnboardingStepCounter />
      <div className="my-8">
        <Typography variant="h5" textAlign="center">
          Please upload a photo of yours
        </Typography>
        <Typography variant="subtitle2" textAlign="center" color="muted">
          Supported image formats : {supportedImageFormats.join(", ")}
        </Typography>
        <ProfileImage
          image={watch("profile_image")}
          onChange={(url) => setValue("profile_image", url)}
          locked={!company?.mediaSupported}
        />
      </div>
      <Stack gap={4} justifyContent="flex-end">
        <Button onClick={handleNextStep} kind="ghost">
          Skip
        </Button>
        <Button onClick={handleNextStep} form="basicInfo">
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default NewUserProfileImage;
