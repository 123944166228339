import { Box, Button, Stack, Typography } from "knack-ui";
import { IProgramInvite } from "../../../Programs/ProgramTypes";
import { useUpdateProgramInvite } from "../../../Programs/programMutations";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import { useMemo, useState } from "react";
import { SuccessInformation } from "../../../../components/Information/SuccessInformation";
import { FailureInformation } from "../../../../components/Information/FailureInformation";

interface IProps {
  onClose: VoidFunction;
  programInvite: IProgramInvite;
}

const ReferralSourceDialog = ({ onClose, programInvite }: IProps) => {
  const { mutateAsync: updateProgramInvite, isLoading } = useUpdateProgramInvite();
  const [referralSource, setReferralSource] = useState<{ value: string; label: string }>();

  const onSubmit = async () => {
    try {
      await updateProgramInvite({ _id: programInvite._id, referralSourceAnswer: referralSource?.value });
      SuccessInformation({ message: "Thank you for answering the question!" });
      onClose();
    } catch (error) {
      FailureInformation({ message: "Error saving your response, please try again." });
    }
  };

  const referralOptions = useMemo(() => {
    return programInvite.program.referralSourceOptions.map((c: string) => ({
      value: c,
      label: c
    }));
  }, [programInvite.program.referralSourceOptions]);

  return (
    <Box dir="ltr" elevation={0}>
      <div className="p-6 border-b bg-blue-100">
        <Typography variant="h6" className="font-normal" as="h2">
          You are now registered for <strong>{programInvite.program.name}</strong>. How did you discover this program?
        </Typography>
      </div>
      <div className="grid grid-cols-1 gap-4 p-6 dialog-content-max-h">
        <KnackSelect
          id="referral_source"
          className="inner-inner-box-shadow-none"
          placeholder="Select a referral source"
          isSearchable={true}
          options={referralOptions}
          isClearable
          value={referralSource}
          onChange={(option: any) => setReferralSource(option)}
          closeMenuOnSelect={true}
        />
      </div>
      <Stack className="p-6 border-t" justifyContent="flex-end" gap={4}>
        <Button isLoading={isLoading} type="submit" onClick={onSubmit} disabled={!Boolean(referralSource)}>
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default ReferralSourceDialog;
