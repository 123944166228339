export const EmailSent = () => {
  return (
    <lottie-player
      src="https://assets8.lottiefiles.com/private_files/lf30_pw5a608o.json"
      background="transparent"
      speed="1"
      style={{ width: 100, height: 100 }}
      autoplay
    ></lottie-player>
  );
};
