import { Stack, Typography, Avatar, AvatarGroup } from "knack-ui";
import { useHistory } from "react-router-dom";
import { RandomImageFallback } from "../../../../../components/Image/RandomImage";
import KnackImage from "../../../../../components/KnackImage/KnackImage";
import SVG from "../../../../../components/SVG/SVG";
import { IProgram } from "../../../../../features/Programs/ProgramTypes";
import { useUserRoles } from "../../../../../features/User/userQueries";
import { programHasExpert } from "../../../../../lib/utils";

interface IProgramCardProps {
  program: IProgram;
  onSelect: (program: IProgram) => void;
  index: number;
}

const SessionProgramCard = ({
  program,
  onSelect,
  index
}: IProgramCardProps) => {
  const history = useHistory();
  const { roleState } = useUserRoles();
  return (
    <Stack
      onClick={() => onSelect(program)}
      gap={6}
      style={{ height: 100 }}
      className="p-4 border rounded-lg cursor-pointer bg-gray-50 hover:bg-skin-light"
    >
      <div style={{ flexBasis: "20%", maxHeight: "100%", cursor: "pointer" }}>
        <KnackImage
          className="object-cover w-full h-full rounded-lg max-w-24"
          src={program?.image_url || RandomImageFallback(index)}
          fallbackProps={{ className: "object-contain w-full h-full" }}
        />
      </div>
      <Stack gap={2} className="flex-1" direction="column">
        <Typography
          clamp={1}
          variant="body2"
          fontWeight="bold"
          className="hover:underline"
        >
          {program.name}
        </Typography>
        <div className="self-start px-2 py-1 text-xs rounded bg-opacity-20 text-knackPrimary bg-knackPrimary">
          {program.coach_type === "knack_coaching" ? (
            <Stack gap={1} alignItems="center">
              <SVG size={13} name="KnackMiniLogo" />{" "}
              <span>Knack {program.program_type}</span>
            </Stack>
          ) : (
            <p>{program.program_type}</p>
          )}
        </div>

        {roleState?.activeRole === "User" && (
          <div>
            <Typography
              className="mb-2"
              fontWeight="semibold"
              variant="caption"
            >
              Expert :
            </Typography>
            <Stack gap={2} alignItems="center">
              {program.expert && (
                <Avatar
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/connections/${program.expert?.user._id}`);
                  }}
                  text={program.expert.first_name}
                  className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                  image={program.expert.user.profile_image}
                />
              )}

              <Stack
                className="flex-1"
                gap={3}
                alignItems="flex-end"
                justifyContent="space-between"
              >
                {!programHasExpert(program) && (
                  <Typography
                    variant="caption"
                    color="muted"
                    fontWeight="medium"
                  >
                    No Expert Assigned
                  </Typography>
                )}

                {program.expert && (
                  <div className="mt-1">
                    {/* <Typography variant="caption">Expert name</Typography> */}
                    <Typography variant="body2" fontWeight="bold">
                      {`${program.expert?.first_name} ${program.expert?.last_name}`}
                    </Typography>
                  </div>
                )}
              </Stack>
            </Stack>
          </div>
        )}
        {roleState?.activeRole === "Expert" && (
          <Stack gap={2} alignItems="center">
            {program.connections && program.connections?.length > 0 && (
              <div>
                <Typography
                  className="mb-2"
                  fontWeight="semibold"
                  variant="caption"
                >
                  Participants :
                </Typography>
                <div className="flex justify-start">
                  <AvatarGroup max={3}>
                    {program.connections?.map((c) => (
                      <Avatar
                        key={c._id}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`/connections/${c.user._id}`);
                        }}
                        className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                        text={c.first_name}
                        image={c.user.profile_image}
                      />
                    ))}
                  </AvatarGroup>
                </div>
              </div>
            )}
            {program.connections?.length === 0 && (
              <Typography
                className="mb-2"
                fontWeight="semibold"
                color="muted"
                variant="caption"
              >
                No Participants yet
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SessionProgramCard;
