import { useCallback } from "react";
import { ILocalStorageKeys } from "../lib/interfaces/generalTypes";

export const localStorageKeys: ILocalStorageKeys = {
  prefferedProfile: "knckpp",
  expandedMenu: "knxmu",
  showProfileComplete: "knspc",
  showOnboardingDialog: "knobdd",
  user: "user",
  tempUser: "temp-user"
};

export const useLocalStorage = () => {
  /**
   * Sets a value to a key in `localStorage`
   * @param key Desired key to change
   * @param value The desired raw value, the function will handle parsing it
   */
  const set = useCallback(<T>(key: keyof ILocalStorageKeys, value: T) => {
    localStorage.setItem(localStorageKeys[key], JSON.stringify(value));
  }, []);
  /**
   * Gets a value from a key in `localStorage`
   * @param key Desired key to get
   * @param defaultValue if provided sets new key/value incase the desired key was not found
   */
  const get = useCallback(
    <T>(key: keyof ILocalStorageKeys, defaultValue?: T) => {
      const value = localStorage.getItem(localStorageKeys[key]);
      if (value && typeof value !== "undefined") {
        return JSON.parse(value) as T;
      }
      if (typeof defaultValue !== "undefined" && (typeof value === "undefined" || !value)) {
        set(key, defaultValue);
        return defaultValue;
      }
      return null;
    },
    [set]
  );

  /**
   * Remove an item by key in `localStorage`
   * @param key Desired key to change
   */
  const removeItem = (key: keyof ILocalStorageKeys) => localStorage.removeItem(localStorageKeys[key]);

  const resetLocalStorage = () => {
    Object.entries(localStorageKeys).forEach(([k, v]) => {
      localStorage.removeItem(v);
    });
  };

  return { getValue: get, setValue: set, removeItem, resetLocalStorage };
};
