import { Button, Stack, Checkbox, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import { systemTextMapping } from "../../../components/FiltersBar/FilterBar.helpers";
import { FilterOptions as TFilterOptions } from "../../../components/FiltersBar/FiltersBar.types";
import SearchInput from "../../../components/SearchInput/SearchInput";
import SVG from "../../../components/SVG/SVG";

function ListFilterOptions<T>({
  data,
  onChange,
  onOptionRemove,
  searchCriteria,
  onSearchInputChange,
  isOptionSelected,
  onOptionsClear,
  renderText
}: TFilterOptions<T>) {
  const [keyword, setKeyword] = useState("");

  const filteredOptions = useMemo(() => {
    return data
      .filter((o) => !isOptionSelected(o))
      .filter((o) => {
        if (typeof searchCriteria !== "undefined") {
          return searchCriteria?.(o, keyword.toLowerCase());
        }
        return true;
      });
  }, [data, searchCriteria, keyword, isOptionSelected]);

  const selectedOptions = useMemo(() => {
    return data.filter(isOptionSelected);
  }, [isOptionSelected, data]);

  const onClearSelections = () => {
    onOptionsClear();
    if (typeof onSearchInputChange !== "undefined") {
      onSearchInputChange("");
    }
  };

  return (
    // <div style={{ gridTemplateRows: "auto auto 1fr auto", maxBlockSize: "600px", minWidth: 200, minHeight: 200 }} className="grid">
    <div style={{ maxBlockSize: "600px", minWidth: 200 }} className="flex flex-col">
      {
        <div style={{ flex: "0 0 auto" }} className="p-4 border-b">
          <SearchInput
            placeholder="Search..."
            value={keyword}
            onChange={(s) => {
              setKeyword(s);
              if (typeof onSearchInputChange !== "undefined") {
                onSearchInputChange(s);
              }
            }}
          />
        </div>
      }
      {/* )} */}
      {selectedOptions.length > 0 && (
        <div className="p-4 pt-6 overflow-y-auto border-b scrollhost2" style={{ maxHeight: "150px", flex: "0 0 auto" }}>
          <Typography variant="caption" className="mb-2" color="muted">
            Selected: (click to unselect)
          </Typography>
          <div className="flex flex-wrap gap-2 ">
            {selectedOptions.map((o, i) => (
              <div
                onClick={() => {
                  onOptionRemove(o);
                  if (typeof onSearchInputChange !== "undefined") {
                    onSearchInputChange("");
                  }
                }}
                key={i}
                className="flex items-center gap-1 px-2 py-1 text-xs transition-colors duration-100 rounded-full cursor-pointer bg-tertiary hover:bg-primary hover:text-primary"
              >
                {systemTextMapping(renderText(o))}
                <SVG name="Close" size={12} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div style={{ flex: "1 1 auto" }} className="p-4 overflow-x-hidden dialog-content-max-h scrollhost2">
        <div className="grid grid-cols-1 gap-2">
          {filteredOptions.map((o, i) => (
            <Stack alignItems="center" key={i} gap={4}>
              <div className="flex-shrink-0">
                <Checkbox
                  checked={isOptionSelected(o)}
                  onChange={() => {
                    onChange(o);
                    if (typeof onSearchInputChange !== "undefined") {
                      onSearchInputChange("");
                    }
                  }}
                  id={`${i}`}
                  size="small"
                />
              </div>
              <Typography as="label" htmlFor={`${i}`} className="cursor-pointer" variant="subtitle2" fontWeight="semibold" clamp={1}>
                {systemTextMapping(renderText(o))}
              </Typography>
            </Stack>
          ))}
          {filteredOptions.length === 0 && (
            <Typography color="muted" variant="caption" textAlign="center">
              {keyword === "" ? "No options available" : `No matches for "${keyword}"`}
            </Typography>
          )}
        </div>
      </div>
      <div style={{ flex: "0 0 auto" }} className="p-4">
        <Button disabled={!selectedOptions.length} onClick={() => onClearSelections()} fullWidth variant="small">
          Clear
        </Button>
      </div>
    </div>
  );
}

export default ListFilterOptions;
