import personIcon from "../Asset/Images/userIcon.svg";

export const Avatar = ({ image, name, className }) => {
  const refinedName = name?.includes("UNDEFINED") ? (image = personIcon) : name;
  return (
    <>
      {
        <div className="-ml-1 relative">
          <div className="relative inline-block text-left">
            {/* Avatar */}
            <div className="cursor-pointer">
              <div
                className={
                  className
                    ? className + " block object-cover rounded-full"
                    : "block relative h-10 w-10 rounded-full object-cover"
                }
                id="options-menu"
              >
                {image && (
                  <img
                    className="inline-block h-full w-full rounded-full object-cover ring-2 ring-white"
                    src={image}
                    alt={refinedName}
                  />
                )}
                {!image && (
                  <div
                    style={{ color: "#fff", fontSize: "inherit" }}
                    className="bg-skin-accent flex items-center justify-center rounded-full text-center h-full w-full uppercase"
                  >
                    {refinedName?.split(" ")[0]?.charAt(0)}
                    {refinedName?.split(" ")[1]?.charAt(0)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};
