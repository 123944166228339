import { useTranslation } from "react-i18next";

export default function Completed(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-12 h-screen">
        <div className=" mx-auto bg-white rounded-xl shadow-md overflow-hidden ">
          <div className="md:flex">
            <div className="w-full p-3 py-10">
              <div className="flex justify-center">
                {" "}
                <img src="https://i.imgur.com/QOi7Nie.png" width="80" />{" "}
              </div>
              <div className="flex justify-center mt-3">
                {" "}
                <span className="text-xl text-center font-medium">
                  Thank you for your participation
                </span>{" "}
              </div>
              <p className="px-16 text-center text-skin-muted">
                Your results will be shared with {props.name} after being
                aggregated and anonymized
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
