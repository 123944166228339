import ContentLoader from "react-content-loader";

interface IProps {
    height: 15,
    width: 85,
    x: 115,
    y: 66,
    rx: 5,
    ry: 5
}

const RectangleSkeleton = ({ height, width, x, y, rx, ry }: Partial<IProps>) => {
    return (
        <div className="p-4 rounded-lg">
            <ContentLoader
                backgroundColor="#ececec"
                foregroundColor="#fafafa"
                viewBox="0 0 400 136"
                height="100%"
                width="100%"
            >
                <rect x={x} y={y} rx={rx} ry={ry} width={width} height={height} />
            </ContentLoader>
        </div>
    );
};

export default RectangleSkeleton;
