import { RefObject } from "react";
import { USER_TYPES_PLURALS } from "../../../../../services/utils/app.constant";
import { IProgram, IProgramInvite } from "../../../ProgramTypes";
import HrProgramParticipantsUserListTableBody from "./components/HrProgramParticipantsUserListTableBody";
import HrProgramParticipantsUserListTableHead from "./components/HrProgramParticipantsUserListTableHead";

interface IProps {
  program: IProgram;
  members: IProgramInvite[];
  userType: IProgramInvite["type"];
  filterBy: string;
  selections: IProgramInvite[];
  onAddRemoveItemSelection: (invite: IProgramInvite) => void;
  onGlobalSelectionChange: (checked: boolean) => void;
  globalCheckboxRef: RefObject<HTMLInputElement>;
}

const HrProgramUserList = ({
  program,
  members,
  userType,
  filterBy,
  selections,
  onAddRemoveItemSelection,
  onGlobalSelectionChange,
  globalCheckboxRef
}: IProps) => {
  return (
    <>
      {members?.length > 0 && (
        <div className="px-1 text-sm text-skin-muted">
          {members?.length} {USER_TYPES_PLURALS[userType]} {filterBy.toLowerCase()}
        </div>
      )}

      <div className="py-1">
        <div className="pb-10">
          <div className="inline-block w-full h-full min-w-full overflow-x-auto">
            <table className="w-full h-full min-w-full leading-normal whitespace-nowrap">
              <HrProgramParticipantsUserListTableHead
                program={program}
                userType={userType}
                onGlobalSelectionChange={onGlobalSelectionChange}
                globalCheckboxRef={globalCheckboxRef}
              />
              <HrProgramParticipantsUserListTableBody
                selections={selections}
                program={program}
                members={members}
                filter={filterBy}
                onAddRemoveItemSelection={onAddRemoveItemSelection}
              />
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default HrProgramUserList;
