import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { DURATIONS } from "../../lib/constants";
import { GroupedMatch, IFavoriteMatch, IMatch, IProgram, IProgramInvite, IProgramUtilization, IUserProgram } from "./ProgramTypes";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { useUser, useUserRoles } from "../User/userQueries";
import { apiHttp } from "../../lib/appConfig";
import { PeopleTypes } from "../People/PeopleTypes";
import { IConnectionRequest } from "../Connections/ConnectionsTypes";
import { IUserProfile, IUserProfileWithAvailability } from "../User/UserTypes";

// 🏭 Keys factory...
export const programsQueryKeys = {
  // We are making it an object to make it easy to destructure from the query context **
  all: [{ scope: "programs" }] as const,
  platformPrograms: (organization?: string) => [{ ...programsQueryKeys.all[0], organization, entity: "platform programs" }] as const,
  userPrograms: (userType: "user" | "expert") => [{ ...programsQueryKeys.all[0], userType, entity: "user programs" }] as const,
  HrProgram: ({ programId }: { programId?: string }) => [{ ...programsQueryKeys.all[0], entity: "hr program", programId }] as const,
  program: (userType: "user" | "expert", programId?: string) => [{ ...programsQueryKeys.all[0], programId, userType }] as const,
  programUtilization: (programId: string) => [{ ...programsQueryKeys.all[0], programId }] as const,
  organization: (organizationId?: string) => [{ ...programsQueryKeys.all[0], organizationId }] as const,
  programParticipants: ({ programId, type }: GetProgramParticipantsOptions) =>
    [{ ...programsQueryKeys.all[0], programId, type, entity: "participants" }] as const,
  programOTFExpertMatches: (programId?: string) => [{ ...programsQueryKeys.all[0], programId, entity: "expert OTF matches" }] as const,
  programExpertMatches: (programId?: string) => [{ ...programsQueryKeys.all[0], programId, entity: "expert matches" }] as const,

  allProgramsExpertMatches: () => [{ ...programsQueryKeys.all[0], entity: "all expert matches" }] as const,
  programMatchRequests: (programId?: string) =>
    [
      {
        ...programsQueryKeys.all[0],
        entity: "program match requests",
        programId
      }
    ] as const,
  programAvailableExperts: (programId?: string) =>
    [
      {
        ...programsQueryKeys.all[0],
        entity: "program available experts",
        programId
      }
    ] as const,
  favoriteProgramMatch: (programId?: string) =>
    [
      {
        ...programsQueryKeys.all[0],
        entity: "favorite program match",
        programId
      }
    ] as const,
  participantConnectionRequests: (request: IParticipantQueryRequest) =>
    [
      {
        ...programsQueryKeys.all[0],
        entity: "participant connection request",
        ...request
      }
    ] as const
};
export const programInvitationsKeys = {
  // We are making it an object to make it easy destructure from the query context **
  all: [{ scope: "programInvitations" }] as const,
  invitations: (id?: string, confirmed?: boolean) => [{ ...programInvitationsKeys.all[0], id, confirmed }] as const,
  oneInvite: (userId: string, inviteId?: string) => [{ ...programInvitationsKeys.all[0], userId, inviteId }],
  inviteById: (userId: string, programId?: string) => [{ ...programInvitationsKeys.all[0], userId, programId }] as const
};

// 😱 Fetch Functions...
async function fetchProgramParticipants({
  queryKey: [{ programId, type }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["programParticipants"]>>) {
  if (!programId) throw new Error("Program id is required");
  const res = await apiHttp.get<ServerResponse<IProgramInvite[]>>("v1/programs/" + programId + "/invites", { params: { type } });
  return res.data.data;
}

async function fetchProgramInvitations({
  queryKey: [{ id, confirmed }]
}: QueryFunctionContext<ReturnType<typeof programInvitationsKeys["invitations"]>>) {
  const res = await apiHttp.get<ServerResponse<IProgramInvite[]>>("v1/programs/invites/", {
    params: {
      user: id,
      confirmed
    }
  });
  return res.data.data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
}

async function fetchSingleProgramInvitation({
  queryKey: [{ inviteId, userId }]
}: QueryFunctionContext<ReturnType<typeof programInvitationsKeys["oneInvite"]>>) {
  if (!inviteId) throw new Error("Invalid Invite Id");
  const res = await apiHttp.get<ServerResponse<IProgramInvite>>("v1/programs/invites/" + inviteId, {
    params: {
      user: userId
    }
  });
  return res.data.data;
}

async function fetchSingleProgramInvitationByProgramId({
  queryKey: [{ programId, userId }]
}: QueryFunctionContext<ReturnType<typeof programInvitationsKeys["inviteById"]>>) {
  if (!programId) throw new Error("Invalid program Id");
  const res = await apiHttp.get<ServerResponse<IProgramInvite>>(`v1/programs/invites/${userId}/${programId}`);
  return res.data.data;
}

export interface IProgramsResponse {
  total: number;
  mentoring: IProgram[];
  coaching: IProgram[];
}

async function fetchEnrolledPrograms({
  queryKey: [{ userType }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["userPrograms"]>>) {
  const res = await apiHttp.get<ServerResponse<IProgramsResponse>>("v1/programs/enrolled", {
    params: {
      user_type: userType
    }
  });
  return res.data.data;
}

async function fetchPlatformPrograms({
  queryKey: [{ organization }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["platformPrograms"]>>) {
  const res = await apiHttp.get<ServerResponse<IProgram[]>>("v1/programs/platform-programs", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization
        })
    }
  });
  return res.data.data;
}

async function fetchPrograms() {
  const res = await apiHttp.get<ServerResponse<IProgram[]>>("v1/programs");
  return res.data.data;
}

async function fetchOrganizationPrograms({
  queryKey: [{ organizationId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["organization"]>>) {
  if (!organizationId) throw new Error("Organization id is required");
  const res = await apiHttp.get<ServerResponse<IProgram[]>>("v1/programs/organizations/" + organizationId);
  return res.data.data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
}

async function fetchProgram({
  queryKey: [{ programId, userType }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["program"]>>) {
  if (!programId) {
    throw new Error("Invalid program ID");
  }
  const res = await apiHttp.get<ServerResponse<IUserProgram>>("v1/programs/enrolled/" + programId, {
    params: {
      user_type: userType
    }
  });
  return res.data.data;
}
async function fetchHrProgram({ queryKey: [{ programId }] }: QueryFunctionContext<ReturnType<typeof programsQueryKeys["HrProgram"]>>) {
  if (!programId) {
    throw new Error("Invalid program ID");
  }
  const res = await apiHttp.get<ServerResponse<IProgram>>(
    "v1/programs/" + programId
    // { params: { platform: "Coaching" } }
  );
  return res.data.data;
}

async function programUtilization({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["programUtilization"]>>) {
  if (!programId) {
    throw new Error("Invalid program ID");
  }
  const res = await apiHttp.get<ServerResponse<IProgramUtilization>>("v1/programs/utilization/" + programId);
  return res.data.data;
}

async function getProgramOTFExpertMatches({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["programOTFExpertMatches"]>>) {
  if (!programId) {
    throw new Error("Invalid program ID");
  }

  const res = await apiHttp.get<ServerResponse<IMatch[]>>("v1/auth/recommendation", { params: { program: programId } });

  const result = res.data.data;

  return result.sort((a, b) => Number(a.match_degree) - Number(b.match_degree));
  /**
   * Some reference
   * https://peteroupc.github.io/randomfunc.html#Weighted_Choice
   * https://medium.com/@anthonyfuentes/do-the-shuffle-the-durstenfeld-shuffle-7920c2ce0c45
   *
   * Optimized Fisher–Yates shuffle
   */
  // result.map((value, index) => {
  //   const rand = Math.floor(Math.random() * (index + 1));
  //   return ([result[index], result[rand]] = [result[rand], result[index]]);
  // });

  // result.sort((a, b) => {
  //   if (+a.match_degree > +b.match_degree) return -1;
  //   if (+a.match_degree < +b.match_degree) return 1;
  //   return gawiBawiBo(); // is this necessary?
  // });

  /**
   * Randomly select between -1 & 1
   */
  function gawiBawiBo() {
    const options = [-1, 1, 0];
    var rand = options[Math.floor(Math.random() * options.length)];
    return rand;
  }

  return result;
}

async function getProgramExpertMatches({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["programExpertMatches"]>>) {
  if (!programId) {
    throw new Error("Invalid program ID");
  }
  const res = await apiHttp.get<ServerResponse<IMatch[]>>("v1/programs/" + programId + "/matches", { params: { program: programId } });
  return res.data.data;
}

async function getAllExpertMatches({}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["allProgramsExpertMatches"]>>) {
  const res = await apiHttp.get<ServerResponse<GroupedMatch[]>>(
    // "v1/programs/matches",
    "v1/auth/recommendation"
  );
  return res.data.data;
}
async function getExternalCoachingTopics() {
  const res = await apiHttp.get<ServerResponse<string[]>>("sessions/topics");
  return res.data.data;
}

async function getProgramMatchRequests({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["programMatchRequests"]>>) {
  const res = await apiHttp.get<ServerResponse<IConnectionRequest[]>>("v1/auth/requests/" + programId);
  return res.data.data;
}

async function getProgramAvailableExperts({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["programAvailableExperts"]>>) {
  const res = await apiHttp.get<ServerResponse<IUserProfileWithAvailability[]>>("v2/programs/" + programId + "/mentors");
  return res.data.data;
}

async function getProgramFavoriteMatches({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["favoriteProgramMatch"]>>) {
  const res = await apiHttp.get<ServerResponse<IFavoriteMatch[]>>("v2/programs/favorites/" + programId);
  return res.data.data;
}

async function fetchParticipantsRequests({
  queryKey: [{ participantId, programId, userType }]
}: QueryFunctionContext<ReturnType<typeof programsQueryKeys["participantConnectionRequests"]>>) {
  if (!programId) {
    throw new Error("Invalid program ID");
  }
  const res = await apiHttp.get<ServerResponse<IConnectionRequest[]>>("v1/auth/mentor-requests", {
    params: {
      ...(userType === "expert"
        ? {
            mentor: participantId
          }
        : { mentee: participantId }),
      program: programId
    }
  });
  return res.data.data;
}

// Queries...

interface GetProgramParticipantsOptions {
  programId?: string;
  type?: PeopleTypes;
}
export const useGetProgramParticipants = <SelectReturnType = IProgramInvite[], ErrorType = unknown>(
  { programId, type }: GetProgramParticipantsOptions,
  options?: UseQueryOptions<IProgramInvite[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programParticipants"]>>
) => {
  return useQuery<IProgramInvite[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programParticipants"]>>(
    programsQueryKeys.programParticipants({ programId, type }),
    fetchProgramParticipants,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins
    }
  );
};

export const useGetOrganizationPrograms = <SelectReturnType = IProgram[], ErrorType = unknown>(
  { organizationId }: { organizationId?: string },
  options?: UseQueryOptions<IProgram[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["organization"]>>
) => {
  return useQuery<IProgram[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["organization"]>>(
    programsQueryKeys.organization(organizationId),
    fetchOrganizationPrograms,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true
    }
  );
};

interface IUseGetProgramInvitationsProps {
  confirmed?: boolean;
}

export const useGetProgramInvitations = <SelectReturnType = IProgramInvite[], ErrorType = unknown>(
  { confirmed = false }: IUseGetProgramInvitationsProps,
  options?: UseQueryOptions<IProgramInvite[], ErrorType, SelectReturnType, ReturnType<typeof programInvitationsKeys["invitations"]>>
) => {
  const { data: id } = useUser({ select: (user) => user._id });
  return useQuery<IProgramInvite[], ErrorType, SelectReturnType, ReturnType<typeof programInvitationsKeys["invitations"]>>(
    programInvitationsKeys.invitations(id as string, confirmed),
    fetchProgramInvitations,
    {
      staleTime: DURATIONS.fiveMins,
      ...options
    }
  );
};
export const useGetProgramInvite = <SelectReturnType = IProgramInvite, ErrorType = unknown>(
  { invitationId }: { invitationId?: string },
  options?: UseQueryOptions<IProgramInvite, ErrorType, SelectReturnType, ReturnType<typeof programInvitationsKeys["oneInvite"]>>
) => {
  const { data: id } = useUser({ select: (user) => user._id });
  return useQuery<IProgramInvite, ErrorType, SelectReturnType, ReturnType<typeof programInvitationsKeys["oneInvite"]>>(
    programInvitationsKeys.oneInvite(id as string, invitationId),
    fetchSingleProgramInvitation,
    {
      ...options,
      staleTime: DURATIONS.fiveMins
    }
  );
};

export const useGetProgramInviteByProgramId = <SelectReturnType = IProgramInvite, ErrorType = unknown>(
  { programId }: { programId?: string },
  options?: UseQueryOptions<IProgramInvite, ErrorType, SelectReturnType, ReturnType<typeof programInvitationsKeys["inviteById"]>>
) => {
  const { data: id } = useUser({ select: (user) => user._id });
  return useQuery<IProgramInvite, ErrorType, SelectReturnType, ReturnType<typeof programInvitationsKeys["inviteById"]>>(
    programInvitationsKeys.inviteById(id as string, programId),
    fetchSingleProgramInvitationByProgramId,
    {
      ...options,
      staleTime: DURATIONS.fiveMins
    }
  );
};

export const useGetEnrolledPrograms = <SelectReturnType = IProgramsResponse, ErrorType = unknown>(
  options?: UseQueryOptions<IProgramsResponse, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["userPrograms"]>>
) => {
  const { roleState } = useUserRoles();
  const currentRole = roleState?.activeRole === "Expert" ? "expert" : roleState?.activeRole === "User" ? "user" : "expert";
  return useQuery<IProgramsResponse, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["userPrograms"]>>(
    programsQueryKeys.userPrograms(currentRole),
    fetchEnrolledPrograms,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins
    }
  );
};

export const useGetPlatformPrograms = <SelectData = IProgram[], Error = unknown>(
  { organization }: { organization?: string },
  options?: UseQueryOptions<IProgram[], Error, SelectData, ReturnType<typeof programsQueryKeys["platformPrograms"]>>
) => {
  return useQuery<IProgram[], Error, SelectData, ReturnType<typeof programsQueryKeys["platformPrograms"]>>(
    programsQueryKeys.platformPrograms(organization),
    fetchPlatformPrograms,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins
    }
  );
};

export const useGetPrograms = <SelectReturnType = IProgram[], ErrorType = unknown>(
  options?: UseQueryOptions<IProgram[], ErrorType, SelectReturnType>
) => {
  return useQuery<IProgram[], ErrorType, SelectReturnType>(programsQueryKeys.all, fetchPrograms, {
    ...options,
    staleTime: DURATIONS.fifteenMins
  });
};

export const useGetProgram = <SelectReturnType = IUserProgram, ErrorType = unknown>(
  { programId }: { programId?: string },
  options?: UseQueryOptions<IUserProgram, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["program"]>>
) => {
  const { roleState } = useUserRoles();
  const currentRole = roleState?.activeRole === "Expert" ? "expert" : roleState?.activeRole === "User" ? "user" : "expert";
  return useQuery<IUserProgram, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["program"]>>(
    programsQueryKeys.program(currentRole, programId),
    fetchProgram,
    {
      staleTime: DURATIONS.fifteenMins,

      suspense: true,
      ...options
    }
  );
};

export const useGetProgramUtilization = <SelectReturnType = IProgramUtilization, ErrorType = unknown>(
  { programId }: { programId: string },
  options?: UseQueryOptions<IProgramUtilization, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programUtilization"]>>
) => {
  return useQuery<IProgramUtilization, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programUtilization"]>>(
    programsQueryKeys.programUtilization(programId),
    programUtilization,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};

export const useGetExternalCoachingTopics = <SelectReturnType = string[], ErrorType = unknown>(
  options?: UseQueryOptions<string[], ErrorType, SelectReturnType>
) => {
  return useQuery<string[], ErrorType, SelectReturnType>([{ scope: "external coaching topics" }], getExternalCoachingTopics, {
    staleTime: DURATIONS.fifteenMins,

    suspense: true,
    ...options
  });
};

export const useGetProgramExpertMatches = <SelectReturnType = IMatch[], ErrorType = unknown>(
  programId?: string,
  options?: UseQueryOptions<IMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programExpertMatches"]>>
) => {
  return useQuery<IMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programExpertMatches"]>>(
    programsQueryKeys.programExpertMatches(programId),
    getProgramExpertMatches,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};

export const useGetProgramOTFExpertMatches = <SelectReturnType = IMatch[], ErrorType = unknown>(
  programId?: string,
  options?: UseQueryOptions<IMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programOTFExpertMatches"]>>
) => {
  return useQuery<IMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programOTFExpertMatches"]>>(
    programsQueryKeys.programOTFExpertMatches(programId),
    getProgramOTFExpertMatches,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};

export const useGetAllExpertMatches = <SelectReturnType = GroupedMatch[], ErrorType = unknown>(
  options?: UseQueryOptions<GroupedMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["allProgramsExpertMatches"]>>
) => {
  return useQuery<GroupedMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["allProgramsExpertMatches"]>>(
    programsQueryKeys.allProgramsExpertMatches(),
    getAllExpertMatches,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};
export const useGetProgramMatchRequests = <SelectReturnType = IConnectionRequest[], ErrorType = unknown>(
  { programId }: { programId?: string },
  options?: UseQueryOptions<IConnectionRequest[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programMatchRequests"]>>
) => {
  return useQuery<IConnectionRequest[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["programMatchRequests"]>>(
    programsQueryKeys.programMatchRequests(programId),
    getProgramMatchRequests,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};

export const useGetAvailableExperts = <SelectReturnType = IUserProfileWithAvailability[], ErrorType = unknown>(
  { programId }: { programId?: string },
  options?: UseQueryOptions<
    IUserProfileWithAvailability[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof programsQueryKeys["programAvailableExperts"]>
  >
) => {
  return useQuery<
    IUserProfileWithAvailability[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof programsQueryKeys["programAvailableExperts"]>
  >(programsQueryKeys.programAvailableExperts(programId), getProgramAvailableExperts, {
    staleTime: DURATIONS.fifteenMins,
    suspense: true,
    ...options
  });
};

export const useGetFavoriteProgramMatches = <SelectReturnType = IFavoriteMatch[], ErrorType = unknown>(
  { programId }: { programId?: string },
  options?: UseQueryOptions<IFavoriteMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["favoriteProgramMatch"]>>
) => {
  return useQuery<IFavoriteMatch[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["favoriteProgramMatch"]>>(
    programsQueryKeys.favoriteProgramMatch(programId),
    getProgramFavoriteMatches,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};

export const useGetHRProgram = <SelectReturnType = IProgram, ErrorType = unknown>(
  { programId }: { programId?: string },
  options?: UseQueryOptions<IProgram, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["HrProgram"]>>
) => {
  return useQuery<IProgram, ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["HrProgram"]>>(
    programsQueryKeys.HrProgram({ programId }),
    fetchHrProgram,
    {
      staleTime: DURATIONS.fifteenMins,

      suspense: true,
      ...options
    }
  );
};

interface IParticipantQueryRequest {
  participantId: string;
  userType: "user" | "expert";
  programId?: string;
}
export const useGetParticipantRequests = <SelectReturnType = IConnectionRequest[], ErrorType = unknown>(
  request: IParticipantQueryRequest,
  options?: UseQueryOptions<
    IConnectionRequest[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof programsQueryKeys["participantConnectionRequests"]>
  >
) => {
  return useQuery<IConnectionRequest[], ErrorType, SelectReturnType, ReturnType<typeof programsQueryKeys["participantConnectionRequests"]>>(
    programsQueryKeys.participantConnectionRequests(request),
    fetchParticipantsRequests,
    {
      staleTime: DURATIONS.fifteenMins,

      suspense: true,
      ...options
    }
  );
};
