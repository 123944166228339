import { SVGComponentProps } from "./SVG";

const LearningIcon = ({ fill, size, ...props }: SVGComponentProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.655,8.571h-3.5a.585.585,0,0,0-.583.583V21.988a.585.585,0,0,0,.583.583h3.5a.585.585,0,0,0,.583-.583V9.155a.585.585,0,0,0-.583-.583Zm-.583,3.5H3.738V10.9H6.071v1.167Zm6.417-3.5h-3.5a.585.585,0,0,0-.583.583V21.988a.585.585,0,0,0,.583.583h3.5a.585.585,0,0,0,.583-.583V9.155a.585.585,0,0,0-.583-.583Zm-.583,3.5H9.571V10.9H11.9Zm4.614-2.6-3.126,1.575a.585.585,0,0,0-.258.783l5.25,10.419a.585.585,0,0,0,.783.258l3.126-1.575a.585.585,0,0,0,.258-.783L17.3,9.732a.585.585,0,0,0-.783-.258Zm2.97,12.515A.583.583,0,1,1,18.9,21.4.583.583,0,0,1,19.488,21.988Z"
        transform="translate(-2.571 -8.571)"
        fill={fill || "currentColor"}
      />
    </svg>
  );
};

export default LearningIcon;
