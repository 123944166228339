import { Box, Stack, Typography } from "knack-ui";
import TFA from "../../../components/Icons/TFA";

interface IAccountLockDialogProps {
  email?: string;
}

const AccountLockDialog = ({ email }: IAccountLockDialogProps) => {
  return (
    <Box
      as={Stack}
      paddingPreset="card"
      direction="column"
      gap={4}
      alignItems="center"
      justifyContent="center"
    >
      <TFA />

      <Typography variant="h5" as="h5">
        Account Lock up{" "}
      </Typography>
      <Typography textAlign="center" color="muted">
        An email has been sent to{" "}
        <span className={`${email ? "text-skin-accent font-bold" : ""}`}>
          {email ? email : "your Email"}
        </span>{" "}
        including instructions on how to reset your password.
      </Typography>
    </Box>
  );
};

export default AccountLockDialog;
