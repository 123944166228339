import { Button, Drawer } from "knack-ui";
import { useState } from "react";
import { PingingDot } from "../../components/PingingDot/PingingDot";
import SVG from "../../components/SVG/SVG";
import { useGetSurveyInvitations } from "../../features/Surveys/surveyQueries";
import SurveyInvitationsDrawer from "../../features/Surveys/ui/SurveyInvitationsDrawer";

const DashboardSurveyInvitations = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { data: invitations } = useGetSurveyInvitations();
  const handleToggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };
  if (invitations && invitations.length === 0) {
    return null;
  }
  return (
    <div className="grid gap-4">
      <Button
        onClick={handleToggleDrawer}
        variant="large"
        dir="ltr"
        startIcon={<SVG name="Surveys" size={25} />}
        kind="tertiary"
      >
        You have {invitations?.length} new survey/s
        <PingingDot end="-4px" top="-4px" />
       
      </Button>

      <Drawer isOpen={drawerOpen} onClose={handleToggleDrawer}>
        <SurveyInvitationsDrawer surveyInvitations={invitations} />
      </Drawer>
    </div>
  );
};

export default DashboardSurveyInvitations;
