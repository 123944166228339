import { Button, Input, Stack, Typography } from "knack-ui";
import moment from "moment";
import React, { useRef } from "react";
import { MEETING_DURATIONS } from "../../../App.constant";
import Divider from "../../../components/Divider/Divider";

interface IProps {
  label?: string;
  setDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEndTime: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStartTime: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  date: string | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  max: string | undefined;
  min: string | undefined;
  duration: number;
}

export const DateTimeSelector = ({
  max,
  min,
  label = "Set a Date & Time",
  setDate,
  setEndTime,
  setStartTime,
  date,
  startTime,
  endTime,
  duration,
  setDuration
}: IProps) => {
  const dateRef = useRef<HTMLInputElement>(null);
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);

  return (
    <Stack direction="column" gap={4} justifyContent="left" className="max-w-96">
      <Typography>{label}</Typography>
      <Stack direction="column" gap={2} justifyContent="right">
        <Stack gap={2} alignItems="center" direction="row">
          <Input
            max={max}
            min={min}
            onClick={() => dateRef?.current?.showPicker()}
            ref={dateRef}
            onChange={(e) => setDate(e.target.value)}
            value={date}
            type="date"
            className="w-60 select-date"
            required
          />
          <Divider vertical />
          <Input
            value={startTime}
            ref={startTimeRef}
            className="select-time"
            type="time"
            onChange={(e) => {
              setStartTime(e.target.value);
              setEndTime(moment(e.target.value, "HH:mm").add(duration, "minutes").format("HH:mm"));
              setDuration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"), "minutes"));
            }}
            required
          />
          <Typography>to</Typography>
          <Input
            ref={endTimeRef}
            value={endTime}
            className="select-time"
            type="time"
            onChange={(e) => {
              setEndTime(e.target.value);
              setDuration(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"), "minutes"));
            }}
            required
          />
        </Stack>

        <Stack gap={1}>
          {MEETING_DURATIONS?.map((s, i) => (
            <Button
              variant="xsmall"
              key={i}
              kind={duration === s.value ? "primary" : "defaultOutline"}
              onClick={() => {
                setDuration(s.value);
                setDate(moment(date).format("YYYY-MM-DD"));
                setStartTime(moment(startTime, "HH:mm").format("HH:mm"));
                setEndTime(moment(startTime, "HH:mm").add(s.value, "minutes").format("HH:mm"));
              }}
            >
              {s.title}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
