/* eslint-disable no-useless-concat */
import { deviceType } from "./utils";
import { ISlackField } from "../features/SlackBugIngest/SlackIngestTypes";
import axios, { AxiosError } from "axios";
import { ServerError } from "../hooks/useAxiosConfig";

export const isServerError = (error: Error): error is ServerError => {
  if (axios.isAxiosError(error)) {
    return true;
  }
  return false;
};

const getGenericFields = () => {
  const device: ISlackField = {
    type: "mrkdwn",
    text: `*Device:*\n ${deviceType()}`
  };
  const date: ISlackField = {
    type: "mrkdwn",
    text: `*When:*\n ${new Date()}`
  };
  const environment: ISlackField = {
    type: "mrkdwn",
    text: `*Environment*` + ":" + " " + "`" + process.env.NODE_ENV + "`"
  };
  const href: ISlackField = {
    type: "mrkdwn",
    text: `*href*` + ":" + " " + "`" + window.location.href + "`"
  };

  return [device, date, environment, href];
};

export const buildGeneralErrorBlocks = (err: Error) => {
  const genericFields = getGenericFields();
  const blocks: ISlackField[] = [...genericFields];
  const errInfo = {
    name: err.name,
    trace: err.stack,
    message: err.message
  };
  Object.entries(errInfo).forEach(([key, value]) => {
    blocks.push({
      type: "mrkdwn",
      text:
        `*${key}*` + ":" + " " + "```" + JSON.stringify(value, null, 4) + "```"
    });
  });
  return blocks;
};
export const buildAxiosErrorBlocks = (err: AxiosError) => {
  const genericFields = getGenericFields();

  const axiosInfo = {
    "API endpoint": `${err.response?.config.baseURL}${err.response?.config.url}`,
    "Error message": err.response?.data,
    headers: err.response?.config.headers,
    params: err.response?.config.params,
    payload: err.response?.config.data
  };
  const blocks: ISlackField[] = [...genericFields];
  Object.entries(axiosInfo).forEach(([key, value]) => {
    blocks.push({
      type: "mrkdwn",
      text:
        `*${key}*` + ":" + " " + "```" + JSON.stringify(value, null, 4) + "```"
    });
  });
  return blocks;
};
