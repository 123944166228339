import { useMutation, useQueryClient } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { TGoal } from "../Goals/goalsTypes";
import { notificationKeys } from "../Notifications/notificationQueries";
import { connectionQueryKeys } from "./connectionsQueries";
import { IConnectionRequest, IConnectionUser } from "./ConnectionsTypes";

async function endRelationship({ _id }: { _id: string }) {
  const res = await apiHttp.get<ServerResponse<IConnectionUser>>("v1/connections/program/" + _id + "/end-relationship");
  return res.data.data;
}

async function updateConnectionStatus({ _id, status }: { _id: string; status: IConnectionRequest["status"] }) {
  const res = await apiHttp.post<ServerResponse<IConnectionUser>>("v1/auth/mentor-requests/_update/" + _id, { status });
  return res.data.data;
}
async function assignNewTaskToConnection({ goalId, task }: { goalId: string; task: string; userId: string }) {
  const res = await apiHttp.post<ServerResponse<TGoal>>(`goals/${goalId}/tasks`, { task });
  return res.data.data;
}

async function deleteConnectionTask({ goalId, taskId }: { goalId: string; taskId: string }) {
  const res = await apiHttp.delete<ServerResponse<TGoal>>(`goals/${goalId}/tasks/${taskId}`);
  return res.data.data;
}

//  Mutations
export const useUpdateConnectionRelationship = () => {
  const queryClient = useQueryClient();
  return useMutation(updateConnectionStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(connectionQueryKeys["all"]);
      queryClient.invalidateQueries(notificationKeys["all"]);
    },
    onError: () => {
      queryClient.invalidateQueries(connectionQueryKeys["all"]);
      queryClient.invalidateQueries(notificationKeys["all"]);
    }
  });
};

export const useEndRelationship = () => {
  const queryClient = useQueryClient();
  return useMutation(endRelationship, {
    onSuccess: () => {
      queryClient.invalidateQueries(connectionQueryKeys["all"]);
    }
  });
};

export const useAssignTaskToConnection = () => {
  const queryClient = useQueryClient();
  return useMutation(assignNewTaskToConnection, {
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries(connectionQueryKeys.connectionGoals(userId));
    }
  });
};

export const useDeleteConnectionTask = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteConnectionTask, {
    onSuccess: () => {
      queryClient.invalidateQueries(connectionQueryKeys["all"]);
    }
  });
};
