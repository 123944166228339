import { Avatar, Button, Popover, Stack, Typography } from "knack-ui";
import { useRef, useState } from "react";
import { useUser } from "../../features/User/userQueries";
import { SidebarState } from "../Layout/AppLayout";
import ProfileMenuPopover from "./ProfileMenuPopover";

const ProfileMenu = () => {
  const { data: user } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const { expanded } = SidebarState();

  return (
    <>
      <Button
        className="cursor-pointer"
        kind="ghost"
        tabIndex={0}
        variant="small"
        onClick={() => setMenuOpen(true)}
        as={Stack}
        gap={2}
        ref={parentRef}
        justifyContent={expanded ? "flex-start" : "center"}
        alignItems="center"
      >
        <Avatar
          className="flex-shrink-0"
          image={user?.profile?.profile_image}
          text={
            (user?.profile?.first_name as string) || (user?.email as string)
          }
        />
        {expanded && (
          <div className="flex-1 overflow-hidden">
            <Typography clamp={1} variant="body2" fontWeight="semibold">{`${
              user?.profile
                ? `${user.profile.first_name} ${user.profile.last_name}`
                : user?.email
            }`}</Typography>
            {user?.organization && (
              <Typography
                clamp={1}
                variant="caption"
                color="muted"
              >{`${user?.organization.name}`}</Typography>
            )}
          </div>
        )}
      </Button>
      <Popover
        animationType="fade-up"
        parentRef={parentRef}
        offset={{ bottom: 10, left: 0 }}
        popoverProps={{
          style: { width: 300 },
          variant: "elevated",
          elevation: 6
        }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <ProfileMenuPopover onClose={() => setMenuOpen(false)} />
      </Popover>
    </>
  );
};

export default ProfileMenu;
