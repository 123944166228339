const Completed = ({ className, size }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size || "70"}
      height={size || "71"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2793 17.3085L18.5335 9.05433L17.1918 7.74183L10.2793 14.6543L6.77933 11.1543L5.46683 12.4668L10.2793 17.3085ZM12.0002 23.6668C10.4057 23.6668 8.89877 23.3606 7.47933 22.7481C6.05988 22.1356 4.8203 21.2995 3.76058 20.2397C2.70086 19.18 1.86475 17.9404 1.25225 16.521C0.639746 15.1015 0.333496 13.5946 0.333496 12.0002C0.333496 10.3863 0.639746 8.86961 1.25225 7.45016C1.86475 6.03072 2.70086 4.796 3.76058 3.746C4.8203 2.696 6.05988 1.86475 7.47933 1.25225C8.89877 0.639746 10.4057 0.333496 12.0002 0.333496C13.614 0.333496 15.1307 0.639746 16.5502 1.25225C17.9696 1.86475 19.2043 2.696 20.2543 3.746C21.3043 4.796 22.1356 6.03072 22.7481 7.45016C23.3606 8.86961 23.6668 10.3863 23.6668 12.0002C23.6668 13.5946 23.3606 15.1015 22.7481 16.521C22.1356 17.9404 21.3043 19.18 20.2543 20.2397C19.2043 21.2995 17.9696 22.1356 16.5502 22.7481C15.1307 23.3606 13.614 23.6668 12.0002 23.6668ZM12.0002 21.9168C14.7613 21.9168 17.1043 20.9495 19.0293 19.0147C20.9543 17.08 21.9168 14.7418 21.9168 12.0002C21.9168 9.23905 20.9543 6.896 19.0293 4.971C17.1043 3.046 14.7613 2.0835 12.0002 2.0835C9.2585 2.0835 6.9203 3.046 4.98558 4.971C3.05086 6.896 2.0835 9.23905 2.0835 12.0002C2.0835 14.7418 3.05086 17.08 4.98558 19.0147C6.9203 20.9495 9.2585 21.9168 12.0002 21.9168Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Completed;
