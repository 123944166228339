import { Avatar, Button, Chip, Stack, Typography } from "knack-ui";
import styled from "styled-components";
import { IProgram } from "../../Programs/ProgramTypes";
import { IConnectionRequest } from "../ConnectionsTypes";

interface IProps {
  request: IConnectionRequest;
  onTakeAction: (action: "approve" | "reject", profile: IConnectionRequest) => void;
  onViewProfile: (c: IConnectionRequest) => void;
  program?: IProgram;
}

const NewConnectionRequestCard = ({ request, onViewProfile, onTakeAction, program }: IProps) => {
  return (
    <Wrapper>
      <Stack gap={4} alignItems="center" className="p-4 border-b bg-paper">
        <Avatar className="flex-shrink-0" image={request.menteeProfile.profile_image} text={request.menteeProfile.first_name} />
        <div className="flex-1">
          <Typography clamp={1} fontWeight="semibold">
            {request.menteeProfile.first_name} {request.menteeProfile.last_name}
          </Typography>
          {program && (
            <Typography clamp={1} variant="caption" color="muted" fontWeight="semibold">
              from program: {program.name}
            </Typography>
          )}
        </div>
        <Button onClick={() => onViewProfile(request)} variant="small">
          View profile
        </Button>
      </Stack>

      <SkillsWrapper>
        <Typography fontWeight="semibold" variant="subtitle2">
          Topic Interests:
        </Typography>
        <SkillsList>
          {request.selectedSkills.map((s) => (
            <Chip as='li' variant='primary' shape="rounded" key={s}>
              {s}
            </Chip>
          ))}
        </SkillsList>
      </SkillsWrapper>

      <Stack>
        <Button variant="large" onClick={() => onTakeAction("approve", request)} shape="square" fullWidth kind="success">
          Accept
        </Button>
        <Button variant="large" onClick={() => onTakeAction("reject", request)} shape="square" fullWidth kind="warning">
          Reject
        </Button>
      </Stack>
    </Wrapper>
  );
};

export default NewConnectionRequestCard;

const Wrapper = styled.div`
  overflow: hidden;
  border: 1px solid var(--color-border);
  border-radius: 8px;
`;

const SkillsWrapper = styled.div`
  border-bottom: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  padding: 16px;

  gap: 8px;
`;
const SkillsList = styled.ul`
  overflow-x: auto;
  padding: 16px;
  margin: -16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
`;
