import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import PersonAvatar from "../Asset/Images/person.svg";

export const AvatarPlaceholderV2 = ({
  classes,
  width = "w-40",
  height = "h-40",
  imageUrl = "",
  isUploading = false,
  nameFallback = "",
  bgColor = "#fff"
}) => {
  const [name, setName] = useState();

  useEffect(() => {
    if (nameFallback !== "" && !nameFallback.includes("undefined")) {
      const splitted = nameFallback.split(" ");
      // console.log(splitted)
      setName(
        `${splitted[0]?.charAt(0) || ""}${
          splitted[1]?.charAt(0) || ""
        }`.toUpperCase()
      );
    }
  }, [nameFallback]);

  return (
    <div
      className={`flex items-center justify-center ${classes} ${width} ${height}`}
    >
      <div className="flex items-center justify-center absolute">
        {" "}
        <ClipLoader loading={isUploading} size={50} />{" "}
      </div>
      {imageUrl !== "" && imageUrl ? (
        <img
          src={imageUrl}
          className={`${width} ${height} border rounded-full shadow-sm object-cover`}
          loading="lazy"
        />
      ) : nameFallback !== "" ? (
        <div
          className={`${width} h-full border rounded-full shadow-sm object-cover text-white bg-skin-fill-accent font-bold flex items-center justify-center fittedText`}
        >
          {" "}
          {name}
        </div>
      ) : (
        <img
          src={PersonAvatar}
          className={`${width} ${height} ${bgColor} border rounded-full shadow-sm object-cover`}
          loading="lazy"
        />
      )}
    </div>
  );
};
