import { endOfDay } from "date-fns/esm";
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import {
  GoalsReportsQuery,
  IGoalsAnalyticsResponse,
  ISessionProgress,
  ISessionsAnalyticsResponse,
  ISessionSatisfactionScoresAnalyticsResponse,
  ISessionStatusesAnalyticsResponse,
  ISessionTimelineAnalyticsResponse,
  ITopicInterests,
  ITopTopicsAnalyticsResponse,
  ITopTopicsGoalsAnalyticsResponse,
  IUserAnalyticsResponse,
  IUserDistributions,
  SessionsReportsQuery,
  TResultOrder,
  UserReportsQuery
} from "./analyticsReportsTypes";
import { QUERY_ENTITIES } from "../../lib/constants";

export const reportsQueryKeys = {
  all: [{ scope: "hr analytics" }] as const,
  sessionsList: ({ query, order }: { query?: SessionsReportsQuery; order: TResultOrder }) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.sessionsList,
        order,

        ...query
      }
    ] as const,
  goalsList: (query: GoalsReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.goalsList,
        ...query
      }
    ] as const,
  topTopics: ({ query }: { query?: SessionsReportsQuery }) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.topTopics,
        ...query
      }
    ] as const,
  goalsTopTopics: ({ query }: { query?: GoalsReportsQuery }) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.goalsTopTopics,
        ...query
      }
    ] as const,
  sessionStatuses: ({ query }: { query?: SessionsReportsQuery }) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.sessionStatuses,
        ...query
      }
    ] as const,
  satisfactionScores: (query?: SessionsReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.satisfactionScores,
        ...query
      }
    ] as const,
  timeline: (query?: SessionsReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.timeline,
        ...query
      }
    ] as const,
  userList: (query: UserReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.userList,
        ...query
      }
    ] as const,
  userDistributions: (query?: UserReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.userDistributions,
        ...query
      }
    ] as const,
  topicInterests: (query: UserReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.topicInterests,
        ...query
      }
    ] as const,
  sessionProgress: (query: UserReportsQuery) =>
    [
      {
        ...reportsQueryKeys.all[0],
        entity: QUERY_ENTITIES.sessionProgress,
        ...query
      }
    ] as const
};

async function fetchSessions({
  queryKey: [{ organization, department, entities, management_level, program, status, date, order, user, expert }],
  pageParam
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["sessionsList"]>>) {
  const res = await apiHttp.get<ServerResponse<ISessionsAnalyticsResponse>>("v2/analytics/sessions", {
    params: {
      page: pageParam,
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          sessionStatus: status.join(",")
        }),
      ...(date &&
        date?.length > 0 &&
        date[0].startDate && {
          startDate: date[0].startDate
        }),
      ...(date &&
        date.length > 0 &&
        date[0].endDate && {
          endDate: endOfDay(date[0].endDate)
        }),
      ...(user &&
        user?.length > 0 && {
          user: user.join(",")
        }),
      ...(expert &&
        expert?.length > 0 && {
          expert: expert.join(",")
        }),
      order
    }
  });
  return res.data.data;
}

async function fetchGoalsTopTopics({
  queryKey: [{ user, department, position, status, topics, userType, organization }]
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["goalsTopTopics"]>>) {
  const res = await apiHttp.get<ServerResponse<ITopTopicsGoalsAnalyticsResponse>>("v2/analytics/goals/topics-aggregate", {
    params: {
      ...(status &&
        status?.length > 0 && {
          status: status.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(position &&
        position?.length > 0 && {
          position: position.join(",")
        }),
      ...(userType &&
        userType?.length > 0 && {
          userType: userType.join(",")
        }),
      ...(topics &&
        topics?.length > 0 && {
          topics: topics.join(",")
        }),
      ...(user &&
        user?.length > 0 && {
          user: user.join(",")
        }),
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        })
    }
  });
  return res.data.data;
}

async function fetchTopTopics({
  queryKey: [{ organization, department, entities, management_level, program, status, date, user, expert }]
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["topTopics"]>>) {
  const res = await apiHttp.get<ServerResponse<ITopTopicsAnalyticsResponse>>("v2/analytics/sessions/top-topics", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          sessionStatus: status.join(",")
        }),
      ...(date &&
        date?.length > 0 &&
        date[0].startDate && {
          startDate: date[0].startDate
        }),
      ...(date &&
        date.length > 0 &&
        date[0].endDate && {
          endDate: endOfDay(date[0].endDate)
        }),
      ...(user &&
        user?.length > 0 && {
          user: user.join(",")
        }),
      ...(expert &&
        expert?.length > 0 && {
          expert: expert.join(",")
        })
    }
  });
  return res.data.data;
}

async function fetchSessionsStatuses({
  queryKey: [{ organization, department, entities, management_level, program, status, date, user, expert }]
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["sessionStatuses"]>>) {
  const res = await apiHttp.get<ServerResponse<ISessionStatusesAnalyticsResponse>>("v2/analytics/sessions/status-counts", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          sessionStatus: status.join(",")
        }),
      ...(date &&
        date?.length > 0 &&
        date[0].startDate && {
          startDate: date[0].startDate
        }),
      ...(date &&
        date.length > 0 &&
        date[0].endDate && {
          endDate: endOfDay(date[0].endDate)
        }),
      ...(user &&
        user?.length > 0 && {
          user: user.join(",")
        }),
      ...(expert &&
        expert?.length > 0 && {
          expert: expert.join(",")
        })
    }
  });
  return res.data.data;
}

async function fetchSessionsSatisfactionScores({
  queryKey: [{ organization, department, entities, management_level, program, status, date, user, expert }]
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["satisfactionScores"]>>) {
  const res = await apiHttp.get<ServerResponse<ISessionSatisfactionScoresAnalyticsResponse>>("v2/analytics/sessions/satisfaction-score", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          sessionStatus: status.join(",")
        }),
      ...(date &&
        date?.length > 0 &&
        date[0].startDate && {
          startDate: date[0].startDate
        }),
      ...(date &&
        date.length > 0 &&
        date[0].endDate && {
          endDate: endOfDay(date[0].endDate)
        }),
      ...(user &&
        user?.length > 0 && {
          user: user.join(",")
        }),
      ...(expert &&
        expert?.length > 0 && {
          expert: expert.join(",")
        })
    }
  });
  return res.data.data;
}

async function fetchSessionsTimeline({
  queryKey: [{ organization, department, entities, management_level, program, status, date, user, expert }]
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["timeline"]>>) {
  const res = await apiHttp.get<ServerResponse<ISessionTimelineAnalyticsResponse>>("v2/analytics/sessions/timeline", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          sessionStatus: status.join(",")
        }),
      ...(date &&
        date?.length > 0 &&
        date[0].startDate && {
          startDate: date[0].startDate
        }),
      ...(date &&
        date.length > 0 &&
        date[0].endDate && {
          endDate: endOfDay(date[0].endDate)
        }),
      ...(user &&
        user?.length > 0 && {
          user: user.join(",")
        }),
      ...(expert &&
        expert?.length > 0 && {
          expert: expert.join(",")
        })
    }
  });
  return res.data.data;
}

async function fetchUsers({
  queryKey: [
    {
      organization,
      userStatus,
      user,
      expert,
      position,
      program,
      userClass,
      department,
      entities,
      management_level,
      search_by_email_or_name,
      limit
      // userType
    }
  ],
  pageParam = 1
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["userList"]>>) {
  const params: Record<string, any> = {
    page: pageParam,
    organization: organization?.join(","),
    program: program?.join(","),
    userClass: userClass?.join(","),
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    position: position?.join(","),
    search_by_email_or_name,
    limit,
    user: user?.join(","),
    expert: expert?.join(","),
    userStatus: userStatus?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponse<IUserAnalyticsResponse>>("v2/analytics/users", {
    params
  });
  return res.data.data;
}

async function fetchGoals({
  queryKey: [{ status, department, position, userType, topics, order, search_by_email_or_name, user, organization }],
  pageParam = 1
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["goalsList"]>>) {
  const params: Record<string, any> = {
    page: pageParam,
    status: status?.join(","),
    department: department?.join(","),
    position: position?.join(","),
    userType: userType?.join(","),
    topics: topics?.join(","),
    order,
    search_by_email_or_name,
    user: user?.join(","),
    organization: organization?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponse<IGoalsAnalyticsResponse>>("v2/analytics/goals", {
    params
  });
  return res.data.data;
}

async function fetchUserDistributions({
  queryKey: [
    {
      organization,
      userStatus,
      user,
      expert,
      position,
      program,
      userClass,
      department,
      entities,
      management_level,
      search_by_email_or_name
    }
  ],
  pageParam
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["userDistributions"]>>) {
  const params: Record<string, any> = {
    page: pageParam,
    organization: organization?.join(","),
    program: program?.join(","),
    userClass: userClass?.join(","),
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    search_by_email_or_name,
    position: position?.join(","),
    user: user?.join(","),
    expert: expert?.join(","),
    userStatus: userStatus?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponse<IUserDistributions>>("v2/analytics/users/distribution", {
    params
  });

  return res.data.data;
}

async function fetchTopicInterests({
  queryKey: [
    {
      organization,
      userStatus,
      user,
      expert,
      position,
      program,
      userClass,
      department,
      entities,
      management_level,
      search_by_email_or_name
    }
  ],
  pageParam
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["topicInterests"]>>) {
  const params: Record<string, any> = {
    page: pageParam,
    organization: organization?.join(","),
    program: program?.join(","),
    userClass: userClass?.join(","),
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    search_by_email_or_name,
    position: position?.join(","),
    user: user?.join(","),
    expert: expert?.join(","),
    userStatus: userStatus?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponse<ITopicInterests[]>>("v2/analytics/users/topic-interests", {
    params
  });

  return res.data.data;
}

async function fetchSessionProgress({
  queryKey: [
    {
      organization,
      userStatus,
      user,
      expert,
      position,
      program,
      userClass,
      department,
      entities,
      management_level,
      search_by_email_or_name
    }
  ],
  pageParam
}: QueryFunctionContext<ReturnType<typeof reportsQueryKeys["sessionProgress"]>>) {
  const params: Record<string, any> = {
    page: pageParam,
    organization: organization?.join(","),
    program: program?.join(","),
    userClass: userClass?.join(","),
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    search_by_email_or_name,
    position: position?.join(","),
    user: user?.join(","),
    expert: expert?.join(","),
    userStatus: userStatus?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponse<ISessionProgress>>("v2/analytics/users/session-progress", {
    params
  });

  return res.data.data;
}

export const useGetAnalyticsSessionList = <SelectData = ISessionsAnalyticsResponse, Error = unknown>(
  { query, order }: { query?: SessionsReportsQuery; order: "asc" | "desc" },
  options?: UseInfiniteQueryOptions<
    ISessionsAnalyticsResponse,
    Error,
    SelectData,
    ISessionsAnalyticsResponse,
    ReturnType<typeof reportsQueryKeys["sessionsList"]>
  >
) => {
  return useInfiniteQuery<ISessionsAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["sessionsList"]>>(
    reportsQueryKeys.sessionsList({ query, order }),
    fetchSessions,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.currentPage < lastPage.totalPages) {
          return +lastPage.currentPage + 1;
        }
        return undefined;
      },
      ...options
    }
  );
};

export const useGetGoalsAnalyticsTopTopics = <SelectData = ITopTopicsGoalsAnalyticsResponse, Error = unknown>(
  query?: GoalsReportsQuery,
  options?: UseQueryOptions<ITopTopicsGoalsAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["goalsTopTopics"]>>
) => {
  return useQuery<ITopTopicsGoalsAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["goalsTopTopics"]>>(
    reportsQueryKeys.goalsTopTopics({ query }),
    fetchGoalsTopTopics,
    {
      ...options
    }
  );
};

export const useGetAnalyticsTopTopics = <SelectData = ITopTopicsAnalyticsResponse, Error = unknown>(
  query?: SessionsReportsQuery,
  options?: UseQueryOptions<ITopTopicsAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["topTopics"]>>
) => {
  return useQuery<ITopTopicsAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["topTopics"]>>(
    reportsQueryKeys.topTopics({ query }),
    fetchTopTopics,
    {
      ...options
    }
  );
};

export const useGetAnalyticsSessionsStatuses = <SelectData = ISessionStatusesAnalyticsResponse, Error = unknown>(
  query?: SessionsReportsQuery,
  options?: UseQueryOptions<ISessionStatusesAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["sessionStatuses"]>>
) => {
  return useQuery<ISessionStatusesAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["sessionStatuses"]>>(
    reportsQueryKeys.sessionStatuses({ query }),
    fetchSessionsStatuses,
    {
      ...options
    }
  );
};

export const useGetAnalyticsSessionsSatisfactionScores = <SelectData = ISessionSatisfactionScoresAnalyticsResponse, Error = unknown>(
  query?: SessionsReportsQuery,
  options?: UseQueryOptions<
    ISessionSatisfactionScoresAnalyticsResponse,
    Error,
    SelectData,
    ReturnType<typeof reportsQueryKeys["satisfactionScores"]>
  >
) => {
  return useQuery<
    ISessionSatisfactionScoresAnalyticsResponse,
    Error,
    SelectData,
    ReturnType<typeof reportsQueryKeys["satisfactionScores"]>
  >(reportsQueryKeys.satisfactionScores(query), fetchSessionsSatisfactionScores, {
    ...options
  });
};

export const useGetAnalyticsSessionsTimelines = <SelectData = ISessionTimelineAnalyticsResponse, Error = unknown>(
  query?: SessionsReportsQuery,
  options?: UseQueryOptions<ISessionTimelineAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["timeline"]>>
) => {
  return useQuery<ISessionTimelineAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["timeline"]>>(
    reportsQueryKeys.timeline(query),
    fetchSessionsTimeline,
    {
      ...options
    }
  );
};

export const useGetUserDistribution = <SelectData = IUserDistributions, Error = unknown>(
  query?: SessionsReportsQuery,
  options?: UseQueryOptions<IUserDistributions, Error, SelectData, ReturnType<typeof reportsQueryKeys["userDistributions"]>>
) => {
  return useQuery<IUserDistributions, Error, SelectData, ReturnType<typeof reportsQueryKeys["userDistributions"]>>(
    reportsQueryKeys.userDistributions(query),
    fetchUserDistributions,
    {
      ...options
    }
  );
};

export const useGetTopicInterests = <SelectData = ITopicInterests[], Error = unknown>(
  query: SessionsReportsQuery,
  options?: UseQueryOptions<ITopicInterests[], Error, SelectData, ReturnType<typeof reportsQueryKeys["topicInterests"]>>
) => {
  return useQuery<ITopicInterests[], Error, SelectData, ReturnType<typeof reportsQueryKeys["topicInterests"]>>(
    reportsQueryKeys.topicInterests(query),
    fetchTopicInterests,
    {
      ...options
    }
  );
};

export const useGetSessionProgress = <SelectData = ISessionProgress, Error = unknown>(
  query: SessionsReportsQuery,
  options?: UseQueryOptions<ISessionProgress, Error, SelectData, ReturnType<typeof reportsQueryKeys["sessionProgress"]>>
) => {
  return useQuery<ISessionProgress, Error, SelectData, ReturnType<typeof reportsQueryKeys["sessionProgress"]>>(
    reportsQueryKeys.sessionProgress(query),
    fetchSessionProgress,
    {
      ...options
    }
  );
};

export const useGetUsers = <SelectData = IUserAnalyticsResponse, Error = unknown>(
  query: UserReportsQuery,
  options?: UseInfiniteQueryOptions<
    IUserAnalyticsResponse,
    Error,
    SelectData,
    IUserAnalyticsResponse,
    ReturnType<typeof reportsQueryKeys["userList"]>
  >
) => {
  return useInfiniteQuery<IUserAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["userList"]>>(
    reportsQueryKeys.userList(query),
    fetchUsers,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.currentPage < lastPage.totalPages) {
          return +lastPage.currentPage + 1;
        }
        return undefined;
      },
      ...options
    }
  );
};

export const useGetGoals = <SelectData = IGoalsAnalyticsResponse, Error = unknown>(
  query: GoalsReportsQuery,
  options?: UseInfiniteQueryOptions<
    IGoalsAnalyticsResponse,
    Error,
    SelectData,
    IGoalsAnalyticsResponse,
    ReturnType<typeof reportsQueryKeys["goalsList"]>
  >
) => {
  return useInfiniteQuery<IGoalsAnalyticsResponse, Error, SelectData, ReturnType<typeof reportsQueryKeys["goalsList"]>>(
    reportsQueryKeys.goalsList(query),
    fetchGoals,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.currentPage < lastPage.totalPages) {
          return +lastPage.currentPage + 1;
        }
        return undefined;
      },
      ...options
    }
  );
};
