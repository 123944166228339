import { Avatar, Box, Breadcrumbs, Button, Input, Popover, Stack, Typography } from "knack-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import FiltersBar from "../../../components/FiltersBar";
import { ActiveUserListMetrics } from "../components/ActiveUserListMetrics";
import { useActiveUserFilters } from "../hooks/ActiveUser.hooks";
import { useGetActiveUsers, useGetLoginActivity } from "../activeUserListQueries";
import { IActiveUserMetrics, IActiveUserQuery, IUserLog } from "../activeUserListTypes";
// import moment from "moment";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { DURATIONS } from "../../../lib/constants";
import AttributeSelector from "../../../components/Selector/AttributeSelector";
import { PaginationState, RowSelectionState } from "@tanstack/react-table";
import { useGenerateActiveUserReport } from "../activeUserMutations";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { Information } from "../../../components/ToastNotification/Information";
import { AiOutlineSearch } from "react-icons/ai";
import SVG from "../../../components/SVG/SVG";
import Table from "../../../components/Table";
import { format, startOfMonth, endOfMonth } from "date-fns/esm";
import Chart from "../../../components/Charts/Chart";
import { resizeObserver } from "../../../lib/utils";
import { Link } from "react-router-dom";

const date = new Date();

const ActiveUsers = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const { setLoading } = useLoadingSpinner();
  const { mutateAsync: generateReport } = useGenerateActiveUserReport();
  const [exportAttributes, setExportAttribute] = useState<Array<keyof IUserLog>>();
  const popoverRefEl = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const headerCheckboxRef = useRef<HTMLInputElement | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  // const startDate = moment().startOf("month").toDate();
  // const endDate = moment().endOf("month").toDate();

  const [query, setQuery] = useState<IActiveUserQuery>({
    dateRange: [
      {
        startDate: startOfMonth(date),
        endDate: endOfMonth(date),
        key: "selection"
      }
    ],
    organization: [],
    userType: [],
    programType: []
  });

  const filters = useActiveUserFilters({
    query,
    onQueryChange: setQuery
  });

  const { data, hasNextPage, fetchNextPage, fetchPreviousPage, isFetchingNextPage } = useGetActiveUsers(
    {
      ...query,
      search_by_email_or_name: searchValue
    },
    {
      staleTime: DURATIONS.fifteenMins
    }
  );

  const metrics = useMemo(() => {
    const _metrics: IActiveUserMetrics = {
      total: 0,
      active: 0
    };

    if (data) {
      const metrics_response = data.pages[0].metrics;
      _metrics.total = metrics_response.total;
      _metrics.active = metrics_response.active;
    }

    return _metrics;
  }, [data]);

  const { data: loginActivity } = useGetLoginActivity(query, {
    enabled: Boolean(searchValue || query),
    staleTime: DURATIONS.fifteenMins
  });

  const flatSelectedRows = useMemo(() => {
    const selectedIndexes = Object.keys(rowSelection).map((k) => k);
    const allData = data?.pages?.flatMap((p) => p.logs);
    const selectedData: IUserLog[] = [];

    allData?.forEach((d, i) => {
      if (selectedIndexes.includes(i.toString())) {
        selectedData.push(d);
      }
    });
    return selectedData;
  }, [data, rowSelection]);

  async function onExportCSV(exportAttributes: Array<keyof IUserLog>) {
    setLoading(true);
    try {
      await generateReport({ exportAttributes, query });
      setMenuOpen(false);
      setLoading(false);
      Information("A CSV report has been sent to your email");
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
  }, [filters, pageSize]);

  useEffect(() => {
    if (data && !data?.pages[pageIndex] && !isFetchingNextPage) {
      setPagination({ pageIndex: 0, pageSize });
    }
  }, [isFetchingNextPage, pageIndex, pageSize, data]);

  const loginActivityChartOptions = useMemo(() => {
    if (!loginActivity) return null;
    const data = loginActivity.allLogs.map((t) => [startOfMonth(new Date(t.date)), t.count]);

    return {
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          return `
            Activity in ${format(new Date(params[0].axisValueLabel), "MMMM - yyyy")}
            <br />
            ${params[0].marker} Number of ${params[0].seriesName}: ${params[0].data[1]}
          `;
        }
      },
      xAxis: {
        type: "category",
        axisLabel: {
          formatter: (value: string) => {
            const formattedDate = format(new Date(value), "MMM - yyyy");
            return formattedDate;
          },
          hideOverlap: true,
          interval: 0
        },
        axisPointer: {
          type: "shadow"
        }
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"]
      },
      dataZoom: [
        {
          type: "inside",
          start: 50,
          end: 100
        },
        { type: "slider", start: 0, end: 100 }
      ],
      grid: {
        left: "0%",
        right: "0%",
        bottom: "50px",
        top: "20px",
        containLabel: true
      },

      series: [
        {
          name: "Active users",
          data: data,
          type: "bar"
          // smooth: true
        },
        {
          name: "Active users",
          data: data,
          type: "line",
          smooth: true,
          color: "orange"
        }
      ]
    };
  }, [loginActivity]);

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Active users report</Typography>]}</Breadcrumbs>
      </Box>
      <Stack direction="column" gap={4}>
        <Box className="px-6 py-4 border">
          <Stack direction="column" gap={4}>
            <Typography as="h5" variant="h6">
              Active Users
            </Typography>
            <Stack gap={4} direction="column">
              <Typography fontWeight="normal">Filters</Typography>
              <FiltersBar filters={filters} query={query} />
            </Stack>
          </Stack>
        </Box>

        <Box className="grid grid-cols-1 gap-6 px-6 py-4 border">
          <ActiveUserListMetrics total={metrics.total} active={metrics.active} />

          <TitledDisplayBox title="Login activity by month">
            {loginActivity?.allLogs.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Smile" />}
            {loginActivity && loginActivity.allLogs.length > 0 && (
              <Chart options={loginActivityChartOptions as any} resizeObserver={resizeObserver} />
            )}
          </TitledDisplayBox>

          <>
            <Stack direction="column" gap={4}>
              <Stack justifyContent="space-between" alignItems="center">
                <Typography fontWeight="semibold">Active Users</Typography>
                <Stack gap={4} direction="row" alignItems="center">
                  <Input
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    className="w-72"
                    inputPrefix={<AiOutlineSearch size={20} />}
                    inputSuffix={
                      searchValue !== "" ? (
                        <Button onClick={() => setSearchValue("")} iconOnly kind="ghost" variant="small">
                          <SVG name="Close" />
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                    placeholder="Search by name or email"
                  />

                  <Stack gap={4} direction="row" alignItems="center">
                    <Button ref={popoverRefEl} onClick={() => setMenuOpen(true)}>
                      Export
                    </Button>
                  </Stack>
                </Stack>
              </Stack>

              <div>
                {data && data?.pages?.length === 0 && <EmptyState iconSize={70} title="No logs found" icon="SessionsEmptyState" />}
                {data && data?.pages?.length > 0 && (
                  <Table
                    rowSelection={rowSelection}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    setRowSelection={setRowSelection}
                    initialSorting={[{ id: "start_time", desc: true }]}
                    paginationProps={{
                      totalPages: data?.pages[pageIndex]?.totalPages,
                      canGetNextPage: hasNextPage,
                      onNextPage: async (newPage) => {
                        if (!data?.pages[newPage - 1]) {
                          fetchNextPage();
                        }
                      },
                      onPreviousPage: async (newPage) => {
                        if (!data?.pages[newPage - 1]) {
                          fetchPreviousPage();
                        }
                      }
                    }}
                    data={data?.pages.flatMap((p) => p.logs)}
                    columns={(helper) => [
                      // helper.display({
                      //   header: ({ table }) => {
                      //     return (
                      //       <Checkbox
                      //         {...{
                      //           ref: headerCheckboxRef,
                      //           checked: table.getIsAllRowsSelected(),
                      //           indeterminate: table.getIsSomeRowsSelected().toString(),
                      //           onChange: table.getToggleAllRowsSelectedHandler()
                      //         }}
                      //       />
                      //     );
                      //   },
                      //   cell: ({ row }) => (
                      //     <Checkbox
                      //       {...{
                      //         checked: row.getIsSelected(),
                      //         indeterminate: row.getIsSomeSelected().toString(),
                      //         onChange: row.getToggleSelectedHandler()
                      //       }}
                      //     />
                      //   ),
                      //   id: "selection",
                      //   size: 40
                      // }),
                      helper.accessor((row) => (row.user_first_name ? `${row.user_first_name} ${row.user_last_name}` : row.user_email), {
                        header: "User",
                        cell: ({ row, getValue }) => {
                          const name = getValue();
                          return (
                            <div className="flex items-center gap-2 font-bold">
                              <Avatar size="small" className="flex-shrink-0" text={name} image={row.original.user_profile_image} />
                              {name}
                            </div>
                          );
                        }
                      }),
                      helper.accessor((row) => `${row.organization_name}`, {
                        header: "Organization",
                        cell: ({ row, getValue }) => {
                          const name = getValue();
                          return (
                            <div className="flex items-center gap-2">
                              <Avatar size="small" className="flex-shrink-0" text={name} image={row.original.organization_logo} />
                              {name}
                            </div>
                          );
                        }
                      }),
                      helper.accessor("user_department", {
                        header: "Management Level"
                      }),
                      helper.accessor("user_management_level", {
                        header: "Department"
                      }),
                      helper.accessor("login_count", {
                        header: "Login Count"
                      }),
                      helper.accessor("log_date", {
                        header: "Login Month",
                        cell: ({ getValue }) => (getValue() ? format(new Date(getValue()), "MMMM") : ""),
                        maxSize: 135
                      })
                    ]}
                  />
                )}
              </div>
            </Stack>

            <Popover
              animationType="fade"
              parentRef={popoverRefEl}
              offset={{ bottom: 10, left: 0 }}
              popoverProps={{
                // style: { maxWidth: 500 },
                variant: "elevated",
                elevation: 6
              }}
              isOpen={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <AttributeSelector<IUserLog>
                attributeList={[
                  {
                    accessorKey: "user_email",
                    label: "Email"
                  },
                  {
                    accessorKey: "user_first_name",
                    label: "First Name"
                  },
                  {
                    accessorKey: "user_last_name",
                    label: "Last Name"
                  },
                  {
                    accessorKey: "organization_name",
                    label: "Organization"
                  },
                  {
                    accessorKey: "user_management_level",
                    label: "Management Level"
                  },
                  {
                    accessorKey: "user_department",
                    label: "Department"
                  },
                  {
                    accessorKey: "login_count",
                    label: "Login Count"
                  },
                  {
                    accessorKey: "log_date",
                    label: "Login Month"
                  }
                ]}
                onChange={(attributes) => {
                  setExportAttribute(attributes);
                }}
                onExportCSV={onExportCSV}
              />
            </Popover>
          </>
        </Box>
      </Stack>
    </div>
  );
};

export default ActiveUsers;
