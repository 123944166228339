import React from "react";
import Question from "./question";
import AnswerOption from "./answerOption";
import { useTranslation } from "react-i18next";

function Quiz(props) {
  const { t } = useTranslation(["assessments", "360"]);
  function renderAnswerOptions(key) {
    return (
      <AnswerOption
        key={t(key.option_value, { ns: props.is360 ? "360" : "assessments" })}
        answerContent={t(key.option_text)}
        answerType={key.option_value}
        answer={props.answer}
        questionId={props.questionId}
        onAnswerSelected={props.onAnswerSelected}
      />
    );
  }

  return (
    <div className="w-full overflow-hidden quiz">
      <p className="py-2 text-sm font-medium text-skin-inverted">{t`in-the-last-3-months`}</p>
      <Question content={props.question} />
      <ul className="answerOptions">
        {props.answerOptions.map(renderAnswerOptions)}
      </ul>
    </div>
  );
}


export default Quiz;
