import React, { useRef } from "react";
import { StaticDialog, useDialog } from "react-st-modal";
import Select from "react-select";

export const CreateCoach = ({
  newUser,
  setNewUser,
  teams,
  companies,
  setOpen,
  isOpen,
  onCreateUser
}) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  function willCreateUser() {
    if (
      newUser.first_name !== "" &&
      newUser.last_name !== "" &&
      newUser.email !== "" &&
      newUser.password !== ""
    ) {
      onCreateUser();
      try {
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        emailRef.current.value = "";
        passwordRef.current.value = "";
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="text-skin-base">
      <StaticDialog
        className="SessionRatingModal CreateUserModal scrollhost"
        isOpen={isOpen}
        onAfterClose={() => {
          setOpen(false);
          setNewUser({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            kind: "COACH"
          });
        }}
      >
        <div className="p-4">
          <div className="font-bold text-xl">
            {newUser._id ? "Edit Coach" : "Create Coach"}
          </div>

          <div className="mt-4">
            {/* First Name */}
            <label>
              <div className="text-sm">First Name</div>
              <input
                ref={firstNameRef}
                defaultValue={newUser.first_name}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, first_name: e.target.value }))
                }
                type="text"
                placeholder="i.e. John"
                className={`rounded-lg`}
              />
            </label>
            {/* last name */}
            <label className="mt-4 block">
              <div className="text-sm">Last Name</div>
              <input
                ref={lastNameRef}
                defaultValue={newUser.last_name}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, last_name: e.target.value }))
                }
                type="text"
                placeholder="i.e. Doe"
                className={`rounded-lg`}
              />
            </label>
            {/* Email */}
            <label className="mt-4 block">
              <div className="text-sm">Email</div>
              <input
                ref={emailRef}
                disabled={newUser._id ? true : false}
                defaultValue={newUser.email}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, email: e.target.value }))
                }
                type="email"
                placeholder="user@gmail.com"
                className={`rounded-lg ${newUser._id ? "bg-gray-300" : ""}`}
              />
            </label>

            {/* Password */}
            {!newUser._id && (
              <label className="mt-4 block">
                <div className="text-sm">Password</div>
                <input
                  ref={passwordRef}
                  onChange={(e) =>
                    setNewUser((p) => ({ ...p, password: e.target.value }))
                  }
                  type=""
                  placeholder="*******"
                  className="rounded-lg"
                />
              </label>
            )}
          </div>

          {/* CTA */}
          <div className="flex items-center justify-end mt-16">
            <div
              className={`font-semibold text-base p-2 cursor-pointer px-4 rounded-lg ${
                newUser.email !== "" && newUser.password !== ""
                  ? "bg-skin-fill text-white"
                  : "bg-gray-300"
              }`}
              onClick={() =>
                newUser.email !== "" && newUser.password !== ""
                  ? willCreateUser()
                  : null
              }
            >
              {newUser?._id ? "Update" : "Create"}
            </div>
            <div
              className="font-semibold text-base p-2 cursor-pointer px-4"
              onClick={() => setOpen()}
            >
              Cancel
            </div>
          </div>
        </div>
      </StaticDialog>
    </div>
  );
};
