import { Suspense, useEffect, useState } from "react";
import { MentoringAboutProfile } from "../MentoringAboutProfile";
import UserDrawerHeader from "./UserDrawerHeader";
import { MentoringInsights } from "../MentoringInsights";
import CoachingInsights from "../CoachingInsights";
import PageSkeleton from "../../skeletons/PageSkeleton";
import { useGetConnectionProfile } from "../../../features/Connections/connectionsQueries";
import { UserTypes } from "../../../lib/constants";
import SVG from "../../SVG/SVG";
import { KnackSpinner } from "../../Asset/KnackSpinner";
import { Stack, Typography } from "knack-ui";
import { IProgramInvite } from "../../../features/Programs/ProgramTypes";

interface IProps {
  userId: string;
  setUserId?: (id: string) => void;
  program?: string;
  admin?: boolean;
  programInvite?: IProgramInvite;
}

const UserDrawer = ({ userId, program, admin, setUserId, programInvite }: IProps) => {
  const [roleTabs, setRoleTabs] = useState([
    {
      name: "Profile",
      icon: <SVG name="User" />
    }
  ]);
  const [tab, setTab] = useState("Profile");
  const { data: user, isLoading } = useGetConnectionProfile({ id: userId }, { suspense: false });

  useEffect(() => {
    if (user) {
      const initialTabs: {
        name: string;
        icon: JSX.Element;
      }[] = [];
      const currentUserRoles = user?.user?.roles || [];
      if (currentUserRoles?.includes(UserTypes["COACH"]) || currentUserRoles?.includes(UserTypes["COACHEE"])) {
        initialTabs.push({
          name: "Coaching Insights",
          icon: <SVG name="Calendar" />
        });
      }
      if (currentUserRoles?.includes(UserTypes["MENTOR"]) || currentUserRoles?.includes(UserTypes["MENTEE"])) {
        initialTabs.push({
          name: "Mentoring Insights",
          icon: <SVG name="Calendar" />
        });
      }

      if (programInvite && programInvite.questionsForExperts?.length > 0) {
        initialTabs.push({
          name: "Expertise Details",
          icon: <SVG name="Program" />
        });
      }

      setTab("Profile");

      setRoleTabs([
        {
          name: "Profile",
          icon: <SVG name="User" />
        },
        ...initialTabs
      ]);
    }
  }, [user, programInvite]);

  return (
    <section className="h-full">
      <div className="flex max-w-full max-h-full overflow-y-scroll transition-all duration-150 ease-in-out delay-150 bg-white scrollhost2">
        <div className="relative w-screen max-w-4xl">
          {isLoading && <KnackSpinner />}
          {!isLoading && !user && <div className="flex items-center justify-center h-screen font-bold">This user has no profile</div>}
          {!isLoading && user && <UserDrawerHeader admin={admin} user={user} />}

          {!isLoading && user && (
            <div className="text-skin-base">
              {/* Tabs & Filter */}
              <div className="flex flex-row items-center justify-between">
                {/* Tabs */}
                <div className="flex flex-row justify-between w-full overflow-x-auto text-xs capitalize border-b border-gray-200 sm:justify-start">
                  {roleTabs?.map((t, i) => (
                    <div
                      onClick={() => setTab(t.name)}
                      key={i}
                      className={`flex flex-row items-center px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-skin-light transition duration-75 ease-in-out text-skin-muted ${
                        t.name === tab && "border-b-2 border-skin-base text-skin-inverted"
                      } `}
                    >
                      {t?.icon && <div className="mr-2">{t?.icon} </div>}
                      <div>{t?.name} </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {!isLoading && user && (
            <div className="p-4">
              <Suspense fallback={<PageSkeleton height="300px" />}>
                {tab === "Profile" && (
                  <MentoringAboutProfile
                    job_function={user?.job_function}
                    position={user?.position}
                    management_level={user?.management_level}
                    entity={user?.entity}
                    capacity={user?.capacity}
                    email={user?.user?.email}
                    phone={user?.whatsapp_number}
                    roles={user?.user?.roles}
                    isKnackCoach={user?.user?.account_type === "Coach"}
                    about={user?.about}
                    experience={user?.experience}
                  />
                )}
                {tab === "Mentoring Insights" && (user?.user.roles?.includes("mentee") || user.user.roles?.includes("mentor")) && (
                  <MentoringInsights user={user} program={program} setUserId={setUserId} />
                )}
                {tab === "Coaching Insights" &&
                  (user?.user.roles?.includes("coachee") || user.user.roles?.includes("coach") || Boolean(user.user.assigned_coach)) && (
                    <CoachingInsights user={user} program={program} setUserId={setUserId} />
                  )}

                {programInvite && tab === "Expertise Details" && (
                  <Stack direction="column">
                    <Stack direction="column" gap={4} className="overflow-y-auto scrollhost2">
                      {programInvite?.questionsForExperts.map((question, index) => (
                        <Stack key={index} direction="column" gap={2}>
                          <Typography fontWeight="bold" className="text-lg">
                            {question.question}
                          </Typography>
                          <Typography>{question.response}</Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default UserDrawer;
