const CalendarBigIcon = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || "94"}
      height={size || "80"}
      viewBox="0 0 94 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7825 70.565C62.7832 70.565 76.565 56.7832 76.565 39.7825C76.565 22.7818 62.7832 9 45.7825 9C28.7818 9 15 22.7818 15 39.7825C15 56.7832 28.7818 70.565 45.7825 70.565Z"
        fill="#01A085"
        fillOpacity="0.04"
      />
      <path
        d="M86.1178 47.2041H92.4301"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 18.6299H7.31226"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6252 78.0723H55.2044"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.6618 56.5957H7.306"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7589 4.99779L25.7935 1"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7935 4.99779L21.7589 1"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.5212 69.8806L84.4908 65.8828"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.4908 69.8806L80.5212 65.8828"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.3869 10.1093L75.3153 7.16016"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3153 10.1093L72.3869 7.16016"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5527 76.958L18.2447 78.662"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5527 78.662L18.2447 76.958"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.3125 53.3188L53.1875 56.5813C51.3875 57.4063 49.4187 57.95 47.375 58.1563V54.3688C48.7625 54.2 50.075 53.8438 51.3125 53.3188ZM30.6313 41.375H26.8438C27.05 43.4375 27.5938 45.3875 28.4188 47.1875L31.6813 45.3125C31.1563 44.075 30.8 42.7625 30.6313 41.375ZM51.3125 25.6813L53.1875 22.4188C51.3875 21.5938 49.4375 21.05 47.375 20.8438V24.6313C48.7625 24.8 50.075 25.1563 51.3125 25.6813ZM60.3688 37.625H64.1563C63.95 35.5625 63.4063 33.6125 62.5813 31.8125L59.3188 33.6875C59.8438 34.925 60.2 36.2375 60.3688 37.625ZM39.6875 53.3188L37.8125 56.5813C39.6125 57.4063 41.5812 57.95 43.625 58.1563V54.3688C42.2375 54.2 40.925 53.8438 39.6875 53.3188ZM43.625 24.6313V20.8438C41.5625 21.05 39.6125 21.5938 37.8125 22.4188L39.6875 25.6813C40.925 25.1563 42.2375 24.8 43.625 24.6313ZM57.425 30.4437L60.6875 28.55C59.5063 26.9187 58.0625 25.475 56.4312 24.2938L54.5375 27.5563C55.6437 28.4 56.6 29.3562 57.425 30.4437ZM31.6813 33.6875L28.4188 31.8125C27.5938 33.6125 27.05 35.5625 26.8438 37.625H30.6313C30.8 36.2375 31.1563 34.925 31.6813 33.6875ZM60.3688 41.375C60.2 42.7625 59.8438 44.075 59.3188 45.3125L62.5813 47.1875C63.4063 45.3875 63.95 43.4187 64.1563 41.375H60.3688ZM54.5562 51.425L56.45 54.6875C58.0812 53.5063 59.525 52.0625 60.7063 50.4312L57.4438 48.5375C56.6 49.6437 55.6437 50.6 54.5562 51.425ZM36.4437 27.575L34.5687 24.2938C32.9187 25.4938 31.4938 26.9187 30.3125 28.5687L33.575 30.4625C34.4 29.3563 35.3562 28.4 36.4437 27.575ZM33.575 48.5562L30.3125 50.4312C31.4938 52.0625 32.9375 53.5063 34.5687 54.6875L36.4625 51.425C35.3563 50.6 34.4 49.6437 33.575 48.5562ZM47.375 30.125H43.625V40.2687L51.6687 48.3125L54.3125 45.6687L47.375 38.7312V30.125Z"
        fill="#01A085"
      />
    </svg>
  );
};

export default CalendarBigIcon;
