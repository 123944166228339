import { Children, ReactNode } from "react";
import EmptyState, { EmptyStateProps } from "../EmptyState/EmptyState";

interface IPageListProps {
  emptyStateProps: EmptyStateProps;
  listComponent: (children: ReactNode | ReactNode[]) => JSX.Element;
  children: ReactNode | ReactNode[];
}

const PageList: React.FC<IPageListProps> = ({
  children,
  emptyStateProps,
  listComponent
}) => {
  return children && Children.count(children) > 0 ? (
    <div className="h-full px-6 py-4">
      {listComponent(children)}
      {/* <ListComponent>{children}</ListComponent> */}
    </div>
  ) : (
    <div className="h-full bg-paper">
      <EmptyState {...emptyStateProps} />
    </div>
  );
};

export default PageList;
