import { Typography, Button, BaseDialog, Input } from "knack-ui";
import { useState } from "react";
import { KnackSpinner } from "../../../../../../components/Asset/KnackSpinner";
import { HeadlessModal } from "../../../../../../components/modal/HeadlessModal";
import { Failure } from "../../../../../../components/ToastNotification/Failure";
import { Tooltip } from "../../../../../../components/Tooltip/Tooltip";
import { queryClient } from "../../../../../../lib/appConfig";
import { usePostAddUserPrompt } from "../../../../../../pages/dashboard/hrDashboard/Merger/PostAddUserInvitationPrompt";
import { useRequestDelegatedAccess } from "../../../../../Scheduler/schedulerMutation";
import { useInviteByEmail } from "../../../../programMutations";
import { programsQueryKeys } from "../../../../programQueries";
import { IProgram, IProgramInvite } from "../../../../ProgramTypes";
import { getUniqueValuesWithCase, isEmail } from "../../../../../../lib/utils";

const checkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

interface IProps {
  program: IProgram;
  type: IProgramInvite["type"];
  open: boolean;
  onClose: () => void;
}

const HrProgramParticipantInvitationByEmail = ({ open, program, type, onClose }: IProps) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const { setProgramData, setUserType } = usePostAddUserPrompt();
  const { mutateAsync: inviteByEmail } = useInviteByEmail();
  const [items, setItems] = useState<string[]>([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState<any>(null);
  const { mutateAsync: requestDelegatedAccess } = useRequestDelegatedAccess();

  const handleKeyDown = (e: any) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      var values = value.trim();

      if (values && isValid(values)) {
        const itemgg = [...items, values];
        const setUniqueEmails = getUniqueValuesWithCase(itemgg, false);
        setItems(setUniqueEmails);
        setValue("");
      }
    }
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setError(null);
  };

  const handleDelete = (item: string) => {
    var itemDelete = items.filter((i) => i !== item);
    setItems(itemDelete);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    var paste = e.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      var toBeAdded = emails.filter((email: string) => !isInList(email));

      // https://stackoverflow.com/questions/46741019/js-new-set-remove-duplicates-case-insensitive/46741042
      var setitems = [...items, ...toBeAdded];
      let setUniqueChars = getUniqueValuesWithCase(setitems, false);
      setItems(setUniqueChars);
    }
  };

  const isValid = (email: string) => {
    let errors = null;

    if (isInList(email)) {
      errors = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      errors = `${email} is not a valid email address.`;
    }

    if (errors) {
      setError(errors);
      return false;
    }

    return true;
  };

  const isInList = (email: string) => {
    return items.includes(email);
  };

  const handleInvite = () => {
    onClose();
    setItems([]);
    onInviteByMail(items);
  };

  async function onInviteByMail(mailArray: string[]) {
    if (mailArray && mailArray.length > 0) {
      try {
        const res = await inviteByEmail({
          programId: program._id,
          data: {
            emails: mailArray,
            type
          }
        });

        const filtered = res.data.filter((s: { status: boolean }) => s.status === false);

        if (!program?.isAutoEnrollment) {
          setUserType(type);
          setProgramData(program);
        }

        setShowModal(true);

        setVerifyEmail(filtered);
        queryClient.invalidateQueries(programsQueryKeys.all);

        const expert = ["coach", "mentor"];
        if (type && expert.includes(type)) {
          requestDelegatedAccess({
            emails: mailArray
          })
            .then(() => { })
            .catch((e) => {
              console.log(e);
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      Failure("No Emails to Add");
    }
  }

  return (
    <>
      {showModal && (
        <HeadlessModal
          width="w-full"
          open={showModal}
          setOpen={setShowModal}
          canDismissFromBackdrop={true}
          showDefaultButton={undefined}
          buttonFunction={undefined}
        >
          <div className="p-2 sm:p-6">
            {verifyEmail.length > 0 && (
              <div>
                <p className="text-lg font-bold text-center sm:text-xl text-skin-base">One or more emails could not be added</p>
              </div>
            )}
            {verifyEmail.length === 0 && (
              <div>
                <div className="z-10 flex items-center justify-center w-8 h-8 m-auto font-bold text-center text-white bg-white rounded-full shadow bg-skin-green ">
                  {checkIcon}
                </div>
                <Typography className="my-4" textAlign="center" variant="h6">
                  All Emails Added Successfully.
                </Typography>
                {!program.isAutoEnrollment && <Typography color="muted" textAlign="center">
                  Adding participants will not invite them to register for the program. You can invite them later at any point to enable
                  them to register for the program.
                </Typography>}
              </div>
            )}
            {
              <div className="flex flex-row flex-wrap min-h-full mt-5 mb-5 overflow-auto max-h-80 no-scrollbar">
                {verifyEmail.map(
                  (item, i) =>
                    item.email &&
                    !item.status && (
                      <Tooltip tooltipText={item.msg}>
                        <div
                          className={`${item.status ? "bg-skin-green" : "bg-skin-red"
                            } m-1 px-2 py-1 text-sm h-7 inline-flex items-center rounded-full text-white font-medium z-1`}
                          key={i}
                        >
                          {item.email}
                        </div>
                      </Tooltip>
                    )
                )}
              </div>
            }
            <Button fullWidth onClick={() => setShowModal(false)}>
              Dismiss
            </Button>
          </div>
        </HeadlessModal>
      )}

      {/* Spinner */}
      {showSpinner && (
        <div onClick={() => setShowSpinner(false)}>
          <KnackSpinner />
        </div>
      )}

      <BaseDialog dialogClassName="max-w-xl" isOpen={open} onClose={() => onClose()} animationType="fade">
        <div className="p-4 sm:p-10">
          <Typography textAlign="center" as="h6" variant="h6" className="mb-4">
            Add by Email
          </Typography>

          <div className="w-full mt-4">
            <Typography as="label" htmlFor="email" variant="subtitle2" className="block mb-2">
              Enter Participant Email
            </Typography>

            <Input
              id="email"
              name="email"
              className={(error && " has-error") + "w-full rounded-md"}
              value={value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              onPaste={handlePaste}
            />
            {error && <p className="text-xs font-medium text-red-400">{error}</p>}
            <p className="py-2 text-sm text-skin-muted">Press Enter to add email</p>
          </div>
          <div className="flex flex-row flex-wrap overflow-auto overflow-h-auto scrollhost2 h-full" style={{ maxHeight: 350 }}>
            {items.map((item) => (
              <div
                className="inline-flex items-center px-2 py-1 m-1 text-sm font-light text-white bg-gray-100 rounded-full h-7 text-skin-muted"
                key={item}
              >
                {item}
                <button
                  type="button"
                  className="w-5 h-5 p-0 m-3 -mr-0 leading-none rounded-full bg-white-100"
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <div className="py-2">
            <button
              className={`w-full text-base text-center text-white p-4 rounded-lg ${items.length === 0 ? "bg-gray-400" : "bg-skin-fill hover:bg-blue-600 "
                }`}
              onClick={handleInvite}
              disabled={items.length === 0}
            >
              {"Add " + type}
            </button>
          </div>
        </div>
      </BaseDialog>
    </>
  );
};

export default HrProgramParticipantInvitationByEmail;
