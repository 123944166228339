import { format, isBefore } from "date-fns/esm";
import { BaseDialog, Box, Button, Chip, Stack, Typography } from "knack-ui";
import { useState } from "react";
import SVG from "../../../../components/SVG/SVG";

import { IUserProgram } from "../../../../features/Programs/ProgramTypes";
import { useUser, useUserRoles } from "../../../../features/User/userQueries";

interface IProps {
  program: IUserProgram;
  showDetails?: boolean;
}

const ProgramOverview = ({ program, showDetails }: IProps) => {
  const [open, setOpen] = useState(false);
  const { data: user } = useUser();
  const { roleState } = useUserRoles();
  return (
    <div dir="ltr" className="relative h-full py-6">
      <div className="mb-8">
        <Typography className="mb-4" variant="h6" fontWeight="bold" as="h6">
          Program Description
        </Typography>
        <Box dangerouslySetInnerHTML={{ __html: program.overview }} variant="outlined" className="p-4" />
      </div>
      {roleState?.activeRole === "Hr" && (
        <div className="mb-8">
          <Typography className="mb-4" variant="h6" fontWeight="bold" as="h6">
            Program Details
          </Typography>
          <div className="grid gap-4">
            <Stack alignItems="center" gap={3}>
              <SVG name="Clock" />
              <Typography variant="subtitle2">Start Date: {format(new Date(program?.start_date), "dd/MM/yyyy")}</Typography>
            </Stack>
            {program.end_date && (
              <Stack alignItems="center" gap={3}>
                <SVG name="Clock" />
                <Typography variant="subtitle2">
                  {isBefore(new Date(program.end_date), new Date())
                    ? `Ended in: ${format(new Date(program.end_date), "dd/MM/yyyy")}`
                    : `Ends in: ${format(new Date(program.end_date), "dd/MM/yyyy")}`}
                </Typography>
              </Stack>
            )}
            <Stack alignItems="center" gap={3}>
              <SVG name="Sessions" />
              <Typography variant="subtitle2">Recommended sessions: {program.session_count}</Typography>
            </Stack>
          </div>
        </div>
      )}

      <div className="px-6 py-4 mb-8 bg-gray-100 border rounded-lg ">
        <Typography className="mb-4" variant="h6" fontWeight="bold" as="h6">
          {program.program_type} Topics
        </Typography>
        <div className="flex flex-wrap gap-4 ">
          {program?.skills.map((s) => (
            <Chip color="primary" shape="rounded" key={s}>
              {s}
            </Chip>
          ))}
        </div>
      </div>
      {program && program?.goals?.length > 0 && (
        <div className="mb-8">
          <Typography className="mb-4" variant="h6" fontWeight="bold" as="h6">
            Program Goals
          </Typography>
          <ul className="" style={{ listStyle: "disc", paddingInlineStart: "20px" }}>
            {program.goals?.map((s) => (
              <li className="list-disc list-item" key={s}>
                {s}
              </li>
            ))}
          </ul>
        </div>
      )}

      {user?.account_type !== "Coach" && (
        <div className="flex justify-end">
          <Button onClick={() => setOpen(true)} kind="tertiary">
            Agreement & rules
          </Button>
        </div>
      )}
      <BaseDialog dialogClassName="max-w-2xl" isOpen={open} onClose={() => setOpen(false)}>
        <Box paddingPreset="card">
          <Typography variant="h5">Program agreement & rules</Typography>
          <div
            style={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}
            dangerouslySetInnerHTML={{ __html: program.agreement }}
            className="my-6"
          />
          <div className="flex justify-end">
            <Button onClick={() => setOpen(false)}>Dismiss</Button>
          </div>
        </Box>
      </BaseDialog>
    </div>
  );
};

export default ProgramOverview;
