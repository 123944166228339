import { Radio, Stack, Typography } from "knack-ui";
import { ISurveyAnswerComponentProps } from "../../surveyTypes";

const SurveyMultipleChoiceAnswer = ({
  index,
  questionIndex,
  question,
  onChange,
  value,
  sectionIndex
}: ISurveyAnswerComponentProps) => {
  const optionLabel = question.options[index].option_label;
  return (
    <Stack gap={4} alignItems="center">
      <Radio
        icon="circle"
        checked={value === optionLabel}
        id={`${optionLabel} ${index} ${sectionIndex} ${questionIndex} - multiple`}
        onChange={() => {
          if (value === optionLabel) {
            onChange("");
          } else {
            onChange(optionLabel);
          }
        }}
      />
      <Typography
        className="block"
        variant="body1"
        fontWeight="medium"
        as="label"
        htmlFor={`${optionLabel} ${index} ${sectionIndex} ${questionIndex} - multiple`}
      >
        {optionLabel}
      </Typography>
    </Stack>
  );
};

export default SurveyMultipleChoiceAnswer;
