import { useState, useEffect } from "react";
import anime from "animejs";
import { useHistory } from "react-router-dom";
import onboardingService from "../../services/assessment/onboarding.service";
import { Failure } from "../../components/ToastNotification/Failure";
import { Success } from "../../components/ToastNotification/Success";
import ContentArea from "../../components/contentArea/contentArea";
import Footer from "../../components/footer/footer";
import Disclaimer from "../../components/disclaimer/disclaimer";
import Quiz from "../../components/questions/quiz";
import { useTranslation } from "react-i18next";
import LanguageToggler from "../../components/LanguageToggler/LanguageToggler";
import { useUser } from "../../features/User/userQueries";

export const SelfAssessmentForm = () => {
  const { data: user } = useUser();
  const { t, i18n } = useTranslation(["assessments"]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const [counter, setCounter] = useState(0);
  const [questionId, setQuestionId] = useState(1);
  const [question, setQuestion] = useState(""); // current question
  const [type, setType] = useState("Assessment");
  const [answerOptions, setAnswerOptions] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]); // all questions
  const [answer, setAnswer] = useState(null);
  const [response, setResponse] = useState([]);
  const [showQuiz, setShowQuiz] = useState(false);
  const [animateSlideInOut, setAnimateSlideInOut] =
    useState("animate-slide-in");

  useEffect(() => {
    if (response.length > 0 && response.length === quizQuestions.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [response]);

  useEffect(() => {
    getOnboardingQuestion();
  }, []);

  function getOnboardingQuestion() {
    onboardingService.getAllQuestions().then(
      (response) => {
        let questionList = response.data.data;

        setQuizQuestions(questionList);

        // set the starting questions
        setQuestion(questionList[counter].question);
        setAnswerOptions(questionList[0].options);

        // resume question from where stopped
        const savedResponses = localStorage.getItem(
          user._id + "_" + "assessment"
        );
        if (savedResponses) {
          const parsedResponse = JSON.parse(savedResponses);
          setResponse(parsedResponse); // set save response for localStorage

          setQuestion(questionList[parsedResponse.length - 1].question); // resume or set starting questions
          setAnswerOptions(questionList[parsedResponse.length - 1].options); // set anser options for selected question

          setQuestionId(parsedResponse.length);
          setCounter(parsedResponse.length - 1); // value of starting position e.g 0, but mostly likely > 0

          setAnswer(parsedResponse[parsedResponse.length - 1].answer_before); // set answer of previous question
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
        Failure(resMessage);
      }
    );
  }

  const getItemIndex = (arr, id) => {
    return arr.findIndex((e) => e.question_id === id);
  };

  function storeEdit(question_id, answer) {
    const currentQuestion = quizQuestions[counter];
    const itemIndex = getItemIndex(response, question_id);

    const obj = {
      question_id: currentQuestion._id,
      answer_before: parseInt(answer),
      level_two: currentQuestion.level_two,
      level_three: currentQuestion.level_three
    };

    // not found?
    if (itemIndex === -1) {
      // add to response list
      const _response = [...response, obj];
      setResponse(_response);
      saveResponseToLocalStorage(_response);
    } else {
      // modify if found
      const newArr = [...response];
      newArr[itemIndex] = obj;
      setResponse(newArr);
      saveResponseToLocalStorage(newArr);
    }
  }

  /**
   * save to localStorage
   * @param {*} responseList
   */
  function saveResponseToLocalStorage(responseList) {
    localStorage.setItem(
      user._id + "_" + "assessment",
      JSON.stringify(responseList)
    );
    // console.log("assessmentAnswers", responseList)
    // scroll to bottom of page
    if (response.length > 0 && response.length === quizQuestions.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  const setUserAnswer = (answer) => {
    // console.log(answer)
    const currentQuestion = quizQuestions[counter];
    storeEdit(currentQuestion._id, answer);
    setAnswer(parseInt(answer));
  };

  const setNextQuestion = () => {
    try {
      const count = counter + 1;
      const qID = questionId + 1;
      setCounter(count);
      setQuestionId(qID);
      setQuestion(quizQuestions[count].question);
      setAnswerOptions(quizQuestions[count].options);
      setAnswer(
        response.includes(response[count]) ? response[count].answer_before : ""
      );
    } catch (error) {}
  };

  const setPreviousQuestion = () => {
    const count = counter - 1;
    const qID = questionId - 1;

    setCounter(count);
    setQuestionId(qID);

    setQuestion(quizQuestions[count].question);
    setAnswerOptions(quizQuestions[count].options);
    setAnswer(response[count].answer_before);
  };

  const submit = () => {
    onboardingService
      .submitAssessment(response, type)
      .then((res) => {
        if (res.status) {
          // remove anwsers
          localStorage.removeItem(user._id + "_" + "assessment");
          // temp fix
          const authToken = JSON.parse(localStorage.getItem("user"))?.data
            ?.authToken;
          // save res to local
          const _user = { ...user, authToken };
          _user.assessment_completed = true;
          localStorage.setItem("user", JSON.stringify({ data: _user }));
          Success(
            "Congratulations! Your assessment has been submitted successfully. You will be redirected to view your reports"
          );
          setIsSubmitted(true);
          setTimeout(() => {
            history.push({ pathname: "/reports" }); // reports page
            window.location.reload();
          }, 2500);
        } else {
          Failure("Oops! Error occurred. Please contact support");
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops! Error occurred. Please contact support");
      });
  };

  const handleNextClick = () => {
    if (questionId < quizQuestions.length) {
      setTimeout(() => setNextQuestion(), 300);
    }
  };

  const handlePrevClick = () => {
    if (questionId > 1) {
      setPreviousQuestion();
    }
  };

  function handleAnswerSelected(event) {
    setUserAnswer(event.currentTarget.value);
    handleNextClick();

    // animation
    if (questionId < quizQuestions.length) {
      setAnimateSlideInOut("animate-slide-out");
      setTimeout(() => {
        setAnimateSlideInOut("animate-slide-in");
      }, 200);
    }
  }

  const onStartButtonClick = () => {
    animateOut();
    setTimeout(() => {
      setShowQuiz(true);
    }, 1000);
  };

  const animateOut = () => {
    setTimeout(
      () =>
        anime({
          targets: ".disclaimer",
          translateX: "150%",
          elasticity: function (el, i, l) {
            return 200 + i * 200;
          }
        }),
      200
    );
  };

  const showNext = () => {
    return answer !== "" ? false : true;
  };

  const showPrev = () => {
    return questionId > 1 ? false : true;
  };

  return (
    <div
      dir={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}
      className={`${
        i18n.resolvedLanguage === "ar" ? "font-cairo" : ""
      } flex flex-col w-full mb-32 overflow-x-hidden`}
    >
      <div className="flex justify-end">
        <LanguageToggler />
      </div>
      <ContentArea classes="">
        <div className="mx-auto">
          {showQuiz ? (
            <>
              {/* Assessment */}
              {isSubmitted ? (
                <div
                  className={`bg-white sm:shadow p-4 sm:rounded-lg my-4 max-w-2xl flex flex-col items-center justify-center mx-auto ${animateSlideInOut}`}
                >
                  <lottie-player
                    src="https://assets10.lottiefiles.com/packages/lf20_si8cnxeb.json"
                    background="transparent"
                    speed="1"
                    style={{ width: 350, height: 350 }}
                    autoplay
                  ></lottie-player>
                  <div className="-mt-10 text-4xl font-bold">
                    {" "}
                    {t`congratulations`}
                  </div>
                  <div className="text-xl font-medium">
                    {" "}
                    {t`successfully-completed`}
                  </div>
                  <p className="mb-8 text-xs text-yellow-700 font-gray-400">
                    {t`home-redirect`}
                  </p>
                </div>
              ) : (
                <div
                  className={`p-4 sm:rounded-lg my-4 max-w-2xl mx-auto ${animateSlideInOut}`}
                >
                  <Quiz
                    counter={counter}
                    answer={answer}
                    answerOptions={answerOptions}
                    questionId={questionId}
                    question={t(question)}
                    questionTotal={quizQuestions.length}
                    onAnswerSelected={handleAnswerSelected}
                  />

                  <div className="grid items-center justify-center gap-4 mt-8 sm:flex sm:justify-between">
                    <button
                      onClick={handlePrevClick}
                      className="flex px-4 py-2 border rounded-lg text-skin-accent prev-next-btn border-skin-base focus:outline-none hover:bg-skin-fill-accent hover:text-white"
                      disabled={showPrev()}
                    >
                      {t`previous-question`}
                    </button>

                    {response.length > 0 &&
                      response.length === quizQuestions.length && (
                        <button
                          onClick={() => submit()}
                          className="relative flex items-center justify-center px-4 py-2 text-base font-medium text-white rounded-lg bg-skin-accent"
                        >
                          {t`submit-assessment`}
                          <span className="absolute inline-flex w-full h-full rounded-full opacity-50 animate-ping bg-skin-accent"></span>
                        </button>
                      )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="max-w-xl mx-auto">
              {/* Disclaimer */}
              <Disclaimer
                title={t("ASSESSMENT.DISCLAIMER.HEADER")}
                subHeading="Very brief disclaimer Very brief disclaimer Very brief disclaimer Very brief disclaimer Very brief disclaimer Very brief disclaimer"
                buttonText={t`start-assessment`}
                onStartButtonClick={onStartButtonClick}
              />
            </div>
          )}
        </div>
      </ContentArea>
      <Footer total={quizQuestions.length} count={questionId} />
    </div>
  );
};
