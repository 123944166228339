import { IPaymentAnalytics } from "./../features/PaymentManagement/paymentTypes";
import moment from "moment";
import { SESSION_BY_PROGRAM_TYPE } from "../App.constant";
import { IProgram } from "../features/Programs/ProgramTypes";
import { INavigationItem } from "./interfaces/generalTypes";
export const datePickerTimeIntervals = 15;
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const ISO8601Format = "YYYY-MM-DDTHH:mm";
/**
 * Convert to ISO 8601 compliant dateTime
 * @param date
 * @returns
 */
export const ISO8601FormattedDate = (date: string | Date) => moment(moment(new Date(date)).format(ISO8601Format)).toISOString(false);

export const BROWSER_LOCALE = () =>
  (navigator as any).userLanguage ||
  (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
  navigator.language ||
  (navigator as any).browserLanguage ||
  (navigator as any).systemLanguage ||
  "en";

export const DURATIONS = {
  tenSeconds: 1000 * 60 * 0.1,
  thirtySeconds: 1000 * 60 * 0.5,
  oneMinute: 1000 * 60 * 1,
  twoMins: 1000 * 60 * 2,
  fiveMins: 1000 * 60 * 5,
  tenMins: 1000 * 60 * 10,
  fifteenMins: 1000 * 60 * 15,
  thirtyMins: 1000 * 60 * 30,
  oneHour: 1000 * 60 * 60
};

export const ROLE_CONSTANTS = {
  Hr: {
    name: "Human Resource",
    icon: "",
    description: "View analytics of coaching and mentoring engagements",
    color: "bg-skin-accent"
  },
  Expert: {
    name: "Expert",
    icon: "",
    description: "Engage with your mentees or coachees assigned to you",
    color: "bg-skin-green"
  },
  User: {
    name: "User",
    icon: "",
    description: "Engage with your mentor or coach you have selected",
    color: "bg-skin-dark"
  }
};
export const UserRoles = ["Hr", "User", "Expert"] as const;
export enum SessionTypes {
  "Goal Setting" = "Goal-setting Session",
  "Chemistry Call" = "Chemistry Call",
  Coaching = "Coaching Session",
  Mentoring = "Mentoring Session",
  "1:1 session" = "One-on-One session",
  "Team session" = "Team session"
}
export const correctlyMapSessionTypes = {
  "1:1 session": "1:1 session",
  Coaching: "1:1 session",
  Mentoring: "1:1 session",
  "Team session": "Team session",
  "Chemistry Call": "Chemistry Call",
  "Goal Setting": "Goal Setting"
};
export enum UserTypes {
  MENTOR = "mentor",
  MENTEE = "mentee",
  COACHEE = "coachee",
  COACH = "coach",
  EMPLOYEE = "Employee"
}

export const apiEndpoint = process.env.REACT_APP_BASE_API;

// 🎨 Layout constants
export const NavbarHeight = 80;
export const LogoMaxWidth = 200;
export const SidebarWidthExtended = 250;
export const SidebarWidthCollapsed = 80;

// 🧪🥼 Experimental :
export const PageTitleHeight = "73px";
export const BreadcrumbsHeight = "61px";
/**
 * Calculates content height without page header/title
 */
export const PageHeightWithoutHeader = `calc(100% - ${PageTitleHeight})`;
/**
 * Calculates content height without breadcrumbs
 */
export const PageHeightWithoutBreadcrumbs = `calc(100% - ${BreadcrumbsHeight})`;

export const HrNavigationItems: INavigationItem[] = [
  // { href: "/", title: "Home", icon: "Home" },
  { href: "/dashboard", title: "Home", icon: "Home" },
  { href: "/programs", title: "Programs", icon: "Program" },
  // { href: "/new-programs", title: "New Programs", icon: "Program" },
  { href: "/people", title: "People", icon: "Users" },
  {
    href: "/assessment-reports",
    title: "Assessment reports",
    icon: "Assessment"
  },
  { href: "/survey", title: "Surveys", icon: "Surveys" },
  { href: "/campaigns", title: "Campaigns", icon: "Campaign" },
  { href: "/reports", title: "Reports", icon: "Report" }
];
export const ExpertNavigationItems: INavigationItem[] = [
  // { href: "/", title: "Dashboard", icon: "Home" },
  { href: "/dashboard", title: "Dashboard", icon: "Home" },
  { href: "/programs", title: "Programs", icon: "Program" },

  { href: "/connections", title: "Connections", icon: "Users" },
  { href: "/sessions", title: "Sessions", icon: "Sessions" },
  { href: "/availability", title: "Availability", icon: "Availability" },
  { href: "/reports", title: "Assessment", icon: "Assessment" }
  // { href: "/learning", title: "Learning", icon: "Surveys" }
];
export const UserNavigationItems: INavigationItem[] = [
  // { href: "/", title: "Dashboard", icon: "Home" },
  { href: "/dashboard", title: "Dashboard", icon: "Home" },
  { href: "/programs", title: "Programs", icon: "Program" },
  { href: "/connections", title: "Connections", icon: "Users" },
  { href: "/goals", title: "Goals", icon: "Goals" },
  { href: "/sessions", title: "Sessions", icon: "Sessions" },
  { href: "/reports", title: "Assessment", icon: "Assessment" }
  // { href: "/learning", title: "Learning", icon: "Surveys" }
];
export const CoachNavigationItems: INavigationItem[] = [
  // { href: "/", title: "Home", icon: "Home" },
  { href: "/dashboard", title: "Home", icon: "Home" },
  { href: "/sessions", title: "Sessions", icon: "Sessions" },
  { href: "/clients", title: "Clients", icon: "Users" },
  { href: "/availability", title: "Availability", icon: "Availability" },
  { href: "/earnings", title: "Earnings", icon: "Earnings" }
];
export const AdminNavigationItems: INavigationItem[] = [
  { href: "/reports", title: "Reports", icon: "Report" },
  { href: "/experts", title: "Experts", icon: "Users" },
  { href: "/campaigns", title: "Campaign Manager", icon: "Campaign" },
  { href: "/coach", title: "Coach Management", icon: "Profile" },
  { href: "/coaches", title: "Knack Coaches", icon: "Profile" },
  { href: "/goals", title: "Goals", icon: "Goals" },
  { href: "/employees", title: "Employee Management", icon: "Users" },
  // { href: "/matchmaking", title: "Matchmaking", icon: "Sessions" },
  { href: "/external-matching", title: "External Matching", icon: "Sessions" },
  { href: "/companies", title: "Companies", icon: "Assessment" },
  { href: "/teams", title: "Teams", icon: "Users" },
  { href: "/allusers", title: "All Users", icon: "Users" },
  { href: "/qa-resources", title: "QA Resources", icon: "Users" },
  { href: "/survey", title: "Surveys", icon: "Surveys" }
];

export const emailRegex = /^\w+([-.\+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;
export const internationalNumberRegex =
  /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;

export const ageGroupOptions = [
  { value: "20-below", label: "20 or younger" },
  { value: "21-29", label: "21-29" },
  { value: "30-39", label: "30-39" },
  { value: "40-49", label: "40-49" },
  { value: "50-59", label: "50-59" },
  { value: "60-above", label: "60 or older" }
];
export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" }
];

export const getRandomInt = (min: number, max: number) => {
  return min + Math.floor(Math.random() * (max - min + 1));
};

/**
 * 🎉😹 Use these with `getRandomInt()` 👆 to get random messages.
 @usage `cuteLoadingMessages[getRandomInt(0, cuteLoadingMessages.length)]`
 *
 *  Maybe consider adding quotes from known people ?
 */

export const cuteLoadingMessages = [
  "Discovering new ways of making you wait.",
  "Your time is very important to us. Please wait while we ignore you.",
  "Still faster than Windows update.",
  "We are not liable for any broken screens as a result of waiting.",
  "Bored of slow loading spinner?, buy more RAM!",
  "Kindly hold on until I finish a cup of coffee.",
  "We will be back in 1/0 minutes.",
  "Why don't you order a sandwich?",
  "Don't panic, Just count to infinite.",
  "Please wait, Your PC is not a superman!"
];

export function computeConstrainSessionTypes(program: IProgram) {
  const type = program.program_type;
  const isGroupCoaching = program?.coach_type === "knack_coaching" && type === "Coaching" && program.plan === "group";

  return SESSION_BY_PROGRAM_TYPE[type === "Coaching" ? (isGroupCoaching ? "Group_Coaching" : "Coaching") : "Mentoring"];
}

export const externalCoachingStats = ["1:1Satisfaction", "groupSatisfaction", "topics", "timeline", "recentSessions", "progress"] as const;
export const internalCoachingStats = ["satisfaction", "topics", "timeline", "recentSessions", "progress"] as const;
export const mentoringStats = ["satisfaction", "topics", "timeline", "recentSessions", "progress"] as const;

export const MediaQueryMaps = {
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)"
};

export const SessionCancellationOrDeletionReasons = (activeRole: typeof UserRoles[number] | undefined) => {
  if (!activeRole) {
    return [
      {
        label: "Other",
        value: "Other"
      }
    ];
  }
  const reasons = {
    Expert: [
      {
        label: "My client had an emergency",
        value: "My client had an emergency"
      },
      {
        label: "I had an emergency",
        value: "I had an emergency"
      },
      {
        label: "I missed the session",
        value: "I missed the session"
      },
      {
        label: "Duplicated session",
        value: "Duplicated session"
      },
      {
        label: "Other",
        value: "Other"
      }
    ],
    User: [
      {
        label: "I had an emergency",
        value: "I had an emergency"
      },
      {
        label: "I missed the session",
        value: "I missed the session"
      },
      {
        label: "Duplicated session",
        value: "Duplicated session"
      },
      {
        label: "Other",
        value: "Other"
      }
    ],
    Hr: []
  };

  return reasons[activeRole];
};

export const ScheduleBufferOptions = [
  {
    label: "5 Mins",
    value: 5
  },
  {
    label: "10 Mins",
    value: 10
  },
  {
    label: "45 Mins",
    value: 45
  },
  {
    label: "1 Hour",
    value: 60
  },
  {
    label: "1.5 Hours",
    value: 90
  },
  {
    label: "2 Hours",
    value: 120
  },
  {
    label: "1 Day",
    value: 1440
  },
  {
    label: "2 Days",
    value: 2880
  },
  {
    label: "3 Days",
    value: 4320
  },
  {
    label: "1 Week",
    value: 10080
  },
  {
    label: "2 Weeks",
    value: 20160
  }
];

export const PlatformFeatures = {
  Mentoring: "Mentoring",
  "Internal Coaching": "Internal Coaching",
  "External Coaching": "External Coaching",
  Learning: "Learning",
  Goals: "Goals",
  Assessment: "Assessment",
  "360 Assessment": "360 Assessment"
} as const;

export function hoursBetween(session: IPaymentAnalytics, timeProperty: "cancelled_at" | "deleted_at") {
  const hoursInBetween = moment(session.start_time).from(session[timeProperty], true);

  const diff = moment(session.start_time).diff(moment(session[timeProperty]), "hours");

  const label = diff > 0 ? "Later" : "Before";

  return `${hoursInBetween} ${label}`;
}

export const weekDayMappings = {
  monday: ["tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
  tuesday: ["wednesday", "thursday", "friday", "saturday", "sunday", "monday"],
  wednesday: ["thursday", "friday", "saturday", "sunday", "monday", "tuesday"],
  thursday: ["friday", "saturday", "sunday", "monday", "tuesday", "wednesday"],
  friday: ["saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"],
  saturday: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday"],
  sunday: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
};

export const COLORS = {
  knackPrimary: "hsl(230deg, 80%, 54%)",
  primary: "hsl(230deg, 80%, 54%)",
  primaryBackground: "hsla(230deg, 80%, 54%, 0.1)",
  knackSecondary: "hsl(170deg, 99%, 32%)",
  text: "hsl(222deg, 22%, 5%)",
  // white
  // background: "hsl(0deg, 0%, 100%)",
  // Figma file background
  background: "hsl(220, 60%, 98%);",
  alert: "hsl(30deg, 100%, 50%)",
  success: "hsl(160deg, 100%, 40%)",
  successBackground: "hsla(160deg, 100%, 40%, 0.1)",
  error: "hsl(340deg, 95%, 60%)",
  indianRed: "hsl(12deg, 35%, 50%)",
  indianRedBackground: "hsla(12deg, 35%, 50%, 0.1)",
  purpleBackground: "hsla(275deg, 100%, 40%, 0.1)",
  purple: "hsl(275deg, 100%, 40%)",
  gray: {
    1000: "hsl(210deg, 15%, 20%)",
    900: "hsl(210deg, 15%, 25%)",
    700: "hsl(210deg, 10%, 40%)",
    600: "hsl(210deg, 9%, 45%)",
    500: "hsl(210deg, 8%, 50%)",
    400: "hsl(210deg, 12%, 55%)",
    300: "hsl(210deg, 14%, 66%)",
    200: "hsl(210deg, 25%, 88%)",
    100: "hsl(210deg, 25%, 96%)"
  },
  paper: "hsl(0, 0%, 100%)"
};

export const BREAKPOINTS = {
  tabletMin: 550,
  laptopMin: 1100,
  desktopMin: 1500
};
export const QUERIES = {
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMin / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin / 16}rem)`,
  desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin / 16}rem)`,
  precisePointerDevices: "(pointer:fine)",
  hoverSupported: "(hover:hover)",
  hoverPointerDevices: () => `${QUERIES.hoverSupported} and ${QUERIES.hoverPointerDevices}`
};

export const ELEVATIONS = {
  small: `
  0px 1px 1.3px hsl(var(--shadow-color) / 0.11),
  0px 1.6px 2.1px -0.7px hsl(var(--shadow-color) / 0.17),
  0px 3.2px 4.2px -1.3px hsl(var(--shadow-color) / 0.24);
  `,
  medium: `
    0px 1px 1.3px hsl(var(--shadow-color) / 0.12),
    0px 3.4px 4.4px -0.4px hsl(var(--shadow-color) / 0.17),
    0.1px 7.3px 9.5px -0.9px hsl(var(--shadow-color) / 0.22),
    0.1px 16.1px 20.9px -1.3px hsl(var(--shadow-color) / 0.27)
`,
  // medium: `
  //   3.4px 3.4px 3.4px -6px hsl(var(--shadow-color) / 0.7),
  //   8.6px 8.5px 7.3px -6px hsl(var(--shadow-color) / 0.5),
  //   17.7px 17.3px 14.8px -6px hsl(var(--shadow-color) / 0.4),
  //   36.5px 35.7px 30.6px -6px hsl(var(--shadow-color) / 0.3),
  //   100px 98px 84px -6px hsl(var(--shadow-color) / 0.2)
  // `,
  large: `
    0px 1px 1.3px hsl(var(--shadow-color) / 0.12),
    0.1px 5.8px 7.5px -0.2px hsl(var(--shadow-color) / 0.15),
    0.1px 10.5px 13.6px -0.4px hsl(var(--shadow-color) / 0.18),
    0.1px 16.7px 21.7px -0.7px hsl(var(--shadow-color) / 0.21),
    0.2px 26.1px 33.9px -0.9px hsl(var(--shadow-color) / 0.24),
    0.4px 40.3px 52.3px -1.1px hsl(var(--shadow-color) / 0.27),
    0.5px 61px 79.2px -1.3px hsl(var(--shadow-color) / 0.3)
  `
};

export const barChartDataZoom = (dataLength: number) => {
  const start = dataLength > 10 ? 95 : 0;
  return [
    {
      type: "slider",
      show: true,
      yAxisIndex: [0],
      left: "93%",
      start,
      end: 100
    }
  ];
};

export const dateIsValid = (value: any) => {
  return moment(value, "YYYY-MM-DD", true).isValid();
};

export const NumberInputTokens = ["e", "-", ".", "+"];

export const CHART_LIST = [
  "DISTRIBUTION_BY_ENTITY",
  "DISTRIBUTION_BY_DEPARTMENT",
  "DISTRIBUTION_BY_MANAGEMENT_LEVEL",
  "TOPIC_INTERESTS",
  "ACTIVE_EXPERTS_BY_SESSION",
  "ACTIVE_USERS_BY_SESSION",
  "PAIRED_VS_UNPAIRED_USERS",
  "PAIRED_VS_UNPAIRED_EXPERTS",
  "AVAILABLE_CAPACITY_VS_UNPAIRED_USERS",
  "SATISFACTION_SCORE",
  "SESSION_TOP_TOPICS",
  "SESSION_TIMELINE",
  "SESSION_STATUSES",
  "TOP_TOPICS_IN_GOALS",
  "GOALS_STATUSES"
] as const;

export const QUERY_ENTITIES = {
  sessionProgress: "session progress",
  topicInterests: "topic interest",
  userDistributions: "user distribution list",
  userList: "user list",
  timeline: "timeline",
  satisfactionScores: "satisfaction scores",
  sessionStatuses: "session statuses",
  goalsTopTopics: "goals top topics",
  topTopics: "top topics",
  goalsList: "goals list",
  sessionsList: "sessions list",
  pairingsTableData: "pairings table",
  unpaired: "unpaired",
  summary: "summary",
  chart: "chart",
};
