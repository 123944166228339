import { Button, Typography } from "knack-ui";
import { useGetDisposableEmail } from "../qaResourceQueries";

export const QAResources = () => {
  const { data, refetch } = useGetDisposableEmail();

  return (
    <>
      <div className="grid gap-4">
        <Typography variant="h5">Generate linked email</Typography>
        <div className="flex">
          <div className="p-4 bg-skin-light border">
            <Typography variant="body1" fontWeight="bold">
              {data}
            </Typography>
          </div>
          <Button shape="square" onClick={() => refetch()}>
            Generate New
          </Button>
        </div>
      </div>
    </>
  );
};
