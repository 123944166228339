

function Question({ content, animate }) {
  return (
    <>
      {" "}
      <h2 className="w-full pb-4 text-2xl font-bold transform translate-y-0 text-start translate delay-350">
        {content}
      </h2>{" "}
    </>
  );
}
export default Question;
