import { useEffect, useState } from "react";
import SVG from "../../../../components/SVG/SVG";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import { AdminServices, createNewUser } from "../../../../services/dashboard/adminDashboard.services";
import { CreateCoach } from "./CreateCoach";
import { LargeScreenModificationPanel } from "./LargeScreenModificationPanel";
import { updateProfile } from "../../../../services/base-api";
import { Button, Stack } from "knack-ui";
import { useGetAuthToken } from "../../../../features/Auth/authMutations";
import { useUser } from "../../../../features/User/userQueries";
import { isDev } from "../../../../lib/utils";

export const CoachesTable = ({ onEditUser, companies, setUserDrawerOpen, setSelectedUser }) => {
  const { data: user } = useUser();

  const [coaches, setCoachs] = useState([]);
  const [filteredCoaches, setFilteredCoachs] = useState([]);

  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    kind: "COACH"
  });
  const [itemsSelected, setItemsSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getCoaches();
  }, [setCoachs, setFilteredCoachs]);

  function getCoaches() {
    AdminServices.getCoaches()
      .then((res) => {
        if (res.data.status === true) {
          setCoachs(res.data.data);
          setFilteredCoachs(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
        // Failure('Oops Something went wrong. Please try again')
      });
  }

  function onSaveUser() {
    if (newUser.first_name !== "" && newUser.last_name !== "" && newUser.email !== "" && newUser.password !== "") {
      // NEW USER
      if (!newUser._id) {
        createNewUser(newUser)
          .then((res) => {
            if (res.data.status) {
              Success("User Created Successfully!");
              setIsOpen(false);
              getCoaches();
              setNewUser({
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                kind: "COACH"
              });
            } else {
              Failure(res.data.message);
              console.log(res.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            Failure("Oops Something went wrong. Please try again");
          });
      }
      // UPDATE USER
      else {
        updateUserName(newUser);
        // updateNewUser(newUser).then((res) => {
        //     Success('User updated Successfully!');
        //     getCoaches();
        //     setNewUser({ first_name: '', last_name: '', email: '', password: '', kind: "COACH" });
        // }).catch((e) => {
        //     console.log(e);
        //     Failure('Oops Something went wrong. Please try again')
        // });
      }
    }
  }

  function updateUserName(pending_user) {
    let userProfile = { ...pending_user, user: pending_user._id };
    updateProfile(userProfile)
      .then((res) => {
        if (res.data.status) {
          Success("User updated Successfully!");
          getCoaches();
          setNewUser({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            kind: "COACH"
          });
          setIsOpen(false);
        } else {
          console.log(res);
          Failure("Something went wrong, Please try again");
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Something went wrong, Please try again");
      });
  }

  function onSearchContacts(value) {
    if (value !== "") {
      const _filtered = coaches.filter(
        (c) =>
          c.email.includes(value.toLowerCase()) ||
          `${c.profile_data?.first_name} ${c.profile_data?.last_name}`.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCoachs(_filtered);
    } else {
      setFilteredCoachs(coaches);
    }
  }

  function onDeleteUser(userId) {
    AdminServices.deleteUser(userId)
      .then(() => {
        Success("User deleted");
        getCoaches();
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  const { mutateAsync } = useGetAuthToken();
  async function onLoginToAccount(e, user) {
    e.stopPropagation();
    await mutateAsync({ user });
  }

  return (
    <div>
      {isOpen && (
        <CreateCoach
          newUser={newUser}
          setNewUser={setNewUser}
          isOpen={isOpen}
          setOpen={setIsOpen}
          companies={companies}
          onCreateUser={() => onSaveUser()}
        />
      )}

      <div className="flex flex-row items-center justify-between p-2 my-4 bg-white">
        <div className="w-full">
          {" "}
          <input
            placeholder="Search Coach..."
            type="text"
            className="w-full max-w-sm rounded-lg"
            onChange={(e) => onSearchContacts(e.target.value)}
          />
        </div>
        {/* Create Company */}
        <div
          className="flex flex-row items-center px-4 py-2 text-sm text-white rounded-md cursor-pointer"
          onClick={() => {
            setNewUser({
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              kind: "COACH"
            });
            setIsOpen(true);
          }}
          style={{ background: "rgb(31, 64, 230)" }}
        >
          <div className="hidden mr-2 -ml-2 sm:inline-block">
            {" "}
            <SVG name="Add" />
          </div>
          <span className="whitespace-nowrap"> Create Coach</span>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        {/* table header */}
        <div className="grid grid-cols-4 p-4 text-sm bg-gray-100 border-b rounded-t-lg xl:text-base">
          <div>Name</div>
          <div>Email</div>
          <div>Industry</div>
          <div>Actions</div>
        </div>

        {filteredCoaches?.map((c, i) => (
          <div
            key={i}
            className={`grid grid-cols-4 p-4text-sm xl:text-base p-4 hover:bg-blue-50 cursor-pointer font-medium ${
              itemsSelected.find(({ _id }) => _id === c._id) === undefined ? "" : "bg-blue-50"
            }`}
            onClick={() => {
              if (itemsSelected.find(({ _id }) => _id === c._id)) {
                const selected = [...itemsSelected].filter((f) => f._id !== c._id);
                setItemsSelected(selected);
              } else {
                const selected = itemsSelected.length > 0 ? [...itemsSelected] : [];
                selected.push(c);
                setItemsSelected(selected);
              }
            }}
          >
            <div className="text-sm">
              <span
                className={`h-3 w-3 rounded mr-2 mt-0 inline-block border ${
                  itemsSelected.find(({ _id }) => _id === c._id) === undefined ? "" : "bg-skin-fill"
                }`}
              />
              {c?.profile_data?.first_name} {c?.profile_data?.last_name}
            </div>
            <div className="text-sm">{c?.email}</div>
            <div className="text-sm">{c?.profile_data?.coaching_industry?.toString()}</div>
            <Stack gap={4}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setUserDrawerOpen(true);
                  setSelectedUser(c._id);
                }}
                kind="ghost"
                iconOnly
              >
                <SVG name="EyeOpen" />
              </Button>
              {user.account_type === "Admin" && (
                <Button iconOnly onClick={(e) => onLoginToAccount(e, c?._id)} kind="ghost">
                  <SVG name="FiExternalLink" />
                </Button>
              )}
            </Stack>
          </div>
        ))}
      </div>

      <LargeScreenModificationPanel
        List={itemsSelected}
        resetList={() => setItemsSelected([])}
        components={<div></div>}
        onDelete={user.account_type === "Admin" ? onDeleteUser : null}
        onEdit={onEditUser}
      />
    </div>
  );
};
