import { ISatisfactionScores, ISession, ISessionNote, ITask, ITopSessionTopicKey } from "./SessionsTypes";
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";

export const sessionQueryKeys = {
  all: [{ scope: "sessions" }] as const,
  ratingTasks: () => [{ ...sessionQueryKeys.all[0], entity: "sessions ratings" }] as const,
  sessionsByPage: () =>
    [
      {
        ...sessionQueryKeys.all[0],
        entity: "paginatedSessions"
      }
    ] as const,
  connectionSessions: ({ completion_status, expertId, from, programId, to, userId, platform, coachingMode }: IUseGetSessionsProps) =>
    [
      {
        ...sessionQueryKeys.all[0],
        entity: "connections",
        expertId,
        userId,
        programId,
        completion_status,
        from,
        to,
        platform,
        coachingMode
      }
    ] as const,
  sessionTimeline: ({ time, program, expert, profiles, user }: ConnectionsSessionsTimelineOptions) =>
    [
      {
        ...sessionQueryKeys.all[0],
        entity: "sessionTimeline",
        program,
        time,
        profiles,
        user,
        expert
      }
    ] as const,
  sessionNotes: (sessionId: string) => [{ ...sessionQueryKeys.all[0], entity: "notes", sessionId }] as const,
  satisfactionScores: ({ type, coachingType, platform }: ISatisfactionScoresOptions) =>
    [
      {
        ...sessionQueryKeys.all[0],
        entity: "satisfaction scores",
        type,
        coachingType,
        platform
      }
    ] as const,
  topTopics: ({ platform, coachingType }: { platform: "coaching" | "mentoring"; coachingType?: "external" | "internal" }) =>
    [
      {
        ...sessionQueryKeys.all[0],
        entity: "top topics",
        coachingType,
        platform
      }
    ] as const,
  sessionById: (sessionId: string) => [{ ...sessionQueryKeys.all[0], entity: "session-by-id", sessionId }] as const
};

async function fetchConnectionSessions({
  queryKey: [{ userId, expertId, programId, completion_status, from, to, platform, coachingMode }]
}: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["connectionSessions"]>>) {
  const res = await apiHttp.get<ServerResponse<ISession[]>>("sessions/raw", {
    params: {
      user: userId,
      expert: expertId,
      program: programId,
      completion_status,
      from,
      to,
      platform,
      ...(coachingMode === "external" && { external: true })
    }
  });

  const sortOrder = ["Pending", "Completed", "No Show"];

  return res.data.data.sort((a, b) => new Date(b.start_time).getTime() - new Date(a.start_time).getTime());
  // .sort(
  //   (a, b) =>
  //     sortOrder.indexOf(a.completion_status) -
  //     sortOrder.indexOf(b.completion_status)
  // );
}

interface ISessionByPageResponse {
  pageno: string;
  sessions: ISession[];
  totalPages: number;
  totalRecords: number;
  totalRecordsPerPage: number;
}

async function fetchSessionByPage({ pageParam }: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["sessionsByPage"]>>) {
  const res = await apiHttp.get<ServerResponse<ISessionByPageResponse>>("sessions/", {
    params: {
      page: pageParam
    }
  });

  return res.data.data;
}

async function fetchConnectionSessionsTimeline({
  queryKey: [{ program, time, profiles, user, expert }]
}: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["sessionTimeline"]>>) {
  if (!user || !expert) {
    throw new Error("User or Expert id is not provided");
  }
  const res = await apiHttp.get<ServerResponse<ISession[]>>("sessions/connections/timeline", {
    params: {
      program,
      time,
      profiles,
      user,
      expert
    }
  });
  return res.data.data;
}

async function fetchSessionNotes({ queryKey: [{ sessionId }] }: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["sessionNotes"]>>) {
  const res = await apiHttp.get<ServerResponse<ISessionNote[]>>("/sessions/notes", {
    params: { session: sessionId }
  });
  return res.data.data;
}

async function fetchSessionById({ queryKey: [{ sessionId }] }: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["sessionById"]>>) {
  const res = await apiHttp.get<ServerResponse<ISession>>("/sessions/session-by-id/" + sessionId);
  return res.data.data;
}

async function fetchSatisfactionScores({
  queryKey: [{ coachingType, platform, type }]
}: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["satisfactionScores"]>>) {
  const res = await apiHttp.get<ServerResponse<ISatisfactionScores>>("sessions/satisfaction-score", {
    params: {
      platform,
      type,
      ...(coachingType === "external" && { external: true })
    }
  });
  return res.data.data;
}

async function fetchTopSessionsTopics({
  queryKey: [{ platform, coachingType }]
}: QueryFunctionContext<ReturnType<typeof sessionQueryKeys["topTopics"]>>) {
  const res = await apiHttp.get<ServerResponse<ITopSessionTopicKey[]>>("sessions/top-topics", {
    params: {
      platform,
      ...(coachingType === "external" && { external: true })
    }
  });
  return res.data.data;
}

async function fetchRatingTasks() {
  const res = await apiHttp.get<ServerResponse<ITask[]>>("sessions/tasks?type=rating");
  return res.data.data.filter((t) => t.session !== null).reverse();
}

interface IUseGetSessionsProps {
  /**
   * can be used to cross with mentor/coach
   */
  expertId?: string;
  /**
   * The person id who we need to fetch sessions for
   */
  userId?: string;
  programId?: string;
  completion_status?: ISession["completion_status"];
  from?: string;
  to?: string;
  platform?: "coaching" | "mentoring";
  coachingMode?: "external" | "internal";
}

export const useGetConnectionSessions = <SelectData = ISession[], Error = unknown>(
  { expertId, userId, programId, completion_status, from, to, platform, coachingMode }: IUseGetSessionsProps,
  options?: UseQueryOptions<ISession[], Error, SelectData, ReturnType<typeof sessionQueryKeys["connectionSessions"]>>
) => {
  return useQuery<ISession[], Error, SelectData, ReturnType<typeof sessionQueryKeys["connectionSessions"]>>(
    sessionQueryKeys.connectionSessions({
      completion_status,
      expertId,
      from,
      programId,
      to,
      userId,
      platform,
      coachingMode
    }),
    fetchConnectionSessions,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetSessionByPage = <SelectData = ISessionByPageResponse, Error = unknown>(
  options?: UseInfiniteQueryOptions<any[], Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionsByPage"]>>
) => {
  return useInfiniteQuery<ISessionByPageResponse, Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionsByPage"]>>(
    sessionQueryKeys.sessionsByPage(),
    fetchSessionByPage,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.pageno < lastPage.totalPages) {
          return +lastPage.pageno + 1;
        }
        return undefined;
      }
    }
  );
};

interface ConnectionsSessionsTimelineOptions {
  program?: string | null;
  time: "month" | "week";
  profiles?: boolean;
  user: string;
  expert: string;
}

export const useGetConnectionSessionTimeline = <SelectData = any[], Error = unknown>(
  { program, time, profiles, user, expert }: ConnectionsSessionsTimelineOptions,
  options?: UseQueryOptions<any[], Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionTimeline"]>>
) => {
  return useQuery<any[], Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionTimeline"]>>(
    sessionQueryKeys.sessionTimeline({ user, expert, program, time, profiles }),
    fetchConnectionSessionsTimeline,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true,
      ...options
    }
  );
};

export const useGetSessionNotes = <SelectData = ISessionNote[], Error = unknown>(
  {
    sessionId
  }: {
    sessionId: string;
  },
  options?: UseQueryOptions<any[], Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionNotes"]>>
) => {
  return useQuery<ISessionNote[], Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionNotes"]>>(
    sessionQueryKeys.sessionNotes(sessionId),
    fetchSessionNotes,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      keepPreviousData: true
    }
  );
};
export const useGetOneSession = <SelectData = ISession, Error = unknown>(
  {
    sessionId
  }: {
    sessionId: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionById"]>>
) => {
  return useQuery<ISession, Error, SelectData, ReturnType<typeof sessionQueryKeys["sessionById"]>>(
    sessionQueryKeys.sessionById(sessionId),
    fetchSessionById,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      keepPreviousData: true
    }
  );
};

export interface ISatisfactionScoresOptions {
  platform?: "mentoring" | "coaching";
  type?: "1:1" | "team";
  coachingType?: "external" | "internal";
}

export const useGetSatisfactionScores = <SelectData = ISatisfactionScores, Error = unknown>(
  { type, coachingType, platform }: ISatisfactionScoresOptions,
  options?: UseQueryOptions<ISatisfactionScores, Error, SelectData, ReturnType<typeof sessionQueryKeys["satisfactionScores"]>>
) => {
  return useQuery<ISatisfactionScores, Error, SelectData, ReturnType<typeof sessionQueryKeys["satisfactionScores"]>>(
    sessionQueryKeys.satisfactionScores({ type, coachingType, platform }),
    fetchSatisfactionScores,
    {
      suspense: true,
      staleTime: DURATIONS.fifteenMins,
      keepPreviousData: true,
      ...options
    }
  );
};

export interface UseGetTopSessionsTopicsProps {
  platform: "mentoring" | "coaching";
  coachingType?: "external" | "internal";
}

export const useGetTopSessionsTopics = <SelectData = ITopSessionTopicKey[], Error = unknown>(
  { platform, coachingType }: UseGetTopSessionsTopicsProps,
  options?: UseQueryOptions<ITopSessionTopicKey[], Error, SelectData, ReturnType<typeof sessionQueryKeys["topTopics"]>>
) => {
  return useQuery<ITopSessionTopicKey[], Error, SelectData, ReturnType<typeof sessionQueryKeys["topTopics"]>>(
    sessionQueryKeys.topTopics({ platform, coachingType }),
    fetchTopSessionsTopics,
    {
      suspense: true,
      staleTime: DURATIONS.fifteenMins,
      keepPreviousData: true,
      ...options
    }
  );
};

export const useGetPendingRatingTasks = <SelectData = ITask[], Error = unknown>(options?: UseQueryOptions<ITask[], Error, SelectData>) => {
  return useQuery<ITask[], Error, SelectData>(sessionQueryKeys.ratingTasks(), fetchRatingTasks, {
    staleTime: DURATIONS.fifteenMins,
    ...options
  });
};
