import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MicrolearningService from "../../../../../../services/learning/microlearning.service";
import ContentArea from "../../../../../../components/contentArea/contentArea";
import FileTypeLength from "../../../../../../components/fileTypeLength/fileTypeLength";
import ContentTable from "./contentTable";
import Utils from "../../../../../../services/utils/utils";
import ReactImageFallback from "react-image-fallback";
import { Success } from "../../../../../../components/ToastNotification/Success";
import { BackButton } from "../../../../../../components/nav/BackButton";
import { UserStore } from "../../../../../../RoleManagement/Discriminator";

export default function SingleJourney() {
  const { currentRole } = UserStore();
  const user = JSON.parse(localStorage.getItem("user"));


  const  setStarted = useState(false)[1];
  const setCompleted = useState(false)[1];
  const  setBookmark = useState(false)[1];
  let { slug } = useLocation().state;

  const [journeyDetail, setJourneyDetail] = useState({});

  const getPercentCompleted = (completedTime, totalTime) => {
    return (completedTime / totalTime).toFixed(2) * 100 + "%";
  };

  const getJourneyDetails = (id) => {
    MicrolearningService.getJourneyDetails(id).then(
      (response) => {
        if (response.status) {
          setJourneyDetail(response.data.data);
          // console.log(response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getJourneyDetails(slug);
  }, [slug]);

  const openUrl = (url, resource_id) => {
    window.open(url);
    if (user && user.data && currentRole !== "Hr") {
      markStarted(resource_id);
    }
  };

  const markStarted = (resource_id) => {
    MicrolearningService.markStarted(resource_id).then(
      (response) => {
        if (response.data.status) {
          setStarted(true);
          console.log(response.data);
        } else {
          console.log(response.status.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const markCompleted = (status, resource_id) => {
    MicrolearningService.markCompleted(!status, resource_id).then(
      (response) => {
        if (response.status) {
          getJourneyDetails(slug);
          setCompleted((completed) => !status);
          // console.log(completed);
        } else {
          console.log(response.status.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const bookmarkArticle = (status, resource_id) => {
    MicrolearningService.bookmarkArticle(!status, resource_id).then(
      (response) => {
        if (response.status) {
          setBookmark(!status);
          getJourneyDetails(slug);
          // console.log(response);
        } else {
          console.log(response);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const subscribeJourney = (status, journey_id) => {
    MicrolearningService.subscribeJourney(status, journey_id).then(
      (response) => {
        if (response.status) {
          getJourneyDetails(slug);
          Success(
            `You have ${
              !journeyDetail.subscribed ? "subscribed" : "unsubscribed"
            } successfully!`
          );
        } else {
          console.log(response);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div className="grid gap-4">
      <BackButton />

      <div className="flex items-center justify-center -m-1">
        <div className="relative flex flex-col items-center w-full px-4 py-16 mx-auto text-center shadow lg:block bg-gradient-to-br from-blue-600 via-indigo-500 to-blue-400 sm:px-6 md:pb-0 md:pt-12 lg:px-12 lg:py-12">
          <h2 className="my-4 text-2xl font-extrabold tracking-tight text-white sm:text-3xl md:text-4xl lg:my-0 xl:text-3xl sm:leading-tight">
            {journeyDetail.title}
          </h2>
          <p className="mt-1 mb-5 text-sm font-medium text-indigo-200 uppercase xl:text-base xl:tracking-wider">
            {journeyDetail.sections && journeyDetail.sections.length} sections •
            11 items • {Utils.getFormattedTimeInHours(journeyDetail.totalTime)}s
          </p>
          <p className="mt-1 mb-10 text-xs font-medium text-gray-100 xl:text-base xl:tracking-wider lg:mb-0">
            {journeyDetail.description}
          </p>
          {user && user.data && currentRole !== "Hr" && (
            <div className="flex items-center justify-center mb-6 lg:mt-6 lg:mb-0">
              <div className="inline-flex">
                <button
                  onClick={() =>
                    subscribeJourney(
                      !journeyDetail.subscribed,
                      journeyDetail._id
                    )
                  }
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium transition duration-150 ease-in-out bg-indigo-100 border border-transparent rounded-md text-skin-accent hover:text-blue-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300"
                >
                  <svg
                    className="w-4 h-4 mr-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {journeyDetail.subscribed ? (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    ) : (
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      ></path>
                    )}
                  </svg>
                  {user && user.data && currentRole !== "Hr" && (
                    <>{journeyDetail.subscribed ? "Subscribed" : "Subscribe"}</>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <ContentTable data={journeyDetail} />
        <div className="flex flex-col w-full h-screen overflow-y-hidden ">
          <div className="flex flex-col w-full h-screen overflow-x-hidden text-gray-500 border-t scrollhost2 ">
            <ContentArea>
              <div className="px-6 pt-6 overflow-hidden lg:w-3/4">
                {user && user.data && currentRole !== "Hr" && (
                  <div className="flex flex-row justify-end">
                    <div className="w-1/2 bg-white border">
                      <div
                        className="py-1 text-xs leading-none text-center text-white bg-gradient-to-br from-blue-700 via-indigo-500 to-blue-400"
                        style={{
                          width:
                            journeyDetail &&
                            getPercentCompleted(
                              journeyDetail.completedCount,
                              journeyDetail.contentCount
                            )
                        }}
                      >
                        {journeyDetail &&
                          getPercentCompleted(
                            journeyDetail.completedCount,
                            journeyDetail.contentCount
                          )}{" "}
                        {" completed"}
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap">
                  <div className="w-full py-4">
                    <div>
                      {Object.keys(journeyDetail).length !== 0 &&
                        journeyDetail.sections.map((detail, i) => {
                          return (
                            <div key={i + Math.random()}>
                              <div className="px-6 py-4 mt-4 text-gray-700 bg-gray-100 border border-gray-200">
                                <h2 className="text-lg font-bold tracking-tight sm:leading-tight">
                                  {`${i + 1}. ${detail.title}`}
                                </h2>
                                <p className="py-2 text-xs font-medium">
                                  {detail.content.length} items •{" "}
                                  {Utils.getFormattedTimeInHours(
                                    detail.sectionTime
                                  )}
                                </p>
                              </div>
                              {detail.content.map((content) => {
                                return (
                                  <div key={i + Math.random()}>
                                    <div
                                      id={content._id}
                                      className="flex px-6 py-4 border border-t-0 border-gray-200 "
                                    >
                                      <div className="w-4/5">
                                        <span className="inline-flex items-center px-3 py-1 mr-4 -mb-3 text-xs font-medium leading-tight text-gray-800 bg-gray-100 border rounded-full">
                                          <FileTypeLength
                                            time={Utils.getFormattedTimeInHours(
                                              content.time
                                            )}
                                            type={content.type}
                                          />
                                        </span>
                                        <button
                                          onClick={() => {
                                            openUrl(content.url, content._id);
                                          }}
                                          className="block py-2 text-sm font-semibold leading-7 text-left text-gray-900 lg:text-base hover:underline"
                                        >
                                          {content.title}
                                        </button>
                                        <p className="pb-2 text-sm leading-6 text-gray-500">
                                          {content.source.name}
                                        </p>
                                        {user &&
                                          user.data &&
                                          currentRole !== "Hr" && (
                                            <p className="mt-3 text-sm font-medium leading-5">
                                              <button
                                                onClick={() =>
                                                  bookmarkArticle(
                                                    content.bookmarked,
                                                    content._id
                                                  )
                                                }
                                                className="relative inline-block mr-2 tooltip"
                                              >
                                                <span
                                                  className={
                                                    "inline-flex items-center p-1 text-xs font-medium leading-tight rounded-full" +
                                                    " " +
                                                    (content.bookmarked
                                                      ? "text-white border bg-yellow-400 border-yellow-400"
                                                      : "text-gray-200 border border-gray-200")
                                                  }
                                                >
                                                  <svg
                                                    className="w-4 h-4"
                                                    fill="currentColor"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      strokeWidth="2"
                                                      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                <span className="p-3 -mt-6 -ml-6 text-white bg-gray-400 rounded tooltip-text">
                                                  Bookmark
                                                </span>
                                              </button>
                                              <button
                                                onClick={() =>
                                                  markCompleted(
                                                    content.completed,
                                                    content._id
                                                  )
                                                }
                                                className={
                                                  "inline-block tooltip relative mr-2"
                                                }
                                              >
                                                <span
                                                  className={
                                                    "inline-flex items-center p-1 text-xs font-medium leading-tight rounded-full" +
                                                    " " +
                                                    (content.completed
                                                      ? "text-white border bg-green-400 border-green-400"
                                                      : "text-gray-200 border border-gray-200")
                                                  }
                                                >
                                                  <svg
                                                    className="w-4 h-4"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      strokeWidth="2"
                                                      d="M5 13l4 4L19 7"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                <span className="p-3 -mt-6 -ml-6 text-white bg-gray-400 rounded tooltip-text">
                                                  Completed
                                                </span>
                                              </button>
                                            </p>
                                          )}
                                      </div>
                                      <div className="flex w-1/5">
                                        <ReactImageFallback
                                          src={content.source.image}
                                          initialImage="loader.gif"
                                          fallbackImage={<div> </div>}
                                          alt="Learn material image"
                                          className="object-contain object-center w-full align-middle"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </ContentArea>
          </div>
        </div>
      </div>
    </div>
  );
}
