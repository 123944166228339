import { Filter } from "./FiltersBar.types";

export function createFilter<TData extends any[], TName extends string>(data: TData[], key: TName): Filter<TName, TData> {
  return null;
}

export const systemTextMapping = (filter: string) => {
  const mappings: any = {
    mentor: "Mentor",
    mentee: "Mentee",
    coach: "Coach",
    coachee: "Coachee",
    user: "User",
    expert: "Expert",
    COMPLETED: "Completed",
    IN_PROGRESS: "In progress",
    approved: "Approved",
    cancelled: "Cancelled",
    pending: "Pending",
    rejected: "Rejected",
    withdrawn: "Withdrawn",
    "external-coaching": "External coaching",
    "internal-coaching": "Internal coaching",
    mentoring: "Mentoring"
  };

  const text = mappings[filter];

  if (!text) return filter;

  return text;
};
