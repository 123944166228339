import { useEffect, useState } from "react";
import { useMemo } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import hrDashboardServices, {
  setProgramCoachRecommendations
} from "../../../../services/dashboard/hrDashboard.services";
import { PROGRAM_APPLICATION_TYPES } from "../../../../services/utils/app.constant";
import { ProgramCard } from "../../../mentoring/hrDashboard/Programs/Components/ProgramCard";
import Select from "react-select";
import { Success } from "../../../../components/ToastNotification/Success";
import { getMentorMatches } from "../../../../services/mentoring/mentoring.service";
const initialCoachSelections = {
  0: null,
  1: null,
  2: null
};
export const ProgramMatchMaking = () => {
  const [companies, setCompanies] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [applications, setApplications] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedApplication, setSelectedApplication] = useState();
  const [coaches, setCoaches] = useState([]);
  const [coachSelections, setCoachSelections] = useState(
    initialCoachSelections
  );
  useEffect(() => {
    AdminServices.getCompanies()
      .then((res) => {
        if (res.data.status) {
          setCompanies(res.data.data);
        }
      })
      .catch((e) => console.log(e));
    getCoaches();
  }, [setCompanies]);

  function getPrograms(organizationId) {
    setSelectedCompany(organizationId);
    hrDashboardServices
      .getProgramByQuery("organizations/" + organizationId)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          let sorted = data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          const filtered = sorted.filter(
            (s) =>
              !s.deleted &&
              s.program_type === "Coaching" &&
              s.coach_type === "knack_coaching"
          );
          setPrograms(filtered);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getProgramApplications(program) {
    hrDashboardServices
      .getProgramInvitations(program._id, PROGRAM_APPLICATION_TYPES.COACHEE)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setApplications(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Contact support");
      });
  }

  function getCoaches() {
    AdminServices.getCoaches()
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setCoaches(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please contact support team");
      });
  }

  function getMatches(user) {
    getMentorMatches(`?user=${user}&program=${selectedProgram._id}`)
      .then((res) => {
        const { data } = res.data;
        const preSelections = data.map((c) => {
          return {
            value: `${c.profile[0].first_name} ${c.profile[0].last_name}`,
            label: `${c.profile[0].first_name} ${c.profile[0].last_name}`,
            _id: c.mentor._id
          };
        });
        setCoachSelections((prev) => {
          return {
            ...prev,
            ...Object.assign({}, preSelections)
          };
        });
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please contact support team");
      });
  }

  const onSaveCoachesChanges = async () => {
    try {
      const matches = Object.values(coachSelections).map((c) => c?._id);

      const res = await setProgramCoachRecommendations(
        selectedApplication.user._id,
        selectedProgram._id,
        matches
      );
      if (res.data.status) {
        Success("Coach recommendations updated successfully !");
      } else {
        Failure("Oops Something went wrong. Please contact support team");
      }
    } catch (e) {
      console.log(e);
      Failure("Oops Something went wrong. Please contact support team");
    }
  };

  const coachesOptions = useMemo(() => {
    return coaches
      .map((c) => ({
        value: c?.profile_data?.first_name + " " + c?.profile_data?.last_name,
        label: c?.profile_data?.first_name + " " + c?.profile_data?.last_name,
        _id: c._id
      }))
      .filter((f) => !f.label.includes("undefined"));
  }, [coaches]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "0.5fr 0.5fr 1fr",
        gap: "1rem"
      }}
    >
      {/* Companies */}
      <div
        className={`grid grid-flow-row h-screen gap-4 scrollhost2 overflow-auto border-r-2 pr-4`}
      >
        {companies.map((res, i) => (
          <button
            onClick={() => getPrograms(res._id)}
            key={i}
            className={`h-auto flex w-auto flex-col p-4 border justify-center items-center text-center hover:bg-gray-200 ${
              selectedCompany === res._id ? "bg-skin-light border-2" : ""
            }`}
            style={{ height: "fit-content" }}
          >
            <img src={res?.logo} alt={res?.name} />
            <span className="text-2xl font-bold">{res?.name}</span>
          </button>
        ))}
      </div>
      {/* Programs */}
      {!selectedCompany && (
        <div className="flex items-center justify-center h-screen p-4 pr-4 border-r-2 shadow">
          Select a compnany to view programs
        </div>
      )}
      {selectedCompany && (
        <div
          className={`grid grid-flow-row h-screen gap-4 scrollhost2 overflow-auto border-r-2 pr-4`}
        >
          {programs?.map((r, i) => (
            <div
              style={{ height: "fit-content" }}
              onClick={() => {
                getProgramApplications(r);
                setSelectedProgram(r);
                setSelectedApplication(null);
                setCoachSelections(initialCoachSelections);
              }}
              key={i}
              className={`w-full h-auto rounded-lg ${
                selectedProgram?._id === r._id
                  ? "border-2 border-skin-accent"
                  : ""
              }`}
            >
              <ProgramCard index={i} program={r} />
            </div>
          ))}
        </div>
      )}
      {!selectedProgram && (
        <div className="flex items-center justify-center h-screen p-4 shadow">
          Select a Program to view applications
        </div>
      )}
      {!selectedApplication && selectedProgram && applications.length === 0 && (
        <div className="flex items-center justify-center h-screen p-4 shadow">
          No applications for this program yet
        </div>
      )}
      {!selectedApplication && selectedProgram && applications.length > 0 && (
        <div className="bg-white rounded-lg shadow">
          {/* table header */}
          <div className="p-4 text-sm font-bold border-b xl:text-base">
            <div>Applications</div>
          </div>
          <div className="grid grid-cols-2 p-4 text-sm font-bold border-b xl:text-base">
            <div>Name</div>
            <div>Email</div>
          </div>
          {applications.map((c, i) => (
            <div key={i}>
              <div
                onClick={() => {
                  setSelectedApplication(c);
                  getMatches(c.user._id);
                }}
                className={`grid grid-cols-2 p-4text-sm xl:text-base p-4 hover:bg-gray-100 transition delay-350 duration-350`}
              >
                <div className="text-sm">
                  {c.first_name} {c.last_name}
                </div>
                <div>{c.email}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedApplication && (
        <div>
          <h1 className="text-xl font-bold">
            Application for {selectedApplication.email}
          </h1>
          <div className="p-4 mt-4 bg-white rounded-lg shadow">
            <div className="mb-2">
              <h1 className="mb-2 text-xl font-bold">Coach Gender</h1>
              <p>{selectedApplication.mentor_gender_preference || "-"}</p>
            </div>

            <div className="mb-2">
              <h1 className="mb-2 text-xl font-bold">
                Coach Language Preference
              </h1>
              <p>{selectedApplication.coach_language_preference || "-"}</p>
            </div>
            <div className="mb-2">
              <h1 className="mb-2 text-xl font-bold">Selected Skills</h1>
              <div>
                {selectedApplication.selectedSkills.map((s) => (
                  <p key={s}>{s}</p>
                ))}
                {selectedApplication.selectedSkills.length === 0 && (
                  <div className="flex items-center justify-center p-4">
                    No Skills were selected
                  </div>
                )}
              </div>
            </div>
            <div>
              <h1 className="mb-2 text-xl font-bold">
                Coaches Recommendations
              </h1>
              <div className="mb-2">
                <label className="block mb-1">First Coach</label>
                <Select
                  value={coachSelections["0"]}
                  onChange={(e) => {
                    if (e) {
                      setCoachSelections((prev) => ({
                        ...prev,
                        0: e
                      }));
                    } else {
                      setCoachSelections((prev) => ({
                        ...prev,
                        0: null
                      }));
                    }
                  }}
                  className="rounded-lg"
                  isOptionDisabled={(option, value) => {
                    const found = Object.values(coachSelections).find((i) =>
                      i ? i._id === option._id : false
                    );
                    return Boolean(found);
                  }}
                  isClearable={true}
                  isSearchable={true}
                  options={coachesOptions}
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Second Coach</label>
                <Select
                  value={coachSelections["1"]}
                  onChange={(e) => {
                    if (e) {
                      setCoachSelections((prev) => ({
                        ...prev,
                        1: e
                      }));
                    } else {
                      setCoachSelections((prev) => ({
                        ...prev,
                        1: null
                      }));
                    }
                  }}
                  className="rounded-lg"
                  isOptionDisabled={(option) => {
                    const found = Object.values(coachSelections).find((i) =>
                      i ? i._id === option._id : false
                    );
                    return Boolean(found);
                  }}
                  isClearable={true}
                  isSearchable={true}
                  options={coachesOptions}
                />
              </div>
              <div className="mb-2">
                <label className="block mb-1">Third Coach</label>
                <Select
                  value={coachSelections["2"]}
                  onChange={(e) => {
                    if (e) {
                      setCoachSelections((prev) => ({
                        ...prev,
                        2: e
                      }));
                    } else {
                      setCoachSelections((prev) => ({
                        ...prev,
                        2: null
                      }));
                    }
                  }}
                  className="rounded-lg"
                  isOptionDisabled={(option) => {
                    const found = Object.values(coachSelections).find((i) =>
                      i ? i._id === option._id : false
                    );
                    return Boolean(found);
                  }}
                  isClearable={true}
                  isSearchable={true}
                  options={coachesOptions}
                />
              </div>
            </div>
            <div className="flex items-center justify-end">
              <button
                onClick={onSaveCoachesChanges}
                className="px-4 py-2 text-sm text-white border rounded-lg whitespace-nowrap bg-skin-accent"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
