const GoalsInProgress = ({ className, size }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size || "70"}
      height={size || "71"}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4597 27.93C10.6863 27.7433 9.04134 27.2533 7.52467 26.46C6.008 25.6667 4.6955 24.64 3.58717 23.38C2.47883 22.12 1.60967 20.685 0.979668 19.075C0.349668 17.465 0.034668 15.7617 0.034668 13.965C0.034668 10.3483 1.21883 7.21 3.58717 4.55C5.9555 1.89 8.92467 0.373333 12.4947 0V2.1C9.508 2.54333 7.03467 3.885 5.07467 6.125C3.11467 8.365 2.13467 10.9783 2.13467 13.965C2.13467 16.9517 3.10884 19.565 5.05717 21.805C7.0055 24.045 9.473 25.3867 12.4597 25.83V27.93ZM15.5397 27.93V25.83C16.613 25.69 17.6455 25.4158 18.6372 25.0075C19.6288 24.5992 20.5447 24.0567 21.3847 23.38L22.9247 24.92C21.8513 25.7367 20.6905 26.4133 19.4422 26.95C18.1938 27.4867 16.893 27.8133 15.5397 27.93ZM21.4197 4.585C20.533 3.955 19.5997 3.42417 18.6197 2.9925C17.6397 2.56083 16.613 2.26333 15.5397 2.1V0C16.893 0.163333 18.188 0.5075 19.4247 1.0325C20.6613 1.5575 21.828 2.22833 22.9247 3.045L21.4197 4.585ZM24.9197 22.75L23.4147 21.28C24.0913 20.4167 24.628 19.4892 25.0247 18.4975C25.4213 17.5058 25.6897 16.4733 25.8297 15.4H27.9647C27.8013 16.7533 27.463 18.0542 26.9497 19.3025C26.4363 20.5508 25.7597 21.7 24.9197 22.75ZM25.8297 12.53C25.6897 11.4567 25.4213 10.4183 25.0247 9.415C24.628 8.41167 24.0913 7.49 23.4147 6.65L25.0597 5.215C25.8763 6.28833 26.5297 7.44333 27.0197 8.68C27.5097 9.91667 27.8247 11.2 27.9647 12.53H25.8297Z"
        fill="currentColor"
      />
      <path
        d="M6.51 15.75C7 15.75 7.41417 15.5808 7.7525 15.2425C8.09083 14.9042 8.26 14.49 8.26 14C8.26 13.51 8.09083 13.0958 7.7525 12.7575C7.41417 12.4192 7 12.25 6.51 12.25C6.02 12.25 5.60583 12.4192 5.2675 12.7575C4.92917 13.0958 4.76 13.51 4.76 14C4.76 14.49 4.92917 14.9042 5.2675 15.2425C5.60583 15.5808 6.02 15.75 6.51 15.75ZM14 15.75C14.49 15.75 14.9042 15.5808 15.2425 15.2425C15.5808 14.9042 15.75 14.49 15.75 14C15.75 13.51 15.5808 13.0958 15.2425 12.7575C14.9042 12.4192 14.49 12.25 14 12.25C13.51 12.25 13.0958 12.4192 12.7575 12.7575C12.4192 13.0958 12.25 13.51 12.25 14C12.25 14.49 12.4192 14.9042 12.7575 15.2425C13.0958 15.5808 13.51 15.75 14 15.75ZM21.455 15.75C21.945 15.75 22.3592 15.5808 22.6975 15.2425C23.0358 14.9042 23.205 14.49 23.205 14C23.205 13.51 23.0358 13.0958 22.6975 12.7575C22.3592 12.4192 21.945 12.25 21.455 12.25C20.965 12.25 20.5508 12.4192 20.2125 12.7575C19.8742 13.0958 19.705 13.51 19.705 14C19.705 14.49 19.8742 14.9042 20.2125 15.2425C20.5508 15.5808 20.965 15.75 21.455 15.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GoalsInProgress;
