import { Button, Input, Typography } from "knack-ui";
import {
  Controller,
  FieldError,
  SubmitHandler,
  useForm
} from "react-hook-form";
import SVG from "../../../../components/SVG/SVG";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import { useState } from "react";
import {
  useGetOrganizationPrograms,
  useGetProgramParticipants
} from "../../../../features/Programs/programQueries";
import {
  useGetExternalCoaches,
  useGetOrganizations
} from "../../../../features/Company/companyQueries";
import { IOrganization } from "../../../../features/User/UserTypes";
import { useCreateTeam } from "../../../../features/Team/teamMutations";
import { SuccessInformation } from "../../../../components/Information/SuccessInformation";
import { FailureInformation } from "../../../../components/Information/FailureInformation";
import { useGetOneTeam } from "../../../../features/Team/teamQueries";
import { ITeamPayload } from "../../../../features/Team/TeamTypes";
import { FieldErrorLabel } from "../../../../components/ErrorViews/FieldErrorLabel";
interface ITeamForm {
  name: string;
  coach: { label: string; value: string };
  program: { label: string; value: string };
  members: { label: string; value: string }[];
  organization: { label: string; value: string };
}

interface AddEditTeamModalProps {
  onClose: VoidFunction;
  teamId?: string;
}

export const AddEditTeamModal = ({
  onClose,
  teamId
}: AddEditTeamModalProps) => {
  const { data: selectedTeam } = useGetOneTeam({ id: teamId });
  const { data: coaches, isLoading: isCoachesLoading } = useGetExternalCoaches({
    select: (comp) =>
      comp.map((c) => ({
        ...c,
        label: `${c?.profile_data?.first_name || c.email} ${
          c?.profile_data?.last_name || ""
        }`,
        value: c._id
      }))
  });
  const { data: companies } = useGetOrganizations({
    select: (comp) => comp.map((c) => ({ ...c, label: c.name, value: c._id }))
  });

  const [company, selectedCompany] = useState();
  const [program, selectedProgram] = useState(selectedTeam?.program?._id);

  const { data: programs, isLoading } = useGetOrganizationPrograms(
    {
      organizationId: selectedTeam?.organization?._id || company
    },
    {
      select: (prog) =>
        prog
          .filter(
            (p) =>
              p.program_type === "Coaching" &&
              p.coach_type === "knack_coaching" &&
              p.plan === "group"
          )
          .map((c) => ({ ...c, label: c.name, value: c._id })),
      enabled: Boolean(selectedTeam?.organization?._id || company)
    }
  );

  const { data: members, isLoading: isParticipantsLoading } =
    useGetProgramParticipants(
      { programId: program, type: "coachee" },
      {
        enabled: Boolean(program),
        select: (members) =>
          members
            .filter((f) => f !== null)
            .map((m) => ({
              label: `${m.profile.first_name} ${m.profile.last_name}`,
              value: m.profile.user?._id
            }))
      }
    );

  const {
    register,
    control,
    formState: { errors },
    handleSubmit
  } = useForm<ITeamForm>({
    defaultValues: {
      name: selectedTeam?.name,
      ...(selectedTeam?.organization?._id
        ? {
            organization: {
              value: selectedTeam?.organization?._id,
              label: selectedTeam?.organization?.name
            }
          }
        : {}),
      ...(selectedTeam?.coach
        ? {
            coach: {
              value: selectedTeam?.coach?.user?._id,
              label: `${selectedTeam?.coach?.first_name} ${selectedTeam?.coach?.last_name}`
            }
          }
        : {}),
      ...(selectedTeam?.program?._id
        ? {
            program: {
              value: selectedTeam?.program?._id,
              label: selectedTeam?.program?.name
            }
          }
        : {}),
      members:
        selectedTeam?.members
          ?.filter((f) => f !== null)
          ?.map((m) => ({
            label: `${m?.first_name} ${m?.last_name}`,
            value: m?.user?._id
          })) || []
    }
  });

  const { mutateAsync: addEditTeam } = useCreateTeam();

  const onSubmit: SubmitHandler<ITeamForm> = async (data) => {
    const ids = data.members.map((m) => m.value);

    const payload: ITeamPayload = {
      ...data,
      coach: data?.coach?.value,
      program: data?.program?.value,
      members: ids,
      organization: data.organization.value,
      ...(selectedTeam?._id ? { _id: selectedTeam._id } : {})
    } as ITeamPayload;

    addEditTeam(payload)
      .then(() => {
        onClose();
        SuccessInformation({ message: "Team created successfully" });
      })
      .catch(() => {
        FailureInformation({ message: "Error occurred" });
      });
  };

  return (
    <div className="grid h-full gap-4 p-4 pb-32">
      <div dir="ltr" className="flex items-center">
        <Typography variant="h6" as="h2">
          {selectedTeam?._id ? "Edit Team" : "Add Team"}
        </Typography>

        <Button
          className="z-10 end-4"
          style={{ position: "absolute" }}
          onClick={onClose}
          kind="ghost"
          variant="small"
          iconOnly
        >
          <SVG name="Close" />
        </Button>
      </div>

      <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Typography className="block mb-2" variant="subtitle2" as="label">
            Team Name
          </Typography>
          <Input
            placeholder="Enter Team name"
            {...register("name", { required: "Required" })}
            error={errors?.name?.message}
          />
        </div>
        <div>
          <Typography className="block mb-2" variant="subtitle2" as="label">
            Link team to an organization
          </Typography>
          <Controller
            rules={{ required: "Required" }}
            control={control}
            name="organization"
            render={({ field: { value, onChange } }) => (
              <KnackSelect
                isDisabled={selectedTeam?._id ? true : false}
                placeholder="Select an organization"
                isSearchable={true}
                options={companies}
                isClearable
                noOptionsMessage={() => "No organization found"}
                value={value}
                onChange={(option: IOrganization | any) => {
                  onChange(option);
                  selectedCompany(option?._id ?? null);
                }}
                isLoading={isLoading}
              />
            )}
          />
          <FieldErrorLabel error={errors?.organization as FieldError} />
        </div>

        <div>
          <Typography className="block mb-2" variant="subtitle2" as="label">
            Link team to a program
          </Typography>
          <Controller
            control={control}
            name="program"
            rules={{ required: "Required" }}
            render={({ field: { value, onChange } }) => (
              <KnackSelect
                placeholder="Select a program"
                isSearchable={false}
                options={programs}
                isClearable
                noOptionsMessage={() => "No programs found"}
                value={value}
                onChange={(option: any) => {
                  onChange(option);
                  selectedProgram(option?._id ?? null);
                }}
                isLoading={isLoading}
              />
            )}
          />
          <FieldErrorLabel error={errors?.program as FieldError} />
        </div>

        <div>
          <Typography className="block mb-2" variant="subtitle2" as="label">
            Select an external coach
          </Typography>
          <Controller
            rules={{ required: "Required" }}
            control={control}
            name="coach"
            render={({ field: { value, onChange } }) => {
              return (
                <KnackSelect
                  placeholder="Select an external coach"
                  isSearchable={true}
                  options={coaches}
                  isClearable
                  noOptionsMessage={() => "No coaches found"}
                  value={value}
                  onChange={(option: any) => {
                    onChange(option);
                  }}
                  isLoading={isCoachesLoading}
                />
              );
            }}
          />
          <FieldErrorLabel error={errors?.coach as FieldError} />
        </div>

        <div>
          <Typography className="block mb-2" variant="subtitle2" as="label">
            Add members to team
          </Typography>
          <Controller
            control={control}
            name="members"
            rules={{ required: "Required" }}
            render={({ field: { value, onChange } }) => {
              return (
                <KnackSelect
                  isMulti
                  placeholder="Select 1 or more members"
                  isSearchable={false}
                  options={members}
                  isClearable
                  noOptionsMessage={() => "No members found"}
                  value={value}
                  onChange={(option: any) => {
                    onChange(option);
                  }}
                  isLoading={isParticipantsLoading}
                  closeMenuOnSelect={false}
                />
              );
            }}
          />
          <FieldErrorLabel error={errors?.members as unknown as FieldError} />
        </div>

        <div className="flex justify-end w-full">
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
};
