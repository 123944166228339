import { format } from "date-fns/esm";
import React, { useMemo } from "react";
import Chart from "../../../components/Charts/Chart";
import { resizeObserver } from "../../../lib/utils";

interface IProps {
  timelines: any[];
}

const SessionsTimelineChart = ({ timelines }: IProps) => {
  const chartOptions = useMemo(() => {
    if (timelines) {
      return {
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timelines.map((s) =>
            format(new Date(s.key_as_string), "MMMM y")
          )
        },
        yAxis: {
          type: "value"
        },

        series: [
          {
            name: "Sessions Completed",
            data: timelines.map((s) => s.doc_count),
            type: "line",
            smooth: true,
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" }
              ]
            },
            markLine: {
              data: [{ type: "average", name: "Avg" }]
            }
          }
        ]
      };
    }
    return null;
  }, [timelines]);
  return (
    <div className="h-full">
      <Chart resizeObserver={resizeObserver} options={chartOptions as any} />
    </div>
  );
};

export default SessionsTimelineChart;
