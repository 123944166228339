import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";

export const AssessmentFrameworkTagsList = ({
  customTags = [],
  defaultTags = [],
  collections = [],
  companyId,
  onMoveTag,
  onCreateCollection
}) => {
  const { t: translate } = useTranslation(["assessments"]);

  const label_value_appended_collection = collections.map((c) => ({
    ...c,
    label: translate(c.custom_level_two),
    value: translate(c.custom_level_two)
  }));
  // console.log(label_value_appended_collection);

  return (
    <div className="bg-white rounded-lg shadow ">
      {/* Header */}
      <div className="grid grid-cols-2 gap-4 font-bold text-sm xl:text-base border-b p-4">
        <div className="text-sm ">Tag</div>
        <div className="text-sm ">Collection</div>
      </div>

      {/* List */}
      <div className="evenOddBorder">
        {customTags.map((t, i) => (
          <div key={i} className="grid sm:grid-cols-2 gap-4 p-4 mt-4">
            <div className="font-bold text-sm">
              <span className="mr-4 text-skin-muted">{}</span>
              {translate(t.level_three)}
            </div>
            <div>
              <CreatableSelect
                defaultValue={{
                  label: translate(t.custom_level_two?.custom_level_two),
                  value: translate(t.custom_level_two?.custom_level_two)
                }}
                placeholder="Select Collection"
                onChange={(e) => {
                  if (e) {
                    onMoveTag(t, e);
                  }
                }}
                className="rounded-none outline-none border-none text-sm"
                classNamePrefix="Select Collection"
                isClearable={false}
                isSearchable={true}
                options={label_value_appended_collection}
                onCreateOption={(e) => onCreateCollection(e)}
              />
            </div>
          </div>
        ))}

        {defaultTags.map((t, i) => (
          <div key={i} className="grid sm:grid-cols-2 gap-4 p-4 mt-4">
            <div className="font-bold text-sm">
              <span className="mr-4 text-skin-muted"></span>
              {translate(t.level_three)}
            </div>
            <div>
              <CreatableSelect
                defaultValue={{
                  label: translate(t.level_two) + " (Default)",
                  value: translate(t.level_two) + " (Default)"
                }}
                placeholder="Select Collection"
                onChange={(e) => {
                  if (e) {
                    onMoveTag(t, e);
                  }
                }}
                className="rounded-none outline-none border-none text-sm"
                classNamePrefix="Select Collection"
                isClearable={false}
                isSearchable={true}
                options={label_value_appended_collection}
                onCreateOption={(e) => onCreateCollection(e)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
