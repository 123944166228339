import { useTranslation } from "react-i18next";

export const BlindSpots = ({
  stateData = [],
  user,
  requestFeedback,
  canRequestFeedback
}) => {
  const { t } = useTranslation(["assessments"]);

  return (
    <div className="shadow p-4 bg-white rounded-lg">
      <div className="font-bold text-sm">Blind Spots</div>
      <div className="text-xs pb-2">Lowest scores based on 360 assessment</div>
      {/* Result */}
      <div>
        {stateData.length > 0 && (
          <>
            {stateData?.map((s, i) => (
              <div key={i} className="flex border-b border-gray-200 py-2">
                <span className="text-gray-500 text-xs">{t(s.key)}</span>
                <span className="ml-auto text-gray-900 text-xs font-bold">
                  {s?.avg_score?.value.toFixed(1)}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
      {/* Fallback message */}
      <div>
        {stateData.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <div className="mt-8 text-xs text-gray-300 text-center font-semibold">
              🙈 {user?.first_name} Blind Spots will appear here when it is
              available{" "}
            </div>
            {canRequestFeedback && (
              <button
                className="text-skin-accent underline text-xs"
                onClick={() => requestFeedback()}
              >
                {" "}
                Request feedback
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
