import { Feedback360SkillsResultBreakdown } from "../../../../../components/Chart/Feedback360SkillsResultBreakdown";
import { useTranslation } from "react-i18next";

export default function Detailed({ stateData = [], userName, canRequest, expanded }) {
  const { t } = useTranslation(["assessments"]);
  // TODO: To Fix
  const chartData = () => {
    if (stateData && stateData.length > 0) {
      const mapped = stateData.map((state, i) => {
        if (state.scores) {
          return {
            ...state,
            key: t(state.key),
            scores: Object.entries(state.scores).map(([key, value]) => {
              return { label: t(key), value: Math.round(value.value) };
            })
          };
        }
      });

      return mapped;
    } else return [];
  };

  return (
    <>
      {/* Skill BreakdownResult */}
      <Feedback360SkillsResultBreakdown expanded={expanded} stateData={chartData()} userName={userName} canRequest={canRequest} />
    </>
  );
}
