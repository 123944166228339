export default function Footer(props) {
  const percentage = () => {
    let val;
    if (props.total !== 0) {
      val = (props.count / props.total) * 100;
      return parseInt(val);
    }
    return "";
  };
  return (
    <footer
      dir="ltr"
      className="bottom-0 left-0 block w-full p-4 overflow-x-hidden bg-white sm:fixed"
    >
      <div className="flex flex-col items-center justify-end gap-4 sm:flex-row">
        {props.total > 0 && (
          <div className="">
            <div className="relative w-full">
              <div className="flex items-center justify-between mb-2">
                <div>
                  <span className="inline-block px-2 py-1 text-xs font-semibold uppercase rounded-full text-skin-accent bg-skin-fill-light">
                    {props.total === 0 ? (
                      <>Loading...</>
                    ) : (
                      <>
                        <span>Question {props.count}</span> of
                        <span>{" " + props.total}</span>
                      </>
                    )}
                  </span>
                </div>
                <div className="ml-2 text-right">
                  <span className="inline-block text-xs font-semibold text-skin-accent">
                    {percentage() && percentage() + "%"}
                  </span>
                </div>
              </div>
              <div className="flex h-2 max-w-sm overflow-hidden text-xs rounded bg-skin-fill-light">
                <div
                  style={{ width: percentage() + "%" }}
                  className="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap bg-skin-accent"
                ></div>
              </div>
            </div>
          </div>
        )}
        <div className="rounded-lg">
          <button className="px-4 py-2 text-sm rounded-lg text-skin-accent focus:outline-none bg-skin-fill-light whitespace-nowrap">
            Powered by &nbsp;
            <span className="font-bold text-skin-accent">Knack</span>
          </button>
        </div>
      </div>
    </footer>
  );
}
