import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const Integrations = () => {
  i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
      react: {
        useSuspense: true
      },
      fallbackLng: "en",
      ns: ["common"],
      defaultNS: "common",
      fallbackNS: "common",
      supportedLngs: ["en", "ar"]
    });
};
