import { Avatar, Button, Stack, Typography } from "knack-ui";
import { Link, useHistory } from "react-router-dom";
import { useLogout, useUser, useUserRoles } from "../../features/User/userQueries";
import { UserRoleState } from "../../features/User/UserTypes";
import KnackLogoSVG from "../SVG/KnackLogoSVG";
import { UserStore } from "../../RoleManagement/Discriminator";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import SVG from "../SVG/SVG";
import styled from "styled-components";
interface IProfileMenuPopverProps {
  onClose: () => void;
}

const ProfileMenuPopover = ({ onClose }: IProfileMenuPopverProps) => {
  const { data: user } = useUser();
  const history = useHistory();
  const { roleState, setCurrentRole } = useUserRoles();
  const { setValue } = useLocalStorage();
  const { mutate: logout } = useLogout();
  // @ts-ignore
  const { setCurrentRole: setLocalRole } = UserStore();

  const handleUpdateRole = (role: UserRoleState["activeRole"]) => {
    // update intercom
    if (user) {
      //@ts-ignore
      window.Intercom("update", {
        updated_at: Date.now(),
        email: user.email,
        current_role: role,
        domain_url: window.location.host
      });
    }

    setValue("prefferedProfile", role);
    setLocalRole(role);
    setCurrentRole(role);
    onClose();
    history.push("/");
  };

  return (
    <Stack direction="column" gap={2}>
      {roleState && (
        <PoweredBy>
          <span>Powered by KNACK</span>
          <KnackLogoSVG />
        </PoweredBy>
      )}
      <Stack className="py-2 border-b" alignItems="center" justifyContent="center" direction="column" gap={2}>
        <Avatar size="large" image={user?.profile?.profile_image} text={(user?.profile?.first_name as string) || (user?.email as string)} />
        <div>
          <Typography textAlign="center" variant="body1" fontWeight="bold">{`${
            user?.profile ? `${user.profile.first_name} ${user.profile.last_name}` : user?.email
          }`}</Typography>
          {user?.organization && <Typography textAlign="center" variant="body2" color="muted">{`${user?.organization?.name}`}</Typography>}
        </div>
      </Stack>
      {roleState && (
        <div className="p-2 pt-0 border-b">
          <Button startIcon={<SVG name="User" size={20} />} onClick={onClose} as={Link} to="/account" kind="ghost">
            View profile
          </Button>
          <Button startIcon={<SVG name="Gear" size={20} />} onClick={onClose} as={Link} to="/settings" kind="ghost">
            Settings
          </Button>
          {/* <Button
            startIcon={<MdInfoOutline size={20} />}
            onClick={onClose}
            as="a"
            href="https://help.knacklab.co/"
            target="#"
            rel="noopener noreferrer"
            kind="ghost"
          >
            Help center
          </Button> */}
        </div>
      )}
      {roleState && roleState.roles?.length > 1 && (
        <Stack direction="column" gap={2} className="p-2 border-b">
          <Typography textAlign="center" variant="caption" fontWeight="semibold" as="p">
            Switch Profile to
          </Typography>
          {roleState?.roles.map((role) => (
            <ProfileItem
              role={role}
              key={role}
              isActive={role === roleState.activeRole}
              onClick={() => {
                handleUpdateRole(role);
              }}
            />
          ))}
        </Stack>
      )}
      <Stack justifyContent="center" className="p-4 pt-2">
        <Button
          onClick={() => {
            logout();
            onClose();
          }}
          kind="warning"
        >
          Logout
        </Button>
      </Stack>
    </Stack>
  );
};

export default ProfileMenuPopover;

const ProfileItem = ({ isActive, onClick, role }: { isActive: boolean; onClick: () => void; role: UserRoleState["activeRole"] }) => {
  return (
    <Button
      onClick={onClick}
      className="cursor-pointer "
      kind={isActive ? "tertiary" : "ghost"}
      as={Stack}
      gap={2}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Avatar square text={role} />
      <div>
        <Typography variant="body2" fontWeight="bold">
          {role === "Hr" ? "Admin" : role}
        </Typography>
        <Typography variant="caption" color="muted">
          User Profile
        </Typography>
      </div>
    </Button>
  );
};

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary-background);
  color: var(--color-primary);
  font-size: ${11 / 16}rem;
  padding: 8px;
  font-weight: 500;
  border-radius: 8px 8px 0 0;
`;
