import EmptyState from "../../../../../components/EmptyState/EmptyState";
import { IProgram } from "../../../../../features/Programs/ProgramTypes";
import { ProgramsSearch } from "../../../../../V3/Pages/Programs/Programs";
import SessionProgramCard from "./SessionProgramCard";

interface IProgramsListProps {
  programs: IProgram[];
  onSelect: (program: IProgram) => void;
}

const SessionProgramsList = ({ programs, onSelect }: IProgramsListProps) => {
  const { search } = ProgramsSearch();
  return (
    <>
      {programs?.length === 0 && (
        <EmptyState
          key="empty"
          icon="ProgramBig"
          iconSize={100}
          title={
            search === ""
              ? "No programs found"
              : `No search results for "${search}"`
          }
          subtitle={search !== "" ? "Try using another spelling" : ""}
        />
      )}
      {programs?.length > 0 && (
        <div className="grid grid-cols-1 gap-4">
          {programs?.map((program, i) => (
            <SessionProgramCard
              index={i}
              program={program}
              key={program?._id}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default SessionProgramsList;
