import ReactImageFallback from "react-image-fallback";
import FileTypeLength from "../../../../../components/fileTypeLength/fileTypeLength";
import {
  useUser,
  useUserRoles
} from "../../../../../features/User/userQueries";
import Utils from "../../../../../services/utils/utils";

export default function LearnItem(props) {
  const { roleState } = useUserRoles();
  const { data: user } = useUser();

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-lg shadow h-full">
        <div
          className="relative flex-shrink-0 cursor-pointer"
          onClick={() => {
            if (props.openUrl) {
              props.openUrl(props.url, props.id);
            } else {
              props.sendJourneyId();
            }
          }}
        >
          <ReactImageFallback
            src={props.image}
            initialImage="loading.gif"
            fallbackImage="loading.gif"
            alt="Learn material image"
            className="object-cover w-full md:h-40"
          />

          {props.time && props.type && (
            <span className="absolute bottom-0 right-0 inline-flex items-center px-3 py-1 mr-4 -mb-3 text-xs font-medium leading-tight text-gray-800 bg-gray-100 border rounded-full">
              <FileTypeLength
                time={Utils.getFormattedTimeInHours(props.time)}
                type={props.type}
              />
            </span>
          )}
        </div>

        <div className="flex flex-col justify-between flex-1">
          <div className="flex flex-col justify-between flex-1 p-3 bg-white">
            <div>
              {props.openUrl ? (
                <div
                  onClick={() => props.openUrl(props.url, props.id)}
                  className="clampText text-sm sm:text-base font-bold text-skin-base text-left cursor-pointer"
                >
                  {" "}
                  {props.title}
                </div>
              ) : (
                <>
                  <p className="text-sm leading-6 text-gray-500 cursor-pointer">
                    {props.itemsLeft && props.itemsLeft()}
                  </p>
                  <div
                    onClick={() => props.sendJourneyId()}
                    className="clampText text-sm sm:text-base font-bold text-skin-base text-left cursor-pointer"
                  >
                    {props.title}
                  </div>
                </>
              )}
            </div>

            <p className="text-xs text-skin-muted font-semibold flex items-center flex-row mt-2 cursor-pointer">
              {props.source && props.source}
            </p>

            {props.bookmarked !== undefined && (
              <p className="flex flex-row w-full mt-3 text-sm font-medium leading-5">
                <button
                  onClick={() =>
                    props.bookmarkArticle(
                      props.bookmarked,
                      props.id,
                      props.title
                    )
                  }
                  className="inline-block tooltip relative mr-2"
                >
                  <span
                    className={
                      "inline-flex items-center p-1 text-xs font-medium leading-tight rounded-full" +
                      " " +
                      (props.bookmarked
                        ? "text-white border bg-yellow-400 border-yellow-400"
                        : "text-gray-200 border border-gray-200")
                    }
                  >
                    <svg
                      className="w-4 h-4"
                      fill="currentColor"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                      ></path>
                    </svg>
                  </span>
                  <span className="tooltip-text bg-gray-400 text-white p-3 -mt-6 -ml-6 rounded">
                    Bookmark
                  </span>
                </button>

                <button
                  onClick={() =>
                    props.markCompleted(props.completed, props.id, props.title)
                  }
                  className={"inline-block tooltip relative mr-2"}
                >
                  <span
                    className={
                      "inline-flex items-center p-1 text-xs font-medium leading-tight rounded-full" +
                      " " +
                      (props.completed
                        ? "text-white border bg-green-400 border-green-400"
                        : "text-gray-200 border border-gray-200")
                    }
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </span>
                  <span className="tooltip-text bg-gray-400 text-white p-3 -mt-6 -ml-6 rounded">
                    Completed
                  </span>
                </button>
                <button
                  onClick={() =>
                    props.addTodo(props.title, user._id, "learning", props.id)
                  }
                  className="inline-block tooltip relative ml-auto"
                ></button>
              </p>
            )}
            {props.percentage ? (
              <div className="border w-full bg-white">
                <div
                  className="bg-blue-500 text-xs leading-none py-1 text-center text-white"
                  style={{
                    width: props.percentage()
                  }}
                >
                  {props.percentage()}
                </div>
              </div>
            ) : props.subscribeJourney ? (
              <div className="flex items-center flex-wrap">
                {user && roleState.activeRole !== "Hr" && (
                  <button
                    onClick={() => props.subscribeJourney()}
                    className={
                      (props.subscribed
                        ? "bg-skin-fill text-white "
                        : "text-skin-accent") +
                      " border border-skin-base py-2 px-3 ml-auto inline-flex items-center md:mb-2 lg:mb-0 text-xs rounded-lg"
                    }
                  >
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {props.subscribed ? (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      ) : (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        ></path>
                      )}
                    </svg>
                    {props.subscribed ? "Subscribed" : "Subscribe"}
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
