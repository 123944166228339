const KnackMiniLogo = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || "12"}
      height={size || "11"}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.75"
        d="M6.9677 10.9266H6.96526C6.64406 10.9729 6.28393 10.9996 5.90189 10.9996C5.20109 10.9996 4.56842 10.912 4.12069 10.7709C1.91852 10.2137 -0.714357 8.35946 0.178679 2.51214C0.271145 1.90381 0.862447 1.51204 1.44402 1.67264C2.03532 1.83567 2.80425 2.14227 3.57319 2.71654C2.67042 4.58048 2.33462 7.08196 4.57086 9.65887C4.95289 9.58587 5.41036 9.54207 5.90189 9.54207C6.43966 9.54207 6.9385 9.59317 7.34243 9.68077C9.60787 7.08926 9.26234 4.57075 8.35227 2.69951C9.11634 2.13011 9.87797 1.82594 10.4668 1.66291C11.0484 1.49987 11.6397 1.89407 11.7322 2.50241C12.7396 9.10407 9.2526 10.6225 6.9677 10.9266Z"
        fill="#1F40E6"
      />
      <path
        d="M7.30019 10.2741C7.16392 10.4225 7.01792 10.5734 6.86462 10.7218C6.64076 10.9457 6.30009 10.9992 5.96672 10.9992C5.63335 10.9992 5.29268 10.9457 5.07125 10.7218C4.91552 10.571 4.76952 10.4201 4.63325 10.2717C3.18784 8.69001 2.74254 7.1351 2.82284 5.7335C2.75471 4.62146 3.08321 3.56782 3.53338 2.68695C4.43615 3.32449 5.32675 4.33189 5.95455 5.83813C6.58722 4.32216 7.48269 3.30746 8.39276 2.66992C8.8478 3.55322 9.17873 4.61416 9.11303 5.73107C9.1909 7.1351 8.7456 8.69001 7.30019 10.2741Z"
        fill="#1F40E6"
      />
      <path
        opacity="0.75"
        d="M8.40642 2.69979C7.49878 3.36409 6.58871 4.38366 5.95604 5.95803C5.32824 4.39583 4.42547 3.37869 3.52026 2.71683C4.02397 1.69726 4.70044 0.869919 5.21387 0.324851C5.62267 -0.108284 6.31131 -0.108284 6.72011 0.324851C7.23111 0.867486 7.90515 1.68996 8.40642 2.69979Z"
        fill="#1F40E6"
      />
    </svg>
  );
};

export default KnackMiniLogo;
