import { GoalsReportsQuery, PairingReportsQuery, SessionsReportsQuery, UserReportsQuery } from "./analyticsReportsTypes";
import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp, queryClient } from "../../lib/appConfig";
import { TChartType } from "../../lib/interfaces/generalTypes";
import { QUERY_ENTITIES } from "../../lib/constants";
import { endOfDay } from "date-fns/esm";

async function exportChart(chartType: TChartType) {
  let params: Record<string, any> = {};
  let queries, latestQuery;
  const queryCache = queryClient.getQueryCache();
  const queryKeys = queryCache.getAll().map((q) => q.queryKey);

  switch (chartType) {
    case "DISTRIBUTION_BY_ENTITY":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.userDistributions);
      latestQuery = queries[queries.length - 1][0] as UserReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        userStatus: latestQuery?.userStatus?.join(","),
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(","),
        position: latestQuery?.position?.join(","),
        program: latestQuery?.program?.join(","),
        userClass: latestQuery?.userClass?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        search_by_email_or_name: latestQuery?.search_by_email_or_name
      };
      break;
    case "DISTRIBUTION_BY_DEPARTMENT":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.userDistributions);
      latestQuery = queries[queries.length - 1][0] as UserReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        userStatus: latestQuery?.userStatus?.join(","),
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(","),
        position: latestQuery?.position?.join(","),
        program: latestQuery?.program?.join(","),
        userClass: latestQuery?.userClass?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        search_by_email_or_name: latestQuery?.search_by_email_or_name
      };
      break;
    case "DISTRIBUTION_BY_MANAGEMENT_LEVEL":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.userDistributions);
      latestQuery = queries[queries.length - 1][0] as UserReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        userStatus: latestQuery?.userStatus?.join(","),
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(","),
        position: latestQuery?.position?.join(","),
        program: latestQuery?.program?.join(","),
        userClass: latestQuery?.userClass?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        search_by_email_or_name: latestQuery?.search_by_email_or_name
      };
      break;
    case "TOPIC_INTERESTS":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.topicInterests);
      latestQuery = queries[queries.length - 1][0] as UserReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        userStatus: latestQuery?.userStatus?.join(","),
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(","),
        position: latestQuery?.position?.join(","),
        program: latestQuery?.program?.join(","),
        userClass: latestQuery?.userClass?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        search_by_email_or_name: latestQuery?.search_by_email_or_name
      };
      break;
    case "ACTIVE_EXPERTS_BY_SESSION":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.sessionProgress);
      latestQuery = queries[queries.length - 1][0] as UserReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        userStatus: latestQuery?.userStatus?.join(","),
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(","),
        position: latestQuery?.position?.join(","),
        program: latestQuery?.program?.join(","),
        userClass: latestQuery?.userClass?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        search_by_email_or_name: latestQuery?.search_by_email_or_name
      };
      break;
    case "ACTIVE_USERS_BY_SESSION":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.sessionProgress);
      latestQuery = queries[queries.length - 1][0] as UserReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        userStatus: latestQuery?.userStatus?.join(","),
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(","),
        position: latestQuery?.position?.join(","),
        program: latestQuery?.program?.join(","),
        userClass: latestQuery?.userClass?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        search_by_email_or_name: latestQuery?.search_by_email_or_name
      };
      break;
    case "PAIRED_VS_UNPAIRED_USERS":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.chart);
      latestQuery = queries[queries.length - 1][0] as PairingReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        status: latestQuery?.status?.join(","),
        program: latestQuery?.program?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        position: latestQuery?.position?.join(",")
      };
      break;
    case "PAIRED_VS_UNPAIRED_EXPERTS":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.chart);
      latestQuery = queries[queries.length - 1][0] as PairingReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        status: latestQuery?.status?.join(","),
        program: latestQuery?.program?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        position: latestQuery?.position?.join(",")
      };
      break;
    case "AVAILABLE_CAPACITY_VS_UNPAIRED_USERS":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.chart);
      latestQuery = queries[queries.length - 1][0] as PairingReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        status: latestQuery?.status?.join(","),
        program: latestQuery?.program?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        position: latestQuery?.position?.join(",")
      };
      break;
    case "SESSION_TOP_TOPICS":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.topTopics);
      latestQuery = queries[queries.length - 1][0] as SessionsReportsQuery;

      params = {
        organization: latestQuery?.organization?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        program: latestQuery?.program?.join(","),
        sessionStatus: latestQuery?.status?.join(","),
        startDate:
          latestQuery?.date && latestQuery?.date.length > 0 && latestQuery?.date[0].startDate ? latestQuery?.date[0].startDate : undefined,
        endDate:
          latestQuery?.date && latestQuery?.date.length > 0 && latestQuery?.date[0].endDate
            ? endOfDay(latestQuery?.date[0].endDate)
            : undefined,
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(",")
      };
      break;
    case "SESSION_TIMELINE":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.timeline);
      latestQuery = queries[queries.length - 1][0] as SessionsReportsQuery;
      params = {
        organization: latestQuery?.organization?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        program: latestQuery?.program?.join(","),
        status: latestQuery?.status?.join(","),
        startDate:
          latestQuery?.date && latestQuery?.date.length > 0 && latestQuery?.date[0].startDate ? latestQuery?.date[0].startDate : undefined,
        endDate:
          latestQuery?.date && latestQuery?.date.length > 0 && latestQuery?.date[0].endDate
            ? endOfDay(latestQuery?.date[0].endDate)
            : undefined,
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(",")
      };
      break;
    case "SESSION_STATUSES":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.sessionStatuses);
      latestQuery = queries[queries.length - 1][0] as SessionsReportsQuery;
      params = {
        organization: latestQuery?.organization?.join(","),
        department: latestQuery?.department?.join(","),
        entities: latestQuery?.entities?.join(","),
        management_level: latestQuery?.management_level?.join(","),
        program: latestQuery?.program?.join(","),
        status: latestQuery?.status?.join(","),
        startDate:
          latestQuery?.date && latestQuery?.date.length > 0 && latestQuery?.date[0].startDate ? latestQuery?.date[0].startDate : undefined,
        endDate:
          latestQuery?.date && latestQuery?.date.length > 0 && latestQuery?.date[0].endDate
            ? endOfDay(latestQuery?.date[0].endDate)
            : undefined,
        user: latestQuery?.user?.join(","),
        expert: latestQuery?.expert?.join(",")
      };
      break;
    case "TOP_TOPICS_IN_GOALS":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.goalsTopTopics);
      latestQuery = queries[queries.length - 1][0] as GoalsReportsQuery;
      params = {
        status: latestQuery?.status?.join(","),
        department: latestQuery?.department?.join(","),
        position: latestQuery?.position?.join(","),
        userType: latestQuery?.userType?.join(","),
        topics: latestQuery?.topics?.join(","),
        user: latestQuery?.user?.join(","),
        organization: latestQuery?.organization?.join(",")
      };
      break;
    case "GOALS_STATUSES":
      queries = queryKeys.filter((q: any) => q[0].entity === QUERY_ENTITIES.goalsList);
      latestQuery = queries[queries.length - 1][0] as GoalsReportsQuery;
      params = {
        // page: pageParam,
        status: latestQuery?.status?.join(","),
        department: latestQuery?.department?.join(","),
        position: latestQuery?.position?.join(","),
        userType: latestQuery?.userType?.join(","),
        topics: latestQuery?.topics?.join(","),
        order: latestQuery?.order,
        search_by_email_or_name: latestQuery?.search_by_email_or_name,
        user: latestQuery?.user?.join(","),
        organization: latestQuery?.organization?.join(",")
      };
      break;
    case "SATISFACTION_SCORE":
      break;
  }

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>("v2/analytics/charts", { chartTypes: [chartType] }, { params });
  return res.data.data;
}

// Mutations

export const useExportChart = () => {
  return useMutation(exportChart);
};
