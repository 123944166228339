import { useState, useEffect } from "react";

function importAll(r) {
  return r.keys().map(r);
}

export const RandomImageFallback = (index) => {
  const images = [
    ...importAll(require?.context("./Wallpaper", false, /\.(png|jpe?g)$/))
  ].map((image) => image.default);

  return images[Math.abs(images.length > index ? index : 0)];
};

/**
 * @returns a random image
 */
export const RandomImage = ({ index, useIndex = false, classes }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const images = [
      ...importAll(require.context("./Wallpaper", false, /\.(png|jpe?g)$/))
    ].map((image) => image.default);
    setImages(images);
  }, [setImages]);

  function importAll(r) {
    return r.keys().map(r);
  }

  return (
    <>
      {!useIndex ? (
        <img
          src={images[Math.floor(Math.random() * images.length)]}
          loading="lazy"
          className="w-full object-cover"
        />
      ) : (
        <img
          src={
            images[
              index <= images.length - 1
                ? index
                : Math.floor(Math.random() * images.length)
            ]
          }
          loading="eager"
          className="w-full object-cover"
        />
      )}
    </>
  );
};
