import { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppLayout from "../components/Layout/AppLayout";
import PageSkeleton from "../components/skeletons/PageSkeleton";
import ExpertAvailability from "../features/Calendar/ui/ExpertAvailability";
import { withErrorBoundary } from "../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../lib/HOCs/withSuspense";
import { loadComponent } from "../lib/utils";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import CoachAccount from "../pages/CoachDashboard/CoachAccount";

import CoachEarning from "../pages/dashboard/coachDashboard/CoachEarning";
import { CoachHome } from "../pages/dashboard/coachDashboard/CoachHome";
import { CoachRources } from "../pages/dashboard/coachDashboard/CoachRources";
import { ViewProfile } from "../pages/dashboard/coachDashboard/ViewProfile";
import { Scheduler } from "../services/Scheduling/Scheduler/Scheduler";
import ConnectionProfile from "../V3/Pages/ConnectionProfile/ConnectionProfile";
import Connections from "../V3/Pages/Connections/Connections";
import { SelfAssessmentForm } from "../V3/Pages/SelfAssessmentForm";
import { Settings } from "../V3/Pages/Settings";
import Sessions from "../features/Sessions/ui/Sessions";

const LazyProgram = lazy(() =>
  loadComponent(() => import("../V3/Pages/Programs/Program"))
);
const Program = withSuspense(withErrorBoundary(LazyProgram), {
  fallBackComponent: <PageSkeleton />
});

const CoachRoutes = () => {
  return (
    <>
      <AppLayout>
        <Switch>
          {/* <Route exact path="/" component={CoachHome} /> */}
          <Route exact path="/dashboard" component={CoachHome} />
          {/* <Route exact path="/programs/:programId" component={Program} /> */}
          <Route exact path="/programs/:programId/:r" component={Program} />
          <Route exact path="/sessions" component={Sessions} />
          <Route exact path="/earnings" component={CoachEarning} />
          <Route exact path="/availability" component={ExpertAvailability} />
          <Route exact path="/scheduler" component={Scheduler} />
          <Route exact path="/scheduler/:programId" component={Scheduler} />
          <Route
            exact
            path="/scheduler/:programId/:userId"
            component={Scheduler}
          />
          <Route
            exact
            path="/scheduler/session/:sessionId"
            component={Scheduler}
          />
          <Route exact path="/resources" component={CoachRources} />
          <Route exact path="/account" component={CoachAccount} />
          <Route exact path="/view-profile" component={ViewProfile} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
         
          <Route exact path="/self-assessment" component={SelfAssessmentForm} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/clients" component={Connections} />
        
          <Route exact path="/clients/:id" component={ConnectionProfile} />
          <Route exact path="/profile/:id" component={ConnectionProfile} />
          <Redirect path="*" to="/dashboard" />
        </Switch>
      </AppLayout>
    </>
  );
};

export default CoachRoutes;
