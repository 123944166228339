export const PROGRAM_APPLICATION_TYPES = {
  COACH: "coach",
  MENTOR: "mentor",
  COACHEE: "coachee",
  MENTEE: "mentee"
};

export const USER_TYPES_PLURALS = {
  coach: "coaches",
  mentor: "mentors",
  coachee: "coachees",
  mentee: "mentees"
};
