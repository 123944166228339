import axios from "axios";
import { useCallback } from "react";
import { ErrorComponentProps } from "../../lib/HOCs/withErrorBoundary";
import NotFoundView from "./NotFoundView";
import ServerErrorView from "./ServerErrorView";

const ErrorBoundaryComponent = ({
  error,
  resetErrorBoundary,
  props
}: ErrorComponentProps) => {
  const renderError = useCallback(() => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return (
          <NotFoundView
            props={props}
            error={error}
            resetErrorBoundary={resetErrorBoundary}
          />
        );
      }

      return (
        <ServerErrorView
          error={error}
          props={props}
          resetErrorBoundary={resetErrorBoundary}
        />
      );
    } else {
      return (
        <ServerErrorView
          error={error}
          props={props}
          resetErrorBoundary={resetErrorBoundary}
        />
      );
    }
  }, [error, props, resetErrorBoundary]);
  return (
    <
      // className="h-full"
      // gap={2}
      // alignItems="center"
      // justifyContent="center"
    >
      {renderError()}
    </>
  );
};

export default ErrorBoundaryComponent;
