import { Drawer } from "knack-ui";
import { PostAddUserInvitationPrompt, usePostAddUserPrompt } from "../../pages/dashboard/hrDashboard/Merger/PostAddUserInvitationPrompt";
import { onStartManualMatch } from "../../StateManagement/ManualMatching";
import { ManualMatching } from "./ManualMatching";

export const DrawerCollection = () => {
  const { isOpen, setOpen } = onStartManualMatch();
  const { program } = usePostAddUserPrompt();
  return (
    <>
      <Drawer isOpen={isOpen} onClose={() => setOpen()}>
        <ManualMatching />
      </Drawer>
      {program && <PostAddUserInvitationPrompt />}
    </>
  );
};
