import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SVG from "../SVG/SVG";

export const OptionsMenu = ({ children, options = [], id, state }) => {
  const ref = useRef();
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current?.contains(event?.target)) {
        setMenuActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setMenuActive]);

  return (
    <>
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          setMenuActive(true);
        }}
      >
        {children}
      </div>
      {menuActive && options.length > 0 && (
        <div
          className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg knack_option_menu_custom_items"
          style={{
            transform: "translate(-17px, 0px)",
            maxWidth: "max-content",
            top: 0,
            right: 8,
            position: "absolute"
          }}
        >
          <div
            style={{ lineHeight: "30px" }}
            className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {options.map((p, i) => (
              <Link
                key={i}
                to={{ pathname: p?.Link, state: { id: id, ...state } }}
                onClick={p?.onHandler}
              >
                <div
                  key={i}
                  id={`list-box-item-${i}`}
                  className="relative py-2 pl-3 text-gray-900 cursor-default cursor-pointer select-none hover:bg-gray-50 knack-dark-text pr-9"
                >
                  <div className="flex items-center">
                    <div className="mr-4">
                      <SVG name={p?.Icon} fill="var(--color-muted)" />
                    </div>
                    <span className="block w-full font-normal">{p?.Label}</span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
