import { addMonths, isBefore } from "date-fns";
import { Button, Step, Stepper } from "knack-ui";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import SVG from "../../../../components/SVG/SVG";
import { useCreateGoal } from "../../../../features/Goals/goalQueries";
import { INewGoalFormProps, TGoal } from "../../../../features/Goals/goalsTypes";
import GoalShareWith from "./NewGoal/NewGoalShareWith";
import GoalCreationSuccess from "./NewGoal/GoalCreationSuccess";
import GoalInfo from "./NewGoal/GoalInfo";
import NewGoalTopics from "./NewGoal/NewGoalTopics";
import { Success } from "../../../../components/ToastNotification/Success";
interface NewGoalModalProps {
  onClose: VoidFunction;
  selectedGoal?: TGoal;
  admin?: boolean;
}

export const NewSessionModalHeight = "calc(100vh - 250px)";

const NewGoalModal = ({ onClose, selectedGoal, admin = false }: NewGoalModalProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const [newGoalId, setNewGoalId] = useState<string | null>(null);

  const goalSteps = useMemo(() => {
    const baseSteps = ["Goal info", "Goal Topics"];
    if (admin) return baseSteps;
    return [...baseSteps, "Share goal"];
  }, [admin]);

  const [completedSteps, setCompletedSteps] = useState(() => {
    let steps: { [key: string]: boolean } = {};
    goalSteps.forEach((_, i) => {
      steps[i] = false;
    });
    return steps;
  });
  const methods = useForm<INewGoalFormProps>({
    defaultValues: {
      title: selectedGoal?.title,
      topics: selectedGoal?.topics || [],
      share_with: [],
      outcome: selectedGoal?.outcome,
      due_date:
        typeof selectedGoal?.due_date === "string"
          ? isBefore(new Date(), new Date(selectedGoal?.due_date))
            ? new Date(selectedGoal?.due_date)
            : addMonths(new Date(), 1)
          : isBefore(selectedGoal?.due_date as Date, new Date())
          ? selectedGoal?.due_date
          : addMonths(new Date(), 1)
    }
  });

  const { mutateAsync } = useCreateGoal();

  const values = methods.watch();

  useEffect(() => {
    return () => {
      setActiveStep(0);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Calculate completed steps on mount
  useEffect(() => {
    const initialCompleted = { ...completedSteps };
    const { title, due_date, outcome, topics } = values;
    if (Boolean(title && due_date && outcome)) initialCompleted[0] = true;
    if (topics?.length > 0) initialCompleted[1] = true;
    setCompletedSteps(initialCompleted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onClose();
    methods.reset();
    setActiveStep(0);
  };

  const handleSubmitGoal: SubmitHandler<INewGoalFormProps> = async (data) => {
    try {
      const newGoal = await mutateAsync(data);
      setCompletedSteps((prev) => ({ ...prev, 2: true }));
      if (admin) {
        handleClose();
        Success("Goal created successfully");
      } else {
        setNewGoalId(newGoal._id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <div dir="ltr" className="grid dialog-max-h" style={{ gridTemplateRows: "auto 1fr auto" }}>
        <Button className="z-10 end-6 top-6" style={{ position: "absolute" }} onClick={handleClose} kind="ghost" variant="small" iconOnly>
          <SVG name="Close" />
        </Button>

        {!newGoalId && (
          <div className="relative p-6 pb-16 border-b">
            <Stepper activeStep={activeStep}>
              {goalSteps.map((step, i) => (
                <Step key={step} completed={completedSteps[i]}>
                  {step}
                </Step>
              ))}
            </Stepper>
          </div>
        )}
        {!newGoalId && (
          <>
            {activeStep === 0 && <GoalInfo admin={admin} setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
            {activeStep === 1 && (
              <NewGoalTopics
                admin={admin}
                setActiveStep={setActiveStep}
                handleSubmitGoal={handleSubmitGoal}
                setCompletedSteps={setCompletedSteps}
              />
            )}
            {activeStep === 2 && !admin && (
              <GoalShareWith handleSubmitGoal={handleSubmitGoal} setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />
            )}
          </>
        )}

        {newGoalId && <GoalCreationSuccess handleClose={handleClose} goalId={newGoalId} />}
      </div>
    </FormProvider>
  );
};

export default NewGoalModal;
