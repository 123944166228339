import { PROGRAM_TYPES } from "./../../Sessions/SessionsTypes";
import { IActiveUserQuery } from "../activeUserListTypes";
import { IOrganization } from "../../User/UserTypes";
import { Dispatch, SetStateAction, useMemo } from "react";
import { DateRangeFilter, DefaultFilter, Filter } from "../../../components/FiltersBar/FiltersBar.types";
import { useGetOrganizations } from "../../Company/companyQueries";
import { format } from "date-fns";
import { useGetPrograms } from "../../Programs/programQueries";
import { IProgram } from "../../Programs/ProgramTypes";
import { USER_TYPES } from "../../User/UserTypes";
import moment from "moment";
import { addDays } from "date-fns/esm";

interface IProps {
  query: IActiveUserQuery;
  onQueryChange: Dispatch<SetStateAction<IActiveUserQuery>>;
}

export const useActiveUserFilters = ({ query, onQueryChange }: IProps) => {
  const { data: programList } = useGetPrograms();
  const { data: companyList } = useGetOrganizations();
  const startDate = moment().startOf("month").toDate();
  const endDate = moment().endOf("month").toDate();

  const filters = useMemo(() => {
    const f: Filter<keyof IActiveUserQuery, any>[] = [];
    Object.keys(query).forEach((key) => {
      switch (key) {
        case "program":
          if (!programList) break;
          const programs: DefaultFilter<"program", IProgram> = {
            label: "Program",
            name: "program",
            type: "default",
            options: {
              renderText: (o) => `${o?.name}`,
              data: programList,
              searchCriteria: (option, keyword) => {
                return `${option?.name}`.toLowerCase().includes(keyword.toLowerCase());
              },
              isOptionSelected: (o) => query.program!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.program!, newValue._id],
                  type: "program"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "program"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.program!.filter((id) => id !== option._id),
                  type: "program"
                });
              }
            }
          };
          f.push(programs);
          break;
        case "userType":
          const userType: Filter<"userType", USER_TYPES> = {
            label: "User Type",
            name: "userType",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["mentor", "mentee", "coach", "coachee"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.userType!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.userType!, newValue],
                  type: "userType"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "userType"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.userType!.filter((id) => id !== option),
                  type: "userType"
                });
              }
            }
          };
          f.push(userType);
          break;
        case "programType":
          const programType: Filter<"programType", PROGRAM_TYPES> = {
            label: "Program Type",
            name: "programType",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["internal-coaching", "external-coaching", "mentoring"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.programType!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.programType!, newValue],
                  type: "programType"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "programType"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.programType!.filter((id) => id !== option),
                  type: "programType"
                });
              }
            }
          };
          f.push(programType);
          break;
        case "dateRange":
          const dateRange: DateRangeFilter = {
            type: "dateRange",
            label:
              (query.dateRange![0].startDate &&
                query.dateRange![0].endDate &&
                `${format(query.dateRange![0].startDate, "yyyy-MM-dd")} / ${format(query.dateRange![0].endDate, "yyyy-MM-dd")}`) ||
              "Date",
            // name:'date' is actually wrong here, I kept it because if
            // I change it to `dateRange` a counter will appear next to `Date` button text
            name: "date",
            options: {
              onChange: (newValue) => {
                onOptionsChange({
                  newValue,
                  type: "dateRange"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [
                    {
                      // startDate: date,
                      endDate: addDays(Date.now(), 7),
                      key: "selection"
                    }
                  ],
                  type: "dateRange"
                });
              },
              ranges: query.dateRange!
            }
          };
          f.push(dateRange);
          break;

        case "organization":
          if (!companyList) break;
          const organizations: DefaultFilter<"organization", IOrganization> = {
            label: "Organization",
            name: "organization",
            type: "default",
            options: {
              renderText: (o) => `${o?.name}`,
              data: companyList,
              searchCriteria: (option, keyword) => {
                return `${option?.name}`.toLowerCase().includes(keyword.toLowerCase());
              },
              isOptionSelected: (o) => query.organization!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.organization!, newValue._id],
                  type: "organization"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "organization"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.organization!.filter((id) => id !== option._id),
                  type: "organization"
                });
              }
            }
          };
          f.push(organizations);
          break;
      }
    });

    return f;
  }, [query, programList, companyList]);

  const onOptionsChange = <T extends keyof IActiveUserQuery>({ newValue, type }: { type: T; newValue: IActiveUserQuery[T] }) => {
    onQueryChange((state) => ({ ...state, [type]: newValue }));
  };
  return filters;
};
