import { useEffect, useState } from "react";
import { CONFERENCING_PROFILES } from "../../../../../App.constant";
import { WarningInformation } from "../../../../../components/Information/WarningInformation";
import { HeadlessMenuDropdown } from "../../../../../components/Popper/HeadlessMenuDropdown";
import { ICalendarAccount, IConferencingProfile } from "../../../../../features/Calendar/CalendarTypes";

interface IProps {
  calendarAccount: ICalendarAccount;
  onSelectProfile: (profile: IConferencingProfile) => void;
  onSetCustomURL: (url: string) => void;
  selectedProfile: IConferencingProfile;
  width: string;
  error: string;
  conferenceURL: string;
  customConferenceURL: string | undefined;
  canUseIntegratedConferencing: boolean;
}

export const ConferencingProfile = ({
  canUseIntegratedConferencing,
  width,
  selectedProfile,
  onSelectProfile,
  calendarAccount,
  onSetCustomURL,
  conferenceURL,
  error,
  customConferenceURL
}: IProps) => {
  const [tapState, setTapState] = useState(false);
  const [conferencingProfiles, setConferenceProfiles] = useState<IConferencingProfile[]>([]);

  useEffect(() => {
    /**
     * Conferencing profiles assumptions
     * 1. Can be empty or [ ] ✔
     * 2. Can have a profile with profile_id:explicit ✔
     * 3. Conferencing maybe integrated but not included in the conferencing profile ✔
     * 4. Can have a conferencing profile record with profile_connected:false ✔
     */
    const conf_profiles = calendarAccount["cronofy.data"].conferencing_profiles;
    const computedConferencingProfiles: IConferencingProfile[] = [];
    // Support Google suite
    const isGoogleDomainAccount = calendarAccount["cronofy.data"].profiles.find(
      (a) => a.profile_name === calendarAccount.email && a.provider_name === "google" && a.profile_connected === true
    );
    // Support personal email account
    if (canUseIntegratedConferencing) {
      const isGmailAccount = calendarAccount.email.endsWith("@gmail.com");
      if (isGmailAccount || isGoogleDomainAccount) {
        computedConferencingProfiles.push({
          profile_name: calendarAccount.email,
          provider_name: "google_meet",
          profile_id: "integrated",
          profile_connected: true
        });
      }
    }

    // Add the connected conferencing profiles
    const connectedConferencingProfiles = conf_profiles.filter((c) => c.profile_connected);
    computedConferencingProfiles.push(...connectedConferencingProfiles);

    // Allow custom url
    const customUrlProfile = {
      profile_id: "explicit",
      profile_name: calendarAccount.email,
      provider_name: "explicit",
      profile_connected: true
    } as IConferencingProfile;
    computedConferencingProfiles.push(customUrlProfile);

    // Allow face-2-face meeting
    computedConferencingProfiles.push({
      profile_id: "face-2-face",
      provider_name: "face-2-face",
      profile_name: calendarAccount.email,
      profile_connected: true
    });

    if (customConferenceURL) {
      onSelectProfile(customUrlProfile);
      onSetCustomURL(customConferenceURL);
    } else {
      // Set First available
      onSelectProfile(computedConferencingProfiles[0]);
    }

    setConferenceProfiles(computedConferencingProfiles);
  }, [calendarAccount, customConferenceURL, canUseIntegratedConferencing]);

  return (
    <div className="grid gap-4 z-50">
      <div className="">Conferencing </div>
      <div className={`relative ${width}`}>
        <HeadlessMenuDropdown
          trigger={
            <div
              style={{ height: 50 }}
              className="relative gap-4 pl-4 flex flex-row items-center text-left rounded-lg cursor-pointer hover:bg-skin-lighter border"
              onClick={() => setTapState(!tapState)}
            >
              <div className="flex flex-row gap-4 items-center">
                {CONFERENCING_PROFILES[selectedProfile?.provider_name]?.icon}
                <div className="font-bold">{CONFERENCING_PROFILES[selectedProfile?.provider_name]?.type}</div>
              </div>
              {/* Droplet Arrow */}
              <div className={`absolute right-0 translate transform delay-300 duration-300 p-4 ${tapState ? "" : "-rotate-180"}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="var(--color-accent)">
                  <path
                    id="Icon_ionic-md-arrow-dropdown"
                    data-name="Icon ionic-md-arrow-dropdown"
                    d="M9,13.5l9,9,9-9Z"
                    transform="translate(-9 -13.5)"
                  />
                </svg>
              </div>
            </div>
          }
          popComponent={
            <div className="right-0 z-auto w-full pt-4 -mt-3 overflow-y-auto scrollhost2" style={{ maxHeight: 400 }}>
              <div className="overflow-hidden bg-white border rounded-lg shadow-md">
                {conferencingProfiles?.map((m, i) => (
                  <div key={i}>
                    {m?.profile_id !== selectedProfile?.profile_id && (
                      <div
                        style={{ height: 50 }}
                        className="relative gap-4 pl-4 flex flex-row items-center text-left cursor-pointer hover:bg-skin-lighter border"
                        onClick={() => {
                          setTapState(!tapState);
                          onSelectProfile(m);
                          if (customConferenceURL && m.profile_id === "explicit") {
                            onSetCustomURL(customConferenceURL);
                          }
                        }}
                      >
                        <div className="flex flex-row gap-4 items-center">
                          {CONFERENCING_PROFILES[m.provider_name]?.icon}
                          <div className="font-bold">{CONFERENCING_PROFILES[m.provider_name]?.type}</div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          }
        />

        {selectedProfile?.profile_id === "explicit" && (
          <div className="rounded-b-lg conferencing-item w-full">
            <div className="flex flex-row gap-2 items-center">
              <input
                className="bg-transparent border-none m-0 bg-none p-4 w-full"
                defaultValue={conferenceURL}
                onChange={(e) => onSetCustomURL(e.target.value)}
                placeholder="Paste link"
              />
            </div>
            {error && <WarningInformation message={error} />}
          </div>
        )}
      </div>
    </div>
  );
};
