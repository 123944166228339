import { useEffect, useState } from "react";
import { getSessionCountByQuery } from "../../../../../services/dashboard/userDashboard.services";
import { ProgressBar } from "../../../../../components/Progress/ProgressBar";

export const ProgramProgress = ({ program, userId }) => {
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (program && program._id) {
      onGetMentoringProgressForProgram();
    }
  }, [setProgress]);

  function onGetMentoringProgressForProgram() {
    getSessionCountByQuery(`&user=${userId}&program=${program._id}`)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          // console.log(data)
          setProgress(data.totalCompleted);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const SessionCompleted = ({ programId, session_count }) => {
    const _session_count = parseInt(session_count);
    const completed = progress > _session_count ? _session_count : progress;

    if (progress) {
      return (
        <div className="text-xs text-skin-muted">
          {" "}
          Sessions Completed [{completed}/{_session_count}]
        </div>
      );
    }
    return <div></div>;
  };

  const CompletedTimeline = ({ session_count }) => {
    const _session_count = parseInt(session_count);
    const completed = progress > _session_count ? _session_count : progress;

    if (completed && progress) {
      const bars = new Array(parseInt(completed)).fill(0);
      return bars.map((p, i) => <label key={i} className={`block rounded-lg h-2 w-full bg-skin-accent`} style={{ minWidth: 1 }}></label>);
    }
    return <div></div>;
  };

  const RemainingTimeline = ({ session_count }) => {
    const _session_count = parseInt(session_count);
    const completed = progress > _session_count ? _session_count : progress;

    if (progress && progress && session_count) {
      const bars = new Array(Math.abs(_session_count - completed)).fill(0);
      return bars.map((p, i) => <label key={i} className={`block rounded-lg h-2 w-full bg-gray-100`}></label>);
    }
    return <div></div>;
  };

  return (
    <>
      {
        <div>
          <SessionCompleted programId={program?._id} session_count={program?.session_count} />

          <>
            {program && program.session_count && parseInt(program.session_count) < 10 ? (
              <div className="flex flex-row gap-2 mt-4">
                <CompletedTimeline programId={program?._id} session_count={program?.session_count} />
                <RemainingTimeline programId={program?._id} session_count={program?.session_count} />
              </div>
            ) : (
              <div className=" mt-4">
                <ProgressBar value={(progress / parseInt(program?.session_count)) * 100} color={{ solid: "var(--color-accent)" }} />
              </div>
            )}
          </>
        </div>
      }
    </>
  );
};
