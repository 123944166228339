import { Stack, Typography } from 'knack-ui'
import MetricCard from '../../../components/Metrics/MetricCard'
import { IActiveUserMetrics } from '../activeUserListTypes'

export const ActiveUserListMetrics = (metrics: IActiveUserMetrics) => {
    return (
        <Stack direction='column' gap={4}>
            <Typography fontWeight='semibold'>Summary</Typography>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
                <MetricCard
                    icon="User"
                    title="Total Users"
                    value={metrics.total}
                />
                <MetricCard
                    icon="User"
                    title="Active Users"
                    value={metrics.active}
                />
            </div>
        </Stack>
    )
}
