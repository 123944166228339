import { Box, Button, Stack, Typography } from "knack-ui";
import React, { ReactNode } from "react";

interface IDashboardBoxProps {
  title: string;
  subtitle?: string | ReactNode;
  height?: number | string;
  onExport?: () => void;
}

const DashboardBox: React.FC<IDashboardBoxProps> = ({ title, subtitle, height, children, onExport }) => {
  return (
    <Box variant="outlined">
      <div className="p-4" style={{ minHeight: 60 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column">
            <Typography variant="body1" as="h6" fontWeight="bold">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle2" color="muted">
                {subtitle}
              </Typography>
            )}
          </Stack>
          {onExport && (
            <Button variant="small" onClick={() => onExport()} style={{ height: "fit-content" }}>
              Export
            </Button>
          )}
        </Stack>
      </div>
      <div className="relative p-4 border-t scrollhost2" style={{ height: height || 300, overflowY: "auto", overflowX: "hidden" }}>
        {children}
      </div>
    </Box>
  );
};

export default DashboardBox;
