import { useState } from "react";
import { Overview } from "./Overview";
import { AssessmentResults } from "./AssessmentResults";
import { TeamResult } from "./TeamResult";

export const AssessmentReports = () => {
  const [openTab, setOpenTab] = useState(1);
  const [user] = useState(JSON.parse(localStorage.getItem("user")).data);

  return (
    <div className="pb-4">
      <div className="w-full p-4 bg-white rounded-lg">
        <div className="text-2xl font-bold text-skin-base">
          {user?.organization?.name} Assessments Reports
        </div>
          {/* <div className="text-sm font-light">Last Updated on Apr 12, 2021</div> */}
      </div>

      <div className="flex flex-row w-full -mt-4 overflow-x-auto bg-white">
        <a
          className={
            "sm:px-6 py-3 px-2 whitespace-nowrap sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-white inline-flex items-center leading-none  tracking-wider rounded-t " +
            (openTab === 1
              ? "border-skin-base text-skin-accent"
              : "border-gray-200 hover:text-gray-900")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(1);
          }}
          data-toggle="tab"
          href="#link1"
          role="tablist"
        >
          Overview
        </a>
        <a
          className={
            "sm:px-6 py-3 px-2 whitespace-nowrap sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-white inline-flex items-center leading-none  tracking-wider " +
            (openTab === 2
              ? "border-skin-base text-skin-accent"
              : "border-gray-200 hover:text-gray-900")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(2);
          }}
          data-toggle="tab"
          href="#link2"
          role="tablist"
        >
          Assessment Results
        </a>

        <a
          className={
            "sm:px-6 py-3 px-2 whitespace-nowrap sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-white inline-flex items-center leading-none  tracking-wider " +
            (openTab === 3
              ? "border-skin-base text-skin-accent"
              : "border-gray-200 hover:text-gray-900")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(3);
          }}
          data-toggle="tab"
          href="#link3"
          role="tablist"
        >
          Team Results
        </a>
      </div>

      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words">
        <div className="flex-auto py-5">
          <div className="tab-content tab-space">
            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <Overview />
            </div>
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <AssessmentResults />
            </div>
            <div className={openTab === 3 ? "block" : "hidden"} id="link3">
              <TeamResult />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
