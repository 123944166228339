import { lazy, useMemo, useState } from "react";
import { Box, Button, Drawer, Input, Stack, Typography } from "knack-ui";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import { useGetConnections } from "../../../features/Connections/connectionsQueries";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../lib/utils";
import NewConnectionsDrawer from "./ui/NewConnectionsDrawer";
import { IConnectionRequest, IConnectionUser } from "../../../features/Connections/ConnectionsTypes";
import { AiOutlineSearch } from "react-icons/ai";
import create from "zustand";
import { useUser, useUserRoles } from "../../../features/User/userQueries";
import SVG from "../../../components/SVG/SVG";
import NewConnectionRequestsSlider from "../../../features/Connections/ui/NewConnectionRequestsSlider";
import Divider from "../../../components/Divider/Divider";

const LazyConnectionsList = lazy(() => loadComponent(() => import("./ui/ConnectionsList")));
const ConnectionsList = withSuspense(withErrorBoundary(LazyConnectionsList), {
  fallBackComponent: <PageSkeleton height="300px" />
});

export interface ConnectionsSearchState {
  search: string;
  setSearch: (value: string) => void;
}

export const ConnectionsSearch = create<ConnectionsSearchState>((set) => ({
  search: "",
  setSearch: (value: string) => set(() => ({ search: value }))
}));

const Connections = () => {
  const { data: user } = useUser();
  const { roleState } = useUserRoles();
  const [newConnectionsOpen, setNewConnectionsOpen] = useState(false);
  const { search, setSearch } = ConnectionsSearch();
  const { data: connections } = useGetConnections(
    { search },
    {
      suspense: true
    }
  );

  const tabHeight = useMemo(() => {
    if (roleState?.activeRole === "User") return "calc(100vh - 175px)";
    if (connections && connections?.pendingCount > 0) {
      return "calc(100vh - 220px)";
    } else {
      return "calc(100vh - 175px)";
    }
  }, [connections, roleState?.activeRole]);

  return (
    <div>
      <div className="overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0
            // height: PageTitleHeight
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <div>
            <Typography as="h5" variant="h6">
              {user?.account_type !== "Coach" ? "Your Connections" : "Your Clients"}
            </Typography>
            {user?.account_type !== "Coach" && (
              <div className="flex flex-row gap-2">
                <Typography as="p" variant="subtitle2">
                  {connections?.menteesOrMentorsCount} {roleState?.activeRole === "Expert" ? "Mentees" : "Mentors"},{" "}
                </Typography>
                <Typography as="p" variant="subtitle2">
                  {connections?.coachesOrCoachesCount} {roleState?.activeRole === "Expert" ? "Coachees" : "Coaches"}{" "}
                </Typography>
              </div>
            )}
          </div>
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="w-72"
            inputSuffix={
              search !== "" ? (
                <Button onClick={() => setSearch("")} iconOnly kind="ghost" variant="small">
                  <SVG name="Close" />
                </Button>
              ) : (
                <></>
              )
            }
            inputPrefix={<AiOutlineSearch size={20} />}
            placeholder="Search for a connection..."
          />
        </Box>
        {(connections?.pendingCount as number) > 0 && roleState?.activeRole === "Expert" && (
          <>
            <div className="p-4 bg-paper">
              <NewConnectionRequestsSlider />
            </div>
            <Divider className="block" />
          </>
        )}
        <Tabs>
          <TabList className="px-4 border-b">
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              All
            </Tab>
            {user?.account_type !== "Coach" && (
              <>
                <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
                  {roleState?.activeRole === "Expert" ? "Mentees" : "Mentors"}
                </Tab>
                <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
                  {roleState?.activeRole === "Expert" ? "Coachees" : "Coaches"}
                </Tab>
              </>
            )}
          </TabList>

          {user?.account_type !== "Coach" && (
            <>
              <TabPanel
                style={{
                  minHeight: tabHeight,
                  overflowX: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem"
                }}
              >
                <ConnectionsList connections={connections?.allConnections as IConnectionUser[]} />
              </TabPanel>
              <TabPanel
                key="MenteeList"
                style={{
                  minHeight: tabHeight,
                  overflowX: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem"
                }}
              >
                <ConnectionsList connections={connections?.menteesOrMentors as IConnectionUser[]} />
              </TabPanel>
            </>
          )}
          <TabPanel
            key="coacheelist"
            style={{
              minHeight: tabHeight,
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <ConnectionsList connections={connections?.coacheesOrCoaches as IConnectionUser[]} />
          </TabPanel>
        </Tabs>
      </div>
      <Drawer isOpen={newConnectionsOpen} onClose={() => setNewConnectionsOpen(false)}>
        <NewConnectionsDrawer
          pendingConnections={connections?.pendingRequests as IConnectionRequest[]}
          onClose={() => setNewConnectionsOpen(false)}
        />
      </Drawer>
    </div>
  );
};

export default Connections;
