import { Variants } from "framer-motion";

export const SlideLeftWithFade: Variants = {
  hidden: { x: 100, opacity: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  exit: {
    x: -100,
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3
    }
  }
};
export const FadeInOut: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,

    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3
    }
  }
};
export const Popin: Variants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,

    transition: {
      duration: 0.3,
      delay: 0.1,
      ease: "backIn"
    }
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      // ease: "easeInOut",
      duration: 0.3
    }
  }
};
