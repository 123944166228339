import { useState } from "react";
import { PeopleListComponent } from "./PeopleListComponent";

export const Coaching = () => {
  const [options] = useState([
    { name: "My Coaches", id: 0 },
    { name: "Coachees", id: 1 }
  ]);
  const [selected, setSelected] = useState(0);
  return (
    <>
      <div className="flex flex-wrap mb-4 text-xs">
        {options.map((o, i) => (
          <div
            key={i}
            onClick={() => setSelected(o.id)}
            className={`${
              selected === o.id
                ? "bg-skin-fill-light text-skin-inverted border-opacity-100 rounded-md"
                : "text-skin-muted"
            } ' font-semibold px-2 py-2 cursor-pointer `}
          >
            {" "}
            {o.name}
          </div>
        ))}
      </div>
      {selected === 0 && <PeopleListComponent type="coach" />}
      {selected === 1 && <PeopleListComponent type="coachee" />}
    </>
  );
};
