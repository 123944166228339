import styled, { css } from "styled-components";

export const PingingDot = styled.span<{ end?: string; top: string }>`
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background-color: var(--color-alert);
  height: 12px;
  width: 12px;
  top: ${(p) => p.top};
  ${(p) =>
    p.end &&
    css`
      [dir="rtl"] & {
        left: ${p.end};
      }
      [dir="ltr"] & {
        right: ${p.end};
      }
    `}
  &:before {
    position: absolute;
    background: inherit;
    content: " ";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    [dir="rtl"] & {
      right: 0px;
    }
    [dir="ltr"] & {
      left: 0px;
    }
  }
`;
