import { Button, Popover, Stack } from "knack-ui";
import { useMemo, useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { DeleteUser } from "../../../../components/ActionSheets/DeleteUser";
import { RemoveUserFromProgram } from "../../../../components/ActionSheets/RemoveUserFromProgram";
import { StatusIndicator } from "../../../../components/Information/StatusIndicator";
import { useGetParticipantRequests } from "../../../../features/Programs/programQueries";
import { MyMenteesAvatar } from "./MyMenteesAvatar";

export const PairingStatus = ({ program, mentor, isCardStyle = true, align = "right-0", isRegistered, sectionText }) => {
  const buttonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { data: approvedRequestsList } = useGetParticipantRequests(
    {
      participantId: mentor?.profile?.user?._id,
      programId: program._id,
      userType: "expert"
    },
    {
      select: (res) => res.filter((r) => r.status === "approved")
    }
  );

  const approvedRequests = useMemo(() => {
    if (approvedRequestsList) return approvedRequestsList;
    return [];
  }, [approvedRequestsList]);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmRemoveFromProgram, setOpenConfirmRemoveFromProgram] = useState(false);

  return (
    <>
      <DeleteUser userProfile={mentor?.profile} open={openConfirmDelete} setOpen={setOpenConfirmDelete} />
      <RemoveUserFromProgram
        userType="expert"
        program={program}
        userProfile={mentor?.profile}
        open={openConfirmRemoveFromProgram}
        setOpen={setOpenConfirmRemoveFromProgram}
      />
      {!isCardStyle && (
        <>
          <td className="px-5 py-5 text-xs bg-white border-b border-gray-200">
            {approvedRequests?.length > 0 ? (
              <StatusIndicator color="green" text="Paired" />
            ) : isRegistered ? (
              <StatusIndicator color="yellow" text="Registered" />
            ) : !program && mentor?.profile !== null ? (
              <StatusIndicator color="yellow" text="Registered" />
            ) : (
              <StatusIndicator color="red" text="Unregistered" />
            )}
          </td>

          <td onClick={(e) => e.stopPropagation()} className="px-5 py-5 text-sm bg-white border-b border-gray-200">
            <div className="flex items-center -space-x-2">
              <MyMenteesAvatar mentees={approvedRequests} as="div" program={program} align="right-0" />
            </div>
          </td>
          <td className="px-5 py-5 leading-normal cursor-pointer border-b border-gray-200 bg-white text-sm">
            <div className="flex items-center ">
              <Button
                ref={buttonRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpen(true);
                }}
                iconOnly
                kind="ghost"
                shape="rounded"
              >
                <BiDotsVerticalRounded size={20} />
              </Button>
            </div>
          </td>
        </>
      )}

      {isCardStyle && (
        <>
          <div onClick={(e) => e.stopPropagation()} className="my-4 text-xs font-semibold text-skin-muted">
            {sectionText}
          </div>
          <Stack justifyContent="space-between" direction="row">
            {approvedRequests.length > 0 && <MyMenteesAvatar mentees={approvedRequests} as="div" program={program} align={align} />}
            <div className='float-right w-full'>
              <Button
                ref={buttonRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpen(true);
                }}
                iconOnly
                kind="ghost"
                shape="rounded"
                className="float-right"
              >
                <BiDotsVerticalRounded size={20} />
              </Button>
            </div>
          </Stack>
        </>
      )}

      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        parentRef={buttonRef}
      >
        {[
          {
            Label: `Remove ${mentor?.profile?.first_name || mentor?.profile?.user?.email || ""} ${
              mentor?.profile?.last_name || ""
            } from this program`,
            Icon: "",
            onHandler: () => {
              if (mentor) {
                setOpenConfirmRemoveFromProgram(true);
              }
            }
          },
          ...(!isRegistered
            ? [
                {
                  Label: `Delete ${mentor?.profile?.first_name || mentor?.profile?.user.email || ""} ${mentor?.profile?.last_name || ""}`,
                  Icon: "",
                  onHandler: () => {
                    if (mentor) {
                      setOpenConfirmDelete(true);
                    }
                  }
                }
              ]
            : [])
        ].map((o, i) => (
          <Button
            style={{ justifyContent: "left", textAlign: "left", borderBottom: "1px solid #eee" }}
            key={i}
            className="w-full"
            variant="small"
            kind="ghost"
            shape="square"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              o.onHandler();
            }}
          >
            {o?.Label}
          </Button>
        ))}
      </Popover>
    </>
  );
};
