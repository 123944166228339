import { BaseDialog, Button, Typography } from "knack-ui";
import { ReactNode } from "react";
import SVG from "../SVG/SVG";

interface IProps {
  headerText: string | ReactNode;

  buttons?: ReactNode[];
  onClose: VoidFunction;
  isOpen: boolean;
  footerComponent: () => JSX.Element;
}

const ConfirmationDialog: React.FC<IProps> = ({
  headerText = "Confirm action",
  children,
  isOpen,
  onClose,
  footerComponent
}) => {
  return (
    <BaseDialog
      dialogClassName="max-w-xl"
      isOpen={isOpen}
      onClose={onClose}
      animationType="fade"
    >
      <div
        dir="ltr"
        style={{ gridTemplateRows: "auto 1fr auto" }}
        className="grid dialog-max-h"
      >
        <div className="relative p-6 border-b">
          <Typography variant="h6">{headerText}</Typography>
          <Button
            className="z-10 end-5 top-5"
            style={{ position: "absolute" }}
            onClick={onClose}
            kind="ghost"
            variant="small"
            iconOnly
          >
            <SVG name="Close" />
          </Button>
        </div>

        <div className="p-6 dialog-content-max-h">{children}</div>

        <div className="p-6 border-t">{footerComponent()}</div>
      </div>
    </BaseDialog>
  );
};

export default ConfirmationDialog;
