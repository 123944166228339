import { Input, Typography } from "knack-ui";
import React from "react";
import SVG from "../../../../../../../components/SVG/SVG";
import { ProgramState } from "../../../../../../../StateManagement/ProgramCreation";

export const Goals = ({ reference }) => {
  const { program, setProgram } = ProgramState();

  return (
    <section ref={reference}>
      {/* <CollapsibleWrapperWithLineBar
                ItemsCounts={1}
                Icon={
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" stroke="currentColor" viewBox="0 0 28.001 28.001">
                        <g id="pencil" transform="translate(-0.001)">
                            <g id="Group_494" data-name="Group 494" transform="translate(0.001 0)">
                                <path id="Path_591" data-name="Path 591" d="M26.328,18.333a5.676,5.676,0,0,0-5.674-1.408l-2-2L27.39,6.191a2.092,2.092,0,0,0,0-2.958L24.768.613a2.092,2.092,0,0,0-2.958,0L13.076,9.347l-2-2A5.665,5.665,0,0,0,3.38.5a.547.547,0,0,0-.166.887L5.823,3.992,3.978,5.836,1.369,3.227a.547.547,0,0,0-.887.166,5.665,5.665,0,0,0,6.853,7.7l2,2-6.69,6.69A.552.552,0,0,0,2.511,20L.13,27.181a.547.547,0,0,0,.691.691L8,25.493a.659.659,0,0,0,.223-.135l6.69-6.69,2,2a5.665,5.665,0,0,0,7.7,6.853.547.547,0,0,0,.166-.887l-2.609-2.609,1.845-1.845,2.609,2.609a.547.547,0,0,0,.887-.166,5.665,5.665,0,0,0-1.179-6.289ZM22.584,1.386a1,1,0,0,1,1.411,0l2.621,2.621a1,1,0,0,1,0,1.411L24.842,7.193,20.81,3.161ZM7.867,10.076a.547.547,0,0,0-.58-.125,4.573,4.573,0,0,1-6.07-5.33L3.592,7a.547.547,0,0,0,.773,0L6.983,4.378a.547.547,0,0,0,0-.773L4.608,1.23A4.573,4.573,0,0,1,9.938,7.3a.547.547,0,0,0,.125.58l2.24,2.24-2.2,2.2ZM1.51,26.492,2.3,24.1l1.6,1.6Zm3.549-1.176L2.687,22.943l.584-1.763,3.552,3.552ZM7.836,24.2,6.207,22.569l5.353-5.353a.547.547,0,0,0-.773-.773L5.433,21.8,3.8,20.166,20.036,3.934l.044.044,1.585,1.585-7.383,7.383a.547.547,0,1,0,.773.773l7.383-7.383,1.629,1.629Zm18.936-.8L24.4,21.019a.547.547,0,0,0-.773,0l-2.618,2.618a.547.547,0,0,0,0,.773l2.375,2.375a4.573,4.573,0,0,1-5.33-6.07.547.547,0,0,0-.125-.58l-2.24-2.24,2.2-2.2,2.24,2.24a.547.547,0,0,0,.58.125,4.573,4.573,0,0,1,6.07,5.33Z" transform="translate(-0.001 0)" />
                                <g id="Group_493" data-name="Group 493" transform="translate(12.382 14.528)">
                                    <path id="Path_592" data-name="Path 592" d="M226.945,266.743a.547.547,0,1,1,.536-.44A.552.552,0,0,1,226.945,266.743Z" transform="translate(-226.399 -265.651)" />
                                </g>
                            </g>
                        </g>
                    </svg>
                }
                SectionName="Add Program Topics"> */}
      <div>
        <label>
          <div>
            <Typography className="mb-4" variant="h6">
              Add Goals
            </Typography>
            <Typography className="my-4" fontWeight="semibold" color="muted" variant="subtitle2">
              Outline Key Goals and Objectives for the program
            </Typography>
            <Input
              onKeyUp={(e) => {
                if (e.keyCode === 13 && e.target.value.length > 0) {
                  const goals = program.goals && program.goals.length ? [...program.goals] : [];
                  // console.log(goals)

                  if (!goals.includes(e.target.value)) {
                    goals.push(e.target.value);
                    const _program = { ...program };
                    _program.goals = goals;
                    setProgram(_program);

                    e.target.value = "";
                  }
                }
              }}
              type="text"
              className="w-full border-gray-300 rounded-md"
              onChange={(v) => setProgram((p) => ({ ...p, goals: v.target.value }))}
            />
          </div>
          <p className="mt-2 text-xs text-skin-base">Press Enter to separate Goals</p>

          {/* Goal List */}
          <div className="flex flex-row flex-wrap mt-4 overflow-y-auto max-h-60">
            {program?.goals?.map((t, i) => (
              <label
                key={i}
                onClick={() => {
                  const goals = [...program.goals].filter((v) => v !== t);
                  const _program = { ...program };
                  _program.goals = goals;
                  setProgram(_program);
                }}
              >
                <div className="flex flex-row items-center justify-between px-2 py-1 m-1 text-xs font-semibold bg-gray-300 rounded-full cursor-pointer">
                  <div>{t}</div>
                  <div className="ml-2">
                    <SVG name="Cancel" fill="#eee" height="16px" />
                  </div>
                </div>
              </label>
            ))}
          </div>
        </label>
      </div>
      {/* </CollapsibleWrapperWithLineBar> */}
    </section>
  );
};
