export default function HeadingSmall({ title, subHeading }) {
  return (
    <div className="flex flex-col text-left w-full">
      <h2 className="text-2xl font-extrabold leading-7 text-gray-700 sm:text-3xl sm:truncate">
        {title}
      </h2>
      <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
        <div className="mt-2 flex items-center text-sm text-gray-500">
          {subHeading}
        </div>
      </div>
    </div>
  );
}
