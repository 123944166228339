import { useEffect, useState } from "react";
import {
  CronofyService,
  CRONOFY_CLIENT_ID,
  CRONOFY_SCOPE,
  REDIRECT_URL
} from "../CronofyService";
import { CalendarSync } from "../Calendar/CalendarSync";
import { useUser } from "../../../features/User/userQueries";

export const ConnectedCalendarWrapper = () => {
  const { data: user } = useUser();
  const [options, setOptions] = useState(null);

  useEffect(() => {
    onRequestElementAccessToken();
  }, [setOptions]);

  function onRequestElementAccessToken() {
    if (user?.profile && user?.profile?.scheduler_id) {
      CronofyService.requestElementAccessToken([user?.profile?.scheduler_id])
        .then((res) => {
          if (res && res.data.status) {
            // prepare options
            const options = {
              ...(user &&
                user.account_type !== "Coach" && { single_profile: 1 }), // only coach can connect multiple calendar
              data_center: "de",
              element_token: res.data.data.element_token.token,
              target_id: "calendar-sync",
              authorization: {
                redirect_uri: REDIRECT_URL,
                client_id: CRONOFY_CLIENT_ID,
                scope: CRONOFY_SCOPE
              }
            };
            setOptions(options);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  return (
    <>
      <div className="m-auto">
        {options && <CalendarSync options={{ ...options }} />}
      </div>
    </>
  );
};
