import { BaseDialog, Box, Button, Stack, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Slider from "../../../components/Slider/Slider";
import { PageTitleHeight } from "../../../lib/constants";
import { openManualSessionLogging } from "../../../services/Scheduling/SessionLogging/SessionState";
import LogSessionModal from "./components/LogSessionModal/LogSessionModal";
import { useGetCalendarProfile } from "../../Calendar/calendarQueries";
import { useUser, useUserCompany, useUserRoles } from "../../User/userQueries";
import { sessionQueryKeys, useGetConnectionSessions, useGetPendingRatingTasks } from "../sessionsQueries";
import { ITask, TSessionWithProgram } from "../SessionsTypes";
import RatingTaskItem from "./RatingTaskItem";
import RatingQuestionModal from "./SessionRating/SessionRatingSliders";
import SessionsList from "./SessionsList";

const Sessions = () => {
  const [logSessionOpen, setLogSessionOpen] = useState(false);

  const { roleState } = useUserRoles();
  const { data: tasks } = useGetPendingRatingTasks({
    enabled: roleState?.activeRole === "User"
  });
  const { data: user } = useUser();
  const { data: sessions } = useGetConnectionSessions(
    {
      ...(roleState?.activeRole === "Expert" ? { expertId: user?._id } : { userId: user?._id })
    },
    {
      select: (sessions) => {
        return {
          pending: sessions.filter((s) => s.completion_status === "Pending"),
          completed: sessions.filter((s) => s.completion_status !== "Pending")
        };
      }
    }
  );
  const queryClient = useQueryClient();
  const [ratingTask, setRatingTask] = useState<ITask | null>();
  const { setOpen, setCallBack, setScheduler } = openManualSessionLogging();
  const { data: calendarAccountData } = useGetCalendarProfile(user?.profile?.scheduler_id, user?._id);

  function onSchedule(isScheduler?: boolean) {
    setOpen(true);
    if (isScheduler) {
      setScheduler(isScheduler);
    }

    const callback = () => {
      queryClient.invalidateQueries(sessionQueryKeys["all"]);
    };
    setCallBack(callback);
  }
  const tabHeight = useMemo(() => {
    if (roleState?.activeRole === "User" && tasks && tasks.length > 0) {
      return "calc(100% - 197px)";
    }
    return "calc(100% - 48px)";
  }, [tasks, roleState]);

  function onRateSession(task: ITask) {
    setRatingTask(task);
  }

  const { data: company } = useUserCompany();
  const schedulingEnabled = useMemo(() => {
    if (user?.account_type === "Coach") {
      return true;
    }

    if (company && company?.schedulingSupported) return true;
  }, [company]);

  return (
    <div>
      <div className="overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            height: PageTitleHeight
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <div className="flex-1">
            <Typography as="h5" variant="h6">
              Sessions
            </Typography>
          </div>
          <Stack gap={3}>
            <Button
              onClick={() => {
                onSchedule();
                // setLogSessionOpen(true);
              }}
              kind="tertiary"
            >
              Log previous session
            </Button>
            {schedulingEnabled && <Button onClick={() => onSchedule(true)}>Schedule upcoming session</Button>}
          </Stack>
        </Box>
        <Tabs style={{ overflowY: "auto" }}>
          {roleState?.activeRole === "User" && (tasks?.length as number) > 0 && (
            <div className="p-4 bg-gray-100 border-b bg-opacity-90">
              <Typography className="mb-4" variant="body1" fontWeight="bold">
                Rate your last session/s
              </Typography>
              <Slider
                breakpoints={{
                  // when window width is >= 320px
                  550: {
                    slidesPerView: 1.8,
                    spaceBetween: 10
                  },

                  // when window width is >= 640px

                  1200: {
                    slidesPerView: 2.5,
                    spaceBetween: 20
                  },
                  1800: {
                    slidesPerView: 3.1,
                    spaceBetween: 20
                  },
                  2036: {
                    slidesPerView: 4.5,
                    spaceBetween: 20
                  }
                }}
              >
                {tasks?.map((s) => (
                  <RatingTaskItem key={s._id} task={s} onRateSession={onRateSession} />
                ))}
              </Slider>
            </div>
          )}
          <TabList className="px-4 border-b">
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Upcoming sessions
            </Tab>
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Past sessions
            </Tab>
          </TabList>
          <TabPanel
            key="past"
            style={{
              height: tabHeight,
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <SessionsList sessions={sessions?.pending as TSessionWithProgram[]} />
          </TabPanel>

          <TabPanel
            key="upcoming"
            style={{
              height: tabHeight,
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <SessionsList sessions={sessions?.completed as TSessionWithProgram[]} />
          </TabPanel>
        </Tabs>
      </div>

      <BaseDialog dialogClassName="max-w-3xl" isOpen={Boolean(ratingTask)} onClose={() => setRatingTask(null)}>
        <RatingQuestionModal onClose={() => setRatingTask(null)} task={ratingTask as ITask} />
      </BaseDialog>
      <BaseDialog dialogClassName="max-w-3xl" isOpen={logSessionOpen} onClose={() => setLogSessionOpen(false)}>
        <LogSessionModal onClose={() => setLogSessionOpen(false)} />
      </BaseDialog>
    </div>
  );
};

export default Sessions;
