import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { useImpersonation } from "../../hooks/useImpersonation";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { apiHttp } from "../../lib/appConfig";

async function getAuthTokenForUser({ user, landingPage }: { user: string; landingPage?: URL }) {
  const res = await apiHttp.post<ServerResponse<any>>("v1/auth/temp-generate-token", { user });

  if (landingPage) {
    return { ...res.data.data, landingPage };
  }
  return res.data.data;
}

async function updateAccessToken(authToken: string) {
  if (authToken) {
    const user = { data: { authToken } };
    localStorage.setItem("user", JSON.stringify(user));
    window.location.reload();
    return true;
  }
}

export const useUpdateAccessToken = () => {
  return useMutation(updateAccessToken);
};

export const useGetAuthToken = () => {
  const { setValue, getValue } = useLocalStorage();
  const { setImpersonation } = useImpersonation();
  return useMutation(getAuthTokenForUser, {
    onSuccess: (e) => {
      const adminCred = getValue("user");
      setValue("tempUser", adminCred);
      setValue("user", e);
      setImpersonation(true);

      if (e?.landingPage) {
        window.open(e.landingPage, "_blank");

        return;
      }
      window.location.reload();
    }
  });
};
