import React, { useMemo } from "react";
import Chart from "../../../components/Charts/Chart";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { barChartDataZoom } from "../../../lib/constants";
import { resizeObserver } from "../../../lib/utils";
import { ISessionProgress } from "../analyticsReportTypes";

interface IProps {
  sessionProgress: ISessionProgress | undefined;
  type: keyof ISessionProgress;
}

export const SessionProgress = ({ sessionProgress, type }: IProps) => {
  const options = useMemo(() => {
    if (!sessionProgress) return null;
    if (sessionProgress[type].length === 0) return null;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}"
        }
      },
      dataZoom: barChartDataZoom(sessionProgress[type].length),
      yAxis: {
        type: "category",
        inverse: true,
        // data: sessionProgress[type].map((s) => s._id),
        data: sessionProgress[type].map((s) => `${s.first_name} ${s.last_name}`),
        axisLabel: {
          margin: 20
        }
      },
      series: [
        {
          type: "bar",
          data: sessionProgress[type].map((s) => s.count),
          barWidth: "10px"
        }
      ]
    };
  }, [sessionProgress, type]);

  return (
    <>
      {!options && <EmptyState iconSize={70} title="No data found" icon="Smile" />}
      {options && <Chart options={options as any} resizeObserver={resizeObserver} />}
    </>
  );
};
