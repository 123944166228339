const SuccessBigIcon = ({
  className,
  size
}: {
  size?: number;
  className?: string;
}) => {
  return (
    <svg
      width={size || "70"}
      height={size || "71"}
      viewBox="0 0 70 71"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 70.1133C54.33 70.1133 70 54.4433 70 35.1133C70 15.7833 54.33 0.113281 35 0.113281C15.67 0.113281 0 15.7833 0 35.1133C0 54.4433 15.67 70.1133 35 70.1133Z"
        fill="#01A085"
        fill-opacity="0.04"
      />
      <g clip-path="url(#clip0_1053_1129)">
        <path
          d="M49.9714 21.9566L52.6245 24.6097L28.482 48.7522L17.8699 38.1401L20.5229 35.4871L28.482 43.4461L49.9714 21.9566ZM49.9714 16.5938L28.482 38.0832L20.5229 30.1242L12.507 38.1401L28.482 54.1151L57.9873 24.6097L49.9714 16.5938Z"
          fill="#01A085"
        />
      </g>
      <defs>
        <clipPath id="clip0_1053_1129">
          <rect
            width="45.4804"
            height="45.4804"
            fill="white"
            transform="translate(12.507 12.6143)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessBigIcon;
