import styled from "styled-components";

export interface IActivityIndicatorWrapperProps {
  height?: number;
  bordered?: boolean;
}

export const ActivityIndicatorWrapper = styled.div<IActivityIndicatorWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-100);
  height: ${(p) => p.height || "100px"}px;
  border: ${(p) => (p.bordered ? "1px solid var(--color-border)" : "none")};
  border-radius: 12px;
`;
