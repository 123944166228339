import { AnimatePresence } from "framer-motion";
import { Box, Breadcrumbs, Typography } from "knack-ui";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";
import { useGetConnectionProfile } from "../../../../features/Connections/connectionsQueries";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import ConnectionDetails from "../../../../V3/Pages/ConnectionProfile/ui/ConnectionDetails";
// import ConnectionEnrolledPrograms from "../../../../V3/Pages/ConnectionProfile/ui/ConnectionEnrolledPrograms";
import ConnectionProfileHeader from "../../../../V3/Pages/ConnectionProfile/ui/ConnectionProfileHeader";
// import ConnectionSessions from "../../../../V3/Pages/ConnectionProfile/ui/ConnectionSessions";

const AdminCoachProfile = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams<{ id?: string }>();
  const { height } = useWindowSize();

  const { data: profile } = useGetConnectionProfile({ id });

  return (
    <div className="h-full">
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[
          <Link key="link" to="/experts">
            Experts
          </Link>,
          <Typography key="name" clamp={1}>
            {profile?.first_name}
          </Typography>
        ]}
      </Box>
      <Box
        // To make tailwind rtl plugin work
        dir="ltr"
        variant="outlined"
        className="overflow-y-auto"
      >
        <ConnectionProfileHeader />
        <Tabs
          style={{
            height: `calc(${height}px - 330px)`,
            overflowY: "hidden",
            overflowX: "hidden"
          }}
          selectedIndex={tab}
          onSelect={(tab) => setTab(tab)}
        >
          <TabList className="px-4 overflow-x-hidden border-b">
            <Tab
              className="inline-block px-4 py-3 text-sm cursor-pointer"
              selectedClassName="border-b-2 border-skin-base"
            >
              Profile
            </Tab>
            {/* <Tab
              className="inline-block px-4 py-3 text-sm cursor-pointer"
              selectedClassName="border-b-2 border-skin-base"
            >
              Programs
            </Tab>
            <Tab
              key="sessions"
              className="inline-block px-4 py-3 text-sm cursor-pointer"
              selectedClassName="border-b-2 border-skin-base"
            >
              Sessions
            </Tab> */}
          </TabList>
          <AnimatePresence exitBeforeEnter>
            {tab === 0 && <ConnectionDetails />}
            {/* {tab === 1 && <ConnectionEnrolledPrograms />}
            {tab === 2 && <ConnectionSessions />} */}
          </AnimatePresence>
        </Tabs>
      </Box>
    </div>
  );
};

export default AdminCoachProfile;
