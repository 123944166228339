import { useEffect, useState } from "react";
import * as CronofyElements from "cronofy-elements";

const AvailabilityViewerWrapper = ({ options }: { options: any }) => {
  const [element, setElement] = useState<any>(null);
  useEffect(() => {
    if (!element) {
      setElement(CronofyElements.AvailabilityRules(options));
    }
  }, [element, options]);

  useEffect(() => {
    if (element) {
      element.update({ ...options });
    }
  }, [element, options]);

  return <div className="h-full overflow-x-hidden overflow-y-auto" id="availability-viewer" />;
};

export default AvailabilityViewerWrapper;
