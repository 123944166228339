import { Box } from "knack-ui";

export const CardPaper = ({
  children,
  title,
  subTitle,
  link,
  linkLabel,
  headerUnderline,
  extra,
  classes,
  styles,
  height = "h-full"
}) => {
  return (
    <Box
      variant="outlined"
      className={` overflow-hidden p-4 w-full ${height}  ${classes}`}
      style={styles}
    >
      <div className="flex flex-wrap items-center justify-between">
        <div>
          {/* texts */}
          <div className="">
            <div className="text-base font-bold">{title}</div>
            <div className="text-xs font-light text-gray-600">{subTitle}</div>
          </div>
          {/* link */}
          {link && (
            <div>
              <a href={link}>{linkLabel}</a>
            </div>
          )}
        </div>
        {/* Extra */}
        {extra && <div className="mt-2 mb-4 sm:mt-0">{extra}</div>}
      </div>
      {headerUnderline && (
        <div
          style={{ height: 1, backgroundColor: "#eee" }}
          className="w-full mt-4 bg-gray"
        ></div>
      )}
      <>{children}</>
    </Box>
  );
};
