import { useTranslation } from "react-i18next";

export const TopStrengths = ({
  stateData,
  user,
  requestFeedback,
  canRequestFeedback
}) => {
  const { t } = useTranslation(["assessments"]);

  return (
    <>
      <div className="shadow p-4 bg-white rounded-lg">
        <div className="font-bold text-sm">Top Strengths</div>
        <div className="text-xs pb-2">
          Highest scores based on self-assessment
        </div>
        {/* Result */}
        <div>
          {stateData?.map((s, i) => (
            <div key={i} className="flex border-b border-gray-200 py-2">
              <span className="text-gray-500 text-xs">{t(s.key)}</span>
              <span className="ml-auto text-gray-900 text-xs font-bold">
                {s.avg_score_before.value.toFixed(1)}
              </span>
            </div>
          ))}
        </div>
        {/* Fallback message */}
        <div>
          {stateData.length === 0 && (
            <div className="flex flex-col items-center justify-center">
              <div className="mt-8 text-xs text-gray-300 text-center font-semibold">
                <span className="opacity-50">🏆</span> {user?.first_name} Top
                Strengths will appear here when it is available{" "}
              </div>
              {canRequestFeedback && (
                <button
                  className="text-skin-accent underline text-xs"
                  onClick={() => requestFeedback()}
                >
                  {" "}
                  Request feedback
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
