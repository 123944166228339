import { PlatformFeatures, UserRoles } from "../../lib/constants";
import { IProgramInvite } from "../Programs/ProgramTypes";

export interface IUser {
  profile_image: string;
  email: string;
  roles: ["mentor", "mentee", "coach", "coachee"];
  assessment_completed: boolean;
  platform: ["Mentoring", "Coaching"];
  profile_completed: boolean;
  profile: IUserProfile | null;
  _id: string;
  account_type: "Hr" | "Admin" | "Employee" | "Subadmin" | "Mentor" | "Mentee" | "Coach" | "Coachee";
  organization?: Omit<IOrganization, "calendar" | "one_grade_above" | "custom_framework" | "include_user_job_function">;
  assigned_coach: IUser;
}
export interface IUserProfile {
  experience?: string[];
  icf_certificate_level?: keyof typeof ICFLevels;
  primary_specialization: [];
  secondary_specialization: [];
  coaching_industry: [];
  industries_worked_in: [];
  preferred_client_profile: [];
  preferred_client_industry: [];
  testimonials?: IClientTestimonial[];
  coach_language_preference: "English";
  professional_situation: [];
  focus: [];
  profile_image: string;
  certifications?: string[];
  program_skills: string[];
  programs: string[];
  assessment_report_visible_to?: string[];
  _id: string;
  profile_type: string;
  first_name: string;
  last_name: string;
  user: IUser;
  organization: IOrganization;
  updated_at: string;
  created_at: string;
  gender: "Female" | "Male";
  whatsapp_number: string;
  age_group: string;
  capacity: number;
  entity: string;
  job_function: string;
  management_level: string;
  position: string;
  mentor_gender_preference: "Female" | "Male" | string;
  scheduler_id: string;
  about: string;
  tagline: string;
  selectedSkills?: string[];
  spoken_languages: string[];
  professional_coaching_topics?: string[];
  schedule_buffer_minutes: number;
  linkedin_profile_url?: string;
  topics?: string[];
  timezone?: string;
  onboarding?: {
    onboarding_completed: boolean;
    onboarding_dismissed: boolean;
  };
  customMeetingLink: string;
}

export interface IUserProfileWithAvailability extends IUserProfile {
  availability: number;
  programInvite: IProgramInvite;
}

export type INewUserProfile = Pick<
  IUserProfile,
  | "age_group"
  | "about"
  | "first_name"
  | "last_name"
  | "entity"
  | "job_function"
  | "management_level"
  | "gender"
  | "position"
  | "profile_image"
  | "linkedin_profile_url"
  | "spoken_languages"
>;

export interface IOrganization {
  dark_color: string;
  maximumAllowedCapacity: number;
  calendar: {
    enterpriseName: string;
    isEnterprise: boolean;
  };
  custom_framework: boolean;
  one_grade_above: boolean;
  include_user_job_function: boolean;
  light_color: string;
  goals: string[];
  departments: string[];
  management_levels: string[];
  entities: string[];
  features: [keyof typeof PlatformFeatures];
  _id: string;
  name: string;
  logo: string;
  mediaSupported: boolean;
  positions: string[];
  meetingDurations: number[];
  externalLink?: {
    name: string;
    url: string;
  };
  schedulingSupported?: boolean;
}

export interface UserRoleState {
  activeRole: typeof UserRoles[number];
  roles: Array<typeof UserRoles[number]>;
}

export interface IClientTestimonial {
  comment: string;
  name: string;
  position: string | null;
}

export enum ICFLevels {
  "Professional Certified Coach" = "PCC",
  "Associate Certified Coach" = "ACC",
  "Master Certified Coach" = "MCC"
}

export interface ICoachAccountFormProps {
  first_name: string;
  last_name: string;
  about: string;
  tagline: string;
  gender: "Male" | "Female";
  professional_coaching_topics: string[];
  certifications: string[];
  profile_image: string;
  spoken_languages?: string[];
  icf_certificate_level: keyof typeof ICFLevels;
  testimonials: IClientTestimonial[];
  experience: string[];
  linkedin_profile_url: string;
}
export interface IUserAccountFormProps {
  first_name: string;
  last_name: string;
  about: string;
  capacity: number;
  entity: string;
  profile_image: string;
  gender: "Male" | "Female";
  job_function: string;
  management_level?: string;
  mentor_gender_preference: "Male" | "Female" | "None";
  position: string;
  coach_language_preference: string;
  whatsapp_number: string[];
  schedule_buffer_minutes?: number;
  scheduler_id?: string;
  customMeetingLink: string;
  timezone?: string;
}

export type USER_TYPES = "mentor" | "mentee" | "coach" | "coachee";
export type USER_TYPES_CLASS = "user" | "expert";
