import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import SVG from "../../../../../components/SVG/SVG";

export const ShareAssessmentPrompt = ({
  open,
  setOpen,
  canDismissFromBackdrop = true,
  onShare
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        style={{ zIndex: 9999999999999 }}
        as="div"
        auto-reopen="true"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 w-80 ml-auto`}
            >
              <div className="w-full relative">
                <button
                  className="p-2 absolute z-50 top-0 right-0"
                  onClick={() => setOpen(false)}
                >
                  <SVG name="Cancel" fill="var(--color-muted)" />
                </button>

                <div className="">
                  <div className="w-full h-44 justify-between gap-4 flex items-end p-4 animated-bar-chart bg-skin-light pt-4">
                    {/* Animated bar */}
                    <div className="block w-16 bg-skin-near-full rounded-lg"></div>
                    <div className="block w-16 bg-skin-red rounded-lg"></div>
                    <div className="block w-16 bg-skin-yellow rounded-lg"></div>
                    <div className="block w-16 bg-skin-green rounded-lg"></div>
                  </div>
                </div>

                {/*  */}
                <div className="p-4 block">
                  <div className="font-extrabold text-lg text-left leading-5 tracking-tight">
                    Would you like to share your assessment report with your
                    mentor?
                  </div>

                  <div className="flex flex-end items-end justify-end mt-4">
                    <button
                      className="px-4 py-2 bg-skin-accent text-white rounded-lg text-sm"
                      onClick={() => onShare()}
                    >
                      Share now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
