import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getExternalCoachingPrograms } from "../../../../services/dashboard/hrDashboard.services";

const ExternalCoachingPrograms = () => {
  const [programs, setPrograms] = useState([]);

  const fetchPrograms = async () => {
    const res = await getExternalCoachingPrograms();
    setPrograms(res.data.data);
  };

  useEffect(() => {
    fetchPrograms();
  }, []);
  return (
    <div className="h-full mb-4">
      <div
        style={{ minHeight: 100 }}
        className="hidden bg-white rounded-lg shadow lg:block"
      >
        {/* table header */}
        <div className="grid-cols-5 p-4 text-sm font-bold border-b lg:grid xl:text-base">
          <div>
            <span>Program Name</span>
          </div>
          <div>Employee</div>
          <div>Plan</div>
          <div>Duration</div>
          <div>Start Date</div>
          <div>End Date</div>
        </div>
        {/* table body large screens*/}
        {programs.map((e, i) => (
          <Link
            to={`/external-programs/${e._id}/edit`}
            key={i}
            className={` block w-full cursor-pointer`}
          >
            <div className="hidden grid-cols-5 p-4 text-sm border-b lg:grid ">
              <div className="flex items-center">
                <div className="flex items-center">
                  <div className="flex flex-col">
                    <span>{e?.name} </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">{e.created_by?.email}</div>
              <div className="flex items-center">{e.plan}</div>
              <div className="flex items-center ">{e.duration}</div>
              <div className="flex items-center">
                <span className="text-xs">
                  {new Date(e.start_date).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric"
                  })}{" "}
                </span>
              </div>
              <div className="flex items-center">
                {" "}
                <span className="text-xs">
                  {new Date(e.end_date).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric"
                  })}{" "}
                </span>{" "}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ExternalCoachingPrograms;
