import { useMemo } from "react";
import Chart from "../../../components/Charts/Chart";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { barChartDataZoom } from "../../../lib/constants";
import { resizeObserver } from "../../../lib/utils";
import { ITopicInterests } from "../analyticsReportTypes";

interface IProps {
  topicInterests: ITopicInterests[] | undefined;
  /**
   * Default true
   */
  expertIsEnabled?: boolean;
  /**
   * Default true
   */
  userIsEnabled?: boolean;
}

export const TopicInterests = ({ topicInterests, expertIsEnabled = true, userIsEnabled = true }: IProps) => {
  const options = useMemo(() => {
    if (!topicInterests) return null;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      legend: {},
      grid: {
        left: "20px",
        right: "50px",
        bottom: "0%",
        containLabel: true
      },
      xAxis: {
        type: "value"
      },
      yAxis: {
        boundaryGap: [0, "100%"],
        type: "category",
        scale: true,
        data: topicInterests.map((t) => t.topic),
        axisLabel: {
          overflow: "truncate",
          width: 150
        }
      },
      dataZoom: barChartDataZoom(topicInterests.length),

      series: [
        ...(expertIsEnabled
          ? [
              {
                name: "Expert",
                type: "bar",
                stack: "total",
                label: {
                  show: false,
                  formatter: (params: any) => {
                    if (params.data === 0) return "";
                    return params.data;
                  }
                },
                emphasis: {
                  focus: "series"
                },
                data: topicInterests.map((t) => t.expertCount),
                color: ["#c26700"],
                barWidth: "20px"
              }
            ]
          : []),
        ...(userIsEnabled
          ? [
              {
                name: "User",
                type: "bar",
                stack: "total",
                label: {
                  show: false,
                  formatter: (params: any) => {
                    if (params.data === 0) return "";
                    return params.data;
                  }
                },
                emphasis: {
                  focus: "series"
                },
                data: topicInterests.map((t) => t.userCount),
                color: ["#1cac78"]
              }
            ]
          : [])
      ]
    };
  }, [topicInterests]);

  return (
    <>
      {(!options || topicInterests?.length === 0) && <EmptyState iconSize={70} title="No data found" icon="Smile" />}
      {options && topicInterests && topicInterests?.length > 0 && <Chart options={options as any} resizeObserver={resizeObserver} />}
    </>
  );
};
