import { Button, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import styled from "styled-components";
import Grid from "../../../../../components/Layout/Grid";
import ProgramCardSkeleton from "../../../../../components/ProgramCard/ProgramCardSkeleton";
import SuspenseWrapper from "../../../../../components/SuspenseWrapper";
import SVG from "../../../../../components/SVG/SVG";
import { range } from "../../../../../lib/utils";
import { IProgram } from "../../../../Programs/ProgramTypes";
import ProgramSelection from "./ProgramSelection";
import TopicsAndDateSelection from "./TopicsAndDateSelection";

interface IProps {
  onClose: VoidFunction;
}

const LogSessionModal = ({ onClose }: IProps) => {
  const [program, setProgram] = useState<IProgram | null>(null);
  const [step, setStep] = useState(0);
  const [date, setDate] = useState<Date | null>(null);
  const [topics, setTopics] = useState<string[]>([]);

  // const modalSubtitle = useMemo(() => {
  //   if (step === 0) return "Select the program";
  //   if (step === 1) return "Select the discussed topics and the timing of the session";
  //   // if (step === 2) return "Select discussed topics";
  // }, [step]);

  const styles: { [key: string]: string } = {
    padding: "24px"
  };

  return (
    <Wrapper dir="ltr" className="dialog-max-h">
      <HeaderWrapper style={{ "--padding": styles["padding"] } as React.CSSProperties}>
        <Typography variant="h6">Log previous session</Typography>
        <Button className="z-10 end-5 top-5" style={{ position: "absolute" }} onClick={onClose} kind="ghost" variant="small" iconOnly>
          <SVG name="Close" />
        </Button>
      </HeaderWrapper>

      <ContentWrapper style={{ "--padding": styles["padding"] } as React.CSSProperties} className="dialog-content-max-h">
        {step === 0 && (
          <SuspenseWrapper
            fallback={
              <Grid gap="gap-6" gridTemplateColumns="grid-cols-1 md:grid-cols-2">
                {range(0, 6).map((i) => (
                  <ProgramCardSkeleton key={i} />
                ))}
              </Grid>
            }
          >
            <ProgramSelection
              selected={program}
              onProgramSelect={(p) => {
                setProgram(p);
                setStep(1);
              }}
            />
          </SuspenseWrapper>
        )}
        {step === 1 && (
          <TopicsAndDateSelection
            date={date}
            topics={topics}
            setTopics={setTopics}
            setDate={setDate}
            setStep={setStep}
            program={program as IProgram}
          />
        )}
      </ContentWrapper>

      <FooterWrapper style={{ "--padding": styles["padding"] } as React.CSSProperties}>
        <Button
          kind="ghost"
          onClick={() => {
            if (step === 0) return onClose();
            setStep(step - 1);
          }}
        >
          {step === 0 ? "Dismiss" : "Back"}
        </Button>
        {step !== 0 && (
          <Button
            disabled={!date || !topics.length}
            onClick={() => {
              if (step === 0) return onClose();
              setStep(step + 1);
            }}
          >
            {step === 1 ? "Next" : "Next"}
          </Button>
        )}
      </FooterWrapper>
    </Wrapper>
  );
};

export default LogSessionModal;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;
const HeaderWrapper = styled.div`
  padding: var(--padding);
  position: relative;
  border-bottom: 1px solid var(--color-border);
`;
const ContentWrapper = styled.div`
  padding: var(--padding);
`;
const FooterWrapper = styled.div`
  border-top: 1px solid var(--color-border);
  padding: var(--padding);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
