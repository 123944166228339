import React, { useEffect, useState } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import Select from "react-select";
import { Link } from "react-router-dom";
import { WarningInformation } from "../../../../components/Information/WarningInformation";

export const Matchmaking = () => {
  const [coaches, setCoachs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [matchStatus, setMatchStatus] = useState(null);

  useEffect(() => {
    getEmployees();
    getCoaches();
  }, [setCoachs, setEmployees]);

  function getCoaches() {
    AdminServices.getCoaches()
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setCoachs(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please contact support team");
      });
  }

  function getEmployees() {
    AdminServices.getEmployees()
      .then((res) => {
        if (res.data.status === true) {
          const { data } = res.data;
          setEmployees(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please contact support team");
      });
  }

  return (
    <div>
      <WarningInformation message="Important! This Page is now deprecated. Please consult dev team before applying any changes" />
      <WarningInformation
        message={
          <div className="italics">
            If you want to create coach matches for a user that is within an
            External Coaching Program created by <strong>Hr</strong>{" "}
            <Link className="font-bold text-red-700" to="/external-matching">
              click here or goto External Matching Page
            </Link>
          </div>
        }
      />

      {/* Program Filters */}
      <div className="flex flex-row my-4 justify-between px-4">
        <div className="flex flex-row  w-full items-center">
          <div className="text-sm font-semibol mr-8 flex flex-row items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
              />
            </svg>
            <span className="ml-2">Filters</span>
          </div>
          {/* Filters */}
          <div className="flex-row w-full modify-program-select hidden sm:flex">
            <div
              className="bg-white rounded-full w-full inline-block mr-4"
              style={{ maxWidth: "10rem" }}
            >
              <Select
                placeholder="Match Status"
                onChange={(e) => {
                  if (e === null) {
                    setMatchStatus(null);
                  } else {
                    setMatchStatus(e.value);
                  }
                }}
                className="rounded-full outline-none border-none text-sm"
                // classNamePrefix=""
                isClearable={true}
                isSearchable={true}
                options={[
                  { value: "Matched", label: "Matched" },
                  { value: "Unmatched", label: "Unmatched" }
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow">
        {/* table header */}
        <div className="grid grid-cols-2 p-4 font-bold text-sm xl:text-base border-b">
          <div>Employee</div>
          <div>Assigned Coach</div>
        </div>
        {employees.map((c, i) => (
          <Link
            to={{ pathname: "matching", state: { user: c, coaches: coaches } }}
            key={i}
          >
            <div
              className={`grid grid-cols-2 p-4text-sm xl:text-base p-4 hover:bg-gray-100 transition delay-350 duration-350
                         ${
                           matchStatus === null
                             ? "grid"
                             : matchStatus === "Matched" && c.assigned_coach
                             ? "grid"
                             : matchStatus === "Unmatched" && !c.assigned_coach
                             ? "grid"
                             : "hidden"
                         } `}
            >
              {!c.employee || !c.employee.first_name ? (
                <div> {c.email} </div>
              ) : (
                <div className="text-sm">
                  {c?.employee?.first_name} {c?.employee?.last_name}
                </div>
              )}

              <div className="text-sm">
                {" "}
                {c?.coach?.first_name} {c?.coach?.last_name}{" "}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
