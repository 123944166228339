import {
  Avatar,
  AvatarGroup,
  BaseDialog,
  Box,
  Stack,
  Typography,
  Button
} from "knack-ui";
import { useState } from "react";
import { useGetTeams } from "../../../../features/Team/teamQueries";
import { AddEditTeamModal } from "../Components/AddEditTeamModal";

export const Teams = () => {
  const { data: teams } = useGetTeams();
  const [open, setOpen] = useState(false);
  const [team, selectedTeam] = useState<string | null>();

  return (
    <Stack>
      <div className="flex-1 overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <Typography as="h5" variant="h6">
            Teams
          </Typography>
          <Button onClick={() => setOpen(true)}>Create Team</Button>
        </Box>

        <div className="grid gap-4 grid-cols-4 p-4">
          {teams?.map((t, i) => (
            <Box
              as="button"
              onClick={() => {
                selectedTeam(t._id);
                setOpen(true);
              }}
              key={t._id}
              variant="elevated"
              elevation={0}
              square
              className="grid gap-4 bg-white p-4 text-left"
            >
              <Box
                className="flex items-center gap-2 border-none shadow-none"
                elevation={0}
              >
                <Avatar
                  className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                  style={{ width: 30, height: 30 }}
                  text={t.organization.name}
                  image={t.organization.logo}
                />
                <Typography fontWeight="medium">
                  {t.organization.name}
                </Typography>
              </Box>
              <Typography fontWeight="bold" className="text-xl">
                {t.name}
              </Typography>
              <div className="flex justify-start">
                <AvatarGroup max={3}>
                  {t.members?.map((c) => (
                    <Avatar
                      onClick={(e) => null}
                      key={c?._id}
                      className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                      text={c?.email || ""}
                      image={c?.profile_image}
                    />
                  ))}
                </AvatarGroup>
              </div>
            </Box>
          ))}
        </div>

        <BaseDialog
          dialogClassName="max-w-3xl"
          allowPinchZoom
          isOpen={open}
          onClose={() => {
            setOpen(false);
            selectedTeam(null);
          }}
        >
          <AddEditTeamModal
            teamId={team as string}
            onClose={() => {
              setOpen(false);
              selectedTeam(null);
            }}
          />
        </BaseDialog>
      </div>
    </Stack>
  );
};
