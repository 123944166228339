import { Typography } from "knack-ui";
import { useMemo } from "react";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import LinearProgressWithLabel from "../../../../components/LinearProgressWithLabel";
import TitledDisplayBox from "../../../../components/TitledDisplayBox/TitledDisplayBox";
import { ISessionSatisfactionScoresAnalyticsResponse } from '../../../../features/AnalyticsReports/analyticsReportsTypes';
import { range } from "../../../../lib/utils";

interface IProps {
  satisfactionScores: ISessionSatisfactionScoresAnalyticsResponse;
}
/**
 * Satisfaction score chart for a program
 * @returns
 */
const SatisfactionScore = ({ satisfactionScores }: IProps) => {
  const averageSatisfactionScore = useMemo(() => {
    if (!satisfactionScores) return 0;
    if (!satisfactionScores.satisfactionScore.length) return 0;
    const sum = satisfactionScores.satisfactionScore.reduce((prev, curr) => prev + curr.avgScore, 0);
    return (sum / satisfactionScores.satisfactionScore.length).toFixed(1);
  }, [satisfactionScores]);

  return (
    <TitledDisplayBox title="Satisfaction score" subtitle={`How satisfied participant are in the sessions`}>
      {satisfactionScores?.satisfactionScore.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Smile" />}
      {satisfactionScores && satisfactionScores.satisfactionScore.length > 0 && (
        <div style={{ gridTemplateColumns: "1fr 0.4fr" }} className="grid h-full gap-4">
          <div className="relative">
            <div style={{ paddingBottom: 41 }} className="grid grid-cols-1 gap-4">
              {satisfactionScores?.satisfactionScore.map((c, i) => (
                <LinearProgressWithLabel barInnerText={c.avgScore.toFixed(2)} label={c._id} percentage={(c.avgScore / 5) * 100} key={i} />
              ))}
            </div>
            <div className="sticky bottom-0 z-10 flex justify-between pt-4 border-t bg-paper">
              {range(1, 5).map((i) => (
                <Typography fontWeight="bold" variant="subtitle2" key={i}>
                  {i}
                </Typography>
              ))}
            </div>
          </div>
          <div style={{ top: "25%" }} className="relative self-start">
            <Typography variant="body1" textAlign="center" className="mb-4" fontWeight="semibold">
              Average satisfaction score
            </Typography>
            <Typography variant="h4" textAlign="center" color="primary">
              {averageSatisfactionScore}{" "}
              <Typography variant="subtitle2" as="span" color="muted">
                out of 5
              </Typography>
            </Typography>
          </div>
        </div>
      )}
    </TitledDisplayBox>
  );
};

export default SatisfactionScore;
