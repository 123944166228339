import { Typography } from "knack-ui";
import { useEffect, useState } from "react";
import { CONFERENCING_PROFILES } from "../../../App.constant";
import { WarningInformation } from "../../../components/Information/WarningInformation";
import { HeadlessMenuDropdown } from "../../../components/Popper/HeadlessMenuDropdown";

export const AddConferencing = ({
  canUseIntegratedConferencing,
  customConferenceURL,
  calendarProfileAccount,
  link,
  setConferenceData,
  error,
  width = "w-72"
}) => {
  const [tapState, setTapState] = useState(false);
  const [selected, setSelected] = useState();
  const [conferencingProfiles, setConferencingProfiles] = useState([]);

  useEffect(() => {
    /**
     * Conferencing profiles assumptions
     * 1. Can be empty or [ ] ✔
     * 2. Can have a profile with profile_id:explicit ✔
     * 3. Conferencing maybe integrated but not included in the conferencing profile ✔
     * 4. Can have a conferencing profile record with profile_connected:false ✔
     */
    if (calendarProfileAccount) {
      const calendarAccount = calendarProfileAccount;
      const conf_profiles = calendarAccount["cronofy.data"].conferencing_profiles;
      const computedConferencingProfiles = [];

      if (canUseIntegratedConferencing) {
        // Support integrated conferencing for ms account
        const isMSAccountProfile = calendarAccount["cronofy.data"].profiles.find(
          (a) => a.profile_name === calendarAccount.email && a.provider_name === "exchange" && a.profile_connected === true
        );
        const isMSAccount = isMSAccountProfile?.profile_calendars?.find(
          (p) => p.calendar_primary === true && p.calendar_integrated_conferencing_available === true
        );

        if (isMSAccount) {
          computedConferencingProfiles.push({
            profile_name: calendarAccount.email,
            provider_name: "ms_teams",
            profile_id: "integrated",
            profile_connected: true
          });
        }

        // Support Google suite
        const isGoogleDomainAccount = calendarAccount["cronofy.data"].profiles.find(
          (a) => a.profile_name === calendarAccount.email && a.provider_name === "google" && a.profile_connected === true
        );

        // Support personal email account
        const isGmailAccount = calendarAccount.email.endsWith("@gmail.com");
        if (isGmailAccount || isGoogleDomainAccount) {
          computedConferencingProfiles.push({
            profile_name: calendarAccount.email,
            provider_name: "google_meet",
            profile_id: "integrated",
            profile_connected: true
          });
        }
      }

      // Add the connected conferencing profiles
      const connectedConferencingProfiles = conf_profiles.filter((c) => c.profile_connected);
      computedConferencingProfiles.push(...connectedConferencingProfiles);

      // Allow custom url
      const customUrlProfile = {
        profile_id: "explicit",
        profile_name: calendarAccount.email,
        provider_name: "explicit",
        profile_connected: true,
        provider_description: "Conference Link",
        join_url: ""
      };
      computedConferencingProfiles.push(customUrlProfile);

      // Allow face-2-face meeting
      computedConferencingProfiles.push({
        profile_id: "face-2-face",
        provider_name: "face-2-face"
      });

      if (customConferenceURL) {
        setSelected(customUrlProfile);
        setConferenceData({
          profile_id: "explicit",
          provider_description: "Conference Link",
          join_url: customConferenceURL
        });
      } else {
        // Set First available
        setSelected(computedConferencingProfiles[0]);
        setConferenceData({
          profile_id: computedConferencingProfiles[0].profile_id
        });
      }

      setConferencingProfiles(computedConferencingProfiles);
    }
  }, [calendarProfileAccount, canUseIntegratedConferencing]);

  return (
    <div className="grid gap-4 z-20">
      <div className="">Conferencing</div>
      <div className={`relative ${width}`}>
        <HeadlessMenuDropdown
          trigger={
            <div
              style={{ height: 50 }}
              className="relative gap-4 pl-4 flex flex-row items-center text-left rounded-lg cursor-pointer hover:bg-skin-lighter border"
              onClick={() => setTapState(!tapState)}
            >
              {CONFERENCING_PROFILES[selected?.provider_name]?.icon || (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              )}
              <div className="font-medium">{CONFERENCING_PROFILES[selected?.provider_name]?.type}</div>
              {/* Droplet Arrow */}
              <div className={`absolute right-0 translate transform delay-300 duration-300 p-4 ${tapState ? "" : "-rotate-180"}`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="var(--color-accent)">
                  <path
                    id="Icon_ionic-md-arrow-dropdown"
                    data-name="Icon ionic-md-arrow-dropdown"
                    d="M9,13.5l9,9,9-9Z"
                    transform="translate(-9 -13.5)"
                  />
                </svg>
              </div>
            </div>
          }
          popComponent={
            <div className="right-0 z-auto w-full pt-4 -mt-3 overflow-y-auto scrollhost2" style={{ maxHeight: 400 }}>
              <div className="overflow-hidden bg-white border rounded-lg shadow-md ">
                {conferencingProfiles.map((m, i) => (
                  <div key={i}>
                    {m?.profile_id !== selected?.profile_id && (
                      <div
                        style={{ height: 50 }}
                        className="relative gap-4 pl-4 flex flex-row items-center text-left cursor-pointer hover:bg-skin-lighter border"
                        onClick={() => {
                          setSelected(m);
                          setTapState(!tapState);
                          setConferenceData(m);
                          if (customConferenceURL && m.profile_id === "explicit") {
                            setConferenceData({
                              profile_id: "explicit",
                              provider_description: "Conference Link",
                              join_url: customConferenceURL
                            });
                          }
                        }}
                      >
                        {CONFERENCING_PROFILES[m?.provider_name]?.icon || (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                            />
                          </svg>
                        )}
                        <div className="font-medium">{CONFERENCING_PROFILES[m.provider_name]?.type}</div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          }
        />
      </div>

      {selected?.profile_id === "explicit" && (
        <div className={`rounded-lg conferencing-item ${width}`}>
          <div className={`flex flex-row gap-2 items-center rounded-t-lg  ${error.show ? "border-2 border-red-400" : ""}`}>
            <input
              className="bg-transparent border-none m-0 bg-none p-4 w-full"
              defaultValue={link}
              onChange={(e) =>
                setConferenceData({
                  profile_id: "explicit",
                  provider_description: "Conference Link",
                  join_url: e.target.value
                })
              }
              placeholder="Paste meeting link"
            />
          </div>
          {error?.show && (
            <>
              <WarningInformation
                message={
                  <>
                    <Typography variant="body2">Please provide a valid meeting link</Typography>
                  </>
                }
              />
              <Typography variant="caption" fontWeight="normal">
                {error?.message}
              </Typography>
            </>
          )}
        </div>
      )}
    </div>
  );
};
