import { useMutation, useQueryClient } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { sessionQueryKeys } from "./sessionsQueries";
import { ICalendarEvent, IMeeting, IRateExternalCoach, IRateInternalCoach, IRateMentor, ISession, ISessionNote } from "./SessionsTypes";

// create session
async function createSessionNote({
  content,
  color,
  sessionId,
  shared_with
}: {
  content: string;
  color: string;
  sessionId: string;
  shared_with: string[];
}) {
  const res = await apiHttp.post<ServerResponse<ISessionNote[]>>("/sessions/notes", {
    color,
    content,
    session: sessionId,
    shared_with
  });
  return res.data.data;
}

async function editSessionNote({
  noteId,
  content,
  color,
  shared_with,
  contentUpdatedAt
}: {
  noteId: string;
  content?: string;
  color?: string;
  shared_with?: string[];
  contentUpdatedAt?: number;
}) {
  const res = await apiHttp.post<ServerResponse<ISessionNote[]>>("/sessions/notes/" + noteId, {
    color,
    content,
    shared_with,
    contentUpdatedAt
  });
  return res.data.data;
}

async function deleteSessionNote(noteId: string) {
  const res = await apiHttp.delete<ServerResponse<ISessionNote[]>>("/sessions/notes/" + noteId);
  return res.data.data;
}

async function markSessionComplete({ sessionId, createRatingTask }: { sessionId: string; createRatingTask: boolean }) {
  const res = await apiHttp.post<ServerResponse<ISessionNote[]>>(
    `sessions/mark-complete/${sessionId}`,
    {},
    {
      params: {
        ...(createRatingTask && { createRatingTask: true })
      }
    }
  );
  return res.data.data;
}

async function updateSession({ updates, sessionId }: { updates: Partial<ISession>; sessionId: string }) {
  const res = await apiHttp.post<ServerResponse<ISession>>(`sessions/update/` + sessionId, updates);
  return res.data.data;
}

interface CalendarEventResponse {
  events: ICalendarEvent[];
}

async function getCalendarEvents({
  filter,
  scheduler_id,
  organizerUserId
}: {
  filter: string;
  scheduler_id: string;
  organizerUserId: string;
}) {
  const res = await apiHttp.post<ServerResponse<CalendarEventResponse>>(`scheduler/meetings/${scheduler_id}/${organizerUserId}`, {
    filter
  });
  return res.data.data.events;
}

async function deleteCalendarEvent({
  event_id,
  scheduler_id,
  organizerUserId
}: {
  event_id: string;
  scheduler_id: string;
  organizerUserId: string;
}) {
  const res = await apiHttp.delete<ServerResponse<any>>(`scheduler/delete-event/${event_id}/${scheduler_id}/${organizerUserId}`);
  return res.data;
}

async function deleteSession(sessionId: string | undefined) {
  if (!sessionId) {
    throw new Error("session id is required");
  }
  const res = await apiHttp.delete<ServerResponse<any>>(`sessions/${sessionId}`);
  return res.data;
}

async function updateCalendarEvent({
  event,
  scheduler_id,
  organizerUserId
}: {
  event: IMeeting;
  scheduler_id: string;
  organizerUserId: string;
}) {
  const res = await apiHttp.post<ServerResponse<any>>(`scheduler/update-meeting/${scheduler_id}/${organizerUserId}`, { ...event });
  return res.data;
}

async function updateParticipationStatus({
  scheduler_id,
  event_uid,
  status
}: {
  scheduler_id: string;
  event_uid: string;
  status: "accepted" | "declined" | "tentative";
}) {
  const res = await apiHttp.post<ServerResponse<ISession>>("scheduler/participation-status/" + event_uid + "/" + scheduler_id, { status });
  return res.data.data;
}

async function saveSessionRating(rating: IRateExternalCoach | IRateInternalCoach | IRateMentor) {
  const res = await apiHttp.post<ServerResponse<IRateExternalCoach | IRateInternalCoach | IRateMentor>>("sessions/ratings", rating);
  return res.data.data;
}

async function markRatingTaskComplete({ status, task_id, user }: { status: boolean; task_id: string; user: string }) {
  const res = await apiHttp.post<ServerResponse<any>>("sessions/tasks/mark-complete", {
    status,
    task_id,
    user
  });
  return res.data.data;
}

//  Mutations
export const useCreateSessionNote = () => {
  const queryClient = useQueryClient();
  return useMutation(createSessionNote, {
    onSuccess: () => {
      queryClient.invalidateQueries([{ entity: "notes" }]);
    }
  });
};

export const useEditSessionNote = () => {
  const queryClient = useQueryClient();
  return useMutation(editSessionNote, {
    onSuccess: () => {
      queryClient.invalidateQueries([{ entity: "notes" }]);
    }
  });
};

export const useDeleteSessionNote = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSessionNote, {
    onSuccess: () => {
      queryClient.invalidateQueries([{ entity: "notes" }]);
    }
  });
};

export const useMarkSessionComplete = () => {
  const queryClient = useQueryClient();
  return useMutation(markSessionComplete, {
    onSuccess: () => {
      queryClient.invalidateQueries(sessionQueryKeys["all"]);
    }
  });
};

export const useUpdateSession = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSession, {
    onSuccess: () => {
      queryClient.invalidateQueries(sessionQueryKeys["all"]);
    }
  });
};

export const useUpdateParticipationStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateParticipationStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(sessionQueryKeys["all"]);
    }
  });
};

export const useSaveSessionRating = () => {
  return useMutation(saveSessionRating);
};

export const useFetchCalendarEvents = () => {
  return useMutation(getCalendarEvents);
};

export const useDeleteCalendarEvent = () => {
  return useMutation(deleteCalendarEvent);
};

export const useUpdateCalendarEvent = () => {
  return useMutation(updateCalendarEvent);
};

export const useMarkRatingTaskComplete = () => {
  const queryClient = useQueryClient();
  return useMutation(markRatingTaskComplete, {
    onSuccess: () => {
      queryClient.invalidateQueries(sessionQueryKeys.ratingTasks());
    }
  });
};

export const useDeleteSession = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSession, {
    onSuccess: () => {
      queryClient.invalidateQueries(sessionQueryKeys["all"]);
    }
  });
};
