import { Box, Button, Stack, Typography } from "knack-ui";
import SVG, { SVGProps } from "../SVG/SVG";
import MetricCardLoading from "./MetricCard.loading";

interface IMetricCardProps {
  icon: SVGProps["name"];
  title: string;
  value?: string | number;
  color?: string;
  isLoading?: boolean;
  rightButton?: {
    onClick: VoidFunction;
    text: string;
  };
  clamp?: any;
}

const MetricCard = ({ icon, title, value, color, isLoading, rightButton, clamp = 0 }: IMetricCardProps) => {
  if (isLoading) return <MetricCardLoading />;
  return (
    <Box direction="row" variant="outlined" as={Stack} gap={4} alignItems="center" className="p-4 w-full justify-between">
      <Box as={Stack} gap={4} alignItems="center" elevation={0}>
        <div className={`text-white ${color || "bg-primary"} flex items-center rounded-lg justify-center p-3`}>
          <SVG size={25} name={icon} />
        </div>

        <div className="flex-1">
          <Typography className="mb-1" variant="caption">
            {title}
          </Typography>
          <Typography clamp={clamp} variant="body1" fontWeight="bold" className="max-w-3xl">
            {value}
          </Typography>
        </div>
      </Box>
      {rightButton && <Button onClick={() => rightButton.onClick()}>{rightButton.text}</Button>}
    </Box>
  );
};

export default MetricCard;
