import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BackButton } from "../../../../components/nav/BackButton";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { PageHeader } from "../../../../components/Typography/PageHeader";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import { MatchmakingSelectionInput } from "../Components/MatchmakingSelectionInput";

export const EmployeeMatching = () => {
  const location = useLocation();
  const [user, setUser] = useState({});
  const [matches, setMatches] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    if (location.state && location.state.user) {
      setUser(location.state.user);
      setCoaches(location.state.coaches);
      console.log(location.state.user);
      getCoachMatches();
    }
  }, []);

  function getCoachMatches() {
    AdminServices.getUserCoachMatches(location.state.user._id)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setMatches(data);
          console.log(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  return (
    <div>
      <BackButton />

      <PageHeader
        PageTitle="Coach Preference for"
        postHighlightedText={
          user.employee && user.employee.first_name
            ? `${user?.employee?.first_name} ${user?.employee?.last_name}`
            : user.email
        }
      />

      <div className="bg-white rounded-lg shadow mt-4">
        {/* table header */}
        <div className="grid grid-cols-3 p-4 text-sm xl:text-base border-b bg-gray-100 rounded-t-lg">
          <div>Coach Gender</div>
          <div>Coach Language Preference</div>
          <div>Goals</div>
        </div>

        <div className="grid grid-cols-3 p-4text-sm xl:text-base p-4 hover:bg-blue-50 cursor-pointer font-medium">
          <div>
            {user?.employee?.coach_gender_preference === "other"
              ? "No Preference"
              : user?.employee?.coach_gender_preference}
          </div>
          <div>{user?.employee?.coach_language_preference}</div>
          <div>{user?.employee?.focus?.toLocaleString()}</div>
        </div>
      </div>

      {/* Select Coach */}

      <div className="my-4 font-bold ">Select Coach </div>
      {/* Match Inputs */}
      <div className="grid sm:grid-cols-3 gap-4">
        <MatchmakingSelectionInput
          matches={matches}
          // onRefetch={ }
          coaches={[
            ...coaches
              .map((c) => ({
                value:
                  c?.profile_data?.first_name +
                  " " +
                  c?.profile_data?.last_name,
                label:
                  c?.profile_data?.first_name +
                  " " +
                  c?.profile_data?.last_name,
                _id: c._id
              }))
              .filter((f) => !f.label.includes("undefined"))
          ]}
          employeeId={user._id}
        />
      </div>
    </div>
  );
};
