import React from "react";

/**
 * @param {*} PageTile set a title for the page
 * postHighlightedText is text to highlight after title
 * @returns
 */
export const PageHeader = ({
  PageTitle,
  postHighlightedText,
  classes = "text-base sm:text-2xl font-extrabold text-skin-base flex flex-row"
}) => {
  return (
    <>
      <div data-testid="page-title" className={`${classes}`}>
        {PageTitle}{" "}
        <div
          style={{
            textDecorationStyle: "double",
            textDecorationLine: "underline"
          }}
          className="ml-2 text-skin-accent"
        >
          {" "}
          {postHighlightedText}
        </div>
      </div>
    </>
  );
};
