import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../../hooks/useAxiosConfig";
import { apiHttp } from "../../../lib/appConfig";
import {
  PairingChartResponse,
  PairingReportsQuery,
  PairingReportsResponse,
  PairingSummaryResponse,
  UnpairedReportResponse
} from "../../AnalyticsReports/analyticsReportsTypes";
import { QUERY_ENTITIES } from "../../../lib/constants";

export const pairingReportKeys = {
  all: [{ scope: "pairing report" }] as const,
  pairingsTableData: ({ query }: { query?: PairingReportsQuery }) =>
    [{ ...pairingReportKeys.all[0], entity: QUERY_ENTITIES.pairingsTableData, ...query }] as const,
  unpaired: ({ query }: { query?: PairingReportsQuery }) =>
    [{ ...pairingReportKeys.all[0], entity: QUERY_ENTITIES.unpaired, ...query }] as const,
  summary: ({ query }: { query?: PairingReportsQuery }) =>
    [{ ...pairingReportKeys.all[0], entity: QUERY_ENTITIES.summary, ...query }] as const,
  chart: ({ query }: { query?: PairingReportsQuery }) => [{ ...pairingReportKeys.all[0], entity: QUERY_ENTITIES.chart, ...query }] as const
};

async function fetchPairingReportTableData({
  queryKey: [{ organization, program, status, entities, department, management_level, position }],
  pageParam
}: QueryFunctionContext<ReturnType<typeof pairingReportKeys["pairingsTableData"]>>) {
  const res = await apiHttp.get<ServerResponse<PairingReportsResponse>>("v2/analytics/connections", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      page: pageParam,
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          status: status.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(position &&
        position?.length > 0 && {
          position: position.join(",")
        })
    }
  });
  return res.data.data;
}
async function fetchUnpaired({
  queryKey: [{ organization, program, status, entities, department, management_level, position }],
  pageParam
}: QueryFunctionContext<ReturnType<typeof pairingReportKeys["unpaired"]>>) {
  const res = await apiHttp.get<ServerResponse<UnpairedReportResponse>>("v2/analytics/pairings", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      page: pageParam,
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          status: status.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(position &&
        position?.length > 0 && {
          position: position.join(",")
        })
    }
  });
  return res.data.data;
}
async function fetchPairingsSummary({
  queryKey: [{ organization, program, status, entities, department, management_level, position }]
}: QueryFunctionContext<ReturnType<typeof pairingReportKeys["summary"]>>) {
  const res = await apiHttp.get<ServerResponse<PairingSummaryResponse>>("v2/analytics/pairings/summary", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          status: status.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(position &&
        position?.length > 0 && {
          position: position.join(",")
        })
    }
  });
  return res.data.data;
}
async function fetchPairingsChart({
  queryKey: [{ organization, program, status, entities, department, management_level, position }],
  pageParam
}: QueryFunctionContext<ReturnType<typeof pairingReportKeys["chart"]>>) {
  const res = await apiHttp.get<ServerResponse<PairingChartResponse>>("v2/analytics/pairings/chart", {
    params: {
      ...(organization &&
        organization?.length > 0 && {
          organization: organization.join(",")
        }),
      ...(status &&
        status?.length > 0 && {
          status: status.join(",")
        }),
      ...(program &&
        program?.length > 0 && {
          program: program.join(",")
        }),
      ...(department &&
        department?.length > 0 && {
          department: department.join(",")
        }),
      ...(entities &&
        entities?.length > 0 && {
          entity: entities.join(",")
        }),
      ...(management_level &&
        management_level?.length > 0 && {
          management_level: management_level.join(",")
        }),
      ...(position &&
        position?.length > 0 && {
          position: position.join(",")
        })
    }
  });
  return res.data.data;
}

export const useGetPairingsData = <SelectData = PairingReportsResponse, Error = unknown>(
  { query }: { query?: PairingReportsQuery },
  options?: UseInfiniteQueryOptions<
    PairingReportsResponse,
    Error,
    SelectData,
    PairingReportsResponse,
    ReturnType<typeof pairingReportKeys["pairingsTableData"]>
  >
) => {
  return useInfiniteQuery<PairingReportsResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["pairingsTableData"]>>(
    pairingReportKeys.pairingsTableData({ query }),
    fetchPairingReportTableData,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.currentPage < lastPage.totalPages) {
          return +lastPage.currentPage + 1;
        }
        return undefined;
      },
      ...options
    }
  );
};
export const useGetUnpaired = <SelectData = UnpairedReportResponse, Error = unknown>(
  { query }: { query?: PairingReportsQuery },
  options?: UseQueryOptions<UnpairedReportResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["unpaired"]>>
) => {
  return useQuery<UnpairedReportResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["unpaired"]>>(
    pairingReportKeys.unpaired({ query }),
    fetchUnpaired,
    {
      ...options
    }
  );
};
export const useGetPairingsSummary = <SelectData = PairingSummaryResponse, Error = unknown>(
  { query }: { query?: PairingReportsQuery },
  options?: UseQueryOptions<PairingSummaryResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["summary"]>>
) => {
  return useQuery<PairingSummaryResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["summary"]>>(
    pairingReportKeys.summary({ query }),
    fetchPairingsSummary,
    {
      ...options
    }
  );
};
export const useGetPairingsChart = <SelectData = PairingChartResponse, Error = unknown>(
  { query }: { query?: PairingReportsQuery },
  options?: UseQueryOptions<PairingChartResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["chart"]>>
) => {
  return useQuery<PairingChartResponse, Error, SelectData, ReturnType<typeof pairingReportKeys["chart"]>>(
    pairingReportKeys.chart({ query }),
    fetchPairingsChart,
    {
      ...options
    }
  );
};
