import React, { useEffect, useMemo, useRef } from "react";
import { ProgramState } from "../../../../../../../StateManagement/ProgramCreation";
import DatePicker from "react-datepicker";
import { HTMLEditor } from "../../../../../../../components/Editor/HTMLEditor";
import moment from "moment";
import { useState } from "react";
import Select from "react-select";
import { Button, Input, Stack, Typography } from "knack-ui";
import { Delete } from "../../../../../../../components/SVG/Delete";
import DeleteButton from "../../../../../../../components/DeleteButton/DeleteButton";
import Divider from "../../../../../../../components/Divider/Divider";
import DividerWithText from "../../../../../../../components/Divider/DividerWithText";
import { CustomToggle } from "../../../../../../../components/Inputs/CustomToggle";

export const ProgramOverview = ({ reference }) => {
  const questionForExpertInputRef = useRef(null);
  const referralSourceInputRef = useRef(null);

  const [questionForExpert, setQuestionForExpert] = useState();
  const [referralSource, setReferralSource] = useState();

  const { program, setProgram } = ProgramState();
  const [user] = useState(JSON.parse(localStorage.getItem("user"))?.data);
  const sessionsPerCoachee = useMemo(() => {
    if (!program.start_date && !program.end_date) return 1;
    try {
      const startDate = moment(program.start_date);
      const endDate = moment(program.end_date);
      // const sessionDuration = 15; // 14 days, each 14 days we can have a new session, or we can set this from the settings
      const differenceInDays = endDate.diff(startDate, "days");
      console.log({ differenceInDays: differenceInDays / 31 });
      // return Math.floor(differenceInDays / sessionDuration);
      return Math.round(differenceInDays / 30) * 2;
    } catch (error) {
      console.error(error);
    }
  }, [program.start_date, program.end_date]);
  useEffect(() => {
    if (program.program_type === "Mentoring" || program?.coach_type === "own_coach") {
      return;
    }
    const _program = { ...program };
    if (sessionsPerCoachee > 0) {
      _program.session_count = sessionsPerCoachee;
      setProgram(_program);
    } else {
      _program.session_count = 1;
      setProgram(_program);
    }
  }, [program, sessionsPerCoachee, setProgram]);

  const selectOptions = useMemo(() => {
    return [
      { value: "1:1", label: "1:1" },
      { value: "group", label: "Group" }
    ];
  }, []);

  const participantsNames = useMemo(() => {
    return {
      user: program.program_type === "Mentoring" ? "Mentee" : "Coachee",
      expert: program.program_type === "Mentoring" ? "Mentor" : "Coach"
    };
  }, [program]);

  const addQuestionsForExperts = () => {
    const uniqueQuestions = Array.from(new Set([questionForExpert, ...program.questionsForExperts]));
    const updatedProgram = {
      ...program,
      questionsForExperts: uniqueQuestions
    };
    setProgram(updatedProgram);
    questionForExpertInputRef.current.value = "";
    setQuestionForExpert("");
  };

  const deleteQuestionForExperts = (questionToDelete) => {
    const filteredQuestions = program.questionsForExperts.filter((q) => q !== questionToDelete);
    const updatedProgram = {
      ...program,
      questionsForExperts: filteredQuestions
    };
    setProgram(updatedProgram);
  };

  const addOptionsForReferralSource = () => {
    const uniqueOptions = Array.from(new Set([referralSource, ...program.referralSourceOptions]));
    const updatedProgram = {
      ...program,
      referralSourceOptions: uniqueOptions
    };
    setProgram(updatedProgram);
    referralSourceInputRef.current.value = "";
    setReferralSource("");
  };

  const deleteReferralSource = (optionToDelete) => {
    const filteredOptions = program.referralSourceOptions.filter((q) => q !== optionToDelete);
    const updatedProgram = {
      ...program,
      referralSourceOptions: filteredOptions
    };
    setProgram(updatedProgram);
  };

  return (
    <section ref={reference} className="relative z-50 mb-6">
      <div className="grid gap-1">
        {["Admin", "Subadmin"].includes(user.account_type) && (
          <div className="py-2">
            <div className="mb-4 text-sm font-bold">Plan</div>
            <Select
              value={selectOptions.find((i) => i.value === program.plan)}
              onChange={(option) => {
                if (option) {
                  const _program = { ...program };
                  _program.plan = option.value;
                  setProgram(_program);
                }
              }}
              className="text-sm border-none rounded-none outline-none"
              classNamePrefix="Select Program"
              isClearable={false}
              isSearchable={false}
              options={selectOptions}
            />
          </div>
        )}
        {/* Program Title */}
        <div className="py-2">
          <div className="mb-4 text-sm font-bold">What do you want to name this program?</div>
          <input
            type="text"
            className="w-full border-gray-300 rounded-md"
            defaultValue={program.name}
            onChange={(v) => {
              const _program = { ...program };
              _program.name = v.target.value;
              setProgram(_program);
            }}
          />
        </div>

        {/* Program Description */}
        <div className="py-2">
          <div className="mb-4 text-sm font-bold">How would you describe this program?</div>

          <HTMLEditor
            defaultValue={program.overview}
            onChange={(value) => {
              const _program = { ...program };
              _program.overview = value;
              setProgram(_program);
            }}
          />
        </div>

        {/* Number of Sessions, Only outside Knack coaching */}
        {program.program_type === "Mentoring" || program?.coach_type === "own_coach" ? (
          <div className="py-2">
            <div className="mb-4 text-sm font-bold">What is the minimum required sessions to be completed?</div>
            <Input
              max="999"
              value={program.session_count}
              onChange={(v) => {
                const session_count = v.target.value.replace(/\D/g, "");
                const re = /^[0-9\b]+$/;
                // if ( || re.test(e.target.value)) {
                if (
                  (v.target.value === "" || re.test(v.target.value)) && // should number
                  v.target.value.indexOf("e") === -1 && // don't allow exponential value
                  v.target.value.indexOf(".") === -1 && // don't allow decimal value
                  session_count < 1000 && // should be less than 1000
                  v.target.value.indexOf("0") !== 0
                ) {
                  // should not start with 0
                  const _program = { ...program };
                  _program.session_count = session_count;
                  setProgram(_program);
                }
              }}
            />
          </div>
        ) : null}
        <div className="flex flex-wrap py-2 -mx-3">
          <div className="w-full px-3 md:w-1/2 md:mb-0">
            <div className="mb-4 text-sm font-bold">When do you want to the program to start?</div>
            <div className="relative flex flex-row items-center justify-between">
              <DatePicker
                shouldCloseOnSelect={true}
                dateFormat="MMMM d, yyyy"
                selected={program.start_date ? new Date(program.start_date) : ""}
                customInput={<Input />}
                onChange={(date) => {
                  const _program = { ...program };
                  _program.start_date = date;
                  setProgram(_program);
                  // if(_program.end_date >= _program.start_date){
                  //     return
                  // }else{
                  //     Failure('StartDate is greater than EndDate')
                  // }
                }}
                placeholderText="Select a date"
                // placeholder="Select start time"
                // placeholderText={new Date()}
                // className="w-full border-gray-300 rounded-lg text-skin-base"
              />
              <div className="absolute right-0 flex items-center justify-center w-8 h-8 mr-4 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="mr-3" viewBox="0 0 20 20" fill="gray">
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full px-3 md:w-1/2">
            <div className="mb-4 text-sm font-bold">When do you want to the program to end?</div>
            <div className="relative flex flex-row items-center justify-between">
              <DatePicker
                shouldCloseOnSelect={true}
                dateFormat="MMMM d, yyyy"
                selected={program.end_date ? new Date(program.end_date) : ""}
                customInput={<Input />}
                onChange={(date) => {
                  const _program = { ...program };
                  _program.end_date = date;
                  setProgram(_program);
                  // if(_program.end_date >= _program.start_date){
                  //     return
                  // }else{
                  //     Failure('Start date is greater than end date')
                  // }
                }}
                placeholderText="Select a date"
                // className="w-full border-gray-300 rounded-lg text-skin-base"
              />
              <div className="absolute right-0 flex items-center justify-center w-8 h-8 mr-4 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="mr-3" viewBox="0 0 20 20" fill="gray">
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {program.program_type !== "Mentoring" && program?.coach_type !== "own_coach" ? (
          <div>
            <h6 className="text-skin-muted">
              Total sessions per coachee:{" "}
              {sessionsPerCoachee < 0 ? (
                <span className="text-red-400">Please select a valid period.</span>
              ) : program.session_count >= 0 ? (
                program.session_count
              ) : (
                <span className="text-skin-accent">Please set Program period</span>
              )}
            </h6>
          </div>
        ) : null}

        <DividerWithText text="Optional Information" className="my-10" />

        {/* Program Tag */}
        <div className="py-2">
          <div className="mb-4 text-sm font-bold">Add tag</div>
          <Input
            maxLength={38}
            placeholder="e.g One-to-One Mentoring"
            defaultValue={program.tag}
            onChange={(v) => {
              const _program = { ...program };
              _program.tag = v.target.value;
              setProgram(_program);
            }}
          />
          <p className="font-light text-xs mt-2">{program?.tag?.length || 0}/38 characters</p>
        </div>

        {/* Naming replacements */}
        {program?.coach_type !== "knack_coaching" && (
          <Stack direction="column">
            <Typography className="mb-2" variant="h6">
              Customize Role Naming (optional)
            </Typography>
            <Typography className="mb-4" variant="subtitle2">
              Modify the default naming for your program roles. This is the custom name that will replace the default '
              {participantsNames.expert}/{participantsNames.user}' in your program. For instance, you can replace '
              {participantsNames.expert}' with 'Advisor'.
            </Typography>
            <Stack direction="column" gap={4}>
              <Stack alignItems="center" gap={4}>
                <Typography variant="subtitle1" className="w-20">
                  {participantsNames.user}
                </Typography>
                <Input
                  defaultValue={program.menteeCoacheeWordReplacement}
                  placeholder="Enter replacement"
                  type="text"
                  className="w-full border-gray-300 rounded-md"
                  onChange={(v) => {
                    const _program = { ...program };
                    _program.menteeCoacheeWordReplacement = v.target.value;
                    setProgram(_program);
                  }}
                />
              </Stack>
              <Stack alignItems="center" gap={4}>
                <Typography variant="subtitle1" className="w-20">
                  {participantsNames.expert}
                </Typography>
                <Input
                  defaultValue={program.mentorCoachWordReplacement}
                  placeholder="Enter replacement"
                  type="text"
                  className="w-full border-gray-300 rounded-md"
                  onChange={(v) => {
                    const _program = { ...program };
                    _program.mentorCoachWordReplacement = v.target.value;
                    setProgram(_program);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        )}

        {/* Questions for experts during their program registration */}
        {program?.coach_type !== "knack_coaching" && (
          <Stack direction="column" className="mt-4">
            <Typography className="mb-2" variant="h6">
              Onboarding Questions for {participantsNames.expert}s in the Program
            </Typography>
            <Typography className="mb-4" variant="subtitle2">
              Any questions added here will be presented to {participantsNames.expert}s during program registration. It is mandatory for
              experts to respond to each question.
            </Typography>
            <Stack direction="column" gap={4}>
              <form
                onSubmit={(e) => {
                  e.preventDefault(); // Prevent default form submission behavior
                  addQuestionsForExperts();
                }}
              >
                <Stack alignItems="center" gap={4}>
                  <Input
                    style={{
                      padding: "5px",
                      transition: "width 0.3s"
                    }}
                    placeholder="Type your question"
                    type="text"
                    className="w-full border-gray-300 rounded-md"
                    onChange={(v) => setQuestionForExpert(v.target.value)}
                    ref={questionForExpertInputRef}
                    defaultValue={questionForExpert}
                  />

                  <Button type="submit" disabled={!Boolean(questionForExpert)}>
                    Add Question
                  </Button>
                </Stack>
              </form>

              <Stack gap={4} direction="column" className="max-h-96 overflow-y-scroll h-full">
                {program.questionsForExperts.map((question, index) => (
                  <Stack key={index} alignItems="center" gap={4} className="w-full">
                    <Typography className="w-full">{question}</Typography>
                    <DeleteButton onClick={() => deleteQuestionForExperts(question)} />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}

        {/* Program Auto enrollment flag */}
        {program?.coach_type !== "knack_coaching" && (
          <div className="py-2">
            <Stack direction="column">
              <Typography className="mb-2" variant="h6">
                Auto Enrollment
              </Typography>
              <Typography className="mb-4" variant="subtitle2">
                Turn this on to automatically enroll invited participants; allowing them to skip the program registration step.{" "}
              </Typography>
              <CustomToggle
                state={program.isAutoEnrollment}
                onChange={(v) => {
                  const _program = { ...program };
                  _program.isAutoEnrollment = v;
                  setProgram(_program);
                }}
              />
            </Stack>
          </div>
        )}

        {/* Referral Source */}
        {program?.coach_type !== "knack_coaching" && (
          <Stack direction="column" className="mt-4">
            <Typography className="mb-2" variant="h6">
              Referral Source
            </Typography>
            <Typography className="mb-4" variant="subtitle2">
              Please provide a list of options that participants can choose from to indicate how they discovered this program.
            </Typography>
            <Stack direction="column" gap={4}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  addOptionsForReferralSource();
                }}
              >
                <Stack alignItems="center" gap={4}>
                  <Input
                    style={{
                      padding: "5px",
                      transition: "width 0.3s"
                    }}
                    placeholder="Type an option"
                    type="text"
                    className="w-full border-gray-300 rounded-md"
                    onChange={(v) => setReferralSource(v.target.value)}
                    ref={referralSourceInputRef}
                    defaultValue={referralSource}
                  />

                  <Button type="submit" disabled={!Boolean(referralSource)}>
                    Add option
                  </Button>
                </Stack>
              </form>

              <Stack gap={4} direction="column" className="max-h-96 overflow-y-scroll h-full">
                {program.referralSourceOptions.map((option, index) => (
                  <Stack key={index} alignItems="center" gap={4} className="w-full">
                    <Typography className="w-full">{option}</Typography>
                    <DeleteButton onClick={() => deleteReferralSource(option)} />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        )}
      </div>
    </section>
  );
};
