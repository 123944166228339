import { format } from "date-fns";
import { Box, Stack, Typography, Avatar, Button } from "knack-ui";
import { memo } from "react";
import { ITask } from "../SessionsTypes";

interface IProps {
  task: ITask;
  onRateSession: (task: ITask) => void;
}

 const RatingTaskItem = ({ task, onRateSession }: IProps) => {
  return (
    <Box variant="outlined" className="self-start w-full p-4">
      <Stack
        gap={4}
        justifyContent="space-between"
        alignItems="center"
        className="flex-1"
      >
        <Stack alignItems="center" gap={2}>
          <Avatar
            size="medium"
            className="flex-shrink-0"
            text={(task.session?.expert?.first_name as string) || ""}
            image={task.session?.expert?.user?.profile_image}
          />
          <Stack alignItems="start" direction="column">
            <Typography clamp={1} fontWeight="semibold" variant="caption">
              on{" "}
              {format(
                new Date(task?.session?.start_time),
                "LLL dd yyyy, kk:mm a"
              )}
            </Typography>
            <Typography fontWeight="semibold" variant="body2">
              with {task.session?.expert?.first_name}{" "}
              {task.session?.expert?.last_name}
            </Typography>
          </Stack>
        </Stack>
        <Button variant="small" onClick={() => onRateSession(task)}>
          Rate session
        </Button>
      </Stack>
    </Box>
  );
};
export default memo(RatingTaskItem)