import { Checkbox, Stack, Typography } from "knack-ui";
import {
  ISurveyAnswerComponentProps,
  MultiValueAnswer
} from "../../surveyTypes";

const SurveyCheckboxAnswer = ({
  index,
  questionIndex,
  onChange,
  value,
  question,
  sectionIndex
}: ISurveyAnswerComponentProps) => {
  const optionLabel = question.options[index].option_label;
  return (
    <Stack gap={4} alignItems="center">
      <Checkbox
        checked={value.includes(optionLabel)}
        id={`${optionLabel} ${index} ${sectionIndex} ${questionIndex} - checkbox`}
        onChange={() => {
          let newValue = value as MultiValueAnswer;
          if (newValue.includes(optionLabel)) {
            onChange(newValue.filter((i) => i !== optionLabel));
          } else {
            newValue = [...newValue, optionLabel];
            onChange(newValue);
          }
        }}
      />
      <Typography
        className="block"
        variant="body1"
        fontWeight="medium"
        as="label"
        htmlFor={`${optionLabel} ${index} ${sectionIndex} ${questionIndex} - checkbox`}
      >
        {optionLabel}
      </Typography>
    </Stack>
  );
};

export default SurveyCheckboxAnswer;
