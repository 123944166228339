import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { BackButton } from "../../../../components/nav/BackButton";

export const AssessmentTags = () => {
  const location = useLocation();
  const [tags, setTags] = useState([]);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    if (location.state && location.state.collections) {
      setTags(location.state.tags);
      setCollections(location.state.collections);
    }
  }, [setTags]);

  function onChangeCollection(e) {}

  return (
    <div className="p-4">
      <BackButton />

      <div className="grid gap-4 mt-4">
        {tags.map((t, i) => (
          <div key={i} className="grid sm:grid-cols-2 gap-2">
            <div className="font-bold text-sm">{t.level_three}</div>
            <div>
              {/* <Select
                                    defaultValue={{ label: t.custom_level_two || t.level_two, value: t.custom_level_two || t.level_two }}
                                    placeholder="Select Collection"
                                    onChange={(e) => {
                                        if (e) { onChangeCollection(e) }
                                    }}
                                    className="rounded-none outline-none border-none text-sm"
                                    classNamePrefix="Select Collection"
                                    isClearable={true}
                                    isSearchable={true}
                                    options={collections}
                                    getOptionLabel={(option) => option.key}
                                    getOptionValue={(option) => option.key}
                                /> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
