import React, { useState, useEffect } from "react";
import { updateProfile } from "../../../../services/base-api";
import { Success } from "../../../../components/ToastNotification/Success";
import { Failure } from "../../../../components/ToastNotification/Failure";
import _ from "lodash";

function CoachData({ oldProfile, newProfile }) {
  const [newData, setNewData] = useState([]);
  const [oldProfileData, setOldProfileData] = useState(oldProfile);
  const [newProfileData, setNewProfileData] = useState(newProfile);
  function compareTwoArray(array1, array2) {
    let is_same =
      array1.length === array2.length &&
      array1.every(function (element, index) {
        return element === array2[index];
      });
    return is_same;
  }
  useEffect(() => {
    checkObjEqual();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function OnAccept(key) {
    // setShow(true)

    let _oldProfile = { ...oldProfileData };
    _oldProfile[key] = newProfileData[key];
    setOldProfileData(_oldProfile);
  }
  function OnDecline(key) {
    let _oldProfile = { ...oldProfileData };
    let _newProfileData = { ...newProfileData };
    if (_oldProfile[key]) {
      _newProfileData[key] = _oldProfile[key];
    } else {
      delete _newProfileData[key];
    }
    setOldProfileData(_oldProfile);
    setNewProfileData(_newProfileData);
  }

  function onSaveProfile() {
    if (_.isEqual(oldProfileData, newProfileData)) {
      updateProfile(oldProfileData)
        .then((res) => {
          if (res.data.status) {
            Success("Accept Successfully", "Your profile update successfully!");
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Oh no! Something went wrong");
        });
    }
  }
  function checkObjEqual() {
    delete newProfileData._id;
    delete newProfileData.__v;
    delete oldProfileData._id;
    delete oldProfileData.__v;
    let keys = Object.keys(newProfileData);
    let updated_keys = keys.filter((key) => {
      if (typeof oldProfileData[key] == "undefined") {
        return key;
      }
      if (
        typeof oldProfileData[key] != "undefined" &&
        typeof oldProfileData[key] == "string" &&
        newProfileData[key] !== oldProfileData[key]
      ) {
        return key;
      }

      if (
        typeof oldProfileData[key] != "undefined" &&
        typeof oldProfileData[key] == "object" &&
        !compareTwoArray(oldProfileData[key], newProfileData[key])
      ) {
        return key;
      }
      return false
    });
    setNewData(updated_keys);
  }
  return (
    <>
      <div
        className={`hidden sm:grid gap-4 bg-gray-100 rounded-lg px-4 py-2 my-4 font-medium sm:grid-cols-4`}
      >
        <div></div>
        <div>Current Profile</div>
        <div>Update Profile</div>
      </div>
      <div className="flex items-center justify-end px-8 mr-24 text-center">
        <button
          onClick={() => onSaveProfile()}
          type="button"
          className={`inline-flex items-center px-10 py-3 text-base font-medium leading-6 text-white ${
            _.isEqual(oldProfileData, newProfileData)
              ? "bg-skin-fill "
              : "bg-gray-400"
          }  border border-transparent rounded-lg focus:outline-none `}
        >
          Save
        </button>
      </div>
      {/* {console.log("======new Data-----",newData)} */}
      {newData.map((label, i) => (
        <>
          <label key={i} className={`grid gap-4 p-4 sm:grid-cols-4`}>
            <div className="font-medium">{label} </div>
            <div className="gap-4 grid">
              <div className="flex flex-row w-full items-center">
                <div className="ml-4 flex flex-row items-center">
                  <div className="w-10 whitespace-nowrap">
                    {!oldProfileData[label] ? (
                      <div className="font-medium text-center">-</div>
                    ) : (
                      <div className="font-medium">{oldProfileData[label]}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="gap-4 grid">
              <div className="flex flex-row w-full items-center">
                <div className="ml-4 flex flex-row items-center">
                  <div className="w-10 whitespace-nowrap">
                    <div className="font-medium">{newProfileData[label]}</div>
                  </div>
                </div>
              </div>
            </div>
            {oldProfileData[label] !== newProfileData[label] && (
              <div className="grid gap-4 grid-cols-2">
                <div>
                  <button onClick={() => OnAccept(label)} type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-green-600 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </button>
                </div>
                <div>
                  <button onClick={() => OnDecline(label)} type="button">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-red-600 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </label>
        </>
      ))}
    </>
  );
}

export default CoachData;
