import { useState } from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { colorPalette } from "../../../../services/utils/utils";
import UserDrawer from "../../../../components/Profile/UserDrawer/UserDrawer";
import { Drawer } from "knack-ui";

export const MyMenteesAvatar = ({ align, as = "tr", mentees }) => {
  const [selectedMentee, setSelectedMentee] = useState(null);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);

  return (
    <>
      <Drawer onClose={() => setUserDrawerOpen(false)} isOpen={userDrawerOpen}>
        <UserDrawer userId={selectedMentee} />
      </Drawer>

      {as === "tr" && (
        <td className="px-5 py-5 text-sm bg-white">
          <div className="flex items-center -space-x-2">
            {/* if mentees > 4 show the +length-4 remaining more */}
            {mentees?.slice(0, 4).map((n, i) => (
              <div
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  setUserDrawerOpen(true);
                  setSelectedMentee(n?.menteeProfile?.user?._id);
                }}
              >
                <Avatar
                  align={align}
                  name={`${n?.menteeProfile?.first_name} ${n?.menteeProfile?.last_name}`}
                  image={n?.menteeProfile?.profile_image}
                  bgcolor={`${colorPalette[i].solid}`}
                />
              </div>
            ))}
            {mentees.length > 4 && (
              <span
                className="text-gray-500 dark:text-gray-300"
                style={{ marginLeft: 4 }}
              >
                {" "}
                +{mentees.length - 4} more{" "}
              </span>
            )}
          </div>
        </td>
      )}

      {as === "div" && (
        <div className="text-sm bg-white">
          <div className="flex items-center -space-x-2">
            {/* if mentees > 4 show the +length-4 remaining more */}
            {mentees.slice(0, 4).map((n, i) => (
              <div
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  setUserDrawerOpen(true);
                  setSelectedMentee(n?.menteeProfile?.user?._id);
                }}
              >
                <Avatar
                  align={align}
                  name={`${n?.menteeProfile?.first_name} ${n?.menteeProfile?.last_name}`}
                  image={n?.menteeProfile?.profile_image}
                  bgcolor={`${colorPalette[i].solid}`}
                  user={n?.menteeProfile?.user}
                />
              </div>
            ))}
            {mentees.length > 4 && (
              <span
                className="text-gray-500 dark:text-gray-300"
                style={{ marginLeft: 4 }}
              >
                {" "}
                +{mentees.length - 4} more{" "}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
