import { useMemo } from "react";
import Chart from "../../../components/Charts/Chart";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { barChartDataZoom } from "../../../lib/constants";
import { resizeObserver } from "../../../lib/utils";

interface IProps {
  topics: { value: number; name: string }[];
}

export const TopGoalTopics = ({ topics }: IProps) => {
  const options = useMemo(() => {
    if (topics?.length === 0) return null;
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "20px",
        right: "50px",
        bottom: "0%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}"
        }
      },
      // dataZoom: barChartDataZoom(topics.length),
      ...(topics.length > 5 ? { dataZoom: barChartDataZoom(topics.length) } : {}),
      yAxis: {
        boundaryGap: [0, "100%"],
        scale: true,
        type: "category",
        data: topics.map((s) => s.name),
        axisLabel: {
          // margin: 20,
          overflow: "truncate",
          width: 150
        }
      },
      series: [
        {
          type: "bar",
          data: topics.map((s) => s.value),
          barWidth: "20px"
        }
      ]
    };
  }, [topics]);

  return (
    <>
      {!options && <EmptyState iconSize={70} title="No data found" icon="Smile" />}
      {options && <Chart options={options as any} resizeObserver={resizeObserver} />}
    </>
  );
};
