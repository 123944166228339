import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

const getAllQuestions = () => {
  return axios.get(API_URL + "assessments/questions", {
    headers: authHeader()
  });
};

const submitAssessment = (response, type) => {
  return axios
    .post(
      API_URL + "assessments/",
      {
        type: type,
        responses: response
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      if (response.data.status) {
        // localStorage.setItem("assessment", JSON.stringify(response.data));
      }
      // console.log(response.data)
      return response.data;
    });
};

export default {
  getAllQuestions,
  submitAssessment
};
