const AssessmentsBigIcon = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || "94"}
      height={size || "80"}
      viewBox="0 0 94 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7824 70.565C62.7832 70.565 76.5649 56.7832 76.5649 39.7825C76.5649 22.7818 62.7832 9 45.7824 9C28.7817 9 14.9999 22.7818 14.9999 39.7825C14.9999 56.7832 28.7817 70.565 45.7824 70.565Z"
        fill="#01A085"
        fill-opacity="0.04"
      />
      <path
        d="M64.2499 32.1283H47.3749V35.8783H64.2499V32.1283ZM64.2499 47.1283H47.3749V50.8783H64.2499V47.1283ZM33.3874 39.6283L26.7499 32.9908L29.3937 30.3471L33.3687 34.3221L41.3187 26.3721L43.9624 29.0158L33.3874 39.6283ZM33.3874 54.6283L26.7499 47.9908L29.3937 45.3471L33.3687 49.3221L41.3187 41.3721L43.9624 44.0158L33.3874 54.6283Z"
        fill="#01A085"
      />
      <path
        d="M86.1178 47.2041H92.43"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.999939 18.6299H7.3122"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.6252 78.0723H55.2043"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.66174 56.5957H7.30594"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.7588 4.99779L25.7935 1"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.7935 4.99779L21.7588 1"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.5212 69.8806L84.4907 65.8828"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M84.4907 69.8806L80.5212 65.8828"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.3869 10.1093L75.3152 7.16016"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.3152 10.1093L72.3869 7.16016"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5527 76.958L18.2446 78.662"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5527 78.662L18.2446 76.958"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AssessmentsBigIcon;
