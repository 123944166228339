import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { CalendarAvailability, ICalendarAccount, ICalendarFreeBusy } from "./CalendarTypes";

export const calendarQueryKeys = {
  all: [{ scope: "calendarProfile" }] as const,
  calendarProfile: (scheduler_id: string | undefined, userId: string | undefined) =>
    [{ ...calendarQueryKeys.all[0], scheduler_id, userId }] as const,
  freeBusy: (scheduler_id: string | undefined, userId: string | undefined) =>
    [
      {
        ...calendarQueryKeys.all[0],
        scheduler_id,
        userId,
        entity: "freeBusy"
      }
    ] as const,
  availability: (scheduler_id: string | undefined, userId: string | undefined) =>
    [
      {
        ...calendarQueryKeys.all[0],
        scheduler_id,
        userId,
        entity: "availability"
      }
    ] as const,
  schedulerAccountId: (userId?: string | null) => [{ ...calendarQueryKeys.all[0], userId, entity: "sub-account-id" }] as const,
  validateIntegratedConferencing: (userId: string | undefined) => [{ ...calendarQueryKeys.all[0], userId }] as const
};

// get free busy
async function fetchFreeBusy({
  queryKey: [{ scheduler_id, userId }]
}: QueryFunctionContext<ReturnType<typeof calendarQueryKeys["calendarProfile"]>>) {
  if (!scheduler_id || !userId) {
    return null;
  }
  try {
    const res = await apiHttp.get<ServerResponse<ICalendarFreeBusy[]>>(`scheduler/get-free-busy/${scheduler_id}/${userId}`);
    return res.data.data;
  } catch (error) {
    return null;
  }
}

// Get user calendar profile
async function fetchUserCalendarProfile({
  queryKey: [{ scheduler_id, userId }]
}: QueryFunctionContext<ReturnType<typeof calendarQueryKeys["calendarProfile"]>>) {
  if (!scheduler_id || !userId) {
    return null;
  }
  try {
    const res = await apiHttp.get<ServerResponse<ICalendarAccount>>(`scheduler/profiles/account/${scheduler_id}/${userId}`);
    return res.data.data;
  } catch (error) {
    return null;
  }
}

async function validateIntegratedConferencing({
  queryKey: [{ userId }]
}: QueryFunctionContext<ReturnType<typeof calendarQueryKeys["validateIntegratedConferencing"]>>) {
  if (!userId) {
    return null;
  }
  try {
    const res = await apiHttp.get<ServerResponse<Boolean>>(`scheduler/can-use-integrated-conferencing/${userId}`);
    return res.data.data;
  } catch (error) {
    return null;
  }
}

// Get user calendar availability
async function fetchUserCalendarAvailability({
  queryKey: [{ scheduler_id, userId }]
}: QueryFunctionContext<ReturnType<typeof calendarQueryKeys["availability"]>>) {
  if (!scheduler_id || !userId) {
    throw new Error("scheduler_id, userId are required");
  }
  const res = await apiHttp.get<ServerResponse<CalendarAvailability>>(`scheduler/availability-rules/${scheduler_id}/${userId}`);
  return res.data.data;
}
// Get a sub account id
async function fetchSchedulerId({
  queryKey: [{ userId }]
}: QueryFunctionContext<ReturnType<typeof calendarQueryKeys["schedulerAccountId"]>>) {
  if (userId) {
    const res = await apiHttp.get<ServerResponse<string>>("scheduler/get-sub-id/" + userId);
    return res.data.data;
  } else {
    throw new Error("userId is required");
  }
}

export const useGetFreeBusy = <SelectReturnType = ICalendarFreeBusy[], ErrorType = unknown>(
  scheduler_id: string | undefined,
  userId: string | undefined,
  options?: UseQueryOptions<ICalendarFreeBusy[], ErrorType, SelectReturnType, ReturnType<typeof calendarQueryKeys["freeBusy"]>>
) => {
  return useQuery(calendarQueryKeys.freeBusy(scheduler_id, userId), fetchFreeBusy);
};

export const useGetCalendarProfile = <SelectReturnType = ICalendarAccount, ErrorType = unknown>(
  scheduler_id: string | undefined,
  userId: string | undefined,
  options?: UseQueryOptions<ICalendarAccount, ErrorType, SelectReturnType, ReturnType<typeof calendarQueryKeys["calendarProfile"]>>
) => {
  return useQuery(calendarQueryKeys.calendarProfile(scheduler_id, userId), fetchUserCalendarProfile, {
    staleTime: DURATIONS.fifteenMins,
    suspense: true
  });
};

export const useValidateIntegratedConferencing = (userId: string | undefined) => {
  return useQuery(calendarQueryKeys.validateIntegratedConferencing(userId), validateIntegratedConferencing, {
    staleTime: DURATIONS.fifteenMins,
    suspense: true
  });
};

export const useGetCalendarAvailability = <SelectReturnType = CalendarAvailability, ErrorType = unknown>(
  scheduler_id: string | undefined,
  userId: string | undefined,
  options?: UseQueryOptions<CalendarAvailability, ErrorType, SelectReturnType, ReturnType<typeof calendarQueryKeys["availability"]>>
) => {
  // const queryClient = useQueryClient();
  return useQuery<CalendarAvailability, ErrorType, SelectReturnType, ReturnType<typeof calendarQueryKeys["availability"]>>(
    calendarQueryKeys.availability(scheduler_id, userId),
    fetchUserCalendarAvailability,
    {
      staleTime: DURATIONS.thirtySeconds,
      suspense: true,
      enabled: Boolean(scheduler_id),
      useErrorBoundary: false,
      retry: 0,
      ...options
      // initialData: () => {
      //   const availableCalendars = queryClient.getQueryData<ICalendarAccount[]>(
      //     calendarQueryKeys["all"]
      //   );
      //   return availableCalendars?.find((c) => c.sub === scheduler_id);
      // },
      // initialDataUpdatedAt: () => {
      //   const availableCampaigns = queryClient.getQueryState<ICalendarAccount>(
      //     calendarQueryKeys["all"]
      //   );
      //   return availableCampaigns?.dataUpdatedAt;
      // }
    }
  );
};

export const useGetSchedulerId = <SelectReturnType = string, ErrorType = unknown>(
  userId: string,
  options?: UseQueryOptions<string, ErrorType, SelectReturnType, ReturnType<typeof calendarQueryKeys["schedulerAccountId"]>>
) => {
  return useQuery<string, ErrorType, SelectReturnType, ReturnType<typeof calendarQueryKeys["schedulerAccountId"]>>(
    calendarQueryKeys.schedulerAccountId(userId),
    fetchSchedulerId,
    {
      staleTime: DURATIONS.thirtySeconds,
      suspense: true,
      enabled: Boolean(userId),
      ...options
    }
  );
};
