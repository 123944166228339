import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { ProgramState } from "../../../../../../../StateManagement/ProgramCreation";
import { useState } from "react";
import { Failure } from "../../../../../../../components/ToastNotification/Failure";
import { uploadFormData } from "../../../../../../../services/base-api";
import { isValidImage } from "../../../../../../../services/utils/utils";
import { useUserCompany } from "../../../../../../../features/User/userQueries";
import { Typography } from "knack-ui";
import SVG from "../../../../../../../components/SVG/SVG";

export const UploadBanner = ({ reference }) => {
  const { program, setProgram } = ProgramState();
  const { data: company } = useUserCompany();

  const [showImageSpinner, setShowImageSpinner] = useState(false);

  function imageSelected(e) {
    setShowImageSpinner(true);
    const bannerFormData = new FormData();
    bannerFormData.append("file", e.target.files[0]);
    bannerFormData.append("type", "image");

    // upload Banner
    uploadFormData(bannerFormData)
      .then((bannerFileRes) => {
        const _program = { ...program };
        _program.image_url = bannerFileRes.data.url;
        setProgram(_program);
        setShowImageSpinner(false);
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops! Banner upload failed, please try again");
        setShowImageSpinner(false);
      });
  }

  return (
    <section ref={reference}>
      <label className="relative top-0 left-0 z-0 flex flex-row items-center justify-center w-full border-2 border-dashed cursor-pointer h-60 sm:h-80">
        {program.image_url && (
          <img
            alt=""
            src={program.image_url}
            className="object-cover w-full h-full"
            loading="lazy"
            style={{ zIndex: -1 }}
          />
        )}
        <div className="absolute flex flex-col items-center p-2 m-auto">
          <div className="flex flex-row items-center p-2 m-auto bg-white rounded-lg shadow">
            <ClipLoader loading={showImageSpinner} size={50} />
            <SVG name={company?.mediaSupported ? "AddPhoto" : "LockClosed"} />
            <div className="ml-2 text-sm font-semibold text-center text-skin-base">
              {" "}
              Select Image{" "}
            </div>
          </div>
          <p className="mt-4 text-xs text-gray-500">
            Upload an image with max of 20 MB
          </p>
          {!company?.mediaSupported && (
            <Typography textAlign="center" color="warning" variant="subtitle2">
              Image uploading is not permitted, please contact your
              administrator
            </Typography>
          )}
        </div>
        <input
          disabled={!company?.mediaSupported}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            if (!isValidImage(e.target.value)) {
              return false;
            }
            imageSelected(e);
          }}
          style={{ display: "none" }}
        />
      </label>
    </section>
  );
};
