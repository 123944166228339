import { Variants } from "framer-motion";
import { Button, Stack, Typography } from "knack-ui";
import { motion } from "framer-motion";
import SVG, { SVGProps } from "../SVG/SVG";
import { ComponentPropsWithoutRef } from "react";
export interface EmptyStateProps {
  img?: string;
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonCb?: VoidFunction;
  icon?: SVGProps["name"];
  iconSize?: number;
  iconProps?: ComponentPropsWithoutRef<"svg">;
}
const variants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
};
const EmptyState = ({ img, subtitle, title, buttonText, buttonCb, icon, iconSize, iconProps }: EmptyStateProps) => {
  return (
    <Stack
      as={motion.div}
      variants={variants}
      exit="exit"
      initial="hidden"
      animate="visible"
      className="w-full h-full"
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={4}
    >
      {img && <img style={{ maxHeight: 60, height: 60 }} src={img} alt={title} />}
      {icon && <SVG className="text-gray-300" size={iconSize} {...iconProps} name={icon} />}
      <Stack direction="column" gap={2}>
        <Typography textAlign="center" variant="subtitle2" fontWeight="semibold" as="p">
          {title}
        </Typography>
        {subtitle && (
          <Typography textAlign="center" variant="subtitle2" color="muted">
            {subtitle}
          </Typography>
        )}
      </Stack>
      {buttonText && <Button onClick={buttonCb}>{buttonText}</Button>}
    </Stack>
  );
};

export default EmptyState;
