import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ServerResponseV2 } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { ISurvey, ISurveyInvitation, ISurveyResponse } from "./surveyTypes";

// 🏭 Keys factory...
export const surveyQueryKeys = {
  // We are making it an object to make it easy to destructure from the query context **
  all: [{ scope: "surveys" }] as const,
  oneSurvey: ({ id }: { id?: string }) =>
    [{ ...surveyQueryKeys.all[0], entity: "one survey", id }] as const,
  oneSurveyResponse: ({ id }: { id?: string }) =>
    [{ ...surveyQueryKeys.all[0], entity: "one survey response", id }] as const
};
export const surveyInvitationsQueryKeys = {
  // We are making it an object to make it easy to destructure from the query context **
  all: [{ scope: "survey invitations" }] as const,
  oneSurveyInvitation: ({ id }: { id?: string }) =>
    [
      { ...surveyQueryKeys.all[0], entity: "one survey invitation", id }
    ] as const
};

async function fetchSurveys() {
  const res = await apiHttp.get<ServerResponseV2<ISurvey[]>>("v2/survey");
  return res.data.data.reverse();
}
async function fetchOneSurvey({
  queryKey: [{ id }]
}: QueryFunctionContext<ReturnType<typeof surveyQueryKeys["oneSurvey"]>>) {
  if (!id) {
    throw new Error("Invalid survey ID");
  }
  const res = await apiHttp.get<ServerResponseV2<ISurvey>>("v2/survey/" + id);
  return res.data.data;
}

interface IFetchSurveyInvitationsResponse {
  responses: ISurveyResponse[];
  survey: ISurvey;
}

async function fetchSurveyResponse({
  queryKey: [{ id }]
}: QueryFunctionContext<
  ReturnType<typeof surveyQueryKeys["oneSurveyResponse"]>
>) {
  if (!id) {
    throw new Error("Invalid survey ID");
  }
  const res = await apiHttp.get<
    ServerResponseV2<IFetchSurveyInvitationsResponse>
  >("v2/survey/" + id + "/responses");
  return res.data.data;
}

async function fetchSurveyInvitations() {
  const res = await apiHttp.get<ServerResponseV2<ISurveyInvitation[]>>(
    "v2/survey/invites/pending"
  );
  return res.data.data;
}
async function fetchOneSurveyInvitation({
  queryKey: [{ id }]
}: QueryFunctionContext<
  ReturnType<typeof surveyInvitationsQueryKeys["oneSurveyInvitation"]>
>) {
  if (!id) {
    throw new Error("Invalid survey invitation ID");
  }
  const res = await apiHttp.get<ServerResponseV2<ISurveyInvitation>>(
    "v2/survey/invites/" + id
  );
  return res.data.data;
}
export const useGetSurveys = <
  SelectReturnType = ISurvey[],
  ErrorType = unknown
>(
  options?: UseQueryOptions<ISurvey[], ErrorType, SelectReturnType>
) => {
  return useQuery<ISurvey[], ErrorType, SelectReturnType>(
    surveyQueryKeys.all,
    fetchSurveys,
    { ...options, suspense: true, staleTime: DURATIONS.fifteenMins }
  );
};
export const useGetSurvey = <SelectReturnType = ISurvey, ErrorType = unknown>(
  { id }: { id?: string },
  options?: UseQueryOptions<
    ISurvey,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof surveyQueryKeys["oneSurvey"]>
  >
) => {
  return useQuery<
    ISurvey,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof surveyQueryKeys["oneSurvey"]>
  >(surveyQueryKeys.oneSurvey({ id }), fetchOneSurvey, {
    ...options,
    suspense: true,
    staleTime: DURATIONS.fifteenMins
  });
};
export const useGetSurveyResponse = <
  SelectReturnType = IFetchSurveyInvitationsResponse,
  ErrorType = unknown
>(
  { id }: { id?: string },
  options?: UseQueryOptions<
    IFetchSurveyInvitationsResponse,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof surveyQueryKeys["oneSurveyResponse"]>
  >
) => {
  return useQuery<
    IFetchSurveyInvitationsResponse,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof surveyQueryKeys["oneSurveyResponse"]>
  >(surveyQueryKeys.oneSurveyResponse({ id }), fetchSurveyResponse, {
    ...options,
    suspense: true,
    staleTime: DURATIONS.fifteenMins
  });
};
export const useGetSurveyInvitations = <
  SelectReturnType = ISurveyInvitation[],
  ErrorType = unknown
>(
  options?: UseQueryOptions<ISurveyInvitation[], ErrorType, SelectReturnType>
) => {
  return useQuery<ISurveyInvitation[], ErrorType, SelectReturnType>(
    surveyInvitationsQueryKeys.all,
    fetchSurveyInvitations,
    {
      ...options,
      suspense: true,
      staleTime: DURATIONS.fifteenMins
    }
  );
};
export const useGetSurveyInvitation = <
  SelectReturnType = ISurveyInvitation,
  ErrorType = unknown
>(
  { id }: { id?: string },
  options?: UseQueryOptions<
    ISurveyInvitation,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof surveyInvitationsQueryKeys["oneSurveyInvitation"]>
  >
) => {
  return useQuery<
    ISurveyInvitation,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof surveyInvitationsQueryKeys["oneSurveyInvitation"]>
  >(
    surveyInvitationsQueryKeys.oneSurveyInvitation({ id }),
    fetchOneSurveyInvitation,
    {
      ...options,
      cacheTime: 0,
      staleTime: 0,
      suspense: true
    }
  );
};
