import { Button, Stack, Typography } from "knack-ui";
import { Dispatch, SetStateAction, useCallback } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import styled from "styled-components";
import ActivityIndicator from "../../../../../components/ActivityIndicator/ActivityIndicator";
import ControlledChipList from "../../../../../components/ControlledChipList";
import { INewGoalFormProps } from "../../../../../features/Goals/goalsTypes";
import { IProgramsResponse, useGetEnrolledPrograms } from "../../../../../features/Programs/programQueries";
import { capitalizeFirstLetter } from "../../../../../lib/utils";

const NewGoalTopics = ({
  setActiveStep,
  setCompletedSteps,
  admin,
  handleSubmitGoal
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  admin?: boolean;
  setCompletedSteps: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  handleSubmitGoal: SubmitHandler<INewGoalFormProps>;
}) => {
  const { getValues, setValue, watch, handleSubmit } = useFormContext<INewGoalFormProps>();

  const selectedTopics = watch("topics");

  const { data: skills, isLoading } = useGetEnrolledPrograms({
    select: useCallback(
      (res: IProgramsResponse) => {
        const all = [...res.coaching, ...res.mentoring];
        const allSkills = all.flatMap((p) => p.skills).filter((s) => Boolean(s));
        const uniqueSkills = Array.from(new Set(allSkills)).filter((s) => !selectedTopics.includes(s));
        const filteredAllSkills = [...uniqueSkills, ...selectedTopics];
        const filteredSelectedTopics = selectedTopics;
        return {
          allProgramSkills: uniqueSkills,
          filteredAllSkills,
          filteredSelectedTopics
        };
      },
      [selectedTopics]
    )
  });

  const onAddCustomTopic = (value: string) => {
    setValue("topics", [capitalizeFirstLetter(value), ...selectedTopics]);
  };

  function validationFn(customValue: string, items: string[]) {
    const lowerCaseSkills = items.map((s) => s.toLowerCase());

    if (lowerCaseSkills.includes(customValue.toLowerCase())) {
      return false;
    }
    return true;
  }

  const onTopicToggle = (topic: string) => {
    if (selectedTopics.includes(topic)) {
      setValue(
        "topics",
        selectedTopics.filter((t) => t !== topic)
      );
    } else {
      setValue("topics", [...selectedTopics, topic]);
    }
  };

  return (
    <>
      <div className="p-6 dialog-content-max-h scrollhost2">
        <div>
          <Typography className="block " variant="body1" fontWeight="bold">
            What topics or skills do you want to focus on?
          </Typography>
          <Typography className="block mb-4" variant="subtitle2" color="muted">
            Select from the list or add your own.
          </Typography>

          {isLoading && <ActivityIndicator />}
          {!isLoading && skills && (
            <div>
              <TopicsWrapper>
                <ControlledChipList
                  items={skills.allProgramSkills}
                  onItemSelect={onTopicToggle}
                  addItemProps={{
                    buttonText: "Add new skill or topic",
                    inputLabel: "Enter skill/topic",
                    validationFn,
                    onAddItem: onAddCustomTopic
                  }}
                  selectedItems={skills.filteredSelectedTopics}
                  searchBarProps={{
                    placeholder: "Search or add a skill/topic...",
                    searchFn: (item, search) => item.toLowerCase().indexOf(search.toLowerCase()) !== -1
                  }}
                />
              </TopicsWrapper>
            </div>
          )}
        </div>
      </div>

      <Stack className="p-6 border-t" justifyContent="flex-end" gap={2}>
        <Button type="button" onClick={() => setActiveStep((prev) => prev - 1)} kind="tertiary">
          Back
        </Button>
        <Button
          disabled={!selectedTopics.length}
          onClick={
            admin
              ? handleSubmit(handleSubmitGoal)
              : (e) => {
                  if (getValues("topics")) {
                    setCompletedSteps((prev) => ({ ...prev, 1: true }));
                  }

                  setActiveStep((prev) => prev + 1);
                }
          }
        >
          {admin ? "Create goal" : "Next"}
        </Button>
      </Stack>
    </>
  );
};

export default NewGoalTopics;

const TopicsWrapper = styled.div`
  border: 1px solid var(--color-border);
  padding: 16px;
  border-radius: 12px;
  background-color: var(--color-gray-100);
`;
