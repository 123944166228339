import React, { useRef } from "react";
import { StaticDialog } from "react-st-modal";
import Select from "react-select";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import { BaseDialog, Input } from "knack-ui";

export const CreateHR = ({
  newUser,
  setNewUser,
  teams,
  companies,
  setOpen,
  isOpen,
  onCreateUser
}) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const organizationRef = useRef();

  function willCreateUser() {
    if (
      newUser.first_name !== "" &&
      newUser.last_name !== "" &&
      newUser.email !== "" &&
      newUser.password !== "" &&
      newUser.organization !== ""
    ) {
      onCreateUser();
      try {
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        emailRef.current.value = "";
        passwordRef.current.value = "";
        organizationRef.current.select.clearValue();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div id="chr" className="text-skin-base">
      <BaseDialog
        dialogClassName="max-w-xl"
        // className="SessionRatingModal CreateUserModal scrollhost"
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          setNewUser({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            organization: "",
            kind: "HR"
          });
        }}
      >
        <div className="p-4">
          <div className="text-xl font-bold">
            {newUser._id ? "Edit Hr" : "Create Hr"}
          </div>

          <div className="mt-4">
            {/* First Name */}
            <label>
              <div className="mb-4 text-sm">First Name</div>
              <Input
                ref={firstNameRef}
                defaultValue={newUser.first_name}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, first_name: e.target.value }))
                }
                placeholder="i.e. John"
              />
            </label>
            {/* last name */}
            <label className="block mt-4">
              <div className="mb-4 text-sm">Last Name</div>
              <Input
                ref={lastNameRef}
                defaultValue={newUser.last_name}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, last_name: e.target.value }))
                }
                placeholder="i.e. Doe"
              />
            </label>
            {/* Email */}
            <label className="block mt-4">
              <div className="mb-4 text-sm">Email</div>
              <Input
                ref={emailRef}
                disabled={newUser._id ? true : false}
                defaultValue={newUser.email}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, email: e.target.value }))
                }
                type="email"
                placeholder="user@gmail.com"
              />
            </label>

            {/* Password */}
            {!newUser._id && (
              <label className="block mt-4">
                <div className="mb-4 text-sm">Password</div>
                <Input
                  ref={passwordRef}
                  onChange={(e) =>
                    setNewUser((p) => ({ ...p, password: e.target.value }))
                  }
                  type=""
                  placeholder="*******"
                />
              </label>
            )}

            {/* Organisation */}
            {
              <label className="block mt-4">
                <div className="text-sm">Select Organisation</div>
                <div className="mt-1">
                  <KnackSelect
                    menuPortalTarget={document.body}
                    ref={organizationRef}
                    defaultValue={{
                      value: newUser.organization.name,
                      label: newUser.organization.name,
                      _id: newUser.organization._id
                    }}
                    onChange={(e) => {
                      if (e) {
                        setNewUser((p) => ({ ...p, organization: e._id }));
                      }
                    }}
                    className="rounded-lg"
                    classNamePrefix=""
                    isClearable={true}
                    isSearchable={true}
                    options={companies}
                  />
                </div>
              </label>
            }
          </div>

          {/* CTA */}
          <div className="flex items-center justify-end mt-5">
            <div
              className={`font-semibold text-base p-2 cursor-pointer px-4 rounded-lg ${
                newUser.first_name !== "" &&
                newUser.last_name !== "" &&
                newUser.email !== "" &&
                newUser.password !== "" &&
                newUser.organization !== ""
                  ? "bg-skin-fill text-white"
                  : "bg-gray-300"
              }`}
              onClick={() =>
                newUser.first_name !== "" &&
                newUser.last_name !== "" &&
                newUser.email !== "" &&
                newUser.password !== "" &&
                newUser.organization !== ""
                  ? willCreateUser()
                  : null
              }
            >
              {newUser?._id ? "Update" : "Create"}
            </div>
            <div
              className="p-2 px-4 text-base font-semibold cursor-pointer"
              onClick={() => setOpen()}
            >
              Cancel
            </div>
          </div>
        </div>
      </BaseDialog>
    </div>
  );
};
