import { Button, Stack, Typography } from "knack-ui";
import { Link } from "react-router-dom";
import goalSuccess from "../../../../../components/Asset/Images/goalSuccess.png";

interface IGoalCreationSuccessProps {
  goalId: string | null;
  handleClose: () => void;
}

const GoalCreationSuccess = ({ handleClose, goalId }: IGoalCreationSuccessProps) => {
  return (
    <>
      <Stack justifyContent="center" className="p-6 mx-auto dialog-content-max-h scrollhost2" direction="column" gap={6}>
        <img style={{ maxWidth: "200px", height: "150px", margin: "auto" }} src={goalSuccess} alt="Goal created successfully" />

        <div>
          <Typography textAlign="center" variant="h6" as="h6">
            Well Done!
          </Typography>
          <Typography className="mb-2" textAlign="center" variant="h6" as="h6">
            The goal has been created
          </Typography>
          <Typography textAlign="center" variant="subtitle2" color="muted" as="h6">
            Start working on it!
          </Typography>
        </div>
      </Stack>
      <Stack direction="column" className="p-6 border-t" alignItems="center" gap={2}>
        <Button as={Link} to={`/goals/${goalId}`}>
          View Goal
        </Button>
        <Button onClick={handleClose} kind="tertiary">
          Back to goals
        </Button>
      </Stack>
    </>
  );
};

export default GoalCreationSuccess;
