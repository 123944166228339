import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import {
  ISlackBugPayload,
  ISlackField,
  ISlackMessageBlock
} from "./SlackIngestTypes";
import html2canvas from "html2canvas";
import { uploadFileOrImage } from "../User/userMutations";
import { isDev } from "../../lib/utils";

export async function slackSendErrorMessage(fields: ISlackField[]) {
  // upload screenshot
  if (isDev()) return;
  const canvas = await html2canvas(document.body, { scale: 0.7 });

  canvas.toBlob(
    async (blob) => {
      if (!blob) return;
      const uploadScreenshot = await uploadFileOrImage({
        file: blob,
        type: "image"
      });
      // 👋 Attach the error details
      const section: ISlackMessageBlock = {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "🐛👋 *We've caught an Error!*"
        },
        fields
      };

      // Divider for Readability
      const divider: ISlackMessageBlock = {
        type: "divider"
      };
      // 📳 Attach a screenshot for better visual
      const image: ISlackMessageBlock = {
        type: "image",
        title: {
          type: "plain_text",
          text: "Page screenshot",
          emoji: true
        },
        image_url: uploadScreenshot,
        alt_text: "marg"
      };

      const blocks: ISlackMessageBlock[] = [divider, section, image];

      slackSendBug({
        blocks,
        channel: "C02ETS4UD88"
      }).then(() => {
        console.log("Error captured");
      });
    },
    "image/jpg",
    0.5
  );
}

export async function slackSendBug(newBug: ISlackBugPayload) {
  const res = await apiHttp.post<ServerResponse<ISlackBugPayload>>(
    "v1/auth/debugger",
    newBug
  );
  return res.data.data;
}

// Mutations
export const useSendBug = () => {
  return useMutation(slackSendBug, {
    onSuccess: () => {
      console.log("Error captured");
    }
  });
};
