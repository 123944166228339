const ProgramBigIcon = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || "94"}
      height={size || "80"}
      viewBox="0 0 94 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7825 70.565C62.7832 70.565 76.565 56.7832 76.565 39.7825C76.565 22.7818 62.7832 9 45.7825 9C28.7818 9 15 22.7818 15 39.7825C15 56.7832 28.7818 70.565 45.7825 70.565Z"
        fill="#01A085"
        fillOpacity="0.04"
      />
      <path
        d="M56.75 20.75H34.25C32.1875 20.75 30.5 22.4375 30.5 24.5V54.5C30.5 56.5625 32.1875 58.25 34.25 58.25H56.75C58.8125 58.25 60.5 56.5625 60.5 54.5V24.5C60.5 22.4375 58.8125 20.75 56.75 20.75ZM39.875 24.5H43.625V33.875L41.75 32.4688L39.875 33.875V24.5ZM56.75 54.5H34.25V24.5H36.125V41.375L41.75 37.1562L47.375 41.375V24.5H56.75V54.5Z"
        fill="#01A085"
      />
      <path
        d="M86.1178 47.2041H92.4301"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 18.6299H7.31226"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6252 78.0723H55.2044"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.6618 56.5957H7.306"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7589 4.99779L25.7935 1"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7935 4.99779L21.7589 1"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.5212 69.8806L84.4908 65.8828"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.4908 69.8806L80.5212 65.8828"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.3869 10.1093L75.3153 7.16016"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3153 10.1093L72.3869 7.16016"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5527 76.958L18.2447 78.662"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5527 78.662L18.2447 76.958"
        stroke="#01A085"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProgramBigIcon;
