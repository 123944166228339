import { BaseDialog, Button, Stack, Textarea, Typography } from "knack-ui";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useGetCalendarProfile } from "../../features/Calendar/calendarQueries";
import { useDeleteCalendarEvent, useUpdateSession } from "../../features/Sessions/sessionsMutations";
import { ISession } from "../../features/Sessions/SessionsTypes";
import { useUser, useUserRoles } from "../../features/User/userQueries";
import { useLoadingSpinner } from "../../hooks/useLoadingSpinner";
import { SessionCancellationOrDeletionReasons as Reasons } from "../../lib/constants";
import KnackSelect from "../KnackSelect/KnackSelect";
import { Success } from "../ToastNotification/Success";

interface IDeleteSession {
  open: boolean;
  setOpen: (value: boolean) => void;
  session: ISession | null;
  onSuccess?: (callback: any) => void;
  onError?: (error: any) => void;
}

interface IDeletionReasonForm {
  deletion_reason?: string;
  deletion_comment?: string;
}

const DeleteSession = (props: IDeleteSession) => {
  const { roleState } = useUserRoles();
  const { setLoading } = useLoadingSpinner();
  const { mutateAsync: updateSession } = useUpdateSession();
  const { mutateAsync: deleteCalendarEvent } = useDeleteCalendarEvent();
  const { data: expertCalendar } = useGetCalendarProfile(props?.session?.expert?.scheduler_id, props.session?.expert?.user._id);
  const { data: user } = useUser();
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<IDeletionReasonForm>({});

  const deletion_reason = watch("deletion_reason");

  const deleteSession: SubmitHandler<IDeletionReasonForm> = async (data) => {
    try {
      if (deletion_reason) setLoading(true);
      await updateSession({
        sessionId: props.session?._id as string,
        updates: {
          completion_status: "Deleted",
          deletion_reason: data.deletion_reason,
          deletion_comment: data.deletion_comment,
          deleted_by: user?._id,
          deleted_at: new Date()
        }
      });
      props?.setOpen(false);
      Success("Session deleted successfully");
      setLoading(false);
      if (props.session?.completion_status !== "Deleted" && props.session?.calendar_event_id && expertCalendar?.sub) {
        await deleteCalendarEvent({
          event_id: props.session?.calendar_event_id,
          scheduler_id: expertCalendar?.sub,
          organizerUserId: (props.session?.coach || props.session?.mentor) as string
        });
      }
      if (props.onSuccess) {
        props?.onSuccess(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <BaseDialog animationType="fade" dialogClassName="max-w-lg" isOpen={props?.open} onClose={() => props?.setOpen(false)}>
      <form onSubmit={handleSubmit(deleteSession)} dir="ltr" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
        <div className="p-6 border-b">
          <Typography variant="h6" className="font-normal" as="h2">
            Please tell us why you want to delete this session
          </Typography>
        </div>
        <div className="grid grid-cols-1 gap-4 p-6 dialog-content-max-h">
          <Controller
            control={control}
            name="deletion_reason"
            rules={{ required: "Required" }}
            render={({ field: { value, onChange } }) => {
              return (
                <KnackSelect
                  id="deletion_reason"
                  className="inner-inner-box-shadow-none"
                  placeholder="Select a reason"
                  isSearchable={true}
                  options={Reasons(roleState?.activeRole).map((c) => ({
                    value: c.label,
                    label: c.value
                  }))}
                  isClearable
                  value={Reasons(roleState?.activeRole).find((r) => r.value === value)}
                  onChange={(option: any) => {
                    onChange(option?.value);
                  }}
                  closeMenuOnSelect={true}
                />
              );
            }}
          />

          {watch("deletion_reason") === "Other" && (
            <Textarea
              rows={4}
              {...register("deletion_comment", {
                required: watch("deletion_reason") === "Other" && "Required"
              })}
              error={errors.deletion_comment?.message}
              id="deletion_comment"
            />
          )}
        </div>
        <Stack className="p-6 border-t" justifyContent="flex-end" gap={4}>
          <Button kind="ghost" onClick={() => props.setOpen(false)}>
            Dismiss
          </Button>
          <Button isLoading={isSubmitting} disabled={!deletion_reason} type="submit">
            Delete session
          </Button>
        </Stack>
      </form>
    </BaseDialog>
  );
};
export default DeleteSession;
