import React from "react";
import Select from "react-select";
import { ProgramState } from "../../../../../../../StateManagement/ProgramCreation";

export const ProgramType = ({ reference }) => {
  const { program, setProgram } = ProgramState();

  return (
    <section ref={reference} className="relative z-50 mb-6">
      <div className="grid gap-1">
        {/* Program Title */}
        <div className="py-2">
          <div className="mb-4 text-sm font-bold">
            What type of program do you want to create?
          </div>
          <div className="flex items-center justify-center gap-10 my-3">
            <div
              className={`w-1/3 flex items-center border-2 border-gray p-6   ${
                program?.program_type === "Mentoring"
                  ? "bg-skin-fill-accent text-white "
                  : "bg-gray-100"
              } rounded-md`}
            >
              <input
                type="radio"
                name="program_type"
                value="Mentoring"
                checked={program?.program_type === "Mentoring"}
                onChange={(item) => {
                  if (program.status === "Published") return;
                  const _program = { ...program };
                  _program.program_type = item.target.value;
                  _program.coach_type = "";
                  setProgram(_program);
                }}
              />
              <p className="ml-2 text-center">Mentoring Program</p>
            </div>
            <div
              className={`w-1/3 flex items-center border-2 border-gray p-6  ${
                program?.program_type === "Coaching"
                  ? "bg-skin-fill-accent text-white "
                  : "bg-gray-100"
              } rounded-md`}
            >
              <input
                type="radio"
                name="program_type"
                value="Coaching"
                checked={program?.program_type === "Coaching"}
                onChange={(item) => {
                  if (program.status === "Published") return;
                  const _program = { ...program };
                  _program.program_type = item.target.value;
                  setProgram(_program);
                }}
              />
              <p className="ml-2 text-center">Coaching Program</p>
            </div>
          </div>
        </div>
        {program.program_type === "Coaching" && (
          <div className="py-2">
            <div className="mb-4 text-sm font-bold">Select Coaches</div>
            <div className="flex items-center justify-center gap-10 my-3">
              <div
                className={`w-1/3 flex items-center border-2 border-gray p-6  ${
                  program?.coach_type === "knack_coaching"
                    ? "bg-skin-fill-accent text-white"
                    : "bg-gray-100"
                } rounded-md`}
              >
                <input
                  type="radio"
                  name="coach_type"
                  value="knack_coaching"
                  checked={program?.coach_type === "knack_coaching"}
                  onChange={(item) => {
                    if (program.status === "Published") return;
                    const _program = { ...program };
                    _program.coach_type = item.target.value;
                    setProgram(_program);
                  }}
                />
                <p className="ml-2 text-center">Knack Coaching</p>
              </div>

              <div
                className={`w-1/3 flex items-center border-2 border-gray p-6  ${
                  program?.coach_type === "own_coach"
                    ? "bg-skin-fill-accent text-white"
                    : "bg-gray-100"
                } rounded-md`}
              >
                <input
                  type="radio"
                  name="coach_type"
                  value="own_coach"
                  checked={program?.coach_type === "own_coach"}
                  onChange={(item) => {
                    if (program.status === "Published") return;
                    const _program = { ...program };
                    _program.coach_type = item.target.value;
                    _program.plan = "1:1";
                    setProgram(_program);
                  }}
                />
                <p className="ml-2 text-center">I have my own coaches</p>
              </div>
            </div>
          </div>
        )}
        {program.coach_type === "knack_coaching" &&
          program.program_type === "Coaching" && (
            <div className="py-2">
              <div className="mb-4 text-sm font-bold">Select Plan</div>
              <Select
                onChange={(item) => {
                  const _program = { ...program };
                  _program.plan = item.value;
                  setProgram(_program);
                }}
                placeholder="Select Plan"
                defaultValue={
                  program?.plan
                    ? {
                        value: program?.plan,
                        label:
                          program?.plan === "1:1"
                            ? "One-on-one coaching"
                            : "Group coaching"
                      }
                    : null
                }
                className="text-sm border-none rounded-none outline-none"
                isClearable={false}
                isSearchable={false}
                options={[
                  { value: "1:1", label: "One-on-one coaching" },
                  { value: "group", label: "Group coaching" }
                ]}
              />
            </div>
          )}
      </div>
    </section>
  );
};
