import { Box, Breadcrumbs, Button, Stack, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import { MdCheckCircleOutline } from "react-icons/md";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { CONFERENCING_PROFILES } from "../../App.constant";
import { KnackSpinner } from "../../components/Asset/KnackSpinner";
import { Information } from "../../components/ToastNotification/Information";
import { useConnectConferenceService, useRevokeCalendarAccess } from "../../features/Calendar/calendarMutations";
import { calendarQueryKeys, useGetCalendarProfile, useValidateIntegratedConferencing } from "../../features/Calendar/calendarQueries";
import { ICalendarConferencingOptions, ICalendarAccount, IConferencingProfile } from "../../features/Calendar/CalendarTypes";
import CapacitySetting from "../../features/Settings/ui/CapacitySetting";
import CustomConferenceLink from "../../features/Settings/ui/CustomConferenceLink";
import ScheduleBuffer from "../../features/Settings/ui/ScheduleBuffer";
import { userQueryKeys, useUser, useUserRoles } from "../../features/User/userQueries";
import { getCurrentTimezoneDiff } from "../../lib/utils";
import { ConnectedCalendarWrapper } from "../../services/Scheduling/Calendar/ConnectedCalendarWrapper";
import { requestCronofyAuthorizationURL } from "../../services/Scheduling/CronofyService";
import { PopUp } from "../../services/Scheduling/Scheduler/PopUp";

export const Settings = () => {
  const queryClient = useQueryClient();
  const { roleState } = useUserRoles();
  const { data: user } = useUser({
    suspense: false
  });
  const { data: calendarProfile, isError } = useGetCalendarProfile(user?.profile?.scheduler_id, user?._id);
  const { mutateAsync: revokeCalendarAccess } = useRevokeCalendarAccess();
  const { mutateAsync: connectConferenceService } = useConnectConferenceService();
  const [confirmRevoke, setConfirmRevoke] = useState(false);
  const [spinnerOpen2, setSpinnerOpen2] = useState(false);

  const { data: canUseIntegratedConferencing } = useValidateIntegratedConferencing(user?._id);

  async function onRevokeCalendarAccess() {
    setSpinnerOpen2(true);
    try {
      if (user?.profile?.scheduler_id) {
        await revokeCalendarAccess(user?.profile?.scheduler_id as string);
      }
      Information("You have successfully revoked access your calendar.");
      await queryClient.invalidateQueries(calendarQueryKeys.all);
      await queryClient.invalidateQueries(userQueryKeys.all);
      window.location.reload();
      setConfirmRevoke(false);
      setSpinnerOpen2(false);
    } catch (reason) {
      console.log(reason);
      setSpinnerOpen2(false);
    }
  }

  const conferencingProfile = useMemo(() => {
    let profiles: ICalendarConferencingOptions[] = [];
    if (calendarProfile) {
      const conferencingProfiles = (calendarProfile as ICalendarAccount)["cronofy.data"]?.conferencing_profiles;
      const confKeys: IConferencingProfile["provider_name"][] = ["ms_teams", "zoom"];

      profiles = confKeys
        .map((c) => {
          const confOption = (CONFERENCING_PROFILES as any)[c] as ICalendarConferencingOptions;

          confOption.isConnected = conferencingProfiles.find(
            ({ provider_name, profile_connected }) => provider_name === c && profile_connected === true
          )?.profile_connected
            ? true
            : false;

            // can use integrated conferencing for exchange
          if (c === "ms_teams" && canUseIntegratedConferencing) {
            confOption.isConnected = true;
          }

          return confOption;
        })
        .sort((a) => (a.isConnected ? -1 : 1));
    }
    return profiles;
  }, [calendarProfile, canUseIntegratedConferencing]);

  async function onConnectConferenceService(confProfile: ICalendarConferencingOptions) {
    if (!confProfile.isConnected) {
      await connectConferenceService({
        scheduler_id: user?.profile?.scheduler_id as string,
        redirect_uri: window.location.href,
        provider_name: confProfile.value
      });
    }
  }

  return (
    <div className="h-full">
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4">
        {[<Link to="/dashboard">Home</Link>, <Typography clamp={1}>Settings</Typography>]}
      </Box>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {roleState?.activeRole === "Expert" && user?.account_type !== "Coach" && (
          <div className="self-start">
            <CapacitySetting />
          </div>
        )}
        {roleState?.activeRole === "Expert" && (
          <div className="self-start">
            <ScheduleBuffer />
          </div>
        )}
        {roleState?.activeRole === "Expert" && (
          <div className="self-start">
            <CustomConferenceLink />
          </div>
        )}
        <div className="grid self-start gap-4">
          <Box variant="outlined">
            <div className="p-4 border-b">
              <Typography variant="h6">Timezone</Typography>
              <Typography variant="subtitle2" color="muted">
                Your detected timezone.
              </Typography>
            </div>
            <div className="p-4">
              <Typography>Detected timezone: </Typography>
              <Typography fontWeight="bold">
                {Intl.DateTimeFormat().resolvedOptions().timeZone} UTC{getCurrentTimezoneDiff("xx")}
              </Typography>
            </div>
          </Box>
          {roleState?.activeRole === "Expert" && (!calendarProfile || isError) && (
            <div className="grid gap-4 p-4 bg-white border rounded-lg">
              <div>
                <div className="text-xl font-bold">Calendar Authorization</div>
                <div>Connect your calendar to seamlessly create schedules for your sessions.</div>
              </div>
              <button
                onClick={() => requestCronofyAuthorizationURL()}
                className="px-4 py-2 text-base font-medium text-white rounded-lg bg-skin-green"
                style={{ width: "fit-content" }}
              >
                Connect Calendar
              </button>
            </div>
          )}
          {roleState?.activeRole === "Expert" && calendarProfile && (
            <>
              <Box variant="outlined">
                <div className="p-4 border-b">
                  <Typography variant="h6">Calendar account information</Typography>
                </div>
                <div className="p-4">
                  <Stack direction="column" gap={2}>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <Typography variant="subtitle2" color="muted">
                          Email
                        </Typography>
                        <Typography fontWeight="medium">{calendarProfile?.email}</Typography>
                      </div>
                      <div>
                        <Typography variant="subtitle2" color="muted">
                          Name
                        </Typography>
                        <Typography fontWeight="medium">{calendarProfile?.name}</Typography>
                      </div>
                    </div>
                    <div>
                      <Typography variant="subtitle2" color="muted">
                        Scope
                      </Typography>
                      <Typography fontWeight="medium">
                        {calendarProfile?.["cronofy.data"]?.authorization?.scope.toUpperCase().split(" ").join(", ")}
                      </Typography>
                    </div>
                    <div className="border-t">
                      <ConnectedCalendarWrapper />
                    </div>
                  </Stack>
                </div>
              </Box>
              <Box variant="outlined">
                <div className="p-4 border-b">
                  <Typography variant="h6">Conferencing</Typography>
                </div>
                <div className="p-4 border-b">
                  <Stack direction="column" gap={2}>
                    {conferencingProfile?.map((conf) => (
                      <div key={conf.type} className="grid grid-cols-2 gap-4">
                        <Typography fontWeight="bold" className="flex flex-row items-center gap-4">
                          {conf.icon}
                          {conf.type}
                        </Typography>
                        <Button
                          onClick={() => onConnectConferenceService(conf)}
                          variant="small"
                          startIcon={conf.isConnected ? <MdCheckCircleOutline size={20} /> : <></>}
                          className="w-min"
                          kind={conf.isConnected ? "success" : "tertiary"}
                        >
                          {conf?.isConnected ? "Connected" : "Connect"}
                        </Button>
                      </div>
                    ))}
                  </Stack>
                </div>
                <div className="p-4">
                  <Typography variant="body1" fontWeight="bold">
                    Revoke Calendar Authorization
                  </Typography>
                  <Typography variant="subtitle2" color="muted">
                    Revoke access to your calendar profiles and scheduled events
                  </Typography>
                  <Button kind="danger" onClick={() => setConfirmRevoke(true)} className="ml-auto">
                    Revoke access to my calendar
                  </Button>
                </div>
              </Box>
            </>
          )}
        </div>
      </div>

      <PopUp open={confirmRevoke} setOpen={() => setConfirmRevoke(!confirmRevoke)} width="max-w-lg">
        {spinnerOpen2 && <KnackSpinner loadingText="Please Wait" />}
        <div className="grid gap-4 p-6 text-center" style={{ maxWidth: 470 }}>
          <div className="text-2xl font-extrabold tracking-tight">You are about to revoke access to your calendar</div>
          <div className="-mt-2 text-base">If you proceed, you will not be able to Schedule upcoming sessions for your sessions.</div>
          <div className="flex items-center gap-6 mx-auto mt-4">
            <button
              onClick={() => setConfirmRevoke(false)}
              className="px-4 py-2 font-medium text-red-800 rounded-lg bg-skin-lighter"
              style={{ width: "fit-content" }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                onRevokeCalendarAccess();
              }}
              className="px-4 py-2 font-medium text-white rounded-lg bg-skin-accent"
              style={{ width: "fit-content" }}
            >
              Revoke Calendar
            </button>
          </div>
        </div>
      </PopUp>
    </div>
  );
};
