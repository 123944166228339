import React from "react";

/**
 * @param {*} PageTile set a title for the page
 * @returns
 */
export const PageTitle = ({ title, subTitle }) => {
  return (
    <div className="pb-4">
      <div className={`text-base sm:text-2xl font-black text-skin-base`}>
        {title}
      </div>
      <div className={`text-sm text-skin-muted text-gray-600`}>{subTitle}</div>
    </div>
  );
};
