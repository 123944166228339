import React from "react";
import { Link } from "react-router-dom";

export const DataEmpty = ({
  Icon,
  route,
  Text,
  CTA_Text,
  onHandler,
  customElement
}) => {
  return (
    <div>
      <div className="flex flex-col text-center items-center justify-center text-skin-base mt-24 pb-8">
        {Icon ? (
          <div> {Icon} </div>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16 mb-4"
            viewBox="0 0 286.644 298.633"
          >
            <g id="event" transform="translate(0.734 0)">
              <path
                id="Path_617"
                data-name="Path 617"
                d="M285.521,110.631V64.566a32.852,32.852,0,0,0-32.813-32.813h-8.64V26.573a26.712,26.712,0,0,0-53.423,0v5.181h-96.5V26.573a26.712,26.712,0,0,0-53.423,0v5.181H32.076A32.851,32.851,0,0,0-.734,64.566V265.82a32.851,32.851,0,0,0,32.81,32.813H252.708A32.852,32.852,0,0,0,285.52,265.82V115.368a7.4,7.4,0,0,0,0-4.736ZM217.358,14.8a11.784,11.784,0,0,1,11.769,11.774V42.3a11.773,11.773,0,0,1-23.546,0V26.573A11.786,11.786,0,0,1,217.358,14.8ZM55.654,26.573a11.773,11.773,0,0,1,23.546,0V42.3a11.773,11.773,0,0,1-23.546,0ZM32.076,46.692h9.007a26.708,26.708,0,0,0,52.689,0h97.24a26.708,26.708,0,0,0,52.689,0h9.007a17.894,17.894,0,0,1,17.874,17.874v40.961H14.2V64.568A17.9,17.9,0,0,1,32.075,46.692Zm220.632,237H32.076A17.9,17.9,0,0,1,14.2,265.82V120.466H270.582V265.82a17.894,17.894,0,0,1-17.874,17.874Zm0,0"
                fill="#b5b5b5"
              />
              <path
                id="Path_618"
                data-name="Path 618"
                d="M249.017,291.155l-26.855-3.826-11.98-23.8a7.467,7.467,0,0,0-13.341,0l-11.985,23.8L158,291.155a7.467,7.467,0,0,0-4.107,12.794l19.34,18.484-4.556,26.058a7.47,7.47,0,0,0,10.78,7.927l24.051-12.394,24.051,12.394a7.471,7.471,0,0,0,10.778-7.924l-4.559-26.058,19.343-18.484a7.469,7.469,0,0,0-4.109-12.794Zm-28.441,23.23a7.464,7.464,0,0,0-2.2,6.687l2.655,15.173-14.1-7.267a7.471,7.471,0,0,0-6.844,0l-14.1,7.267,2.652-15.173a7.458,7.458,0,0,0-2.2-6.687l-11.105-10.609,15.488-2.206a7.468,7.468,0,0,0,5.618-4.035l7.064-14.033,7.068,14.033a7.468,7.468,0,0,0,5.616,4.035l15.491,2.206Zm0,0"
                transform="translate(-61.117 -104.088)"
                fill="#b5b5b5"
              />
            </g>
          </svg>
        )}

        <div className="font-medium text-lg text-center text-gray-500 max-w-lg">
          {Text}
        </div>
        {/* Custom Element */}
        {customElement}
        {route ? (
          <Link
            to={route}
            className="underline text-skin-inverted text-center text-sm"
            onClick={() => (onHandler ? onHandler() : null)}
          >
            {CTA_Text}
          </Link>
        ) : (
          <div
            className="cursor-pointer underline text-skin-inverted text-center text-sm"
            onClick={() => (onHandler ? onHandler() : null)}
          >
            {CTA_Text}{" "}
          </div>
        )}
      </div>
    </div>
  );
};
