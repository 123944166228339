import { BaseDialog, Stack } from "knack-ui";
import { useState, useCallback, lazy, useEffect } from "react";
import { useGetCalendarProfile, useGetSchedulerId } from "../../features/Calendar/calendarQueries";
import OnboardingDialog from "../../features/Onboarding/ui/OnboardingDialog/OnboardingDialog";
import DashboardProgramInvitations from "../../features/Programs/ui/DashboardProgramInvitations";
import { useRequestDelegatedAccess } from "../../features/Scheduler/schedulerMutation";
import { useUpdateProfile } from "../../features/User/userMutations";
import { useUser } from "../../features/User/userQueries";
import { DURATIONS, PageTitleHeight } from "../../lib/constants";
import { withErrorBoundary } from "../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../lib/HOCs/withSuspense";
import { loadComponent } from "../../lib/utils";
import DashboardSurveyInvitations from "../UserDashboard/DashboardSurveyInvitations";
import DashboardWelcome from "../UserDashboard/DashboardWelcome";
import DashboardProgramsSkeleton from "../UserDashboard/skeletons/DashboardProgramsSkeleton";
import DashboardStatsSkeleton from "../UserDashboard/skeletons/DashboardStatsSkeleton";
import SideExpertDashboard from "./SideExpertDashboard";
import { useGetProgramInvitations } from "../../features/Programs/programQueries";
import { IProgramInvite } from "../../features/Programs/ProgramTypes";
import ReferralSourceDialog from "../../features/Onboarding/ui/ReferralSourceDialog/ReferralSourceDialog";

const LazyDashboardStats = lazy(() => loadComponent(() => import("../UserDashboard/DashboardStats")));

const DashboardStats = withErrorBoundary(
  withSuspense(LazyDashboardStats, {
    fallBackComponent: <DashboardStatsSkeleton />
  })
);
const LazyDashboardPrograms = lazy(() => loadComponent(() => import("../UserDashboard/DashboardPrograms")));

const DashboardPrograms = withErrorBoundary(
  withSuspense(LazyDashboardPrograms, {
    fallBackComponent: <DashboardProgramsSkeleton />
  })
);

const ExpertDashboard = () => {
  const { mutateAsync: requestDelegatedAccess } = useRequestDelegatedAccess();
  const { data: user, refetch } = useUser({
    suspense: false
  });
  const { data: scheduler_id } = useGetSchedulerId(user?._id as string);
  const { data: calendarProfile } = useGetCalendarProfile(scheduler_id, user?._id);
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const [referralSourceOpen, setReferralSourceOpen] = useState(false);
  const [programInvite, setProgramInvite] = useState<IProgramInvite>();
  const { data: invitations } = useGetProgramInvitations(
    { confirmed: true },
    {
      select: useCallback((data: IProgramInvite[]) => {
        const filtered = data.filter(
          (p) =>
            p.sent === true &&
            p.confirmed === true &&
            !p.referralSourceAnswer &&
            (p.type === "coach" || p.type === "mentor") &&
            p.program &&
            p.program.referralSourceOptions?.length > 0
        );
        let sorted = filtered.sort(
          // @ts-ignore
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        return sorted;
      }, []),
      staleTime: DURATIONS.thirtyMins
    }
  );
  const handleToggleReferralSourceDialog = useCallback(() => {
    setReferralSourceOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (invitations && invitations.length > 0) {
      setProgramInvite(invitations[0]);
      setReferralSourceOpen(true);
    }
  }, [invitations]);

  useEffect(() => {
    // ambiguous delegation request
    if (user?.email && !calendarProfile) {
      requestDelegatedAccess({ emails: [user?.email] });
    }

    // Update profile with scheduler id calendar profile exist
    if (user?.email && calendarProfile && scheduler_id && !user?.profile?.scheduler_id) {
      updateProfile({ scheduler_id })
        .then(() => refetch())
        .catch((e) => {
          console.log(e);
        });
    }
  }, [user, scheduler_id, calendarProfile]);

  const [invitesOpen, setInvitesOpen] = useState(false);
  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);

  const handleToggleInvitations = useCallback(() => {
    setInvitesOpen((prev) => !prev);
  }, []);

  const handleToggleOnboardingDialog = useCallback(() => {
    setOnboardingDialogOpen((prev) => !prev);
  }, []);

  return (
    <Stack className="h-full" gap={2}>
      <div className="flex-1 overflow-y-auto overflow-x-hidden pe-2">
        <Stack
          direction="column"
          gap={6}
          style={{
            height: PageTitleHeight
          }}
          className="pt-6"
        >
          <DashboardWelcome />
          <DashboardSurveyInvitations />
          <DashboardProgramInvitations handleToggleInvitations={handleToggleInvitations} invitesOpen={invitesOpen} />

          <DashboardStats enrolledPrograms completedSessions totalConnections />
          <DashboardPrograms />
        </Stack>
      </div>
      <SideExpertDashboard handleToggleInvitations={handleToggleInvitations} handleToggleOnboardingDialog={handleToggleOnboardingDialog} />

      <BaseDialog animationType="fade" dialogClassName="max-w-xl" isOpen={onboardingDialogOpen} onClose={handleToggleOnboardingDialog}>
        <OnboardingDialog onClose={handleToggleOnboardingDialog} />
      </BaseDialog>

      <BaseDialog animationType="fade" dialogClassName="max-w-xl" isOpen={referralSourceOpen} onClose={() => setReferralSourceOpen(true)}>
        <ReferralSourceDialog programInvite={programInvite as IProgramInvite} onClose={handleToggleReferralSourceDialog} />
      </BaseDialog>
    </Stack>
  );
};

export default ExpertDashboard;
