import { Fragment } from "react";
import Select from "react-select";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";

export const MatchmakingSelectionInput = ({
  coaches,
  employeeId,
  onRefetch,
  matches = []
}) => {
  console.log(matches);

  let matchList = matches;
  let match1;
  let match2;
  let match3;

  // { value: c?.profile_data?.first_name + ' ' + c?.profile_data?.last_name, label: c?.profile_data?.first_name + ' ' + c?.profile_data?.last_name, _id: c._id }

  if (matchList.length > 0) {
    match1 = {
      value:
        matchList[0].coach._doc?.first_name +
        " " +
        matchList[0].coach._doc?.last_name,
      label:
        matchList[0].coach._doc?.first_name +
        " " +
        matchList[0].coach._doc?.last_name,
      _id: matchList[0].coach._id
    };

    if (matchList[1]) {
      match2 = {
        value:
          matchList[1].coach._doc?.first_name +
          " " +
          matchList[1].coach._doc?.last_name,
        label:
          matchList[1].coach._doc?.first_name +
          " " +
          matchList[1].coach._doc?.last_name,
        _id: matchList[1].coach._id
      };
    }
    if (matchList[2]) {
      match3 = {
        value:
          matchList[2].coach._doc?.first_name +
          " " +
          matchList[2].coach._doc?.last_name,
        label:
          matchList[2].coach._doc?.first_name +
          " " +
          matchList[2].coach._doc?.last_name,
        _id: matchList[2].coach._id
      };
    }
  }

  function setCoachMatch(coach, employee) {
    if (coach && employee) {
      AdminServices.addCoachMatchToEmployee(coach, employee)
        .then((res) => {
          Success("Coach Match added to Employee");
          if (onRefetch) onRefetch();
        })
        .catch((e) => {
          console.log(e);
          Failure(
            "Employee already matched to the coach, Please select another coach"
          );
        });
    }
  }

  function deleteCoachMatch(coach, employee) {
    if (coach && employee) {
      AdminServices.deleteCoachMatch(coach, employee)
        .then((res) => {
          Success("Coach Match delete");
          if (onRefetch) onRefetch();
        })
        .catch((e) => {
          console.log(e);
          Failure("Oops Something went wrong. Please try again");
        });
    }
  }

  return (
    <Fragment>
      <div className="mr-4 text-sm">
        <Select
          value={match1}
          onChange={(e) => {
            if (e) {
              setCoachMatch(e._id, employeeId);
            } else {
              if (match1) deleteCoachMatch(match1._id, employeeId);
            }
          }}
          className="rounded-lg"
          classNamePrefix=""
          isClearable={true}
          isSearchable={true}
          options={coaches}
        />
      </div>
      <div className="mr-4 text-sm">
        <Select
          value={match2}
          onChange={(e) => {
            if (e) {
              setCoachMatch(e._id, employeeId);
            } else {
              if (match2) deleteCoachMatch(match2._id, employeeId);
            }
          }}
          className="rounded-lg"
          classNamePrefix=""
          isClearable={true}
          isSearchable={true}
          options={coaches}
        />
      </div>
      <div className="text-sm">
        <Select
          value={match3}
          onChange={(e) => {
            if (e) {
              setCoachMatch(e._id, employeeId);
            } else {
              if (match3) deleteCoachMatch(match3._id, employeeId);
            }
          }}
          className="rounded-lg"
          classNamePrefix=""
          isClearable={true}
          isSearchable={true}
          options={coaches}
        />
      </div>
    </Fragment>
  );
};
