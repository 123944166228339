import { QueryFunctionContext, useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { calculateGoalPercentage } from "../../lib/utils";
import { INewGoalFormProps, TGoal, TGoalListItem } from "./goalsTypes";

const queryKeys = {
  all: [{ scope: "goals" }] as const,
  goal: (id: string) => [{ ...queryKeys.all[0], id }] as const
};

async function createGoal(values: INewGoalFormProps) {
  const res = await apiHttp.post<ServerResponse<TGoal>>("goals/", {
    ...values
  });

  return res.data.data;
}
async function editGoal({
  _id,
  ...rest
}: { _id: string } & Partial<
  Omit<TGoal, "tasks" | "challenges" | "_id" | 'share_with'> & {
    tasks?: string[];
    challenges?: string[];
    share_with?: string[];
  }
>) {
  const res = await apiHttp.patch<ServerResponse<TGoal>>("goals/" + _id + "/", rest);

  return res.data.data;
}

export interface GoalResponse {
  defaultGoals: TGoal[];
  goals: TGoal[];
}

async function fetchGoals() {
  const res = await apiHttp.get<ServerResponse<GoalResponse>>("goals/");
  return res.data.data;
}

async function fetchOneGoal({ queryKey: [{ id }] }: QueryFunctionContext<ReturnType<typeof queryKeys["goal"]>>) {
  const res = await apiHttp.get<ServerResponse<TGoal>>("goals/" + id + "/");
  return res.data.data;
}

async function editEmbeddedGoalItem({
  _id: id,
  goalId,
  completed,
  title,
  type
}: { _id: string } & Partial<TGoalListItem> & {
    goalId: string;
    type: "challenges" | "tasks";
  }) {
  const res = await apiHttp.patch<ServerResponse<TGoal>>("goals/" + goalId + "/embedded/" + id + "?type=" + type, { title, completed });
  return res.data.data;
}

async function deleteEmbeddedGoalItem({
  _id: id,
  goalId,
  type
}: { _id: string } & Partial<TGoalListItem> & {
    goalId: string;
    type: "challenges" | "tasks";
  }) {
  const res = await apiHttp.delete<ServerResponse<TGoal>>("goals/" + goalId + "/embedded/" + id + "?type=" + type);
  return res.data.data;
}
async function deleteOneGoal(id: string) {
  const res = await apiHttp.delete<ServerResponse<TGoal>>("goals/" + id + "/");
  return res.data.data;
}

// 🎇 Mutations
export const useCreateGoal = () => {
  const queryClient = useQueryClient();
  return useMutation(createGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.all);
    }
  });
};
export const useEditGoal = () => {
  const queryClient = useQueryClient();
  return useMutation(editGoal, {
    onMutate: async ({ _id, title, description, outcome, topics }) => {
      await queryClient.cancelQueries(queryKeys.goal(_id));
      // Snapshot the previous value
      const previousGoal = queryClient.getQueryData(queryKeys.goal(_id)) as TGoal;
      if (previousGoal) {
        queryClient.setQueryData<TGoal | undefined>(queryKeys.goal(_id), {
          ...previousGoal,
          title: title ?? previousGoal.title,
          topics: topics ?? previousGoal.topics,
          description: description ?? previousGoal.description,
          outcome: outcome ?? previousGoal.outcome
        });
      }
      return previousGoal;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_, { _id }, previousGoal) => {
      queryClient.setQueryData(queryKeys.goal(_id), previousGoal);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKeys["all"]);
    }
  });
};
export const useEditEmbeddedGoalItem = () => {
  const queryClient = useQueryClient();
  return useMutation(editEmbeddedGoalItem, {
    onSuccess: async (newGoal) => {
      const newProgress = calculateGoalPercentage(newGoal);
      if (newProgress === 100) {
        await editGoal({ status: "COMPLETED", _id: newGoal._id });
      } else if (newGoal.status === "COMPLETED") {
        await editGoal({ status: "IN_PROGRESS", _id: newGoal._id });
      }
      queryClient.invalidateQueries(queryKeys["all"]);
    }
  });
};
export const useDeleteGoal = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOneGoal, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys["all"]);
    }
  });
};

export const useDeleteEmbeddedGoalItem = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteEmbeddedGoalItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys["all"]);
    }
  });
};
// 🎇 Queries
export const useGetGoals = <SelectData = GoalResponse, Error = unknown>(options?: UseQueryOptions<GoalResponse, Error, SelectData>) => {
  return useQuery<GoalResponse, Error, SelectData>(queryKeys.all, fetchGoals, {
    staleTime: DURATIONS.fifteenMins,
    ...options
  });
};

export const useGetGoal = (id: string) => {
  const queryClient = useQueryClient();
  return useQuery(queryKeys.goal(id), fetchOneGoal, {
    staleTime: DURATIONS.fifteenMins,
    suspense: true,
    initialData: () => {
      const availableGoals = queryClient.getQueryData<GoalResponse>(queryKeys["all"]);
      return availableGoals?.goals.find((goal) => goal._id === id);
    },
    initialDataUpdatedAt: () => {
      // ✅ will refetch in the background if our list query data is older
      // than the provided staleTime.
      const availableGoals = queryClient.getQueryState<GoalResponse>(queryKeys["all"]);
      return availableGoals?.dataUpdatedAt;
    }
  });
};
