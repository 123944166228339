import { HeadlessModal } from "../../../components/modal/HeadlessModal";

export const PopUp = ({ open, setOpen, children, width }) => {
  return (
    <HeadlessModal
      overflowY=""
      width={width}
      open={open}
      setOpen={(value) => setOpen(false)}
      canDismissFromBackdrop={true}
      showDefaultButton={false}
      defaultButtonText=""
    >
      {children}
    </HeadlessModal>
  );
};
