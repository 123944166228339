import { useGetOrganizations } from "./../../Company/companyQueries";
import { useGetCompanyPositions, useUser } from "./../../User/userQueries";
import { IOrganization, USER_TYPES } from "./../../User/UserTypes";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { DefaultFilter, Filter } from "../../../components/FiltersBar/FiltersBar.types";
import { useUserCompany } from "../../User/userQueries";
import { useGetUsers } from "../analyticsReportQueries";
import { GoalsReportsQuery, IUserAnalytics, TGOAL_STATUS } from "../analyticsReportsTypes";

interface FilterProps {
  query: GoalsReportsQuery;
  onQueryChange: Dispatch<SetStateAction<GoalsReportsQuery>>;
}

export const useGoalsFilters = ({ query, onQueryChange }: FilterProps) => {
  const { data: user } = useUser();
  const { data: userOrganization } = useUserCompany({ enabled: user?.account_type === "Hr" });
  const { data: organizations } = useGetOrganizations({ enabled: user?.account_type === "Admin" });

  const { data: positions } = useGetCompanyPositions();

  const [userSearchValue, setUserSearchValue] = useState<string>("");

  const { data: userData } = useGetUsers(
    {
      userClass: ["user"],
      limit: 1000,
      search_by_email_or_name: userSearchValue
    },
    { keepPreviousData: true }
  );

  const resources = useMemo(() => {
    if (organizations) {
      return {
        departments: organizations.map((o) => o.departments).flat(),
        entities: organizations.map((o) => o.entities).flat(),
        management_levels: organizations.map((o) => o.management_levels).flat()
      };
    }

    return {
      departments: userOrganization?.departments || [],
      entities: userOrganization?.entities || [],
      management_levels: userOrganization?.management_levels || []
    };
  }, [userOrganization, organizations]);

  const filters = useMemo(() => {
    const f: Filter<keyof GoalsReportsQuery, any>[] = [];

    Object.keys(query).forEach((key) => {
      switch (key) {
        case "status":
          const status: Filter<"status", TGOAL_STATUS> = {
            label: "Status",
            name: "status",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["COMPLETED", "IN_PROGRESS"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.status!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.status!, newValue],
                  type: "status"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "status"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.status!.filter((id) => id !== option),
                  type: "status"
                });
              }
            }
          };
          f.push(status);
          break;
        case "department":
          if (!resources) return;
          const department: Filter<"department", string> = {
            label: "Department",
            name: "department",
            type: "default",
            options: {
              renderText: (o) => o,
              data: resources.departments,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.department!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.department!, newValue],
                  type: "department"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "department"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.department!.filter((id) => id !== option),
                  type: "department"
                });
              }
            }
          };
          f.push(department);
          break;
        case "userType":
          const userType: Filter<"userType", USER_TYPES> = {
            label: "User Type",
            name: "userType",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["mentee", "coachee"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.userType!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.userType!, newValue],
                  type: "userType"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "userType"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.userType!.filter((id) => id !== option),
                  type: "userType"
                });
              }
            }
          };
          f.push(userType);
          break;
        case "position":
          if (!resources) return;
          const position: Filter<"position", string> = {
            label: "Position",
            name: "position",
            type: "default",
            options: {
              renderText: (o) => o,
              data: positions || [],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.position!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.position!, newValue],
                  type: "position"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "position"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.position!.filter((id) => id !== option),
                  type: "position"
                });
              }
            }
          };
          f.push(position);
          break;
        case "user":
          if (!userData) break;
          const users: Filter<"user", IUserAnalytics> = {
            label: "User",
            name: "user",
            type: "default",
            options: {
              renderText: (o) => (o.user_first_name ? `${o.user_first_name} ${o.user_last_name}` : o.user_email),
              data: userData.pages.flatMap((p) => p.users),
              onSearchInputChange: (keyword) => setUserSearchValue(keyword),
              isOptionSelected: (o) => query.user!.includes(o.user_id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.user!, newValue.user_id],
                  type: "user"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "user"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.user!.filter((id) => id !== option.user_id),
                  type: "user"
                });
              }
            }
          };
          f.push(users);
          break;
        case "organization":
          if (!organizations) break;
          const organization: Filter<"organization", IOrganization> = {
            label: "Organizations",
            name: "organization",
            type: "default",
            options: {
              renderText: (o) => o.name,
              data: organizations,
              searchCriteria: (option, keyword) => {
                return option.name?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.organization!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.organization!, newValue._id],
                  type: "organization"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "organization"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.organization!.filter((id) => id !== option._id),
                  type: "organization"
                });
              }
            }
          };
          f.push(organization);
          break;
      }
    });

    return f;
  }, [organizations, positions, query, resources, userData]);

  const onOptionsChange = <T extends keyof GoalsReportsQuery>({ newValue, type }: { type: T; newValue: GoalsReportsQuery[T] }) => {
    onQueryChange((state) => ({ ...state, [type]: newValue }));
  };
  return filters;
};
