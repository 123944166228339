import { ConnectCalendar } from "../../components/IntegrationAlert/ConnectCalendar";
import { useUser } from "../../features/User/userQueries";
import { FreeBusyWrapper } from "./Availability/FreeBusyWrapper";

export const Calendar = () => {
  const { data: profile } = useUser({
    select: (user) => user.profile,
    suspense: false
  });
  return (
    <>
      {(!profile || !profile?.scheduler_id) && <ConnectCalendar />}
      {profile && profile.scheduler_id && (
        <div className="z-0 grid gap-4">
          <div className="">
            <FreeBusyWrapper />
          </div>
        </div>
      )}
    </>
  );
};
