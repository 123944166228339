import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "knack-ui";

export const ConfirmationModal = ({
  children,
  open,
  setOpen,
  header,
  title,
  message,
  canDismissFromBackdrop = true,
  width = "w-full max-w-md",
  defaultButtonText = "Okay",
  defaultButtonCancelText = "Cancel",
  buttonFunction,
  buttonStyle = "inline-flex justify-center px-5 py-2 justify-self-auto text-sm font-bold text-white bg-skin-accent rounded-lg",
  disabled = false
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        style={{ zIndex: 9999999999999 }}
        as="div"
        auto-reopen="true"
        className="fixed z-50 inset-0 overflow-y-auto"
        // initialFocus={cancelButtonRef}
        onClose={() => (canDismissFromBackdrop ? setOpen(false) : null)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block ${width} pt-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
            >
              <div className="text-xl px-4 py-2 font-bold">{title}</div>
              <div className="text-base text-gray-500 border-b px-4 pb-4">{header}</div>
              <div className={message ? "px-4 mt-2" : ""}>{message}</div>

              <>{children}</>

              <div className="p-4 flex justify-end gap-4">
                <Button kind="ghost" onClick={() => setOpen(false)}>
                  {defaultButtonCancelText}
                </Button>
                <Button disabled={disabled} onClick={() => (buttonFunction ? buttonFunction() : setOpen(false))}>
                  {defaultButtonText}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
