import { useState } from "react";

export default function Pagination({ setPage, page, totalPages }, props) {
  const [data, setData] = useState([]);

  const onPrevClick = () => {
    let count = page;
    if (count > 1) {
      setPage(count - 1);
    }
  };

  const onNextClick = () => {
    let count = page;
    if (count < totalPages) {
      setPage(count + 1);
    }
  };

  return (
    <div className="w-full mx-auto flex flex-row text-center items-center justify-center pb-8">
      Page: {page} of {totalPages}
      <button
        disabled={page <= 1 ? true : false}
        className="border p-2 px-3 m-2 rounded-lg disabled:opacity-5"
        onClick={onPrevClick}
      >
        Previous
      </button>
      <button
        className="border p-2 px-3 m-2 rounded-lg disabled:opacity-5"
        onClick={onNextClick}
        disabled={page >= totalPages ? true : false}
      >
        Next
      </button>
    </div>
  );
}
