interface IProps {
  vertical?: boolean;
  className?: string;
}
const Divider = ({ vertical, className }: IProps) => {
  return (
    <span
      className={`${vertical ? "border-r  h-8" : "border-t"} ${className}`}
    ></span>
  );
};

export default Divider;
