import { BaseDialog, Button, Stack, Textarea, Typography } from "knack-ui";
import moment from "moment";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useGetCalendarProfile } from "../../features/Calendar/calendarQueries";
import {
  useDeleteCalendarEvent,
  useFetchCalendarEvents,
  useUpdateCalendarEvent,
  useUpdateSession
} from "../../features/Sessions/sessionsMutations";
import { ISession } from "../../features/Sessions/SessionsTypes";
import { useUser, useUserRoles } from "../../features/User/userQueries";
import { useLoadingSpinner } from "../../hooks/useLoadingSpinner";
import { SessionCancellationOrDeletionReasons as Reasons } from "../../lib/constants";
import KnackSelect from "../KnackSelect/KnackSelect";
import { Success } from "../ToastNotification/Success";

interface ICancelSession {
  open: boolean;
  setOpen: (value: boolean) => void;
  session: ISession | null;
  onSuccess?: (callback: any) => void;
  onError?: (error: any) => void;
}

interface ICancellationReasonForm {
  cancellation_reason?: string;
  cancellation_comment?: string;
}

const CancelSession = (props: ICancelSession) => {
  const { roleState } = useUserRoles();
  const { data: user } = useUser();
  const { setLoading } = useLoadingSpinner();
  const { mutateAsync: updateSession } = useUpdateSession();
  const { mutateAsync: fetchCalendarEvents } = useFetchCalendarEvents();
  const { mutateAsync: deleteCalendarEvent } = useDeleteCalendarEvent();
  const { mutateAsync: updateCalendarEvent } = useUpdateCalendarEvent();
  const { data: expertCalendar } = useGetCalendarProfile(props?.session?.expert?.scheduler_id, props.session?.expert?.user._id);

  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<ICancellationReasonForm>({});

  const cancellation_reason = watch("cancellation_reason");

  const cancelSession: SubmitHandler<ICancellationReasonForm> = async (data) => {
    try {
      if (cancellation_reason) setLoading(true);
      await updateSession({
        sessionId: props.session?._id as string,
        updates: {
          completion_status: "Cancelled",
          cancelled_by: user?._id,
          cancelled_at: new Date(),
          cancellation_reason: data.cancellation_reason,
          cancellation_comment: data.cancellation_comment
        }
      });
      props?.setOpen(false);
      Success("Session cancelled successfully");
      setLoading(false);
      mutateCalendarEvent();
      if (props.onSuccess) {
        props?.onSuccess(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  async function mutateCalendarEvent() {
    if (expertCalendar) {
      const isPast = moment(props?.session?.start_time).isBefore(moment());
      const diff = moment(props?.session?.start_time).diff(moment(), "days");
      const isDateBeyondMinimum = Math.abs(diff) > 30 && isPast;
      if (isDateBeyondMinimum) {
        return;
      }
      const filter = `?only_managed=true&tzid=${expertCalendar?.zoneinfo || "Asia/Dubai"}&from=${moment(props?.session?.start_time)
        .subtract(30, "minutes")
        .toISOString()}`;
      try {
        const events = await fetchCalendarEvents({
          filter,
          scheduler_id: expertCalendar?.sub as string,
          organizerUserId: (props?.session?.coach || props?.session?.mentor) as string
        });

        const foundEvent = events?.filter((e) => e.event_id === props?.session?.calendar_event_id)[0];

        if (foundEvent && props?.session?.calendar_event_location_key) {
          if (roleState?.activeRole === "Expert") {
            deleteCalendarEvent({
              event_id: foundEvent.event_id,
              scheduler_id: expertCalendar?.sub as string,
              organizerUserId: (props?.session?.coach || props?.session?.mentor) as string
            })
              .then(() => {})
              .catch(() => {});
          } else {
            if (props?.session?.team) {
              updateCalendarEvent({
                event: {
                  end: foundEvent.end,
                  start: foundEvent.start,
                  summary: foundEvent.summary,
                  event_id: foundEvent.event_id,
                  attendees: {
                    remove: [
                      {
                        email: user?.email as string
                      }
                    ]
                  }
                },
                scheduler_id: expertCalendar?.sub as string,
                organizerUserId: (props?.session?.coach || props?.session?.mentor) as string
              })
                .then((e) => {
                  console.log(e);
                })
                .catch(() => {});
            } else {
              deleteCalendarEvent({
                event_id: foundEvent.event_id,
                scheduler_id: expertCalendar?.sub as string,
                organizerUserId: (props?.session?.coach || props?.session?.mentor) as string
              })
                .then(() => {})
                .catch(() => {});
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <BaseDialog animationType="fade" dialogClassName="max-w-lg" isOpen={props?.open} onClose={() => props?.setOpen(false)}>
      <form onSubmit={handleSubmit(cancelSession)} dir="ltr" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
        <div className="p-6 border-b">
          <Typography variant="h6" className="font-normal" as="h2">
            Please tell us why you want to cancel this session
          </Typography>
        </div>
        <div className="grid grid-cols-1 gap-4 p-6 dialog-content-max-h">
          <Controller
            control={control}
            name="cancellation_reason"
            rules={{ required: "Required" }}
            render={({ field: { value, onChange } }) => {
              return (
                <KnackSelect
                  id="cancellation_reason"
                  className="inner-inner-box-shadow-none"
                  placeholder="Select a reason"
                  isSearchable={true}
                  options={Reasons(roleState?.activeRole).map((c) => ({
                    value: c.label,
                    label: c.value
                  }))}
                  isClearable
                  value={Reasons(roleState?.activeRole).find((r) => r.value === value)}
                  onChange={(option: any) => {
                    onChange(option?.value);
                  }}
                  closeMenuOnSelect={true}
                />
              );
            }}
          />

          {watch("cancellation_reason") === "Other" && (
            <Textarea
              rows={4}
              {...register("cancellation_comment", {
                required: watch("cancellation_reason") === "Other" && "Required"
              })}
              error={errors.cancellation_comment?.message}
              id="cancellation_comment"
            />
          )}
        </div>
        <Stack className="p-6 border-t" justifyContent="flex-end" gap={4}>
          <Button kind="ghost" onClick={() => props.setOpen(false)}>
            Dismiss
          </Button>
          <Button isLoading={isSubmitting} disabled={!cancellation_reason} type="submit">
            Cancel session
          </Button>
        </Stack>
      </form>
    </BaseDialog>
  );
};
export default CancelSession;
