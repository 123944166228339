import { format } from "date-fns";
import { Avatar, Button, Chip, Typography } from "knack-ui";
import { Dispatch, SetStateAction } from "react";
import DatePicker from "react-datepicker";
import Divider from "../../../../../components/Divider/Divider";
import SVG from "../../../../../components/SVG/SVG";
import { IProgram } from "../../../../Programs/ProgramTypes";

interface IProps {
  program: IProgram;
  setStep: Dispatch<SetStateAction<number>>;
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  topics: string[];
  setTopics: Dispatch<SetStateAction<string[]>>;
}

const DateSelection = ({ program, setStep, date, setDate, setTopics, topics }: IProps) => {
  const onTopicClick = (t: string) => {
    if (topics.includes(t)) {
      return setTopics((prev) => prev.filter((top) => top !== t));
    }
    setTopics((prev) => [...prev, t]);
  };
  return (
    <>
      <Typography className="mb-4" fontWeight="bold">
        Select the discussed topics and the timing of the session
      </Typography>
      <div className="p-6 border rounded-lg bg-gray-50">
        <div className="flex gap-4">
          <Avatar className="flex-shrink-0" square image={program.image_url} text={program.name} />

          <div className="flex-1">
            <Typography color="muted" variant="caption">
              Program
            </Typography>

            <Typography clamp={1} variant="subtitle2" fontWeight="semibold">
              {program.name}
            </Typography>
          </div>
          <Button onClick={() => setStep(0)} kind="ghost" variant="xsmall" startIcon={<SVG size={10} name="Edit" />}>
            Change
          </Button>
        </div>
        <Divider className="block my-6" />
        <div className="flex gap-4">
          <Avatar className="flex-shrink-0" square image={program.expert?.profile_image} text={program.expert?.first_name as string} />

          <div>
            <Typography color="muted" variant="caption">
              Expert
            </Typography>

            <Typography clamp={1} variant="subtitle2" fontWeight="semibold">
              {`${program.expert?.first_name} ${program.expert?.last_name}`}
            </Typography>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Typography className="mb-4" variant="body1" fontWeight="bold">
          Select topics
        </Typography>

        <div className="flex items-center gap-4">
          {program.skills.map((t) => (
            <Chip
              style={{ cursor: "pointer" }}
              onClick={() => onTopicClick(t)}
              shape="rounded"
              variant={topics.includes(t) ? "primary" : "primaryOutline"}
              key={t}
            >
              {t}
            </Chip>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <Typography className="mb-4" variant="body1" fontWeight="bold">
          Select date and time
        </Typography>
        <DatePicker
          selected={date}
          showTimeSelect
          onChange={(date) => setDate(date)}
          customInput={
            <div className="p-4 border rounded-lg cursor-pointer bg-gray-50">
              {date ? format(date, "MMM dd yyyy - hh:mm") : "Click to select the Date and time of the session"}
            </div>
          }
        />
      </div>
    </>
  );
};

export default DateSelection;
