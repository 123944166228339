export const individualRatingQuestions = [
  "I felt heard, understood and respected",
  "The session was a valuable use of my time",
  "I gained useful insight or takeaway",
  "I will put what I have discovered into practice"
];

export const teamRatingQuestions = [
  "I felt empowered to participate during the session",
  "The session was well organized and facilitated",
  "I gained useful insight or takeaway",
  "I will put what I have discovered into practice"
];
