import { Typography } from "knack-ui";
import { toast } from "react-toastify";
import SVG from "../SVG/SVG";

export const Information = (title, subtitle) => {
  toast.info(
    <div>
      <div>
        <Typography variant="subtitle2" fontWeight="medium">
          {title || "Info"}
        </Typography>
        {subtitle && (
          <Typography variant="caption" color="muted">
            {subtitle}
          </Typography>
        )}
      </div>
    </div>,
    {
      autoClose: true,
      icon: () => <SVG name="ExclamationFull" />
    }
  );
};
