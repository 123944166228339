import React, { useMemo } from "react";
import { forwardRef } from "react";
import Select, { StylesConfig, Props, GroupBase } from "react-select";
import Color from "color";

function KnackSelect<O, IsMulti extends boolean = false, Group extends GroupBase<O> = GroupBase<O>>(
  { styles, ...props }: Props<O, IsMulti, Group>,
  ref: React.ForwardedRef<any>
) {
  const customStyles: StylesConfig<O, IsMulti, Group> = useMemo(() => {
    return {
      option: (provided) => ({
        ...provided,
        fontSize: "0.9rem"
      }),

      control: (provided) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        fontSize: "0.9rem",
        borderRadius: "0.5rem"
      }),

      noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: "0.9rem"
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: "6px"
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 140000
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 14000
      }),
      input: (provided) => {
        return {
          ...provided,
          ":focus": {
            boxShadow: "none !important"
          }
        };
      },

      ...styles
    };
  }, [styles]);
  const PrimaryHex = window.getComputedStyle(document.documentElement).getPropertyValue("--primary-color-hex");

  return (
    <Select
      styles={customStyles}
      isSearchable={false}
      menuPortalTarget={document.body}
      {...props}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            ...(PrimaryHex
              ? {
                  primary: PrimaryHex.toString(),
                  primary25: Color(PrimaryHex.toString()).alpha(0.25).hexa(),
                  primary50: Color(PrimaryHex.toString()).alpha(0.5).hexa(),
                  primary75: Color(PrimaryHex.toString()).alpha(0.75).hexa()
                }
              : {})
          }
        };
      }}
      components={{ IndicatorSeparator: () => null }}
      ref={ref}
    />
  );
}

export default forwardRef(KnackSelect);
