import { useEffect, useState } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";

export const CoachingRating = () => {
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    getCoachesRating();
  }, [setRatings]);

  function getCoachesRating() {
    AdminServices.getCoachesRating()
      .then((res) => {
        if (res.data && res.data.status === true) {
          setRatings(res.data.data?.sort((a, b) => b.degree - a.degree));
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  return (
    <div className="grid gap-4 bg-white">
      <div className="grid grid-cols-3 gap-4 p-4 text-base font-bold bg-white bg-white shadow rounded-t-lg">
        <div>Coach</div>
        <div>Email</div>
        <div>Rating</div>
      </div>

      <div className="evenOddBorder rounded-b-lg">
        {ratings?.map((r, i) => (
          <div
            key={i}
            className="grid gap-4 grid-cols-3 p-4 text-base bg-white bg-white"
          >
            <div>
              {r?.firstName} {r?.lastName}
            </div>
            <div>{r?.email}</div>
            <div>{r?.degree}%</div>
          </div>
        ))}
      </div>
    </div>
  );
};
