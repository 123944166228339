import format from "date-fns/format";
import { Avatar, Stack, Typography } from "knack-ui";
import { ConfirmationModal } from "../../../components/modal/ConfirmationModal";
import { Success } from "../../../components/ToastNotification/Success";
import { hoursBetween } from "../../../lib/constants";
import { IPaymentStatusUpdate, useUpdatePaymentStatus } from "../paymentMutations";
import { IPaymentAnalytics } from "../paymentTypes";
import { SessionIndicator } from "./Indicator";

interface IProps {
  rowSelected: IPaymentAnalytics[];
  open: boolean;
  setOpen: (value: any) => void;
}

export const ConfirmPaymentStatusUpdate = ({ rowSelected, open, setOpen }: IProps) => {
  const { mutateAsync: updatePaymentStatus } = useUpdatePaymentStatus();

  async function onUpdatePaymentStatus() {
    try {
      const earningsRefs: IPaymentStatusUpdate[] = rowSelected.map((r) => ({
        reference_number: r.payment_reference_number,
        payment_status: r.payment_status === "Paid" ? "Unpaid" : "Paid"
      }));

      await updatePaymentStatus(earningsRefs);
      Success("Payment update successfully");
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ConfirmationModal
      title={"Update payment status"}
      header={
        <Stack direction="column" gap={4}>
          <Typography fontWeight="semibold">You have selected the following sessions</Typography>
          <div className="grid grid-cols-6">
            <Typography fontWeight="semibold">Coach</Typography>
            <Typography fontWeight="semibold">Client</Typography>
            <Typography fontWeight="semibold">Session Date</Typography>
            <Typography fontWeight="semibold">Session Status</Typography>
            <Typography fontWeight="semibold">Payment Status</Typography>
          </div>
          <div className="grid gap-4 h-full max-h-96 scrollhost2 overflow-y-auto">
            {rowSelected.map((r, i) => (
              <div className="grid grid-cols-6 gap-4" key={i}>
                <Typography fontWeight="semibold">
                  <div className="flex items-center gap-2 font-bold">
                    <Avatar size="small" className="flex-shrink-0" text={r.expert_first_name} image={r.expert_profile_image} />
                    {r?.expert_first_name} {r?.expert_last_name}
                  </div>
                </Typography>
                <Typography fontWeight="semibold">
                  <div className="flex items-center gap-2 font-bold">
                    <Avatar size="small" className="flex-shrink-0" text={r.user_first_name} image={r.user_profile_image} />
                    {r?.user_first_name} {r?.user_last_name}
                  </div>
                </Typography>
                <Typography fontWeight="semibold"> {format(new Date(r.start_time), "MMM dd-hh:mm a")}</Typography>
                <Typography fontWeight="semibold">
                  <div className="flex">
                    {["Cancelled", "Deleted"].includes(r.completion_status) && (
                      <Stack direction="column" gap={4}>
                        {r.completion_status === "Cancelled" && (
                          <Stack direction="column" gap={4}>
                            <Stack direction="row" gap={1}>
                              <SessionIndicator label="Cancelled" status="danger" />
                              {r.cancelled_at && <SessionIndicator label={hoursBetween(r, "cancelled_at")} status="danger" />}
                            </Stack>
                            {r?.cancelled_by_first_name && (
                              <div className="flex items-center gap-2">
                                <Avatar
                                  size="small"
                                  className="flex-shrink-0"
                                  text={r.cancelled_by_first_name}
                                  image={r.cancelled_by_profile_image}
                                />
                                {r.cancelled_by_first_name} {r.cancelled_by_last_name}
                              </div>
                            )}
                            {r?.cancellation_reason && <Typography variant="body2">{r.cancellation_reason}</Typography>}
                          </Stack>
                        )}
                        {r.completion_status === "Deleted" && (
                          <Stack direction="column" gap={4}>
                            <Stack direction="row" gap={1}>
                              <SessionIndicator label="Deleted" status="danger" />
                              {r.deleted_at && <SessionIndicator label={hoursBetween(r, "deleted_at")} status="danger" />}
                            </Stack>
                            {r?.deleted_by_first_name && (
                              <div className="flex items-center gap-2">
                                <Avatar
                                  size="small"
                                  className="flex-shrink-0"
                                  text={r.deleted_by_first_name || ""}
                                  image={r.deleted_by_profile_image}
                                />
                                {r.deleted_by_first_name} {r.deleted_by_last_name}
                              </div>
                            )}
                            {r?.deletion_reason && <Typography variant="body2">{r.deletion_reason}</Typography>}
                          </Stack>
                        )}
                      </Stack>
                    )}
                    {["No Show"].includes(r.completion_status) && <SessionIndicator status={"danger"} label={r.completion_status} />}
                    {["Pending"].includes(r.completion_status) && <SessionIndicator status={"warning"} label={r.completion_status} />}
                    {["Completed"].includes(r.completion_status) && <SessionIndicator status={"success"} label={r.completion_status} />}
                  </div>
                </Typography>
                <Typography fontWeight="semibold">
                  <SessionIndicator
                    label={r.payment_status === "Paid" ? "Paid" : "Unpaid"}
                    status={r.payment_status === "Paid" ? "success" : "danger"}
                  />
                </Typography>

                {/* <Button
                startIcon={<CgRemove />}
                kind="ghostOutline"
                onClick={() => r.toggleSelected()}
              >
                Remove
              </Button> */}
              </div>
            ))}
          </div>
        </Stack>
      }
      buttonFunction={() => onUpdatePaymentStatus()}
      open={open}
      setOpen={setOpen}
      defaultButtonText={rowSelected.length > 1 ? "Update all" : "Update"}
      width=""
      children={undefined}
      message={undefined}
      disabled={rowSelected.length === 0}
    />
  );
};
