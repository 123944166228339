import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Feedback from "./pages/feedback/feedback";
import { logoutAfter24Hours } from "./services/user.service";
import { CronofyAggregator } from "./Aggregator/CronofyAggregator";
import { ConferencingAuthorization } from "./Aggregator/ConferencingAuthorization";
import { CronofyConnectCalendar } from "./ProgramOnboarding/CronofyConnectCalendar";
import { Integrations } from "./Integrations";
import { Discriminator } from "./RoleManagement/Discriminator";
import PageSkeleton from "./components/skeletons/PageSkeleton";
import useAxiosConfig from "./hooks/useAxiosConfig";
import { withSuspense } from "./lib/HOCs/withSuspense";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { withErrorBoundary } from "./lib/HOCs/withErrorBoundary";
import { RequestEnterpriseAuthorization } from "./services/Scheduling/EnterpriseConnect/RequestEnterpriseAuthorization";
import GlobalStyles from "./styles/globalStyles";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";

import "./App.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "knack-ui/dist/esm/knack-ui.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.minimal.css";
import "react-tabs/style/react-tabs.css";
import "@reach/accordion/styles.css";
import SVG from "./components/SVG/SVG";
import AuthRoutes from "./routes/AuthRoutes";
import { useUpdateAccessToken } from "./features/Auth/authMutations";

function App() {
  Integrations();

  useEffect(() => {
    // @ts-ignore
    window.Intercom("boot", {
      app_id: "re0bm69r",
      created_at: Date.now(),
      alignment: "right"
    });

    // Check if 24hours has passed then logout the user
    logoutAfter24Hours();

    // mount toastController outside root
    // ReactDOM.render(, document.getElementById("toastPortal"));

    /// Set Company skin
  }, []);

  useAxiosConfig();

  const { search, state } = useLocation<{ redirect?: string }>();
  const ssoRedirectAccessToken = new URLSearchParams(search).get("access_code");

  const { mutateAsync: updateAccessToken } = useUpdateAccessToken();

  useEffect(() => {
    if (ssoRedirectAccessToken) {
      updateAccessToken(ssoRedirectAccessToken);
    }
  }, [ssoRedirectAccessToken, updateAccessToken]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Switch>
        <Route path="/auth" component={AuthRoutes} />
        <Route exact path="/360-feedback/:token" component={Feedback} />
        <Route exact path="/request-enterprise-auth" component={RequestEnterpriseAuthorization} />

        <ProtectedRoute exact component={CronofyAggregator} path="/cronofy-aggregator" />
        <ProtectedRoute exact component={ConferencingAuthorization} path="/conferencing/authorization" />
        <ProtectedRoute exact component={CronofyConnectCalendar} path="/cronofy-connect-calendar" />

        {/* Fallback for old routes */}
        <Redirect from="/forgot-password" to="/auth/forgot-password" />
        <Redirect from="/reset-password" to="/auth/reset-password" />
        <Redirect from="/reset-password/:id" to="/auth/reset-password/:id" />
        <Redirect from="/login" to="/auth/login" />
        {/* End of fallback */}
        <ProtectedRoute component={Discriminator} path="/" />
      </Switch>
      <StyledContainer hideProgressBar pauseOnHover closeButton={<SVG name="Close" className="flex-shrink-0" size={18} />} />
    </ThemeProvider>
  );
}

export default withErrorBoundary(
  withSuspense(Sentry.withProfiler(App), {
    fallBackComponent: <PageSkeleton height="100vh" />
  }),
  { errorComponentProps: { className: "h-screen" } }
);

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast-body {
    padding: 16px;
    align-items: flex-start;
  }
  .Toastify__progress-bar {
  }
  & .Toastify__toast--success {
    color: var(--color-success);
    background-color: hsla(135deg, 50%, 95%, 1);
  }
  & .Toastify__toast--error {
    color: hsla(4deg, 99%, 66%, 1);
    background-color: hsla(0deg, 88%, 95%, 1);
  }
  & .Toastify__toast--info {
    color: hsla(230deg, 80%, 51%, 1);
    background-color: hsla(220deg, 55%, 95%, 1);
  }
`;
