import { Avatar, Box, Breadcrumbs, Button, Chip, Input, Popover, Stack, Typography } from "knack-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EmptyState from "../../../components/EmptyState/EmptyState";
import FiltersBar from "../../../components/FiltersBar";
import Table from "../../../components/Table";
import { useGetSessionProgress, useGetUserDistribution, useGetUsers, useGetTopicInterests } from "../analyticsReportQueries";
import { IUserAnalytics, UserReportsQuery, IUserAnalyticsMetrics } from "../analyticsReportsTypes";
import { AiOutlineSearch } from "react-icons/ai";
import SVG from "../../../components/SVG/SVG";
import { useUserFilters } from "../hooks/UserReports.hooks";
import MetricCard from "../../../components/Metrics/MetricCard";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import { PaginationState, RowSelectionState } from "@tanstack/react-table";
import { SessionProgress } from "../components/SessionProgress";
import { Distribution } from "../components/Distribution";
import { TopicInterests } from "../components/TopicInterests";
import { DURATIONS } from "../../../lib/constants";
import { useGenerateExportAssessmentReport, useGenerateUserCSVReport } from "../analyticsReportMutations";
import { useExportChart } from "../chartExportMutations";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { Information } from "../../../components/ToastNotification/Information";
import AttributeSelector from "../../../components/Selector/AttributeSelector";
import { useUser } from "../../User/userQueries";
import { TChartType } from "../../../lib/interfaces/generalTypes";

const UserReportsPage = () => {
  const { data: user } = useUser();
  const popoverRefEl = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { setLoading } = useLoadingSpinner();

  const [searchValue, setSearchValue] = useState<string>("");

  const [query, setQuery] = useState<UserReportsQuery>({
    ...(user?.account_type === "Admin" ? { organization: [] } : []),
    program: [],
    user: [],
    expert: [],
    userClass: [],
    department: [],
    entities: [],
    management_level: [],
    position: [],
    userStatus: []
    // userType: ["coach", "coachee", "mentee", "mentor"]
  });

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const { data, hasNextPage, fetchNextPage, fetchPreviousPage, isFetchingNextPage } = useGetUsers(
    {
      ...query,
      search_by_email_or_name: searchValue
    },
    { suspense: true, keepPreviousData: true, staleTime: DURATIONS.twoMins }
  );

  const metrics = useMemo(() => {
    const _metrics: IUserAnalyticsMetrics = {
      registeredExperts: 0,
      registeredUsers: 0,
      unRegisteredExperts: 0,
      unRegisteredUsers: 0
    };

    if (data) {
      const metrics_response = data.pages[0].metrics;
      _metrics.registeredExperts = metrics_response.registeredExperts;
      _metrics.registeredUsers = metrics_response.registeredUsers;
      _metrics.unRegisteredExperts = metrics_response.unRegisteredExperts;
      _metrics.unRegisteredUsers = metrics_response.unRegisteredUsers;
    }

    return _metrics;
  }, [data]);

  const filters = useUserFilters({
    query,
    onQueryChange: setQuery
  });

  const { data: userDistributions } = useGetUserDistribution(query, {});

  const { data: topicInterests } = useGetTopicInterests(query, {});

  const { data: sessionProgress } = useGetSessionProgress(query, {});

  /** Reset page index to 0 whenever we change the query to support fresh queries */
  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
  }, [filters, pageSize]);

  useEffect(() => {
    if (data && !data.pages[pageIndex] && !isFetchingNextPage) {
      setPagination({ pageIndex: 0, pageSize });
    }
  }, [isFetchingNextPage, pageIndex, pageSize, data]);

  const [exportAttributes, setExportAttribute] = useState<Array<keyof IUserAnalytics>>();
  const { mutateAsync: generateReport } = useGenerateUserCSVReport();
  const { mutateAsync: exportAssessmentReports } = useGenerateExportAssessmentReport();
  const { mutateAsync: onExportChart } = useExportChart();

  const exportChart = async (chartType: TChartType) => {
    await onExportChart(chartType);
    Information("An email with chart export will be sent to your email");
  };

  async function exportAssessment() {
    setLoading(true);
    try {
      await exportAssessmentReports(query);
      setLoading(false);
      Information("An email with assessment export will be sent to your email");
    } catch (error) {
      setLoading(false);
    }
  }

  async function onExportCSV(exportAttributes: Array<keyof IUserAnalytics>) {
    setLoading(true);
    try {
      await generateReport({ exportAttributes, query });
      setMenuOpen(false);
      setLoading(false);
      Information("A CSV report has been sent to your email");
    } catch (error) {
      setLoading(false);
    }
  }

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  function clearAllSelection() {
    setRowSelection({});
  }

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Users report</Typography>]}</Breadcrumbs>
      </Box>

      <Box variant="outlined" className="grid grid-cols-1 gap-6 px-6 py-4">
        <div className="pb-6 border-b">
          <Typography fontWeight="semibold" className="mb-4">
            Filters
          </Typography>
          <FiltersBar query={query} filters={filters} />
        </div>
        <div>
          <Typography className="mb-4" fontWeight="semibold">
            Summary
          </Typography>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <MetricCard icon="Users" title="Registered Experts" value={metrics.registeredExperts} />
            <MetricCard icon="Users" title="Registered Users" value={metrics.registeredUsers} />
            {/* {isDev() && ( */}
            <>
              <MetricCard icon="Users" title="Unregistered Experts" value={metrics.unRegisteredExperts} />
              <MetricCard icon="Users" title="Unregistered Users" value={metrics.unRegisteredUsers} />
            </>
            {/* )} */}
          </div>
        </div>

        <Stack direction="column" gap={4}>
          <Typography fontWeight="semibold">Analysis</Typography>
          <div className="relative grid grid-cols-1 gap-6 md:grid-cols-2">
            <TitledDisplayBox
              height={400}
              title="Distribution by entity"
              subtitle={`Registered users`}
              onExport={() => exportChart("DISTRIBUTION_BY_ENTITY")}
            >
              <Distribution type="entityDistribution" userDistributions={userDistributions} />
            </TitledDisplayBox>
            <TitledDisplayBox
              height={400}
              title="Distribution by department"
              subtitle={`Registered users`}
              onExport={() => exportChart("DISTRIBUTION_BY_DEPARTMENT")}
            >
              <Distribution type="departmentDistribution" userDistributions={userDistributions} />
            </TitledDisplayBox>
            <TitledDisplayBox
              height={400}
              title="Distribution by management level"
              subtitle={`Registered users`}
              onExport={() => exportChart("DISTRIBUTION_BY_MANAGEMENT_LEVEL")}
            >
              <Distribution type="managementLevelDistribution" userDistributions={userDistributions} />
            </TitledDisplayBox>
            <TitledDisplayBox height={400} title="Topic interests" onExport={() => exportChart("TOPIC_INTERESTS")}>
              <TopicInterests
                topicInterests={topicInterests}
                expertIsEnabled={!Boolean(query.user?.length)}
                userIsEnabled={!Boolean(query.expert?.length)}
              />
            </TitledDisplayBox>
            <TitledDisplayBox
              height={400}
              title="Active experts by session"
              subtitle={`Number of sessions completed`}
              onExport={() => exportChart("ACTIVE_EXPERTS_BY_SESSION")}
            >
              <SessionProgress type="activeExpertsBySession" sessionProgress={sessionProgress} />
            </TitledDisplayBox>
            <TitledDisplayBox
              height={400}
              title="Active users by session"
              subtitle={`Number of sessions completed`}
              onExport={() => exportChart("ACTIVE_USERS_BY_SESSION")}
            >
              <SessionProgress type="activeUsersBySession" sessionProgress={sessionProgress} />
            </TitledDisplayBox>
          </div>

          <Stack justifyContent="space-between" alignItems="center">
            <Typography fontWeight="semibold">Users</Typography>
            <Stack gap={4} direction="row" alignItems="center">
              <Input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="w-72"
                inputPrefix={<AiOutlineSearch size={20} />}
                inputSuffix={
                  searchValue !== "" ? (
                    <Button onClick={() => setSearchValue("")} iconOnly kind="ghost" variant="small">
                      <SVG name="Close" />
                    </Button>
                  ) : (
                    <></>
                  )
                }
                placeholder="Search by name or email"
              />
              {Object.keys(rowSelection)?.length > 0 && (
                <Stack gap={4} direction="row" alignItems="center">
                  <Stack alignItems="center" gap={2}>
                    <Chip size="small" className="w-8 h-8 rounded-full">
                      {Object.keys(rowSelection)?.length}
                    </Chip>
                    <Typography fontWeight="semibold">Selected</Typography>
                    <Button kind="ghost" onClick={() => clearAllSelection()}>
                      Clear all
                    </Button>
                  </Stack>
                </Stack>
              )}
              <Button ref={popoverRefEl} onClick={() => setMenuOpen(true)}>
                Export
              </Button>

              <Button kind="tertiary" ref={popoverRefEl} onClick={() => exportAssessment()}>
                Export Assessment
              </Button>
            </Stack>
          </Stack>

          {data?.pages[pageIndex]?.users?.length === 0 && <EmptyState iconSize={70} title="No users found" icon="SessionsEmptyState" />}
          {data && data?.pages[pageIndex]?.users?.length > 0 && (
            <Table
              // setRowSelection={setRowSelection}
              // rowSelection={rowSelection}
              pagination={{ pageIndex, pageSize }}
              setPagination={setPagination}
              paginationProps={{
                totalPages: data.pages[pageIndex].totalPages,
                canGetNextPage: hasNextPage,
                onNextPage: async (newPage) => {
                  if (!data?.pages[newPage - 1]) {
                    fetchNextPage();
                  }
                },
                onPreviousPage: async (newPage) => {
                  if (!data?.pages[newPage - 1]) {
                    fetchPreviousPage();
                  }
                }
              }}
              data={data.pages.flatMap((p) => p.users)}
              columns={(helper) => [
                // helper.display({
                //   header: ({ table }) => {
                //     return (
                //       <Checkbox
                //         {...{
                //           ref: headerCheckboxRef,
                //           checked: table.getIsAllPageRowsSelected(),
                //           indeterminate: table.getIsSomeRowsSelected().toString(),
                //           onChange: table.getToggleAllPageRowsSelectedHandler()
                //         }}
                //       />
                //     );
                //   },
                //   cell: ({ row }) => (
                //     <Checkbox
                //       {...{
                //         checked: row.getIsSelected(),
                //         indeterminate: row.getIsSomeSelected().toString(),
                //         onChange: row.getToggleSelectedHandler()
                //       }}
                //     />
                //   ),
                //   id: "selection",
                //   size: 20
                // }),
                helper.accessor((row) => `${row.user_first_name || row.user_email || ""} ${row.user_last_name || ""}`, {
                  header: "User",
                  sortingFn: "text",
                  cell: ({ row, getValue }) => {
                    const name = getValue();
                    return (
                      <div className="flex items-center gap-2">
                        <Avatar className="flex-shrink-0" text={name} image={row.original?.user_profile_image || ""} />
                        {name}
                      </div>
                    );
                  }
                }),
                helper.accessor("user_department", {
                  header: "Department"
                }),
                helper.accessor("user_position", {
                  header: "Position"
                }),
                helper.accessor("user_entity", {
                  header: "Entity"
                }),
                helper.accessor("user_management_level", {
                  header: "Management Level"
                }),
                helper.accessor("session_completed", {
                  header: "Completed sessions"
                })
              ]}
            />
          )}
        </Stack>
      </Box>

      <Popover
        animationType="fade"
        parentRef={popoverRefEl}
        offset={{ bottom: 10, left: 0 }}
        popoverProps={{
          // style: { maxWidth: 500 },
          variant: "elevated",
          elevation: 6
        }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <AttributeSelector<IUserAnalytics>
          attributeList={[
            {
              accessorKey: "user_email",
              label: "Email"
            },
            {
              accessorKey: "user_first_name",
              label: "First name"
            },
            {
              accessorKey: "user_last_name",
              label: "Last Name"
            },
            {
              accessorKey: "user_department",
              label: "Department"
            },
            {
              accessorKey: "user_entity",
              label: "Entity"
            },
            {
              accessorKey: "user_management_level",
              label: "Management Level"
            },
            {
              accessorKey: "user_position",
              label: "Position"
            },
            {
              accessorKey: "session_completed",
              label: "Sessions completed"
            },
            {
              accessorKey: "user_roles",
              label: "Roles"
            }
          ]}
          onChange={(attributes) => {
            setExportAttribute(attributes);
          }}
          onExportCSV={onExportCSV}
        />
      </Popover>
    </div>
  );
};

export default UserReportsPage;
