import { useEffect, useState } from "react";
import { HRCoachServices } from "../../../../services/dashboard/hrCoachDashboard.services";
import { CardPaper } from "../../../../components/Cards/CardPaper";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import SessionsTimelineChart from "../../../../features/Sessions/ui/SessionsTimelineChart";

export const SessionCompletedChart = ({ Program }) => {
  const [coachingTimelineMonthly, setCoachingTimelineMonthly] = useState([]);
  const [coachingTimelineWeekly, setCoachingTimelyWeekly] = useState({
    series: [],
    categories: []
  });
  const [selectedTimeline, setSelectedTimeline] = useState(0);

  function getCoachingTimelineMonthly() {
    let query = `month&type=mentoring&program=${Program._id}`;
    if (Program.program_type === "Coaching")
      query = `month&type=coaching&program=${Program._id}`;
    HRCoachServices.getSessionTimeline(query)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          if (data && data.length > 0) {
            return setCoachingTimelineMonthly(data);
            const series = data.map((d) => d.doc_count);
            const categories = data.map((d) =>
              new Date(d.key_as_string).toLocaleString("en-US", {
                month: "short",
                year: "numeric"
              })
            );
            setCoachingTimelineMonthly({ series, categories });
          }
        }
      })
      .catch((e) => console.log(e));
  }

  function getCoachingTimelineWeekly() {
    let query = `week&type=mentoring&program=${Program._id}`;
    if (Program.program_type === "Coaching")
      query = `week&type=coaching&program=${Program._id}`;
    if (Program.program_type === "External Coaching") query = `week`;
    HRCoachServices.getSessionTimeline(query)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          if (data && data.length > 0) {
            const series = data.map((d) => d.doc_count);
            const categories = data.map((d) => {
              const parsedDate = new Date(d.key_as_string);
              const weekYear = new Date(d.key_as_string).toLocaleString(
                "en-US",
                { year: "numeric" }
              );
              const weekStart = new Date(d.key_as_string).toLocaleString(
                "en-US",
                { day: "numeric" }
              );
              const next6Days = parsedDate.setDate(parsedDate.getDate() + 6);
              const weekEnd = new Date(next6Days).toLocaleString("en-US", {
                day: "numeric"
              }); // + 6days
              return `${weekStart} - ${weekEnd}, ${weekYear}`;
            });
            setCoachingTimelyWeekly({ series, categories });
          }
        }
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getCoachingTimelineMonthly();
    getCoachingTimelineWeekly();
  }, []);

  return (
    <>
      <CardPaper
        subTitle=" Number of coaching sessions completed by time"
        title={` ${Program?.program_type} Sessions Timeline`}
        variant="outlined"
        className="p-4 overflow-hidden"
      >
        <div style={{ minHeight: 300, height: 300 }}>
          {coachingTimelineMonthly && coachingTimelineMonthly.length === 0 && (
            <EmptyState title="No recent sessions found" />
          )}

          {coachingTimelineMonthly && coachingTimelineMonthly?.length === 0 && (
            <EmptyState
              icon="SessionsEmptyState"
              iconSize={80}
              title="No data available"
            />
          )}
          {coachingTimelineMonthly && coachingTimelineMonthly?.length > 0 && (
            <SessionsTimelineChart timelines={coachingTimelineMonthly} />
          )}
        </div>
      </CardPaper>
    </>
  );
};
