import { Box, Button, Input, Stack, Typography } from "knack-ui";
import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import logo from "../../../components/Asset/logo.svg";
import SVG from "../../../components/SVG/SVG";
import { useValidateOtp } from "../../../features/Auth/authQueries";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { userQueryKeys } from "../../../features/User/userQueries";
import { InitCompanySkin } from "../../../StateManagement/ThemeConfigurations";
import { LoginPagesTransition, LoginStore } from "./LoginForm";
import { motion } from "framer-motion";
interface IOTPSectionProps {
  setShowOtp: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: { password: string; email: string }) => void;
}

const OTPSection = ({ setShowOtp, onSubmit }: IOTPSectionProps) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);


  const [errorMessage, setErrorMessage] = useState("");

  const { email: emailSent, password } = LoginStore();
  const { setValue } = useLocalStorage();

  const [otpExpired, setOTPExpired] = useState(false);
  const otpContainerRef = useRef<HTMLDivElement | null>(null);

  const [resendDisabled, setResendDisabled] = useState(true);

  const { minutes, seconds, restart } = useTimer({
    expiryTimestamp: time,
    autoStart: true,
    onExpire: () => setResendDisabled(false)
  });

  const { restart: restartCodeTimer } = useTimer({
    expiryTimestamp: time,
    autoStart: true,
    onExpire: () => setOTPExpired(true)
  });

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useValidateOtp();

  const history = useHistory();
  const location = useLocation<{ redirect?: string }>();

  const [otp, setOTP] = useState<{ [key: string]: string }>({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: ""
  });
  const otpSet = useMemo(() => {
    if (otp[0] && otp[1] && otp[2] && otp[3] && otp[4] && otp[5] && otp[6] && otp[7]) {
      return true;
    }
    return false;
  }, [otp]);
  const focusOtpField = (index: number) => {
    if (otpContainerRef.current) {
      const childElement = otpContainerRef.current.childNodes[index]?.childNodes[0] as HTMLElement;
      childElement?.focus();
    }
  };

  const onOTPChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let { value } = e.target;
    const reg = /^[0-9]+$/;
    if (!value.match(reg)) return;
    if (value.length > 1) {
      value = value.slice(1);
    }
    setOTP((prev) => ({ ...prev, [index]: value }));
    if (value !== "") {
      focusOtpField(index + 1);
    }
  };

  const onBackPress = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      focusOtpField(index - 1);
      setOTP((prev) => ({ ...prev, [index]: "" }));
    }
  };
  const onOTPPaste: React.ClipboardEventHandler = (e) => {
    e.preventDefault();
    const copiedText = e.clipboardData.getData("text").trim();
    const reg = /^[0-9]+$/;
    if (!copiedText.match(reg)) return;
    const splittedCopiedData = copiedText.split("").slice(0, 8);
    const newOtp: { [index: number]: string } = {};
    splittedCopiedData.forEach((c, i) => {
      newOtp[i] = c;
    });
    setOTP((prev) => ({ ...prev, ...newOtp }));
  };

  const restartTimers = () => {
    restart(time);
    restartCodeTimer(time);
  };

  useEffect(() => {
    return () => {
      restartTimers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitOtp = async () => {
    setErrorMessage("");
    setOTPExpired(false);
    try {
      const code = [...Object.values(otp)].join("");
      const user = await mutateAsync({ otp: code, email: emailSent });
      if (user.authToken) {
        setValue("showOnboardingDialog", true);
        localStorage.setItem("user", JSON.stringify({ data: user }));
        if (user.profile) {
          localStorage.setItem("profile", JSON.stringify({ data: user.profile }));
        }

        // save timestamp in ls
        //@ts-ignore
        localStorage.setItem("[LAST_LOGIN_ATTEMPT]", new Date());
        // Admin & Subadmin do not have a profile, that's why we are forcing the creation of the profile object here.
        await queryClient.invalidateQueries(userQueryKeys.user());
        if (["Admin", "Subadmin"].includes(user.account_type)) {
          queryClient.setQueryData(userQueryKeys.user(), user, {
            updatedAt: Date.now()
          });
        } else {
          queryClient.invalidateQueries(userQueryKeys.all);
        }
        // incase it didn't run after useUser.onSuccess()
        InitCompanySkin(); /// Set Company skin

        const retrievedRoute = location.state?.redirect || "/dashboard";
        setTimeout(() => {
          if (retrievedRoute) {
            history.push({ pathname: retrievedRoute });
          } else {
            history.push({ pathname: "/dashboard" });
          }
          // window.location.reload();
        }, 500);
      }
    } catch (error: any) {
      if (error.message === "Invalid or expired OTP") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <Box
      className="container max-w-lg rounded-2xl"
      elevation={2}
      as={motion.form}
      variants={LoginPagesTransition}
      exit="exit"
      initial="hidden"
      key="otp"
      animate="visible"
      onSubmit={(e: any) => {
        e.preventDefault();
        onSubmitOtp();
      }}
      paddingPreset="card"
    >
      <div className="mb-4">
        <img src={logo} alt="Knack logo" />
      </div>
      <Typography className="mb-2" variant="h5" as="h2">
        Two factor verification
      </Typography>
      <Typography variant="body1" color="muted">
        A verification code has been sent to {emailSent}. This code is only valid for 5 minutes.
      </Typography>

      <div className="my-8">
        <Typography fontWeight="semibold" className="mb-4" variant="h6">
          Verification code
        </Typography>
        <Stack gap={2} ref={otpContainerRef}>
          {Object.keys(otp).map((c, i) => (
            <Input
              onClick={(e) => e.currentTarget.select()}
              variant="filled"
              key={c}
              max={9}
              onPaste={onOTPPaste}
              min={0}
              // size="small"
              onChange={(e) => onOTPChange(e, i)}
              onKeyDown={(e) => onBackPress(e, i)}
              value={otp[i]}
              inputClassName="min-w-0  whitespace-nowrap font-bold text-center"
              className="flex-1 p-0 bg-gray-100 whitespace-nowrap"
            />
          ))}
        </Stack>
        {errorMessage && (
          <Typography as={Stack} alignItems="center" gap={2} variant="body2" className="mt-4" color="warning">
            <SVG name="Danger" />
            <span>{errorMessage}</span>
          </Typography>
        )}
        {otpExpired && (
          <Typography as={Stack} alignItems="center" gap={2} variant="body2" className="mt-4" color="warning">
            <SVG name="Danger" />
            <span>Your OTP is expired.</span>
          </Typography>
        )}
      </div>
      <Button isLoading={isLoading} disabled={!otpSet} variant="large" fullWidth type="submit">
        Verify
      </Button>
      <Stack alignItems="center" justifyContent="center" direction="column" gap={2} className="mt-4">
        <Typography variant="subtitle2" color="primary" fontWeight="bold">
          Resend in {minutes} : {seconds.toString().padEnd(2, "0")}
        </Typography>
        <Button
          onClick={() => {
            onSubmit({ email: emailSent, password: password as string });
            restartTimers();
            setErrorMessage("");
            setOTPExpired(false);
          }}
          fullWidth
          disabled={resendDisabled}
          kind="tertiary"
        >
          Resend OTP again
        </Button>
        <Button
          onClick={() => {
            setShowOtp(false);
          }}
          fullWidth
          kind="tertiary"
        >
          Back
        </Button>
      </Stack>
    </Box>
  );
};

export default OTPSection;
