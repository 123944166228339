import { Button } from "knack-ui";
import { useMemo } from "react";
import { IProgram } from "../../../../../features/Programs/ProgramTypes";
import {
  computeConstrainSessionTypes,
  SessionTypes
} from "../../../../../lib/constants";

interface ISelectSessionTypeProps {
  type: keyof typeof SessionTypes;
  onSelect: (type: keyof typeof SessionTypes) => void;
  onSelectTopics: (value: string[]) => void;
  program: IProgram;
  setError: (value: string | null) => void;
}

export const SessionSelect = ({
  type,
  onSelect,
  program,
  setError,
  onSelectTopics
}: ISelectSessionTypeProps) => {
  const constrainedSessionTypes = useMemo(() => {
    if (program) {
      const list = computeConstrainSessionTypes(program).filter(
        (s) => s.name !== "Chemistry Call"
      );
      return list;
    }

    return [];
  }, [program]);

  return (
    <div className="grid gap-4">
      <div className="">Session Type</div>
      <div className="flex flex-wrap gap-2 ">
        {constrainedSessionTypes?.map((s, i) => (
          <Button
            key={i}
            kind={type === s.name ? "primary" : "defaultOutline"}
            onClick={() => {
              setError(null);
              onSelect(s.name as keyof typeof SessionTypes);
              if (s.name === "Chemistry Call") {
                onSelectTopics([]);
              }
            }}
          >
            {SessionTypes[s.name as keyof typeof SessionTypes]}
          </Button>
        ))}
      </div>
    </div>
  );
};
