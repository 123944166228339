import { useUser } from "./../User/userQueries";
import { useCallback } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { useGetProgramInvitations } from "../Programs/programQueries";
import { IProgramInvite } from "../Programs/ProgramTypes";
import { useGetSurveyInvitations } from "../Surveys/surveyQueries";
import {
  ConnectionRequestNotification,
  INotification,
  NotificationsSecretSauce,
  ProgramInvitationNotification,
  SurveyNotification
} from "./notificationsTypes";

export const notificationKeys = {
  all: [{ scope: "notifications" }] as const
};

async function fetchAllNotifications() {
  const res = await apiHttp.get<ServerResponse<INotification[]>>(
    "v2/notification"
  );
  return res.data.data.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
}

export const useGetNotifications = <Error = unknown>({
  filterBy,
  max = undefined,
  options
}: {
  filterBy: "all" | "unread";
  max?: number;
  options?: UseQueryOptions<
    INotification[],
    Error,
    { data: INotification[]; unSeenCount: number; allCount: number }
  >;
}) => {
  const { data } = useUser();
  // Temporary solution to hide notifications that are resolved
  const { data: invitations } = useGetProgramInvitations(
    { confirmed: false },
    {
      select: useCallback((data: IProgramInvite[]) => {
        return data.filter((p) => p.sent === true && p.program);
      }, []),
      refetchOnWindowFocus: true,
      enabled: Boolean(data?.account_type !== "Admin")
    }
  );
  // Temporary solution to hide notifications that are resolved
  const { data: surveyInvitations } = useGetSurveyInvitations({
    useErrorBoundary: false
  });
  return useQuery<
    INotification[],
    Error,
    { data: INotification[]; unSeenCount: number; allCount: number }
  >(notificationKeys.all, fetchAllNotifications, {
    staleTime: DURATIONS.oneMinute,
    refetchInterval: DURATIONS.thirtySeconds,
    select: useCallback(
      (data: INotification[]) => {
        // Filtering out values with proper context and not program registration
        const filtered = data.filter((i) => {
          if (
            i._ktid_nt === NotificationsSecretSauce["programInvite"] &&
            invitations
          ) {
            const notif = i as ProgramInvitationNotification;
            if (
              invitations.findIndex(
                (invite) =>
                  invite._id === notif.attributes.programInvitation._id
              ) !== -1
            ) {
              return true;
            }
            return false;
          }
          // Removing survey invitations that are submitted
          if (
            i._ktid_nt === NotificationsSecretSauce["surveyInvite"] &&
            surveyInvitations
          ) {
            const notif = i as SurveyNotification;
            if (
              surveyInvitations.findIndex(
                (invite) => invite._id === notif.attributes.surveyInvitation._id
              ) !== -1
            ) {
              return true;
            }
            return false;
          }
          // Removing cancelled match requests
          if (
            i._ktid_nt === NotificationsSecretSauce["matchRequest"] &&
            surveyInvitations
          ) {
            const notif = i as ConnectionRequestNotification;
            if (notif.attributes.matchRequest.status !== "pending") {
              return false;
            }
            return true;
          }
          return true;
        });
        return {
          data: filtered
            .filter((n) => {
              if (filterBy === "all") {
                return true;
              }
              return n.isSeen === false;
            })
            .slice(0, max),
          unSeenCount: filtered.filter((i) => !i.isSeen).length,
          allCount: filtered.length
        };
      },
      [invitations, surveyInvitations, filterBy, max]
    ),
    enabled: Boolean(invitations),

    ...options
  });
};
