import { useState, useEffect } from "react";
import CoachProfileCard from "./CoachProfileCard";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { getPendingUpdateRequests } from "../../../../services/dashboard/adminDashboard.services";
export const ProfileUpdateRequest = () => {
  const [updateRequests, setUpdateRequests] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(user)
  useEffect(() => {
    if (user?.data?._id) {
      onGetPendingUpdateRequests();
    }
  }, [setUpdateRequests]);

  function onGetPendingUpdateRequests() {
    getPendingUpdateRequests(user?.data?._id)
      .then((res) => {
        const { data } = res.data;
        // console.log(">>>>>>>>>> ", data);
        if (res.data.status) {
          setUpdateRequests(data);
        }
      })
      .catch((e) => {
        Failure("Oops! Something went wrong");
        console.log(e);
      });
  }

  return (
    <div>
      {/* Request counter */}
      {/* <div className="px-2 py-3">
                <div>
                    <span className="font-bold">Requests</span> <span className="font-bold py-1 px-2 rounded-full text-white text-xs" style={{ backgroundColor: '#EC5569' }}>{updateRequests?.length}</span>
                </div>
            </div> */}

      {/* Request List */}
      <div className="py-4">
        <CoachProfileCard coachData={updateRequests} />
      </div>
    </div>
  );
};
