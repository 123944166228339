import { Typography } from "knack-ui";
import { toast } from "react-toastify";
import SVG from "../SVG/SVG";

export const Success = (title, subtitle) => {
  toast.success(
    <div>
      <div>
        <Typography variant="subtitle2" fontWeight="medium">
          {title || "Success"}
        </Typography>
        {subtitle && (
          <Typography variant="caption" color="muted">
            {subtitle}
          </Typography>
        )}
      </div>
    </div>,
    {
      autoClose: true,
      icon: () => <SVG name="CheckmarkCircle" />
    }
  );
};
