import { Box, Breadcrumbs, Typography } from "knack-ui";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { INewCampaignFormProps } from "../CampaignTypes";
import NewCampaignBody from "./NewCampaign/NewCampaignBody";
import NewCampaignParticipants from "./NewCampaign/NewCampaignParticipants";

const NewCampaign = () => {
  const location = useLocation<{ body: string; subject: string }>();
  const methods = useForm<INewCampaignFormProps>({
    defaultValues: {
      body: location.state?.body || `<p><strong><span style="font-family: Inter;">Dear {{first_name}},</span></strong></p>`,
      subject: location.state?.subject ,
      recipients: []
    }
  });
  return (
    <div>
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[
          <Link key="link" to="/campaigns">
            Campaigns
          </Link>,
          <Typography key="name" clamp={1}>
            New campaign
          </Typography>
        ]}
      </Box>
      <FormProvider {...methods}>
        <div
          className="flex flex-row-reverse space-x-4 lg:flex-row"
        >
          <NewCampaignBody />
          <NewCampaignParticipants />
        </div>
      </FormProvider>
    </div>
  );
};

export default NewCampaign;
