import { BaseDialog } from "knack-ui";
import { USER_TYPES } from "../../../../../App.constant";
import { IProgram } from "../../../../../features/Programs/ProgramTypes";
import {
  useUser,
  useUserRoles
} from "../../../../../features/User/userQueries";
import { requestCronofyAuthorizationURL } from "../../../CronofyService";

interface Props {
  open: boolean;
  setOpen: () => void;
  program: IProgram;
}

export const ConnectCalendarPrompt = ({ open, setOpen, program }: Props) => {
  const { roleState } = useUserRoles();
  const { data: user } = useUser();

  return (
    <BaseDialog
      dialogClassName="max-w-lg"
      allowPinchZoom
      isOpen={open}
      onClose={() => setOpen()}
    >
      <div className="grid gap-4 p-6 text-center">
        <div className="text-skin-accent font-bold text-sm">
          Calendar Integration
        </div>
        <div className="text-lg sm:text-2xl font-bold max-w-screen-md mx-auto">
          Connect your calendar to seamlessly schedule meetings with your{" "}
          {roleState?.activeRole === "User"
            ? program.program_type === "Mentoring"
              ? USER_TYPES.MENTOR
              : USER_TYPES.COACH
            : USER_TYPES.CLIENT}
        </div>
        <button
          onClick={() =>
            requestCronofyAuthorizationURL()
          }
          className="text-skin-accent px-4 py-2 border-none"
        >
          {" "}
          <u>Click here </u> to connect a calendar attached to your email{" "}
          <strong>{user?.email}</strong>
        </button>
      </div>
    </BaseDialog>
  );
};
