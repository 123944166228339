import React, { useState, useEffect } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { getPaymentConfiguration } from "../../../../services/dashboard/adminDashboard.services";
import { PaymentConfigurationInput } from "./PaymentConfigurationInput";

export const PaymentConfiguration = ({ coaches = [] }) => {
  const [paymentConfig, setPaymentConfig] = useState([]);

  useEffect(() => {
    onGetPaymentConfiguration();
  }, [setPaymentConfig]);

  function onGetPaymentConfiguration() {
    getPaymentConfiguration()
      .then((res) => {
        if (res.data && res.data.status) {
          setPaymentConfig(res.data.data);
          // console.log(res.data.data)
        }
      })
      .catch((e) => {
        Failure("Oops! Something went wrong");
        console.log(e);
      });
  }

  return (
    <div>
      <div className="bg-white rounded-lg shadow">
        {/* table header */}
        <div className="grid grid-cols-5 gap-4 p-4 font-bold text-sm xl:text-base border-b text-center">
          <div>Coach</div>
          <div>1:1 session (Invidual Plan)</div>
          <div>1:1 session (Team Plan)</div>
          <div>Team Session (Team Plan)</div>
        </div>
        {coaches &&
          coaches?.map((c, i) => (
            <div key={i}>
              {" "}
              <PaymentConfigurationInput
                getPaymentConfiguration={onGetPaymentConfiguration}
                CoachName={
                  c?.profile_data?.first_name
                    ? `${c?.profile_data?.first_name} ${c?.profile_data?.last_name}`
                    : `${c?.email}`
                }
                coachId={c._id}
                hiPotentialPlan={
                  paymentConfig.filter(
                    (f) =>
                      c._id === f.coach &&
                      f.session_type === "1:1 session" &&
                      f.subscription_plan === "Hi-Potential"
                  )[0]?.amount
                }
                hiPotentialTeamPlan={
                  paymentConfig.filter(
                    (f) =>
                      c._id === f.coach &&
                      f.session_type === "1:1 session" &&
                      f.subscription_plan === "Team"
                  )[0]?.amount
                }
                teamPlan={
                  paymentConfig.filter(
                    (f) =>
                      c._id === f.coach &&
                      f.session_type === "Team session" &&
                      f.subscription_plan === "Team"
                  )[0]?.amount
                }
              />{" "}
            </div>
          ))}
      </div>
    </div>
  );
};
