import { useHistory, useLocation } from "react-router-dom";

export const BackButton = ({ defaultRef, margin = "mb-2" }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <button
      style={{ width: "fit-content" }}
      className={`flex flex-start ${margin}`}
      onClick={() =>
        defaultRef ? history.push({ pathname: defaultRef }) : history.goBack()
      }
    >
      <div className="bg-white rounded-lg w-auto text-xs flex flex-row items-center p-2 cursor-pointer shadow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {" "}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        {location.state && location.state.from ? (
          <span className="ml-2 uppercase"> BACK TO {location.state.from}</span>
        ) : (
          <span className="ml-2 uppercase"> BACK </span>
        )}
      </div>
    </button>
  );
};
