import { Avatar, Button, Stack, Typography } from "knack-ui";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { INewGoalFormProps } from "../../../../../features/Goals/goalsTypes";
import { useGetConnections } from "../../../../../features/Connections/connectionsQueries";
import styled from "styled-components";
import SVG from "../../../../../components/SVG/SVG";
import TransferList from "../../../../../components/TransferList";

const NewGoalShareWith = ({
  setActiveStep,
  setCompletedSteps,
  handleSubmitGoal
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  setCompletedSteps: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  handleSubmitGoal: SubmitHandler<INewGoalFormProps>;
}) => {
  const [search, setSearch] = useState("");

  const { data: connections } = useGetConnections({ search });

  const { setValue, watch, handleSubmit } = useFormContext<INewGoalFormProps>();

  const sharedWith = watch("share_with");

  const onAllSelect = () => {
    if (!connections) return;
    const ids = connections?.allConnections.map((c) => c._id);

    setValue("share_with", [...sharedWith, ...ids]);
  };

  const onDeselectAll = () => {
    setValue("share_with", []);
  };

  const onConnectionToggle = (id: string) => {
    if (sharedWith.includes(id)) {
      return setValue(
        "share_with",
        sharedWith.filter((c) => c !== id)
      );
    }
    setValue("share_with", [...sharedWith, id]);
  };

  const sharedWithProfiles = useMemo(() => {
    if (!connections) return [];
    return connections.allConnections.filter((c) => sharedWith.includes(c._id)).map((c) => c);
  }, [sharedWith, connections]);

  const unselectedConnections = useMemo(() => {
    if (!connections) return [];
    return connections.allConnections.filter((c) => !sharedWith.includes(c._id));
  }, [sharedWith, connections]);

  return (
    <>
      <div className="p-6 dialog-content-max-h scrollhost2">
        <Typography variant="body1" fontWeight="bold">
          Share your goal
        </Typography>
        <Typography className="mb-6" variant="subtitle2" color="muted">
          You can choose to share your goal with your experts, they will able to view your progress.
        </Typography>

        <TransferList
          renderSelectedItem={(item) => (
            <Connection onClick={() => onConnectionToggle(item._id)} key={item._id}>
              <Avatar image={item.profile_image} text={item.first_name} />
              <Typography clamp={1} fontWeight="medium" variant="subtitle2">{`${item.first_name} ${item.last_name}`}</Typography>
              <SVG name="Close" className="text-warning" />
            </Connection>
          )}
          onDeselectAll={onDeselectAll}
          onSelectAll={onAllSelect}
          unselectedItems={unselectedConnections}
          selectedItems={sharedWithProfiles}
          renderUnselectedItem={(item) => (
            <Connection onClick={() => onConnectionToggle(item._id)} key={item._id}>
              <Avatar image={item.profile_image} text={item.first_name} />
              <Typography clamp={1} fontWeight="medium" variant="subtitle2">{`${item.first_name} ${item.last_name}`}</Typography>
              <SVG name="RightChevron" />
            </Connection>
          )}
          searchProps={{ onChange: setSearch, value: search, placeholder: "Search for an expert..." }}
          selectedItemsListTitle="Your goal is shared with:"
          emptyStateProps={{
            whenAllItemsAreSelected: "You have selected all of your experts",
            whenNoItemsAreSelected: "Select who you want to share your goal with from the list on the left"
          }}
        />
      </div>
      <Stack className="p-6 border-t" justifyContent="flex-end" gap={4}>
        <Button onClick={() => setActiveStep((prev) => prev - 1)} kind="tertiary">
          Back
        </Button>
        <Button onClick={handleSubmit(handleSubmitGoal)}>Create goal</Button>
      </Stack>
    </>
  );
};

export default NewGoalShareWith;

const Connection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  transition: background 150ms ease;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-border);
  }
  @media ${(p) => p.theme.queries.hoverPointerDevices} {
    cursor: pointer;
    &:hover {
      background-color: var(--color-gray-200);
    }
  }
`;
