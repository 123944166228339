import { Button, Popover, Stack, Typography } from "knack-ui";
import { useMemo, useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { DeleteUser } from "../../../../../../components/ActionSheets/DeleteUser";
import { RemoveUserFromProgram } from "../../../../../../components/ActionSheets/RemoveUserFromProgram";
import ConfirmationDialog from "../../../../../../components/ConfirmationDialog";
import { Failure } from "../../../../../../components/ToastNotification/Failure";
import { queryClient } from "../../../../../../lib/appConfig";
import { updateMentoringRequests } from "../../../../../../services/mentoring/mentoring.service";
import { onStartManualMatch } from "../../../../../../StateManagement/ManualMatching";
import { IConnectionRequest } from "../../../../../Connections/ConnectionsTypes";
import { programsQueryKeys, useGetParticipantRequests } from "../../../../programQueries";
import { IProgram, IProgramInvite } from "../../../../ProgramTypes";

interface IProps {
  program: IProgram;
  member: IProgramInvite;
}

export const HrProgramParticipantUserActions = ({ program, member }: IProps) => {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmRemoveFromProgram, setOpenConfirmRemoveFromProgram] = useState(false);
  const { setOpen, setData } = onStartManualMatch();
  const [approvedRequest, setApprovedRequest] = useState<IConnectionRequest>();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const { data: approvedRequestsList, refetch } = useGetParticipantRequests(
    {
      participantId: member?.profile?.user?._id,
      programId: program._id,
      userType: "user"
    },
    {
      select: (res) => res.filter((r) => r.status === "approved")
    }
  );

  const approvedRequests = useMemo(() => {
    if (approvedRequestsList) return approvedRequestsList;
    return [];
  }, [approvedRequestsList]);

  function onEndRelation(request: IConnectionRequest) {
    setApprovedRequest(request);
    console.log(request);
    setOpenConfirm(true);
  }

  const getOptions = useMemo(() => {
    let defaultArr = [
      ...(member.confirmed && program.coach_type !== "knack_coaching"
        ? [
            {
              Label: `Change ${program.program_type === "Mentoring" ? "mentor" : "coach"} `,
              Icon: "",
              onHandler: () => {
                if (member && member?.profile?.first_name) {
                  setOpen();
                  setData({ mentee: member, program: program, refresh: refetch });
                }
              }
            }
          ]
        : [])
    ];
    approvedRequestsList?.forEach((m) => {
      defaultArr.push({
        Label: `End pairing with ${m?.mentorProfile?.first_name} ${m?.mentorProfile.last_name}`,
        Icon: "",
        onHandler: () => {
          onEndRelation(m);
        }
      });
    });

    return defaultArr;
  }, [member, program, approvedRequestsList]);

  function willEndRelation() {
    if (approvedRequest) {
      updateMentoringRequests("cancelled", approvedRequest._id)
        .then(() => {
          setOpenConfirm(false);
          queryClient.invalidateQueries(programsQueryKeys.all);
        })
        .catch((e) => {
          console.log(e);
          setOpenConfirm(false);
          Failure("Error occurred ending the pairing status, Please contact support");
        });
    }
  }

  return (
    <>
      <DeleteUser userProfile={member?.profile} open={openConfirmDelete} setOpen={setOpenConfirmDelete} />
      <RemoveUserFromProgram
        userType="user"
        program={program}
        userProfile={member?.profile}
        open={openConfirmRemoveFromProgram}
        setOpen={setOpenConfirmRemoveFromProgram}
      />

      <ConfirmationDialog
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        headerText="End pairing"
        footerComponent={() => {
          return (
            <Stack justifyContent="flex-end" gap={2}>
              <Button kind="ghost" onClick={() => setOpenConfirm(false)}>
                Cancel
              </Button>
              <Button onClick={() => willEndRelation()}>End pairing</Button>
            </Stack>
          );
        }}
      >
        <Typography className="mb-4" fontWeight="semibold">
          {`Are you sure you want to end pairing between ${approvedRequest?.menteeProfile?.first_name} ${approvedRequest?.menteeProfile?.last_name} and  ${approvedRequest?.mentorProfile?.first_name} ${approvedRequest?.mentorProfile?.last_name}`}
          ?
        </Typography>
      </ConfirmationDialog>

      <Button
        style={{ justifyContent: "left" }}
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation();
          setMenuOpen(true);
        }}
        iconOnly
        kind="ghost"
        shape="rounded"
      >
        <BiDotsVerticalRounded size={20} />
      </Button>

      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        parentRef={buttonRef}
      >
        {[
          ...(approvedRequests?.length === 0 && member.confirmed && program.coach_type !== "knack_coaching"
            ? [
                {
                  Label: `Assign a ${program.program_type === "Mentoring" ? "Mentor" : "Coach"}`,
                  Icon: "",
                  onHandler: () => {
                    if (member && member?.profile?.first_name) {
                      setOpen();
                      setData({
                        mentee: member,
                        program: program,
                        refresh: refetch
                      });
                    }
                  }
                }
              ]
            : getOptions),

          {
            Label: `Remove ${member?.profile?.first_name || member?.profile.user?.email} ${
              member?.profile?.last_name || ""
            } from this program`,
            Icon: "",
            onHandler: () => {
              if (member) {
                setOpenConfirmRemoveFromProgram(true);
              }
            }
          },
          ...(!member.confirmed
            ? [
                {
                  Label: `Delete ${member?.profile?.first_name || member?.profile?.user?.email} ${member?.profile?.last_name || ""}`,
                  Icon: "",
                  onHandler: () => {
                    if (member) {
                      setOpenConfirmDelete(true);
                    }
                  }
                }
              ]
            : [])
        ].map((o, i) => (
          <Button
            style={{ justifyContent: "left", textAlign: "left", borderBottom: "1px solid #eee" }}
            key={i}
            className="w-full"
            variant="small"
            kind="ghost"
            shape="square"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              o.onHandler();
            }}
          >
            {o?.Label}
          </Button>
        ))}
      </Popover>
    </>
  );
};
