import { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ViewProgram } from "../../../pages/mentoring/hrDashboard/v2/Program/ViewProgram";
import { ResetPassword } from "../../../pages/Auth/ResetPassword";
import { Reports } from "../../../pages/dashboard/userDashboard/v2/Pages/Reports";
import { SelfAssessmentForm } from "../SelfAssessmentForm";
import HrAccount from "../../../pages/account/mentoraccount";
import { Learning } from "../../../pages/dashboard/userDashboard/v2/Pages/Learning";
import Journeys from "../../../pages/dashboard/userDashboard/userLearning/journeys/journeys";
import SingleJourney from "../../../pages/dashboard/userDashboard/userLearning/journeys/singleJourney/singleJourney";
import { Scheduler } from "../../../services/Scheduling/Scheduler/Scheduler";
import Goals from "../Goals/Goals";
import { Calendar } from "../../../services/Scheduling/Calendar";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import { loadComponent } from "../../../lib/utils";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { Settings } from "../Settings";
import { useUserCompany } from "../../../features/User/userQueries";
import Connections from "../Connections/Connections";
import ConnectionProfile from "../ConnectionProfile/ConnectionProfile";
import Programs from "../Programs/Programs";
import Sessions from "../../../features/Sessions/ui/Sessions";
import UserSurveyInvitation from "../../../features/Surveys/ui/SurveyInvitation/UserSurveyInvitation";
import NewUserDashboard from "../../../pages/UserDashboard/UserDashboard";
import { SessionSummary } from "../../../features/Sessions/ui/SessionSummary";
// import NewUserDashboard from "../../../pages/UserDashboard/NewUserDashboard";

const LazyGoal = lazy(() => loadComponent(() => import("../Goal/Goal")));
const Goal = withSuspense(withErrorBoundary(LazyGoal), {
  fallBackComponent: <PageSkeleton />
});
const LazyProgram = lazy(() => loadComponent(() => import("../Programs/Program")));
const Program = withSuspense(withErrorBoundary(LazyProgram), {
  fallBackComponent: <PageSkeleton />
});

const LazyNotificationCenter = lazy(() => loadComponent(() => import("../../../features/Notifications/ui/NotificationCenter")));

const NotificationCenter = withErrorBoundary(
  withSuspense(LazyNotificationCenter, {
    fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
  })
);
const LazyProgramRegistration = lazy(() =>
  loadComponent(() => import("../../../features/Programs/ui/ProgramRegistration/ProgramRegistration"))
);

const ProgramRegistration = withErrorBoundary(
  withSuspense(LazyProgramRegistration, {
    fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
  })
);
const UserDashboard = () => {
  const { data: company } = useUserCompany();
  return (
    <Switch>
      <Route exact path="/programs" component={Programs} />
      <Route exact path="/dashboard" component={NewUserDashboard} />
      <Route exact path="/sessions" component={Sessions} />
      <Route exact path="/sessions/session-summary/:sessionId" component={SessionSummary} />
      <Route exact path="/scheduler" component={Scheduler} />
      <Route exact path="/scheduler/:programId" component={Scheduler} />
      <Route exact path="/scheduler/:programId/:userId" component={Scheduler} />
      <Route exact path="/scheduler/session/:sessionId" component={Scheduler} />

      <Route exact path="/reports" component={Reports} />
      <Route exact path="/self-assessment" component={SelfAssessmentForm} />
      <Route exact path="/programs/:programId/:r" component={Program} />
      <Route exact path="/view-program" component={ViewProgram} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/reset-password/:id" component={ResetPassword} />
      <Route exact path="/fill-survey/:invitationId" component={UserSurveyInvitation} />
      <Route exact path="/account" component={HrAccount} />
      {company?.features?.includes("Learning") && <Route exact path="/learning" component={Learning} />}
      <Route exact path="/journeys" component={Journeys} />
      <Route exact path="/journey" component={SingleJourney} />
      <Route exact path="/journey/:slug" component={SingleJourney} />
      <Route exact path="/goals" component={Goals} />
      <Route exact path="/goals/:id" component={Goal} />
      <Route exact path="/calendar" component={Calendar} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/connections" component={Connections} />
      <Route exact path="/connections/:id" component={ConnectionProfile} />
      <Route exact path="/profile/:id" component={ConnectionProfile} />
      <Route exact component={ProgramRegistration} path="/program-application/:id" />

      <Route exact path="/notification-center" component={NotificationCenter} />
      <Redirect to="/dashboard" />
    </Switch>
  );
};
export default UserDashboard;
