import { useEffect, useState } from "react";
import { CronofyService } from "../CronofyService";
import { PageHeader } from "../../../components/Typography/PageHeader";
import { SetAvailabilityRules } from "../Availability/SetAvailabilityRules";
import { useUser } from "../../../features/User/userQueries";

export const FreeBusyWrapper = () => {
  const { data: user } = useUser();
  const companyColorAccent = `${window
    .getComputedStyle(document.documentElement)
    .getPropertyValue("--color-accent")}`.trim();
  const [options, setOptions] = useState(null);

  useEffect(() => {
    onRequestElementAccessToken();
  }, [setOptions]);

  function onRequestElementAccessToken() {
    if (user?.profile?.scheduler_id) {
      CronofyService.requestElementAccessToken([user.profile.scheduler_id])
        .then((res) => {
          if (res && res.data.status) {
            // Sniff the browser's timezone
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // prepare options
            const options = {
              tzid: timezone,
              data_center: "de",
              element_token: res.data.data.element_token.token,
              target_id: "availability-rules",
              availability_rule_id: "work_hours",
              // calendar_ids: [
              //     ls_profile.scheduler_id
              // ],
              config: {
                start_time: "08:00",
                end_time: "18:00",
                duration: 30
              },
              styles: {
                colors: {
                  available: "#00a085",
                  unavailable: "#9ca3af",
                  primary: companyColorAccent
                },
                prefix: ""
              },
              translations: {
                en: {
                  availability_rules: {
                    save_new_rules: "Update"
                  }
                }
              }
            };
            setOptions(options);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  return (
    <div className="grid gap-4 m-auto">
      <PageHeader PageTitle="Set your Availability" />
      <div className="z-0">
        {options && <SetAvailabilityRules options={{ ...options }} />}
      </div>
    </div>
  );
};
