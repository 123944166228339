import { Box, Button, Input, Stack, Typography } from "knack-ui";
import { useEffect, useState } from "react";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import SVG from "../../../components/SVG/SVG";
import { Failure } from "../../../components/ToastNotification/Failure";
import { Success } from "../../../components/ToastNotification/Success";
import { queryClient } from "../../../lib/appConfig";
import { isValidURL } from "../../../services/utils/utils";
import { useUpdateProfile } from "../../User/userMutations";
import { userQueryKeys, useUser } from "../../User/userQueries";
import { IUser } from "../../User/UserTypes";

interface IProps {
  user?: IUser;
}

const CustomConferenceLink = ({ user }: IProps) => {
  const { data } = useUser();
  const [error, setError] = useState<boolean>(false);
  const [url, setURL] = useState<string | undefined>();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const submit = async (removeLink = false, message = "Meeting link added successfully!") => {
    try {
      const link = removeLink ? "" : url;
      await updateProfile({ customMeetingLink: link as string });
      Success(message);
      queryClient.invalidateQueries(userQueryKeys.all);
    } catch (error) {
      Failure("Oh no! Something went wrong");
    }
  };

  useEffect(() => {
    if (data?.profile?.customMeetingLink) {
      setURL(data?.profile?.customMeetingLink);
      if (isValidURL(data?.profile?.customMeetingLink)) {
        setError(false);
      } else {
        setError(true);
      }
    }
  }, [data]);

  return (
    <Box variant="outlined">
      <div className="p-4 border-b">
        <Typography variant="h6">Custom Conference Link</Typography>
        <Typography variant="subtitle2" color="muted">
          You can optionally provide meeting / conferencing link to specific room on zoom, google meet, microsoft teams or any other
          conferencing service. This link will be automatically added to any scheduled session.
        </Typography>
      </div>
      <Stack className="p-4 w-full" direction="column" gap={2}>
        <Input
          value={url}
          className="w-full"
          onChange={(e) => {
            setURL(e.target.value);
            if (isValidURL(e.target.value)) {
              setError(false);
            } else {
              setError(true);
            }
          }}
          inputPrefix={<AiOutlineVideoCameraAdd />}
          type="url"
          placeholder="Paste meeting link"
          inputSuffix={
            url ? (
              <Button
                kind="ghost"
                iconOnly
                variant="small"
                onClick={() => {
                  if (!isValidURL(url)) {
                    setURL("");
                  } else {
                    setURL("");
                    submit(true, "Meeting link removed successfully");
                  }
                }}
              >
                <SVG name="Close" />
              </Button>
            ) : (
              <></>
            )
          }
        />
        {error && url !== "" && (
          <Typography color="warning" variant="caption">
            Please paste a valid link
          </Typography>
        )}
      </Stack>
      <Box className="p-4">
        <Button disabled={error || !url} onClick={() => submit()} className="item-right">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CustomConferenceLink;
