import { Box, Breadcrumbs, Button, Input, Stack, Typography } from "knack-ui";
import { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { WarningInformation } from "../../../../components/Information/WarningInformation";
import { CustomToggle } from "../../../../components/Inputs/CustomToggle";
import { HeadlessModal } from "../../../../components/modal/HeadlessModal";
import { AvatarPlaceholder } from "../../../../components/SVG/AvatarPlaceholder";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import { PlatformFeatures } from "../../../../lib/constants";
import { uploadFormData } from "../../../../services/base-api";
import { AdminServices, getCompanyById } from "../../../../services/dashboard/adminDashboard.services";
import { isValidImage } from "../../../../services/utils/utils";
import { useCSVReader } from "react-papaparse";
import positionCSVSample from "./position-csv-sample.csv";

export const CreateCompany = () => {
  const { CSVReader } = useCSVReader();
  const goalRef = useRef();

  const [showImageSpinner, setShowImageSpinner] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [company, setCompany] = useState({
    name: "",
    custom_framework: false,
    logo: "",
    light_color: "",
    dark_color: "",
    goals: [],
    entities: [],
    departments: [],
    management_levels: [],
    one_grade_above: false,
    include_user_job_function: false,
    calendar: { isEnterprise: false, enterpriseName: "" },
    features: [],
    enableSingleSignOn: false,
    mediaSupported: true,
    positions: [],
    maximumAllowedCapacity: 10,
    programInvitationSubject: "",
    meetingDurations: [],
    externalLink: {
      name: "",
      url: ""
    },
    schedulingSupported: false
  });
  const [companyCoaches, setCompanyCoaches] = useState([]);
  const [goal, setGoal] = useState("");
  const [coaches, setCoaches] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [openCoach, setOpenCoaches] = useState(false);

  useEffect(() => {
    if (location?.state?.company) {
      const _company = { ...location.state.company };
      setCompany((prev) => ({ ...prev, ..._company }));
      getCompany(location?.state?.company._id);
      getExternalCoaches();
      getCompanyCoaches(location?.state?.company._id);
    }
  }, [setCompany, setCompanyCoaches, location.state.company]);

  function getCompany(id) {
    getCompanyById(id)
      .then((res) => {
        if (res.data.data) {
          setCompany(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getCompanyCoaches() {
    AdminServices.getCompanyPreferredCoaches(location?.state?.company._id).then((res) => {
      if (res.data.data && res.data.status === true) {
        const coaches = res.data.data.map((c) => c.coach._id);
        setCompanyCoaches(coaches);
      }
    });
  }

  function getExternalCoaches() {
    AdminServices.getCoaches()
      .then((res) => {
        if (res.data.status === true) {
          setCoaches(res.data.data);
          setFilteredCoaches(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function save(willExit = true) {
    if (company._id) {
      delete company.updatedAt;
      delete company.updated_at;
      delete company.createdAt;
      delete company.created_at;

      AdminServices.updateCompany(company, location.state.company._id)
        .then((res) => {
          if (res.data.status) {
            Success("Company Updated Successfully!");
            if (willExit) {
              history.goBack();
            }
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Oops Something went wrong. Please try again");
        });
    } else {
      AdminServices.createCompany(company)
        .then((res) => {
          getCompany(res.data.data._id);
          Success("Company Created Successfully!");
          if (willExit) {
            history.goBack();
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Oops Something went wrong. Please try again");
        });
    }
  }
  const handleCheckFeature = (name) => {
    if (company.features.includes(name)) {
      setCompany((prev) => ({
        ...prev,
        features: company.features.filter((i) => i !== name)
      }));
    } else {
      setCompany((prev) => ({
        ...prev,
        features: [...company.features, name]
      }));
    }
  };

  function imageSelected(e) {
    // validate file

    setShowImageSpinner(true);
    const bannerFormData = new FormData();
    bannerFormData.append("file", e.target.files[0]);
    bannerFormData.append("type", "image");

    // upload image
    uploadFormData(bannerFormData)
      .then((bannerFileRes) => {
        setCompany((p) => ({ ...p, logo: bannerFileRes.data.url }));
        setShowImageSpinner(false);
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops! Banner upload failed, please try again");
        setShowImageSpinner(false);
      });
  }

  function onAddGoal(goal) {
    const _goals = company.goals.length > 0 ? [...company.goals] : [];
    if (!company.goals.includes(goal)) {
      _goals.push(goal);
      setCompany((e) => ({ ...e, goals: _goals }));
      setGoal("");
      goalRef.current.value = "";
    }
  }

  function deleteGoal(goal) {
    const _goals = company.goals.filter((f) => f !== goal);
    setCompany((e) => ({ ...e, goals: _goals }));
  }

  function deleteDepartment(item) {
    const _items = company.departments.filter((f) => f !== item);
    setCompany((e) => ({ ...e, departments: _items }));
  }

  function deletePosition(item) {
    const _items = company.positions.filter((f) => f !== item);
    setCompany((e) => ({ ...e, positions: _items }));
  }

  function deleteEntity(item) {
    const _items = company.entities.filter((f) => f !== item);
    setCompany((e) => ({ ...e, entities: _items }));
  }

  function deleteManagementLevel(item) {
    const _items = company.management_levels.filter((f) => f !== item);
    setCompany((e) => ({ ...e, management_levels: _items }));
  }

  function onToggleChange(coachId) {
    const coaches = [...companyCoaches];
    if (!coaches.includes(coachId)) {
      AdminServices.setCompanyPreferredCoaches(company._id, [coachId])
        .then((res) => {
          if (res.data.status === true) {
            getCompanyCoaches(company._id);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      AdminServices.deleteCompanyPreferredCoach(company._id, coachId)
        .then(() => {
          getCompanyCoaches(company._id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function onSearchProgram(value) {
    if (value !== "") {
      const filtered = coaches.filter(
        (p) =>
          p?.email?.toLowerCase()?.includes(value.toLowerCase()) ||
          `${p?.profile_data?.first_name} ${p?.profile_data?.last_name}`?.toLowerCase()?.includes(value.toLowerCase())
      );
      setFilteredCoaches(filtered);
    } else {
      setFilteredCoaches([...coaches]);
    }
  }

  function onSavePreferredCoach() {
    if (companyCoaches.length > 0) {
      setOpenCoaches(false);
    } else {
      setWarning("Please select at least 1 coach");
    }
  }

  const [warning, setWarning] = useState(null);

  return (
    <div>
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4">
        {[
          <Link key="link" to={"/companies"}>
            {" "}
            Companies{" "}
          </Link>,
          <Typography key="name" clamp={1}>
            {company?._id || "New Company"}
          </Typography>
        ]}
      </Box>

      <HeadlessModal
        disabled={coaches.length === 0}
        width="max-w-screen-md"
        open={openCoach}
        setOpen={setOpenCoaches}
        canDismissFromBackdrop={companyCoaches.length > 0}
        showDefaultButton={true}
        defaultButtonText="Done"
        buttonFunction={() => onSavePreferredCoach()}
      >
        {warning && companyCoaches.length === 0 && <WarningInformation message={warning} />}

        <div className="">
          <div className="p-4 border-b">
            <div className=""> Toggle to set coach as Preferred </div>
          </div>
          <div className="">
            <div className="flex items-center justify-start font-bold border-b">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute w-5 h-5 left-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <input
                placeholder="Search coach ..."
                className="p-4 pl-12 m-0 border-none rounded-lg outline-none"
                onChange={(e) => onSearchProgram(e.target.value)}
              />
            </div>
            {/* Coaches */}
            <div className="grid gap-4 overflow-y-auto scrollhost2" style={{ maxHeight: 500 }}>
              {filteredCoaches?.map((r, i) => (
                <div key={i + 1} className="grid grid-cols-3 px-4 py-2 border-b">
                  <div>
                    <div className="text-base font-bold">
                      {r?.profile_data?.first_name} {r?.profile_data?.last_name}
                    </div>
                    <div className="text-sm whitespace-wrap">{r?.profile_data?.spoken_languages?.toString()}</div>
                  </div>
                  <div>
                    <div className="text-base whitespace-wrap">{r?.email}</div>
                  </div>

                  <div className="flex justify-end">
                    <label className="block custom-switch-button">
                      <input
                        type="checkbox"
                        onChange={() => onToggleChange(r._id)}
                        defaultChecked={companyCoaches.includes(r?._id) ? true : false}
                      />
                      <span className="custom-switch-slider round"></span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </HeadlessModal>

      <div className="grid gap-4 p-4 bg-white rounded-lg shadow">
        {/* Upload Company Logo */}
        <div className="flex flex-row items-center justify-center w-full">
          <label htmlFor="file-upload" className="cursor-pointer">
            <div>
              <div className="relative">
                <AvatarPlaceholder classes="h-16 lg:h-32 lg:w-32 w-16" imageUrl={company.logo} isUploading={showImageSpinner} />
                <div className="absolute bottom-0 right-0 flex items-center justify-center w-8 h-8 rounded-full bg-skin-fill m lg:m-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="#fff">
                    <path
                      fillRule="evenodd"
                      d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              className="sr-only"
              onChange={(e) => {
                if (!isValidImage(e.target.value)) {
                  return false;
                }
                imageSelected(e);
              }}
            />
          </label>
        </div>

        {/* Company Name */}
        <div className="mt-4">
          <label className="">
            <div className="mb-3 text-base font-medium">Company Name</div>
            <input
              type="text"
              className="rounded-lg"
              defaultValue={company.name}
              onChange={(v) => setCompany((e) => ({ ...e, name: v.target.value }))}
              placeholder="e.g Demo Company"
            />
          </label>
        </div>

        {/* Company Goals */}
        <div className="mt-4 border-b">
          <div className="">
            <div className="mb-3 text-base font-medium">Set Company Goals</div>
            <div className="flex flex-row max-w-md gap-4">
              <input
                onKeyUp={(e) => (e.keyCode === 13 ? onAddGoal(goal) : null)}
                ref={goalRef}
                type="text"
                className="rounded-lg"
                onChange={(v) => setGoal(v.target.value)}
                placeholder="Add company goal"
              />
              <Button kind="primary" onClick={() => onAddGoal(goal)} disabled={goal === "" ? true : false}>
                Add Goal
              </Button>
            </div>

            <div className="w-full max-w-md mt-4">
              {company?.goals?.map((g, i) => (
                <div key={i} className="flex flex-row justify-between w-full p-2 my-4 bg-gray-100">
                  <div>{g}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 cursor-pointer"
                    style={{ minWidth: 16 }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => deleteGoal(g)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Company Departments */}
        <div className="mt-4 border-b">
          <div className="">
            <div className="text-base font-medium">
              <Typography fontWeight="bold"> Upload Company departments</Typography>
              <Typography variant="body2" fontWeight="light">
                CSV must contain only department and nothing else.{" "}
                <a
                  href={positionCSVSample}
                  className="text-xs outline-none border-none text-green-500 font-bold"
                  download="./knacklab-position-cvs-sample.csv"
                >
                  Download Sample
                </a>
              </Typography>
            </div>
            <div className="flex flex-row max-w-md gap-4">
              <Stack direction="column" gap={4}>
                <CSVReader
                  accept=".csv"
                  onUploadAccepted={(results) => {
                    const columns = results.data.map((r) => r[0].replace("\r", ""));
                    columns.shift();

                    let _departments = company?.departments?.length > 0 ? [...company.departments] : [];
                    _departments = Array.from(new Set([..._departments, ...columns]));
                    setCompany((p) => ({
                      ...p,
                      departments: _departments
                    }));
                  }}
                >
                  {({ getRootProps, acceptedFile, ProgressBar }) => (
                    <Stack direction="column" gap={4}>
                      <Stack alignItems="center" gap={4}>
                        <Button type="button" {...getRootProps()}>
                          Select CSV
                        </Button>
                        <Typography kind="">{acceptedFile && acceptedFile.name}</Typography>
                      </Stack>
                      <ProgressBar style={{ backgroundColor: "rgb(17,51,186)" }} />
                    </Stack>
                  )}
                </CSVReader>
              </Stack>
            </div>

            <div className="w-full max-w-md mt-4 scrollhost2 max-h-96 overflow-y-auto">
              {company?.departments?.map((g, i) => (
                <div key={i} className="flex flex-row justify-between w-full p-2 my-4 bg-gray-100">
                  <div>{g}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 cursor-pointer"
                    style={{ minWidth: 16 }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => deleteDepartment(g)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Company Position */}
        <div className="mt-4 border-b">
          <div className="">
            <div className="text-base font-medium">
              <Typography fontWeight="bold"> Upload Company positions</Typography>
              <Typography variant="body2" fontWeight="light">
                CSV must contain only positions.{" "}
                <a
                  href={positionCSVSample}
                  className="text-xs outline-none border-none text-green-500 font-bold"
                  download="./knacklab-position-cvs-sample.csv"
                >
                  Download Sample
                </a>
              </Typography>
            </div>
            <div className="flex flex-row max-w-md gap-4">
              <Stack direction="column" gap={4}>
                <CSVReader
                  accept=".csv"
                  onUploadAccepted={(results) => {
                    const columns = results.data.map((r) => r[0].replace("\r", ""));
                    columns.shift();

                    let _positions = company?.positions?.length > 0 ? [...company.positions] : [];
                    _positions = Array.from(new Set([..._positions, ...columns]));
                    setCompany((p) => ({
                      ...p,
                      positions: _positions
                    }));
                  }}
                >
                  {({ getRootProps, acceptedFile, ProgressBar }) => (
                    <Stack direction="column" gap={4}>
                      <Stack alignItems="center" gap={4}>
                        <Button type="button" {...getRootProps()}>
                          Select CSV
                        </Button>
                        <Typography kind="">{acceptedFile && acceptedFile.name}</Typography>
                      </Stack>
                      <ProgressBar style={{ backgroundColor: "rgb(17,51,186)" }} />
                    </Stack>
                  )}
                </CSVReader>
              </Stack>
            </div>

            <div className="w-full max-w-md mt-4 scrollhost2 max-h-96 overflow-y-auto">
              {company?.positions?.map((g, i) => (
                <div key={i} className="flex flex-row justify-between w-full p-2 my-4 bg-gray-100">
                  <div>{g}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 cursor-pointer"
                    style={{ minWidth: 16 }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => deletePosition(g)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Company Entities */}
        <div className="mt-4 border-b">
          <div className="">
            <div className="text-base font-medium">
              <Typography fontWeight="bold"> Upload Company entities</Typography>
              <Typography variant="body2" fontWeight="light">
                CSV must contain only entities{" "}
                <a
                  href={positionCSVSample}
                  className="text-xs outline-none border-none text-green-500 font-bold"
                  download="./knacklab-position-cvs-sample.csv"
                >
                  Download Sample
                </a>
              </Typography>
            </div>
            <div className="flex flex-row max-w-md gap-4">
              <Stack direction="column" gap={4}>
                <CSVReader
                  accept=".csv"
                  onUploadAccepted={(results) => {
                    const columns = results.data.map((r) => r[0].replace("\r", ""));
                    columns.shift();

                    let _state = company?.entities?.length > 0 ? [...company.entities] : [];
                    _state = Array.from(new Set([..._state, ...columns]));
                    setCompany((p) => ({
                      ...p,
                      entities: _state
                    }));
                  }}
                >
                  {({ getRootProps, acceptedFile, ProgressBar }) => (
                    <Stack direction="column" gap={4}>
                      <Stack alignItems="center" gap={4}>
                        <Button type="button" {...getRootProps()}>
                          Select CSV
                        </Button>
                        <Typography kind="">{acceptedFile && acceptedFile.name}</Typography>
                      </Stack>
                      <ProgressBar style={{ backgroundColor: "rgb(17,51,186)" }} />
                    </Stack>
                  )}
                </CSVReader>
              </Stack>
            </div>

            <div className="w-full max-w-md mt-4 scrollhost2 max-h-96 overflow-y-auto">
              {company?.entities?.map((g, i) => (
                <div key={i} className="flex flex-row justify-between w-full p-2 my-4 bg-gray-100">
                  <div>{g}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 cursor-pointer"
                    style={{ minWidth: 16 }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => deleteEntity(g)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Company Management Levels */}
        <div className="mt-4 border-b">
          <div className="">
            <div className="text-base font-medium">
              <Typography fontWeight="bold"> Upload Company management levels</Typography>
              <Typography variant="body2" fontWeight="light">
                CSV must contain only management levels and nothing else.{" "}
                <a
                  href={positionCSVSample}
                  className="text-xs outline-none border-none text-green-500 font-bold"
                  download="./knacklab-position-cvs-sample.csv"
                >
                  Download Sample
                </a>
              </Typography>
            </div>
            <div className="flex flex-row max-w-md gap-4">
              <Stack direction="column" gap={4}>
                <CSVReader
                  accept=".csv"
                  onUploadAccepted={(results) => {
                    const columns = results.data.map((r) => r[0].replace("\r", ""));
                    columns.shift();

                    let _state = company?.management_levels?.length > 0 ? [...company.management_levels] : [];
                    _state = Array.from(new Set([..._state, ...columns]));
                    setCompany((p) => ({
                      ...p,
                      management_levels: _state
                    }));
                  }}
                >
                  {({ getRootProps, acceptedFile, ProgressBar }) => (
                    <Stack direction="column" gap={4}>
                      <Stack alignItems="center" gap={4}>
                        <Button type="button" {...getRootProps()}>
                          Select CSV
                        </Button>
                        <Typography kind="">{acceptedFile && acceptedFile.name}</Typography>
                      </Stack>
                      <ProgressBar style={{ backgroundColor: "rgb(17,51,186)" }} />
                    </Stack>
                  )}
                </CSVReader>
              </Stack>
            </div>

            <div className="w-full max-w-md mt-4 scrollhost2 max-h-96 overflow-y-auto">
              {company?.management_levels?.map((g, i) => (
                <div key={i} className="flex flex-row justify-between w-full p-2 my-4 bg-gray-100">
                  <div>{g}</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 cursor-pointer"
                    style={{ minWidth: 16 }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    onClick={() => deleteManagementLevel(g)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Use One Grade Above*/}
        <div className="py-4 border-b">
          <label className="flex flex-row items-center gap-4">
            <CustomToggle
              state={company.one_grade_above}
              onChange={(v) => {
                setCompany((e) => ({ ...e, one_grade_above: v }));
              }}
            />
            <div>
              <div className="text-sm">Apply One Grade Above for Matching</div>
              <div className="text-xs text-muted">
                If true, the mentees of this organization will be matched only to mentors above the mentee's job band
              </div>
            </div>
          </label>
        </div>

        {/* Use Job Function */}
        <div className="py-4 border-b">
          <label className="flex flex-row items-center gap-4">
            <CustomToggle
              state={company.include_user_job_function}
              onChange={(v) => {
                setCompany((e) => ({ ...e, include_user_job_function: v }));
              }}
            />
            <div>
              <div className="text-sm">Include job function/department for Matching</div>
              <div className="text-xs text-muted">
                If false, the mentees/coachees of this organization will be matched only to mentors/coaches of different job function or
                department
              </div>
            </div>
          </label>
        </div>

        {/* Custom Mapping */}
        <div className="py-4 border-b">
          <label className="flex flex-row items-center gap-4">
            <CustomToggle
              state={company.custom_framework}
              onChange={(v) => {
                setCompany((e) => ({ ...e, custom_framework: v }));
              }}
            />
            <div className="text-sm">Use Custom Mapping</div>
          </label>
        </div>

        {/* Calendar */}
        <div className="mt-4 border-b">
          <div className="py-4">
            <div className="mb-3 text-base font-medium">Calendar Settings</div>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company?.calendar?.isEnterprise}
                onChange={(v) => {
                  setCompany((e) => ({
                    ...e,
                    calendar: { ...e.calendar, isEnterprise: v }
                  }));
                }}
              />
              <div>
                <div className="text-sm">Is Enterprise</div>
              </div>
            </label>
            <div className="text-sm">
              <label>Enterprise name</label>
              <input
                value={company?.calendar?.enterpriseName}
                type="text"
                className="rounded-lg"
                onChange={(v) =>
                  setCompany((prev) => ({
                    ...prev,
                    calendar: {
                      ...prev.calendar,
                      enterpriseName: v.target.value
                    }
                  }))
                }
                placeholder="Enter Enterprise name"
              />
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="mt-4 border-b">
          <div className="py-4">
            <div className="mb-3 text-base font-medium">Features</div>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["Mentoring"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["Mentoring"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["Mentoring"]}</div>
              </div>
            </label>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["Internal Coaching"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["Internal Coaching"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["Internal Coaching"]}</div>
              </div>
            </label>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["External Coaching"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["External Coaching"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["External Coaching"]}</div>
              </div>
            </label>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["Assessment"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["Assessment"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["Assessment"]}</div>
              </div>
            </label>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["Goals"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["Goals"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["Goals"]}</div>
              </div>
            </label>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["Learning"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["Learning"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["Learning"]}</div>
              </div>
            </label>
            <label className="flex flex-row items-center gap-4 my-4">
              <CustomToggle
                state={company.features.includes(PlatformFeatures["360 Assessment"])}
                onChange={() => {
                  handleCheckFeature(PlatformFeatures["360 Assessment"]);
                }}
              />
              <div>
                <div className="text-sm">{PlatformFeatures["360 Assessment"]}</div>
              </div>
            </label>
          </div>
        </div>

        {/* Company Theme settings */}
        <div className="pt-4 mt-4 border-b">
          <div className="text-lg font-bold">Set Custom Theme / WhiteLabel</div>
          {/* Company Color accent */}
          <div>
            <label className="flex flex-row items-center gap-4">
              <div className="text-sm font-medium">
                <div className="text-base"> Company Color Accent</div>
                <p className="text-xs text-skin-muted">Select a primary color to apply to components like buttons, text</p>
              </div>
              <input
                onChange={(v) => {
                  setCompany((e) => ({ ...e, dark_color: v.target.value }));
                }}
                type="color"
                value={company.dark_color}
                className="w-24 h-14"
              />{" "}
              {company.dark_color}
            </label>
          </div>
        </div>

        {/* Company Preferred Coaches */}
        {company._id && (
          <div className="pt-4 mt-4 border-b cursor-pointer hover:bg-gray-100" onClick={() => setOpenCoaches(true)}>
            <div className="text-lg font-bold"> Select Preferred Coaches for {company?.name}</div>
            {/* Company Color accent */}
            <div>
              <label className="flex flex-row items-center gap-4">
                <div className="text-sm font-medium">
                  <div className="text-base"> Click here to add select coaches</div>
                  <div></div>
                  <div></div>
                </div>
              </label>
            </div>
          </div>
        )}

        {/* Use One Grade Above*/}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <div className="text-lg font-bold">Company Authentication</div>
          <label className="flex flex-row items-center gap-4">
            <CustomToggle
              state={company.enableSingleSignOn}
              onChange={(v) => {
                setCompany((e) => ({ ...e, enableSingleSignOn: v }));
              }}
            />
            <div>
              <div className="text-sm font-medium">Enable Single Sign for {company?.name}</div>
              <div className="text-xs text-muted">
                If true, members of this organization will be authenticated with their custom identity provider e.g Google
              </div>
            </div>
          </label>
        </div>

        {/* Media Settings */}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <div className="text-lg font-bold">Media Settings</div>
          <label className="flex flex-row items-center gap-4">
            <CustomToggle
              state={company.mediaSupported}
              onChange={(v) => {
                setCompany((e) => ({ ...e, mediaSupported: v }));
              }}
            />
            <div>
              <div className="text-sm font-medium">
                Allow file upload such as profile photo, CSV, program banners etc for {company?.name}
              </div>
            </div>
          </label>
        </div>

        {/* Maximum allowed capacity */}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <div className="text-lg font-bold">Capacity Settings</div>
          <label className="flex flex-col items-left gap-4">
            <Input
              style={{ maxWidth: 100 }}
              type="number"
              className="rounded-lg"
              value={company?.maximumAllowedCapacity?.toString()}
              onChange={(v) => setCompany((e) => ({ ...e, maximumAllowedCapacity: +v.target.value }))}
            />
            <div>
              <div className="text-sm font-medium">This will control the maximum number of connections an experts can have.</div>
            </div>
          </label>
        </div>

        {/* Program Invitation Custom subject */}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <div className="text-lg font-bold">Program Invitation Custom subject</div>
          <label className="flex flex-col items-left gap-4">
            <Input
              style={{ maxWidth: 1000 }}
              type="text"
              className="rounded-lg"
              value={company?.programInvitationSubject}
              onChange={(v) => setCompany((e) => ({ ...e, programInvitationSubject: v.target.value }))}
              placeholder="e.g Invitation to register to program"
            />
            <div>
              <div className="text-sm font-medium">Text provided will appear has the subject for program invitation sent to users.</div>
            </div>
          </label>
        </div>

        {/* Meeting Durations */}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <Stack direction="column">
            <div className="text-lg font-bold">Meeting durations</div>
            <div className="text-sm font-medium">Provide meeting durations separated by a comma e.g 20, 30</div>
          </Stack>
          <label className="flex flex-col items-left gap-4">
            <Input
              style={{ maxWidth: 1000 }}
              type="text"
              className="rounded-lg"
              value={company?.meetingDurations?.toString()}
              onChange={(v) => setCompany((e) => ({ ...e, meetingDurations: v.target.value.split(",") }))}
              placeholder="e.g 20, 30"
            />
            <div>
              <div className="text-sm font-medium">This will restrict user to scheduling on the provided durations in minutes</div>
            </div>
          </label>
        </div>

        {/* External Link */}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <Stack direction="column">
            <div className="text-lg font-bold">Link to external resource / Learning pathway</div>
            <div className="text-sm font-medium">Provide a resource name and url. This will be displayed in the navigation section</div>
          </Stack>
          <label className="flex flex-col items-left gap-4">
            <Input
              type="text"
              className="rounded-lg"
              value={company?.externalLink?.name}
              onChange={(v) =>
                setCompany((e) => ({
                  ...e,
                  externalLink: {
                    ...company?.externalLink,
                    name: v.target.value
                  }
                }))
              }
              placeholder="resource name or label to display"
            />
          </label>
          <label className="flex flex-col items-left gap-4">
            <Input
              type="text"
              className="rounded-lg"
              value={company?.externalLink?.url}
              onChange={(v) =>
                setCompany((e) => ({
                  ...e,
                  externalLink: {
                    ...company?.externalLink,
                    url: v.target.value
                  }
                }))
              }
              placeholder="link to resource"
            />
          </label>
        </div>

        {/* Scheduling enabled Settings */}
        <div className="py-4 border-b pt-4 mt-4 grid gap-4">
          <div className="text-lg font-bold">Scheduling Settings</div>
          <label className="flex flex-row items-center gap-4">
            <CustomToggle
              state={company.schedulingSupported}
              onChange={(v) => {
                setCompany((e) => ({ ...e, schedulingSupported: v }));
              }}
            />
            <div>
              <div className="text-sm font-medium">Enable scheduling</div>
            </div>
          </label>
        </div>

        {/* Create / Update Company */}
        <div className="sticky flex flex-row items-end justify-end p-2 mt-16 bg-white top-20">
          <div
            onClick={() => (company.name !== "" ? save() : null)}
            className={`text-white px-4 py-2 rounded-md cursor-pointer text-sm mr-4 ${
              company.name !== "" ? "bg-skin-fill" : "bg-gray-300"
            }`}
          >
            Save Company{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
