import { Dispatch, SetStateAction, useRef, useState } from "react";
import { InputSelect } from "../../../../../components/Inputs/InputSelect";
import { InviteParticipantsCard } from "../../../../../components/Cards/InviteParticipantsCard";
import { SearchInput } from "../../../../../components/Inputs/SearchInput";
import { IProgram } from "../../../ProgramTypes";
import { ExpertTableList } from "../../../../../pages/mentoring/hrDashboard/People/ExpertTableList";
import { useFilter } from "./utils/Filter";
import { onSearchPeople } from "../../../../../pages/dashboard/hrDashboard/hrPeople/PeopleList";
import { Button, Popover } from "knack-ui";
import HrProgramParticipantInvitationByEmail from "./components/HrProgramParticipantInvitation";

interface IProps {
  program: IProgram;
}

export const HRProgramExperts = ({ program }: IProps) => {
  const [emailModal, setEmailModal] = useState(false);
  const buttonRef = useRef(null);



  return (
    <>
      <HrProgramParticipantInvitationByEmail
        open={emailModal}
        program={program}
        type={program.program_type === "Coaching" ? "coach" : "mentor"}
        onClose={() => setEmailModal(false)}
      />

      <div className="w-full">
        <FilterComponent program={program} setEmailModal={setEmailModal} />
        <ExpertTableList program={program} setEmailModal={setEmailModal} />
      </div>
    </>
  );
};


interface IFilterProps {
  program: IProgram;
  setEmailModal: Dispatch<SetStateAction<boolean>>;
}
const FilterComponent = ({ program, setEmailModal }: IFilterProps) => {
  const [showHoverCard, setShowHoverCard] = useState(false);
  const { onFilterChange, value } = useFilter();
  const { onChange } = onSearchPeople();
  const buttonRef = useRef(null);

  return (
    <div className="flex-row items-center hidden w-full gap-4 modify-program-select sm:flex">
      {program && (
        <div className="mr-4">
          <InputSelect options={["Registered", "Unregistered"]} w={"w-40"} onChange={onFilterChange} selected={value} name={undefined} />
        </div>
      )}

      <div className="w-full inline-block">
        <SearchInput placeholder="Search email, name..." onChange={onChange} />
      </div>

      {/* Invite CTA */}
      {program && program._id && !program?.archived && (
        <>
          <Button ref={buttonRef} onClick={() => setShowHoverCard(true)} kind="defaultOutline">
            {program.program_type === "Coaching" ? "Add Coaches" : "Add Mentors"}
          </Button>
          <Popover
            animationType="fade-up"
            offset={{ bottom: 10, left: 0 }}
            isOpen={showHoverCard}
            onClose={() => setShowHoverCard(false)}
            parentRef={buttonRef}
          >
            <InviteParticipantsCard
              program={program}
              type={program.program_type === "Coaching" ? "coach" : "mentor"}
              openAddByEmailModal={() => setEmailModal(true)}
            />
          </Popover>
        </>
      )}
    </div>
  );
};