import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { IActiveUserQuery, IUserLog } from "./activeUserListTypes";

export interface IActiveUserGenerateReportParams {
  query: IActiveUserQuery;
  exportAttributes: Array<keyof IUserLog>;
}

async function generateActiveUserReport(
  request: IActiveUserGenerateReportParams
) {
  const {
    organization,
    dateRange,
    programType,
    userType,
    program,
    search_by_email_or_name
  } = request.query;

  const params: Record<string, any> = {
    organization: organization?.join(","),
    userType: userType?.join(","),
    startDate: dateRange![0]?.startDate,
    endDate: dateRange![0]?.endDate,
    programType: programType?.join(","),
    program: program?.join(","),
    search_by_email_or_name
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>(
    "v2/analytics/logs/active-users/generate-report",
    { attributes: request.exportAttributes },
    { params }
  );
  return res.data.data;
}

// Mutations
export const useGenerateActiveUserReport = () => {
  return useMutation(generateActiveUserReport);
};
