import Header from "./header";
import React, { useState, useEffect } from "react";
import MicrolearningService from "../../../../../services/learning/microlearning.service";
import DashboardService from "../../../../../services/dashboard/userDashboard.services";
import Filters from "./filters";
import ContentArea from "../../../../../components/contentArea/contentArea";
import LearnItem from "./learnItem";
import Pagination from "../../../../../components/Pagination/updatedPagination";
import { Success } from "../../../../../components/ToastNotification/Success";

export default function Microlearning() {
  const [learnings, setLearnings] = useState([]);
  const [timeRanges, setTimeRanges] = useState([]);
  const [types, setTypes] = useState([]);
  const [themes, setThemes] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [themeQueryStrings, setThemeQueryStrings] = useState([]);
  const [typeQueryStrings, setTypeQueryStrings] = useState([]);
  const [timeQueryStrings, setTimeQueryStrings] = useState([]);
  const [queryStrings, setQueryStrings] = useState("");
  const [query, setQuery] = useState("");
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(true);
  const [bookmark, setBookmark] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const searchChange = (e) => {
    setSearchField(e.target.value);
  };

  const querystring = (string) => {
    return encodeURIComponent(string.join(","));
  };

  const finalQuery = (theme, type, time) => {
    return `theme=${querystring(theme ? theme : [])}`;
  };

  const onChange = (item, key) => {
    switch (key) {
      case "theme":
        if (themeQueryStrings.includes(item)) {
          setThemeQueryStrings(
            themeQueryStrings.filter((query) => query != item)
          );
        } else {
          setThemeQueryStrings([...themeQueryStrings, item]);
        }
        break;
      case "type":
        if (typeQueryStrings.includes(item)) {
          setTypeQueryStrings(
            typeQueryStrings.filter((query) => query != item)
          );
        } else {
          setTypeQueryStrings([...typeQueryStrings, item]);
        }
        break;
      case "time":
        if (timeQueryStrings.includes(item)) {
          setTimeQueryStrings(
            timeQueryStrings.filter((query) => query != item)
          );
        } else {
          setTimeQueryStrings([...timeQueryStrings, item]);
        }
        break;
      default:
        break;
    }
    console.log(query);
  };

  const addTodo = (title, user, type, id) => {
    DashboardService.addTodo(title, user, type, id).then(
      (response) => {
        if (response.data.status) {
          Success("Added to todo's");
          getLearnings(query, page);
        } else {
          console.log(response.data.data + ": " + response.data.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    let finalQ = finalQuery(
      themeQueryStrings,
      typeQueryStrings,
      timeQueryStrings
    );
    setQuery(finalQ);
    getLearnings(query, page);
  }, [themeQueryStrings, typeQueryStrings, timeQueryStrings, query, page]);

  let searchPhrase;
  if (searchField !== "") {
    searchPhrase = (
      <span className="">| Showing search results for: {searchField}</span>
    );
  }

  const openUrl = (url, resource_id) => {
    window.open(url);
    markStarted(resource_id);
  };

  const markStarted = (resource_id) => {
    MicrolearningService.markStarted(resource_id).then(
      (response) => {
        if (response.data.status) {
          setStarted(true);
          console.log(response.data);
        } else {
          console.log(response.status.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const markCompleted = (status, resource_id) => {
    MicrolearningService.markCompleted(!status, resource_id).then(
      (response) => {
        if (response.status) {
          setCompleted(!status);
          getLearnings(query, page);
          completed && Success("You marked this article completed");
          console.log(completed);
        } else {
          console.log(response.status.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const bookmarkArticle = (status, resource_id) => {
    MicrolearningService.bookmarkArticle(!status, resource_id).then(
      (response) => {
        if (response.status) {
          setBookmark(!status);
          getLearnings(query, page);
          Success(
            `You have ${
              bookmark ? "added to" : "removed from"
            } your bookmarks successfully!`
          );
          console.log(response);
        } else {
          console.log(response);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getLearnings = (query, page) => {
    MicrolearningService.getLearnings(query, page).then(
      (response) => {
        if (response.data.status) {
          setLearnings(response.data.data.learnings);
          setResultCount(response.data.data.totalRecords);
          setTotalPages(response.data.data.totalPages);
          // console.log(learnings);
          // console.log(resultCount);
        } else {
          console.log(response.status.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    getLearnings(query, page);

    MicrolearningService.getFilters().then(
      (response) => {
        if (response.data.status) {
          setThemes(response.data.data.category.buckets);
          setTimeRanges(response.data.data.time_ranges.buckets);
          setTypes(response.data.data.type.buckets);
        } else {
          console.log(response.status.message);
        }
        // console.log(themes);
        // console.log(types);
        // console.log(timeRanges);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const filteredLearnings = learnings.filter((learn) => {
    return (
      learn.title
        .toLocaleLowerCase()
        .includes(searchField.toLocaleLowerCase()) ||
      learn.source.name
        .toLocaleLowerCase()
        .includes(searchField.toLocaleLowerCase())
    );
  });
  return (
    <>
      {/* <Header searchChange={searchChange} /> */}
      <div className="flex">
        <Filters
          themes={themes}
          timeRanges={timeRanges}
          types={types}
          queryStrings={queryStrings}
          onChange={onChange}
        />
        <div className="relative w-full flex flex-col  overflow-y-hidden lg:ml-4">
          <div className="w-full text-gray-500  overflow-x-hidden flex flex-col">
            <ContentArea>
              <div className="text-xs">
                {`${resultCount} results`} {searchPhrase}
              </div>
              <div className="grid max-w-lg gap-5 lg:mr-4 my-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:max-w-none">
                {filteredLearnings.map((learn, i) => {
                  return (
                    <div key={i}>
                      <LearnItem
                        key={learn._id}
                        id={learn._id}
                        time={learn.time}
                        image={learn.image}
                        type={learn.type}
                        source={learn.source.name}
                        title={learn.title}
                        tag={learn.tag}
                        url={learn.url}
                        started={started}
                        openUrl={openUrl}
                        markCompleted={markCompleted}
                        bookmarkArticle={bookmarkArticle}
                        completed={learn.completed}
                        bookmarked={learn.bookmarked}
                        addTodo={addTodo}
                      />
                    </div>
                  );
                })}
              </div>
              <Pagination
                setPage={setPage}
                page={page}
                totalPages={totalPages}
              />
            </ContentArea>
          </div>
        </div>
      </div>
    </>
  );
}
