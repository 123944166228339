import EmptyState from "../../../../components/EmptyState/EmptyState";
import ProgramCard from "../../../../components/ProgramCard/ProgramCard";
import { IProgram } from "../../../../features/Programs/ProgramTypes";
import { ProgramsSearch } from "../Programs";

interface IProgramsListProps {
  programs: IProgram[];
}

const ProgramsList = ({ programs }: IProgramsListProps) => {
  const { search } = ProgramsSearch();

  return (
    <>
      {programs?.length === 0 && (
        <EmptyState
          key="empty"
          icon="ProgramBig"
          iconSize={100}
          // img={empty}
          title={
            search === ""
              ? "No programs found"
              : `No search results for "${search}"`
          }
          subtitle={search !== "" ? "Try using another spelling" : ""}
        />
      )}
      {programs.length > 0 && (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
          {programs.map((program) => (
            <ProgramCard program={program} key={program?._id} />
          ))}
        </div>
      )}
    </>
  );
};

export default ProgramsList;
