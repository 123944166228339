import { motion } from "framer-motion";
import { Box, Button, Stack, Typography } from "knack-ui";
import { Controller, SubmitHandler, useForm, useFormContext } from "react-hook-form";
import AutoCompleteInput from "../../../../components/AutoCompleteInput/AutoCompleteInput";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { useAddCompanyPosition } from "../../../../features/User/userMutations";
import { useUserCompany } from "../../../../features/User/userQueries";
import { INewUserProfile } from "../../../../features/User/UserTypes";
import usePositionMatch from "../../../../hooks/usePositionMatch";
import { ProfileCreationTransitions } from "./NewUserProfile";
import { OnboardingStepCounter } from "./OnboardingStepCounter";

interface IProfileCompanyInfoProps {
  handleNextStep: VoidFunction;
  handlePreviousStep: VoidFunction;
  handleCreateProfile: SubmitHandler<INewUserProfile>;
}
type FormProps = Pick<INewUserProfile, "entity" | "job_function" | "position" | "management_level">;
const ProfileCompanyInfo = ({ handleNextStep, handlePreviousStep, handleCreateProfile }: IProfileCompanyInfoProps) => {
  const { data: company } = useUserCompany();
  const { getValues, setValue } = useFormContext<INewUserProfile>();

  const { mutateAsync: addCompanyPosition } = useAddCompanyPosition();

  const {
    control,
    handleSubmit,
    watch: watchLocalValues,
    formState: { errors }
  } = useForm<FormProps>({
    defaultValues: {
      entity: getValues("entity"),
      job_function: getValues("job_function"),
      management_level: getValues("management_level"),
      position: getValues("position") || ""
    }
  });

  const positions = usePositionMatch(watchLocalValues("position"));
  const onSubmit: SubmitHandler<FormProps> = (data) => {
    // @ts-ignore
    Object.keys(data).forEach((k) => setValue(k, data[k]));
    handleCreateProfile(getValues());
  };
  async function onAddCompanyPosition(position: string) {
    try {
      await addCompanyPosition({ position });
    } catch (error) {
      Failure("Something went wrong, please try again later");
    }
  }

  return (
    <Box paddingPreset="card" as={motion.div} variants={ProfileCreationTransitions} exit="exit" initial="hidden" animate="visible">
      <OnboardingStepCounter />
      <form onSubmit={handleSubmit(onSubmit)} id="basicInfo" className="my-8">
        <Typography variant="h5" textAlign="center">
          Tell us more details about your job
        </Typography>
        <div className="grid grid-cols-1 gap-6 mt-6 lg:grid-cols-2">
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="position">
              Your Position
            </Typography>

            <Controller
              rules={{ required: "Required field" }}
              name="position"
              control={control}
              render={({ field: { onChange, ref, value } }) => {
                return (
                  <>
                    <AutoCompleteInput
                      hideEmptyState
                      ref={ref}
                      disableCustomValueAddition
                      onChange={(v) => {
                        onChange(v);
                      }}
                      onCustomValueSelection={onAddCompanyPosition}
                      value={value}
                      suggestions={positions}
                    />
                    {errors.position && (
                      <Typography className="mt-1" variant="caption" color="warning">
                        {errors.position.message}
                      </Typography>
                    )}
                  </>
                );
              }}
            />
          </div>
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="managmentLevel">
              Management level
            </Typography>
            <Controller
              rules={{ required: "Required field" }}
              name="management_level"
              control={control}
              render={({ field: { onChange, ref, value }, fieldState: { error } }) => {
                const v = company?.management_levels.find((o) => o === value);

                return (
                  <>
                    <KnackSelect
                      isSearchable
                      ref={ref}
                      onChange={(o: any) => onChange(o.value)}
                      value={{ label: v, value: v }}
                      id="managmentLevel"
                      options={company?.management_levels?.map((l) => ({
                        value: l,
                        label: l
                      }))}
                    />
                    {error?.message && (
                      <Typography fontWeight="normal" className="mt-2" variant="caption" color="warning">
                        {error.message}
                      </Typography>
                    )}
                  </>
                );
              }}
            />
          </div>
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="jobFunction">
              Job function
            </Typography>
            <Controller
              rules={{ required: "Required field" }}
              name="job_function"
              control={control}
              render={({ field: { onChange, ref, value }, fieldState: { error } }) => {
                const v = company?.departments.find((o) => o === value);
                return (
                  <>
                    <KnackSelect
                      isSearchable
                      ref={ref}
                      id="jobFunction"
                      onChange={(o: any) => onChange(o.value)}
                      value={{ label: v, value: v }}
                      options={
                        company?.departments
                          ? [...company?.departments]?.map((d) => ({
                              value: d,
                              label: d
                            }))
                          : []
                      }
                    />
                    {error?.message && (
                      <Typography fontWeight="normal" className="mt-2" variant="caption" color="warning">
                        {error.message}
                      </Typography>
                    )}
                  </>
                );
              }}
            />
          </div>
          <div>
            <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="entity">
              Entity
            </Typography>
            <Controller
              rules={{ required: "Required field" }}
              name="entity"
              control={control}
              render={({ field: { onChange, ref, value }, fieldState: { error } }) => {
                const v = company?.entities.find((o) => o === value);
                return (
                  <>
                    <KnackSelect
                      isSearchable
                      ref={ref}
                      id="entity"
                      onChange={(o: any) => onChange(o.value)}
                      value={{ label: v, value: v }}
                      options={
                        company?.entities
                          ? [...company?.entities]?.map((d) => ({
                              value: d,
                              label: d
                            }))
                          : []
                      }
                    />
                    {error?.message && (
                      <Typography fontWeight="normal" className="mt-2" variant="caption" color="warning">
                        {error.message}
                      </Typography>
                    )}
                  </>
                );
              }}
            />
          </div>
        </div>
      </form>
      <Stack gap={4} justifyContent="flex-end">
        <Button onClick={handlePreviousStep} kind="ghost">
          Back
        </Button>
        <Button type="submit" form="basicInfo">
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

export default ProfileCompanyInfo;
