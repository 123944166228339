import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CardPaper } from "../../../components/Cards/CardPaper";
import { PopperWrapper } from "../../../components/Popper/PopperWrapper";
import { CoachServices } from "../../../services/dashboard/coachDashboard.services";
import { EarningChart } from "./Components/EarningChart";
import { LoadMore } from "../../../components/LazyLoading/LoadMore";
import { useUser } from "../../../features/User/userQueries";
import { useGetConnectionSessions } from "../../../features/Sessions/sessionsQueries";
import { completionStatusesColor } from "../../../App.constant";
import { useGetConnections } from "../../../features/Connections/connectionsQueries";
import { useGetEarningMetrics, useGetEarnings } from "../../../features/Earning/earningQueries";

export const CoachHome = () => {
  const { data: connections } = useGetConnections(
    { search: "" },
    {
      suspense: true
    }
  );
  const { data: earnings } = useGetEarnings();
  const { data: earningMetrics } = useGetEarningMetrics();

  const [displayLimit, setDisplayLimit] = useState(10);
  const { data: user } = useUser();
  const { data: sessions } = useGetConnectionSessions({
    expertId: user._id
  });

  useEffect(() => {
    getCoachingSatisfactionScore_1_1();
    getCoachingSatisfactionScore_team();
  }, []);

  const metrics = useMemo(() => {
    let clientsCount = 0;
    let totalEarnings = "$0";
    let totalSessions = 0;
    if (earningMetrics) {
      totalEarnings = "$" + (Number(earningMetrics.paid) + Number(earningMetrics.unPaid));
    }

    if (connections) {
      clientsCount = connections.coachesOrCoachesCount;
    }

    if (sessions) {
      totalSessions = sessions.length;
    }

    const metrics = [
      {
        title: "Total Clients",
        value: clientsCount,
        icon: ""
      },
      {
        title: "Total Coaching Sessions",
        value: totalSessions,
        icon: ""
      },
      {
        title: "1:1 Sessions Satisfaction Score",
        value: "0%",
        hasPrompt: true,
        prompt: (
          <div className="p-4 border shadow rounded-lg">
            <label className="mb-4 block">
              <h1 className="text-xs font-bold">What is 1:1 Session Satisfaction Score?</h1>
              <p className="text-xs font-extralight " style={{ lineHeight: "inherit" }}>
                Session satisfaction score is a key indicator that tracks how satisfied your clients are with the session outcomes.
              </p>
            </label>

            <label className="mb-4 block">
              <h1 className="text-xs font-bold">How is Session Satisfaction Score calculated?</h1>
              <p className="text-xs font-extralight " style={{ lineHeight: "inherit" }}>
                Sessions are rated on a 1-5 scale based on 3 items:
              </p>
              <ul className="text-xs font-extralight list-disc pl-8 " style={{ lineHeight: "inherit" }}>
                <li>Whether they felt heard, understood and respected</li>
                <li>Whether the session was a valuable use of their time</li>
                <li>Whether they gained useful insight or takeaway from the session</li>
                <li>Whether they will put what they have discovered during the session into practice</li>
              </ul>
            </label>

            <label className="mb-4 text-red-500 block">
              <p className="text-xs font-extralight">
                *Note: Clients have the option to keep their ratings private, in which case, this score won't reflect their private ratings.
              </p>
            </label>
          </div>
        ),
        icon: ""
      },
      {
        title: "Team Session Satisfaction  Score",
        value: "0%",
        hasPrompt: true,
        prompt: (
          <div className="p-4 border block shadow rounded-lg">
            <label className="mb-4 block">
              <h1 className="text-xs font-bold">What is Team Session Satisfaction Score?</h1>
              <p className="text-xs font-extralight" style={{ lineHeight: "inherit" }}>
                Session satisfaction score is a key indicator that tracks how satisfied your team clients are with the session outcomes.
              </p>
            </label>
            <label className="mb-4 block">
              <h1 className="text-xs font-bold">How is Session Satisfaction Score calculated?</h1>
              <p className="text-xs font-extralight">Sessions are rated on a 1-5 scale based on 3 items:</p>
              <ul className="text-xs font-extralight list-disc pl-8" style={{ lineHeight: "inherit" }}>
                <li>Whether they felt empowered to participate during the session</li>
                <li>Whether the session was well organized and facilitated</li>
                <li>Whether they gained useful insight or takeaway from the session</li>
                <li>Whether they will put what they have discovered during the session into practice</li>
              </ul>
            </label>
          </div>
        ),
        icon: ""
      },
      {
        title: "Total Earning",
        value: totalEarnings,
        icon: ""
      }
    ];

    return metrics;
  }, [connections, earningMetrics, sessions]);

  function getCoachingSatisfactionScore_1_1() {
    CoachServices.getCoachingSatisfactionScore("1:1")
      .then((res) => {
        if (res?.data?.status) {
          const { data } = res.data;
          const _metrics = [...metrics];
          const percentages = [...data.rating_question.buckets].map((p) => p.score_percentage.value);
          _metrics[2].value = Math.round(computeAverage(percentages)) + "%";
        }
      })
      .catch((e) => console.log(e));
  }

  function getCoachingSatisfactionScore_team() {
    CoachServices.getCoachingSatisfactionScore("team")
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          const _metrics = [...metrics];
          const percentages = [...data.rating_question.buckets].map((p) => p.score_percentage.value);
          _metrics[3].value = Math.round(computeAverage(percentages)) + "%";
        }
      })
      .catch((e) => console.log(e));
  }

  const computeAverage = (array) => array.reduce((p, c) => p + c, 0) / array.length || 0;

  return (
    <div className="">
      {/* Metrics */}
      <div className="grid grid-cols-1 sm:grid-cols-5 gap-4">
        {metrics?.map((m, i) => (
          <div key={i} className="bg-white p-4 shadow-sm rounded-2xl bg-white dark:bg-gray-800 border-gray-100 border-2">
            <div className="text-4xl sm:text-2xl lg:text-2xl  xl:text-4xl font-bold text-skin-base">{m.value}</div>
            <div className="text-md sm:text-sm lg:text-md font-extralight mt-1 flex flex-row justify-between items-center sm:items-end relative">
              <span>{m.title}</span>
              {m.hasPrompt && (
                <PopperWrapper
                  trigger={
                    <span className="cursor-pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 " viewBox="0 0 20 20" fill="gray">
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  }
                  popComponent={m.prompt}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="py-4 grid grid-cols-1 sm:grid-cols-5 gap-4">
        {/* Earnings Chart */}
        <div className="max-h-full sm:col-span-3 h-full">
          <CardPaper title="Earning" subTitle="Session earnings to-date in Dollars ($)">
            <div style={{ minHeight: 250 }} className="block sm:h-96">
              <EarningChart stateData={earnings} />
            </div>
          </CardPaper>
        </div>

        {/* Recent Sessions */}
        <div className="max-h-full h-full sm:col-span-2">
          <CardPaper title="Recent Sessions" subTitle="Top 5 recently completed sessions">
            <div style={{ minHeight: 250 }} className="block sm:h-96 overflow-y-auto scrollhost">
              {/* Header */}
              <div className="w-full grid grid-cols-1 sm:grid-cols-4 gap-4 font-bold hidden xl:grid py-2 text-sm border-b">
                {/* Employee */}
                <div className="flex items-start">
                  <span>Client</span>
                </div>
                {/* Time (hrs) */}
                <div className="flex items-center">
                  <span>Type</span>
                </div>
                {/*Date */}
                <div className="flex items-start">
                  <span>Date</span>
                </div>
                {/* Status */}
                <div className="flex items-center">
                  <span>Status</span>
                </div>
              </div>
              {/* Content */}
              <div className="evenOddBorder">
                {sessions.slice(0, displayLimit).map((t, i) => (
                  <Link
                    to={{
                      pathname: "/clients/" + t.employee
                    }}
                    key={i}
                    className="w-full block"
                  >
                    <div className="flex items-center justify-between py-2 hover:bg-gray-200 cursor-pointer">
                      <div
                        key={i}
                        className="w-full grid grid-cols-1 xl:grid-cols-4 gap-4 py-2 text-xs font-semibold xl:font-normal leading-normal"
                      >
                        <div className="xl:flex flex-row">
                          <div className="flex flex-row justify-between w-full">
                            <div className="flex flex-col">
                              <span className="text-sm sm:text-xs">
                                {t?.user?.first_name} {t?.user?.last_name}
                              </span>{" "}
                              {/* Employee */}
                              <span className="xl:hidden">
                                {new Date(t.start_time)?.toLocaleString("en-US", {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric"
                                })}
                              </span>{" "}
                              {/* Date */}
                              <span className="xl:hidden">{t.type}</span> {/*  Type */}
                            </div>
                            <span
                              className={`block xl:hidden ${
                                completionStatusesColor[t?.completion_status]
                              } text-sm font-semibold px-2 py-1 rounded-lg text-center fit-w-h`}
                            >
                              {t?.completion_status}
                            </span>
                          </div>
                        </div>

                        <div className="xl:flex flex-row hidden">
                          <span>{t.type}</span> {/* Type */}
                        </div>
                        {/*  Date */}
                        <div className="xl:flex flex-row hidden">
                          {
                            <span>
                              {new Date(t.start_time).toLocaleString("en-US", {
                                month: "short",
                                day: "2-digit",
                                year: "numeric"
                              })}
                            </span>
                          }
                        </div>

                        {/* Status */}
                        <span
                          className={`hidden xl:block ${
                            completionStatusesColor[t?.completion_status]
                          } text-sm font-semibold px-2 py-1 rounded-lg text-center fit-w-h`}
                        >
                          {t?.completion_status}
                        </span>
                      </div>

                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#ddd">
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              <LoadMore onLoad={() => setDisplayLimit(displayLimit + 5)} showHide={sessions.length > displayLimit} />
            </div>
          </CardPaper>
        </div>
      </div>
    </div>
  );
};
