import create from "zustand";

type IProps = {
  isImpersonating: boolean;
  setImpersonation: (state: boolean) => void;
};

export const useImpersonation = create<IProps>((set) => ({
  isImpersonating: localStorage.getItem("temp-user") ? true : false,
  setImpersonation: (state: boolean) => set(() => ({ isImpersonating: state }))
}));
