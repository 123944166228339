import {
  createColumnHelper,
  flexRender,
  Table as TTable,
  getCoreRowModel,
  useReactTable,
  ColumnHelper,
  ColumnDef,
  getSortedRowModel,
  SortingState,
  RowSelectionState,
  OnChangeFn,
  PaginationState
} from "@tanstack/react-table";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import styled from "styled-components";
import SVG from "../SVG/SVG";
import TableCell from "./TableCell";
import TablePagination from "./TablePagination";
import { TableRow } from "./TableRow";

interface TableProps<T> {
  data: T[];
  columns: (columnHelper: ColumnHelper<T>) => ColumnDef<T, any>[];
  paginationProps?: {
    onNextPage: (newPage: number) => void;
    onPreviousPage: (newPage: number) => void;
    totalPages: number;
    canGetNextPage?: boolean;
  };
  getSelectedRows?: (event: any) => void;
  initialSorting?: SortingState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  rowSelection?: RowSelectionState;
  sorting?: SortingState;
  pagination?: PaginationState;
  setPagination?: OnChangeFn<PaginationState>;
  setSorting?: Dispatch<SetStateAction<SortingState>>;
  manualPagination?: boolean;
}

function Table<T>({
  data,
  columns,
  paginationProps,
  setSorting,
  sorting,
  pagination,
  setPagination,
  setRowSelection,
  manualPagination = true,
  rowSelection
}: TableProps<T>) {
  const columnHelper = createColumnHelper<T>();

  const state: any = {
    sorting,
    rowSelection,
    pagination
  };

  //Remove fields with null values
  Object.keys(state).forEach((key) => {
    if (state[key] === undefined) {
      delete state[key];
    }
  });
  const table = useReactTable({
    data,
    state,
    manualPagination,
    onPaginationChange: setPagination,
    pageCount: paginationProps?.totalPages,
    onSortingChange: setSorting,
    columns: columns(columnHelper),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection
  });

  const tableDataRows = useCallback(
    (table: TTable<T>) => {
      if (pagination && pagination.pageIndex && pagination.pageSize) {
        return table
          .getRowModel()
          .rows.slice(
            table.getState().pagination.pageIndex * table.getState().pagination.pageSize,
            (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize
          );
      }
      return table.getRowModel().rows;
    },
    [pagination]
  );
  return (
    <>
      <TableWrapper>
        <TableEl>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    as="th"
                    {...{
                      key: header.id,
                      style: {
                        // width: header.getSize()
                      }
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? "cursor-pointer select-none flex items-center" : "",
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <SVG name="ArrowUp" />,
                          desc: <SVG name="ArrowDown" />
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </thead>
          <tbody>
            {tableDataRows(table).map((row) => (
              <TableRow key={row.id}>
                {row
                  .getVisibleCells()

                  .map(({ row, column, id, getContext }) => (
                    <TableCell
                      {...{
                        key: id,
                        style: {
                          // width: column.getSize()
                        },
                        className: `${row.getIsSelected() ? "bg-skin-light" : ""}`
                      }}
                    >
                      {flexRender(column.columnDef.cell, getContext())}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </tbody>

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                ))}
              </tr>
            ))}
          </tfoot>
        </TableEl>
      </TableWrapper>
      {typeof paginationProps !== "undefined" && (
        <TablePagination
          canGetNextPage={table.getCanNextPage()}
          currentPage={table.getState().pagination.pageIndex + 1}
          totalPages={table.getPageCount()}
          onNextPage={(newPage) => {
            if (table.getCanNextPage()) {
              table.nextPage();
              paginationProps.onNextPage(newPage);
            }
          }}
          onPreviousPage={(newPage) => {
            if (table.getCanPreviousPage()) {
              console.log("Previous page called");
              paginationProps.onPreviousPage(newPage);
              table.previousPage();
            }
          }}
        />
      )}
    </>
  );
}

export default Table;

const TableWrapper = styled.div`
  overflow-x: auto;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TableEl = styled.table`
  width: 100%;
  border-radius: 12px;
  isolation: isolate;
  border: 1px solid var(--color-border);
  border-collapse: collapse;
`;
