import { Link } from "react-router-dom";
import { SquareImage } from "../../../../../components/Image/SquareImage";
import { ParsedHTML } from "../../../../../components/Parser/ParsedHTML";

export const ProgramCard = ({ program, route, index }) => {
  return (
    <div className="h-full">
      {
        <div className="flex bg-white rounded-lg overflow-hidden shadow bg-white relative h-full">
          <Link to={route || "#"} className="w-full h-full">
            <div className="cursor-pointer w-full h-full hover:bg-skin-light h-full">
              {/* Image */}
              <div>
                <SquareImage image_url={program?.image_url} index={index} />
              </div>
              {/* Progran type */}
              <div className="flex flex-row my-4 px-2">
                <p
                  className={`px-2 py-1 text-xs rounded-full font-medium text-white bg-skin-accent`}
                  style={{ width: "fit-content" }}
                >
                  {program?.tag
                    ? program?.tag
                    : program?.program_type === "Coaching"
                    ? program?.plan === "1:1"
                      ? "One-on-One Coaching"
                      : "Group Coaching"
                    : program?.tag
                    ? program?.tag
                    : program?.type === "1:1"
                    ? "One-on-One Mentoring"
                    : program?.type}
                </p>
              </div>
              {/* Program Name */}
              <div className="px-2 mb-4">
                <h1 className="text-gray-800 dark:text-white text-base font-bold  mb-3 text-skin-base clampText">
                  {" "}
                  {program?.name}
                </h1>
                <div className="text-sm text-gray-600 dark:text-gray-300 font-reguler mt-2 restrict-grid-text text-skin-base clampText">
                  <ParsedHTML htmlString={program?.overview} />{" "}
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
    </div>
  );
};
