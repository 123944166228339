import { Button, Input, Typography } from "knack-ui";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { useUpdateProfile } from "../../features/User/userMutations";
import { useGetUserUtilization, userQueryKeys, useUser, useUserCompany } from "../../features/User/userQueries";
import { Failure } from "../ToastNotification/Failure";
import { Success } from "../ToastNotification/Success";

interface ICapacityForm {
  capacity: number;
}

interface IProps {
  onSuccess?: VoidFunction;
}

const CapacityController = ({ onSuccess }: IProps) => {
  const { data: user } = useUser();
  const { data: company } = useUserCompany();
  const { data: utilizationData } = useGetUserUtilization({ userId: user?._id as string });
  const queryClient = useQueryClient();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const utilization = useMemo(() => {
    const util = { connections: 0, capacity: 0 };
    if (utilizationData) {
      util.connections = utilizationData.connections as unknown as number;
      util.capacity = utilizationData.capacity as number;
    }
    return util;
  }, [utilizationData]);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<ICapacityForm>({
    defaultValues: {
      capacity: (utilization.capacity as number) || 0
    }
  });
  const onSubmit: SubmitHandler<ICapacityForm> = async (data) => {
    try {
      await updateProfile(data);
      Success("Capacity updated successfully!");
      queryClient.invalidateQueries(userQueryKeys.all);
      onSuccess?.();
    } catch (error) {
      Failure("Oh no! Something went wrong");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <CapacityInput
          // size="large"
          min={utilization.connections}
          max={company?.maximumAllowedCapacity}
          required
          {...register("capacity", {
            required: true,
            validate: {
              greatThan0: (value) => {
                if (utilization.connections === 0) {
                  return value > 0;
                } else {
                  return value >= utilization.connections;
                }
              },
              maximum: (value) => {
                if (company?.maximumAllowedCapacity) {
                  return value <= company?.maximumAllowedCapacity;
                }
              }
            }
          })}
          id="capacity"
          type="number"
          onKeyDown={(evt) => ["e", "-", "."].includes(evt.key) && evt.preventDefault()}
          error={
            errors.capacity?.type === "greatThan0"
              ? `Your capacity must be greater than ${utilization.connections}`
              : errors.capacity?.type === "maximum"
              ? `Your capacity must be lower than ${company?.maximumAllowedCapacity}`
              : undefined
          }
        />

        <Typography>Persons {company?.maximumAllowedCapacity && `(${company.maximumAllowedCapacity} maximum).`}</Typography>
      </Wrapper>

      <Button className="mt-8" type="submit">
        Save
      </Button>
    </form>
  );
};

export default CapacityController;

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const CapacityInput = styled(Input)`
  width: 75px;
  & input {
    text-align: center;
    font-size: ${18 / 16}rem;
    font-weight: 700;
  }
`;
