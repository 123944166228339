import { addMonths, format } from "date-fns/esm";
import { Avatar, Box, Breadcrumbs, Button, Popover, Stack, Typography } from "knack-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EmptyState from "../../../components/EmptyState/EmptyState";
import FiltersBar from "../../../components/FiltersBar";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";
import Table from "../../../components/Table";
import { useSessionsFilters } from "../hooks/SessionReports.hooks";
import {
  useGetAnalyticsSessionList,
  useGetAnalyticsSessionsSatisfactionScores,
  useGetAnalyticsSessionsStatuses,
  useGetAnalyticsSessionsTimelines,
  useGetAnalyticsTopTopics
} from "../analyticsReportQueries";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import MetricCard from "../../../components/Metrics/MetricCard";
import Chart from "../../../components/Charts/Chart";
import { range, resizeObserver } from "../../../lib/utils";
import { KnackSpinner } from "../../../components/Asset/KnackSpinner";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { DURATIONS } from "../../../lib/constants";
import styled from "styled-components";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { useGenerateSessionCSVReport } from "../analyticsReportMutations";
import { Information } from "../../../components/ToastNotification/Information";
import AttributeSelector from "../../../components/Selector/AttributeSelector";
import { ISessionAnalytics, SessionsReportsQuery } from "../analyticsReportsTypes";
import { useUser } from "../../User/userQueries";
import { useExportChart } from "../chartExportMutations";
import { TChartType } from "../../../lib/interfaces/generalTypes";

let date = new Date();

const SessionsReportsPage = () => {
  const { data: user } = useUser();
  const [sorting, setSorting] = useState<SortingState>([{ desc: true, id: "start_time" }]);

  const [query, setQuery] = useState<SessionsReportsQuery>({
    ...(user?.account_type === "Admin" ? { organization: [] } : []),
    program: [],
    user: [],
    expert: [],
    status: ["Cancelled", "Completed", "No Show", "Pending"],
    department: [],
    entities: [],
    management_level: [],
    // date here is here to be compatible with range library.. will add startDate & endDate to the query itself which
    // are extracted from this `date` prop
    date: [
      {
        // startDate: date,
        endDate: addMonths(date, 7),
        key: "selection"
      }
    ]
  });
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const {
    data: sessions,
    hasNextPage,
    fetchNextPage,
    isFetching,
    fetchPreviousPage,
    isFetchingNextPage
  } = useGetAnalyticsSessionList(
    {
      query,
      order: sorting.length > 0 ? (sorting[0].desc ? "desc" : "asc") : "desc"
    },
    { suspense: true, keepPreviousData: true, staleTime: DURATIONS.twoMins }
  );

  const { data: topTopics } = useGetAnalyticsTopTopics(query, {
    keepPreviousData: true
  });

  const { data: sessionsStatuses } = useGetAnalyticsSessionsStatuses(query, {
    suspense: true,
    keepPreviousData: true
  });

  const { data: timelines } = useGetAnalyticsSessionsTimelines(query, {
    suspense: true,
    keepPreviousData: true
  });

  const { data: satisfactionScores } = useGetAnalyticsSessionsSatisfactionScores(query, {
    suspense: true,
    keepPreviousData: true
  });

  const { mutateAsync: onExportChart } = useExportChart();

  const exportChart = async (chartType: TChartType) => {
    await onExportChart(chartType);
    Information("An email with chart export will be sent to your email");
  };

  const filters = useSessionsFilters({
    query,
    onQueryChange: setQuery
  });

  const topTopic = useMemo(() => {
    if (!topTopics) return "";
    let topic: { topic: string; count: number } = { count: 0, topic: "" };
    topTopics.topTopics.forEach((t) => {
      if (!topic) topic = t;
      if (t.count > topic.count) {
        topic = t;
      }
    });
    return topic.topic;
  }, [topTopics]);

  const sessionsStatusesChartOptions = useMemo(() => {
    if (!sessionsStatuses) return null;
    return {
      tooltip: {
        trigger: "item"
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        left: "left"
      },

      series: [
        {
          type: "pie",
          itemStyle: {
            borderRadius: 8,
            borderColor: "#fff",
            borderWidth: 2
          },
          // radius: "50%",
          radius: ["40%", "60%"],
          data: Object.entries(sessionsStatuses.statusCount).map(([key, value]) => ({
            value,
            name: key
          }))
        }
      ],
      color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"]
    };
  }, [sessionsStatuses]);

  const sessionsTimelineChartOptions = useMemo(() => {
    if (!timelines) return null;
    const data = timelines.timeline.map((t) => [t._id, t.count]);

    return {
      tooltip: {
        trigger: "axis"
      },
      xAxis: {
        type: "category"
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, "100%"]
      },
      dataZoom: [
        {
          type: "inside",
          start: 50,
          end: 100
        },
        { type: "slider", start: 50, end: 100 }
      ],
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },

      series: [
        {
          name: "Completed sessions",
          data: data,
          type: "line",
          smooth: true
        }
      ]
    };
  }, [timelines]);

  const averageSatisfactionScore = useMemo(() => {
    if (!satisfactionScores) return 0;
    if (!satisfactionScores.satisfactionScore.length) return 0;
    const sum = satisfactionScores.satisfactionScore.reduce((prev, curr) => prev + curr.avgScore, 0);
    return (sum / satisfactionScores.satisfactionScore.length).toFixed(1);
  }, [satisfactionScores]);

  /** Reset page index to 0 whenever we change the query to support fresh queries */
  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
  }, [filters, pageSize]);

  useEffect(() => {
    if (sessions && !sessions.pages[pageIndex] && !isFetchingNextPage) {
      setPagination({ pageIndex: 0, pageSize });
    }
  }, [isFetchingNextPage, pageIndex, pageSize, sessions]);

  const [exportAttributes, setExportAttribute] = useState<Array<keyof ISessionAnalytics>>();
  const { mutateAsync: generateReport } = useGenerateSessionCSVReport();

  const popoverRefEl = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const { setLoading } = useLoadingSpinner();

  async function onExportCSV(exportAttributes: Array<keyof ISessionAnalytics>) {
    setLoading(true);
    try {
      console.log(query);
      await generateReport({ exportAttributes, query: query });
      setMenuOpen(false);
      setLoading(false);
      Information("A CSV report has been sent to your email");
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Sessions report</Typography>]}</Breadcrumbs>
      </Box>

      <Box variant="outlined" className="grid grid-cols-1 gap-6 px-6 py-4 ">
        <div className="pb-6 border-b">
          <Typography fontWeight="semibold" className="mb-4">
            Filters
          </Typography>
          <FiltersBar query={query} filters={filters} />
        </div>
        <div>
          <Typography className="mb-4" fontWeight="semibold">
            Summary
          </Typography>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <MetricCard icon="Sessions" title="Scheduled Sessions" value={sessionsStatuses?.statusCount.Pending || "-"} />
            <MetricCard
              color="bg-success text-successDark"
              icon="Sessions"
              title="Completed Sessions"
              value={sessionsStatuses?.statusCount.Completed || "-"}
            />
            <MetricCard color="bg-tertiary text-tertiary" icon="Topic" title="Top discussed topic" value={topTopic || "-"} />
            <MetricCard
              color="bg-blue-400 text-white"
              icon="Smile"
              title="Overall satisfaction"
              value={`${averageSatisfactionScore} of out 5`}
            />
          </div>
        </div>
        <ChartsGrid>
          {isFetching && (
            <div className="absolute inset-0 z-10 flex flex-col justify-center">
              <KnackSpinner />{" "}
            </div>
          )}
          <TitledDisplayBox title="Satisfaction score" subtitle={`How satisfied people are from the sessions`}>
            {satisfactionScores?.satisfactionScore.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Smile" />}
            {satisfactionScores && satisfactionScores.satisfactionScore.length > 0 && (
              <div style={{ gridTemplateColumns: "1fr 0.4fr" }} className="grid h-full gap-4">
                <div className="relative">
                  <div style={{ paddingBottom: 41 }} className="grid grid-cols-1 gap-4">
                    {satisfactionScores?.satisfactionScore.map((c, i) => (
                      <LinearProgressWithLabel
                        barInnerText={c.avgScore?.toFixed(2)}
                        label={c._id}
                        percentage={(c.avgScore / 5) * 100}
                        key={i}
                      />
                    ))}
                  </div>
                  <div className="sticky bottom-0 z-10 flex justify-between pt-4 border-t bg-paper">
                    {range(1, 5).map((i) => (
                      <Typography fontWeight="bold" variant="subtitle2" key={i}>
                        {i}
                      </Typography>
                    ))}
                  </div>
                </div>
                <div style={{ top: "25%" }} className="relative self-start">
                  <Typography variant="body1" textAlign="center" className="mb-4" fontWeight="semibold">
                    Average satisfaction score
                  </Typography>
                  <Typography variant="h4" textAlign="center" color="primary">
                    {averageSatisfactionScore}{" "}
                    <Typography variant="subtitle2" as="span" color="muted">
                      out of 5
                    </Typography>
                  </Typography>
                </div>
              </div>
            )}
          </TitledDisplayBox>
          <TitledDisplayBox
            title="Sessions top topics"
            subtitle={`What people are discussing during sessions`}
            onExport={() => exportChart("SESSION_TOP_TOPICS")}
          >
            {topTopics?.topTopics.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Topic" />}
            {topTopics && topTopics.topTopics.length > 0 && (
              <div className="grid grid-cols-1 gap-4">
                {topTopics?.topTopics.map((c, i) => (
                  <LinearProgressWithLabel
                    label={c.topic}
                    color="dynamic"
                    percentage={Number(c.count).toFixed(2) as unknown as number}
                    key={i}
                  />
                ))}
              </div>
            )}
          </TitledDisplayBox>
          <TitledDisplayBox
            title="Sessions timeline"
            subtitle={`Completed sessions over time`}
            onExport={() => exportChart("SESSION_TIMELINE")}
          >
            {timelines?.timeline?.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Sessions" />}
            {timelines && timelines?.timeline.length > 0 && (
              <Chart options={sessionsTimelineChartOptions as any} resizeObserver={resizeObserver} />
            )}
          </TitledDisplayBox>

          <TitledDisplayBox
            title="Sessions statuses"
            subtitle={`Total sessions: ${sessions?.pages[0].totalRecords}`}
            onExport={() => exportChart("SESSION_STATUSES")}
          >
            {sessions?.pages[0]?.sessions?.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Sessions" />}
            {sessions && sessions?.pages[0]?.sessions?.length > 0 && (
              <Chart options={sessionsStatusesChartOptions as any} resizeObserver={resizeObserver} />
            )}
          </TitledDisplayBox>
        </ChartsGrid>
        <div>
          <Stack justifyContent="space-between" alignItems="center" className="mb-4">
            <Typography fontWeight="semibold">Sessions</Typography>
            <Stack gap={4} direction="row" alignItems="center">
              {/* <Input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="w-72"
                inputPrefix={<AiOutlineSearch size={20} />}
                inputSuffix={
                  searchValue !== "" ? (
                    <Button onClick={() => setSearchValue("")} iconOnly kind="ghost" variant="small">
                      <SVG name="Close" />
                    </Button>
                  ) : (
                    <></>
                  )
                }
                placeholder="Search by name or email"
              /> */}
              <Button ref={popoverRefEl} onClick={() => setMenuOpen(true)}>
                Export
              </Button>
            </Stack>
          </Stack>

          <div className="">
            {sessions?.pages[0]?.sessions?.length === 0 && <EmptyState iconSize={70} title="No sessions found" icon="SessionsEmptyState" />}
            {isFetchingNextPage && <KnackSpinner />}
            {sessions && sessions?.pages[0]?.sessions?.length > 0 && (
              <Table
                initialSorting={sorting}
                sorting={sorting}
                setSorting={setSorting}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                paginationProps={{
                  totalPages: sessions.pages[pageIndex]?.totalPages,
                  canGetNextPage: hasNextPage,
                  onNextPage: async (newPage) => {
                    if (!sessions.pages[newPage - 1]) {
                      console.log("fetching next page SSR");
                      fetchNextPage();
                    }
                  },
                  onPreviousPage: async (newPage) => {
                    if (!sessions.pages[newPage - 1]) {
                      console.log("fetching previous page SSR");
                      fetchPreviousPage();
                    }
                  }
                }}
                data={sessions.pages.flatMap((p) => p.sessions)}
                columns={(helper) => [
                  helper.accessor("program_name", {
                    header: "Program name",
                    enableSorting: false
                  }),
                  helper.accessor((row) => `${row.expert_first_name} ${row.expert_last_name}`, {
                    header: "Expert",
                    sortingFn: "text",
                    enableSorting: false,
                    cell: ({ row, getValue }) => {
                      const name = getValue();
                      return (
                        <div className="flex items-center gap-2">
                          <Avatar className="flex-shrink-0" text={name} image={row.original.expert_profile_image} />
                          {name}
                        </div>
                      );
                    }
                  }),
                  helper.accessor((row) => `${row.user_first_name} ${row.user_last_name}`, {
                    header: "User",
                    enableSorting: false,
                    cell: ({ row, getValue }) => {
                      const name = getValue();
                      return (
                        <div className="flex items-center gap-2">
                          <Avatar className="flex-shrink-0" text={name} image={row.original.user_profile_image} />
                          {name}
                        </div>
                      );
                    }
                  }),
                  helper.accessor("start_time", {
                    header: "Date",
                    cell: (props) => (props.getValue() ? format(new Date(props.getValue()), "MMM dd yyyy - hh:mm a") : "-")
                  }),
                  ...(user?.account_type === "Admin"
                    ? [
                        helper.accessor((row) => row.organization_name, {
                          header: "Organization",
                          enableSorting: false,
                          cell: ({ row, getValue }) => {
                            const name = getValue();
                            return (
                              <div className="flex items-center gap-2">
                                <Avatar className="flex-shrink-0" text={name} image={row.original.organization_logo || ""} />
                                {name}
                              </div>
                            );
                          }
                        })
                      ]
                    : []),
                  helper.accessor("completion_status", {
                    header: "Session status",
                    enableSorting: false,
                    cell: (props) => {
                      const status = props.getValue();
                      return (
                        <div className="flex justify-center">
                          <div
                            className={`${
                              status === "Completed" ? "bg-success text-success" : "bg-warning text-warning"
                            } bg-opacity-20 rounded px-2 py-1 text-xs`}
                          >
                            {status}
                          </div>
                        </div>
                      );
                    }
                  })
                ]}
              />
            )}
          </div>
        </div>
      </Box>

      <Popover
        animationType="fade"
        parentRef={popoverRefEl}
        offset={{ bottom: 10, left: 0 }}
        popoverProps={{
          // style: { maxWidth: 500 },
          variant: "elevated",
          elevation: 6
        }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <AttributeSelector<ISessionAnalytics>
          attributeList={[
            {
              accessorKey: "program_name",
              label: "Program"
            },
            {
              accessorKey: "expert_email",
              label: "Expert email"
            },
            {
              accessorKey: "expert_first_name",
              label: "Expert first name"
            },
            {
              accessorKey: "expert_last_name",
              label: "Expert last name"
            },
            {
              accessorKey: "expert_department",
              label: "Expert department"
            },
            {
              accessorKey: "expert_entity",
              label: "Expert entity"
            },
            {
              accessorKey: "expert_management_level",
              label: "Expert management level"
            },
            {
              accessorKey: "user_email",
              label: "Mentee / coachee email"
            },
            {
              accessorKey: "user_first_name",
              label: "Mentee / coachee first name"
            },
            {
              accessorKey: "user_last_name",
              label: "Mentee / coachee last name"
            },
            {
              accessorKey: "user_department",
              label: "Mentee / coachee department"
            },
            {
              accessorKey: "user_entity",
              label: "Mentee / coachee entity"
            },
            {
              accessorKey: "user_management_level",
              label: "Mentee / coachee management level"
            },
            {
              accessorKey: "start_time",
              label: "Session date"
            },
            {
              accessorKey: "completion_status",
              label: "Session status"
            },
            {
              accessorKey: "type",
              label: "Session type"
            }
          ]}
          onChange={(attributes) => {
            setExportAttribute(attributes);
          }}
          onExportCSV={onExportCSV}
        />
      </Popover>
    </div>
  );
};

export default SessionsReportsPage;

const ChartsGrid = styled.div`
  display: grid;
  gap: 16px;
  position: relative;
  grid-template-columns: 1fr;
  @media ${(p) => p.theme.queries.tabletAndUp} {
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  }
  @media ${(p) => p.theme.queries.laptopAndUp} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
