import { Textarea } from "knack-ui";
import { ISurveyAnswerComponentProps } from "../../surveyTypes";

const SurveyTextAnswer = ({

  onChange,
  value,
 
}: ISurveyAnswerComponentProps) => {
  return (
    <Textarea
      rows={4}
      onChange={(e) => onChange(e.target.value)}
      value={value}

      // {...register(`answers.${sectionIndex}.${questionIndex}.question_answer`, {
      //   required: question.required
      // })}
    />
  );
};

export default SurveyTextAnswer;
