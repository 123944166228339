import { Button, Drawer, Input, Typography } from "knack-ui";
import { useCallback, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import create from "zustand";
import SVG from "../../../components/SVG/SVG";
import {
  IProgramsResponse,
  useGetProgramInvitations,
  useGetEnrolledPrograms
} from "../../../features/Programs/programQueries";
import { IProgramInvite } from "../../../features/Programs/ProgramTypes";
import { useUserRoles } from "../../../features/User/userQueries";
import { PageTitleHeight } from "../../../lib/constants";
import ProgramInvitationsDrawer from "../../../pages/UserDashboard/ProgramInvitationsDrawer";
import ProgramsList from "./ui/ProgramsList";

export interface ProgramsSearchState {
  search: string;
  setSearch: (value: string) => void;
}

export const ProgramsSearch = create<ProgramsSearchState>((set) => ({
  search: "",
  setSearch: (value: string) => set(() => ({ search: value }))
}));

const Programs = () => {
  const { search, setSearch } = ProgramsSearch();
  const [invitesOpen, setInvitesOpen] = useState(false);

  const { data } = useGetEnrolledPrograms({
    select: useCallback(
      (res: IProgramsResponse) => {
        const all = [...res.coaching, ...res.mentoring];
        return {
          total: res.total,
          all: all.filter(
            (p) => p.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
          ),
          //   all,
          oneToOneCount: all.filter((p) => p.type === "1:1").length,
          groupCount: all.filter((p) => p.type === "group").length,
          coachingCount: res.coaching.length,
          mentoringCount: res.mentoring.length
        };
      },
      [search]
    )
  });
  const { roleState } = useUserRoles();
  const { data: invitations } = useGetProgramInvitations(
    { confirmed: false },
    {
      select: useCallback(
        (data: IProgramInvite[]) => {
          const filtered = data.filter((p) => p.sent === true && p.program);
          let sorted = filtered.sort(
            // @ts-ignore
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          const filterByRole =
            roleState?.activeRole === "User"
              ? sorted.filter(
                  (s) => s.type === "mentee" || s.type === "coachee"
                )
              : sorted.filter((s) => s.type === "mentor" || s.type === "coach");
          return filterByRole;
        },
        [roleState]
      )
    }
  );

  const handleToggleInvitations = () => {
    setInvitesOpen(!invitesOpen);
  };
  return (
    <>
      <div className="relative border rounded-lg bg-paper">
        <div
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            height: PageTitleHeight,
            // backdropFilter: "blur(40px)",
            zIndex: 20
          }}
          className="sticky top-0 flex items-center justify-between px-6 py-4 border bg-paper"
        >
          <div>
            <Typography as="h5" variant="h6">
              Your Programs
            </Typography>
            {data && data.total > 0 && (
              <Typography variant="body2" color="muted">
                {data.oneToOneCount} one-to-one, {data.groupCount} group,{" "}
                {data.mentoringCount} Mentoring, {data.coachingCount} Coaching.
              </Typography>
            )}
          </div>
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="w-72"
            inputPrefix={<AiOutlineSearch size={20} />}
            inputSuffix={
              search !== "" ? (
                <Button
                  onClick={() => setSearch("")}
                  iconOnly
                  kind="ghost"
                  variant="small"
                >
                  <SVG name="Close" />
                </Button>
              ) : (
                <></>
              )
            }
            placeholder="Search for a program..."
          />
        </div>

        {data && (
          <div
            className="p-4 bg-paper"
            style={
              {
                // height: PageHeightWithoutHeader,
                // overflowY: "auto"
              }
            }
          >
            {invitations && invitations?.length > 0 && (
              <Button
                startIcon={<SVG name="ProgramBig" size={35} />}
                onClick={handleToggleInvitations}
                fullWidth
                className="mb-4"
                kind="success"
                variant="large"
              >
                You have +{invitations.length} Program Invitations
              </Button>
            )}
            <ProgramsList programs={data.all} />
          </div>
        )}
      </div>
      <Drawer isOpen={invitesOpen} onClose={handleToggleInvitations}>
        <ProgramInvitationsDrawer invitations={invitations} />
      </Drawer>
    </>
  );
};

export default Programs;
