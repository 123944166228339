import React from "react";
import { RandomImage } from "../Image/RandomImage";
import { AvatarPlaceholderV2 } from "../SVG/AvatarPlaceholderV2";
import StarRating from "react-star-ratings";

export const DefaultProfileCard = ({
  isKnackCoach,
  tagline,
  firstName,
  lastName,
  position,
  email,
  phoneNumber,
  imageUrl,
  rating,
  totalSessions = 0,
  subscription,
  profile_type
}) => {
  return (
    <div>
      <div className="h-32 overflow-hidden bg-white">
        <RandomImage useIndex={true} index={0} />
      </div>

      <div className="-mt-28 bg-white flex flex-col item-start justify-start p-4">
        <AvatarPlaceholderV2
          imageUrl={imageUrl}
          nameFallback={`${firstName} ${lastName}`}
          height="h-36"
          width="w-36"
          classes="mr-2 mr-auto"
        />

        <div className="mt-2">
          {/* Name */}
          <div className="font-bold text-3xl">
            {firstName} {lastName}
          </div>
          {/* Position */}
          <div className="text-base text-skin-muted">{position}</div>
          {/* Contact */}
          {!isKnackCoach && (
            <div className="flex flex-row">
              <div className="text-sm text-skin-muted">{email}</div>
              {phoneNumber && (
                <div className="text-sm text-skin-muted">
                  <span className="mx-2 font-bold">&#183;</span>
                  {phoneNumber}
                </div>
              )}
            </div>
          )}

          <div className="flex flex-row">
            {profile_type === "MentorProfile" && (
              <StarRating
                rating={rating || 0}
                starRatedColor="#f0cd22"
                numberOfStars={5}
                starDimension="20px"
                starSpacing="3px"
              />
            )}
          </div>

          {tagline && <div className="text-base">{tagline}</div>}

          <div className="flex flex-row mt-2">
            {/* Subscription */}
            {subscription && (
              <div className="text-sm text-sm font-bold text-blue-500">
                {subscription}
              </div>
            )}
            {subscription && <span className="mx-2 font-bold">&#183;</span>}
            {/* Total Sessions */}
            {totalSessions > 0 && (
              <div className="text-sm font-bold text-blue-500">
                {totalSessions}{" "}
                {totalSessions > 1 ? "sessions completed" : "session completed"}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
