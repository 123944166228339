import { Button } from "knack-ui";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Information } from "../../../../components/ToastNotification/Information";
import { useExportCompanyData } from "../../../../features/AnalyticsReports/analyticsReportMutations";
import { useLoadingSpinner } from "../../../../hooks/useLoadingSpinner";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";

export const Companies = () => {
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { mutateAsync: exportCompanyData } = useExportCompanyData();
  const { setLoading } = useLoadingSpinner();

  const onExportCompanyData = async (organizationId) => {
    try {
      setLoading(true);
      await exportCompanyData(organizationId);
      setLoading(false);
      Information("Please check your email to download the company's report");
    } catch (error) {
      setLoading(false);
      Information("Error exporting data. Please try again or contact support");
    }
  };

  useEffect(() => {
    AdminServices.getCompanies()
      .then((res) => {
        if (res.data.status) {
          setCompanies(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  }, [setCompanies]);

  return (
    <div className="">
      {/* Create Company */}
      <div className="flex flex-col items-end justify-end">
        <Link
          to={{
            pathname: "create-company",
            state: { title: "Create Company", aliasTitle: "Create Company" }
          }}
          className="px-4 py-2 text-sm text-white rounded-md cursor-pointer"
          style={{ background: "rgb(31, 64, 230)" }}
        >
          {" "}
          Create Company{" "}
        </Link>
      </div>

      {/* Table List */}
      <div className="mt-4 bg-white rounded-lg shadow">
        {/* table header */}
        <div className="grid grid-cols-5 p-4 text-sm font-bold border-b xl:text-base">
          <div>Company</div>
          <div>SSO Integration</div>
          <div>Custom Framework</div>
        </div>

        {companies.map((c, i) => (
          <label
            key={i}
            className="grid grid-cols-5 p-4 cursor-pointer p-4text-sm xl:text-base hover:bg-blue-50"
            onClick={() => {
              history.push({
                pathname: "create-company",
                state: {
                  title: "Update Company",
                  aliasTitle: "Update Company",
                  company: c
                }
              });
            }}
          >
            <div className="flex items-center text-sm">{c?.name}</div>
            <div className="flex flex-row items-center gap-4 text-sm"> {c.enableSingleSignOn ? "True" : "False"} </div>
            <div className="flex flex-row items-center gap-4 text-sm"> {c.custom_framework ? "True" : "False"} </div>
            <div
              className="px-4 py-2 text-xs border rounded-lg hover:bg-white hover:shadow"
              style={{ width: "fit-content" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push({
                  pathname: "assessment-collections",
                  state: { _id: c._id, from: "Companies" }
                });
              }}
            >
              {c.custom_framework ? "Edit Framework" : "Create Framework"}
            </div>

            <Button
              kind="primary"
              style={{ width: "fit-content" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onExportCompanyData(c._id);
              }}
            >
              Download Report
            </Button>
          </label>
        ))}
      </div>
    </div>
  );
};
