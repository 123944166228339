import { BaseDialog, Box, Button, Stack, Typography } from "knack-ui";
import { lazy, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import { useGetGoals } from "../../../features/Goals/goalQueries";
import { PageHeightWithoutHeader, PageTitleHeight } from "../../../lib/constants";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../lib/utils";
import { TGoal } from "../../../features/Goals/goalsTypes";
import { IGoalsListProps } from "./ui/GoalsList";
import GoalsProgress from "./ui/GoalsProgress";
import NewGoalModal from "./ui/NewGoalModal";

const LazyGoalsList = lazy(() => loadComponent(() => import("./ui/GoalsList")));
const GoalsList = withSuspense(withErrorBoundary<IGoalsListProps>(LazyGoalsList), {
  fallBackComponent: <PageSkeleton />
});

const Goals = () => {
  const location = useLocation<{ new?: boolean }>();
  const [newGoalOpen, setNewGoalOpen] = useState(() => {
    if (location.state?.new) {
      return true;
    }
    return false;
  });
  const [selectedGoal, setSelectedGoal] = useState<TGoal | null>(null);

  const { data } = useGetGoals({
    select: (goals) => {
      return {
        inProgress: goals.goals.filter((goal) => goal.status === "IN_PROGRESS"),
        completed: goals.goals.filter((goal) => goal.status === "COMPLETED"),
        suggested: goals.defaultGoals,
        total: goals.goals.length
      };
    }
  });

  return (
    <Stack className="h-full" gap={6}>
      <div className="flex-1 overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            height: PageTitleHeight
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <Typography as="h5" variant="h6">
            Goals
          </Typography>
          <Button onClick={() => setNewGoalOpen(true)}>Create Goal</Button>
        </Box>

        <Tabs
          defaultIndex={data?.total === 0 ? 0 : 1}
          style={{
            height: PageHeightWithoutHeader,
            overflowY: "auto"
          }}
        >
          <TabList className="px-4 overflow-x-hidden border-b">
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Suggested
            </Tab>
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              In progress
              {data?.inProgress.length !== 0 && (
                <span className="inline-flex items-center self-center justify-center w-6 h-6 text-xs text-center bg-white rounded-full shadow ms-1">
                  {data?.inProgress.length}
                </span>
              )}
            </Tab>
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Completed
              {data?.completed.length !== 0 && (
                <span className="inline-flex items-center self-center justify-center w-6 h-6 text-xs text-center rounded-full shadow text-tertiary bg-tertiary ms-1">
                  {data?.completed.length}
                </span>
              )}
            </Tab>
          </TabList>

          <TabPanel
            style={{
              height: "calc(100% - 51px)",
              padding: "1rem",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <GoalsList
              goals={data?.suggested}
              title="suggested"
              total={data?.suggested.length || 0}
              setNewGoalOpen={setNewGoalOpen}
              setSelectedGoal={setSelectedGoal}
            />
          </TabPanel>
          <TabPanel
            style={{
              height: "calc(100% - 51px)",
              padding: "1rem",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <GoalsList goals={data?.inProgress} title="inprogress" total={data?.total || 0} setNewGoalOpen={setNewGoalOpen} />
          </TabPanel>
          <TabPanel
            style={{
              height: "calc(100% - 51px)",
              padding: "1rem",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            <GoalsList goals={data?.completed} title="completed" total={data?.total || 0} setNewGoalOpen={setNewGoalOpen} />
          </TabPanel>
        </Tabs>
        <BaseDialog
          disableFocusLock
          dialogClassName="max-w-3xl"
          allowPinchZoom
          isOpen={newGoalOpen}
          onClose={() => {
            setSelectedGoal(null);
            setNewGoalOpen(false);
          }}
        >
          <NewGoalModal
            selectedGoal={selectedGoal!}
            onClose={() => {
              setSelectedGoal(null);
              setNewGoalOpen(false);
            }}
          />
        </BaseDialog>
      </div>
      <GoalsProgress />
    </Stack>
  );
};

export default Goals;
