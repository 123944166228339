import React, { useState } from "react";
import { BackButton } from "../../../components/nav/BackButton";
import { AvatarPlaceholder } from "../../../components/SVG/AvatarPlaceholder";
export const ViewProfile = () => {
  const [profile] = useState(
    localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile")).data
      : ""
  );
  // console.log("====Profile======",profile);
  
  return (
    <>
      <BackButton />
      <div
        className="flex flex-row h-full"
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="w-full pb-10 bg-gray-50">
          <div className="w-full p-8 mx-auto bg-white rounded-lg shadow 2xl:w-1/2">
            <h1 className="text-xl font-bold text-center text-gray-700 md:text-left">
              View Profile
            </h1>
            <div className="p-10 pb-6">
              {/* Profile Photo */}
              {profile?.profile_image && (
                <div className="pb-4">
                  <label
                    className="block pl-4 cursor-pointer"
                    title="Profile Photo"
                  >
                    <div>
                      <div
                        className="relative mx-auto"
                        style={{ width: "fit-content" }}
                      >
                        <AvatarPlaceholder
                          classes="h-40 w-40 mx-auto"
                          imageHeight="h-40"
                          imageWidth="w-40"
                          imageUrl={profile?.user?.profile_image}
                        />
                      </div>
                    </div>
                  </label>
                </div>
              )}

              <div className="flex flex-wrap mb-6 -mx-3">
                {profile?.first_name && (
                  <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-first-name"
                    >
                      First Name
                    </label>
                    <div className="py-1 border rounded-lg bg-white-100 ">
                      <h3 className="px-4 py-2">{profile?.first_name}</h3>
                    </div>
                  </div>
                )}

                {profile?.last_name && (
                  <div className="w-full px-3 md:w-1/2">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-last-name"
                    >
                      Last Name
                    </label>
                    <div className="py-1 border rounded-lg bg-white-100 ">
                      <h3 className="px-4 py-2">{profile?.last_name}</h3>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-wrap -mx-3">
                {profile?.user?.email && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-email"
                    >
                      Email
                    </label>
                    <div className="py-1 border rounded-lg bg-white-100 ">
                      <h3 className="px-4 py-2">
                        {profile?.user?.email ? profile?.user?.email : ""}
                      </h3>
                    </div>
                  </div>
                )}

                {profile?.about && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase border-gray-200 "
                      htmlFor="grid-number"
                    >
                      About Me
                    </label>
                    <div className="px-4 py-1 bg-gray-100 rounded-lg">
                      <h3 className="text-sm">{profile?.about}</h3>
                    </div>
                  </div>
                )}

                {profile?.experience && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-number"
                    >
                      Experience
                    </label>
                    <div className="flex flex-row flex-wrap mt-4 overflow-y-auto max-h-60">
                      {profile?.experience?.map((t, i) => (
                        <label key={i}>
                          <div className="flex flex-row items-center justify-between px-2 py-1 m-1 text-xs font-semibold bg-gray-300 rounded-full cursor-pointer">
                            <div>{t}</div>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {profile?.last_name && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-number"
                    >
                      ICF Credential
                    </label>
                    <div className="py-1 border rounded-lg bg-white-100 ">
                      <h3 className="px-4 py-2">{profile?.last_name}</h3>
                    </div>
                  </div>
                )}
                {profile?.other_relevant_certifications && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-number"
                    >
                      Licenses & Certifications
                    </label>
                    <div className="px-4 py-1 bg-gray-100 rounded-lg">
                      {profile?.other_relevant_certifications?.map((t, i) => (
                        <label key={i}>
                          <div className="flex flex-row items-center justify-between">
                            <span className="py-2 text-sm ">{t}</span>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {profile?.about && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-number"
                    >
                      Coaching Style
                    </label>
                    <div className="px-4 py-1 bg-gray-100 rounded-lg">
                      <h3 className="text-sm">{profile?.about} </h3>
                    </div>
                  </div>
                )}
                {profile?.client_testimonials && (
                  <div className="w-full px-3 mb-6">
                    <label
                      className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
                      htmlFor="grid-number"
                    >
                      Testimonials
                    </label>
                    <div className="px-4 py-1 bg-gray-100 rounded-lg">
                      {profile?.client_testimonials?.map((t, i) => (
                        <label key={i}>
                          <div className="flex flex-row items-center justify-between">
                            <span className="py-2 text-sm ">{t}</span>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
