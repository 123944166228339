import { Typography, Button } from "knack-ui";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useUpdateProfile } from "../../features/User/userMutations";
import { userQueryKeys, useUser } from "../../features/User/userQueries";
import { ScheduleBufferOptions } from "../../lib/constants";
import { useQueryClient } from "react-query";
import KnackSelect from "../KnackSelect/KnackSelect";
import { Failure } from "../ToastNotification/Failure";
import { Success } from "../ToastNotification/Success";

interface IBufferForm {
  schedule_buffer_minutes: { value: number; label: string }[];
}

const ScheduleBufferController = () => {
  const { data: user } = useUser({
    suspense: false
  });

  const [bufferOption, setBufferOption] = useState<IBufferForm["schedule_buffer_minutes"]>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: updateProfile, isLoading: profileLoading } = useUpdateProfile();

  const onBufferUpdate = async (e: IBufferForm) => {
    try {
      if (e?.schedule_buffer_minutes) {
        await updateProfile({ schedule_buffer_minutes: e?.schedule_buffer_minutes[0].value });
        Success("Scheduling buffer updated successfully!");
        queryClient.invalidateQueries(userQueryKeys.all);
      }
    } catch (error) {
      Failure("Oh no! Something went wrong");
    }
  };

  const { control, handleSubmit } = useForm<IBufferForm>({
    defaultValues: {
      schedule_buffer_minutes: user?.profile?.schedule_buffer_minutes
        ? [
            {
              label: ScheduleBufferOptions.find((b) => b.value === user?.profile?.schedule_buffer_minutes)?.label,
              value: user?.profile?.schedule_buffer_minutes
            }
          ]
        : []
    }
  });

  useEffect(() => {
    const schedule_buffer_minutes = user?.profile?.schedule_buffer_minutes
      ? [
          {
            label: ScheduleBufferOptions.find((b) => b.value === user?.profile?.schedule_buffer_minutes)?.label,
            value: user?.profile?.schedule_buffer_minutes
          }
        ]
      : [];
    setBufferOption(schedule_buffer_minutes as IBufferForm["schedule_buffer_minutes"]);
  }, [user?.profile?.schedule_buffer_minutes]);
  return (
    <form onSubmit={handleSubmit(onBufferUpdate)}>
      <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="capacity">
        Start Buffer
      </Typography>

      <Controller
        control={control}
        name="schedule_buffer_minutes"
        rules={{ required: "Required" }}
        render={({ field: { value, onChange } }) => {
          return (
            <KnackSelect
              id="schedule_buffer_minutes"
              className="inner-inner-box-shadow-none"
              isMulti
              placeholder="Select buffer"
              isSearchable={true}
              options={ScheduleBufferOptions}
              isClearable
              value={value}
              onChange={(option: any) => {
                onChange(option);
                setBufferOption(option);
              }}
              isLoading={profileLoading}
              closeMenuOnSelect={true}
              isOptionDisabled={() => bufferOption?.length >= 1}
            />
          );
        }}
      />

      {bufferOption.length > 0 && (
        <Button className="mt-4 ms-auto" kind="success" type="submit">
          Save
        </Button>
      )}
    </form>
  );
};

export default ScheduleBufferController;
