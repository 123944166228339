import moment from "moment";
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions, useQuery, UseQueryOptions } from "react-query";
import { ServerResponseV2 } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { IUserLog, IActiveUserMetrics, IActiveUserQuery } from "./activeUserListTypes";

export interface ILogResponse {
  logs: IUserLog[];
  totalPages: number;
  totalRecords: number;
  totalRecordsPerPage: number;
  currentPage: number;
  metrics: IActiveUserMetrics;
}
export interface ILoginActivityResponse {
  allLogs: { _id: number; count: number; date: string }[];
}

export const activeUsersAnalyticsQueryKeys = {
  all: [{ scope: "active-user analytics" }] as const,
  activeUsers: (query: Partial<IActiveUserQuery>) =>
    [
      {
        ...activeUsersAnalyticsQueryKeys.all[0],
        entity: "active-user list",
        ...query
      }
    ] as const,
  loginActivity: ({ query }: { query: Partial<IActiveUserQuery> }) =>
    [
      {
        ...activeUsersAnalyticsQueryKeys.all[0],
        entity: "login activity",
        ...query
      }
    ] as const
};

async function fetchActiveUsers({
  pageParam = 1,
  queryKey: [{ organization, dateRange, programType, userType, program, search_by_email_or_name, limit }]
}: QueryFunctionContext<ReturnType<typeof activeUsersAnalyticsQueryKeys["activeUsers"]>>) {
  const endDate =
    dateRange![0]?.startDate === dateRange![0]?.endDate ? moment(dateRange![0]?.startDate).endOf("D").toDate() : dateRange![0]?.endDate;

  const params: Record<string, any> = {
    page: pageParam,
    organization: organization?.join(","),
    userType: userType?.join(","),
    startDate: dateRange![0]?.startDate,
    endDate,
    programType: programType?.join(","),
    program: program?.join(","),
    search_by_email_or_name,
    limit
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponseV2<ILogResponse>>("v2/analytics/logs/active-users", {
    params
  });

  return res.data.data;
}

async function getLoginActivity({
  pageParam = 1,
  queryKey: [{ organization, dateRange, programType, userType, program, search_by_email_or_name }]
}: QueryFunctionContext<ReturnType<typeof activeUsersAnalyticsQueryKeys["loginActivity"]>>) {
  const endDate =
    dateRange![0]?.startDate === dateRange![0]?.endDate ? moment(dateRange![0]?.startDate).endOf("D").toDate() : dateRange![0]?.endDate;

  const params: Record<string, any> = {
    organization: organization?.join(","),
    userType: userType?.join(","),
    startDate: dateRange![0]?.startDate,
    endDate,
    programType: programType?.join(","),
    program: program?.join(","),
    search_by_email_or_name
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponseV2<ILoginActivityResponse>>("v2/analytics/logs/aggregate", {
    params
  });

  return res.data.data;
}

export const useGetActiveUsers = <SelectData = ILogResponse, Error = unknown>(
  query: Partial<IActiveUserQuery>,
  options?: UseInfiniteQueryOptions<
    ILogResponse,
    Error,
    SelectData,
    ILogResponse,
    ReturnType<typeof activeUsersAnalyticsQueryKeys["activeUsers"]>
  >
) => {
  return useInfiniteQuery<ILogResponse, Error, SelectData, ReturnType<typeof activeUsersAnalyticsQueryKeys["activeUsers"]>>(
    activeUsersAnalyticsQueryKeys.activeUsers(query),
    fetchActiveUsers,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.currentPage < lastPage.totalPages) {
          return +lastPage.currentPage + 1;
        }
        return undefined;
      },
      ...options
    }
  );
};

export const useGetLoginActivity = <SelectData = ILoginActivityResponse, Error = unknown>(
  query: Partial<IActiveUserQuery>,
  options?: UseQueryOptions<ILoginActivityResponse, Error, SelectData, ReturnType<typeof activeUsersAnalyticsQueryKeys["loginActivity"]>>
) => {
  return useQuery<ILoginActivityResponse, Error, SelectData, ReturnType<typeof activeUsersAnalyticsQueryKeys["loginActivity"]>>(
    activeUsersAnalyticsQueryKeys.loginActivity({ query }),
    getLoginActivity,
    {
      ...options
    }
  );
};
