import React from "react";
import ReactApexChart from "react-apexcharts";

export const EarningChart = ({ stateData = [] }) => {
  const categories = [
    ...stateData.map((s) =>
      new Date(s.payment_date).toLocaleString("en-US", {
        month: "short",
        day: "numeric"
      })
    )
  ];
  const earning = [...stateData.map((s) => s.earning)];

  const state = {
    series: [
      {
        data: earning
      }
    ],
    options: {
      chart: {
        height: 350,
        type: "area"
      },
      fill: {
        colors: [
          "#1F40E6",
          "#1F40E6",
          "#1F40E6",
          "#1F40E6",
          "#1F40E6",
          "#6279EC"
        ],
        opacity: 1
      },
      markers: {
        size: 0
      },
      stroke: {
        curve: "smooth",
        colors: [
          "#6279EC",
          "#6279EC",
          "#6279EC",
          "#6279EC",
          "#6279EC",
          "#6279EC"
        ],
        width: 2
      },
      dataLabels: {
        enabled: false
      },
      labels: categories,
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div className="reformatChatTooltip">
                            <span className="p-2 text-xs">${w.globals.categoryLabels[dataPointIndex]} : <span className="font-bold">$${earning[dataPointIndex]}</span></span>
                        </div>
                        `;
        },
        shared: false,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return "$" + y.toFixed(0);
            }
            return y;
          }
        }
      }
    }
  };

  return (
    <div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={350}
      />
    </div>
  );
};
