import { useState } from "react";
import { createProfile, updateProfile } from "../../services/base-api";
import { Failure } from "../../components/ToastNotification/Failure";
import { Success } from "../../components/ToastNotification/Success";
import KnackSelect from "../../components/KnackSelect/KnackSelect";
import { useUserCompany, useUser } from "../../features/User/userQueries";
import { Box, Breadcrumbs, Button, Input, Textarea, Typography } from "knack-ui";
import { useQueryClient } from "react-query";
import { userQueryKeys } from "../../features/User/userQueries";
import { Link, useHistory } from "react-router-dom";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import { useGetLanguagesList } from "../../features/Company/companyQueries";
import AutoCompleteInput from "../../components/AutoCompleteInput/AutoCompleteInput";
import usePositionMatch from "../../hooks/usePositionMatch";
import { useAddCompanyPosition } from "../../features/User/userMutations";

export default function HrAccount() {
  const  setShowImageSpinner = useState(false)[1];
  const history = useHistory();
  const { data: user } = useUser();
  const queryClient = useQueryClient();
  const { data: company } = useUserCompany();
  const { data: languages, isLoading: languagesLoading } = useGetLanguagesList();

  const { mutateAsync: addCompanyPosition } = useAddCompanyPosition();

  const [profile, setProfile] = useState(user?.profile ? user.profile : null);
  const positions = usePositionMatch(profile.position || '');
  // function imageSelected(e) {
  //   setShowImageSpinner(true);
  //   const bannerFormData = new FormData();
  //   bannerFormData.append("file", e.target.files[0]);
  //   bannerFormData.append("type", "image");

  //   // upload image
  //   uploadFormData(bannerFormData)
  //     .then((bannerFileRes) => {
  //       updateProfile(
  //         {
  //           user: user._id,
  //           profile_image: bannerFileRes.data.url
  //         },
  //         "?internal=true"
  //       )
  //         .then((res) => {
  //           if (res.data.status) {
  //             Success("Profile Photo Changed Successfully!");
  //             setShowImageSpinner(false);
  //             queryClient.invalidateQueries(userQueryKeys.all);
  //           }
  //         })
  //         .catch((e) => {
  //           console.log(e);
  //           Failure("Oh no! Something went wrong");
  //         });
  //       setShowImageSpinner(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       Failure("Oops! Photo upload failed, please try again");
  //       setShowImageSpinner(false);
  //     });
  // }

  function onSaveProfile() {
    if (!user.profile) {
      createProfile({
        first_name: profile.first_name,
        last_name: profile.last_name,
        whatsapp_number: profile.whatsapp_number,
        position: profile.position,
        age_group: profile.age_group,
        capacity: profile.capacity,
        profile_image: profile.profile_image,
        management_level: profile.management_level,
        job_function: profile.job_function,
        entity: profile.entity,
        about: profile.about,
        gender: profile.gender
      })
        .then((res) => {
          if (res.data.status) {
            Success("Your profile created Sucessfully!");
            history.push("/");
            queryClient.invalidateQueries(userQueryKeys.all);
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Oh no! Something went wrong");
        });
    } else {
      if (profile.spoken_languages.length === 0) return Failure("Spoken Languages cannot be empty");
      updateProfile(
        {
          user: user._id,
          whatsapp_number: profile.whatsapp_number,
          first_name: profile.first_name,
          last_name: profile.last_name,
          position: profile.position,
          age_group: profile.age_group,
          profile_image: profile.profile_image,
          capacity: profile.capacity,
          management_level: profile.management_level,
          job_function: profile.job_function,
          linkedin_profile_url: profile.linkedin_profile_url,
          spoken_languages: profile.spoken_languages,
          entity: profile.entity,
          about: profile.about,
          gender: profile.gender
        },
        "?internal=true"
      )
        .then((res) => {
          if (res.data.status) {
            Success("Profile updated successfully!");
            setShowImageSpinner(false);
            // user profile
            queryClient.invalidateQueries(userQueryKeys.all);
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Oh no! Something went wrong");
        });
    }
  }

  async function onAddCompanyPosition(position) {
    try {
      await addCompanyPosition({ position });
    } catch (error) {
      Failure("Something went wrong, please try again later");
    }
  }

  return (
    <div className="h-full">
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[<Link to="/dashboard">Home</Link>, <Typography clamp={1}>Account</Typography>]}
      </Box>
      <Box variant="outlined" paddingPreset="card">
        <Typography variant="h6" className="mb-6">
          Personal Information
        </Typography>
        <ProfileImage
          image={profile.profile_image}
          onChange={(url) => setProfile((prev) => ({ ...prev, profile_image: url }))}
          locked={!company.mediaSupported}
        />
        <form className="max-w-2xl mx-auto">
          <div className="flex flex-wrap mb-6 -mx-3">
            <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-first-name">
                First Name
              </Typography>

              <Input
                onChange={(v) =>
                  setProfile((e) => ({
                    ...e,
                    first_name: v.target.value
                  }))
                }
                id="grid-first-name"
                defaultValue={profile?.first_name}
              />
            </div>
            <div className="w-full px-3 md:w-1/2">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-last-name">
                Last Name
              </Typography>
              <Input
                onChange={(v) => setProfile((e) => ({ ...e, last_name: v.target.value }))}
                id="grid-last-name"
                defaultValue={profile?.last_name}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-bio">
                Bio
              </Typography>
              <Textarea
                placeholder="Add a brief description about yourself"
                onChange={(v) => setProfile((e) => ({ ...e, about: v.target.value }))}
                rows={8}
                id="grid-bio"
                defaultValue={profile?.about}
              />
            </div>

            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-email">
                Email
              </Typography>
              <Input id="grid-email" type="email" defaultValue={user?.email} disabled />
            </div>

            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-linkedin">
                LinkedIn profile
              </Typography>
              <Input
                onChange={(v) =>
                  setProfile((e) => ({
                    ...e,
                    linkedin_profile_url: v.target.value
                  }))
                }
                id="grid-linkedin"
                defaultValue={profile?.linkedin_profile_url}
              />
            </div>
            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="job-function">
                Spoken languages
              </Typography>
              <KnackSelect
                placeholder={`Select your language preference`}
                className="text-sm border-none rounded-none outline-none"
                isClearable={false}
                isMulti
                value={profile.spoken_languages.map((l) => ({
                  value: l,
                  label: l
                }))}
                onChange={(o) => {
                  if (o.length === 0) return;
                  setProfile((p) => ({
                    ...p,
                    spoken_languages: o.map((l) => l.value)
                  }));
                }}
                isLoading={languagesLoading}
                options={languages?.map((i) => ({
                  label: i,
                  value: i
                }))}
              />
            </div>
            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-organization">
                Organization
              </Typography>
              <Input id="grid-organization" defaultValue={user?.organization?.name} disabled />
            </div>

            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-entity">
                Entity
              </Typography>
              <KnackSelect
                id="grid-entity"
                onChange={(e) => (e ? setProfile((p) => ({ ...p, entity: e.value })) : null)}
                defaultValue={profile?.entity ? { value: profile?.entity, label: profile?.entity } : null}
                placeholder={profile?.entity}
                className="text-sm border-none rounded-none outline-none"
                isClearable={false}
                options={
                  company?.entities
                    ? [...company?.entities]?.map((d) => ({
                        value: d,
                        label: d
                      }))
                    : []
                }
              />
            </div>

            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="grid-position">
                Position
              </Typography>
              <AutoCompleteInput
                disableCustomValueAddition
                placeholder="Start typing to get suggestions..."
                onChange={(v) => {
                  setProfile((e) => ({ ...e, position: v }));
                }}
                hideEmptyState
                onCustomValueSelection={onAddCompanyPosition}
                value={profile.position}
                suggestions={positions}
              />
              {/* <Input id="grid-position" defaultValue={profile?.position} /> */}
            </div>
            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="gender">
                Gender
              </Typography>
              <KnackSelect
                isSearchable
                id="gender"
                placeholder={profile?.gender}
                onChange={(e) => (e ? setProfile((p) => ({ ...p, gender: e.value })) : null)}
                defaultValue={
                  profile?.gender
                    ? {
                        value: profile?.gender,
                        label: profile?.gender
                      }
                    : null
                }
                className="text-sm border-none rounded-none outline-none"
                options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" }
                ]}
              />
            </div>
            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="age">
                Age group
              </Typography>
              <KnackSelect
                id="age"
                isSearchable
                placeholder={profile?.age_group}
                onChange={(e) => (e ? setProfile((p) => ({ ...p, age_group: e.value })) : null)}
                defaultValue={
                  profile?.age_group
                    ? {
                        value: profile?.age_group,
                        label: profile?.age_group
                      }
                    : null
                }
                className="text-sm border-none rounded-none outline-none"
                options={[
                  { value: "20-below", label: "20 or younger" },
                  { value: "21-29", label: "21-29" },
                  { value: "30-39", label: "30-39" },
                  { value: "40-49", label: "40-49" },
                  { value: "50-59", label: "50-59" },
                  { value: "60-above", label: "60 or older" }
                ]}
              />
            </div>

            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="management">
                Management level
              </Typography>
              <KnackSelect
                isSearchable
                id="management"
                placeholder={profile?.management_level}
                onChange={(e) =>
                  e
                    ? setProfile((p) => ({
                        ...p,
                        management_level: e.value
                      }))
                    : null
                }
                defaultValue={
                  profile?.management_level
                    ? {
                        value: profile?.management_level,
                        label: profile?.management_level
                      }
                    : null
                }
                className="text-sm border-none rounded-none outline-none"
                isClearable={false}
                options={company?.management_levels?.map((l) => ({
                  value: l,
                  label: l
                }))}
              />
            </div>
            {profile?.profile_type === "MentorProfile" && (
              <div className="w-full px-3 mb-6">
                <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="capacity">
                  Capacity
                </Typography>
                <KnackSelect
                  id="capacity"
                  isSearchable
                  placeholder={profile?.capacity}
                  onChange={(e) => (e ? setProfile((p) => ({ ...p, capacity: e.value })) : null)}
                  defaultValue={
                    profile?.capacity
                      ? {
                          value: profile?.capacity,
                          label: profile?.capacity
                        }
                      : null
                  }
                  minMenuHeight={370}
                  maxMenuHeight={370}
                  className="text-sm border-none rounded-none outline-none"
                  isClearable={false}
                  options={new Array(10).fill(10).map((f, i) => ({ value: i + 1, label: i + 1 }))}
                />
              </div>
            )}

            <div className="w-full px-3 mb-6">
              <Typography className="block mb-2" variant="subtitle2" fontWeight="medium" as="label" htmlFor="job-function">
                Job Function
              </Typography>
              <KnackSelect
                isSearchable
                id="job-function"
                placeholder={profile?.job_function}
                onChange={(e) => (e ? setProfile((p) => ({ ...p, job_function: e.value })) : null)}
                defaultValue={
                  profile?.job_function
                    ? {
                        value: profile?.job_function,
                        label: profile?.job_function
                      }
                    : null
                }
                className="text-sm border-none rounded-none outline-none"
                isClearable={false}
                options={
                  company?.departments
                    ? [...company?.departments]?.map((d) => ({
                        value: d,
                        label: d
                      }))
                    : []
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-end px-8 text-center">
            <Button variant="large" onClick={() => onSaveProfile()}>
              Save
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
}
