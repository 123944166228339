import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { CONFERENCING_AUTH_REDIRECT_URL } from "../../services/Scheduling/CronofyService";
import { ICalendarConferencingOptions } from "./CalendarTypes";

async function revokeCalendarAccess(scheduler_id: string) {
  const res = await apiHttp.get<ServerResponse<any>>(
    "scheduler/profiles/revoke/" + scheduler_id
  );
  return res.data.data;
}

async function initConferenceAuth({
  scheduler_id,
  redirect_uri,
  provider_name
}: {
  scheduler_id: string;
  redirect_uri: string;
  provider_name: ICalendarConferencingOptions["value"];
}) {
  localStorage.setItem("[conferencingConnectCallback]", redirect_uri);
  const res = await apiHttp.post<ServerResponse<any>>(
    `scheduler/conferencing_service_authorizations/${scheduler_id}`,
    { redirect_uri: CONFERENCING_AUTH_REDIRECT_URL, provider_name }
  );

  if (res.data.status === true) {
    window.location.replace(res.data.data.authorization_request.url);
  }
  return res.data.data;
}

export const useRevokeCalendarAccess = () => {
  return useMutation(revokeCalendarAccess);
};

export const useConnectConferenceService = () => {
  return useMutation(initConferenceAuth);
};
