import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { useUserCompany } from "../User/userQueries";
import { IOrganization, IUser, IUserProfile } from "../User/UserTypes";

export const organizationQueryKeys = {
  all: [{ scope: "company" }] as const,
  companyMembers: (id: string | null) =>
    [{ ...organizationQueryKeys.all[0], entity: "members", id }] as const,
  company: (id?: string) => [{ ...organizationQueryKeys.all[0], id }] as const,
  externalCoaches: [{ scope: "externalCoaches" }] as const,
  companyExternalCoaches: ({ id }: { id?: string }) =>
    [{ scope: "company external coaches", id }] as const,
  languagesList: () => [{ scope: "list of languages" }] as const
};

async function fetchLanguagesList() {
  const res = await apiHttp.get<ServerResponse<string[]>>("v1/auth/languages");
  return res.data.data;
}
async function fetchOrganization() {
  const res = await apiHttp.get<ServerResponse<IOrganization[]>>(
    "v1/companies"
  );
  return res.data.data;
}

interface ExternalCoachResponse extends IUser {
  profile_data: IUserProfile;
}
export interface CompanyExternalCoachResponse {
  coachProfile: IUserProfile;
  coach: Pick<IUser, "_id" | "profile_image" | "account_type" | "email">;
}

async function fetchExternalCoaches() {
  const res = await apiHttp.get<ServerResponse<ExternalCoachResponse[]>>(
    "v1/auth/coaches"
  );
  return res.data.data;
}

async function fetchCompanyExternalCoaches({
  queryKey: [{ id }]
}: QueryFunctionContext<
  ReturnType<typeof organizationQueryKeys["companyExternalCoaches"]>
>) {
  if (!id) throw new Error("Invalid Company ID");
  const res = await apiHttp.get<ServerResponse<CompanyExternalCoachResponse[]>>(
    "v1/auth/company-coach/" + id
  );
  return res.data.data;
}

async function fetchMembers({
  queryKey: [{ id }]
}: QueryFunctionContext<
  ReturnType<typeof organizationQueryKeys["companyMembers"]>
>) {
  let res;
  if (!id) {
    res = await apiHttp.get<ServerResponse<IUserProfile[]>>(`v1/profile/users`);
  } else {
    res = await apiHttp.get<ServerResponse<IUserProfile[]>>(
      `v1/companies/${id}/users`
    );
  }
  return res.data.data;
}

// Queries
export const useGetExternalCoaches = <
  SelectData = ExternalCoachResponse[],
  Error = unknown
>(
  options?: UseQueryOptions<ExternalCoachResponse[], Error, SelectData>
) => {
  return useQuery<ExternalCoachResponse[], Error, SelectData>(
    organizationQueryKeys.externalCoaches,
    fetchExternalCoaches,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};
export const useGetCompanyExternalCoaches = <
  SelectData = CompanyExternalCoachResponse[],
  Error = unknown
>(
  options?: UseQueryOptions<
    CompanyExternalCoachResponse[],
    Error,
    SelectData,
    ReturnType<typeof organizationQueryKeys["companyExternalCoaches"]>
  >
) => {
  const { data: company } = useUserCompany();
  return useQuery<
    CompanyExternalCoachResponse[],
    Error,
    SelectData,
    ReturnType<typeof organizationQueryKeys["companyExternalCoaches"]>
  >(
    organizationQueryKeys.companyExternalCoaches({ id: company?._id }),
    fetchCompanyExternalCoaches,
    {
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      ...options
    }
  );
};

export const useGetOrganizations = <
  SelectData = IOrganization[],
  Error = unknown
>(
  options?: UseQueryOptions<IOrganization[], Error, SelectData>
) => {
  return useQuery<IOrganization[], Error, SelectData>(
    organizationQueryKeys.all,
    fetchOrganization,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};

export const useGetMembers = <SelectData = IUserProfile[], Error = unknown>(
  { id }: { id: string | null },
  options?: UseQueryOptions<
    IUserProfile[],
    Error,
    SelectData,
    ReturnType<typeof organizationQueryKeys["companyMembers"]>
  >
) => {
  return useQuery<
    IUserProfile[],
    Error,
    SelectData,
    ReturnType<typeof organizationQueryKeys["companyMembers"]>
  >(organizationQueryKeys.companyMembers(id), fetchMembers, {
    staleTime: DURATIONS.fifteenMins,
    useErrorBoundary: true,
    suspense: true,
    ...options
  });
};
export const useGetLanguagesList = <SelectData = string[], Error = unknown>(
  options?: UseQueryOptions<
    string[],
    Error,
    SelectData,
    ReturnType<typeof organizationQueryKeys["languagesList"]>
  >
) => {
  return useQuery<
    string[],
    Error,
    SelectData,
    ReturnType<typeof organizationQueryKeys["languagesList"]>
  >(organizationQueryKeys.languagesList(), fetchLanguagesList, {
    staleTime: Infinity,

    ...options
  });
};
