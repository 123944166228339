import { Button, Stack, Typography } from "knack-ui";
import { ErrorComponentProps } from "../../lib/HOCs/withErrorBoundary";
import errorImg from "../../components/Asset/illustrations/error.png";
import SVG from "../SVG/SVG";
import { useEffect, useRef, useState } from "react";
import { isDev } from "../../lib/utils";
import axios from "axios";
import {
  buildAxiosErrorBlocks,
  buildGeneralErrorBlocks,
  isServerError
} from "../../lib/errUtils";
import { slackSendErrorMessage } from "../../features/SlackBugIngest/slackIngestMutations";

const ServerErrorView = ({
  error,
  props,
  resetErrorBoundary
}: ErrorComponentProps) => {
  const [copied, setCopied] = useState(false);
  const errRef = useRef<HTMLDivElement | null>(null);
  const onCodeCopy = () => {
    if (errRef.current) {
      navigator.clipboard.writeText(errRef.current.innerText).then(() => {
        setCopied(true);
      });
    }
  };
  useEffect(() => {
    if (axios.isAxiosError(error)) {
      const fields = buildAxiosErrorBlocks(error);
      slackSendErrorMessage(fields);
    } else {
      if (error instanceof Error) {
        const fields = buildGeneralErrorBlocks(error);
        slackSendErrorMessage(fields);
      }
    }
  }, [error]);
  console.log({ error });
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction="column"
      gap={6}
      {...props}
      className={`container max-w-2xl p-6 mx-auto ${
        props ? props?.className : ""
      }`}
    >
      <div className="">
        <img
          className="h-40 mx-auto"
          src={errorImg}
          alt="Something went wrong"
        />
        <div>
          <Typography
            variant="body1"
            fontWeight="bold"
            className="my-4"
            textAlign="center"
          >
            Oops, Someone unpluged the cable! Please try again
          </Typography>
          <Button
            onClick={() => {
              // If its a fail load
              if (
                error instanceof Error &&
                error.message ===
                  "Failed loading page, Please refresh your browser window"
              ) {
                return window.location.reload();
              }
              resetErrorBoundary();
            }}
            className="mx-auto"
          >
            Try again
          </Button>
          {isDev() && (
            <p className="mt-4 text-xs text-center">
              If you keep seeing this page, screenshot or copy the debug section
              below and report it to support@knacklab.co. This will help us
              identify the problem more accurately and improve your experience.
            </p>
          )}
        </div>
      </div>

      {isDev() && (
        <div
          dir="ltr"
          className="relative flex flex-col max-w-full gap-6 p-6 overflow-hidden bg-gray-100 border rounded-lg"
        >
          <div className="absolute end-2 top-2">
            <Button
              onClick={onCodeCopy}
              kind={copied ? "success" : "ghost"}
              variant="xsmall"
              startIcon={
                <SVG size={15} name={copied ? "ColoredCheckmark" : "Copy"} />
              }
            >
              {copied ? "Copied!" : "Copy code"}
            </Button>
          </div>
          <>
            <pre className="text-lg font-semibold text-center text-knackPrimary">
              Debugs for devs 👨‍💻👩‍💻
            </pre>
            <Stack ref={errRef} direction="column" gap={4}>
              {isServerError(error) ? (
                <span>
                  <code>The Fox say: </code>
                  <code className="text-red-800 bg-red-400 rounded-lg bg-opacity-20">
                    {error.response?.data.error ||
                      error.response?.data.data ||
                      error.response?.data.message}
                  </code>
                </span>
              ) : (
                ""
              )}
              <span>
                <code>Error name: </code>
                <code className="text-warning">
                  {JSON.stringify(error.name, null, 4)}
                </code>
              </span>
              <span>
                <code>Error message: </code>

                <code className="text-warning">
                  {JSON.stringify(error.message, null, 4)}
                </code>
              </span>
              <span>
                <code>Error stack: </code>
                <code className="block p-4 mt-4 overflow-y-auto max-h-72 bg-warning bg-opacity-10 text-warning rounded-xl">
                  {JSON.stringify(error.stack, null, 4)}
                </code>
              </span>
              {/* {error.cause && (
                <code>{JSON.stringify(error.cause, null, 4)}</code>
              )} */}
            </Stack>
          </>
        </div>
      )}
    </Stack>
  );
};

export default ServerErrorView;
