import { Button } from 'knack-ui'
import React from 'react'

interface IProps {
    type: 'danger' | 'success' | 'ghost' | 'warning' |'tertiary',
    text: string
}

export const Status = ({ type = 'success', text = 'Success' }: IProps) => {
    return (
        <Button kind={type} variant='xsmall' className='text-xs'>{text}</Button>
    )
}
