import ContentLoader from "react-content-loader";

const DashboardGoalCardSkeleton = () => {
  return (
    <div className="p-6 border rounded-lg ">
      <ContentLoader
        backgroundColor="#ececec"
        foregroundColor="#fafafa"
        viewBox="0 0 400 160"
        width="100%"
        height="100%"
      >
        <rect x="0" y="0" rx="5" ry="5" width="200" height="13" />
        <rect x="0" y="30" rx="5" ry="5" width="75" height="15" />
        <rect x="325" y="10" rx="5" ry="5" width="65" height="32" />

        <rect x="0" y="80" rx="2" ry="2" width="65" height="10" />
        <rect x="0" y="100" rx="2" ry="2" width="195" height="10" />
        <rect x="0" y="120" rx="2" ry="2" width="125" height="10" />
        <rect x="0" y="140" rx="2" ry="2" width="95" height="10" />
      </ContentLoader>
    </div>
  );
};

export default DashboardGoalCardSkeleton;
