import { useMutation, useQueryClient } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { campaignQueryKeys } from "./campaignQueries";
import {  INewCampaign } from "./CampaignTypes";

async function addEditCampaign(campaign: INewCampaign) {
  const res = await apiHttp.post<ServerResponse<INewCampaign>>(
    `v1/auth/campaigns`,
    campaign
  );
  return res.data.data;
}

// Mutations
export const useAddEditCampaign = () => {
  const queryClient = useQueryClient();
  return useMutation(addEditCampaign, {
    onSuccess: () => {
      queryClient.invalidateQueries(campaignQueryKeys["all"]);
    }
  });
};
