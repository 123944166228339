import { Route, Redirect, Switch } from "react-router-dom";
import { UserProfile } from "../UserProfile";
import { ResetPassword } from "../../../pages/Auth/ResetPassword";
import { AssessmentReports } from "../../../pages/dashboard/hrDashboard/AssessmentReport/AssessmentReports";
import { HRPeopleV2 } from "../../../pages/dashboard/hrDashboard/Merger/hrPeopleV2";
// import { PreviewSurvey } from "../../../pages/mentoring/hrDashboard/Survey/PreviewSurvey";
// import { Survey } from "../../../pages/mentoring/hrDashboard/Survey/Survey";
import { Programs } from "../../../pages/mentoring/hrDashboard/Programs/Programs";
import { CreateProgramFullPageV3 } from "../../../pages/mentoring/hrDashboard/ProgramV3/Program/CreateProgramFullPageV3";
import { ViewProgram } from "../../../pages/mentoring/hrDashboard/v2/Program/ViewProgram";
// import { HRCampaign } from "../../../pages/mentoring/hrDashboard/Campaigns/HRCampaign";
import Microlearning from "../../../pages/dashboard/userDashboard/userLearning/microlearning/microlearning";
import Journeys from "../../../pages/dashboard/userDashboard/userLearning/journeys/journeys";
import SingleJourney from "../../../pages/dashboard/userDashboard/userLearning/journeys/singleJourney/singleJourney";
import { MenteeProfile } from "../../../pages/mentoring/mentorDashboard/menteeProfile";
import ProfileAccount from "../../../pages/account/mentoraccount";
import { Settings } from "../Settings";
import HrDashboard from "../../../pages/HrDashboard/HrDashboard";
import HrPrograms from "../Programs/HrPrograms";
import HrCampaign from "../../../features/Campaign/ui/HrCampaigns";
import NewCampaign from "../../../features/Campaign/ui/NewCampaign";
import { lazy } from "react";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import { loadComponent } from "../../../lib/utils";
import Surveys from "../../../features/Surveys/ui/Surveys";
import Survey from "../../../features/Surveys/ui/Survey";
import { useUserCompany } from "../../../features/User/userQueries";
import HrProgramDashboard from "../../../features/Programs/ui/HrProgramDashboard/HrProgramDashboard";
import PairingReportPage from "../../../features/PairingsReport/pages/PairingReportPage";
import GoalsReportsPage from "../../../features/AnalyticsReports/pages/GoalsReportsPage";
import SessionsReportsPage from "../../../features/AnalyticsReports/pages/SessionsReportsPage";
import UserReportsPage from "../../../features/AnalyticsReports/pages/UserReportsPage";
import AnalyticsReportPage from "../../../features/AnalyticsReports/pages/AnalyticsReportPage";

const LazyViewCampaign = lazy(() => loadComponent(() => import("../../../features/Campaign/ui/ViewCampaign")));

const ViewCampaign = withErrorBoundary(
  withSuspense(LazyViewCampaign, {
    fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
  })
);
const LazyCreateSurvey = lazy(() => loadComponent(() => import("../../../features/Surveys/ui/CreateSurvey")));

const CreateSurvey = withErrorBoundary(
  withSuspense(LazyCreateSurvey, {
    fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
  })
);

const LazyNotificationCenter = lazy(() => loadComponent(() => import("../../../features/Notifications/ui/NotificationCenter")));

const NotificationCenter = withErrorBoundary(
  withSuspense(LazyNotificationCenter, {
    fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
  })
);
const LazyProgramRegistration = lazy(() =>
  loadComponent(() => import("../../../features/Programs/ui/ProgramRegistration/ProgramRegistration"))
);

const ProgramRegistration = withErrorBoundary(
  withSuspense(LazyProgramRegistration, {
    fallBackComponent: <PageSkeleton height={`calc(${window.innerHeight}px - 357px)`} />
  })
);
const HrRoutes = () => {
  const { data: company } = useUserCompany();

  return (
    <Switch>
      <Route exact path="/programs" component={Programs} />
      <Route exact path="/new-programs" component={HrPrograms} />
      <Route exact path="/dashboard" component={HrDashboard} />

      {company?.features.includes("Assessment") && <Route exact path="/assessment-reports" component={AssessmentReports} />}

      <Route exact path="/people" component={HRPeopleV2} />
      <Route exact path="/survey" component={Surveys} />
      <Route exact path="/surveys/:id" component={Survey} />
      <Route exact path="/new-survey" component={CreateSurvey} />
      <Route exact path="/edit-survey/:id" component={CreateSurvey} />
      {/* <Route exact path="/preview-survey/:share_id" component={PreviewSurvey} /> */}
      <Route exact path="/create-program" component={CreateProgramFullPageV3} />
      <Route exact path="/edit-program/:programId" component={CreateProgramFullPageV3} />
      <Route exact path="/view-program" component={ViewProgram} />
      <Route exact path="/programs/:programId/:tab?" component={HrProgramDashboard} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/reset-password/:id" component={ResetPassword} />
      <Route exact path="/mentee-profile" component={MenteeProfile} />
      <Route exact path="/user/:userId" component={UserProfile} />
      <Route exact path="/user/:userId/:programId" component={UserProfile} />
      <Route exact path="/user/:userId/:programId/:color" component={UserProfile} />
      <Route exact path="/user/:userId/:programId/:color/:profileOnly" component={UserProfile} />
      <Route path="/account" component={ProfileAccount} />
      <Route exact path="/microlearnings" component={Microlearning} />
      <Route exact path="/journeys" component={Journeys} />
      <Route exact path="/journey" component={SingleJourney} />
      <Route exact path="/campaigns" component={HrCampaign} />
      <Route exact path="/new-campaign" component={NewCampaign} />
      <Route exact path="/campaigns/:id" component={ViewCampaign} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/reports" component={AnalyticsReportPage} />
      <Route exact path="/reports/sessions" component={SessionsReportsPage} />
      <Route exact path="/reports/user" component={UserReportsPage} />
      <Route exact path="/reports/goals" component={GoalsReportsPage} />
      <Route exact path="/reports/pairings" component={PairingReportPage} />
      <Route exact component={ProgramRegistration} path="/program-application/:id" />
      <Route exact path="/notification-center" component={NotificationCenter} />
      <Redirect to="/dashboard" />
    </Switch>
  );
};

export default HrRoutes;
