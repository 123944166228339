import { Box, Button, Stack, Typography } from "knack-ui";
import { useHistory, useLocation } from "react-router-dom";
import office365 from "../../../components/Asset/Images/office-365-logo.png";
import outlook from "../../../components/Asset/Images/outlook-logo.png";
import icloud from "../../../components/Asset/Images/apple-icloud-logo.png";
import google from "../../../components/Asset/Images/google-logo.png";
import { CalendarProviders } from "../../../lib/interfaces/generalTypes";
import { CronofyService } from "../../../services/Scheduling/CronofyService";
import SVG from "../../../components/SVG/SVG";
import { IProgramInvite } from "../../Programs/ProgramTypes";

interface IProps {
  helperText?: string;
}

const NoCalendarView = ({ helperText }: IProps) => {
  const location = useLocation<{ programInvite: IProgramInvite }>();
  const history = useHistory();
  function onConnectCalendar(provider: CalendarProviders) {
    if (location.state) {
      CronofyService.setCallBackData("/programs", {});
    }
    // data to pull after redirect it completed
    CronofyService.requestCronofyAuthorizationURL({ provider_name: provider });
  }

  return (
    <Box variant="outlined" paddingPreset="card" className="h-full">
      <Stack
        gap={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="container h-full max-w-xl mx-auto"
      >
        <SVG name="Calendar" className="text-primaryDark" size={70} />
        <Typography textAlign="center" variant="h5">
          {helperText || "Connect your calendar"}
        </Typography>
        <Typography className="mb-6" variant="subtitle2" textAlign="center">
          As a part of the mentoring or coaching program you will engage
          with others by scheduling multiple meetings including conference
          calls.
        </Typography>
        <Typography
          variant="body1"
          fontWeight="medium"
          color="muted"
          className="mb-6"
          textAlign="center"
        >
          Select an option below to authorize access to your calendar
        </Typography>
        <div className="grid grid-cols-2 gap-6">
          <Button
            variant="large"
            kind="tertiary"
            onClick={() => onConnectCalendar("office365")}
          >
            <img src={office365} className="max-h-9" alt="" />
          </Button>
          <Button
            variant="large"
            kind="tertiary"
            onClick={() => onConnectCalendar("google")}
          >
            <img src={google} className="max-h-14" alt="" />
          </Button>
          <Button
            variant="large"
            kind="tertiary"
            onClick={() => onConnectCalendar("live_connect")}
          >
            <img src={outlook} className="max-h-14" alt="" />
          </Button>
          <Button
            variant="large"
            kind="tertiary"
            onClick={() => onConnectCalendar("apple")}
          >
            <img src={icloud} className="max-h-14" alt="" />
          </Button>
        </div>
        {location.state && location.state.programInvite && (
          <Button
            onClick={() => {
              history.replace(
                `/programs/${location.state.programInvite.program._id}/c`
              );
            }}
            kind="tertiary"
            variant="large"
          >
            Skip
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default NoCalendarView;
