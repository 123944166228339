export const StatusIndicator = ({
  color,
  text,
  fontSize = "text-xs"
}) => {
  return (
    <div
      className={`flex items-center justify-center ${fontSize} whitespace-none px-2 py-1 rounded-lg text-white font-bold ${
        color === "green"
          ? "bg-skin-green"
          : color === "yellow"
          ? "bg-skin-yellow"
          : color === "red"
          ? "bg-skin-red"
          : "bg-skin-accent"
      }`}
      style={{ width: "fit-content" }}
    >
      {text}
    </div>
  );
};
