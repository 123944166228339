import { useCallback } from "react";
import { QueryFunctionContext, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { TGoal } from "../Goals/goalsTypes";
import { useUser, useUserRoles } from "../User/userQueries";
import { IUserProfile } from "../User/UserTypes";
import { IConnectionProgram, IConnectionUser, IConnectionRequest } from "./ConnectionsTypes";

export const connectionQueryKeys = {
  all: [{ scope: "connections" }] as const,
  connectionList: (userType: "expert" | "user") => [{ ...connectionQueryKeys.all[0], userType }] as const,
  connection: (id?: string, userType?: "expert" | "user") => [{ scope: "connections", id, userType }] as const,
  connectionPrograms: (id?: string, userType?: "expert" | "user") =>
    [{ ...connectionQueryKeys.all[0], entity: "programs", id, userType }] as const,
  connectionGoals: (connectionId: string) =>
    [
      {
        ...connectionQueryKeys.all[0],
        entity: "goals",
        connectionId,
      }
    ] as const,
  connectionProfile: (id?: string) => [{ ...connectionQueryKeys.all[0], entity: "profiles", id }] as const
};

export type ExpertConnectionsResponse = {
  coachees: IConnectionUser[];
  mentees: IConnectionUser[];
  all: IConnectionUser[];
  pending: IConnectionRequest[];
  accepted: IConnectionRequest[];
};
export type UserConnectionsResponse = {
  pending: IConnectionRequest[];
  accepted: IConnectionRequest[];
  mentors: IConnectionUser[];
  coaches: IConnectionUser[];
  all: IConnectionUser[];
};
export type ConnectionsResponse = ExpertConnectionsResponse | UserConnectionsResponse;

async function fetchConnections({
  queryKey: [{ userType }]
}: QueryFunctionContext<ReturnType<typeof connectionQueryKeys["connectionList"]>>) {
  const res = await apiHttp.get<ServerResponse<ConnectionsResponse>>("v1/connections", { params: { user_type: userType } });
  return res.data.data;
}

async function fetchConnectionPrograms({
  queryKey: [{ id, userType }]
}: QueryFunctionContext<ReturnType<typeof connectionQueryKeys["connectionPrograms"]>>) {
  if (!id) {
    return Promise.reject("Connection ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<IConnectionProgram[]>>("v1/connections/profile/" + id + "/programs", {
    params: { user_type: userType }
  });
  return res.data.data;
}

async function fetchConnectionProfile({
  queryKey: [{ id }]
}: QueryFunctionContext<ReturnType<typeof connectionQueryKeys["connectionProfile"]>>) {
  if (!id) {
    return Promise.reject("User ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<IUserProfile>>("v1/profile/" + id);
  return res.data.data;
}

async function fetchConnectionGoals({
  queryKey: [{ connectionId }]
}: QueryFunctionContext<ReturnType<typeof connectionQueryKeys["connectionGoals"]>>) {
  const res = await apiHttp.get<ServerResponse<TGoal[]>>(`goals/shared/${connectionId}`);
  return res.data.data;
}

async function fetchOneConnection({
  queryKey: [{ id, userType }]
}: QueryFunctionContext<ReturnType<typeof connectionQueryKeys["connection"]>>) {
  if (!id) {
    return Promise.reject("Connection ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<IConnectionUser[]>>("v1/connections/profile/" + id + "/programs", {
    params: { user_type: userType }
  });
  return res.data.data[0];
}

interface UseGetConnections {
  allCount: number;
  allConnections: IConnectionUser[];
  menteesOrMentorsCount: number;
  menteesOrMentors: IConnectionUser[];
  coachesOrCoachesCount: number;
  coacheesOrCoaches: IConnectionUser[];
  pendingCount: number;
  pendingRequests: IConnectionRequest[];
  accepted: IConnectionRequest[];
}

// Queries
export const useGetConnections = <Error = unknown>(
  props?: { search: string },
  options?: UseQueryOptions<ConnectionsResponse, Error, UseGetConnections, ReturnType<typeof connectionQueryKeys["connectionList"]>>
) => {
  const { roleState } = useUserRoles();
  const { data: user } = useUser();
  const currentRole = roleState?.activeRole === "Expert" ? "expert" : roleState?.activeRole === "User" ? "user" : "expert";

  return useQuery<ConnectionsResponse, Error, UseGetConnections, ReturnType<typeof connectionQueryKeys["connectionList"]>>(
    connectionQueryKeys.connectionList(currentRole),
    fetchConnections,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      useErrorBoundary: true,
      suspense: true,
      select: useCallback(
        (all: ConnectionsResponse) => {
          let connections;
          const currentRole = roleState?.activeRole;
          const search = props?.search || "";
          if (currentRole === "Expert" || user?.account_type === "Coach") {
            connections = all as ExpertConnectionsResponse;

            return {
              accepted: connections.accepted,
              allCount: connections.all.length,
              allConnections: connections.all.filter(
                (c) => `${c.first_name?.toLowerCase()} ${c.last_name?.toLowerCase()}`.indexOf(search?.toLowerCase()) !== -1
              ),
              menteesOrMentorsCount: connections.mentees.length,
              menteesOrMentors: connections.mentees.filter(
                (c) => `${c.first_name?.toLowerCase()} ${c.last_name?.toLowerCase()}`.indexOf(search?.toLowerCase()) !== -1
              ),
              coachesOrCoachesCount: connections.coachees.length,
              coacheesOrCoaches: connections.coachees.filter(
                (c) => `${c.first_name?.toLowerCase()} ${c.last_name?.toLowerCase()}`.indexOf(search?.toLowerCase()) !== -1
              ),
              pendingCount: connections.pending.length,
              pendingRequests: connections.pending
            };
          } else {
            connections = all as UserConnectionsResponse;

            return {
              accepted: connections.accepted,
              coaches: connections.coaches,
              allCount: connections.all.length,
              allConnections: connections.all.filter(
                (c) => `${c.first_name?.toLowerCase()} ${c.last_name?.toLowerCase()}`.indexOf(search.toLowerCase()) !== -1
              ),
              menteesOrMentorsCount: connections.mentors.length,
              menteesOrMentors: connections.mentors.filter(
                (c) => `${c.first_name.toLowerCase()} ${c.last_name?.toLowerCase()}`.indexOf(search.toLowerCase()) !== -1
              ),
              coachesOrCoachesCount: connections.coaches.length,
              coacheesOrCoaches: connections.coaches.filter(
                (c) => `${c.first_name?.toLowerCase()} ${c.last_name?.toLowerCase()}`.indexOf(search.toLowerCase()) !== -1
              ),
              pendingCount: connections.pending.length,
              pendingRequests: connections.pending
            };
          }
        },
        [roleState?.activeRole, props, user?.account_type]
      )
    }
  );
};

export const useGetConnectionPrograms = <SelectData = IConnectionProgram[], Error = unknown>(
  { id }: { id?: string },
  options?: UseQueryOptions<IConnectionProgram[], Error, SelectData, ReturnType<typeof connectionQueryKeys["connectionPrograms"]>>
) => {
  const { roleState } = useUserRoles();

  const currentRole = roleState?.activeRole === "Expert" ? "expert" : roleState?.activeRole === "User" ? "user" : "expert";
  return useQuery<IConnectionProgram[], Error, SelectData, ReturnType<typeof connectionQueryKeys["connectionPrograms"]>>(
    connectionQueryKeys.connectionPrograms(id, currentRole),
    fetchConnectionPrograms,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      useErrorBoundary: true,
      suspense: true
    }
  );
};

export const useGetConnectionProfile = <SelectData = IUserProfile, Error = unknown>(
  { id }: { id?: string },
  options?: UseQueryOptions<IUserProfile, Error, SelectData, ReturnType<typeof connectionQueryKeys["connectionProfile"]>>
) => {
  return useQuery<IUserProfile, Error, SelectData, ReturnType<typeof connectionQueryKeys["connectionProfile"]>>(
    connectionQueryKeys.connectionProfile(id),
    fetchConnectionProfile,
    {
      staleTime: DURATIONS.fifteenMins,
      useErrorBoundary: true,
      suspense: true,
      ...options
    }
  );
};

export const useGetConnectionGoals = <SelectData = TGoal[], Error = unknown>(
  connectionId: string,
  options?: UseQueryOptions<TGoal[], Error, SelectData, ReturnType<typeof connectionQueryKeys["connectionGoals"]>>
) => {
  return useQuery<TGoal[], Error, SelectData, ReturnType<typeof connectionQueryKeys["connectionGoals"]>>(
    connectionQueryKeys.connectionGoals(connectionId),
    fetchConnectionGoals,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      useErrorBoundary: true,
      suspense: true
    }
  );
};

export const useGetConnection = <SelectData = IConnectionUser, Error = unknown>(
  { id }: { id?: string },
  options?: UseQueryOptions<IConnectionUser, Error, SelectData, ReturnType<typeof connectionQueryKeys["connection"]>>
) => {
  const { roleState } = useUserRoles();
  const currentRole = roleState?.activeRole === "Expert" ? "expert" : roleState?.activeRole === "User" ? "user" : "expert";
  const queryClient = useQueryClient();
  return useQuery<IConnectionUser, Error, SelectData, ReturnType<typeof connectionQueryKeys["connection"]>>(
    connectionQueryKeys.connection(id, currentRole),
    fetchOneConnection,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      useErrorBoundary: true,
      suspense: true,
      initialData: () => {
        const availableConnections = queryClient.getQueryData<ConnectionsResponse>(connectionQueryKeys["all"]);

        if (availableConnections) {
          if (currentRole === "user") {
            const availableConnections = queryClient.getQueryData<UserConnectionsResponse>(connectionQueryKeys["all"]);

            if (availableConnections) {
              const allConnections = [
                ...availableConnections.all
                // ...availableConnections.pending
              ];
              return allConnections?.find((connection) => connection.user._id === id);
            }
          } else {
            const availableConnections = queryClient.getQueryData<ExpertConnectionsResponse>(connectionQueryKeys["all"]);

            if (availableConnections) {
              const allConnections = [
                // ...availableConnections.coachees,
                ...availableConnections.all
                // ...availableConnections.pending
              ];
              return allConnections?.find((connection) => connection.user._id === id);
            }
          }
        }
        return undefined;
      },
      initialDataUpdatedAt: () => {
        // ✅ will refetch in the background if our list query data is older
        // than the provided staleTime.
        const availableConnections = queryClient.getQueryState(connectionQueryKeys["all"]);
        return availableConnections?.dataUpdatedAt;
      }
    }
  );
};
