import { RotatingLines } from "react-loader-spinner";
import { ActivityIndicatorWrapper, IActivityIndicatorWrapperProps } from "./ActivityIndicatorWrapper";

interface IProps {
  /**
   * @default 30
   */
  width?: string;
  wrapperProps?: IActivityIndicatorWrapperProps;
}

const ActivityIndicator = ({ width = "30", wrapperProps }: IProps) => {
  if (typeof wrapperProps !== "undefined") {
    return (
      <ActivityIndicatorWrapper {...wrapperProps}>
        <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width={width} visible />
      </ActivityIndicatorWrapper>
    );
  }

  return <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width={width} visible />;
};

export default ActivityIndicator;
