import { Box, Button, Stack, Typography } from 'knack-ui';
import SVG from '../../../components/SVG/SVG';
import { CronofyService } from '../CronofyService';
import KnackMiniLogo from '../../../components/SVG/KnackMiniLogo';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Failure } from '../../../components/ToastNotification/Failure';

export const RequestEnterpriseAuthorization = () => {
  const { search } = useLocation();
  const code = new URLSearchParams(search).get("code");
  const companyId = new URLSearchParams(search).get("_kntid");
  const state = new URLSearchParams(search).get("state");
  const history = useHistory()
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (code && state) {
      CronofyService.linkServiceAccount(code, state)
        .then((res: any) => {
          console.log(res.data.data)
          if (res?.data?.data) {
            setSuccess(true);
            setTimeout(() => {
              history.push('/')
            }, 20000)
          }
        })
        .catch(e => {
          Failure('Integration failed, Please try again or contact support')
        })
    }
  }, [code, state])

  function onConnectCalendar() {
    CronofyService.requestCronofyAuthorizationURL(null, 'ENTERPRISE_REDIRECT_URL', companyId);
  }

  return (
    <div className='flex items-center justify-center m-auto h-screen max-w-md'>
      <Box variant="outlined" paddingPreset="card">
        <Stack
          gap={4}
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="container h-full max-w-xl mx-auto"
        >
          <div className='flex flex-row items-center justify-center'>
            <div className='z-10'> <KnackMiniLogo size={40} /></div>
            <div className='-m-4 bg-white rounded-full p-4 border-2 box-shadow'> <SVG name="Calendar" className="text-primaryDark" size={25} /></div>
          </div>

          {success ?
            <div>
              <Typography textAlign="center" className='text-lg text-blue-700'>
                Congratulation, enterprise integration was successful.
              </Typography>
            </div> : <div>
              <Typography variant="body1" textAlign="center" className='font-medium'>
                As a part of the mentoring or coaching program participants will engage
                with others by scheduling multiple meetings including conference
                calls.
              </Typography>
              <Typography className="mb-6" variant="subtitle2" textAlign="center">
                Ensure to have created a service account before proceeding to authorization. <a className='text-blue-700 font-medium' rel="noreferrer" target="_blank" href="https://docs.cronofy.com/calendar-admins/enterprise-connect-guide/">click here to see guide</a>
              </Typography>

              <div className="grid grid-flow-col">
                <Button
                  variant="large"
                  kind="tertiary"
                  onClick={() => onConnectCalendar()}
                >
                  Proceed to authorization
                </Button>
              </div>
            </div>
          }
        </Stack>
      </Box>
    </div>
  )
}
