import {
  BaseDialog,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Input,
  Stack,
  Textarea,
  Typography
} from "knack-ui";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Divider from "../../components/Divider/Divider";
import EmptyState from "../../components/EmptyState/EmptyState";
import KnackSelect from "../../components/KnackSelect/KnackSelect";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import SVG from "../../components/SVG/SVG";
import { Success } from "../../components/ToastNotification/Success";
import { useGetExternalCoachingTopics } from "../../features/Programs/programQueries";
import { useUpdateCoachProfile } from "../../features/User/userMutations";
import { useUser } from "../../features/User/userQueries";
import {
  IClientTestimonial,
  ICoachAccountFormProps
} from "../../features/User/UserTypes";
import TestimonialCard from "../../V3/Pages/ConnectionProfile/ui/TestimonialCard";
import NewTestimontialDialog from "./NewTestimontialDialog";
const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" }
];
const icfOptions = [
  {
    label: "Professional Certified Coach (PCC)",
    value: "Professional Certified Coach"
  },
  {
    label: "Master Certified Coach (MCC)",
    value: "Master Certified Coach"
  },
  {
    label: "Associate Certified Coach (ACC)",
    value: "Associate Certified Coach"
  }
];
const langOptions = [
  {
    label: "English",
    value: "English"
  },
  {
    label: "Arabic",
    value: "Arabic"
  },
  {
    label: "Spanish",
    value: "Spanish"
  },
  {
    label: "French",
    value: "French"
  },
  {
    label: "Italian",
    value: "Italian"
  },
  {
    label: "Greek",
    value: "Greek"
  },
  {
    label: "Czech",
    value: "Czech"
  },
  {
    label: "Hindi",
    value: "Hindi"
  }
];

type FormProps = Omit<ICoachAccountFormProps, "spoken_languages"> & {
  spoken_languages: { label: string; value: string }[];
};

const CoachAccount = () => {
  const { data: user } = useUser();
  const { data: externalTopics } = useGetExternalCoachingTopics();
  const [experience, setExperience] = useState("");
  const [certificate, setCertificate] = useState("");
  const [testimonialsDialogOpen, setTestimonialsDialogOpen] = useState(false);
  const { mutateAsync: updateProfile } = useUpdateCoachProfile();
  const {
    register,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm<FormProps>({
    defaultValues: {
      about: user?.profile?.about,

      certifications: user?.profile?.certifications || [],
      first_name: user?.profile?.first_name,
      last_name: user?.profile?.last_name,
      tagline: user?.profile?.tagline,
      profile_image: user?.profile?.profile_image,
      linkedin_profile_url: user?.profile?.linkedin_profile_url,
      gender: user?.profile?.gender || "Male",
      spoken_languages: user?.profile?.spoken_languages?.map((l) => ({
        value: l,
        label: l
      })),
      professional_coaching_topics:
        user?.profile?.professional_coaching_topics || [],
      experience: user?.profile?.experience || [],
      icf_certificate_level: user?.profile?.icf_certificate_level,
      testimonials: user?.profile?.testimonials || []
    }
  });
  const onAddTestimonial = (t: IClientTestimonial) => {
    setValue("testimonials", [...getValues("testimonials"), t]);
  };
  const onAddExperience = (t: string) => {
    setValue("experience", [...getValues("experience"), t]);
    setExperience("");
  };
  const onAddCertificate = (t: string) => {
    setValue("certifications", [...getValues("certifications"), t]);
    setCertificate("");
  };

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      await updateProfile({
        ...data,
        spoken_languages: data.spoken_languages.map((i) => i.value)
      });
      Success("Profile updated successfully");
    } catch (reason) {
      console.error(reason);
    }
  };
  return (
    <div className="h-full">
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[
          <Link to="/dashboard">Home</Link>,
          <Typography clamp={1}>Account</Typography>
        ]}
      </Box>

      <Box variant="outlined" paddingPreset="card">
        <ProfileImage
          image={watch("profile_image")}
          onChange={(url) => setValue("profile_image", url)}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid max-w-2xl gap-8 mx-auto"
        >
          <div className="grid grid-cols-2 gap-4">
            <div>
              <Typography
                className="block mb-2"
                variant="body1"
                fontWeight="medium"
                as="label"
                htmlFor="grid-first-name"
              >
                First Name
              </Typography>

              <Input
                error={errors.first_name?.message}
                {...register("first_name", { required: "Required field" })}
                id="grid-first-name"
              />
            </div>
            <div>
              <Typography
                className="block mb-2"
                variant="body1"
                fontWeight="medium"
                as="label"
                htmlFor="grid-last-name"
              >
                Last Name
              </Typography>
              <Input
                error={errors.last_name?.message}
                {...register("last_name", { required: "Required field" })}
                id="grid-last-name"
              />
            </div>
          </div>

          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="grid-bio"
            >
              Bio
            </Typography>
            <Textarea
              placeholder="Add a brief description about yourself"
              {...register("about")}
              rows={8}
              id="grid-bio"
            />
          </div>

          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="grid-tagline"
            >
              Tagline
            </Typography>
            <Input {...register("tagline")} id="grid-tagline" />
          </div>
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="grid-linkedin"
            >
              LinkedIn profile
            </Typography>
            <Input {...register("linkedin_profile_url")} id="grid-linkedin" />
          </div>
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="gender"
            >
              Gender
            </Typography>
            <Controller
              control={control}
              name="gender"
              render={({ field: { onChange, value } }) => {
                return (
                  <KnackSelect
                    id="gender"
                    onChange={(e) => (e ? onChange(e.value) : null)}
                    value={genderOptions.find((g) => g.value === value)}
                    className="text-sm border-none rounded-none outline-none"
                    options={genderOptions}
                  />
                );
              }}
            />
          </div>
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="grid-email"
            >
              Email
            </Typography>
            <Input value={user?.email} disabled id="grid-email" />
          </div>
          <Divider />
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="icf"
            >
              ICF Cerfiticate level
            </Typography>
            <Controller
              control={control}
              name="icf_certificate_level"
              render={({ field: { onChange, value } }) => (
                <KnackSelect
                  id="icf"
                  options={icfOptions}
                  onChange={(o) => {
                    if (o) {
                      onChange(o.value);
                    } else {
                      onChange(undefined);
                    }
                  }}
                  value={icfOptions.find((i) => i.value === value)}
                />
              )}
            />
          </div>
          <Divider />
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="lang"
            >
              Spoken languages
            </Typography>
            <Controller
              control={control}
              name="spoken_languages"
              render={({ field: { onChange, value } }) => {
                return (
                  <KnackSelect
                    id="lang"
                    isMulti
                    options={langOptions}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </div>
          <Divider />
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="lang"
            >
              Professional coaching topics
            </Typography>
            <Controller
              control={control}
              name="professional_coaching_topics"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex flex-wrap gap-4">
                    {externalTopics?.map((t) => {
                      return (
                        <Chip
                          size="small"
                          shape="rounded"
                          className="cursor-pointer"
                          onClick={() => {
                            if (value.includes(t)) {
                              onChange(value.filter((i) => i !== t));
                            } else {
                              onChange([...value, t]);
                            }
                          }}
                          key={t}
                          variant={
                            value.includes(t) ? "primary" : "primaryOutline"
                          }
                        >
                          {t}
                        </Chip>
                      );
                    })}
                  </div>
                );
              }}
            />
          </div>
          <Divider />
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="lang"
            >
              Experiences
            </Typography>

            <Controller
              control={control}
              name="experience"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex flex-wrap gap-4 my-4">
                    {value.length === 0 && (
                      <EmptyState title="No Experiences added" />
                    )}
                    {value.length > 0 &&
                      value.map((e) => (
                        <div
                          className="flex items-center justify-center gap-3 px-4 py-2 border rounded"
                          key={e}
                        >
                          {e}
                          <Button
                            variant="small"
                            onClick={() => {
                              onChange(value.filter((i) => i !== e));
                            }}
                            iconOnly
                            kind="danger"
                          >
                            <SVG name="Close" size={12} />
                          </Button>
                        </div>
                      ))}
                  </div>
                );
              }}
            />
            <div>
              <Typography
                className="block mb-2"
                variant="subtitle2"
                fontWeight="medium"
                as="label"
                htmlFor="addxp"
              >
                Add experience
              </Typography>

              <Input
                id="addxp"
                placeholder="Add experience"
                value={experience}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onAddExperience(experience);
                  }
                }}
                onChange={(e) => setExperience(e.target.value)}
              />
            </div>
          </div>
          <Divider />
          <div>
            <Typography
              className="block mb-2"
              variant="body1"
              fontWeight="medium"
              as="label"
              htmlFor="lang"
            >
              Licences & Cerfiticates
            </Typography>

            <Controller
              control={control}
              name="certifications"
              render={({ field: { onChange, value } }) => {
                return (
                  <div className="flex flex-wrap gap-4 my-4">
                    {value.length === 0 && (
                      <EmptyState title="No Certifications added" />
                    )}
                    {value.length > 0 &&
                      value.map((e) => (
                        <div
                          className="flex items-center justify-center gap-3 px-4 py-2 border rounded"
                          key={e}
                        >
                          {e}
                          <Button
                            variant="small"
                            onClick={() => {
                              onChange(value.filter((i) => i !== e));
                            }}
                            iconOnly
                            kind="danger"
                          >
                            <SVG name="Close" size={12} />
                          </Button>
                        </div>
                      ))}
                  </div>
                );
              }}
            />
            <div>
              <Typography
                className="block mb-2"
                variant="subtitle2"
                fontWeight="medium"
                as="label"
                htmlFor="addxp"
              >
                Add Licence or certificate
              </Typography>

              <Input
                id="addxp"
                placeholder="Licence or certificate"
                value={certificate}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onAddCertificate(certificate);
                  }
                }}
                onChange={(e) => setCertificate(e.target.value)}
              />
            </div>
          </div>
          <Divider />
          <div>
            <Stack className="mb-2" alignItems="center" gap={4}>
              <Typography
                className="block"
                variant="body1"
                fontWeight="medium"
                as="label"
                htmlFor="grid-email"
              >
                Testimontials
              </Typography>
              <Button
                onClick={() => setTestimonialsDialogOpen(true)}
                variant="xsmall"
                startIcon={<SVG name="Add" />}
              >
                Add
              </Button>
            </Stack>
            <Controller
              control={control}
              name="testimonials"
              render={({ field: { value } }) => {
                return (
                  <div className="flex flex-wrap gap-4 my-4">
                    {value.length === 0 && (
                      <EmptyState title="No Testimonials added" />
                    )}
                    {value.length > 0 &&
                      value.map((t) => (
                        <TestimonialCard
                          onRemove={(t) => {
                            setValue(
                              "testimonials",
                              value.filter(
                                (i) =>
                                  i.comment !== t.comment && i.name !== t.name
                              )
                            );
                          }}
                          testimonial={t}
                          key={t.name}
                        />
                      ))}
                  </div>
                );
              }}
            />
          </div>
          <Divider />
          <Stack justifyContent="flex-end">
            <Button type="submit">Save Profile</Button>
          </Stack>
        </form>
        <BaseDialog
          dialogClassName="max-w-3xl"
          isOpen={testimonialsDialogOpen}
          onClose={() => setTestimonialsDialogOpen(false)}
        >
          <NewTestimontialDialog
            onAddTestimonial={onAddTestimonial}
            handleClose={() => setTestimonialsDialogOpen(false)}
          />
        </BaseDialog>
      </Box>
    </div>
  );
};

export default CoachAccount;
