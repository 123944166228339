import { useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";

export const qaResourceKeys = {
  all: [{ scope: "team" }] as const
};

async function generateDisposableEmail() {
  const res = await apiHttp.get<ServerResponse<String>>(
    "v1/auth/generate-email"
  );
  return res.data.message;
}

// Queries
export const useGetDisposableEmail = <SelectData = String, Error = unknown>(
  options?: UseQueryOptions<String, Error, SelectData>
) => {
  return useQuery<String, Error, SelectData>(
    qaResourceKeys.all,
    generateDisposableEmail,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};
