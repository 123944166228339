import { AnimatePresence } from "framer-motion";
import { Box, Button, Typography, Chip, Textarea } from "knack-ui";
import { useState } from "react";
import { motion } from "framer-motion";
import { TSessionWithProgram } from "../SessionsTypes";
import { useMarkSessionComplete, useUpdateSession } from "../sessionsMutations";
import { Failure } from "../../../components/ToastNotification/Failure";
import { Success } from "../../../components/ToastNotification/Success";
import { FadeInOut, SlideLeftWithFade } from "../../../lib/animationConstants";
import SVG from "../../../components/SVG/SVG";

interface IProps {
  onClose: VoidFunction;
  expertOrUserName: string;
  session: TSessionWithProgram;
}

const MarkSessionAsDoneDialog = ({
  expertOrUserName,
  onClose,
  session
}: IProps) => {
  const [showTopics, setShowTopics] = useState(false);
  const [rateClient, setRateClient] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [feedbackValue, setFeedbackValue] = useState<number>();
  const [comment, setComment] = useState<string>('');
  const { mutateAsync: markSessionAsDone } = useMarkSessionComplete();
  const { mutateAsync: updateSession } = useUpdateSession();
  const markSessionComplete = async (updated: TSessionWithProgram, close = false) => {
    try {
      await markSessionAsDone({
        createRatingTask: true,
        sessionId: updated._id as string
      });
      await updateSession({
        sessionId: updated._id as string,
        updates: {
          completion_status: "Completed",
          ...(session?.program?.coach_type === "knack_coaching"
            ? { topics: selectedTopics }
            : { mentoring_topics: selectedTopics }),
          ...(feedbackValue ? {
            expert_feedback: {
              question: 'How engaged was the client in the coaching session? (i.e., listened attentively, asked follow-up questions and took important notes)',
              rating_value: feedbackValue,
              additionalComment: comment
            }
          } : {}),
        }
      });
      Success("Session marked as complete");
      setSelectedTopics([]);
      if (close) {
        onClose();
      }
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  };

  const onMetClick = () => {
    if (session.type !== "Chemistry Call") {
      setShowTopics(true);
    } else {
      markSessionComplete(session, true);
    }
  };

  const onToggleTopic = (t: string) => {
    if (selectedTopics.includes(t)) {
      setSelectedTopics((prev) => prev.filter((i) => i !== t));
    } else {
      setSelectedTopics((prev) => [...prev, t]);
    }
  };
  return (
    <Box
      className="grid items-start dialog-max-h"
      style={{ gridTemplateRows: "auto 1fr auto" }}
      dir="ltr"
      elevation={0}
    >
      <div className="block p-6 border-b">
        <Typography variant="h6">Mark session as done</Typography>
        <Button
          className="z-10 end-5 top-5"
          style={{ position: "absolute" }}
          onClick={onClose}
          kind="ghost"
          variant="small"
          iconOnly
        >
          <SVG name="Close" />
        </Button>
      </div>
      <div className="p-6 overflow-x-hidden dialog-content-max-h">
        <AnimatePresence exitBeforeEnter>
          {(!showTopics && !rateClient) && (
            <motion.div
              key="met"
              variants={SlideLeftWithFade}
              exit="exit"
              initial="hidden"
              animate="visible"
              className="flex flex-col flex-1"
            >
              <Typography variant="h6" className="flex-1">
                Did you meet with {expertOrUserName}?
              </Typography>
            </motion.div>
          )}
          {showTopics && (
            <motion.div
              key="topics"
              variants={SlideLeftWithFade}
              exit="exit"
              initial="hidden"
              animate="visible"
              className="flex flex-col"
            >
              <Typography variant="h6" className="mb-4">
                Select session Topics
              </Typography>
              <div className="flex flex-wrap flex-1 gap-4 ">
                {session?.program?.skills.map((s) => (
                  <Chip
                    onClick={() => onToggleTopic(s)}
                    className="self-start cursor-pointer"
                    variant={
                      selectedTopics.includes(s) ? "primary" : "primaryOutline"
                    }
                  >
                    {s}
                  </Chip>
                ))}
              </div>
            </motion.div>
          )}
          {rateClient && session?.program?.coach_type === 'knack_coaching' && (
            <motion.div
              key="topics"
              variants={SlideLeftWithFade}
              exit="exit"
              initial="hidden"
              animate="visible"
              className="flex flex-col"
            >
              <Typography variant="h6" className="mb-2">
                How engaged was {session.user?.first_name} {session.user?.last_name}  in the coaching session (i.e., listened attentively, asked follow-up questions and took important notes)
              </Typography>
              {/* Rating Options */}
              <div className="grid grid-cols mt-4">
                <div className="grid grid-cols-10 gap-2">
                  {new Array(10).fill(0).map((r, i) => (
                    <Button kind={feedbackValue === i + 1 ? 'primary' : 'defaultOutline'} onClick={() => setFeedbackValue(i + 1)}>{i + 1}</Button>
                  ))}
                </div>
                <div className='flex justify-between mt-2'>
                  <Typography fontWeight='bold' className='text-sm'>
                    Not engaged at all
                  </Typography>
                  <Typography fontWeight='bold' className='text-sm'>
                    Very engaged
                  </Typography>
                </div>

                <Textarea
                  onChange={(e) => setComment(e.target.value)}
                  rows={4} placeholder="Additional comments or suggestions for Knack team internal use (Optional)" className='mt-8' />
              </div>

            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="p-6">
        <AnimatePresence exitBeforeEnter>
          {(!showTopics && !rateClient) && (
            <motion.div
              key="not show"
              variants={FadeInOut}
              exit="exit"
              initial="hidden"
              animate="visible"
              className="flex justify-end gap-4"
            >
              <Button kind="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onMetClick}>
                I met with {expertOrUserName}
              </Button>
            </motion.div>
          )}
          {showTopics && (
            <motion.div
              key="show"
              variants={FadeInOut}
              exit="exit"
              initial="hidden"
              animate="visible"
              className="flex justify-end gap-4"
            >
              <Button kind="ghost" onClick={() => setShowTopics(false)}>
                Back
              </Button>
              <Button
                disabled={!selectedTopics.length}
                onClick={() => {
                  if (session?.program?.coach_type === 'knack_coaching') {
                    setRateClient(true);
                    setShowTopics(false)
                  } else {
                    markSessionComplete(session)
                  }
                }}
              >
                {session.program.coach_type === 'knack_coaching' ? 'Client engagement' : 'Save topics'}
              </Button>
            </motion.div>
          )}
          {rateClient && (
            <motion.div
              key="show"
              variants={FadeInOut}
              exit="exit"
              initial="hidden"
              animate="visible"
              className="flex justify-end gap-4"
            >
              <Button kind="ghost" onClick={() => {
                setRateClient(false)
                setShowTopics(true)
              }}>
                Back
              </Button>
              <Button
                disabled={!feedbackValue}
                onClick={() => markSessionComplete(session, true)}
              >
                Save Feedback
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Box>
  );
};

export default MarkSessionAsDoneDialog;
