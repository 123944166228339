import create from "zustand";

/**
 * Monitors of the ManaualMatching Drawer
 */
export const onStartManualMatch = create((set) => ({
  isOpen: false,
  data: null,
  setOpen: () => set((state) => ({ isOpen: !state.isOpen })),
  setData: (data) => set((state) => ({ data: data }))
}));
