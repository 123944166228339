import { Tab, TabList, Tabs } from "react-tabs";
import create from "zustand";
import HrCoachingDashboard from "./HrCoachingDashboard/HrCoachingDashboard";
import HrMentoringDashboard from "./HrMentoringDashboard/HrMentoringDashboard";

interface IProps {
  mode: "Coaching" | "Mentoring";
  coachingMode: "external" | "internal";
  userDrawerOpen: boolean;
  setUserDrawerOpen: (bool: boolean) => void;
  setSelectedUserId: (id: string | null) => void;
  selectedUserId: string | null;
  setMode: (type: IProps["mode"]) => void;
  setCoachingMode: (mode: IProps["coachingMode"]) => void;
}

export const useHrDashboardMode = create<IProps>((set) => ({
  mode: "Coaching",
  coachingMode: "external",
  userDrawerOpen: false,
  selectedUserId: null,
  setMode: (mode) => set({ mode }),
  setCoachingMode: (coachingMode) => set({ coachingMode }),
  setSelectedUserId: (id) => set({ selectedUserId: id }),
  setUserDrawerOpen: (bool) => set({ userDrawerOpen: bool })
}));

const HrDashboard = () => {
  const { mode, setMode } = useHrDashboardMode();

  return (
    <Tabs defaultIndex={mode === "Coaching" ? 0 : 1}>
      <TabList className="px-4 border-b bg-paper">
        <Tab
          className="inline-block px-4 py-3 text-sm cursor-pointer "
          selectedClassName="border-b-2 border-primary font-bold"
          onClick={() => {
            setMode("Coaching");
          }}
        >
          Coaching
        </Tab>
        <Tab
          className="inline-block px-4 py-3 text-sm cursor-pointer"
          selectedClassName="border-b-2 border-primary font-bold"
          onClick={() => {
            setMode("Mentoring");
          }}
        >
          Mentoring
        </Tab>
      </TabList>
      <div style={{ height: "calc(100% - 44px)" }} className="py-6">
        {mode === "Coaching" && <HrCoachingDashboard />}
        {mode === "Mentoring" && <HrMentoringDashboard />}
      </div>
    </Tabs>
  );
};

export default HrDashboard;
