import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

/**
 * @param {String} platform Mentoring / Coaching
 * @param {String} name
 * @param {String} overview
 * @param {*} duration
 * @param {*} end_date
 * @param {*} skills
 * @param {String} mentors_csv
 * @param {String} image_url
 * @param {String} status
 * @param {Boolean} invite
 * @param {String} mentees_csv
 * @param {String} mentors_csv
 * @param {number} session_count
 * @param {HTMLElement} agreement
 *
 * @returns
 */



export const createNewProgram = (program) => {
  return axios.post(API_URL + "v1/programs", program, {
    headers: authHeader()
  });
};

export const updateNewProgram = (program, query = "") => {
  return axios.post(
    API_URL + "v1/programs/" + program._id + "/_update" + query,
    program,
    { headers: authHeader() }
  );
};

/**
 * deprecated use createNewProgram
 * @param {*} name
 * @param {*} overview
 * @param {*} type
 * @param {*} duration
 * @param {*} end_date
 * @param {*} soft_skills
 * @param {*} techinical_skills
 * @param {*} mentee_emails
 * @param {*} mentor_emails
 * @param {*} pair_confirmation
 * @param {*} match_method
 * @param {*} status
 * @returns
 */
const createProgram = (
  name,
  overview,
  type = "1:1",
  duration,
  end_date,
  soft_skills,
  techinical_skills,
  mentee_emails,
  mentor_emails,
  pair_confirmation,
  match_method,
  status
) => {
  return axios.post(
    API_URL + "v1/programs",
    {
      name,
      overview,
      type,
      duration,
      end_date,
      soft_skills,
      techinical_skills,
      mentee_emails,
      mentor_emails,
      pair_confirmation,
      match_method,
      status
    },
    { headers: authHeader() }
  );
};

/**
 *
 * @param {*} programId
 * @param {*} name
 * @param {*} overview
 * @param {*} type
 * @param {*} duration
 * @param {*} end_date
 * @param {*} soft_skills
 * @param {*} techinical_skills
 * @param {*} mentee_emails
 * @param {*} mentor_emails
 * @param {*} pair_confirmation
 * @param {*} match_method
 * @param {*} status
 * @returns
 */
const updateProgram = (
  programId,
  name,
  overview,
  type = "1:1",
  duration,
  end_date,
  soft_skills,
  techinical_skills,
  mentee_emails,
  mentor_emails,
  pair_confirmation,
  match_method,
  status
) => {
  return axios.post(
    API_URL + "v1/programs/" + programId + "/_update",
    {
      name,
      overview,
      type,
      duration,
      end_date,
      soft_skills,
      techinical_skills,
      mentee_emails,
      mentor_emails,
      pair_confirmation,
      match_method,
      status
    },
    { headers: authHeader() }
  );
};

const publishProgram = (program_id, status = 'Pending') => {
  return axios.post(
    API_URL + "v1/programs/publish",
    {
      program_id,
      status
    },
    { headers: authHeader() }
  );
};

export const getMentors = () => {
  return axios.get(API_URL + "v1/auth/get-mentors", {
    headers: authHeader()
  });
};


export const getUsersByQuery = (type) => {
  return axios.get(API_URL + "v1/auth/users/types?type=" + type, {
    headers: authHeader()
  });
};

export const getExternalCoaching = () => {
  return axios.get(API_URL + "v1/auth/users/types/external-coachees", {
    headers: authHeader()
  });
};

export const getMentees = (query = "") => {
  return axios.get(API_URL + "v1/auth/get-mentees" + query, {
    headers: authHeader()
  });
};

export const deleteProgram = (program_id) => {
  return axios.get(API_URL + "v1/programs/" + program_id + "/_delete", {
    headers: authHeader()
  });
};

/**
 * Returns registered mentors & registered mentees
 */
const getEnrolledUsers = () => {
  return axios.get(API_URL + "v1/auth/get-users-count", {
    headers: authHeader()
  });
};

const getSessionActivities = (query) => {
  return axios.get(API_URL + "sessions/dashboard/activity" + query, {
    headers: authHeader()
  });
};

const getProgramByQuery = (query) => {
  return axios.get(API_URL + "v1/programs/" + query, { headers: authHeader() });
};

const getPrograms = () => {
  const ls = localStorage.getItem("user");
  if (ls) {
    return axios.get(API_URL + "v1/programs", { headers: authHeader() });
  }
};

export const getExternalCoachingPrograms = () => {
  const ls = localStorage.getItem("user");
  if (ls) {
    return axios.get(API_URL + "v1/programs?platform=Coaching", {
      headers: authHeader()
    });
  }
};

const sessionAggregate = () => {
  return axios.get(API_URL + "sessions/session-agg", { headers: authHeader() });
};

export const getMentoringProgressForProgram = (query = "") => {
  return axios.get(API_URL + "v1/auth/mentoring-progress" + query, {
    headers: authHeader()
  });
};

/**
 *
 * @param {} program_id
 * @param {*} platform Coaching/Mentoring
 * @returns
 */
export const getProgram = (program_id, platform) => {
  return axios.get(
    API_URL + "v1/programs/" + program_id + "?platform=" + platform,
    { headers: authHeader() }
  );
};

const getProgramMentees = (programId) => {
  return axios.get(API_URL + "v1/programs/" + programId + "/get-mentees", {
    headers: authHeader()
  }); // mentees participants in program
};
export const setProgramCoachRecommendations = (userId, programId, matches) => {
  return axios.post(
    `${API_URL}v1/programs/${userId}/${programId}/matches`,
    { matches: matches },
    {
      headers: authHeader()
    }
  ); // mentees participants in program
};

const getMentorsOf_A_Mentee_Within_A_Program = (mentee, program) => {
  if (program) {
    return axios.get(
      API_URL +
        "v1/auth/mentor-requests?" +
        "mentee=" +
        mentee +
        "&program=" +
        program,
      { headers: authHeader() }
    ); // mentees participants in program
  } else {
    return axios.get(
      API_URL + "v1/auth/mentor-requests?" + "mentee=" + mentee,
      { headers: authHeader() }
    ); // mentees participants in program
  }
};

const getMenteesOf_A_Mentor_Within_A_Program = (mentor, program) => {
  if (program) {
    return axios.get(
      API_URL +
        "v1/auth/mentor-requests?" +
        "mentor=" +
        mentor +
        "&program=" +
        program,
      { headers: authHeader() }
    );
  } else {
    return axios.get(
      API_URL + "v1/auth/mentor-requests?" + "mentor=" + mentor,
      { headers: authHeader() }
    );
  }
};

export const getRequests = (query) => {
  return axios.get(API_URL + "v1/auth/mentor-requests" + query, {
    headers: authHeader()
  });
};

export const getRequestById = (id) => {
  return axios.get(API_URL + "v1/auth/mentor-requests/" + id, {
    headers: authHeader()
  });
};

const getProgramMentors = (programId) => {
  return axios.get(API_URL + "v1/programs/" + programId + "/get-mentors", {
    headers: authHeader()
  }); // mentors participants in program
};

const getProgramUnregisteredUsers = (
  programId,
  type = "mentor" | "mentee" | "coach" | "coachee"
) => {
  return axios.get(
    API_URL + "v1/programs/" + programId + "/invites?type=" + type,
    { headers: authHeader() }
  ); // invited mentors/mentees in program - i.e not yet registered to program
};

export const getProgramInvitations = (
  programId,
  type = "mentor" | "mentee" | "coach" | "coachee"
) => {
  return axios.get(
    API_URL + "v1/programs/" + programId + "/invites?type=" + type,
    { headers: authHeader() }
  ); // invited mentors/mentees in program - i.e not yet registered to program
};

export const getInvitations = (query = "") => {
  return axios.get(API_URL + "v1/programs/invites" + query, {
    headers: authHeader()
  });
};

export const getInvitationById = (id, user) => {
  return axios.get(API_URL + `v1/programs/invites/${id}?user=${user}`, {
    headers: authHeader()
  });
};

const getProgramEnrolledUsers = (programId) => {
  return axios.get(API_URL + "v1/programs/" + programId + "/get-users-count", {
    headers: authHeader()
  });
};

const getProgramSessions = (programId) => {
  return axios.get(API_URL + "sessions/programs/" + programId, {
    headers: authHeader()
  }); // session for a selected program
};

const getProgramSessionCount = (programId) => {
  return axios.get(API_URL + "sessions/count/" + programId, {
    headers: authHeader()
  }); // session for a selected program
};

/**
 * GET Get Top Learners
 */

const getTopLearners = () => {
  return axios.get(API_URL + "learnings/top-learners", {
    headers: authHeader()
  });
};

/**
 * GET Get Journeys
 */

const getJourneys = () => {
  return axios.get(API_URL + "learnings/journeys", { headers: authHeader() });
};

/**
 * Trigger match recommendation
 */
const triggerMatchRecommendation = (program_id) => {
  return axios.get(API_URL + "v1/profile/trigger-match/" + program_id, {
    headers: authHeader()
  });
};

const getCampaigns = () => {
  return axios.get(API_URL + "v1/auth/campaigns", { headers: authHeader() });
};

const createCampaign = ({ recipients, subject, body }) => {
  return axios.post(
    API_URL + "v1/auth/campaigns",
    { recipients, subject, body },
    { headers: authHeader() }
  );
};

const exports = {
  triggerMatchRecommendation,
  getMentors,
  getUsersByQuery,
  getMentees,
  getEnrolledUsers,
  getSessionActivities,
  createProgram,
  updateProgram,
  getPrograms,
  getProgram,
  getProgramSessionCount,
  getProgramMentees,
  getProgramMentors,
  getProgramUnregisteredUsers,
  getProgramInvitations,
  getProgramEnrolledUsers,
  getProgramSessions,
  sessionAggregate,
  publishProgram,
  getTopLearners,
  getJourneys,
  createNewProgram,
  deleteProgram,
  getMentorsOf_A_Mentee_Within_A_Program,
  getMenteesOf_A_Mentor_Within_A_Program,
  getMentoringProgressForProgram,
  getCampaigns,
  createCampaign,
  getInvitations,
  getRequests,
  getExternalCoachingPrograms,
  getProgramByQuery
};
export default exports;
