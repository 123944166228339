import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import { notificationKeys } from "../../../Notifications/notificationQueries";
import { useCreateSurveyResponse } from "../../surveyMutations";
import { useGetSurvey, useGetSurveyInvitation } from "../../surveyQueries";
import { ISurvey, SurveyResponseSection } from "../../surveyTypes";
import SurveyForm from "../SurveyForm/SurveyForm";

const UserSurveyInvitation = () => {
  const { invitationId } = useParams<{ invitationId?: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data: surveyInvitation } = useGetSurveyInvitation({
    id: invitationId
  });
  const { mutateAsync: submitSurvey } = useCreateSurveyResponse();

  const onResponseSubmit = async (data: SurveyResponseSection[]) => {
    await submitSurvey({ answers: data, invitationId: invitationId as string });
    await queryClient.invalidateQueries(notificationKeys.all);
    history.replace("/dashboard");

    Success("Thank you for submitting the survey");
    try {
    } catch (error) {
      Failure("Something went wrong, Please try again.");
    }
  };
  const { data: survey } = useGetSurvey(
    { id: surveyInvitation?.survey._id },
    { enabled: Boolean(surveyInvitation) }
  );
  return (
    <div>
      <SurveyForm
        onSurveySubmit={onResponseSubmit}
        survey={survey as ISurvey}
        recipientProfile={surveyInvitation?.recipientProfile}
      />
    </div>
  );
};

export default UserSurveyInvitation;
