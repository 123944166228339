import { Box, Button, Chip, Popover, Typography } from "knack-ui";
import React, { useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ISurvey, surveyTypes } from "../surveyTypes";

interface IProps {
  survey: ISurvey;
  handleDeleteSurvey: (id: string) => void;
  handleStopRecurrentMode?: (id: string) => void;
}

const SurveyCard = ({ survey, handleDeleteSurvey, handleStopRecurrentMode }: IProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Container dir="ltr" variant="outlined">
        {survey.status === "pending" && (
          <div className="absolute top-2 end-2" style={{ marginTop: 0 }}>
            <Button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(true);
              }}
              iconOnly
              kind="ghost"
              shape="rounded"
            >
              <BiDotsVerticalRounded size={20} />
            </Button>
          </div>
        )}
        {survey.status === "published" && survey.recurring?.isActive && (
          <div className="absolute top-2 end-2" style={{ marginTop: 0 }}>
            <Button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(true);
              }}
              iconOnly
              kind="ghost"
              shape="rounded"
            >
              <BiDotsVerticalRounded size={20} />
            </Button>
          </div>
        )}
        <Typography fontWeight="semibold">{survey.title}</Typography>
        <ChipsFlex>
          {survey.recurring?.isActive && survey.status === "published" && (
            <Chip variant="success" shape="rounded" size="small">
              Recurrent
            </Chip>
          )}
          <Chip shape="rounded" size="small">
            {surveyTypes.find((t) => t.type === survey.type)?.title} survey
          </Chip>
          <Chip shape="rounded" size="small" variant={survey.status === "published" ? "success" : "warning"}>
            {survey.status}
          </Chip>
        </ChipsFlex>
        <Button
          kind="tertiary"
          as={Link}
          variant="small"
          to={survey.status === "pending" ? `/edit-survey/${survey._id}` : `surveys/${survey._id}`}
          fullWidth
        >
          View details
        </Button>
      </Container>
      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={menuOpen}
        popoverProps={{ className: "py-1" }}
        onClose={() => setMenuOpen(false)}
        parentRef={buttonRef}
      >
        <Button fullWidth variant="small" kind="warning" shape="square" onClick={() => handleDeleteSurvey(survey._id)}>
          Delete survey
        </Button>
        {survey.status === "published" && survey.recurring?.isActive && handleStopRecurrentMode && (
          <Button fullWidth variant="small" kind="warning" shape="square" onClick={() => handleStopRecurrentMode(survey._id)}>
            Stop recurrency
          </Button>
        )}
      </Popover>
    </>
  );
};

export default SurveyCard;

const Container = styled(Box)`
  padding: 22px 24px;
  position: relative;
`;

const ChipsFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  margin: 16px 0;
`;
