import { useEffect, useState } from "react";
import Select from "react-select";
import { SmallInformation } from "../Information/SmallInformation";

export const ReportRangeFilter = ({ onChange, filters }) => {
  const [filter0, setFilter0] = useState();
  const [filter1, setFilter1] = useState();

  function onSelectMonth(value, index) {
    if (index === 0) setFilter0(value);
    if (index === 1) setFilter1(value);
  }

  useEffect(() => {
    onChange([filter0, filter1]);
  }, [filter0, filter1, filters]);

  return (
    <div>
      {filters.length > 1 && (
        <div className="flex flex-row items-start gap-2 max-w-screen-sm">
          <div className="flex flex-row items-center gap-2">
            <Select
              onChange={(value) => onSelectMonth(value, 0)}
              minMenuHeight={370}
              maxMenuHeight={370}
              placeholder="From Month"
              className="rounded-none outline-none border-none text-sm"
              isClearable={false}
              isSearchable={false}
              options={filters.map((f) => ({
                ...f,
                value: f.month,
                label:
                  f.month > 0 ? `After ${f.month} months` : `Month ${f.month}`
              }))}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="grid gap-1">
            <Select
              onChange={(value) => onSelectMonth(value, 1)}
              minMenuHeight={370}
              maxMenuHeight={370}
              placeholder="To Month"
              className="rounded-none outline-none border-none text-sm"
              isClearable={false}
              isSearchable={false}
              options={filters.map((f) => ({
                ...f,
                value: f?.month,
                label:
                  f?.month > 0
                    ? `After ${f?.month} months`
                    : `Month ${f?.month}`
              }))}
            />
            {filter0 && filter0?.month === filter1?.month && (
              <SmallInformation message="Select a different month" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
