import {
  useQuery,
  UseQueryOptions
} from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { IEarning, IEarningMetrics } from "./EarningTypes";

export const earningQueryKeys = {
  earningMetrics: [{ scope: "earningMetrics" }] as const,
  earnings: [{ scope: "earnings" }] as const
};

async function fetchEarningMetrics() {
  const res = await apiHttp.get<ServerResponse<IEarningMetrics>>(
    "sessions/coach/earnings/count"
  );
  return res.data.data;
}

async function fetchEarnings() {
  const res = await apiHttp.get<ServerResponse<IEarning[]>>(
    "sessions/coach/earnings"
  );
  const sorted = res.data.data.sort(
    (a, b) =>
      new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime()
  );

  return sorted;
}

// Queries
export const useGetEarningMetrics = <
  SelectData = IEarningMetrics,
  Error = unknown
>(
  options?: UseQueryOptions<IEarningMetrics, Error, SelectData>
) => {
  return useQuery<IEarningMetrics, Error, SelectData>(
    earningQueryKeys.earningMetrics,
    fetchEarningMetrics,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};

export const useGetEarnings = <SelectData = IEarning[], Error = unknown>(
  options?: UseQueryOptions<IEarning[], Error, SelectData>
) => {
  return useQuery<IEarning[], Error, SelectData>(
    earningQueryKeys.earnings,
    fetchEarnings,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};
