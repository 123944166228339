import React, { useRef } from "react";
import { StaticDialog, useDialog } from "react-st-modal";
import Select from "react-select";

export const CreateEmployee = ({
  newUser,
  setNewUser,
  teams,
  companies,
  setOpen,
  isOpen,
  onCreateUser
}) => {
  console.log(newUser);

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const positionRef = useRef();
  const organizationRef = useRef();
  const subscriptionRef = useRef();
  const teamRef = useRef();

  function willCreateUser() {
    if (
      newUser.first_name !== "" &&
      newUser.last_name !== "" &&
      newUser.email !== "" &&
      newUser.password !== "" &&
      newUser.position !== "" &&
      newUser.organization !== "" &&
      newUser.subscription !== ""
    ) {
      onCreateUser();
      try {
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        emailRef.current.value = "";
        passwordRef.current.value = "";
        positionRef.current.value = "";
        organizationRef.current.select.clearValue();
        subscriptionRef.current.select.clearValue();
        if (teamRef.current) {
          teamRef.current.select.clearValue();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="text-skin-base">
      <StaticDialog
        className="SessionRatingModal CreateUserModal scrollhost"
        isOpen={isOpen}
        onAfterClose={() => {
          setOpen(false);
          setNewUser({
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            organization: "",
            kind: "EMPLOYEE",
            subscription: "",
            team_id: "",
            team_leader: false,
            position: ""
          });
        }}
      >
        <div
          className="p-4 scrollhost2 overflow-y-auto mb-16"
          style={{ maxHeight: 700 }}
        >
          <div className="font-bold text-xl">
            {newUser._id ? "Edit Employee" : "Create Employee"}
          </div>

          <div className="mt-4">
            {/* First Name */}
            <label>
              <div className="text-sm">First Name</div>
              <input
                ref={firstNameRef}
                defaultValue={newUser.first_name}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, first_name: e.target.value }))
                }
                type="text"
                placeholder="i.e. John"
                className={`rounded-lg`}
              />
            </label>
            {/* last name */}
            <label className="mt-4 block">
              <div className="text-sm">Last Name</div>
              <input
                ref={lastNameRef}
                defaultValue={newUser.last_name}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, last_name: e.target.value }))
                }
                type="text"
                placeholder="i.e. Doe"
                className={`rounded-lg`}
              />
            </label>
            {/* Email */}
            <label className="mt-4 block">
              <div className="text-sm">Email</div>
              <input
                ref={emailRef}
                disabled={newUser._id ? true : false}
                defaultValue={newUser.email}
                onChange={(e) =>
                  setNewUser((p) => ({ ...p, email: e.target.value }))
                }
                type="email"
                placeholder="user@gmail.com"
                className={`rounded-lg ${newUser._id ? "bg-gray-300" : ""}`}
              />
            </label>

            {/* Password */}
            {!newUser._id && (
              <label className="mt-4 block">
                <div className="text-sm">Password</div>
                <input
                  ref={passwordRef}
                  onChange={(e) =>
                    setNewUser((p) => ({ ...p, password: e.target.value }))
                  }
                  type=""
                  placeholder="*******"
                  className="rounded-lg"
                />
              </label>
            )}

            {/* Coachee Position */}
            {newUser.kind === "EMPLOYEE" && (
              <label className="mt-4 block">
                <div className="text-sm">Position</div>
                <input
                  ref={positionRef}
                  onChange={(e) =>
                    setNewUser((p) => ({ ...p, position: e.target.value }))
                  }
                  type="text"
                  defaultValue={newUser.position}
                  placeholder="e.g Sales Manager"
                  className="rounded-lg"
                />
              </label>
            )}

            {/* Organisation */}
            {newUser.kind !== "COACH" && (
              <label className="mt-4 block">
                <div className="text-sm">Select Organisation</div>
                <div className="mt-1">
                  <Select
                    ref={organizationRef}
                    defaultValue={{
                      value: newUser.organization.name,
                      label: newUser.organization.name,
                      _id: newUser.organization._id
                    }}
                    onChange={(e) => {
                      if (e) {
                        setNewUser((p) => ({ ...p, organization: e._id }));
                      }
                    }}
                    className="rounded-lg"
                    classNamePrefix=""
                    isClearable={true}
                    isSearchable={true}
                    options={companies}
                  />
                </div>
              </label>
            )}

            {/* Subscription */}
            {newUser.kind !== "COACH" && newUser.kind !== "HR" && (
              <label className="mt-4 block">
                <div className="text-sm">Select Subscription</div>
                <div className="mt-1">
                  <Select
                    ref={subscriptionRef}
                    defaultValue={{
                      label: newUser.subscription,
                      value: newUser.subscription
                    }}
                    onChange={(e) =>
                      e
                        ? setNewUser((p) => ({ ...p, subscription: e.value }))
                        : null
                    }
                    className="rounded-lg"
                    classNamePrefix=""
                    isClearable={true}
                    isSearchable={true}
                    options={[
                      { value: "Hi-Potential", label: "Hi-Potential" },
                      { value: "Team", label: "Team" }
                      // { value: "Group", label: "Group" },
                    ]}
                  />
                </div>
              </label>
            )}

            {/* Team */}
            {newUser.subscription === "Team" && (
              <label className="mt-4 block">
                <div className="text-sm">Select Team</div>
                <div className="mt-1">
                  <Select
                    ref={teamRef}
                    defaultValue={{
                      label: newUser.team_id.name,
                      value: newUser.team_id.name
                    }}
                    onChange={(e) =>
                      e ? setNewUser((p) => ({ ...p, team_id: e._id })) : null
                    }
                    className="rounded-lg"
                    classNamePrefix=""
                    isClearable={true}
                    isSearchable={true}
                    options={teams.map((t) => ({
                      ...t,
                      value: t.name,
                      label: t.name
                    }))}
                    // getOptionLabel={(option) => option.name}
                    // getOptionValue={(option) => option.name}
                  />
                </div>
              </label>
            )}

            {/* Team Session Count */}
            {newUser.subscription === "Team" && (
              <label className="mt-4 block">
                <div className="text-sm">How many Sessions for this team?</div>
                <div className="mt-1">
                  <input
                    type="number"
                    className="rounded-lg"
                    onChange={(e) =>
                      setNewUser((p) => ({
                        ...p,
                        team_session_count: parseInt(e.target.value)
                      }))
                    }
                  />
                </div>
              </label>
            )}
            {/* is Team Leader */}
            {newUser.subscription === "Team" && (
              <div>
                <label
                  className="mt-4 block flex items-center cursor-pointer"
                  onClick={(e) =>
                    setNewUser((p) => ({
                      ...p,
                      team_leader: !newUser.team_leader
                    }))
                  }
                >
                  {/* Custom Toggle */}
                  <div className="mr-4">
                    <div
                      className={`h-6 w-10 rounded-full flex transition delay-350 duration-350 bg-gray-200 ${
                        newUser.team_leader ? "justify-end" : "justify-start"
                      }`}
                    >
                      <div
                        className={`h6 w-6 block rounded-full transition delay-350 duration-350 ${
                          newUser.team_leader ? "bg-skin-fill" : "bg-gray-500"
                        }`}
                      ></div>
                    </div>
                  </div>
                  <div className="text-sm">Is Team Leader ?</div>
                </label>
              </div>
            )}
          </div>

          {/* CTA */}
          <div className="flex items-center justify-end mt-16">
            <div
              className={`font-semibold text-base p-2 cursor-pointer px-4 rounded-lg ${
                newUser.email !== "" &&
                newUser.password !== "" &&
                newUser.position !== "" &&
                newUser.organization !== "" &&
                newUser.subscription !== ""
                  ? "bg-skin-fill text-white"
                  : "bg-gray-300"
              }`}
              onClick={() =>
                newUser.email !== "" &&
                newUser.password !== "" &&
                newUser.position !== "" &&
                newUser.organization !== "" &&
                newUser.subscription !== ""
                  ? willCreateUser()
                  : null
              }
            >
              {newUser?._id ? "Update" : "Create"}
            </div>
            <div
              className="font-semibold text-base p-2 cursor-pointer px-4"
              onClick={() => setOpen()}
            >
              Cancel
            </div>
          </div>
        </div>
      </StaticDialog>
    </div>
  );
};
