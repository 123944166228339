import { Checkbox, Typography } from "knack-ui";
import { IProgram, IProgramInvite } from "../../../../ProgramTypes";
import { RefObject } from "react";

interface IProps {
  program: IProgram;
  userType: IProgramInvite["type"];
  onGlobalSelectionChange: (checked: boolean) => void;
  globalCheckboxRef: RefObject<HTMLInputElement>;
}
const HrProgramParticipantsUserListTableHead = ({ program, userType, onGlobalSelectionChange, globalCheckboxRef }: IProps) => {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="flex gap-4 items-center px-2 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base"
        >
          <Checkbox ref={globalCheckboxRef} onChange={(e) => onGlobalSelectionChange(e.target.checked)} />
          <Typography>Name</Typography>
        </th>
        <th scope="col" className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base">
          Email
        </th>
        <th scope="col" className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base">
          Reminders
        </th>
        <th scope="col" className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base">
          Status
        </th>
        {program.coach_type === "knack_coaching" && (
          <th scope="col" className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base">
            Plan Status
          </th>
        )}
        <th scope="col" className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base">
          {userType === "coachee" ? "Coach" : "Mentor"}
        </th>

        <th
          scope="col"
          className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-200 text-skin-base"
        ></th>
      </tr>
    </thead>
  );
};

export default HrProgramParticipantsUserListTableHead;
