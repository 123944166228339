import SVG from "../../../../components/SVG/SVG";

export const LargeScreenModificationPanel = ({
  List = [],
  components,
  onDelete,
  willConfirmDelete,
  onEdit,
  resetList,
  onInvite,
  onSendReminder
}) => {
  function deleteMultiple() {
    if (!willConfirmDelete) {
      List.map((l) => onDelete(l._id));
      resetList();
    } else {
      onDelete();
    }
  }

  return (
    <div className="flex justify-center w-full">
      {List.length > 0 && (
        <div className="absolute bottom-0 px-4 py-2 bg-skin-accent w-full max-w-2xl mx-auto my-4 rounded-full h-16 flex flex-row items-center justify-between shadow-lg">
          <div className="flex items-center flex-row">
            <div
              onClick={() => resetList()}
              className="cursor-pointer hover:bg-gray-600 rounded-full"
            >
              <SVG name="Canceloutline" fill="#eee" />
            </div>
            <div className="text-xs ml-4 w-full">
              <span className="bg-gray-50 rounded-lg px-2 mr-2">
                {List.length}
              </span>
              <span className="text-white">items selected</span>
            </div>
          </div>

          {/* CTA */}
          <div className="grid gap-1 grid-flow-col">
            {/* Primary CTA */}
            {onSendReminder && (
              <button
                className="rounded-full text-white flex flex-row items-center p-2 text-sm mr-2 border font-bold"
                onClick={() => onSendReminder()}
              >
                <div className="mr-1">
                  <SVG name="Clock" />
                </div>
                <span className="pr-1">
                  {List.length > 1 ? "Send reminder to all" : "Send reminder"}
                </span>
              </button>
            )}
            {onInvite && (
              <button
                className="rounded-full text-white flex flex-row items-center p-2 text-sm mr-2 bg-skin-green"
                onClick={() => onInvite()}
              >
                <div className="mr-1">
                  <SVG name="Published" fill="#eee" />
                </div>
                <span className="pr-1">
                  {List.length > 1 ? "Invite all" : "Invite"}
                </span>
              </button>
            )}
            {List.length === 1 && onEdit && (
              <button
                className="rounded-full text-white flex flex-row items-center p-2 text-sm mr-2"
                style={{ background: "#dddddd36" }}
                onClick={() => onEdit(List[0])}
              >
                <div className="mr-1">
                  <SVG name="Edit" fill="#eee" />
                </div>
                <span className="pr-1">Edit</span>
              </button>
            )}
            {onDelete && (
              <button
                className="rounded-full text-white flex flex-row items-center p-2 text-sm"
                style={{ background: "#ea4c89" }}
                onClick={() => deleteMultiple()}
              >
                <div className="mr-1">
                  <SVG name="Delete" fill="#eee" />
                </div>
                <span className="pr-1">Remove</span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
