import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HeadlessModal } from "../../../../../components/modal/HeadlessModal";
import { Failure } from "../../../../../components/ToastNotification/Failure";
import { Success } from "../../../../../components/ToastNotification/Success";
import userDashboardServices from "../../../../../services/dashboard/userDashboard.services";
import Feedback360 from "../../userReports/feedback360/Feedback360";
import PersonalReport from "../../userReports/personalReports/personalReport";
import { ShareAssessmentPrompt } from "../Components.js/ShareAssessmentPrompt";
import LanguageToggler from "../../../../../components/LanguageToggler/LanguageToggler";
import { userQueryKeys, useUser, useUserCompany, useUserRoles } from "../../../../../features/User/userQueries";
import { BaseDialog } from "knack-ui";
import RequestFeedbackDialog from "../../../../../V3/Pages/Assessments/ui/RequestFeedbackDialog";
import { useGetConnections } from "../../../../../features/Connections/connectionsQueries";
import { useUpdateCoachProfile } from "../../../../../features/User/userMutations";
import { useQueryClient } from "react-query";

export const Reports = () => {
  const queryClient = useQueryClient();
  const { roleState } = useUserRoles();
  const { data: company } = useUserCompany();
  const { search } = useLocation();
  const { data: user } = useUser();
  const [openTab, setOpenTab] = React.useState(1);
  const [stateBreakdown, setStateBreakdown] = useState([]);
  const [sharePromptOpen, setSharePromptOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState();
  const [openReassessment, setOpenReassessment] = useState(false);
  const history = useHistory();
  const [requestFeedbackDialogOpen, setRequestFeedbackDialogOpen] = useState(false);
  const { data: connections } = useGetConnections(
    { search: "" },
    {
      suspense: true
    }
  );

  const handleToggleFeedbackDialog = () => {
    setRequestFeedbackDialogOpen(!requestFeedbackDialogOpen);
  };

  const { mutateAsync: updateProfile } = useUpdateCoachProfile();

  useEffect(() => {
    if (!company?.features?.includes("Assessment")) return;
    if (user?._id) {
      if (user?.profile) {
        setUpdatedProfile(user.profile);
      }

      userDashboardServices
        .getStateBreakdownbyQuery(`?user=${user._id}`)
        .then((res) => {
          if (res.data.status) {
            let { data } = res.data;
            setStateBreakdown(data);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [setStateBreakdown, user, roleState.activeRole, search, company]);

  function onShare() {
    setShareOpen(true);
    setSharePromptOpen(false);
  }

  async function onShareWillUpdateProfile() {
    try {
      await updateProfile({
        assessment_report_visible_to: updatedProfile.assessment_report_visible_to
      });
      await queryClient.invalidateQueries(userQueryKeys.user());

      Success(
        "You have updated your assessment report visibility",
        "Assessment report will only be visible to the specified mentors/coaches"
      );
      setShareOpen(false);
      setSharePromptOpen(false);
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  }

  useEffect(() => {
    if (!company?.features?.includes("Assessment")) return;

    const query = new URLSearchParams(search);
    const tab = query.get("tab");

    // if (!tab) return;
    if (tab === "2" && company?.features?.includes("360 Assessment")) {
      return setOpenTab(2);
    }
    setOpenTab(1);
  }, [company?.features, search, company]);

  function onToggleChange(e, mentorId) {
    const _profile = { ...updatedProfile };
    let assessment_report_visible_to = _profile?.assessment_report_visible_to ? [..._profile.assessment_report_visible_to] : [];

    if (!assessment_report_visible_to.includes(mentorId)) {
      assessment_report_visible_to.push(mentorId);
    } else {
      assessment_report_visible_to = assessment_report_visible_to.filter((m) => m !== mentorId);
    }
    _profile.assessment_report_visible_to = assessment_report_visible_to;
    setUpdatedProfile(_profile);
  }

  return (
    <>
      <BaseDialog dialogClassName="max-w-5xl" isOpen={requestFeedbackDialogOpen} onClose={handleToggleFeedbackDialog}>
        <RequestFeedbackDialog handleClose={handleToggleFeedbackDialog} />
      </BaseDialog>
      <HeadlessModal
        disabled={connections.length === 0}
        width="max-w-screen-md"
        open={shareOpen}
        setOpen={setShareOpen}
        canDismissFromBackdrop={true}
        showDefaultButton={true}
        defaultButtonText="Save"
        buttonFunction={() => {
          onShareWillUpdateProfile();
        }}
      >
        <div className="">
          <div className="p-4 border-b">
            <div className="text-xl font-bold sm:text-xl">
              {roleState.activeRole === "User"
                ? "Share your assessment report with your mentors / coaches"
                : "Share your assessment report with others"}
            </div>
            <div className="text-sm text-skin-muted"> Toggle to the change visibility status for each mentor / coach below </div>
          </div>
          <div className="">
            <div className="p-4 text-xs text-skin-muted"> Mentors / Coaches</div>
            {/* Mentors */}
            <div className="grid gap-4">
              {connections.allConnections
                ?.filter((f) => f.user.account_type !== "Coach")
                .map((r, i) => (
                  <div key={i} className="flex flex-row items-center justify-between px-4 py-2 border-b">
                    <div>
                      <div className="text-lg font-bold">
                        {r?.first_name} {r?.last_name}
                      </div>
                      <div className="text-xs text-skin-muted">{r?.job_function} </div>
                    </div>
                    <label className="custom-switch-button">
                      <input
                        type="checkbox"
                        onChange={(e) => onToggleChange(e, r.user?._id)}
                        defaultChecked={updatedProfile?.assessment_report_visible_to?.includes(r.user?._id) ? true : false}
                      />
                      <span className="custom-switch-slider round"></span>
                    </label>
                  </div>
                ))}
              {connections.allConnections?.filter((f) => f.user.account_type !== "Coach").length === 0 && (
                <div className="p-4 text-lg text-center text-skin-muted">
                  {" "}
                  You have no mentor / coach to share your assessment with at the moment.
                </div>
              )}
            </div>
          </div>
        </div>
      </HeadlessModal>

      <HeadlessModal
        width="max-w-screen-md"
        open={openReassessment}
        setOpen={setOpenReassessment}
        canDismissFromBackdrop={true}
        showDefaultButton={true}
        defaultButtonText="Take Assessment"
        buttonFunction={() => {
          history.push("/self-assessment");
        }}
      >
        <div className="">
          <div className="p-4 border-b">
            <div className="text-xl sm:text-xl">
              It is recommended for you to retake another assessment after a minimum 3 months of coaching,{" "}
              <strong>do you want to continue?</strong>
            </div>
          </div>
        </div>
      </HeadlessModal>
      {sharePromptOpen && <ShareAssessmentPrompt onShare={onShare} open={sharePromptOpen} setOpen={setSharePromptOpen} />}
      {stateBreakdown.length > 0 && (
        <>
          <div className="flex flex-row items-center justify-between" id="report-action-button-wrapper">
            <div className="flex flex-row items-center px-4 border-b">
              <div
                className={`cursor-pointer text-sm px-4 py-2 ${
                  openTab === 1 ? "text-skin-inverted border-b-2 border-skin-base font-bold" : ""
                }`}
                onClick={() => setOpenTab(1)}
              >
                {" "}
                Personal{" "}
              </div>
              {company?.features?.includes("360 Assessment") && (
                <div
                  className={`cursor-pointer text-sm px-4 py-2 ${
                    openTab === 2 ? "text-skin-inverted border-b-2 border-skin-base font-bold" : ""
                  }`}
                  onClick={() => setOpenTab(2)}
                >
                  {" "}
                  360 Feedback{" "}
                </div>
              )}
            </div>
            {
              <div className="flex flex-row items-center">
                <LanguageToggler reload />
                {roleState.activeRole === "User" && (
                  <div
                    onClick={() => setShareOpen(true)}
                    className="flex flex-row items-center p-2 text-sm rounded-lg cursor-pointer sm:hover:bg-gray-100"
                  >
                    <div className="hidden sm:block">Share Report</div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                      />
                    </svg>
                  </div>
                )}
                <div className="block h-6 border-r"></div>
                {openTab === 2 && (
                  <div
                    onClick={() => setRequestFeedbackDialogOpen(true)}
                    className="flex flex-row items-center p-2 text-sm rounded-lg cursor-pointer sm:hover:bg-gray-100"
                  >
                    <div className="hidden sm:block">Request feedback</div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                      />
                    </svg>
                  </div>
                )}
                {openTab === 1 && (
                  <div
                    onClick={() => setOpenReassessment(true)}
                    className="flex flex-row items-center p-2 text-sm rounded-lg cursor-pointer sm:hover:bg-gray-100"
                  >
                    <div className="hidden sm:block">Re-take assessment</div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            }
          </div>
          <div className="mt-4" id="personal-report">
            <div className={`${openTab === 1 ? "block" : "hidden"} grid gap-4`}>
              <PersonalReport isPersonal={true} />
            </div>
            <div className={openTab === 2 ? "block" : "hidden"}>
              <Feedback360 isPersonal={true} userId={user._id} canRequest={true} />
            </div>
          </div>
        </>
      )}

      {(stateBreakdown.length === 0 || !stateBreakdown || Object.keys(stateBreakdown)?.length === 0) && (
        <div
          className="max-w-screen-md p-4 mx-auto text-center rounded-lg text-skin-base bg-skin-fill-light sm:p-16 sm:mt-32"
          style={{ width: "fit-content" }}
        >
          <div className="text-2xl font-extrabold sm:text-3xl">Assessment Report</div>
          <div className="text-base text-skin-muted">
            Get a firsthand report of your core/personal skills. Find out your Top Strengths, Hidden Strengths, BlindSpots & Challenges and
            Peer reviews{" "}
          </div>
          <Link
            to="/self-assessment"
            className="block px-4 py-2 mx-auto mt-6 font-bold text-white rounded-lg bg-skin-accent"
            style={{ width: "fit-content" }}
          >
            Take Assessment
          </Link>
        </div>
      )}
    </>
  );
};
