import { useMutation, useQueryClient } from "react-query";
import { Success } from "../../components/ToastNotification/Success";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { userQueryKeys } from "./userQueries";
import { ICoachAccountFormProps, INewUserProfile, IUserAccountFormProps, IUserProfile } from "./UserTypes";

async function deleteUser(user: string) {
  const res = await apiHttp.delete<ServerResponse<boolean>>("v1/auth/users/" + user);
  return res.data.data;
}

export const uploadFileOrImage = async ({ file, type }: { file: File | Blob; type: "image" | "doc" }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  const res = await apiHttp.post("upload/aws-s3", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });

  // @ts-ignore
  const url = res.data.url;
  return url;
};

const changeUserProfileImage = async ({ file, userId }: { file: File; userId?: string }) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", "image");
  if (!userId) {
    throw new Error("Invalid userId");
  }
  const res = await apiHttp.post<{ url: string }>("upload/aws-s3", formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });

  return res.data.url;
};

async function createUserProfile(data: INewUserProfile) {
  const res = await apiHttp.post<ServerResponse<IUserProfile>>("v1/profile/init", data);
  return res.data.data;
}
async function addCompanyPosition({ position }: { position: string }) {
  const res = await apiHttp.post<ServerResponse<IUserProfile>>("v1/companies/positions", { position });
  return res.data.data;
}
async function updateCoachProfile(data: ICoachAccountFormProps) {
  const res = await apiHttp.post<ServerResponse<IUserProfile>>("v1/profile/update", data);
  return res.data.data;
}
async function updateProfile(data: Partial<IUserAccountFormProps>) {
  const res = await apiHttp.post<ServerResponse<IUserProfile>>("v1/profile/update", data);
  return res.data.data;
}

async function sendForgotPasswordRequest(data: { email: string }) {
  const res = await apiHttp.post<
    ServerResponse<{
      message: string;
      status: boolean;
    }>
  >("v1/auth/recover", data);
  return res.data.data;
}
export const useChangeUserProfileImage = () => {
  return useMutation(changeUserProfileImage);
};
export const useUploadImage = () => {
  return useMutation(changeUserProfileImage);
};
export const useCreateUserProfile = () => {
  return useMutation(createUserProfile);
};
export const useUpdateCoachProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCoachProfile, {
    onSuccess: () => queryClient.invalidateQueries(userQueryKeys.all)
  });
};
export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(userQueryKeys.all);
    }
  });
};
export const useForgotPassword = () => {
  return useMutation(sendForgotPasswordRequest);
};
export const useAddCompanyPosition = () => {
  const queryClient = useQueryClient();
  return useMutation(addCompanyPosition, {
    onSuccess: () => {
      Success("Successfully added a new position");
      queryClient.invalidateQueries(userQueryKeys.all);
    }
  });
};
export const useDeleteUser = () => {
  return useMutation(deleteUser, {
    onSuccess: () => {
      Success("User delete successfully");
    }
  });
};
