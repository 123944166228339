import { IPaymentAnalytics, IPaymentReportsQuery } from "./paymentTypes";
import { paymentAnalyticsQueryKeys } from "./paymentQueries";
import { useMutation, useQueryClient } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { PAYMENT_STATUS } from "../Sessions/SessionsTypes";

export interface IPaymentStatusUpdate {
  reference_number: string;
  payment_status: PAYMENT_STATUS;
}

async function updatePaymentStatus(earnings: IPaymentStatusUpdate[]) {
  const res = await apiHttp.post<ServerResponse<any>>(
    "v2/analytics/payments/update-status",
    { earnings }
  );
  return res.data.data;
}

// Mutations
export const useUpdatePaymentStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePaymentStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(paymentAnalyticsQueryKeys["all"]);
    }
  });
};

export interface IPaymentGenerateReportParams {
  query: IPaymentReportsQuery;
  exportAttributes: Array<keyof IPaymentAnalytics>;
}

async function generateEarningsCSVReport(
  request: IPaymentGenerateReportParams
) {
  const {
    organization,
    dateRange,
    programType,
    search_by_email_or_name,
    client,
    coach,
    paymentStatus,
    sessionMode,
    sessionStatus,
    sessionType
  } = request.query;

  const params: Record<string, any> = {
    organization: organization?.join(","),
    startDate: dateRange![0]?.startDate,
    endDate: dateRange![0]?.endDate,
    programType: programType?.join(","),
    client: client?.join(","),
    coach: coach?.join(","),
    paymentStatus: paymentStatus?.join(","),
    sessionMode: sessionMode?.join(","),
    sessionStatus: sessionStatus?.join(","),
    sessionType: sessionType?.join(","),
    search_by_email_or_name
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>(
    "v2/analytics/payments/generate-report",
    { attributes: request.exportAttributes },
    { params }
  );
  return res.data.data;
}

// Mutations
export const useGenerateEarningsCSVReport = () => {
  return useMutation(generateEarningsCSVReport);
};
