import { Chip, Stack, Typography } from "knack-ui";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetConnectionProfile } from "../../../../features/Connections/connectionsQueries";
import { motion } from "framer-motion";
import acc from "../../../../components/Asset/ACC.png";
import mcc from "../../../../components/Asset/MCC.png";
import pcc from "../../../../components/Asset/PCC.png";
import certificate from "../../../../components/Asset/certificate.png";
import { ConnectionPageTransitions } from "../ConnectionProfile";
import TestimonialCard from "./TestimonialCard";
import { ICFLevels } from "../../../../features/User/UserTypes";
import Divider from "../../../../components/Divider/Divider";
import styled from "styled-components";

const ConnectionDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const { data: profile } = useGetConnectionProfile({ id });

  const details = useMemo(() => {
    if (profile)
      return [
        ...(profile.job_function
          ? [
              {
                title: "Department",
                value: profile.job_function
              }
            ]
          : []),
        ...(profile.entity
          ? [
              {
                title: "Entity",
                value: profile.entity
              }
            ]
          : [])
      ];
    return [];
  }, [profile]);

  const icfLogo = useMemo(() => {
    if (!profile?.icf_certificate_level) {
      return null;
    }
    switch (profile.icf_certificate_level) {
      case "Associate Certified Coach":
        return acc;
      case "Master Certified Coach":
        return mcc;
      case "Professional Certified Coach":
        return pcc;
      default:
        return null;
    }
  }, [profile]);

  return (
    <Wrapper variants={ConnectionPageTransitions} exit="exit" initial="hidden" animate="visible">
      <div className="grid gap-6">
        {profile?.about && (
          <div>
            <Typography fontWeight="bold" as="h6">
              About {profile.first_name}
            </Typography>
            <AboutWrapper>{profile.about}</AboutWrapper>
          </div>
        )}
        <div className="grid grid-cols-1 gap-4">
          {details.length > 0 && (
            <div>
              <Typography fontWeight="bold" as="h6">
                Details
              </Typography>
              <DetailsGrid>
                {details.map((d) => (
                  <DetailCard key={d.title} title={d.title} value={d?.value as string} />
                ))}
              </DetailsGrid>
            </div>
          )}
          {profile?.topics && profile.topics.length > 0 && (
            <div>
              <Typography fontWeight="bold" as="h6">
                Topic interests
              </Typography>
              <TopicsList>
                {profile.topics.map((t) => (
                  <Chip as="li" shape="rounded" variant="tertiary" key={t}>
                    {t}
                  </Chip>
                ))}
              </TopicsList>
            </div>
          )}
        </div>

        {profile?.user.account_type === "Coach" && (
          <>
            {profile.professional_coaching_topics && profile.professional_coaching_topics.length > 0 && (
              <div>
                <Typography fontWeight="bold" as="h6">
                  Coaching topics
                </Typography>
                <div className="flex flex-wrap gap-4 mt-4">
                  {profile.professional_coaching_topics.map((e) => (
                    <div className="flex items-center justify-center px-3 py-1.5 text-xs rounded-full bg-tertiary text-tertiary " key={e}>
                      #{e}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Divider />
            {profile.experience && profile.experience.length > 0 && (
              <div>
                <Typography fontWeight="bold" as="h6">
                  Experience
                </Typography>
                <ul className="grid gap-4 mt-4 list-disc ps-6">
                  {profile.experience.map((e) => (
                    <li className="text-sm" key={e}>
                      {e}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <Divider />
            <div>
              <Typography fontWeight="bold" as="h6">
                Licenses & certifications
              </Typography>
              <div className="flex flex-wrap items-center gap-4 mt-4">
                {profile.icf_certificate_level && (
                  <Stack gap={3} key="1icf" alignItems="center">
                    <img className="h-16" src={icfLogo as string} alt="icf level badge" />
                    <Typography variant="body1" fontWeight="bold">
                      {profile.icf_certificate_level} ({ICFLevels[profile.icf_certificate_level]})
                    </Typography>
                  </Stack>
                )}
                {profile.certifications &&
                  profile.certifications.length > 0 &&
                  profile.certifications
                    .map<React.ReactNode>((e) => (
                      <Stack gap={3} key={e} alignItems="center">
                        <img className="h-8" src={certificate} alt="icf level badge" />
                        <Typography variant="body1" fontWeight="bold">
                          {e}
                        </Typography>
                      </Stack>
                    ))
                    .reduce((prev, curr) => [prev, <Divider vertical />, curr], [])}
              </div>
            </div>
            <Divider />
            {profile.testimonials && profile.testimonials.length > 0 && (
              <div>
                <Typography variant="body1" fontWeight="bold" as="h6">
                  Testimonials
                </Typography>
                <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3">
                  {profile.testimonials.map((t) => (
                    <TestimonialCard testimonial={t} key={t.name} />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default ConnectionDetails;

const DetailCard = ({ title, value, children }: { title: string; value?: string; children?: any }) => {
  return (
    <DetailCardWrapper>
      {title && (
        <Typography variant="subtitle2" color="muted">
          {title}
        </Typography>
      )}
      {children}
      {value && (
        <Typography variant="body1" fontWeight="semibold">
          {value}
        </Typography>
      )}
    </DetailCardWrapper>
  );
};

const Wrapper = styled(motion.div)`
  padding: 24px;
`;

const AboutWrapper = styled.div`
  margin: 24px 0;
`;
const TopicsList = styled.ul`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 24px 0;
`;

const DetailCardWrapper = styled.div`
  background-color: var(--color-gray-100);
  padding: 12px;
  border-radius: 12px;
`;

const DetailsGrid = styled.div`
  margin: 24px 0;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media ${(p) => p.theme.queries.tabletAndUp} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
