import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { ITeam, ITeamWithMemberProfile } from "./TeamTypes";

export const teamKeys = {
  all: [{ scope: "team" }] as const,
  team: (id?: string) => [{ ...teamKeys.all[0], id }] as const,
  program: (programId?: string) => [{ ...teamKeys.all[0], programId }] as const
};

async function fetchProgramTeams({
  queryKey: [{ programId }]
}: QueryFunctionContext<ReturnType<typeof teamKeys["program"]>>) {
  const res = await apiHttp.get<ServerResponse<ITeam[]>>("v1/teams", {
    params: {
      program: programId
    }
  });
  return res.data.data;
}

async function fetchTeams() {
  const res = await apiHttp.get<ServerResponse<ITeam[]>>("v1/teams");
  return res.data.data;
}

async function fetchOneTeam({
  queryKey: [{ id }]
}: QueryFunctionContext<ReturnType<typeof teamKeys["team"]>>) {
  if (!id) {
    // throw new Error("Team id is required");
  } else {
    const res = await apiHttp.get<ServerResponse<ITeamWithMemberProfile>>(
      `/v1/teams/${id}`
    );
    return res.data.data;
  }
}

// Queries
export const useGetProgramTeams = <SelectData = ITeam[], Error = unknown>(
  { programId }: { programId: string },
  options?: UseQueryOptions<
    ITeam[],
    Error,
    SelectData,
    ReturnType<typeof teamKeys["program"]>
  >
) => {
  return useQuery<
    ITeam[],
    Error,
    SelectData,
    ReturnType<typeof teamKeys["program"]>
  >(teamKeys.program(programId), fetchProgramTeams, {
    ...options,
    staleTime: DURATIONS.fifteenMins,
    suspense: true,
    keepPreviousData: true
  });
};

export const useGetTeams = <SelectData = ITeam[], Error = unknown>(
  options?: UseQueryOptions<ITeam[], Error, SelectData>
) => {
  return useQuery<ITeam[], Error, SelectData>(teamKeys.all, fetchTeams, {
    staleTime: DURATIONS.fifteenMins,
    ...options
  });
};

export const useGetOneTeam = <
  SelectData = ITeamWithMemberProfile,
  Error = unknown
>(
  { id }: { id: string | undefined },
  options?: UseQueryOptions<
    ITeamWithMemberProfile,
    Error,
    SelectData,
    ReturnType<typeof teamKeys["team"]>
  >
) => {
  return useQuery<
    ITeamWithMemberProfile,
    Error,
    SelectData,
    ReturnType<typeof teamKeys["team"]>
  >(teamKeys.team(id), fetchOneTeam, {
    ...options,
    staleTime: DURATIONS.fifteenMins,
    useErrorBoundary: true,
    suspense: true
  });
};
