import { AnimatePresence } from "framer-motion";
import { Box, Stack } from "knack-ui";
import { lazy, memo } from "react";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import useOnboardingCards from "../../../features/Onboarding/hooks/useOnboardingCards";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../lib/utils";

const LazyDashboardUpcomingSessions = lazy(() =>
  loadComponent(() => import("./DashboardUpcomingSessions"))
);

const DashboardUpcomingSessions = withSuspense(
  withErrorBoundary(LazyDashboardUpcomingSessions),
  {
    fallBackComponent: <PageSkeleton />
  }
);
const LazyDashboardExpertMatches = lazy(() =>
  loadComponent(
    () => import("../../../features/Programs/ui/DashboardExpertMatches")
  )
);

const DashboardExpertMatches = withSuspense(
  withErrorBoundary(LazyDashboardExpertMatches),
  {
    fallBackComponent: <PageSkeleton />
  }
);
interface ISideUserDashboardProps {
  handleToggleInvitations: VoidFunction;
  handleToggleOnboardingDialog: VoidFunction;
}

const SideUserDashboard = ({
  handleToggleInvitations,
  handleToggleOnboardingDialog
}: ISideUserDashboardProps) => {
  const { activeCard, cards } = useOnboardingCards({
    handleToggleInvitations,
    handleToggleOnboardingDialog
  });
  return (
    <Box
      className="max-h-full overflow-x-hidden overflow-y-auto"
      as={Stack}
      variant="outlined"
      direction="column"
      gap={4}
      style={{ flexBasis: "30%", maxWidth: "400px" }}
      paddingPreset="card"
    >
      <AnimatePresence exitBeforeEnter>{cards[activeCard]}</AnimatePresence>
      <DashboardExpertMatches />
      <DashboardUpcomingSessions />
    </Box>
  );
};

export default memo(SideUserDashboard);
