const GoalsBigIcon = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || "94"}
      height={size || "80"}
      viewBox="0 0 94 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.7825 70.565C62.7832 70.565 76.565 56.7832 76.565 39.7825C76.565 22.7818 62.7832 9 45.7825 9C28.7818 9 15 22.7818 15 39.7825C15 56.7832 28.7818 70.565 45.7825 70.565Z"
        fill="#01A085"
        fill-opacity="0.04"
      />
      <path
        d="M64.25 32.1283H47.375V35.8783H64.25V32.1283ZM64.25 47.1283H47.375V50.8783H64.25V47.1283ZM33.3875 39.6283L26.75 32.9908L29.3938 30.3471L33.3688 34.3221L41.3188 26.3721L43.9625 29.0158L33.3875 39.6283ZM33.3875 54.6283L26.75 47.9908L29.3938 45.3471L33.3688 49.3221L41.3188 41.3721L43.9625 44.0158L33.3875 54.6283Z"
        fill="#01A085"
      />
      <path
        d="M86.1178 47.2041H92.4301"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 18.6299H7.31226"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.6252 78.0723H55.2044"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.6618 56.5957H7.306"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.7589 4.99779L25.7935 1"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.7935 4.99779L21.7589 1"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.5212 69.8806L84.4908 65.8828"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M84.4908 69.8806L80.5212 65.8828"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.3869 10.1093L75.3153 7.16016"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.3153 10.1093L72.3869 7.16016"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5527 76.958L18.2447 78.662"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5527 78.662L18.2447 76.958"
        stroke="#01A085"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GoalsBigIcon;
