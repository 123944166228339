import create from "zustand";
import { IProgram } from "../../../features/Programs/ProgramTypes";

interface SessionLoggingProps {
  open: boolean;
  callbackFn: any;
  isScheduler: boolean;
  connectionPrograms: IProgram[] | null;
  connectionUserId: string | null;
  matchUserId: string | null;
  setConnectionUserId: (id: string | null) => void;
  setMatchUserId: (id: string | null) => void;
  setConnectionPrograms: (list: IProgram[] | null) => void;
  setScheduler: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  setCallBack: (fn: any) => void;
}

export const openManualSessionLogging = create<SessionLoggingProps>((set) => ({
  open: false,
  callbackFn: null,
  isScheduler: false,
  connectionPrograms: null,
  connectionUserId: null,
  matchUserId: null,
  setConnectionUserId: (id: string | null) =>
    set(() => ({ connectionUserId: id })),
  setMatchUserId: (id: string | null) => set(() => ({ matchUserId: id })),
  setConnectionPrograms: (list: IProgram[] | null) =>
    set(() => ({ connectionPrograms: list })),
  setScheduler: (value: boolean) => set(() => ({ isScheduler: value })),
  setOpen: (value: boolean) => set(() => ({ open: value })),
  setCallBack: (fn: any) => set((key) => ({ callbackFn: fn }))
}));
