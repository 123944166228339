import ContentLoader from "react-content-loader";

const ProgramCardSkeleton = () => {
  return (
    <div className="p-4 border rounded-lg ">
      <ContentLoader
        backgroundColor="#ececec"
        foregroundColor="#fafafa"
        viewBox="0 0 400 136"
        height="100%"
        width="100%"
      >
        <rect x="0" y="0" rx="5" ry="5" width="136" height="136" />
        <rect x="155" y="8" rx="5" ry="5" width="200" height="13" />
        <rect x="155" y="36" rx="5" ry="5" width="75" height="15" />
        <rect x="155" y="66" rx="5" ry="5" width="85" height="15" />
        <circle cx="175" cy="115" r="21" />
      </ContentLoader>
    </div>
  );
};

export default ProgramCardSkeleton;
