import { BaseDialog, Stack } from "knack-ui";
import { lazy, useCallback, useEffect, useState } from "react";
import OnboardingDialog from "../../features/Onboarding/ui/OnboardingDialog/OnboardingDialog";
import DashboardProgramInvitations from "../../features/Programs/ui/DashboardProgramInvitations";
import { withErrorBoundary } from "../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../lib/HOCs/withSuspense";
import { loadComponent } from "../../lib/utils";

import DashboardWelcome from "./DashboardWelcome";
import SideUserDashboard from "./SideUserDashboard/SideUserDashboard";
import DashboardGoalsSkeleton from "./skeletons/DashboardGoalsSkeleton";
import DashboardProgramsSkeleton from "./skeletons/DashboardProgramsSkeleton";
import DashboardStatsSkeleton from "./skeletons/DashboardStatsSkeleton";
import DashboardSurveyInvitationsSkeleton from "./skeletons/DashboardSurveyInvitationsSkeleton";
import ReferralSourceDialog from "../../features/Onboarding/ui/ReferralSourceDialog/ReferralSourceDialog";
import { IProgramInvite } from "../../features/Programs/ProgramTypes";
import { useGetProgramInvitations } from "../../features/Programs/programQueries";
import { DURATIONS } from "../../lib/constants";

const LazyDashboardSurveyInvitations = lazy(() => loadComponent(() => import("./DashboardSurveyInvitations")));

const DashboardSurveyInvitations = withErrorBoundary(
  withSuspense(LazyDashboardSurveyInvitations, {
    fallBackComponent: <DashboardSurveyInvitationsSkeleton />
  })
);
const LazyDashboardStats = lazy(() => loadComponent(() => import("./DashboardStats")));

const DashboardStats = withErrorBoundary(
  withSuspense(LazyDashboardStats, {
    fallBackComponent: <DashboardStatsSkeleton />
  })
);
const LazyDashboardPrograms = lazy(() => loadComponent(() => import("./DashboardPrograms")));

const DashboardPrograms = withErrorBoundary(
  withSuspense(LazyDashboardPrograms, {
    fallBackComponent: <DashboardProgramsSkeleton />
  })
);
const LazyDashboardGoals = lazy(() => loadComponent(() => import("./DashboardGoals")));

const DashboardGoals = withErrorBoundary(
  withSuspense(LazyDashboardGoals, {
    fallBackComponent: <DashboardGoalsSkeleton />
  })
);
const UserDashboard = () => {
  const [invitesOpen, setInvitesOpen] = useState(false);
  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);

  const handleToggleInvitations = useCallback(() => {
    setInvitesOpen((prev) => !prev);
  }, []);
  const handleToggleOnboardingDialog = useCallback(() => {
    setOnboardingDialogOpen((prev) => !prev);
  }, []);

  const [referralSourceOpen, setReferralSourceOpen] = useState(false);
  const [programInvite, setProgramInvite] = useState<IProgramInvite>();
  const { data: invitations } = useGetProgramInvitations(
    { confirmed: true },
    {
      select: useCallback((data: IProgramInvite[]) => {
        const filtered = data.filter(
          (p) =>
            p.sent === true &&
            p.confirmed === true &&
            !p.referralSourceAnswer &&
            (p.type === "coachee" || p.type === "mentee") &&
            p.program &&
            p.program.referralSourceOptions?.length > 0
        );
        let sorted = filtered.sort(
          // @ts-ignore
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        return sorted;
      }, []),
      staleTime: DURATIONS.thirtyMins
    }
  );

  const handleToggleReferralSourceDialog = useCallback(() => {
    setReferralSourceOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (invitations && invitations.length > 0) {
      setProgramInvite(invitations[0]);
      setReferralSourceOpen(true);
    }
  }, [invitations]);

  return (
    <Stack className="h-full" gap={2}>
      <div className="flex-1 overflow-x-hidden overflow-y-auto pe-2">
        <Stack direction="column" gap={6} className="pt-6">
          <DashboardWelcome />
          <DashboardSurveyInvitations />
          <DashboardProgramInvitations handleToggleInvitations={handleToggleInvitations} invitesOpen={invitesOpen} />
          <DashboardStats goals completedSessions enrolledPrograms />
          <DashboardGoals />
          <DashboardPrograms />
        </Stack>
      </div>
      <SideUserDashboard handleToggleOnboardingDialog={handleToggleOnboardingDialog} handleToggleInvitations={handleToggleInvitations} />

      <BaseDialog animationType="fade" dialogClassName="max-w-xl" isOpen={onboardingDialogOpen} onClose={handleToggleOnboardingDialog}>
        <OnboardingDialog onClose={handleToggleOnboardingDialog} />
      </BaseDialog>

      <BaseDialog animationType="fade" dialogClassName="max-w-xl" isOpen={referralSourceOpen} onClose={() => setReferralSourceOpen(true)}>
        <ReferralSourceDialog programInvite={programInvite as IProgramInvite} onClose={handleToggleReferralSourceDialog} />
      </BaseDialog>
    </Stack>
  );
};

export default UserDashboard;
