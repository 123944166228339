import { Box, Button, Checkbox, Stack, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import { VscClose } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import KnackImage from "../../../../components/KnackImage/KnackImage";
import logo from "../../../../components/Asset/logo.svg";

import { useEndRelationship } from "../../../../features/Connections/connectionsMutations";
import { useGetConnectionProfile, useGetConnectionPrograms } from "../../../../features/Connections/connectionsQueries";
import { IProgram } from "../../../../features/Programs/ProgramTypes";

interface IEndRelationshipModalProps {
  onClose: VoidFunction;
  id?: string;
}

const EndRelationshipModal = ({ onClose, id }: IEndRelationshipModalProps) => {
  const history = useHistory();
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const appendProgram = (id: string) => {
    if (selectedPrograms.includes(id)) {
      setSelectedPrograms((prev) => prev.filter((p) => p !== id));
    } else {
      setSelectedPrograms((prev) => [...prev, id]);
    }
  };
  const { data: connectionProgramList } = useGetConnectionPrograms({ id });
  const { data: profile } = useGetConnectionProfile({ id });
  const { mutateAsync: endRelationship } = useEndRelationship();

  function onEndRelationship() {
    try {
      selectedPrograms.map(async (_id) => {
        await endRelationship({ _id });
      });
    } catch (error) {
      console.log(error);
    }
  }

  const connectionPrograms = useMemo(() => {
    if (connectionProgramList?.length === 0) {
      history.push("/connections");
    }
    if (connectionProgramList) {
      return connectionProgramList;
    }
  }, [connectionProgramList]);

  return (
    <Box dir="ltr" elevation={0} paddingPreset="card" style={{ gridTemplateRows: "auto 1fr auto" }} className="grid dialog-max-h">
      <Button className="z-10 end-6 top-6" style={{ position: "absolute" }} onClick={onClose} kind="ghost" variant="small" iconOnly>
        <VscClose size={25} />
      </Button>
      <div>
        <Typography variant="h5" as="h2">
          Are you sure you want to end your relationship with {profile?.first_name}?
        </Typography>
        <Typography variant="body1" color="muted">
          Ending relationship will cut off all connections with {profile?.first_name}
        </Typography>
      </div>
      <Stack className="my-8 dialog-content-max-h scrollhost2" direction="column" gap={4}>
        {connectionPrograms?.map((p) => (
          <ProgramCard key={p._id} program={p} appendProgram={appendProgram} checked={selectedPrograms.includes(p._id)} />
        ))}
      </Stack>
      <Stack direction="column" gap={3}>
        <Button disabled={selectedPrograms.length === 0} kind="danger" onClick={onEndRelationship}>
          End Relationship{" "}
          {`${
            selectedPrograms.length > 0 ? `in ${selectedPrograms.length} ${selectedPrograms.length === 1 ? "program" : "programs"}` : ""
          }`}
        </Button>
        <Button onClick={onClose} kind="ghost">
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};

export default EndRelationshipModal;

const ProgramCard = ({ program, appendProgram, checked }: { program: IProgram; appendProgram: (id: string) => void; checked: boolean }) => {
  return (
    <Button
      as={Stack}
      onClick={() => {
        appendProgram(program._id);
      }}
      justifyContent="flex-start"
      kind={checked ? "success" : "ghost"}
      variant="large"
      className="cursor-pointer"
      alignItems="center"
      gap={4}
    >
      <div className="flex-shrink-0">
        <Checkbox size="large" checked={checked} />
      </div>
      <Stack alignItems="center" gap={4}>
        <KnackImage
          fallback={logo}
          className="object-cover h-full rounded-lg"
          style={{ width: 60, height: 60 }}
          fallbackProps={{ className: "object-contain" }}
          src={program?.image_url}
          alt={`${program.name} cover`}
        />
        <Stack className="flex-1" direction="column" gap={2}>
          <div>
            <Typography variant="body2" color="muted">
              Program Name
            </Typography>
            <Typography clamp={1} variant="body2" fontWeight="semibold">
              {program?.name}
            </Typography>
          </div>
        </Stack>
      </Stack>
    </Button>
  );
};
