import { useEffect, useState } from "react";
import UserDashboardService from "../../../../../services/dashboard/userDashboard.services";
import Detailed from "./detailed";
import { Failure } from "../../../../../components/ToastNotification/Failure";

export default function Feedback360({ isPersonal, userId = "", user = "", canRequest, expanded }) {
  const [reviewerSkillsBreakdown, setReviewerSkillsBreakdown] = useState([]);

  const getReviewerSkillsBreakdown360 = () => {
    let filter = `?reviewer_type=true&user=${userId}`;
    if (isPersonal) {
      filter = `?reviewer_type=true&user=${userId}`;
    }
    UserDashboardService.getSkillsBreakdown360ForUser(filter)
      .then((res) => {
        if (res.data.status === true && res.data.data.length) {
          let data = res.data.data;

          setReviewerSkillsBreakdown(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure();
      });
  };

  useEffect(() => {
    getReviewerSkillsBreakdown360();
  }, []);

  return (
    <>
      <div className="flex flex-wrap w-full">
        <Detailed
          title="Be motivated"
          subTitle="The state of being self motivated and leading life and work with purpose and meaning"
          stateData={reviewerSkillsBreakdown}
          canRequest={canRequest}
          expanded={expanded}
        />
      </div>
    </>
  );
}
