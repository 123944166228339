import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomCircularChart } from "../Chart/CustomCircularChart";
import { MultiSkillBar } from "./MultiSkillBar";

export const SkillsBreackdownCollapsiblePanelV2 = ({ SectionName, SectionDescription, skillData, latestResult = [], expanded }) => {
  const { t } = useTranslation(["assessments"]);
  const MAX_SCORE = 5;
  const localOpenState = localStorage.getItem(SectionName);
  const [open, setOpen] = useState(localOpenState ? JSON.parse(localOpenState) : false);
  const flattened = skillData.flat();
  const mappedLatestResult = latestResult.map((item) => item.avg_score_before.value);
  const Initial_Score_Percentage_per_max_score = mappedLatestResult.map((score) => Math.round((score / MAX_SCORE) * 100)); // Scale score to percentage value out of 5

  const clustered = cluster(flattened);
  function cluster(list) {
    const clustered = {};
    // eslint-disable-next-line array-callback-return
    list.map((m) => {
      if (!clustered[m.key]) {
        clustered[m.key] = [m.avg_score_before.value];
      } else {
        clustered[m.key].push(m.avg_score_before.value);
      }
    });
    return clustered;
  }

  const keys = Object.keys(clustered);

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  return (
    <div className="">
      {/* RESPONSIVE HEADER */}
      <div
        onClick={() => {
          localStorage.setItem(SectionName, !open);
          setOpen(!open);
        }}
      >
        {/* Mobile Header */}
        <div className="sm:hidden">
          <div className="flex flex-row items-center justify-between">
            {/* Percentage Score */}
            <div className="flex flex-row items-center">
              {/* Chart */}
              <CustomCircularChart classes="h-12 w-12 mr-4" scorePercentages={Initial_Score_Percentage_per_max_score} />
              {/* Title */}
              <div className="text-lg font-bold">{SectionName}</div>
            </div>

            {/* Droplet Arrow */}
            <div className={`translate transform delay-300 duration-300 ${open ? "" : "-rotate-90"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="#ddd">
                <path
                  id="Icon_ionic-md-arrow-dropdown"
                  data-name="Icon ionic-md-arrow-dropdown"
                  d="M9,13.5l9,9,9-9Z"
                  transform="translate(-9 -13.5)"
                />
              </svg>
            </div>
          </div>

          {/* Section Description */}
          <div className={`text-skin-muted my-2 ${open ? "" : "clampText"}`}>{SectionDescription}</div>
        </div>

        {/* Large Screen Header */}
        <div className="flex-row items-center justify-between hidden sm:flex">
          {/* Header */}
          <div>
            {/* Title */}
            <div className={`font-bold transition-all duration-150 delay-150 ${open ? "text-2xl" : "text-xl"}`}>{t(SectionName)}</div>
            {/* Section Description */}
            <div className={`text-skin-muted my-2 max-w-xl ${open ? "" : "clampText"}`}>{SectionDescription}</div>
          </div>

          <div className="flex flex-row items-center">
            {/* Chart */}
            <CustomCircularChart
              classes="h-24 w-24 mr-2 transition-all delay-200 duration-350"
              scorePercentages={Initial_Score_Percentage_per_max_score}
            />
            {/* Droplet Arrow */}
            <div className={`translate transform delay-300 duration-300 ${open ? "" : "-rotate-90"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="#ddd">
                <path
                  id="Icon_ionic-md-arrow-dropdown"
                  data-name="Icon ionic-md-arrow-dropdown"
                  d="M9,13.5l9,9,9-9Z"
                  transform="translate(-9 -13.5)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Mentrics */}
      <div className={`transition	duration-500 ease-in-out ${open ? "h-auto opacity-100" : "h-0 opacity-10 overflow-hidden"}`}>
        {/* Metrics Header */}
        <div className={`hidden sm:grid gap-4 bg-gray-100 rounded-lg px-4 py-2 my-4 font-medium sm:grid-cols-2`}>
          <div>Skill</div>
          <div>Score</div>
        </div>
        {/* Metrics Content */}
        <div className="list-light-border">
          {keys.map((key, i) => (
            <label key={i} className="block">
              <MultiSkillBar scoreList={clustered[key]} name={key} color={""} />
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
