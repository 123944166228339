import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CoachServices } from "../../../services/dashboard/coachDashboard.services";
import mentoringService from "../../../services/mentoring/mentoring.service";
import hrService from "../../../services/dashboard/hrDashboard.services";
import { Success } from "../../../components/ToastNotification/Success";
import { useParams } from "react-router-dom";

export const AddTaskToMentee = ({
  mentee,
  setOpenCloseDrawer,
  todos,
  refereshCallback
}) => {
  const { programId, userId } = useParams();
  const [newTask, setNewTask] = useState({
    title: "",
    details: "",
    type: "mentor",
    user: userId,
    program: programId
  });
  const [program, setProgram] = useState();
  const titleRef = useRef();
  const detailsRef = useRef();

  useEffect(() => {
    getUserProgram();
  }, [setProgram]);

  function getUserProgram() {
    hrService
      .getProgram(programId, "Mentoring")
      .then((res) => {
        if (res?.data && res.data.status === true) {
          setProgram(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  }

  function createTask() {
    mentoringService
      .createTaskForMentee({ ...newTask, program: programId, user: userId })
      .then((res) => {
        if (res.status) {
          refereshCallback(userId);
          titleRef.current.value = "";
          detailsRef.current.value = "";
          setOpenCloseDrawer(false);
          Success("Task Created Successfully.");
        }
      })
      .catch((e) => {
        toast.error("hmm, Something went wrong");
        console.log(e);
      });
  }

  function deleteItem(item) {
    CoachServices.deleteTask(item._id)
      .then((res) => {
        refereshCallback();
      })
      .catch((e) => {
        toast.error("hmm, Something went wrong");
        console.log(e);
      });
  }

  return (
    <div
      className="absolute top-0 right-0 z-50 w-full sm:w-96 p-4 bg-white mb-4 z-50 border-l"
      style={{
        height: "100vh",
        zIndex: 9999999,
        boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0%), 0 1px 2px 0 rgb(0 0 0 / 4%)"
      }}
    >
      <div className="relative h-full">
        <div className="flex items-cneter justify-between">
          <div>
            <h1 className="text-base sm:text-lg font-bold">
              Send task to{" "}
              {program?.program_type === "Mentoring" ? "mentee" : "coachee"}
            </h1>
          </div>
          <div
            onClick={() => setOpenCloseDrawer(false)}
            className="cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>

        <div className="mt-8">
          <input
            ref={titleRef}
            defaultValue={newTask.title}
            onChange={(e) =>
              setNewTask((v) => ({ ...v, title: e.target.value }))
            }
            className={`w-full border border-gray-200 my-2 text-sm focus:ring-blue-600 focus:ring focus:rounded-none outline-none h-16`}
            placeholder="Enter Title"
          />
          <textarea
            ref={detailsRef}
            defaultValue={newTask.details}
            onChange={(e) =>
              setNewTask((v) => ({ ...v, details: e.target.value }))
            }
            className="w-full border border-gray-200 my-2 text-sm focus:ring-blue-600 focus:ring focus:rounded-none outline-none"
            placeholder="Add details"
            style={{ minHeight: 200 }}
          />
        </div>

        <div className="mt-4 flex justify-end">
          <button
            className={`items-center flex justify-end px-4 py-2 rounded-lg  ${
              newTask.title !== "" && newTask.details !== ""
                ? "bg-skin-fill text-white"
                : "bg-gray-200 pointer-events-none"
            }`}
            onClick={() =>
              newTask.title !== "" && newTask.details !== ""
                ? createTask()
                : null
            }
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span>Add</span>
          </button>
        </div>

        {/* TODO List */}
        <div className="flex flex-col justify-between mt-2">
          <div className="py-4 h-full max-h-60 overflow-y-auto scrollhost evenOddBorder">
            {todos?.map((t, i) => (
              <div
                key={i}
                className="flex items-center justify-between bg-white mb-1 py-2"
              >
                <div className="flex flex-row items-center">
                  <p className="text-xs font-light mr-4">{i + 1}</p>
                  <h1 className="text-sm font-semibold">{t.title}</h1>
                </div>

                <div onClick={() => deleteItem(t)} className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
