import { Drawer } from "knack-ui";
import { lazy, ReactNode, useCallback, useMemo } from "react";
import { Tab, TabList, Tabs } from "react-tabs";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import LinearProgressWithLabel from "../../../components/LinearProgressWithLabel";
import UserDrawer from "../../../components/Profile/UserDrawer/UserDrawer";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import SuspenseWrapper from "../../../components/SuspenseWrapper";
import TopSessionsTopicsWrapper from "../../../features/Sessions/DataWrappers/TopSessionsTopicsWrapper";
import { ISession, ITopSessionTopicKey } from "../../../features/Sessions/SessionsTypes";
import { useUserCompany } from "../../../features/User/userQueries";
import { externalCoachingStats, internalCoachingStats } from "../../../lib/constants";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../lib/utils";
import DashboardStatsSkeleton from "../../UserDashboard/skeletons/DashboardStatsSkeleton";
import { useHrDashboardMode } from "../HrDashboard";
import { useGetConnectionSessions } from "../../../features/Sessions/sessionsQueries";

const LazyHrDashboardStats = lazy(() => loadComponent(() => import("../HrDashboardStats/HrDashboardStats")));
const HrDashboardStats = withErrorBoundary(
  withSuspense(LazyHrDashboardStats, {
    fallBackComponent: <DashboardStatsSkeleton count={4} />
  })
);

const LazyDashboardSatisfactionScores = lazy(() => loadComponent(() => import("../DashboardSatisfactionScores")));

const DashboardSatisfactionScores = withErrorBoundary(
  withSuspense(LazyDashboardSatisfactionScores, {
    fallBackComponent: <PageSkeleton />
  })
);
const LazyDashboardRecentSessions = lazy(() => loadComponent(() => import("../DashboardRecentSessions")));

const DashboardRecentSessions = withErrorBoundary(
  withSuspense(LazyDashboardRecentSessions, {
    fallBackComponent: <PageSkeleton />
  })
);

const LazyDashboardEmployeesProgress = lazy(() => loadComponent(() => import("../DashboardEmployeesProgress")));

const DashboardEmployeesProgress = withErrorBoundary(
  withSuspense(LazyDashboardEmployeesProgress, {
    fallBackComponent: <PageSkeleton />
  })
);
const LazyDashboardSessionsTimeline = lazy(() => loadComponent(() => import("../DashboardSessionsTimeline")));

const DashboardSessionsTimeline = withErrorBoundary(
  withSuspense(LazyDashboardSessionsTimeline, {
    fallBackComponent: <PageSkeleton />
  })
);

const HrCoachingDashboard = () => {
  const { coachingMode, setCoachingMode, selectedUserId, setSelectedUserId, userDrawerOpen, mode, setUserDrawerOpen } =
    useHrDashboardMode();
  const { data: company } = useUserCompany();
  const { data: sessions } = useGetConnectionSessions(
    {
      platform: mode === "Coaching" ? "coaching" : "mentoring",
      completion_status: "Completed",
      coachingMode: mode === "Coaching" ? (coachingMode === "external" ? "external" : "internal") : undefined
    },
    {
      select: useCallback((sessions: ISession[]) => {
        return {
          thisMonth: sessions.filter((a) => {
            return (
              new Date(a.start_time).toLocaleString("en-US", {
                year: "numeric",
                month: "long"
              }) ===
                new Date().toLocaleString("en-US", {
                  year: "numeric",
                  month: "long"
                }) && a.completion_status === "Completed"
            );
          }).length,
          all: sessions.length
        };
      }, [])
    }
  );

  const topicsSelectData = useCallback(
    (topics: ITopSessionTopicKey[]) => {
      let categories: string[] = [];
      let seriesPercentages: number[] = [];

      if (sessions) {
        categories = topics.map((d) => d.key);
        const series = topics.map((d) => d.doc_count);
        seriesPercentages = [...series].map((s) => Math.floor((s / sessions.all) * 100));
      }
      return { series: seriesPercentages, categories };
    },
    [sessions]
  );

  const sections = useMemo(() => {
    const toBeShown: ReactNode[] = [];
    let sections = coachingMode === "external" ? externalCoachingStats : internalCoachingStats;
    sections.forEach((a) => {
      switch (a) {
        case "1:1Satisfaction":
          toBeShown.push(
            <TitledDisplayBox
              title="1:1 Coaching Satisfaction score"
              subtitle={`How satisfied people at ${company?.name} with the 1:1 coaching sessions`}
            >
              <DashboardSatisfactionScores type="1:1" />
            </TitledDisplayBox>
          );
          break;
        case "groupSatisfaction":
          toBeShown.push(
            <TitledDisplayBox
              title="Group Coaching Satisfaction score"
              subtitle={`How satisfied people at ${company?.name} with the group coaching sessions`}
            >
              <DashboardSatisfactionScores type="team" />
            </TitledDisplayBox>
          );
          break;
        case "satisfaction":
          toBeShown.push(
            <TitledDisplayBox
              title="Coaching Satisfaction score"
              subtitle={`How satisfied people at ${company?.name} with the coaching sessions`}
            >
              <DashboardSatisfactionScores />
            </TitledDisplayBox>
          );
          break;
        case "recentSessions":
          toBeShown.push(
            <TitledDisplayBox title="Recent coaching sessions" subtitle={`List of coaching sessions completed by ${company?.name} Members`}>
              <DashboardRecentSessions />
            </TitledDisplayBox>
          );
          break;
        case "topics":
          toBeShown.push(
            <TitledDisplayBox
              title="Sessions top topics"
              subtitle={`What people at ${company?.name} are discussing during coaching sessions`}
            >
              <SuspenseWrapper withErrorBoundary fallback={<PageSkeleton />}>
                <TopSessionsTopicsWrapper
                  platform={mode === "Coaching" ? "coaching" : "mentoring"}
                  coachingType={coachingMode}
                  select={topicsSelectData}
                  render={(topics) => {
                    return (
                      <div className="grid gap-4">
                        {topics?.categories.map((c, i) => (
                          <LinearProgressWithLabel label={c} percentage={Number(topics.series[i].toFixed(2))} key={i} />
                        ))}
                      </div>
                    );
                  }}
                />
              </SuspenseWrapper>
            </TitledDisplayBox>
          );
          break;
        case "progress":
          toBeShown.push(
            <TitledDisplayBox
              title="Employees coaching progress"
              subtitle={`How people at ${company?.name} are progressing based on their plan`}
            >
              <DashboardEmployeesProgress />
            </TitledDisplayBox>
          );
          break;
        case "timeline":
          toBeShown.push(
            <TitledDisplayBox
              title="Coaching sessions timeline"
              subtitle={`How people at ${company?.name} are progressing based on their plan`}
            >
              <DashboardSessionsTimeline />
            </TitledDisplayBox>
          );
          break;

        default:
          break;
      }
    });
    return toBeShown;
  }, [coachingMode, company?.name, mode, topicsSelectData]);

  return (
    <Tabs
      defaultIndex={coachingMode === "external" ? 0 : 1}
      // style={{ height: "100%", overflowY: "auto" }}
    >
      <TabList className="px-4 border-b bg-paper">
        <Tab
          className="inline-block px-4 py-3 text-xs cursor-pointer "
          selectedClassName="border-b-2 border-primary font-bold"
          onClick={() => {
            setCoachingMode("external");
          }}
        >
          Knack Coaching
        </Tab>
        <Tab
          className="inline-block px-4 py-3 text-xs cursor-pointer"
          selectedClassName="border-b-2 border-primary font-bold"
          onClick={() => {
            setCoachingMode("internal");
          }}
        >
          My Coaching
        </Tab>
      </TabList>
      <div className="py-6">
        <Drawer onClose={() => setUserDrawerOpen(false)} isOpen={userDrawerOpen}>
          <UserDrawer userId={selectedUserId as string} setUserId={(id) => setSelectedUserId(id)} />
        </Drawer>
        <HrDashboardStats />
        <div className="grid grid-cols-1 gap-6 mt-6 lg:grid-cols-2">{sections}</div>
      </div>
    </Tabs>
  );
};

export default HrCoachingDashboard;
