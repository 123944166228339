import React, { useEffect, useState } from "react";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";

export const ConnectedCalendars = () => {
  const [calendars, setCalandars] = useState([]);

  useEffect(() => {
    AdminServices.getConnectedCalendars()
      .then((res) => {
        if (res.data.status) {
          setCalandars(res.data.data.events);
          // console.log(res.data.data.events)
        }
      })
      .catch((e) => console.log(e));
  }, [setCalandars]);

  return <div>Connected Calendars</div>;
};
