import { useGetPlatformPrograms } from "./../../Programs/programQueries";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Filter } from "../../../components/FiltersBar/FiltersBar.types";
import { useGetOrganizationPrograms } from "../../Programs/programQueries";
import { IProgram } from "../../Programs/ProgramTypes";
import { useGetCompanyPositions, useUser, useUserCompany } from "../../User/userQueries";
import { IOrganization, USER_TYPES_CLASS } from "../../User/UserTypes";
import { useGetUsers } from "../analyticsReportQueries";
import { IUserAnalytics, UserReportsQuery, USER_REGISTRATION_STATUS } from "../analyticsReportsTypes";
import { useGetOrganizations } from "../../Company/companyQueries";

interface IUserFilterProps {
  query: UserReportsQuery;
  onQueryChange: Dispatch<SetStateAction<UserReportsQuery>>;
}

export const useUserFilters = ({ query, onQueryChange }: IUserFilterProps) => {
  const { data: user } = useUser();
  const { data: userOrganization } = useUserCompany({ enabled: user?.account_type === "Hr" });
  const { data: organizations } = useGetOrganizations({ enabled: user?.account_type === "Admin" });
  const { data: userPrograms } = useGetOrganizationPrograms(
    {
      organizationId: userOrganization?._id
    },
    { enabled: user?.account_type === "Hr" }
  );
  const { data: programs } = useGetPlatformPrograms({}, { enabled: user?.account_type === "Admin" });

  const { data: positions } = useGetCompanyPositions();

  const [userSearchValue, setUserSearchValue] = useState<string>("");
  const [expertSearchValue, setExpertSearchValue] = useState<string>("");

  const resources = useMemo(() => {
    if (organizations) {
      return {
        departments: organizations.map((o) => o.departments).flat(),
        entities: organizations.map((o) => o.entities).flat(),
        management_levels: organizations.map((o) => o.management_levels).flat(),
        programs
      };
    }

    return {
      departments: userOrganization?.departments || [],
      entities: userOrganization?.entities || [],
      management_levels: userOrganization?.management_levels || [],
      programs: userPrograms
    };
  }, [userOrganization, organizations, programs, userPrograms]);

  const { data: userData } = useGetUsers(
    {
      userClass: ["user"],
      limit: 1000,
      search_by_email_or_name: userSearchValue
    },
    { keepPreviousData: true }
  );

  const { data: expertData } = useGetUsers(
    {
      userClass: ["expert"],
      limit: 1000,
      search_by_email_or_name: expertSearchValue
    },
    { keepPreviousData: true }
  );
  const filters = useMemo(() => {
    const f: Filter<keyof UserReportsQuery, any>[] = [];

    Object.keys(query).forEach((key) => {
      switch (key) {
        case "organization":
          if (!organizations) break;
          const organization: Filter<"organization", IOrganization> = {
            label: "Organizations",
            name: "organization",
            type: "default",
            options: {
              renderText: (o) => o.name,
              data: organizations,
              searchCriteria: (option, keyword) => {
                return option.name?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.organization!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.organization!, newValue._id],
                  type: "organization"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "organization"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.organization!.filter((id) => id !== option._id),
                  type: "organization"
                });
              }
            }
          };
          f.push(organization);
          break;

        case "program":
          if (!resources) break;
          const program: Filter<"program", IProgram> = {
            label: "Program",
            name: "program",
            type: "default",
            options: {
              renderText: (o) => o.name,
              data: resources.programs || [],
              searchCriteria: (option, keyword) => {
                return option.name?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.program!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.program!, newValue._id],
                  type: "program"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "program"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.program!.filter((id) => id !== option._id),
                  type: "program"
                });
              }
            }
          };
          f.push(program);
          break;
        case "user":
          if (!userData) break;
          const users: Filter<"user", IUserAnalytics> = {
            label: "User",
            name: "user",
            type: "default",
            options: {
              renderText: (o) => (o.user_first_name ? `${o.user_first_name} ${o.user_last_name}` : o.user_email),
              data: userData.pages.flatMap((p) => p.users),
              onSearchInputChange: (keyword) => setUserSearchValue(keyword),
              isOptionSelected: (o) => query.user!.includes(o.user_id),
              onChange: (newValue) => {
                // remove selections in userClass
                onOptionsChange({
                  newValue: [],
                  type: "userClass"
                });

                onOptionsChange({
                  newValue: [...query.user!, newValue.user_id],
                  type: "user"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "user"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.user!.filter((id) => id !== option.user_id),
                  type: "user"
                });
              }
            },
            isDisabled: Boolean(query.expert?.length) || Boolean(query.userClass?.includes("expert"))
          };
          f.push(users);
          break;
        case "expert":
          if (!expertData) break;
          const experts: Filter<"expert", IUserAnalytics> = {
            label: "Expert",
            name: "expert",
            type: "default",
            options: {
              renderText: (o) => (o.user_first_name ? `${o.user_first_name} ${o.user_last_name}` : o.user_email),
              data: expertData.pages.flatMap((p) => p.users),
              onSearchInputChange: (keyword) => setExpertSearchValue(keyword),
              isOptionSelected: (o) => query.expert!.includes(o.user_id),
              onChange: (newValue) => {
                // remove selections in userClass
                onOptionsChange({
                  newValue: [],
                  type: "userClass"
                });

                onOptionsChange({
                  newValue: [...query.expert!, newValue.user_id],
                  type: "expert"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "expert"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.expert!.filter((id) => id !== option.user_id),
                  type: "expert"
                });
              }
            },
            isDisabled: Boolean(query.user?.length) || Boolean(query.userClass?.includes("user"))
          };
          f.push(experts);
          break;
        case "userClass":
          const userClass: Filter<"userClass", USER_TYPES_CLASS> = {
            label: "User Type",
            name: "userClass",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["user", "expert"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.userClass!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.userClass!, newValue],
                  type: "userClass"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "userClass"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.userClass!.filter((id) => id !== option),
                  type: "userClass"
                });
              }
            },
            isDisabled: Boolean(query.expert?.length || query.user?.length)
          };
          f.push(userClass);
          break;
        case "userStatus":
          const userStatus: Filter<"userStatus", USER_REGISTRATION_STATUS> = {
            label: "User Status",
            name: "userStatus",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["Paired", "Unpaired", "Registered", "Unregistered"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.userStatus!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.userStatus!, newValue],
                  type: "userStatus"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "userStatus"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.userStatus!.filter((id) => id !== option),
                  type: "userStatus"
                });
              }
            }
          };
          f.push(userStatus);
          break;
        case "entities":
          if (!resources) return;
          const entity: Filter<"entities", string> = {
            label: "Entity",
            type: "default",
            name: "entities",
            options: {
              renderText: (o) => o,
              data: resources.entities,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.entities!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.entities!, newValue],
                  type: "entities"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "entities"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.entities!.filter((id) => id !== option),
                  type: "entities"
                });
              }
            }
          };
          f.push(entity);
          break;
        case "management_level":
          if (!resources) return;
          const management_level: Filter<"management_level", string> = {
            label: "Management level",
            name: "management_level",
            type: "default",
            options: {
              renderText: (o) => o,
              data: resources.management_levels,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.management_level!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.management_level!, newValue],
                  type: "management_level"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "management_level"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.management_level!.filter((id) => id !== option),
                  type: "management_level"
                });
              }
            }
          };
          f.push(management_level);
          break;
        case "department":
          if (!resources) return;
          const department: Filter<"department", string> = {
            label: "Department",
            name: "department",
            type: "default",
            options: {
              renderText: (o) => o,
              data: resources.departments,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.department!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.department!, newValue],
                  type: "department"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "department"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.department!.filter((id) => id !== option),
                  type: "department"
                });
              }
            }
          };
          f.push(department);
          break;
        case "position":
          if (!resources) return;
          const position: Filter<"position", string> = {
            label: "Position",
            name: "position",
            type: "default",
            options: {
              renderText: (o) => o,
              data: positions || [],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.position!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.position!, newValue],
                  type: "position"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "position"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.position!.filter((id) => id !== option),
                  type: "position"
                });
              }
            }
          };
          f.push(position);
          break;
      }
    });

    return f;
  }, [expertData, userOrganization, positions, programs, query, userData]);

  const onOptionsChange = <T extends keyof UserReportsQuery>({ newValue, type }: { type: T; newValue: UserReportsQuery[T] }) => {
    onQueryChange((state) => ({ ...state, [type]: newValue }));
  };
  return filters;
};
