import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { IAssessmentInvite } from "./AssessmentTypes";

export const assessmentQueryKeys = {
  all: [{ scope: "assessment" }] as const,
  connectionAssessment: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "assessment",
        user
      }
    ] as const,
  feedbackInvites: () => [{ ...assessmentQueryKeys.all[0], entity: "360Invites" }],
  connectionTopStrength: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "connectionTopStrength",
        user
      }
    ] as const,
  connectionHiddenStrength: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "connectionHiddenStrength",
        user
      }
    ] as const,
  connectionTopChallenges: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "connectionTopChallenges",
        user
      }
    ] as const,

  connectionHBlindspot: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "connectionHBlindspot",
        user
      }
    ] as const,
  connectionStateBreakdown: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "connectionStateBreakdown",
        user
      }
    ] as const,
  connectionStateBreakdownByReviewerType: (user?: string) =>
    [
      {
        ...assessmentQueryKeys.all[0],
        entity: "connectionStateBreakdownByReviewerType",
        user
      }
    ] as const
};

async function fetchConnectionSelfAssessment({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionAssessment"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/state_breakdown", {
    params: { user }
  });
  return res.data.data;
}
async function fetch360Invites() {
  const res = await apiHttp.get<ServerResponse<IAssessmentInvite[]>>("assessments/360-requests");
  return res.data.data;
}

async function fetchTopStrength({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionTopStrength"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/top_strengths", {
    params: { user }
  });
  return res.data.data;
}

async function fetchTopChallenges({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionTopChallenges"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/top_challenges", {
    params: { user }
  });
  return res.data.data;
}

async function fetchHiddenStrength({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionHiddenStrength"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/360/top_strengths/" + user);
  return res.data.data;
}

async function fetchBlindspots({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionHBlindspot"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/360/top_challenges/" + user);
  return res.data.data;
}

async function fetchStateBreakdown({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionStateBreakdown"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/skills_breakdown", {
    params: { user }
  });
  return res.data.data;
}

async function fetchStateBreakdownByReviewerType({
  queryKey: [{ user }]
}: QueryFunctionContext<ReturnType<typeof assessmentQueryKeys["connectionStateBreakdownByReviewerType"]>>) {
  if (!user) {
    return Promise.reject("Connection ID or user ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<any>>("assessments/report/360/skills_breakdown", {
    params: { user, reviewer_type: true }
  });
  return res.data.data;
}

export const useGetConnectionAssessment = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionAssessment"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionAssessment"]>>(
    assessmentQueryKeys.connectionAssessment(user),
    fetchConnectionSelfAssessment,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetTopChallenges = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionTopChallenges"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionTopChallenges"]>>(
    assessmentQueryKeys.connectionTopChallenges(user),
    fetchTopChallenges,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetTopStrength = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionTopStrength"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionTopStrength"]>>(
    assessmentQueryKeys.connectionTopStrength(user),
    fetchTopStrength,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetHiddenStrength = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionHiddenStrength"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionHiddenStrength"]>>(
    assessmentQueryKeys.connectionHiddenStrength(user),
    fetchHiddenStrength,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetBlindspot = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionHBlindspot"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionHBlindspot"]>>(
    assessmentQueryKeys.connectionHBlindspot(user),
    fetchBlindspots,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetStateBreakdown = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionStateBreakdown"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionStateBreakdown"]>>(
    assessmentQueryKeys.connectionStateBreakdown(user),
    fetchStateBreakdown,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};

export const useGetStateBreakdownByReviewerType = <SelectData = any, Error = unknown>(
  {
    user
  }: {
    user?: string;
  },
  options?: UseQueryOptions<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionStateBreakdownByReviewerType"]>>
) => {
  return useQuery<any, Error, SelectData, ReturnType<typeof assessmentQueryKeys["connectionStateBreakdownByReviewerType"]>>(
    assessmentQueryKeys.connectionStateBreakdownByReviewerType(user),
    fetchStateBreakdownByReviewerType,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,
      suspense: true,
      keepPreviousData: true
    }
  );
};
export const useGet360Invites = <SelectData = IAssessmentInvite[], Error = unknown>(
  options?: UseQueryOptions<IAssessmentInvite[], Error, SelectData, ReturnType<typeof assessmentQueryKeys["feedbackInvites"]>>
) => {
  return useQuery<IAssessmentInvite[], Error, SelectData, ReturnType<typeof assessmentQueryKeys["feedbackInvites"]>>(
    assessmentQueryKeys.feedbackInvites(),
    fetch360Invites,
    {
      ...options,
      staleTime: DURATIONS.fifteenMins,

      keepPreviousData: true
    }
  );
};
