import knackSpinnerSVG from "../Asset/KnackSpinner.svg";

interface IProps {
  text: "Loading more";
  isLoading: boolean;
}

export const ListLoadingSpinner = ({ text, isLoading }: IProps) => {
  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <div className="">
            <img
              src={knackSpinnerSVG}
              alt="loading-spinner"
              className="h-24 w-12"
            />
          </div>
          <p className="font-bold -mt-4 text-gray-500">{text}...</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
