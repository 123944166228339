import { BaseDialog } from "knack-ui";
import { Suspense, useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import create from "zustand";
import { DrawerCollection } from "../components/Drawers/DrawerCollection";
import PageSkeleton from "../components/skeletons/PageSkeleton";
import { useUpdateProfile } from "../features/User/userMutations";
import { useUser, useUserRoles } from "../features/User/userQueries";
import { useLoadingSpinner } from "../hooks/useLoadingSpinner";
import { IdleTimerSessionActivity } from "../Logging_And_Monitoring/IdleTimerSessionActivity";
import { Admin } from "../pages/dashboard/Admin/Admin";
import ClientsRoutes from "../routes/ClientsRoutes";
import CoachRoutes from "../routes/CoachRoutes";
import { KnackSchedulingSystem } from "../services/Scheduling/KnackSchedulingSystem";

export const UserStore = create<any>((set) => ({
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : null,
  profile: localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile") as string) : null,
  userRoles: [],
  currentRole: localStorage.getItem("settings") ? JSON.parse(localStorage.getItem("settings") as string).currentRole : null,
  updateUserStore: (user: any) => set(() => ({ user })),
  updateProfileStore: (profile: any) => set(() => ({ profile })),
  updateUserRoles: (roles: any) => set(() => ({ userRoles: roles })),
  setCurrentRole: (role: any) => {
    const _ls_settings = localStorage.getItem("settings");
    const parsed = _ls_settings ? JSON.parse(_ls_settings) : { currentRole: role };
    parsed.currentRole = role;
    localStorage.setItem("settings", JSON.stringify(parsed));
    return set(() => ({ currentRole: role }));
  }
}));

export const Discriminator = () => {
  const { setCurrentRole } = UserStore();
  const { data: user } = useUser({});
  const { roleState } = useUserRoles();

  useEffect(() => {
    setCurrentRole(roleState?.activeRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleState]);

  const { mutateAsync } = useUpdateProfile();
  const { loading } = useLoadingSpinner();

  //Update user timezone after
  useEffect(() => {
    // const diff = getCurrentTimezoneDiff("x");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // if (!user?.profile?.timezone || user.profile.timezone !== timezone) {
    if (user && user?.profile) {
      mutateAsync({ timezone });
    }
    // }
  }, [user]);

  return (
    <>
      <BaseDialog
        isOpen={loading}
        onClose={() => null}
        dialogClassName="w-full h-full bg-skin-lighter"
        style={{ width: "100%", borderRadius: 0 }}
      >
        <PageSkeleton />
      </BaseDialog>

      <DrawerCollection />
      <IdleTimerSessionActivity />
      <KnackSchedulingSystem />
      <Suspense fallback={<PageSkeleton height="100vh" />}>
        {user?.account_type === "Admin" || user?.account_type === "Subadmin" ? (
          <Admin />
        ) : user?.account_type === "Coach" ? (
          <CoachRoutes />
        ) : (
          <ClientsRoutes />
        )}
      </Suspense>
      <ReactQueryDevtools position="bottom-right" />
    </>
  );
};
