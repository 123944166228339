import { Avatar, AvatarGroup, Drawer } from "knack-ui";
import { useEffect, useState } from "react";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import { LoadMore } from "../../../../components/LazyLoading/LoadMore";
import UserDrawer from "../../../../components/Profile/UserDrawer/UserDrawer";
import { useGetPeople } from "../../../../features/People/peopleQueries";
import { PeopleTypes, PeopleUser } from "../../../../features/People/PeopleTypes";
import { Utilization } from "./Utilization";
import expertsEmpty from "../../../../components/Asset/EmptyStates/emptyexperts.svg";
import create from "zustand";
import { USER_TYPES_PLURALS } from "../../../../services/utils/app.constant";

export interface SearchPeople {
  value: string;
  onChange: (value: string) => void;
}
/**
 * onSearch Participant Input state changes
 */
export const onSearchPeople = create<SearchPeople>((set) => ({
  value: "",
  onChange: (value: string) => set(() => ({ value }))
}));

interface IProps {
  type: PeopleTypes;
}
export const PeopleList = ({ type }: IProps) => {
  const [ListPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { value, onChange } = onSearchPeople();
  const [selectedMentor, setSelectedMentor] = useState<string | null>(null);
  const [userDrawerOpen, setUserDrawerOpen] = useState<boolean>(false);
  const { data: peopleList } = useGetPeople(
    { type: type },
    {
      select: (peoples) => {
        if (value) {
          const _filtered = peoples.filter(
            (u) =>
              u?.email?.includes(value.toLowerCase()) ||
              `${u?.profile?.first_name || u?.first_name} ${u?.profile?.last_name || u?.last_name}`
                .toLowerCase()
                .includes(value.toLowerCase())
          );
          return _filtered;
        }
        return peoples;
      },
      suspense: true
    }
  );

  useEffect(() => {
    onChange("");
  }, []);

  return (
    <>
      <Drawer onClose={() => setUserDrawerOpen(false)} isOpen={userDrawerOpen}>
        <UserDrawer
          userId={selectedMentor as string}
          setUserId={(id) => {
            setSelectedMentor(id);
          }}
        />
      </Drawer>

      {(peopleList as PeopleUser[])?.length > 0 && (
        <div className="py-1">
          <div className="pb-10 rounded-lg shadow">
            <div className="inline-block w-full h-full min-w-full overflow-x-auto ">
              <table className="w-full h-full min-w-full leading-normal whitespace-nowrap">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                    >
                      Active Programs
                    </th>
                    {(type === "coach" || type === "mentor") && (
                      <th
                        scope="col"
                        className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                      >
                        Utilization
                      </th>
                    )}
                    {(type === "coachee" || type === "mentee") && (
                      <th
                        scope="col"
                        className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                      >
                        {type === "coachee" ? "Coaches" : "Mentors"}
                      </th>
                    )}
                    {(type === "coach" || type === "mentor") && (
                      <th
                        scope="col"
                        className="px-5 py-3 text-sm font-bold text-left text-gray-800 bg-white border-b border-gray-100 text-skin-base"
                      >
                        {type === "coach" ? "Coachees" : "Mentees"}
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {peopleList?.slice(0, page * ListPerPage).map((m, i) => (
                    <tr
                      onClick={() => {
                        setUserDrawerOpen(true);
                        setSelectedMentor(m?._id);
                      }}
                      key={i}
                    >
                      <td className="px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <div className="">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {m?.profile?.first_name} {m?.profile?.last_name}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td className="px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <div className="">
                            <p className="text-gray-900 whitespace-no-wrap">{m.email}</p>
                          </div>
                        </div>
                      </td>
                      <td
                        className="px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer white-space-break-spaces"
                        style={{ maxWidth: "268px" }}
                      >
                        <div className="flex items-center">
                          <div className="">
                            <p className="text-gray-900 whitespace-no-wrap"> {m?.profile?.position}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer">
                        {["coach", "mentor"].includes(type)
                          ? m.utilization?.activePrograms?.expert?.coaching.length +
                              m.utilization?.activePrograms?.expert?.mentoring.length || 0
                          : m.utilization?.activePrograms?.user?.mentoring.length + m.utilization?.activePrograms?.user?.coaching.length ||
                            0}
                      </td>
                      {(type === "coach" || type === "mentor") && (
                        <td className="px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer">
                          <Utilization utilization={m.utilization} />
                        </td>
                      )}
                      {(type === "coachee" || type === "mentee") && (
                        <td className="h-full px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer flex justify-start">
                          <AvatarGroup max={3}>
                            {m?.utilization?.connections[type === "coachee" ? "coaches" : "mentors"]?.map((c) => (
                              <Avatar
                                key={c._id}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setUserDrawerOpen(true);
                                  setSelectedMentor(c?.mentorProfile.user as unknown as string);
                                }}
                                className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                                text={c.mentorProfile?.first_name || ""}
                                image={c.mentorProfile?.profile_image || ""}
                              />
                            ))}
                          </AvatarGroup>
                        </td>
                      )}
                      {(type === "coach" || type === "mentor") && (
                        <td className="h-full px-5 py-5 text-sm leading-normal bg-white border-b border-gray-100 cursor-pointer flex justify-start">
                          <AvatarGroup max={3}>
                            {m?.utilization?.connections[type === "coach" ? "coachees" : "mentees"]?.map((c) => (
                              <Avatar
                                key={c._id}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setUserDrawerOpen(true);
                                  setSelectedMentor(c?.menteeProfile.user as unknown as string);
                                }}
                                className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                                text={c.menteeProfile?.first_name || ""}
                                image={c.menteeProfile?.profile_image || ""}
                              />
                            ))}
                          </AvatarGroup>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <LoadMore
                onLoad={() => {
                  setPage(page + 1);
                }}
                showHide={Math.floor((peopleList as PeopleUser[])?.length / ListPerPage) >= page}
              />
            </div>
          </div>
        </div>
      )}
      {peopleList?.length === 0 && (
        <div className="bg-white rounded shadow" style={{ height: 250 }}>
          <EmptyState title={`No ${USER_TYPES_PLURALS[type]} were found`} img={expertsEmpty} />
        </div>
      )}
    </>
  );
};
