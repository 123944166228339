import { useEffect, useState } from "react";
import userDashboardServices from "../../../services/dashboard/userDashboard.services";
import { BlindSpots } from "../ProfileTabPagesComponents/BlindSpots";
import { HiddenStrengths } from "../ProfileTabPagesComponents/HiddenStrengths";
import { SelfAssessmentResultBreakdownBySkills } from "../ProfileTabPagesComponents/SelfAssessmentResultBreakdownBySkills";
import { SelfAssessmentResultBreakdownByState } from "../ProfileTabPagesComponents/SelfAssessmentResultBreakdownByState";
import { TopChallenges } from "../ProfileTabPagesComponents/TopChallenges";
import { TopStrengths } from "../ProfileTabPagesComponents/TopStrengths";
import { ReportRangeFilter } from "../../Filter/ReportRangeFilter";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "knack-ui";
import RequestFeedbackDialog from "../../../V3/Pages/Assessments/ui/RequestFeedbackDialog";
import { useUserCompany } from "../../../features/User/userQueries";
import { useGetBlindspot, useGetHiddenStrength } from "../../../features/Assessment/assessmentQueries";

export const Assessment = ({ expanded, isPersonal, userId, user, canRequestFeedback, showStateRaderChart = true, assessmentId }) => {
  const { t } = useTranslation(["assessments"]);
  const companyColorAccent = `${window.getComputedStyle(document.documentElement).getPropertyValue("--color-accent")}`.trim();
  const colorGreen = `${window.getComputedStyle(document.documentElement).getPropertyValue("--color-green")}`.trim();
  const [filters, setFilters] = useState([]);
  const [skillsBreakdown, setSkillsBreakdown] = useState([]);
  const [topStrengths, setTopStrengths] = useState([]);
  const [topChallenges, setTopChallenges] = useState([]);
  const { data: hiddenStrength } = useGetHiddenStrength(
    { user: userId },
    {
      select: (data) => (Object.keys(data).length > 0 ? data?.map((i) => ({ ...i, key: t(i.key) })) : [])
    }
  );
  const { data: blindSpots } = useGetBlindspot(
    { user: userId },
    {
      select: (data) => (Object.keys(data).length > 0 ? data?.map((i) => ({ ...i, key: t(i.key) })) : [])
    }
  );

  const [chartData, setChartData] = useState({
    series: null,
    categories: null,
    colors: null
  });

  const IndexNamings = {
    0: "Before",
    1: "After"
  };

  const IndexColors = {
    0: companyColorAccent,
    1: colorGreen
  };

  let query = `?user=${userId}`;
  const [requestFeedbackDialogOpen, setRequestFeedbackDialogOpen] = useState(false);

  const handleToggleFeedbackDialog = () => {
    setRequestFeedbackDialogOpen(!requestFeedbackDialogOpen);
  };

  useEffect(() => {
    getStateBreakdown();
    getSkillsBreakdown();
    getTopStrengths();
    getTopChallenges();
  }, [assessmentId, setSkillsBreakdown, setTopStrengths, setTopChallenges]);

  const { data: company } = useUserCompany();

  const getStateBreakdown = () => {
    userDashboardServices
      .getStateBreakdownbyQuery(query)
      .then((res) => {
        if (res.data.status && res.data.data.length) {
          let { data } = res.data;
          if (data.length > 0) {
            const categories = data.map((r) => r.Nest.level_two_report.buckets.map((b) => t(b.key)))[0];
            const buckets = data.map((r) => r.Nest.level_two_report.buckets);
            const mappedBuckets = buckets.map((d, i) => ({
              name: IndexNamings[i],
              data: d.map((r) => r.avg_score_before.value.toFixed(1))
            }));
            const series = mappedBuckets.slice(0, 2);
            const colors = new Array(series.length).fill(0).map((c, i) => IndexColors[i]);
            setChartData({ categories, series, colors });
            let monthAfter = 3;
            let mappedFilters = [];
            mappedBuckets.map((m, i) =>
              mappedFilters.push({
                month: i === 0 ? 0 : i === 1 ? 3 : mappedFilters[i - 1].month + monthAfter,
                data: mappedBuckets[i].data
              })
            );
            setFilters(mappedFilters);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSkillsBreakdown = () => {
    userDashboardServices
      .getSkillsBreakdownForUser(query)
      .then((res) => {
        let { data } = res.data;
        if (res.data.status === true) {
          setSkillsBreakdown(data);
        }
      })
      .catch((e) => console.log(e));
  };

  const getTopStrengths = () => {
    userDashboardServices
      .getTopStrengthsForUser(query)
      .then((res) => {
        let { data } = res.data;
        if (res.data.status === true) {
          setTopStrengths(data.map((i) => ({ ...i, key: t(i.key) })));
        }
      })
      .catch((e) => console.log(e));
  };

  const getTopChallenges = () => {
    userDashboardServices
      .getTopChallengesForUser(query)
      .then((res) => {
        let { data } = res.data;
        if (res.data.status === true) {
          setTopChallenges(data.map((i) => ({ ...i, key: t(i.key) })));
        }
      })
      .catch((e) => console.log(e));
  };

  async function requestFeedback() {
    if (canRequestFeedback) {
      handleToggleFeedbackDialog();
      // await CustomDialog(<RequestFeedback />, {
      //   title: "Request 360 Feedback",
      //   showCloseIcon: true,
      //   defaultBodyOverflow: "hidden",
      //   className: "request-feedback-modal rounded-lg"
      // }).catch((e) => console.log(e));
    }
  }

  function onFilterChanged(values) {
    // console.log(values)
    const _chartData = { ...chartData };
    _chartData.series = [];
    if (values[0]) {
      _chartData.series.push({ name: values[0].label, data: values[0].data });
    }
    if (values[1]) {
      _chartData.series.push({ name: values[1].label, data: values[1].data });
    }
    if (_chartData.series.length > 0) {
      setChartData(_chartData);
    }
  }

  return (
    <div className="grid gap-4">
      <BaseDialog dialogClassName="max-w-5xl" isOpen={requestFeedbackDialogOpen} onClose={handleToggleFeedbackDialog}>
        <RequestFeedbackDialog handleClose={handleToggleFeedbackDialog} />
      </BaseDialog>
      <ReportRangeFilter filters={filters} onChange={(values) => onFilterChanged(values)} />
      <div className="grid gap-4 lg:grid-cols-2">
        {showStateRaderChart && (
          <SelfAssessmentResultBreakdownByState
            categories={chartData.categories}
            series={chartData.series}
            colors={chartData.colors}
            user={user}
            userId={userId}
          />
        )}
        <div className="grid gap-4 sm:grid-cols-2">
          <TopStrengths
            stateData={topStrengths}
            user={user}
            userId={userId}
            requestFeedback={requestFeedback}
            canRequestFeedback={canRequestFeedback}
          />
          <TopChallenges
            stateData={topChallenges}
            user={user}
            userId={userId}
            requestFeedback={requestFeedback}
            canRequestFeedback={canRequestFeedback}
          />

          {company?.features?.includes("360 Assessment") && (
            <HiddenStrengths
              stateData={hiddenStrength}
              user={user}
              userId={userId}
              requestFeedback={requestFeedback}
              canRequestFeedback={canRequestFeedback}
            />
          )}
          {company?.features?.includes("360 Assessment") && (
            <BlindSpots
              stateData={blindSpots}
              user={user}
              userId={userId}
              requestFeedback={requestFeedback}
              canRequestFeedback={canRequestFeedback}
            />
          )}
        </div>
      </div>

      <div className="mt-10 border-t">
        <SelfAssessmentResultBreakdownBySkills expanded={expanded} stateData={skillsBreakdown} user={user} userId={userId} />
      </div>
    </div>
  );
};
