import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AvatarPlaceholder } from "../../../../../components/SVG/AvatarPlaceholder";
import userDashboardServices from "../../../../../services/dashboard/userDashboard.services";
import microlearningService from "../../../../../services/learning/microlearning.service";
import { getFormattedTimeInHours } from "../../../../../services/utils/utils";
import LearnItem from "../../userLearning/microlearning/learnItem";
import { Success } from "../../../../../components/ToastNotification/Success";

export const Learning = () => {
  const [profile, setProfile] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("profile")).data
      : null
  );
  const [subscription, setSubscriptions] = useState([]);
  const [timeSpentLearning, setTimeSpentLearning] = useState(0);
  const [completedJourneys, setCompletedJourneys] = useState(0);
  const [bookmarks, setBookmarks] = useState([]);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    getSubscriptions();
    getDashActivity();
    getJourneys();
    getBookmarks();
  }, [setSubscriptions]);

  const getSubscriptions = () => {
    microlearningService
      .getSubscriptions()
      .then((res) => {
        if (res.data.status) {
          setSubscriptions(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const getDashActivity = () => {
    userDashboardServices
      .getTimeSpentLearning()
      .then((res) => {
        if (res.data.status) {
          setTimeSpentLearning(res.data.data.total);
        }
      })
      .catch((e) => console.log(e));
  };

  const getJourneys = () => {
    microlearningService
      .getJourneys()
      .then((res) => {
        if (res.data.status) {
          const filtered = [...res.data.data.journeys].filter(
            (j) => j.contentCount === j.completedCount
          ).length;
          setCompletedJourneys(filtered);
        }
      })
      .catch((e) => console.log(e));
  };

  const getBookmarks = () => {
    microlearningService
      .getBookmarked()
      .then((res) => {
        if (res.data.status) {
          setBookmarks(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const calculateRadius = (total, completed) => {
    if (completed === 0) return 0;
    const currentTotal = total;
    const currentCompleted = completed;
    const currentDash = currentTotal - currentCompleted;
    const computedDash = currentDash > 10 ? currentDash : currentDash * 10;
    const finalDash = 100 - computedDash;
    return finalDash;
  };

  const markStarted = (resource_id) => {
    microlearningService
      .markStarted(resource_id)
      .then((res) => {
        if (res.data.status) {
          setStarted(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const markCompleted = (status, resource_id, title) => {
    microlearningService
      .markCompleted(!status, resource_id)
      .then((res) => {
        if (res.data.status) {
          Success(`You completed ${title}`);
          getBookmarks();
        }
      })
      .catch((e) => console.log(e));
  };

  const bookmarkArticle = (status, resource_id, title) => {
    microlearningService
      .bookmarkArticle(!status, resource_id)
      .then((res) => {
        if (res.status) {
          getBookmarks();
          Success(`You removed ${title} from your bookmarks`);
        }
      })
      .catch((e) => console.log(e));
  };

  const openUrl = (url, resource_id) => {
    window.open(url);
    markStarted(resource_id);
  };

  const addTodo = (title, user, type, id) => {
    userDashboardServices
      .addTodo(title, user, type, id)
      .then((res) => {
        if (res.data.status) {
          getBookmarks();
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="">
      <div className="">
        {/* Header */}
        <div className="relative flex flex-col items-center justify-center p-4 text-center text-white bg-white rounded-t-lg shadow sm:justify-start sm:flex-row sm:text-left">
          {/* <div className="absolute left-0 z-0 h-full overflow-hidden bg-white rounded-t-lg shadow max-h-64"></div> */}

          {/* Photo */}
          <AvatarPlaceholder
            classes="h-24 w-24"
            imageUrl={profile?.user.profile_image}
          />
          {/*  */}
          <div className="sm:ml-4">
            {/* Name */}
            <div className="text-2xl font-bold text-skin-base">
              {profile?.first_name} {profile?.last_name}
            </div>
            {/* Metrics */}
            <div className="flex items-center justify-center sm:justify-start text-skin-base">
              <div className="mr-4">
                <span className="text-base font-semibold ">
                  {completedJourneys}
                </span>{" "}
                <span className="text-xs">Completed Journeys</span>
              </div>
              <div className="">
                <span className="text-base font-semibold">
                  {getFormattedTimeInHours(timeSpentLearning)}
                </span>{" "}
                <span className="text-xs">Time Spent</span>
              </div>
            </div>
            {/* CTA */}
            <div className="flex flex-row justify-center mt-4 sm:justify-start">
              {/* <Link to="microlearnings" className="block px-4 py-2 mr-4 text-xs font-bold bg-white rounded-lg text-skin-inverted">Explore MicroLearning</Link> */}
              <Link
                to="journeys"
                className="block px-4 py-2 text-xs font-bold text-white rounded-lg bg-skin-fill"
              >
                Explore Journeys
              </Link>
            </div>
          </div>
        </div>

        {/* Learning Subscriptions */}
        <div className="p-4">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <defs>
                <radialGradient
                  id="radial-gradient"
                  cx="1.052"
                  cy="0.973"
                  r="0.737"
                  gradientUnits="objectBoundingBox"
                >
                  <stop offset="0" stopColor="#ec5569" />
                  <stop offset="0.123" stopColor="#fdba4c" />
                  <stop offset="0.285" stopColor="#2d3958" />
                  <stop offset="0.334" stopColor="var(--color-accent)" />
                  <stop offset="1" stopColor="#00a085" />
                </radialGradient>
              </defs>
              <path
                id="Path_441"
                data-name="Path 441"
                d="M11,3A1,1,0,0,0,9,3V4a1,1,0,0,0,2,0Zm4.657,2.757a1,1,0,1,0-1.414-1.414l-.707.707A1,1,0,1,0,14.95,6.464l.707-.707ZM18,10a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h1A1,1,0,0,1,18,10ZM5.05,6.464A1,1,0,1,0,6.464,5.05l-.707-.707A1,1,0,1,0,4.343,5.757l.707.707ZM5,10a1,1,0,0,1-1,1H3A1,1,0,0,1,3,9H4A1,1,0,0,1,5,10Zm3,6V15h4v1a2,2,0,0,1-4,0Zm4-2a1.187,1.187,0,0,1,.477-.859,4,4,0,1,0-4.954,0A1.18,1.18,0,0,1,8,14h4Z"
                transform="translate(-2 -2)"
                fill="url(#radial-gradient)"
              />
            </svg>
            <div className="ml-4 text-sm font-semibold text-skin-base">
              Continue Learning
            </div>
          </div>

          {/* Subscription List */}
          <div className="grid gap-4 mt-4 sm:grid-cols-3 lg:grid-cols-4">
            {subscription.map((s, i) => (
              <Link
                key={i}
                to={{ pathname: "journey", state: { slug: s._id } }}
                className="block bg-white rounded-lg shadow"
              >
                <div className="">
                  {/* Image */}
                  <div className="h-48">
                    <img
                      src={s.image}
                      loading="lazy"
                      className="object-cover w-full h-full rounded-t-lg"
                    />
                  </div>
                  <div className="p-2">
                    {/* Title */}
                    <div className="text-sm font-bold clampText sm:text-base text-skin-base">
                      {s.title}
                    </div>
                    {/* Metrics */}
                    <div className="flex flex-row items-center mt-2 text-xs font-semibold text-skin-muted">
                      {/* Chart */}
                      <div className="mr-1">
                        <div className="flex items-center justify-center w-3 h-3">
                          <svg viewBox="0 0 36 36" className="circular-chart">
                            <path
                              className="circle"
                              stroke={"#00A085"}
                              strokeWidth="4"
                              strokeDasharray={`${calculateRadius(
                                s.contentCount,
                                s.completedCount
                              )}, 100`}
                              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                          </svg>
                          {/* <div className="w-full h-full figure-pie-chart" style={{
                                                            background: `radial-gradient(circle closest-side, transparent 22%, white 0), conic-gradient(from 30deg, #00A085 0, #00A085 65.6%, #ffffff 0, #ffffff 100%)`
                                                        }}></div> */}
                        </div>
                      </div>
                      <div>
                        Lessons {s.completedCount} of {s.contentCount}
                        <span className="px-2">&middot;</span>
                        {getFormattedTimeInHours(s.totalTime)} spent
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* Bookmarks */}
        {
          <div className="p-4">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="16"
                viewBox="0 0 10 16"
              >
                <path
                  id="Path_444"
                  data-name="Path 444"
                  d="M5,4A2,2,0,0,1,7,2h6a2,2,0,0,1,2,2V18l-5-2.5L5,18Z"
                  transform="translate(-5 -2)"
                  fill="#00a085"
                />
              </svg>
              <div className="ml-4 text-sm font-semibold text-skin-base">
                Bookmarks
              </div>
            </div>

            {/* Subscription List */}
            <div className="grid gap-4 mt-4 sm:grid-cols-3 lg:grid-cols-4">
              {bookmarks.map((learn, i) => (
                <div key={i}>
                  <LearnItem
                    key={learn?._id}
                    id={learn?._id}
                    time={learn?.time}
                    image={learn?.image}
                    type={learn?.type}
                    source={learn?.source?.name}
                    title={learn?.title}
                    tag={learn?.tag}
                    url={learn?.url}
                    started={started}
                    openUrl={openUrl}
                    markCompleted={markCompleted}
                    bookmarkArticle={bookmarkArticle}
                    completed={learn?.completed}
                    bookmarked={learn?.bookmarked}
                    addTodo={addTodo}
                  />
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
