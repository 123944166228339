import create from "zustand";
/**
 * State Management for program creation
 */
export const ProgramState = create((set) => ({
  program: {
    program_type: "Mentoring",
    coach_type: "",
    plan: "1:1",
    platform: "Mentoring",
    title: "",
    description: "",
    session_count: 1,
    start_date: "",
    end_date: "",
    image_url: "",

    skills: [],
    goals: [],
    mentors_csv: "",
    mentees_csv: "",

    agreement: "",

    status: "Pending", // or Published

    links: [],
    milestones: [],
    assignments: [],
    resources: [],

    invite: false,
    topicSelectionMax: null,
    menteeCoacheeWordReplacement: null,
    mentorCoachWordReplacement: null,
    questionsForExperts: [],

    isAutoEnrollment: false,
    referralSourceOptions: []
  },
  programBeforeMutation: null,
  setProgram: (updatedProgram) => {
    set((state) => ({ program: Object.assign(state.program, updatedProgram) }));

    let tempProgram;
    if (updatedProgram?._id) {
      const programInLs = localStorage.getItem(updatedProgram?._id);
      if (programInLs) {
        tempProgram = JSON.parse(programInLs);
      } else {
        tempProgram = updatedProgram;
        localStorage.setItem(updatedProgram?._id, JSON.stringify(tempProgram));
      }

      set(() => ({
        programBeforeMutation: tempProgram
      }));
    }
  },
  resetProgram: () => {
    set(({ program }) => {
      if (program?._id) localStorage.removeItem(program._id);
      return {
        program: { ...resetProgramDefault },
        programBeforeMutation: null
      };
    });
  }
}));

export const resetProgramDefault = {
  program_type: "Mentoring",
  platform: "Mentoring",
  title: "",
  description: "",
  session_count: 1,
  start_date: "",
  end_date: "",

  image_url: "",

  skills: [],

  mentors_csv: "",
  mentees_csv: "",

  agreement: "",

  status: "Pending", // or Published

  links: [],
  milestones: [],
  assignments: [],
  resources: [],

  invite: false,

  topicSelectionMax: null,
  menteeCoacheeWordReplacement: null,
  mentorCoachWordReplacement: null,
  questionsForExperts: [],
  isAutoEnrollment: false,
  referralSourceOptions: []
};
