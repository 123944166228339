import { Typography } from "knack-ui";
import svg from "../Asset/illustrations/mobilewarning.png";

const MobileWarning = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-screen p-6 space-y-6">
        <img className="h-40 mx-auto" src={svg} alt="please use a computer" />
        <Typography textAlign="center" variant='h5'>
          To have the best Knack experience, Please login using a computer{" "}
        </Typography>
      </div>
    </div>
  );
};

export default MobileWarning;
