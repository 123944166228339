import { PAYMENT_STATUS, PROGRAM_TYPES, SESSION_MODES } from "./../../Sessions/SessionsTypes";
import { IOrganization } from "./../../User/UserTypes";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { DateRangeFilter, DefaultFilter, Filter } from "../../../components/FiltersBar/FiltersBar.types";
import { useGetOrganizations } from "../../Company/companyQueries";
import { useGetClients, useGetExperts } from "../../People/peopleQueries";
import { SESSION_STATUS } from "../../Sessions/SessionsTypes";
import { IUserProfile } from "../../User/UserTypes";
import { IPaymentReportsQuery } from "../paymentTypes";
import { addDays, format } from "date-fns";

interface IProps {
  query: IPaymentReportsQuery;
  onQueryChange: Dispatch<SetStateAction<IPaymentReportsQuery>>;
}

export const usePaymentFilters = ({ query, onQueryChange }: IProps) => {
  const [expertSearchValue] = useState<string>("");
  const [clientSearchValue] = useState<string>("");

  const { data: expertList } = useGetExperts(
    { search: expertSearchValue, expertType: "external-coach" },
    {
      enabled: Boolean(expertSearchValue),
      suspense: true
    }
  );

  const { data: clientList } = useGetClients(
    { search: expertSearchValue },
    {
      enabled: Boolean(clientSearchValue),
      suspense: true
    }
  );

  const { data: companyList } = useGetOrganizations();

  const filters = useMemo(() => {
    const f: Filter<keyof IPaymentReportsQuery, any>[] = [];
    Object.keys(query).forEach((key) => {
      switch (key) {
        case "dateRange":
          const dateRange: DateRangeFilter = {
            type: "dateRange",
            label:
              (query.dateRange![0].startDate &&
                query.dateRange![0].endDate &&
                `${format(query.dateRange![0].startDate, "yyyy-MM-dd")} / ${format(query.dateRange![0].endDate, "yyyy-MM-dd")}`) ||
              "Date",
            // name:'date' is actually wrong here, I kept it because if
            // I change it to `dateRange` a counter will appear next to `Date` button text
            name: "date",
            options: {
              onChange: (newValue) => {
                onOptionsChange({
                  newValue,
                  type: "dateRange"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [
                    {
                      // startDate: date,
                      endDate: addDays(Date.now(), 7),
                      key: "selection"
                    }
                  ],
                  type: "dateRange"
                });
              },
              ranges: query.dateRange!
            }
          };
          f.push(dateRange);
          break;
        case "coach":
          if (!expertList) break;
          const experts: Filter<"coach", IUserProfile> = {
            label: "Coach",
            name: "coach",
            type: "default",
            options: {
              renderText: (o) => `${o?.first_name} ${o?.last_name}`,
              data: expertList?.pages.map((p) => p.profiles).flat(),
              searchCriteria: (option, keyword) => {
                return `${option?.first_name}${option?.last_name}${option?.user?.email}`.toLowerCase().includes(keyword.toLowerCase());
              },
              isOptionSelected: (o) => query.coach!.includes(o.user._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.coach!, newValue.user._id],
                  type: "coach"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "coach"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.coach!.filter((id) => id !== option.user._id),
                  type: "coach"
                });
              }
            }
          };
          f.push(experts);
          break;
        case "client":
          if (!clientList) break;
          const clients: Filter<"client", IUserProfile> = {
            label: "Client",
            name: "client",
            type: "default",
            options: {
              renderText: (o) => `${o?.first_name} ${o?.last_name}`,
              data: clientList?.pages.map((p) => p.profiles).flat(),
              searchCriteria: (option, keyword) => {
                return `${option?.first_name}${option?.last_name}${option?.user?.email}`.toLowerCase().includes(keyword.toLowerCase());
              },
              isOptionSelected: (o) => query.client!.includes(o.user._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.client!, newValue.user._id],
                  type: "client"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "client"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.client!.filter((id) => id !== option.user._id),
                  type: "client"
                });
              }
            }
          };
          f.push(clients);
          break;
        case "paymentStatus":
          const paymentStatus: DefaultFilter<"paymentStatus", PAYMENT_STATUS> = {
            label: "Payment Status",
            name: "paymentStatus",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["Paid", "Unpaid"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.paymentStatus!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.paymentStatus!, newValue],
                  type: "paymentStatus"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "paymentStatus"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.paymentStatus!.filter((id) => id !== option),
                  type: "paymentStatus"
                });
              }
            }
          };
          f.push(paymentStatus);
          break;
        case "programType":
          const programType: DefaultFilter<"programType", PROGRAM_TYPES> = {
            label: "Program Type",
            name: "programType",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["external-coaching", "internal-coaching", "mentoring"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.programType!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.programType!, newValue],
                  type: "programType"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "programType"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.programType!.filter((id) => id !== option),
                  type: "programType"
                });
              }
            }
          };
          f.push(programType);
          break;
        case "sessionStatus":
          const sessionStatus: DefaultFilter<"sessionStatus", SESSION_STATUS> = {
            label: "Session Status",
            name: "sessionStatus",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["Cancelled", "Completed", "No Show", "Pending", "Deleted"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.sessionStatus!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.sessionStatus!, newValue],
                  type: "sessionStatus"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "sessionStatus"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.sessionStatus!.filter((id) => id !== option),
                  type: "sessionStatus"
                });
              }
            }
          };
          f.push(sessionStatus);
          break;
        case "sessionMode":
          const sessionMode: Filter<"sessionMode", SESSION_MODES> = {
            label: "Session Mode",
            name: "sessionMode",
            type: "default",
            options: {
              renderText: (o) => o,
              data: ["Logged", "Scheduled"],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.sessionMode!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.sessionMode!, newValue],
                  type: "sessionMode"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "sessionMode"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.sessionMode!.filter((id) => id !== option),
                  type: "sessionMode"
                });
              }
            }
          };
          f.push(sessionMode);
          break;
        case "organization":
          if (!companyList) break;
          const organizations: DefaultFilter<"organization", IOrganization> = {
            label: "Organization",
            name: "organization",
            type: "default",
            options: {
              renderText: (o) => `${o?.name}`,
              data: companyList,
              searchCriteria: (option, keyword) => {
                return `${option?.name}`.toLowerCase().includes(keyword.toLowerCase());
              },
              isOptionSelected: (o) => query.organization!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.organization!, newValue._id],
                  type: "organization"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "organization"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.organization!.filter((id) => id !== option._id),
                  type: "organization"
                });
              }
            }
          };
          f.push(organizations);
          break;
      }
    });

    return f;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, expertList, clientList, companyList]);

  const onOptionsChange = <T extends keyof IPaymentReportsQuery>({ newValue, type }: { type: T; newValue: IPaymentReportsQuery[T] }) => {
    onQueryChange((state) => ({ ...state, [type]: newValue }));
  };
  return filters;
};
