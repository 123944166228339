import create from "zustand";

interface FilterProps {
  value: string;
  onFilterChange: (value: string) => void;
  reset: () => void;
}

export const useFilter = create<FilterProps>((set) => ({
  value: "",
  onFilterChange: (value: string) => set(() => ({ value })),
  reset: () => set(() => ({ value: "" }))
}));
