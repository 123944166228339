import { Chip, Input, Stack, Typography } from "knack-ui";
import { useEffect, useMemo } from "react";
import { useGetExternalCoachingTopics } from "../../../../../../../features/Programs/programQueries";
import { ProgramState } from "../../../../../../../StateManagement/ProgramCreation";

export const ExpertSkills = ({ reference }) => {
  const { program, setProgram, programBeforeMutation } = ProgramState();
  const { data: externalTopics } = useGetExternalCoachingTopics({
    enabled: Boolean(program?.coach_type === "knack_coaching")
  });

  function canDelete(topic) {
    if (program.status !== "Published") return true;
    if (programBeforeMutation && !programBeforeMutation?.skills?.includes(topic)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (program && program?.coach_type === "knack_coaching") {
      setProgram({ ...program, skills: [...externalTopics] });
    }
  }, [externalTopics, program, setProgram]);

  const participantsNames = useMemo(() => {
    return {
      user: program.program_type === "Mentoring" ? "Mentee" : "Coachee",
      expert: program.program_type === "Mentoring" ? "Mentor" : "Coach"
    };
  }, [program]);

  return (
    <section ref={reference}>
      <Typography className="mb-4" variant="h6">
        {program?.coach_type === "knack_coaching" ? "Available topics (Knack coaching topics)" : "Topics (add atleast 3 topics)"}
      </Typography>
      {program?.coach_type === "knack_coaching" && (
        <Typography className="my-4" fontWeight="semibold" color="muted" variant="subtitle2">
          Participants will be able to select from these topics when they register to the program
        </Typography>
      )}
      {program?.coach_type === "knack_coaching" && (
        <div className="flex flex-wrap gap-4">
          {externalTopics?.map((t) => (
            <Chip shape="rounded" key={t}>
              {t}
            </Chip>
          ))}
        </div>
      )}

      {program?.coach_type !== "knack_coaching" && (
        <div>
          <label>
            <div>
              <Input
                onKeyUp={(e) => {
                  if (e.keyCode === 13 && e.target.value.length > 0) {
                    const skills = program.skills && program.skills.length ? [...program.skills] : [];
                    // console.log(skills)

                    if (!skills.includes(e.target.value)) {
                      skills.push(e.target.value);
                      const _program = { ...program };
                      _program.skills = skills;
                      setProgram(_program);

                      e.target.value = "";
                    }
                  }
                }}
                placeholder="Emotional Intelligence"
                type="text"
                className="w-full border-gray-300 rounded-md"
                onChange={(v) => setProgram((p) => ({ ...p, title: v.target.value }))}
              />
            </div>
            <p className="mt-2 text-xs text-skin-base">Press Enter to separate Topics</p>

            {/* Skills List */}
            <div className="flex flex-row flex-wrap gap-4 mt-4 overflow-y-auto max-h-60">
              {program?.skills?.map((t, i) => (
                <Chip
                  onDelete={
                    canDelete(t)
                      ? () => {
                          const skills = [...program.skills].filter((v) => v !== t);
                          const _program = { ...program };
                          _program.skills = skills;
                          setProgram(_program);
                        }
                      : undefined
                  }
                  shape="rounded"
                  variant="tertiary"
                  key={i}
                >
                  {t}
                </Chip>
              ))}
            </div>
          </label>
        </div>
      )}

      {program?.coach_type !== "knack_coaching" && (
        <div className="mt-4">
          <Stack direction="column">
            <Typography className="mb-2" variant="h6">
              Maximum Topics per Program Registration (optional)
            </Typography>
            <Typography className="mb-4" variant="subtitle2">
              This is the highest number of topics a {participantsNames.user} or {participantsNames.expert} can select during program
              registration. For example, if you enter '5', users will be able to choose up to 5 topics when they sign up for a program.
            </Typography>
            <Input
              onChange={(v) => {
                const value = v.target.value ? parseInt(v.target.value) : "";

                if (value === "") {
                  // Do nothing
                } else if (value >= 1 && value <= (program.skills.length || 1)) {
                  const _program = { ...program };
                  _program.topicSelectionMax = value;
                  setProgram(_program);
                } else if (value > program.skills.length) {
                  v.target.value = program.skills.length;
                } else if (value < 1) {
                  v.target.value = 1;
                }
              }}
              max={program?.skills.length || 1}
              defaultValue={program.topicSelectionMax}
              placeholder={`Enter a number up to ${program?.skills.length > 0 ? program.skills.length : 1}`}
              type="number"
              className="w-full border-gray-300 rounded-md"
            />
          </Stack>
        </div>
      )}
    </section>
  );
};
