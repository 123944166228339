import axios from "axios";
import { Failure } from "../components/ToastNotification/Failure";
import { Success } from "../components/ToastNotification/Success";
import { apiHttp } from "../lib/appConfig";
import authHeader from "./auth-header";
import { API_URL, updateMenteeProfile } from "./base-api";
import { getUser, getUserProfile } from "./user.service";

export const UPDATED_APPLICATION_VERSION = "3.0.1";

const onPasswordUpdated = () => {
  return axios.post(
    API_URL + "v1/auth/users/_update",
    { password_updated: true },
    { headers: authHeader() }
  );
};

const login = async (email, password) => {
  // const ipAddress = await fetch("https://api.ipify.org/?format=json").then(res => res.json()).then(res => res);
  return axios.post(API_URL + "v1/auth/login", { email, password });
};

const getHMAC = () => {
  return axios.get(API_URL + "v1/profile/generate-hmac", {
    headers: authHeader()
  });
};

const validateOtp = (email, otp) => {
  return axios
    .post(
      API_URL + "v1/auth/verify-otp",
      { email, otp },
      { headers: authHeader() }
    )
    .then((res) => {
      if (res.data.data.authToken) {
        localStorage.setItem("user", JSON.stringify(res.data));

        // save timestamp in ls
        localStorage.setItem("[LAST_LOGIN_ATTEMPT]", new Date());

        // save app version in ls
        localStorage.setItem(
          "[LAST_APPLICATION_VERSION]",
          UPDATED_APPLICATION_VERSION
        );

        // getUserProfile
        getUser().then((res) => {
          if (res && res.data.status === true) {
            localStorage.setItem("profile", JSON.stringify(res.data));
          }
        });
      }
      return res.data;
    });
};


const menteeOnboarding = (
  first_name,
  last_name,
  whatsapp_number,
  job_function,
  management_level,
  areas_develop,
  milestones,
  program_skills
) => {
  return axios
    .post(
      API_URL + "v1/profile/init",
      {
        first_name,
        last_name,
        whatsapp_number,
        job_function,
        management_level,
        areas_develop,
        milestones,
        program_skills
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.status) {
        localStorage.setItem("profile", JSON.stringify(response.data));
      }
      console.log(response.data);
      return response.data;
    });
};

const mentorOnboarding = (
  first_name,
  last_name,
  whatsapp_number,
  job_function,
  management_level,
  areas_strength,
  milestones,
  program_skills
) => {
  return axios
    .post(
      API_URL + "v1/profile/init",
      {
        first_name,
        last_name,
        whatsapp_number,
        job_function,
        management_level,
        areas_strength,
        milestones,
        program_skills
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.status) {
        localStorage.setItem("profile", JSON.stringify(response.data));
      }
      console.log(response.data);
      return response.data;
    });
};

export const resetLocalStorage = () => {
  localStorage.removeItem("profile");
  localStorage.removeItem("user");
  localStorage.removeItem("onboardingState");
  localStorage.removeItem("[LAST_LOGIN_ATTEMPT]");
  localStorage.removeItem("[LAST_APPLICATION_VERSION]");
  localStorage.removeItem("settings");
};

export const logout = async () => {
  // const ipAddress = await fetch("https://api.ipify.org/?format=json").then(res => res.json()).then(res => res);

  axios
    .get(API_URL + "v1/auth/logout", { headers: authHeader() })
    .then((res) => {
      resetLocalStorage();
      window.location.reload();
    })
    .catch((e) => {
      resetLocalStorage();
      window.location.reload();
    });
};

const passwordReset = (password, confirm_password, resetToken) => {
  return apiHttp.post("v1/auth/reset/" + resetToken, {
    password,
    confirm_password
  });
};

const passwordRecovery = (email) => {
  return axios.post(API_URL + "v1/auth/recover", { email });
};

const getCurrentUser = () => {
  return JSON.pasrse(localStorage.getItem("user"));
};

// function getUserToken() {
//   return localStorage.getItem('authToken');
// }

/**
 * Function will check if profile completed then updates the user profile profile_completed to true
 */
export const updateProfileCompletionStatus = () => {
  const localProfile = localStorage.getItem("profile");
  if (localProfile) {
    const parsedProfile = JSON.parse(localProfile).data;
    // check if profile completed
    if (
      parsedProfile.whatsapp_number &&
      parsedProfile.first_name &&
      parsedProfile.last_name &&
      parsedProfile.years_of_experience &&
      parsedProfile.coach_gender_preference &&
      parsedProfile.coach_language_preference &&
      parsedProfile.focus.length > 0 &&
      parsedProfile.professional_situation.length > 0 &&
      parsedProfile.previous_coaching_experience
    ) {
      updateMenteeProfile({ profile_completed: true })
        .then((res) => {
          if (res.data.status) {
            getUserProfile();
            Success("Profile completed!");
            // update local storage
            const user = JSON.parse(localStorage.getItem("user"));
            const profile = JSON.parse(localStorage.getItem("profile"));
            const updateUser = { ...user };
            updateUser.data.profile_completed = true;
            localStorage.setItem("user", JSON.stringify(updateUser));
            // redirect to dashboard
            if (profile.data.user.assessment_completed) {
              console.log("assessment completed, should redirect to dashboard");
            }

            // remove saved onboarding profile
            localProfile.removeItem("unsavedProfile" + "_" + user.data._id);
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Oh no! Something went wrong");
        });
    }
  }
};

export default {
  passwordRecovery,
  passwordReset,
  login,
  logout,
  getCurrentUser,
  menteeOnboarding,
  mentorOnboarding,
  validateOtp,
  onPasswordUpdated,
  UPDATED_APPLICATION_VERSION,
  getHMAC
};