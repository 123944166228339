import { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  PROGRAM_TYPES,
  SESSION_BY_PROGRAM_TYPE,
  SESSION_KEYS,
  SESSION_TYPES,
  USER_ROLES,
  USER_TYPES,
  USER_TYPES_PLURALS
} from "../../../App.constant";
import { BackButton } from "../../../components/nav/BackButton";
import { SchedulerWrapper } from "./SchedulerWrapper";
import { KnackSpinner } from "../../../components/Asset/KnackSpinner";
import { PopUp } from "./PopUp";
import { AssignedExpertOrMatches } from "./AssignedExpertOrMatches";
import { SelectSessionType } from "./SelectSessionType";
import { AddConferencing } from "./AddConferencing";
import { CronofyService } from "../CronofyService";
import { Information } from "../../../components/ToastNotification/Information";
import { WarningInformation } from "../../../components/Information/WarningInformation";
import { Failure } from "../../../components/ToastNotification/Failure";
import { MeetingScheduleSuccessInformation } from "./MeetingScheduleSuccessInformation";
import { isValidURL } from "../../utils/utils";
import { useUser, useUserRoles } from "../../../features/User/userQueries";
import { useGetProgram, useGetProgramExpertMatches, useGetProgramOTFExpertMatches } from "../../../features/Programs/programQueries";
import { openManualSessionLogging } from "../SessionLogging/SessionState";
import { useQueryClient } from "react-query";
import { sessionQueryKeys } from "../../../features/Sessions/sessionsQueries";
import {
  useGetCalendarAvailability,
  useGetCalendarProfile,
  useValidateIntegratedConferencing
} from "../../../features/Calendar/calendarQueries";
import { Input, Stack, Typography } from "knack-ui";
import { DurationSelector } from "./DurationSelector";
import { useGetConnectionProfile } from "../../../features/Connections/connectionsQueries";

export const Scheduler = () => {
  const queryClient = useQueryClient();
  const { connectionUserId, matchUserId } = openManualSessionLogging();
  const { roleState } = useUserRoles();
  const { data: user } = useUser();
  const history = useHistory();
  const [selectedMatch, setSelectedMatch] = useState();
  const [selectedExpert, setSelectedExpert] = useState([]);

  const [sessionType, setSessionType] = useState();
  const [spinnerOpen2, setSpinnerOpen2] = useState(false);
  const [duration, setDuration] = useState(30);
  const [openNoExpertModal, setOpenNoExpertModal] = useState(false);
  const [confirmSendInvitation, setConfirmInvitation] = useState(false);
  const [canSelectSessionType, setCanSelectSessionType] = useState(false);
  const [eventDetails, setEvent] = useState({
    summary: "",
    description: "",
    start: "",
    end: "",
    tzid: "Asia/Dubai"
  });
  const [error, setError] = useState({
    show: false,
    message: ""
  });
  const [successCallback, setSuccessCallback] = useState(false);
  const [scheduledEventInformation, setScheduledEventInformation] = useState("");

  const { programId } = useParams();
  const { data: program } = useGetProgram({ programId });
  const { data: internalMatches } = useGetProgramOTFExpertMatches(programId, {
    enabled: Boolean(program?.coach_type === "own_coach" || program?.program_type === "Mentoring")
  });
  const { data: externalMatches } = useGetProgramExpertMatches(programId, {
    enabled: Boolean(program?.coach_type === "knack_coaching")
  });

  const { data: expertCalendarProfile, isError: expertCalendarProfileError } = useGetCalendarProfile(
    selectedExpert?.scheduler_id || selectedMatch?.expert?.scheduler_id,
    selectedExpert?.user?._id || selectedMatch?.expert?.user?._id,
    {
      enabled: Boolean(selectedExpert?.scheduler_id || selectedMatch?.expert?.scheduler_id)
    }
  );

  const { data: availability } = useGetCalendarAvailability(
    selectedExpert?.scheduler_id || selectedMatch?.expert?.scheduler_id,
    selectedExpert?.user?._id || selectedMatch?.expert?.user?._id,
    {
      enabled: Boolean(selectedExpert?.scheduler_id || selectedMatch?.expert?.scheduler_id)
    }
  );

  const { data: canUseIntegratedConferencing } = useValidateIntegratedConferencing(
    selectedExpert?.user?._id || selectedMatch?.expert?.user?._id,
    {
      enabled: Boolean(selectedExpert?.user?._id || selectedMatch?.expert?.user?._id)
    }
  );

  console.log("canUseIntegratedConferencing", canUseIntegratedConferencing);

  const expertCalendar = useMemo(() => {
    if (expertCalendarProfile) return expertCalendarProfile;
    if (expertCalendarProfileError) return null;

    return null;
  }, [expertCalendarProfile, expertCalendarProfileError]);

  const expertHasCalendarProfile = useMemo(() => {
    if (expertCalendar) return expertCalendarProfile["cronofy.data"]?.profiles?.length > 0;
    return null;
  }, [expertCalendar]);

  const setDisabled = useState(false)[1];

  useEffect(() => {
    const promptEl = document.getElementById("disabled-datepicker-component");
    if (
      eventDetails.conferencing?.profile_id === "explicit" &&
      (!isValidURL(eventDetails.conferencing?.join_url) || !eventDetails.conferencing?.join_url)
    ) {
      setDisabled(true);
      setError({
        show: true,
        message: eventDetails.conferencing?.join_url
      });
      if (promptEl) {
        promptEl.style.display = "flex";
      }
    } else if (eventDetails.conferencing?.profile_id === "face-2-face" && !eventDetails?.location?.description) {
      setDisabled(true);
      setError({
        show: true,
        message: "Meeting location or address is required"
      });
      if (promptEl) {
        promptEl.style.display = "flex";
      }
    } else if (duration == 0) {
      setDisabled(true);
      setError({
        show: true,
        message: "Duration is too short"
      });
      if (promptEl) {
        promptEl.style.display = "flex";
      }
    } else {
      setDisabled(false);
      if (promptEl) {
        promptEl.style.display = "none";
      }
    }
  }, [setSelectedMatch, selectedExpert, setOpenNoExpertModal, eventDetails, setDuration]);

  const expert = useMemo(() => {
    if (!program) return null;
    if (program?.expert && program?.expert?.user) {
      setCanSelectSessionType(true);
      setSessionType(SESSION_BY_PROGRAM_TYPE[program.program_type][0].name);
      setSelectedExpert(program.expert);

      return program.expert;
    }

    return null;
  }, [program]);

  const matches = useMemo(() => {
    let matchList = [];
    if (program?.coach_type === "knack_coaching") {
      matchList = externalMatches;
    } else {
      matchList = internalMatches;
    }
    if (!program?.expert && matchList?.length === 0) {
      setOpenNoExpertModal(true);
    }
    if (!program?.expert && matchList && matchList?.length > 0) {
      setCanSelectSessionType(false);
      setSessionType(SESSION_TYPES.CHEMISTRY_CALL);
      let match = matchList[0];
      if (matchUserId) {
        match = matchList.filter((m) => m.expert?.user?._id === matchUserId)[0];
      }
      setSelectedMatch(match);
      return matchList;
    }

    return null;
  }, [externalMatches, internalMatches, matchUserId, program]);

  const { data: expertProfile } = useGetConnectionProfile(
    { id: selectedExpert?.user?._id || selectedMatch?.expert?.user?._id },
    {
      enabled: Boolean(selectedExpert?.user?._id || selectedMatch?.expert?.user?._id)
    }
  );

  const onSelectDateTime = (s) => {
    if (s?.notification?.type === "slot_selected") {
      setEvent((e) => ({
        ...e,
        tzid: s?.notification?.tzid,
        start: s?.notification?.slot.start,
        end: s?.notification?.slot.end
      }));
      setConfirmInvitation(true);
      setError({ show: false, message: "" });
    }
  };

  function createMeeting() {
    const eventData = {
      ...eventDetails
    };

    let description = `${duration} minutes ${SESSION_KEYS[sessionType]}`;

    if (eventData?.conferencing?.profile_id === "explicit" && eventData?.conferencing.join_url) {
      description = `Join the Meeting with the link below انضم إلى الاجتماع بالرابط أدناه  ${eventData?.conferencing.join_url}`;
    }

    if (eventData.conferencing?.profile_id === "face-2-face") {
      description = `You can meet at ${eventData?.location.description}`;
    }

    if (eventData.conferencing?.profile_id === "face-2-face") {
      delete eventData.conferencing;
    }

    const meetingLocationDescription = eventData?.location?.description || "Virtual";

    setSpinnerOpen2(true);
    const summary = `${duration} minutes ${SESSION_KEYS[sessionType]}`;
    const updatedEvent = {
      ...eventData,
      summary,
      description,
      attendees: {
        invite: [
          {
            email: user.email,
            display_name: `${user.profile.first_name} ${user.profile.last_name}`
          },
          {
            email: expertCalendar?.email || selectedExpert?.user?.email || selectedMatch?.expert?.user?.email,
            display_name: `${selectedExpert?.first_name || selectedMatch?.expert?.first_name} ${
              selectedExpert?.last_name || selectedMatch?.expert?.last_name
            }`
          }
        ]
      },
      reminders: [{ minutes: 30 }, { minutes: 1440 }, { minutes: 0 }],
      location: {
        description: meetingLocationDescription
      }
    };

    setEvent(updatedEvent);
    const organizer = selectedExpert?.scheduler_id || selectedMatch?.expert?.scheduler_id;

    let fields = {
      organizer,
      event: updatedEvent,
      program: programId,
      expert: selectedExpert?.user?._id || selectedMatch?.expert?.user._id,
      user: user._id,
      type: sessionType,
      formattedDateTime: new Date(eventData.start).toString()
    };

    CronofyService.createSchedule({ ...fields })
      .then((res) => {
        if (res.data.status === true) {
          queryClient.invalidateQueries(sessionQueryKeys["all"]);
          Information("Session scheduled Successfully", "");
          setConfirmInvitation(false);
          setSuccessCallback(true);
          setScheduledEventInformation(
            "This meeting has been added to your calendar, Please check your email to update your participation status"
          );
          setSpinnerOpen2(false);
        } else {
          Failure("An Error Occurred. Please try again");
          setSpinnerOpen2(false);
        }
      })
      .catch((e) => {
        Failure("An Error Occurred. Please try again");
        console.log(e);
        setSpinnerOpen2(false);
      });
  }

  return (
    <>
      {successCallback ? (
        <MeetingScheduleSuccessInformation
          sessionType={sessionType}
          event={eventDetails}
          expert={expert}
          program={program}
          selectedMatch={selectedMatch}
          scheduledEventInformation={scheduledEventInformation}
        />
      ) : (
        <div className="grid w-full max-w-screen-xl gap-4 mx-auto">
          <PopUp open={confirmSendInvitation} setOpen={() => setConfirmInvitation(!confirmSendInvitation)} width="max-w-lg">
            {spinnerOpen2 && <KnackSpinner loadingText="Please Wait" />}
            <div className="grid gap-4 p-6 text-center" style={{ maxWidth: 470 }}>
              <div className="text-2xl font-extrabold tracking-tight">You are about to send an invitation</div>
              <div className="-mt-2 text-base">
                If you proceed, the invitation recipients will receive a calendar invite in their email.
              </div>
              <div className="flex items-center gap-6 mx-auto mt-4">
                <button
                  onClick={() => setConfirmInvitation(false)}
                  className="px-4 py-2 font-medium text-red-800 rounded-lg bg-skin-lighter"
                  style={{ width: "fit-content" }}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  onClick={() => {
                    createMeeting();
                  }}
                  className="px-4 py-2 font-medium text-white rounded-lg bg-skin-accent"
                  style={{ width: "fit-content" }}
                >
                  {" "}
                  Send Invitation
                </button>
              </div>
            </div>
          </PopUp>
          <PopUp
            open={openNoExpertModal}
            setOpen={() => {
              history.push("/sessions");
              setOpenNoExpertModal(false);
            }}
            width="max-w-lg"
          >
            <div className="grid gap-4 p-6 text-center" style={{ maxWidth: 500 }}>
              <div className="text-2xl font-extrabold tracking-tight">Oops! You cannot schedule a meeting</div>
              {roleState.activeRole === USER_ROLES.USER && (
                <div className="-mt-2 text-base">
                  You will be able to schedule a meeting when you have an assigned{" "}
                  {program?.program_type === PROGRAM_TYPES.MENTORING ? USER_TYPES.MENTOR : USER_TYPES.COACH} or matches for the selected
                  program. <br /> Please try again when you have matches or a{" "}
                  {program?.program_type === PROGRAM_TYPES.MENTORING ? USER_TYPES.MENTOR : USER_TYPES.COACH}.
                </div>
              )}
              {(roleState.activeRole === USER_ROLES.EXPERT || USER_TYPES.COACH === user?.account_type) && (
                <div className="-mt-2 text-base">
                  You will be able to schedule a meeting when you have assigned{" "}
                  {program?.program_type === PROGRAM_TYPES.MENTORING ? USER_TYPES_PLURALS.MENTEES : USER_TYPES_PLURALS.COACHEES} or matches
                  for the selected program. Please try again when you have matches or{" "}
                  {program?.program_type === PROGRAM_TYPES.MENTORING ? USER_TYPES_PLURALS.MENTEES : USER_TYPES_PLURALS.COACHEES}.
                </div>
              )}
              <Link
                className="block p-2 mx-auto mt-2 font-medium text-white rounded-lg bg-skin-accent"
                style={{ width: "fit-content" }}
                to={"/sessions"}
              >
                Got It
              </Link>
            </div>
          </PopUp>

          <BackButton />

          <div className="flex flex-row w-full bg-white rounded-lg shadow">
            <div className="flex flex-row w-full">
              {/* Modifiable PreSelection */}
              <div className="flex flex-col gap-6 p-6">
                {/* Summary */}
                <div className="max-w-lg text-xl font-extrabold tracking-tight">
                  <div className="lowercase">
                    {duration} minutes {SESSION_KEYS[sessionType]} with{" "}
                    <span className="capitalize">
                      {expert?.first_name || selectedMatch?.expert?.first_name} {expert?.last_name || selectedMatch?.expert?.last_name}{" "}
                    </span>
                  </div>
                </div>
                {/* Expert */}
                <AssignedExpertOrMatches
                  connectionUserId={connectionUserId}
                  expert={expert}
                  selectedMatch={selectedMatch}
                  matches={matches}
                  setSelectedMatch={(match) => {
                    setSelectedMatch(match);
                  }}
                />
                {/* Session Type */} {/* Can select session type only if user has a assigned mentor or coach */}
                <SelectSessionType
                  programId={programId}
                  canSelectSessionType={canSelectSessionType}
                  expert={expert}
                  sessionType={sessionType}
                  program={program}
                  setSessionType={(type) => {
                    setSessionType(type);
                  }}
                />
                {/* Duration */}
                <DurationSelector duration={duration} setDuration={setDuration} />
                {/* Conferencing */}
                {expertCalendar && (
                  <AddConferencing
                    canUseIntegratedConferencing={canUseIntegratedConferencing}
                    customConferenceURL={expertProfile.customMeetingLink}
                    calendarProfileAccount={expertCalendar}
                    error={error}
                    link={eventDetails.conferencing?.join_url}
                    setConferenceData={(data) => {
                      const _event = { ...eventDetails, conferencing: data };
                      setEvent(_event);
                      if (data?.profile_id === "explicit" && !isValidURL(data?.join_url)) {
                        setError({
                          show: true,
                          message: data.join_url
                        });
                      } else {
                        setError({ show: false, message: "" });
                      }
                    }}
                  />
                )}
                {eventDetails.conferencing?.profile_id === "face-2-face" && (
                  <Stack direction="column" gap={4}>
                    <Typography>Enter a location or address</Typography>
                    <Input
                      placeholder="e.g Conference room"
                      onChange={(e) => {
                        const location = {
                          description: e.target.value
                        };
                        setEvent((e) => ({
                          ...e,
                          location
                        }));
                      }}
                    />
                    {!eventDetails?.location?.description && (
                      <WarningInformation message={<Typography color="warning">Location or address is required</Typography>} />
                    )}
                  </Stack>
                )}
              </div>
              {/* DateTime Picker */}
              <div className="w-full border-l">
                <>
                  {!expertHasCalendarProfile && (
                    <WarningInformation
                      message={
                        <div className="text-lg">
                          You cannot view {expert?.first_name || selectedMatch?.expert?.first_name}{" "}
                          {expert?.last_name || selectedMatch?.expert?.last_name}
                          's availability because they have not connected their calendar. Please contact them at
                          <strong> {expert?.user?.email || selectedMatch?.expert?.user?.email} </strong>
                          to find a suitable time to meet.
                        </div>
                      }
                    />
                  )}
                </>

                {expertCalendar &&
                  expertHasCalendarProfile &&
                  [expert?.scheduler_id || selectedMatch?.expert.scheduler_id].filter((s) => s !== undefined).length > 0 && (
                    <div className="p-6">
                      <SchedulerWrapper
                        error={error}
                        availability={availability}
                        buffer={expert?.schedule_buffer_minutes || selectedMatch?.expert?.schedule_buffer_minutes}
                        // need to support teams
                        subAccountsWithUserId={[
                          {
                            scheduler_id: expert?.scheduler_id || selectedMatch?.expert?.scheduler_id,
                            userId: expert?.user?._id || selectedMatch?.expert?.user?._id
                          }
                        ].filter((s) => s !== undefined)}
                        required_duration={duration}
                        onCallback={(res) => {
                          if (eventDetails?.conferencing?.profile_id === "explicit" && !isValidURL(eventDetails?.conferencing?.join_url)) {
                            setError({
                              show: true,
                              message: eventDetails?.conferencing?.join_url
                            });

                            return;
                          }

                          onSelectDateTime(res);
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
