import React, { useState, useEffect } from "react";
import { useDialog } from "react-st-modal";
import UserDashboardService from "../../../../../services/dashboard/userDashboard.services";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { Success } from "../../../../../components/ToastNotification/Success";
import { Failure } from "../../../../../components/ToastNotification/Failure";

// The element to be shown in the modal window
export default function RequestFeedback() {
  // use this hook to control the dialog
  const dialog = useDialog();
  const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const [validator] = React.useState(
    new SimpleReactValidator({
      messages: {
        email: "This is not a valid email"
      }
    })
  );

  const [feedback360, setFeedback360] = useState([]);
  const [error, setError] = useState("");
  const [reviewerEmail, setReviewerEmail] = useState("");
  const [reviewerType, setReviewerType] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const get360Feedback = () => {
    UserDashboardService.get360Feedback().then(
      (response) => {
        let data = response?.data?.data;
        setFeedback360(data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
        setError(resMessage);
      }
    );
  };

  const reviewTypes = ["Direct Report", "Manager", "Peer", "Worked with"];

  const addPeer = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      setMessage("");
      setLoading(true);

      UserDashboardService.addPeerTo360(reviewerEmail, reviewerType)
        .then((response) => {
          if (response && response?.status) {
            validator.hideMessages();
            setReviewerType("");
            setReviewerEmail("");
            get360Feedback();
            Success("Email added successfully");
          }
        })
        .catch((e) => {
          setLoading(false);
          Failure(e.response.data.message);
        });
    } else {
      validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      forceUpdate();
    }
  };

  const deletePeerFrom360 = (_id) => {
    UserDashboardService.deletePeerFrom360(_id).then(
      (response) => {
        if (response.status) {
          get360Feedback();
          Success("Email removed successfully");
        } else {
          Failure(response.data);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
        console.log(resMessage);
      }
    );
  };

  const invitePeersTo360 = (e) => {
    if (feedback360.length >= 1) {
      UserDashboardService.invitePeersTo360().then(
        (response) => {
          if (response.status) {
            get360Feedback();
            Success(
              "Your feedback requests have been sent successfully. They should receive an invite to their mails shortly."
            );
            setTimeout(() => {
              dialog.close(reviewerType, reviewerEmail);
            }, 1000);
          } else {
            setMessage(response.data);
            setLoading(false);
            toast.error(response.data);
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
          Failure(resMessage);
          console.log(resMessage);
        }
      );
    } else {
      Failure(
        "You need to add at least 1 colleagues to be able to make a feedback request"
      );
    }
  };

  useEffect(() => {
    get360Feedback();
  }, []);

  return (
    <div
      className="py-4 w-full flex flex-col md:items-start md:text-left items-center text-center relative overflow-y-auto h-full"
      style={{}}
    >
      <div className="w-11/12 py-2 mx-auto">
        <p className="text-gray-500 text-sm text-left pb-4">
          Leaders within organizations use the 360 feedback system to help them
          get a clear picture of what their Hidden Strengths and Blind Spots
          are.
        </p>
        <p className="text-gray-500 text-sm text-left pb-4">
          Nominate at least 10-15 colleagues to fill out an anonymous online
          feedback form that asks questions covering many of your skills and
          competencies.
        </p>
        <div className="flex w-full mb-4 sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0">
          <div className="relative flex-grow md:w-4/6">
            <input
              id="reviewer_email"
              name="reviewer_email"
              required
              type="text"
              value={reviewerEmail}
              placeholder="Insert reviewer work email address"
              onChange={(e) => {
                setReviewerEmail(e.target.value);
              }}
              className="block w-full px-3 py-2 mt-1 text-gray-700 border border-gray-300 focus:border-skin-base text-sm rounded-lg"
            />
            {validator.message(
              "reviewer_email",
              reviewerEmail,
              "required|email",
              { className: "text-red-500 text-xs" }
            )}
          </div>
          <div className="relative flex-grow md:w-2/6">
            <select
              id="reviewer_email"
              name="reviewer_email"
              className="block w-full px-3 py-2 mt-1 text-gray-700 border border-gray-300 focus:border-skin-base text-sm rounded-lg"
              value={reviewerType}
              placeholder="Direct report"
              onChange={(e) => {
                setReviewerType(e.target.value);
              }}
            >
              <option key={0} disabled hidden value="">
                Reviewer type
              </option>
              {reviewTypes.map((type, index) => {
                return (
                  <option key={index + 1} value={type}>
                    {type}
                  </option>
                );
              })}
            </select>
            {validator.message("reviewer_type", reviewerType, "required", {
              className: "text-red-500 text-xs"
            })}
          </div>
        </div>
        <button
          className="flex text-center ml-auto text-skin-base bg-white border mt-1 border-skin-base py-2 px-4 focus:outline-none hover:bg-skin-fill hover:text-white align-middle justify-center py-auto rounded-lg"
          onClick={addPeer}
        >
          Add
        </button>

        {/* Selected Reviewers */}
        <div className="h-40 sm:h-52 overflow-y-auto mt-4 scrollhost2">
          {feedback360.length > 0 &&
            feedback360.map((feed, index) => {
              return (
                <div
                  key={index}
                  className="flex my-2 py-2 hover:bg-gray-100 w-full sm:flex-row flex-col mx-auto sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0"
                >
                  <div className="px-2 relative flex-grow md:w-4/6 text-left">
                    <p className="text-gray-700 mt-1">{feed.reviewer_email}</p>
                  </div>
                  <div className="px-2 relative flex-grow md:w-2/6">
                    <div className="flex">
                      <p className="text-gray-700 inline mt-1 align-middle items-center justify-center">
                        {feed.reviewer_type}
                      </p>
                      <button
                        className="rounded-full w-8 h-8 p-0 border-0 inline-flex items-center justify-center text-gray-500 ml-auto"
                        onClick={() => {
                          deletePeerFrom360(feed._id);
                        }}
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <button
          className="flex text-center w-full mx-auto mt-4 text-skin-accentbg-white border border-skin-base py-2 px-4 focus:outline-none hover:bg-skin-fill-accent hover:text-white items-center justify-center rounded-lg"
          // disabled={feedback360.length < 5}
          onClick={() => invitePeersTo360()}
        >
          Request Feedback
        </button>
      </div>
    </div>
  );
}
