import { Typography, Stack } from "knack-ui";
import ProgramCard from "../../components/ProgramCard/ProgramCard";
import { IProgramInvite } from "../../features/Programs/ProgramTypes";
import { useUserRoles } from "../../features/User/userQueries";
import { useWindowSize } from "../../hooks/useWindowSize";

interface IProps {
  invitations?: IProgramInvite[];
}

const ProgramInvitationsDrawer = ({ invitations }: IProps) => {
  const { height: pageHeight, width: pageWidth } = useWindowSize();
  const { roleState } = useUserRoles();

  return (
    <div style={{ minWidth: `calc(${pageWidth}px / 3.3 )`, maxWidth: 400 }}>
      <div className="p-6">
        <Typography variant="h6" as="h6">
          Program invitations
        </Typography>
        <Typography variant="subtitle2" as="p" color="muted">
          {roleState?.activeRole === "User"
            ? "programs that has been recommended to you by your managers"
            : "List of coaching and mentoring requests"}
        </Typography>
      </div>
      <div
        style={{ height: `calc(${pageHeight}px - 97px)` }}
        className="overflow-y-auto"
      >
        <Stack className="px-6 py-4" gap={4} direction="column">
          {invitations?.map((i) => (
            <ProgramCard
              invitationId={i._id}
              key={i._id}
              program={i.program}
              invitationType={i.type}
            />
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default ProgramInvitationsDrawer;
