import { useState } from "react";
import { TaskTypeColors } from "../../../../services/utils/utils";
import TimeAgo from "react-timeago";
import { LoadMore } from "../../../../components/LazyLoading/LoadMore";

export const TaskView = ({ taskList, mentee_id }) => {
  const [displayCount, setDisplayCount] = useState(10);
  return (
    <div>
      {taskList?.slice(0, displayCount).map((task) => (
        <div
          key={task._id}
          className={`flex flex-row items-center bg-white p-4 rounded-lg shadow mb-4 relative rounded-lg`}
          style={{
            borderLeft: `2px solid ${TaskTypeColors["session"]}`,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }}
        >
          {
            <input
              type="checkbox"
              disabled={true}
              className="mr-4 mt-0 removeInputRing rounded"
              style={{ color: TaskTypeColors[task.type] }}
              checked={task.completed_by?.includes(mentee_id)}
              onChange={() => null}
            />
          }
          <div className="w-full">
            <div className="flex flex-row items-center w-full">
              <span>{task?.title}</span>
            </div>
            <div className="text-xs text-skin-muted">{task?.details}</div>
            <div className="text-xs text-skin-muted">
              Program : {task?.program?.name}
            </div>
          </div>
          {task.type === "mentor" && (
            <span
              className="text-xs block mt-1 font-light"
              style={{ color: TaskTypeColors["muted"] }}
            >
              {" "}
              <TimeAgo
                date={task.created_at}
                live={false}
                component={(e) => (
                  <span>
                    {" "}
                    {e.children
                      .replace("minutes", "mins")
                      .replace("hours", "hrs")}{" "}
                  </span>
                )}
              />{" "}
            </span>
          )}
        </div>
      ))}

      {/* Load More */}
      <LoadMore
        onLoad={() => {
          setDisplayCount(displayCount + 10);
        }}
        showHide={taskList.length > displayCount}
      />
    </div>
  );
};
