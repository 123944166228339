/* eslint-disable jsx-a11y/alt-text */
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useState
} from "react";
import { useUserCompany } from "../../features/User/userQueries";
import knacklogo from '../../components/Asset/logo.svg'
interface IKnackImageProps extends ComponentPropsWithoutRef<"img"> {
  fallback?: string;
  fallbackProps?: Omit<ComponentPropsWithoutRef<"img">, "src">;
}
const KnackImage = forwardRef<HTMLImageElement, IKnackImageProps>(
  ({ fallback, src, fallbackProps, ...props }, ref) => {
    const { data: company } = useUserCompany();
    const [showFallback, setShowFallback] = useState(() => Boolean(!src));
    useEffect(() => {
      if (src) {
        if (showFallback) {
          setShowFallback(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);
 
    return (
      <>
        {src && !showFallback && (
          <img
            ref={ref}
            onError={() => setShowFallback(true)}
            src={src}
            {...props}
          />
        )}
        {(showFallback || !src) && (
          <img ref={ref} src={fallback || company?.logo || knacklogo} {...props} {...fallbackProps} />
        )}
      </>
    );
  }
);

export default KnackImage;
