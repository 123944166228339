import { Box, Breadcrumbs, Button, Typography } from "knack-ui";
import { lazy } from "react";
import { Link, useParams } from "react-router-dom";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import { PageHeightWithoutBreadcrumbs } from "../../../lib/constants";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { loadComponent, validateSurveyComponents } from "../../../lib/utils";
import { useGetSurvey } from "../surveyQueries";
import SurveyForm from "./SurveyForm/SurveyForm";
import { MdOutlineAttachEmail } from "react-icons/md";

const LazySurveyResponses = lazy(() => loadComponent(() => import("./SurveyResponses/SurveyResponses")));
const SurveyResponses = withErrorBoundary(
  withSuspense(LazySurveyResponses, {
    fallBackComponent: <PageSkeleton />
  })
);

const Survey = () => {
  const { id } = useParams<{ id: string }>();
  const { data: survey } = useGetSurvey({ id });
  return (
    <div className="h-full">
      <Box variant="outlined" as={Breadcrumbs} className="px-8 py-2 mb-4 ">
        {[
          <Link key="link" to="/survey">
            Surveys
          </Link>,
          <Typography key="name" clamp={1}>
            {survey?.title}
          </Typography>
        ]}
      </Box>
      <div style={{ height: PageHeightWithoutBreadcrumbs, overflowY: "auto" }} className="h-full overflow-hidden border rounded-lg">
        <Tabs style={{ height: "100%", overflowY: "auto" }}>
          <TabList className="px-4 border-b">
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Questions
            </Tab>
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Results
            </Tab>
          </TabList>

          <TabPanel
            key="past"
            style={{
              height: `calc(100% - 48px)`,
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            {survey && (
              <SurveyForm
                survey={{
                  ...survey,
                  components: validateSurveyComponents(survey.components)
                }}
                preview
              />
            )}
          </TabPanel>
          <TabPanel
            key="past"
            style={{
              height: `calc(100% - 48px)`,
              overflowX: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem"
            }}
          >
            {survey && <SurveyResponses surveyId={survey._id} />}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Survey;
