import { Stack, Typography } from "knack-ui";
import { useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";

import {
  SidebarWidthExtended,
  HrNavigationItems,
  ExpertNavigationItems,
  UserNavigationItems,
  SidebarWidthCollapsed,
  AdminNavigationItems,
  CoachNavigationItems
} from "../../lib/constants";
import SVG, { SVGProps } from "../SVG/SVG";
import { INavigationItem } from "../../lib/interfaces/generalTypes";
import { useUserCompany, useUser, useUserRoles } from "../../features/User/userQueries";
import { SidebarState } from "./AppLayout";
import { Logo } from "../logo/Logo";
import ProfileMenu from "../Navbar/ProfileMenu";
import { useGetNotifications } from "../../features/Notifications/notificationQueries";
import styled from "styled-components";
import Divider from "../Divider/Divider";
import { useImpersonation } from "../../hooks/useImpersonation";
import { useLocalStorage } from "../../hooks/useLocalStorage";

const Sidebar = () => {
  const { setValue, getValue, removeItem } = useLocalStorage();
  const { isImpersonating, setImpersonation } = useImpersonation();

  // TODO: get this value from localStorage.
  const { expanded, setExpanded } = SidebarState();
  const { roleState } = useUserRoles();
  const { data: user } = useUser();
  const { data: company } = useUserCompany();

  const { data: notifications } = useGetNotifications({
    filterBy: "all",
    max: 10
  });

  const schedulingEnabled = useMemo(() => {
    if (user?.account_type === "Coach") {
      return true;
    }

    if (company && company?.schedulingSupported) return true;
  }, [company]);

  const sideItems = useMemo(() => {
    // TODO: - Make dynamic for all features or routes
    let _navigations = UserNavigationItems;
    if (user && ["Admin", "Subadmin"].includes(user.account_type)) {
      _navigations = AdminNavigationItems;
      if (user.account_type !== "Admin") {
        _navigations = _navigations.filter((r) => r.href !== "/payment" && r.href !== "/coach");
      }
    } else if (user?.account_type === "Coach") {
      _navigations = CoachNavigationItems;
    } else if (roleState?.activeRole === "Hr") {
      _navigations = HrNavigationItems;
    } else if (roleState?.activeRole === "Expert") {
      if (!schedulingEnabled) {
        _navigations = ExpertNavigationItems.filter((n) => n.title !== "Availability");
      } else {
        _navigations = ExpertNavigationItems;
      }
    }
    //
    if (!company?.features?.includes("Learning")) {
      _navigations = _navigations.filter((item) => item.title !== "Learning");
    }
    if (!company?.features?.includes("Assessment")) {
      _navigations = _navigations.filter((item) => item.title !== "Assessment" && item.title !== "Assessment reports");
    }
    return _navigations;
  }, [roleState, user, company]);

  function onSwitchToAdmin() {
    const adminCred = getValue("tempUser");
    setValue("user", adminCred);
    removeItem("tempUser");
    setImpersonation(false);
    window.location.replace(window.location.origin + "/allusers");
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  return (
    <Wrapper id="app-side-bar">
      <aside
        style={{
          width: expanded ? SidebarWidthExtended : SidebarWidthCollapsed,
          gridTemplateRows: "auto 1fr auto auto auto",
          maxBlockSize: "100%"
        }}
        className="fixed top-0 bottom-0 grid grid-cols-1 gap-4 px-4 py-4 place-content-start start-0 border-e bg-paper"
      >
        <Link style={{ maxHeight: 40 }} className="flex justify-center" to="/dashboard">
          <Logo />
        </Link>

        <nav
          className={`grid h-full place-content-start grid-cols-1 gap-2 pt-4 overflow-x-hidden border-t dialog-content-max-h -mx-4 px-4 ${
            expanded ? "scrollhost" : ""
          }`}
        >
          {sideItems.map((navItem) => (
            <NavItem item={navItem} expanded={expanded} key={navItem.href} />
          ))}
        </nav>
        <div className="grid grid-cols-1 gap-2">
          {isImpersonating && (
            <NavItem
              item={{
                href: "",
                title: "Switch To Admin",
                icon: "User",
                onClick: () => onSwitchToAdmin()
              }}
              expanded={expanded}
            />
          )}

          {company?.externalLink && company?.externalLink?.url && company?.externalLink?.name && (
            <NavItem
              item={{
                href: company.externalLink.url,
                title: company.externalLink.name,
                icon: "Learning",
                externalLink: true
              }}
              expanded={expanded}
            />
          )}
          {!["Admin", "Coach", "Subadmin"].includes(user?.account_type as string) && Boolean(user?.profile) && (
            <NavItem
              item={{
                href: "/notification-center",
                title: "Notifications",
                icon: "Bell",
                meta: notifications && notifications.data.some((n) => n.isSeen === false) ? notifications.unSeenCount.toString() : undefined
              }}
              expanded={expanded}
            />
          )}
          {!["Admin", "Subadmin"].includes(user?.account_type as string) && (
            <>
              {/* <NavItem
                item={{
                  href: "/settings",
                  title: "Settings",
                  icon: "Gear"
                }}
                expanded={expanded}
              /> */}
              <NavItem
                item={{
                  href: "https://help.knacklab.co/",
                  title: "Help Center",
                  icon: "HelpCenter",
                  externalLink: true
                }}
                expanded={expanded}
              />
            </>
          )}
        </div>

        <Divider />
        <ProfileMenu />
        <ExpandButton onClick={() => setExpanded(!expanded)}>
          <BiChevronLeft className={`transform transition-transform duration-200 ${expanded ? "" : " rotate-180"}`} size={20} />
        </ExpandButton>
      </aside>
    </Wrapper>
  );
};

export default Sidebar;

const NavItem = ({ item, expanded }: { item: INavigationItem; expanded: boolean }) => {
  return (
    <NavLink
      dir="ltr"
      title={item.title}
      activeClassName={"bg-tertiary text-tertiary"}
      className="relative font-medium transition-colors duration-150 rounded-lg hover:bg-tertiary hover:text-tertiary"
      style={{ fontSize: "0.925em" }}
      to={{ pathname: item.href }}
      target={item.externalLink ? "_blank" : "_self"}
      onClick={() => {
        if (item.onClick) item.onClick();
      }}
    >
      <Stack
        justifyContent={expanded ? "flex-start" : "center"}
        alignItems="center"
        className="p-3 rounded-md cursor-pointer flex-nowrap"
        gap={4}
      >
        <SVG className="flex-shrink-0" name={item.icon as SVGProps["name"]} size={18} />
        {expanded && <Typography variant="body2">{item.title}</Typography>}
      </Stack>
      {item.meta && (
        <span
          style={{ width: "22px", height: "22px" }}
          className={`absolute z-10 flex justify-center items-center bottom-0 text-xs rounded-full text-white bg-warning my-auto  ${
            expanded ? "top-0 end-3" : "-top-9 -end-2"
          }  pointer-events-none `}
        >
          {item.meta}
        </span>
      )}
    </NavLink>
  );
};

const Wrapper = styled.div`
  isolation: isolate;
`;

const ExpandButton = styled.button`
  background-color: rgb(var(--tertiary-color));
  &:hover {
    background-color: rgb(var(--tertiary-dark-color));
  }
  color: var(--tertiary-text-color);
  border-radius: 50%;
  position: absolute;
  left: 100%;
  bottom: 25%;
  transform: translateX(-50%);
  z-index: 100;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
