import create from "zustand";

type IProps = {
  loading: boolean;
  setLoading: (state: boolean) => void;
};

export const useLoadingSpinner = create<IProps>((set) => ({
  loading: false,
  setLoading: (loadingState: boolean) => set(() => ({ loading: loadingState }))
}));
