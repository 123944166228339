import { useCallback, useEffect, useState } from "react";
import { PROGRAM_TYPES, USER_ROLES } from "../../App.constant";
import hrDashboardServices from "../../services/dashboard/hrDashboard.services";
import CoachingInsightsTimeline from "./CoachingInsightsTimeline";
import { ProfileCard } from "./ProfileCard";

const CoachingInsights = ({ user, program, setUserId }) => {
  const [coachees, setCoachees] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [tab, setTab] = useState("Sessions & Timeline");
  const [tabs, setTabs] = useState([]);

  const getCoachees = useCallback(async (id, programId) => {
    try {
      let filter = programId
        ? `?mentor=${id}&program=${programId}`
        : `?mentor=${id}`;
      const res = await hrDashboardServices.getRequests(filter);
      if (res.status) {
        const { data } = res.data;
        if (typeof data !== "string" && data.length > 0) {
          let accepted = data?.filter(
            (d) =>
              d.status === "approved" &&
              d.program.program_type === PROGRAM_TYPES.COACHING &&
              d.mentee !== id
          );
          setCoachees(accepted);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getCoaches = useCallback(async (id, programId) => {
    try {
      let filter = programId
        ? `?mentee=${id}&program=${programId}`
        : `?mentee=${id}`;
      const res = await hrDashboardServices.getRequests(filter);
      if (res.data.status === true) {
        const { data } = res.data;
        if (typeof data !== "string" && data.length > 0) {
          let accepted = data?.filter(
            (d) =>
              d.status === "approved" &&
              d.program.program_type === PROGRAM_TYPES.COACHING &&
              d.mentor !== id
          );
          setCoaches(accepted);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const addedTabs = [{ name: "Sessions & Timeline" }];
    if (user.user.assigned_coach !== null) {
    }
    if (user.user.roles.includes("coachee")) {
      addedTabs.push({ name: "Coaches" });
      getCoaches(user.user._id, program?._id);
    }
    if (user.user.roles.includes("coach")) {
      addedTabs.push({ name: "Coachees" });
      getCoachees(user.user._id, program?._id);
    }
    setTabs(addedTabs);
  }, [getCoachees, getCoaches, user, program]);

  return (
    <div>
      <div className="text-skin-base">
        {/* Tabs & Filter */}
        <div className="flex flex-row items-center justify-between">
          {/* Tabs */}
          <div className="flex flex-row justify-between w-full overflow-x-auto text-xs capitalize border-b border-gray-200 sm:justify-start">
            {tabs?.map((t, i) => (
              <div
                onClick={() => setTab(t.name)}
                key={i}
                className={`flex flex-row items-center px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-skin-light transition duration-75 ease-in-out text-skin-muted ${
                  t.name === tab &&
                  "border-b-2 border-skin-base text-skin-inverted"
                } `}
              >
                {t?.icon && <div className="mr-2">{t?.icon} </div>}
                <div>{t?.name} </div>
              </div>
            ))}
          </div>
        </div>

        {/* Card List */}
        <div className="">
          {
            <div>
              {tabs?.map((t, i) => (
                <div key={i}> {t.name === tab.name ? t?.Component : ""} </div>
              ))}
            </div>
          }
        </div>
      </div>
      <div className="my-2">
        {tab === "Sessions & Timeline" && (
          <CoachingInsightsTimeline user={user} />
        )}
        <div
          className="grid gap-4 pr-2 overflow-y-auto scrollhost2"
          style={{ maxHeight: 400 }}
        >
          {tab === "Coachees" ? (
            coachees.length > 0 ? (
              coachees.map((c, i) => (
                <div key={c._id}>
                  {" "}
                  <ProfileCard
                    onClick={() => setUserId(c?.mentee)}
                    m={c}
                    key={c?._id}
                    i={i}
                    listType={USER_ROLES.USER}
                  />
                </div>
              ))
            ) : (
              <div>
                <p className="flex items-center justify-center text-sm h-28">
                  No Coachees are available
                </p>
              </div>
            )
          ) : null}
          {tab === "Coaches" ? (
            coaches.length > 0 ? (
              coaches.map((c, i) => (
                <div key={c._id}>
                  {" "}
                  <ProfileCard
                    m={c}
                    onClick={() => setUserId(c?.mentor)}
                    key={c?._id}
                    i={i}
                    listType={USER_ROLES.EXPERT}
                  />{" "}
                </div>
              ))
            ) : (
              <div>
                <p className="flex items-center justify-center text-sm h-28">
                  No Coaches are available
                </p>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CoachingInsights;
