import { format } from "date-fns";
import { Box, Typography, Stack, Popover, Button } from "knack-ui";
import { useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import SVG from "../../../components/SVG/SVG";
import { ICampaign } from "../CampaignTypes";

interface IProps {
  campaign: ICampaign;
}

const CampaignCard = ({ campaign }: IProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  return (
    <>
      <Box
        onClick={() => {
          history.push(`/campaigns/${campaign._id}`);
        }}
        className="relative cursor-pointer"
        dir="ltr"
        paddingPreset="card"
        variant="outlined"
      >
        <Typography variant="body1" fontWeight="semibold">
          {campaign.subject}
        </Typography>
        <Typography
          className="my-1"
          as={Stack}
          gap={3}
          color="muted"
          variant="body2"
        >
          <SVG name="Users" />
          <span>{campaign.recipients.length} recipients</span>
        </Typography>

        <Typography as={Stack} gap={3} color="muted" variant="body2">
          <SVG name="Calendar" />
          <span>{format(new Date(campaign.created_at), "dd/MM/yyyy")}</span>
        </Typography>
        <div className="absolute top-2 end-2">
          <Button
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(true);
            }}
            iconOnly
            // variant="small"
            kind="ghost"
            shape="rounded"
          >
            <BiDotsVerticalRounded size={20} />
          </Button>
        </div>
      </Box>
      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={menuOpen}
        popoverProps={{ className: "py-1" }}
        onClose={() => setMenuOpen(false)}
        parentRef={buttonRef}
      >
        <Button
          variant="small"
          kind="tertiary"
          shape="square"
          onClick={() =>
            history.push({
              pathname: "/new-campaign",
              state: { subject: campaign.subject, body: campaign.body }
            })
          }
        >
          Make copy
        </Button>
      </Popover>
    </>
  );
};

export default CampaignCard;
