import PageSkeleton from "../skeletons/PageSkeleton";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useUser } from "../../features/User/userQueries";
import { useMediaQuery } from "react-responsive";
import MobileWarning from "../MobileWarning/MobileWarning";

const ProtectedRoute = (props: RouteProps) => {
  const { pathname } = useLocation();
  const {
    data: user,
    isLoading,
    error
  } = useUser({
    useErrorBoundary: false,
    retry: (retryCount, error) => {
      if (error === "Not Authenticated") {
        return false;
      }
      if (retryCount === 3) {
        return false;
      }
      return true;
    }
  });
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  if (isMobile) {
    return <MobileWarning />;
  }
  if (isLoading) {
    return <PageSkeleton height="100vh" />;
  }
  if (!isLoading && Boolean(user)) {
    return <Route {...props} />;
  }
  if (!isLoading && error) {
    return <Redirect to={{ pathname: "/auth/login", state: { redirect: pathname } }} />;
  }
  return <Redirect to={{ pathname: "/auth/login", state: { redirect: pathname } }} />;
};

export default ProtectedRoute;
