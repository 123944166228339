import { Box, Button, Input, Stack, Typography } from "knack-ui";
import React, { useCallback } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import SVG from "../../../components/SVG/SVG";
import { useGetOrganizationPrograms } from "../../../features/Programs/programQueries";
import { IProgram } from "../../../features/Programs/ProgramTypes";
import { useUser } from "../../../features/User/userQueries";
import {
  PageHeightWithoutHeader,
  PageTitleHeight
} from "../../../lib/constants";
import { ProgramsSearch } from "./Programs";
import ProgramsList from "./ui/ProgramsList";

const HrPrograms = () => {
  const { search, setSearch } = ProgramsSearch();

  const { data: user } = useUser();
  const { data } = useGetOrganizationPrograms(
    {
      organizationId: user?.profile!.organization
    },
    {
      select: useCallback(
        (programs: IProgram[]) => {
          return programs
            .filter((p) => !p.deleted)
            .filter(
              (p) => p.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
        },
        [search]
      )
    }
  );
  return (
    <div className="h-full">
      <div className="h-full overflow-hidden border rounded-lg">
        <Box
          as={Stack}
          square
          variant="outlined"
          elevation={0}
          alignItems="center"
          style={{
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            height: PageTitleHeight
          }}
          justifyContent="space-between"
          className="px-6 py-4"
        >
          <Stack gap={2}>
            <Typography as="h5" variant="h6">
              Programs
            </Typography>
            <Typography color="muted">({data?.length})</Typography>
          </Stack>
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="w-72"
            inputPrefix={<AiOutlineSearch size={20} />}
            inputSuffix={
              search !== "" ? (
                <Button
                  onClick={() => setSearch("")}
                  iconOnly
                  kind="ghost"
                  variant="small"
                >
                  <SVG name="Close" />
                </Button>
              ) : (
                <></>
              )
            }
            placeholder="Search for a program..."
          />
        </Box>

        {data && (
          <div
            className="p-4 bg-paper"
            style={{
              height: PageHeightWithoutHeader,
              overflowY: "auto"
            }}
          >
            <ProgramsList programs={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HrPrograms;
