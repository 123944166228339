import { Box, Button, Stack, Typography, Popover } from "knack-ui";
import { Link } from "react-router-dom";
import { TGoal } from "../../../../features/Goals/goalsTypes";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useDeleteGoal } from "../../../../features/Goals/goalQueries";
import goalDefaultImg from "../../../../components/Asset/Images/goalDefaultImg.png";
import { motion } from "framer-motion";
import { useRef, useState } from "react";
import { GoalCardAnimationVariants } from "./GoalsList";
interface IGoalCardProps {
  goal: TGoal;
  admin?: boolean;
  defaultGoal?: boolean;
  onGoalClick?: (goal: TGoal) => void;
}
// TODO: Add text clamp
const GoalCard = ({
  goal,
  admin,
  onGoalClick,
  defaultGoal
}: IGoalCardProps) => {
  const { mutateAsync } = useDeleteGoal();
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const handleDeleteGoal = async () => {
    try {
      await mutateAsync(goal._id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        as={motion.div}
        layout
        dir="ltr"
        variant="outlined"
        variants={GoalCardAnimationVariants}
        exit="exit"
        initial="hidden"
        animate="visible"
        paddingPreset="card"
        className="relative flex flex-col gap-3"
      >
        <div className="self-center w-24 h-24 ">
          <img
            className="object-cover w-full h-full"
            src={goalDefaultImg}
            alt={goal?.title}
          />
        </div>
        <Stack style={{ height: "108px" }} direction="column" gap={3}>
          <Typography
            textAlign="center"
            variant="body1"
            fontWeight="bold"
            as="h6"
            clamp={2}
          >
            {goal.title}
          </Typography>
          <Typography
            clamp={2}
            textAlign="center"
            color="muted"
            variant="body2"
            as="p"
          >
            {goal.outcome}
          </Typography>
        </Stack>
        <Button
          kind={defaultGoal ? "secondaryOutline" : "primary"}
          onClick={() => {
            if (admin || defaultGoal) {
              onGoalClick?.(goal);
            }
          }}
          variant="small"
          fullWidth
        >
          {defaultGoal && "Use template"}
          {admin && "View / Edit"}
          {!admin && !defaultGoal && (
            <Link className="w-full text-center" to={`/goals/${goal._id}`}>
              Details
            </Link>
          )}
        </Button>
        {!defaultGoal && (
          <div className="absolute top-2 end-2" style={{ marginTop: 0 }}>
            <Button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(true);
              }}
              iconOnly
              // variant="small"
              kind="ghost"
              shape="rounded"
            >
              <BiDotsVerticalRounded size={20} />
            </Button>
          </div>
        )}
      </Box>
      <Popover
        animationType="fade-up"
        offset={{ bottom: 10, left: 0 }}
        isOpen={menuOpen}
        popoverProps={{ className: "py-1" }}
        onClose={() => setMenuOpen(false)}
        parentRef={buttonRef}
      >
        <Button
          variant="small"
          kind="warning"
          shape="square"
          onClick={handleDeleteGoal}
        >
          Delete goal
        </Button>
      </Popover>
    </>
  );
};

export default GoalCard;
