import { Box, Button, Stack, Typography } from "knack-ui";
import { SlideLeftWithFade } from "../../../../lib/animationConstants";
import {
  ISurveyResponse,
  SurveyResponseSection,
  SurveySection as SurveySectionType
} from "../../surveyTypes";
import SurveyQuestion from "./SurveyQuestion";
import { motion } from "framer-motion";
import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";

interface IProps {
  index: number;
  section: SurveySectionType;
  setActiveSection: Dispatch<SetStateAction<number>>;
  preview?: boolean;
  submitSurvey: VoidFunction;
}

const SurveySection = ({
  index,
  section,
  setActiveSection,
  submitSurvey,
  preview
}: IProps) => {
  const { getValues: getGlobalFormValues, setValue: setGlobalFormValue } =
    useFormContext<ISurveyResponse>();
  const { control, handleSubmit, getValues } = useForm<{
    answers: SurveyResponseSection;
  }>({
    defaultValues: { answers: getGlobalFormValues(`answers.${index}`) }
  });
  const numberOfSections = getGlobalFormValues("answers").length;

  const onSubmit: SubmitHandler<{ answers: SurveyResponseSection }> = (
    data
  ) => {
    try {
      setGlobalFormValue(`answers.${index}`, data.answers);
      if (numberOfSections - 1 !== index) {
        return incrementSection();
      }
      if (numberOfSections - 1 === index) {
        submitSurvey();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const incrementSection = () => {
    setActiveSection((prev) => prev + 1);
  };
  return (
    <motion.div
      variants={SlideLeftWithFade}
      exit="exit"
      initial="hidden"
      animate="visible"
    >
      {index !== 0 && (
        <Box
          variant="outlined"
          className={`${
            section.section_description ? "mb-4" : ""
          } relative overflow-hidden`}
        >
          <div className="px-6 py-3 bg-primary text-primary">
            <Typography variant="h6">{section.section_title}</Typography>
          </div>
          {section.section_description && (
            <div className="px-6 py-4">
              <Typography variant="body2">
                {section.section_description}
              </Typography>
            </div>
          )}
        </Box>
      )}
      {section.section_questions.map((q, i) => (
        <SurveyQuestion
          control={control}
          question={q}
          key={i}
          sectionIndex={index}
          index={i}
        />
      ))}
      <Stack className="mt-6" gap={4}>
        {index > 0 && (
          <Button
            onClick={() => {
              setGlobalFormValue(`answers.${index}`, getValues().answers);
              setActiveSection((prev) => prev - 1);
            }}
          >
            Back
          </Button>
        )}
        {numberOfSections - 1 !== index && (
          <Button onClick={preview ? incrementSection : handleSubmit(onSubmit)}>
            Next
          </Button>
        )}
        {numberOfSections - 1 === index && !preview && (
          <Button disabled={preview} onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
        )}
      </Stack>
    </motion.div>
  );
};

export default SurveySection;
