import moment from "moment";
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import { ServerResponseV2 } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { IPaymentAnalytics, PaymentMetrics, IPaymentReportsQuery } from "./paymentTypes";
import { endOfDay } from "date-fns/esm";

export interface IPaymentAnalyticsResponse {
  sessions: IPaymentAnalytics[];
  totalPages: number;
  totalRecords: number;
  totalRecordsPerPage: number;
  currentPage: number;
  metrics: PaymentMetrics;
}

export const paymentAnalyticsQueryKeys = {
  all: [{ scope: "payment analytics" }] as const,
  paymentList: (query: Partial<IPaymentReportsQuery>) => [{ ...paymentAnalyticsQueryKeys.all[0], entity: "payments list", ...query }]
};

async function fetchPaymentSession({
  pageParam = 1,
  queryKey: [{ coach, client, organization, sessionStatus, paymentStatus, sessionType, sessionMode, dateRange, programType }]
}: QueryFunctionContext<ReturnType<typeof paymentAnalyticsQueryKeys["paymentList"]>>) {
  console.log(dateRange);
  const endDate =
    dateRange![0]?.startDate === dateRange![0]?.endDate
      ? endOfDay(dateRange![0]?.startDate as Date)
      : endOfDay(dateRange![0]?.endDate as Date);

  const params: Record<string, any> = {
    page: pageParam,
    coach: coach?.join(","),
    client: client?.join(","),
    organization: organization?.join(","),
    sessionStatus: sessionStatus?.join(","),
    paymentStatus: paymentStatus?.join(","),
    sessionType: sessionType?.join(","),
    sessionMode: sessionMode?.join(","),
    startDate: dateRange![0]?.startDate,
    endDate,
    programType: programType?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponseV2<IPaymentAnalyticsResponse>>("v2/analytics/payments", {
    params
  });

  return res.data.data;
}

export const useGetPaymentAnalyticsList = <SelectData = IPaymentAnalyticsResponse, Error = unknown>(
  query: Partial<IPaymentReportsQuery>,
  options?: UseInfiniteQueryOptions<
    IPaymentAnalyticsResponse,
    Error,
    SelectData,
    IPaymentAnalyticsResponse,
    ReturnType<typeof paymentAnalyticsQueryKeys["paymentList"]>
  >
) => {
  return useInfiniteQuery<IPaymentAnalyticsResponse, Error, SelectData, ReturnType<typeof paymentAnalyticsQueryKeys["paymentList"]>>(
    paymentAnalyticsQueryKeys.paymentList(query),
    fetchPaymentSession,
    {
      getNextPageParam: (lastPage) => {
        if (+lastPage.currentPage < lastPage.totalPages) {
          return +lastPage.currentPage + 1;
        }
        return undefined;
      },
      ...options
    }
  );
};
