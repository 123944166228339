const GoalsCompleted = ({ className, size }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size || "70"}
      height={size || "71"}
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2615 3.79065H13.5182V6.12398H24.2615V3.79065ZM24.2615 13.124H13.5182V15.4573H24.2615V13.124ZM4.61316 8.45732L0.387451 4.32732L2.07057 2.68232L4.60122 5.15565L9.66252 0.208984L11.3456 1.85398L4.61316 8.45732ZM4.61316 17.7906L0.387451 13.6607L2.07057 12.0157L4.60122 14.489L9.66252 9.54232L11.3456 11.1873L4.61316 17.7906Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GoalsCompleted;
