import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { PageTitle } from "../../../../../../../components/Typography/PageTitle";
import { ProgramState } from "../../../../../../../StateManagement/ProgramCreation";

export const Agreement = ({ reference }) => {
  const { program, setProgram } = ProgramState();
  const [agreement] = useState(
    program.agreement !== "" ? program.agreement : ""
  );

  return (
    <div ref={reference}>
      <SunEditor
        disable={program.status === "Published" ? true : false}
        setOptions={{
          buttonList: [
            ["undo", "redo", "font", "fontSize", "formatBlock"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
              "removeFormat"
            ],
            [
              "fontColor",
              "hiliteColor",
              "align",
              "horizontalRule",
              "list",
              "table"
            ],
            ["link", "showBlocks", "codeView"]
          ]
        }}
        defaultValue={agreement}
        placeholder="Type or paste in your agreement"
        onChange={(e) => {
          const _program = { ...program };
          _program.agreement = e;
          setProgram(_program);
        }}
        height="400"
      />
      <PageTitle
        subTitle={`* ${
          program.program_type === "Mentoring" ? "Mentors" : "Coaches"
        } and  ${
          program.program_type === "Mentoring" ? "mentees" : "coachees"
        } will read & accept this agreement before starting their engagement together`}
      />
    </div>
  );
};
