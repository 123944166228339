import { Box, Button, Typography } from "knack-ui";
import SVG, { SVGProps } from "../../../components/SVG/SVG";

interface IProps {
  title: string;
  onView: VoidFunction;
  icon: SVGProps["name"];
  isComingSoon?: boolean;
}

const ReportTitledCard = ({ icon, onView, title, isComingSoon }: IProps) => {
  return (
    <Box variant="outlined" className="overflow-hidden">
      <div
        dir="ltr"
        className={`p-6 grid relative items-center grid-cols-1 gap-6 justify-items-center place-content-center ${
          isComingSoon ? "bg-gray-50" : ""
        }`}
      >
        {isComingSoon && (
          <div className="absolute px-4 py-2 text-xs bg-red-200 rounded-full rounded-r-none top-5 end-0 bg-opacity-40 text-warning">
            Coming soon
          </div>
        )}
        <SVG name={icon} size={55} className="text-skin-muted" />
        <Typography variant="h6" fontWeight="medium">
          {title}
        </Typography>
        <Button variant="small" onClick={onView} disabled={isComingSoon} fullWidth>
          View
        </Button>
      </div>
    </Box>
  );
};

export default ReportTitledCard;
