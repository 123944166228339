import { Stack, Typography } from "knack-ui";
import {
  MdOutlineSentimentVerySatisfied,
  MdOutlineSentimentSatisfied,
  MdOutlineSentimentNeutral,
  MdSentimentDissatisfied,
  MdSentimentVeryDissatisfied
} from "react-icons/md";
import { IUserProfile } from "../../../User/UserTypes";
import { ITask } from "../../SessionsTypes";
const emotions = [
  {
    score: 1,
    emoji: <MdSentimentVeryDissatisfied size={30} />,
    name: "Strongly disagree"
  },
  {
    score: 2,
    emoji: <MdSentimentDissatisfied size={30} />,
    name: "I disagree"
  },
  {
    score: 3,
    emoji: <MdOutlineSentimentNeutral size={30} />,
    name: "Neutral"
  },
  {
    score: 4,
    emoji: <MdOutlineSentimentSatisfied size={30} />,
    name: "I agree"
  },
  {
    score: 5,
    emoji: <MdOutlineSentimentVerySatisfied size={30} />,
    name: "Strongly agree"
  }
];

interface IProps {
  expert: IUserProfile;
  type: ITask["type"];
  step: number;
  setStep: (value: number) => void;
  questions: string[];
  setRating: (question: any, score: any) => void;
}
export const SessionRagingSliders = ({
  expert,
  type,
  step,
  setStep,
  questions,
  setRating
}: IProps) => {
  return (
    <div className="p-6 dialog-content-max-h">
      <div className="">
        <>
          <div className="flex flex-row items-center justify-between">
            {step !== questions.length && (
              <Typography color="muted" variant="caption">
                {step + 1} of {questions.length}
              </Typography>
            )}
          </div>
          {/* Rating Question */}

          <div>
            {[...questions].map((q, i) => (
              <div
                key={i}
                className={`transform transition delay-150 duration-300 ease-in-out ${
                  step === i
                    ? "translate-x-0"
                    : "absolute -translate-x-full opacity-0"
                }`}
              >
                <Typography className="my-2" variant="h6" fontWeight="semibold">
                  {q}
                </Typography>
                {/* <Typography variant="subtitle2">
                 How satisfied are you with the statement above?
                </Typography> */}

                {/* Rating Options */}
                <div className="flex justify-center mt-8">
                  <div className="grid grid-cols-5 gap-2">
                    {emotions.map((e, i) => (
                      <Stack
                        gap={2}
                        key={i}
                        direction='column'
                        alignItems="center"
                        className="px-4 py-2 transition-colors duration-200 rounded-lg cursor-pointer whitespace-nowrap hover:bg-tertiary"
                        onClick={() => {
                          setRating(q, e.score);
                          if (questions.length !== step + 1) {
                            setStep(step + 1);
                          }
                        }}
                      >
                        {e.emoji}
                        <Typography variant="caption" textAlign="center">
                          {e.name}
                        </Typography>
                      </Stack>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      </div>
    </div>
  );
};
