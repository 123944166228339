import { Box, Button, Typography } from "knack-ui";
import { useCallback } from "react";
import { VscClose } from "react-icons/vsc";
import ProgramCard from "../../../../components/ProgramCard/ProgramCard";
import { DURATIONS } from "../../../../lib/constants";
import { useGetProgramInvitations } from "../../../Programs/programQueries";
import { IProgramInvite } from "../../../Programs/ProgramTypes";

interface IProps {
  onClose: VoidFunction;
}

const OnboardingDialog = ({ onClose }: IProps) => {
  const { data: invitations } = useGetProgramInvitations(
    { confirmed: false },
    {
      select: useCallback((data: IProgramInvite[]) => {
        const filtered = data.filter((p) => p.sent === true && p.program);
        let sorted = filtered.sort(
          // @ts-ignore
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        return sorted;
      }, []),
      staleTime: DURATIONS.thirtyMins
    }
  );

  return (
    <Box className="h-96" dir="ltr" elevation={0}>
      <div className="p-6 border-b">
        <Typography variant="h6">Join your first program! 🎉</Typography>
        <Button className="z-10 end-5 top-5" style={{ position: "absolute" }} onClick={onClose} kind="ghost" variant="small" iconOnly>
          <VscClose size={25} />
        </Button>
      </div>
      <div className="p-6" style={{ height: "calc(100% - 73px)", overflow: "hidden" }}>
        <Typography fontWeight="semibold" className="mb-6">
          You have an invitation to join this program:
        </Typography>
        {invitations && invitations.length > 0 && (
          <ProgramCard program={invitations[0].program} invitationId={invitations[0]._id} invitationType={invitations[0].type} />
        )}
      </div>
    </Box>
  );
};

export default OnboardingDialog;
