import { Link } from "react-router-dom";
import { SquareImage } from "../../../../../components/Image/SquareImage";
import { ParsedHTML } from "../../../../../components/Parser/ParsedHTML";
import { ProgramProgress } from "./ProgramProgress";

export const ListOfPrograms = ({
  surveys = [],
  programs = [],
  isLoaded = false,
  user,
  invitations = [],
  route,
  mentor,
  pendingRequests,
  approvedRequests
}) => {
  const currentRoute = window.location.pathname;

  const ConfirmedMentor = ({ programId, programType }) => {
    if (mentor) {
      const mentorRequest = mentor[programId];
      if (mentorRequest && mentor) {
        return (
          <div className="flex flex-row px-2 my-4">
            <p
              className="px-2 py-1 text-xs font-medium text-white bg-green-600 rounded-full"
              style={{ width: "fit-content" }}
            >
              {" "}
              Confirmed {programType === "Mentoring" ? "Mentor" : "Coach"}{" "}
            </p>
          </div>
        );
      }
    }
    return <div></div>;
  };

  const PendingRequests = ({ programId }) => {
    let pendingRequest = pendingRequests?.filter((s) => {
      if (s?.program?._id === programId) {
        return s.program;
      }
    });
    if (pendingRequest?.length > 0)
      return (
        <div className="flex flex-row px-2 my-4">
          <p
            className="px-2 py-1 text-xs font-medium text-white rounded-full"
            style={{ width: "fit-content", backgroundColor: "#2D3958" }}
          >
            {" "}
            {pendingRequest.length} Pending Request
          </p>
        </div>
      );
    return <div></div>;
  };

  const AcceptRequests = ({ programId, programType }) => {
    if (approvedRequests && approvedRequests?.length > 0) {
      let approvedRequest = approvedRequests?.filter((s) => {
        if (s?.program?._id === programId) {
          return s.program;
        }
      });
      if (approvedRequest.length > 0)
        return (
          <div className="flex flex-row px-2 my-4">
            <p
              className="px-2 py-1 text-xs font-medium text-white bg-green-600 rounded-full"
              style={{ width: "fit-content", backgroundColor: "#00A085" }}
            >
              Accepted {programType === "Mentoring" ? "Mentee" : "Coachee"}
            </p>
          </div>
        );
    }
    return <div></div>;
  };

  return (
    <div className="grid w-full gap-4 mb-8 sm:p-0 sm:grid-cols-1 xl:grid-cols-2">
      {/* isLoading Preview starts here*/}
      {!isLoaded &&
        [1, 2, 3, 4, 4, 4, 4].map((_, i) => (
          <div
            key={i}
            className="relative flex overflow-hidden bg-white rounded-lg shadow animate-pulse"
          >
            <div className="w-full p-4 animate-pulse">
              <h1 className="w-1/2 text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse h-7 "></h1>
              <p className="h-3 p-1 px-2 my-2 text-sm font-bold round-full animate-pulse text-skin-accent ">
                {" "}
              </p>
              <h1 className="text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse "></h1>
              <h1 className="w-2/3 h-3 mb-3 text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse"></h1>
              <h1 className="w-1/2 h-3 mb-3 text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse"></h1>
            </div>
          </div>
        ))}

      {isLoaded &&
        invitations.map((p, i) => (
          <div
            key={i}
            className={`flex bg-white rounded-lg overflow-hidden shadow  relative `}
          >
            <Link
              to={{
                pathname: `/program-application/${p._id}`,
                state: { _id: p._id },
                key: "programs"
              }}
              className="w-full"
            >
              <div className="w-full h-full cursor-pointer hover:bg-skin-light">
                {/* Image */}
                <div>
                  <SquareImage image_url={p?.program?.image_url} index={i} />
                </div>
                {/* Progran type */}
                <div className="flex flex-row px-2 my-4">
                  <p
                    className={`px-2 py-1 text-xs rounded-full font-medium text-white bg-skin-accent`}
                    style={{ width: "fit-content" }}
                  >
                    {p?.program?.tag
                      ? p?.program?.tag
                      : p?.program?.program_type === "Coaching"
                      ? p?.program?.plan === "1:1"
                        ? "One-on-One Coaching"
                        : "Group Coaching"
                      : p?.program?.tag
                      ? p?.program?.tag
                      : p?.program?.type === "1:1"
                      ? "One-on-One Mentoring"
                      : p?.program?.type}
                  </p>
                </div>
                {/* Program Name */}
                <div className="px-2 mb-4">
                  <h1 className="mb-3 text-base font-bold text-gray-800 dark:text-white text-skin-base clampText">
                    {" "}
                    {p?.program?.name}
                  </h1>
                  <div className="mt-2 text-sm text-gray-600 dark:text-gray-300 font-reguler restrict-grid-text text-skin-base clampText">
                    <ParsedHTML htmlString={p?.program?.overview} />{" "}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      {isLoaded &&
        programs.map((p, i) => (
          <div
            key={i}
            className={`flex bg-white rounded-lg overflow-hidden shadow relative `}
          >
            <Link
              to={{
                pathname:
                  currentRoute === "/dashboard" || currentRoute === "/"
                    ? `programs/${p._id}/${p.program_type}`
                    : currentRoute === "/find-mentor"
                    ? "matches"
                    : route,
                state: { _id: p._id },
                key: "programs"
              }}
              className="w-full"
            >
              <div className="w-full h-full cursor-pointer hover:bg-skin-light">
                {/* Image */}
                <div>
                  <SquareImage image_url={p.image_url} index={i} />
                </div>
                {/* Progran type */}
                <div className="flex flex-row px-2 my-4 gap-2">
                  <p
                    className={`px-2 py-1 text-xs rounded-full font-medium text-white bg-skin-accent`}
                    style={{ width: "fit-content" }}
                  >
                    {p?.tag
                      ? p?.tag
                      : ["Coaching", "External Coaching"].includes(
                          p?.program_type
                        )
                      ? p?.plan === "1:1"
                        ? "One-on-One Coaching"
                        : "Group Coaching"
                      : p?.tag
                      ? p?.tag
                      : p?.type === "1:1"
                      ? "One-on-One Mentoring"
                      : p?.type}
                  </p>
                </div>
                {/* Program Name */}
                <div className="px-2 mb-4">
                  <h1 className="mb-3 text-base font-bold text-gray-800 dark:text-white text-skin-base clampText">
                    {" "}
                    {p?.name}
                  </h1>
                  <div className="mt-2 text-sm text-gray-600 dark:text-gray-300 font-reguler restrict-grid-text text-skin-base clampText">
                    <ParsedHTML htmlString={p?.overview} />{" "}
                  </div>
                </div>

                {user && user.account_type === "Mentor" && (
                  <>
                    <div className="flex flex-row px-1 my-4">
                      <div className="pb-4">
                        <PendingRequests programId={p._id} />
                      </div>
                      <div className="pb-4">
                        <AcceptRequests
                          programId={p._id}
                          programType={p.program_type}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* Sessions Completed */}
                {user && user.account_type === "Mentee" && (
                  <>
                    <div className="pb-4">
                      <ConfirmedMentor
                        programId={p._id}
                        programType={p.program_type}
                      />
                    </div>
                    <div className="px-2 pb-4">
                      <ProgramProgress program={p} userId={user._id} />
                    </div>
                  </>
                )}
              </div>
            </Link>
          </div>
        ))}
      {isLoaded &&
        surveys.map((m, i) => (
          <div
            key={i}
            className={`flex bg-white rounded-lg overflow-hidden shadow bg-white relative h-28 `}
          >
            <Link
              to={{
                pathname: `/fill-survey/${m.survey_share_id}`,
                state: { notification: m }
              }}
              key={i}
              className="flex flex-row items-center justify-between gap-1 p-2 border-b border-gray-100"
            >
              <div className="flex flex-row w-full gap-1 items-base">
                <div className="w-full p-2">
                  {/* Program Title */}
                  <div className="mb-3 text-base font-bold text-gray-800 dark:text-white text-skin-base clampText">
                    {m?.survey_name}{" "}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </div>
  );
};
