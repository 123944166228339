import AppLayout from "../components/Layout/AppLayout";
import { useUser, useUserRoles } from "../features/User/userQueries";
import ExpertDashboard from "../V3/Pages/Dasboard/ExpertDashboard";
import HrRoutes from "../V3/Pages/Dasboard/HrRoutes";
import UserDashboard from "../V3/Pages/Dasboard/UserDashboard";
import NewUserProfile from "../V3/Pages/Onboarding/ProfileCreation/NewUserProfile";

const ClientsRoutes = () => {
  const { data: user } = useUser();
  const { roleState } = useUserRoles();
  if (
    !user?.profile ||
    !user.profile?.first_name ||
    !user.profile?.last_name ||
    !user.profile?.position ||
    !user.profile?.entity ||
    !user.profile?.job_function ||
    !user.profile?.management_level
  ) {
    return <NewUserProfile />;
  }
  return (
    <>
      <AppLayout>
        {roleState?.activeRole === "Hr" && <HrRoutes />}
        {roleState?.activeRole === "Expert" && <ExpertDashboard />}
        {roleState?.activeRole === "User" && <UserDashboard />}
      </AppLayout>
    </>
  );
};

export default ClientsRoutes;
