import { Avatar, BaseDialog, Button, Popover, Stack, Typography } from "knack-ui";
import { useMemo, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAddAlarm, MdOutlineAccessAlarms } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import SVG from "../../../../components/SVG/SVG";
import { useGetConnectionProfile, useGetConnectionPrograms } from "../../../../features/Connections/connectionsQueries";
import { sessionQueryKeys } from "../../../../features/Sessions/sessionsQueries";
import { useUser, useUserCompany, useUserRoles } from "../../../../features/User/userQueries";
import { withErrorBoundary } from "../../../../lib/HOCs/withErrorBoundary";
import { normalizeLinkedinLink } from "../../../../lib/utils";
import { openManualSessionLogging } from "../../../../services/Scheduling/SessionLogging/SessionState";
// import LogSessionModal from "../../../../features/Sessions/ui/components/LogSessionModal/LogSessionModal";
import ScheduleSessionModal from "./ConnectionSessions/ScheduleSessionModal";
import EndRelationshipModal from "./EndRelationshipModal";

const ConnectionProfileHeader = () => {
  const { setOpen, setConnectionPrograms, setScheduler, setConnectionUserId, setCallBack } = openManualSessionLogging();

  const { id } = useParams<{ id?: string }>();
  const { data: connectionPrograms } = useGetConnectionPrograms({ id });
  const { data: connection } = useGetConnectionProfile({ id });
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [endModalOpen, setEndModalOpen] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const queryClient = useQueryClient();
  const { data: user } = useUser();

  const { data: programs } = useGetConnectionPrograms({ id }, { suspense: false });

  function onSchedule(isScheduler: boolean = false) {
    setConnectionPrograms(connectionPrograms);
    setOpen(true);
    setScheduler(isScheduler);
    setConnectionUserId(id);
    const callback = () => {
      queryClient.invalidateQueries(sessionQueryKeys["all"]);
    };
    setCallBack(callback);
  }

  const { data: company } = useUserCompany();
  const schedulingEnabled = useMemo(() => {
    if (user?.account_type === "Coach") {
      return true;
    }

    if (company && company?.schedulingSupported) return true;
  }, [company]);

  return (
    <Stack alignItems="flex-start" className="px-8 py-8">
      <Stack className="flex-1" alignItems="flex-start" gap={3}>
        <Avatar className="flex-shrink-0" size="large" text={connection?.first_name as string} image={connection?.user.profile_image} />
        <div className="grid gap-1">
          <Stack alignItems="center" gap={3}>
            <Typography variant="h5" as="h2">
              {`${connection?.first_name} ${connection?.last_name}`}
            </Typography>
            {connection?.user.account_type === "Coach" && (
              <>
                <Stack alignItems="center" gap={2} className="px-2 py-1 text-xs rounded bg-opacity-20 text-knackPrimary bg-knackPrimary">
                  <SVG name="KnackMiniLogo" size={12} /> <span>Knack coach</span>
                </Stack>
                <div className="px-2 py-1 text-xs rounded bg-opacity-20 text-warning bg-warning">Certified</div>
              </>
            )}
          </Stack>
          {connection?.user.account_type !== "Coach" && connection?.position && (
            <Typography variant="body1" as="p">
              {connection.position}
            </Typography>
          )}

          {connection?.user.account_type === "Coach" && connection?.tagline && (
            <Typography variant="body2" as="p">
              {connection.tagline}
            </Typography>
          )}
          <Stack alignItems="center" gap={4}>
            {connection?.linkedin_profile_url && (
              <Stack alignItems="center" gap={2}>
                <SVG className="text-blue-600" name="LinkedIn" />
                <Typography
                  as="a"
                  href={normalizeLinkedinLink(connection.linkedin_profile_url)}
                  target="_blank"
                  rel="noreferrer noopener"
                  variant="caption"
                  className="cursor-pointer hover:underline"
                >
                  View Linkedin profile
                </Typography>
              </Stack>
            )}
            <Typography alignItems="center" as={Stack} gap={2} variant="caption" color="secondary">
              <SVG name="Email" />
              <span>{connection?.user.email}</span>
            </Typography>
          </Stack>
        </div>
      </Stack>
      {!["Admin", "Subadmin"].includes(user?.account_type as string) && (
        <Stack alignItems="center" gap={4}>
          {connection?.user.account_type !== "Coach" && programs && programs.length !== 0 && (
            <Button onClick={() => onSchedule()} startIcon={<MdOutlineAccessAlarms size={20} />} kind="tertiary">
              Log previous session
            </Button>
          )}
          {schedulingEnabled && programs && programs.length !== 0 && (
            <Button onClick={() => onSchedule(true)} startIcon={<MdOutlineAddAlarm size={20} />}>
              Schedule upcoming session
            </Button>
          )}
          {user?.account_type !== "Coach" && programs && programs.length !== 0 && (
            <>
              <Button variant="large" iconOnly shape="rounded" ref={buttonRef} kind="ghost" onClick={() => setMenuOpen(true)}>
                <BsThreeDotsVertical size={20} />
                <Popover
                  offset={{ bottom: 10, left: 0 }}
                  animationType="fade-up"
                  parentRef={buttonRef}
                  popoverProps={{ variant: "outlined", className: "py-2" }}
                  isOpen={menuOpen}
                  onClose={() => setMenuOpen(false)}
                >
                  <Button onClick={() => setEndModalOpen(true)} shape="square" kind="warning" variant="small">
                    End Relationship
                  </Button>
                </Popover>
              </Button>
            </>
          )}
        </Stack>
      )}
      <BaseDialog dialogClassName="max-w-3xl" isOpen={scheduleModalOpen} onClose={() => setScheduleModalOpen(false)}>
        <ScheduleSessionModal handleClose={() => setScheduleModalOpen(false)} />
      </BaseDialog>

      <BaseDialog dialogClassName="max-w-2xl" isOpen={endModalOpen} onClose={() => setEndModalOpen(false)}>
        <EndRelationshipModal id={id} onClose={() => setEndModalOpen(false)} />
      </BaseDialog>
    </Stack>
  );
};

export default withErrorBoundary(ConnectionProfileHeader);
