import { CronofyService } from "../../services/Scheduling/CronofyService";
import office365 from "../../components/Asset/Images/office-365-logo.png";
import outlook from "../../components/Asset/Images/outlook-logo.png";
import icloud from "../../components/Asset/Images/apple-icloud-logo.png";
import google from "../../components/Asset/Images/google-logo.png";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export const ConnectCalendar = () => {
  const location = useLocation();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")).data
  );
  /**
   * Function will redirect to authenticate with user prefered calendar
   */
  function onConnectCalendar(provider) {
    if (location.state) {
      CronofyService.setCallBackData("/view-program", {
        _id: location.state._id
      });
    }
    // data to pull after redirect it completed
    CronofyService.requestCronofyAuthorizationURL({ provider_name: provider });
  }

  return (
    <div className="text-center text-skin-base">
      <div className="text-skin-accent font-bold text-sm">
        Calendar Integration
      </div>
      <div className="text-lg sm:text-2xl font-bold max-w-screen-md mx-auto">
        As part of the mentoring & coaching program you will engage with{" "}
        {user?.account_type === "Coach" ? "your clients" : "others"} by
        scheduling multiple meetings including conference calls
      </div>
      <div className="text-skin-muted font-medium text-base sm:text-lg mt-8">
        Select an option below to authorize access to your calendar
      </div>
      {/* Calendar list */}
      <div className="mt-8 grid sm:grid-cols-2 gap-6 sm:gap-4 max-w-screen-md mx-auto">
        {/* Google Calendar */}
        <div
          className="border rounded-lg  flex flex-col items-base sm:border-r w-full justify-center gap-4 text-center hover:bg-skin-light cursor-pointer p-4"
          onClick={() => onConnectCalendar("google")}
        >
          <div className="flex flex-col items-center">
            {/* Logo */}
            <img src={google} className="max-h-16" alt="" />
            {/* Name & Short Description */}
            {/* <div className="">
                            <div className="font-semibold text-base">Google Calendar</div>
                            <div className="max-w-xs text-center text-skin-muted">View your daily schedule, create events with access to create meet conference calls with your recipients.</div>
                        </div> */}
          </div>
        </div>

        {/* Office365 */}
        <div
          className="border rounded-lg  flex flex-col items-center w-full justify-center gap-4 text-center hover:bg-skin-light cursor-pointer p-4"
          onClick={() => onConnectCalendar("office365")}
        >
          <div className="flex flex-col items-center justify-center">
            {/* Logo */}
            <img src={office365} className="max-h-10" alt="" />
            {/* Name & Short Description */}
            {/* <div className="">
                            <div className="font-semibold text-base">Office 365</div>
                            <div className="max-w-xs text-center text-skin-muted">Authorize knack to easily manage your schedules and see upcoming events.</div>
                        </div> */}
          </div>
        </div>

        {/* Outlook */}
        <div
          className="border rounded-lg  flex flex-col items-center w-full justify-center gap-4 text-center hover:bg-skin-light cursor-pointer p-4"
          onClick={() => onConnectCalendar("live_connect")}
        >
          <div className="flex flex-col items-center justify-center">
            {/* Logo */}
            <img src={outlook} className="max-h-16" />
            {/* Name & Short Description */}
            {/* <div className="">
                            <div className="font-semibold text-base">Microsoft Outlook</div>
                            <div className="max-w-xs text-center text-skin-muted">Authorize knack to easily manage your schedules and see upcoming events.</div>
                        </div> */}
          </div>
        </div>

        {/* Icloud */}
        <div
          className="border rounded-lg  flex flex-col items-center w-full justify-center gap-4 text-center hover:bg-skin-light cursor-pointer p-4"
          onClick={() => onConnectCalendar("apple")}
        >
          <div className="flex flex-col items-center justify-center">
            {/* Logo */}
            <img src={icloud} className="max-h-16" />
            {/* Name & Short Description */}
            {/* <div className="">
                            <div className="font-semibold text-base">Apple Mail</div>
                            <div className="max-w-xs text-center text-skin-muted">Authorize knack to easily manage your schedules and see upcoming events.</div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
