import { Button, ButtonProps } from "knack-ui";
import { ComponentPropsWithRef } from "react";
import { MdModeEdit } from "react-icons/md";

type EditButtonProps = Omit<ButtonProps, "children"> & ComponentPropsWithRef<"button">;

const EditButton = ({ ...delegated }: EditButtonProps) => {
  return (
    <Button className="flex-shrink-0" variant="small" iconOnly kind="tertiary" {...delegated}>
      <MdModeEdit size={15} />
    </Button>
  );
};

export default EditButton;
