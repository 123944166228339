import { Button, Stack } from 'knack-ui'
import moment from 'moment';
import { canEnableNoShow, canReschedule } from '../../../lib/utils';
import { IProgram } from '../../Programs/ProgramTypes';
import { useUser } from '../../User/userQueries';
import { IUser } from '../../User/UserTypes';
import { TSessionWithProgram } from '../SessionsTypes';

interface IProps {
    setOpen: (value: boolean) => void,
    session: TSessionWithProgram,
    setConfirmMarkAsDone: (value: boolean) => void,
    setConfirmDelete: (value: boolean) => void,
    setConfirmCancel: (value: boolean) => void,
    setConfirmNoShow: (value: boolean) => void,
    setOpenReschedule: (value: boolean) => void,
}
export const SessionCardActions =
    ({
        setOpen,
        session,
        setConfirmMarkAsDone,
        setConfirmDelete,
        setConfirmCancel,
        setConfirmNoShow,
        setOpenReschedule
    }: IProps) => {
        const { data: user } = useUser();
        return (
            <>
                <Stack direction='column'>
                    {
                        session?.completion_status === "Pending" &&
                        <>
                            {(session?.program as IProgram).coach_type !== "knack_coaching" &&
                                moment(new Date()).isSameOrAfter(
                                    moment(new Date(session?.start_time as string))
                                ) &&
                                !session?.completion_status?.includes((user as IUser)?._id) && <Button
                                    kind='ghost'
                                    variant="small"
                                    shape="square"
                                    onClick={() => setConfirmMarkAsDone(true)}
                                    style={{ display: 'flex', justifyContent: 'left', borderBottom: '1px solid #eee' }}
                                >
                                    Mark as done
                                </Button>
                            }
                            {canReschedule(session?.start_time as Date) &&
                                <Button
                                    kind='ghost'
                                    variant="small"
                                    shape="square"
                                    onClick={() => setOpenReschedule(true)}
                                    style={{ display: 'flex', justifyContent: 'left', borderBottom: '1px solid #eee' }}
                                >
                                    Reschedule
                                </Button>
                            }
                            {moment(new Date()).isAfter(
                                moment(new Date(session?.start_time as string))
                            ) &&
                                canEnableNoShow(session?.program.coach_type, user?.account_type as IUser["account_type"]) &&
                                <Button
                                    kind='ghost'
                                    variant="small"
                                    shape="square"
                                    onClick={() => setConfirmNoShow(true)}
                                    style={{ display: 'flex', justifyContent: 'left', borderBottom: '1px solid #eee' }}
                                >
                                    No Show
                                </Button>}
                            <Button
                                variant="small"
                                kind='ghost'
                                shape="square"
                                onClick={() => setConfirmCancel(true)}
                                style={{ display: 'flex', justifyContent: 'left', borderBottom: '1px solid #eee' }}
                            >
                                Cancel Session
                            </Button>
                        </>
                    }
                    <Button
                        kind='ghost'
                        variant="small"
                        shape="square"
                        onClick={() => setConfirmDelete(true)}
                        style={{ display: 'flex', justifyContent: 'left', borderBottom: '1px solid #eee' }}
                    >
                        Delete Session
                    </Button>
                </Stack>
            </>
        )
    }
