import { Box } from "knack-ui";
import ContentLoader from "react-content-loader";

const MetricCardLoading = () => {
  return (
    <Box variant="outlined" className="overflow-hidden">
      <ContentLoader backgroundColor="#ececec" foregroundColor="#fafafa" viewBox="0 0 303 80" height={80} width="303">
        <rect x="16" y="16" rx="5" ry="5" width="49" height="49" />
        <rect x="97" y="22" rx="5" ry="5" width="150" height="14" />
        <rect x="97" y="48" rx="5" ry="5" width="125" height="14" />
      </ContentLoader>
    </Box>
  );
};

export default MetricCardLoading;
