import { Box, Stack, Typography, Button } from "knack-ui";
import { useHistory } from "react-router-dom";
import PageList from "../../../components/PageList/PageList";
import { PageTitleHeight, PageHeightWithoutHeader } from "../../../lib/constants";
import SurveyCard from "./SurveyCard";
import { useGetSurveys } from "../surveyQueries";
import { useDeleteSurvey, useEditSurvey } from "../surveyMutations";
import { Failure } from "../../../components/ToastNotification/Failure";
import { Success } from "../../../components/ToastNotification/Success";
import Grid from "../../../components/Layout/Grid";
import { useState } from "react";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const Surveys = () => {
  const history = useHistory();
  const { data: surveys } = useGetSurveys();
  const { mutateAsync: deleteSurvey } = useDeleteSurvey();

  const { mutateAsync: editSurvey } = useEditSurvey();

  const [stopRecurrentConfirmationOpen, setStopRecurrentConfirmationOpen] = useState(false);
  const [surveyId, setSurveyId] = useState<string | null>(null);

  const handleDeleteSurvey = async (surveyId: string) => {
    try {
      await deleteSurvey(surveyId);
      Success("Survey deleted successfully");
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  };
  const handleStopRecurrentMode = async (confirm: boolean) => {
    if (confirm) return setStopRecurrentConfirmationOpen(true);
    if (!surveyId) return;
    try {
      await editSurvey({ id: surveyId, recurring: { isActive: false, sendUntil: new Date() } });
      setStopRecurrentConfirmationOpen(false);
      Success("The survey is no longer recurrent");
    } catch (error) {
      Failure("Something went wrong, Please try againF ");
    }
  };

  return (
    <div className="h-full overflow-hidden border rounded-lg">
      <Box
        as={Stack}
        square
        variant="outlined"
        elevation={0}
        alignItems="center"
        style={{
          borderTop: 0,
          borderRight: 0,
          borderLeft: 0,
          height: PageTitleHeight
        }}
        justifyContent="space-between"
        className="px-6 py-4"
      >
        <Typography as="h5" variant="h6">
          Surveys
        </Typography>
        <Button onClick={() => history.push("/new-survey")}>Create new survey</Button>
      </Box>
      <div
        style={{
          height: PageHeightWithoutHeader,
          overflowY: "auto"
        }}
      >
        <PageList
          emptyStateProps={{
            title: "No surveys found",
            buttonCb: () => history.push("/new-survey"),
            buttonText: "Create your first survey",
            icon: "Surveys",
            iconSize: 60
          }}
          listComponent={(children) => (
            <Grid
              gridTemplateColumns="grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
              gap="gap-4"
              children={children}
            />
          )}
        >
          {surveys?.map((s) => (
            <SurveyCard
              key={s._id}
              survey={s}
              handleStopRecurrentMode={(id) => {
                setSurveyId(id);
                handleStopRecurrentMode(true);
              }}
              handleDeleteSurvey={handleDeleteSurvey}
            />
          ))}
        </PageList>
      </div>
      <ConfirmationDialog
        headerText="Stop Recurrent mode"
        footerComponent={() => (
          <Stack alignItems="center" justifyContent="flex-end">
            <Button kind="ghost" onClick={() => setStopRecurrentConfirmationOpen(false)}>
              Cancel
            </Button>
            <Button kind="warning" onClick={() => handleStopRecurrentMode(false)}>
              Stop recurrent mode
            </Button>
          </Stack>
        )}
        isOpen={stopRecurrentConfirmationOpen}
        onClose={() => setStopRecurrentConfirmationOpen(false)}
      >
        <Typography>Participants will stop recieving recurrent surveys</Typography>
      </ConfirmationDialog>
    </div>
  );
};

export default Surveys;
