import { Box, Typography } from "knack-ui";
import { useEffect, useState } from "react";
import { CronofyService } from "../../../services/Scheduling/CronofyService";
import { useRequestDelegatedAccess } from "../../Scheduler/schedulerMutation";
import { useUpdateProfile } from "../../User/userMutations";
import { useUser } from "../../User/userQueries";
import { useGetCalendarProfile, useGetSchedulerId } from "../calendarQueries";
import AvailabilityViewerWrapper from "./AvailabilityViewerWrapper";
import NoCalendarView from "./NoCalendarView";

const ExpertAvailability = () => {
  const { mutateAsync: requestDelegatedAccess } = useRequestDelegatedAccess();
  const { data: user, refetch } = useUser({
    suspense: false
  });
  const { data: scheduler_id } = useGetSchedulerId(user?._id as string);
  const { data: calendarProfile } = useGetCalendarProfile(scheduler_id, user?._id);

  const { mutateAsync: updateProfile } = useUpdateProfile();
  const companyColorAccent = `${window.getComputedStyle(document.documentElement).getPropertyValue("--color-accent")}`.trim();

  const [options, setOptions] = useState<{ [key: string]: any } | null>(null);

  useEffect(() => {
    // ambiguous delegation request
    if (user?.email && !calendarProfile) {
      requestDelegatedAccess({ emails: [user?.email] });
    }

    // Update profile with scheduler id calendar profile exist
    if (user?.email && calendarProfile && scheduler_id && !user?.profile?.scheduler_id) {
      updateProfile({ scheduler_id })
        .then((res) => {
          refetch();
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (user?.profile && user.profile.scheduler_id) {
      onRequestElementAccessToken();
    }
  }, [user, scheduler_id, calendarProfile]);

  function onRequestElementAccessToken() {
    CronofyService.requestElementAccessToken([user?.profile!.scheduler_id as string])
      .then((res: any) => {
        if (res && res.data.status) {
          // Sniff the browser's timezone
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          // prepare options
          const opts = {
            tzid: timezone,
            data_center: "de",
            element_token: res.data.data.element_token.token,
            target_id: "availability-viewer",
            availability_rule_id: "work_hours",
            // calendar_ids: [
            //     ls_profile.scheduler_id
            // ],
            config: {
              start_time: "08:00",
              end_time: "15:00",
              duration: 30
            },
            styles: {
              colors: {
                available: "#00a085",
                unavailable: "#9ca3af",
                primary: companyColorAccent
              },
              prefix: ""
            },
            translations: {
              en: {
                availability_rules: {
                  save_new_rules: "Update"
                }
              }
            }
          };
          setOptions(opts);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  if (!user?.profile || !calendarProfile) {
    return <NoCalendarView helperText="Connect your calendar to set up your availability" />;
  }
  return (
    <div className="border rounded-lg">
      <Box className="border-b" elevation={0} paddingPreset="card">
        <Typography variant="h6">Availability</Typography>
        <Typography style={{ marginTop: "8px" }}>
          Define the custom hours you want to offer your availability for coaching or mentoring to avoid getting booked at any time of the
          day.
        </Typography>
      </Box>
      <div className="p-6 overflow-x-hidden">{options && <AvailabilityViewerWrapper options={options} />}</div>
    </div>
  );
};

export default ExpertAvailability;
