import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../../components/nav/BackButton";
import { MentoringSessionTimeline } from "../../components/Timeline/MentoringSessionTimeline";
import Feedback360 from "../../pages/dashboard/userDashboard/userReports/feedback360/Feedback360";
import { KnackUserCardProfile } from "../../components/Profile/KnackUserCardProfile";
import { Assessment } from "../../components/Profile/ProfileTabPages/Assessment";
import { HRCoachServices } from "../../services/dashboard/hrCoachDashboard.services";
import mentoringService, {
  getSessionsByQuery
} from "../../services/mentoring/mentoring.service";
import { getProfile } from "../../services/user.service";
import hrDashboardServices from "../../services/dashboard/hrDashboard.services";

export const UserProfile = (props) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")).data
  );
  const history = useHistory();
  const [mentoringTopics, setMentoringTopics] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tab, setTab] = useState(1);
  const [sessions, setSessions] = useState([]);
  const [menteeTasks, setMenteeTasks] = useState([]);
  const { userId, programId, profileOnly, color } = props.match.params;
  const [program, setProgram] = useState();
  const [fetchedUser, setFetchedUser] = useState(null);

  useEffect(() => {
    if (!userId) {
      history.push("/");
      return;
    } else {
      setIsLoaded(true);
      getProfile(userId)
        .then((res) => {
          const { data } = res.data;
          setFetchedUser(data);
          onGetSessions(userId, data.profile_type);
          if (programId) {
            onGetProgram(data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
      if (!["Hr"].includes(user.account_type)) {
        getMenteeTasks(userId);
      }
    }
  }, [setFetchedUser, setSessions, setMenteeTasks]);

  function onGetProgram(user) {
    if (programId) {
      hrDashboardServices
        .getProgram(programId, "Mentoring")
        .then((res) => {
          if (res.data.status) {
            const { data } = res.data;
            const programSkills = data.skills;
            if (programSkills) {
              const _user_skills = user?.program_skills;
              const selectedSkills = _user_skills?.filter((s) =>
                programSkills?.includes(s)
              );
              setMentoringTopics(selectedSkills);
            }
            setProgram(data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      history.push("programs");
    }
  }

  const onGetSessions = (userId, userType) => {
    if (userId) {
      if (user.account_type !== "Hr") {
        getSessionsByQuery(`user=${userId}&program=${programId}`)
          .then((response) => {
            if (response.status) {
              let data = response.data.data;
              if (data && data.length > 0) {
                const sorted = data?.sort(
                  (a, b) =>
                    new Date(b.start_time).getTime() -
                    new Date(a.start_time).getTime()
                );
                setSessions(sorted);
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        if (userType) {
          HRCoachServices.getSessions(
            `mentoring/recent?type=${
              userType === "MentorProfile" ? "mentor" : "mentee"
            }&no_paginate=true&completion_status=Completed&user=${userId}&program=${programId}`
          )
            .then((res) => {
              if (res.data.status) {
                const { data } = res.data;
                if (data) {
                  const sorted = data?.allSessions.sort(
                    (a, b) => new Date(b.start_time) - new Date(a.start_time)
                  );
                  setSessions(sorted);
                }
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }
  };

  function getMenteeTasks(userId) {
    mentoringService
      .getMenteeTasks(userId)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          // const filtered = [...data].filter(s => s.session._id === session._id && s.type !== "rating");
          setMenteeTasks(data);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <div>
      <BackButton />
      <div className="mt-4 max-w-screen-lg mx-auto bg-white">
        {fetchedUser && (
          <KnackUserCardProfile
            menteeId={fetchedUser.user._id}
            canHover={false}
            profile_image={fetchedUser?.user?.profile_image}
            color={color || "#264653"}
            first_name={fetchedUser?.first_name}
            last_name={fetchedUser.last_name}
            position={fetchedUser?.position}
            job_function={fetchedUser?.job_function}
            email={fetchedUser?.user?.email}
            component={
              <div className="mt-4 grid gap-4">
                {/* Program */}
                {program && (
                  <div className="border-b pb-4 grid gap-4">
                    <div>
                      <div className="font-medium text-sm text-skin-muted">
                        Program
                      </div>
                      <div className="font-bold text-base">{program?.name}</div>
                    </div>

                    <div>
                      <div className="font-medium text-sm text-skin-muted">
                        Mentoring Topics
                      </div>
                      <div className="text-sm tracking-wider gap-2 flex flex-row flex-wrap mt-2">
                        {mentoringTopics?.map((s, i) => (
                          <div
                            key={i}
                            className="bg-skin-muted rounded-full px-2 py-1 text-white"
                            style={{ maxWidth: "fit-content" }}
                          >
                            {" "}
                            {s}{" "}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <div className="grid sm:grid-cols-2 gap-4">
                  {fetchedUser && fetchedUser?.job_function && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Department
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.job_function}
                      </div>
                    </div>
                  )}
                  {fetchedUser && fetchedUser?.position && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Position
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.position}
                      </div>
                    </div>
                  )}
                  {fetchedUser && fetchedUser?.management_level && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Management Level
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.management_level}
                      </div>
                    </div>
                  )}

                  {fetchedUser && fetchedUser?.entity && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Entity
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.entity}
                      </div>
                    </div>
                  )}

                  {fetchedUser && fetchedUser?.whatsapp_number && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Phone No.
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.whatsapp_number}
                      </div>
                    </div>
                  )}

                  {fetchedUser && fetchedUser.user && fetchedUser?.user?.email && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Email
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.user?.email}
                      </div>
                    </div>
                  )}
                </div>

                {/* {
                                    mentee?.enrolledPrograms &&
                                    <CollapsibleWrapperWithLineBar
                                        ItemsCounts={fetchedUser?.enrolledPrograms?.length}
                                        isOpen={false}
                                        SectionName="Programs"
                                        Icon={
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                            </svg>
                                        } >
                                        <div>
                                            {
                                                mentee?.enrolledPrograms && mentee?.enrolledPrograms?.length > 0 &&
                                                <ListOfPrograms programs={mentee?.enrolledPrograms} isLoaded={isLoaded} route="view-program" />
                                            }
                                        </div>
                                    </CollapsibleWrapperWithLineBar>
                                } */}

                {!profileOnly && (
                  <>
                    {/* Tabs */}
                    <div className="flex flex-row w-full text-sm justify-between sm:justify-start border-b border-gray-200 overflow-x-auto">
                      <div
                        onClick={() => setTab(1)}
                        className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                          tab == 1 &&
                          "border-b-2 border-skin-base text-skin-accent"
                        } `}
                      >
                        Sessions
                      </div>
                      {fetchedUser &&
                        fetchedUser?.assessment_report_visible_to?.includes(
                          user._id
                        ) && (
                          <div
                            onClick={() => setTab(2)}
                            className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                              tab == 2 &&
                              "border-b-2 border-skin-base text-skin-accent"
                            } `}
                          >
                            Assessment
                          </div>
                        )}
                      {fetchedUser &&
                        fetchedUser?.assessment_report_visible_to?.includes(
                          user._id
                        ) && (
                          <div
                            onClick={() => setTab(3)}
                            className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                              tab == 3 &&
                              "border-b-2 border-skin-base text-skin-accent"
                            } `}
                          >
                            360 Feedback
                          </div>
                        )}
                      {fetchedUser && user?.account_type == "Mentor" && (
                        <div
                          onClick={() => setTab(4)}
                          className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                            tab == 4 &&
                            "border-b-2 border-skin-base text-skin-accent"
                          } `}
                        >
                          Tasks
                        </div>
                      )}
                    </div>

                    {/* Tab Content */}
                    {/* Completed Sessions */}
                    {tab === 1 && (
                      <div
                        className="mt-4 grid gap-4 overflow-y-auto scrollhost2"
                        style={{ maxHeight: 700 }}
                      >
                        <div className="">
                          <MentoringSessionTimeline sessionList={sessions} />
                        </div>
                      </div>
                    )}

                    {tab === 2 && (
                      <Assessment userId={userId} user={fetchedUser} />
                    )}
                    {tab === 3 && (
                      <Feedback360
                        userId={userId}
                        user={fetchedUser}
                        canRequest={false}
                      />
                    )}
                  </>
                )}

                {/* Assessment Result */}
                {/* {
                                    mentee && mentee?.assessment_report_visible_to?.includes(user._id) &&
                                    <div className="grid gap-4">
                                        <div className="bg-gray-100 w-full rounded-lg p-4 font-bold flex flex-row items-center justify-between cursor-pointer" onClick={() => setAssessmentOpen(!assessmentOpen)}>
                                            <div>{assessmentOpen ? 'Assessment Report' : 'View Asssessment Report'}</div>
                                            <div className={`translate transform delay-300 duration-300 ${assessmentOpen ? '' : '-rotate-90'}`}>
                                                <svg className="w-4 h-4" viewBox="0 0 18 9" fill="#ddd" >
                                                    <path id="Icon_ionic-md-arrow-dropdown" data-name="Icon ionic-md-arrow-dropdown" d="M9,13.5l9,9,9-9Z" transform="translate(-9 -13.5)" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className={`${assessmentOpen ? 'block' : 'hidden'}`}>
                                            <Assessment userId={mentee.user} user={mentee} />
                                        </div>
                                    </div>
                                } */}
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};
