import { QueryFunctionContext, useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useLocation } from "react-router-dom";
import { LANGUAGES } from "../../App.constant";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { capitalizeFirstLetter, getUserRoles } from "../../lib/utils";
import { UserStore } from "../../RoleManagement/Discriminator";
import { resetLocalStorage } from "../../services/auth.service";
import { InitCompanySkin } from "../../StateManagement/ThemeConfigurations";
import { IUtilization } from "../People/PeopleTypes";
import { IOrganization, IUser, UserRoleState } from "./UserTypes";

export const userQueryKeys = {
  all: [{ scope: "user" }] as const,
  supportedLanguages: [{ scope: "supportedLanguages" }] as const,
  positions: [{ scope: "supportedLanguages" }] as const,
  user: () => [{ ...userQueryKeys.all[0], entity: "user" }] as const,
  role: (user: IUser | undefined) => [{ ...userQueryKeys.all[0], entity: "role", user }] as const,
  company: (id?: string) => [{ ...userQueryKeys.all[0], entity: "company", id }] as const,
  utilization: (userId?: string) => [{ ...userQueryKeys.all[0], entity: "utilization", userId }] as const
};

const fetchUtilization = async ({ queryKey: [{ userId }] }: QueryFunctionContext<ReturnType<typeof userQueryKeys["utilization"]>>) => {
  if (typeof userId === undefined) {
    throw new Error("User id is required");
  }
  const res = await apiHttp.get<ServerResponse<IUtilization>>("v1/auth/utilization/" + userId);

  return res.data.data;
};

async function getPlatformSupportedLanguages() {
  const res = await apiHttp.get<ServerResponse<string[]>>("v1/profile/spoken-languages");
  const langs = res.data.data;
  if (langs.length > 0) {
    return langs;
  }

  return LANGUAGES;
}

async function getHMAC(user: IUser) {
  const res = await apiHttp.get<ServerResponse<unknown>>("v1/profile/generate-hmac");
  const HMAC = res.data.data;
  //@ts-ignore
  window.Intercom("shutdown");
  const name = user?.profile ? `${user.profile.first_name} ${user.profile.last_name}` : user?.email || "";

  //@ts-ignore
  window.Intercom("boot", {
    app_id: "re0bm69r",
    created_at: Date.now(),
    email: user.email,
    name,
    user_hash: HMAC,
    alignment: "right",
    domain_url: window.location.host
  });

  return true;
}

const getUser = async () => {
  const user = JSON.parse(localStorage.getItem("user") as string);
  if (!user) {
    return Promise.reject("Not Authenticated");
  }
  const res = await apiHttp.get<ServerResponse<IUser>>("v1/auth/users/me");
  if (res.data.status === true) {
    const userData = res.data.data;
    // Boot intercom with user information
    getHMAC(userData);
    return res.data.data;
  } else {
    return Promise.reject("Not Authenticated");
  }
};

const getUserCompany = async ({ queryKey: [{ id }] }: QueryFunctionContext<ReturnType<typeof userQueryKeys["company"]>>) => {
  if (typeof id === "undefined") {
    return Promise.reject("Invalid company id");
  }
  const res = await apiHttp.get<ServerResponse<IOrganization>>("v1/companies/" + id);
  return res.data.data;
};

const getCompanyPositions = async () => {
  const res = await apiHttp.get<ServerResponse<string[]>>("v1/profile/positions");
  return res.data.data;
};

const logoutUser = async () => {
  const res = await apiHttp.get<ServerResponse<unknown>>("v1/auth/logout");
  return res.data.data;
};

export const useFactoryReset = () => {
  const queryClient = useQueryClient();
  return useMutation(logoutUser, {
    onSuccess: () => {
      resetLocalStorage();
      queryClient.resetQueries(userQueryKeys.all);
      window.location.reload();
    }
  });
};

export const useLogout = () => {
  const { mutate: reset } = useFactoryReset();
  return useMutation(logoutUser, {
    onSuccess: () => {
      reset();
    }
  });
};

export const useGetSupportedLanguages = <TSelectData = string[], Error = unknown>(
  options?: UseQueryOptions<string[], Error, TSelectData>
) => {
  return useQuery<string[], Error, TSelectData>(userQueryKeys.supportedLanguages, getPlatformSupportedLanguages, {
    suspense: true,
    staleTime: DURATIONS.fifteenMins,
    ...options
  });
};

// 🙋‍♀️ Get user profile information based on his auth token

export const useUser = <TSelectData = IUser, Error = unknown>(options?: UseQueryOptions<IUser, Error, TSelectData>) => {
  return useQuery<IUser, Error, TSelectData>(userQueryKeys.user(), getUser, {
    suspense: true,
    staleTime: DURATIONS.fifteenMins,
    ...options,
    onSuccess: () => {
      InitCompanySkin();
    }
  });
};

export const useGetUserUtilization = <SelectReturnType = IUtilization, ErrorType = unknown>(
  { userId }: { userId: string },
  options?: UseQueryOptions<IUtilization, ErrorType, SelectReturnType, ReturnType<typeof userQueryKeys["utilization"]>>
) => {
  return useQuery<IUtilization, ErrorType, SelectReturnType, ReturnType<typeof userQueryKeys["utilization"]>>(
    userQueryKeys.utilization(userId),
    fetchUtilization,
    {
      ...options,
      suspense: true,

      staleTime: DURATIONS.fifteenMins
    }
  );
};

// 🏭 Get user company information
export const useUserCompany = <TSelectData = IOrganization, Error = unknown>(
  options?: UseQueryOptions<IOrganization, Error, TSelectData, ReturnType<typeof userQueryKeys["company"]>>
) => {
  const { data: user } = useUser();
  return useQuery<IOrganization, Error, TSelectData, ReturnType<typeof userQueryKeys["company"]>>(
    userQueryKeys.company(user?.organization?._id),
    getUserCompany,
    {
      ...options,
      suspense: true,
      enabled: Boolean(user) && Boolean(user?.organization) && !["Admin", "Subadmin", "Coach"].includes(user?.account_type as string),
      staleTime: DURATIONS.fifteenMins
    }
  );
};

export const useGetCompanyPositions = <TSelectData = string[], Error = unknown>(
  options?: UseQueryOptions<string[], Error, TSelectData>
) => {
  return useQuery<string[], Error, TSelectData>(userQueryKeys.positions, getCompanyPositions, {
    suspense: true,
    staleTime: DURATIONS.fifteenMins,
    ...options
  });
};

// 🏭 saves current role
export const useUserRoles = () => {
  const queryClient = useQueryClient();
  const { setCurrentRole: setUserStoreRole } = UserStore();
  const { data: user } = useUser();
  const { getValue, setValue } = useLocalStorage();
  const { search } = useLocation();
  // 💡 Getting the preferred initial role from URL search params
  const queryParam = new URLSearchParams(search).get("profile_type");
  const { data: roleState } = useQuery<UserRoleState | undefined>(
    userQueryKeys.role(user),
    async () => {
      if (!user) return undefined;
      const roles = getUserRoles(user);

      // 💡 Getting the preffered initial role from localStorage
      let initialProfile = getValue("prefferedProfile", roles[0]);
      if (queryParam && queryParam !== "") {
        initialProfile = capitalizeFirstLetter(queryParam.toLowerCase()) as UserRoleState["activeRole"];
      }
      // ⚡ checking if the saved role is actually valid
      if (initialProfile && !roles.includes(initialProfile)) {
        initialProfile = roles[0];
        setValue("prefferedProfile", roles[0]);
      }

      return {
        roles,
        activeRole: initialProfile as UserRoleState["activeRole"]
      };
    },
    {
      suspense: true,
      staleTime: DURATIONS.oneHour,
      enabled: Boolean(user) && !["Admin", "Subadmin"].includes(user?.account_type as string)
    }
  );

  const setCurrentRole = (role: UserRoleState["activeRole"]) => {
    setUserStoreRole(role);
    queryClient.setQueryData<UserRoleState | undefined>(userQueryKeys.role(user), (prev) => {
      if (prev) {
        return { ...prev, activeRole: role };
      }
    });
  };

  return { roleState, setCurrentRole };
};
