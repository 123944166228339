import ContentLoader from "react-content-loader";

const LazySurveyInvitationsSkeleton = () => {
  return (
    <ContentLoader
      backgroundColor="#ececec"
      foregroundColor="#fafafa"
      viewBox="0 0 900 50"
      height={50}
      width="100%"
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="50" />
    </ContentLoader>
  );
};

export default LazySurveyInvitationsSkeleton;
