import { Button, Stack, Typography } from "knack-ui";
import ConfirmationDialog from "../../../../../../components/ConfirmationDialog";
import { useArchiveGlobalProgram } from "../../../../../../features/Programs/programMutations";
import { programsQueryKeys } from "../../../../../../features/Programs/programQueries";
import { IProgram } from "../../../../../../features/Programs/ProgramTypes";
import { useLoadingSpinner } from "../../../../../../hooks/useLoadingSpinner";
import { queryClient } from "../../../../../../lib/appConfig";

interface IProps {
  setOpen: (value: boolean) => void;
  open: boolean;
  program: IProgram;
  onSuccess: (value: boolean) => void;
}

export const ConfirmGlobalProgramArchive = ({ setOpen, open, program, onSuccess }: IProps) => {
  const { mutateAsync: archiveGlobalProgram } = useArchiveGlobalProgram();
  const { setLoading } = useLoadingSpinner();

  const onArchiveProgram = async () => {
    setLoading(true);
    try {
      await archiveGlobalProgram(program._id);
      setLoading(false);
      setOpen(false);
      onSuccess(true);
      queryClient.invalidateQueries(programsQueryKeys.all);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ConfirmationDialog
      isOpen={open}
      onClose={() => setOpen(false)}
      headerText={`Archive ${program?.name}`}
      footerComponent={() => {
        return (
          <Stack justifyContent="flex-end" gap={2}>
            <Button kind="ghost" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button kind="danger" onClick={() => onArchiveProgram()}>
              Archive program
            </Button>
          </Stack>
        );
      }}
    >
      <Typography className="mb-4" fontWeight="semibold">
        Archiving this program will terminate all engagements in this program. Are you sure you want to archive this program?
      </Typography>
      <Typography variant="body1" fontWeight="medium">
        Once you archive this program:
      </Typography>
      <ul className="grid grid-cols-1 gap-1 my-4 list-disc ms-4">
        <Typography as="li" variant="subtitle2">
          Program will be removed from active program list for all users.
        </Typography>
        <Typography as="li" variant="subtitle2">
          {program?.program_type} engagements, session logging and session scheduling will be terminated for all users.
        </Typography>
      </ul>
    </ConfirmationDialog>
  );
};
