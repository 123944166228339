import { Button, Popover } from "knack-ui";
import { useMemo, useRef, useState } from "react";
import ListFilterOptions from "../../features/AnalyticsReports/components/ListFilterOptions";
import { ReactDateRange } from "../DateRange/ReactDateRange";
import { DateRangeFilterOptions, Filter, FilterBarProps, FilterOptions } from "./FiltersBar.types";

function FilterBar<TData>({ filters, query }: FilterBarProps<TData>) {
  const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);
  const refEl = useRef<HTMLButtonElement | null>(null);

  const onMenuOpen = ({ target, filter }: { target: HTMLButtonElement; filter: Filter }) => {
    setSelectedFilter(filter);
    refEl.current = target;
  };

  const options = useMemo(() => {
    if (!selectedFilter) return null;
    return filters.find((f) => f.name === selectedFilter.name)?.options;
  }, [filters, selectedFilter]);
  return (
    <>
      <div className="filters-bar__container">
        {filters.map((filter) => (
          <Button
            disabled={filter.isDisabled}
            key={filter.name}
            onClick={(e) => onMenuOpen({ target: e.currentTarget, filter: filter as Filter })}
            variant="small"
            endIcon={
              Array.isArray(query?.[filter.name]) ? (
                query?.[filter.name].length > 0 ? (
                  <div className="flex flex-col justify-center w-3 text-xs rounded-full text-primary">{query?.[filter.name].length}</div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )
            }
          >
            {filter.label}
          </Button>
        ))}
      </div>
      <Popover
        animationType="fade"
        parentRef={refEl}
        offset={{ bottom: 10, left: 0 }}
        popoverProps={{
          style: { maxWidth: 500 },
          variant: "elevated",
          elevation: 6
        }}
        isOpen={Boolean(selectedFilter)}
        onClose={() => setSelectedFilter(null)}
      >
        {options && selectedFilter?.type === "default" && <ListFilterOptions {...(options as FilterOptions<TData>)} />}
        {options && selectedFilter?.type === "dateRange" && (
          <>
            <ReactDateRange {...(options as DateRangeFilterOptions)} />
            <div className="p-4">
              <Button onClick={options.onOptionsClear} fullWidth variant="small">
                Clear
              </Button>
            </div>
          </>
        )}
      </Popover>
    </>
  );
}

export default FilterBar;
