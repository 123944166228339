import moment from "moment";
import { USER_ROLES, USER_TYPES } from "../../../App.constant";
import { BackButton } from "../../../components/nav/BackButton";
import { AvatarPlaceholderV2 } from "../../../components/SVG/AvatarPlaceholderV2";
import { UserStore } from "../../../RoleManagement/Discriminator";

export const MeetingScheduleSuccessInformation = ({ event, expert, program, scheduledEventInformation, selectedMatch, sessionType }) => {
  const { currentRole } = UserStore();
  return (
    <div className="max-w-screen-xl w-full grid gap-4 w-full mx-auto">
      <BackButton />
      <div className="mx-auto flex flex-col justify-center items-center border rounded-lg bg-white">
        <div className="p-6 ">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_f10vwzy8.json"
            background="transparent"
            speed="1"
            style={{ width: 100, height: 100 }}
            autoplay
          ></lottie-player>
        </div>
        <div className="text-center">
          <div className="text-2xl font-bold">Meeting Information</div>
          <div className="text-lg font-medium max-w-sm mx-auto">{event?.summary}</div>
          <div className="text-base text-skin-green font-medium max-w-sm mx-auto">{scheduledEventInformation}</div>
          <div className="grid gap-4 grid-cols-2 border-t mt-6">
            <div className="text-left grid gap-4 p-6 border-r">
              {currentRole === USER_ROLES.USER && (
                <div className="text-skin-muted font-medium">
                  {program?.program_type === "Mentoring" ? USER_TYPES.MENTOR : USER_TYPES.COACH}
                </div>
              )}
              <div className="flex flex-row gap-4 items-center">
                <AvatarPlaceholderV2
                  imageUrl={expert?.user?.profile_image || selectedMatch?.expert?.user?.profile_image}
                  height="h-12"
                  width="w-12"
                  nameFallback={`${expert?.first_name || selectedMatch?.expert?.first_name} ${
                    expert?.last_name || selectedMatch?.expert?.last_name
                  }`?.trim()}
                  classes="border-2 rounded-full overflow-hidden"
                />
                <div className="text-lg capitalize font-medium">
                  {expert?.first_name || selectedMatch?.expert?.first_name} {expert?.last_name || selectedMatch?.expert?.last_name}{" "}
                </div>
              </div>
            </div>
            <div className="text-left grid gap-4 p-6">
              <div className="text-skin-muted font-medium">DATE & TIME</div>
              <div className="text-lg capitalize font-medium">{moment(new Date(event?.start)).format("dddd, MMM DD, h:mm A")}</div>
            </div>
          </div>

          {event?.location?.description && (
            <div className='border-t'>
              <div className="text-left grid gap-4 p-6">
                <div className="text-skin-muted font-medium">Location</div>
                <div className="text-lg capitalize font-medium">{event?.location?.description}</div>
              </div>
            </div>
          )}

          <footer className="grid grid-cols-2 border-t disabled hidden">
            <button className="font-bold text-red-600 text-lg border-r p-4">CANCEL MEETING</button>
            <button className="font-bold text-skin-green text-lg p-4">RE-SCHEDULE</button>
          </footer>
        </div>
      </div>
    </div>
  );
};
