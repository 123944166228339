import { Button, Typography } from "knack-ui";
import { ITablePaginationProps } from "./Table.types";

const TablePagination = ({
  currentPage,
  onNextPage,
  onPreviousPage,

  canGetNextPage,
  totalPages
}: ITablePaginationProps) => {
  return (
    <div className="flex items-center mt-6 ">
      <Typography variant="subtitle2" className="flex-1">
        Total Pages: {totalPages}
      </Typography>
      <div className="flex items-center flex-1 overflow-hidden rounded-lg">
        <Button
          disabled={currentPage === 1}
          onClick={() => onPreviousPage(currentPage - 1)}
          kind="ghost"
          shape="square"
          variant="small"
        >
          Prev
        </Button>
        <Button shape="square" variant="small">
          {currentPage}
        </Button>
        {canGetNextPage && (
          <Button
            kind="ghost"
            onClick={() => onNextPage(currentPage + 1)}
            shape="square"
            variant="small"
          >
            {currentPage + 1}
          </Button>
        )}

        <Button
          disabled={!canGetNextPage}
          // disabled={!hasNextPage && currentPage === totalPages}
          onClick={() => onNextPage(currentPage + 1)}
          kind="ghost"
          shape="square"
          variant="small"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default TablePagination;
