import { Stack, Typography } from 'knack-ui'
import MetricCard from '../../../components/Metrics/MetricCard'
import { PaymentMetrics as TPaymentMetrics } from '../paymentTypes'

export const PaymentMetrics = (metrics: TPaymentMetrics) => {
    return (
        <Stack direction='column' gap={4}>
            <Typography fontWeight='semibold'>Summary</Typography>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
                <MetricCard
                    icon="Sessions"
                    title="Total Sessions"
                    value={metrics.total}
                />
                <MetricCard
                    icon="Sessions"
                    title="Completed Sessions"
                    value={metrics.completed}
                />
                <MetricCard
                    icon="Sessions"
                    title="Paid Earnings"
                    value={metrics.paid}
                />
                <MetricCard
                    icon="Sessions"
                    title="Unpaid Earnings"
                    value={metrics.unpaid}
                />
            </div>
        </Stack>
    )
}
