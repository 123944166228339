import React from "react";

const SessionEmptyStateIcon = ({ size }: { size?: number }) => {
  return (
    <svg
      width={size || "81"}
      height={size || "81"}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.5 13.5H13.5C9.7875 13.5 6.75 16.5375 6.75 20.25V60.75C6.75 64.4625 9.7875 67.5 13.5 67.5H67.5C71.2125 67.5 74.25 64.4625 74.25 60.75V20.25C74.25 16.5375 71.2125 13.5 67.5 13.5ZM67.5 60.75H13.5V20.25H67.5V60.75ZM13.5 0H67.5V6.75H13.5V0ZM13.5 74.25H67.5V81H13.5V74.25ZM40.5 40.5C45.1575 40.5 48.9375 36.72 48.9375 32.0625C48.9375 27.405 45.1575 23.625 40.5 23.625C35.8425 23.625 32.0625 27.405 32.0625 32.0625C32.0625 36.72 35.8425 40.5 40.5 40.5ZM40.5 28.6875C42.3563 28.6875 43.875 30.2062 43.875 32.0625C43.875 33.9188 42.3563 35.4375 40.5 35.4375C38.6437 35.4375 37.125 33.9188 37.125 32.0625C37.125 30.2062 38.6437 28.6875 40.5 28.6875ZM57.375 53.9662C57.375 46.9125 46.2038 43.875 40.5 43.875C34.7962 43.875 23.625 46.9125 23.625 53.9662V57.375H57.375V53.9662ZM29.7338 52.3125C31.7925 50.5575 36.585 48.9375 40.5 48.9375C44.4487 48.9375 49.2412 50.5575 51.3 52.3125H29.7338Z"
        fill="#EAEBEE"
      />
    </svg>
  );
};

export default SessionEmptyStateIcon;
