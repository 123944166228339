import { useMutation, useQueryClient } from "react-query";
import { apiHttp } from "../../lib/appConfig";
import { assessmentQueryKeys } from "./assessmentQueries";
import { ReviewerTypes } from "./AssessmentTypes";

const send360Invite = async (
  invites: { email: string; type: ReviewerTypes }[]
) => {
  const res = await apiHttp.post("assessments/send-requests", invites);
  return res.data;
};

export const useSend360Invite = () => {
  const queryClient = useQueryClient();
  return useMutation(send360Invite, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        assessmentQueryKeys.feedbackInvites()
      );
    }
  });
};
