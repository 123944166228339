import { Button } from "knack-ui";
import { MEETING_DURATIONS } from "../../../../../App.constant";

interface Props {
  duration: number;
  onSelect: (value: number) => void;
  defaultText?: "Select Meeting Duration";
}
export const SelectMeetingDuration = ({
  defaultText,
  duration,
  onSelect
}: Props) => {
  return (
    <div className="grid gap-4">
      {defaultText && <div className="">{defaultText}</div>}
      <div className="flex flex-wrap gap-2">
        {MEETING_DURATIONS?.map((s, i) => (
          <Button
            shape="rounded"
            key={i}
            kind={duration === s.value ? "primary" : "defaultOutline"}
            onClick={() => {
              onSelect(s.value);
            }}
          >
            {s.title}
          </Button>
        ))}
      </div>
    </div>
  );
};
