import { Box, Typography } from "knack-ui";
import { Control, Controller } from "react-hook-form";
import { SectionQuestion, SurveyResponseSection } from "../../surveyTypes";
import SurveyCheckboxAnswer from "./SurveyCheckboxAnswer";
import SurveyLinearScaleAnswer from "./SurveyLinearScaleAnswer";
import SurveyMultipleChoiceAnswer from "./SurveyMultipleChoiceAnswer";
import SurveyTextAnswer from "./SurveyTextAnswer";

interface IProps {
  question: SectionQuestion;
  sectionIndex: number;
  index: number;
  control: Control<
    {
      answers: SurveyResponseSection;
    },
    object
  >;
}

const SurveyQuestion = ({ question, sectionIndex, index, control }: IProps) => {
  return (
    <Controller
      rules={{
        validate: (value) => {
          if (question.required) {
            if (question.question_type === "checkbox") {
              return value.length > 0;
            } else {
              return value !== "";
            }
          }
          return true;
        }
      }}
      control={control}
      name={`answers.${index}.question_answer`}
      render={({
        field: { value, onChange, ref },
        fieldState: { error },
        formState: { errors }
      }) => {
        return (
          <Box
            variant="outlined"
            paddingPreset="card"
            className="relative mb-4"
          >
            <Typography variant="h6" className="mb-6" fontWeight="normal">
              {question.title}
            </Typography>
            {question.question_type === "text" && (
              <SurveyTextAnswer
                question={question}
                sectionIndex={sectionIndex}
                questionIndex={index}
                index={0}
                onChange={onChange}
                value={value}
              />
            )}
            {question.question_type === "checkbox" && (
              <div className="grid gap-4">
                {question.options.map((_, i) => (
                  <SurveyCheckboxAnswer
                    question={question}
                    sectionIndex={sectionIndex}
                    questionIndex={index}
                    index={i}
                    onChange={onChange}
                    value={value}
                  />
                ))}
              </div>
            )}
            {question.question_type === "multiple_choice" && (
              <div className="grid gap-4">
                {question.options.map((_, i) => (
                  <SurveyMultipleChoiceAnswer
                    question={question}
                    sectionIndex={sectionIndex}
                    questionIndex={index}
                    index={i}
                    onChange={onChange}
                    value={value}
                  />
                ))}
              </div>
            )}
            {question.question_type === "linear" && (
              <SurveyLinearScaleAnswer
                question={question}
                sectionIndex={sectionIndex}
                questionIndex={index}
                index={0}
                onChange={onChange}
                value={value}
              />
            )}
            {error && (
              <Typography className="mt-4" variant="caption" color="warning">
                Required question
              </Typography>
            )}
          </Box>
        );
      }}
    />
  );
};

export default SurveyQuestion;
