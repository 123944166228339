import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";


// GET Get Coach count Copy
const getCompletedAssessmentCount = () => {
  return axios.get(API_URL + "v1/auth/assessment-completed", {
    headers: authHeader()
  });
};

// GET Get Total Completed sessions
const getCompletedSessionsThisMonth = () => {
  return axios.get(API_URL + "sessions/count/?type=completed", {
    headers: authHeader()
  });
};

// GET Get Total Completed sessions
const getTotalCompletedSessions = (query = "") => {
  return axios.get(API_URL + "sessions/count?type=total" + query, {
    headers: authHeader()
  });
};

// GET Get Total time spent Learning
const getTotalTimeSpentLearning = () => {
  return axios.get(API_URL + "learnings/time-spent", {
    headers: authHeader()
  });
};

// GET Microlearning Minutes Breakdown by Month

const getMicroLearningMinutesBreakdownByMonth = (query = "") => {
  return axios.get(API_URL + "learnings/month-breakdown" + query, {
    headers: authHeader()
  });
};

// GET Get user profile by ID
export const getUserById = (userId) => {
  return axios.get(API_URL + "v1/profile/" + userId, {
    headers: authHeader()
  });
};

const getUserRatingById = (userId) => {
  return axios.get(API_URL + "sessions/rating-agg/" + userId, {
    headers: authHeader()
  });
};

// GET Get Sessions
export const getSessions = (query = "") => {
  console.log(query);
  return axios.get(API_URL + "sessions/" + query, {
    headers: authHeader()
  });
};

// GET Get Sessions
const getMentoringSessions = (query = "") => {
  return axios.get(API_URL + "sessions/mentoring/" + query, {
    headers: authHeader()
  });
};

const getSessionsForUser = (query) => {
  return axios.get(API_URL + "sessions/" + query, {
    headers: authHeader()
  });
};

// GET Get Enrolled Employees list
const getEnrolledTeam = () => {
  return axios.get(API_URL + "v1/teams/report/enrolled-teams", {
    headers: authHeader()
  });
};

export const getEnrolledTeamForUser = (query) => {
  return axios.get(API_URL + "v1/teams/report/enrolled-teams" + query, {
    headers: authHeader()
  });
};

export const getEnrolledTeamByQuery = (query) => {
  return axios.get(API_URL + "v1/teams/report/enrolled-teams" + query, {
    headers: authHeader()
  });
};

export const getTeamSessions = (id) => {
  return axios.get(API_URL + "sessions/team/" + id, {
    headers: authHeader()
  });
};

// GET Get Confirmed Coaches count
const getConfirmedCoachCount = () => {
  return axios.get(API_URL + "v1/auth/count?type=confirmed_coaches", {
    headers: authHeader()
  });
};

// GET Get Team Count
const getTeamCount = () => {
  return axios.get(API_URL + "v1/teams/report/count", {
    headers: authHeader()
  });
};

// GET Get Completed Assessment count
const getAssessmentCount = () => {
  return axios.get(API_URL + "v1/auth/count/?type=completed_assessment", {
    headers: authHeader()
  });
};

// GET Employee Coaching Progress
const getEmployeeCoachingProgress = (query = "") => {
  return axios.get(API_URL + "v1/auth/coaching-progress" + query, {
    headers: authHeader()
  });
};

// GET Employee Coaching Progress
const getMentoringProgress = (query = "") => {
  return axios.get(API_URL + "v1/auth/mentoring-progress" + query, {
    headers: authHeader()
  });
};

// GET Get Sessions Timeline monthly / week
export const getSessionTimeline = (query) => {
  return axios.get(API_URL + "sessions/timeline?time=" + query, {
    headers: authHeader()
  });
};

// GET Coaching Satisfaction score
const getCoachingSatisfactionScore = (query) => {
  return axios.get(API_URL + "sessions/satisfaction-score?type=" + query, {
    headers: authHeader()
  });
};

// GET Mentoring Satisfaction score
const getMentoringSatisfactionScore = (query) => {
  return axios.get(API_URL + "sessions/satisfaction-score?" + query, {
    headers: authHeader()
  });
};

// GET Get Sessions Top Topics
const getTopCoachingTopics = () => {
  return axios.get(API_URL + "sessions/top-topics", {
    headers: authHeader()
  });
};

// GET Get Mentoring Top Topics
const getTopMentoringTopics = (platform) => {
  if (platform) {
    return axios.get(API_URL + `sessions/top-topics?platform=${platform}`, {
      headers: authHeader()
    });
  }
  return new Promise((resolve, reject) => reject("platform is required"));
};

// GET Skills Breakdown report per Team
const getAssessmentResultPerTeam = (teamId = "") => {
  return axios.get(
    API_URL + "assessments/report/skills_breakdown?team_id=" + teamId,
    {
      headers: authHeader()
    }
  );
};

// GET Skills Breakdown report per Team
export const getAssessmentResultPerTeamV1 = (organization, team) => {
  return axios.get(
    API_URL +
      `assessments/report/team_skills_breakdown/${organization}/?team_id=` +
      team,
    {
      headers: authHeader()
    }
  );
};

// GET Get all 360 feedback requests per user
const get360FeedbackRequests = () => {
  return axios.get(API_URL + "assessments/360-requests", {
    headers: authHeader()
  });
};
// GET Get all external-coaches
const getExternalCoaches = () => {
  return axios.get(API_URL + "v1/auth/users/types/external-coachees", {
    headers: authHeader()
  });
};
// GET Get Program subscription metrics
const getSubMetrics = (userId) => {
  return axios.get(API_URL + "v1/programs/subscription/fetch?user=" + userId, {
    headers: authHeader()
  });
};

export const HRCoachServices = {
  getCompletedAssessmentCount,
  getCompletedSessionsThisMonth,
  getTotalCompletedSessions,
  getTotalTimeSpentLearning,
  getMicroLearningMinutesBreakdownByMonth,
  getUserById,
  getSessions,
  getSessionsForUser,
  getEnrolledTeam,
  getConfirmedCoachCount,
  getMentoringProgress,
  getTeamCount,
  getAssessmentCount,
  getEmployeeCoachingProgress,
  getSessionTimeline,
  getCoachingSatisfactionScore,
  getMentoringSatisfactionScore,
  getTopCoachingTopics,
  getTopMentoringTopics,
  getAssessmentResultPerTeam,
  get360FeedbackRequests,
  getEnrolledTeamForUser,
  getSubMetrics,
  getMentoringSessions,
  getUserRatingById,
  getExternalCoaches,
  getAssessmentResultPerTeamV1,
  getTeamSessions
};
