import { useEffect, useState } from "react";
import { CoachServices } from "../../../services/dashboard/coachDashboard.services";

export default function CoachEarning() {
  const [metrics, setMetrics] = useState([
    {
      title: "Paid Earnings to date",
      value: 0,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 21.235 38.506">
          <path
            id="Union_4"
            data-name="Union 4"
            d="M13.622,38.507V33.189a20.371,20.371,0,0,1-3.685-.839A29.686,29.686,0,0,1,7,31.154l1.87-5A22.9,22.9,0,0,0,12.5,27.5q.554.158,1.118.275V20.2q-.431-.185-.857-.381a11.337,11.337,0,0,1-2.479-1.544,6.662,6.662,0,0,1-2.459-5.439,9.225,9.225,0,0,1,.413-2.764A7.322,7.322,0,0,1,9.523,7.657a7.4,7.4,0,0,1,2.263-1.849,10.984,10.984,0,0,1,1.836-.761V0h8.5V4.706q1.186.178,2.352.471a17.22,17.22,0,0,1,2.588.87l-1.348,5.221a20.143,20.143,0,0,0-3.329-1.044l-.263-.057v6.968q.373.176.742.361a13.291,13.291,0,0,1,2.829,1.894,7.435,7.435,0,0,1,2.545,5.849,8.815,8.815,0,0,1-.392,2.587,6.688,6.688,0,0,1-1.305,2.37,7.967,7.967,0,0,1-2.372,1.87,11.452,11.452,0,0,1-2.047.8v5.636Z"
            transform="translate(-7)"
            fill="var(--color-accent)"
          />
        </svg>
      ),
      backgroundColor: "#7F92F1"
    },
    {
      title: "Unpaid Earnings to date",
      value: 0,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 32 38.506">
          <path
            id="Union_1"
            data-name="Union 1"
            d="M13.092,38.507V33.329a20.379,20.379,0,0,1-5.156-.979A29.865,29.865,0,0,1,5,31.154l1.871-5A22.9,22.9,0,0,0,10.5,27.5a15.963,15.963,0,0,0,4.46.608A6.525,6.525,0,0,0,18.6,27.39a2.366,2.366,0,0,0,.979-2.023,2.493,2.493,0,0,0-.392-1.414,3.84,3.84,0,0,0-1.131-1.066A11.317,11.317,0,0,0,16.3,22H0V16H6.485a6.655,6.655,0,0,1-.658-3.164,9.225,9.225,0,0,1,.413-2.764A7.306,7.306,0,0,1,7.523,7.657,7.4,7.4,0,0,1,9.786,5.809,10.971,10.971,0,0,1,13.092,4.7V0h5.483V4.525a24.4,24.4,0,0,1,3.894.653,17.254,17.254,0,0,1,2.588.87l-1.349,5.221a20.133,20.133,0,0,0-3.328-1.044A17.691,17.691,0,0,0,16.4,9.79a5.028,5.028,0,0,0-3,.7,2.189,2.189,0,0,0-.913,1.828,2.379,2.379,0,0,0,.283,1.2,2.6,2.6,0,0,0,.892.892,9.561,9.561,0,0,0,1.5.762q.891.37,2.154.806L17.4,16H32v6H25.606a7.813,7.813,0,0,1,.627,3.24,8.814,8.814,0,0,1-.391,2.587,6.7,6.7,0,0,1-1.305,2.37,7.978,7.978,0,0,1-2.372,1.87A11.476,11.476,0,0,1,18.575,33.2v5.308Z"
            fill="#fdba4c"
          />
        </svg>
      ),
      backgroundColor: "#F6E0BD"
    },
    {
      title: "Total Earning",
      value: { right: 0, left: 0 },
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 21.234 38.506">
          <path
            id="tot"
            d="M50.761,85.448a13.258,13.258,0,0,0-2.828-1.893,32.173,32.173,0,0,0-3.546-1.526q-1.262-.437-2.154-.807a9.58,9.58,0,0,1-1.5-.762,2.6,2.6,0,0,1-.892-.892,2.386,2.386,0,0,1-.283-1.2,2.19,2.19,0,0,1,.914-1.828,5.032,5.032,0,0,1,3-.7,17.751,17.751,0,0,1,3.981.435,20.142,20.142,0,0,1,3.329,1.044l1.349-5.221a17.241,17.241,0,0,0-2.589-.87,24.459,24.459,0,0,0-3.894-.653V66.057H40.166v4.7a10.958,10.958,0,0,0-3.307,1.11A7.387,7.387,0,0,0,34.6,73.715a7.309,7.309,0,0,0-1.284,2.415,9.23,9.23,0,0,0-.413,2.763,6.661,6.661,0,0,0,2.459,5.439,11.3,11.3,0,0,0,2.48,1.545q1.371.631,2.806,1.153,1.479.522,2.611.979a11.252,11.252,0,0,1,1.871.936,3.838,3.838,0,0,1,1.131,1.066,2.492,2.492,0,0,1,.391,1.414,2.363,2.363,0,0,1-.979,2.023,6.53,6.53,0,0,1-3.633.718,15.987,15.987,0,0,1-4.46-.609,22.867,22.867,0,0,1-3.633-1.349l-1.871,5a29.651,29.651,0,0,0,2.937,1.2,20.349,20.349,0,0,0,5.156.979v5.177h5.482V99.256a11.489,11.489,0,0,0,3.59-1.131,7.99,7.99,0,0,0,2.371-1.87,6.7,6.7,0,0,0,1.305-2.37,8.816,8.816,0,0,0,.392-2.587,7.805,7.805,0,0,0-.674-3.349,7.718,7.718,0,0,0-1.871-2.5Z"
            transform="translate(-32.073 -66.057)"
            fill="#00a085"
          />
        </svg>
      ),
      backgroundColor: "#A1D8CE"
    }
  ]);

  const [earnings, setEarnings] = useState([]);

  useEffect(() => {
    getEarningMetrics();
    getEarnings();
  }, [setMetrics]);

  function getEarningMetrics() {
    CoachServices.getEarningMetrics()
      .then((res) => {
        const { data } = res.data;
        if (res.data.status) {
          try {
            const _metrics = [...metrics];
            _metrics[0].value = data.paid;
            _metrics[1].value = data.unPaid;
            const realValue = data.paid + data.unPaid;
            const formatedValue = realValue.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
            const rightFraction = formatedValue.split(".")[0].replace("$", "");
            const leftDigits = formatedValue.split(".")[1];
            _metrics[2].value = { right: rightFraction, left: leftDigits };
            setMetrics(_metrics);
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((e) => console.log(e));
  }

  function getEarnings() {
    CoachServices.getEarnings()
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          const sorted = [...data].sort((a, b) => new Date(b.session.start_time) - new Date(a.session.start_time));
          setEarnings(sorted);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <div className="">
      {/* Metrics */}
      <div className="hidden lg:grid grid-cols-1 sm:grid-cols-3 gap-4">
        {metrics.map((m, i) => (
          <div key={i} className="bg-white p-4 shadow-sm rounded-2xl bg-white dark:bg-gray-800 border-gray-100 border-2">
            <div className="flex flex-row items-center">
              <div
                className="mr-4 p-2 rounded-full h-8 w-8 flex items-center justify-center"
                style={{ backgroundColor: m.backgroundColor }}
              >
                {m.icon}
              </div>
              <div>
                <div className="text-4xl sm:text-2xl lg:text-2xl  xl:text-4xl font-bold text-skin-base">
                  ${m.title === "Total Earning" ? m.value.right : m.value}
                </div>
                <div className="text-md sm:text-sm lg:text-md font-extralight mt-1 flex flex-row justify-between items-center sm:items-end relative">
                  <span>{m.title}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Large Screen */}
      <div style={{ minHeight: 250 }} className="hidden lg:block rounded-lg mt-4 bg-white shadow">
        {/* table header */}
        <div className="lg:grid grid-cols-7 p-4 font-bold text-sm xl:text-base border-b">
          <div>Reference</div>
          <div>Type</div>
          <div>Client</div>
          <div>Session Status</div>
          <div>Session Date</div>
          <div>Earning</div>
          <div>Payment Status</div>
        </div>
        {/* table body large screens*/}
        {earnings.map((e, i) => (
          <div className="lg:grid grid-cols-7 hidden p-4 text-sm border-b hover:bg-gray-50" key={i}>
            <div>{e.reference_number} </div>
            <div>{e.type}</div>
            <div>
              {e?.session.team ? (
                <>{e.session?.team?.name}</>
              ) : (
                <>
                  {e.session?.user?.first_name} {e.session?.user?.last_name}
                </>
              )}
            </div>
            <div>{e.session.completion_status}</div>
            <div>
              {new Date(e?.session?.start_time).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric"
              })}{" "}
            </div>
            <div>${e.earning}</div>
            <div
              style={{
                color: e.payment_status === "Unpaid" ? "#fdba4c" : e.payment_status === "Paid" ? "#7F92F1" : ""
              }}
            >
              {e.payment_status}
            </div>
          </div>
        ))}
      </div>

      {/* Mobile Screen */}
      <div className="lg:hidden  p-4 ">
        <div className="sm:grid grid-cols-2 gap-4 w-full">
          {/* Total Earnings */}
          <div>
            <div className="text-skin-muted text-sm font-semibold pl-6 mb-2">TOTAL EARNING</div>
            <div>
              <div className="flex items-start">
                <span className={`text-skin-muted font-semibold ${metrics[2].value.right.length <= 5 ? "text-4xl" : "text-2xl"}`}>$</span>
                <span
                  className={`font-bold text-skin-base ${metrics[2].value.right.length <= 5 ? "text-6xl" : "text-4xl"}`}
                  style={{ lineHeight: 1 }}
                >
                  {metrics[2].value.right}
                </span>
                <span className="text-2xl" style={{ placeSelf: "flex-end" }}>
                  .
                </span>
                <span
                  className={`font-bold text-gray-300 ${metrics[2].value.right.length <= 5 ? "text-6xl" : "text-4xl"}`}
                  style={{ lineHeight: 1 }}
                >
                  {metrics[2].value.left}
                </span>
              </div>
              <div
                className="mt-4 ml-6 text-xs rounded-full text-green-400 bg-green-100 py-1 px-2 font-bold"
                style={{ width: "fit-content" }}
              >
                since{" "}
                {new Date(JSON.parse(localStorage.getItem("user")).data.created_at).toLocaleString("en-US", {
                  month: "short",
                  year: "numeric"
                })}
              </div>
            </div>
          </div>
          {/* Other Earning Metrics */}
          <div className="h-full flex items-center justify-center my-4 sm:m-0">
            <div
              className="border border-gray-100 p-4 w-full rounded-lg flex flex-row items-center justify-around"
              style={{ height: "fit-content" }}
            >
              {/* Paid */}
              <div className="flex flex-col justify-center items-center">
                <div className="flex items-center">
                  <span className="block rounded-full h-1 w-1 mr-2" style={{ backgroundColor: "var(--color-accent)" }}></span>
                  <span className="text-skin-muted font-semibold text-xs">Paid</span>
                </div>
                <div className="font-bold text-2xl text-skin-base">
                  {metrics[0]?.value?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0
                  })}
                </div>
              </div>
              {/* Unpaid */}
              <div className="flex flex-col justify-center items-center">
                <div className="flex items-center">
                  <span className="block rounded-full h-1 w-1 mr-2" style={{ backgroundColor: "#FCBB50" }}></span>
                  <span className="text-skin-muted font-semibold text-xs">Unpaid</span>
                </div>
                <div className="font-bold text-2xl text-skin-base">
                  {metrics[1]?.value?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Earnings Table */}
      <div className="bg-white p-4 mt-4 text-skin-base w-full lg:hidden rounded-lg shadow">
        <div className="text-lg font-bold">Earning Log</div>
        <div className="mt-4 evenOddBorder">
          {earnings.map((e, i) => (
            <div key={i} className="flex flex-row justify-between items-center w-full py-2">
              <div className="flex flex-col">
                <span className="font-semibold text-sm text-skin-base">
                  {e?.session.team ? (
                    <>{e.session?.team?.name}</>
                  ) : (
                    <>
                      {e.session?.user?.first_name} {e.session?.user?.last_name}
                    </>
                  )}
                </span>
                <span className="text-xs text-skin-muted">{e.type}</span>
                <span className="text-xs text-skin-muted">
                  {new Date(e.session.created_at).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric"
                  })}{" "}
                </span>
              </div>

              <div className="">
                <span className="font-semibold text-sm text-skin-base mr-4">${e.earning}</span>
                <span
                  className="text-xs"
                  style={{
                    color: e.payment_status === "Unpaid" ? "#fdba4c" : e.payment_status === "Paid" ? "#7F92F1" : ""
                  }}
                >
                  {e.payment_status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
