import { Box, Button, Input, Stack, Typography } from "knack-ui";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { INewCampaignFormProps } from "../../CampaignTypes";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import SVG from "../../../../components/SVG/SVG";
import { useAddEditCampaign } from "../../campaignMutations";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { campaignQueryKeys } from "../../campaignQueries";

const NewCampaignBody = () => {
  const {
    register,
    control,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useFormContext<INewCampaignFormProps>();

  const onDeleteParticipant = (r: string) => {
    const oldRecepients = getValues("recipients");
    setValue(
      "recipients",
      oldRecepients.filter((i) => i._id !== r)
    );
  };
  const queryClient = useQueryClient();
  const history = useHistory();
  const { mutateAsync } = useAddEditCampaign();

  const onSubmit: SubmitHandler<INewCampaignFormProps> = async (data) => {
    if (data.recipients.length === 0) {
      return Failure("Please add atleast one recipient");
    }
    try {
      await mutateAsync({
        ...data,
        recipients: data.recipients.map((r) => r._id)
      });
      await queryClient.invalidateQueries(campaignQueryKeys.all);
      Success("Campaign created successfully");
      history.replace("/campaigns");
    } catch (reason) {
      Failure("Something went wrong");
    }
  };

  return (
    <Box
      // To make tailwind rtl plugin work
      dir="ltr"
      variant="outlined"
      className="flex-1 h-full px-8 py-6 overflow-y-auto"
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        className="mb-6"
      >
        <Typography variant="h5">Create new campaign</Typography>
        <Button kind="success" onClick={handleSubmit(onSubmit)}>
          Create campaign
        </Button>
      </Stack>

      <div className="mb-6">
        <Stack alignItems="center" justifyContent="space-between">
          <Typography variant="body1" fontWeight="semibold">
            Participants
          </Typography>
          {watch("recipients").length > 0 && (
            <Button
              onClick={() => setValue("recipients", [])}
              kind="warning"
              variant="xsmall"
            >
              Clear all
            </Button>
          )}
        </Stack>
        <div className="my-4 overflow-y-auto max-h-40 scrollhost">
          {watch("recipients").length === 0 && (
            <div className="p-2 bg-gray-100 border rounded-lg">
              <EmptyState title="Add participants from the right pane" />
            </div>
          )}
          {watch("recipients").length > 0 && (
            <div className="flex flex-wrap gap-2">
              {watch("recipients").map((r) => (
                <Stack
                  className="items-center px-2 py-2 text-xs transition-colors duration-100 rounded-full cursor-pointer bg-tertiary hover:bg-tertiaryDark"
                  onClick={() => onDeleteParticipant(r._id)}
                  key={r._id}
                  gap={2}
                >
                  <span>{`${r.first_name || ''} ${r.last_name || ''} (${r.email})`}</span>
                  <SVG size={15} name="Close" />
                </Stack>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="mb-6">
        <Typography
          className="block mb-2"
          variant="body1"
          fontWeight="semibold"
          as="label"
          htmlFor="grid-subject"
        >
          Campaign subject
        </Typography>
        <Input
          id="subject"
          placeholder="Campaign subject/title"
          {...register("subject", { required: "Required field" })}
          error={errors.subject?.message}
        />
      </div>
      <div>
        <Typography
          className="block"
          variant="body1"
          fontWeight="semibold"
          as="label"
          htmlFor="grid-subject"
        >
          Campaign body
        </Typography>
        <Typography className="mb-4 " variant="caption" color="muted">
          Note: {`{{first_name}}`} will be replaced by the recepient name when
          sent.
        </Typography>
        <Controller
          control={control}
          name="body"
          render={({ field: { value, onChange } }) => (
            <SunEditor
              setOptions={{
                buttonList: [
                  ["undo", "redo", "font", "fontSize", "formatBlock"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "removeFormat"
                  ],
                  [
                    "fontColor",
                    "hiliteColor",
                    "align",
                    "horizontalRule",
                    "list",
                    "table"
                  ],
                  ["link", "showBlocks", "codeView"]
                ]
              }}
              onChange={onChange}
              defaultValue={value}
              height="350"
            />
          )}
        />
      </div>
    </Box>
  );
};

export default NewCampaignBody;
