import { useEffect, useState } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { StatusIndicator } from "../../../../components/Information/StatusIndicator";
import { LoadMore } from "../../../../components/LazyLoading/LoadMore";
import { ConfirmationModal } from "../../../../components/modal/ConfirmationModal";
import { deleteProgramInvite, sendProgramInvitation } from "../../../../services/base-api";
import { Success } from "../../../../components/ToastNotification/Success";
import { KnackSpinner } from "../../../../components/Asset/KnackSpinner";
import { Information } from "../../../../components/ToastNotification/Information";
import { InviteParticipantsCard } from "../../../../components/Cards/InviteParticipantsCard";
import { LargeScreenModificationPanel } from "../../../dashboard/Admin/Components/LargeScreenModificationPanel";
import { PairingStatus } from "./PairingStatus";
import { WarningInformation } from "../../../../components/Information/WarningInformation";
import UserDrawer from "../../../../components/Profile/UserDrawer/UserDrawer";
import { Avatar, Checkbox, Drawer, Typography } from "knack-ui";
import { useSendRegistrationReminder } from "../../../../features/Programs/programMutations";
import { Utilization } from "../../../dashboard/hrDashboard/hrPeople/Utilization";
import { useGetProgramParticipants } from "../../../../features/Programs/programQueries";
import { useFilter } from "../../../../features/Programs/ui/HrProgramDashboard/Participants/utils/Filter";
import { onSearchPeople } from "../../../dashboard/hrDashboard/hrPeople/PeopleList";

export const ExpertTableList = ({ program, setEmailModal }) => {
  const [userType] = useState(program.program_type === "Mentoring" ? "mentor" : "coach");

  const { value, onChange } = onSearchPeople();
  const { value: filterBy } = useFilter();
  const [showSpinner, setShowSpinner] = useState(false);
  const [isSelection, setIsSelection] = useState(false);
  const [selectedExpert, setSelectedExperts] = useState(null);
  const [selectedExpertProgramInvite, setSelectedExpertProgramInvite] = useState(null);

  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [experts, setExperts] = useState([]);
  const [filteredExperts, setFilteredExperts] = useState([]);
  const [unregisteredExperts, setUnregisteredExperts] = useState([]);
  const [unRegisteredFilteredExperts, setUnregisteredFilteredExperts] = useState([]);
  const [ListPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [inviteConfirm, setInviteConfirm] = useState(false);
  const { data: membersList, refetch } = useGetProgramParticipants(
    { programId: program?._id, type: userType },
    {
      enabled: Boolean(program),
      select: (members) =>
        members.filter((m) =>
          value
            ? m?.profile?.user?.email?.includes(value.toLowerCase()) ||
              `${m?.profile?.first_name} ${m?.profile?.last_name}`.toLowerCase().includes(value.toLowerCase())
            : members
        )
    }
  );

  const { onFilterChange } = useFilter();

  useEffect(() => {
    onChange("");
    onFilterChange("");
  }, []);

  useEffect(() => {
    if (value !== "") {
      const _filtered = experts.filter(
        (u) =>
          u?.profile?.user?.email?.includes(value.toLowerCase()) ||
          `${u?.profile?.first_name} ${u?.profile?.last_name}`.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredExperts(_filtered);

      const _filteredUnregistered = unregisteredExperts.filter(
        (u) =>
          u?.profile?.user?.email?.includes(value.toLowerCase()) ||
          `${u?.profile?.first_name} ${u?.profile?.last_name}`.toLowerCase().includes(value.toLowerCase())
      );
      setUnregisteredFilteredExperts(_filteredUnregistered);
    } else {
      setFilteredExperts(experts);
      setUnregisteredFilteredExperts(unregisteredExperts);
    }
  }, [value, setFilteredExperts, setUnregisteredFilteredExperts, experts, unregisteredExperts]);

  useEffect(() => {
    if (program && membersList) {
      const unregistered = membersList.filter((user) => user.confirmed === false).map((m) => ({ ...m, registered: false }));
      const registered = membersList.filter((user) => user.confirmed === true).map((m) => ({ ...m, registered: true }));
      setExperts(registered);
      setFilteredExperts(registered);
      setUnregisteredExperts(unregistered);
      setUnregisteredFilteredExperts(unregistered);
    }
  }, [membersList, program]);

  useEffect(() => {
    if (filterBy === "") {
      setFilteredExperts(experts);
      setUnregisteredFilteredExperts(unregisteredExperts);
    } else {
      if (filterBy === "Registered") {
        if (program) {
          setFilteredExperts(experts);
          setUnregisteredFilteredExperts([]);
        } else {
          const filtered = experts.filter((s) => s?.registered === true);
          setFilteredExperts(filtered);
          // setUnregisteredFilteredExperts([])
        }
      } else {
        if (program) {
          setFilteredExperts([]);
          setUnregisteredFilteredExperts(unregisteredExperts);
        } else {
          const filtered = experts.filter((s) => s?.registered === false);
          setFilteredExperts(filtered);
        }
      }
    }
  }, [experts, filterBy, program, setFilteredExperts, setUnregisteredFilteredExperts, unregisteredExperts]);

  function onSelectDeselectAll(e) {
    if (e.target.checked) {
      setIsSelection(true);
      var itemgg = [...unregisteredExperts];
      setItemsSelected(itemgg);
    } else {
      setIsSelection(false);
      setItemsSelected([]);
    }
  }

  function onAddRemoveItem(item) {
    if (itemsSelected.find(({ _id }) => _id === item._id)) {
      const selected = [...itemsSelected].filter((f) => f._id !== item._id);
      setItemsSelected(selected);
    } else {
      const selected = itemsSelected.length > 0 ? [...itemsSelected] : [];
      selected.push(item);
      setItemsSelected(selected);
    }
  }

  function onDeleteInvite() {
    if (itemsSelected.length > 0) {
      setIsSelection(false); // false selections
      setOpenConfirm(false); // hide prompt
      setShowSpinner(true);

      itemsSelected.map((s, i) => {
        deleteProgramInvite(s.program, s.profile.user)
          .then((res) => {
            setShowSpinner(false);
            setItemsSelected([]);
            refetch();
            if (i === itemsSelected.length - 1) {
              Success("Coaches deleted successfully!");
            }
          })
          .catch((e) => {
            setShowSpinner(false);
            console.log(e);
            Failure("Oops! Something went wrong. Please try again");
          });
      });
    }
  }

  function confirmDelete() {
    setOpenConfirm(true);
  }

  function onInviteSelected() {
    if (program?.status !== "Published") {
      Failure("You need to Publish program First");
      return;
    }

    sendProgramInvitation(program._id, itemsSelected)
      .then((res) => {
        Information("program Invitation sent", "Coaches will receive your invitation in their email");
        setItemsSelected([]);
        setIsSelection(false);
        setInviteConfirm(false);
        refetch();
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops! Something went wrong. Please try again");
      });
  }

  const { mutateAsync: sendReminder } = useSendRegistrationReminder();
  const [openConfirmSendReminder, setOpenConfirmSendReminder] = useState(false);

  function onSendReminder() {
    sendReminder({
      userIDS: itemsSelected.map((i) => i.profile.user._id),
      programId: program._id
    }).then(() => {
      Success(`Reminder sent successfully`);
      refetch();
      setOpenConfirmSendReminder(false);
    });
  }

  function onInvite() {
    if (program?.status === "Published") {
      setInviteConfirm(true);
      refetch();
    } else {
      Information("You cannot send out invitations until you PUBLISH your program", "Click the publish button to publish your program");
    }
  }

  return (
    <>
      <ConfirmationModal
        title={`
       Registration reminder
        `}
        header={`Are you sure you want to send a reminder to the selected participant${itemsSelected.length > 1 ? "s" : ""}?`}
        showDefaultButton={true}
        buttonFunction={() => onSendReminder()}
        open={openConfirmSendReminder}
        setOpen={setOpenConfirmSendReminder}
        defaultButtonText={itemsSelected.length > 1 ? "Send Reminder to all" : "Send Reminder"}
        width=""
      />

      {showSpinner && (
        <div onClick={() => setShowSpinner(false)}>
          <KnackSpinner />
        </div>
      )}

      <Drawer onClose={() => setUserDrawerOpen(false)} isOpen={userDrawerOpen}>
        <UserDrawer userId={selectedExpert} programInvite={selectedExpertProgramInvite} />
      </Drawer>

      <ConfirmationModal
        title={"Delete participant"}
        header={
          itemsSelected.length > 1
            ? "Are you sure you want to delete this participants?"
            : "Are you sure you want to delete this participant?"
        }
        showDefaultButton={true}
        buttonFunction={() => onDeleteInvite()}
        open={openConfirm}
        setOpen={setOpenConfirm}
        defaultButtonText={itemsSelected.length > 1 ? "Delete all" : "Delete"}
        width=""
      />

      <ConfirmationModal
        title={"Invite participant(s)"}
        header={
          itemsSelected.length > 1
            ? "Are you sure you want to invite these participants?"
            : "Are you sure you want to invite this participant?"
        }
        showDefaultButton={true}
        buttonFunction={() => onInviteSelected()}
        open={inviteConfirm}
        setOpen={setInviteConfirm}
        defaultButtonText={itemsSelected.length > 1 ? "Invite all" : "Invite"}
        width=""
        canDismissFromBackdrop={true}
      >
        {itemsSelected.filter((s) => s.sent === true).length > 0 && (
          <div className="">
            <WarningInformation message="You have already sent an invitation to the following users" />
            <div className="p-4">
              {itemsSelected
                .filter((s) => s.sent === true)
                .map((m, i) => (
                  <p className="text-gray-900 whitespace-no-wrap text-sm" key={i}>
                    * {m?.profile?.first_name || m?.first_name} {m?.profile?.last_name || m?.last_name} {m?.user?.email || m?.email}
                  </p>
                ))}
            </div>

            <div className="px-4">
              <div>
                Click <strong>{itemsSelected.length > 1 ? "Invite all" : "Invite"}</strong> to send to the following users
              </div>
              <div className="scrollhost2 overflow-auto" style={{ maxHeight: 150 }}>
                {itemsSelected
                  .filter((s) => s.sent === false)
                  .map((m, i) => (
                    <p className="text-gray-900 whitespace-no-wrap text-sm" key={i}>
                      * {m?.profile?.first_name} {m?.profile?.last_name} {m?.profile?.user?.email}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        )}
      </ConfirmationModal>

      {program && (
        <div className="px-1 text-sm text-skin-muted">
          <strong>
            {" "}
            {filteredExperts.length > 0 && `${filteredExperts.length} ${userType}`}
            {filteredExperts.length === 0 && `No ${userType}`}
          </strong>{" "}
          Registered
        </div>
      )}
      {program && !program?.archived && program._id && experts.length === 0 && unregisteredExperts.length === 0 && (
        <InviteParticipantsCard
          program={program}
          type={program.program_type === "Coaching" ? "coach" : "mentor"}
          openAddByEmailModal={() => setEmailModal(true)}
        />
      )}
      {(experts.length > 0 || unregisteredExperts.length > 0) && (
        <div className="py-1">
          <div className="rounded-lg shadow pb-10">
            <div className="inline-block min-w-full h-full w-full overflow-x-auto ">
              <table className="min-w-full leading-normal whitespace-nowrap h-full w-full">
                <thead>
                  <tr>
                    {program && (
                      <th
                        scope="col"
                        className="flex items-center gap-4 px-2 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                      >
                        <Checkbox
                          type="checkbox"
                          disabled={unRegisteredFilteredExperts?.length === 0}
                          checked={isSelection}
                          className={unRegisteredFilteredExperts?.length === 0 ? "h-3 w-3 rounded opacity-40" : "h-3 w-3 rounded"}
                          onChange={(e) => {
                            e.stopPropagation();
                            onSelectDeselectAll(e);
                          }}
                        />
                        <Typography>Name</Typography>
                      </th>
                    )}

                    <th
                      scope="col"
                      className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                    >
                      Sent Reminders
                    </th>

                    <th
                      scope="col"
                      className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                    >
                      Utilization
                    </th>

                    <th
                      scope="col"
                      className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                    >
                      {program?.program_type === "Mentoring" ? "Mentees" : "Coachees"}
                    </th>
                    <th
                      scope="col"
                      className="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm font-bold text-skin-base"
                    ></th>
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {!isSelection &&
                    [...filteredExperts, ...unRegisteredFilteredExperts].slice(0, page * ListPerPage).map((m, i) => (
                      <tr
                        onClick={() => {
                          if (m.registered) {
                            setSelectedExperts(m?.profile?.user?._id);
                            setUserDrawerOpen(true);
                            setSelectedExpertProgramInvite(m);
                          }
                        }}
                        key={i}
                      >
                        <td className="px-2 py-5 leading-normal cursor-pointer border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center gap-4">
                            <Checkbox
                              type="checkbox"
                              checked={
                                !m.registered &&
                                itemsSelected.find(({ _id }) => {
                                  return _id === m._id;
                                })
                              }
                              disabled={m.registered}
                              className={
                                m.registered ? "mt-0 pt-0 h-3 w-3 rounded gray-200 opacity-40" : "mt-0 pt-0 h-3 w-3 rounded gray-200"
                              }
                              onChange={(e) => onAddRemoveItem(m)}
                            />
                            <Avatar
                              className="flex-shrink-0"
                              text={m?.profile?.first_name || m?.profile?.user?.email || ""}
                              image={m?.profile?.profile_image || ""}
                            />
                            {m?.profile?.first_name} {m?.profile?.last_name}
                          </div>
                        </td>

                        <td className="px-5 py-5 leading-normal cursor-pointer border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">{m?.profile?.user?.email}</p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 leading-normal cursor-pointer border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">{m?.reminder_count}</p>
                            </div>
                          </div>
                        </td>

                        <td className="px-5 py-5 leading-normal cursor-pointer border-b border-gray-200 bg-white text-sm">
                          <Utilization utilization={m.utilization} />
                        </td>

                        <PairingStatus key={m?._id} program={program} mentor={m} isCardStyle={false} isRegistered={m.confirmed} />
                      </tr>
                    ))}

                  {isSelection &&
                    unRegisteredFilteredExperts.map((m, i) => (
                      <tr key={i} className={`${itemsSelected.find(({ _id }) => _id === m._id) === undefined ? "" : "bg-blue-50"}`}>
                        <td className="px-2 py-5 border-b border-gray-200 text-sm">
                          <div className="flex items-center gap-4">
                            <Checkbox
                              type="checkbox"
                              checked={itemsSelected.find(({ _id }) => {
                                return _id === m._id;
                              })}
                              className="mt-0 pt-0 h-3 w-3 rounded gray-200 pr-4"
                              onChange={(e) => onAddRemoveItem(m)}
                            />
                            <Avatar
                              className="flex-shrink-0"
                              text={m?.profile?.first_name || m?.profile?.user?.email || ""}
                              image={m?.profile?.profile_image || ""}
                            />
                            {m?.profile?.first_name} {m?.profile?.last_name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                          <div className="flex items-center">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">{m?.profile?.user?.email}</p>
                            </div>
                          </div>
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                          <div className="flex items-center">
                            <div className="">
                              <p className="text-gray-900 whitespace-no-wrap">{m?.reminder_count}</p>
                            </div>
                          </div>
                        </td>

                        <td className="px-5 py-5 leading-normal cursor-pointer border-b border-gray-200 bg-white text-sm">
                          <Utilization utilization={m.utilization} />
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 text-sm">
                          <StatusIndicator color="red" text="Unregistered" />
                        </td>

                        <td className="px-5 py-5 border-b border-gray-200 text-sm"></td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <LoadMore
                onLoad={() => {
                  setPage(page + 1);
                }}
                showHide={Math.floor([...experts, ...unregisteredExperts].length / ListPerPage) >= page}
              />
            </div>
          </div>
        </div>
      )}

      <LargeScreenModificationPanel
        List={itemsSelected}
        onSendReminder={() => setOpenConfirmSendReminder(true)}
        resetList={() => setItemsSelected([])}
        willConfirmDelete={true}
        onDelete={confirmDelete}
        onInvite={
          itemsSelected.filter((f) => f.sent === false).length > 0
            ? () => {
                onInvite();
              }
            : null
        }
      />
    </>
  );
};
