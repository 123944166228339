import { CardPaper } from "../../../../components/Cards/CardPaper";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import LinearProgressWithLabel from "../../../../components/LinearProgressWithLabel";

export const TopTopics = ({ topTopics, program_type }) => {
  return (
    <div>
      <CardPaper classes="" title={`Top 10 ${program_type} Topics`}>
        <div style={{ minHeight: 250, height: 250 }} className="overflow-y-auto">
          {topTopics && topTopics?.categories?.length === 0 && <EmptyState title="No data was found" />}
          <div className="grid gap-4 mt-4">
            {topTopics?.map((c, i) => (
              <LinearProgressWithLabel label={c?.topic} color="dynamic" percentage={Number(c?.count).toFixed(2)} key={i} />
            ))}
          </div>
        </div>
      </CardPaper>
    </div>
  );
};
