import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

export const AvatarPlaceholder = ({
  classes,
  width,
  height,
  imageUrl = "",
  isUploading = false,
  imageHeight = "h-24",
  imageWidth = "w-24",
  imagePixelHeight,
  imagePixelWidth
}) => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-center absolute ">
        {" "}
        <ClipLoader loading={isUploading} size={50} />{" "}
      </div>
      {imageUrl === "" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || "50px"}
          height={height || "50px"}
          viewBox="0 0 50 50"
          className={classes}
        >
          <g
            id="Group_47"
            data-name="Group 47"
            transform="translate(-302 -265)"
          >
            <circle
              id="Ellipse_11"
              data-name="Ellipse 11"
              cx="25"
              cy="25"
              r="25"
              transform="translate(302 265)"
              fill="#ebebeb"
            />
            <path
              id="Icon_awesome-user-alt"
              data-name="Icon awesome-user-alt"
              d="M11.83,13.308A6.654,6.654,0,1,0,5.175,6.654,6.656,6.656,0,0,0,11.83,13.308Zm5.915,1.479H15.2a8.044,8.044,0,0,1-6.737,0H5.915A5.914,5.914,0,0,0,0,20.7v.739a2.219,2.219,0,0,0,2.218,2.218H21.441a2.219,2.219,0,0,0,2.218-2.218V20.7A5.914,5.914,0,0,0,17.744,14.787Z"
              transform="translate(315.171 278.171)"
              fill="#7b7b7b"
            />
          </g>
        </svg>
      ) : (
        <img
          src={imageUrl}
          className={`${classes} ${imageHeight} ${imageWidth} border rounded-full shadow-sm object-cover`}
          loading="lazy"
          style={{ height: imagePixelHeight, width: imagePixelHeight }}
        />
      )}
    </div>
  );
};
