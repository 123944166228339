import { Avatar, Checkbox, Drawer } from "knack-ui";
import { useState } from "react";
import { StatusIndicator } from "../../../../../../components/Information/StatusIndicator";
import { LoadMore } from "../../../../../../components/LazyLoading/LoadMore";
import UserDrawer from "../../../../../../components/Profile/UserDrawer/UserDrawer";
import { IProgram, IProgramInvite } from "../../../../ProgramTypes";
import { HrProgramParticipantUserActions } from "./HrProgramParticipantUserActions";

interface IProps {
  program: IProgram;
  members: IProgramInvite[];
  filter: string;
  selections: IProgramInvite[];
  onAddRemoveItemSelection: (invite: IProgramInvite) => void;
}
const HrProgramParticipantsUserListTableBody = ({ program, members, selections, onAddRemoveItemSelection }: IProps) => {
  const [page, setPage] = useState(1);
  const [ListPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState<string>("");

  return (
    <>
      <Drawer onClose={() => setSelectedUser("")} isOpen={Boolean(selectedUser)}>
        <UserDrawer userId={selectedUser} />
      </Drawer>

      <tbody className="bg-white">
        {members?.slice(0, page * ListPerPage)?.map((m, i) => (
          <tr key={i} className="border-b border-gray-200">
            <td className="flex items-center gap-4 px-2 py-5 text-sm leading-normal bg-white ">
              <Checkbox
                checked={
                  selections.find(({ _id }) => {
                    return _id === m._id;
                  })
                    ? true
                    : false
                }
                disabled={Boolean(m.connection)}
                onChange={() => onAddRemoveItemSelection(m)}
              />
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2" onClick={() => setSelectedUser(m?.profile?.user?._id)}>
                  <Avatar
                    className="flex-shrink-0"
                    text={m?.profile?.first_name || m?.profile?.user?.email || ""}
                    image={m?.profile?.profile_image || m?.profile?.user?.profile_image || ""}
                  />
                  {m?.profile?.first_name} {m?.profile?.last_name}
                </div>
              </div>
            </td>
            <td className="px-5 py-5 text-sm leading-normal bg-white ">
              <div className="flex items-center">
                <div className="">
                  <p className="text-gray-900 whitespace-no-wrap">{m?.profile?.user?.email}</p>
                </div>
              </div>
            </td>
            <td className="px-5 py-5 text-sm leading-normal bg-white ">
              <div className="flex items-center">
                <div className="">
                  <p className="text-gray-900 whitespace-no-wrap">{m?.reminder_count}</p>
                </div>
              </div>
            </td>
            <td className="px-5 py-5 text-xs">
              {m.connection ? (
                <StatusIndicator color="green" text="Paired" />
              ) : m.confirmed ? (
                <StatusIndicator color="yellow" text="Registered" />
              ) : (
                <StatusIndicator color="red" text="Unregistered" />
              )}
            </td>
            {program?.coach_type === "knack_coaching" && (
              <td className="px-5 py-5 text-xs ">
                {<StatusIndicator color={m?.plan_status === "Active" ? "green" : "red"} text={m?.plan_status || "Not Active"} />}
              </td>
            )}
            {m.connection ? (
              <td className="py-5 text-sm leading-normal bg-white">
                <div className="flex items-center gap-2" onClick={() => setSelectedUser(m?.connection?.mentor as unknown as string)}>
                  <Avatar
                    className="flex-shrink-0"
                    text={m?.connection.mentorProfile?.first_name || m?.connection.mentorProfile?.user?.email || ""}
                    image={m?.connection.mentorProfile?.profile_image || m?.connection.mentorProfile?.user?.profile_image || ""}
                  />
                  {m?.connection.mentorProfile?.first_name} {m?.connection.mentorProfile?.last_name}
                </div>
              </td>
            ) : (
              <td className="py-5 text-sm leading-normal bg-white "></td>
            )}
            <td className="px-5 py-5 text-xs">
              {<HrProgramParticipantUserActions program={program} member={m} />}
            </td>
          </tr>
        ))}
      </tbody>

      <tfoot className="w-full">
        <div className="w-full justify-center absolute">
          <LoadMore
            onLoad={() => {
              setPage(page + 1);
            }}
            showHide={Math.floor(members?.length / ListPerPage) >= page}
          />
        </div>
      </tfoot>
    </>
  );
};

export default HrProgramParticipantsUserListTableBody;
