import { Avatar, AvatarGroup, BaseDialog, Box, Button, Stack, Typography } from "knack-ui";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { ISession, ISessionNote, TSessionWithProgram } from "../../../../../features/Sessions/SessionsTypes";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { SessionTypes } from "../../../../../lib/constants";
import moment from "moment";
import { useGetSessionNotes } from "../../../../../features/Sessions/sessionsQueries";
import Color from "color";
import SessionNoteModal from "./SessionNoteModal";
import EmptyState from "../../../../../components/EmptyState/EmptyState";

import { useDeleteSessionNote, useUpdateSession } from "../../../../../features/Sessions/sessionsMutations";
import { ITeam } from "../../../../../features/Team/TeamTypes";
import MarkSessionAsDoneDialog from "../../../../../features/Sessions/ui/MarkSessionAsDoneDialog";
import { useUser, useUserRoles } from "../../../../../features/User/userQueries";
import { IUser } from "../../../../../features/User/UserTypes";
import { useGetCalendarProfile } from "../../../../../features/Calendar/calendarQueries";
import { useLoadingSpinner } from "../../../../../hooks/useLoadingSpinner";
import { IProgram } from "../../../../../features/Programs/ProgramTypes";
import Divider from "../../../../../components/Divider/Divider";
import SVG from "../../../../../components/SVG/SVG";
import { ReschedulePopup } from "./ReschedulePopup";
import { ICalendarAccount } from "../../../../../features/Calendar/CalendarTypes";
import CancelSession from "../../../../../components/ActionSheets/CancelSession";
import { Success } from "../../../../../components/ToastNotification/Success";
import { canEnableNoShow, canReschedule } from "../../../../../lib/utils";
import MiniNote from "../components/MiniNote";
import { Failure } from "../../../../../components/ToastNotification/Failure";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import styled from "styled-components";

interface SessionDrawerDetailsProps {
  session?: TSessionWithProgram;
  onClose?: () => void;
}

const SessionDetailsDrawer = ({ session, onClose }: SessionDrawerDetailsProps) => {
  const [highlighted, setHighlighted] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [state, setState] = useState<TSessionWithProgram | undefined>(session);
  const { roleState } = useUserRoles();
  const { data: user } = useUser();

  const [selectedSession, setSelectedSession] = useState<TSessionWithProgram | null>(null);
  const [markAsDoneOpen, setMarkAsDoneOpen] = useState(false);
  const { height: pageHeight, width: pageWidth } = useWindowSize();
  const [selectedNote, setSelectedNote] = useState<ISessionNote | null>(null);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [cancelPrompt, showCancelPrompt] = useState(false);
  const { mutateAsync: updateSession } = useUpdateSession();
  const [confirmNoShow, setConfirmNoShow] = useState(false);

  const [deleteNoteConfirmationOpen, setDeleteNoteConfirmationOpen] = useState(false);

  const { data: expertCalendar } = useGetCalendarProfile(
    session?.expert?.scheduler_id,
    session?.expert?.user?._id // this always fetches the calendar profile of the expert
  );
  const { data: notes } = useGetSessionNotes(
    {
      sessionId: state?._id as string
    },
    {
      select: useCallback((notes: ISessionNote[]) => {
        const sharedNotes: ISessionNote[] = [];
        const privateNotes: ISessionNote[] = [];
        notes.forEach((note) => {
          if (typeof note.shared_with !== "undefined" && note.shared_with.length > 0) {
            sharedNotes.push(note);
          } else {
            privateNotes.push(note);
          }
        });
        return { sharedNotes, privateNotes };
      }, [])
    }
  );
  const { mutateAsync: deleteSessionNote } = useDeleteSessionNote();

  const handleDeleteNote = async (noteId: string, confirm: boolean) => {
    if (confirm) {
      return setDeleteNoteConfirmationOpen(true);
    }
    try {
      await deleteSessionNote(noteId);
      setDeleteNoteConfirmationOpen(false);
      Success("Note has been deleted");
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  };

  const { setLoading } = useLoadingSpinner();

  const onSetNoShowStatus = async () => {
    setLoading(true);
    try {
      await updateSession({
        sessionId: state?._id as string,
        updates: {
          completion_status: "No Show"
        }
      });

      const _updateSession = {
        ...state,
        completion_status: "No Show"
      };
      setState(_updateSession as TSessionWithProgram);
      setConfirmNoShow(false);
      setLoading(false);
      if (onClose) {
        onClose();
      }

      Success("Session status updated successfully");
    } catch (error) {
      setConfirmNoShow(false);
      setLoading(false);
    }
  };

  const expertOrUserName = useMemo(() => {
    if (!session) return null;
    if (roleState?.activeRole === "User" && session?.expert) {
      return session?.expert;
    } else if (roleState?.activeRole === "Expert" && session.user) {
      return session?.user;
    }
    return null;
  }, [session, roleState]);

  useEffect(() => {
    setState(session);
  }, [session, setState]);

  return (
    <>
      <CancelSession
        open={cancelPrompt}
        setOpen={(value) => showCancelPrompt(value)}
        session={state as ISession}
        onSuccess={() => {
          if (onClose) onClose();
        }}
      />
      <div style={{ minWidth: `calc(${pageWidth}px / 3.3 )`, maxWidth: 500 }}>
        <div className="p-6">
          <Typography variant="h6" as="h6">
            Session Details
          </Typography>
        </div>
        <div style={{ height: `calc(${pageHeight}px - 97px)` }} className="overflow-y-auto">
          {user?.account_type !== "Admin" && state?.conferencing?.join_url && (
            <div className="px-6 py-4">
              <DetailCard
                title="Join Conference Link"
                value={
                  <a className="block text-blue-700" href={state?.conferencing?.join_url} target="_blank" rel="noreferrer">
                    {state?.conferencing?.join_url}
                  </a>
                }
              />
            </div>
          )}

          <div className="grid grid-cols-2 gap-6 px-6">
            <DetailCard title="Session Type" value={SessionTypes[(state as TSessionWithProgram)?.type]} />
            <DetailCard
              title="Session Date"
              value={moment((state as TSessionWithProgram)?.start_time).format("MMM Do YYYY, h:mm a")}
              highlighted={highlighted}
            />
            <DetailCard title="Session Status" value={(state as TSessionWithProgram)?.completion_status} />
            {user?.account_type !== "Admin" && (
              <DetailCard
                title={roleState?.activeRole === "Expert" ? (state?.team ? "Participants" : "Participant") : "Expert"}
                value={
                  state?.team ? (
                    <div className="flex">
                      <AvatarGroup max={4}>
                        {(state?.team as ITeam)?.members.map((c) => (
                          <Avatar
                            size="small"
                            key={c._id}
                            className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                            text={c?.profile?.first_name || c?.email}
                            image={c?.profile_image}
                          />
                        ))}
                      </AvatarGroup>
                    </div>
                  ) : (
                    <>{`${expertOrUserName?.first_name} ${expertOrUserName?.last_name}`}</>
                  )
                }
              />
            )}

            {user?.account_type === "Admin" && (
              <DetailCard
                title={(state?.program as IProgram)?.program_type === "Mentoring" ? "Mentor" : "Coach"}
                value={
                  state?.expert && (
                    <Stack alignItems="center" gap={2} className="mt-2">
                      <Avatar size="small" text={state?.expert?.first_name || ""} image={state?.expert?.user?.profile_image || ""} />
                      <Typography fontWeight="semibold" variant="body2">
                        {state?.expert?.first_name} {state?.expert?.last_name}
                      </Typography>
                    </Stack>
                  )
                }
              />
            )}

            {user?.account_type === "Admin" && (
              <DetailCard
                title={state?.team ? "Participants" : "Participant"}
                value={
                  state?.team ? (
                    <div className="flex mt-2">
                      <AvatarGroup max={4}>
                        {(state?.team as ITeam)?.members.map((c) => (
                          <Avatar
                            size="small"
                            key={c._id}
                            className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                            text={c?.profile?.first_name || c?.email}
                            image={c?.profile_image}
                          />
                        ))}
                      </AvatarGroup>
                    </div>
                  ) : (
                    <Stack alignItems="center" gap={2} className="mt-2">
                      <Avatar size="small" text={state?.user?.first_name || ""} image={state?.user?.user?.profile_image || ""} />
                      <Typography fontWeight="semibold" variant="body2">
                        {state?.user?.first_name} {state?.user?.last_name}
                      </Typography>
                    </Stack>
                  )
                }
              />
            )}
          </div>

          {user?.account_type !== "Admin" && state?.completion_status === "Pending" && (
            <Stack className="px-6 py-4 mt-4" gap={4} direction="column">
              {(state?.program as IProgram).coach_type !== "knack_coaching" &&
                moment(new Date()).isSameOrAfter(moment(new Date(state?.start_time as string))) &&
                !state?.completion_status?.includes((user as IUser)?._id) && (
                  <Button
                    variant="small"
                    kind="primary"
                    onClick={() => {
                      setSelectedSession(state);
                      setMarkAsDoneOpen(true);
                    }}
                  >
                    Mark as done
                  </Button>
                )}

              {canReschedule(state?.start_time as Date) && (
                <Button variant="small" kind="primaryOutline" onClick={() => setReschedule(true)}>
                  Reschedule
                </Button>
              )}
              {moment(new Date()).isAfter(moment(new Date(session?.start_time as string))) &&
                canEnableNoShow(session?.program.coach_type, user?.account_type as IUser["account_type"]) && (
                  <Button variant="small" kind="defaultOutline" onClick={() => setConfirmNoShow(true)}>
                    No Show
                  </Button>
                )}
              <Button
                variant="small"
                kind="warning"
                onClick={() => {
                  showCancelPrompt(true);
                }}
              >
                Cancel Session
              </Button>
            </Stack>
          )}

          {user?.account_type !== "Admin" && (
            <>
              <Divider className="block w-full my-6" />
              <div className="px-6 py-4 pt-0">
                <div className="mb-4">
                  <Typography variant="h6" as="h6">
                    Shared Notes
                  </Typography>
                  <Typography className="mb-4" variant="body2" color="muted">
                    Shared session notes.
                  </Typography>
                  {notes && notes.sharedNotes.length === 0 && (
                    <Box paddingPreset="card" variant="outlined">
                      <EmptyState title="No notes were shared" />
                    </Box>
                  )}
                  {notes && notes.sharedNotes.length > 0 && (
                    <NotesGrid dir="ltr">
                      {notes.sharedNotes.map((note) => (
                        <MiniNote
                          note={note}
                          key={note._id}
                          color={Color(note.color).alpha(0.1).hexa()}
                          onDelete={() => {
                            handleDeleteNote(note._id, true);
                            setSelectedNote(note);
                          }}
                          onEdit={() => {
                            setNoteModalOpen(true);
                            setSelectedNote(note);
                          }}
                        />
                      ))}
                    </NotesGrid>
                  )}
                </div>
                <Stack className="mb-4" gap={3} justifyContent="space-between" alignItems="center">
                  <div>
                    <Typography variant="h6" as="h6">
                      Private Notes
                    </Typography>
                    <Typography variant="body2" color="muted">
                      Private notes are only visible to you
                    </Typography>
                  </div>
                  <Button
                    onClick={() => {
                      setNoteModalOpen(true);
                      if (selectedNote) setSelectedNote(null);
                    }}
                    kind="tertiary"
                    variant="small"
                  >
                    Add Note
                  </Button>
                </Stack>
                {notes && notes.privateNotes.length === 0 && (
                  <Box paddingPreset="card" variant="outlined">
                    <EmptyState title="No notes were found" buttonText="Create new note" buttonCb={() => setNoteModalOpen(true)} />
                  </Box>
                )}
                {notes && notes.privateNotes.length > 0 && (
                  <NotesGrid dir="ltr">
                    {notes?.privateNotes.map((note) => (
                      <MiniNote
                        note={note}
                        key={note._id}
                        color={Color(note.color).alpha(0.1).hexa()}
                        onDelete={() => {
                          handleDeleteNote(note._id, true);
                          setSelectedNote(note);
                        }}
                        onShare={() => {
                          setSelectedNote(note);
                        }}
                        onEdit={() => {
                          setNoteModalOpen(true);
                          setSelectedNote(note);
                        }}
                      />
                    ))}
                  </NotesGrid>
                )}
              </div>
            </>
          )}
        </div>
        <BaseDialog dialogClassName="max-w-3xl" isOpen={noteModalOpen} onClose={() => setNoteModalOpen(false)}>
          <SessionNoteModal
            expertOrUser={expertOrUserName}
            sessionId={state?._id as string}
            note={selectedNote}
            onClose={() => setNoteModalOpen(false)}
          />
        </BaseDialog>

        <BaseDialog animationType="fade" dialogClassName="max-w-2xl" isOpen={markAsDoneOpen} onClose={() => setMarkAsDoneOpen(false)}>
          <MarkSessionAsDoneDialog
            expertOrUserName={`${expertOrUserName?.first_name} ${expertOrUserName?.last_name}`}
            onClose={() => setMarkAsDoneOpen(false)}
            session={selectedSession as TSessionWithProgram}
          />
        </BaseDialog>

        <BaseDialog
          style={{ background: "none", height: 500, boxShadow: "none" }}
          animationType="fade"
          dialogClassName="max-w-2xl bg-none"
          isOpen={reschedule}
          onClose={() => setReschedule(false)}
        >
          <ReschedulePopup
            session={state as TSessionWithProgram}
            onSuccess={(session) => {
              setState(session);
              setHighlighted(true);
            }}
            onClose={() => setReschedule(false)}
            calendar={expertCalendar as ICalendarAccount}
          />
        </BaseDialog>
        <BaseDialog dialogClassName="max-w-md" isOpen={confirmNoShow} onClose={() => setConfirmNoShow(false)}>
          <Box dir="ltr" elevation={0} paddingPreset="card" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
            <Typography variant="h6" className="mb-6 font-normal" as="h2">
              Are you sure {""}
              {roleState?.activeRole === "User" ? (
                <strong>
                  {session?.expert?.first_name} {session?.expert?.last_name}
                </strong>
              ) : (
                <strong>
                  {session?.user?.first_name} {session?.user?.last_name}
                </strong>
              )}{" "}
              did not attend this session?
            </Typography>
            <Stack justifyContent="flex-end" gap={3}>
              <Button kind="ghost" onClick={() => setConfirmNoShow(false)}>
                Dismiss
              </Button>
              <Button onClick={() => onSetNoShowStatus()}>Yes, mark as No Show</Button>
            </Stack>
          </Box>
        </BaseDialog>
        <ConfirmationDialog
          isOpen={deleteNoteConfirmationOpen}
          onClose={() => setDeleteNoteConfirmationOpen(false)}
          headerText="Delete note"
          footerComponent={() => {
            return (
              <Stack justifyContent="flex-end" gap={2}>
                <Button kind="ghost" onClick={() => setDeleteNoteConfirmationOpen(false)}>
                  Cancel
                </Button>
                <Button
                  kind="danger"
                  onClick={() => {
                    handleDeleteNote(selectedNote?._id as string, false);
                  }}
                >
                  Delete note
                </Button>
              </Stack>
            );
          }}
        >
          <Typography>Are you sure you want to delete the note?</Typography>
          <Typography variant="subtitle2" color="muted" className="mt-4">
            Any shared notes will be deleted and will not be visible to shared participants.
          </Typography>
        </ConfirmationDialog>
      </div>
    </>
  );
};

export default SessionDetailsDrawer;

const DetailCard = ({ title, value, highlighted }: { title: string; value: string | ReactNode; highlighted?: boolean }) => {
  return (
    <div>
      <Typography variant="subtitle2" color="muted">
        {title}
      </Typography>
      <Typography
        style={
          highlighted
            ? {
                textDecorationStyle: "dotted",
                textDecorationLine: "underline"
              }
            : {}
        }
        variant="body1"
        fontWeight="semibold"
      >
        {value}
        {highlighted && <SVG name="Checkmark" fill="#00a085" className="" width={14} style={{ float: "right" }} />}
      </Typography>
    </div>
  );
};

const NotesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 8px;
`;
