import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Failure } from "../components/ToastNotification/Failure";
import { Success } from "../components/ToastNotification/Success";
import { useUser } from "../features/User/userQueries";
import { CronofyService } from "../services/Scheduling/CronofyService";

export const CronofyAggregator = () => {
  const history = useHistory();
  const location = useLocation();
  const { refetch } = useUser();
  useEffect(() => {
    try {
      const returnQueryString = location.search.split("=")[1];
      const accessTokenCode = returnQueryString.split("&state")[0];
      // request access Token with retrieved code
      postAccessTokenCode(accessTokenCode);
    } catch (error) {
      console.log(error);
      history.push("/calendar");
    }
  }, []);

  /**
   * Request access Token with retrieved code
   * @param {*} code
   */
  function postAccessTokenCode(code) {
    CronofyService.postAccessTokenCode(code)
      .then((res) => {
        if (res.data?.error?.entity?.error === "invalid_grant") {
          // start authorization over again
          Failure(
            "Unable to complete authorization with your calendar. Go back and Select your calendar again.",
            "Please contact support if the problem persists."
          );
        } else {
          if (res.data.status === true) {
            refetch();
            const routeBack = localStorage.getItem("calendarConnectCallback");
            if (routeBack) {
              window.location.replace(routeBack);
              localStorage.removeItem("calendarConnectCallback");
            } else {
              history.push("/calendar");
            }
            Success("Calendar Linked Successfully.");
          }
        }
      })
      .catch((e) => {
        Failure(
          "Something went please try selecting your calendar again.",
          "Please contact support if the problem persists."
        );
        console.log(e);
        const routeBack = localStorage.getItem("calendarConnectCallback");
        if (routeBack) {
          window.location.replace(routeBack);
          localStorage.removeItem("calendarConnectCallback");
        } else {
          history.push("/calendar");
        }
      });
  }

  return (
    <div className="text-xs text-skin-accent p-4">
      {" "}
      Will redirect automatically...{" "}
    </div>
  );
};
