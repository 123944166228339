import { Analytics } from "../../../../pages/mentoring/hrDashboard/Analytics/Analytics";
import { IProgram } from "../../ProgramTypes";

interface IProps {
  program: IProgram;
}

const HrProgramDashboardAnalytics = ({ program }: IProps) => {
  return (
    <div dir="ltr" className="relative h-full py-6">
      <Analytics Program={program} />
    </div>
  );
};

export default HrProgramDashboardAnalytics;
