export const LoadMore = ({ onLoad, showHide = true }) => {
  return (
    <>
      {showHide && (
        <div className="flex items-center justify-center mt-4">
          <div
            onClick={() => onLoad()}
            className="py-2 px-4 text-xs bg-gray-200 hover:bg-gray-300 rounded-lg font-semibold cursor-pointer"
          >
            Load more
          </div>
        </div>
      )}
    </>
  );
};
