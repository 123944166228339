import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  updateNewProgram,
  getProgram
} from "../../../../services/dashboard/hrDashboard.services";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import HeadingSmall from "../../../../components/heading/heading-small";
import { ConfirmationModal } from "../../../../components/modal/ConfirmationModal";
import { KnackSpinnerShowHideState } from "../../../../StateManagement/zustand";
import { ProgramState } from "../../../../StateManagement/ProgramCreation";
import { ProgramOverview } from "../../../mentoring/hrDashboard/ProgramV3/Program/Components/Sections/ProgramOverview";
import { UploadBanner } from "../../../mentoring/hrDashboard/ProgramV3/Program/Components/Sections/UploadBanner";
import { ExpertSkills } from "../../../mentoring/hrDashboard/ProgramV3/Program/Components/Sections/ExpertSkills";
import { Goals } from "../../../mentoring/hrDashboard/ProgramV3/Program/Components/Sections/Goals";
import { Agreement } from "../../../mentoring/hrDashboard/ProgramV3/Program/Components/Sections/Agreement";
const checkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-5 h-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);
const EditCoachingProgram = () => {
  const { id } = useParams();
  const history = useHistory();
  const { program, setProgram, resetProgram } = ProgramState();

  const [allProgramSections] = useState([
    ProgramOverview,
    UploadBanner,
    ExpertSkills,
    Goals,
    Agreement
  ]);
  const [currentSection, setSection] = useState(0);

  const { setIsopen } = KnackSpinnerShowHideState();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [backConfirm, setBackConfirm] = useState(false);
  const [statusAndExit, setstatusAndExit] = useState();
  const [timelines] = useState([
    {
      name: "Overview",

      title: "Overview",
      subTitle: "Input the details of the program for all participants to view",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          stroke="currentColor"
          viewBox="0 0 35 39.054"
        >
          <g id="writing" transform="translate(-26.57)">
            <g
              id="Group_492"
              data-name="Group 492"
              transform="translate(26.57)"
            >
              <g id="Group_491" data-name="Group 491">
                <path
                  id="Path_586"
                  data-name="Path 586"
                  d="M125.223,201.526A1.525,1.525,0,0,0,123.7,200H107.985a1.526,1.526,0,1,0,0,3.051H123.7A1.525,1.525,0,0,0,125.223,201.526Z"
                  transform="translate(-100.365 -184.745)"
                />
                <path
                  id="Path_587"
                  data-name="Path 587"
                  d="M107.985,280a1.526,1.526,0,1,0,0,3.051h9.543a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-100.365 -258.643)"
                />
                <path
                  id="Path_588"
                  data-name="Path 588"
                  d="M37.774,36h-5.1a3.054,3.054,0,0,1-3.051-3.051V6.1a3.054,3.054,0,0,1,3.051-3.051H51.427A3.054,3.054,0,0,1,54.479,6.1v9.382a1.526,1.526,0,1,0,3.051,0V6.1a6.109,6.109,0,0,0-6.1-6.1H32.672a6.109,6.109,0,0,0-6.1,6.1V32.951a6.109,6.109,0,0,0,6.1,6.1h5.1a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-26.57)"
                />
                <path
                  id="Path_589"
                  data-name="Path 589"
                  d="M260.575,273.363a4.581,4.581,0,0,0-6.471,0l-8.375,8.357a1.526,1.526,0,0,0-.382.637l-1.824,6a1.525,1.525,0,0,0,1.867,1.914l6.157-1.706a1.525,1.525,0,0,0,.67-.39l8.358-8.342A4.582,4.582,0,0,0,260.575,273.363Zm-10.229,12.372-3.1.858.907-2.985,5.651-5.639,2.158,2.158Zm8.073-8.058-.3.295-2.157-2.157.295-.294a1.526,1.526,0,0,1,2.158,2.156Z"
                  transform="translate(-226.914 -251.275)"
                />
                <path
                  id="Path_590"
                  data-name="Path 590"
                  d="M123.7,120H107.985a1.526,1.526,0,1,0,0,3.051H123.7a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-100.365 -110.847)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },

    {
      name: "Banner",
      // contentRef: useRef(),
      title: "Banner",
      subTitle: "Insert an image attributed to this program",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      )
    },
    {
      name: "Topics",

      title: "Topics",
      subTitle: "Select the areas or topics of interest for this program",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          stroke="currentColor"
          viewBox="0 0 28.001 28.001"
        >
          <g id="pencil" transform="translate(-0.001)">
            <g
              id="Group_494"
              data-name="Group 494"
              transform="translate(0.001 0)"
            >
              <path
                id="Path_591"
                data-name="Path 591"
                d="M26.328,18.333a5.676,5.676,0,0,0-5.674-1.408l-2-2L27.39,6.191a2.092,2.092,0,0,0,0-2.958L24.768.613a2.092,2.092,0,0,0-2.958,0L13.076,9.347l-2-2A5.665,5.665,0,0,0,3.38.5a.547.547,0,0,0-.166.887L5.823,3.992,3.978,5.836,1.369,3.227a.547.547,0,0,0-.887.166,5.665,5.665,0,0,0,6.853,7.7l2,2-6.69,6.69A.552.552,0,0,0,2.511,20L.13,27.181a.547.547,0,0,0,.691.691L8,25.493a.659.659,0,0,0,.223-.135l6.69-6.69,2,2a5.665,5.665,0,0,0,7.7,6.853.547.547,0,0,0,.166-.887l-2.609-2.609,1.845-1.845,2.609,2.609a.547.547,0,0,0,.887-.166,5.665,5.665,0,0,0-1.179-6.289ZM22.584,1.386a1,1,0,0,1,1.411,0l2.621,2.621a1,1,0,0,1,0,1.411L24.842,7.193,20.81,3.161ZM7.867,10.076a.547.547,0,0,0-.58-.125,4.573,4.573,0,0,1-6.07-5.33L3.592,7a.547.547,0,0,0,.773,0L6.983,4.378a.547.547,0,0,0,0-.773L4.608,1.23A4.573,4.573,0,0,1,9.938,7.3a.547.547,0,0,0,.125.58l2.24,2.24-2.2,2.2ZM1.51,26.492,2.3,24.1l1.6,1.6Zm3.549-1.176L2.687,22.943l.584-1.763,3.552,3.552ZM7.836,24.2,6.207,22.569l5.353-5.353a.547.547,0,0,0-.773-.773L5.433,21.8,3.8,20.166,20.036,3.934l.044.044,1.585,1.585-7.383,7.383a.547.547,0,1,0,.773.773l7.383-7.383,1.629,1.629Zm18.936-.8L24.4,21.019a.547.547,0,0,0-.773,0l-2.618,2.618a.547.547,0,0,0,0,.773l2.375,2.375a4.573,4.573,0,0,1-5.33-6.07.547.547,0,0,0-.125-.58l-2.24-2.24,2.2-2.2,2.24,2.24a.547.547,0,0,0,.58.125,4.573,4.573,0,0,1,6.07,5.33Z"
                transform="translate(-0.001 0)"
              />
              <g
                id="Group_493"
                data-name="Group 493"
                transform="translate(12.382 14.528)"
              >
                <path
                  id="Path_592"
                  data-name="Path 592"
                  d="M226.945,266.743a.547.547,0,1,1,.536-.44A.552.552,0,0,1,226.945,266.743Z"
                  transform="translate(-226.399 -265.651)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },
    {
      name: "Goals",

      title: "Goals",
      subTitle: "Select the goals for this program",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          stroke="currentColor"
          viewBox="0 0 28.001 28.001"
        >
          <g id="pencil" transform="translate(-0.001)">
            <g
              id="Group_494"
              data-name="Group 494"
              transform="translate(0.001 0)"
            >
              <path
                id="Path_591"
                data-name="Path 591"
                d="M26.328,18.333a5.676,5.676,0,0,0-5.674-1.408l-2-2L27.39,6.191a2.092,2.092,0,0,0,0-2.958L24.768.613a2.092,2.092,0,0,0-2.958,0L13.076,9.347l-2-2A5.665,5.665,0,0,0,3.38.5a.547.547,0,0,0-.166.887L5.823,3.992,3.978,5.836,1.369,3.227a.547.547,0,0,0-.887.166,5.665,5.665,0,0,0,6.853,7.7l2,2-6.69,6.69A.552.552,0,0,0,2.511,20L.13,27.181a.547.547,0,0,0,.691.691L8,25.493a.659.659,0,0,0,.223-.135l6.69-6.69,2,2a5.665,5.665,0,0,0,7.7,6.853.547.547,0,0,0,.166-.887l-2.609-2.609,1.845-1.845,2.609,2.609a.547.547,0,0,0,.887-.166,5.665,5.665,0,0,0-1.179-6.289ZM22.584,1.386a1,1,0,0,1,1.411,0l2.621,2.621a1,1,0,0,1,0,1.411L24.842,7.193,20.81,3.161ZM7.867,10.076a.547.547,0,0,0-.58-.125,4.573,4.573,0,0,1-6.07-5.33L3.592,7a.547.547,0,0,0,.773,0L6.983,4.378a.547.547,0,0,0,0-.773L4.608,1.23A4.573,4.573,0,0,1,9.938,7.3a.547.547,0,0,0,.125.58l2.24,2.24-2.2,2.2ZM1.51,26.492,2.3,24.1l1.6,1.6Zm3.549-1.176L2.687,22.943l.584-1.763,3.552,3.552ZM7.836,24.2,6.207,22.569l5.353-5.353a.547.547,0,0,0-.773-.773L5.433,21.8,3.8,20.166,20.036,3.934l.044.044,1.585,1.585-7.383,7.383a.547.547,0,1,0,.773.773l7.383-7.383,1.629,1.629Zm18.936-.8L24.4,21.019a.547.547,0,0,0-.773,0l-2.618,2.618a.547.547,0,0,0,0,.773l2.375,2.375a4.573,4.573,0,0,1-5.33-6.07.547.547,0,0,0-.125-.58l-2.24-2.24,2.2-2.2,2.24,2.24a.547.547,0,0,0,.58.125,4.573,4.573,0,0,1,6.07,5.33Z"
                transform="translate(-0.001 0)"
              />
              <g
                id="Group_493"
                data-name="Group 493"
                transform="translate(12.382 14.528)"
              >
                <path
                  id="Path_592"
                  data-name="Path 592"
                  d="M226.945,266.743a.547.547,0,1,1,.536-.44A.552.552,0,0,1,226.945,266.743Z"
                  transform="translate(-226.399 -265.651)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },

    {
      name: "Agreement",

      title: "Agreement",
      subTitle:
        "Insert the guidelines for all participants to agree to in advance of the program",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
          />
        </svg>
      )
    }
  ]);
  async function fetchProgram(id) {
    try {
      const {
        data: { data: program }
      } = await getProgram(id, "Coaching");

      setProgram(program);
    } catch (error) {
      console.log(error);
      Failure("Oops! something went wrong. Please contact support");
    }
  }
  useEffect(() => {
    if (id) {
      fetchProgram(id);
    }
    return () => resetProgram(); // reset program to default
  }, [id]);

  function onHandleClick(status, willExit) {
    setOpenConfirm(true);
    setstatusAndExit({ status, willExit });
  }

  function uploadProgram(status = "Published", willExit = false) {
    setOpenConfirm(false);
    const _program = { ...program };
    let isValid = false;

    if (status === "Published") {
      _program.invite = true;
      _program.isClosed = true;
      isValid = validateProgram("full");
    } else if (status === "Pending") {
      // if we save as draft then bypass validation
      isValid = true;
      if (_program.end_date && _program.start_date) {
        isValid = true;
      } else {
        Failure("please select a date");
        return;
      }
      if (new Date(_program.end_date) >= new Date(_program.start_date)) {
        isValid = true;
      } else {
        Failure("Please select a valid date range");
        return;
      }
    }
    if (!isValid) {
      setOpenConfirm(false);
      if (new Date(_program.end_date) >= new Date(_program.start_date)) {
        if (_program.skills.length >= 3) {
          Failure("Please Complete All Fields to Publish Program");
        } else {
          Failure("Please add at least 3 topics");
        }
      } else {
        Failure("Please select a valid date range");
      }
      return;
    }
    _program.status = status;
    setIsopen(true);

    // is not new program and has id - should update
    if (_program._id) {
      updateNewProgram(_program, `?platform=Coaching`)
        .then((res) => {
          Success("Program Updated Successfully!");
          setIsopen(false);
          history.push("/external-programs");
        })
        .catch((e) => {
          console.log(e);
          Failure("Something went wrong, Please try again");
          setIsopen(false);
        });
    }
    // is new program - should create
  }

  function handleNext() {
    setSection(currentSection + 1);
  }
  function validateProgram(sectionId) {
    const _program = { ...program };
    const contentTestContainer = document.createElement("div");
    contentTestContainer.innerHTML = _program.agreement;
    const textContent = contentTestContainer.textContent;
    const regex = /(<([^>]+)>)/gi;
    let programOverview = !!_program?.overview?.replace(regex, "").length;
    switch (sectionId) {
      case 0:
        return (
          _program.name &&
          programOverview &&
          _program.session_count > 0 &&
          _program.end_date &&
          _program.start_date &&
          new Date(_program.end_date) >= new Date(_program.start_date)
        );
      case 1:
        return _program.image_url && _program.image_url !== "";
      case 2:
        return _program.skills && _program.skills.length >= 3;
      case 3:
        return _program.goals && _program.goals.length > 0;

      case 4:
        return textContent && textContent !== "";
      case "full":
        return (
          _program.platform &&
          _program.name &&
          programOverview &&
          _program.session_count > 0 &&
          _program.end_date &&
          _program.start_date &&
          new Date(_program.end_date) >= new Date(_program.start_date) &&
          (_program.program_type === "Coaching"
            ? _program.coach_type
            : _program.program_type === "Mentoring") &&
          _program.skills &&
          _program.skills.length >= 3 &&
          _program.goals &&
          _program.goals.length > 0 &&
          // _program.mentors_csv && _program.mentors_csv != '' &&
          // _program.mentees_csv && _program.mentees_csv != '' &&
          textContent &&
          textContent !== ""
        );
      default:
        return true;
    }
  }

  function handlePrevious() {
    setSection(currentSection - 1);
  }
  return (
    <>
      <ConfirmationModal
        title={"Save Changes"}
        header={"Are you sure you want to save changes ?"}
        showDefaultButton={true}
        buttonFunction={() =>
          uploadProgram(statusAndExit.status, statusAndExit.willExit)
        }
        open={openConfirm}
        setOpen={setOpenConfirm}
        defaultButtonText={"Save"}
        width="250"
      />

      <ConfirmationModal
        title={"Leave Program"}
        header={"Are you sure you want to leave without saving this program?"}
        showDefaultButton={true}
        buttonFunction={() => history.goBack()}
        open={backConfirm}
        setOpen={setBackConfirm}
        defaultButtonText={"Leave without saving"}
        defaultButtonCancelText={"Stay on this page"}
        width="250"
      />
      {
        <button
          className="flex mb-2 flex-start"
          onClick={() => setBackConfirm(true)}
        >
          <div className="flex flex-row items-center w-auto p-2 text-xs bg-white rounded-lg shadow cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {" "}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            <span className="ml-2 uppercase"> BACK </span>
          </div>
        </button>
      }
      {/* Header */}
      <div className="flex justify-between my-4">
        <HeadingSmall
          title={timelines[currentSection]?.title}
          subHeading={timelines[currentSection]?.subTitle}
        />

        <div className="sticky top-0 z-50 flex items-center justify-end gap-4 mb-4 transition transform delay-350 duration-350 sm:mb-0">
          <button
            onClick={() => onHandleClick(program.status, true)}
            className="px-4 py-2 text-sm text-white border rounded-lg whitespace-nowrap bg-skin-accent"
          >
            Save Changes
          </button>
        </div>
        {/* } */}
      </div>
      <div className="flex flex-row h-full gap-4 mb-32 text-skin-base">
        {/* Program Creation Timeline */}
        <div className="hidden h-full p-4 sm:block" style={{ maxWidth: 200 }}>
          {/* Floating line */}

          {/* Timelines */}
          <div className="relative">
            <div className="h-full max-h-64 absolute w-1 bg-skin-light transform translate-x-3.5 z-1"></div>
            {timelines.map((t, i) => (
              <label
                onClick={() => {
                  setSection(i);
                  // t.contentRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
                }}
                className={`flex flex-row items-center mb-4 hover:bg-blue-50 cursor-pointer rounded-full pr-4`}
                key={i}
              >
                {/* Icons */}
                {validateProgram(i) && (
                  <>
                    <div
                      className={`rounded-full font-bold h-8 w-8 bg-white flex items-center justify-center shadow mr-4 z-10 bg-skin-green text-white `}
                    >
                      {checkIcon}
                    </div>
                    <div
                      className={`text-sm whitespace-nowrap text-green-600 font-bold`}
                    >
                      {t.name}
                    </div>
                  </>
                )}

                {!validateProgram(i) && (
                  <>
                    <div
                      className={`rounded-full font-bold h-8 w-8 bg-white flex items-center justify-center shadow mr-4 z-10 ${
                        currentSection === i ? "bg-skin-accent text-white" : ""
                      }`}
                    >
                      {i + 1}
                    </div>
                    <div
                      className={`text-sm whitespace-nowrap ${
                        currentSection === i ? "text-skin-accent font-bold" : ""
                      }`}
                    >
                      {t.name}
                    </div>
                  </>
                )}

                {/* <div className={`rounded-full font-bold h-8 w-8 bg-white flex items-center justify-center shadow mr-4 z-10 ${currentSection === i ? 'bg-skin-accent text-white' : (t.isValid) ? 'bg-skin-green text-white' : ''}`}>{!t.isvalid ? i + 1 : checkIcon}</div> */}
                {/* Name */}
                {/* <div className={`text-sm whitespace-nowrap ${currentSection === i ? 'text-skin-accent font-bold' : ''}`}>{t.name}</div> */}
              </label>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full h-full">
          <div className="px-6 py-4 bg-white rounded-lg shadow">
            {/* Sections */}
            <div className="my-4">
              {allProgramSections.map((SECTION, i) => (
                <div
                  key={"program_section_" + i}
                  className={`transition delay-300 ${
                    currentSection === i ? "block" : "hidden"
                  }`}
                >
                  {currentSection === i && (
                    <SECTION reference={timelines[i].contentRef} />
                  )}
                </div>
              ))}
            </div>

            {/* Navigations */}
            <div className="flex justify-between gap-4 p-2">
              <div>
                {currentSection > 0 && (
                  <button
                    onClick={() => handlePrevious()}
                    className="px-8 py-2 text-sm font-bold bg-gray-200 border rounded-lg text-skin"
                  >
                    Back
                  </button>
                )}
              </div>
              {currentSection < allProgramSections.length - 1 && (
                <button
                  onClick={() => handleNext()}
                  className="px-8 py-2 text-sm font-bold text-white border rounded-lg bg-skin-fill"
                >
                  Next
                </button>
              )}
              {currentSection === allProgramSections.length - 1 && (
                <button
                  onClick={() => onHandleClick(program.status, true)}
                  className="px-4 py-2 text-sm text-white border rounded-lg whitespace-nowrap bg-skin-accent"
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoachingProgram;
