import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

// getCoachingSatisfactionScore
const getCoachingSatisfactionScore = (query = "") => {
  return axios.get(API_URL + "sessions/satisfaction-score?type=" + query, {
    headers: authHeader()
  });
};

const getAllCoachClients = () => {
  return axios.get(API_URL + "v1/auth/clients", {
    headers: authHeader()
  });
};

// GET Get Sessions
const getSessions = (query = "") => {
  return axios.get(API_URL + "sessions/" + query, {
    headers: authHeader()
  });
};

// GET Sessions by page
const getSessionsByPage = (page) => {
  return axios.get(API_URL + "sessions/?page=" + page, {
    headers: authHeader()
  });
};

// POST Add Session
const postSession = (session) => {
  return axios.post(API_URL + "sessions/", session, {
    headers: authHeader()
  });
};

// POST Add Session
export const createSession = (session) => {
  return axios.post(API_URL + "sessions/", session, {
    headers: authHeader()
  });
};

// POST Add Session
export const createNewSession = (session) => {
  return axios.post(API_URL + "sessions/new", session, {
    headers: authHeader()
  });
};

// POST Update Session
export const markSession = (updates, sessionId, query = "") => {
  return axios.post(API_URL + "sessions/mark-complete/" + sessionId + query, updates, {
    headers: authHeader()
  });
};

// GET Get Tasks
const getTasks = (filter = "") => {
  return axios.get(API_URL + "sessions/tasks" + filter, {
    headers: authHeader()
  });
};

// POST Add Task
/**
 * "title":"Eat 500 apples",
 *  "session":"6088f936effa28001d3ddf7b",
 *  "user":"5ffd962b287f46001dec7475",
 * type":"session"
 *
 */
const createTask = (newTask) => {
  return axios.post(API_URL + "sessions/tasks", newTask, {
    headers: authHeader()
  });
};

const deleteTask = (task_id) => {
  return axios.get(API_URL + "sessions/tasks/_delete/" + task_id, {
    headers: authHeader()
  });
};

const notifyRecipient = ({ user, session }) => {
  return axios.post(
    API_URL + "sessions/notify",
    { user, session },
    {
      headers: authHeader()
    }
  );
};

const getEarningMetrics = () => {
  return axios.get(API_URL + "sessions/coach/earnings/count", {
    headers: authHeader()
  });
};

const getEarnings = () => {
  return axios.get(API_URL + "sessions/coach/earnings", {
    headers: authHeader()
  });
};

export const CoachServices = {
  getSessions,
  getCoachingSatisfactionScore,
  postSession,
  getTasks,
  createTask,
  deleteTask,
  notifyRecipient,
  getEarningMetrics,
  getEarnings,
  getSessionsByPage,
  getAllCoachClients
};
