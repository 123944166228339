import { Redirect, Route, Switch } from "react-router-dom";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login/LoginForm";
import { useUser } from "../features/User/userQueries";

const AuthRoutes = () => {
  const { data: user } = useUser({
    useErrorBoundary: false,
    retry: (retryCount, error) => {
      if (error === "Not Authenticated") {
        return false;
      }
      if (retryCount === 3) {
        return false;
      }
      return true;
    }
  });
  if (user) {
    return <Redirect to="/" />;
  }
  return (
    <Switch>
      {/* <Route exact path="/" component={Login} /> */}
      <Route exact path="/auth/login" component={Login} />
      <Route exact path="/auth/reset-password" component={ResetPassword} />
      <Route exact path="/auth/reset-password/:id" component={ResetPassword} />
      <Route exact path="/auth/forgot-password" component={ForgotPassword} />
      <Redirect to="/auth/login" />
    </Switch>
  );
};

export default AuthRoutes;
