import { USER_TYPES } from "../../App.constant";

export const MentoringAboutProfile = ({
  experience,

  about,
  job_function,
  position,
  management_level,
  entity,
  capacity,
  email,
  phone,
  roles = [],
  isKnackCoach
}) => {
  return (
    <div className="grid gap-4">
      {about && (
        <div className="grid gap-2">
          <div className="text-sm font-medium text-skin-muted">About</div>
          <div className="text-sm text-left">{about}</div>
        </div>
      )}
      {isKnackCoach && (
        <div className="grid gap-4">
          <div className="text-sm font-medium text-skin-muted">Experience</div>
          <div className="flex flex-row flex-wrap overflow-y-auto max-h-60">
            {experience?.map((t, i) => (
              <div
                key={i}
                className="flex flex-row items-center justify-between px-2 py-1 m-1 text-xs font-semibold bg-gray-300 rounded-full"
              >
                <div>{t}</div>
              </div>
            ))}
            {/* {other_relevant_certifications &&
              typeof other_relevant_certifications !== "string" &&
              other_relevant_certifications.map((t, i) => (
                <div
                  key={i}
                  className="flex flex-row items-center justify-between px-2 py-1 m-1 text-xs font-semibold bg-gray-300 rounded-full"
                >
                  <div>{t}</div>
                </div>
              ))}
            {other_relevant_certifications &&
              typeof other_relevant_certifications === "string" &&
              other_relevant_certifications.split(",").map((t, i) => (
                <div
                  key={i}
                  className="flex flex-row items-center justify-between px-2 py-1 m-1 text-xs font-semibold bg-gray-300 rounded-full"
                >
                  <div>{t}</div>
                </div>
              ))} */}
          </div>
        </div>
      )}

      {!isKnackCoach && (
        <div className="grid gap-4 mt-4 sm:grid-cols-2">
          {roles.length > 0 && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">
                {roles.length > 1 ? "Profiles" : "Profile"}
              </div>
              <div className="text-base font-bold capitalize">
                {roles.toString()}
              </div>
            </div>
          )}
          {job_function && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">
                Department
              </div>
              <div className="text-base font-bold">{job_function}</div>
            </div>
          )}
          {position && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">
                Position
              </div>
              <div className="text-base font-bold">{position}</div>
            </div>
          )}
          {management_level && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">
                Management Level
              </div>
              <div className="text-base font-bold">{management_level}</div>
            </div>
          )}
          {entity && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">Entity</div>
              <div className="text-base font-bold">{entity}</div>
            </div>
          )}
          {(roles.includes(USER_TYPES.MENTOR.toLowerCase()) ||
            roles.includes(USER_TYPES.COACH.toLowerCase())) && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">
                Capacity
              </div>
              <div className="text-base font-bold">{capacity || 0}</div>
            </div>
          )}
          {email && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">Email</div>
              <div className="text-base font-bold">{email}</div>
            </div>
          )}
          {phone && (
            <div className="">
              <div className="text-sm font-medium text-skin-muted">Phone</div>
              <div className="text-base font-bold">{phone}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
