import { Button, Typography } from "knack-ui";
import React, { useState } from "react";
import { useChangeUserProfileImage } from "../../features/User/userMutations";
import { useUser } from "../../features/User/userQueries";
// import { cuteLoadingMessages, getRandomInt } from "../../lib/constants";
import { isImageValid, supportedImageFormats } from "../../lib/utils";
import SVG from "../SVG/SVG";

interface IProps {
  locked?: boolean;
  onChange: (url: string) => void;
  image: string;
}

const ProfileImage = ({ locked, onChange, image }: IProps) => {
  const { data: user } = useUser();
  const [imageError, setImageError] = useState("");

  const { mutateAsync: uploadImage, isLoading } = useChangeUserProfileImage();

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageError("");
    try {
      if (e.target.files) {
        const url = await uploadImage({
          file: e.target.files[0],
          userId: user?._id
        });
        onChange(url);
      }
    } catch (reason) {
      setImageError("Something went wrong during the process");
    }
  };

  return (
    <div className="my-6">
      <div className="flex items-center justify-center my-6 ">
        {image === "" && (
          <div
            dir="ltr"
            className={`relative flex items-center justify-center bg-gray-100 rounded-full w-28 h-28 ${isLoading? 'opacity-70' : ''}`}
          >
            <SVG name="User" size={40} />
            <div className="absolute -bottom-2 -end-0.5">
              <input
                disabled={locked}
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => {
                  console.log(e);
                  setImageError("");
                  if (!isImageValid(e.target.value)) {
                    setImageError(
                      `Please select a valid image format,${supportedImageFormats.join(
                        ", "
                      )}`
                    );
                  }
                  handleUploadImage(e);
                }}
                style={{
                  opacity: 0,
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                  zIndex: 1
                }}
              />
              <Button isLoading={isLoading} variant="large" iconOnly shape="rounded">
                <SVG name="AddPhoto" />
              </Button>
            </div>
          </div>
        )}
        {image !== "" && (
          <div className={`${isLoading? 'opacity-70' : ''} relative flex rounded-full w-28 h-28`} dir="ltr">
            <img
              className="object-cover w-full h-full rounded-full"
              src={image}
              alt="your avatar"
            />
            <div className="absolute -bottom-2 -end-0.5">
              <input
                disabled={locked}
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => {
                  console.log(e);
                  setImageError("");
                  if (!isImageValid(e.target.value)) {
                    setImageError(
                      `Please select a valid image format,${supportedImageFormats.join(
                        ", "
                      )}`
                    );
                  }
                  handleUploadImage(e);
                }}
                style={{
                  opacity: 0,
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  cursor: "pointer",
                  zIndex: 1
                }}
              />
              <Button isLoading={isLoading} variant="large" iconOnly shape="rounded">
                <SVG name={locked ? "LockClosed" : "AddPhoto"} />
              </Button>
            </div>
          </div>
        )}
      </div>
      {locked && (
        <Typography textAlign="center" color="warning" variant="subtitle2">
          Sorry, image uploading is not authorized.
        </Typography>
      )}
      {imageError && (
        <Typography textAlign="center" color="warning" variant="subtitle2">
          {imageError}
        </Typography>
      )}
    </div>
  );
};

export default ProfileImage;
