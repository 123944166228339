import ExternalCoachingPrograms from "./Pages/ExternalCoachingPrograms";
import EditCoachingProgram from "./Pages/EditCoachingProgram";
import { Route, Switch, Redirect } from "react-router-dom";
import { EmploymentManagement } from "./Pages/EmploymentManagement";
import { CoachManagement } from "./Pages/CoachManagement";
import { Companies } from "./Pages/Companies";
import { CreateCompany } from "./Components/CreateCompany";
import { AllUsers } from "./Pages/AllUsers";
import { Matchmaking } from "./Pages/Matchmaking";
import { AssessmentFramework } from "./Pages/AssessmentFramework";
import { AssessmentTags } from "./Pages/AssessmentTags";
import { ResetPassword } from "../../Auth/ResetPassword";
import { EmployeeMatching } from "./Pages/EmployeeMatching";
import { ConnectedCalendars } from "./Pages/ConnectedCalendars";
import { ProgramMatchMaking } from "./Pages/ProgramMatchMaking";
import { useUser } from "../../../features/User/userQueries";
import AppLayout from "../../../components/Layout/AppLayout";
import AdminGoals from "./Pages/AdminGoals";
import { Teams } from "./Pages/Teams";
import AdminCoachProfile from "./Pages/AdminCoachProfile";
import { QAResources } from "../../../features/QAResources/UI/QAResources";
import HrCampaign from "../../../features/Campaign/ui/HrCampaigns";
import NewCampaign from "../../../features/Campaign/ui/NewCampaign";
import ViewCampaign from "../../../features/Campaign/ui/ViewCampaign";
import { AdminSessions } from "./Pages/AdminSessions";
import { Expert } from "./Pages/Expert";
import PaymentManagement from "../../../features/PaymentManagement/pages/Payments";
import ActiveUsers from "../../../features/ActiveUserList/pages/ActiveUsers";
import AnalyticsReportPage from "../../../features/AnalyticsReports/pages/AnalyticsReportPage";
import SessionsReportsPage from "../../../features/AnalyticsReports/pages/SessionsReportsPage";
import UserReportsPage from "../../../features/AnalyticsReports/pages/UserReportsPage";
import GoalsReportsPage from "../../../features/AnalyticsReports/pages/GoalsReportsPage";
import PairingReportPage from "../../../features/PairingsReport/pages/PairingReportPage";
import MiscellaneousReportPage from "../../../features/AnalyticsReports/pages/MiscellaneousReportPage";

export const Admin = () => {
  const { data: user } = useUser();

  return (
    <>
      <AppLayout>
        <Switch>
          <Route exact path="/external-programs" component={ExternalCoachingPrograms} />
          <Route exact path="/external-programs/:id/edit" component={EditCoachingProgram} />

          <Route exact path="/campaigns" component={HrCampaign} />
          <Route exact path="/new-campaign" component={NewCampaign} />
          <Route exact path="/campaigns/:id" component={ViewCampaign} />

          <Route exact path="/session" component={AdminSessions} />
          {user && user.account_type === "Admin" && <Route exact path="/coach" component={CoachManagement} />}

          {user && user.account_type === "Admin" && <Route exact path="/experts" component={Expert} />}
          <Route exact path="/connections/:id" component={AdminCoachProfile} />

          <Route exact path="/employees" component={EmploymentManagement} />
          <Route exact path="/companies" component={Companies} />
          <Route exact path="/create-company" component={CreateCompany} />
          <Route exact path="/teams" component={Teams} />

          <Route exact path="/goals" component={AdminGoals} />
          <Route exact path="/external-matching" component={ProgramMatchMaking} />
          <Route exact path="/allusers" component={AllUsers} />
          <Route exact path="/matchmaking" component={Matchmaking} />
          <Route exact path="/assessment-collections" component={AssessmentFramework} />
          <Route exact path="/assessment-tags" component={AssessmentTags} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route exact path="/matching" component={EmployeeMatching} />
          <Route exact path="/reports" component={AnalyticsReportPage} />
          <Route exact path="/reports/sessions" component={SessionsReportsPage} />
          <Route exact path="/reports/user" component={UserReportsPage} />
          <Route exact path="/reports/goals" component={GoalsReportsPage} />
          <Route exact path="/reports/pairings" component={PairingReportPage} />
          <Route exact path="/reports/active-users" component={ActiveUsers} />
          <Route exact path="/reports/miscellaneous" component={MiscellaneousReportPage} />
          {user && user.account_type === "Admin" && <Route exact path="/reports/payment-management" component={PaymentManagement} />}

          <Route exact path="/qa-resources" component={QAResources} />

          <Route exact path="/connected-calendars" component={ConnectedCalendars} />
          <Redirect to="/" />
        </Switch>
      </AppLayout>
    </>
  );
};
