import { HiOutlineLocationMarker } from "react-icons/hi";

export const SESSION_BY_PROGRAM_TYPE = {
  Group_Coaching: [
    {
      name: "Team session",
      color: "",
      value: "Team session"
    },
    {
      name: "Chemistry Call",
      color: "",
      value: "Chemistry Call"
    }
  ],
  Coaching: [
    {
      name: "1:1 session",
      color: "",
      value: "1:1 session"
    },
    // {
    //   name: "Goal Setting",
    //   color: "",
    //   value: "Goal-setting Session"
    // },
    {
      name: "Chemistry Call",
      color: "",
      value: "Chemistry Call"
    }
  ],
  USER_EXTERNAL_COACHING: [
    {
      name: "1:1 session",
      color: "",
      value: "1:1 session"
    },
    // {
    //   name: "Goal Setting",
    //   color: "",
    //   value: "Goal-setting Session"
    // },
    {
      name: "Chemistry Call",
      color: "",
      value: "Chemistry Call"
    }
  ],
  Mentoring: [
    {
      name: "1:1 session",
      color: "",
      value: "1:1 session"
    },
    // {
    //   name: "Goal Setting",
    //   color: "",
    //   value: "Goal-setting Session"
    // },
    {
      name: "Chemistry Call",
      color: "",
      value: "Chemistry Call"
    }
  ]
};

export const SESSION_KEYS = {
  "Goal Setting": "Goal-setting Session",
  "Chemistry Call": "Chemistry Call",
  Coaching: "Coaching Session",
  Mentoring: "Mentoring Session",
  "1:1 session": "One-on-One session",
  "Team session": "Team session"
};

export const SESSION_TYPES = {
  GOAL_SETTING: "Goal Setting",
  CHEMISTRY_CALL: "Chemistry Call",
  COACHING: "Coaching",
  MENTORING: "Mentoring",
  ONE_ON_ONE_SESSION: "1:1 session",
  TEAM_SESSION: "Team session"
};

export const MEETING_DURATIONS = [
  {
    value: 30,
    title: "30 mins"
  },
  {
    value: 45,
    title: "45 mins"
  },
  {
    value: 60,
    title: "60 mins"
  },
  {
    value: 90,
    title: "90 mins"
  }
];

export const USER_ROLES = {
  EXPERT: "Expert",
  USER: "User"
};

export const USER_TYPES = {
  MENTOR: "Mentor",
  MENTEE: "Mentee",
  COACH: "Coach",
  COACHEE: "Coachee",
  EMPLOYEE: "Employee",
  CLIENT: "Clients"
};

export const USER_TYPES_PLURALS = {
  MENTORS: "Mentors",
  MENTEES: "Mentees",
  COACHES: "Coaches",
  COACHEES: "Coachees",
  EMPLOYEES: "Employees",
  CLIENTS: "Clients"
};

export const PROGRAM_TYPES = {
  MENTORING: "Mentoring",
  COACHING: "Coaching",
  EXTERNAL_COACHING: "External Coaching"
};

export const KNACK_SCHEDULER_SUB_ACCOUNT = "acc_624eb82a688d44005e32a1df";

export const COACH_TYPES = {
  KNACK_COACHING: "knack_coaching",
  OWN_COACH: "own_coach"
};

export const SESSION_COMPLETION_STATUS = {
  PENDING: "Pending",
  COMPLETED: "Completed"
};

export const LANGUAGES = [
  "English",
  "Arabic",
  // "Arabic or English",
  "Hindi",
  // "Hindi or English",
  "Spanish",
  "French",
  "Italian",
  "Greek",
  "Czech"
];

export const CONFERENCING_PROFILES = {
  google_meet: {
    type: "Google Meet",
    value: "google_meet",
    provider_name: "google",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 87.5 72" className="w-8">
        <path fill="#00832d" d="M49.5 36l8.53 9.75 11.47 7.33 2-17.02-2-16.64-11.69 6.44z" />
        <path fill="#0066da" d="M0 51.5V66c0 3.315 2.685 6 6 6h14.5l3-10.96-3-9.54-9.95-3z" />
        <path fill="#e94235" d="M20.5 0L0 20.5l10.55 3 9.95-3 2.95-9.41z" />
        <path fill="#2684fc" d="M20.5 20.5H0v31h20.5z" />
        <path
          fill="#00ac47"
          d="M82.6 8.68L69.5 19.42v33.66l13.16 10.79c1.97 1.54 4.85.135 4.85-2.37V11c0-2.535-2.945-3.925-4.91-2.32zM49.5 36v15.5h-29V72h43c3.315 0 6-2.685 6-6V53.08z"
        />
        <path fill="#ffba00" d="M63.5 0h-43v20.5h29V36l20-16.57V6c0-3.315-2.685-6-6-6z" />
      </svg>
    )
  },
  ms_teams: {
    type: "Microsoft Teams",
    value: "ms_teams",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-8" viewBox="-334.32495 -518.3335 2897.4829 3110.001">
        <path
          d="M1554.637 777.5h575.713c54.391 0 98.483 44.092 98.483 98.483v524.398c0 199.901-162.051 361.952-361.952 361.952h-1.711c-199.901.028-361.975-162-362.004-361.901V828.971c.001-28.427 23.045-51.471 51.471-51.471z"
          fill="#5059C9"
        />
        <circle r="233.25" cy="440.583" cx="1943.75" fill="#5059C9" />
        <circle r="336.917" cy="336.917" cx="1218.083" fill="#7B83EB" />
        <path
          d="M1667.323 777.5H717.01c-53.743 1.33-96.257 45.931-95.01 99.676v598.105c-7.505 322.519 247.657 590.16 570.167 598.053 322.51-7.893 577.671-275.534 570.167-598.053V877.176c1.245-53.745-41.268-98.346-95.011-99.676z"
          fill="#7B83EB"
        />
        <path
          d="M1244 777.5v838.145c-.258 38.435-23.549 72.964-59.09 87.598a91.856 91.856 0 01-35.765 7.257H667.613c-6.738-17.105-12.958-34.21-18.142-51.833a631.287 631.287 0 01-27.472-183.49V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z"
          opacity=".1"
        />
        <path
          d="M1192.167 777.5v889.978a91.802 91.802 0 01-7.257 35.765c-14.634 35.541-49.163 58.833-87.598 59.09H691.975c-8.812-17.105-17.105-34.21-24.362-51.833-7.257-17.623-12.958-34.21-18.142-51.833a631.282 631.282 0 01-27.472-183.49V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z"
          opacity=".2"
        />
        <path
          d="M1192.167 777.5v786.312c-.395 52.223-42.632 94.46-94.855 94.855h-447.84A631.282 631.282 0 01622 1475.177V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z"
          opacity=".2"
        />
        <path
          d="M1140.333 777.5v786.312c-.395 52.223-42.632 94.46-94.855 94.855H649.472A631.282 631.282 0 01622 1475.177V877.02c-1.246-53.659 41.198-98.19 94.855-99.52z"
          opacity=".2"
        />
        <path
          d="M1244 509.522v163.275c-8.812.518-17.105 1.037-25.917 1.037-8.812 0-17.105-.518-25.917-1.037a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003a288.02 288.02 0 01-16.587-51.833h258.648c52.305.198 94.657 42.549 94.856 94.854z"
          opacity=".1"
        />
        <path
          d="M1192.167 561.355v111.442a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003h190.228c52.304.198 94.656 42.55 94.855 94.854z"
          opacity=".2"
        />
        <path
          d="M1192.167 561.355v111.442a284.472 284.472 0 01-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003h190.228c52.304.198 94.656 42.55 94.855 94.854z"
          opacity=".2"
        />
        <path
          d="M1140.333 561.355v103.148c-104.963-24.857-191.679-98.469-233.25-198.003h138.395c52.305.199 94.656 42.551 94.855 94.855z"
          opacity=".2"
        />
        <linearGradient
          gradientTransform="matrix(1 0 0 -1 0 2075.333)"
          y2="394.261"
          x2="942.234"
          y1="1683.073"
          x1="198.099"
          gradientUnits="userSpaceOnUse"
          id="a"
        >
          <stop offset="0" stopColor="#5a62c3" />
          <stop offset=".5" stopColor="#4d55bd" />
          <stop offset="1" stopColor="#3940ab" />
        </linearGradient>
        <path
          d="M95.01 466.5h950.312c52.473 0 95.01 42.538 95.01 95.01v950.312c0 52.473-42.538 95.01-95.01 95.01H95.01c-52.473 0-95.01-42.538-95.01-95.01V561.51c0-52.472 42.538-95.01 95.01-95.01z"
          fill="url(#a)"
        />
        <path d="M820.211 828.193h-189.97v517.297h-121.03V828.193H320.123V727.844h500.088z" fill="#FFF" />
      </svg>
    )
  },
  zoom: {
    type: "Zoom",
    value: "zoom",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="w-8">
        <circle cx="24" cy="24" r="20" fill="#2196f3" />
        <path fill="#fff" d="M29,31H14c-1.657,0-3-1.343-3-3V17h15c1.657,0,3,1.343,3,3V31z" />
        <polygon fill="#fff" points="37,31 31,27 31,21 37,17" />
      </svg>
    )
  },
  explicit: {
    type: "Custom Link",
    value: "explicit",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
        />
      </svg>
    )
  },
  "face-2-face": {
    type: "In Person",
    value: "face-2-face",
    icon: <HiOutlineLocationMarker size={20} />
  }
};

export const completionStatusesColor = {
  Pending: "bg-yellow-100 text-red-700",
  Completed: "bg-green-100 text-green-700",
  Cancelled: "bg-red-100 text-red-700",
  "No Show": "bg-red-100 text-red-700"
};
