import { useState } from "react";
import SVG from "../SVG/SVG";
import { HeadlessModal } from "../modal/HeadlessModal";
import { Button, Stack, Typography } from "knack-ui";
import { useUser, useUserCompany } from "../../features/User/userQueries";
import { Information } from "../ToastNotification/Information";
import { Failure } from "../ToastNotification/Failure";
import { usePostAddUserPrompt } from "../../pages/dashboard/hrDashboard/Merger/PostAddUserInvitationPrompt";
import { KnackSpinner } from "../Asset/KnackSpinner";
import { programsQueryKeys } from "../../features/Programs/programQueries";
import { queryClient } from "../../lib/appConfig";
import { uploadFormData } from "../../services/base-api";
import { updateNewProgram } from "../../services/dashboard/hrDashboard.services";
import { Tooltip } from "../Tooltip/Tooltip";
import { ProgramParticipantsTab } from "../../pages/mentoring/hrDashboard/Programs/Participants";
import { useCSVReader } from "react-papaparse";
import { IProgram } from "../../features/Programs/ProgramTypes";
import { isValidEmail } from "../../lib/utils";
import { AddByEmailResponse, useInviteByEmail } from "../../features/Programs/programMutations";
import { PeopleTypes } from "../../features/People/PeopleTypes";
import { useRequestDelegatedAccess } from "../../features/Scheduler/schedulerMutation";
import sampleCSV from "../../pages/mentoring/hrDashboard/Programs/knacklab-cvs-sample.csv";

const checkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

interface Props {
  program: IProgram;
  type: PeopleTypes;
  openAddByEmailModal: VoidFunction;
}

export const InviteParticipantsCard = ({ program, type, openAddByEmailModal }: Props) => {
  const { CSVReader } = useCSVReader();
  const { openTab } = ProgramParticipantsTab();
  const [showSpinner, setShowSpinner] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState<Array<AddByEmailResponse>>([]);
  const [showModal, setShowModal] = useState(false);
  const { data: user } = useUser();
  const { data: company } = useUserCompany();
  const { mutateAsync: inviteByEmail } = useInviteByEmail();
  const { setProgramData, setUserType } = usePostAddUserPrompt();
  const { mutateAsync: requestDelegatedAccess } = useRequestDelegatedAccess();

  const handleOpenEmail = () => {
    if (program?.status === "Pending") {
      Information("Please create your program before inviting participants");
      return;
    }
    openAddByEmailModal();
  };

  const onUploadAccepted = async (results: any) => {
    const validEmails: Array<string> = [];

    results.data.forEach((row: any) => {
      row.forEach((cell: string) => {
        if (isValidEmail(cell)) {
          validEmails.push(cell);
        }
      });
    });

    console.log(validEmails);

    await onInviteByMail(validEmails);
  };

  async function onInviteByMail(mailArray: string[]) {
    if (mailArray && mailArray.length > 0) {
      try {
        const res = await inviteByEmail({
          programId: program._id,
          data: {
            emails: mailArray,
            type
          }
        });

        const filtered = res.data.filter((s: { status: boolean }) => s.status === false);

        if (!program?.isAutoEnrollment) {
          setUserType(type);
          setProgramData(program);
        }

        setShowModal(true);

        setVerifyEmail(filtered);
        queryClient.invalidateQueries(programsQueryKeys.all);

        const expert = ["coach", "mentor"];
        if (type && expert.includes(type)) {
          requestDelegatedAccess({
            emails: mailArray
          })
            .then(() => { })
            .catch((e) => {
              console.log(e);
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      Failure("No Emails to Add");
    }
  }

  return (
    <>
      {showModal && (
        <HeadlessModal
          width="w-full"
          open={showModal}
          setOpen={setShowModal}
          canDismissFromBackdrop={true}
          showDefaultButton={undefined}
          buttonFunction={undefined}
        >
          <div className="p-2 sm:p-6">
            {verifyEmail.length > 0 && (
              <div>
                <p className="text-lg font-bold text-center sm:text-xl text-skin-base">One or more emails could not be added</p>
              </div>
            )}
            {verifyEmail.length === 0 && (
              <div>
                <div className="z-10 flex items-center justify-center w-8 h-8 m-auto font-bold text-center text-white bg-white rounded-full shadow bg-skin-green ">
                  {checkIcon}
                </div>
                <Typography className="my-4" textAlign="center" variant="h6">
                  All Emails Added Successfully.
                </Typography>
                {!program.isAutoEnrollment && <Typography color="muted" textAlign="center">
                  Adding participants will not invite them to register for the program. You can invite them later at any point to enable
                  them to register for the program.
                </Typography>}
              </div>
            )}
            {
              <div className="flex flex-row flex-wrap min-h-full mt-5 mb-5 overflow-auto max-h-80 no-scrollbar">
                {verifyEmail.map(
                  (item, i) =>
                    item.email &&
                    !item.status && (
                      <Tooltip tooltipText={item.msg}>
                        <div
                          className={`${item.status ? "bg-skin-green" : "bg-skin-red"
                            } m-1 px-2 py-1 h-7 inline-flex items-center rounded-full text-sm text-white font-medium z-1`}
                          key={i}
                        >
                          {item.email}
                        </div>
                      </Tooltip>
                    )
                )}
              </div>
            }
            <Button fullWidth onClick={() => setShowModal(false)}>
              Dismiss
            </Button>
          </div>
        </HeadlessModal>
      )}

      {/* Spinner */}
      {showSpinner && (
        <div onClick={() => setShowSpinner(false)}>
          <KnackSpinner />
        </div>
      )}

      <div>
        <div className="flex flex-col items-center justify-center py-6 text-center text-skin-base">
          <label className="w-19">
            <div
              className="flex flex-row items-center justify-center gap-4 px-4 py-2 text-xs font-bold border rounded-lg cursor-pointer hover:bg-gray-100"
              onClick={handleOpenEmail}
            >
              <SVG name="Email" width="20px" height="20px" />
              <span className="whitespace-nowrap">Add by Email</span>
            </div>
          </label>
          {company?.mediaSupported && (
            <>
              {<p className="mt-4 text-xs">OR</p>}
              <CSVReader accept=".csv" onUploadAccepted={onUploadAccepted}>
                {({ getRootProps, acceptedFile, ProgressBar }: any) => (
                  <Stack direction="column" gap={4} alignItems="center">
                    <button
                      className="mt-4 w-19"
                      onClick={(e) => {
                        if (program?.status === "Pending") {
                          Information("Please create your program before inviting participants");
                          return;
                        }
                      }}
                    >
                      <div
                        {...getRootProps()}
                        className="flex flex-row items-center justify-center gap-4 px-4 py-2 text-xs font-bold border rounded-lg cursor-pointer hover:bg-gray-100"
                      >
                        <SVG name="Attachment" width="20px" height="20px" />
                        <span className="whitespace-nowrap">Upload CSV File</span>
                      </div>
                      <Stack alignItems="center" gap={4}>
                        <Typography>{acceptedFile && acceptedFile.name}</Typography>
                      </Stack>
                    </button>
                    <div className="flex flex-row flex-wrap items-center mt-2">
                      <a href={sampleCSV} className="text-xs text-green-500 border-none outline-none" download="./knacklab-cvs-sample.csv">
                        Download Sample
                      </a>
                    </div>
                    <div className="max-w-lg mt-2 text-xs font-medium text-center text-gray-500 px-4">
                      Upload a CSV file with email of participants
                    </div>
                    <ProgressBar style={{ backgroundColor: "rgb(17,51,186)" }} />
                  </Stack>
                )}
              </CSVReader>
            </>
          )}
        </div>
      </div>
    </>
  );
};
