import { AnimatePresence, Variants } from "framer-motion";
import { Typography } from "knack-ui";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useCreateUserProfile, useUpdateProfile } from "../../../../features/User/userMutations";
import { INewUserProfile } from "../../../../features/User/UserTypes";
import { NavbarHeight } from "../../../../lib/constants";
import { useOnboardingCounterStore } from "./OnboardingStepCounter";
import ProfileBasicInfo from "./ProfileBasicInfo";
import ProfileCompanyInfo from "./ProfileCompanyInfo";
import ProfileCompletion from "./ProfileCompletion";
import NewUserProfileImage from "./NewUserProfileImage";
import { useUser } from "../../../../features/User/userQueries";

export const ProfileCreationTransitions: Variants = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  exit: {
    y: 200,
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3
    }
  }
};

const NewUserProfile = () => {
  const { setStep, totalSteps, step } = useOnboardingCounterStore();

  const { mutateAsync: createProfile } = useCreateUserProfile();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const methods = useForm<INewUserProfile>({
    defaultValues: { profile_image: "", spoken_languages: ["English"] }
  });

  const { data: user } = useUser();
  const handleNextStep = () => {
    setStep(step + 1);
  };
  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCreateProfile: SubmitHandler<INewUserProfile> = async (data) => {
    try {
      if (user && !user.profile) {
        await createProfile(data);
      } else {
        await updateProfile(data);
      }
      setStep(step + 1);
    } catch (reason) {
      console.log(reason);
    }
  };

  return (
    <div style={{ height: `calc(100vh - ${NavbarHeight}px)` }}>
      <div className="container max-w-2xl py-6 mx-auto">
        {step !== totalSteps && (
          <div className="w-full py-6 mb-8 rounded-lg bg-primary text-primary">
            <Typography textAlign="center" className="mb-4" variant="h5" as="h2">
              Welcome to Knack!
            </Typography>
            <Typography textAlign="center">Please tell us a little about yourself before proceeding</Typography>
          </div>
        )}
        <FormProvider {...methods}>
          <AnimatePresence exitBeforeEnter>
            {step === 0 && <NewUserProfileImage key="image" handleNextStep={handleNextStep} />}

            {step === 1 && <ProfileBasicInfo key="basic" handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />}

            {step === 2 && (
              <ProfileCompanyInfo
                key="company"
                handleNextStep={handleNextStep}
                handlePreviousStep={handlePreviousStep}
                handleCreateProfile={handleCreateProfile}
              />
            )}
            {step === totalSteps && <ProfileCompletion key="success" />}
          </AnimatePresence>
        </FormProvider>
      </div>
    </div>
  );
};

export default NewUserProfile;
