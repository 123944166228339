import { SearchInput } from "../../../../components/Inputs/SearchInput";
import { PeopleTypes } from "../../../../features/People/PeopleTypes";
import { PeopleList, onSearchPeople } from "./PeopleList";

interface IProps {
  type: PeopleTypes;
}
export const PeopleListComponent = ({ type }: IProps) => {
  const FilterComponents = () => {
    const { onChange } = onSearchPeople();
    return (
      <div className="flex-row items-center hidden w-full gap-4 modify-program-select sm:flex">
        <div className="inline-block w-full mr-4">
          <SearchInput onChange={onChange} placeholder="Search email, name..." />
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <FilterComponents />
        <div className="py-4">
          <PeopleList type={type} />
        </div>
      </div>
    </>
  );
};
