import { Box, Button, Stack, Typography } from "knack-ui";
import { lazy } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../../../components/Layout/Grid";
import PageSkeleton from "../../../components/skeletons/PageSkeleton";
import {
  PageHeightWithoutHeader,
  PageTitleHeight
} from "../../../lib/constants";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../lib/utils";
import { useGetCampaigns } from "../campaignQueries";
import CampaignCard from "./CampaignCard";

const LazyPageList = lazy(() =>
  loadComponent(() => import("../../../components/PageList/PageList"))
);
const PageList = withSuspense(withErrorBoundary(LazyPageList), {
  fallBackComponent: <PageSkeleton />
});

const HrCampaign = () => {
  const history = useHistory();
  const { data: campaigns } = useGetCampaigns({ suspense: true });
  return (
    <div className="h-full overflow-hidden border rounded-lg">
      <Box
        as={Stack}
        square
        variant="outlined"
        elevation={0}
        alignItems="center"
        style={{
          borderTop: 0,
          borderRight: 0,
          borderLeft: 0,
          height: PageTitleHeight
        }}
        justifyContent="space-between"
        className="px-6 py-4"
      >
        <Typography as="h5" variant="h6">
          Campaigns
        </Typography>
        <Button onClick={() => history.push("/new-campaign")}>
          Create Campaign
        </Button>
      </Box>
      <div
        style={{
          height: PageHeightWithoutHeader,
          overflowY: "auto"
        }}
      >
        <PageList
          emptyStateProps={{
            title: "No campaigns found",
            buttonCb: () => history.push("/new-campaign"),
            buttonText: "Create your first campaign",
            icon: "Campaign",
            iconSize: 60
          }}
          listComponent={(children) => (
            <Grid
              gridTemplateColumns="grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
              gap="gap-4"
              children={children}
            />
          )}
        >
          {campaigns?.map((c) => (
            <CampaignCard campaign={c} key={c._id} />
          ))}
        </PageList>
      </div>
    </div>
  );
};

export default HrCampaign;
