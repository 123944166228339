import { Button, Stack, Typography } from "knack-ui";
import { useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { TSessionWithProgram } from "../../../../../features/Sessions/SessionsTypes";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  BROWSER_LOCALE,
  datePickerTimeIntervals,
  ISO8601FormattedDate
} from "../../../../../lib/constants";
import moment from "moment";
import { SelectMeetingDuration } from "../../../../../services/Scheduling/SessionLogging/components/session/SelectMeetingDuration";
import { RiTimer2Line } from "react-icons/ri";
import { ICalendarAccount } from "../../../../../features/Calendar/CalendarTypes";
import {
  useFetchCalendarEvents,
  useUpdateCalendarEvent,
  useUpdateSession
} from "../../../../../features/Sessions/sessionsMutations";
import { useLoadingSpinner } from "../../../../../hooks/useLoadingSpinner";
import { Information } from "../../../../../components/ToastNotification/Information";
import { Failure } from '../../../../../components/ToastNotification/Failure';

interface IProps {
  session: TSessionWithProgram;
  onSuccess: (session: TSessionWithProgram) => void;
  onClose: () => void;
  calendar: ICalendarAccount;
}

export const ReschedulePopup = ({
  session,
  onClose,
  onSuccess,
  calendar
}: IProps) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [duration, setDuration] = useState<number>(30);
  const { mutateAsync: updateCalendarEvent } = useUpdateCalendarEvent();
  const { mutateAsync: fetchCalendarEvents } = useFetchCalendarEvents();
  const { mutateAsync: updateSession } = useUpdateSession();
  const { setLoading } = useLoadingSpinner();

  async function onReschedule() {
    setLoading(true);
    const filter = `?only_managed=true&tzid=${calendar?.zoneinfo || "Asia/Dubai"
      }&from=${moment(session?.start_time)
        .subtract(30, "minutes")
        .toISOString()}`;

    try {
      const events = await fetchCalendarEvents({
        filter,
        scheduler_id: calendar?.sub as string,
        organizerUserId: (session.mentor || session.coach) as string
      });

      const foundEvent = events?.filter(
        (e) => e.event_id === session?.calendar_event_id
      )[0];

      if (foundEvent) {
        updateCalendarEvent({
          event: {
            end: moment(ISO8601FormattedDate(startDate || session?.start_time))
              .add(duration as number, "minutes")
              .toISOString(false),
            start: ISO8601FormattedDate(startDate || session?.start_time),
            summary: foundEvent.summary,
            event_id: foundEvent.event_id
          },
          scheduler_id: calendar?.sub as string,
          organizerUserId: (session.mentor || session.coach) as string
        })
          .then(async (e) => {
            setLoading(false);
            console.log(e);

            updateSession({
              sessionId: session._id as string,
              updates: {
                start_time: ISO8601FormattedDate(
                  startDate || session?.start_time
                ),
                end_time: moment(
                  ISO8601FormattedDate(startDate || session?.start_time)
                )
                  .add(duration as number, "minutes")
                  .toISOString(false),
                _id: session?._id
              }
            })
              .then((e) => {
                onSuccess({ ...e, program: session.program });
                onClose();
                Information("Meeting updated successfully");
              })
              .catch((e) => { });
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        Failure('Sorry this meeting cannot be rescheduled', 'You may delete / cancel this session then schedule a new one')
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Stack className="p-6 bg-white rounded-b-lg" direction="column" gap={4}>
      <Typography fontWeight="bold" className="text-2xl pl-20">
        Reschedule session
      </Typography>
      <div className="pt-2">
        <Stack direction="column" gap={4}>
          {/* <div className="flex flex-row items-center gap-4 relative">
            <div
              className="bg-gray-100 p-4 rounded-lg"
              style={{ maxWidth: "fit-content" }}
            >
              <MdOutlineTitle fill="#000" size={26} />
            </div>

            <Input placeholder="Meeting Agenda"
              defaultValue={}
            />
          </div> */}

          <div className="session-logging-datepicker">
            <DatePicker
              locale={BROWSER_LOCALE() as string}
              minTime={new Date(new Date().setHours(6, 0, 0, 0))}
              maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
              maxDate={moment().add(300, "days").toDate()}
              minDate={new Date()}
              // selected={startDate || (session?.start_time as Date)}
              selected={startDate}
              onChange={(date) => setStartDate(date as Date)}
              showTimeSelect
              timeFormat="p"
              timeIntervals={datePickerTimeIntervals}
              dateFormat="MMMM d, yyyy h:mm aa"
              className="w-full"
              customInput={
                <div className="flex flex-row items-center gap-4 relative">
                  <div
                    className="bg-gray-100 p-4 rounded-lg"
                    style={{ maxWidth: "fit-content" }}
                  >
                    <AiOutlineCalendar fill="#000" size={26} />
                  </div>

                  <Stack direction="column">
                    <div className="font-medium text-lg cursor-pointer border border-gray-1 p-4 rounded-lg">
                      {new Date(
                        startDate || (session?.start_time as Date)
                      ).toLocaleString("en-US", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        hour: "numeric",
                        minute: "numeric"
                      })}{" "}
                      <>
                        -{" "}
                        {moment(
                          new Date(startDate || (session?.start_time as Date))
                        )
                          .add(duration as number, "minutes")
                          .format("h:mm A")}
                      </>
                    </div>
                  </Stack>
                </div>
              }
            />
          </div>

          <div className="flex flex-row items-center gap-4 relative">
            <div
              className="bg-gray-100 p-4 rounded-lg"
              style={{ maxWidth: "fit-content" }}
            >
              <RiTimer2Line fill="#000" size={26} />
            </div>

            <SelectMeetingDuration
              duration={duration}
              onSelect={(value) => setDuration(value)}
            />
          </div>
        </Stack>
      </div>

      <Stack gap={4} direction="row" className="pl-20 pt-6">
        <Button kind="primary" variant="medium" onClick={() => onReschedule()}>
          Save
        </Button>
        <Button kind="ghost" variant="medium" onClick={() => onClose()}>
          Dismiss
        </Button>
      </Stack>
    </Stack>
  );
};
