import { ComponentType } from "react";
import { IUser, UserRoleState } from "../features/User/UserTypes";
import { TGoal } from "../features/Goals/goalsTypes";
import * as echarts from "echarts";
import { IProgram, IUserProgram } from "../features/Programs/ProgramTypes";
import { DefaultSectionTitle, DefaultQuestionTitle, SectionQuestion, SurveySection } from "../features/Surveys/surveyTypes";
import { ISession } from "../features/Sessions/SessionsTypes";
import moment from "moment";
import { format } from "date-fns";
import { IGoalsAnalytics } from "../features/AnalyticsReports/analyticsReportsTypes";

/**
 *  🌟 Calculates progress based on all completed tasks + challanges / total
 */
export const calculateGoalPercentage = (goal: TGoal) => {
  if (goal.challenges.length === 0 && goal.tasks.length === 0) return 0;
  const totalItems = goal.tasks.length + goal.challenges.length;
  if (totalItems === 0) {
    return 100;
  }
  const completedItemsCount =
    goal.tasks.filter((t) => t.completed === true).length + goal.challenges.filter((c) => c.completed === true).length;
  return +((completedItemsCount / totalItems) * 100).toFixed(0);
};

export const calculateGoalAnalyticsPercentage = (goal: IGoalsAnalytics) => {
  if (goal.goal_total_challenges === 0 && goal.goal_total_tasks === 0) return 0;
  const totalItems = goal.goal_total_tasks + goal.goal_total_challenges;
  if (totalItems === 0) {
    return 0;
  }
  const completedItemsCount = goal.goal_completed_tasks || 0 + goal.goal_completed_challenges || 0;
  if (completedItemsCount === 0) {
    return 0;
  }
  return +((completedItemsCount / totalItems) * 100).toFixed(0);
};

/**
 * 🚀 Calculate Input & Textarea height based on their content.
 */

export const calculateInputHeight = (el: HTMLInputElement | HTMLTextAreaElement) => {
  el.style.height = `${el.scrollHeight}px`;
};

/**
 * 🚀 Lazy component loader. Helps with catching `failed loading chunk X` errors and retry again.
 */

export const loadComponent = <T = ComponentType<any>>(callableElement: () => Promise<T>, attempts = 3): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem("page-has-been-force-refreshed") || "false");
    callableElement()
      .then((c) => {
        window.localStorage.setItem("page-has-been-force-refreshed", "false");
        resolve(c);
      })
      .catch((error) => {
        // if (!pageHasAlreadyBeenForceRefreshed) {
        //   // Assuming that the user is not on the latest version of the application.
        //   // Let's refresh the page immediately.
        //   window.localStorage.setItem("page-has-been-force-refreshed", "true");
        //   return window.location.reload();
        // }

        // // The page has already been reloaded
        // // Assuming that user is already using the latest version of the application.
        // // Let's let the application crash and raise the error.
        // throw error;

        setTimeout(() => {
          // after 3 attempts
          if (attempts === 1) {
            //  Check if we refreshed the page before in order to solve it.
            // throw the error for the debugger to catch if the error happens again
            if (pageHasAlreadyBeenForceRefreshed) {
              reject(new Error("Failed loading page, Please refresh your browser window", { cause: error }));
            } else {
              // Assuming that the user is not on the latest version of the application.
              // Let's refresh the page immediately.
              window.localStorage.setItem("page-has-been-force-refreshed", "true");
              window.location.reload();
              return;
            }
          }
          // We try again until attempts  === 1
          loadComponent(callableElement, attempts - 1).then(resolve, reject);
        }, 1000);
      });
  });
};

/**
 * 🚀 Gets user roles
 */

export const getUserRoles = (user: IUser): UserRoleState["roles"] => {
  const userRoles = [user.account_type.toLowerCase(), ...(user.roles || [])];
  const roles: UserRoleState["roles"] = [];
  if (userRoles.includes("hr")) {
    roles.push("Hr");
  }
  if (userRoles.includes("employee") || userRoles.includes("coachee") || userRoles.includes("mentee")) {
    roles.push("User");
  }
  if (userRoles.includes("coach") || userRoles.includes("mentor")) {
    roles.push("Expert");
  }
  return roles;
};

export const resizeObserver = new ResizeObserver((entries) => {
  entries.forEach(({ target }) => {
    const instance = echarts.getInstanceByDom(target as HTMLElement);
    if (instance) {
      instance.resize();
    }
  });
});
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isDev() {
  return process.env.NODE_ENV === "development";
}

export const programHasExpert = (program: IProgram | IUserProgram) => {
  return !(!program?.hasOwnProperty("expert") || program?.expert === null);
};

export const supportedImageFormats = ["png", "jpeg", "jpg"];
export const isImageValid = (image: string) => {
  const extension = image.split(".").pop();

  if (extension && !supportedImageFormats.includes(extension)) {
    return false;
  }
  return true;
};

export function deviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
}

export const getUniqueArrayOfObjects = (a: any[], b: any, compareKey: string) => {
  const mergedArray = [...a, ...b];
  const map: { [key: string]: any } = {};

  for (const element of mergedArray) {
    map[element[compareKey]] = element;
  }
  return Object.values(map);
};

export const validateSurveyComponents = (sections: SurveySection[]): SurveySection[] => {
  const isAllOptionsLabelEmpty = (
    options: {
      option_label: string;
    }[]
  ) => {
    return options.every((o) => o.option_label === "");
  };

  const getValidQuestionsFromSection = (section: SurveySection) => {
    const validQuestions: SectionQuestion[] = [];
    section.section_questions.forEach((q) => {
      if (q.question_type === "checkbox" || q.question_type === "multiple_choice") {
        // if all the options have no label then don't add the question
        if (!isAllOptionsLabelEmpty(q.options)) {
          validQuestions.push({
            ...q,
            title: q.title || DefaultQuestionTitle,
            // Only add options that have a label (One of the options might have an empty value)
            options: q.options.filter((o) => o.option_label !== "")
          });
        }
      } else if (q.question_type === "text" || q.question_type === "linear") {
        validQuestions.push({
          ...q,
          title: q.title || DefaultQuestionTitle
        });
      }
    });
    return validQuestions;
  };
  const validatedSections: SurveySection[] = [];
  sections.forEach((s, sectionIndex) => {
    if (sectionIndex === 0) {
      // if the first section (main section) doesn't have a title, then add a default title
      validatedSections.push({
        ...s,
        section_title: s.section_title || "Untitled survey",
        section_questions: getValidQuestionsFromSection(s)
      });
    } else {
      // Section is not the main one (sectionIndex > 0)
      // only show the section if it has questions
      if (s.section_questions.length > 0) {
        validatedSections.push({
          ...s,
          section_title: s.section_title || DefaultSectionTitle,
          section_questions: getValidQuestionsFromSection(s)
        });
      }
    }
  });
  return validatedSections;
};

export function range(start: number, end: number) {
  const list = [];
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
  return list;
}
export function normalizeLinkedinLink(link: string) {
  if (link.toLowerCase().startsWith("https://")) {
    return link;
  }
  return `https://${link}`;
}

export function canMarkSessionAsDone(s: ISession, user: IUser) {
  if (s.completion_status === "Pending") {
    if ((s?.program as IProgram)?.coach_type === "knack_coaching" && user?.account_type === "Coach") {
      return true;
    } else if ((s?.program as IProgram)?.coach_type !== "knack_coaching" && user?.account_type !== "Coach") {
      return true;
    }
  }

  return false;
}

export function canReschedule(start_time: Date) {
  const isPast = moment(start_time).isBefore(moment());
  const diff = moment(start_time).diff(moment(), "days");
  const isDateBeyondMinimum = Math.abs(diff) > 30 && isPast;
  if (isDateBeyondMinimum) {
    return false;
  }

  return true;
}

export function canEnableNoShow(coach_type: IProgram["coach_type"], account_type: IUser["account_type"]) {
  if (coach_type === "knack_coaching" && account_type !== "Coach") {
    return false;
  }

  return true;
}

export function pluralize(count: number, word: string) {
  return count === 1 ? word : `${word}s`;
}

export const getCurrentTimezoneDiff = (f: string) => {
  const diff = format(new Date(), f);
  return diff;
};

export const getUniqueValuesWithCase = (arr: any[], caseSensitive: boolean) => {
  let temp: string[] = [];
  return [
    ...(new Set(
      caseSensitive
        ? arr
        : // eslint-disable-next-line array-callback-return
          arr.filter((x) => {
            let _x = typeof x === "string" ? x.toLowerCase() : x;
            if (temp.indexOf(_x) === -1) {
              temp.push(_x);
              return x;
            }
          })
    ) as any)
  ];
};

export const isEmail = (email: string) => {
  return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
};

export const isValidEmail = (email: string) => {
  const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
};
