import React, { lazy } from "react";
import { withErrorBoundary } from "../../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../../lib/utils";
import DashboardStatsSkeleton from "../../../UserDashboard/skeletons/DashboardStatsSkeleton";
import { Coaching } from "../hrPeople/Coaching";
import { MentoringPeople } from "../hrPeople/MentoringPeople";

const LazyPeopleMetrics = lazy(() =>
  loadComponent(() => import("../../../../V3/Pages/People/PeopleMetrics"))
);
const PeopleMetrics = withErrorBoundary(
  withSuspense(LazyPeopleMetrics, {
    fallBackComponent: <DashboardStatsSkeleton count={4} />
  })
);

export const HRPeopleV2 = () => {
  const [openTab, setOpenTab] = React.useState(1);

  return (
    <div className="pb-4">
      <PeopleMetrics />

      <div className="flex flex-row items-center justify-between w-full h-full my-2 mt-6">
        <div className="grid h-full grid-flow-row grid-cols-2 bg-white rounded-lg shadow">
          <div
            className={
              "my-1 whitespace-nowrap mx-1 px-6 py-3 text-center cursor-pointer text-base font-medium " +
              (openTab === 1 ? "text-white bg-skin-fill rounded-lg" : "")
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
          >
            Coaching
          </div>
          <div
            className={
              "my-1 whitespace-nowrap mx-1 px-6 py-3 text-center cursor-pointer text-base font-medium " +
              (openTab === 2 ? "text-white bg-skin-fill rounded-lg" : "")
            }
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
          >
            Mentoring
          </div>
        </div>
      </div>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words">
        <div className="flex-auto py-5">
          <div className="tab-content tab-space">
            {openTab === 1 && <Coaching />}
            {openTab === 2 && <MentoringPeople />}
          </div>
        </div>
      </div>
    </div>
  );
};
