import { Box, Button, Input, Stack, Typography } from "knack-ui";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DeleteButton from "../../../../components/DeleteButton/DeleteButton";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import KnackSelect from "../../../../components/KnackSelect/KnackSelect";
import SVG from "../../../../components/SVG/SVG";
import { Success } from "../../../../components/ToastNotification/Success";
import { useSend360Invite } from "../../../../features/Assessment/assessmentMutations";
import { useGet360Invites } from "../../../../features/Assessment/assessmentQueries";
import { ReviewerTypes } from "../../../../features/Assessment/AssessmentTypes";
import { emailRegex } from "../../../../lib/constants";

const reviewerTypes = [
  { label: "Direct Report" },
  { label: "Manager" },
  { label: "Peer" },
  { label: "Worked with" }
] as const;

interface IProps {
  handleClose: VoidFunction;
}

interface FormProps {
  email: string;
  type: ReviewerTypes;
}

const RequestFeedbackDialog = ({ handleClose }: IProps) => {
  const [invites, setInvites] = useState<FormProps[]>([]);
  const {
    register,
    handleSubmit,
    control,
    setError,
    resetField,
    formState: { errors }
  } = useForm<FormProps>({ defaultValues: { type: "Direct Report" } });

  const { data: existingInvitations } = useGet360Invites();
  const { mutateAsync: sendInvites } = useSend360Invite();
  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      const exists = invites.find((i) => i.email === data.email);
      const sentPrev = existingInvitations?.find(
        (i) => i.reviewer_email === data.email
      );
      if (!exists && !sentPrev) {
        setInvites((prev) => [...prev, data]);
        resetField("email");
        resetField("type");
      } else {
        setError("email", { message: "Email Already Added" });
      }
    } catch (error) {}
  };
  const handleDeleteInvite = (email: string) => {
    setInvites((prev) => prev.filter((i) => i.email !== email));
  };

  const handleSendRequests = async () => {
    try {
      await sendInvites(invites);
      Success("Invitations sent successfully");
      handleClose();
    } catch (error) {}
  };

  return (
    <Box
      dir="ltr"
      style={{ gridTemplateRows: "1fr" }}
      className="grid overflow-y-auto dialog-max-h"
    >
      <Button
        className="z-10 end-6 top-6"
        style={{ position: "absolute" }}
        onClick={handleClose}
        kind="ghost"
        variant="small"
        iconOnly
      >
        <SVG name="Close" />
      </Button>
      <Stack className="dialog-content-max-h scrollhost2">
        <div className="grid flex-1 gap-4 p-8 pt-8">
          <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h6" as="h6">
              Request feedback from others
            </Typography>
            <Typography color="muted">
              Nominate 10~15 colleagues to fill out an anonymous feedback
            </Typography>
            <Stack direction="column" gap={3} className="">
              <Typography htmlFor="email" as="label" variant="body1">
                Reviewer work email
              </Typography>
              <Input
                id="email"
                inputPrefix={<SVG name="Email" />}
                {...register("email", {
                  required: "Required",
                  pattern: {
                    value: emailRegex,
                    message: "Please provide a valid email address"
                  }
                })}
                error={errors.email?.message}
              />
              <div className="px-2 py-1 text-xs rounded bg-primary bg-opacity-20 text-primaryDark">
                invited email gets a link to fill out an anonymous online
                feedback form
              </div>
            </Stack>
            <Stack direction="column" gap={3}>
              <Typography htmlFor="type" as="label" variant="body1">
                Reviewer type
              </Typography>
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <KnackSelect
                    id="type"
                    getOptionValue={(o: any) => o.label}
                    getOptionLabel={(o: any) => o.label}
                    options={reviewerTypes}
                    onChange={(o: any) => onChange(o.label)}
                    value={reviewerTypes.find((i) => i.label === value)}
                  />
                )}
              />
            </Stack>
            <Button type="submit">Add Reviewer</Button>
          </form>
          <div>
            <div className="grid grid-cols-2 gap-4 p-4">
              <Typography fontWeight="bold" variant="body1">
                Reviewer Email
              </Typography>
              <Typography fontWeight="bold" variant="body1">
                Reviewer type
              </Typography>
            </div>
            <div className="overflow-y-auto h-52">
              {invites.length === 0 && (
                <Box variant="outlined" className="h-full p-4">
                  <EmptyState title="No Reviewers added" />
                </Box>
              )}
              {invites.map((i) => (
                <div
                  key={i.email}
                  className="grid grid-cols-2 gap-4 p-4 bg-gray-100"
                >
                  <Typography variant="body1">{i.email}</Typography>
                  <Stack
                    alignItems="center"
                    justifyContent="space-between"
                    gap={4}
                  >
                    <Typography variant="body1">{i.type}</Typography>
                    <DeleteButton onClick={() => handleDeleteInvite(i.email)} />
                  </Stack>
                </div>
              ))}
            </div>
          </div>

          <Stack justifyContent="flex-end" gap={4}>
            <Button onClick={handleClose} kind="tertiary">
              Cancel
            </Button>
            <Button onClick={handleSendRequests} disabled={!invites.length}>
              Request Feedback
            </Button>
          </Stack>
        </div>
        <div style={{ flexBasis: "40%" }} className="p-8 pt-8 bg-gray-100">
          <Typography fontWeight="bold" className="mb-3" variant="body1">
            Reviewers you invited before to give you feedback
          </Typography>
          {existingInvitations && existingInvitations.length === 0 && (
            <Box variant="outlined" className="p-4 ">
              <EmptyState title="No previous invites were sent" />
            </Box>
          )}
          {existingInvitations && existingInvitations?.length > 0 && (
            <div className="mt-4 overflow-y-auto scrollhost2 " style={{ height: "39rem" }}>
              <div className="grid gap-4 ">
                {existingInvitations.map((i) => (
                  <Box className="px-4 py-2" variant="outlined" key={i._id}>
                    <Typography fontWeight="semibold">
                      {i.reviewer_email}
                    </Typography>
                    <Typography>{i.reviewer_type}</Typography>
                  </Box>
                ))}
              </div>
            </div>
          )}
        </div>
      </Stack>
    </Box>
  );
};

export default RequestFeedbackDialog;
