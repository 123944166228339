import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Success } from "../components/ToastNotification/Success";
import { CronofyService } from "../services/Scheduling/CronofyService";

export const ConferencingAuthorization = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (CronofyService.getCallBackData()) {
      const returnQueryString:string = location.search.split("=")[1];
      const accessTokenCode = returnQueryString.split("&state")[0];
      // request access Token with retrieved code
      postAccessTokenCode(accessTokenCode);
    } else {
      const returnQueryString = location.search.split("=")[1];
      const accessTokenCode = returnQueryString.split("&state")[0];
      // request access Token with retrieved code
      postAccessTokenCode(accessTokenCode);
    }
  }, []);

  /**
   * request access Token with retrieved code
   * @param {*} code
   */
  function postAccessTokenCode(code) {
    console.log(code);
    Success("Linked Successfully.");
    const routeBack = localStorage.getItem("[conferencingConnectCallback]");
    if (routeBack) {
      window.location.replace(`${routeBack}?conferenceProfile=${routeBack}`);
      localStorage.removeItem("[conferencingConnectCallback]");
    } else {
      history.push("/calendar");
    }
  }

  return (
    <div className="p-4 text-xs text-skin-accent">
      {" "}
      Will redirect automatically...{" "}
    </div>
  );
};
