import { Box, Typography } from "knack-ui";
import { useState } from "react";
import { Success } from "../../../../components/ToastNotification/Success";
import { correctlyMapSessionTypes } from "../../../../lib/constants";
import { individualRatingQuestions, teamRatingQuestions } from "../../../../services/utils/mockData";
import { IProgram } from "../../../Programs/ProgramTypes";
import { useUser } from "../../../User/userQueries";
import { IUserProfile } from "../../../User/UserTypes";
import { useMarkRatingTaskComplete, useSaveSessionRating } from "../../sessionsMutations";
import { IRateExternalCoach, IRateInternalCoach, IRateMentor, IRatingScore, ITask } from "../../SessionsTypes";
import { SessionRagingSliders } from "./SessionRatingModal";

interface IProps {
  onClose: VoidFunction;
  task: ITask;
}

const RatingQuestionModal = ({ task, onClose }: IProps) => {
  const { mutateAsync: saveRating } = useSaveSessionRating();
  const { mutateAsync: markRatingTask } = useMarkRatingTaskComplete();
  const { data: user } = useUser();
  const [ratingsScores, setRatingsScores] = useState<IRatingScore[]>([]);
  const [ratingStep, setRatingStep] = useState(0);

  function onRateSession(currentQuestion: string, score: IRatingScore["score"]) {
    let ratingScores = [...ratingsScores];
    const questionIndex = (ratingsScores as IRatingScore[]).findIndex(({ question }) => question === currentQuestion);
    if (questionIndex === -1) {
      ratingScores.push({ question: currentQuestion, score });
    } else {
      ratingScores[questionIndex] = {
        ...ratingScores[questionIndex],
        score
      };
    }

    setRatingsScores(ratingScores);
    if (ratingScores.length === 4) {
      const program = task.session.program as IProgram;
      const session = task.session;

      const rating = {
        session: task.session._id as string,
        type: correctlyMapSessionTypes[task.session.type],
        ratings: ratingScores,
        program: program._id,
        ...(program.program_type === "Mentoring"
          ? ({
              mentor: session.expert?.user._id,
              mentee: session?.mentee as string
            } as IRateMentor)
          : {}),
        ...(program.program_type === "Coaching" && program.coach_type === "own_coach"
          ? ({
              coach: session.expert?.user._id,
              coachee: session.coachee
            } as IRateInternalCoach)
          : {}),
        ...(program.program_type === "Coaching" && program.coach_type === "knack_coaching"
          ? ({
              coach: session.expert?.user._id,
              employee: session.employee
            } as IRateExternalCoach)
          : {})
      } as IRateMentor | IRateInternalCoach | IRateExternalCoach;

      try {
        saveRating(rating).then(async (res) => {
          await markRatingTask({
            status: true,
            task_id: task._id,
            user: user?._id as string
          });
          onClose();
          Success("Thanks for Sharing your feedback"); // TODO: - Replace this with nicer UI, consult Ahmad
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <Box className="grid items-start dialog-max-h" style={{ gridTemplateRows: "auto 1fr" }} dir="ltr" elevation={0}>
      <div className="p-6 border-b">
        <Typography variant="h6">
          Rate your session with{" "}
          <span className="font-bold capitalize">
            {task?.session?.expert?.first_name} {task?.session?.expert?.last_name}
          </span>
        </Typography>
      </div>
      <SessionRagingSliders
        type={task?.type}
        questions={task?.session?.type === "Team session" ? teamRatingQuestions : individualRatingQuestions}
        step={ratingStep}
        setStep={setRatingStep}
        expert={
          {
            first_name: task?.session?.expert?.first_name as string,
            last_name: task?.session?.expert?.last_name as string
          } as IUserProfile
        }
        setRating={(currentQuestion, score) => onRateSession(currentQuestion, score)}
      />
    </Box>
  );
};

export default RatingQuestionModal;
