import { motion } from "framer-motion";
import { Box, Button, Typography } from "knack-ui";
import { ProfileCreationTransitions } from "./NewUserProfile";
import goalSuccess from "../../../../components/Asset/Images/goalSuccess.png";
import { useQueryClient } from "react-query";
import { userQueryKeys } from "../../../../features/User/userQueries";
import { useHistory } from "react-router-dom";

const ProfileCompletion = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const handleCompletion = async () => {
    await queryClient.invalidateQueries(userQueryKeys.all);
    history.push("/");
  };
  return (
    <Box
      paddingPreset="card"
      as={motion.div}
      variants={ProfileCreationTransitions}
      exit="exit"
      initial="hidden"
      animate="visible"
    >
      <div className="grid gap-6 p-8">
        <div className="flex items-center justify-center">
          <img
            style={{ height: "auto", maxWidth: "100%" }}
            src={goalSuccess}
            alt="Goal created successfully"
          />
        </div>
        <div>
          <Typography textAlign="center" className="mb-3" variant="h5">
            Congratulations! Your profile is now complete.
          </Typography>
          <Typography textAlign="center" color="muted" variant="body1">
            You can start with your personal journey
          </Typography>
        </div>
        <Button onClick={handleCompletion}>Start on Knack!</Button>
      </div>
    </Box>
  );
};

export default ProfileCompletion;
