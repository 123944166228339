import { useEffect, useState } from "react";

interface WindowSize {
  height: number | undefined;
  width: number | undefined;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    height: undefined,
    width: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({ height: window.innerHeight, width: window.innerWidth });
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
