import { format } from "date-fns";
import {  Stack, Typography } from "knack-ui";
import styled from "styled-components";
import DeleteButton from "../../../../../components/DeleteButton/DeleteButton";
import EditButton from "../../../../../components/EditButton/EditButton";
import { ISessionNote } from "../../../../../features/Sessions/SessionsTypes";
import { useUser } from "../../../../../features/User/userQueries";

interface MiniNoteProps {
  note: ISessionNote;
  color?: string;
  onEdit?: VoidFunction;
  onDelete?: VoidFunction;
  onShare?: VoidFunction;
}

const MiniNote = ({ note, color, onDelete, onEdit, onShare }: MiniNoteProps) => {
  const { data: user } = useUser();
  return (
    // @ts-ignore
    <Wrapper style={{ "--bg": color, "--pt": note.author._id === user?.profile?._id ? "40px" : "24px " }}>
      {(onEdit || onDelete || onShare) && (
        <IconsWrapper>
          <Stack gap={2}>
            {onEdit && note.author._id === user?.profile?._id && <EditButton title="Edit note" onClick={onEdit} />}
            {onDelete && note.author._id === user?.profile?._id && <DeleteButton onClick={onDelete} />}
            {/* {onShare && (
              <Button onClick={onShare} title="Share Note" className="flex-shrink-0" variant="small" iconOnly kind="success">
                <SVG name="Share" size={15} />
              </Button>
            )} */}
          </Stack>
        </IconsWrapper>
      )}
      <Typography style={{ marginBottom: "16px" }} variant="body2">
        {note.content}
      </Typography>
      <MetaDataWrapper>
        {/* <MetaDataText style={{ textDecoration: "underline", textAlign: "right", cursor: "pointer" }}>View note </MetaDataText> */}
        {typeof note.author !== "undefined" && note.author && note.shared_with && note.shared_with.length > 0 && (
          <MetaDataText>
            Shared by {note.author._id === user?.profile?._id ? "you" : `${note.author.first_name} ${note.author.last_name}`}{" "}
          </MetaDataText>
        )}
        <MetaDataText>created on {format(new Date(note.created_at), "LLL dd yyyy, kk:mm a")}</MetaDataText>
        {typeof note.contentUpdatedAt !== "undefined" && (
          <MetaDataText>last updated on {format(new Date(note.contentUpdatedAt), "LLL dd yy, kk:mm a")}</MetaDataText>
        )}
      </MetaDataWrapper>
    </Wrapper>
  );
};

export default MiniNote;

const Wrapper = styled.div`
  background-color: var(--bg, var(--color-primary-background));
  border-radius: 12px;
  position: relative;
  padding: var(--pt) 24px 24px 24px;
  display: flex;
  flex-direction: column;
  min-height: 250px;
`;
const IconsWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const MetaDataText = styled.p`
  font-size: ${12 / 16}rem;
  font-style: italic;
  margin-top: auto;
`;
const MetaDataWrapper = styled.div`
  margin-top: auto;
`;
