import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/footer";
import ContentArea from "../../components/contentArea/contentArea";
import { useHistory, useLocation } from "react-router-dom";
import Quiz from "../../components/questions/quiz";
import UserDashboardService from "../../services/dashboard/userDashboard.services";
import Modal from "../../components/modal/modal";
import anime from "animejs";
import { useTranslation } from "react-i18next";
import Completed from "./complete";
import { DataPrivacyModal } from "./DataPrivacyModal";
import { Failure } from "../../components/ToastNotification/Failure";
import { Success } from "../../components/ToastNotification/Success";

export default function Feedback(props) {
  const [openPrivacyModal, setOpenPrivacyModal] = useState(true);
  const [animateSlideInOut, setAnimateSlideInOut] =
    useState("animate-slide-in");
  const { t, i18n } = useTranslation(["common", "360"]);
  const history = useHistory();
  const location = useLocation();

  const user = localStorage.getItem("user");
  const [counter, setCounter] = useState(0);
  const [questionId, setQuestionId] = useState(1);
  const [question, setQuestion] = useState("");
  const [userName, setUserName] = useState("");
  const [answerOptions, setAnswerOptions] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [answer, setAnswer] = useState("");
  const [response, setResponse] = useState([]);
  const [showModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showQuiz, setShowQuiz] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);
  const [isSubmited, setIsSumitted] = useState(false);

  // console.log(location)

  const [token, setToken] = useState(
    location.pathname.split("/360-feedback/")[1]
  );

  useEffect(() => {
    // console.log(token);

    UserDashboardService.get360Questions(token).then(
      (response) => {
        if (response.data.status) {
          // console.log(response.data);
          let user = response.data.data.user;
          let questionList = response.data.data.questions;

          setUserName(user.first_name + " " + user.last_name);
          setQuizQuestions(questionList);
          setQuestion(questionList[0].question);
          setAnswerOptions(questionList[0].options);

          // resume question from where stopped
          const savedResponses = localStorage.getItem(token);
          if (savedResponses) {
            const parsedResponse = JSON.parse(savedResponses);
            setResponse(parsedResponse); // set save response for localStorage

            setQuestion(questionList[parsedResponse.length].question); // resume or set starting questions
            setAnswerOptions(questionList[parsedResponse.length].options); // set anser options for selected question

            setQuestionId(parsedResponse.length + 1);
            setCounter(parsedResponse.length); // value of starting position e.g 0, but mostly likely > 0

            setAnswer(parsedResponse[parsedResponse.length - 1].answer_before); // set answer of previous question
            setShowQuiz(true);
          }
        } else {
          Failure(response.data.data);

          console.log(response.data);

          setShowFeedback(false);
          setShowQuiz(false);
          setShowCompleted(true);

          setTimeout(() => {
            history.push({ pathname: "/" });
          }, 1000);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }, []);

  useEffect(() => {
    // assessment completed
    // console.log(response.length, quizQuestions.length)
    //  console.log("Some happened");
    if (response.length > 0 && response.length === quizQuestions.length) {
      console.log("Will submit");
      submit();
    }
  }, [response]);

  const getItemIndex = (arr, item) => {
    return arr.findIndex((e) => e.question_id === item);
  };

  function storeEdit(question_id, answer) {
    const currentQuestion = quizQuestions[counter];
    const itemIndex = getItemIndex(response, question_id);

    const obj = {
      question_id: currentQuestion._id,
      answer: parseInt(answer),
      level_two: currentQuestion.level_two,
      level_three: currentQuestion.level_three
    };

    if (itemIndex === -1) {
      // add to response list
      const _response = [...response, obj];
      setResponse(_response);
      saveResponseToLocalStorage(_response);
      // break if response not found
      return;
    }

    // modify if found
    const newArr = [...response];
    newArr[itemIndex] = obj;
    setResponse(newArr);
    saveResponseToLocalStorage(newArr);
  }

  const setUserAnswer = (answer) => {
    const currentQuestion = quizQuestions[counter];
    storeEdit(currentQuestion._id, answer);
    setAnswer(parseInt(answer));
  };

  const setNextQuestion = () => {
    const count = counter + 1;
    const qID = questionId + 1;
    setCounter(count);
    setQuestionId(qID);
    setQuestion(quizQuestions[count].question);
    setAnswerOptions(quizQuestions[count].options);
    setAnswer(response.includes(response[count]) ? response[count].answer : "");
  };

  const setPreviousQuestion = () => {
    const count = counter - 1;
    const qID = questionId - 1;
    setCounter(count);
    setQuestionId(qID);
    setQuestion(quizQuestions[count].question);
    setAnswerOptions(quizQuestions[count].options);
    setAnswer(response[count].answer);
  };

  const submit = () => {
    UserDashboardService.submit360Assessment(response, token).then(
      (response) => {
        console.log(response);
        if (response.status) {
          Success(
            "Thank you, your responses have been submitted successfully."
          );
          setShowFeedback(false);
          setShowCompleted(true);
          localStorage.removeItem(token);
          setIsSumitted(true);

          setTimeout(() => {
            history.push({ pathname: "/" });
          }, 1500);
        } else {
          Failure(
            "We're unable to submit your feedback. Please have a look and try again.",
            response.message
          );
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(
          resMessage +
            ": " +
            "There's a little problem. Looks like you've done this before."
        );
        Failure(resMessage);
      }
    );
  };

  const handleNextClick = () => {
    if (questionId < quizQuestions.length) {
      setTimeout(() => setNextQuestion(), 300);
    }
  };

  const handlePrevClick = () => {
    if (questionId > 1) {
      setPreviousQuestion();
      // setTimeout(() => setPreviousQuestion(), 300);
    }
  };
  const handleAnswerSelected = (event) => {
    setUserAnswer(event.currentTarget.value);
    handleNextClick();

    // animation
    setAnimateSlideInOut("animate-slide-out");
    setTimeout(() => {
      setAnimateSlideInOut("animate-slide-in");
    }, 150);
  };

  const onStartButtonClick = () => {
    animateOut();
    setTimeout(() => {
      setShowFeedback(false);
      setShowQuiz(true);
      setShowCompleted(false);
    }, 1000);
  };

  const animateOut = () => {
    setTimeout(
      () =>
        anime({
          targets: ".privacy",
          translateX: "150%",
          elasticity: function (el, i) {
            return 200 + i * 200;
          }
        }),
      200
    );
  };

  const showNext = () => {
    return answer !== "" ? false : true;
  };

  const showPrev = () => {
    return questionId > 1 ? false : true;
  };

  function onOpenClosePrivacyModal(value) {
    setOpenPrivacyModal(value);
    // if accepted
    if (!value) {
      setShowFeedback(false);
      setShowQuiz(true);
      setShowCompleted(false);
    }
  }

  /**
   * save to localStorage
   * @param {*} responseList
   */
  function saveResponseToLocalStorage(responseList) {
    localStorage.setItem(token, JSON.stringify(responseList));
    // console.log("token", responseList)
  }
  const handleChangeLanguage = () => {
    if (i18n.resolvedLanguage === "ar") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ar");
    }
    // window.location.reload();
  };
  console.log(i18n.language);
  // <div className="relative sticky top-0 border-b bg-skin-fill-body-light"> <Header name={userName && userName} /></div>

  //  !showQuiz && showCompleted && <Completed name={userName} />
  return (
    <>
      {!showCompleted && showFeedback && (
        <DataPrivacyModal
          feedbackForUser={userName}
          open={openPrivacyModal}
          setOpen={onOpenClosePrivacyModal}
        />
      )}

      <div
        dir={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}
        className={`${
          i18n.resolvedLanguage === "ar" ? "font-cairo" : ""
        } flex flex-col w-full mt-4 overflow-x-hidden`}
      >
        <ContentArea classes="p-4">
          <div className="flex justify-end">
            <button
              className="p-2 text-sm rounded text-skin-accent bg-skin-light"
              onClick={handleChangeLanguage}
            >
              {i18n.resolvedLanguage === "en" ? "العربية" : "English"}
            </button>
          </div>
          <div className="mx-auto">
            {showQuiz ? (
              <>
                {/* Assessment */}
                {isSubmited ? (
                  <Completed name={userName} />
                ) : (
                  <div
                    className={`p-4 sm:rounded-lg my-4 max-w-2xl mx-auto ${animateSlideInOut}`}
                  >
                    <Quiz
                      is360
                      answer={answer}
                      answerOptions={answerOptions}
                      questionId={questionId}
                      question={t(question, {
                        ns: "360"
                      })}
                      questionTotal={quizQuestions.length}
                      onAnswerSelected={handleAnswerSelected}
                      title="Self / Be Self Aware / Self Awareness"
                    />
                    <div className="flex w-full mx-auto mt-8">
                      <button
                        onClick={handlePrevClick}
                        className="flex px-4 py-2 bg-white border rounded-lg text-skin-accent prev-next-btn text-skin-base border-skin-base focus:outline-none hover:bg-skin-fill-accent hover:text-white"
                        disabled={showPrev()}
                      >
                        {" "}
                        {t("previous-question", {
                          ns: "360"
                        })}
                      </button>

                      <Modal
                        show={showModal}
                        content={message}
                        title={"Assessment"}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
          </div>
        </ContentArea>
        <Footer total={quizQuestions.length} count={questionId} />
      </div>
    </>
  );
}
