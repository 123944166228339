import { useEffect, useMemo, useState } from "react";
import { axiosBatchRequests } from "../../base-api";
import { CronofyService } from "../CronofyService";
import * as CronofyElements from "cronofy-elements";
import moment from "moment";
import { weekDayMappings } from "../../../lib/constants";
import { BiLock } from "react-icons/bi";
import { Typography } from "knack-ui";

const companyColorAccent = `${window.getComputedStyle(document.documentElement).getPropertyValue("--color-accent")}`.trim();
const companyColorAccentSemiLight = `${window
  .getComputedStyle(document.documentElement)
  .getPropertyValue("--color-fill-semi-light")}`.trim();
const companyColorBlack = `${window.getComputedStyle(document.documentElement).getPropertyValue("--color-fill-dark")}`.trim();
const companyColorMuted = `${window.getComputedStyle(document.documentElement).getPropertyValue("--color-muted")}`.trim();

export const SchedulerWrapper = ({
  availability,
  subAccountsWithUserId = [], // expected to {scheduler_id:string, user:string}[]
  required_duration = 60,
  onCallback,
  buffer,
  error
}) => {

  const [element, setElement] = useState(null);
  const start = moment().toISOString().split(".")[0] + ".000Z";
  const end = moment().add(50, "days").toISOString().split(".")[0] + ".000Z";

  const queryPeriods = useMemo(() => {
    let periods = [
      // default
      {
        start,
        end
      }
    ];

    if (availability.availability_rules.length > 0) {
      const availability_weekly_rules = availability.availability_rules[0].weekly_periods;

      const currentDate = new Date();
      /**
       *  Generate up to 5 month but at least 2 month
       *  TODO: Consider controlling size based on the length of availability_weekly_rules, that way we can make the size dynamic
       */
      const size = 100;
      periods = availability_weekly_rules.map((a) => {
        let weeks = new Array(Math.abs(size)).fill(0);
        const _daysToWeekDay = daysToWeekDay(a.day);
        let computedDays = 0;
        weeks = weeks.map((w, i) => {
          const start =
            moment(currentDate)
              .add(computedDays + _daysToWeekDay, "days")
              .set({
                hours: moment(a.start_time, "HH:mm").get("hours"),
                minutes: moment(a.start_time, "HH:mm").get("minutes"),
                second: 0
              })
              .toISOString()
              .split(".")[0] + ".000Z";
          const end =
            moment(currentDate)
              .add(computedDays + _daysToWeekDay, "days")
              .set({
                hours: moment(a.end_time, "HH:mm").get("hours"),
                minutes: moment(a.end_time, "HH:mm").get("minutes"),
                second: 0
              })
              .toISOString()
              .split(".")[0] + ".000Z";
          computedDays = computedDays + 7;

          const computedStartEnd = { start, end };

          const isToday = moment().startOf("day").isSame(moment(start).startOf("day"));
          const dayString = moment().startOf("day").format("dddd").toLowerCase();

          if (dayString === a.day && isToday) {
            const currentStartTime = moment().format("HH:mm");
            const endTime = moment(a.end_time, "HH:mm").format("HH:mm");
            const startTime = moment(a.start_time, "HH:mm").format("HH:mm");

            const isCurrentTimeBetweenStartAndEnd = moment(currentStartTime, "HH:mm").isBetween(
              moment(a.start_time, "HH:mm"),
              moment(a.end_time, "HH:mm")
            );
            const isCurrentTimeBeforeEndTime = moment(currentStartTime, "HH:mm").isBefore(moment(endTime, "HH:mm"));
            const isValidEndTime = moment(endTime, "HH:mm").isAfter(moment(currentStartTime, "HH:mm"));

            if (isCurrentTimeBeforeEndTime && isValidEndTime) {
              const bufferedStart =
                moment()
                  .add("minutes", buffer || 0)
                  .toISOString()
                  .split(".")[0] + ".000Z";

              const isBufferedStartValid = moment(end).isAfter(bufferedStart);

              if (isBufferedStartValid) {
                computedStartEnd.start = bufferedStart;
              } else {
                return;
              }
            } else if (isCurrentTimeBetweenStartAndEnd && isValidEndTime) {
              computedStartEnd.start = moment().toISOString().split(".")[0] + ".000Z";
            } else {
              return;
            }
          }

          return computedStartEnd;
        });

        return weeks;
      });
    }

    const flattened = periods.flat().filter((f) => f !== undefined);
    const sortedArray = flattened.sort((a, b) => moment(a.start).format("YYYYMMDD") - moment(b.start).format("YYYYMMDD"));
    const sliced = sortedArray.slice(0, 50);

    return sliced;
  }, [availability.availability_rules, end, start]);

  function daysToWeekDay(weekDayName) {
    const currentDayOfWeek = moment().format("dddd").toLowerCase();
    const maps = weekDayMappings[currentDayOfWeek];
    const position = maps.indexOf(weekDayName) + 1;
    return position;
  }

  useEffect(() => {
    if (subAccountsWithUserId.length > 0 && !subAccountsWithUserId.includes(undefined)) {
      onRequestElementAccessToken();
    }
  }, [availability, required_duration]);

  function onRequestElementAccessToken() {
    CronofyService.requestElementAccessToken(subAccountsWithUserId.map((s) => s.scheduler_id))
      .then(async (res) => {
        if (res && res.data.status) {
          // prepare options
          const options = {
            element_token: res.data.data.element_token.token,
            data_center: "de",
            target_id: "scheduler-date-time-picker",
            availability_query: {
              participants: [
                {
                  required: "all",
                  members: await getCalendarProfiles(subAccountsWithUserId)
                }
              ],
              required_duration: { minutes: required_duration },
              query_periods: queryPeriods.length > 0 ? queryPeriods : [{ start, end }]
              // buffer: {
              //   before: { minutes: buffer || 0 }
              // }
            },
            max_results: 512,
            response_format: "slots",
            styles: {
              colors: {
                buttonConfirm: companyColorAccent,
                // button: companyColorAccent,
                buttonActive: companyColorAccent,
                buttonActiveText: "#fff",
                buttonHover: companyColorAccentSemiLight,
                buttonTextHover: companyColorBlack,
                // buttonText: "#fff",
                buttonCancel: "#fff",
                neutralDark: companyColorMuted,
                black: companyColorBlack
              }
            },
            callback: (res) => onCallback(res)
          };

          setElement(CronofyElements.DateTimePicker({ ...options }));
          if (element) {
            element.update({ ...options });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function getCalendarProfiles(subAccounts = []) {
    const batchRequests = await axiosBatchRequests([
      ...subAccounts.map((s) => CronofyService.getCalendarAccount(s.scheduler_id, s.userId))
    ]);
    const members = batchRequests.map((res) => ({
      calendar_ids: [res.data.data["cronofy.data"]["profiles"][0]["profile_calendars"][0]?.calendar_id],
      sub: res.data.data.sub,
      // managed_availability: true
      include_managed: true
    }));
    return members;
  }

  return (
    <>
      <div style={{ height: "max-content" }} className="z-50 relative">
        <div
          id="disabled-datepicker-component"
          className="h-full w-full bg-gray-100 absolute z-50 opacity-95 rounded-lg p-4 flex flex-col items-center justify-center gap-4"
        >
          <BiLock size={40} />
          <Typography fontWeight="medium" variant="h6">
            {error?.message || "Please provide a valid meeting url"}
          </Typography>
        </div>
        <div id="scheduler-date-time-picker" />
      </div>
    </>
  );
};
