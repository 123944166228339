import { Button, Stack, Typography } from "knack-ui";
import { programsQueryKeys } from "../../features/Programs/programQueries";
import { useDeleteUser } from "../../features/User/userMutations";
import { IUserProfile } from "../../features/User/UserTypes";
import { queryClient } from "../../lib/appConfig";
import ConfirmationDialog from "../ConfirmationDialog";
import { Failure } from "../ToastNotification/Failure";

interface IProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  userProfile: IUserProfile;
}

export const DeleteUser = ({ setOpen, open, userProfile }: IProps) => {
  const { mutateAsync: deleteUser } = useDeleteUser();

  const willDeleteUser = async () => {
    try {
      await deleteUser(userProfile.user._id);
      setOpen(false);
      queryClient.invalidateQueries(programsQueryKeys.all);
    } catch (error: any) {
      Failure(error?.response?.data?.data);
      setOpen(false);
    }
  };

  return (
    <ConfirmationDialog
      isOpen={open}
      onClose={() => setOpen(false)}
      headerText={`Delete ${userProfile?.first_name || userProfile?.user?.email || ""} ${userProfile.last_name || ""}`}
      footerComponent={() => {
        return (
          <Stack justifyContent="flex-end" gap={2}>
            <Button kind="ghost" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => willDeleteUser()}>
              Delete {userProfile?.first_name || userProfile?.user?.email || ""} {userProfile?.last_name || ""}
            </Button>
          </Stack>
        );
      }}
    >
      <Typography className="mb-4" fontWeight="semibold">
        Deleting {userProfile?.first_name || userProfile?.user?.email || ""} will remove their access to knack. Profile, assessment(s) data
        will be lost if any.
      </Typography>
    </ConfirmationDialog>
  );
};
