const GoalsOverdue = ({ className, size }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size || "70"}
      height={size || "71"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1792 22.7917L14.7709 15.3834L12.0584 18.0959C11.9611 18.1931 11.8639 18.2612 11.7667 18.3C11.6695 18.3389 11.5625 18.3584 11.4459 18.3584C11.3292 18.3584 11.2222 18.3389 11.125 18.3C11.0278 18.2612 10.9306 18.1931 10.8334 18.0959L5.55419 12.8167C5.37919 12.6417 5.29655 12.423 5.30627 12.1605C5.31599 11.898 5.39863 11.6889 5.55419 11.5334C5.74863 11.3389 5.95766 11.2466 6.18127 11.2563C6.40488 11.266 6.60419 11.3584 6.77919 11.5334L11.4459 16.2L13.5167 14.1292L1.61669 2.25838C1.44169 2.08338 1.35905 1.86463 1.36877 1.60213C1.37849 1.33963 1.46113 1.1306 1.61669 0.975043C1.81113 0.780599 2.02016 0.688238 2.24377 0.69796C2.46738 0.707682 2.66669 0.800043 2.84169 0.975043L23.4334 21.5667C23.6084 21.7417 23.6959 21.9459 23.6959 22.1792C23.6959 22.4125 23.6084 22.6167 23.4334 22.7917C23.2584 22.9667 23.0493 23.0493 22.8063 23.0396C22.5632 23.0299 22.3542 22.9473 22.1792 22.7917ZM17.25 12.9042L15.9959 11.65L22.0334 5.61254C22.2084 5.43754 22.4174 5.35004 22.6604 5.35004C22.9035 5.35004 23.1125 5.43754 23.2875 5.61254C23.4625 5.78754 23.55 5.99657 23.55 6.23963C23.55 6.48268 23.4625 6.69171 23.2875 6.86671L17.25 12.9042ZM14.7709 10.425L13.5167 9.17088L17.075 5.61254C17.25 5.43754 17.459 5.35004 17.7021 5.35004C17.9452 5.35004 18.1542 5.43754 18.3292 5.61254C18.5042 5.78754 18.5917 5.99657 18.5917 6.23963C18.5917 6.48268 18.5042 6.69171 18.3292 6.86671L14.7709 10.425ZM6.48752 18.3584C6.37085 18.3584 6.26391 18.3389 6.16669 18.3C6.06946 18.2612 5.97224 18.1931 5.87502 18.0959L0.595852 12.8167C0.420852 12.6417 0.338213 12.423 0.347936 12.1605C0.357658 11.898 0.440296 11.6889 0.595852 11.5334C0.790296 11.3389 0.999325 11.2466 1.22294 11.2563C1.44655 11.266 1.64585 11.3584 1.82085 11.5334L6.48752 16.2L7.74169 17.4542L7.10002 18.0959C7.0028 18.1931 6.90557 18.2612 6.80835 18.3C6.71113 18.3389 6.60419 18.3584 6.48752 18.3584Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GoalsOverdue;
