import { Button, Checkbox, Stack, Typography } from "knack-ui";
import { useState } from "react";

interface IColumDefinition<T> {
  accessorKey: keyof T;
  label: string;
}

interface IProps<T> {
  attributeList: IColumDefinition<T>[];
  onChange: (accessorKeys: Array<keyof T>) => void;
  onExportCSV: (accessorKeys: Array<keyof T>) => void;
  isLoading?: boolean;
}

function AttributeSelector<T>({ attributeList, onChange, onExportCSV, isLoading }: IProps<T>) {
  const [exportAttributes, setExportAttribute] = useState<Array<keyof T>>(attributeList.map((a) => a.accessorKey));

  return (
    <Stack gap={2} direction="column">
      <Typography className="p-4 border-b" fontWeight="medium">
        Export attributes
      </Typography>
      <Stack gap={2} direction="column" className="px-4 pb-4">
        {attributeList.map((a, i) => (
          <label key={i}>
            <Stack gap={2} alignItems={"center"}>
              <Checkbox
                size="small"
                checked={exportAttributes.includes(a.accessorKey)}
                onChange={(e) => {
                  let list = [...exportAttributes];
                  if (e.target.checked) {
                    list = Array.from(new Set([...exportAttributes, a.accessorKey]));
                  } else {
                    list = list.filter((l) => l !== a.accessorKey);
                  }
                  setExportAttribute(list);
                  onChange(list);
                }}
              />
              <Typography fontWeight="medium" variant="body2">
                {a.label}
              </Typography>
            </Stack>
          </label>
        ))}

        <Button
          isLoading={isLoading}
          disabled={exportAttributes.length === 0}
          kind="secondary"
          variant="xsmall"
          onClick={() => onExportCSV(exportAttributes)}
        >
          Send CSV to email
        </Button>
      </Stack>
    </Stack>
  );
}

export default AttributeSelector;
