/**
 * Make sure your skillItemsList array is a list of numbers
 * @returns
 */
export const CustomCircularChart = ({ classes, scorePercentages = [] }) => {
  const computeAverage = (array) =>
    Math.round(array.reduce((p, c) => p + c, 0) / array.length || 0);

  return (
    <>
      {scorePercentages.length > 0 && (
        <div
          className={`${
            classes || "h-32 w-32"
          } flex items-center justify-center rounded-full`}
        >
          <svg viewBox="0 0 36 36" className="circular-chart">
            <path
              stroke="#eee"
              fill={"#fff"}
              strokeWidth="2"
              strokeDasharray="100 100"
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle"
              stroke={"var(--color-body-fill-green)"}
              strokeWidth="4"
              strokeDasharray={`${Math.round(
                computeAverage(scorePercentages)
              )}, 100`}
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text
              textAnchor="middle"
              x="50%"
              y="55%"
              className="text-xs font-bold text-skin-base"
              style={{ fontSize: 7 }}
            >
              {computeAverage(scorePercentages) + "%"}
            </text>
          </svg>
        </div>
      )}
    </>
  );
};
