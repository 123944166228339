import Color from "color";
import { BaseDialog, Box, Button, Stack, Typography } from "knack-ui";
import moment from "moment";
import { useMemo, useState } from "react";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BiVideo } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { HiOutlineExternalLink, HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineEditNote, MdOutlineFreeCancellation } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import CancelSession from "../../../components/ActionSheets/CancelSession";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Divider from "../../../components/Divider/Divider";
import SVG from "../../../components/SVG/SVG";
import { Failure } from "../../../components/ToastNotification/Failure";
import { Success } from "../../../components/ToastNotification/Success";
import { SessionTypes } from "../../../lib/constants";
import MiniNote from "../../../V3/Pages/ConnectionProfile/ui/components/MiniNote";
import { ReschedulePopup } from "../../../V3/Pages/ConnectionProfile/ui/ConnectionSessions/ReschedulePopup";
import SessionNoteModal from "../../../V3/Pages/ConnectionProfile/ui/ConnectionSessions/SessionNoteModal";
import { useGetCalendarProfile } from "../../Calendar/calendarQueries";
import { ICalendarAccount } from "../../Calendar/CalendarTypes";
import { useUser, useUserRoles } from "../../User/userQueries";
import { useDeleteSessionNote } from "../sessionsMutations";
import { useGetOneSession, useGetSessionNotes } from "../sessionsQueries";
import { ISession, ISessionNote, TSessionWithProgram } from "../SessionsTypes";

export const SessionSummary = () => {
  const { roleState } = useUserRoles();
  const { data: user } = useUser();
  const { sessionId } = useParams<{ sessionId: string }>();
  const { data: session } = useGetOneSession({ sessionId });
  const history = useHistory();
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const { data: expertCalendar } = useGetCalendarProfile(
    session?.expert?.scheduler_id,
    session?.expert?.user?._id // this always fetches the calendar profile of the expert
  );
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<ISessionNote | null>(null);
  const [deleteNoteConfirmationOpen, setDeleteNoteConfirmationOpen] = useState(false);

  const { data: notes } = useGetSessionNotes({
    sessionId: session?._id as string
  });

  const sessionDetails = useMemo(() => {
    if (session) {
      if (!session.end_time) return;
      return session;
    }
    return null;
  }, [session]);

  const expertOrUserName = useMemo(() => {
    if (!session) return null;
    if (roleState?.activeRole === "User" && session?.expert) {
      return session?.expert;
    } else if (roleState?.activeRole === "Expert" && session.user) {
      return session?.user;
    }
    return null;
  }, [session, roleState]);

  const { mutateAsync: deleteSessionNote } = useDeleteSessionNote();

  const handleDeleteNote = async (noteId: string, confirm: boolean) => {
    if (confirm) {
      return setDeleteNoteConfirmationOpen(true);
    }
    try {
      await deleteSessionNote(noteId);
      setDeleteNoteConfirmationOpen(false);
      Success("Note has been deleted");
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  };

  if (!sessionDetails)
    return (
      <div className="flex items-center justify-center m-auto h-full" style={{ maxWidth: 600, maxHeight: 400 }}>
        <Box className="p-8">
          <Stack direction="column" className="text-center" gap={4}>
            <div className="flex justify-center">
              <MdOutlineFreeCancellation size={80} className="text-red-200" />
            </div>
            <Typography variant="h6">The session / meeting details you are looking for may have been deleted or does not exist.</Typography>

            <Button kind="primary" className="mx-auto" style={{ width: "fit-content" }} onClick={() => history.push("/sessions")}>
              See all sessions
            </Button>
          </Stack>
        </Box>
      </div>
    );

  const duration = moment(sessionDetails.end_time).diff(moment(sessionDetails.start_time), "minute");
  const summary = `${duration} minutes ${SessionTypes[sessionDetails.type as keyof typeof SessionTypes]}`;

  const statusColorMapping: any = {
    Pending: "warning",
    Cancelled: "warning",
    Completed: "primary",
    "No Show": "warning"
  };
  return (
    <Box as={Stack} direction="column" gap={4} className="mx-auto p-6" style={{ maxWidth: 600 }}>
      <Stack direction="column" gap={4}>
        <div className="">
          <SVG name="CalendarBig" size={50} fill="green" />
        </div>
        <div className="gap-2 grid">
          <Typography color={statusColorMapping[sessionDetails.completion_status]} variant="caption" style={{ fontWeight: "bold" }}>
            {sessionDetails.completion_status}
          </Typography>
          <Typography variant="h5">{summary}</Typography>
          <div className="flex gap-2 items-center cursor-pointer" onClick={() => setNoteModalOpen(true)}>
            <MdOutlineEditNote size={24} />
            <Typography>Write a note</Typography>
          </div>
        </div>
      </Stack>
      <Divider className="block w-full" />
      <Stack gap={8} direction="column">
        <div className="grid grid-cols-3 gap-4">
          <Stack gap={2}>
            <AiOutlineFieldTime size={24} />
            <Typography>Date & Time</Typography>
          </Stack>
          <Typography> {moment(new Date(sessionDetails?.start_time)).format("dddd, MMM DD, h:mm A")}</Typography>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <Stack gap={2}>
            <HiOutlineLocationMarker size={24} />
            <Typography>Location</Typography>
          </Stack>
          <Typography> {sessionDetails?.calendar_event_location_key}</Typography>
        </div>
        {sessionDetails?.conferencing?.join_url && (
          <div className="grid grid-cols-3 gap-4">
            <Stack gap={2}>
              <BiVideo size={24} />
              <Typography>Join Meeting</Typography>
            </Stack>
            <div className="col-span-2">
              <Typography
                as="a"
                href={sessionDetails?.conferencing?.join_url}
                target="_blank"
                color="primary"
                className="cursor-pointer gap-2"
              >
                {sessionDetails?.conferencing?.join_url} <HiOutlineExternalLink />
              </Typography>
            </div>
          </div>
        )}
        <div className="grid grid-cols-3 gap-4">
          <Stack gap={2}>
            <FiUsers size={24} />
            <Typography>Participants</Typography>
          </Stack>
          <div className="col-span-2">
            <Typography>
              {[
                {
                  display_name: `${sessionDetails.expert?.first_name} ${sessionDetails.expert?.last_name}`,
                  email: sessionDetails.expert?.user.email
                },
                {
                  display_name: `${sessionDetails.user?.first_name} ${sessionDetails.user?.last_name}`,
                  email: sessionDetails.user?.user.email
                }
              ]
                .map((t) => `${t.display_name} - ${t.email}`)
                .toString()}
            </Typography>
          </div>
        </div>
      </Stack>

      <Divider className="block w-full" />
      {false && (
        <Stack direction="column" gap={4}>
          <div>
            <Typography style={{ fontWeight: "bold" }}>Take an action</Typography>
            <Typography>You can directly accept invitation on your calendar or click the accept invitation button below.</Typography>
          </div>
          <Stack gap={4}>
            <Button variant="small" kind="success" onClick={() => setOpenReschedule(true)}>
              Accept Invitation
            </Button>
            <Button variant="small" kind="warning" onClick={() => setOpenReschedule(true)}>
              Decline Invitation
            </Button>
          </Stack>
        </Stack>
      )}

      {/* <Divider className="block w-full" /> */}
      <Stack direction="column" gap={4}>
        <Typography>Want to make a change?</Typography>
        <Stack gap={4}>
          <Button variant="small" kind="secondaryOutline" onClick={() => setOpenReschedule(true)}>
            Reschedule
          </Button>
          {["Pending"].includes(sessionDetails?.completion_status) && (
            <Button variant="small" kind="primaryOutline" onClick={() => setConfirmCancel(true)}>
              Cancel Meeting
            </Button>
          )}
        </Stack>
      </Stack>

      {notes && notes.length > 0 && (
        <>
          <Divider className="block w-full" />
          <div className="">
            <div className="mb-4">
              <Typography variant="h6" as="h6" className="mb-4">
                Notes
              </Typography>
              <NotesGrid dir="ltr">
                {notes.map((note) => (
                  <MiniNote
                    note={note}
                    key={note._id}
                    color={Color(note.color).alpha(0.1).hexa()}
                    onDelete={() => {
                      handleDeleteNote(note._id, true);
                      setSelectedNote(note);
                    }}
                    onEdit={() => {
                      setNoteModalOpen(true);
                      setSelectedNote(note);
                    }}
                  />
                ))}
              </NotesGrid>
            </div>
          </div>
        </>
      )}

      <ConfirmationDialog
        isOpen={deleteNoteConfirmationOpen}
        onClose={() => setDeleteNoteConfirmationOpen(false)}
        headerText="Delete note"
        footerComponent={() => {
          return (
            <Stack justifyContent="flex-end" gap={2}>
              <Button kind="ghost" onClick={() => setDeleteNoteConfirmationOpen(false)}>
                Cancel
              </Button>
              <Button
                kind="danger"
                onClick={() => {
                  handleDeleteNote(selectedNote?._id as string, false);
                }}
              >
                Delete note
              </Button>
            </Stack>
          );
        }}
      >
        <Typography>Are you sure you want to delete the note?</Typography>
        <Typography variant="subtitle2" color="muted" className="mt-4">
          Any shared notes will be deleted and will not be visible to shared participants.
        </Typography>
      </ConfirmationDialog>

      <CancelSession
        open={confirmCancel}
        setOpen={(value) => setConfirmCancel(value || false)}
        session={sessionDetails as ISession}
        onSuccess={() => setConfirmCancel(false)}
      />

      <BaseDialog
        style={{ background: "none", height: 500, boxShadow: "none" }}
        animationType="fade"
        dialogClassName="max-w-2xl bg-none"
        isOpen={openReschedule}
        onClose={() => setOpenReschedule(false)}
      >
        <ReschedulePopup
          session={sessionDetails as TSessionWithProgram}
          onSuccess={(session) => setOpenReschedule(false)}
          onClose={() => setOpenReschedule(false)}
          calendar={expertCalendar as ICalendarAccount}
        />
      </BaseDialog>

      <BaseDialog dialogClassName="max-w-3xl" isOpen={noteModalOpen} onClose={() => setNoteModalOpen(false)}>
        <SessionNoteModal
          expertOrUser={expertOrUserName}
          sessionId={sessionDetails?._id as string}
          note={selectedNote}
          onClose={() => setNoteModalOpen(false)}
        />
      </BaseDialog>
    </Box>
  );
};

const NotesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 8px;
`;
