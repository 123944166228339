export const KnackLogo = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-8"
        viewBox="0 0 501.743 113.106"
      >
        <g id="XMLID_1_" transform="translate(-122.157 -273.85)">
          <g id="XMLID_2_" transform="translate(122.157 273.85)">
            <path
              id="XMLID_30_"
              d="M193.787,436.4h-.025a77.378,77.378,0,0,1-10.932.75,63.9,63.9,0,0,1-18.311-2.351c-22.639-5.729-49.705-24.79-40.525-84.9A10.428,10.428,0,0,1,137,341.262a66.012,66.012,0,0,1,21.888,10.732c-9.281,19.162-12.733,44.877,10.256,71.369a74.257,74.257,0,0,1,13.683-1.2,71.645,71.645,0,0,1,14.809,1.426c23.289-26.641,19.737-52.532,10.381-71.769a65.853,65.853,0,0,1,21.738-10.657,10.413,10.413,0,0,1,13.008,8.63C253.123,417.659,217.276,433.268,193.787,436.4Z"
              transform="translate(-122.157 -324.039)"
              fill="#1f40e6"
              opacity="0.75"
            />
            <path
              id="XMLID_27_"
              d="M283.951,461.873c-1.4,1.526-2.9,3.077-4.478,4.6-2.3,2.3-5.8,2.852-9.231,2.852s-6.929-.55-9.206-2.852c-1.6-1.551-3.1-3.1-4.5-4.628-14.859-16.26-19.437-32.245-18.611-46.654-.7-11.432,2.677-22.264,7.3-31.319,9.281,6.554,18.436,16.91,24.89,32.395,6.5-15.585,15.71-26.016,25.065-32.57,4.678,9.081,8.08,19.987,7.4,31.469C303.388,429.6,298.81,445.588,283.951,461.873Z"
              transform="translate(-208.894 -356.221)"
              fill="#1f40e6"
            />
            <path
              id="XMLID_28_"
              d="M317.031,301.63c-9.331,6.8-18.711,17.311-25.19,33.47-6.454-16.06-15.735-26.516-25.04-33.32a103.89,103.89,0,0,1,17.411-24.59,10.644,10.644,0,0,1,15.484,0A105.475,105.475,0,0,1,317.031,301.63Z"
              transform="translate(-230.617 -273.85)"
              fill="#1f40e6"
              opacity="0.75"
            />
          </g>
          <g id="XMLID_3_" transform="translate(278.063 305.307)">
            <path
              id="XMLID_41_"
              d="M1065.5,399.7a26.154,26.154,0,0,0-13.708,3.477,23.436,23.436,0,0,0-8.906,9.381l-1.351-11.232H1026.4v66.391h17.135V433.446q0-9.118,4.628-14.184A15.227,15.227,0,0,1,1060,414.184c4.9,0,8.53,1.526,10.832,4.553q3.49,4.54,3.477,13.108v35.872h17.135v-37.5c0-9.981-2.276-17.586-6.829-22.764Q1077.819,399.687,1065.5,399.7Z"
              transform="translate(-956.107 -399.675)"
              fill="#1f40e6"
            />
            <path
              id="XMLID_42_"
              d="M806.437,401.3H786.1l-23.564,26.891V399.7H745.4v67.992h17.135V434.346l26.891,33.345H810.84l-31.569-36.147Z"
              transform="translate(-745.4 -399.675)"
              fill="#1f40e6"
            />
            <path
              id="XMLID_43_"
              d="M1927.312,401.3h-20.337l-23.564,26.891V399.7H1866.3v67.992h17.11V434.346l26.917,33.345h21.413l-31.594-36.147Z"
              transform="translate(-1585.904 -399.675)"
              fill="#1f40e6"
            />
            <path
              id="XMLID_44_"
              d="M1617.046,414.284a15.9,15.9,0,0,1,9.306,2.752,13.148,13.148,0,0,1,5.278,7.555h18.061c-1.6-7.755-5.3-13.858-11.107-18.261q-8.705-6.642-21.413-6.629a35.581,35.581,0,0,0-17.936,4.428,32.36,32.36,0,0,0-12.258,12.308,38.664,38.664,0,0,0,0,36.122,32.016,32.016,0,0,0,12.258,12.307,35.581,35.581,0,0,0,17.936,4.428q12.72,0,21.413-6.829a30.264,30.264,0,0,0,11.107-18.061H1631.63a12.965,12.965,0,0,1-5.278,7.63,16.221,16.221,0,0,1-9.306,2.677,15.8,15.8,0,0,1-12.057-5.353q-4.953-5.366-4.953-14.859t4.953-14.859A15.8,15.8,0,0,1,1617.046,414.284Z"
              transform="translate(-1373.097 -399.675)"
              fill="#1f40e6"
            />
            <path
              id="XMLID_47_"
              d="M1365.708,406.7c-5.453-4.728-12.883-7.1-22.339-7.1a37.4,37.4,0,0,0-14.984,2.877,27.337,27.337,0,0,0-10.907,8.23,23.445,23.445,0,0,0-4.878,12.583h16.735a10.673,10.673,0,0,1,4.828-7.3,16.548,16.548,0,0,1,9.106-2.477,14.666,14.666,0,0,1,9.631,3.2c2.577,2.151,3.877,5.478,3.877,10.031v1.6h-16.86q-14.446,0-21.688,5.754A18.22,18.22,0,0,0,1311,449.08a19.489,19.489,0,0,0,2.677,10.031,18.989,18.989,0,0,0,8.155,7.3q5.479,2.739,14.059,2.752,8.293,0,13.583-3.477a24.592,24.592,0,0,0,8.23-8.83l1.6,10.707h14.584V426.792Q1373.888,413.809,1365.708,406.7Zm-14.509,44.3c-2.727,3.2-6.529,4.828-11.432,4.828a12.887,12.887,0,0,1-7.755-2.151,7.041,7.041,0,0,1-2.952-6.029,7.621,7.621,0,0,1,2.952-6.079c1.951-1.651,5.228-2.477,9.781-2.477h14.584A23.64,23.64,0,0,1,1351.2,451.006Z"
              transform="translate(-1169.513 -399.6)"
              fill="#1f40e6"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
