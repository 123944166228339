/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { KnackSpinner } from "../../../components/Asset/KnackSpinner";
import { WarningInformation } from "../../../components/Information/WarningInformation";
import { openManualSessionLogging } from "./SessionState";
import {
  useGetExternalCoachingTopics,
  useGetProgramExpertMatches,
  useGetProgramInvitations
} from "../../../features/Programs/programQueries";
import { IMatch, IProgram } from "../../../features/Programs/ProgramTypes";
import { useUser } from "../../../features/User/userQueries";
import { computeConstrainSessionTypes, dateIsValid, SessionTypes, TIMEZONE } from "../../../lib/constants";
import { useGetConnections } from "../../../features/Connections/connectionsQueries";
import { useGetOneTeam, useGetProgramTeams } from "../../../features/Team/teamQueries";
import { IConnectionRequest } from "../../../features/Connections/ConnectionsTypes";
import { BaseDialog, Button, Input, Stack, Textarea, Typography } from "knack-ui";
import SessionProgramsList from "./components/program/SessionProgramsList";
import { SessionSelect } from "./components/session/SessionSelect";
import { SelectConnection } from "./components/program/connection/SelectConnection";
import { ITeam } from "../../../features/Team/TeamTypes";
import { SelectTopics } from "./components/program/SelectTopics";
import { IMeeting, ISession } from "../../../features/Sessions/SessionsTypes";
import { Information } from "../../../components/ToastNotification/Information";
import { Failure } from "../../../components/ToastNotification/Failure";
import { CoachServices, createNewSession } from "../../dashboard/coachDashboard.services";
import { isValidURL } from "../../utils/utils";
import { PopUp } from "../Scheduler/PopUp";
import { CronofyService } from "../CronofyService";
import moment from "moment";
import { ConnectCalendarPrompt } from "./components/prompts/connectCalendar";
import { useQueryClient } from "react-query";
import { sessionQueryKeys } from "../../../features/Sessions/sessionsQueries";
import { useGetCalendarProfile, useValidateIntegratedConferencing } from "../../../features/Calendar/calendarQueries";
import { ICalendarAccount, IConferencingProfile } from "../../../features/Calendar/CalendarTypes";
import { ConferencingProfile } from "./components/conferencingProfile/ConferencingProfile";
import { DateTimeSelector } from "./DateTimeSelector";

export const ManualSessionLoggingForExpert = () => {
  const queryClient = useQueryClient();
  const {
    open,
    setOpen,
    callbackFn,
    setScheduler,
    isScheduler,
    connectionPrograms,
    setConnectionPrograms,
    setConnectionUserId,
    connectionUserId
  } = openManualSessionLogging() as any;
  const [openModal, setOpenModal] = useState(false);
  const [event, setEvent] = useState<IMeeting>();
  const [spinnerOpen2, setSpinnerOpen2] = useState(false);
  const [confirmSendInvitation, setConfirmInvitation] = useState(false);

  const [section, setSection] = useState<number | null>(0);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const { data: externalCoachingTopics } = useGetExternalCoachingTopics();
  const { data: user } = useUser();
  const [searchString, setSearchString] = useState<string>("");
  const [searchTopicString, setSearchTopicString] = useState<string>("");

  const [selectedProgram, setSelectedProgram] = useState<IProgram | null>();
  const [sessionType, setSessionType] = useState<keyof typeof SessionTypes>();
  const [selectedMatch, setSelectedMatch] = useState<IMatch | any>();
  const [selectedTeam, setSelectedTeam] = useState<ITeam | null>();

  const [selectedConnection, setSelectedConnection] = useState<IConnectionRequest | null>();

  const [conferenceProfile, setConferenceProfile] = useState<IConferencingProfile>();

  const [startTime, setStartTime] = useState<string | undefined>(moment().format("HH:mm"));
  const [endTime, setEndTime] = useState<string | undefined>(moment().add(30, "minutes").format("HH:mm"));
  const [date, setDate] = useState<string | undefined>(moment().format("YYYY-MM-DD"));
  const [duration, setDuration] = useState<number>(30);

  const { data: canUseIntegratedConferencing } = useValidateIntegratedConferencing(user?._id);

  const { data: programList } = useGetProgramInvitations(
    {
      confirmed: true
    },
    {
      select: (invites) =>
        invites.filter((i) => ["mentor", "coach"].includes(i.type)).map((i) => ({ ...i.program, selectedSkills: i.selectedSkills }))
    }
  );

  const { data: connectionsList } = useGetConnections(
    { search: "" },
    {
      suspense: true,
      enabled: Boolean(sessionType)
    }
  );

  const { data: matchList } = useGetProgramExpertMatches(selectedProgram?._id, {
    enabled: Boolean((sessionType || selectedProgram) && user?.account_type !== "Coach")
  });

  const { data: teamList } = useGetProgramTeams(
    {
      programId: selectedProgram?._id as string
    },
    {
      enabled:
        Boolean(selectedProgram) &&
        selectedProgram?.program_type === "Coaching" &&
        selectedProgram?.coach_type === "knack_coaching" &&
        selectedProgram.plan === "group"
    }
  );

  const { data: teamWithParticipantProfile } = useGetOneTeam(
    {
      id: selectedTeam?._id as string
    },
    {
      enabled:
        Boolean(selectedTeam) &&
        selectedProgram?.program_type === "Coaching" &&
        selectedProgram?.coach_type === "knack_coaching" &&
        selectedProgram.plan === "group"
    }
  );

  const programs = useMemo(() => {
    let programs: IProgram[] = [];
    if (connectionUserId) {
      programs = connectionPrograms as IProgram[];
    } else {
      programs = programList as IProgram[];
    }
    if (searchString?.length > 0) {
      programs = programs.filter(
        (i) =>
          i?.name?.toLowerCase()?.includes(searchString.toLowerCase()) || i?.overview?.toLowerCase()?.includes(searchString.toLowerCase())
      );
    }
    // dont show group session if it is programs for a connections
    if (user?.account_type === "Coach" && connectionUserId) {
      programs = programs.filter((p) => p.plan !== "group");
    }

    return programs;
  }, [connectionUserId, searchString, user?.account_type, connectionPrograms, programList]);

  const programTopics = useMemo(() => {
    let topics: string[] = [];

    if (selectedProgram?.coach_type === "knack_coaching" && selectedProgram?.program_type === "Coaching") {
      topics = externalCoachingTopics as string[];
    } else {
      topics = selectedProgram?.skills as string[];
    }

    if (searchTopicString.length > 0) {
      topics = topics.filter((p) => p?.toLowerCase()?.includes(searchTopicString.toLowerCase()));
    }

    return topics;
  }, [externalCoachingTopics, searchTopicString, selectedProgram]);

  function onCloseModal(closeModal = true) {
    setOpen(false);
    setScheduler(false);
    setConnectionUserId(null);
    setConnectionPrograms(null);
    if (closeModal) {
      setOpenModal(false);
    }
    setError(null);
    setTimeout(() => {
      setSection(null);
      setSelectedTopics([]);
      setSelectedProgram(null);
    }, 2000);
  }

  const connections = useMemo(() => {
    setSelectedConnection(null);
    let connections: IConnectionRequest[] = [];
    if (connectionsList && selectedProgram) {
      if (selectedProgram?.program_type === "Coaching") {
        connections = connectionsList?.accepted?.filter((c) => c.program?._id === selectedProgram?._id) as IConnectionRequest[];
      } else {
        connections = connectionsList?.accepted?.filter((c) => c.program?._id === selectedProgram?._id) as IConnectionRequest[];
      }
    }
    if (sessionType !== "Chemistry Call" && selectedProgram?.plan !== "group") {
      setSelectedConnection(connections[0]);
    }

    if (connectionUserId) {
      connections = connections.filter((c) => c.mentee._id === connectionUserId);
      setSelectedConnection(connections[0]);
    }

    return connections;
  }, [connectionUserId, connectionsList, selectedProgram, sessionType]);

  const teams = useMemo(() => {
    setSelectedTeam(null);
    const teams = teamList as ITeam[];
    if (teamList && sessionType !== "Chemistry Call" && selectedProgram?.plan === "group") setSelectedTeam(teams[0]);
    return teams;
  }, [selectedProgram?.plan, sessionType, teamList]);

  const matches = useMemo(() => {
    setSelectedMatch(null);
    let _matchlist = matchList as IMatch[];
    if (_matchlist && sessionType === "Chemistry Call") {
      setSelectedMatch(_matchlist[0]);
    }

    if (connectionUserId && _matchlist) {
      _matchlist = _matchlist?.filter((m) => m.mentee === connectionUserId);
      setSelectedMatch(_matchlist[0]);
    }

    return _matchlist;
  }, [connectionUserId, matchList, sessionType]);

  function onSelectProgram(program: IProgram) {
    const sessionType = computeConstrainSessionTypes(program)[0].name as keyof typeof SessionTypes;

    setSelectedProgram(program);
    setSection(1);
    setSessionType(sessionType);
  }

  const [feedbackValue, setFeedbackValue] = useState<number>();
  const [comment, setComment] = useState<string>();

  const { data: calendarAccountData } = useGetCalendarProfile(user?.profile?.scheduler_id, user?._id);

  const calendarAccount = useMemo(() => {
    let _calendarAccount = calendarAccountData as ICalendarAccount;
    if (_calendarAccount) {
      setEvent(
        (e) =>
          ({
            ...e,
            tzid: TIMEZONE
            // start: ISO8601FormattedDate(
            //   moment(date)
            //     .set({
            //       hours: moment(startTime, "HH:mm").get("hours"),
            //       minutes: moment(startTime, "HH:mm").get("minutes")
            //     })
            //     .toDate()
            // ),
            // end: moment(
            //   ISO8601FormattedDate(
            //     moment(date)
            //       .set({
            //         hours: moment(endTime, "HH:mm").get("hours"),
            //         minutes: moment(endTime, "HH:mm").get("minutes")
            //       })
            //       .toDate()
            //   )
            // )
            //   .add(duration as number, "minutes")
            //   .toISOString(false)
          } as IMeeting)
      );

      return _calendarAccount;
    }
    return null;
  }, [calendarAccountData]);

  useEffect(() => {
    setSection(0);
    setOpenModal(true);
  }, [open, callbackFn, setSection, isScheduler, connectionPrograms, connectionUserId]);

  function onLogSessionAsExpert() {
    setSpinnerOpen(true);
    try {
      const session: ISession = {
        completion_status: "Completed",
        start_time: moment(date)
          .set({
            hours: moment(startTime, "HH:mm").get("hours"),
            minutes: moment(startTime, "HH:mm").get("minutes")
          })
          .toDate(),
        end_time: moment(date)
          .set({
            hours: moment(endTime, "HH:mm").get("hours"),
            minutes: moment(endTime, "HH:mm").get("minutes")
          })
          .toDate(),
        type: sessionType as keyof typeof SessionTypes,
        mentoring_topics: [],
        topics: [],
        program: selectedProgram?._id as string,
        formattedDateTime: moment(date).set({
          hours: moment(startTime, "HH:mm").get("hours"),
          minutes: moment(startTime, "HH:mm").get("minutes")
        }),
        isLoggedSession: true
      } as ISession;

      if (feedbackValue) {
        session.expert_feedback = {
          question:
            "How engaged was the client in the coaching session? (i.e., listened attentively, asked follow-up questions and took important notes)",
          rating_value: feedbackValue
        };
        if (comment) {
          session.expert_feedback.additionalComment = comment;
        }
      }

      // is Mentoring program
      if (selectedProgram?.program_type === "Mentoring") {
        session.mentor = user?._id;
        session.mentoring_topics = selectedTopics;

        if (sessionType !== "Chemistry Call") {
          session["mentee"] = selectedConnection?.mentee?._id;
        } else {
          session["mentee"] = selectedMatch?.mentee;
        }
      }
      // is Knack Coaching program
      else if (selectedProgram?.program_type === "Coaching" && selectedProgram?.coach_type === "knack_coaching") {
        session.topics = selectedTopics;
        session.coach = user?._id;

        if (sessionType === "Chemistry Call") {
          session.employee = selectedMatch?.mentee;
        } else if (sessionType === "Team session" && selectedProgram.plan === "group") {
          session.team = selectedTeam?._id;
          session.participants = selectedTeam?.members.map((m) => m._id);
        }
        // One on One Coaching
        else {
          session.employee = selectedConnection?.mentee?._id;
        }
      }
      // is Internal Coaching program
      else if (selectedProgram?.program_type === "Coaching" && selectedProgram?.coach_type === "own_coach") {
        session.coach = user?._id;
        session.mentoring_topics = selectedTopics;
        if (sessionType !== "Chemistry Call") {
          session.coachee = selectedConnection?.mentee?._id;
        } else {
          session.coachee = selectedMatch?.mentee;
        }
      }

      if (user?.account_type === "Coach") {
        // TODO: Move to mutations
        CoachServices.postSession(session)
          .then((res: any) => {
            if (res?.data && res?.data.status === true) {
              queryClient.invalidateQueries(sessionQueryKeys["all"]);
              if (callbackFn) {
                callbackFn();
              }
              setSpinnerOpen(false);
              onCloseModal(false);
              if (sessionType === "Team session") {
                Information(`You have logged a ${session.type} session you had with ${selectedTeam?.name} team`);
              } else if (sessionType === "Chemistry Call") {
                Information(
                  `You have logged a ${session.type} session you had with ${selectedMatch?.user?.first_name} ${selectedMatch?.user?.last_name}`
                );
              } else {
                Information(
                  `You have logged a ${session.type} session you had with ${selectedConnection?.menteeProfile?.first_name} ${selectedConnection?.menteeProfile?.last_name}`
                );
              }
            } else {
              setSpinnerOpen(false);
              Failure("Something went wrong. Please try again or contact support");
            }
          })
          .catch((e) => {
            console.log(e);
            Failure("Something went wrong. Please try again or contact support");
          });
      } else {
        // TODO: Move to mutations
        createNewSession(session)
          .then((res: any) => {
            if (res.data && res.data.status === true) {
              queryClient.invalidateQueries(sessionQueryKeys["all"]);
              if (callbackFn) {
                callbackFn();
              }
              setSpinnerOpen(false);
              onCloseModal(false);
              if (sessionType === "Team session") {
                Information(`You have logged a ${session.type} session you had with ${selectedTeam?.name} team`);
              } else if (sessionType === "Chemistry Call") {
                Information(
                  `You have logged a ${session.type} session you had with ${selectedMatch?.user?.first_name} ${selectedMatch?.user?.last_name}`
                );
              } else {
                Information(
                  `You have logged a ${session.type} session you had with ${selectedConnection?.menteeProfile?.first_name} ${selectedConnection?.menteeProfile?.last_name}`
                );
              }
            } else {
              setSpinnerOpen(false);
              Failure("Something went wrong. Please try again or contact support");
            }
          })
          .catch((e) => {
            setSpinnerOpen(false);
            Failure("Something went wrong. Please try again or contact support");
            console.log(e);
          });
      }
    } catch (error) {
      setSpinnerOpen(false);
      console.log(error);
    }
  }

  function createMeeting() {
    setSpinnerOpen2(true);
    const start = moment(date)
      .set({
        hours: moment(startTime, "HH:mm").get("hours"),
        minutes: moment(startTime, "HH:mm").get("minutes")
      })
      .toDate();
    const end = moment(start).add(duration, "minutes").toDate();

    const eventData: IMeeting = {
      ...event,
      start,
      end
    } as IMeeting;

    console.log(eventData.start, eventData.end);
    console.log(duration);

    const summary = `${duration} minutes ${SessionTypes[sessionType as keyof typeof SessionTypes]}`;

    let description = `${duration} minutes ${SessionTypes[sessionType as keyof typeof SessionTypes]}`;

    if (eventData?.conferencing?.profile_id === "explicit" && eventData?.conferencing.join_url) {
      description = `Join the Meeting with the link below انضم إلى الاجتماع بالرابط أدناه  ${eventData?.conferencing.join_url}`;
    }

    if (eventData.conferencing?.profile_id === "face-2-face") {
      description = `You can meet at ${eventData?.location?.description}`;
    }

    if (eventData.conferencing?.profile_id === "face-2-face") {
      delete eventData.conferencing;
    }

    const meetingLocationDescription = eventData?.location?.description || "Virtual";

    const updatedEvent = {
      ...eventData,
      summary,
      description,
      attendees: {
        invite: [
          {
            email: user?.email,
            display_name: `${user?.profile?.first_name} ${user?.profile?.last_name}`
          },
          // is team session
          ...(sessionType === "Team session"
            ? (teamWithParticipantProfile?.members.map((m) => ({
                email: m.user.email,
                display_name: m?.first_name && m?.last_name ? `${m?.first_name} ${m?.last_name}` : m?.user?.email
              })) as { email: string; display_name: string }[])
            : []),
          // is chemistry call
          ...(sessionType === "Chemistry Call"
            ? [
                {
                  email: selectedMatch?.user?.user.email,
                  display_name:
                    selectedMatch?.user?.first_name && selectedMatch?.user?.last_name
                      ? `${selectedMatch?.user?.first_name} ${selectedMatch?.user?.last_name}`
                      : selectedMatch?.user?.user.email
                }
              ]
            : []),
          // is not chemistry call and not team session then it is a connection
          ...(!["Team session", "Chemistry Call"].includes(sessionType as string)
            ? [
                {
                  email: selectedConnection?.mentee.email,
                  display_name:
                    selectedConnection?.menteeProfile?.first_name && selectedConnection?.menteeProfile?.last_name
                      ? `${selectedConnection?.menteeProfile?.first_name} ${selectedConnection?.menteeProfile?.last_name}`
                      : selectedConnection?.mentee?.email
                }
              ]
            : [])
        ]
      },
      reminders: [{ minutes: 30 }, { minutes: 1440 }, { minutes: 0 }],
      location: {
        description: meetingLocationDescription
      }
    };

    setEvent(updatedEvent as IMeeting);
    let organizer = user?.profile?.scheduler_id; // Expert will always be the organizer

    let fields = {
      organizer,
      event: updatedEvent,
      program: selectedProgram?._id,
      expert: user?._id,
      user:
        sessionType === "Chemistry Call" ? selectedMatch?.user?.user?._id : selectedConnection?.mentee?._id || selectedTeam?.members[0]._id,
      ...(sessionType === "Team session" ? { team: selectedTeam?._id } : {}),
      type: sessionType,
      formattedDateTime: new Date(updatedEvent?.start as string).toString()
    };

    CronofyService.createSchedule({ ...fields })
      .then((res: any) => {
        if (res.data.status === true) {
          queryClient.invalidateQueries(sessionQueryKeys["all"]);
          const createdEvent = res.data.data;
          Information("Session scheduled Successfully", "");
          setConfirmInvitation(false);
          setSpinnerOpen2(false);
          setOpenModal(false);
          setScheduler(false);
          setOpen(false);
          if (user?.account_type !== "Coach" && user?.profile?.scheduler_id) {
            // update participation if roleState.activeRole is User
            setTimeout(() => {
              CronofyService.readUserSchedules(
                user?.profile?.scheduler_id,
                `?tzid=${updatedEvent.tzid}&from=${moment().subtract(2, "days").toISOString()}&only_managed=true`,
                user._id
              )
                .then((res: any) => {
                  if (res.data.status) {
                    const eventToParticipateIn = res.data.data.events.filter(
                      (e: any) =>
                        moment(e.start).isSame(moment(createdEvent.start)) &&
                        moment(e.end).isSame(moment(createdEvent.end)) &&
                        e.summary === createdEvent.summary
                    )[0];
                    if (eventToParticipateIn) {
                      CronofyService.updateParticipationStatus(user?.profile?.scheduler_id, eventToParticipateIn.event_uid)
                        .then((res: any) => {
                          if (res?.data.status === true) {
                          }
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    } else {
                    }
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }, 10000);
          }
        } else {
          Failure("An Error Occurred. Please try again");
          setSpinnerOpen2(false);
        }
      })
      .catch((e) => {
        Failure("An Error Occurred. Please try again");
        console.log(e);
        setSpinnerOpen2(false);
      });
  }

  if (isScheduler && (!user?.profile?.scheduler_id || !calendarAccount)) {
    return (
      <ConnectCalendarPrompt
        program={selectedProgram as IProgram}
        open={openModal}
        setOpen={() => {
          onCloseModal(false);
        }}
      />
    );
  }

  const validateScheduleSessionButton = useMemo(() => {
    const hasValidDateAndTime = dateIsValid(date) && startTime && endTime;
    const hasConnection = selectedMatch || selectedConnection?.mentee || selectedTeam;
    const hasValidConferencingURL = event?.conferencing?.profile_id === "explicit" && isValidURL(event?.conferencing?.join_url);
    const hasValidFace2FaceLocation = event?.conferencing?.profile_id === "face-2-face" && Boolean(event?.location?.description);
    const nonConferencingProfile = ["face-2-face", "explicit"];
    const hasValidProfileId =
      Boolean(event?.conferencing?.profile_id) && !nonConferencingProfile.includes(event?.conferencing?.profile_id as string);
    const isValidConferencing = hasValidConferencingURL || hasValidFace2FaceLocation || hasValidProfileId;

    const baseLogic = hasConnection && hasValidDateAndTime;

    if (baseLogic && isValidConferencing) return true;

    return false;
  }, [date, endTime, event, selectedConnection?.mentee, selectedMatch, selectedTeam, startTime]);

  return (
    <BaseDialog animationType="fade" dialogClassName="max-w-2xl" isOpen={openModal} onClose={() => onCloseModal()}>
      {error && <WarningInformation message={error} />}
      {spinnerOpen && <KnackSpinner loadingText="Please Wait" />}
      <PopUp open={confirmSendInvitation} setOpen={() => setConfirmInvitation(!confirmSendInvitation)} width="max-w-lg">
        {spinnerOpen2 && <KnackSpinner loadingText="Please Wait" />}
        <div className="grid gap-4 p-6 text-center" style={{ maxWidth: 470 }}>
          <div className="text-2xl font-extrabold tracking-tight">You are about to send an invitation</div>
          <div className="-mt-2 text-base">If you proceed, the invitation recipients will receive a calendar invite in their email.</div>
          <div className="flex items-center gap-6 mx-auto mt-4">
            <button
              onClick={() => setConfirmInvitation(false)}
              className="px-4 py-2 font-medium text-red-800 rounded-lg bg-skin-lighter"
              style={{ width: "fit-content" }}
            >
              {" "}
              Cancel
            </button>
            <button
              onClick={() => {
                createMeeting();
              }}
              className="px-4 py-2 font-medium text-white rounded-lg bg-skin-accent"
              style={{ width: "fit-content" }}
            >
              Send Invitation
            </button>
          </div>
        </div>
      </PopUp>

      <div className="grid dialog-max-h" style={{ gridTemplateRows: "1fr auto" }}>
        {section === 0 && (
          <div className="grid gap-8 bg-white dialog-content-max-h max-h-screen-lg">
            {/* Select program */}
            <div className="grid gap-4">
              <Typography variant="h5" className="px-8 pt-8">
                Select program
              </Typography>
              <div className="px-8 overflow-y-auto scrollhost2" style={{ maxHeight: 500 }}>
                {/* <input
                placeholder="Search program ..."
                className="pl-12 m-0 border-none rounded-lg outline-none"
                onChange={(e) => setSearchString(e.target.value)}
              /> */}

                <SessionProgramsList onSelect={(program) => onSelectProgram(program)} programs={programs} />
              </div>
            </div>
          </div>
        )}

        <div className="grid gap-8 p-8 bg-white dialog-content-max-h max-h-screen-lg scrollhost2">
          {/* Select Session Type */}
          {section === 1 && (
            <div className="grid gap-4">
              <div>
                <Typography variant="h5">
                  {isScheduler ? <>Schedule a {sessionType}</> : <> Log a {sessionType}</>}{" "}
                  {sessionType?.includes("session") ? "" : "session"}
                </Typography>

                <Typography color="muted" variant="body1">
                  {selectedProgram?.name}
                </Typography>
              </div>

              <SessionSelect
                type={sessionType as keyof typeof SessionTypes}
                onSelect={(type) => setSessionType(type)}
                onSelectTopics={(topics) => setSelectedTopics(topics)}
                program={selectedProgram as IProgram}
                setError={(err) => setError(err)}
              />
            </div>
          )}

          {/* Select Connection / Match / Team */}
          {section === 1 && (
            <SelectConnection
              match={selectedMatch as IMatch}
              matches={matches as IMatch[]}
              team={selectedTeam as ITeam}
              teams={teams as ITeam[]}
              connection={selectedConnection as IConnectionRequest}
              connections={connections as IConnectionRequest[]}
              onSelectTeam={(team) => setSelectedTeam(team)}
              onSelectConnection={(connection) => setSelectedConnection(connection)}
              onSelectMatch={(match) => setSelectedMatch(match)}
              program={selectedProgram as IProgram}
              isDisabled={Boolean(connectionUserId)}
              sessionType={sessionType as keyof typeof SessionTypes}
              connectionUserId={connectionUserId}
            />
          )}

          {/* Select Date & Time */}
          {section === 1 && (
            <DateTimeSelector
              setDuration={setDuration}
              duration={duration}
              setDate={setDate}
              setEndTime={setEndTime}
              setStartTime={setStartTime}
              date={date}
              startTime={startTime}
              endTime={endTime}
              max={isScheduler ? undefined : moment().format("YYYY-MM-DD")}
              min={isScheduler ? moment().format("YYYY-MM-DD") : undefined}
            />
          )}

          {/* Conferencing Profile */}
          {section === 1 && isScheduler && calendarAccount && (
            <div className="relative z-30">
              <ConferencingProfile
                canUseIntegratedConferencing={canUseIntegratedConferencing}
                customConferenceURL={user?.profile?.customMeetingLink}
                error={error as string}
                width={"w-full"}
                calendarAccount={calendarAccount as ICalendarAccount}
                selectedProfile={conferenceProfile as IConferencingProfile}
                onSelectProfile={(p) => {
                  setConferenceProfile(p);
                  setEvent(
                    (e) =>
                      ({
                        ...e,
                        conferencing: {
                          profile_id: p.profile_id
                        }
                      } as IMeeting)
                  );
                }}
                onSetCustomURL={(p) => {
                  if (p && !isValidURL(p)) {
                    setError("Please provide a valid meeting url");
                    setEvent(
                      (e) =>
                        ({
                          ...e,
                          conferencing: {
                            profile_id: "explicit",
                            provider_description: "Conference Link",
                            join_url: p
                          }
                        } as IMeeting)
                    );
                  } else {
                    setEvent(
                      (e) =>
                        ({
                          ...e,
                          conferencing: {
                            profile_id: "explicit",
                            provider_description: "Conference Link",
                            join_url: p
                          }
                        } as IMeeting)
                    );
                    setError(null);
                  }
                }}
                conferenceURL={event?.conferencing?.join_url as string}
              />
            </div>
          )}

          {/* Meeting location */}
          {section === 1 && isScheduler && calendarAccount && event?.conferencing?.profile_id === "face-2-face" && (
            <div className="relative z-0">
              <Stack direction="column" gap={4} className="z-0">
                <Typography>Enter a location or address</Typography>
                <Input
                  className="z-0"
                  placeholder="e.g Conference room"
                  onChange={(e) => {
                    const location = {
                      description: e.target.value
                    };
                    setEvent(
                      (e) =>
                        ({
                          ...e,
                          location
                        } as IMeeting)
                    );
                  }}
                />
                {!event?.location?.description && (
                  <WarningInformation message={<Typography color="warning">Location or address is required</Typography>} />
                )}
              </Stack>
            </div>
          )}

          {/* Select Topics */}
          {section === 2 && !isScheduler && (
            <SelectTopics
              topics={programTopics}
              selectedTopics={selectedTopics}
              setTopics={setSelectedTopics}
              onSearch={(value) => setSearchTopicString(value)}
              sessionType={sessionType as keyof typeof SessionTypes}
              setError={setError}
              error={error}
              canSearch={true}
            />
          )}

          {/* Client engagement */}
          {section === 3 && !isScheduler && selectedConnection && (
            <>
              <Typography variant="h6" className="mb-2">
                How engaged was {selectedConnection.menteeProfile?.first_name} {selectedConnection?.menteeProfile?.last_name} in the
                coaching session (i.e., listened attentively, asked follow-up questions and took important notes)
              </Typography>
              {/* Rating Options */}
              <div className="grid mt-4 grid-cols">
                <div className="grid grid-cols-10 gap-2">
                  {new Array(10).fill(0).map((r, i) => (
                    <Button kind={feedbackValue === i + 1 ? "primary" : "defaultOutline"} onClick={() => setFeedbackValue(i + 1)}>
                      {i + 1}
                    </Button>
                  ))}
                </div>
                <div className="flex justify-between mt-2">
                  <Typography fontWeight="bold" className="text-sm">
                    Not engaged at all
                  </Typography>
                  <Typography fontWeight="bold" className="text-sm">
                    Very engaged
                  </Typography>
                </div>
                <Textarea
                  onChange={(e) => setComment(e.target.value)}
                  rows={4}
                  placeholder="Additional comments or suggestions for Knack team internal use (Optional)"
                  className="mt-8"
                />
              </div>
            </>
          )}
        </div>

        {/* Buttons */}
        <div className="relative flex justify-between w-full gap-4 p-8 bg-white">
          {section === 0 && (
            <Button kind="defaultOutline" onClick={() => onCloseModal()}>
              Cancel
            </Button>
          )}
          {(section as number) > 0 && (
            <Button kind="defaultOutline" onClick={() => setSection((section as number) - 1)}>
              Back
            </Button>
          )}
          {!isScheduler && (section as number) > 0 && sessionType === "Chemistry Call" && selectedMatch && (
            <Button kind="tertiary" onClick={() => onLogSessionAsExpert()}>
              Log previous session
            </Button>
          )}
          {!isScheduler &&
            (section as number) === 1 &&
            sessionType !== "Chemistry Call" &&
            (selectedMatch || selectedConnection?.mentee || selectedTeam) && (
              <Button disabled={!dateIsValid(date) || !startTime || !endTime} onClick={() => setSection((section as number) + 1)}>
                Select Topics
              </Button>
            )}
          {!isScheduler &&
            (section as number) === 2 &&
            sessionType !== "Chemistry Call" &&
            (selectedMatch || selectedConnection?.mentee || selectedTeam) && (
              <Button
                onClick={() => {
                  if (user?.account_type === "Coach") {
                    setSection(3);
                  } else {
                    onLogSessionAsExpert();
                  }
                }}
                disabled={selectedTopics.length > 0 ? false : true}
              >
                {user?.account_type === "Coach" ? "Client engagement" : "Log previous session"}
              </Button>
            )}
          {!isScheduler &&
            (section as number) === 3 &&
            sessionType !== "Chemistry Call" &&
            (selectedMatch || selectedConnection?.mentee || selectedTeam) && (
              <Button onClick={() => onLogSessionAsExpert()} disabled={selectedTopics.length > 0 ? false : true}>
                Log previous session
              </Button>
            )}
          {isScheduler && (section as number) === 1 && (
            <Button disabled={!validateScheduleSessionButton} onClick={() => setConfirmInvitation(true)}>
              Schedule upcoming session
            </Button>
          )}
        </div>
      </div>
    </BaseDialog>
  );
};
