import { useState } from "react";
import {
  SESSION_BY_PROGRAM_TYPE,
  SESSION_KEYS,
  USER_ROLES
} from "../../../App.constant";
import { HeadlessMenuDropdown } from "../../../components/Popper/HeadlessMenuDropdown";
import { useUserRoles } from "../../../features/User/userQueries";

export const SelectSessionType = ({
  programId,
  sessionType,
  program,
  setSessionType,
  canSelectSessionType
}) => {
  const [sessionTapState, setSessionTapState] = useState(false);
  const { roleState } = useUserRoles();

  return (
    <>
      {canSelectSessionType && sessionType && (
        <div className="w-72 z-50">
          <HeadlessMenuDropdown
            trigger={
              <div
                style={{ height: 50 }}
                className="relative pl-4 gap-4 flex flex-row items-center w-full text-left border rounded-lg cursor-pointer bg-skin-lighter"
                onClick={() => {
                  setSessionTapState(!sessionTapState);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                  />
                </svg>
                <div className="font-medium whitespace-nowrap">
                  {SESSION_KEYS[sessionType]}
                </div>
                {/* Droplet Arrow */}
                <div
                  className={`absolute right-0 translate transform delay-300 duration-300 p-4 ${
                    sessionTapState ? "" : "-rotate-180"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3"
                    viewBox="0 0 18 9"
                    fill="var(--color-accent)"
                  >
                    <path
                      id="Icon_ionic-md-arrow-dropdown"
                      data-name="Icon ionic-md-arrow-dropdown"
                      d="M9,13.5l9,9,9-9Z"
                      transform="translate(-9 -13.5)"
                    />
                  </svg>
                </div>
              </div>
            }
            popComponent={
              <div
                className="absolute right-0 z-auto w-full pt-4 -mt-3 overflow-y-auto scrollhost2"
                style={{ maxHeight: 400 }}
              >
                <div className="overflow-hidden bg-white border rounded-lg shadow-md">
                  {program &&
                    program &&
                    program.program_type &&
                    [
                      ...(programId === "External Coaching" &&
                      roleState.activeRole === USER_ROLES.USER
                        ? SESSION_BY_PROGRAM_TYPE.USER_EXTERNAL_COACHING
                        : SESSION_BY_PROGRAM_TYPE[program.program_type])
                    ].map((s, i) => (
                      <div
                        key={i}
                        className={`cursor-pointer w-full text-left hover:bg-skin-light ${
                          sessionType !== s.name ? "" : "hidden"
                        }`}
                        onClick={() => {
                          setSessionType(s.name);
                          setSessionTapState(false);
                        }}
                      >
                        <div className="flex flex-col gap-4 p-4 border-b sm:flex-row sm:items-center">
                          <div className="relative">
                            <div className="text-sm font-bold">
                              {SESSION_KEYS[s.name]}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};
