import React from "react";
import { SkillBreakdownResultV2 } from "../../../../../components/Chart/SkillBreakdownResultV2";

export default function SkillBreakdown({
  title,
  stateData = [],
  isTeamResult = false
}) {
  const level_two_reports = stateData
    .map((report) => report.Nest.level_two_report.buckets)
    .map((report) =>
      report.map((reportData) => ({
        key: reportData.key,
        buckets: reportData.level_three_report.buckets
      }))
    );
  const flattened = level_two_reports.flat();
  const clustered = cluster(flattened);
  const skills = Object.keys(clustered);

  function cluster(list) {
    const clustered = {};
    // console.log(matches);
    list.map((m) => {
      if (!clustered[m.key]) {
        clustered[m.key] = [m.buckets];
      } else {
        clustered[m.key].push(m.buckets);
      }
    });
    return clustered;
  }

  return (
    <>
      <div>
        <div className="p-4 rounded-lg hidden">
          <div className="font-bold text-xl sm:text-2xl">
            {title || "Self-Assessment Results Breakdown"}
          </div>
          <div
            className="font-bold text-sm sm:text-base mb-1"
            style={{ color: "var(--color-body-fill-green)" }}
          >
            by Value
          </div>
        </div>

        {/* Skill BreakdownResult */}
        <SkillBreakdownResultV2 skills={skills} clustered={clustered} />
      </div>
    </>
  );
}
