import React from "react";
import { HrFilterableReport } from "../../userDashboard/userReports/personalReports/HrFilterableReport";

export const Overview = () => {
  return (
    <>
      <HrFilterableReport />
    </>
  );
};
