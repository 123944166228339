import { useEffect, useState } from "react";
import { useCallback } from "react";
import hrDashboardServices from "../../services/dashboard/hrDashboard.services";
import MentoringInsightsTimeline from "./MentoringInsightsTimeline";
import { USER_ROLES } from "../../App.constant";
import { ProfileCard } from "./ProfileCard";
export const MentoringInsights = ({ user, program, setUserId }) => {
  const [mentees, setMentees] = useState(1);
  const [mentors, setMentors] = useState(1);
  const [tab, setTab] = useState("Sessions & Timeline");
  const [tabs, setTabs] = useState([]);

  const getMentees = useCallback(async (id, programId) => {
    try {
      const res =
        await hrDashboardServices.getMenteesOf_A_Mentor_Within_A_Program(
          id,
          programId
        );
      if (res.status) {
        const { data } = res.data;
        if (typeof data !== "string" && data.length > 0) {
          let accepted = data?.filter((d) => d.status === "approved");

          accepted = accepted.filter(
            (a) => a.program.program_type === "Mentoring"
          );

          setMentees(accepted);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const getMentors = useCallback(async (id, programId) => {
    try {
      const res =
        await hrDashboardServices.getMentorsOf_A_Mentee_Within_A_Program(
          id,
          programId
        );
      if (res.status) {
        const { data } = res.data;
        if (typeof data !== "string" && data.length > 0) {
          const accepted = data?.filter(
            (d) =>
              d.status === "approved" && d.program.program_type === "Mentoring"
          );
          setMentors(accepted);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const addedTabs = [{ name: "Sessions & Timeline" }];
    if (user?.user.roles.includes("mentor")) {
      addedTabs.push({ name: "Mentees" });
      getMentees(user.user._id, program?._id);
    }

    if (user.user.roles.includes("mentee")) {
      addedTabs.push({ name: "Mentors" });
      getMentors(user.user._id, program?._id);
    }
    setTabs(addedTabs);
  }, [getMentees, getMentors, program, user]);

  return (
    <div>
      <div className="text-skin-base">
        {/* Tabs & Filter */}
        <div className="flex flex-row items-center justify-between">
          {/* Tabs */}
          <div className="flex flex-row justify-between w-full overflow-x-auto text-xs capitalize border-b border-gray-200 sm:justify-start">
            {tabs?.map((t, i) => (
              <div
                onClick={() => setTab(t.name)}
                key={i}
                className={`flex flex-row items-center px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-skin-light transition duration-75 ease-in-out text-skin-muted ${
                  t.name === tab &&
                  "border-b-2 border-skin-base text-skin-inverted"
                } `}
              >
                {t?.icon && <div className="mr-2">{t?.icon} </div>}
                <div>{t?.name} </div>
              </div>
            ))}
          </div>
        </div>

        {/* Card List */}
        <div className="">
          {
            <div>
              {tabs?.map((t, i) => (
                <div key={i}> {t.name === tab.name ? t?.Component : ""} </div>
              ))}
            </div>
          }
        </div>
      </div>

      <div className="my-2">
        {tab === "Sessions & Timeline" && (
          <MentoringInsightsTimeline user={user} />
        )}
        <div
          className="grid gap-4 overflow-y-auto scrollhost2 pr-2"
          style={{ maxHeight: 400 }}
        >
          {tab === "Mentees" ? (
            mentees.length > 0 ? (
              mentees.map((c, i) => (
                <ProfileCard
                  onClick={() => setUserId(c?.mentee)}
                  m={c}
                  key={c?._id}
                  i={i}
                  listType={USER_ROLES.USER}
                />
              ))
            ) : (
              <div>
                <p className="flex items-center justify-center text-sm h-28">
                  No Mentees are available
                </p>
              </div>
            )
          ) : null}
          {tab === "Mentors" ? (
            mentors.length > 0 ? (
              mentors.map((c, i) => (
                <ProfileCard
                  onClick={() => setUserId(c?.mentor)}
                  m={c}
                  key={c?._id}
                  i={i}
                  listType={USER_ROLES.EXPERT}
                />
              ))
            ) : (
              <div>
                <p className="flex items-center justify-center text-sm h-28">
                  No Mentors are available
                </p>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
