import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BackButton } from "../../../../components/nav/BackButton";
import SVG from "../../../../components/SVG/SVG";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import { AssessmentFrameworkTagsList } from "../Components/AssessmentFrameworkTagsList";
import { CreateAssessmentFramework } from "../Components/CreateAssessmentFramework";

export const AssessmentFramework = () => {
  const [tempCollectionName, setTempCollectionName] = useState("");

  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const [tab, setTab] = useState(1);
  const [custom_level_two, setCustomLevelTwo] = useState([]); // a list of collection created by the company to replace the default assessment level_two
  const [mappedLevelThree, setMappedLevelThree] = useState([]); // a list of level_three tags that are already mapped to a custom level_two
  const [defaultLevelThree, setDefaultLevelThree] = useState([]); // default level three by Knack

  useEffect(() => {
    getCustomLevelTwo();
    getTags();
  }, [setCustomLevelTwo, setDefaultLevelThree]);

  function getCustomLevelTwo() {
    AdminServices.getCustomLevelTwo(location.state._id)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setCustomLevelTwo(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Unable to fetch Custom Framework");
      });
  }

  function getTags() {
    AdminServices.getMappedLevelThree(location.state._id)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setMappedLevelThree(data);
          getDefaultLevelThree(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  function getDefaultLevelThree(mappedLevelThree) {
    AdminServices.getDefaultAssessmentTags()
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          if (mappedLevelThree.length) {
            // remove already mapped tag
            const _defaultLevelThree = data.filter(
              (d) => !mappedLevelThree.find(({ level_three }) => level_three.toLowerCase() === d.level_three.toLowerCase())
            );
            setDefaultLevelThree(_defaultLevelThree);
          } else {
            setDefaultLevelThree(data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  function onMoveTag(tag, selectedCollection) {
    let framework;

    if (!tag.level_two) {
      framework = { ...tag };
      framework["custom_level_two"] = selectedCollection._id;
    } else {
      framework = {
        company: location.state._id,
        custom_level_two: selectedCollection._id,
        level_three: tag.level_three
      };
    }

    // console.log(framework)
    AdminServices.moveAssessmentTagToCollection(framework)
      .then((res) => {
        if (res.data.status) {
          Success("Tagged moved");
          onCallback();
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  function onCallback() {
    getCustomLevelTwo();
    getTags();

    setTempCollectionName("");
  }

  function onCreateCollection(collectionName) {
    setTempCollectionName(collectionName);
    setOpen(true);
  }

  return (
    <div className="p-4">
      {/* Create Collection */}
      {isOpen && (
        <CreateAssessmentFramework
          collectionName={tempCollectionName}
          callBack={() => onCallback()}
          allCollections={[...custom_level_two].map((c) => c.custom_level_two)}
          isOpen={isOpen}
          setOpen={setOpen}
          companyId={location.state._id}
        />
      )}

      {/* Back button */}
      <BackButton />

      {/* Header */}
      <div className="mt-4 text-base font-extrabold sm:text-2xl text-skin-base"> Assessment Framework </div>

      {/* Tabs */}
      <div className="flex flex-row gap-4 mt-4">
        <div
          className={`border px-4 py-2 rounded-lg text-sm font-medium cursor-pointer ${tab === 0 ? "bg-skin-fill text-white" : ""}`}
          onClick={() => setTab(0)}
        >
          Collections{" "}
        </div>
        <div
          className={`border px-4 py-2 rounded-lg text-sm font-medium cursor-pointer ${tab === 1 ? "bg-skin-fill text-white" : ""}`}
          onClick={() => setTab(1)}
        >
          Tags
        </div>
      </div>

      {/* Collection List */}
      {tab === 0 && (
        <div className="grid gap-4 mt-4 sm:grid-cols-3 xl:grid-cols-4">
          <button
            className="flex flex-row items-center justify-center gap-2 p-4 text-sm font-bold border rounded-lg"
            onClick={() => setOpen(true)}
          >
            <SVG name="Add" />
            <div>Create Collection</div>
          </button>
          {/* Showing All Collections */}

          {/* custom framework  */}
          {custom_level_two.map((c, i) => (
            <button
              key={i}
              className="block p-4 text-left bg-white rounded-lg"
              // onClick={() => history.push({ pathname: 'assessment-tags', state: { tags: c.value, from: 'Collections', collections: [...defaultCollection].concat(collection_custom) } })}
            >
              {/* Collection Tag */}
              <p
                className="flex flex-row items-center px-2 py-1 text-xs font-bold text-yellow-700 bg-yellow-100 rounded-full"
                style={{ width: "fit-content" }}
              >
                Custom
              </p>
              {/* Collection Name */}
              <div className="mt-2 text-base font-bold">{c.custom_level_two}</div>

              {/* Tags count */}
              {c.value && <div className="text-xs text-skin-muted">{c.value.length} tags</div>}
            </button>
          ))}
        </div>
      )}

      {/* Tags List */}
      {tab === 1 && (
        <div className="mt-4">
          <AssessmentFrameworkTagsList
            onCreateCollection={(e) => onCreateCollection(e)}
            onMoveTag={onMoveTag}
            companyId={location.state._id}
            customTags={mappedLevelThree}
            defaultTags={defaultLevelThree}
            collections={custom_level_two}
          />
        </div>
      )}
    </div>
  );
};
