import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

export const HeadlessMenuDropdown = ({ trigger, popComponent }) => {
  return (
    <div className="w-full">
      <Menu as="div" className="w-full relative">
        <div>
          <Menu.Button className={"w-full"}> {trigger} </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-full mt-2 origin-top-right bg-white rounded-md shadow-lg focus:outline-none">
            <Menu.Item>
              <div>{popComponent}</div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
