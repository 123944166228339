/* eslint-disable react/no-array-index-key */
import { Children, FC } from "react";
// eslint-disable-next-line import/no-unresolved
import { SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

export const SliderBreakpoints: SwiperOptions["breakpoints"] = {
  // when window width is >= 320px
  320: {
    slidesPerView: 2.1,
    spaceBetween: 10
  },
  // when window width is >= 480px
  480: {
    slidesPerView: 2.1,
    spaceBetween: 10
  },
  // when window width is >= 640px
  600: {
    slidesPerView: 2.2,
    spaceBetween: 20
  },
  900: {
    slidesPerView: 2.2,
    spaceBetween: 20
  },
  1200: {
    slidesPerView: 2.2,
    spaceBetween: 20
  },
  2036: {
    slidesPerView: 3.3,
    spaceBetween: 20
  }
};

interface ISliderProps {
  breakpoints?: SwiperOptions["breakpoints"];
}

const Slider: FC<ISliderProps> = ({
  children,
  breakpoints = SliderBreakpoints
}) => {
  return (
    <Swiper
      // dir={i18n.dir()}
      // className="px-1 py-8"
      // freeMode={{
      //   enabled: true,
      //   momentumRatio: 3,
      //   momentumVelocityRatio: 3,
      //   momentumBounceRatio: 3,
      //   minimumVelocity: 0.3,
      //   momentum: true,
      //   momentumBounce: true,
      //   sticky: false
      // }}
      // freeMode
      longSwipes
      breakpoints={breakpoints}
    >
      {Children.map(children, (child, i) => (
        <SwiperSlide className="" key={i + 1}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
