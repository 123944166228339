import React, { useState } from "react";
import { colorPalette } from "../../services/utils/utils";
import { LoadMore } from "../LazyLoading/LoadMore";
/**
 * @returns a list of sessions in custom List-UI
 */
export const MentoringSessionTimeline = ({
  sessionList = [],
  HeaderTitle = "Completed Sessions",
  noSessionText = "No Session completed yet."
}) => {
  const [displayCount, setDisplayCount] = useState(10);

  return (
    <div className="relative w-full h-full overflow-hidden ">
      {/* Header */}
      <div
        className="text-sm p-4 font-bold text-skin-base z-50 -mx-2 -mt-2"
        style={{ zIndex: 9999999, background: "#fafafa" }}
      >
        {" "}
        {HeaderTitle}{" "}
      </div>
      {/* Floating Left Rod */}
      <div className="absolute h-full max-h-md w-1 bg-skin-fill transform translate-x-4"></div>
      {/* Session Cards */}
      <div className="z-50 relative -mx-2 border-t pr-4">
        {sessionList?.slice(0, displayCount).map((s, i) => (
          <label className="flex flex-row items-center w-full py-2" key={i}>
            <div
              className="bg-white border-4 rounded-full h-5 w-5 mx-4"
              style={{ borderColor: i === 0 ? colorPalette[4].solid : "black" }}
            ></div>
            <div className="bg-white shadow rounded-lg w-full">
              {/* Grid */}
              <div className="grid sm:grid-cols-3 p-4 gap-4">
                {/* Date & Time */}
                <div className=" flex flex-col items-start justify-center">
                  {/* Start Date */}
                  <div className="text-2xl font-medium">
                    {new Date(s.start_time).toLocaleDateString("en-US", {
                      day: "numeric"
                    })}{" "}
                    {new Date(s.start_time).toLocaleDateString("en-US", {
                      month: "short"
                    })}{" "}
                    &apos;
                    {new Date(s.start_time).toLocaleDateString("en-US", {
                      year: "2-digit"
                    })}
                  </div>
                  {/* Duration */}
                  <div className="text-skin-muted text-xs mt-1">
                    {new Date(s.start_time).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit"
                    })}
                    {/* - {new Date(s.end_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} */}
                  </div>
                </div>
                {/* Session Type */}
                <div className="sm:border-l sm:p-4 flex flex-col items-start justify-center">
                  <div className="text-gray-500 text-xs font-medium">Type</div>
                  <div className="text-lg font-medium">{s.type}</div>
                </div>
                <div className="sm:border-l sm:p-4 flex flex-col items-start justify-center">
                  <div className="text-gray-500 text-xs font-medium">
                    Topics
                  </div>
                  <div className="text-xs font-medium">
                    {" "}
                    {s?.mentoring_topics?.slice(0, 3)?.toString()}{" "}
                  </div>
                </div>
              </div>
            </div>
          </label>
        ))}

        {sessionList.length === 0 && (
          <div className="flex flex-row items-center justify-center">
            <div className="text-lg text-skin-muted">{noSessionText}</div>
          </div>
        )}

        {/* Load More */}
        <LoadMore
          onLoad={() => {
            setDisplayCount(displayCount + 10);
          }}
          showHide={sessionList.length > displayCount}
        />
      </div>
    </div>
  );
};
