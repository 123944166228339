import { FC } from "react";
// eslint-disable-next-line import/no-unresolved
import { SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { SliderBreakpoints } from "../../../components/Slider/Slider";
import { range } from "../../../lib/utils";

interface IProps {
  breakpoints?: SwiperOptions["breakpoints"];
  count?: number;
  placeholderElement: JSX.Element;
}
const SliderSkeleton: FC<IProps> = ({
  breakpoints = SliderBreakpoints,
  children,
  placeholderElement,
  count = 5
}) => {
  return (
    <Swiper
      // dir={i18n.dir()}
      // className="px-1 py-8"
      // freeMode={{
      //   enabled: true,
      //   momentumRatio: 3,
      //   momentumVelocityRatio: 3,
      //   momentumBounceRatio: 3,
      //   minimumVelocity: 0.3,
      //   momentum: true,
      //   momentumBounce: true,
      //   sticky: false
      // }}
      // freeMode
      longSwipes
      breakpoints={breakpoints}
    >
      {range(0, count).map((i) => (
        <SwiperSlide className="" key={i + 1}>
          {placeholderElement}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderSkeleton;
