export const CustomBar = ({ backgroundColor, Width, classes, isOpen }) => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center">
        <div className="w-full rounded-lg bg-gray-100">
          <div
            className={`rounded-lg ${
              isOpen ? "transition-height delay-500 ease-in-out" : ""
            } ${classes}`}
            style={{ backgroundColor: backgroundColor, width: `${Width}%` }}
          >
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
};
