import { Typography } from "knack-ui";
import { useCallback, useState } from "react";
import styled from "styled-components";
import EmptyState from "../../../../../components/EmptyState/EmptyState";
import Grid from "../../../../../components/Layout/Grid";
import ProgramCard from "../../../../../components/ProgramCard/ProgramCard";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import { IProgramsResponse, useGetEnrolledPrograms } from "../../../../Programs/programQueries";
import { IProgram } from "../../../../Programs/ProgramTypes";

interface IProps {
  selected: IProgram | null;
  onProgramSelect: (p: IProgram) => void;
}

const ProgramSelection = ({ selected, onProgramSelect }: IProps) => {
  const [search, setSearch] = useState("");

  const { data } = useGetEnrolledPrograms({
    select: useCallback(
      (res: IProgramsResponse) => {
        const all = [...res.coaching, ...res.mentoring];
        return {
          total: res.total,
          //   all,
          all: all.filter(
            (p) => p.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1 && p.expert !== null && p.coach_type !== "knack_coaching"
          ),
          coachingCount: res.coaching.length,
          mentoringCount: res.mentoring.length
        };
      },
      [search]
    )
  });
  return (
    <>
      <Typography fontWeight="bold">Select the program</Typography>
      <StickyWrapper>
        <SearchInput placeholder="Search for a program..." onChange={(v) => setSearch(v)} value={search} />
      </StickyWrapper>
      {search !== "" && data && data.all.length === 0 && (
        <EmptyStateWrapper>
          <EmptyState
            icon="ProgramBig"
            iconSize={60}
            title={`Could not find results for ${search}`}
            subtitle="Try using a different spelling"
          />
        </EmptyStateWrapper>
      )}
      <Grid gap="gap-6" gridTemplateColumns="grid-cols-1">
        {data?.all.map((program) => (
          <ProgramCard selected={selected?._id === program._id} onSelect={(p) => onProgramSelect(p)} program={program} key={program?._id} />
        ))}
      </Grid>
    </>
  );
};

export default ProgramSelection;

const StickyWrapper = styled.div`
  position: sticky;
  top: -24px;
  z-index: 1;
  /* margin-bottom: 24px; */
  background-color: var(--color-paper-background);
  /* margin-left: -24px; */
  /* margin-right: -24px; */
  /* padding-right: 24px; */
  /* padding-left: 24px; */
  padding-bottom: 16px;
  padding-top: 16px;
  /* padding: 24px; */
  /* box-shadow: ${(p) => p.theme.elevations.medium}; */
`;
const EmptyStateWrapper = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
`;
