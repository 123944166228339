import { Typography } from "knack-ui";
import { FieldError } from "react-hook-form";

interface Props {
  error: FieldError;
}
export const FieldErrorLabel = ({ error }: Props) => {
  if (error?.message)
    return (
      <Typography color="warning" variant="subtitle2">
        {error?.message}
      </Typography>
    );
  return <p></p>;
};
