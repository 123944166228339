import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption, ComboboxOptionText } from "@reach/combobox";
import { Input } from "knack-ui";
import { forwardRef } from "react";
import styled from "styled-components";

interface AutoCompleteInputProps {
  value: string;
  onChange: (v: string) => void;
  onExistingSuggestionSelect?: (v: string) => void;
  suggestions?: string[];
  /**
   * if `true`, will show an empty state message in case suggestions doesn't match the value.
   */
  disableCustomValueAddition?: boolean;
  onCustomValueSelection?: (value: string) => void;
  hideEmptyState?: boolean;
  inputProps?: Record<string, any>;
}

const AutoCompleteInput = forwardRef<HTMLInputElement, AutoCompleteInputProps>(
  (
    {
      value,
      onChange,
      suggestions,
      disableCustomValueAddition,
      onCustomValueSelection,
      hideEmptyState,
      inputProps,
      onExistingSuggestionSelect,
      ...delegated
    },
    ref
  ) => {
    return (
      <Combobox
        style={{ isolation: "isolate" }}
        onSelect={(v) => {
          if (suggestions && suggestions?.length === 0 && onCustomValueSelection) {
            return onCustomValueSelection(value);
          }
          onChange(v);
          onExistingSuggestionSelect?.(v);
        }}
        openOnFocus
        aria-label="choose your position"
      >
        <ComboboxInput ref={ref} as={Input} {...inputProps} onChange={(e) => onChange(e.target.value)} value={value} />

        {suggestions && (
          <Popover>
            {suggestions.length > 0 ? (
              <List className="scrollhost2">
                {suggestions.map((s) => (
                  <Option value={s} key={s} />
                ))}
              </List>
            ) : !disableCustomValueAddition && value.trim() !== "" ? (
              <List>
                <Option value={value}>
                  add <ComboboxOptionText />
                </Option>
              </List>
            ) : !hideEmptyState ? (
              <EmptyState>Can't find suggestions for {value}</EmptyState>
            ) : null}
          </Popover>
        )}
      </Combobox>
    );
  }
);

export default AutoCompleteInput;

/* possible component states: "idle" | "suggesting" | "navigating" | "interacting"  */
// eg: [data-reach-combobox-popover][data-state="👆"] {}

const Popover = styled(ComboboxPopover)`
  background-color: var(--paper-color, "#fff");
  isolation: isolate;
`;
const List = styled(ComboboxList)`
  max-height: 200px;
  overflow: auto;
`;
const Option = styled(ComboboxOption)`
  padding: 8px;

  background-color: var(--color-gray-100);
  &[data-highlighted] {
    background-color: var(--color-primary-background);
    color: var(--color-primary);
  }
  @media ${(p) => p.theme.queries.hoverPointerDevices()} {
    &:hover {
      background-color: var(--color-gray-200);
    }
  }

  font-size: ${14 / 16}rem;
`;
const EmptyState = styled.span`
  font-size: ${14 / 16}rem;
  display: block;
  padding: 8px;
  background-color: var(--color-gray-200);
`;
