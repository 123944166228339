import { Stack, Typography } from 'knack-ui'
import { useMemo } from 'react';
import { CONFERENCING_PROFILES } from '../../../../App.constant';
import RectangleSkeleton from '../../../../components/skeletons/Rectangle';
import { Status } from '../../../../components/Status/Status';
import { useGetCalendarAvailability, useGetCalendarProfile } from '../../../../features/Calendar/calendarQueries';
import { ICalendarAccount, ICalendarConferencingOptions, IConferencingProfile } from '../../../../features/Calendar/CalendarTypes';
import { useUser } from '../../../../features/User/userQueries';
import { IUserProfile } from '../../../../features/User/UserTypes'

export const CronofyIntegrationStatus = ({ profile }: { profile: IUserProfile }) => {
    const { data: user } = useUser();
    const { data: calendarProfile, isError, isLoading } = useGetCalendarProfile(profile?.scheduler_id, user?._id);
    const { data: availability, isLoading: availabilityIsLoading, isError: isAvailabilityError } = useGetCalendarAvailability(
        profile?.scheduler_id,
        user?._id,
        {
            enabled: Boolean(profile.scheduler_id)
        }
    );

    const conferencingProfile = useMemo(() => {
        let profiles: ICalendarConferencingOptions[] = [];
        if (calendarProfile) {
            const conferencingProfiles = (calendarProfile as ICalendarAccount)[
                "cronofy.data"
            ]?.conferencing_profiles;
            const confKeys: IConferencingProfile["provider_name"][] = [
                "ms_teams",
                "zoom"
            ];

            profiles = confKeys
                .map((c) => {
                    const confOption = (CONFERENCING_PROFILES as any)[c] as ICalendarConferencingOptions;

                    confOption.isConnected = conferencingProfiles.find(
                        ({ provider_name }) => provider_name === c
                    )?.profile_connected
                        ? true
                        : false;

                    return confOption;
                })
                .filter((a) => a.isConnected);

            console.log(profiles)
        }
        return profiles;
    }, [calendarProfile]);

    return (
        <>
            <Typography variant='body1'>
                {isLoading && <RectangleSkeleton />}
                {!isLoading && <Status type={(calendarProfile && !isError) ? 'success' : 'warning'} text={(calendarProfile && !isError) ? 'Connected' : 'Not connected'} />}
            </Typography>
            <Typography variant='body1'>
                {availabilityIsLoading && <RectangleSkeleton />}
                {!availabilityIsLoading && <Status type={(availability && !isAvailabilityError) ? 'success' : 'tertiary'} text={(availability && !isAvailabilityError) ? 'Updated' : 'Pending'} />}
            </Typography>
            <Typography variant='body1'>
                {isLoading && <RectangleSkeleton />}
                {(!isLoading && calendarProfile && calendarProfile?.['cronofy.data']?.conferencing_profiles?.filter(c => c.profile_connected).length > 0) ?
                    <Stack direction="row" gap={4}>
                        {conferencingProfile?.map((conf) => (
                            <Typography key={conf.type}
                                fontWeight="bold"
                                className="flex flex-row items-center gap-1 text-xs"
                            >
                                <span className='text-lg'>{conf.icon}</span>
                                {conf.type}
                            </Typography>

                        ))}
                    </Stack>
                    : <Status type='warning' text='Not connected' />}
            </Typography>
        </>
    )
}
