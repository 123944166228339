import { Typography, Button } from "knack-ui";
import styled from "styled-components";
import EmptyState from "../EmptyState/EmptyState";
import SearchInput from "../SearchInput/SearchInput";
import SVG from "../SVG/SVG";

interface ITransferListProps<T> {
  renderSelectedItem: (item: T) => JSX.Element;
  renderUnselectedItem: (item: T) => JSX.Element;
  unselectedItems: T[];
  selectedItems: T[];
  searchProps?: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
  };
  onSelectAll: () => void;
  onDeselectAll: () => void;
  emptyStateProps?: {
    whenAllItemsAreSelected?: string;
    whenNoItemsAreSelected?: string;
  };
  selectedItemsListTitle?: string;
}

function TransferList<T>({
  renderSelectedItem,
  renderUnselectedItem,
  selectedItems,
  unselectedItems,
  emptyStateProps,
  selectedItemsListTitle,
  searchProps,
  onDeselectAll,
  onSelectAll
}: ITransferListProps<T>) {
  return (
    <Wrapper>
      <SecondList searchEnabled={Boolean(searchProps)}>
        {searchProps && (
          <SearchInputWrapper>
            <SearchInput placeholder={searchProps.placeholder} value={searchProps.value} onChange={searchProps.onChange} />
          </SearchInputWrapper>
        )}

        {unselectedItems.length === 0 && searchProps && searchProps?.value !== "" && (
          <EmptyStateWrapper>
            <EmptyState title={`No search results for "${searchProps?.value}"`} subtitle="Try using a different spelling" />
          </EmptyStateWrapper>
        )}
        {emptyStateProps?.whenAllItemsAreSelected && unselectedItems.length === 0 && (!searchProps || searchProps?.value === "") && (
          <EmptyStateWrapper>
            <EmptyState title={emptyStateProps.whenAllItemsAreSelected} />
          </EmptyStateWrapper>
        )}
        {unselectedItems.length > 0 && (
          <ItemsWrapper className="scrollhost2">{unselectedItems.map((c) => renderUnselectedItem(c))}</ItemsWrapper>
        )}
      </SecondList>
      <Controls>
        <ControlButton onClick={onSelectAll} title="Select all" kind="primaryOutline" iconOnly>
          <SVG size={25} name="ChevronsRight" />
        </ControlButton>
        <ControlButton onClick={onDeselectAll} title="Deselect all" kind="primaryOutline" iconOnly>
          <SVG size={25} name="ChevronsLeft" />
        </ControlButton>
      </Controls>
      <FirstList>
        {selectedItems.length > 0 && (
          <>
            {selectedItemsListTitle && (
              <SearchInputWrapper>
                <Typography variant="body1" fontWeight="bold">
                  {selectedItemsListTitle}
                </Typography>
              </SearchInputWrapper>
            )}
            <ItemsWrapper className="scrollhost2">{selectedItems.map((c) => renderSelectedItem(c))}</ItemsWrapper>
          </>
        )}
        {emptyStateProps?.whenNoItemsAreSelected && selectedItems.length === 0 && (
          <EmptyStateWrapper>
            <EmptyState title={emptyStateProps.whenNoItemsAreSelected} />
          </EmptyStateWrapper>
        )}
      </FirstList>
    </Wrapper>
  );
}

export default TransferList;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px 1fr;
  gap: 24px;
  height: 400px;
`;

const BaseList = styled.div`
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-border);
  border-radius: 12px;
  height: 100%;
  overflow-y: auto;
`;

const FirstList = styled(BaseList)``;
const SecondList = styled(BaseList)<{ searchEnabled?: boolean }>`
  display: grid;
  grid-template-rows: ${(p) => (p.searchEnabled ? "auto 1fr" : "1fr")};
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;
const ControlButton = styled(Button)`
  display: flex;
`;

const SearchInputWrapper = styled.div`
  border-bottom: 1px solid var(--color-border);
  padding: 16px;
`;
const EmptyStateWrapper = styled.div`
  padding: 16px;
  height: 100%;
`;
const ItemsWrapper = styled.div`
  max-block-size: 100%;
  overflow-y: auto;
  overscroll-behavior-y: contain;
`;
