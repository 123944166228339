export const MetricCard = ({ title, value }) => {
  return (
    <div className="pb-0 h-full">
      <div className="shadow-sm rounded-2xl p-4 bg-white dark:bg-gray-800 border-gray-100 border-2 h-full">
        <div className="flex items-center">
          <p className="text-md text-gray dark:text-gray text-skin-base">
            {title}
          </p>
        </div>

        <div className="flex flex-col justify-start mt-2">
          <p className="text-gray-700 dark:text-gray-100 text-4xl text-left font-bold text-skin-base whitespace-nowrap">
            {value}
          </p>
        </div>
      </div>
    </div>
  );
};
