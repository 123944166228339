import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { IUser } from "../User/UserTypes";

export interface INVD_CRED_RES {
  message: string;
  attempts: number;
  blocked: boolean;
  email: string;
}
interface INestedDataResponse {
  url?: string;
}

const loginUser = async ({
  email,
  password
}: {
  email: string;
  password: string;
}) => {
  const res = await apiHttp.post<
    ServerResponse<
      | { attempts: number; blocked: boolean; email: string }
      | "Account is temporarily blocked"
      | "Incorrect credentials provided"
      | INestedDataResponse
    >
  >("v1/auth/login", { email, password });
  if (res.data.status === false) {
    if (
      res.data.data === "Account is temporarily blocked" ||
      res.data.data === "Incorrect credentials provided"
    ) {
      // eslint-disable-next-line no-throw-literal
      throw { message: res.data.data };
    } else {
      // eslint-disable-next-line no-throw-literal
      throw { message: res.data.message, ...res.data.data };
    }
  } else {
    if ((res.data.data as INestedDataResponse)?.url) {
      const url = (res.data.data as INestedDataResponse)?.url as string;
      window.location.replace(url);
      return { url };
    } else if (res.data.message === "Password is required") {
      return { message: "Password is required" };
    }
    return { message: "success" };
  }
};

const validateOtp = async ({ email, otp }: { email: string; otp: string }) => {
  const res = await apiHttp.post<
    ServerResponse<(IUser & { authToken: string }) | "Invalid or expired OTP">
  >("v1/auth/verify-otp", {
    email,
    otp
  });
  if (res.data.status === false) {
    if (res.data.data === "Invalid or expired OTP") {
      // eslint-disable-next-line no-throw-literal
      throw { message: res.data.data };
    } else {
      // eslint-disable-next-line no-throw-literal
      throw { message: res.data.message };
    }
  } else {
    return res.data.data as IUser & { authToken: string };
  }
};

export const useLogin = () => {
  return useMutation(loginUser, { onError: (e) => {} });
};

export const useValidateOtp = () => {
  return useMutation(validateOtp);
};
