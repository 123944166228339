import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

const getLearnings = (query, page) => {
  return axios.get(API_URL + "learnings/?page=" + page, {
    headers: authHeader()
  });
};

const getLearningsBase = (query) => {
  return axios.get(API_URL + "learnings/" + query, {
    headers: authHeader()
  });
};

const getJourneys = (page) => {
  return axios.get(API_URL + "learnings/journeys?page=" + page, {
    headers: authHeader()
  });
};

const getJourneyDetails = (id) => {
  return axios.get(API_URL + "learnings/journeys/" + id, {
    headers: authHeader()
  });
};

const getSubscriptions = () => {
  return axios.get(API_URL + "learnings/subscriptions", {
    headers: authHeader()
  });
};

const getBookmarked = () => {
  return axios.get(API_URL + "learnings/bookmarks", {
    headers: authHeader()
  });
};

const getFilters = () => {
  return axios.get(API_URL + "learnings/filters/", {
    headers: authHeader()
  });
};

const markStarted = (resource_id) => {
  return axios
    .post(
      API_URL + "learnings/start",
      {
        resource_id
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const markCompleted = (status, resource_id) => {
  return axios.post(
    API_URL + "learnings/complete",
    { status, resource_id },
    { headers: authHeader() }
  );
};

const bookmarkArticle = (status, resource_id) => {
  return axios
    .post(
      API_URL + "learnings/bookmarks",
      {
        status,
        resource_id
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const subscribeJourney = (status, journey_id) => {
  return axios
    .post(
      API_URL + "learnings/subscriptions",
      {
        status,
        journey_id
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

export default {
  getLearnings,
  getFilters,
  markStarted,
  markCompleted,
  bookmarkArticle,
  subscribeJourney,
  getJourneys,
  getJourneyDetails,
  getSubscriptions,
  getBookmarked,
  getLearningsBase
};
