import { Box, Breadcrumbs, Stack, Typography } from "knack-ui";
import { Link } from "react-router-dom";
import FiltersBar from "../../../components/FiltersBar";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { useState } from "react";
import { useActiveUserFilters } from "../../ActiveUserList/hooks/ActiveUser.hooks";
import MetricCard from "../../../components/Metrics/MetricCard";
import { Information } from "../../../components/ToastNotification/Information";
import { TMiscellaneous, useMiscellaneousRequest } from "../miscellaneousRequestMutation";
import { useUser } from "../../User/userQueries";

const MiscellaneousReportPage = () => {
  const { data: user } = useUser();
  const { mutateAsync: requestReport } = useMiscellaneousRequest();
  const { setLoading } = useLoadingSpinner();
  const [query, setQuery] = useState({
    organization: []
  } as any);

  const filters = useActiveUserFilters({
    query,
    onQueryChange: setQuery
  });

  const onRequestMiscellaneousReport = async (type: TMiscellaneous) => {
    if (query.organization.length === 0) {
      Information("Please select an organization");
      return;
    }
    try {
      setLoading(true);
      await requestReport({ type, organization: query.organization });
      setLoading(false);
      Information(`Report has been sent to ${user?.email}`);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Miscellaneous report</Typography>]}</Breadcrumbs>
      </Box>
      <Box variant="outlined" className="grid grid-cols-1 gap-6 px-6 py-4 ">
        <div className="pb-6 border-b">
          <Typography fontWeight="semibold" className="mb-4">
            Filters
          </Typography>
          <FiltersBar query={query} filters={filters} />
        </div>

        <Stack>
          <MetricCard
            clamp={0}
            icon="Question"
            title=""
            value="Can we have a list of the users who has done the assessment, with the dates they took it on, along with how many attempts?"
            rightButton={{
              text: "Export",
              onClick: () => onRequestMiscellaneousReport("Assessment Audit Trail")
            }}
          />
        </Stack>
      </Box>
    </div>
  );
};

export default MiscellaneousReportPage;
