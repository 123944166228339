import { BaseDialog, Box, Button, Checkbox, Input, Stack, Typography } from "knack-ui";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import SVG from "../../../components/SVG/SVG";
import { useLogin } from "../../../features/Auth/authQueries";
import { useEffect, useState } from "react";
import OTPSection from "./OTPSection";
import AccountLockDialog from "../../../features/Auth/ui/AccountLockDialog";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../components/Asset/logo.svg";
import create from "zustand";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { emailRegex } from "../../../lib/constants";
import { useUpdateAccessToken } from "../../../features/Auth/authMutations";

interface LoginForm {
  email: string;
  password?: string;
  tos: boolean;
}

interface LoginStoreProps {
  email: string;
  setEmail: (email: string) => void;
  password?: string;
  setPassword: (password: string) => void;
}

export const LoginStore = create<LoginStoreProps>((set) => ({
  email: "",
  setEmail: (email: string) => set((state) => ({ ...state, email })),
  password: "",
  setPassword: (password: string) => set((state) => ({ ...state, password }))
}));

export const LoginPagesTransition: Variants = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  exit: {
    y: 200,
    opacity: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3
    }
  }
};

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setEmail, setPassword } = LoginStore();
  const { mutateAsync: onLogin, isLoading } = useLogin();
  const {
    register,
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors }
  } = useForm<LoginForm>({ defaultValues: { tos: true } });
  const [showPwd, showHidePwd] = useState<boolean>(false);
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_BASE_APP_URL);
  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      setErrorMessage("");
      const res = await onLogin({
        email: data.email,
        password: data.password as string
      });
      if (res.message === "Password is required") {
        showHidePwd(true);
      }
      if (res.message === "success") {
        setShowOtp(true);
        setEmail(data.email);
        setPassword(data?.password as string);
      }
    } catch (error: any) {
      if (error.message === "Incorrect credentials provided") {
        setError("email", { type: "validate" });
        setError("password", { type: "validate" });
        setErrorMessage(error.message);
        if (error.blocked) {
          setErrorMessage("Account is temporarily blocked");
          setShowBlockedModal(true);
          return;
        }
        if (error.attempts > 2) {
          setErrorMessage(`You have ${5 - error.attempts} attempts left before your account gets temporarily locked`);
        }
      } else if (error.message === "Account is temporarily blocked") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex items-center justify-center h-screen m-auto" style={{ maxWidth: 487.93 }}>
      <AnimatePresence exitBeforeEnter>
        {!showPwd && !showOtp && (
          <Box
            className="w-full"
            key="login"
            as={motion.form}
            onSubmit={handleSubmit(onSubmit)}
            elevation={2}
            paddingPreset="card"
            variants={LoginPagesTransition}
            exit="exit"
            initial="hidden"
            animate="visible"
          >
            <div className="mb-4">
              <img src={logo} alt="Knack logo" />
            </div>
            <Typography className="mb-4" variant="h5" as="h2">
              Login
            </Typography>
            <Stack direction="column" gap={6} className="my-6">
              <div>
                <Typography className="block mb-2" htmlFor="email" variant="body1" as="label">
                  Email
                </Typography>
                <Input
                  {...register("email", {
                    required: "Required field",
                    pattern: {
                      value: emailRegex,
                      message: "Please provide a valid email address"
                    }
                  })}
                  id="email"
                  size="large"
                  inputPrefix={<SVG name="Person" />}
                  error={errors.email?.message || errors.email?.type === "validate"}
                />
              </div>

              {errorMessage && (
                <Typography
                  as={Stack}
                  alignItems="center"
                  gap={2}
                  fontWeight="semibold"
                  justifyContent="center"
                  variant="body2"
                  className="mt-4"
                  color="warning"
                >
                  <SVG name="Danger" />
                  <span>{errorMessage}</span>
                </Typography>
              )}
            </Stack>
            <Stack direction="column" gap={4}>
              <Button isLoading={isLoading} disabled={!watch("tos")} variant="large" type="submit">
                {showPwd ? "Login" : "Continue"}
              </Button>
              <Button
                as={Link}
                to="/auth/forgot-password"
                kind="ghost"
                // className={showPwd ? "" : "hidden"}
              >
                Forgot password?
              </Button>
            </Stack>
          </Box>
        )}
        {!showOtp && showPwd && (
          <Box
            className="w-full"
            key="pwd"
            as={motion.form}
            onSubmit={handleSubmit(onSubmit)}
            elevation={2}
            paddingPreset="card"
            variants={LoginPagesTransition}
            exit="exit"
            initial="hidden"
            animate="visible"
          >
            <div className="mb-4">
              <img src={logo} alt="Knack logo" />
            </div>
            <Typography className="mb-4" variant="h5" as="h2">
              Login
            </Typography>
            <Stack direction="column" gap={6} className="my-6">
              <div>
                <Typography className="block mb-2" htmlFor="email" variant="body1" as="label">
                  Email
                </Typography>
                <Input
                  {...register("email", {
                    required: "Required field",
                    pattern: {
                      value: emailRegex,
                      message: "Please provide a valid email address"
                    }
                  })}
                  id="email"
                  size="large"
                  inputPrefix={<SVG name="Person" />}
                  error={errors.email?.message || errors.email?.type === "validate"}
                />
              </div>

              <div>
                <Typography className="block mb-2" htmlFor="password" variant="body1" as="label">
                  Password
                </Typography>
                <Input
                  size="large"
                  inputClassName="outlinbe"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  {...register("password", { required: "Required field" })}
                  inputPrefix={<SVG name="LockOpen" />}
                  inputSuffix={
                    <Button onClick={toggleShowPassword} iconOnly variant="small" kind="ghost">
                      <SVG name={showPassword ? "EyeClosed" : "EyeOpen"} />
                    </Button>
                  }
                  error={errors.password?.type === "validate"}
                />
              </div>

              <Stack alignItems="center" gap={2}>
                <Controller
                  control={control}
                  name="tos"
                  render={({ field: { value, onChange } }) => (
                    <div className="flex-shrink-0">
                      <Checkbox checked={value} onChange={onChange} id="tos" />
                    </div>
                  )}
                />
                <Typography htmlFor="tos" variant="subtitle2" as="label">
                  I accept the Knack{" "}
                  <a className="text-skin-accent" href="https://knacklab.co/terms/" target="_blank" rel="noreferrer">
                    Terms & Conditions
                  </a>{" "}
                  and have read the{" "}
                  <a className="text-skin-accent" href="https://knacklab.co/privacy/" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>
                  .
                </Typography>
              </Stack>
              {errorMessage && (
                <Typography
                  as={Stack}
                  alignItems="center"
                  gap={2}
                  fontWeight="semibold"
                  justifyContent="center"
                  variant="body2"
                  className="mt-4"
                  color="warning"
                >
                  <SVG name="Danger" />
                  <span>{errorMessage}</span>
                </Typography>
              )}
            </Stack>
            <Stack direction="column" gap={4}>
              <Button isLoading={isLoading} disabled={!watch("tos")} variant="large" type="submit">
                {showPwd ? "Login" : "Continue"}
              </Button>
              <Button as={Link} to="/auth/forgot-password" kind="ghost" className={showPwd ? "" : "hidden"}>
                Forgot password?
              </Button>
            </Stack>
          </Box>
        )}
        {showOtp && <OTPSection setShowOtp={setShowOtp} onSubmit={onSubmit as any} />}
      </AnimatePresence>
      <BaseDialog dialogClassName="max-w-lg" onClose={() => setShowBlockedModal(false)} isOpen={showBlockedModal}>
        <AccountLockDialog />
      </BaseDialog>
    </div>
  );
};

export default Login;
