import {
  QueryFunctionContext,
  useQuery,
  UseQueryOptions
} from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import { ICampaign } from "./CampaignTypes";

export const campaignQueryKeys = {
  all: [{ scope: "campaign" }] as const,
  campaign: (id?: string) => [{ ...campaignQueryKeys.all[0], id }] as const
};

async function fetchCampaigns() {
  const res = await apiHttp.get<ServerResponse<ICampaign[]>>(
    "v1/auth/campaigns/"
  );
  return res.data.data.reverse();
}

async function fetchOneCampaign({
  queryKey: [{ id }]
}: QueryFunctionContext<ReturnType<typeof campaignQueryKeys["campaign"]>>) {
  if (!id) {
    return Promise.reject("campaign ID not provided");
  }
  const res = await apiHttp.get<ServerResponse<ICampaign>>(
    "v1/auth/campaigns/" + id
  );
  return res.data.data;
}

// Queries
export const useGetCampaigns = <SelectData = ICampaign[], Error = unknown>(
  options?: UseQueryOptions<ICampaign[], Error, SelectData>
) => {
  return useQuery<ICampaign[], Error, SelectData>(
    campaignQueryKeys.all,
    fetchCampaigns,
    {
      staleTime: DURATIONS.fifteenMins,
      ...options
    }
  );
};

export const useGetOneCampaign = <SelectData = ICampaign, Error = unknown>(
  {
    id
  }: {
    id?: string;
  },
  options?: UseQueryOptions<
    any,
    Error,
    SelectData,
    ReturnType<typeof campaignQueryKeys["campaign"]>
  >
) => {
  // const queryClient = useQueryClient();
  return useQuery(campaignQueryKeys.campaign(id), fetchOneCampaign, {
    staleTime: DURATIONS.fifteenMins,
    suspense: true,
    // initialData: () => {
    //   const availableCampaigns = queryClient.getQueryData<ICampaign[]>(
    //     campaignQueryKeys["all"]
    //   );
    //   return availableCampaigns?.find((c) => c._id === id);
    // },
    // initialDataUpdatedAt: () => {
    //   const availableCampaigns = queryClient.getQueryState<ICampaign>(
    //     campaignQueryKeys["all"]
    //   );
    //   return availableCampaigns?.dataUpdatedAt;
    // },
    ...options
  });
};
