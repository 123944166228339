import axios from "axios";
import authHeader from "./auth-header";
import authService from "./auth.service";
import { API_URL } from "./base-api";

export const getUser = () => {
  return axios.get(API_URL + "v1/profile", { headers: authHeader() });
};

export const getProfile = (id) => {
  return axios.get(API_URL + "v1/profile/" + id, { headers: authHeader() });
};

export const getTeam = (id) => {
  return axios.get(API_URL + "v1/teams/" + id, { headers: authHeader() });
};

/**
 * Fetchs a user profile and saves to localStorage
 * @returns
 */
export const getUserProfile = (callback) => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  if (user) {
    getProfile(user.data._id).then((res) => {
      if (res.data.status) {
        localStorage.setItem("profile", JSON.stringify(res.data));
        if (callback) {
          callback();
        }
      }
    });
  }
};

const getEmployeeContent = () => {
  return axios.get(API_URL + "v1/employee", { headers: authHeader() });
};

const getHrContent = () => {
  return axios.get(API_URL + "v1/hr", { headers: authHeader() });
};

const getCoachContent = () => {
  return axios.get(API_URL + "v1/coach", { headers: authHeader() });
};

export const getAllUsers = (query = "") => {
  return axios.get(API_URL + "v1/auth/users" + query, {
    headers: authHeader()
  });
};

export const logoutAfter24Hours = () => {
  // check application version and logout if not latest value
  const LAST_APPLICATION_VERSION = localStorage.getItem(
    "[LAST_APPLICATION_VERSION]"
  );
  if (
    LAST_APPLICATION_VERSION &&
    LAST_APPLICATION_VERSION !== authService.UPDATED_APPLICATION_VERSION
  ) {
    // Trigger logout
    authService.logout();
    return;
  }

  // logout after 24 hrs bcos token will expire
  const LAST_LOGIN_ATTEMPT = localStorage.getItem("[LAST_LOGIN_ATTEMPT]");

  if (
    LAST_LOGIN_ATTEMPT &&
    new Date(LAST_LOGIN_ATTEMPT).getUTCDate() < new Date().getUTCDate()
  ) {
    // Trigger logout
    authService.logout();
    return;
  }
};

export default {
  getTeam,
  getEmployeeContent,
  getHrContent,
  getCoachContent,
  getUser,
  getProfile,
  getAllUsers
};

export const UserService = {
  getTeam,
  getEmployeeContent,
  getHrContent,
  getCoachContent,
  getUser,
  getProfile,
  getAllUsers
};
