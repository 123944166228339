import { Feedback360SkillsBreackdownCollapsiblePanel } from "../Collapsible/Feedback360SkillsBreackdownCollapsiblePanel";
import { getDescription } from "../../services/utils/subtitlesAndDescriptions";
import { SmallInformation } from "../Information/SmallInformation";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "knack-ui";
import { useState } from "react";
import RequestFeedbackDialog from "../../V3/Pages/Assessments/ui/RequestFeedbackDialog";

export const Feedback360SkillsResultBreakdown = ({ expanded, stateData = [], userName, canRequest = true }) => {
  const { t } = useTranslation(["assessments"]);

  const [requestFeedbackDialogOpen, setRequestFeedbackDialogOpen] = useState(false);

  const handleToggleFeedbackDialog = () => {
    setRequestFeedbackDialogOpen(!requestFeedbackDialogOpen);
  };
  return (
    <>
      <BaseDialog dialogClassName="max-w-5xl" isOpen={requestFeedbackDialogOpen} onClose={handleToggleFeedbackDialog}>
        <RequestFeedbackDialog handleClose={handleToggleFeedbackDialog} />
      </BaseDialog>
      {stateData.length ? (
        <div className="w-full">
          {stateData.length > 0 && (
            <div className="flex justify-end flex-end">
              <SmallInformation />
            </div>
          )}
          <div className="flex flex-col w-full gap-4">
            {stateData.map((skill, i) => (
              <label key={i} className="block w-full p-4 bg-white rounded-lg shadow border-bottom">
                <Feedback360SkillsBreackdownCollapsiblePanel
                  expanded={expanded}
                  scores={skill.scores}
                  SectionName={t(skill.key)}
                  SectionDescription={getDescription(skill.key)}
                />
              </label>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="max-w-screen-md px-4 py-2 mx-auto text-center rounded-lg text-skin-base bg-skin-fill-light sm:p-16 sm:mt-32"
          style={{ width: "fit-content" }}
        >
          <div className="text-2xl font-extrabold sm:text-3xl">360 Feedback</div>
          <div className="mt-2 text-base text-skin-muted">Feedback report will appear here when it is available </div>
          {canRequest && (
            <button
              className="px-4 py-2 mt-5 text-sm text-white rounded-lg bg-skin-fill"
              onClick={async () => {
                // const result = await CustomDialog(<RequestFeedback />, {
                //   title: "Request 360 Feedback",
                //   showCloseIcon: true,
                //   defaultBodyOverflow: "hidden",
                //   className: "request-feedback-modal text-skin-accent"
                // });
                handleToggleFeedbackDialog();
              }}
            >
              {" "}
              Request feedback
            </button>
          )}
        </div>
      )}
    </>
  );
};
