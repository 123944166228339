import { Typography } from "knack-ui";

interface IProps {
  text: string;
  className?: string;
}
const DividerWithText = ({ text, className }: IProps) => {
  return (
    <div className={`flex items-center space-x-4 flex-nowrap ${className}`}>
      <span className="flex-1 border-t" />
      <Typography color="muted" variant="subtitle2">
        {text}
      </Typography>
      <span className="flex-1 border-t" />
    </div>
  );
};

export default DividerWithText;
