import { DateRange, DefinedRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangeFilterOptions } from "../FiltersBar/FiltersBar.types";

export const ReactDateRange = ({
  onChange,
  ranges
}: DateRangeFilterOptions) => {
  return (
    <>
      <DefinedRange
        ranges={ranges}
        onChange={(item) => {
          onChange([item.selection]);
        }}
        inputRanges={[]}
      />
      <DateRange
        onChange={(item) => {
          onChange([item.selection]);
        }}
        // months={2}
        ranges={ranges}
        // direction="vertical"
        rangeColors={["#2c4be7"]}
      />
    </>
  );
};
