import axios from "axios";
import authHeader from "./auth-header";

export const FRONTEND_URL = process.env.REACT_APP_BASE_APP_URL;
export const API_URL = process.env.REACT_APP_BASE_API;

function fileUploadAuthHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.data.authToken) {
    return {
      "x-access-token": user.data.authToken,
      "Content-Type": "multipart/form-data"
    };
  } else return {};
}

export const uploadFormData = (formdata) => {
  return axios.post(API_URL + "upload/aws-s3", formdata, {
    headers: fileUploadAuthHeader()
  });
};

export const updateProfile = (profile, query = "") => {
  return axios.post(
    API_URL + "v1/profile/update" + query,
    { ...profile },
    { headers: authHeader() }
  );
};

export const updatePendingProfile = (profile) => {
  return axios.post(
    API_URL + "v1/profile/pending-profile",
    { ...profile },
    { headers: authHeader() }
  );
};

export const updateMenteeProfile = (profile) => {
  return axios.post(
    API_URL + "v1/profile/init",
    { ...profile },
    { headers: authHeader() }
  );
};

export const createProfile = (profile) => {
  return axios.post(
    API_URL + "v1/profile/init",
    { ...profile },
    { headers: authHeader() }
  );
};

export const deleteProgramInvite = (programId, { _id }) => {
  if (_id) {
    return axios.delete(
      API_URL + "v1/programs/" + programId + "/" + _id + "/invites",
      { headers: authHeader() }
    );
  } else {
    return axios.delete(API_URL + "v1/programs/" + programId + "/invites", {
      headers: authHeader()
    });
  }
};

export const sendProgramInvitation = (programId, data) => {
  return axios.post(API_URL + "v1/programs/" + programId + "/invites", data, {
    headers: authHeader()
  });
};

export const updateProgramInvitation = (inviteId, updates) => {
  return axios.post(API_URL + "v1/programs/invites/" + inviteId, updates, {
    headers: authHeader()
  });
};

export const triggerExternalCoachingMatching = (programId, userId) => {
  return axios.get(API_URL + `v1/profile/match-user/${programId}/${userId}`, {
    headers: authHeader()
  });
};

export const addProgramparticipants = (programId, data) => {
  return axios.post(
    API_URL + "v1/programs/" + programId + "/updateByEmail",
    data,
    { headers: authHeader() }
  );
};

export const axiosBatchRequests = (requests) => {
  return axios.all(requests);
};
