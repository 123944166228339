import { Button } from "knack-ui";
import { useTranslation } from "react-i18next";

const LanguageToggler = ({ reload }: { reload?: boolean }) => {
  const { i18n } = useTranslation();
  const handleChangeLanguage = () => {
    if (i18n.resolvedLanguage === "ar") {
      i18n.changeLanguage("en");
      reload && window.location.reload();
      return;
    }
    reload && window.location.reload();
    i18n.changeLanguage("ar");
  };
  return (
    <Button
      kind="tertiary"
      className="p-2 text-sm rounded text-skin-accent bg-skin-light"
      onClick={handleChangeLanguage}
    >
      {i18n.resolvedLanguage === "en" ? "العربية" : "English"}
    </Button>
  );
};

export default LanguageToggler;
