import { useGetPlatformPrograms } from "./../../Programs/programQueries";
import { useGetOrganizations } from "./../../Company/companyQueries";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Filter } from "../../../components/FiltersBar/FiltersBar.types";
import { PairingReportsQuery } from "../../AnalyticsReports/analyticsReportsTypes";
import { IConnectionRequest } from "../../Connections/ConnectionsTypes";
import { useGetOrganizationPrograms } from "../../Programs/programQueries";
import { IProgram } from "../../Programs/ProgramTypes";
import { useGetCompanyPositions, useUser, useUserCompany } from "../../User/userQueries";
import { IOrganization } from "../../User/UserTypes";

/**
 * Depending on the query, we will return the related filters.
 */
interface ISessionFilterProps {
  query: PairingReportsQuery;
  onQueryChange: Dispatch<SetStateAction<PairingReportsQuery>>;
}

export const usePairingFilters = ({ query, onQueryChange }: ISessionFilterProps) => {
  const { data: user } = useUser();
  const { data: userOrganization } = useUserCompany({ enabled: user?.account_type === "Hr" });
  const { data: organizations } = useGetOrganizations({ enabled: user?.account_type === "Admin" });

  const { data: userPrograms } = useGetOrganizationPrograms(
    {
      organizationId: userOrganization?._id
    },
    { enabled: user?.account_type === "Hr" }
  );

  const { data: programs } = useGetPlatformPrograms({}, { enabled: user?.account_type === "Admin" });

  const { data: positions } = useGetCompanyPositions();

  const resources = useMemo(() => {
    if (organizations) {
      return {
        departments: organizations.map((o) => o.departments).flat(),
        entities: organizations.map((o) => o.entities).flat(),
        management_levels: organizations.map((o) => o.management_levels).flat(),
        programs
      };
    }

    return {
      departments: userOrganization?.departments || [],
      entities: userOrganization?.entities || [],
      management_levels: userOrganization?.management_levels || [],
      programs: userPrograms
    };
  }, [userOrganization, organizations, programs, userPrograms]);

  const filters = useMemo(() => {
    const f: Filter<keyof PairingReportsQuery, any>[] = [];

    Object.keys(query).forEach((key) => {
      switch (key) {
        case "organization":
          if (!organizations) break;
          const organization: Filter<"organization", IOrganization> = {
            label: "Organizations",
            name: "organization",
            type: "default",
            options: {
              renderText: (o) => o.name,
              data: organizations,
              searchCriteria: (option, keyword) => {
                return option.name?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.organization!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.organization!, newValue._id],
                  type: "organization"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "organization"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.organization!.filter((id) => id !== option._id),
                  type: "organization"
                });
              }
            }
          };
          f.push(organization);
          break;

        case "program":
          if (!resources) break;
          const program: Filter<"program", IProgram> = {
            label: "Program",
            name: "program",
            type: "default",
            options: {
              renderText: (o) => o.name,
              data: resources.programs || [],
              searchCriteria: (option, keyword) => {
                return option.name?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.program!.includes(o._id),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.program!, newValue._id],
                  type: "program"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "program"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.program!.filter((id) => id !== option._id),
                  type: "program"
                });
              }
            }
          };
          f.push(program);
          break;
        case "status":
          const status: Filter<"status", IConnectionRequest["status"]> = {
            label: "Status",
            name: "status",
            type: "default",
            options: {
              renderText: (o) => (o === "approved" ? "Paired" : o === "pending" ? "Pending request" : "Ended relationship"),
              data: ["approved", "cancelled", "pending"],
              // searchCriteria: (option, keyword) => {
              //   return option?.toLowerCase().indexOf(keyword) !== -1;
              // },
              isOptionSelected: (o) => query.status!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.status!, newValue],
                  type: "status"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "status"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.status!.filter((id) => id !== option),
                  type: "status"
                });
              }
            }
          };
          f.push(status);
          break;
        case "entities":
          if (!resources) return;
          const entity: Filter<"entities", string> = {
            label: "Entity",
            type: "default",
            name: "entities",
            options: {
              renderText: (o) => o,
              data: resources.entities,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.entities!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.entities!, newValue],
                  type: "entities"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "entities"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.entities!.filter((id) => id !== option),
                  type: "entities"
                });
              }
            }
          };
          f.push(entity);
          break;
        case "management_level":
          if (!resources) return;
          const management_level: Filter<"management_level", string> = {
            label: "Management level",
            name: "management_level",
            type: "default",
            options: {
              renderText: (o) => o,
              data: resources.management_levels,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.management_level!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.management_level!, newValue],
                  type: "management_level"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "management_level"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.management_level!.filter((id) => id !== option),
                  type: "management_level"
                });
              }
            }
          };
          f.push(management_level);
          break;
        case "department":
          if (!resources) return;
          const department: Filter<"department", string> = {
            label: "Department",
            name: "department",
            type: "default",
            options: {
              renderText: (o) => o,
              data: resources.departments,
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.department!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.department!, newValue],
                  type: "department"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "department"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.department!.filter((id) => id !== option),
                  type: "department"
                });
              }
            }
          };
          f.push(department);
          break;
        case "position":
          if (!resources) return;
          const position: Filter<"position", string> = {
            label: "Position",
            name: "position",
            type: "default",
            options: {
              renderText: (o) => o,
              data: positions || [],
              searchCriteria: (option, keyword) => {
                return option?.toLowerCase().indexOf(keyword) !== -1;
              },
              isOptionSelected: (o) => query.position!.includes(o),
              onChange: (newValue) => {
                onOptionsChange({
                  newValue: [...query.position!, newValue],
                  type: "position"
                });
              },
              onOptionsClear: () => {
                onOptionsChange({
                  newValue: [],
                  type: "position"
                });
              },
              onOptionRemove: (option) => {
                onOptionsChange({
                  newValue: query.position!.filter((id) => id !== option),
                  type: "position"
                });
              }
            }
          };
          f.push(position);
          break;
      }
    });

    return f;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOrganization, programs, query]);

  const onOptionsChange = <T extends keyof PairingReportsQuery>({ newValue, type }: { type: T; newValue: PairingReportsQuery[T] }) => {
    onQueryChange((state) => ({ ...state, [type]: newValue }));
  };
  return filters;
};
