import {
  Drawer
} from "knack-ui";
import { useState, memo } from "react";
import EmptyState from "../../../components/EmptyState/EmptyState";
import SessionDetailsDrawer from "../../../V3/Pages/ConnectionProfile/ui/ConnectionSessions/SessionDetailsDrawer";
import { TSessionWithProgram } from "../SessionsTypes";
import { SessionCard } from './SessionCard';

interface ISessionListProps {
  sessions: TSessionWithProgram[];
}

const SessionsList = ({ sessions }: ISessionListProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSession, setSelectedSession] =
    useState<TSessionWithProgram | null>(null);

  return (
    <div className="h-full p-8">
      {sessions?.length === 0 && (
        <EmptyState
          key="empty"
          iconSize={60}
          icon="SessionsEmptyState"
          title="No sessions found"
        />
      )}
      <div dir="ltr" className="relative grid gap-4 py-4 ">
        <div
          className="absolute top-0 bottom-0 w-1 h-full bg-gray-100"
          style={{ left: 84, zIndex: 0 }}
        ></div>
        {sessions.map((s, i) => (
          <SessionCard key={i} setDrawerOpen={setDrawerOpen} session={s} setSelectedSession={setSelectedSession} />
        ))}
      </div>

      <Drawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <SessionDetailsDrawer
          session={selectedSession as TSessionWithProgram}
          onClose={() => setDrawerOpen(false)}
        />
      </Drawer>
    </div>
  );
};

export default memo(SessionsList);
