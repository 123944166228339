import React, {  useState } from "react";
import CoachData from "./CoachData";

function CoachProfileCard({ coachData }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {coachData.map((s, i) => (
        <div
          key={i}
          className="block w-full border-bottom bg-white p-4 rounded-lg shadow py-3"
        >
          <div className="w-full bg-white py-3">
            <div className="py-3">
              <div className="">
                {/* RESPONSIVE HEADER */}
                <div
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {/* Mobile Header */}
                  <div className="sm:hidden">
                    <div className="flex flex-row justify-between items-center">
                      {/* Droplet Arrow */}
                      <div
                        className={`translate transform delay-300 duration-300 ${
                          open ? "" : "-rotate-90"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6"
                          viewBox="0 0 18 9"
                          fill="#ddd"
                        >
                          <path
                            id="Icon_ionic-md-arrow-dropdown"
                            data-name="Icon ionic-md-arrow-dropdown"
                            d="M9,13.5l9,9,9-9Z"
                            transform="translate(-9 -13.5)"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Section Description */}
                    <div
                      className={`text-skin-muted my-2 ${
                        open ? "" : "clampText"
                      }`}
                    >
                      {s?.pending_update?.first_name}{" "}
                      {s?.pending_update?.last_name}
                    </div>
                  </div>

                  {/* Large Screen Header */}
                  <div className="hidden sm:flex flex-row justify-between items-center">
                    {/* Header */}
                    <div>
                      <div
                        className={`font-bold transition-all duration-150 delay-150 ${
                          open ? "text-2xl" : "text-xl"
                        }`}
                      >
                        {s?.pending_update?.first_name}{" "}
                        {s?.old_profile?.last_name}
                      </div>
                    </div>

                    <div className="flex flex-row items-center">
                      <div
                        className={`translate transform delay-300 duration-300 ${
                          open ? "" : "-rotate-90"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4"
                          viewBox="0 0 18 9"
                          fill="#ddd"
                        >
                          <path
                            id="Icon_ionic-md-arrow-dropdown"
                            data-name="Icon ionic-md-arrow-dropdown"
                            d="M9,13.5l9,9,9-9Z"
                            transform="translate(-9 -13.5)"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Mentrics */}
                <div
                  className={`transition	duration-500 ease-in-out ${
                    open
                      ? "h-auto opacity-100"
                      : "h-0 opacity-10 overflow-hidden"
                  }`}
                >
                  <CoachData
                    oldProfile={s?.pending_update}
                    newProfile={s?.old_profile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CoachProfileCard;
