const DESCRIPTIONS = {
  "Be motivated":
    "The state of being self motivated and leading life and work with purpose and meaning",
  "Be self aware":
    "The state of being self aware and can objectively assess one’s inner world",
  "Be self balanced":
    "The state of being self balanced and can function at a good level of emotional and behavioral adjustment",
  "Build relationships":
    "The state of being able to build healthy relationships in the workplace that encourage honesty and transparency",
  "Create value":
    "The state of being able to solve complex problems creatively, and confidently allocating resources towards creating value for the company",
  "Future proof":
    "The state of being ready for the future of work and being able to embrace uncertainty",
  "Inspire others":
    "The state of being able to inspire and motivate other people and align them towards the same vision and clear purpose",
  "Lead mindfully":
    "The state of being able to lead teams to create value within the company and drive positive change",
  "Recognize problems":
    "The state of being able to recognize complex problems and using cognition to organize and address problems"
};

export const getDescription = (title) => {
  return DESCRIPTIONS[title];
};
