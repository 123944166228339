import { Avatar } from "knack-ui";
import { useState } from "react";
import { WarningInformation } from "../../../../../../components/Information/WarningInformation";
import { HeadlessMenuDropdown } from "../../../../../../components/Popper/HeadlessMenuDropdown";
import { IConnectionRequest } from "../../../../../../features/Connections/ConnectionsTypes";
import {
  IMatch,
  IProgram
} from "../../../../../../features/Programs/ProgramTypes";
import { ITeam } from "../../../../../../features/Team/TeamTypes";
import { useUser } from "../../../../../../features/User/userQueries";
import { SessionTypes } from "../../../../../../lib/constants";

interface ISelectConnectionProps {
  match: IMatch;
  matches: IMatch[];
  team: ITeam;
  teams: ITeam[];
  connection: IConnectionRequest;
  connections: IConnectionRequest[];
  onSelectTeam: (team: ITeam) => void;
  onSelectConnection: (connection: IConnectionRequest) => void;
  onSelectMatch: (value: IMatch) => void;
  program: IProgram;
  isDisabled: boolean;
  sessionType: keyof typeof SessionTypes;
  connectionUserId: string | null;
}

export const SelectConnection = ({
  connection,
  onSelectTeam,
  onSelectConnection,
  onSelectMatch,
  matches,
  match,
  isDisabled,
  program,
  sessionType,
  connections,
  team,
  teams,
  connectionUserId
}: ISelectConnectionProps) => {
  const [tapState, setTapState] = useState(false);
  const { data: user } = useUser();

  return (
    <div className="grid gap-4">
      {(team || connection?.mentee || match?.user) && (
        <div>
          <>
            {sessionType === "Chemistry Call" ? (
              "Select Client"
            ) : (
              <>
                {program?.plan === "group" &&
                  program?.coach_type === "knack_coaching" &&
                  "Select Team"}

                {program?.plan === "1:1" &&
                  program?.coach_type === "knack_coaching" &&
                  "Select Client"}
                {program?.program_type === "Mentoring" && "Select Mentee"}
                {program?.program_type === "Coaching" &&
                  user?.account_type !== "Coach" &&
                  "Select Coachee"}
              </>
            )}
          </>
        </div>
      )}

      {(team || connection?.mentee || match?.user) && (
        <div className="relative">
          <>
            <HeadlessMenuDropdown
              trigger={
                <div
                  onClick={() => setTapState(!tapState)}
                  className={`cursor-pointer bg-skin-lighter flex flex-row items-center justify-between w-full text-left rounded-lg ${
                    connectionUserId ? "" : "border"
                  }`}
                >
                  <div className="p-4 flex sm:flex-row flex-col sm:items-center gap-4">
                    {/* Selected Connection */}
                    {!["Team session", "Chemistry Call"].includes(
                      sessionType
                    ) &&
                      connection &&
                      connection?.mentee &&
                      connection?.menteeProfile && (
                        <div className="block relative">
                          <Avatar
                            text={connection?.menteeProfile?.first_name || ""}
                            className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                            image={connection?.mentee?.profile_image}
                          />
                        </div>
                      )}
                    {!["Team session", "Chemistry Call"].includes(
                      sessionType
                    ) &&
                      connection &&
                      connection?.mentee &&
                      connection?.menteeProfile && (
                        <div className="relative">
                          <div className="text-lg sm:text-xl font-bold capitalize">
                            {connection?.menteeProfile?.first_name}{" "}
                            {connection?.menteeProfile?.last_name}{" "}
                          </div>
                          {connection?.menteeProfile?.job_function && (
                            <div className="text-sm text-skin-muted">
                              {connection?.menteeProfile?.job_function}{" "}
                            </div>
                          )}
                        </div>
                      )}
                    {/* Selected Team */}
                    {sessionType === "Team session" && team && (
                      <div className="relative">
                        <div className="text-lg sm:text-xl font-bold capitalize">
                          {team?.name}
                        </div>
                        <div className="text-sm text-skin-muted">
                          {team?.members?.length} member
                        </div>
                      </div>
                    )}
                    {/* Selected Match */}
                    {sessionType === "Chemistry Call" && match && match.user && (
                      <div className="block relative">
                        <Avatar
                          text={match?.user?.first_name || ""}
                          className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                          image={match?.user?.user?.profile_image}
                        />
                      </div>
                    )}
                    {sessionType === "Chemistry Call" && match && (
                      <div className="relative">
                        <div className="text-lg sm:text-xl font-bold capitalize">
                          {match?.user?.first_name} {match?.user?.last_name}{" "}
                        </div>
                        {match?.user?.job_function && (
                          <div className="text-sm text-skin-muted">
                            {match?.user?.job_function}{" "}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Droplet Arrow */}
                  <div
                    className={`${
                      connectionUserId ? "hidden" : ""
                    } translate transform delay-300 duration-300 p-4 ${
                      tapState ? "" : "-rotate-180"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-3"
                      viewBox="0 0 18 9"
                      fill="var(--color-accent)"
                    >
                      <path
                        id="Icon_ionic-md-arrow-dropdown"
                        data-name="Icon ionic-md-arrow-dropdown"
                        d="M9,13.5l9,9,9-9Z"
                        transform="translate(-9 -13.5)"
                      />
                    </svg>
                  </div>
                </div>
              }
              popComponent={
                <>
                  {!isDisabled &&
                    (connections?.length > 1 ||
                      teams?.length > 1 ||
                      matches?.length > 1) && (
                      <div
                        className="-mt-3 scrollhost2 overflow-y-auto absolute w-full z-50 right-0 pt-4"
                        style={{ maxHeight: 350 }}
                      >
                        <div className="shadow rounded-lg bg-white overflow-hidden border">
                          {/* Connection List */}
                          {sessionType !== "Chemistry Call" &&
                            connection &&
                            connection?.menteeProfile &&
                            connections?.map((m, i) => (
                              <div
                                key={i}
                                className={`cursor-pointer w-full text-left hover:bg-skin-light ${
                                  connection?._id === m?._id ? "hidden" : ""
                                }`}
                                onClick={() => {
                                  onSelectConnection(m);
                                }}
                              >
                                <div className="p-4 flex sm:flex-row flex-col sm:items-center gap-4 border-b">
                                  <div className="block relative">
                                    <Avatar
                                      text={m?.menteeProfile?.first_name || ""}
                                      className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                                      image={m?.mentee?.profile_image}
                                    />
                                  </div>

                                  <div className="relative">
                                    <div className="text-lg sm:text-xl font-bold capitalize">
                                      {m?.menteeProfile?.first_name}{" "}
                                      {m?.menteeProfile?.last_name}{" "}
                                    </div>
                                    {m?.menteeProfile?.job_function && (
                                      <div className="text-sm text-skin-muted">
                                        {m?.menteeProfile?.job_function}{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* Team List */}
                          {program.plan === "group" &&
                            team &&
                            sessionType !== "Chemistry Call" &&
                            teams?.map((m, i) => (
                              <div
                                key={i}
                                className={`cursor-pointer w-full text-left hover:bg-skin-light ${
                                  team?._id === m?._id ? "hidden" : ""
                                }`}
                                onClick={() => {
                                  onSelectTeam(m);
                                }}
                              >
                                <div className="p-4 flex sm:flex-row flex-col sm:items-center gap-4 border-b">
                                  <div className="relative">
                                    <div className="text-lg sm:text-xl font-bold capitalize">
                                      {m?.name}
                                    </div>
                                    <div className="text-sm text-skin-muted">
                                      {m?.members?.length} member
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* Match List */}
                          {sessionType === "Chemistry Call" &&
                            matches?.map((m, i) => (
                              <div
                                key={i}
                                className={`cursor-pointer w-full text-left hover:bg-skin-light ${
                                  match?._id === m?._id ? "hidden" : ""
                                }`}
                                onClick={() => {
                                  onSelectMatch(m);
                                }}
                              >
                                <div className="p-4 flex sm:flex-row flex-col sm:items-center gap-4 border-b">
                                  <div className="block relative">
                                    <Avatar
                                      text={m?.user?.first_name || ""}
                                      className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                                      image={m?.user?.user?.profile_image}
                                    />
                                  </div>
                                  <div className="relative">
                                    <div className="text-lg sm:text-xl font-bold capitalize">
                                      {m?.user?.first_name} {m?.user?.last_name}{" "}
                                    </div>
                                    {m?.user?.job_function && (
                                      <div className="text-sm text-skin-muted">
                                        {m?.user?.job_function}{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                </>
              }
            />
          </>
        </div>
      )}

      {sessionType === "Chemistry Call" && (!match || !match?.user) && (
        <WarningInformation message="You do not have matches for this program" />
      )}

      {!team &&
        program?.plan === "group" &&
        sessionType !== "Chemistry Call" &&
        program?.coach_type === "knack_coaching" && (
          <WarningInformation message="You do not have a team for this program" />
        )}

      {(!connection || !connection?.mentee || !connection?.menteeProfile) &&
        program?.plan === "1:1" &&
        sessionType !== "Chemistry Call" &&
        program?.coach_type === "knack_coaching" && (
          <WarningInformation message="You do not have client(s) for this program" />
        )}

      {(!connection || !connection.mentee || !connection.menteeProfile) &&
        program?.program_type === "Mentoring" &&
        sessionType !== "Chemistry Call" && (
          <WarningInformation message="You do not have mentees for this program" />
        )}

      {(!connection || !connection?.mentee || !connection?.menteeProfile) &&
        sessionType !== "Chemistry Call" &&
        program?.program_type === "Coaching" &&
        program?.coach_type === "own_coach" && (
          <WarningInformation message="You do not have coachees for this program" />
        )}

      {!matches &&
        program?.program_type === "Mentoring" &&
        sessionType === "Chemistry Call" &&
        user?.account_type !== "Coach" && (
          <WarningInformation message="You do not have mentees for this program" />
        )}
    </div>
  );
};
