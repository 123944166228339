import { Participants } from "../../../../../pages/mentoring/hrDashboard/Programs/Participants";
import { IProgram } from "../../../ProgramTypes";

interface IProps {
  program: IProgram;
}
const HrProgramDashboardParticipants = ({ program }: IProps) => {
  return (
    <div dir="ltr" className="relative h-full py-4">
      <Participants program={program} />
    </div>
  );
};

export default HrProgramDashboardParticipants;
