import { Stack, Typography } from "knack-ui";
import create from "zustand";

interface IProps {
  step: number;
  totalSteps: number;
  setStep: (value: number) => void;
  hasPrevious: () => boolean;
}

export const useOnboardingCounterStore = create<IProps>((set, get) => ({
  step: 0,
  totalSteps: 3,
  setStep: (value) => {
    set({ step: value });
  },
  hasPrevious: () => {
    const step = get().step;
    if (step === 0) return false;
    return true;
  }
}));

export const OnboardingStepCounter = () => {
  const { step, totalSteps } = useOnboardingCounterStore();
  return (
    <div>
      <Typography color="muted" className="mb-2" variant="subtitle2">
        Step {step + 1} of {totalSteps}
      </Typography>
      <Stack gap={2}>
        {new Array(totalSteps).fill(0).map((_, i) => (
          <div
            key={i}
            className={`h-1 rounded-full w-10 bg-primary ${
              i <= step ? "bg-opacity-100" : "bg-opacity-20"
            }`}
          />
        ))}
      </Stack>
    </div>
  );
};
