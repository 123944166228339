import { Box } from "knack-ui";
import ContentLoader from "react-content-loader";
import { range } from "../../../lib/utils";

interface IProps {
  count?: number;
}

const DashboardStatsSkeleton = ({ count = 3 }: IProps) => {
  return (
    <div
      className={`grid grid-cols-1 gap-4 ${
        count === 4 ? "md:grid-cols-2 xl:grid-cols-4" : "xl:grid-cols-3"
      }`}
    >
      {range(1, count).map((i) => (
        <Box key={i} variant="outlined" className="overflow-hidden">
          <ContentLoader
            backgroundColor="#ececec"
            foregroundColor="#fafafa"
            viewBox="0 0 303 82"
            height={82}
            width="303"
          >
            <rect x="16" y="16" rx="5" ry="5" width="49" height="49" />
            <rect x="97" y="24" rx="5" ry="5" width="150" height="12" />
            <rect x="97" y="40" rx="5" ry="5" width="125" height="14" />
          </ContentLoader>
        </Box>
      ))}
    </div>
  );
};

export default DashboardStatsSkeleton;
