import { useState } from "react";
import { LoadMore } from "../../../../components/LazyLoading/LoadMore";
import { CardPaper } from "../../../../components/Cards/CardPaper";
import UserDrawer from "../../../../components/Profile/UserDrawer/UserDrawer";
import { Drawer } from "knack-ui";
import { useGetConnectionSessions } from "../../../../features/Sessions/sessionsQueries";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import { format } from "date-fns/esm";

export const SessionCompletedList = ({ Program }) => {
  const { data: sessions } = useGetConnectionSessions({
    programId: Program._id
  });
  const [displayCount, setDisplayCount] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);

  return (
    <div>
      <Drawer onClose={() => setUserDrawerOpen(false)} isOpen={userDrawerOpen}>
        <UserDrawer userId={selectedUser} />
      </Drawer>

      <CardPaper title={`Recent ${Program.program_type} Sessions`}>
        {sessions?.length === 0 && <EmptyState title="No recent sessions found" />}
        {sessions?.length > 0 && (
          <div style={{ minHeight: 300, height: 300 }} className="mt-4 overflow-y-auto evenOddBorder scrollhost removeScrollhost">
            {/* Header */}
            <div className="grid hidden w-full grid-cols-1 gap-4 py-2 pt-4 text-sm font-bold sm:grid-cols-4 xl:grid">
              {/* Employee */}
              <div className="flex items-start ">
                <span>{Program?.plan === "group" ? "Group" : "Employee"}</span>
              </div>
              {/* Time (hrs) */}
              <div className="flex items-center ">
                <span>Type</span>
              </div>
              {/*Date */}
              <div className="flex items-start ">
                <span>Date</span>
              </div>
              {/* Status */}
              <div className="flex items-center ">
                <span>Status</span>
              </div>
            </div>
            {/* Content */}
            <div className="pt-4">
              {sessions?.slice(0, displayCount)?.map((t, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setUserDrawerOpen(true);
                    setSelectedUser(t.user?.user?._id);
                  }}
                  className="flex items-center justify-between py-2 cursor-pointer hover:bg-gray-200"
                >
                  <div
                    key={i}
                    className="w-full grid-cols-1 gap-4 py-2 text-xs font-semibold leading-normal lg:grid lg:grid-cols-4 xl:font-normal"
                  >
                    <div className="flex-row sm:flex">
                      <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-col">
                          <span className="text-sm sm:text-xs">
                            {t.team ? (
                              <>{t?.team?.name} </>
                            ) : (
                              <>
                                {" "}
                                {t?.user?.first_name} {t?.user?.last_name}
                              </>
                            )}
                          </span>
                          <span className="sm:hidden">{format(new Date(t.start_time), "MMM dd-hh:mm a")}</span>
                          <span className="sm:hidden">{t.type}</span>
                        </div>
                        {/* Status */}
                        <h1
                          className="px-2 py-1 text-xs font-normal font-semibold text-green-700 whitespace-pre bg-green-100 rounded-lg xl:font-semibold w-min lg:hidden"
                          style={{ height: "fit-content" }}
                        >
                          {t.completion_status}{" "}
                        </h1>
                      </div>
                    </div>

                    <div className="flex-row hidden sm:flex">
                      <span>{t.type}</span> {/* Type */}
                    </div>

                    <div className="flex-row hidden sm:flex">
                      <span>{format(new Date(t.start_time), "MMM dd-hh:mm a")}</span>
                    </div>

                    {/* Status */}
                    <div className="hidden lg:block">
                      {" "}
                      <h1
                        className="px-2 py-1 text-xs font-normal font-semibold text-green-700 whitespace-pre bg-green-100 rounded-lg w-min xl:font-semibold"
                        style={{ height: "fit-content" }}
                      >
                        {t.completion_status}{" "}
                      </h1>{" "}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Load More */}
            <div className="flex items-center justify-center mt-4">
              <LoadMore
                onLoad={() => {
                  setDisplayCount(displayCount + 10);
                }}
                showHide={sessions.length > displayCount}
              />
            </div>
          </div>
        )}
      </CardPaper>
    </div>
  );
};
