import { colorPalette } from "../../services/utils/utils";

export const SquareImage = ({ image_url = null, index, classes, height }) => {
  return (
    <>
      {image_url && (
        <img
          src={image_url}
          className={`block w-full object-cover ${classes} ${
            height ? height : "h-48"
          }`}
          loading="lazy"
        />
      )}
      {!image_url && (
        <div
          className={`block w-full object-cover ${classes} ${
            height ? height : "h-48"
          }`}
          style={{ background: colorPalette[index].solid }}
        ></div>
      )}
    </>
  );
};
