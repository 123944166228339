import { Cancel } from "./Cancel";
import { Checkmark } from "./Checkmark";
import { Plus } from "./Plus";
import { Canceloutline } from "./Canceloutline";
import { Edit } from "./Edit";
import { Pending } from "./Pending";
import { VerticalDots } from "./VerticalDots";
import { BurgerMenu } from "./BurgerMenu";
import { Support } from "./Support";
import { Feedback } from "./Feedback";
import { Phone } from "./Phone";
import { Settings } from "./Settings";
import { ScheduleIcon } from "./ScheduleIcon";
import { Attachment } from "./Attachment";
import { Profile } from "./Profile";
import { ComponentPropsWithoutRef } from "react";
import ReportsIcon from "./ReportsIcon";
import LearningIcon from "./LearningIcon";
import { VscBell, VscBellDot, VscCalendar, VscHome, VscSearchStop, VscSmiley } from "react-icons/vsc";
import { CgDanger } from "react-icons/cg";
import { IoLanguageSharp } from "react-icons/io5";
import {
  MdOutlinePeopleAlt,
  MdOutlineAssessment,
  MdOutlineChecklist,
  MdOutlineContacts,
  MdLockOpen,
  MdOutlineClass,
  MdOutlineAddTask,
  MdAlternateEmail,
  MdOutlineCopyAll,
  MdOutlineAddAPhoto,
  MdAddCircleOutline,
  MdOutlineDragHandle,
  MdLockOutline,
  MdOutlineTopic,
  MdPublish,
  MdWorkOutline,
  MdOutlineReviews,
  MdLogout,
  MdOutlineAnalytics,
  MdOutlineHideSource
} from "react-icons/md";
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight, BiHelpCircle, BiMessageDetail } from "react-icons/bi";
import { HiOutlineSpeakerphone, HiOutlineThumbUp, HiShare } from "react-icons/hi";
import { FiCalendar, FiCheckCircle, FiChevronDown, FiChevronUp, FiClock } from "react-icons/fi";
import { BsArrowDownShort, BsArrowUpShort, BsCurrencyDollar, BsPerson, BsQuestionLg, BsSearch } from "react-icons/bs";
import { RiMoneyDollarCircleLine, RiUser3Line } from "react-icons/ri";
import { GoGear, GoQuote } from "react-icons/go";
import {
  AiFillBank,
  AiFillDelete,
  AiFillExclamationCircle,
  AiFillInfoCircle,
  AiFillLinkedin,
  AiFillStar,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineRedo,
  AiOutlineReload,
  AiOutlineStar,
  AiOutlineTeam
} from "react-icons/ai";
import ProgramBigIcon from "./ProgramBigIcon";
import GoalsBigIcon from "./GoalsBigIcon";
import CalendarBigIcon from "./CalendarBigIcon";
import AssessmentsBigIcon from "./AssessmentsBigIcon";
import SessionEmptyStateIcon from "./EmptyStates/SessionEmptyStateIcon";
import { IoIosRocket, IoMdClose } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc";
import SuccessBigIcon from "./SuccessBigIcon";
import KnackMiniLogo from "./KnackMiniLogo";
import UserEmptyStateIcon from "./EmptyStates/UserEmptyStateIcon";
import { FiExternalLink } from "react-icons/fi";
import GoalsCompleted from "./GoalsCompleted";
import Completed from "./Completed";
import GoalsInProgress from "./GoalsInProgress";
import GoalsOverdue from "./GoalsOverdue";

export const SVGS = {
  CircleDollar: RiMoneyDollarCircleLine,
  IoIosRocket: IoIosRocket,
  Cancel: Cancel,
  Topic: MdOutlineTopic,
  Checkmark: Checkmark,
  CheckmarkCircle: FiCheckCircle,
  ExclamationFull: AiFillExclamationCircle,
  Task: MdOutlineAddTask,
  Add: Plus,
  AddOutline: MdAddCircleOutline,
  Canceloutline: Canceloutline,
  Edit: Edit,
  Pending: Pending,
  Published: MdPublish,
  Delete: AiFillDelete,
  VerticalDots: VerticalDots,
  BurgerMenu: BurgerMenu,
  Logout: MdLogout,
  Support: Support,
  Feedback: Feedback,
  Phone: Phone,
  Email: MdAlternateEmail,
  Copy: MdOutlineCopyAll,
  Drag: MdOutlineDragHandle,
  Redo: AiOutlineRedo,
  Settings: Settings,
  Profile: Profile,
  ScheduleIcon: ScheduleIcon,
  Users: MdOutlinePeopleAlt,
  Home: VscHome,
  Sessions: MdOutlineContacts,
  Assessment: MdOutlineAssessment,
  Surveys: BiMessageDetail,
  Campaign: HiOutlineSpeakerphone,
  Report: ReportsIcon,
  Availability: FiCalendar,
  Goals: MdOutlineChecklist,
  Learning: LearningIcon,
  Attachment: Attachment,
  Earnings: BsCurrencyDollar,
  Person: BsPerson,
  LockOpen: MdLockOpen,
  LockClosed: MdLockOutline,
  EyeOpen: AiOutlineEye,
  EyeClosed: AiOutlineEyeInvisible,
  Danger: CgDanger,
  Program: MdOutlineClass,
  ProgramBig: ProgramBigIcon,
  GoalsBig: GoalsBigIcon,
  CalendarBig: CalendarBigIcon,
  Clock: FiClock,
  AssessmentsBig: AssessmentsBigIcon,
  SessionsEmptyState: SessionEmptyStateIcon,
  UsersEmptyState: UserEmptyStateIcon,
  SuccessBigIcon: SuccessBigIcon,
  GoalsCompleted: GoalsCompleted,
  Calendar: VscCalendar,
  Close: IoMdClose,
  Quote: GoQuote,

  ColoredCheckmark: FcCheckmark,
  KnackMiniLogo: KnackMiniLogo,
  Info: AiFillInfoCircle,
  StarOutlined: AiOutlineStar,
  StarFilled: AiFillStar,
  AddPhoto: MdOutlineAddAPhoto,
  User: RiUser3Line,
  FiExternalLink: FiExternalLink,
  Bell: VscBell,
  BellDot: VscBellDot,
  Department: AiFillBank,
  Work: MdWorkOutline,
  Language: IoLanguageSharp,
  Team: AiOutlineTeam,
  ArrowUp: BsArrowUpShort,
  ArrowDown: BsArrowDownShort,
  Gear: GoGear,
  HelpCenter: BiHelpCircle,
  LinkedIn: AiFillLinkedin,
  Smile: VscSmiley,
  Search: BsSearch,
  Reviews: MdOutlineReviews,
  Analytics: MdOutlineAnalytics,
  ThumbsUp: HiOutlineThumbUp,
  ChevronDown: FiChevronDown,
  ChevronUp: FiChevronUp,
  Share: HiShare,
  Reload: AiOutlineReload,
  SearchStop: VscSearchStop,
  ChevronsRight: BiChevronsRight,
  RightChevron: BiChevronRight,
  LeftChevron: BiChevronLeft,
  ChevronsLeft: BiChevronsLeft,
  Completed: Completed,
  GoalsInProgress: GoalsInProgress,
  GoalsOverdue: GoalsOverdue,
  Archive: MdOutlineHideSource,
  Question: BsQuestionLg
};

export interface SVGProps extends ComponentPropsWithoutRef<"svg"> {
  name: keyof typeof SVGS;
  size?: number;
}
export type SVGComponentProps = Omit<SVGProps, "name">;

const SVG = ({ name, size = 20, ...props }: SVGProps) => {
  const Component = SVGS[name];
  if (!Component) return null;
  return <Component size={size} {...props} />;
};

export default SVG;
