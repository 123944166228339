import { SessionTypes } from "../../../../../lib/constants";

interface ITopicsProps {
  topics: string[];
  selectedTopics: string[];
  setTopics: React.Dispatch<React.SetStateAction<string[]>>;
  onSearch: (value: string) => void;
  sessionType: keyof typeof SessionTypes;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  canSearch?: boolean;
}

export const SelectTopics = ({
  topics,
  setTopics,
  sessionType,
  onSearch,
  selectedTopics,
  setError,
  canSearch
}: ITopicsProps) => {
  return (
    <div className="relative grid gap-4">
      <div className="">
        <div className="text-xl sm:text-xl font-bold">
          Select {sessionType} Topics
        </div>
        <div className="text-skin-muted text-sm">
          Please select one or more topics discussed during this session{" "}
        </div>
      </div>

      {canSearch && (
        <div className="font-bold flex items-center justify-start border-b">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 left-4 absolute"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
          <input
            placeholder="Search Topics ..."
            className="rounded-lg p-4 pl-12 m-0 border-none outline-none w-full"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      )}

      <div className="">
        {/* Topics */}
        <div className="grid gap-4 sm:grid-cols-2 max-h-96 scrollhost2 overflow-y-auto">
          {topics?.map((s, i) => (
            <label
              key={i}
              className={`block border p-4 text-sm flex flex-row items-center justify-between rounded-lg ${
                selectedTopics.includes(s) ? "bg-skin-light" : ""
              }`}
              onClick={() => {
                const filtered =
                  selectedTopics.length > 0 ? [...selectedTopics] : [];
                if (!filtered?.includes(s)) {
                  filtered.push(s);
                  setTopics(filtered);
                } else {
                  const _filtered = filtered.filter((t) => t !== s);
                  setTopics(_filtered);
                }
                setError(null);
              }}
            >
              <span>{s}</span>
              {selectedTopics &&
                selectedTopics.length > 0 &&
                selectedTopics.includes(s) && (
                  <div className="rounded-full h-6 w-6 bg-skin-accent text-white flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#fff"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
