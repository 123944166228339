import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";

export type TMiscellaneous = "Assessment Audit Trail";

async function miscellaneousRequest({ type, organization }: { type: TMiscellaneous; organization: [] }) {
  let params: Record<string, any> = {
    organization: organization.join(",")
  };

  const res = await apiHttp.post<ServerResponse<boolean>>("v2/analytics/miscellaneous", { type }, { params });
  return res.data.data;
}

// Mutations
export const useMiscellaneousRequest = () => {
  return useMutation(miscellaneousRequest);
};
