import create from "zustand";

export const TaskDrawerOpenState = create((set) => ({
  isOpen: false,
  setOpen: () => set((state) => ({ isOpen: !state.isOpen }))
}));

export const CreateProgramDrawerOpenState = create((set) => ({
  isOpen: false,
  setOpen: () => set((state) => ({ isOpen: !state.isOpen }))
}));

export const ChangeBigCalendarView = create((set) => ({
  currentView: "month",
  setView: (view) => set((state) => ({ currentView: view }))
}));

/**
 * Make Calendar schedule list available everywhere
 */
export const CalendarScheduleList = create((set) => ({
  scheduleList: [],
  setScheduleList: (list) => set((state) => ({ scheduleList: list }))
}));

/**
 * Trigger Next/Previous page on react-big-calendar
 */
export const onNavigateCalendarPage = create((set) => ({
  navigateCalendar: null,
  setNavigationFunction: (navigationDirection) =>
    set((state) => ({ navigation: navigationDirection }))
}));

/**
 * Drawer width state
 */
export const onChangeDrawerWidth = create((set) => ({
  drawerWidth: localStorage.getItem("_drawerWidth") || "w-72",
  setDrawerWidth: (width) => set((state) => ({ drawerWidth: width }))
}));

/**
 * Knack Loading Spinner state
 */
export const KnackSpinnerShowHideState = create((set) => ({
  isOpen: false,
  setIsopen: (isOpen) => set((state) => ({ isOpen: isOpen }))
}));

/**
 * Silect information message bar
 */
export const KnackSilentInformationBar = create((set) => ({
  state: { isOpen: false, message: "" },
  setState: (state) => set(() => ({ state: state }))
}));

/**
 * Trigger Invite modal
 */
export const KnackPeopleInviteModal = create((set) => ({
  isOpen: false,
  setIsopen: (isOpen) => set((state) => ({ isOpen: isOpen }))
}));

/**
 * Triggered Selection Mode
 */
export const onInviteSelectionMode = create((set) => ({
  isSelection: false,
  setIsSelection: (value) => set((state) => ({ isSelection: value }))
}));

/**
 * Onboarding completed State
 */
export const OnboardingState = create((set) => ({
  state: localStorage.getItem("onboardingState")
    ? JSON.parse(localStorage.getItem("onboardingState"))
    : ["profile-setup", "coach-preference", "goal-setting", "take-assessment"],
  setState: (updatedList) => set((state) => ({ state: updatedList }))
}));

/**
 * Pop open modal to show program invitation
 */
export const ProgramInvitation = create((set) => ({
  invitation: null,
  isOpen: false,
  setOpen: (value) => set((state) => ({ isOpen: value })),
  setInvitation: (invitation) => set((state) => ({ invitation: invitation }))
}));

export const refreshNotification = create((set) => ({
  refresh: false,
  setRefresh: (value) => set((state) => ({ refresh: value }))
}));
