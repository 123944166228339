import { useUser } from "../../../../../../features/User/userQueries";
import microlearningService from "../../../../../../services/learning/microlearning.service";

export default function ContentTable(props) {
  const { data: user } = useUser();
  const openUrl = (url, resource_id) => {
    window.open(url);
    if (
      user &&
      ["Mentee", "Mentor", "Coach", "Employee"].includes(user.account_type)
    ) {
      markStarted(resource_id);
    }
  };

  const markStarted = (resource_id) => {
    microlearningService.markStarted(resource_id).then(
      (response) => {
        if (response.data.status) {
          // setStarted(true);
          console.log(response.data);
        } else {
          console.log(response.status.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <aside className="relative bg-sidebar h-screen w-2/5 hidden lg:block">
      <nav className="text-gray-800 font-normal w-full font-open text-sm px-6 pt-6">
        <p className="flex flex-row text-gray-500 text-base">
          <svg
            className="w-6 h-6 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            ></path>
          </svg>
          Contents
        </p>
        <div className="flex flex-wrap">
          <div className="w-full py-4">
            {Object.keys(props.data).length !== 0 &&
              props.data.sections.map((detail, i) => {
                return (
                  <div key={i + Math.random()}>
                    <p className="font-bold text-sm py-4">
                      {`${i + 1}. ${detail.title}`}
                    </p>
                    <ul className="px-0">
                      {detail.content.map((content, i) => {
                        return (
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openUrl(content.url, content._id);
                            }}
                            href={"#" + content._id}
                            key={i + Math.random()}
                          >
                            <li className="flex flex-row align-middle border-b list-none  rounded-sm py-3 text-gray-500 hover:text-gray-800">
                              <svg
                                className={
                                  "w-5 h-5 p-1 mr-2" +
                                  " " +
                                  (content.completed &&
                                    "text-white border rounded-full bg-green-400 border-green-400")
                                }
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                                ></path>
                              </svg>
                              {content.title}
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>
      </nav>
    </aside>
  );
}
