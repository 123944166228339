import { useUser, useUserRoles } from "../../features/User/userQueries";
import { ManualSessionLoggingForExpert } from "./SessionLogging/ManualSessionLoggingForExpert";
import { ManualSessionLoggingUser } from "./SessionLogging/ManualSessionLoggingUser";
import { openManualSessionLogging } from "./SessionLogging/SessionState";

export const KnackSchedulingSystem = () => {
  const { data: user } = useUser();
  const { open } = openManualSessionLogging();
  const { roleState } = useUserRoles();
  const unrestrictedExperts = ["Coach", "Expert"];

  return (
    <>
      {open && roleState.activeRole === "User" && <ManualSessionLoggingUser />}
      {open &&
        unrestrictedExperts.includes(
          roleState.activeRole || user.account_type
        ) && <ManualSessionLoggingForExpert />}
    </>
  );
};
