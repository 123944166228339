
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
// Sentry Error Tracking
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { logout } from "./services/auth.service";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./lib/appConfig";

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://461c99076ac84abdae694418d86306fc@o985942.ingest.sentry.io/5943358",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });

// intercept request to ensure user object exist in localstorage
const requestHandler = async (request) => {
  if (request.url.includes("login")) {
    return request;
  }
  if (request.url.includes("verify-otp")) {
    return request;
  }
  if (request.url.includes("assessments/360-responses")) {
    return request;
  }
  // get ipAddress
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const roles = user?.data?.roles || [];
  // const cleanedUrl = request.url.endsWith('/') ? request.url.substring(0, request.url.length - 1) : request.url // remove '/' at end of url
  // const url = cleanedUrl.includes('?') ? cleanedUrl.endsWith('&') ? `${cleanedUrl}` : `${cleanedUrl}&` : `${cleanedUrl}?`
  const url = request.url.includes("?")
    ? request.url.endsWith("&")
      ? `${request.url}`
      : `${request.url}&`
    : `${request.url}?`;
  const settings = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : null;

  if (user && user.data.account_type === "Admin") {
    return request;
  }
  if (user && user.data.account_type === "Subadmin") {
    return request;
  }
  if (user && user.data.account_type === "Coach") {
    return request;
  }
  if (user && user.data && roles?.length === 0) {
    return request;
  }
  if (settings && settings.currentRole === "Expert") {
    if (roles?.includes("coach")) {
      request.url = `${url}state=Coach`;
    } else {
      request.url = `${url}state=Mentor`;
    }
    return request;
  }
  if (settings && settings.currentRole === "User") {
    request.url = `${url}state=Mentee`;
    if (roles?.includes("coachee")) {
      request.url = `${url}state=Coachee`;
    } else {
      request.url = `${url}state=Mentee`;
    }
    return request;
  }
  return request;
};

const errorHandler = (error) => {
  return error;
};

axios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axios.interceptors.response.use(
  (response) => {
    if (response.data.message === "Token Error") {
      logout();
      return;
    }
    return response;
  },
  (error) => {
    console.log("[Intercepted Response]", JSON.stringify(error, null, 4));
    // throw new Error(error);
  }
);

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
