import { getDescription } from "../../services/utils/subtitlesAndDescriptions";
import { SkillsBreackdownCollapsiblePanelV2 } from "../Collapsible/SkillsBreackdownCollapsiblePanelV2";
import { SmallInformation } from "../Information/SmallInformation";

/**
 * Display skills breakdown result in collapsible list
 * @returns
 */
export const SkillBreakdownResultV2 = ({ skills = [], clustered = {}, expanded }) => {
  function latestResult(skill) {
    // (clustered && clustered[skill]) ? [...clustered[skill]].pop() : []
    if (clustered && clustered[skill]) {
      const result = [...clustered[skill]].pop();
      return result;
    }
    return [];
  }

  return (
    <>
      {skills.length ? (
        <>
          {skills.length > 0 && (
            <div className="flex flex-end justify-end">
              <SmallInformation />
            </div>
          )}
          <div className="flex flex-col gap-4">
            {skills.map((skill, i) => (
              <label key={i} className="block w-full border-bottom bg-white p-4 rounded-lg shadow">
                <SkillsBreackdownCollapsiblePanelV2
                  skillData={clustered[skill]}
                  SectionName={skill}
                  SectionDescription={getDescription(skill)}
                  latestResult={latestResult(skill)}
                  expanded={expanded}
                />
              </label>
            ))}
          </div>
        </>
      ) : (
        <div className="mt-8 text-xs text-skin-muted text-center font-semibold">
          {" "}
          <span className="opacity-50">⚡</span> Assessment report will appear here when it is available{" "}
        </div>
      )}
    </>
  );
};
