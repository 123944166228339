import { Box, Typography } from "knack-ui";
import CapacityController from "../../../components/CapacityController/CapacityController";

const CapacitySetting = () => {
  return (
    <Box variant="outlined">
      <div className="p-4 border-b">
        <Typography variant="h6">General program capacity</Typography>
        <Typography variant="subtitle2" color="muted">
          Specifies how many coachees/mentees you can accept across all programs.
        </Typography>
      </div>
      <div className="p-4">
        <CapacityController />
      </div>
    </Box>
  );
};

export default CapacitySetting;
