import { Button, Drawer } from "knack-ui";
import { useCallback } from "react";
import { PingingDot } from "../../../components/PingingDot/PingingDot";
import SVG from "../../../components/SVG/SVG";
import ProgramInvitationsDrawer from "../../../pages/UserDashboard/ProgramInvitationsDrawer";
import { useGetProgramInvitations } from "../programQueries";
import { IProgramInvite } from "../ProgramTypes";

interface IProps {
  invitesOpen: boolean;
  handleToggleInvitations: VoidFunction;
}

const DashboardProgramInvitations = ({
  handleToggleInvitations,
  invitesOpen
}: IProps) => {
  const { data: invitations } = useGetProgramInvitations(
    { confirmed: false },
    {
      select: useCallback((data: IProgramInvite[]) => {
        return data.filter((p) => p.sent === true && p.program);
      }, []),
      refetchOnWindowFocus: true
    }
  );
  if (!invitations || invitations?.length === 0) return null;
  return (
    <>
      <Button
        startIcon={<SVG name="Program" size={25} />}
        onClick={handleToggleInvitations}
        fullWidth
        dir="ltr"
        kind="success"
        variant="large"
        className="relative"
      >
        You have +{invitations.length} program invitations
        <PingingDot end="-4px" top="-4px" />
       
      </Button>
      <Drawer isOpen={invitesOpen} onClose={handleToggleInvitations}>
        <ProgramInvitationsDrawer invitations={invitations} />
      </Drawer>
    </>
  );
};

export default DashboardProgramInvitations;
