import { UserRoles } from "../../lib/constants";
import { IConnectionRequest } from "../Connections/ConnectionsTypes";
import { IProgram, IProgramInvite } from "../Programs/ProgramTypes";
import { ISurvey } from "../Surveys/surveyTypes";
import { IUser, IUserProfile } from "../User/UserTypes";

type NotificationBase = {
  _id: string;
  isSeen: boolean;
  user: IUser;
  createdAt: string;
  updatedAt: string;
  profileContext: typeof UserRoles[number];
};

export type INotification =
  | ProgramInvitationNotification
  | ExpertMatchNotification
  | SurveyNotification
  | ConnectionRequestNotification
  | InfoNotification;

export interface ProgramInvitationNotification extends NotificationBase {
  attributes: { programInvitation: IProgramInvite };
  _ktid_nt: string;
}
export interface ExpertMatchNotification extends NotificationBase {
  attributes: {
    matches: { first_name: string; last_name: string; profile_image: string }[];
    program: IProgram;
  };
  _ktid_nt: string;
}
export interface SurveyNotification extends NotificationBase {
  attributes: {
    surveyInvitation: {
      _id: string;
      program: Pick<IProgram, "image_url" | "name" | "overview" | "_id">;
      survey: Pick<ISurvey, "_id" | "type" | "title">;
      recipient?: Pick<
        IUserProfile,
        "first_name" | "last_name" | "profile_image" | "user"
      >;
    };
  };
  _ktid_nt: string;
}
export interface ConnectionRequestNotification extends NotificationBase {
  attributes: { matchRequest: IConnectionRequest };
  _ktid_nt: string;
}
export interface InfoNotification extends NotificationBase {
  attributes: { message: string };
  _ktid_nt: string;
}

export enum NotificationsSecretSauce {
  programInvite = "cHJvZ3JhbUludml0ZQ",
  matchRequest = "ZXhwZXJ0TWF0Y2g",
  surveyInvite = "c3VydmV5SW52aXRl",
  matches = "bWF0Y2hlcw",
  info = "aW5mbw"
}
