import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { SquareImage } from "../../../../../components/Image/SquareImage";
import hrDashboardServices from "../../../../../services/dashboard/hrDashboard.services";
import { CollapsibleWrapperWithLineBar } from "../../../../../components/Collapsible/CollapsibleWrapperWithLineBar";
import { ParsedHTML } from "../../../../../components/Parser/ParsedHTML";
import { BackButton } from "../../../../../components/nav/BackButton";

export const ViewProgram = () => {
  const location = useLocation();
  const history = useHistory();
  const program_id = location.state?._id;
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")).data
  );
  const [program, setProgram] = useState();

  useEffect(() => {
    if (!program_id) {
      history.push("/");
      return;
    }
    getProgram();
  }, [setProgram]);

  function getProgram() {
    hrDashboardServices
      .getProgram(program_id, "Mentoring")
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setProgram(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <BackButton />
      <div className="text-skin-base relative mb-32">
        {/* Content Area */}
        <div className="max-w-screen-lg z-50 relative mx-auto xl:pt-14 rounded-lg">
          {/* Header Grid */}
          {program?.status === "Pending" && (
            <div className="text-center bg-gray-300 rounded-t-lg py-1">
              Draft
            </div>
          )}
          <div className="shadow-lg rounded-xl bg-white ">
            <div className="grid sm:grid-cols-5 bg-white border-b">
              {/* Banner */}
              <div className="w-full h-full sm:col-span-3">
                <SquareImage
                  image_url={program?.image_url}
                  index={0}
                  height="h-full"
                />
              </div>
              {/* Program Details */}
              <div className="p-4 sm:col-span-2 flex flex-col gap-4 sm:border-l">
                {/* Title */}
                <div className="text-xl font-semibold">{program?.name}</div>
                {/* Topics */}
                <div>
                  <CollapsibleWrapperWithLineBar
                    ItemsCounts={program?.program_skills?.length}
                    SectionName={`${program?.program_type} Topics`}
                    Icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="var(--color-green)"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                        />
                      </svg>
                    }
                  >
                    <div className="grid gap-4 scrollhost2 overflow-y-auto max-h-96">
                      {program?.skills?.map((s, i) => (
                        <div
                          key={i}
                          className="text-sm text-skin-muted w-full"
                          style={{ borderColor: "#eeeeee4f" }}
                        >
                          {i + 1}. {s}{" "}
                        </div>
                      ))}
                    </div>
                  </CollapsibleWrapperWithLineBar>
                </div>
                {/* Start Date */}
                <div>
                  <div className="font-semibold">Start Date</div>
                  <div className="text-sm tracking-wider gap-2 flex flex-row flex-wrap mt-2">
                    {" "}
                    {new Date(program?.start_date).toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "long",
                      day: "numeric",
                      year: "numeric"
                    })}{" "}
                  </div>
                </div>

                {/* End Date */}
                <div>
                  <div className="font-semibold">End Date</div>
                  <div className="text-sm tracking-wider gap-2 flex flex-row flex-wrap mt-2">
                    {" "}
                    {new Date(program?.end_date).toLocaleDateString("en-US", {
                      weekday: "short",
                      month: "long",
                      day: "numeric",
                      year: "numeric"
                    })}{" "}
                  </div>
                </div>
                {/* Metrics */}
                {user.account_type === "Hr" &&
                  user.platform[0] === "Mentoring" && (
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div>
                        <div className="font-semibold">N/A</div>
                        <div className="text-sm tracking-wider">Registered</div>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {/* Program Description */}
            <div className="h-full">
              <div className="bg-white p-4 break-words py-8 sm:px-8">
                {" "}
                <ParsedHTML htmlString={program?.overview} />{" "}
              </div>
            </div>

            {program?.goals && (
              <div className="h-full g p-4 bg-white grid gap-4">
                <CollapsibleWrapperWithLineBar
                  ItemsCounts={program?.goals?.length}
                  SectionName="Goals"
                  Icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="var(--color-green)"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                      />
                    </svg>
                  }
                >
                  <div className="grid gap-4 scrollhost2 overflow-y-auto max-h-96">
                    {program?.goals?.map((s, i) => (
                      <div
                        key={i}
                        className="text-sm text-skin-muted w-full"
                        style={{ borderColor: "#eeeeee4f" }}
                      >
                        {i + 1}. {s}{" "}
                      </div>
                    ))}
                  </div>
                </CollapsibleWrapperWithLineBar>
              </div>
            )}

            <div className="px-3">
              <CollapsibleWrapperWithLineBar SectionName="Agreement">
                <div className="h-full">
                  <p className="bg-white break-words sm:px-8 pb-6">
                    <div
                      dangerouslySetInnerHTML={{ __html: program?.agreement }}
                    />
                  </p>
                </div>
              </CollapsibleWrapperWithLineBar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
