import ContentLoader from "react-content-loader";
import ProgramCardSkeleton from "../../../components/ProgramCard/ProgramCardSkeleton";
import Slider from "../../../components/Slider/Slider";

const DashboardProgramsSkeleton = () => {
  return (
    <div className="p-6 border rounded-lg bg-paper">
      <ContentLoader
        backgroundColor="#ececec"
        foregroundColor="#fafafa"
        viewBox="0 0 303 62"
        height={62}
        width="303"
      >
        <rect x="0" y="0" rx="5" ry="5" width="110" height="15" />
        <rect x="0" y="24" rx="5" ry="5" width="200" height="13" />
      </ContentLoader>
      <Slider>
        {[0, 1, 2].map((i) => (
          <ProgramCardSkeleton key={i} />
        ))}
      </Slider>
    </div>
  );
};

export default DashboardProgramsSkeleton;
