import { useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useUserCompany } from "../features/User/userQueries";

/**
 *
 * @param term search term
 * @returns
 * A list of matching positions
 */
const usePositionMatch = (term: string) => {
  const { data: company } = useUserCompany();
  // Fetch positions from organization...
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return useMemo(() => {
    return company ? (term.trim() === "" ? company.positions : matchSorter(company.positions, term)) : [];
  }, [term, company]);
};

export default usePositionMatch;
