import { format } from "date-fns/esm";
import { useState } from "react";
import { useGetUserSessionsTimeline } from "../../features/HrEngagementAnalytics/hrAnalyticsQueries";
import { useGetConnectionSessions } from "../../features/Sessions/sessionsQueries";
import SessionsTimelineChart from "../../features/Sessions/ui/SessionsTimelineChart";

import EmptyState from "../EmptyState/EmptyState";
import { LoadMore } from "../LazyLoading/LoadMore";

/**
 * Fetch all mentoring related sessions where user is either a mentor or mentee
 * @param {*} param0
 * @returns
 */
const MentoringInsightsTimeline = ({ user }) => {
  const { data: sessions } = useGetConnectionSessions({
    platform: "mentoring",
    userId: user.user._id
  });
  const { data: sessionTimeline } = useGetUserSessionsTimeline({
    time: "month",
    type: "mentoring",
    user: user.user._id
  });
  const [displayCount, setDisplayCount] = useState(10);

  return (
    <div className="grid grid-cols-2 gap-4">
      {/* Recent Coaching Sessions */}
      <div className="p-4 rounded-lg shadow">
        <div className="text-sm font-bold">Recent Mentoring Sessions</div>
        <div className="pb-2 text-xs">
          List of mentoring sessions completed by {user?.first_name}
        </div>
        {/* Header */}
        <div className="grid hidden w-full grid-cols-1 gap-4 py-2 text-sm font-bold sm:grid-cols-3 xl:grid">
          {/*Date */}
          <div className="flex items-start ">
            <span>Date</span>
          </div>
          {/* Time (hrs) */}
          <div className="flex items-center ">
            <span>Type</span>
          </div>
          {/* Status */}
          <div className="flex items-center ">
            <span>Status</span>
          </div>
        </div>
        {/* Content */}
        <div className="h-full overflow-y-auto scrollhost removeScrollhost max-h-72 ">
          {sessions && sessions.length === 0 && (
            <div className="flex items-center justify-center text-sm font-bold h-14">
              No Sessions found
            </div>
          )}
          {sessions?.slice(0, displayCount)?.map((t, i) => (
            <div
              key={i}
              className="w-full grid-cols-1 gap-4 py-2 text-xs font-semibold leading-normal lg:grid lg:grid-cols-3 xl:font-normal"
            >
              <div className="flex-row sm:flex">
                <div className="flex flex-row justify-between w-full">
                  <div className="flex flex-col">
                    <span>
                      {format(new Date(t.start_time), "MMM dd-hh:mm a")}
                    </span>{" "}
                    {/* Date */}
                    <span className="sm:hidden">{t.type}</span> {/*  Type */}
                  </div>
                  <h1
                    className="px-2 py-1 text-xs font-normal font-semibold text-green-700 whitespace-pre bg-green-100 xl:font-semibold w-min lg:hidden"
                    style={{ height: "fit-content" }}
                  >
                    {t?.completion_status}{" "}
                  </h1>
                </div>
              </div>
              <div className="flex-row hidden sm:flex">
                <span>{t.type}</span> {/* Type */}
              </div>
              {/* Status */}
              <div className="hidden lg:block">
                {" "}
                <h1
                  className="px-2 py-1 text-xs font-normal font-semibold text-green-700 whitespace-pre bg-green-100 rounded-lg w-min xl:font-semibold"
                  style={{ height: "fit-content" }}
                >
                  {t?.completion_status}{" "}
                </h1>{" "}
              </div>
            </div>
          ))}

          <LoadMore
            onLoad={() => {
              setDisplayCount(displayCount + 10);
            }}
            showHide={sessions.length > displayCount}
          />
        </div>
      </div>
      {/* Mentoring Sessions Timeline */}
      <div className="p-4 rounded-lg shadow">
        <div className="text-sm font-bold">Mentoring sessions timeline</div>
        <div className="pb-4 text-xs">
          Number of mentoring sessions {user?.first_name} completed by time{" "}
        </div>
        {/*Bar Chart   */}
        <div style={{ height: 300 }}>
          {sessionTimeline && sessionTimeline?.length === 0 && (
            <EmptyState
              icon="SessionsEmptyState"
              iconSize={80}
              title="No data available"
            />
          )}
          {sessionTimeline && sessionTimeline?.length > 0 && (
            <SessionsTimelineChart timelines={sessionTimeline} />
          )}
        </div>
        {/* <BarChart
          classes="h-64"
          seriesName="Mentoring Sessions Timeline"
          series={[...mentoringTimelineMonthly.series]}
          categories={[...mentoringTimelineMonthly.categories]}
        /> */}
      </div>
    </div>
  );
};

export default MentoringInsightsTimeline;
