import { format } from "date-fns";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Drawer,
  Stack,
  Typography
} from "knack-ui";
import { useState } from "react";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import { ListLoadingSpinner } from "../../../../components/Spinner/ListLoadingSpinner";
import SVG from "../../../../components/SVG/SVG";
import { IProgram } from "../../../../features/Programs/ProgramTypes";
import { useGetSessionByPage } from "../../../../features/Sessions/sessionsQueries";
import { ISession, TSessionWithProgram } from "../../../../features/Sessions/SessionsTypes";
import { ITeam } from "../../../../features/Team/TeamTypes";
import SessionDetailsDrawer from "../../../../V3/Pages/ConnectionProfile/ui/ConnectionSessions/SessionDetailsDrawer";

export const AdminSessions = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<ISession | null>(null);

  const {
    data: sessions,
    fetchNextPage,
    hasNextPage,
    isLoading
  } = useGetSessionByPage();

  function isLoggedSession(s: ISession) {
    if (s.isLoggedSession) {
      return true;
    }
    if (s.calendar_event_id) {
      return false;
    }
    return true;
  }

  return (
    <div>
      <div className="h-full">
        {sessions?.pages.length === 0 && (
          <EmptyState
            key="empty"
            iconSize={60}
            icon="SessionsEmptyState"
            title="No sessions found"
          />
        )}
        <div dir="ltr" className="relative grid gap-4 py-4">
          <div
            className="absolute top-0 bottom-0 w-1 h-full bg-gray-100"
            style={{ left: 84, zIndex: 0 }}
          ></div>
          {sessions?.pages.map((s) => {
            return s.sessions.map((s, i) => (
              <Stack gap={4} className="z-10" alignItems="center">
                <Typography
                  style={{ width: 60 }}
                  variant="body2"
                  fontWeight="semibold"
                >
                  {format(new Date(s.start_time), "LLL dd, kk:mm a")}
                </Typography>
                <div
                  style={{ borderWidth: 3 }}
                  className={`rounded-full w-5 h-5 bg-paper border ${
                    s.completion_status === "Completed" ? "border-success" : ""
                  }`}
                ></div>

                <Box
                  onClick={() => {
                    setDrawerOpen(true);
                    setSelectedSession(s);
                  }}
                  as={Stack}
                  gap={4}
                  variant="outlined"
                  className="flex-1 p-6 transition-colors duration-150 cursor-pointer hover:bg-gray-100"
                  direction="column"
                >
                  <div className="flex items-center gap-4 border-none shadow-none">
                    <div className="flex-1">
                      <Typography variant="h6" className="mb-4">
                        {s.type}
                      </Typography>

                      <Stack gap={10}>
                        {s.expert && (
                          <Stack alignItems="center" gap={2}>
                            <Avatar
                              size="small"
                              text={s.expert?.first_name || ""}
                              image={s?.expert?.user?.profile_image || ""}
                            />
                            <Typography fontWeight="semibold" variant="body2">
                              {s.expert?.first_name} {s.expert?.last_name}
                              <p className="text-sm font-light">
                                {(s.program as IProgram)?.program_type ===
                                "Mentoring"
                                  ? "Mentor"
                                  : "Coach"}
                              </p>
                            </Typography>
                          </Stack>
                        )}
                        {
                          <Stack alignItems="center" gap={2}>
                            {s?.team ? (
                              <div className="flex">
                                <AvatarGroup max={4}>
                                  {(s?.team as ITeam)?.members.map((c) => (
                                    <Avatar
                                      size="small"
                                      key={c._id}
                                      className="relative transition-transform duration-200 transform cursor-pointer hover:-translate-y-1"
                                      text={c?.profile?.first_name || c?.email}
                                      image={c?.profile_image}
                                    />
                                  ))}
                                </AvatarGroup>
                              </div>
                            ) : (
                              <>
                                <Avatar
                                  size="small"
                                  text={s.user?.first_name || ""}
                                  image={s?.user?.user?.profile_image || ""}
                                />
                                <Typography
                                  fontWeight="semibold"
                                  variant="body2"
                                >
                                  {s.user?.first_name} {s.user?.last_name}
                                  <p className="text-sm font-light">
                                    {(s.program as IProgram)?.program_type ===
                                    "Mentoring"
                                      ? "Mentee"
                                      : "Coachee"}
                                  </p>
                                </Typography>
                              </>
                            )}
                          </Stack>
                        }
                        <Stack gap={2}>
                          <SVG name="Program" />
                          <Typography clamp={1} variant="body2">
                            {(s?.program as IProgram)?.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </div>
                    <Chip
                      shape="rounded"
                      size="small"
                      variant={
                        s.completion_status === "Completed"
                          ? "success"
                          : "warning"
                      }
                      className="cursor-pointer"
                    >
                      {s.completion_status}
                    </Chip>
                    <Chip
                      shape="rounded"
                      size="small"
                      variant="tertiary"
                      className="cursor-pointer"
                    >
                      {isLoggedSession(s) ? "Logged" : "Scheduled"}
                    </Chip>
                  </div>

                  <div className="flex flex-row w-full gap-4">
                    {s?.created_at && (
                      <Typography className="text-xs">
                        Created At:{" "}
                        {format(new Date(s?.created_at), "LLL dd, kk:mm a")}
                      </Typography>
                    )}

                    {s?.updated_at && (
                      <Typography className="text-xs">
                        Last Updated:{" "}
                        {format(new Date(s?.updated_at), "LLL dd, kk:mm a")}
                      </Typography>
                    )}
                  </div>
                </Box>
              </Stack>
            ));
          })}
        </div>
      </div>

      <Drawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <SessionDetailsDrawer
          session={selectedSession as TSessionWithProgram}
          onClose={() => setDrawerOpen(false)}
        />
      </Drawer>

      {hasNextPage && (
        <div className="flex justify-center mt-8">
          {isLoading ? (
            <ListLoadingSpinner text={"Loading more"} isLoading={isLoading} />
          ) : (
            <Button kind="tertiary" onClick={() => fetchNextPage()}>
              Load more
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
