import { useRef, useState } from "react";

export const SearchInput = ({ onChange, placeholder }) => {
  const [value, setValue] = useState("");

  const ref = useRef();

  return (
    <div className="flex flex-row items-center justify-between w-full sm:max-w-screen-sm relative">
      <input
        type="text"
        onChange={(e) => {
          onChange(e.target.value);
          // setValue(e.target.value);
        }}
        className="w-full rounded-md min-w-full"
        placeholder={placeholder}
        // defaultValue={value}
        // ref={ref}
      />
      {value !== "" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 absolute right-0 mr-4"
          viewBox="0 0 20 20"
          fill="currentColor"
          onClick={() => {
            onChange("");
            setValue("");
            // ref.current.value = "";
          }}
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </div>
  );
};
