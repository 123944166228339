import { Link } from "react-router-dom";
import { USER_ROLES } from "../../App.constant";
import { Avatar } from "../Avatar/Avatar";
const colors = [
  "#264653",
  "#2a9d8f",
  "#e9c46a",
  "#f4a261",
  "#e76f51",
  "#03071e",
  "#ef476f",
  "#e07a5f",
  "#f72585",
  "#003049",
  "#0b090a",
  "#6f1d1b",
  "#33415c",
  "#247ba0",
  "#dad7cd"
];

export const ProfileCard = ({ m, i, listType, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="block w-full p-4 transition-colors duration-150 bg-white border rounded-lg cursor-pointer hover:bg-gray-100"
      style={{ height: "fit-content" }}
    >
      <div>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-row items-center w-full">
            {/* Photo */}
            <Avatar
              image={
                listType === USER_ROLES.EXPERT
                  ? m?.mentorProfile?.user?.profile_image
                  : m?.menteeProfile?.user?.profile_image
              }
              bgcolor={`${colors[i]}`}
              name={
                listType === USER_ROLES.EXPERT
                  ? `${m.mentorProfile?.first_name} ${m.mentorProfile?.last_name}`.toUpperCase()
                  : `${m.menteeProfile?.first_name} ${m.menteeProfile?.last_name}`.toUpperCase()
              }
              willShowHover={false}
              className="w-16 h-16 text-xl"
            />
            {/* Info */}
            <div className="ml-4">
              {/* Name */}
              <div className="text-sm font-semibold capitalize">
                {listType === USER_ROLES.EXPERT
                  ? m.mentorProfile?.first_name
                  : m.menteeProfile?.first_name}{" "}
                {listType === USER_ROLES.EXPERT
                  ? m.mentorProfile?.last_name
                  : m.menteeProfile?.last_name}
              </div>
              <div className="text-xs break-words whitespace-wrap">
                {m?.email}
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-4 mt-4">
          {/* Program */}
          <div className="pb-4 border-b">
            <div className="text-sm font-medium text-skin-muted">Program</div>
            <div className="text-base font-bold">{m.program?.name}</div>
          </div>

          <div className="grid gap-4 pb-4 sm:grid-cols-2">
            {(listType === USER_ROLES.EXPERT
              ? m.mentorProfile && m.mentorProfile?.job_function
              : m.menteeProfile && m.menteeProfile?.job_function) && (
              <div className="">
                <div className="text-sm font-medium text-skin-muted">
                  Department
                </div>
                <div className="text-base font-bold">
                  {listType === USER_ROLES.EXPERT
                    ? m.mentorProfile?.job_function
                    : m.menteeProfile?.job_function}
                </div>
              </div>
            )}
            {(listType === USER_ROLES.EXPERT
              ? m.mentorProfile && m.mentorProfile?.position
              : m.menteeProfile && m.menteeProfile?.position) && (
              <div className="">
                <div className="text-sm font-medium text-skin-muted">
                  Position
                </div>
                <div className="text-base font-bold">
                  {listType === USER_ROLES.EXPERT
                    ? m.mentorProfile?.position
                    : m.menteeProfile?.position}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
