import { Avatar, Box, Breadcrumbs, Button, Checkbox, Chip, Popover, Stack, Typography } from "knack-ui";
import { useEffect, useMemo, useRef, useState } from "react";
import FiltersBar from "../../../components/FiltersBar";
import { PaymentMetrics } from "../components/PaymentMetrics";
import { usePaymentFilters } from "../hooks/Payments.hooks";
import { useGetPaymentAnalyticsList } from "../paymentQueries";
import { PaymentMetrics as TPaymentMetrics, IPaymentReportsQuery, IPaymentAnalytics } from "../paymentTypes";
import moment from "moment";
import { PaginationState, RowSelectionState, SortingState } from "@tanstack/react-table";
import { ConfirmPaymentStatusUpdate } from "../components/ConfirmPaymentStatusUpdate";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { useGenerateEarningsCSVReport } from "../paymentMutations";
import { Information } from "../../../components/ToastNotification/Information";
import EmptyState from "../../../components/EmptyState/EmptyState";
import Table from "../../../components/Table";
import { SessionIndicator } from "../components/Indicator";
import AttributeSelector from "../../../components/Selector/AttributeSelector";
import { hoursBetween } from "../../../lib/constants";
import { format } from "date-fns/esm";
import { Link } from "react-router-dom";

const PaymentManagement = () => {
  const { setLoading } = useLoadingSpinner();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const popoverRefEl = useRef<HTMLButtonElement | null>(null);
  const { mutateAsync: generateReport } = useGenerateEarningsCSVReport();
  const [exportAttributes, setExportAttribute] = useState<Array<keyof IPaymentAnalytics>>();
  const [confirmPaymentUpdate, setConfirmPaymentUpdate] = useState<boolean>(false);
  const headerCheckboxRef = useRef<HTMLInputElement | null>(null);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([{ desc: true, id: "start_time" }]);
  const [searchValue, setSearchValue] = useState<string>("");
  const startDate = moment().startOf("month").toDate();
  const endDate = moment().endOf("month").toDate();

  const [query, setQuery] = useState<IPaymentReportsQuery>({
    dateRange: [
      {
        startDate,
        endDate,
        key: "selection"
      }
    ],
    coach: [],
    client: [],
    organization: [],
    sessionStatus: [],
    paymentStatus: [],
    sessionType: ["1:1 session"],
    programType: ["external-coaching"],
    sessionMode: []
  });

  const filters = usePaymentFilters({
    query,
    onQueryChange: setQuery
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, fetchPreviousPage } = useGetPaymentAnalyticsList({
    ...query,
    search_by_email_or_name: searchValue,
    order: sorting.length > 0 ? (sorting[0].desc ? "desc" : "asc") : "desc"
  });

  const metrics = useMemo(() => {
    const _metrics: TPaymentMetrics = {
      total: 0,
      completed: 0,
      paid: 0,
      unpaid: 0
    };

    if (data) {
      const metrics_response = data.pages[0].metrics;
      _metrics.total = metrics_response.total;
      _metrics.completed = metrics_response.completed;
      _metrics.paid = metrics_response.paid;
      _metrics.unpaid = metrics_response.unpaid;
    }

    return _metrics;
  }, [data]);

  const flatSelectedRows = useMemo(() => {
    const selectedIndexes = Object.keys(rowSelection).map((k) => k);
    const allData = data?.pages?.flatMap((p) => p.sessions);
    const selectedData: IPaymentAnalytics[] = [];

    allData?.forEach((d, i) => {
      if (selectedIndexes.includes(i.toString())) {
        selectedData.push(d);
      }
    });
    return selectedData;
  }, [data, rowSelection]);

  function clearAllSelection() {
    setRowSelection({});
  }

  async function onExportCSV(exportAttributes: Array<keyof IPaymentAnalytics>) {
    setLoading(true);
    try {
      await generateReport({ exportAttributes, query });
      setMenuOpen(false);
      setLoading(false);
      Information("A CSV report has been sent to your email");
    } catch (error) {
      setLoading(false);
    }
  }

  /** Reset page index to 0 whenever we change the query to support fresh queries */
  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
    clearAllSelection();
  }, [filters, pageSize]);

  useEffect(() => {
    if (data && !data?.pages[pageIndex] && !isFetchingNextPage) {
      setPagination({ pageIndex: 0, pageSize });
    }
  }, [isFetchingNextPage, pageIndex, pageSize, data]);

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Payments report</Typography>]}</Breadcrumbs>
      </Box>

      <Stack direction="column" gap={4}>
        <Box className="px-6 py-4 border">
          <Stack direction="column" gap={4}>
            <Typography as="h5" variant="h6">
              Payment Management
            </Typography>
            <Stack gap={4} direction="column">
              <Typography fontWeight="normal">Filters</Typography>
              <FiltersBar filters={filters} query={query} />
            </Stack>
          </Stack>
        </Box>

        <Box className="grid grid-cols-1 gap-6 px-6 py-4 border">
          <PaymentMetrics total={metrics.total} completed={metrics.completed} paid={metrics.paid} unpaid={metrics.unpaid} />
          <>
            <ConfirmPaymentStatusUpdate open={confirmPaymentUpdate} setOpen={setConfirmPaymentUpdate} rowSelected={flatSelectedRows} />
            <Stack direction="column" gap={4}>
              <Stack justifyContent="space-between" alignItems="center">
                <Typography fontWeight="semibold">Sessions</Typography>
                <Stack gap={4}>
                  {Object.keys(rowSelection)?.length > 0 && (
                    <Stack gap={4} direction="row" alignItems="center">
                      <Stack alignItems="center" gap={2}>
                        <Chip size="small" className="w-8 h-8 rounded-full">
                          {Object.keys(rowSelection)?.length}
                        </Chip>
                        <Typography fontWeight="semibold">Selected</Typography>
                        <Button kind="ghost" onClick={() => clearAllSelection()}>
                          Clear all
                        </Button>
                      </Stack>
                      <Button onClick={() => setConfirmPaymentUpdate(true)}>Update Payment Status</Button>
                    </Stack>
                  )}
                  <Button ref={popoverRefEl} onClick={() => setMenuOpen(true)}>
                    Export
                  </Button>
                </Stack>
              </Stack>

              <div className="">
                {data?.pages && data.pages[pageIndex]?.sessions?.length === 0 && (
                  <EmptyState iconSize={70} title="No sessions found" icon="SessionsEmptyState" />
                )}
                {data?.pages && data?.pages[pageIndex]?.sessions?.length > 0 && (
                  <Table
                    sorting={sorting}
                    setSorting={setSorting}
                    rowSelection={rowSelection}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    setRowSelection={setRowSelection}
                    initialSorting={[{ id: "start_time", desc: true }]}
                    paginationProps={{
                      totalPages: data.pages[pageIndex]?.totalPages,
                      canGetNextPage: hasNextPage,
                      onNextPage: async (newPage) => {
                        if (!data.pages[newPage - 1]) {
                          console.log("fetching next page SSR");
                          fetchNextPage();
                        }
                      },
                      onPreviousPage: async (newPage) => {
                        if (!data.pages[newPage - 1]) {
                          console.log("fetching previous page SSR");
                          fetchPreviousPage();
                        }
                      }
                    }}
                    data={data.pages.flatMap((p) => p.sessions)}
                    columns={(helper) => [
                      helper.display({
                        header: ({ table }) => {
                          return (
                            <Checkbox
                              {...{
                                ref: headerCheckboxRef,
                                checked: table.getIsAllPageRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected().toString(),
                                onChange: table.getToggleAllPageRowsSelectedHandler()
                              }}
                            />
                          );
                        },
                        cell: ({ row }) => (
                          <Checkbox
                            {...{
                              checked: row.getIsSelected(),
                              indeterminate: row.getIsSomeSelected().toString(),
                              onChange: row.getToggleSelectedHandler()
                            }}
                          />
                        ),
                        id: "selection",
                        size: 40
                      }),
                      helper.accessor((row) => `${row.expert_first_name} ${row.expert_last_name}`, {
                        header: "Expert",
                        cell: ({ row, getValue }) => {
                          const name = getValue();
                          return (
                            <div className="flex items-center gap-2">
                              <Avatar size="small" className="flex-shrink-0" text={name} image={row.original.expert_profile_image} />
                              {name}
                            </div>
                          );
                        }
                      }),
                      helper.accessor("payment_amount", {
                        header: "Earning($)",
                        cell: (props) => {
                          const earning = props.getValue();
                          const rowData = props.row.original;
                          return (
                            <>
                              {rowData.payment_amount ? (
                                <Stack direction="row" gap={1}>
                                  <Typography variant="body2" fontWeight="bold">
                                    ${earning}
                                  </Typography>
                                  <SessionIndicator
                                    label={rowData.payment_status === "Paid" ? "Paid" : "Unpaid"}
                                    status={rowData.payment_status === "Paid" ? "success" : "danger"}
                                  />
                                </Stack>
                              ) : (
                                <> - </>
                              )}
                            </>
                          );
                        },
                        maxSize: 100
                      }),
                      helper.accessor("start_time", {
                        header: "Session Date",
                        cell: ({ getValue }) => format(new Date(getValue()), "MMM dd-hh:mm a"),
                        maxSize: 135
                      }),

                      helper.accessor("creation_date", {
                        header: "Creation Date",
                        cell: ({ getValue }) => format(new Date(getValue()), "MMM dd-hh:mm a"),
                        maxSize: 135
                      }),
                      helper.accessor((row) => `${row.user_first_name} ${row.user_last_name}`, {
                        header: "Client",
                        cell: ({ row, getValue }) => {
                          const name = getValue();
                          return (
                            <div className="flex items-center gap-2">
                              <Avatar size="small" className="flex-shrink-0" text={name} image={row.original.user_profile_image} />
                              {name}
                            </div>
                          );
                        }
                      }),
                      helper.accessor("program_name", {
                        header: "Program name"
                      }),
                      helper.accessor((row) => `${row.organization_name}`, {
                        header: "Organization",
                        cell: ({ row, getValue }) => {
                          const name = getValue();
                          return (
                            <div className="flex items-center gap-2">
                              <Avatar size="small" className="flex-shrink-0" text={name} image={row.original.organization_logo} />
                              {name}
                            </div>
                          );
                        }
                      }),
                      helper.accessor("completion_status", {
                        header: "Session status",
                        cell: (props) => {
                          const status = props.getValue<IPaymentAnalytics["completion_status"]>();
                          const rowData = props.row.original;
                          return (
                            <div className="flex">
                              {["Cancelled", "Deleted"].includes(status) && (
                                <Stack direction="column" gap={4}>
                                  {status === "Cancelled" && (
                                    <Stack direction="column" gap={4}>
                                      <Stack direction="row" gap={1}>
                                        <SessionIndicator status={"danger"} label={status} />
                                        {rowData?.cancelled_at && (
                                          <SessionIndicator label={hoursBetween(rowData, "cancelled_at")} status="danger" />
                                        )}
                                      </Stack>
                                      {rowData?.cancelled_by_first_name && (
                                        <div className="flex items-center gap-2 text-xs">
                                          <Avatar
                                            size="small"
                                            className="flex-shrink-0"
                                            text={rowData.cancelled_by_first_name}
                                            image={rowData.cancelled_by_profile_image}
                                          />
                                          {rowData.cancelled_by_first_name} {rowData.cancelled_by_last_name}
                                        </div>
                                      )}
                                      {rowData?.cancellation_reason && (
                                        <Typography className="text-xs">
                                          {rowData.cancellation_comment || rowData.cancellation_reason}
                                        </Typography>
                                      )}
                                    </Stack>
                                  )}
                                  {status === "Deleted" && (
                                    <Stack direction="column" gap={4}>
                                      <Stack direction="row" gap={1}>
                                        <SessionIndicator status={"danger"} label={status} />
                                        {rowData?.deleted_at && (
                                          <SessionIndicator label={hoursBetween(rowData, "deleted_at")} status="danger" />
                                        )}
                                      </Stack>
                                      {rowData?.deleted_by_first_name && (
                                        <div className="flex items-center gap-2 text-xs">
                                          <Avatar
                                            size="small"
                                            className="flex-shrink-0"
                                            text={rowData.deleted_by_first_name || ""}
                                            image={rowData.deleted_by_profile_image || ""}
                                          />
                                          {rowData.deleted_by_first_name} {rowData.deleted_by_last_name}
                                        </div>
                                      )}
                                      {rowData?.deletion_reason && <Typography className="text-xs">{rowData.deletion_reason}</Typography>}
                                    </Stack>
                                  )}
                                </Stack>
                              )}
                              {["No Show"].includes(status) && <SessionIndicator status={"danger"} label={status} />}
                              {["Pending"].includes(status) && <SessionIndicator status={"warning"} label={status} />}
                              {["Completed"].includes(status) && <SessionIndicator status={"success"} label={status} />}
                            </div>
                          );
                        }
                      }),
                      helper.accessor("isLoggedSession", {
                        header: "Session Mode",
                        enableSorting: false,
                        cell: (props) => {
                          const isLoggedSession = props.getValue();
                          return (
                            <div className="flex justify-center">
                              <SessionIndicator label={isLoggedSession ? "Logged" : "Scheduled"} status="neutral" />
                            </div>
                          );
                        }
                      })
                    ]}
                  />
                )}
              </div>
            </Stack>

            <Popover
              animationType="fade"
              parentRef={popoverRefEl}
              offset={{ bottom: 10, left: 0 }}
              popoverProps={{
                // style: { maxWidth: 500 },
                variant: "elevated",
                elevation: 6
              }}
              isOpen={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <AttributeSelector<IPaymentAnalytics>
                attributeList={[
                  {
                    accessorKey: "user_email",
                    label: "User Email"
                  },
                  {
                    accessorKey: "expert_email",
                    label: "Expert Email"
                  },
                  {
                    accessorKey: "expert_first_name",
                    label: "Expert first name"
                  },
                  {
                    accessorKey: "expert_last_name",
                    label: "Expert last name"
                  },
                  {
                    accessorKey: "user_first_name",
                    label: "Client first Name"
                  },
                  {
                    accessorKey: "user_last_name",
                    label: "Client last Name"
                  },
                  {
                    accessorKey: "organization_name",
                    label: "Organization"
                  },
                  {
                    accessorKey: "program_name",
                    label: "Program name"
                  },
                  {
                    accessorKey: "start_time",
                    label: "Session date"
                  },
                  {
                    accessorKey: "creation_date",
                    label: "Creation date"
                  },
                  {
                    accessorKey: "completion_status",
                    label: "Session status"
                  },
                  {
                    accessorKey: "payment_amount",
                    label: "Earning($)"
                  },
                  {
                    accessorKey: "payment_status",
                    label: "Payment status"
                  }
                ]}
                onChange={(attributes) => {
                  setExportAttribute(attributes);
                }}
                onExportCSV={onExportCSV}
              />
            </Popover>
          </>
        </Box>
      </Stack>
    </div>
  );
};

export default PaymentManagement;
