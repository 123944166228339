import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export const HeadlessModal = ({
  overflowY = "overflow-y-auto",
  disabled = false,
  open,
  setOpen,
  children,
  canDismissFromBackdrop = true,
  width = "sm:w-full",
  showDefaultButton,
  defaultButtonText = "OK",
  buttonFunction,
  smMaxWidth = "sm:max-w-2xl"
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        id="modal"
        style={{ zIndex: 9999999999999 }}
        as="div"
        auto-reopen="true"
        className="fixed inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => (canDismissFromBackdrop ? setOpen(false) : null)}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-bottom bg-white rounded-lg text-left ${overflowY} shadow-xl transform transition-all sm:my-8 sm:align-middle ${smMaxWidth} ${width}`}
            >
              {children}
              {showDefaultButton && (
                <div className="flex items-end justify-end p-4 mt-4">
                  <button
                    type="button"
                    ref={cancelButtonRef}
                    className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white rounded-lg ${
                      disabled ? "bg-skin-muted disabled" : "bg-skin-accent"
                    }`}
                    onClick={() =>
                      buttonFunction && !disabled
                        ? buttonFunction()
                        : setOpen(false)
                    }
                  >
                    {defaultButtonText}
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
