import { Typography, LinearProgress } from "knack-ui";
import { CSSProperties } from "react";

interface IProps {
  label: string;
  percentage: number;
  /**
   * Size of the <LinearProgress/> component
   * @default 12
   */
  progressSize?: number;
  /**
   * Show percentage text inside <LinearProgress/> bar.
   * @default true
   */
  showPercentage?: boolean;
  color?: CSSProperties["color"] | "primary" | "secondary";
  barInnerText?: string;
}

const LinearProgressWithLabel = ({
  label,
  percentage,
  barInnerText,
  color,
  showPercentage = true,
  progressSize = 12
}: IProps) => {
  return (
    <article>
      <div className="flex-1">
        <Typography className="mb-2" variant="caption">
          {label}
        </Typography>
        <LinearProgress
          color={color}
          barInnerText={barInnerText}
          showPercentage={showPercentage}
          percentage={percentage}
          size={progressSize}
        />
      </div>
    </article>
  );
};

export default LinearProgressWithLabel;
