import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Failure } from "../../../../../components/ToastNotification/Failure";
import { Success } from "../../../../../components/ToastNotification/Success";
import hrDashboardServices, { createNewProgram, updateNewProgram } from "../../../../../services/dashboard/hrDashboard.services";
import { KnackSpinnerShowHideState } from "../../../../../StateManagement/zustand";
import "react-datepicker/dist/react-datepicker.css";
import { ProgramState } from "../../../../../StateManagement/ProgramCreation";
import { ProgramType } from "./Components/Sections/ProgramType";
import { ProgramOverview } from "./Components/Sections/ProgramOverview";
import { Goals } from "./Components/Sections/Goals";
import { UploadBanner } from "./Components/Sections/UploadBanner";
import { ExpertSkills } from "./Components/Sections/ExpertSkills";
import { Agreement } from "./Components/Sections/Agreement";
import { ConfirmationModal } from "../../../../../components/modal/ConfirmationModal";
import HeadingSmall from "../../../../../components/heading/heading-small";
import { Button, Stack, Typography } from "knack-ui";
import { queryClient } from "../../../../../lib/appConfig";
import { programsQueryKeys } from "../../../../../features/Programs/programQueries";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";

export const CreateProgramFullPageV3 = () => {
  const { programId } = useParams();
  const [currentSection, setSection] = useState(0);
  const history = useHistory();
  const { setIsopen } = KnackSpinnerShowHideState();
  const { program, setProgram, resetProgram } = ProgramState();
  const [publishConfirm, setPublishConfirm] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [backConfirm, setBackConfirm] = useState(false);
  const [statusAndExit, setStatusAndExit] = useState();
  const checkIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );

  const [timelines] = useState([
    {
      name: "Program type",
      // contentRef: useRef(), // name, description // duration
      title: "Program type",
      subTitle: "Select the program types",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" stroke="currentColor" viewBox="0 0 35 39.054">
          <g id="writing" transform="translate(-26.57)">
            <g id="Group_492" data-name="Group 492" transform="translate(26.57)">
              <g id="Group_491" data-name="Group 491">
                <path
                  id="Path_586"
                  data-name="Path 586"
                  d="M125.223,201.526A1.525,1.525,0,0,0,123.7,200H107.985a1.526,1.526,0,1,0,0,3.051H123.7A1.525,1.525,0,0,0,125.223,201.526Z"
                  transform="translate(-100.365 -184.745)"
                />
                <path
                  id="Path_587"
                  data-name="Path 587"
                  d="M107.985,280a1.526,1.526,0,1,0,0,3.051h9.543a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-100.365 -258.643)"
                />
                <path
                  id="Path_588"
                  data-name="Path 588"
                  d="M37.774,36h-5.1a3.054,3.054,0,0,1-3.051-3.051V6.1a3.054,3.054,0,0,1,3.051-3.051H51.427A3.054,3.054,0,0,1,54.479,6.1v9.382a1.526,1.526,0,1,0,3.051,0V6.1a6.109,6.109,0,0,0-6.1-6.1H32.672a6.109,6.109,0,0,0-6.1,6.1V32.951a6.109,6.109,0,0,0,6.1,6.1h5.1a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-26.57)"
                />
                <path
                  id="Path_589"
                  data-name="Path 589"
                  d="M260.575,273.363a4.581,4.581,0,0,0-6.471,0l-8.375,8.357a1.526,1.526,0,0,0-.382.637l-1.824,6a1.525,1.525,0,0,0,1.867,1.914l6.157-1.706a1.525,1.525,0,0,0,.67-.39l8.358-8.342A4.582,4.582,0,0,0,260.575,273.363Zm-10.229,12.372-3.1.858.907-2.985,5.651-5.639,2.158,2.158Zm8.073-8.058-.3.295-2.157-2.157.295-.294a1.526,1.526,0,0,1,2.158,2.156Z"
                  transform="translate(-226.914 -251.275)"
                />
                <path
                  id="Path_590"
                  data-name="Path 590"
                  d="M123.7,120H107.985a1.526,1.526,0,1,0,0,3.051H123.7a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-100.365 -110.847)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },
    {
      name: "Overview",
      // contentRef: useRef(), // name, description // duration
      title: "Overview",
      subTitle: "Input the details of the program for all participants to view",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" stroke="currentColor" viewBox="0 0 35 39.054">
          <g id="writing" transform="translate(-26.57)">
            <g id="Group_492" data-name="Group 492" transform="translate(26.57)">
              <g id="Group_491" data-name="Group 491">
                <path
                  id="Path_586"
                  data-name="Path 586"
                  d="M125.223,201.526A1.525,1.525,0,0,0,123.7,200H107.985a1.526,1.526,0,1,0,0,3.051H123.7A1.525,1.525,0,0,0,125.223,201.526Z"
                  transform="translate(-100.365 -184.745)"
                />
                <path
                  id="Path_587"
                  data-name="Path 587"
                  d="M107.985,280a1.526,1.526,0,1,0,0,3.051h9.543a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-100.365 -258.643)"
                />
                <path
                  id="Path_588"
                  data-name="Path 588"
                  d="M37.774,36h-5.1a3.054,3.054,0,0,1-3.051-3.051V6.1a3.054,3.054,0,0,1,3.051-3.051H51.427A3.054,3.054,0,0,1,54.479,6.1v9.382a1.526,1.526,0,1,0,3.051,0V6.1a6.109,6.109,0,0,0-6.1-6.1H32.672a6.109,6.109,0,0,0-6.1,6.1V32.951a6.109,6.109,0,0,0,6.1,6.1h5.1a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-26.57)"
                />
                <path
                  id="Path_589"
                  data-name="Path 589"
                  d="M260.575,273.363a4.581,4.581,0,0,0-6.471,0l-8.375,8.357a1.526,1.526,0,0,0-.382.637l-1.824,6a1.525,1.525,0,0,0,1.867,1.914l6.157-1.706a1.525,1.525,0,0,0,.67-.39l8.358-8.342A4.582,4.582,0,0,0,260.575,273.363Zm-10.229,12.372-3.1.858.907-2.985,5.651-5.639,2.158,2.158Zm8.073-8.058-.3.295-2.157-2.157.295-.294a1.526,1.526,0,0,1,2.158,2.156Z"
                  transform="translate(-226.914 -251.275)"
                />
                <path
                  id="Path_590"
                  data-name="Path 590"
                  d="M123.7,120H107.985a1.526,1.526,0,1,0,0,3.051H123.7a1.526,1.526,0,1,0,0-3.051Z"
                  transform="translate(-100.365 -110.847)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },
    {
      name: "Banner",
      // contentRef: useRef(),
      title: "Banner",
      subTitle: "Insert an image attributed to this program",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      )
    },
    {
      name: "Topics",
      // contentRef: useRef(),
      title: "Topics",
      subTitle: "Select the areas or topics of interest for this program",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" stroke="currentColor" viewBox="0 0 28.001 28.001">
          <g id="pencil" transform="translate(-0.001)">
            <g id="Group_494" data-name="Group 494" transform="translate(0.001 0)">
              <path
                id="Path_591"
                data-name="Path 591"
                d="M26.328,18.333a5.676,5.676,0,0,0-5.674-1.408l-2-2L27.39,6.191a2.092,2.092,0,0,0,0-2.958L24.768.613a2.092,2.092,0,0,0-2.958,0L13.076,9.347l-2-2A5.665,5.665,0,0,0,3.38.5a.547.547,0,0,0-.166.887L5.823,3.992,3.978,5.836,1.369,3.227a.547.547,0,0,0-.887.166,5.665,5.665,0,0,0,6.853,7.7l2,2-6.69,6.69A.552.552,0,0,0,2.511,20L.13,27.181a.547.547,0,0,0,.691.691L8,25.493a.659.659,0,0,0,.223-.135l6.69-6.69,2,2a5.665,5.665,0,0,0,7.7,6.853.547.547,0,0,0,.166-.887l-2.609-2.609,1.845-1.845,2.609,2.609a.547.547,0,0,0,.887-.166,5.665,5.665,0,0,0-1.179-6.289ZM22.584,1.386a1,1,0,0,1,1.411,0l2.621,2.621a1,1,0,0,1,0,1.411L24.842,7.193,20.81,3.161ZM7.867,10.076a.547.547,0,0,0-.58-.125,4.573,4.573,0,0,1-6.07-5.33L3.592,7a.547.547,0,0,0,.773,0L6.983,4.378a.547.547,0,0,0,0-.773L4.608,1.23A4.573,4.573,0,0,1,9.938,7.3a.547.547,0,0,0,.125.58l2.24,2.24-2.2,2.2ZM1.51,26.492,2.3,24.1l1.6,1.6Zm3.549-1.176L2.687,22.943l.584-1.763,3.552,3.552ZM7.836,24.2,6.207,22.569l5.353-5.353a.547.547,0,0,0-.773-.773L5.433,21.8,3.8,20.166,20.036,3.934l.044.044,1.585,1.585-7.383,7.383a.547.547,0,1,0,.773.773l7.383-7.383,1.629,1.629Zm18.936-.8L24.4,21.019a.547.547,0,0,0-.773,0l-2.618,2.618a.547.547,0,0,0,0,.773l2.375,2.375a4.573,4.573,0,0,1-5.33-6.07.547.547,0,0,0-.125-.58l-2.24-2.24,2.2-2.2,2.24,2.24a.547.547,0,0,0,.58.125,4.573,4.573,0,0,1,6.07,5.33Z"
                transform="translate(-0.001 0)"
              />
              <g id="Group_493" data-name="Group 493" transform="translate(12.382 14.528)">
                <path
                  id="Path_592"
                  data-name="Path 592"
                  d="M226.945,266.743a.547.547,0,1,1,.536-.44A.552.552,0,0,1,226.945,266.743Z"
                  transform="translate(-226.399 -265.651)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },
    {
      name: "Goals",
      // contentRef: useRef(),
      title: "Goals",
      subTitle: "Select the goals for this program",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" stroke="currentColor" viewBox="0 0 28.001 28.001">
          <g id="pencil" transform="translate(-0.001)">
            <g id="Group_494" data-name="Group 494" transform="translate(0.001 0)">
              <path
                id="Path_591"
                data-name="Path 591"
                d="M26.328,18.333a5.676,5.676,0,0,0-5.674-1.408l-2-2L27.39,6.191a2.092,2.092,0,0,0,0-2.958L24.768.613a2.092,2.092,0,0,0-2.958,0L13.076,9.347l-2-2A5.665,5.665,0,0,0,3.38.5a.547.547,0,0,0-.166.887L5.823,3.992,3.978,5.836,1.369,3.227a.547.547,0,0,0-.887.166,5.665,5.665,0,0,0,6.853,7.7l2,2-6.69,6.69A.552.552,0,0,0,2.511,20L.13,27.181a.547.547,0,0,0,.691.691L8,25.493a.659.659,0,0,0,.223-.135l6.69-6.69,2,2a5.665,5.665,0,0,0,7.7,6.853.547.547,0,0,0,.166-.887l-2.609-2.609,1.845-1.845,2.609,2.609a.547.547,0,0,0,.887-.166,5.665,5.665,0,0,0-1.179-6.289ZM22.584,1.386a1,1,0,0,1,1.411,0l2.621,2.621a1,1,0,0,1,0,1.411L24.842,7.193,20.81,3.161ZM7.867,10.076a.547.547,0,0,0-.58-.125,4.573,4.573,0,0,1-6.07-5.33L3.592,7a.547.547,0,0,0,.773,0L6.983,4.378a.547.547,0,0,0,0-.773L4.608,1.23A4.573,4.573,0,0,1,9.938,7.3a.547.547,0,0,0,.125.58l2.24,2.24-2.2,2.2ZM1.51,26.492,2.3,24.1l1.6,1.6Zm3.549-1.176L2.687,22.943l.584-1.763,3.552,3.552ZM7.836,24.2,6.207,22.569l5.353-5.353a.547.547,0,0,0-.773-.773L5.433,21.8,3.8,20.166,20.036,3.934l.044.044,1.585,1.585-7.383,7.383a.547.547,0,1,0,.773.773l7.383-7.383,1.629,1.629Zm18.936-.8L24.4,21.019a.547.547,0,0,0-.773,0l-2.618,2.618a.547.547,0,0,0,0,.773l2.375,2.375a4.573,4.573,0,0,1-5.33-6.07.547.547,0,0,0-.125-.58l-2.24-2.24,2.2-2.2,2.24,2.24a.547.547,0,0,0,.58.125,4.573,4.573,0,0,1,6.07,5.33Z"
                transform="translate(-0.001 0)"
              />
              <g id="Group_493" data-name="Group 493" transform="translate(12.382 14.528)">
                <path
                  id="Path_592"
                  data-name="Path 592"
                  d="M226.945,266.743a.547.547,0,1,1,.536-.44A.552.552,0,0,1,226.945,266.743Z"
                  transform="translate(-226.399 -265.651)"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    },
    {
      name: "Agreement",
      // contentRef: useRef(),
      title: "Agreement",
      subTitle: "Insert the guidelines for all participants to agree to in advance of the program",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
          />
        </svg>
      )
    }
  ]);

  const AllSections = [ProgramType, ProgramOverview, UploadBanner, ExpertSkills, Goals, Agreement];

  useEffect(() => {
    if (programId) {
      getProgram();
    }

    return () => resetProgram(); // reset program to default
  }, [programId]);

  function getProgram() {
    hrDashboardServices
      .getProgram(programId, "Mentoring")
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          setProgram(data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops! something went wrong. Please contact support");
      });
  }

  function onHandleClick(status, willExit) {
    setOpenConfirm(true);
    setStatusAndExit({ status, willExit });
  }

  function onHandlePreview(status, willExit) {
    const _program = { ...program };
    updateNewProgram(_program).then((res) => {
      history.push({
        pathname: "/view-program",
        state: { _id: _program?._id, isPreview: true }
      });
    });
  }

  function onHandlePublishClick(status, willExit) {
    setPublishConfirm(true);
    setStatusAndExit({ status, willExit });
  }

  function uploadProgram(status = "Published", willExit = false) {
    setOpenConfirm(false);
    const _program = { ...program };
    let isValid = false;

    if (status === "Published") {
      _program.invite = true;
      _program.isClosed = true;
      isValid = validateProgram("full");
    } else if (status === "Pending") {
      // if we save as draft then bypass validation
      isValid = true;
    }
    if (!isValid) {
      setPublishConfirm(false);
      setOpenConfirm(false);
      if (new Date(_program.end_date) >= new Date(_program.start_date)) {
        if (_program.skills.length >= 3) {
          // console.log({ _program });
          Failure("Please complete all fields to create this program");
        } else {
          Failure("Please add at least 3 topics");
        }
      } else {
        Failure("Please select a valid date range");
      }
      return;
    }
    _program.status = status;
    setIsopen(true);

    console.log(_program._id);
    // is not new program and has id - should update
    if (_program._id) {
      updateNewProgram(_program)
        .then((res) => {
          Success(`${_program?.status === "Published" ? "Program updated successfully!" : "Program saved as draft!"}`);
          setIsopen(false);
          if (willExit) {
            if (_program?.status === "Pending") {
              history.push("/programs");
              return;
            }
            history.push({
              pathname: `/programs/${_program._id}`
            });
            resetProgram();
          } else {
            history.push({
              pathname: `/programs/${_program._id}`
            });
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Something went wrong, Please try again");
          setIsopen(false);
        });
    }
    // is new program - should create
    else {
      if (_program.program_type === "Mentoring") {
        delete _program.coach_type;
      }
      createNewProgram(_program)
        .then((res) => {
          const _program = { ...program };
          _program.status = status;
          Success(`${_program?.status === "Published" ? "Program Created Successfully!" : "Program Saved as Draft!"}`);
          setIsopen(false);

          const program_creation_response = res.data.data;
          const updatedProgram = Object.assign(_program, program_creation_response);
          setProgram(_program);

          if (willExit) {
            if (_program?.status === "Pending") {
              history.push("/programs");
              return;
            }
            history.push({
              pathname: `/programs/${updatedProgram._id}`
            });
            resetProgram();
          }
        })
        .catch((e) => {
          console.log(e);
          Failure("Something went wrong, Please try again");
          setIsopen(false);
        });
    }

    queryClient.invalidateQueries(programsQueryKeys.HrProgram({ programId: program._id }));
  }

  function handleNext() {
    setSection(currentSection + 1);
  }

  function validateProgram(sectionId) {
    const _program = { ...program };
    const contentTestContainer = document.createElement("div");
    contentTestContainer.innerHTML = _program.agreement;
    const textContent = contentTestContainer.textContent;
    const regex = /(<([^>]+)>)/gi;
    let programOverview = !!_program?.overview?.replace(regex, "").length;
    switch (sectionId) {
      case 0:
        return _program.platform === "Mentoring" || _program.program_type || _program.coach_type === "own_coach" || _program.plan !== "";
      case 1:
        return (
          _program.name &&
          programOverview &&
          _program.session_count > 0 &&
          _program.end_date &&
          _program.start_date &&
          new Date(_program.end_date) >= new Date(_program.start_date)
        );
      case 2:
        return _program.image_url && _program.image_url !== "";
      case 3:
        return _program.skills && _program.skills.length >= 3;
      case 4:
        return _program.goals && _program.goals.length > 0;
      case 5:
        return textContent && textContent !== "";
      case "full":
        return (
          _program.platform &&
          _program.name &&
          programOverview &&
          _program.session_count > 0 &&
          _program.end_date &&
          _program.start_date &&
          new Date(_program.end_date) >= new Date(_program.start_date) &&
          (_program.program_type === "Coaching" ? _program.coach_type : _program.program_type === "Mentoring") &&
          _program.skills &&
          _program.skills.length >= 3 &&
          _program.goals &&
          _program.goals.length > 0 &&
          textContent &&
          textContent !== ""
        );
      default:
        return true;
    }
  }

  function handlePrevious() {
    setSection(currentSection - 1);
  }

  return (
    <>
      <ConfirmationModal
        title={statusAndExit?.status === "Published" ? "Save Changes" : " Save as Draft"}
        header={
          statusAndExit?.status === "Published" ? (
            <span className="max-w-lg">Are you sure you want to update the program with changes you have made?</span>
          ) : (
            " Are you sure you want to save this program as a draft?"
          )
        }
        showDefaultButton={true}
        buttonFunction={() => uploadProgram(statusAndExit.status, statusAndExit.willExit)}
        open={openConfirm}
        setOpen={setOpenConfirm}
        defaultButtonText={statusAndExit?.status === "Published" ? "Save" : "Save draft"}
        width="250"
      />
      <ConfirmationDialog
        isOpen={publishConfirm}
        onClose={() => setPublishConfirm(false)}
        headerText="Create Program"
        footerComponent={() => {
          return (
            <Stack justifyContent="flex-end" gap={2}>
              <Button kind="ghost" onClick={() => setPublishConfirm(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  uploadProgram(statusAndExit.status, statusAndExit.willExit);
                }}
              >
                Create program
              </Button>
            </Stack>
          );
        }}
      >
        <Typography className="mb-4" fontWeight="semibold">
          Creating this program enables you to start inviting participants and kick-start the program. Are you sure you want to create this
          program?
        </Typography>
        <Typography variant="body1" fontWeight="medium">
          Once you create this program:
        </Typography>
        <ul className="grid grid-cols-1 gap-1 my-4 list-disc ms-4">
          <Typography as="li" variant="subtitle2">
            You will not be able to change the program type after creating this program.
          </Typography>
          <Typography as="li" variant="subtitle2">
            You will no longer be able to remove topics. However, you can still add more topics to the program.
          </Typography>
          <Typography as="li" variant="subtitle2">
            You will not be able to edit the program agreement.
          </Typography>
        </ul>
        <Typography variant="subtitle2" fontWeight="medium">
          If you are not ready to create this program, you can save it as a draft.
        </Typography>
      </ConfirmationDialog>

      <ConfirmationModal
        title={"Leave Program"}
        header={"Are you sure you want to leave without saving this program?"}
        showDefaultButton={true}
        buttonFunction={() => history.goBack()}
        open={backConfirm}
        setOpen={setBackConfirm}
        defaultButtonText={"Leave without saving"}
        defaultButtonCancelText={"Stay on this page"}
        width="250"
      />

      {
        <button className="flex mb-2 flex-start" onClick={() => setBackConfirm(true)}>
          <div className="flex flex-row items-center w-auto p-2 text-xs bg-white rounded-lg shadow cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {" "}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
            <span className="ml-2 uppercase"> BACK </span>
          </div>
        </button>
      }

      {/* Header */}
      <div className="flex justify-between my-4">
        <HeadingSmall title={timelines[currentSection]?.title} subHeading={timelines[currentSection]?.subTitle} />

        <div className="sticky top-0 z-50 flex items-center justify-end gap-4 mb-4 transition transform delay-350 duration-350 sm:mb-0">
          {program?._id && (
            <Button kind="ghost" onClick={() => onHandlePreview(program.status, true)}>
              Preview
            </Button>
          )}

          <Button kind={program.status === "Pending" ? "ghost" : "primary"} onClick={() => onHandleClick(program.status, true)}>
            {program.status === "Pending" ? "Save as Draft" : "Save Changes"}
          </Button>

          {program.status === "Pending" && (
            <Button onClick={() => (program.name !== "" && program.overview !== "" ? onHandlePublishClick("Published", true) : null)}>
              Create Program
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-row h-full gap-4 mb-32 text-skin-base">
        {/* Program Creation Timeline */}
        <div className="hidden h-full p-4 sm:block" style={{ maxWidth: 200 }}>
          {/* Floating line */}

          {/* Timelines */}
          <div className="relative">
            <div className="h-full max-h-64 absolute w-1 bg-skin-light transform translate-x-3.5 z-1"></div>
            {timelines.map((t, i) => (
              <label
                onClick={() => {
                  setSection(i);
                }}
                className={`flex flex-row items-center mb-4 hover:bg-blue-50 cursor-pointer rounded-full pr-4`}
                key={i}
              >
                {/* Icons */}
                {validateProgram(i) && (
                  <>
                    <div
                      className={`rounded-full font-bold h-8 w-8 bg-white flex items-center justify-center shadow mr-4 z-10 bg-skin-green text-white `}
                    >
                      {checkIcon}
                    </div>
                    <div className={`text-sm whitespace-nowrap text-green-600 font-bold`}>{t.name}</div>
                  </>
                )}

                {!validateProgram(i) && (
                  <>
                    <div
                      className={`rounded-full font-bold h-8 w-8 bg-white flex items-center justify-center shadow mr-4 z-10 ${
                        currentSection === i ? "bg-skin-accent text-white" : ""
                      }`}
                    >
                      {i + 1}
                    </div>
                    <div className={`text-sm whitespace-nowrap ${currentSection === i ? "text-skin-accent font-bold" : ""}`}>{t.name}</div>
                  </>
                )}
              </label>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="w-full h-full">
          <div className="px-6 py-4 bg-white rounded-lg shadow">
            {/* Sections */}
            <div className="my-4">
              {AllSections.map((SECTION, i) => (
                <div key={"program_section_" + i} className={`transition delay-300 ${currentSection === i ? "block" : "hidden"}`}>
                  {currentSection === i && <SECTION reference={timelines[i].contentRef} />}
                </div>
              ))}
            </div>

            {/* Navigations */}
            <div className="flex justify-between gap-4 p-2">
              <div>
                {currentSection > 0 && (
                  <button onClick={() => handlePrevious()} className="px-8 py-2 text-sm font-bold bg-gray-200 border rounded-lg text-skin">
                    Back
                  </button>
                )}
              </div>
              {currentSection < AllSections.length - 1 && <Button onClick={() => handleNext()}>Next</Button>}
              {currentSection === AllSections.length - 1 && (
                <Button onClick={() => onHandleClick(program.status, true)}>Save Changes</Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
