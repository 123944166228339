import { lazy } from "react";
import PageSkeleton from "../../../../components/skeletons/PageSkeleton";
import { useGetKnackCoaches } from "../../../../features/People/peopleQueries";
import { withErrorBoundary } from "../../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../../lib/HOCs/withSuspense";
import { loadComponent } from "../../../../lib/utils";
import { onSearchPeople } from '../../hrDashboard/hrPeople/PeopleList';

const LazyConnectionsList = lazy(() =>
  loadComponent(
    () => import("../../../../V3/Pages/Connections/ui/ConnectionsList")
  )
);

const ConnectionsList = withErrorBoundary(
  withSuspense(LazyConnectionsList, {
    fallBackComponent: <PageSkeleton />
  })
);
const AdminCoaches = () => {
  const { value } = onSearchPeople();
  const { data: connections } = useGetKnackCoaches({
    suspense: true,
    select: (users) => {
      const mutated = users.map((u) => ({
        ...u.profile_data,
        user: { _id: u._id, email: u.email }
      })).filter(u => Boolean(u.first_name))

      if (value && value !== "") {
        return mutated.filter(u => `${u?.first_name}${u?.last_name}${u?.user?.email}`.toLowerCase().includes(value))
      }
      return mutated
    }
  });

  return (
    <div className="h-full">
      <ConnectionsList connections={connections} />
    </div>
  );
};

export default AdminCoaches;
