import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { SVGProps } from "../../../components/SVG/SVG";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import OnboardingCard from "../ui/OnboardingCard";
import { requestCronofyAuthorizationURL } from "../../../services/Scheduling/CronofyService";
import { useGetGoals } from "../../Goals/goalQueries";
import { useGetEnrolledPrograms } from "../../Programs/programQueries";
import { useUser, useUserCompany, useUserRoles } from "../../User/userQueries";
import { useGetCalendarAvailability, useGetCalendarProfile } from "../../Calendar/calendarQueries";

export type OnboardingStepOptions = {
  key?: string;
  title: string;
  icon: SVGProps["name"];
  handleSkip: VoidFunction;
  ctaText: string;
  ctaCb: VoidFunction;
};

interface IProps {
  handleToggleInvitations: VoidFunction;
  handleToggleOnboardingDialog: VoidFunction;
}

const useOnboardingCards = ({ handleToggleInvitations, handleToggleOnboardingDialog }: IProps) => {
  const { roleState } = useUserRoles();
  const { data: company } = useUserCompany();
  const { data: hasPrograms } = useGetEnrolledPrograms({
    select: (all) => all.total > 0,
    suspense: true
  });
  const { data: user } = useUser({
    select: (user) => {
      return {
        // hasCalendar: Boolean(user.profile?.scheduler_id),
        hasAssessment: user.assessment_completed,
        calendar_id: user.profile?.scheduler_id,
        userId: user._id,
        account_type: user.account_type
      };
    }
  });
  const { data: calendarProfile } = useGetCalendarProfile(user?.calendar_id, user?.userId);
  const { data: availability } = useGetCalendarAvailability(user?.calendar_id, user?.userId, {
    enabled: Boolean(user?.calendar_id) && Boolean(roleState?.activeRole === "Expert"),
    select: (data) => {
      return { hasSetAvailability: data.availability_rules.length > 0 };
    }
  });
  const { data: hasGoals } = useGetGoals({
    select: (goals) => goals.goals.length > 0,
    suspense: true,
    enabled: roleState?.activeRole === "User"
  });
  const [cards, setCards] = useState<JSX.Element[]>([]);
  const [activeCard, setActiveCard] = useState(0);

  const { getValue, setValue } = useLocalStorage();
  const history = useHistory();

  const handleSkipCard = useCallback(() => {
    if (activeCard + 1 >= cards.length) {
      setActiveCard(0);
    } else {
      setActiveCard((prev) => prev + 1);
    }
  }, [activeCard, cards.length]);

  // useEffect dependencies
  const deps = useMemo(() => {
    if (roleState?.activeRole === "Expert") {
      return [hasPrograms, user, handleSkipCard, company, calendarProfile];
    }

    return [hasPrograms, user, hasGoals, handleSkipCard, company];
  }, [handleSkipCard, hasGoals, hasPrograms, roleState?.activeRole, user, calendarProfile, company]);

  const schedulingEnabled = useMemo(() => {
    if (user?.account_type === "Coach") {
      return true;
    }

    if (company && company?.schedulingSupported) return true;
  }, [company]);

  useEffect(() => {
    const cardsMarkup: JSX.Element[] = [];
    let stepOptions: OnboardingStepOptions[] = [];
    const onboardingStepsCount = company?.features?.includes("Assessment") ? 4 : 3;
    let progress = 0;

    if (!hasPrograms) {
      stepOptions.push({
        key: "program",
        title: "Register in a program",
        icon: "ProgramBig",
        ctaCb: handleToggleInvitations,
        ctaText: "Register in a program",
        handleSkip: handleSkipCard
      });
    }
    if (roleState?.activeRole === "User" && !hasGoals) {
      stepOptions.push({
        key: "goals",
        title: "Create goals",
        icon: "GoalsBig",
        ctaCb: () => history.push("/goals"),
        ctaText: "Set up Goals",
        handleSkip: handleSkipCard
      });
    }
    if (schedulingEnabled && roleState?.activeRole === "Expert" && !calendarProfile) {
      stepOptions.push({
        key: "calendar",
        title: "Set up your calendar",
        icon: "CalendarBig",
        ctaCb: () => requestCronofyAuthorizationURL(),
        ctaText: "Set up your calendar",
        handleSkip: handleSkipCard
      });
    }
    if (schedulingEnabled && roleState?.activeRole === "Expert" && !availability?.hasSetAvailability) {
      stepOptions.push({
        key: "availability",
        title: "Set up your availability",
        icon: "CalendarBig",
        ctaCb: () => history.push("/availability"),
        ctaText: "Go to availability page",
        handleSkip: handleSkipCard
      });
    }
    if (company?.features?.includes("Assessment") && !user?.hasAssessment) {
      stepOptions.push({
        key: "assessment",
        title: "Take assessment",
        icon: "AssessmentsBig",
        ctaCb: () => history.push("/reports"),
        ctaText: "Take assessment",
        handleSkip: handleSkipCard
      });
    }
    const remainingSteps = stepOptions.length;
    progress = Math.round((100 / onboardingStepsCount) * (onboardingStepsCount - remainingSteps));

    // If User has completed onboarding then don't populate cardsMarkup
    if (remainingSteps === 0) {
      // if he  didn't press close before
      if (getValue("showProfileComplete", true)) {
        return setCards([
          <OnboardingCard
            key="success"
            handleSkip={() => {
              setCards([]);
              setValue("showProfileComplete", false);
            }}
            ctaCb={() => {}}
            ctaText=""
            success
            remainingSteps={remainingSteps}
            icon="SuccessBigIcon"
            progress={100}
            title="Profile setup completed"
          />
        ]);
      }
    } else {
      stepOptions.forEach((step) => {
        cardsMarkup.push(
          <OnboardingCard
            remainingSteps={remainingSteps}
            key={step.key}
            handleSkip={step.handleSkip}
            ctaCb={step.ctaCb}
            ctaText={step.ctaText}
            icon={step.icon}
            progress={progress}
            title={step.title}
          />
        );
      });
      setCards(cardsMarkup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  useEffect(() => {
    if (!hasPrograms && getValue("showOnboardingDialog")) {
      setTimeout(() => {
        setValue("showOnboardingDialog", false);
        handleToggleOnboardingDialog();
      }, 500);
    }
  }, [getValue, handleToggleOnboardingDialog, hasPrograms, setValue]);
  return {
    cards,
    activeCard
  };
};

export default useOnboardingCards;
