import ContentLoader from "react-content-loader";

const NewConnectionRequestCardSkeleton = () => {
  return (
    <ContentLoader
      backgroundColor="#ececec"
      foregroundColor="#fafafa"
      viewBox="0 0 303 62"
      height={62}
      width="303"
    >
      <rect x="0" y="0" rx="5" ry="5" width="110" height="15" />
      <rect x="0" y="24" rx="5" ry="5" width="200" height="13" />
    </ContentLoader>
  );
};

export default NewConnectionRequestCardSkeleton;
