import { IFavoriteMatch, IProgramInvite, ProgramApplication } from "./ProgramTypes";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { apiHttp } from "../../lib/appConfig";
import { programsQueryKeys } from "./programQueries";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { PeopleTypes } from "../People/PeopleTypes";
import { Success } from "../../components/ToastNotification/Success";

async function setFavoriteMatch({ expertProfile, program }: { expertProfile: string; program: string }) {
  const res = await apiHttp.post<ServerResponse<IFavoriteMatch>>("v2/programs/favorites/", { expertProfile, program });
  return res.data.data;
}

async function deleteFavoriteMatch(id: string) {
  const res = await apiHttp.delete<ServerResponse<IFavoriteMatch>>("v2/programs/favorites/" + id);
  return res.data.data;
}

async function removeUserFromProgram({ userId, programId }: { userId: string; programId: string }) {
  const res = await apiHttp.get<ServerResponse<any>>("v1/programs/archive-user-program-by-hr/" + userId + "/" + programId);
  return res.data.data;
}

async function endRelationship(request_id: string) {
  const res = await apiHttp.post<ServerResponse<any>>("v1/auth/mentor-requests/_update/" + request_id, { status: "cancelled" });
  return res.data.data;
}

async function manuallyAssignExpert({ expertId, userId, programId }: { expertId: string; userId: string; programId: string }) {
  const res = await apiHttp.post(`/v1/auth/mentor-requests/manual`, {
    mentor: expertId,
    mentee: userId,
    program: programId
  });
  return res.data;
}

async function sendRegistrationReminder({ userIDS, programId }: { userIDS: string[]; programId: string }) {
  const res = await apiHttp.post(`/v1/programs/${programId}/send-registration-reminder`, {
    userIDS
  });
  return res.data;
}

async function sendAutoEnrollmentReminder(programId: string) {
  const res = await apiHttp.get(`/v1/programs/${programId}/auto-enrollment-reminder`);
  return res.data;
}

async function sendExpertSelectionReminder({ userIDS, programId }: { userIDS: string[]; programId: string }) {
  const res = await apiHttp.post(`/v1/programs/${programId}/send-expert-selection-reminder`, {
    userIDS
  });
  return res.data;
}

export interface AddByEmailResponse {
  email: string;
  msg: string;
  status: boolean;
}

async function inviteByEmail({ programId, data }: { programId: string; data: { type: PeopleTypes; emails: string[] } }) {
  const res = await apiHttp.post<ServerResponse<AddByEmailResponse[]>>(`/v1/programs/${programId}/updateByEmail`, data);
  return res.data;
}

async function launchMatching({ programId }: { programId: string }) {
  const res = await apiHttp.get(`/v1/programs/${programId}/launch-matching`);
  return res.data;
}

async function sendInvitation({ data, programId }: { programId: string; data: IProgramInvite[] }) {
  const res = await apiHttp.post(`/v1/programs/${programId}/invites`, data);
  return res.data;
}

async function archiveGlobalProgram(programId: string) {
  const res = await apiHttp.get(`/v1/programs/archive-global-program/${programId}`);
  return res.data;
}

async function createMatches({ userId, programId }: { userId: string; programId: string }) {
  const res = await apiHttp.get(`v1/profile/match-user/${programId}/${userId}`);
  return res.data;
}

async function confirmExpertMatch({
  expertId,
  programId,
  status
}: {
  expertId: string;
  programId?: string;
  status: "pending" | "approved" | "rejected" | "cancelled";
}) {
  const res = await apiHttp.post("/v1/programs/matches/request-expert", {
    mentor: expertId,
    program: programId,
    status
  });
  return res.data;
}
async function withdrawExpertRequest({ requestId }: { requestId: string }) {
  const res = await apiHttp.get(`/v1/programs/matches/withdraw-request/${requestId}`);
  return res.data;
}
async function updateCoachingOrMentoringTopics({ topics, programId }: { topics: string[]; programId?: string }) {
  const res = await apiHttp.post("/v1/programs/update-topics", {
    program: programId,
    selectedSkills: topics
  });
  return res.data;
}

async function archiveUserProgram(programId: string) {
  const res = await apiHttp.get<ServerResponse<IProgramInvite>>("v1/programs/archive-user-program/" + programId);
  return res.data.data;
}

// updates program invitation,, meh
async function enrollInProgram({ invitationId, ...props }: ProgramApplication) {
  const res = await apiHttp.post<ServerResponse<IProgramInvite>>("v1/programs/invites/" + invitationId, {
    ...props
  });
  return res.data.data;
}

async function updateProgramInvite({ _id, ...props }: Partial<IProgramInvite>) {
  const res = await apiHttp.post<ServerResponse<IProgramInvite>>("v1/programs/invites/update/" + _id, {
    ...props
  });
  return res.data.data;
}

export const useArchiveUserProgram = () => {
  const queryClient = useQueryClient();
  return useMutation(archiveUserProgram, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    },
    onError: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    }
  });
};

export const useConfirmExpertMatch = () => {
  const queryClient = useQueryClient();
  return useMutation(confirmExpertMatch, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    },
    onError: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    }
  });
};
export const useWithdrawExpertRequest = () => {
  const queryClient = useQueryClient();
  return useMutation(withdrawExpertRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    },
    onError: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    }
  });
};

export const useUpdateCoachingOrMentoringTopics = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCoachingOrMentoringTopics, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    }
  });
};

export const useCreateMatches = () => {
  const queryClient = useQueryClient();
  return useMutation(createMatches, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    }
  });
};

export const useSendRegistrationReminder = () => {
  return useMutation(sendRegistrationReminder);
};

export const useSendAutoEnrollmentReminder = () => {
  return useMutation(sendAutoEnrollmentReminder);
};

export const useSendExpertSelectionReminder = () => {
  return useMutation(sendExpertSelectionReminder);
};

export const useManualExpertAssignment = () => {
  return useMutation(manuallyAssignExpert);
};

export const useInviteByEmail = () => {
  return useMutation(inviteByEmail);
};

export const useLaunchMatching = () => {
  const queryClient = useQueryClient();
  return useMutation(launchMatching, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
    }
  });
};

export const useSendInvitation = () => {
  return useMutation(sendInvitation);
};

export const useEnrollInProgram = (useMutationOptions?: UseMutationOptions<IProgramInvite, Error, ProgramApplication>) => {
  return useMutation(enrollInProgram, useMutationOptions);
};

export const useUpdateProgramInvite = (useMutationOptions?: UseMutationOptions<IProgramInvite, Error, Partial<IProgramInvite>>) => {
  return useMutation(updateProgramInvite, useMutationOptions);
};

export const useArchiveGlobalProgram = () => {
  return useMutation(archiveGlobalProgram);
};

/**
 * For HR to remove a participant from a program
 * @returns
 */
export const useRemoveUserFromProgram = () => {
  return useMutation(removeUserFromProgram, {
    onSuccess: () => {
      Success("User removed from program successfully");
    }
  });
};

/**
 * For HR to end relationship for a user
 * @returns
 */
export const useEndRelationship = () => {
  const queryClient = useQueryClient();
  return useMutation(endRelationship, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
      Success("Relationship ended successfully");
    }
  });
};

export const useSetFavoriteMatch = () => {
  const queryClient = useQueryClient();
  return useMutation(setFavoriteMatch, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
      Success("Match added to favorites tab.");
    }
  });
};

export const useDeleteFavoriteMatch = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteFavoriteMatch, {
    onSuccess: () => {
      queryClient.invalidateQueries(programsQueryKeys.all);
      Success("Favorite match removed.");
    }
  });
};
