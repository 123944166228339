import { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { HeadlessModal } from "../components/modal/HeadlessModal";
import authService from "../services/auth.service";

/**
 * Component Monitors the application to detect idles times
 * if no activity detected within the timeout period, the application logs out the user.
 */
export const IdleTimerSessionActivity = () => {
  var timeleft = 10;
  const [countdown, setCountdown] = useState(10);
  const [OpenModal, setOpenModal] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeIntervalRef = useRef(null);

  function onStartCountdown(e) {
    if (timeleft <= 0) {
      clearInterval(sessionTimeIntervalRef.current);
      logOut();
    } else {
      setCountdown(timeleft);
    }
    timeleft -= 1;
  }

  function onIdle(e) {
    console.log("[onIdle] isIdle");
    sessionTimeIntervalRef.current = setInterval(onStartCountdown, 1000);
    setOpenModal(true);
  }

  function logOut() {
    console.log("[Logout]");
    setCountdown(10);
    setOpenModal(false);

    authService.logout();
  }

  function stayActive() {
    clearInterval(sessionTimeIntervalRef.current);
    setCountdown(null);
    setOpenModal(false);
  }

  return (
    <>
      <HeadlessModal open={OpenModal} setOpen={stayActive} width="">
        <div className="flex flex-col items-center justify-center max-w-lg p-4 text-center sm:p-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-20 h-20"
            viewBox="0 0 291.406 316.157"
          >
            <g id="alarm-clock" transform="translate(-20.001)">
              <path
                id="Path_632"
                data-name="Path 632"
                d="M238.576,247.917V223.3a9.28,9.28,0,1,0-18.56,0v28.458a9.279,9.279,0,0,0,2.718,6.562l11.358,11.358a9.28,9.28,0,1,0,13.124-13.124Z"
                transform="translate(-76.269 -81.611)"
                fill="var(--color-accent)"
              />
              <path
                id="Path_633"
                data-name="Path 633"
                d="M270.72,180.719c.314-3.514.475-7.048.475-10.569a117.532,117.532,0,0,0-9.29-45.926A46.384,46.384,0,0,0,249.539,38.2V27.841A27.873,27.873,0,0,0,221.7,0H84.963A27.872,27.872,0,0,0,57.122,27.841V38.058a46.391,46.391,0,0,0-12.974,86.165A118.033,118.033,0,0,0,72.275,256.338L54.282,274.332a9.28,9.28,0,0,0,13.124,13.124l19.45-19.449a118.069,118.069,0,0,0,94.5,16.871A71.135,71.135,0,1,0,270.72,180.719Zm-3.228-97.193a27.9,27.9,0,0,1-14.154,24.234,119.083,119.083,0,0,0-37.922-37.921,27.835,27.835,0,0,1,52.076,13.687ZM230.979,27.841V37.954a46.571,46.571,0,0,0-32.027,23.318A117.113,117.113,0,0,0,180.867,55.3V18.56H221.7a9.29,9.29,0,0,1,9.28,9.28ZM153.027,51.982q-4.685,0-9.28.363V18.56h18.56V52.344Q157.713,51.986,153.027,51.982ZM84.963,18.56h40.223V55.3A117.157,117.157,0,0,0,107.1,61.272a46.578,46.578,0,0,0-31.417-23.2V27.841A9.29,9.29,0,0,1,84.963,18.56Zm-46.4,64.965A27.835,27.835,0,0,1,90.637,69.839,119.089,119.089,0,0,0,52.715,107.76,27.9,27.9,0,0,1,38.562,83.526ZM153.027,269.758a99.608,99.608,0,1,1,99.608-99.608q0,2.382-.118,4.769a71.34,71.34,0,0,0-12.256-1.06c-2.12,0-4.216.1-6.288.281.065-1.329.1-2.66.1-3.99a81.021,81.021,0,1,0-64.813,79.41,70.678,70.678,0,0,0,3.585,18.213,99.743,99.743,0,0,1-19.82,1.985Zm17.665-39.677a62.483,62.483,0,1,1,44.259-51.566A71.413,71.413,0,0,0,170.692,230.081ZM240.26,297.6a52.588,52.588,0,1,1,52.588-52.588A52.648,52.648,0,0,1,240.26,297.6Z"
                fill="var(--color-accent)"
              />
              <path
                id="Path_634"
                data-name="Path 634"
                d="M370.293,351.015a9.28,9.28,0,0,0-9.28,9.28v10.725a9.28,9.28,0,1,0,18.56,0V360.3A9.28,9.28,0,0,0,370.293,351.015Z"
                transform="translate(-130.034 -133.848)"
                fill="var(--color-yellow)"
              />
              <circle
                id="Ellipse_175"
                data-name="Ellipse 175"
                cx="9.28"
                cy="9.28"
                r="9.28"
                transform="translate(230.976 254.289)"
                fill="var(--color-yellow)"
              />
            </g>
          </svg>

          <div className="mt-2 text-lg font-bold sm:text-2xl text-skin-base">
            Still with us?
          </div>
          <div className="text-skin-muted">
            {" "}
            Your session is about to expire due to inactivity. You will be
            logged out when the countdown completes{" "}
            <span className="font-bold">{countdown}</span>{" "}
          </div>

          <div className="w-full mt-4">
            <button
              onClick={() => stayActive()}
              className="flex flex-row items-center justify-center w-full p-4 mt-4 text-white rounded-lg bg-skin-accent"
            >
              <span className="">Keep me signed in</span>
            </button>
          </div>
        </div>
      </HeadlessModal>

      <IdleTimer
        ref={idleTimerRef}
        timeout={1800000 * 2}
        onIdle={(e) => onIdle(e)}
        debounce={250}
      />
    </>
  );
};
