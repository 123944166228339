import { useMutation } from "react-query";
import { apiHttp } from "../../lib/appConfig";

async function requestDelegatedAccess({ emails }: { emails: string[] }) {
  const res = await apiHttp.post(`/scheduler/request-delegated-access`, {
    emails
  });
  return res.data;
}

export const useRequestDelegatedAccess = () => {
  return useMutation(requestDelegatedAccess);
};
