import { useEffect, useRef } from "react";
import * as echarts from "echarts";

const Chart = ({
  options,
  resizeObserver
}: {
  options: echarts.EChartsOption;
  resizeObserver: ResizeObserver;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const PrimaryColor = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue("--primary-color");

  useEffect(() => {
    echarts.init(ref.current as HTMLElement).setOption({
      color: `rgb(${PrimaryColor})`,
      useDirtyRect:true,
      ...options
    });
    if (resizeObserver) resizeObserver.observe(ref.current as HTMLElement);
  }, [PrimaryColor, options, resizeObserver]);
  return <div ref={ref} className="w-full h-full"></div>;
};

export default Chart;
