import { useCallback, useEffect, useState } from "react";
import { getProgramInvitations } from "../../services/dashboard/hrDashboard.services";
import { Failure } from "../ToastNotification/Failure";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Information } from "../ToastNotification/Information";
import { SmallInformation } from "../Information/SmallInformation";
import { FailureInformation } from "../Information/FailureInformation";
import { PROGRAM_APPLICATION_TYPES } from "../../services/utils/app.constant";
import { onStartManualMatch } from "../../StateManagement/ManualMatching";
import { Avatar, Stack, Typography } from "knack-ui";
import { queryClient } from "../../lib/appConfig";
import { programsQueryKeys } from "../../features/Programs/programQueries";
import { useManualExpertAssignment } from "../../features/Programs/programMutations";

export const ManualMatching = () => {
  const { data, setData, setOpen } = onStartManualMatch();
  const [mentors, setMentors] = useState([]);
  const [assigned, setAssigned] = useState();
  const [isError, setIsError] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  const { mutateAsync: assignExpert } = useManualExpertAssignment();

  useEffect(() => {
    setAssigned(null);
    if (data) {
      getProgramInvitations(
        data.program._id,
        data.program.program_type === "Mentoring" ? PROGRAM_APPLICATION_TYPES.MENTOR : PROGRAM_APPLICATION_TYPES.COACH
      )
        .then((res) => {
          if (res && res.data && res.data.status) {
            const { data } = res.data;
            const confirmed = data.filter((d) => d.confirmed === true);
            const mapped = confirmed.map((m) => ({
              ...m,
              value: `${m?.profile?.first_name} ${m?.profile?.last_name}`,
              label: `${m?.profile?.first_name} ${m?.profile?.last_name}`
            }));
            const filtered = mapped.filter(
              (m) => m?.utilization?.capacity > 0 && m?.utilization.capacity !== m?.utilization.totalUserConnections
            );
            setMentors(filtered);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [setMentors, data]);

  async function onSubmit(e) {
    if (e && e.mentor && e.mentor.profile.user._id && data && data.mentee && data.mentee.profile && data.mentee.profile?.user?._id) {
      try {
        await assignExpert({
          expertId: e.mentor.profile.user._id,
          userId: data.mentee.profile.user._id,
          programId: data.program._id
        });

        Information(
          `You have assigned ${e.mentor.profile?.first_name} ${e.mentor?.profile?.last_name} to ${
            data?.program?.program_type === "Mentoring" ? "mentor" : "coach"
          } ${data.mentee?.profile?.first_name} ${data.mentee?.profile?.last_name}`
        );
        setAssigned(e.mentor);
        setIsError(false);
        setData();
        setOpen();
        if (data.refresh) {
          data?.refresh();
        }
        queryClient.invalidateQueries(programsQueryKeys["all"]);
      } catch (error) {
        console.log(error);
        if (error.response?.data?.message.includes("Expert is already")) {
          Failure(
            `${e.mentor?.profile?.first_name} ${e.mentor?.profile?.last_name} is already assigned to ${data.mentee?.profile?.first_name} ${data.mentee?.profile?.last_name}. Please select a different expert.`
          );
        } else {
          Failure("Unable to assign expert. Please contact support");
        }
        setIsError(true);
        setData();
        setOpen();
      }
    }
  }

  const filterOptions = useCallback(({ label, value, data }, input) => {
    if (input) {
      const { profile, email } = data;
      const isMatch = `${profile?.first_name}${profile?.last_name}${email}`.toLowerCase().includes(input.toLowerCase());
      return isMatch;
    }
    return true; // if not search, then all match
  }, []);

  return (
    <>
      <div className="relative z-50 bg-white w-full max-w-full sm:max-w-screen-sm">
        {assigned?.profile?.first_name && (
          <SmallInformation
            message={`You have have assigned ${assigned?.first_name} ${assigned?.last_name} to mentor ${data?.mentee?.first_name} ${data?.mentee?.last_name}`}
          />
        )}

        {isError.mentor && <FailureInformation message={`Error Occurred`} />}

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Header */}
          <div className="border-b flex items-center justify-between px-4 py-2">
            <div className="font-bold text-lg text-skin-base">
              Manually assign a {data?.program?.program_type === "Mentoring" ? "mentor" : "coach"} to{" "}
              <span className="text-skin-accent">
                {data?.mentee?.profile?.first_name} {data?.mentee?.profile?.last_name}
              </span>
            </div>
          </div>
          {/* Content */}
          <div className="p-4 mt-6">
            <div className="">
              {/* Main Question */}
              <div className="">
                <p className="text-skin-muted text-sm pb-2">
                  Please select a {data?.program?.program_type === "Mentoring" ? "mentor" : "coach"} from the list below
                </p>
              </div>
              {/* Options */}
              <div className=" ">
                {
                  <Controller
                    name="mentor"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder={`${data?.program?.program_type === "Mentoring" ? "Select Mentor" : "Select Coach"}`}
                        className="rounded-none outline-none border-none text-sm inner-inner-box-shadow-none"
                        isClearable={false}
                        isSearchable={true}
                        sear
                        options={mentors}
                        getOptionLabel={(e) => (
                          <Stack className="flex gap-4 justify-between">
                            <Stack gap={4} alignItems="center">
                              <Avatar size="small" image={e?.profile?.profile_image || ""} text={e?.profile?.first_name || ""} />
                              <Typography variant="body1">
                                {e?.profile?.first_name} {e?.profile?.last_name}
                              </Typography>
                            </Stack>
                            <Typography variant="body1" fontWeight="medium">
                              {e?.utilization?.totalUserConnections} / {e?.utilization?.capacity}
                            </Typography>
                          </Stack>
                        )}
                        filterOption={filterOptions}
                      />
                    )}
                  />
                }
                {errors.mentor && (
                  <span className="text-yellow-700 font-medium text-xs">
                    *Select a {data?.program?.program_type === "Mentoring" ? "mentor" : "coach"}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-4 p-4">
            <button type="submit" className={`px-4 py-2 text-white rounded-lg ${errors.mentor ? "bg-skin-muted" : "bg-skin-accent"}`}>
              {" "}
              Assign {data?.program?.program_type === "Mentoring" ? "mentor" : "coach"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
