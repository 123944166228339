export default function Modal360FeedbackPrivacyText(props) {
  return (
    <div
      className="overflow-y-auto scrollhost removeScrollhost h-full w-full text-left"
      style={{ maxHeight: "75vh" }}
    >
      <p className="mt-10 pr">
        The goal of 360&deg; Feedback is to support individuals in prioritizing
        and planning their personal development. To support this, Knack Lab
        (Legally named Knack LTD) uses your data in a number of ways.
      </p>
      <div>&nbsp;</div>
      <p>
        <strong>
          <span style={{ color: "#3a3a3a" }}>
            This policy is to help you understand:
          </span>
        </strong>
        <br />
        <br />
        &bull; How we use your data
        <br />
        <br />
        &bull; Who we share your data with
        <br />
        <br />
        &bull; Your rights to your data
        <br />
        <br />
        &bull; How we delete data.
        <br />
        <br />
        <br />
        <strong>
          <span style={{ color: "#3a3a3a" }}>How we use your data</span>
        </strong>
        <br />
        <br />
        We use the feedback collected to:
        <br />
        <br />
        &bull; Create individual feedback reports that aggregate the feedback
        collected about an employee being assessed. With the exception of the
        self-assessment feedback, feedback will be anonymized by combining it
        with the feedback given by others. Text responses (if applicable) will
        be unaltered and so may be identifiable from the language used or
        feedback given.
        <br />
        <br />
        &bull; Review group- or company-wide results to review trends and
        accordingly make training-related and other business decisions. All
        feedback will be anonymized by combining responses. Text responses will
        be updated to remove any names used and may be updated to emphasize key
        points. Text responses may still be identifiable from the language used
        or feedback given.
        <br />
        &bull; Screen for and remove any undesirable or abusive feedback.
        <br />
        <br />
        <br />
        <strong>
          <span style={{ color: "#3a3a3a" }}>Who we share your data with</span>
        </strong>
        <br />
        <br />
        We may share:
        <br />
        <br />
        &bull; The individual feedback report with the person assessed
        <br />
        <br />
        &bull; The individual feedback report with the line manager or coach of
        the person assessed
        <br />
        <br />
        &bull; Aggregated and anonymized group or company-wide reports with the
        HR team, senior management, and others to help them review trends and
        identify opportunities to improve how they can support personal
        development at a group level
        <br />
        <br />
        &bull; Individual responses with administrators from Knack Lab (Legally
        named Knack LTD)&nbsp;
        <span style={{ color: "#111111" }}>
          <span style={{ fontSize: "small" }}>
            <span
              style={{ fontFamily: "Verdana, Arial, Helvetica, sans-serif" }}
            >
              &nbsp;
            </span>
          </span>
        </span>
        for the purposes of providing support, reviewing group- and company-wide
        results, and screening for abusive feedback. Only staff from Knack Lab
        (Legally named Knack LTD) has administrator access. You may request an
        up-to-date list of who has access by emailing team@knacklab.co
        <br />
        <br />
        <br />
        <strong>
          <span style={{ color: "#3a3a3a" }}>Your rights to your data</span>
        </strong>
        <br />
        <br />
        You may request access to or deletion of your data at any time by
        emailing team@knacklab.co
        <br />
        <br />
        <br />
        <strong>
          <span style={{ color: "#3a3a3a" }}>How we retain data</span>
        </strong>
        <br />
        <br />
        We will only keep your personal information and data for as long as it
        is necessary for the purposes set out in this agreement, unless a longer
        retention period is required or permitted by law.
        <br />
        <br />
        When we have no ongoing legitimate business need to process your
        personal data, we will either delete or anonymize it (so that it can no
        longer be associated with you) for research or statistical purposes in
        which case may use this information indefinitely without further notice
        to you. In some cases, such as request or permission by law, we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
    </div>
  );
}
