import { useEffect, useState } from "react";
import { InputSelect } from "../../../../components/Inputs/InputSelect";
import { OptionsMenu } from "../../../../components/Inputs/OptionsMenu";
import hrService from "../../../../services/dashboard/hrDashboard.services";
import { Link } from "react-router-dom";
import { Success } from "../../../../components/ToastNotification/Success";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { SquareImage } from "../../../../components/Image/SquareImage";
import { DataEmpty } from "../../../../components/Information/DataEmpty";
import { SearchInput } from "../../../../components/Inputs/SearchInput";
import { ParsedHTML } from "../../../../components/Parser/ParsedHTML";
import { ConfirmationModal } from "../../../../components/modal/ConfirmationModal";
import { useUser } from "../../../../features/User/userQueries";
import { Button } from "knack-ui";
import { ConfirmGlobalProgramArchive } from "../ProgramV3/Program/Components/ConfirmGlobalProgramArchive";

export const Programs = () => {
  const { data: user } = useUser();
  const [programs, setPrograms] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState("");
  const [filterByType, setFilterByType] = useState("");
  const [deleteProgramState, setDeleteProgramState] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [archive, setConfirmArchive] = useState({ program: null, open: false });

  useEffect(() => {
    getPrograms();
  }, [setPrograms, setFilteredPrograms]);

  function getPrograms() {
    hrService
      .getPrograms()
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          let sorted = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          const filtered = sorted.filter((s) => !s.deleted);
          setPrograms(filtered);
          setFilteredPrograms(filtered);
          setIsLoaded(true);
        }
      })
      .catch((e) => {
        console.log(e);
        setIsLoaded(true);
      });
  }

  function handleFilter(filterby, type) {
    let unfilteredPrograms = [...programs];
    if (type === "bystatus") {
      setFilterByStatus(filterby);
    }

    if (type === "bytype") {
      setFilterByType(filterby);
    }
    if (type === "bystatus") {
      if (filterby !== "") {
        unfilteredPrograms = unfilteredPrograms.filter((p) => p.status === filterby);
      }
      if (filterByType !== "") {
        unfilteredPrograms = unfilteredPrograms.filter((p) =>
          filterByType === "One-on-One"
            ? p.type === "1:1" || p.program_type === "Mentoring" || p.program_type === "Coaching"
            : p.plan === "group"
        );
      }
    }

    if (type == "bytype") {
      if (filterby != "") {
        unfilteredPrograms = unfilteredPrograms.filter((p) =>
          filterby === "One-on-One"
            ? p.type === "1:1" || p.program_type === "Mentoring" || p.program_type === "Coaching"
            : p.plan === "group"
        );
      }
      if (filterByStatus != "") {
        unfilteredPrograms = unfilteredPrograms.filter((p) => p.status === filterByStatus);
      }
    }
    setFilteredPrograms(unfilteredPrograms);
  }

  function deleteProgram(Program) {
    setConfirmDelete(false);
    if (Program.status === "Published") {
      Failure("You cannot delete a Live program");
      return;
    }
    if (Program.status === "Pending") {
      hrService
        .deleteProgram(Program._id)
        .then(() => {
          Success(Program.name ? "You deleted " + Program.name : "Program deleted successfully.");
          getPrograms();
        })
        .catch((e) => {
          Failure("Oh no! Something went wrong. Please contact support");
          console.log(e);
        });
    }
  }

  function onSearchProgram(value) {
    if (value !== "") {
      const filtered = programs.filter((p) => p.name?.includes(value) || p.overview?.includes(value));
      setFilteredPrograms(filtered);
    } else {
      setFilteredPrograms(programs);
    }
  }

  function handleProgramDelete(p) {
    setDeleteProgramState(p);
    setConfirmDelete(true);
  }

  return (
    <>
      <ConfirmGlobalProgramArchive
        onSuccess={() => getPrograms()}
        open={archive.open}
        program={archive.program}
        setOpen={(value) => setConfirmArchive({ open: value })}
      />

      <ConfirmationModal
        title={"Delete Program"}
        header={`Are you sure you want to Delete ${deleteProgramState?.name}?`}
        showDefaultButton={true}
        buttonFunction={() => deleteProgram(deleteProgramState)}
        open={confirmDelete}
        setOpen={setConfirmDelete}
        defaultButtonText={"Delete"}
        width="250"
      />

      <>
        <>
          <div className="flex flex-row items-center justify-between mb-4 sm:px-4">
            {/* Header */}
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex-row items-center hidden w-full sm:flex ">
                {/* Filter By Status */}
                <div className="mr-4">
                  <InputSelect
                    name={"status"}
                    selected={filterByStatus}
                    options={["Published", "Pending"]}
                    onChange={(e) => handleFilter(e, "bystatus")}
                  />
                </div>
                {/* Filter By Type */}
                <div className="mr-4">
                  <InputSelect
                    name={"type"}
                    w={"40"}
                    selected={filterByType}
                    options={["One-on-One", "Group Coaching"]}
                    onChange={(e) => handleFilter(e, "bytype")}
                  />
                </div>
                <div className="w-full max-w-md">
                  <SearchInput onChange={onSearchProgram} placeholder="Search by name, description" />
                </div>
              </div>

              <div className="ml-4">
                <Button
                  to={{
                    pathname: "/create-program",
                    state: { title: "Create Program" }
                  }}
                  as={Link}
                >
                  Create Program
                </Button>
              </div>
            </div>
          </div>
        </>
        {/* Program list by grid card */}
        <div className="grid gap-4 mb-32 sm:p-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {/* isLoading Preview starts here*/}
          {!isLoaded &&
            [1, 2, 3, 4, 4, 4, 4].map((_, i) => (
              <div key={i} className="relative flex overflow-hidden bg-white rounded-lg shadow animate-pulse">
                <div className="w-full p-4 animate-pulse">
                  <h1 className="w-1/2 text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse h-7"></h1>
                  <p className="h-3 p-1 px-2 my-2 text-sm font-bold round-full animate-pulse text-skin-accent "> </p>
                  <h1 className="text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse "></h1>
                  <h1 className="w-2/3 h-3 mb-3 text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse"></h1>
                  <h1 className="w-1/2 h-3 mb-3 text-xl font-semibold text-gray-800 bg-gray-300 dark:text-white animate-pulse"></h1>
                </div>
              </div>
            ))}
          {/* isLoading Preview ends here*/}
          {isLoaded &&
            filteredPrograms.map((p, i) => (
              <div key={i} className={`flex bg-white rounded-lg overflow-hidden shadow bg-white relative`}>
                <OptionsMenu
                  options={[
                    {
                      Label: "Edit Program",
                      Icon: "Edit",
                      Link: `edit-program/${p?._id}`
                    },
                    ...(p?.status !== "Published"
                      ? [
                          {
                            Label: "Delete",
                            Icon: "Delete",
                            onHandler: () => {
                              if (p?.status === "Pending") {
                                handleProgramDelete(p);
                              } else {
                                Failure("You cannot delete a Published program");
                              }
                            }
                          }
                        ]
                      : [
                          {
                            Label: "Archive Program",
                            Icon: "Archive",
                            onHandler: () => setConfirmArchive({ open: true, program: p })
                          }
                        ])
                  ]}
                  id={p?._id}
                >
                  <button
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      display: "block"
                    }}
                    className="py-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#CCCCCC" width="25px">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                      />
                    </svg>
                  </button>
                </OptionsMenu>

                <Link
                  to={{
                    pathname: p?.status === "Published" ? `/programs/${p._id}` : `/edit-program/${p._id}`,
                    key: "programs"
                  }}
                  className="w-full"
                >
                  <div className="w-full h-full cursor-pointer hover:bg-skin-light">
                    {/* Image */}
                    <div>
                      <SquareImage image_url={p?.image_url} index={i} />
                    </div>
                    {/* Program type */}
                    <div className="flex flex-row px-2 my-4">
                      <p
                        className="px-2 py-1 text-xs rounded-full font-medium text-white bg-skin-accent clamp-text-1"
                        style={{ width: "fit-content" }}
                      >
                        {p?.tag || p?.program_type}
                      </p>
                      <p
                        className={`px-2 py-1 text-xs ml-2 rounded-full text-white font-medium ${
                          p?.status === "Pending" ? "bg-skin-muted" : "bg-skin-green"
                        }`}
                        style={{ width: "fit-content" }}
                      >
                        {" "}
                        {p?.status}{" "}
                      </p>
                    </div>
                    {/* Program Name */}
                    <div className="px-2 mb-4">
                      <h1 className="mb-3 text-base font-bold text-gray-800 dark:text-white text-skin-base clampText"> {p?.name}</h1>
                      <div className="mt-2 text-sm text-gray-600 dark:text-gray-300 font-regular restrict-grid-text text-skin-base clampText">
                        {" "}
                        <ParsedHTML htmlString={p?.overview} />{" "}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </>

      {programs.length === 0 && isLoaded && user.account_type === "Hr" && (
        <DataEmpty Text="Start creating your first program" CTA_Text="Create program" route="/create-program" />
      )}
    </>
  );
};
