import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CollapsibleWrapperWithLineBar } from "../../../components/Collapsible/CollapsibleWrapperWithLineBar";
import { BackButton } from "../../../components/nav/BackButton";
import { KnackUserCardProfile } from "../../../components/Profile/KnackUserCardProfile";
import { Assessment } from "../../../components/Profile/ProfileTabPages/Assessment";
import { MentoringSessionTimeline } from "../../../components/Timeline/MentoringSessionTimeline";
import { getSessionsByQuery } from "../../../services/mentoring/mentoring.service";
import Feedback360 from "../../dashboard/userDashboard/userReports/feedback360/Feedback360";
import { ListOfPrograms } from "../hrDashboard/Programs/Components/ListOfPrograms";
import { MenteeTaskList } from "./MenteeTaskList";
import { AddTaskToMentee } from "./AddTaskToMentee";
import mentoringService from "../../../services/mentoring/mentoring.service";
import { HRCoachServices } from "../../../services/dashboard/hrCoachDashboard.services";
import { getProfile } from "../../../services/user.service";
import { UserStore } from "../../../RoleManagement/Discriminator";

export const MenteeProfile = () => {
  const { currentRole } = UserStore();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")).data
  );
  const location = useLocation();
  const history = useHistory();
  const [mentee, setMentee] = useState();
  const [color, setColor] = useState();
  const [mentoringTopics, setMentoringTopics] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [sessions, setSessions] = useState([]);
  const [menteeTasks, setMenteeTasks] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [profileOnly, setProfileOnly] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(null);

  useEffect(() => {
    if (!location.state) {
      history.push("/");
      return;
    } else {
      setMentee(location.state?.mentee);
      setColor(location.state?.color);
      setProfileOnly(location.state?.profileOnly);
      const program_skills = location.state?.mentee?.program?.skills;
      if (program_skills) {
        const _mentee_skills = location.state?.mentee?.program_skills;
        const selectedSkills = _mentee_skills?.filter((s) =>
          program_skills?.includes(s)
        );
        setMentoringTopics(selectedSkills);
      }
      setIsLoaded(true);
      // console.log(location.state)
      getProfile(location.state.userId)
        .then((res) => {
          const { data } = res.data;
          setFetchedUser(data);
        })
        .catch((e) => {
          console.log(e);
        });

      if (!["Hr"].includes(user.account_type)) {
        getMenteeTasks(location?.state?.mentee?.user);
      }

      if (location?.state?.mentee?._id) {
        onGetSessions(location.state.mentee);
      }
    }
  }, [setMentee, setSessions, setMenteeTasks]);

  const onGetSessions = (mentee) => {
    if (mentee) {
      if (user.account_type !== "Hr") {
        getSessionsByQuery(`user=${mentee.mentee}`)
          .then((response) => {
            if (response.status) {
              let data = response.data.data;
              if (data && data.length > 0) {
                const sorted = data?.sort(
                  (a, b) =>
                    new Date(b.start_time).getTime() -
                    new Date(a.start_time).getTime()
                );
                setSessions(sorted);
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        HRCoachServices.getSessions(
          `mentoring/recent?type=mentee&no_paginate=true&completion_status=Completed&user=${mentee.mentee}`
        )
          .then((res) => {
            if (res.data.status) {
              const { data } = res.data;
              if (data) {
                const sorted = [...data.allSessions].sort(
                  (a, b) => new Date(b.start_time) - new Date(a.start_time)
                );
                setSessions(sorted);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  function getMenteeTasks(mentee_id) {
    mentoringService
      .getMenteeTasks(mentee_id)
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          // const filtered = [...data].filter(s => s.session._id === session._id && s.type !== "rating");
          setMenteeTasks(data);
        }
      })
      .catch((e) => console.log(e));
  }

  return (
    <div>
      <BackButton />

      <div className="mt-4 max-w-screen-lg mx-auto bg-white">
        {mentee && (
          <KnackUserCardProfile
            menteeId={mentee.user}
            matchDegree={mentee?.match_degree}
            canHover={false}
            profile_image={fetchedUser?.user?.profile_image}
            color={color}
            first_name={mentee?.first_name}
            last_name={mentee.last_name}
            position={mentee?.position}
            job_function={mentee?.job_function}
            email={fetchedUser?.user?.email}
            component={
              <div className="mt-4 grid gap-4">
                {/* Program */}
                {!mentee?.enrolledPrograms && (
                  <div className="border-b pb-4 grid gap-4">
                    <div>
                      <div className="font-medium text-sm text-skin-muted">
                        Program
                      </div>
                      <div className="font-bold text-base">
                        {mentee?.program?.name}
                      </div>
                    </div>

                    <div>
                      <div className="font-medium text-sm text-skin-muted">
                        Mentoring Topics
                      </div>
                      <div className="text-sm tracking-wider gap-2 flex flex-row flex-wrap mt-2">
                        {mentoringTopics?.map((s, i) => (
                          <div
                            key={i}
                            className="bg-skin-muted rounded-full px-2 py-1 text-white"
                            style={{ maxWidth: "fit-content" }}
                          >
                            {" "}
                            {s}{" "}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <div className="grid sm:grid-cols-2 gap-4">
                  {mentee && mentee?.job_function && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Department
                      </div>
                      <div className="font-bold text-base">
                        {mentee?.job_function}
                      </div>
                    </div>
                  )}
                  {mentee && mentee?.position && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Position
                      </div>
                      <div className="font-bold text-base">
                        {mentee?.position}
                      </div>
                    </div>
                  )}
                  {mentee && mentee?.management_level && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Management Level
                      </div>
                      <div className="font-bold text-base">
                        {mentee?.management_level}
                      </div>
                    </div>
                  )}

                  {mentee && mentee?.entity && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Entity
                      </div>
                      <div className="font-bold text-base">
                        {mentee?.entity}
                      </div>
                    </div>
                  )}

                  {mentee && mentee?.whatsapp_number && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Phone No.
                      </div>
                      <div className="font-bold text-base">
                        {mentee?.whatsapp_number}
                      </div>
                    </div>
                  )}

                  {fetchedUser && fetchedUser.user && fetchedUser?.user?.email && (
                    <div className="">
                      <div className="font-medium text-sm text-skin-muted">
                        Email
                      </div>
                      <div className="font-bold text-base">
                        {fetchedUser?.user?.email}
                      </div>
                    </div>
                  )}
                </div>

                {mentee?.enrolledPrograms && (
                  <CollapsibleWrapperWithLineBar
                    ItemsCounts={mentee?.enrolledPrograms?.length}
                    isOpen={false}
                    SectionName="Programs"
                    Icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                      </svg>
                    }
                  >
                    <div>
                      {mentee?.enrolledPrograms &&
                        mentee?.enrolledPrograms?.length > 0 && (
                          <ListOfPrograms
                            programs={mentee?.enrolledPrograms}
                            isLoaded={isLoaded}
                            route="view-program"
                          />
                        )}
                    </div>
                  </CollapsibleWrapperWithLineBar>
                )}

                {!profileOnly && (
                  <>
                    {/* Tabs */}
                    <div className="flex flex-row w-full text-sm justify-between sm:justify-start border-b border-gray-200 overflow-x-auto">
                      <div
                        onClick={() => setTab(1)}
                        className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                          tab == 1 &&
                          "border-b-2 border-skin-base text-skin-accent"
                        } `}
                      >
                        Sessions
                      </div>
                      {mentee &&
                        mentee?.assessment_report_visible_to?.includes(
                          user._id
                        ) && (
                          <div
                            onClick={() => setTab(2)}
                            className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                              tab == 2 &&
                              "border-b-2 border-skin-base text-skin-accent"
                            } `}
                          >
                            Assessment
                          </div>
                        )}
                      {mentee &&
                        mentee?.assessment_report_visible_to?.includes(
                          user._id
                        ) && (
                          <div
                            onClick={() => setTab(3)}
                            className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                              tab == 3 &&
                              "border-b-2 border-skin-base text-skin-accent"
                            } `}
                          >
                            360 Feedback
                          </div>
                        )}
                      {mentee && currentRole == "Expert" && (
                        <div
                          onClick={() => setTab(4)}
                          className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
                            tab == 4 &&
                            "border-b-2 border-skin-base text-skin-accent"
                          } `}
                        >
                          Tasks
                        </div>
                      )}
                    </div>

                    {/* Tab Content */}
                    {/* Completed Sessions */}
                    {tab === 1 && (
                      <div
                        className="mt-4 grid gap-4 overflow-y-auto scrollhost2"
                        style={{ maxHeight: 700 }}
                      >
                        <div className="">
                          <MentoringSessionTimeline sessionList={sessions} />
                        </div>
                      </div>
                    )}

                    {tab === 2 && (
                      <Assessment userId={mentee.user} user={mentee} />
                    )}
                    {tab === 3 && (
                      <Feedback360
                        userId={mentee.user}
                        user={mentee}
                        canRequest={false}
                      />
                    )}
                    {tab === 4 && (
                      <MenteeTaskList
                        mentee_id={mentee.user}
                        taskList={menteeTasks}
                        setOpenDrawer={setOpenDrawer}
                      />
                    )}
                  </>
                )}
              </div>
            }
          />
        )}
      </div>
      {openDrawer && (
        <AddTaskToMentee
          mentee={mentee}
          setOpenCloseDrawer={setOpenDrawer}
          refereshCallback={getMenteeTasks}
        />
      )}
    </div>
  );
};
