import { useState } from "react";

interface IProps {
  max: number;
  totalList: number;
}

const ShowMoreList = ({ max, totalList }: IProps) => {
  const [slice, setSlice] = useState(max);

  return {
    slice,
    onChangeSlice: () => {
      if (slice === max) {
        setSlice(totalList);
      } else {
        setSlice(max);
      }
    },
    label: max === slice ? "show more" : "show less"
  };
};

export default ShowMoreList;
