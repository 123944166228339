import { Box, Button, Stack, Typography } from "knack-ui";
import { useHistory } from "react-router-dom";
import { ErrorComponentProps } from "../../lib/HOCs/withErrorBoundary";

const NotFoundView = ({
  resetErrorBoundary,

  props
}: ErrorComponentProps) => {
  const history = useHistory();
  return (
    <Box
      as={Stack}
      gap={8}
      direction="column"
      // alignItems="center"
      justifyContent="center"
      variant="outlined"
      paddingPreset="card"
      {...props}
    >
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Oops, Content could not be found 😣
      </Typography>
      <Stack gap={4}>
        <Button kind="tertiary" fullWidth onClick={resetErrorBoundary}>
          Try again
        </Button>
        <Button kind="ghost" fullWidth onClick={() => history.goBack()}>
          Go back
        </Button>
      </Stack>
    </Box>
  );
};

export default NotFoundView;
