import { Typography } from "knack-ui";
import styled from "styled-components";
import youngman from "../../components/Asset/illustrations/youngman.svg";
import youngwoman from "../../components/Asset/illustrations/youngwoman.svg";
import { useUser } from "../../features/User/userQueries";
const DashboardWelcome = () => {
  const { data: profile } = useUser({
    select: (user) => user.profile,
    suspense: false
  });
  return (
    <Wrapper
      dir="ltr"
      // style={{ minHeight: 120, height: 120 }}
      // className="relative flex items-center w-full px-6 py-6 bg-primary rounded-xl text-primary"
    >
      <div className="flex flex-col justify-center h-full">
        <Typography as="h5" variant="h5" className="mb-1">
          Hala {profile?.first_name}! 👋
        </Typography>
        <Typography>Welcome to your space of personal transformation</Typography>
      </div>
      <div className="absolute -bottom-1 end-10">
        <img src={profile?.gender === "Male" ? youngman : youngwoman} alt="young man illustration" className="max-h-36 lg:max-h-36" />
      </div>
      <div className="absolute w-56 h-56 bg-white rounded-full -top-24 -start-32 bg-opacity-10" />
    </Wrapper>
  );
};

export default DashboardWelcome;

const Wrapper = styled.div`
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  background: var(--color-primary);
  color: var(--color-on-primary);
  border-radius: 12px;
`;
