import { Stack } from "knack-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardPaper } from "../../../../../components/Cards/CardPaper";
import { ReportRangeFilter } from "../../../../../components/Filter/ReportRangeFilter";
import LanguageToggler from "../../../../../components/LanguageToggler/LanguageToggler";
import { SelfAssessmentResultBreakdownByState } from "../../../../../components/Profile/ProfileTabPagesComponents/SelfAssessmentResultBreakdownByState";
import { useUser } from "../../../../../features/User/userQueries";
import userDashboardServices from "../../../../../services/dashboard/userDashboard.services";
import StrengthOrWeakness from "./strengthOrWeakness";

export const HrFilterableReport = () => {
  const { t } = useTranslation(["assessments"]);
  const companyColorAccent = `${window
    .getComputedStyle(document.documentElement)
    .getPropertyValue("--color-accent")}`.trim();
  const colorGreen = `${window
    .getComputedStyle(document.documentElement)
    .getPropertyValue("--color-green")}`.trim();
  const [stateBreakdown, setStateBreakdown] = useState([]);
  const [chartData, setChartData] = useState({
    series: null,
    categories: null,
    colors: null
  });
  const [topStrengths, setTopStrengths] = useState([]);
  const [topChallenges, setTopChallenges] = useState([]);
  const { data: user } = useUser();

  const [filters, setFilters] = useState([]);
  useEffect(() => {
    getStateBreakdown();
    getTopStrengths();
    getTopChallenges();
  }, [setStateBreakdown]);

  const IndexNamings = {
    0: "Before",
    1: "After"
  };

  const IndexColors = {
    0: companyColorAccent,
    1: colorGreen
  };

  const getStateBreakdown = () => {
    userDashboardServices
      .getStateBreakdown()
      .then((res) => {
        if (res.data.status && res.data.data.length) {
          let { data } = res.data;
          if (data.length > 0) {
            const categories = data.map((r) =>
              r.Nest.level_two_report.buckets.map((b) => t(b.key))
            )[0];
            const buckets = data.map((r) => r.Nest.level_two_report.buckets);
            const mappedBuckets = buckets.map((d, i) => ({
              name: IndexNamings[i],
              data: d.map((r) => r.avg_score_before.value.toFixed(1))
            }));
            const series = mappedBuckets.slice(0, 2);
            const colors = new Array(series.length)
              .fill(0)
              .map((c, i) => IndexColors[i]);
            setChartData({ categories, series, colors });
            setStateBreakdown(mappedBuckets);
            let monthAfter = 3;
            let mappedFilters = [];
            mappedBuckets.map((m, i) =>
              mappedFilters.push({
                month:
                  i === 0
                    ? 0
                    : i === 1
                    ? 3
                    : mappedFilters[i - 1].month + monthAfter,
                data: mappedBuckets[i].data
              })
            );
            // console.log(mappedFilters);
            setFilters(mappedFilters);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  function onFilterChanged(values) {
    // console.log(values)
    const _chartData = { ...chartData };
    _chartData.series = [];
    if (values[0]) {
      _chartData.series.push({ name: values[0].label, data: values[0].data });
    }
    if (values[1]) {
      _chartData.series.push({ name: values[1].label, data: values[1].data });
    }
    if (_chartData.series.length > 0) {
      // console.log(_chartData)
      setChartData(_chartData);
    }
  }

  const getTopStrengths = () => {
    userDashboardServices
      .getTopStrengths()
      .then((res) => {
        let { data } = res.data;
        setTopStrengths(data.map((i) => ({ ...i, key: t(i.key) })));
      })
      .catch((e) => console.log(e));
  };

  const getTopChallenges = () => {
    userDashboardServices
      .getTopChallenges()
      .then((res) => {
        let { data } = res.data;
        setTopChallenges(data.map((i) => ({ ...i, key: t(i.key) })));
      })
      .catch((e) => console.log(e));
  };
  return (
    <div className="grid gap-4">
      <Stack justifyContent="space-between">
        {/* <ReportRangeFilter
          filters={filters}
          onChange={(values) => onFilterChanged(values)}
        /> */}
        <LanguageToggler reload />
      </Stack>
      <div className="grid gap-4 xl:grid-cols-2">
        <SelfAssessmentResultBreakdownByState
          title="Assessment Results Summary"
          categories={chartData.categories}
          series={chartData.series}
          colors={chartData.colors}
        />
        <div className="grid gap-4">
          <CardPaper
            height="auto"
            title={`${user?.organization?.name}'s Top Strengths`}
            subTitle="Highest scores based on self-assessment"
          >
            <div>
              <StrengthOrWeakness stateData={topStrengths} isHr={true} />
            </div>
          </CardPaper>

          <CardPaper
            height="auto"
            title={`${user?.organization?.name}'s Development Areas`}
            subTitle="Lowest scores based on self-assessment"
          >
            <div>
              <StrengthOrWeakness stateData={topChallenges} isHr={true} />
            </div>
          </CardPaper>
        </div>
      </div>
    </div>
  );
};
