import { Typography, Stack, Box, Button, Avatar } from "knack-ui";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { ISurveyInvitation } from "../surveyTypes";

interface IProps {
  surveyInvitations?: ISurveyInvitation[];
}

const SurveyInvitationsDrawer = ({ surveyInvitations }: IProps) => {
  const { height: pageHeight, width: pageWidth } = useWindowSize();
  const history = useHistory();
  return (
    <div style={{ minWidth: `calc(${pageWidth}px / 3.3 )`, maxWidth: 400 }}>
      <div className="p-6">
        <Typography variant="h6" as="h6">
          Survey invitations
        </Typography>
      </div>
      <div
        style={{ height: `calc(${pageHeight}px - 97px)` }}
        className="overflow-y-auto"
      >
        <Stack className="px-6 py-4" gap={4} direction="column">
          {surveyInvitations?.map((i) => (
            <Box variant="outlined" className="p-4">
              <Typography className="mb-4" variant="h6">
                {i.survey.title}
              </Typography>
              {i.survey.type === "user_eval" && i.recipientProfile && (
                <Box elevation={0} as={Stack} alignItems="center" gap={3}>
                  <Avatar
                    square
                    image={i.recipientProfile.profile_image}
                    text={i.recipientProfile.first_name}
                  />
                  <div>
                    <Typography color="muted" variant="body1">
                      This survey is about your client
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      variant="body1"
                    >{`${i.recipientProfile?.first_name} ${i.recipientProfile?.last_name}`}</Typography>
                  </div>
                </Box>
              )}
              {i.survey.type === "expert_eval" && i.recipientProfile && (
                <Box elevation={0} as={Stack} alignItems="center" gap={3}>
                  <Avatar
                    square
                    image={i.recipientProfile.profile_image}
                    text={i.recipientProfile.first_name}
                  />
                  <div>
                    <Typography color="muted" variant="body1">
                      This survey is about your expert
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      variant="body1"
                    >{`${i.recipientProfile?.first_name} ${i.recipientProfile?.last_name}`}</Typography>
                  </div>
                </Box>
              )}
              <Button
                className="mt-4"
                onClick={() => {
                  history.push(`/fill-survey/${i._id}`);
                }}
                variant="small"
                kind="primary"
              >
                Take the survey
              </Button>
            </Box>
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default SurveyInvitationsDrawer;
