import React from "react";

/**
 * pass true or false to toggle on or off
 * @param  state
 * @returns
 */
export const CustomToggle = ({ state = false, onChange, classes }) => {
  return (
    <div
      onClick={() => onChange(!state)}
      className={`${classes} w-16 h-8 bg-gray-200 rounded-full shadow-inner flex items-center ${
        state ? "justify-end" : "justify-start"
      } cursor-pointer`}
    >
      <div
        className={`block w-1/2 h-full rounded-full ${
          state ? "bg-skin-fill" : "bg-gray-400"
        }`}
      ></div>
    </div>
  );
};
