import { IUserProfile } from "./../User/UserTypes";
import {
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { DURATIONS } from "../../lib/constants";
import {
  IExpertTypes,
  IKnackCoach,
  PeopleTypes,
  PeopleUser
} from "./PeopleTypes";

const peopleKeys = {
  // We are making it an object to make it easy destructure from the query context **
  all: [{ scope: "people" }] as const,
  user: (type: PeopleTypes) => [{ ...peopleKeys.all[0], type }] as const,
  adminCoaches: () =>
    [{ ...peopleKeys.all[0], entity: "admin coaches" }] as const,
  experts: (search: string, expertType?: IExpertTypes) =>
    [{ ...peopleKeys.all[0], entity: "experts", search, expertType }] as const,
  clients: (search: string) =>
    [{ ...peopleKeys.all[0], entity: "clients", search }] as const
};

// 😱 Fetch Functions...
export interface IUsersPaginationResponse {
  page: number;
  profiles: IUserProfile[];
  totalPages: number;
  totalRecords: number;
  totalRecordsPerPage: number;
}

async function fetchExperts({
  queryKey: [{ search, expertType }],
  pageParam = 1
}: QueryFunctionContext<ReturnType<typeof peopleKeys["experts"]>>) {
  const res = await apiHttp.get<ServerResponse<IUsersPaginationResponse>>(
    "v1/auth/experts",
    { params: { search, page: pageParam, expertType } }
  );
  return res.data.data;
}

async function fetchClients({
  queryKey: [{ search }],
  pageParam = 1
}: QueryFunctionContext<ReturnType<typeof peopleKeys["clients"]>>) {
  const res = await apiHttp.get<ServerResponse<IUsersPaginationResponse>>(
    "v1/auth/clients",
    { params: { search, page: pageParam } }
  );
  return res.data.data;
}

async function fetchProgramInvitations({
  queryKey: [{ type }]
}: QueryFunctionContext<ReturnType<typeof peopleKeys["user"]>>) {
  const res = await apiHttp.get<ServerResponse<PeopleUser[]>>(
    "v1/auth/users/types",
    {
      params: {
        type
      }
    }
  );
  return res.data.data;
}
async function fetchKnackCoaches() {
  const res = await apiHttp.get<ServerResponse<IKnackCoach[]>>(
    "v1/auth/coaches"
  );
  return res.data.data;
}

interface IUseGetPeople {
  type: PeopleTypes;
}

export const useGetPeople = <
  SelectReturnType = PeopleUser[],
  ErrorType = unknown
>(
  { type }: IUseGetPeople,
  options?: UseQueryOptions<
    PeopleUser[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["user"]>
  >
) => {
  return useQuery<
    PeopleUser[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["user"]>
  >(peopleKeys.user(type), fetchProgramInvitations, {
    ...options,
    staleTime: DURATIONS.fifteenMins
  });
};
export const useGetKnackCoaches = <
  SelectReturnType = IKnackCoach[],
  ErrorType = unknown
>(
  options?: UseQueryOptions<
    IKnackCoach[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["adminCoaches"]>
  >
) => {
  return useQuery<
    IKnackCoach[],
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["adminCoaches"]>
  >(peopleKeys.adminCoaches(), fetchKnackCoaches, {
    ...options,
    staleTime: DURATIONS.fifteenMins
  });
};

export const useGetExperts = <
  SelectReturnType = IUsersPaginationResponse,
  ErrorType = unknown
>(
  { search, expertType }: { search: string; expertType?: IExpertTypes },
  options?: UseInfiniteQueryOptions<
    IUsersPaginationResponse,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["experts"]>
  >
) => {
  return useInfiniteQuery<
    IUsersPaginationResponse,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["experts"]>
  >(peopleKeys.experts(search, expertType), (query) => fetchExperts(query), {
    getNextPageParam: (lastPage) => {
      if (+lastPage.page < lastPage.totalPages) {
        return +lastPage.page + 1;
      }
      return undefined;
    }
  });
};

export const useGetClients = <
  SelectReturnType = IUsersPaginationResponse,
  ErrorType = unknown
>(
  { search }: { search: string },
  options?: UseInfiniteQueryOptions<
    IUsersPaginationResponse,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["clients"]>
  >
) => {
  return useInfiniteQuery<
    IUsersPaginationResponse,
    ErrorType,
    SelectReturnType,
    ReturnType<typeof peopleKeys["clients"]>
  >(peopleKeys.clients(search), (query) => fetchClients(query), {
    getNextPageParam: (lastPage) => {
      if (+lastPage.page < lastPage.totalPages) {
        return +lastPage.page + 1;
      }
      return undefined;
    }
  });
};
