import { useUserCompany } from "../../features/User/userQueries";

export const Logo = () => {
  const { data: company } = useUserCompany({
    useErrorBoundary: false
  });
  return (
    <>
      {(!company || company.dark_color === "" || !company.dark_color) && (
        <>
          {/*  Logo_Only */}
          <div className="md:hidden">
            <svg
              width="35"
              height="32"
              
              viewBox="0 0 35 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.75"
                d="M20.1721 31.6347H20.1651C19.2352 31.7685 18.1926 31.846 17.0865 31.846C15.0576 31.846 13.226 31.5924 11.9298 31.1838C5.55429 29.5705 -2.06813 24.2024 0.517291 7.27391C0.784991 5.51272 2.49687 4.37851 4.18056 4.84347C5.89243 5.31547 8.11857 6.2031 10.3447 7.86567C7.73111 13.2619 6.75894 20.504 13.2331 27.9643C14.3391 27.753 15.6635 27.6262 17.0865 27.6262C18.6434 27.6262 20.0876 27.7741 21.257 28.0277C27.8157 20.5251 26.8153 13.2338 24.1806 7.81635C26.3926 6.16788 28.5977 5.28729 30.3025 4.81529C31.9862 4.34329 33.6981 5.48454 33.9658 7.24573C36.8823 26.3581 26.7872 30.7541 20.1721 31.6347Z"
                fill="#1F40E6"
              />
              <path
                d="M21.1344 29.7453C20.7399 30.175 20.3172 30.6118 19.8734 31.0415C19.2253 31.6897 18.239 31.8446 17.2739 31.8446C16.3087 31.8446 15.3225 31.6897 14.6814 31.0415C14.2305 30.6048 13.8079 30.168 13.4133 29.7383C9.22877 25.1592 7.93957 20.6576 8.17205 16.5998C7.9748 13.3804 8.92584 10.33 10.2291 7.77978C12.8427 9.62551 15.4211 12.542 17.2386 16.9027C19.0703 12.5139 21.6628 9.57619 24.2975 7.73047C25.6149 10.2877 26.5729 13.3592 26.3827 16.5928C26.6082 20.6576 25.319 25.1592 21.1344 29.7453Z"
                fill="#1F40E6"
              />
              <path
                opacity="0.75"
                d="M24.3373 7.81615C21.7096 9.73936 19.0748 12.6911 17.2432 17.2491C15.4257 12.7263 12.8121 9.78163 10.1914 7.86546C11.6497 4.91371 13.6081 2.5185 15.0946 0.940474C16.2781 -0.313491 18.2717 -0.313491 19.4553 0.940474C20.9347 2.51145 22.8861 4.89258 24.3373 7.81615Z"
                fill="#1F40E6"
              />
            </svg>
          </div>
          {/*  Logo_With_Name */}
          <div className="hidden md:block">
            <img src="https://knacklab.co/wp-content/uploads/2022/03/Logo.svg" alt="Knack logo" className="object-contain w-auto max-h-full"/>
            {/* <svg
              width="100%"
              height="100%"
              viewBox="0 0 216 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.75"
                d="M51.0366 55.9909H51.0293C50.0595 56.1304 48.9722 56.2113 47.8188 56.2113C45.703 56.2113 43.7928 55.9468 42.4411 55.5207C35.7923 53.8383 27.8432 48.2401 30.5395 30.5861C30.8186 28.7494 32.6039 27.5666 34.3597 28.0515C36.145 28.5437 38.4665 29.4694 40.7881 31.2032C38.0624 36.8307 37.0486 44.3831 43.8002 52.1632C44.9536 51.9428 46.3348 51.8106 47.8188 51.8106C49.4424 51.8106 50.9485 51.9649 52.168 52.2294C59.0078 44.4052 57.9645 36.8014 55.2169 31.1518C57.5237 29.4326 59.8233 28.5143 61.6011 28.0221C63.357 27.5299 65.1422 28.72 65.4214 30.5567C68.4629 50.4882 57.9352 55.0725 51.0366 55.9909Z"
                fill="#1F40E6"
              />
              <path
                d="M52.0397 54.0199C51.6283 54.468 51.1875 54.9235 50.7246 55.3717C50.0487 56.0476 49.0202 56.2092 48.0137 56.2092C47.0072 56.2092 45.9787 56.0476 45.3101 55.3717C44.8399 54.9162 44.3991 54.4607 43.9877 54.0125C39.6238 49.2372 38.2794 44.5427 38.5218 40.311C38.3161 36.9536 39.3079 33.7725 40.667 31.113C43.3926 33.0378 46.0815 36.0793 47.977 40.6269C49.8871 36.0499 52.5907 32.9864 55.3383 31.0616C56.7122 33.7284 57.7113 36.9315 57.5129 40.3037C57.748 44.5427 56.4036 49.2372 52.0397 54.0199Z"
                fill="#1F40E6"
              />
              <path
                opacity="0.75"
                d="M55.38 31.1511C52.6397 33.1568 49.8921 36.235 47.9819 40.9883C46.0865 36.2717 43.3609 33.2008 40.6279 31.2026C42.1487 28.1243 44.1911 25.6264 45.7412 23.9808C46.9755 22.6731 49.0546 22.6731 50.2888 23.9808C51.8316 25.6191 53.8666 28.1023 55.38 31.1511Z"
                fill="#1F40E6"
              />
              <path
                d="M107.22 32.3646C105.688 32.3646 104.358 32.7046 103.227 33.379C102.097 34.0534 101.234 34.9619 100.638 36.1044L100.248 32.8328H95.8489V52.1618H100.833V42.1852C100.833 40.4183 101.278 39.0417 102.175 38.0552C103.071 37.0687 104.218 36.5726 105.622 36.5726C107.047 36.5726 108.1 37.0129 108.773 37.8991C109.447 38.7797 109.787 40.0561 109.787 41.717V52.1618H114.771V41.2488C114.771 38.3394 114.108 36.1323 112.783 34.6274C111.463 33.1226 109.609 32.3646 107.22 32.3646Z"
                fill="#1F40E6"
              />
              <path
                d="M93.153 32.8328H87.2393L80.3845 40.6636V32.3646H75.4062V52.1618H80.3845V42.4583L88.2138 52.1618H94.4394L85.2513 41.6389L93.153 32.8328Z"
                fill="#1F40E6"
              />
              <path
                d="M174.714 32.8328H168.794L161.945 40.6636V32.3646H156.961V52.1618H161.945V42.4583L169.769 52.1618H176L166.812 41.6389L174.714 32.8328Z"
                fill="#1F40E6"
              />
              <path
                d="M146.366 36.6116C147.402 36.6116 148.304 36.8792 149.072 37.4086C149.835 37.9437 150.348 38.6738 150.609 39.6102H155.866C155.398 37.3529 154.324 35.5749 152.636 34.293C150.949 33.0055 148.872 32.3646 146.405 32.3646C144.434 32.3646 142.691 32.7938 141.187 33.6521C139.684 34.5104 138.492 35.7031 137.624 37.2359C136.755 38.7686 136.321 40.5242 136.321 42.4973C136.321 44.4703 136.755 46.226 137.624 47.7587C138.492 49.2914 139.678 50.4842 141.187 51.3425C142.691 52.2008 144.434 52.63 146.405 52.63C148.872 52.63 150.949 51.9667 152.636 50.6402C154.324 49.3137 155.398 47.5636 155.866 45.3788H150.609C150.348 46.3374 149.835 47.0787 149.072 47.5971C148.304 48.1154 147.402 48.3774 146.366 48.3774C144.991 48.3774 143.821 47.859 142.864 46.8168C141.906 45.7801 141.421 44.3365 141.421 42.4917C141.421 40.6469 141.9 39.2033 142.864 38.1666C143.821 37.1355 144.985 36.6116 146.366 36.6116Z"
                fill="#1F40E6"
              />
              <path
                d="M132.472 34.4324C130.891 33.0557 128.719 32.3646 125.968 32.3646C124.359 32.3646 122.905 32.6433 121.608 33.2006C120.31 33.758 119.252 34.5606 118.434 35.5972C117.615 36.6339 117.142 37.8545 117.014 39.2591H121.881C122.064 38.3227 122.527 37.6149 123.284 37.1355C124.036 36.6562 124.921 36.4165 125.929 36.4165C127.043 36.4165 127.978 36.7287 128.73 37.3529C129.482 37.9771 129.86 38.9525 129.86 40.2734V40.7416H124.955C122.154 40.7416 120.049 41.299 118.645 42.4192C117.242 43.5339 116.541 44.9886 116.541 46.7833C116.541 47.8479 116.802 48.8232 117.32 49.7038C117.838 50.59 118.629 51.2979 119.692 51.8274C120.756 52.3569 122.12 52.6244 123.78 52.6244C125.389 52.6244 126.703 52.2844 127.733 51.61C128.758 50.9356 129.554 50.0773 130.128 49.0406L130.595 52.1562H134.839V40.279C134.85 37.7598 134.059 35.809 132.472 34.4324ZM128.251 47.3295C127.46 48.2659 126.352 48.7341 124.921 48.7341C123.986 48.7341 123.234 48.5278 122.666 48.1098C122.092 47.6974 121.808 47.1122 121.808 46.3542C121.808 45.6519 122.092 45.0611 122.666 44.5818C123.234 44.1025 124.186 43.8628 125.506 43.8628H129.749C129.543 45.2395 129.042 46.3932 128.251 47.3295Z"
                fill="#1F40E6"
              />
            </svg> */}
          </div>
        </>
      )}

      {company && company.dark_color !== "" && (
        <img
          src={company.logo}
          className="object-contain w-auto max-h-full"
          // style={{ maxHeight: 40 }}
          alt=""
        />
      )}
    </>
  );
};
