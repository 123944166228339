import { Box, Drawer } from "knack-ui";
import { useEffect, useState } from "react";
import { useUser } from "../../../../features/User/userQueries";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import { CoachesTable } from "../Components/CoachesTable";
import { EmployeesTable } from "../Components/EmployeesTable";
import { HRTableList } from "../Components/HRTableList";
import { MenteesTable } from "../Components/MenteesTable";
import { MentorsTable } from "../Components/MentorsTable";
import { SubAdmin } from "../Components/SubAdminTable";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UserDrawer from "../../../../components/Profile/UserDrawer/UserDrawer";

export const AllUsers = () => {
  const { data: user } = useUser();
  const [companies, setCompanies] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  useEffect(() => {
    getCompanies();
    getTeams();
  }, [setCompanies, setTeams]);

  function getCompanies() {
    AdminServices.getCompanies()
      .then((res) => {
        if (res.data.status) {
          const _companies = [...res.data.data].map((c) => ({
            value: c.name,
            label: c.name,
            _id: c._id
          }));
          setCompanies(_companies);
        }
      })
      .catch((e) => {
        console.log(e);
        // Failure('Oops Something went wrong. Please try again')
      });
  }

  function getTeams() {
    AdminServices.getTeams()
      .then((res) => {
        if (res.data.status) {
          setTeams(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
        // Failure('Oops Something went wrong. Please try again')
      });
  }

  return (
    <Box variant="outlined" paddingPreset="card">
      {/* Tabs */}
      <Tabs
        style={{
          overflowX: "hidden"
        }}
      >
        <TabList className="px-4 overflow-x-hidden border-b">
          <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
            Coaches
          </Tab>
          <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
            Employees
          </Tab>
          <Tab key="sessions" className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
            Mentors
          </Tab>

          <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
            Mentees
          </Tab>

          <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
            HRs
          </Tab>
          {user && user.account_type === "Admin" && (
            <Tab className="inline-block px-4 py-3 text-sm cursor-pointer" selectedClassName="border-b-2 border-skin-base">
              Admin
            </Tab>
          )}
        </TabList>
        <TabPanel>
          <CoachesTable companies={companies} teams={teams} setSelectedUser={setSelectedUser} setUserDrawerOpen={setUserDrawerOpen} />
        </TabPanel>
        <TabPanel>
          <EmployeesTable companies={companies} teams={teams} setSelectedUser={setSelectedUser} setUserDrawerOpen={setUserDrawerOpen} />
        </TabPanel>
        <TabPanel>
          <MentorsTable companies={companies} teams={teams} setSelectedUser={setSelectedUser} setUserDrawerOpen={setUserDrawerOpen} />
        </TabPanel>
        <TabPanel>
          <MenteesTable companies={companies} teams={teams} setSelectedUser={setSelectedUser} setUserDrawerOpen={setUserDrawerOpen} />
        </TabPanel>
        <TabPanel>
          <HRTableList companies={companies} setSelectedUser={setSelectedUser} setUserDrawerOpen={setUserDrawerOpen} />
        </TabPanel>
        <TabPanel>
          <SubAdmin companies={companies} setSelectedUser={setSelectedUser} setUserDrawerOpen={setUserDrawerOpen} />
        </TabPanel>
      </Tabs>
      <Drawer onClose={() => setUserDrawerOpen(false)} isOpen={userDrawerOpen}>
        <UserDrawer setUserId={(id) => setSelectedUser(id)} admin userId={selectedUser} />
      </Drawer>
    </Box>
  );
};
