import { Box, Button, Popover, Stack, Textarea, Toggle, Typography } from "knack-ui";
import { useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Color from "color";
import { useCreateSessionNote, useEditSessionNote } from "../../../../../features/Sessions/sessionsMutations";
import { ISessionNote } from "../../../../../features/Sessions/SessionsTypes";
import { Failure } from "../../../../../components/ToastNotification/Failure";
import { Success } from "../../../../../components/ToastNotification/Success";
import { IUser, IUserProfile } from "../../../../../features/User/UserTypes";

interface ISessionNoteModalProps {
  sessionId: string;
  note: ISessionNote | null;
  onClose: VoidFunction;
  expertOrUser:
    | (Omit<IUserProfile, "user"> & {
        user: Pick<IUser, "email" | "_id" | "profile_image">;
      })
    | null;
}

const colors = ["#89B5AF", "#94B3FD", "#FF8552", "#D57E7E", "#D885A3", "#F6C863", "#F3C5C5", "#D9D7F1"];

interface FormProps {
  color: string;
  content: string;
  shared_with: string[];
}

const SessionNoteModal = ({ onClose, sessionId, note, expertOrUser }: ISessionNoteModalProps) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
    handleSubmit
  } = useForm<FormProps>({
    defaultValues: {
      color: note ? note.color : colors[0],
      content: note ? note.content : "",
      shared_with: note ? (note.shared_with ? note.shared_with.map((u) => u._id) : []) : []
    }
  });
  const { mutateAsync: editNote } = useEditSessionNote();
  const { mutateAsync: createNote } = useCreateSessionNote();

  const colorRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const onEditNote: SubmitHandler<FormProps> = async ({ content, color, shared_with }) => {
    try {
      if (note) {
        await editNote({
          color,
          content,
          noteId: note?._id,
          shared_with,
          ...(content !== note.content && { contentUpdatedAt: Date.now() })
        });
        onClose();
        Success("Note has been updated");
      }
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  };

  const onCreateNote: SubmitHandler<FormProps> = async ({ content, color, shared_with }) => {
    try {
      await createNote({ color, content, sessionId, shared_with });
      Success("Note created successfully");
      onClose();
    } catch (error) {
      Failure("Something went wrong, Please try again");
    }
  };

  return (
    <Box onSubmit={handleSubmit(note ? onEditNote : onCreateNote)} as="form" dir="ltr" elevation={0} paddingPreset="card">
      <Stack className="mb-4" justifyContent="space-between" gap={2} alignItems="center">
        <Typography variant="h5" as="h6">
          {note ? "Edit" : "Create new"} note
        </Typography>
        <div
          onClick={() => setOpen(true)}
          ref={colorRef}
          className="w-6 h-6 rounded cursor-pointer"
          style={{ backgroundColor: Color(watch("color")).alpha(0.25).hexa() }}
        ></div>
        <Popover
          animationType="fade-up"
          popoverProps={{
            style: { zIndex: 9999 },
            className: "p-2",
            variant: "outlined"
          }}
          offset={{ bottom: -30, left: -135 }}
          isOpen={open}
          onClose={() => setOpen(false)}
          parentRef={colorRef}
        >
          <Stack gap={2}>
            {colors.map((c) => (
              <div
                key={c}
                onClick={() => {
                  setValue("color", c);
                  setOpen(false);
                }}
                className="w-6 h-6 rounded cursor-pointer"
                style={{
                  backgroundColor: Color(c).alpha(0.25).hexa()
                }}
              />
            ))}
          </Stack>
        </Popover>
      </Stack>
      <Textarea
        style={{ backgroundColor: Color(watch("color")).alpha(0.1).hexa() }}
        {...register("content", { required: "Required" })}
        rows={8}
        error={errors.content?.message}
        placeholder="Note contents here..."
      />
      <Stack className="mt-4" justifyContent="space-between" alignItems="center" gap={4}>
        <Stack alignItems="center" gap={4}>
          <div>
            <Typography variant="body1">
              Share note with {expertOrUser?.first_name} {expertOrUser?.last_name}
            </Typography>
            <Typography variant="caption" color="muted">
              If shared, this note will no longer be private
            </Typography>
          </div>
          <Controller
            control={control}
            name="shared_with"
            render={({ field: { value, onChange } }) => (
              <Toggle
                onChange={() => {
                  if (value.includes(expertOrUser?._id as string)) {
                    onChange(value.filter((u) => u !== (expertOrUser?._id as string)));
                  } else {
                    onChange([expertOrUser?._id as string]);
                  }
                }}
                checked={value?.includes(expertOrUser?._id as string)}
              />
            )}
          />
        </Stack>
        <Stack alignItems="center" gap={4}>
          <Button onClick={onClose} variant="small" kind="tertiary">
            Cancel
          </Button>
          <Button variant="small" type="submit">
            {/* {note ? "Edit" : "Create"} Note */}
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SessionNoteModal;
