import styled from "styled-components";
import { useGetPairingsChart, useGetUnpaired } from "../hooks/PairingReport.queries";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import Chart from "../../../components/Charts/Chart";
import { resizeObserver } from "../../../lib/utils";
import { useMemo } from "react";
import { PairingReportsQuery } from "../../AnalyticsReports/analyticsReportsTypes";
import { useExportChart } from "../../AnalyticsReports/chartExportMutations";
import { TChartType } from "../../../lib/interfaces/generalTypes";
import { Information } from "../../../components/ToastNotification/Information";

interface IProps {
  query: PairingReportsQuery;
}

const PairingReportCharts = ({ query }: IProps) => {
  const { mutateAsync: onExportChart } = useExportChart();

  const exportChart = async (chartType: TChartType) => {
    await onExportChart(chartType);
    Information("An email with chart export will be sent to your email");
  };

  const { data } = useGetPairingsChart({ query });

  const { data: unpairedData } = useGetUnpaired({ query });

  const usersChartOptions = useMemo(() => {
    if (!data) return null;

    return {
      tooltip: {
        trigger: "item"
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        left: "left"
      },

      series: [
        {
          type: "pie",
          itemStyle: {
            borderRadius: 8,
            borderColor: "#fff",
            borderWidth: 2
          },
          // radius: "50%",
          radius: ["40%", "60%"],
          data: [
            {
              value: data.pairings.pairedUserCount,
              name: "Paired users"
            },
            {
              value: data.pairings.unPairedUserCount,
              name: "Unpaired users"
            }
          ]
        }
      ],
      color: ["#91cc75", "#fac858"]
    };
  }, [data]);

  const expertsChartOptions = useMemo(() => {
    if (!data) return null;

    return {
      tooltip: {
        trigger: "item"
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        left: "left"
      },

      series: [
        {
          type: "pie",
          itemStyle: {
            borderRadius: 8,
            borderColor: "#fff",
            borderWidth: 2
          },
          // radius: "50%",
          radius: ["40%", "60%"],
          data: [
            {
              value: data.pairings.pairedExpertCount,
              name: "Paired experts"
            },
            {
              value: data.pairings.unPairedExpertCount,
              name: "Unpaired experts"
            }
            // {
            //   value: data.pairings.overdueGoals,
            //   name: "Overdue"
            // }
          ]
        }
      ],
      color: ["#91cc75", "#fac858"]
    };
  }, [data]);

  const availabilityChartOptions = useMemo(() => {
    if (!data) return null;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        data: ["Available capacity", "Unpaired users"]
      },
      yAxis: {
        type: "value",

        axisLabel: {
          margin: 20
        }
      },
      series: [
        {
          type: "bar",

          data: [
            {
              value: data.pairings.availability,
              itemStyle: {
                color: "#91cc75"
              }
            },
            {
              value: unpairedData?.unpairedUsers.length,
              itemStyle: {
                color: "#fac858"
              }
            }
          ],
          barWidth: "30%"
        }
      ]
    };
  }, [data, unpairedData]);

  return (
    <ChartsGrid>
      <TitledDisplayBox title="Paired vs. Unpaired users" onExport={() => exportChart("PAIRED_VS_UNPAIRED_USERS")}>
        {/* {isFetching && <KnackSpinner />} */}
        {/* {data?.unpairedUsers.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Sessions" />} */}
        {data && <Chart options={usersChartOptions as any} resizeObserver={resizeObserver} />}
      </TitledDisplayBox>
      <TitledDisplayBox title="Paired vs. Unpaired experts" onExport={() => exportChart("PAIRED_VS_UNPAIRED_EXPERTS")}>
        {/* {isFetching && <KnackSpinner />} */}
        {/* {data?.unpairedUsers.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Sessions" />} */}
        {data && <Chart options={expertsChartOptions as any} resizeObserver={resizeObserver} />}
      </TitledDisplayBox>
      <TitledDisplayBox title="Available capacity vs. Unpaired users" onExport={() => exportChart("AVAILABLE_CAPACITY_VS_UNPAIRED_USERS")}>
        {/* {isFetching && <KnackSpinner />} */}
        {/* {data?.unpairedUsers.length === 0 && <EmptyState iconSize={70} title="No data found" icon="Sessions" />} */}
        {data && <Chart options={availabilityChartOptions as any} resizeObserver={resizeObserver} />}
      </TitledDisplayBox>
    </ChartsGrid>
  );
};

export default PairingReportCharts;
const ChartsGrid = styled.div`
  display: grid;
  gap: 16px;
  position: relative;
  grid-template-columns: 1fr;
  @media ${(p) => p.theme.queries.tabletAndUp} {
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  }
  @media ${(p) => p.theme.queries.laptopAndUp} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
