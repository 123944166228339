import { TaskView } from "../../../pages/mentoring/mentorDashboard/component/TaskView";

export const MenteeTaskList = ({ mentee_id, taskList, setOpenDrawer }) => {
  return (
    <>
      <button
        onClick={() => setOpenDrawer(true)}
        className="flex justify-end w-max ml-auto whitespace-nowrap rounded-lg border px-4 py-2 text-wrap text-sm bg-skin-accent text-white"
      >
        Create Task
      </button>
      {taskList?.length > 0 && (
        <TaskView taskList={taskList} mentee_id={mentee_id} />
      )}
      {taskList?.length == 0 && (
        <p className="text-center pb-8 color-text-muted">No Task assigned</p>
      )}
    </>
  );
};
