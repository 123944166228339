import { useMutation, useQueryClient } from "react-query";
import { ServerResponse, ServerResponseV2 } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { surveyInvitationsQueryKeys, surveyQueryKeys } from "./surveyQueries";
import { ISurvey, NewSurvey, SurveyResponseSection } from "./surveyTypes";

async function exportSurveyResults(survey: string) {
  const res = await apiHttp.get<ServerResponseV2<boolean>>(`v2/survey/${survey}/export-results`);
  return res.data.data;
}

async function createNewSurvey(survey: NewSurvey) {
  const res = await apiHttp.post<ServerResponseV2<ISurvey>>(`v2/survey`, survey);
  return res.data.data;
}

async function deleteSurvey(id: string) {
  const res = await apiHttp.delete("/v2/survey/" + id);
  return res.data;
}
async function editSurvey(survey: { id: string } & Partial<NewSurvey>) {
  const res = await apiHttp.patch<ServerResponse<ISurvey>>("/v2/survey/" + survey.id, { ...survey });
  return res.data.data;
}
async function createSurveyResponse({ answers, invitationId }: { answers: SurveyResponseSection[]; invitationId: string }) {
  const res = await apiHttp.post("/v2/survey/responses", {
    surveyInvite: invitationId,
    answers
  });
  return res.data;
}

export const useCreateSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(createNewSurvey, {
    onSuccess: () => {
      queryClient.invalidateQueries(surveyQueryKeys.all);
    }
  });
};

export const useEditSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(editSurvey, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(surveyQueryKeys.all);
      queryClient.setQueryData(surveyQueryKeys.oneSurvey({ id: data._id }), data);
    }
  });
};
export const useDeleteSurvey = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSurvey, {
    onSuccess: () => {
      queryClient.invalidateQueries(surveyQueryKeys.all);
    }
  });
};
export const useCreateSurveyResponse = () => {
  const queryClient = useQueryClient();
  return useMutation(createSurveyResponse, {
    onSuccess: () => {
      queryClient.invalidateQueries(surveyInvitationsQueryKeys.all);
    }
  });
};

export const useExportSurveyResults = () => {
  return useMutation(exportSurveyResults);
};
