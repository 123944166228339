import { BaseDialog, Box, Button, Stack, Typography } from "knack-ui";
import { useMemo, useState } from "react";
import Slider from "../../../components/Slider/Slider";
import UserProfileCardExpanded from "../../../components/UserProfileCard/UserProfileCardExpanded";
import { withErrorBoundary } from "../../../lib/HOCs/withErrorBoundary";
import { withSuspense } from "../../../lib/HOCs/withSuspense";
import NewConnectionRequestCardSkeleton from "../../../pages/UserDashboard/skeletons/NewConnectionRequestCardSkeleton";
import SliderSkeleton from "../../../pages/UserDashboard/skeletons/SliderSkeleton";
import { IUserProfile } from "../../User/UserTypes";
import { useUpdateConnectionRelationship } from "../connectionsMutations";
import { useGetConnections } from "../connectionsQueries";
import { IConnectionRequest } from "../ConnectionsTypes";
import NewConnectionRequestCard from "./NewConnectionRequestCard";

interface IProps {
  programId?: string;
}

const NewConnectionRequestsSlider = ({ programId }: IProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  const [requestId, setRequestId] = useState<string | null>(null);
  const [connectionRequest, setConnectionRequest] = useState<IConnectionRequest | null>(null);
  const [confirmOpen, setConfirmOpen] = useState({
    approve: false,
    reject: false
  });
  const {
    mutateAsync: updateConnectionStatus,

    isLoading
  } = useUpdateConnectionRelationship();

  const { data: connections } = useGetConnections(
    { search: "" },
    {
      suspense: true
    }
  );

  const onTakeAction = (status: "approve" | "reject", c: IConnectionRequest, requestId: string) => {
    setConnectionRequest(c);
    setRequestId(requestId);
    if (status === "approve") {
      setConfirmOpen({ approve: true, reject: false });
    } else {
      setConfirmOpen({ approve: false, reject: true });
    }
  };
  const onViewProfile = (c: IConnectionRequest, requestId: string) => {
    setPreviewOpen(true);
    setConnectionRequest(c);
    setRequestId(requestId);
  };

  async function onUpdateConnectionStatus(status: IConnectionRequest["status"]) {
    if (!requestId) return;
    try {
      await updateConnectionStatus({ _id: requestId, status });
    } catch (error) {
      console.log(error);
    } finally {
      setRequestId(null);
      setPreviewOpen(false);
      setConfirmOpen({ approve: false, reject: false });
    }
  }

  const pendingRequests = useMemo(() => {
    if (!connections) return [];
    if (!programId) return connections.pendingRequests;
    return connections.pendingRequests.filter((r) => r.program._id === programId);
  }, [connections, programId]);

  if (pendingRequests.length === 0) return null;

  return (
    <div>
      <Typography className="mb-4" variant="h6">
        New Connection requests
      </Typography>
      <Slider
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2.8,
            spaceBetween: 10
          },

          // when window width is >= 640px

          1800: {
            slidesPerView: 3.1,
            spaceBetween: 20
          },
          2036: {
            slidesPerView: 4.5,
            spaceBetween: 20
          }
        }}
      >
        {pendingRequests.map((s) => (
          <NewConnectionRequestCard
            key={s._id}
            program={programId ? undefined : s.program}
            request={s}
            onTakeAction={(status, c) => onTakeAction(status, c, s._id)}
            onViewProfile={(c) => onViewProfile(c, s._id)}
          />
        ))}
      </Slider>
      <BaseDialog
        dialogClassName="max-w-3xl"
        isOpen={previewOpen}
        onClose={() => {
          setPreviewOpen(false);
        }}
      >
        <UserProfileCardExpanded
          renderCTA={() => (
            <Stack className="w-full border-t-2">
              <Button
                variant="large"
                onClick={() => setConfirmOpen({ approve: true, reject: false })}
                shape="square"
                fullWidth
                kind="success"
              >
                Accept
              </Button>
              <Button
                variant="large"
                onClick={() => setConfirmOpen({ approve: false, reject: true })}
                shape="square"
                fullWidth
                kind="warning"
              >
                Reject
              </Button>
            </Stack>
          )}
          listSkills={connectionRequest?.selectedSkills as string[]}
          profile={connectionRequest?.menteeProfile as IUserProfile}
        />
      </BaseDialog>
      <BaseDialog
        animationType="fade"
        dialogClassName="max-w-xl"
        isOpen={confirmOpen.approve || confirmOpen.reject}
        onClose={() => setConfirmOpen({ approve: false, reject: false })}
      >
        <Box dir="ltr" elevation={0} paddingPreset="card" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
          <Typography variant="h6" className="mb-6" as="h2">
            Are you sure you want to {confirmOpen.approve ? "approve" : "reject"} the connection request of{" "}
            <strong>
              {connectionRequest?.menteeProfile?.first_name} {connectionRequest?.menteeProfile?.last_name}
            </strong>
            ?
          </Typography>
          <Stack justifyContent="flex-end" gap={3}>
            <Button kind="ghost" onClick={() => setConfirmOpen({ approve: false, reject: false })}>
              Cancel
            </Button>
            <Button isLoading={isLoading} onClick={() => onUpdateConnectionStatus(confirmOpen.approve ? "approved" : "rejected")}>
              {confirmOpen.approve ? "Approve" : "Reject"}
            </Button>
          </Stack>
        </Box>
      </BaseDialog>
    </div>
  );
};

export default withErrorBoundary(
  withSuspense(NewConnectionRequestsSlider, {
    fallBackComponent: <SliderSkeleton placeholderElement={<NewConnectionRequestCardSkeleton />} />
  })
);
