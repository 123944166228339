import { ComponentType, Suspense } from "react";

interface WithSuspenseOptions {
  fallBackComponent: JSX.Element;
  upperWrapper?: JSX.Element;
  wrapper?: ComponentType | (() => JSX.Element);
}

export function withSuspense<T>(
  Component: ComponentType<T>,
  {
    fallBackComponent: FallbackComponent,
    upperWrapper: UpperWrapper,
    wrapper: Wrapper
  }: WithSuspenseOptions
) {
  return (hocArgs: T) => {
    if (Wrapper) {
      return (
        <>
          {UpperWrapper && UpperWrapper}
          <Wrapper>
            <Suspense fallback={FallbackComponent}>
              <Component {...hocArgs} />
            </Suspense>
          </Wrapper>
        </>
      );
    }
    return (
      <>
        {UpperWrapper && UpperWrapper}
        <Suspense fallback={FallbackComponent}>
          <Component {...hocArgs} />
        </Suspense>
      </>
    );
  };
}
