import { Box, Button, Typography } from "knack-ui";
import SVG from "../../../../components/SVG/SVG";
import { IClientTestimonial } from "../../../../features/User/UserTypes";

interface ITestimonialCardProps {
  testimonial: IClientTestimonial;
  onRemove?: (testimonial: IClientTestimonial) => void;
}

const TestimonialCard = ({ testimonial, onRemove }: ITestimonialCardProps) => {
  return (
    <Box dir="ltr" variant="outlined" paddingPreset="card" className="relative">
      {onRemove && (
        <Button
          className="z-10 end-2 top-2"
          style={{ position: "absolute" }}
          onClick={() => onRemove(testimonial)}
          kind="danger"
          variant="xsmall"
          iconOnly
        >
          <SVG name="Close"  />
        </Button>
      )}
      <SVG name="Quote" size={15} />
      <Typography variant="subtitle2" className="mb-4" color="muted">
        {testimonial.comment}
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        {testimonial.name}
      </Typography>
      {testimonial.position && (
        <Typography variant="subtitle2" fontWeight="semibold" color="muted">
          {testimonial.position}
        </Typography>
      )}
    </Box>
  );
};

export default TestimonialCard;
