const TFA = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-20 h-20"
      viewBox="0 0 286.58 235.085"
    >
      <g id="login" transform="translate(0 -46)">
        <path
          id="Path_617"
          data-name="Path 617"
          d="M53.584,353.167V308.389A22.389,22.389,0,0,1,75.973,286H42.389A22.389,22.389,0,0,0,20,308.389v44.778a22.389,22.389,0,0,0,22.389,22.389H75.973A22.389,22.389,0,0,1,53.584,353.167Z"
          transform="translate(-8.805 -105.666)"
          fill="var(--color-fill-light)"
        />
        <circle
          id="green_eclipse"
          cx="11.5"
          cy="11.5"
          r="11.5"
          transform="translate(219.29 213.543)"
          fill="var(--color-green)"
        />
        <g id="Group_537" data-name="Group 537" transform="translate(0 46)">
          <circle
            id="Ellipse_171"
            data-name="Ellipse 171"
            cx="11.5"
            cy="11.5"
            r="11.5"
            transform="translate(44.29 167.543)"
            fill="var(--color-accent)"
          />
          <ellipse
            id="Ellipse_172"
            data-name="Ellipse 172"
            cx="11"
            cy="11.5"
            rx="11"
            ry="11.5"
            transform="translate(87.29 167.543)"
            fill="var(--color-accent)"
          />
          <ellipse
            id="Ellipse_173"
            data-name="Ellipse 173"
            cx="11"
            cy="11.5"
            rx="11"
            ry="11.5"
            transform="translate(132.29 167.543)"
            fill="var(--color-accent)"
          />
          <ellipse
            id="Ellipse_174"
            data-name="Ellipse 174"
            cx="11"
            cy="11.5"
            rx="11"
            ry="11.5"
            transform="translate(177.29 167.543)"
            fill="var(--color-accent)"
          />
          <path
            id="top_lock"
            d="M266.307,124.362A11.194,11.194,0,0,0,277.5,113.167V90.778a11.194,11.194,0,0,0-11.195-11.195H164.14a44.778,44.778,0,1,0,0,22.389h46.195v11.195a11.195,11.195,0,0,0,22.389,0V101.973h22.389v11.195A11.194,11.194,0,0,0,266.307,124.362ZM120.778,113.167a22.389,22.389,0,1,1,22.389-22.389A22.414,22.414,0,0,1,120.778,113.167Z"
            transform="translate(-33.461 -46)"
            fill="var(--color-accent)"
          />
          <path
            id="pin_cage"
            d="M253,266H33.584A33.622,33.622,0,0,0,0,299.584v44.778a33.622,33.622,0,0,0,33.584,33.584H253a33.622,33.622,0,0,0,33.584-33.584V299.584A33.622,33.622,0,0,0,253,266Zm11.195,78.362A11.207,11.207,0,0,1,253,355.556H33.584a11.207,11.207,0,0,1-11.195-11.195V299.584a11.207,11.207,0,0,1,11.195-11.195H253a11.207,11.207,0,0,1,11.195,11.195Z"
            transform="translate(0 -142.86)"
            fill="var(--color-accent)"
          />
        </g>
      </g>
    </svg>
  );
};

export default TFA;
