import { Stack, Typography } from "knack-ui";
import knackSpinnerSVG from "../Asset/KnackSpinner.svg";

const PageSkeleton = ({
  loadingText = "Please wait...",
  height = "100%"
}: {
  loadingText?: string;
  /**
   * Height of the container
   *
   * @default "100%"
   */
  height?: string;
}) => {
  return (
    <div style={{ height }}>
      <Stack
        direction="column"
        gap={2}
        className="h-full"
        justifyContent="center"
        alignItems="center"
      >
        <div className="w-20 h-20">
          <img src={knackSpinnerSVG} alt="Loading..." />
        </div>
        <Typography variant="caption">{loadingText}</Typography>
      </Stack>
    </div>
  );
};

export default PageSkeleton;
