import { useMutation, useQueryClient } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { teamKeys } from "./teamQueries";
import { ITeamPayload } from "./TeamTypes";

async function addEditTeam(team: ITeamPayload) {
  if (team._id) {
    const res = await apiHttp.post<ServerResponse<ITeamPayload>>(
      "/v1/teams/" + team._id,
      team
    );
    return res.data.data;
  } else {
    const res = await apiHttp.post<ServerResponse<ITeamPayload>>(
      "/v1/teams",
      team
    );
    return res.data.data;
  }
}

// Mutations
export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(addEditTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries(teamKeys["all"]);
    }
  });
};
