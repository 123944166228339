import { useEffect, useMemo } from "react";
import create from "zustand";
import { HRProgramExperts } from "../../../../features/Programs/ui/HrProgramDashboard/Participants/HRProgramExperts";
import HRProgramUsers from "../../../../features/Programs/ui/HrProgramDashboard/Participants/HrProgramUsers";

export const ProgramParticipantsTab = create((set) => ({
  openTab: undefined,
  setTab: (newTab) => set(() => ({ openTab: newTab }))
}));

export const Participants = ({ program }) => {
  const { openTab, setTab } = ProgramParticipantsTab();

  function onSetTab(tab) {
    setTab(tab);
  }

  useEffect(() => {
    if (program?.coach_type === "knack_coaching") {
      setTab(2);
    } else {
      setTab(2);
    }
  }, [program?.coach_type]);

  const participantsNames = useMemo(() => {
    return {
      user: program?.menteeCoacheeWordReplacement
        ? program?.menteeCoacheeWordReplacement
        : program.program_type === "Coaching"
          ? "Coachees"
          : "Mentees",
      expert: program?.mentorCoachWordReplacement
        ? program?.mentorCoachWordReplacement
        : program.program_type === "Coaching"
          ? "Coaches"
          : "Mentors"
    };
  }, [program]);

  return (
    <>
      <div className="flex flex-row w-full overflow-x-auto bg-white rounded-t-lg">
        {program.coach_type !== "knack_coaching" && (
          <div
            className={
              "cursor-pointer sm:px-6 py-3 px-2 whitespace-nowrap sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-white inline-flex items-center leading-none  tracking-wider text-sm " +
              (openTab === 1 ? "border-skin-base text-skin-accent" : "border-gray-200 hover:text-gray-900 text-skin-muted")
            }
            onClick={(e) => {
              e.preventDefault();
              onSetTab(1);
            }}
          >
            {participantsNames.expert}
          </div>
        )}
        <div
          className={
            "cursor-pointer sm:px-6 py-3 px-2 whitespace-nowrap sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-white inline-flex items-center leading-none  tracking-wider text-sm " +
            (openTab === 2 ? "border-skin-base text-skin-accent" : "border-gray-200 hover:text-gray-900 text-skin-muted")
          }
          onClick={(e) => {
            e.preventDefault();
            onSetTab(2);
          }}
        >
          {participantsNames.user}
        </div>
      </div>

      <div className="relative flex flex-col w-full min-w-0 break-words">
        <div className="flex-auto py-4">
          <div className="tab-content tab-space">
            {openTab === 1 && <HRProgramExperts program={program} />}
            {openTab === 2 && <HRProgramUsers program={program} />}
          </div>
        </div>
      </div>
    </>
  );
};
