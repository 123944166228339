import { format } from "date-fns/esm";
import { Avatar, BaseDialog, Box, Button, Chip, Popover, Stack, Typography } from "knack-ui";
import { useMemo, useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import CancelSession from "../../../components/ActionSheets/CancelSession";
import DeleteSession from "../../../components/ActionSheets/DeleteSession";
import SVG from "../../../components/SVG/SVG";
import { Success } from "../../../components/ToastNotification/Success";
import { useLoadingSpinner } from "../../../hooks/useLoadingSpinner";
import { canMarkSessionAsDone } from "../../../lib/utils";
import { ReschedulePopup } from "../../../V3/Pages/ConnectionProfile/ui/ConnectionSessions/ReschedulePopup";
import { useGetCalendarProfile } from "../../Calendar/calendarQueries";
import { ICalendarAccount } from "../../Calendar/CalendarTypes";
import { IProgram } from "../../Programs/ProgramTypes";
import { ITeam } from "../../Team/TeamTypes";
import { useUser, useUserRoles } from "../../User/userQueries";
import { IUser } from "../../User/UserTypes";
import { useUpdateSession } from "../sessionsMutations";
import { ISession, TSessionWithProgram } from "../SessionsTypes";
import MarkSessionAsDoneDialog from "./MarkSessionAsDoneDialog";
import { SessionCardActions } from "./SessionCardActions";

interface Props {
  session: TSessionWithProgram;
  setSelectedSession: (session: TSessionWithProgram | null) => void;
  setDrawerOpen: (value: boolean) => void;
}

export const SessionCard = ({ session, setSelectedSession, setDrawerOpen }: Props) => {
  const { data: expertCalendar } = useGetCalendarProfile(
    session?.expert?.scheduler_id,
    session?.expert?.user?._id // this always fetches the calendar profile of the expert
  );
  const { setLoading } = useLoadingSpinner();
  const [confirmMarkAsDone, setConfirmMarkAsDone] = useState(false);
  const { data: user } = useUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { roleState } = useUserRoles();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [confirmNoShow, setConfirmNoShow] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const { mutateAsync: updateSession } = useUpdateSession();

  const expertOrUserName = useMemo(() => {
    if (roleState?.activeRole === "User") {
      return `${session?.expert?.first_name} ${session?.expert?.last_name}`;
    } else if (roleState?.activeRole === "Expert") {
      return `${session?.user?.first_name} ${session?.user?.last_name}`;
    }
    return "";
  }, [roleState, session]);

  const onSetNoShowStatus = async () => {
    setLoading(true);
    try {
      await updateSession({
        sessionId: session?._id as string,
        updates: {
          completion_status: "No Show"
        }
      });

      setConfirmNoShow(false);
      setLoading(false);
      Success("Session status updated successfully");
    } catch (error) {
      setConfirmNoShow(false);
      setLoading(false);
    }
  };

  return (
    <Stack key={session._id} gap={4} className="z-10" alignItems="center">
      <Typography style={{ width: 60 }} variant="body2" fontWeight="semibold">
        {format(new Date(session.start_time), "LLL dd, kk:mm a")}
      </Typography>
      <div
        style={{ borderWidth: 3 }}
        className={`rounded-full w-5 h-5 bg-paper border ${session.completion_status === "Completed" ? "border-success" : ""}`}
      ></div>
      <Box
        onClick={() => {
          setDrawerOpen(true);
          setSelectedSession(session);
        }}
        variant="outlined"
        className="relative sessions-list__card--container"
      >
        <div className="flex-1">
          <Typography variant="h6">{session.type}</Typography>
          {session.conferencing?.join_url && (
            <Typography variant="subtitle2" fontWeight="medium">
              <a
                className="flex flex-row items-center gap-1 text-blue-700"
                style={{ width: "fit-content" }}
                href={session?.conferencing?.join_url}
                target="_blank"
                rel="noreferrer"
              >
                {session?.conferencing?.join_url}
                <SVG name="FiExternalLink" stroke="#bcb7b7" size={16} />
              </a>
            </Typography>
          )}

          <Stack className="mt-4" gap={10}>
            {session.expert && !session?.team && (
              <Stack alignItems="center" gap={2}>
                <Avatar
                  size="small"
                  text={session.expert?.first_name}
                  image={roleState?.activeRole === "User" ? session?.expert?.user.profile_image : session?.user?.user?.profile_image}
                />
                <Typography fontWeight="semibold" variant="body2">
                  {roleState?.activeRole === "User"
                    ? `${session.expert?.first_name} ${session.expert?.last_name}`
                    : `${session.user?.first_name} ${session.user?.last_name}`}
                </Typography>
              </Stack>
            )}
            {session.expert && session?.team && (
              <Stack alignItems="center" gap={2}>
                <SVG name="Team" />
                <Typography fontWeight="semibold" variant="body2">
                  {(session.team as ITeam)?.name}
                </Typography>
              </Stack>
            )}
            <Stack gap={2}>
              <SVG name="Program" />
              <Typography clamp={1} variant="body2">
                {(session?.program as IProgram)?.name}
              </Typography>
            </Stack>
          </Stack>
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <Chip shape="rounded" variant={session.completion_status === "Completed" ? "success" : "warning"} className="cursor-pointer">
            {session.completion_status}
          </Chip>

          {canMarkSessionAsDone(session, user as IUser) && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedSession(session);
                setConfirmMarkAsDone(true);
              }}
              kind="success"
            >
              Mark as done
            </Button>
          )}
        </div>
        <div className="absolute top-2 end-2" style={{ marginTop: 0 }}>
          <Button
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(true);
            }}
            iconOnly
            kind="ghost"
            shape="rounded"
          >
            <BiDotsVerticalRounded size={20} />
          </Button>
        </div>
      </Box>

      {user?.account_type !== "Admin" && (
        <Popover
          animationType="fade-up"
          offset={{ bottom: 10, left: 0 }}
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          parentRef={buttonRef}
        >
          <SessionCardActions
            setOpen={setMenuOpen}
            session={session}
            setConfirmMarkAsDone={setConfirmMarkAsDone}
            setConfirmDelete={setConfirmDelete}
            setConfirmCancel={setConfirmCancel}
            setConfirmNoShow={setConfirmNoShow}
            setOpenReschedule={setOpenReschedule}
          />
        </Popover>
      )}

      <BaseDialog animationType="fade" dialogClassName="max-w-2xl" isOpen={confirmMarkAsDone} onClose={() => setConfirmMarkAsDone(false)}>
        <MarkSessionAsDoneDialog
          expertOrUserName={expertOrUserName}
          onClose={() => setConfirmMarkAsDone(false)}
          session={session as TSessionWithProgram}
        />
      </BaseDialog>

      <DeleteSession
        open={confirmDelete}
        setOpen={(value) => setConfirmDelete(value || false)}
        session={session as ISession}
        onSuccess={() => setConfirmDelete(false)}
      />

      <CancelSession
        open={confirmCancel}
        setOpen={(value) => setConfirmCancel(value || false)}
        session={session as ISession}
        onSuccess={() => setConfirmCancel(false)}
      />

      <BaseDialog dialogClassName="max-w-md" isOpen={confirmNoShow} onClose={() => setConfirmNoShow(false)}>
        <Box dir="ltr" elevation={0} paddingPreset="card" style={{ gridTemplateRows: "auto 1fr" }} className="grid dialog-max-h">
          <Typography variant="h6" className="mb-6 font-normal" as="h2">
            Are you sure {""}
            {roleState?.activeRole === "User" ? (
              <strong>
                {session?.expert?.first_name} {session?.expert?.last_name}
              </strong>
            ) : (
              <strong>
                {session?.user?.first_name} {session?.user?.last_name}
              </strong>
            )}{" "}
            did not attend this session?
          </Typography>
          <Stack justifyContent="flex-end" gap={3}>
            <Button kind="ghost" onClick={() => setConfirmNoShow(false)}>
              Dismiss
            </Button>
            <Button onClick={() => onSetNoShowStatus()}>Yes, mark as No Show</Button>
          </Stack>
        </Box>
      </BaseDialog>

      <BaseDialog
        style={{ background: "none", height: 500, boxShadow: "none" }}
        animationType="fade"
        dialogClassName="max-w-2xl bg-none"
        isOpen={openReschedule}
        onClose={() => setOpenReschedule(false)}
      >
        <ReschedulePopup
          session={session as TSessionWithProgram}
          onSuccess={(session) => setOpenReschedule(false)}
          onClose={() => setOpenReschedule(false)}
          calendar={expertCalendar as ICalendarAccount}
        />
      </BaseDialog>
    </Stack>
  );
};
