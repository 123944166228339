import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MicrolearningService from "../../../../../services/learning/microlearning.service";
import Utils from "../../../../../services/utils/utils";
import LearnItem from "../microlearning/learnItem";
import { Success } from "../../../../../components/ToastNotification/Success";
import { Failure } from "../../../../../components/ToastNotification/Failure";
import { PageHeader } from "../../../../../components/Typography/PageHeader";
import { LoadMore } from "../../../../../components/LazyLoading/LoadMore";
import { BackButton } from "../../../../../components/nav/BackButton";

export default function Journeys() {
  const history = useHistory();
  const [journeys, setJourneys] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getJourneys();
  }, [setJourneys]);

  const getItemsLeft = (contentCount, totalTime) => {
    return `${contentCount} items • ${Utils.getFormattedTimeInHours(
      totalTime
    )}`;
  };

  const getJourneys = () => {
    MicrolearningService.getJourneys(page)
      .then((res) => {
        setTotalPages(res.data.data.totalPages);
        const _journeys = res.data.data.journeys;
        setJourneys(_journeys);
      })
      .catch((e) => {
        console.log(e);
        Failure();
      });
  };

  const getMoreJourneys = () => {
    MicrolearningService.getJourneys(page + 1)
      .then((res) => {
        setTotalPages(res.data.data.totalPages);
        setPage(page + 1);
        const _journeys = [...journeys].concat(res.data.data.journeys);
        setJourneys(_journeys);
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
        Failure();
      });
  };

  const sendJourneyId = (id) => {
    history.push({ pathname: "journey", state: { slug: id } });
  };

  const subscribeJourney = (status, journey_id) => {
    MicrolearningService.subscribeJourney(status, journey_id)
      .then((response) => {
        if (response.status) {
          Success(
            `You have ${status ? "subscribed" : "unsubscribed"} successfully!`
          );
          getJourneys();
        }
      })
      .catch((e) => {
        console.log(e);
        Failure();
      });
  };

  return (
    <div className="">
      <BackButton />

      <PageHeader PageTitle="Browse Journeys" />

      <section className="text-gray-600 body-font">
        <div className="py-10">
          <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 md:max-w-none">
            {journeys.map((journey, i) => {
              return (
                <LearnItem
                  key={journey._id}
                  id={journey._id}
                  image={journey.image}
                  title={journey.title}
                  url={journey.url}
                  itemsLeft={() =>
                    getItemsLeft(journey.contentCount, journey.totalTime)
                  }
                  subscribeJourney={() =>
                    subscribeJourney(!journey.subscribed, journey._id)
                  }
                  subscribed={journey.subscribed}
                  sendJourneyId={() => sendJourneyId(journey._id)}
                />
              );
            })}
          </div>
        </div>
      </section>

      <LoadMore
        showHide={page !== totalPages}
        onLoad={() => getMoreJourneys()}
      />
    </div>
  );
}
