import { Button, Stack, Typography } from "knack-ui";
import { motion } from "framer-motion";
import { NewSessionPageTransitions } from "../ScheduleSessionModal";
interface ISessionMenteeSelectionProps {
  handleNextStep: VoidFunction;
  handlePreviousStep: VoidFunction;
}

const SessionMenteeSelection = ({
  handleNextStep,
  handlePreviousStep
}: ISessionMenteeSelectionProps) => {
  return (
    <motion.div
      variants={NewSessionPageTransitions}
      exit="exit"
      initial="hidden"
      animate="visible"
      style={{ height: "100%" }}
    >
      <Typography className="mb-6" variant="body1" fontWeight="bold">
        We will show the availability once you choose the mentee and duration
      </Typography>
      {/* <Stack gap={4} direction="column"></Stack> */}
      <Stack direction="column" gap={2}>
        <Button onClick={handleNextStep}>Next</Button>
        <Button onClick={handlePreviousStep} kind="tertiary">
          Cancel
        </Button>
      </Stack>
    </motion.div>
  );
};

export default SessionMenteeSelection;
