import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import { useHistory, useLocation } from "react-router-dom";

import { KnackLogo } from "../../components/logo/NewLogo";
import { Success } from "../../components/ToastNotification/Success";
import { Failure } from "../../components/ToastNotification/Failure";
import { KnackSpinner } from "../../components/Asset/KnackSpinner";
import PasswordChecklist from "react-password-checklist";
import { WarningInformation } from "../../components/Information/WarningInformation";

const required = (value) => {
  if (!value) {
    return (
      <div
        className="flex items-center mt-1 ml-1 text-xs font-medium tracking-wide text-red-500"
        role="alert"
      >
        This field is required!
      </div>
    );
  }
};

export const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("password");
  const history = useHistory();
  const location = useLocation();
  const resetToken = location.pathname.split("/reset-password/")[1];

  const form = useRef();
  const checkBtn = useRef();

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const [passwordMatch, setPasswordMatch] = useState(false);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [retrievedRoute, setRetrievedRoute] = useState(null); // will redirect to save route on successful login

  useEffect(() => {
    if (location.state && location.state.redirect) {
      console.log(location.state);
      setRetrievedRoute(location.state.redirect);
    }
  }, [setRetrievedRoute]);

  const onChangePasswordAgain = (e) => {
    const password = e.target.value;
    setPasswordAgain(password);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.passwordReset(password, passwordAgain, resetToken)
        .then((response) => {
          if (response.data.status) {
            Success("Password Reset Successful. Please login again");
            setTimeout(() => {
              history.push({
                pathname: "/login",
                state: { redirect: retrievedRoute }
              });
              AuthService.logout();
            }, 2000);
          } else {
            Failure(response.data.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.data.message === "Password similar to old") {
              Failure("Cannot use a previous password as new password");
            } else {
              Failure(e.response.data.message);
            }
          } else {
            Failure("Something went wrong, Please try again");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Failure("Something went wrong, Please try again");
      setLoading(false);
    }
  };

  return (
    <>
      {/* 8 characters  */}
      {loading && <KnackSpinner loadingText="Authenticating Request" />}

      {/* Grid */}
      <div className="grid h-screen overflow-y-auto lg:grid-cols-1">
        {/* LEFT */}
        <div>
          {/* Header with Logo */}
          {/* <div> <KnackLogo /> </div> */}
          {/* Main Login */}
          <div className="flex h-screen">
            <div
              className="p-8 m-auto overflow-hidden text-center bg-white rounded-lg shadow"
              style={{ width: "30%" }}
            >
              {/* Header with Logo */}
              <div className="flex justify-center">
                {" "}
                <KnackLogo />{" "}
              </div>

              {/* Title */}
              <div className="mt-4 text-center">
                {/* <div className="text-3xl font-semibold sm:text-4xl text-skin-base">Welcome to Knack</div> */}
                <p className="text-3xl font-semibold text-skin-base">
                  Reset Password
                </p>
              </div>

              {retrievedRoute?.includes("program-application") &&
                retrievedRoute?.includes("/new") && (
                  <WarningInformation message="Reset your password to continue to program application" />
                )}

              {/* Login Form */}
              <div className="mt-4 sm:mt-8">
                <Form onSubmit={handleChangePassword} ref={form}>
                  <label htmlFor="password" className="relative block mt-4">
                    <span
                      className={`text-base ${
                        password !== ""
                          ? "text-skin-inverted"
                          : " text-skin-muted"
                      }`}
                    >
                      New Password
                    </span>
                    <div className="relative flex flex-row items-center w-full input-field-with-showhide">
                      <Input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        validations={[required]}
                        autoComplete="off"
                        className="block w-full mt-1 text-gray-700 border-blue-100 rounded-lg form-input focus:border-2 focus:border-skin-base h-14"
                      />

                      <div
                        className="absolute right-0 pr-4 text-xs font-bold"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 20 20"
                            fill="val(--color-muted)"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                              clipRule="evenodd"
                            />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            viewBox="0 0 20 20"
                            fill="val(--color-muted)"
                          >
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path
                              fillRule="evenodd"
                              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </label>

                  <label
                    htmlFor="passwordAgain"
                    className="relative block mt-4 mb-2"
                  >
                    <span
                      className={`text-base ${
                        passwordAgain !== ""
                          ? "text-skin-inverted"
                          : " text-skin-muted"
                      }`}
                    >
                      Confirm New Password
                    </span>
                    <Input
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      name="passwordAgain"
                      value={passwordAgain}
                      onChange={onChangePasswordAgain}
                      validations={[required]}
                      className="block w-full mt-1 text-gray-700 border-blue-100 rounded-lg form-input focus:border-2 focus:border-skin-base h-14"
                    />
                  </label>

                  <PasswordChecklist
                    iconSize={12}
                    className="text-sm"
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "lowercase",
                      "match"
                    ]}
                    minLength={8}
                    value={password}
                    valueAgain={passwordAgain}
                    onChange={(isValid) => {
                      if (isValid) setPasswordMatch(true);
                      else setPasswordMatch(false);
                    }}
                    messages={{
                      specialChar:
                        "Password has special characters e.g @, $, % _ ~"
                    }}
                  />

                  {message && (
                    <div className="flex justify-center p-1 mt-2">
                      <ul>
                        <li className="flex items-center py-1">
                          <div className="p-1 text-red-500 bg-red-200 rounded-full fill-current">
                            <svg
                              className="w-2 h-2"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              ></path>
                            </svg>
                          </div>
                          <span className="ml-1 text-sm font-medium text-red-400">
                            {message}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="mt-4">
                    <button
                      type="submit"
                      className={`w-full text-base text-center text-white p-4 rounded-lg ${
                        passwordAgain === "" ||
                        password === "" ||
                        !passwordMatch
                          ? "bg-gray-400"
                          : "bg-skin-fill hover:bg-blue-600"
                      }`}
                      disabled={loading || !passwordMatch}
                    >
                      {loading && (
                        <div className="flex justify-center py-2 space-x-2 animate-pulse">
                          <div className="w-3 h-3 bg-white rounded-full"></div>
                          <div className="w-3 h-3 bg-white rounded-full"></div>
                          <div className="w-3 h-3 bg-white rounded-full"></div>
                        </div>
                      )}
                      <span className={loading ? "hidden" : ""}>
                        Change Password
                      </span>
                    </button>
                  </div>
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div>
            </div>

            {/* <Link to="reset-password">
              <div className="text-sm font-semibold text-right text-skin-muted">Forgot password? <span className="text-skin-inverted">RESET</span> </div>
            </Link> */}
          </div>
        </div>

        {/* <div className="relative hidden lg:block bg-skin-fill ">
          <img src={Login_Bg_Photo} className="absolute top-0 z-0 object-cover w-full h-full" loading="lazy" />

          <div className="relative z-50 flex flex-row items-center justify-center h-full p-4">
            <div className="relative flex items-center justify-center h-full max-w-4xl ml-16 border-l-2" style={{ maxHeight: 550, borderColor: "#6279EC" }}>
              <div className="absolute top-0 left-0 p-4 transform -translate-y-6 rounded-full bg-skin-fill -translate-x-9">
                <svg id="XMLID_2_" xmlns="http://www.w3.org/2000/svg" width="36.828" height="34.021" viewBox="0 0 36.828 34.021">
                  <path id="XMLID_30_" d="M143.7,369.54h-.008a23.272,23.272,0,0,1-3.288.226,19.219,19.219,0,0,1-5.508-.707c-6.809-1.723-14.95-7.456-12.189-25.536a3.136,3.136,0,0,1,3.912-2.6,19.854,19.854,0,0,1,6.584,3.228c-2.791,5.763-3.83,13.5,3.085,21.466a22.335,22.335,0,0,1,4.116-.361,21.549,21.549,0,0,1,4.454.429c7-8.013,5.936-15.8,3.122-21.586a19.807,19.807,0,0,1,6.538-3.205,3.132,3.132,0,0,1,3.912,2.6C161.548,363.9,150.767,368.6,143.7,369.54Z" transform="translate(-122.157 -335.746)" fill="#fff" opacity="0.75" />
                  <path id="XMLID_27_" d="M251.7,407.213c-.421.459-.873.925-1.347,1.384a4.91,4.91,0,0,1-5.545,0c-.482-.466-.933-.933-1.354-1.392-4.469-4.891-5.846-9.7-5.6-14.032a18.412,18.412,0,0,1,2.2-9.42,22.011,22.011,0,0,1,7.486,9.744,22.035,22.035,0,0,1,7.539-9.8,18.5,18.5,0,0,1,2.227,9.465C257.548,397.507,256.171,402.314,251.7,407.213Z" transform="translate(-229.127 -375.435)" fill="#fff" />
                  <path id="XMLID_28_" d="M281.908,282.205a22.477,22.477,0,0,0-7.577,10.067,22.434,22.434,0,0,0-7.532-10.022,31.246,31.246,0,0,1,5.237-7.4,3.2,3.2,0,0,1,4.657,0A31.724,31.724,0,0,1,281.908,282.205Z" transform="translate(-255.917 -273.85)" fill="#fff" opacity="0.75" />
                </svg>
              </div>

              <div className="p-4 pl-8 text-white">
                <div className="text-xl tracking-wide xl:text-2xl">
                  As a seasoned manager, my Knack Coach helped me understand the mindset of a
                  leader vs. a manager. I learned many of the basic skills, like giving feedback,
                  coaching/mentoring, communication with different personality styles, etc.
                </div>
                <div className="mt-4">
                  <div className="text-sm font-bold">Decision Maker</div>
                  <p className="text-xs font-light">Works in Finance, Dubai</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
