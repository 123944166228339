import axios from "axios";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

const getOptions = () => {
  return axios.get(API_URL + "v1/profile/options", {
    headers: authHeader()
  });
};

export const getSessions = (page = "") => {
  return axios.get(API_URL + "sessions/?page=" + page, {
    headers: authHeader()
  });
};

export const getSessionsByQuery = (query) => {
  return axios.get(API_URL + "sessions/?" + query, {
    headers: authHeader()
  });
};

export const getCustomSessions = (query = "") => {
  return axios.get(API_URL + "sessions" + query, {
    headers: authHeader()
  });
};

export const getExternalCoachingTopics = () => {
  return axios.get(API_URL + "sessions/topics", {
    headers: authHeader()
  });
};

const getProfile = (id) => {
  return axios.get(API_URL + "profile/" + id, { headers: authHeader() });
};

const getGoals = () => {
  return axios.get(API_URL + "sessions/goals", {
    headers: authHeader()
  });
};

const addSession = (type, start_time, end_time, feedback, rating) => {
  return axios
    .post(
      API_URL + "sessions/",
      {
        type,
        start_time,
        end_time,
        feedback,
        rating
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      console.log(response.data);
      return response.data;
    });
};

const addGoal = (title, deadline, outcome, initial_task, initial_obstacle) => {
  return axios
    .post(
      API_URL + "sessions/goals",
      {
        title,
        deadline,
        outcome,
        initial_task,
        initial_obstacle
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      console.log(response.data);
      return response.data;
    });
};

const addBlocker = (goal_id, title) => {
  console.log(goal_id, title);
  return axios.post(
    API_URL + "sessions/goals/add-blocker",
    { goal_id, title },
    { headers: authHeader() }
  );
};

const addAction = (goal_id, title) => {
  return axios.post(
    API_URL + "sessions/goals/add-action",
    { goal_id, title },
    { headers: authHeader() }
  );
};

const getMyMentees = () => {
  return axios.get(API_URL + "v1/auth/get-mentees", {
    headers: authHeader()
  });
};

export const getMentoringRequests = (query = "") => {
  return axios.get(API_URL + "v1/auth/mentor-requests" + query, {
    headers: authHeader()
  });
};

export const getMentorMatches = (query = "") => {
  return axios.get(API_URL + "v1/auth/match-mentors" + query, {
    headers: authHeader()
  });
};

/**
 *  "status":"approved/rejected/pending/cancelled"
 */
export const updateMentoringRequests = (status, request_id) => {
  return axios.post(
    API_URL + "v1/auth/mentor-requests/_update/" + request_id,
    { status: status },
    {
      headers: authHeader()
    }
  );
};


const getSessionActivity = (user_id) => {
  return axios.get(API_URL + "sessions/dashboard/activity?user_id=" + user_id, {
    headers: authHeader()
  });
};

const getMyMenteeGoals = (user_id) => {
  return axios.get(API_URL + "sessions/goals?user=" + user_id, {
    headers: authHeader()
  });
};

const deleteBlocker = (obstacle_id, goal_id) => {
  console.log();
  return axios.post(
    API_URL + "sessions/goals/remove-blocker",
    { goal_id, obstacle_id },
    { headers: authHeader() }
  );
};

const deleteAction = (task_id, goal_id) => {
  return axios.post(
    API_URL + "sessions/goals/remove-action",
    { goal_id, task_id },
    { headers: authHeader() }
  );
};

const createTaskForMentee = (newTask) => {
  return axios
    .post(API_URL + "sessions/tasks", newTask, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

export const getTasks = (query = "") => {
  return axios.get(API_URL + "sessions/tasks" + query, {
    headers: authHeader()
  });
};

const getMenteeTasks = (mentee) => {
  return axios.get(API_URL + "sessions/tasks?mentee=" + mentee, {
    headers: authHeader()
  });
};

/**
 * Endpoint to mark an action or blocker as completed
 * @param {String} type is action or blocker
 * @param {boolean} completed true or false
 * @param {*} obstacle_id
 * @param {*} task_id
 * @param {*} goal_id
 * if action, pass task_id,
 * if blocker, pass obstacle_id
 */
const markBlockerOrAction = (
  type,
  completed,
  obstacle_id,
  task_id,
  goal_id
) => {
  return axios.post(
    API_URL + "sessions/goals/toggle",
    { type, completed, obstacle_id, task_id, goal_id },
    { headers: authHeader() }
  );
};

export default {
  getOptions,
  getSessions,
  addSession,
  addGoal,
  getGoals,
  addBlocker,
  addAction,
  markBlockerOrAction,
  getMyMentees,
  getSessionActivity,
  getMyMenteeGoals,
  deleteBlocker,
  deleteAction,
  getExternalCoachingTopics,
  getMentoringRequests,
  updateMentoringRequests,
  getCustomSessions,
  createTaskForMentee,
  getTasks,
  getMenteeTasks
};
