export default function Heading({ title, subHeading }) {
  return (
    <div className="flex flex-col text-center w-full my-2">
      <h1 className="sm:text-3xl text-xl font-bold title-font mt-6 mb-2 text-gray-700">
        {" "}
        {title}{" "}
      </h1>
      <p className="lg:w-2/3 text-gray-500 mx-auto leading-relaxed text-sm sm:text-base">
        {" "}
        {subHeading}{" "}
      </p>
    </div>
  );
}
