import { ComponentPropsWithoutRef, forwardRef } from "react";
import styled from "styled-components";

interface IProps extends ComponentPropsWithoutRef<"td"> {
  as?: "th" | "td";
}

const TableCell: React.FC<IProps> = forwardRef<HTMLTableCellElement, IProps>(({ children, as = "td", ...delegated }) => {
  return (
    <Wrapper as={as} {...delegated}>
      {children}
    </Wrapper>
  );
});

export default TableCell;

const Wrapper = styled.td<{ as: "td" | "th" }>`
  background-color: ${(p) => p.as === "th" && "var(--color-border)"};
  padding: 20px 24px;
  font-size: ${14 / 16}rem;
  line-height: 22px;
  text-align: initial;
  vertical-align: middle;
`;
