import { useState } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { Success } from "../../../../components/ToastNotification/Success";
import {
  createPaymentConfiguration} from "../../../../services/dashboard/adminDashboard.services";

export const PaymentConfigurationInput = ({
  CoachName,
  coachId,
  getPaymentConfiguration,
  hiPotentialPlan,
  hiPotentialTeamPlan,
  teamPlan
}) => {
  const [paymentConfig, setPaymentConfig] = useState({
    "1:1 session (Invidual Plan)": "",
    "1:1 session (Team Plan)": "",
    "Team Session (Team Plan)": ""
  });

  function onCreatePaymentConfiguration() {
    // '1:1 session (Invidual Plan)'
    if (paymentConfig["1:1 session (Invidual Plan)"] !== "") {
      createPaymentConfiguration(
        coachId,
        "1:1 session",
        paymentConfig["1:1 session (Invidual Plan)"],
        "Hi-Potential"
      )
        .then((res) => {
          getPaymentConfiguration();
          Success("1:1 session (Invidual Plan) updated successfully!");
          setPaymentConfig({
            "1:1 session (Invidual Plan)": "",
            "1:1 session (Team Plan)": "",
            "Team Session (Team Plan)": ""
          });
        })
        .catch((e) => {
          Failure("Oops! Something went wrong");
          console.log(e);
        });
    }

    if (paymentConfig["Team Session (Team Plan)"] !== "") {
      // '1:1 session (Team Plan)'
      createPaymentConfiguration(
        coachId,
        "Team session",
        paymentConfig["Team Session (Team Plan)"],
        "Team"
      )
        .then((res) => {
          getPaymentConfiguration();
          Success("Team Session (Team Plan) updated successfully!");
          setPaymentConfig({
            "1:1 session (Invidual Plan)": "",
            "1:1 session (Team Plan)": "",
            "Team Session (Team Plan)": ""
          });
        })
        .catch((e) => {
          Failure("Oops! Something went wrong");
          console.log(e);
        });
    }

    if (paymentConfig["1:1 session (Team Plan)"] !== "") {
      // 'Team Session (Team Plan)'
      createPaymentConfiguration(
        coachId,
        "1:1 session",
        paymentConfig["1:1 session (Team Plan)"],
        "Team"
      )
        .then((res) => {
          getPaymentConfiguration();
          Success("1:1 session (Team Plan) updated successfully!");
          setPaymentConfig({
            "1:1 session (Invidual Plan)": "",
            "1:1 session (Team Plan)": "",
            "Team Session (Team Plan)": ""
          });
        })
        .catch((e) => {
          Failure("Oops! Something went wrong");
          console.log(e);
        });
    }
  }
  return (
    <div className="grid grid-cols-5 gap-4 px-4 py-2">
      {/* Coach Name */}
      <div className="flex items-center text-sm">{CoachName}</div>
      {/* Inputs */}
      <div>
        <input
          type="number"
          className="rounded-lg"
          defaultValue={hiPotentialPlan}
          onChange={(e) =>
            setPaymentConfig((p) => ({
              ...p,
              "1:1 session (Invidual Plan)": e.target.value
            }))
          }
          placeholder="$0.00"
        />
      </div>
      <div>
        <input
          type="number"
          className="rounded-lg"
          defaultValue={hiPotentialTeamPlan}
          onChange={(e) =>
            setPaymentConfig((p) => ({
              ...p,
              "1:1 session (Team Plan)": e.target.value
            }))
          }
          placeholder="$0.00"
        />
      </div>
      <div>
        <input
          type="number"
          className="rounded-lg"
          defaultValue={teamPlan}
          onChange={(e) =>
            setPaymentConfig((p) => ({
              ...p,
              "Team Session (Team Plan)": e.target.value
            }))
          }
          placeholder="$0.00"
        />
      </div>
      {/* CTA */}
      <button
        onClick={() =>
          paymentConfig["1:1 session (Invidual Plan)"] !== "" ||
          paymentConfig["1:1 session (Team Plan)"] !== "" ||
          paymentConfig["Team Session (Team Plan)"] !== ""
            ? onCreatePaymentConfiguration()
            : null
        }
        className={`px-4 py-2 rounded-lg text-white ${
          paymentConfig["1:1 session (Invidual Plan)"] !== "" ||
          paymentConfig["1:1 session (Team Plan)"] !== "" ||
          paymentConfig["Team Session (Team Plan)"] !== ""
            ? " bg-skin-fill"
            : "bg-gray-200"
        }`}
      >
        Save
      </button>
    </div>
  );
};
