import { Box, Typography } from "knack-ui";
import ScheduleBufferController from "../../../components/ScheduleBufferController/ScheduleBufferController";

const ScheduleBuffer = () => {
  return (
    <Box variant="outlined">
      <div className="p-4 border-b">
        <Typography variant="h6">Scheduling Buffer</Typography>
        <Typography variant="subtitle2" color="muted">
          The buffer value provided will be used to filter your availability to ensure that a given time period is free before an event. e.g
          45 minutes before a session
        </Typography>
      </div>
      <div className="p-4">
        <ScheduleBufferController />
      </div>
    </Box>
  );
};

export default ScheduleBuffer;
