import { Suspense, useCallback } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { QueryErrorResetBoundary } from "react-query";
import ErrorBoundaryComponent from "../ErrorViews/ErrorBoundaryComponent";

interface BaseProps {
  fallback: JSX.Element;
}

interface WithErrorBoundary extends BaseProps {
  withErrorBoundary: boolean;
  renderError?: (props: FallbackProps) => JSX.Element;
}
interface WithoutErrorBoundary extends BaseProps {
  withErrorBoundary?: never;
  renderError?: never;
}

type SuspenseWrapperProps = WithErrorBoundary | WithoutErrorBoundary;

const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({
  children,
  withErrorBoundary,
  renderError,
  fallback
}) => {
  const fallbackRender = useCallback(
    ({ error, resetErrorBoundary }: FallbackProps) => {
      if (typeof renderError !== "undefined") {
        return renderError({ error, resetErrorBoundary });
      }
      return (
        <ErrorBoundaryComponent
          resetErrorBoundary={resetErrorBoundary}
          error={error}
        />
      );
    },
    [renderError]
  );
  if (withErrorBoundary)
    return (
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary fallbackRender={fallbackRender} onReset={reset}>
            <Suspense fallback={fallback}>{children}</Suspense>
            {/* <Component {...(hocProps as T)} /> */}
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    );
  return <Suspense fallback={fallback}>{children}</Suspense>;
};

export default SuspenseWrapper;
