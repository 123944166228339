import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  getMentoringRequests,
  updateMentoringRequests
} from "../../services/mentoring/mentoring.service";
import { ConfirmationModal } from "../modal/ConfirmationModal";
import { HeadlessModal } from "../modal/HeadlessModal";
import { AvatarPlaceholderV2 } from "../SVG/AvatarPlaceholderV2";
import { Failure } from "../ToastNotification/Failure";
import { Information } from "../ToastNotification/Information";

export const KnackUserCardProfile = ({
  menteeId,
  profile_image,
  color,
  name,
  first_name,
  last_name,
  component,
  email,
  job_function,
  position,
  canHover = true,
  matchDegree
}) => {
  const location = useLocation();
  const [canEndRelationship, setCanEndRelationship] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  const [openProgramSelection, setOpenProgramSelection] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);

  useEffect(() => {
    if (location.state?.canEndRelationship) {
      setCanEndRelationship(location.state?.canEndRelationship);
      fetchMentoringRequests();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCanEndRelationship]);

  function willEndRelation() {
    setOpenProgramSelection(false);
    console.log(selectedRequests);
    selectedRequests.forEach((r) => {
      updateMentoringRequests("cancelled", r)
        .then((res) => {
          Information(
            "You have ended the pairing status with " +
              first_name +
              " " +
              last_name
          );
          setOpenConfirm(false);
          fetchMentoringRequests();
        })
        .catch((e) => {
          console.log(e);
          setOpenConfirm(false);
          Failure(
            "Error occured while ending the pairing status, Please contact support"
          );
        });
    });
  }

  const fetchMentoringRequests = () => {
    getMentoringRequests()
      .then((res) => {
        if (res.data.status) {
          const { data } = res.data;
          const approved = data.filter(
            (p) => p.status === "approved" && p.mentee === menteeId
          );
          setAcceptedRequests(approved);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {openProgramSelection && (
        <HeadlessModal
          disabled={selectedRequests.length === 0}
          width="max-w-sm"
          open={openProgramSelection}
          setOpen={setOpenProgramSelection}
          canDismissFromBackdrop={true}
          showDefaultButton={true}
          defaultButtonText="End pairing"
          buttonFunction={() =>
            selectedRequests.length > 0 ? setOpenConfirm(true) : null
          }
        >
          <div className="">
            <div className="p-4 border-b">
              <div className="text-xl font-bold sm:text-xl">
                Are you sure you want to end your pairing with{" "}
                {first_name} {last_name} ?
              </div>
              <div className="text-sm text-skin-muted">
                {" "}
                Select one or more programs to continue{" "}
              </div>
            </div>

            <div className="">
              <div className="p-4 text-xs text-skin-muted"> Programs </div>

              {/* Program */}
              <div className="grid gap-4 overflow-y-auto max-h-96 scrollhost2">
                {acceptedRequests?.map((r, i) => (
                  <div
                    key={i}
                    className="flex flex-row items-center justify-between px-4 py-2 border-b"
                  >
                    <div>
                      <div className="text-lg font-bold">
                        {r?.program?.name}
                      </div>
                    </div>

                    <label className="custom-switch-button">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (!selectedRequests.includes(r._id)) {
                            const selected =
                              selectedRequests.length === 0
                                ? []
                                : [...selectedRequests];
                            selected.push(r._id);
                            setSelectedRequests(selected);
                          } else {
                            const filtered = selectedRequests.filter(
                              (s) => s !== r._id
                            );
                            setSelectedRequests(filtered);
                          }
                        }}
                      />
                      <span className="custom-switch-slider round"></span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </HeadlessModal>
      )}
      <ConfirmationModal
        title={"End pairing?"}
        header={`Are you sure you want to end the pairing with ${first_name} ${last_name}`}
        showDefaultButton={true}
        buttonFunction={() => willEndRelation()}
        open={openConfirm}
        setOpen={setOpenConfirm}
        defaultButtonText="End pairing"
      />

      <div
        className={`p-4 bg-white rounded-lg border relative h-full ${
          canHover ? "hover:bg-skin-light" : ""
        } `}
      >
        {/* Profile Base photo, name, job_function */}
        <div className="items-start justify-between gap-4 sm:flex">
          <div className="flex flex-row items-center w-full">
            {/* Photo */}
            {/* <Avatar image={profile_image} bgcolor={color} name={`${first_name} ${last_name}`.toUpperCase()} willShowHover={false} className="w-16 h-16 text-xl" /> */}
            <AvatarPlaceholderV2
              imageUrl={profile_image}
              height="h-20"
              width="w-20"
              nameFallback={`${first_name} ${last_name}`.toUpperCase()}
              classes="border-2 rounded-full overflow-hidden"
            />

            {/* Info */}
            <div className="ml-4">
              {/* Name */}
              <div className="text-base font-semibold capitalize">
                {first_name} {last_name}
              </div>
              <div className="text-xs break-words whitespace-wrap">
                {email || job_function || position}
              </div>
            </div>
          </div>

          {/* Match Degree */}
          {
            // matchDegree &&
            // <div className="absolute top-0 right-0 flex-col items-center justify-center hidden gap-4 p-4 m-4 text-center border rounded-lg sm:flex">
            //     <div className="relative flex items-center justify-center">
            //         <div className={`rounded-full h-8 w-8 opacity-25 absolute ${matchDegree > 50 ? 'bg-skin-yellow' : matchDegree > 70 ? 'bg-skin-green' : 'bg-skin-red'}`}>   </div>
            //         <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke={matchDegree > 50 ? 'var(--color-yellow)' : matchDegree > 70 ? 'var(--color-green)' : 'var(--color-red)'}>
            //             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
            //         </svg>
            //     </div>
            //     <div>
            //         <div className="text-xl font-bold">{matchDegree}% </div>
            //         <div className="text-xs text-skin-muted whitespace-nowrap"> Match Degree</div>
            //     </div>
            // </div>
          }

          {/* End relationship CTA */}
          {canEndRelationship && acceptedRequests.length > 0 && (
            <div className="block mt-4 sm:mt-0">
              <button
                onClick={() => setOpenProgramSelection(true)}
                className="flex flex-row items-center justify-center gap-4 px-4 py-2 text-xs font-bold text-left border rounded-lg cursor-pointer hover:bg-gray-100 text-skin-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="var(--color-muted)"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                  />
                </svg>
                <div className="whitespace-nowrap">End pairing</div>
              </button>
            </div>
          )}
        </div>
        {/* Custom Compoment */}
        {component}
      </div>
    </>
  );
};
