import { useEffect, useState } from "react";
import { Failure } from "../../../../components/ToastNotification/Failure";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import { CoachingRating } from "../Components/CoachingRating";
import { PaymentConfiguration } from "../Components/PaymentConfiguration";
import { ProfileUpdateRequest } from "../Components/ProfileUpdateRequest";

export const CoachManagement = () => {
  const [tab, setTab] = useState(0);
  const [coaches, setCoachs] = useState([]);

  useEffect(() => {
    getCoaches();
  }, [setCoachs]);

  function getCoaches() {
    AdminServices.getCoaches()
      .then((res) => {
        if (res.data && res.data.status === true) {
          setCoachs(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
        Failure("Oops Something went wrong. Please try again");
      });
  }

  return (
    <div className="h-full relative">
      {/* Tabs */}
      <div className="flex flex-row w-full text-xs justify-between sm:justify-start border-b border-gray-200 overflow-x-auto">
        <div
          onClick={() => setTab(0)}
          className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
            tab === 0 && "border-b-2 border-skin-base text-skin-inverted"
          } `}
        >
          Payment Configuration
        </div>
        <div
          onClick={() => setTab(1)}
          className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
            tab === 1 && "border-b-2 border-skin-base text-skin-inverted"
          } `}
        >
          Coach Ratings
        </div>
        <div
          onClick={() => setTab(2)}
          className={`px-4 whitespace-nowrap cursor-pointer text-center font-bold py-2 px-1 hover:bg-blue-50 transition duration-75 ease-in-out text-skin-muted ${
            tab === 2 && "border-b-2 border-skin-base text-skin-inverted"
          } `}
        >
          Update Request
        </div>
      </div>

      {/* Tab Content */}
      {tab === 0 && coaches && <PaymentConfiguration coaches={coaches} />}
      {tab === 1 && <CoachingRating />}
      {tab === 2 && <ProfileUpdateRequest />}
    </div>
  );
};
