import { AnimatePresence } from "framer-motion";
import { Avatar, Box, Stack, Typography } from "knack-ui";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Divider from "../../../../components/Divider/Divider";
import { useGetProgram } from "../../../Programs/programQueries";
import { IUserProfile } from "../../../User/UserTypes";
import {
  ISurveyResponse,
  NewSurvey,
  QuestionAnswer,
  SurveyResponseSection
} from "../../surveyTypes";
import SurveySection from "./SurveySection";

interface BaseProps {
  survey: NewSurvey;
  recipientProfile?: IUserProfile;
}
interface PropsWithSubmit extends BaseProps {
  survey: NewSurvey;
  preview?: false;
  onSurveySubmit: (data: SurveyResponseSection[]) => void;
}
interface PropsWithPreview extends BaseProps {
  survey: NewSurvey;
  preview: true;
  onSurveySubmit?: never;
}

type IProps = PropsWithSubmit | PropsWithPreview;

const SurveyForm = ({
  survey,
  preview,
  onSurveySubmit,
  recipientProfile
}: IProps) => {
  const { data: program } = useGetProgram({ programId: survey.program });

  const generateEmptyAnswers = () => {
    const initialSectionAnswers: SurveyResponseSection[] = [];
    survey.components.forEach((section, sectionIndex) => {
      initialSectionAnswers.push([]);
      section.section_questions.forEach((question) => {
        let answerShape: QuestionAnswer = [];
        if (
          question.question_type === "text" ||
          question.question_type === "multiple_choice" ||
          question.question_type === "linear"
        ) {
          answerShape = "";
        } else if (question.question_type === "checkbox") {
          answerShape = [];
        }
        initialSectionAnswers[sectionIndex].push({
          question,
          question_answer: answerShape
        });
      });
    });
    return initialSectionAnswers;
  };
  const methods = useForm<ISurveyResponse>({
    defaultValues: { answers: generateEmptyAnswers() }
  });

  const [sections, setSections] = useState<JSX.Element[]>([]);
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    setSections(
      survey.components.map((section, sectionIndex) => (
        <SurveySection
          submitSurvey={() => onSurveySubmit?.(methods.getValues().answers)}
          section={section}
          key={sectionIndex}
          index={sectionIndex}
          setActiveSection={setActiveSection}
          preview={preview}
        />
      ))
    );
  }, [methods, onSurveySubmit, preview, survey]);
  return (
    <div className="p-6">
      <div className="container max-w-2xl mx-auto">
        <Box paddingPreset="card" variant="outlined" className="relative mb-4">
          <span className="absolute top-0 left-0 right-0 h-2 rounded-t-lg bg-primary" />
          <Typography variant="h5">
            {survey.title || survey.components[0].section_title}
          </Typography>
          {(survey.subtitle || survey.components[0].section_description) && (
            <Typography variant="body2" className="mt-2">
              {survey.subtitle || survey.components[0].section_description}
            </Typography>
          )}
          {program && (
            <>
              <Divider className="block w-full h-1 my-4" />
              <Stack
                gap={4}
                alignItems="center"
                justifyContent="space-evenly"
                className="relative"
              >
                {recipientProfile && (
                  <Box elevation={0} as={Stack} alignItems="center" gap={3}>
                    <Avatar
                      square
                      image={recipientProfile.profile_image}
                      text={recipientProfile.first_name}
                    />
                    <div>
                      <Typography color="muted" variant="body1">
                        This survey is about your{" "}
                        {survey.type === "expert_eval"
                          ? `program expert:`
                          : survey.type === "user_eval"
                          ? "client:"
                          : ""}
                      </Typography>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                      >{`${recipientProfile?.first_name} ${recipientProfile?.last_name}`}</Typography>
                    </div>
                  </Box>
                )}
                {recipientProfile && <Divider vertical />}

                <Box elevation={0} as={Stack} alignItems="center" gap={3}>
                  <Avatar
                    square
                    image={program.image_url}
                    text={program.name}
                  />
                  <div>
                    <Typography color="muted" variant="body1">
                      Program
                    </Typography>

                    <Typography variant="body1" fontWeight="bold">
                      {program.name}
                    </Typography>
                  </div>
                </Box>
              </Stack>
            </>
          )}
        </Box>
        <FormProvider {...methods}>
          <AnimatePresence exitBeforeEnter>
            {sections[activeSection]}
          </AnimatePresence>
        </FormProvider>
      </div>
    </div>
  );
};

export default SurveyForm;
