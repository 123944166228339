import { Button } from "knack-ui";
import { MdDeleteOutline } from "react-icons/md";
const DeleteButton = ({
  onClick
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  return (
    <Button
      className="flex-shrink-0"
      onClick={onClick}
      variant="small"
      iconOnly
      kind="danger"
    >
      <MdDeleteOutline size={15} />
    </Button>
  );
};

export default DeleteButton;
