import {
  GoalsReportsQuery,
  IGoalsAnalytics,
  ISessionAnalytics,
  IUserAnalytics,
  SessionsReportsQuery,
  UserReportsQuery
} from "./analyticsReportsTypes";
import { useMutation } from "react-query";
import { ServerResponse } from "../../hooks/useAxiosConfig";
import { apiHttp } from "../../lib/appConfig";
import { endOfDay } from "date-fns/esm";

export interface IGenerateReportParams<Query, T> {
  query: Query;
  exportAttributes: Array<keyof T>;
}

async function generateGoalsCSVReport(request: IGenerateReportParams<GoalsReportsQuery, IGoalsAnalytics>) {
  const { search_by_email_or_name, department, position, status, topics, userType, organization } = request.query;

  const params: Record<string, any> = {
    search_by_email_or_name,
    department: department?.join(","),
    position: position?.join(","),
    status: status?.join(","),
    topics: topics?.join(","),
    userType: userType?.join(","),
    organization: organization?.join(",")
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>(
    "v2/analytics/goals/generate-report",
    { attributes: request.exportAttributes },
    { params }
  );
  return res.data.data;
}

async function generateUserAssessmentExport(query: UserReportsQuery) {
  const { user, search_by_email_or_name, department, entities, management_level, program, userClass, organization } = query;

  const params: Record<string, any> = {
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    program: program?.join(","),
    userClass: userClass?.join(","),
    user: user?.join(","),
    organization: organization?.join(","),
    search_by_email_or_name
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.get<ServerResponse<boolean>>("v2/analytics/assessment-report/generate-report", { params });
  return res.data.data;
}

async function generateUserCSVReport(request: IGenerateReportParams<UserReportsQuery, IUserAnalytics>) {
  const { search_by_email_or_name, department, entities, management_level, program, userClass, organization } = request.query;

  const params: Record<string, any> = {
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    program: program?.join(","),
    userClass: userClass?.join(","),
    organization: organization?.join(","),
    search_by_email_or_name
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>(
    "v2/analytics/users/generate-report",
    { attributes: request.exportAttributes },
    { params }
  );
  return res.data.data;
}

async function generateSessionsCSVReport(request: IGenerateReportParams<SessionsReportsQuery, ISessionAnalytics>) {
  const { program, status, department, entities, management_level, date, expert, user, organization } = request.query;

  const params: Record<string, any> = {
    user: user?.join(","),
    expert: expert?.join(","),
    organization: organization?.join(","),
    department: department?.join(","),
    entities: entities?.join(","),
    management_level: management_level?.join(","),
    program: program?.join(","),
    sessionStatus: status?.join(","),
    ...(date &&
      date?.length > 0 &&
      date[0].startDate && {
        startDate: date[0].startDate
      }),
    ...(date &&
      date.length > 0 &&
      date[0].endDate && {
        endDate: endOfDay(date[0].endDate)
      })
  };

  // Remove fields with null values
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  const res = await apiHttp.post<ServerResponse<boolean>>(
    "v2/analytics/sessions/generate-report",
    { attributes: request.exportAttributes },
    { params }
  );
  return res.data.data;
}

async function exportCompanyData(organizationId: string) {
  const res = await apiHttp.get<ServerResponse<any>>("v1/auth/export-company-data/" + organizationId);
  return res.data.data;
}

// Mutations
export const useGenerateGoalsCSVReport = () => {
  return useMutation(generateGoalsCSVReport);
};

export const useGenerateUserCSVReport = () => {
  return useMutation(generateUserCSVReport);
};

export const useGenerateExportAssessmentReport = () => {
  return useMutation(generateUserAssessmentExport);
};

export const useGenerateSessionCSVReport = () => {
  return useMutation(generateSessionsCSVReport);
};

export const useExportCompanyData = () => {
  return useMutation(exportCompanyData);
};
