export const ProgressBar = ({
  value,
  color,
  textCss,
  counts,
  showCount = false
}) => {
  return (
    <div>
      <div className="rounded-lg w-full h-2 shadow m-auto">
        <div className="w-full h-2 bg-white rounded-full mt-3 relative">
          <span
            className={`${textCss || "text-xs knack-text-dark"} `}
            style={{
              position: "absolute",
              right: 0,
              fontWeight: "800",
              transform: "translate(2px, -18px)"
            }}
          >
            {!showCount && <> {value > 0 && value.toFixed(0) + "%"} </>}
            {showCount && <> {counts} </>}
          </span>
          <div
            className={`h-2 rounded-full`}
            style={{
              backgroundColor: color.solid,
              width: `${value ? value + "%" : 0}`
            }}
          >
            {" "}
          </div>
        </div>
      </div>
    </div>
  );
};
