import knackSpinnerSVG from "./KnackSpinner.svg";

export const KnackSpinner = ({ loadingText = "Please wait" }) => {
  return (
    <div
      className="absolute top-0 flex flex-col items-center justify-center w-full h-full"
      style={{ zIndex: 99999, background: "#ffffffc7" }}
    >
      <div className="w-20 h-20">
        <img src={knackSpinnerSVG} alt='knack' />
      </div>
      <div className="text-xs font-bold">{loadingText}</div>
    </div>
  );
};
