import { NavLink as Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Filters(props) {
  const history = useHistory();

  return (
    <aside className="relative bg-sidebar h-screen w-1/4 hidden lg:block bg-white -m-1">
      <nav className="text-gray-800 font-normal w-full font-open text-sm px-6 mt-4">
        <p className="text-gray-500 text-sm">Filter results</p>
        <div className="flex flex-wrap">
          <div className="w-full py-4">
            <details className="mb-4">
              <summary className="focus:outline-none py-4 border-b font-semibold cursor-pointer">
                Theme
              </summary>
              <div className="p-4 text-sm text-gray-600">
                {props.themes &&
                  props.themes.map((theme, index) => {
                    return (
                      <div key={index} className="py-1">
                        <label className="inline-flex items-center text-xs">
                          <input
                            type="checkbox"
                            id={theme.key}
                            name={theme.key}
                            value={theme.key}
                            onChange={() => props.onChange(theme.key, "theme")}
                            className="border form-input focus:border-skin-base"
                          />
                          <span className="ml-2">
                            {theme.key}
                            {` (${theme.doc_count})`}
                          </span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </details>
            <details className="mb-4">
              <summary className="focus:outline-none py-4 border-b font-semibold cursor-pointer">
                Type
              </summary>
              <div className="p-4 text-sm text-gray-600">
                {props.types &&
                  props.types.map((type, index) => {
                    return (
                      <div key={index}>
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            id={type.key}
                            name={type.key}
                            value={type.key}
                            onChange={() => props.onChange(type.key, "type")}
                            className="border form-input focus:border-skin-base"
                          />
                          <span className="ml-2">{type.key}</span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </details>
            <details className="mb-4">
              <summary className="focus:outline-none py-4 border-b font-semibold cursor-pointer">
                Time to Complete (minutes)
              </summary>
              <div className="p-4 text-sm text-gray-600">
                {props.timeRanges &&
                  props.timeRanges.map((timeRange, index) => {
                    return (
                      <div key={index}>
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            id={timeRange.key}
                            name={timeRange.key}
                            value={timeRange.key}
                            onChange={() =>
                              props.onChange(timeRange.key, "time")
                            }
                            className="border form-input focus:border-skin-base"
                          />
                          <span className="ml-2">{timeRange.key}</span>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </details>
          </div>
        </div>
      </nav>
    </aside>
  );
}
