import { useEffect, useState } from "react";
import userDashboardServices from "../../../../services/dashboard/userDashboard.services";
import SkillBreakdown from "../../userDashboard/userReports/personalReports/skillBreakdown";

export const AssessmentResults = () => {
  const [skillsBreakdown, setSkillsBreakdown] = useState([]);

  useEffect(() => {
    getSkillsBreakdown();
  }, [setSkillsBreakdown]);

  const getSkillsBreakdown = () => {
    userDashboardServices
      .getSkillsBreakdown()
      .then((res) => {
        if (res.data.status && res.data.data.length) {
          const { data } = res.data;
          setSkillsBreakdown(data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div>
      <SkillBreakdown stateData={skillsBreakdown} />{" "}
    </div>
  );
};
