import { Radio, Stack, Typography } from "knack-ui";
import { range } from "../../../../lib/utils";
import { ISurveyAnswerComponentProps } from "../../surveyTypes";

const SurveyLinearScaleAnswer = ({
  index,
  questionIndex,
  question,
  onChange,
  value,
  sectionIndex
}: ISurveyAnswerComponentProps) => {
  return (
    <Stack gap={8} alignItems="flex-end" justifyContent="center">
      <Typography>{question.options[index].option_label}</Typography>
      <Stack gap={6} alignItems="center" justifyContent="center">
        {question.question_type === "linear" &&
          range(question.from, question.to).map((i) => (
            <div className="grid gap-4 place-center">
              <Typography
                className="text-center"
                variant="body1"
                fontWeight="medium"
                as="label"
                htmlFor={`${i} ${sectionIndex} ${questionIndex} - linear`}
              >
                {i}
              </Typography>
              <Radio
                checked={value === i.toString()}
                id={`${i} ${sectionIndex} ${questionIndex} - linear`}
                onChange={() => {
                  if (value === i.toString()) {
                    onChange("");
                  } else {
                    onChange(i.toString());
                  }
                }}
              />
            </div>
          ))}
      </Stack>
      <Typography>{question.options[index + 1].option_label}</Typography>
    </Stack>
  );
};

export default SurveyLinearScaleAnswer;
