import { BaseDialog, Button, Typography } from "knack-ui";
import { useEffect, useMemo, useState } from "react";
import create from "zustand";
import { EmailSent } from "../../../../components/Animated/EmailSent";
import { CustomToggle } from "../../../../components/Inputs/CustomToggle";
import { Information } from "../../../../components/ToastNotification/Information";
import { PeopleTypes } from "../../../../features/People/PeopleTypes";
import { useSendInvitation } from "../../../../features/Programs/programMutations";
import { programsQueryKeys, useGetProgramParticipants } from "../../../../features/Programs/programQueries";
import { IProgram, IProgramInvite } from "../../../../features/Programs/ProgramTypes";
import { useRequestDelegatedAccess } from "../../../../features/Scheduler/schedulerMutation";
import { queryClient } from "../../../../lib/appConfig";

interface IPostAddUserPrompt {
  program: IProgram | null;
  setProgramData: (program: IProgram | null) => void;
  type: PeopleTypes | null;
  setUserType: (type: PeopleTypes) => void;
  setUserTypeAndProgram: (args: { type: PeopleTypes; program: IProgram | null }) => void;
}

export const usePostAddUserPrompt = create<IPostAddUserPrompt>((set, get) => ({
  program: null,
  type: null,
  setProgramData: (value: IProgram | null) => {
    set({ program: value });
  },
  emitSuccess: () => {},
  setUserType: (type: PeopleTypes) => set({ type: type }),
  setUserTypeAndProgram({ program, type }) {
    set({ type, program });
  }
}));

export const PostAddUserInvitationPrompt = () => {
  const [inviteSent, setInviteSent] = useState(false);
  const { program, setProgramData, type } = usePostAddUserPrompt();
  const [selections, setSelections] = useState<IProgramInvite[]>([]);

  const { data: membersList, refetch } = useGetProgramParticipants(
    { programId: program?._id, type: type as PeopleTypes },
    {
      enabled: Boolean(program),
      select: (members) => members.filter((m) => m.sent === false)
    }
  );
  const { mutateAsync } = useSendInvitation();
  const { mutateAsync: requestDelegatedAccess } = useRequestDelegatedAccess();
  const members = useMemo(() => {
    if (membersList) {
      setSelections(membersList);

      return membersList;
    }
  }, [membersList]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  async function sendInvitation() {
    try {
      await mutateAsync({
        programId: program?._id as string,
        data: selections
      });
      setInviteSent(true);
      Information("Program Invitation sent", "Participants will receive your invitation in their email");

      // Request delegated access
      const expert = ["coach", "mentor"];
      if (type && expert.includes(type)) {
        requestDelegatedAccess({ emails: [...selections.map((s) => s.email)] })
          .then((res) => {})
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (error) {}
  }

  function closeAndRefetch() {
    queryClient.invalidateQueries(programsQueryKeys.all);
    setProgramData(null);
  }

  if (program && !program?.isAutoEnrollment && membersList && membersList?.length > 0) {
    return (
      <BaseDialog dialogClassName="max-w-4xl" onClose={() => closeAndRefetch()} isOpen={program !== null}>
        {inviteSent ? (
          <div className="flex flex-col items-center justify-center gap-4 p-8">
            <EmailSent />
            <Typography variant="h6">Your invitation(s) has been sent.</Typography>
            {!program?.matchingLaunched && (
              <Typography variant="body1" className="-mt-2">
                Participants will not be matched until you launch matching.
              </Typography>
            )}

            <Button onClick={() => closeAndRefetch()}>Dismiss</Button>
          </div>
        ) : (
          <div style={{ maxHeight: 900 }}>
            <div className="">
              <div className="grid gap-3 p-6 text-left">
                <Typography variant="h6">Send Invitation</Typography>
                <Typography variant="body1" className="-mt-2">
                  You are yet to send invitation to the following participants.
                </Typography>
                <div className="p-4 mt-1 -m-4 bg-skin-light">
                  <div className="grid gap-4">
                    <div className="grid grid-cols-3 gap-4 text-sm">
                      <div>Name</div>
                      <div>Type</div>
                    </div>
                    <div className="grid gap-4 overflow-y-auto scrollhost2" style={{ maxHeight: 380 }}>
                      {members?.map((m, i) => (
                        <div key={i} className="grid grid-cols-3 gap-4 font-medium">
                          {m.profile.first_name && (
                            <div>
                              {m.profile.first_name} {m.profile.last_name}
                            </div>
                          )}

                          {!m.profile.first_name && <div>{m.email}</div>}

                          <div>{m.type}</div>
                          <CustomToggle
                            state={selections.find(({ _id }) => m._id === _id) ? true : false}
                            onChange={() => {
                              const found = selections.find(({ _id }) => m._id === _id) ? true : false;

                              if (found) {
                                const filtered = selections.filter((s) => s._id !== m._id);
                                setSelections(filtered);
                              } else {
                                const filtered = [...selections];
                                filtered.push(m);
                                setSelections(filtered);
                              }
                            }}
                            classes={""}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end gap-4 p-6">
                <Button onClick={() => sendInvitation()}>Send Invitation</Button>
              </div>
            </div>
          </div>
        )}
      </BaseDialog>
    );
  } else {
    return <></>;
  }
};
