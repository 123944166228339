import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export const HTMLEditor = ({
  placeholder = "Type or paste in your agreement",
  onChange,
  height = "400",
  defaultValue = ""
}) => {
  return (
    <SunEditor
      setOptions={{
        buttonList: [
          ["undo", "redo", "font", "fontSize", "formatBlock"],
          [
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "removeFormat"
          ],
          [
            "fontColor",
            "hiliteColor",
            "align",
            "horizontalRule",
            "list",
            "table"
          ],
          ["link", "showBlocks", "codeView"]
        ]
      }}
      setContents={defaultValue}
      // defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      height={height}
    />
  );
};
