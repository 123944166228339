import axios from "axios";
import { apiHttp } from "../../lib/appConfig";
import authHeader from "../auth-header";
import { API_URL } from "../base-api";

const getStateBreakdownForUser = (userId = "") => {
  return axios.get(
    API_URL + "assessments/report/state_breakdown?user=" + userId,
    {
      headers: authHeader()
    }
  );
};

const getStateBreakdownbyQuery = (query = "") => {
  return axios.get(API_URL + "assessments/report/state_breakdown" + query, {
    headers: authHeader()
  });
};

const getStateBreakdown = () => {
  return axios.get(API_URL + "assessments/report/state_breakdown", {
    headers: authHeader()
  });
};

const getSkillsBreakdownForUser = (query = "") => {
  return axios.get(API_URL + "assessments/report/skills_breakdown" + query, {
    headers: authHeader()
  });
};

const getSkillsBreakdownByQuery = (query) => {
  return axios.get(API_URL + "assessments/report/skills_breakdown" + query, {
    headers: authHeader()
  });
};

const getSkillsBreakdown = () => {
  return axios.get(API_URL + "assessments/report/skills_breakdown", {
    headers: authHeader()
  });
};

// GET 360 Top challenges report
// / assessments/report/360/top_challenges
const getTopChallenges = () => {
  return axios.get(API_URL + "assessments/report/top_challenges", {
    headers: authHeader()
  });
};

const getTopChallengesForUser = (query = "") => {
  return axios.get(API_URL + "assessments/report/top_challenges" + query, {
    headers: authHeader()
  });
};

const getTopStrengthsForUser = (query = "") => {
  return axios.get(API_URL + "assessments/report/top_strengths" + query, {
    headers: authHeader()
  });
};

const getTopStrengths = () => {
  return axios.get(API_URL + "assessments/report/top_strengths", {
    headers: authHeader()
  });
};

const get360TopChallenges = () => {
  return axios.get(API_URL + "assessments/report/360/top_challenges", {
    headers: authHeader()
  });
};

const get360TopChallengesForUser = (query = "") => {
  return axios.get(API_URL + "assessments/report/360/top_challenges" + query, {
    headers: authHeader()
  });
};

const get360TopStrengthsForUser = (query = "") => {
  return axios.get(API_URL + "assessments/report/360/top_strengths" + query, {
    headers: authHeader()
  });
};

const get360TopStrengths = () => {
  return axios.get(API_URL + "assessments/report/360/top_strengths", {
    headers: authHeader()
  });
};

const getSkillsBreakdown360 = () => {
  return axios.get(API_URL + "assessments/report/360/skills_breakdown", {
    headers: authHeader()
  });
};

const getReviewerSkillsBreakdown360 = () => {
  return axios.get(
    API_URL + "assessments/report/360/skills_breakdown?reviewer_type=true",
    {
      headers: authHeader()
    }
  );
};
const getSkillsBreakdown360ForUser = (query = "") => {
  return axios.get(
    API_URL + "assessments/report/360/skills_breakdown" + query,
    {
      headers: authHeader()
    }
  );
};

const get360Feedback = () => {
  return axios.get(API_URL + "assessments/360-requests", {
    headers: authHeader()
  });
};

const get360Questions = (token) => {
  return axios.get(API_URL + "assessments/360-questions/" + token, {
    headers: authHeader()
  });
};

const addPeerTo360 = (reviewer_email, reviewer_type) => {
  return apiHttp
    .post("assessments/360-requests", {
      reviewer_email,
      reviewer_type
    })
    .then((res) => res.data);
};

const deletePeerFrom360 = (id) => {
  return axios
    .get(API_URL + "assessments/360-requests/_delete/" + id, {
      headers: authHeader()
    })
    .then((response) => {
      return response.data;
    });
};

const invitePeersTo360 = () => {
  return axios
    .post(
      API_URL + "assessments/send-requests/",
      {},
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const submit360Assessment = (response, token) => {
  return axios
    .post(API_URL + "assessments/360-responses", {
      responses: response,
      access_code: token
    })
    .then((response) => {
      if (response.data.status) {
        localStorage.setItem("assessment", JSON.stringify(response.data));
      }

      return response.data;
    });
};

// Get dashboard activity
const getTimeSpentLearning = () => {
  return axios.get(API_URL + "learnings/time-spent", {
    headers: authHeader()
  });
};

const getTotalSessions = () => {
  return axios.get(API_URL + "sessions/count?type=total", {
    headers: authHeader()
  });
};

export const getSessionCountByQuery = (query = "") => {
  return axios.get(API_URL + "sessions/count?type=total" + query, {
    headers: authHeader()
  });
};

const getDashboardActivity = () => {
  return axios.get(API_URL + "sessions/dashboard/activity", {
    headers: authHeader()
  });
};

const getCompletedSessions = () => {
  return axios.get(API_URL + "sessions/count/?type=completed", {
    headers: authHeader()
  });
};

const getMinutesBreakdownByMonth = () => {
  return axios.get(API_URL + "learnings/month-breakdown", {
    headers: authHeader()
  });
};

// To Do's
const getTodos = () => {
  return axios.get(API_URL + "sessions/tasks", {
    headers: authHeader()
  });
};

// get task by sessionId
export const getTaskBySessionId = (sessionId) => {
  return axios.get(API_URL + "sessions/tasks/" + sessionId, {
    headers: authHeader()
  });
};

/**
 * Delete Todo
 * /sessions/tasks/_delete/:task_id
 *
 * id: pass the task id
 * @returns
 */
const deleteTodos = (id) => {
  return axios.get(API_URL + "sessions/tasks/_delete/" + id, {
    headers: authHeader()
  });
};

const getFeedbackQuestions = (session_type) => {
  return axios.get(
    API_URL + `sessions/ratings-questions?type=${session_type}`,
    {
      headers: authHeader()
    }
  );
};

const updateTodo = (todo) => {
  return axios.post(API_URL + "sessions/tasks/_update/" + todo._id, todo, {
    headers: authHeader()
  });
};

const addTodo = (title, user, type, id) => {
  return axios
    .post(
      API_URL + "sessions/tasks",
      {
        title,
        user,
        type,
        learning: id
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const addScheduleTodo = (
  title,
  user,
  type,
  scheduler_id,
  schedule_uid,
  match_id,
  programId
) => {
  return axios
    .post(
      API_URL + "sessions/tasks",
      {
        title,
        user,
        type,
        scheduler_id: scheduler_id,
        schedule_uid: schedule_uid,
        match_id: match_id,
        program: programId
      },
      {
        headers: authHeader()
      }
    )
    .then((response) => {
      return response.data;
    });
};

const updateScheduleTodo = (todo) => {
  return axios.post(API_URL + "sessions/tasks/_update/" + todo._id, todo, {
    headers: authHeader()
  });
};

const saveSessionRatingV2 = (ratingData) => {
  return axios
    .post(
      API_URL + "sessions/ratings",
      { ...ratingData },
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    });
};

const toggleTodoComplete = (status, task_id, user) => {
  return axios.post(
    API_URL + "sessions/tasks/mark-complete",
    {
      status,
      task_id,
      user
    },
    {
      headers: authHeader()
    }
  );
};

const sendMentoringRequest = (mentorId, program_id) => {
  return axios.post(
    API_URL + "v1/auth/mentor-requests",
    { mentor: mentorId, program: program_id },
    { headers: authHeader() }
  );
};

export default {
  getTaskBySessionId,
  updateTodo,
  getStateBreakdown,
  getSkillsBreakdown,
  getTopChallenges,
  getTopStrengths,
  get360Feedback,
  addPeerTo360,
  invitePeersTo360,
  get360Questions,
  getSkillsBreakdown360,
  getReviewerSkillsBreakdown360,
  submit360Assessment,
  deletePeerFrom360,
  get360TopChallenges,
  get360TopStrengths,
  getTimeSpentLearning,
  getTotalSessions,
  getCompletedSessions,
  getDashboardActivity,
  getSessionCountByQuery,
  getMinutesBreakdownByMonth,
  getTodos,
  addTodo,
  addScheduleTodo,
  updateScheduleTodo,
  toggleTodoComplete,
  getFeedbackQuestions,
  getStateBreakdownForUser,
  getSkillsBreakdownForUser,
  getTopStrengthsForUser,
  getTopChallengesForUser,
  get360TopStrengthsForUser,
  get360TopChallengesForUser,
  getSkillsBreakdown360ForUser,
  deleteTodos,
  sendMentoringRequest,
  getStateBreakdownbyQuery,
  getSkillsBreakdownByQuery,
  saveSessionRatingV2
};
