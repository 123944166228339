import create from "zustand";
import axios from "axios";
import { API_URL, FRONTEND_URL } from "../base-api";
import authHeader from "../auth-header";

export const CRONOFY_CLIENT_ID = process.env.REACT_APP_CRONOFY_CLIENT_ID;
// export const CRONOFY_SCOPE = "read_events create_event delete_event read_free_busy change_participation_status"
export const CRONOFY_SCOPE =
  "create_event read_free_busy delete_event change_participation_status";
export const CRONOFY_ENTERPRISE_SCOPE =
  "service_account%2Faccounts%2Fmanage+service_account%2Fresources%2Fmanage";
export const CRONOFY_ENTERPRISE_DELEGATED_SCOPE = "read_write";
export const REDIRECT_URL = `${FRONTEND_URL}/cronofy-aggregator`;
export const ENTERPRISE_REDIRECT_URL = `${FRONTEND_URL}/request-enterprise-auth`;
export const CONFERENCING_AUTH_REDIRECT_URL = `${FRONTEND_URL}/conferencing/authorization`;
export const ORIGIN_URL = FRONTEND_URL;

/**
 * Save success response when user links their calendar to cronofy
 * @param {*} cronofyResponse
 * @returns
 */
const onLinkCalendar = (cronofyResponse) => {
  return axios.post(API_URL + "scheduler/link-account", cronofyResponse);
};

/**
 * Request Approval from user
 */
export const requestCronofyAuthorizationURL = (
  provider_name,
  redirect_uri = "REDIRECT_URL",
  company
) => {
  let url;
  if (redirect_uri === "ENTERPRISE_REDIRECT_URL") {
    redirect_uri = ENTERPRISE_REDIRECT_URL;
    url = `https://app-de.cronofy.com/enterprise_connect/oauth/authorize?client_id=${CRONOFY_CLIENT_ID}&delegated_scope=${CRONOFY_ENTERPRISE_DELEGATED_SCOPE}&redirect_uri=${encodeURIComponent(
      ENTERPRISE_REDIRECT_URL
    )}&response_type=code&scope=${CRONOFY_ENTERPRISE_SCOPE}&state=${company}`;
  } else {
    redirect_uri = REDIRECT_URL;
    url = `https://app-de.cronofy.com/oauth/authorize?response_type=code&client_id=${CRONOFY_CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${CRONOFY_SCOPE}${
      provider_name ? "&provider_name=" + provider_name : ""
    }&avoid_linking=true`;
  }
  localStorage.setItem("calendarConnectCallback", window.location.href);
  window.location.replace(url);
};

/**
 * Request an Access Token from Cronofy Server
 */
const postAccessTokenCode = (code) => {
  return axios.post(
    API_URL + "scheduler/link-account",
    {
      code: code,
      redirect_uri: REDIRECT_URL
    },
    { headers: authHeader() }
  );
};

/**
 * Link service account
 */
const linkServiceAccount = (code, companyId) => {
  return axios.post(
    API_URL + "scheduler/link-service-account",
    {
      code,
      companyId,
      redirect_uri: ENTERPRISE_REDIRECT_URL
    },
    { headers: authHeader() }
  );
};

/**
 * Function saves the pathname and state in localStorage
 * @param {*} pathname  e.g /home
 * @param {*} state  e.g { any | object | [ ] }
 */
const setCallBackData = (pathname, state) => {
  localStorage.setItem("[CRONOFY_CALLBACK]", JSON.stringify([pathname, state]));
};

const deleteCallBackData = () => {
  localStorage.removeItem("[CRONOFY_CALLBACK]");
};

/**
 * Function saves the recipient user object and redirect url to localStorage
 */
const getCallBackData = () => {
  if (localStorage.getItem("[CRONOFY_CALLBACK]")) {
    return JSON.parse(localStorage.getItem("[CRONOFY_CALLBACK]"));
  } else {
    return null;
  }
};

/**
 * Show or Hide Scheduler
 */
export const onCronofySchedulerState = create((set) => ({
  event_id: null,
  recipient: null,
  open: false,
  summary: null,
  callback: null,
  setSummary: (value) => set(() => ({ summary: value })), // set summary of schedule
  setOpen: (value) => set(() => ({ open: value })), // set open state
  setRecipient: (recipient) => set(() => ({ recipient: recipient })), // set recipient object
  setCallback: (callback) => set(() => ({ callback: callback })), // pass a funtion to run after schdule create/update
  setEventId: (event_id) => set(() => ({ event_id: event_id })) // set event_id to update schedule instead
}));

/**
 * Show or Hide FreeBusy Scheduler
 */
export const onCronofySetFreeBusyState = create((set) => ({
  open: false,
  setOpen: (state) => set(() => ({ open: state })) // set open state
}));

/**
 * Request an Element Access Token from Cronofy Server
 */
const requestElementAccessToken = (subs) => {
  return axios.post(
    API_URL + "scheduler/token",
    { subs: subs, origin: ORIGIN_URL },
    { headers: authHeader() }
  );
};
/**
 * Get list of connected calendars
 */
const getConnectedCalendars = (sub, organizerUserId) => {
  return axios.get(
    API_URL + "scheduler/profiles/calendars/" + sub + "/" + organizerUserId,
    {
      headers: authHeader()
    }
  );
};
/**
 * Get cronofy / calendar account
 */
const getCalendarAccount = (scheduler_id, userId) => {
  return axios.get(
    `${API_URL}scheduler/profiles/account/${scheduler_id}/${userId}`,
    {
      headers: authHeader()
    }
  );
};

/**
 * Request an Element Access Token from Cronofy Server
 */
const readAvailabilityRule = (value) => {
  return axios.post(
    API_URL + "scheduler/update-free-busy",
    { status: value },
    { headers: authHeader() }
  );
};
/**
 * Create Schedule / Event
 * @param {*} organizer - sub id of the person who will be scheduling the event or on behalf
 * @param {*} event
 * @param {*} program
 * @param {*} expert user id of organizer
 * @param {*} user user id
 * @returns
 */
const createSchedule = ({
  organizer,
  event,
  program,
  expert,
  user,
  team,
  type,
  formattedDateTime
}) => {
  return axios.post(
    API_URL + "scheduler/create-meeting",
    {
      organizer,
      event,
      origin: ORIGIN_URL,
      program,
      expert,
      user,
      team,
      type,
      formattedDateTime
    },
    { headers: authHeader() }
  );
};
/**
 * Create Smart Invitation
 * @param {*} recipients
 * @param {*} event must contain - summary, description, start, end, tzid,
 * @param {*} program
 * @param {*} expert user id of organizer
 * @param {*} user user id
 * @returns
 */
const createSmartInvite = ({
  recipients = [],
  event,
  organizer,
  program,
  expert,
  user,
  team,
  type
}) => {
  return axios.post(
    API_URL + "scheduler/create-smart-invite",
    { recipients, event, organizer, program, expert, user, team, type },
    { headers: authHeader() }
  );
};
/**
 * Create Notification Channel
 */

const createNotification = (scheduler_id) => {
  return axios.post(
    API_URL + "scheduler/create-cronofy-notification-channel/" + scheduler_id,
    {},
    { headers: authHeader() }
  );
};

const revokeCalendarAccess = (scheduler_id) => {
  return axios.get(API_URL + "scheduler/profiles/revoke/" + scheduler_id, {
    headers: authHeader()
  });
};

/**
 * Delete Schedule / Event
 */
const deleteSchedule = (event_id, scheduler_id) => {
  const sub = JSON.parse(localStorage.getItem("profile")).data.scheduler_id;
  return axios.post(
    API_URL + "scheduler/delete-schedule/" + sub,
    { event_id },
    { headers: authHeader() }
  );
};
/**
 * Update Participation Status for an event
 */
const updateParticipationStatus = (sub, event_uid, status = "accepted") => {
  return axios.post(
    API_URL + "scheduler/participation-status/" + event_uid + "/" + sub,
    { status },
    { headers: authHeader() }
  );
};
/**
 * Get all user schedules
 */
const readUserSchedules = (sub, filter, organizerUserId) => {
  return axios.post(
    API_URL + `scheduler/meetings/${sub}/${organizerUserId}`,
    { filter },
    { headers: authHeader() }
  );
};

const initConferenceAuth = (sub, redirect_uri) => {
  localStorage.setItem("[conferencingConnectCallback]", redirect_uri);
  return axios.post(
    API_URL + `scheduler/conferencing_service_authorizations/${sub}`,
    { redirect_uri: CONFERENCING_AUTH_REDIRECT_URL },
    { headers: authHeader() }
  );
};

export const CronofyService = {
  linkServiceAccount,
  initConferenceAuth,
  getCalendarAccount,
  getConnectedCalendars,
  createSmartInvite,
  requestCronofyAuthorizationURL,
  getCallBackData,
  setCallBackData,
  deleteCallBackData,
  onLinkCalendar,
  postAccessTokenCode,
  requestElementAccessToken,
  readAvailabilityRule,
  createSchedule,
  createNotification,
  readUserSchedules,
  deleteSchedule,
  updateParticipationStatus,
  revokeCalendarAccess
};
