import { useState } from "react";

import { Link } from "react-router-dom";
import { Success } from "../../components/ToastNotification/Success";
import { Box, Button, Input, Stack, Typography } from "knack-ui";
import { SubmitHandler, useForm } from "react-hook-form";
import { useForgotPassword } from "../../features/User/userMutations";
import SVG from "../../components/SVG/SVG";
import { emailRegex } from "../../lib/constants";
import logo from "../../components/Asset/logo.svg";

interface FormProps {
  email: string;
}

const ForgotPassword = () => {
  const { mutateAsync } = useForgotPassword();
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormProps>();
  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    try {
      setErrorMessage("");
      const res = await mutateAsync(data);
      if (res.message && res.status) {
        Success(
          `You will receive a password reset link in your inbox with further instructions if this user exists in our database`
        );
      }
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        elevation={2}
        paddingPreset="card"
      >
        <div className="mb-4">
          <img src={logo} alt="Knack logo" />
        </div>
        <Typography className="mb-2" variant="h5" as="h2">
          Password Reset
        </Typography>
        <Typography className="mb-4" variant="subtitle2" color="muted">
          Enter your email address to receive a password reset link
        </Typography>
        <Stack direction="column" gap={6} className="my-6">
          <div>
            <Typography
              className="block mb-2"
              htmlFor="email"
              variant="body1"
              as="label"
            >
              Email
            </Typography>
            <Input
              {...register("email", {
                required: "Required field",
                pattern: {
                  value: emailRegex,
                  message: "Please provide a valid email address"
                }
              })}
              id="email"
              inputPrefix={<SVG name="Person" />}
              error={errors.email?.message}
            />
          </div>

          {errorMessage && (
            <Typography
              as={Stack}
              alignItems="center"
              gap={2}
              fontWeight="semibold"
              justifyContent="center"
              variant="body2"
              className="mt-4"
              color="warning"
            >
              <SVG name="Danger" />
              <span>{errorMessage}</span>
            </Typography>
          )}
        </Stack>
        <Stack direction="column" gap={4}>
          <Button disabled={!watch("email")} variant="large" type="submit">
            Send password reset link
          </Button>
          <Button as={Link} to="/login" kind="ghost">
            Back to login
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default ForgotPassword;
