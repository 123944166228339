import { QueryClient, QueryCache, MutationCache } from "react-query";
import { Failure } from "../components/ToastNotification/Failure";
import axios from "axios";
import { apiEndpoint } from "./constants";
import { isDev } from "./utils";
import { buildAxiosErrorBlocks, buildGeneralErrorBlocks } from "./errUtils";
import { slackSendErrorMessage } from "../features/SlackBugIngest/slackIngestMutations";
import { ISlackField } from "../features/SlackBugIngest/SlackIngestTypes";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        Failure(err as any);
      }
      if (axios.isAxiosError(err)) {
        if (err?.response?.status === 404) {
          return Failure("Content could not be found, Please try again");
        }
        const fields = buildAxiosErrorBlocks(err);
        handleSendError(fields);
      } else {
        if (err instanceof Error) {
          Failure("Something went wrong, Please try again");
          const fields = buildGeneralErrorBlocks(err);
          handleSendError(fields);
        }
      }
    }
  }),
  mutationCache: new MutationCache({
    onError: async (err) => {
      if (axios.isAxiosError(err)) {
        if (err?.response?.status === 404) {
          return Failure("Content could not be found, Please try again");
        }
        if (isDev()) {
          const response = err.response?.data as { message: string }; // TODO: 1. Send error to sentry, 2. Enforce message field from server to hold error success response
          Failure(response?.message);
        } else {
          Failure("Something went wrong, Please try again");
        }
        const fields = buildAxiosErrorBlocks(err);
        handleSendError(fields);
      } else {
        if (err instanceof Error) {
          Failure("Something went wrong, Please try again");
          const fields = buildGeneralErrorBlocks(err);
          handleSendError(fields);
        }
      }
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked" // 🌟 Only rerenders if one of the used props used eg: {data, isFetching,...}
    }
  }
});
export const apiHttp = axios.create({
  baseURL: apiEndpoint
});

const handleSendError = (fields: ISlackField[]) => {
  // send message to slack
  try {
    slackSendErrorMessage(fields);
  } catch (reason) {
    console.error(reason);
  }
};
