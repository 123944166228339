import { Avatar, Box, LinearProgress, Stack, Typography } from "knack-ui";
import { useCallback, useEffect, useState } from "react";
import { useGetGoals } from "../../../../features/Goals/goalQueries";
import { useUser } from "../../../../features/User/userQueries";
import { calculateGoalPercentage } from "../../../../lib/utils";
import { TGoal } from "../../../../features/Goals/goalsTypes";

const GoalsProgress = () => {
  const { data: goals } = useGetGoals({ select: (allGoals) => allGoals.goals });
  // @ts-ignore
  const { data: user } = useUser();
  const [goalsPercentage, setGoalsPercentage] = useState<{
    [key: number]: number;
  }>({});
  const [completedGoalsLength, setCompletedGoalsLength] = useState(0);

  const calculateProgress = useCallback((goals: TGoal[]) => {
    const percentages: { [key: number]: number } = {};
    let length = 0;
    goals.forEach((goal, i) => {
      const progress = calculateGoalPercentage(goal);
      if (progress === 100) length++;
      percentages[i] = progress;
    });
    setCompletedGoalsLength(length);
    setGoalsPercentage(percentages);
  }, []);
  useEffect(() => {
    if (goals) {
      calculateProgress(goals);
    }
  }, [calculateProgress, goals]);
  return (
    <Box
      variant="outlined"
      direction="column"
      as={Stack}
      gap={4}
      paddingPreset="card"
      className="self-start"
      style={{ flexBasis: "25%", flexShrink: 0 }}
    >
      <Typography variant="h6" as="h6">
        Your progress
      </Typography>
      <Stack gap={3}>
        {user && (
          <Avatar
            image={user?.profile_image}
            text={user?.profile?.first_name || ""}
          />
        )}
        <div>
          <Typography color="muted" variant="caption" as="p">
            Completed {`${completedGoalsLength} of ${goals?.length} `} goals
          </Typography>
          <Typography
            color={
              goals?.length === 0
                ? "primary"
                : completedGoalsLength === goals?.length
                ? "success"
                : "warning"
            }
            variant="caption"
            as="p"
          >
            {`${
              goals?.length === 0
                ? "No goals added"
                : completedGoalsLength === goals?.length
                ? "Completed"
                : "In Progress"
            }`}
          </Typography>
        </div>
      </Stack>
      <Stack direction="column" gap={3}>
        {goals?.slice(0, 3).map((goal, i) => (
          <div key={goal._id}>
            <Typography clamp={2} className="mb-2" variant="body2" as="p">
              {`${i + 1}. ${goal.title}`}
            </Typography>
            <LinearProgress
              size={12}
              showPercentage
              color="dynamic"
              percentage={goalsPercentage[i]}
            />
          </div>
        ))}
      </Stack>
    </Box>
  );
};

export default GoalsProgress;
