import { Avatar, Box, Button, Stack, Typography } from "knack-ui";
import { useHistory } from "react-router-dom";
import { useUpdateConnectionRelationship } from "../../../../features/Connections/connectionsMutations";
import { IConnectionRequest } from "../../../../features/Connections/ConnectionsTypes";
import { useWindowSize } from "../../../../hooks/useWindowSize";

interface INewConnectionsDrawerProps {
  pendingConnections: IConnectionRequest[];
  onClose: VoidFunction;
}

const NewConnectionsDrawer = ({
  pendingConnections,
  onClose
}: INewConnectionsDrawerProps) => {
  const { height: pageHeight, width: pageWidth } = useWindowSize();
  const { mutateAsync: updateConnectionStatus } =
    useUpdateConnectionRelationship();

  async function onUpdateConnectionStatus(
    _id: string,
    status: IConnectionRequest["status"]
  ) {
    try {
      await updateConnectionStatus({ _id, status });
      onClose();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div style={{ minWidth: `calc(${pageWidth}px / 4 )`, maxWidth: 400 }}>
      <div className="p-6">
        <Typography variant="h6" as="h6">
          New connection requests
        </Typography>
        <Typography variant="subtitle2" as="p" color="muted">
          List of coaching and mentoring requests
        </Typography>
      </div>
      <div
        style={{ height: `calc(${pageHeight}px - 97px)` }}
        className="overflow-y-auto"
      >
        <Stack className="px-6 py-4" gap={4} direction="column">
          {pendingConnections.map((c) => (
            <NewRequestCard
              key={c._id}
              connection={c}
              onUpdateConnectionStatus={onUpdateConnectionStatus}
            />
          ))}
        </Stack>
      </div>
    </div>
  );
};

export default NewConnectionsDrawer;

const NewRequestCard = ({
  connection,
  onUpdateConnectionStatus
}: {
  connection: IConnectionRequest;
  onUpdateConnectionStatus: (
    _id: string,
    status: IConnectionRequest["status"]
  ) => void;
}) => {
  const history = useHistory();
  return (
    <Box
      variant="outlined"
      as={Stack}
      dir="ltr"
      className="h-full px-3 py-4 bg-gray-100"
      alignItems="center"
      justifyContent="space-between"
      gap={3}
    >
      <Stack className="flex-1" alignItems="center" gap={3}>
        <Avatar
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/connections/${connection.mentee?._id}`);
          }}
          className="cursor-pointer"
          image={connection.mentee.profile_image}
          text={connection.menteeProfile.first_name}
        />
        <Stack direction="column">
          <Typography
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/connections/${connection.mentee?._id}`);
            }}
            className="hover:underline"
            variant="body1"
            fontWeight="bold"
            as="h6"
            clamp={2}
          >{`${connection.menteeProfile.first_name} ${connection.menteeProfile.last_name}`}</Typography>
          <Typography
            clamp={2}
            color="primary"
            fontWeight="semibold"
            variant="caption"
          >
            {connection.program.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2} direction="column">
        <Button
          variant="small"
          onClick={() => onUpdateConnectionStatus(connection._id, "approved")}
        >
          Approve
        </Button>
        <Button
          variant="small"
          kind="danger"
          onClick={() => onUpdateConnectionStatus(connection._id, "rejected")}
        >
          Deny
        </Button>
      </Stack>
    </Box>
  );
};
