import { Dispatch, SetStateAction } from "react";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import svg from "../../../../components/Asset/goalsEmptyState.svg";
import GoalCard from "./GoalCard";
import { Box, Stack, Typography } from "knack-ui";
import { IoMdAddCircle } from "react-icons/io";
import { AnimatePresence, Variants } from "framer-motion";
import { TGoal } from "../../../../features/Goals/goalsTypes";
import styled from "styled-components";
export interface IGoalsListProps {
  setNewGoalOpen: Dispatch<SetStateAction<boolean>>;
  goals?: TGoal[];
  total: number;
  title: "inprogress" | "completed" | "suggested";
  setSelectedGoal?: Dispatch<SetStateAction<TGoal | null>>;
}

export const GoalCardAnimationVariants: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    opacity: 1,
    y: 0
  },
  exit: {
    y: -50,
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
};

const GoalsList = ({ setNewGoalOpen, goals, total, title, setSelectedGoal }: IGoalsListProps) => {
  const onGoalClick = (goal: TGoal) => {
    setNewGoalOpen(true);
    setSelectedGoal?.(goal);
  };
  return (
    <div className="h-full overflow-y-auto">
      {total === 0 && (
        <EmptyState
          img={svg}
          buttonCb={() => setNewGoalOpen(true)}
          buttonText="Create new goal"
          title="No goals created yet!"
          subtitle="Create a new goal now"
        />
      )}
      {total !== 0 && goals?.length === 0 && (
        <EmptyState
          img={svg}
          buttonCb={() => setNewGoalOpen(true)}
          buttonText="Create new goal"
          title={`${
            title === "inprogress"
              ? "You have no goals in progress!"
              : title === "completed"
              ? "You have not completed any goals yet"
              : "No Suggested goals were found"
          }`}
          subtitle="Create a new goal now"
        />
      )}

      <GoalsGrid>
        {title !== "suggested" && goals && goals.length > 0 && (
          <Box
            as={Stack}
            onClick={() => {
              setNewGoalOpen(true);
            }}
            elevation={0}
            direction="column"
            gap={3}
            justifyContent="center"
            alignItems="center"
            paddingPreset="card"
            className="transition-colors duration-100 border-2 border-dotted cursor-pointer hover:bg-gray-100"
          >
            <IoMdAddCircle size={30} className="text-skin-accent" />

            <Typography textAlign="center" variant="body1" fontWeight="bold" as="h6">
              Create New Goal
            </Typography>
          </Box>
        )}
        <AnimatePresence>
          {goals?.map((goal) => (
            <GoalCard defaultGoal={title === "suggested"} onGoalClick={onGoalClick} goal={goal} key={goal._id} />
          ))}
        </AnimatePresence>
      </GoalsGrid>
    </div>
  );
};

export default GoalsList;



export const GoalsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding-inline-end: 8px;
`;
