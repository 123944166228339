import React, { useEffect, useState } from "react";
import { HRCoachServices } from "../../../../services/dashboard/hrCoachDashboard.services";
import SkillBreakdown from "../../userDashboard/userReports/personalReports/skillBreakdown";

export const TeamResult = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).data
    : null;

  const [teams, setTeams] = useState([]);
  const [filteredTeam, setFilteredTeam] = useState([]);
  const [assessmentResult, setAssessmentResult] = useState([]);

  useEffect(() => {
    getTeams();
  }, [setFilteredTeam, setTeams]);

  function getTeams() {
    HRCoachServices.getEnrolledTeam()
      .then((res) => {
        const { data } = res.data;
        if (res.data.status === true) {
          setTeams(data);
          setFilteredTeam(data);
        }
      })
      .catch((e) => console.log(e));
  }

  function getAssessmentResultPerTeam(organization, teamId) {
    if (user && user.account_type === "Coach") {
      HRCoachServices.getAssessmentResultPerTeamV1(organization, teamId)
        .then((res) => {
          const { data } = res.data;
          if (res.data.status) {
            // console.log(data);
            setAssessmentResult(data);
          }
        })
        .catch((e) => console.log(e));
    } else {
      HRCoachServices.getAssessmentResultPerTeam(teamId)
        .then((res) => {
          const { data } = res.data;
          if (res.data.status) {
            // console.log(data);
            setAssessmentResult(data);
          }
        })
        .catch((e) => console.log(e));
    }
  }

  function onSearch(value) {
    if (value === "") {
      setFilteredTeam(teams);
    } else {
      const filtered = [...teams].filter((t) =>
        t.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeam(filtered);
    }
  }

  return (
    <div>
      <div className="hidden w-full p-4 mb-4 -mt-5 bg-white">
        <div className="font-bold text-md text-skin-base">
          Assessment Results Breakdown by Team
        </div>
        <div className="text-xs font-light">
          Select a team to view framework report
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-4">
        <div>
          <div>
            <input
              onChange={(e) => onSearch(e.target.value)}
              type="text"
              placeholder="Search Teams"
              className="text-sm rounded-md"
            />
          </div>
          {/* Team List */}
          <div className="flex flex-row w-full gap-3 my-4 overflow-x-scroll md:block team_Assessment">
            {filteredTeam?.map((t, i) => (
              <div
                key={i}
                onClick={() =>
                  getAssessmentResultPerTeam(t.organization, t._id)
                }
                className={`border p-4 shadow rounded-lg mb-4 cursor-pointer transition-all md:whitespace-normal whitespace-pre  ${
                  assessmentResult._id === t._id ? "bg-skin-accent" : ""
                } bg-white`}
              >
                <h1 className="text-base font-bold">{t.name}</h1>
                <h1 className="flex items-center justify-between text-xs text-skin-muted">
                  <span>
                    Lead: {t?.leader?.first_name} {t?.leader?.last_name}
                  </span>
                  <span className="flex items-center font-bold text-skin-base">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                    </svg>
                    <span> {t.member_count}</span>
                  </span>
                </h1>
              </div>
            ))}
          </div>
        </div>

        {/* Assessment Result */}
        <div className="md:col-span-3">
          {assessmentResult.length > 0 && (
            <SkillBreakdown stateData={assessmentResult} isTeamResult={true} />
          )}
        </div>
      </div>
    </div>
  );
};
