import { Box, Breadcrumbs, Button, Chip, Popover, Stack, Typography } from "knack-ui";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import EmptyState from "../../../components/EmptyState/EmptyState";
import FiltersBar from "../../../components/FiltersBar";
import Table from "../../../components/Table";

import MetricCard from "../../../components/Metrics/MetricCard";
import { KnackSpinner } from "../../../components/Asset/KnackSpinner";
import { PaginationState, RowSelectionState, SortingState } from "@tanstack/react-table";
import { useGetPairingsData, useGetPairingsSummary } from "../hooks/PairingReport.queries";
import { usePairingFilters } from "../hooks/PairingReport.hooks";
import UnpairedTables from "../components/UnpairedTables";
import PairingReportCharts from "../components/PairingReportCharts";
import { capitalizeFirstLetter } from "../../../lib/utils";
import AttributeSelector from "../../../components/Selector/AttributeSelector";
import { useGenerateCSVPairingReport } from "../hooks/PairingReport.mutations";
import { Information } from "../../../components/ToastNotification/Information";
import { PairingRecord, PairingReportsQuery } from "../../AnalyticsReports/analyticsReportsTypes";
import { useUser } from "../../User/userQueries";
import { useExportChart } from "../../AnalyticsReports/chartExportMutations";

const PairingReportPage = () => {
  const { data: user } = useUser();

  const [searchValue, setSearchValue] = useState<string>("");

  const [sorting, setSorting] = useState<SortingState>([]);

  const { mutateAsync: generateReport, isLoading: isExportLoading } = useGenerateCSVPairingReport();

  const [query, setQuery] = useState<PairingReportsQuery>({
    ...(user?.account_type === "Admin" ? { organization: [] } : []),
    program: [],
    status: [],
    department: [],
    entities: [],
    management_level: [],
    position: []
  });
  const { data: pairingData, isFetchingNextPage, hasNextPage, fetchNextPage, fetchPreviousPage } = useGetPairingsData({ query });

  const { data: pairingSummary, isLoading: summaryLoading, isFetching: summaryFetching } = useGetPairingsSummary({ query });
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const filters = usePairingFilters({
    query,
    onQueryChange: setQuery
  });

  /** Reset page index to 0 whenever we change the query to support fresh queries */
  useEffect(() => {
    setPagination({ pageSize, pageIndex: 0 });
  }, [filters, pageSize]);

  useEffect(() => {
    if (pairingData && !pairingData.pages[pageIndex] && !isFetchingNextPage) {
      setPagination({ pageIndex: 0, pageSize });
    }
  }, [isFetchingNextPage, pageIndex, pageSize, pairingData]);

  const popoverRefEl = useRef<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  // const { setLoading } = useLoadingSpinner();

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  async function onExportCSV(exportAttributes: Array<keyof PairingRecord>) {
    try {
      await generateReport({ exportAttributes, query });
      setMenuOpen(false);

      Information("A CSV report has been sent to your email");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Box variant="outlined" className="px-6 py-2 mb-4">
        <Breadcrumbs>{[<Link to="/reports">Reports</Link>, <Typography>Pairings report</Typography>]}</Breadcrumbs>
      </Box>

      <Box variant="outlined" className="grid grid-cols-1 gap-6 px-6 py-4 ">
        <div className="pb-6 border-b">
          <Typography fontWeight="semibold" className="mb-4">
            Filters
          </Typography>
          <FiltersBar query={query} filters={filters} />
        </div>
        <div>
          <Typography className="mb-4" fontWeight="semibold">
            Summary
          </Typography>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <MetricCard
              isLoading={summaryLoading || summaryFetching}
              icon="Sessions"
              title="Number of paired users"
              value={pairingSummary?.approved as number}
            />
            <MetricCard
              isLoading={summaryLoading || summaryFetching}
              color="bg-success text-successDark"
              icon="Sessions"
              title="Number of unpaired users"
              value={pairingSummary?.unpairedUsers as number}
            />
            <MetricCard
              isLoading={summaryLoading || summaryFetching}
              color="bg-tertiary text-tertiary"
              icon="Topic"
              title="Number of pending pairings"
              value={pairingSummary?.pending as number}
            />
            <MetricCard
              isLoading={summaryLoading || summaryFetching}
              color="bg-blue-400 text-white"
              icon="Smile"
              title="Number of ended pairings"
              value={pairingSummary?.ended as number}
            />
          </div>
        </div>
        <UnpairedTables query={query} />
        <PairingReportCharts query={query} />
        <div>
          <Stack justifyContent="space-between" alignItems="center" className="mb-4">
            <Typography fontWeight="semibold">Pairings</Typography>
            <Stack gap={4} direction="row" alignItems="center">
              {/* <Input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                className="w-72"
                inputPrefix={<AiOutlineSearch size={20} />}
                inputSuffix={
                  searchValue !== "" ? (
                    <Button onClick={() => setSearchValue("")} iconOnly kind="ghost" variant="small">
                      <SVG name="Close" />
                    </Button>
                  ) : (
                    <></>
                  )
                }
                placeholder="Search by name or email"
              /> */}
              <Button ref={popoverRefEl} onClick={() => setMenuOpen(true)}>
                Export
              </Button>
            </Stack>
          </Stack>

          <div className="">
            {pairingData?.pages[0]?.connections?.length === 0 && (
              <EmptyState iconSize={70} title="No pairings found" icon="UsersEmptyState" />
            )}
            {isFetchingNextPage && <KnackSpinner />}
            {pairingData && pairingData?.pages[0]?.connections?.length > 0 && (
              <Table
                initialSorting={sorting}
                sorting={sorting}
                setSorting={setSorting}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                paginationProps={{
                  totalPages: pairingData.pages[pageIndex]?.totalPages,
                  canGetNextPage: hasNextPage,
                  onNextPage: async (newPage) => {
                    if (!pairingData.pages[newPage - 1]) {
                      console.log("fetching next page SSR");
                      fetchNextPage();
                    }
                  },
                  onPreviousPage: async (newPage) => {
                    if (!pairingData.pages[newPage - 1]) {
                      console.log("fetching previous page SSR");
                      fetchPreviousPage();
                    }
                  }
                }}
                data={pairingData.pages.flatMap((p) => p.connections)}
                columns={(helper) => [
                  helper.accessor((row) => `${row.user_first_name} ${row.user_last_name}`, {
                    header: "User",
                    sortingFn: "text",
                    enableSorting: false,
                    cell: ({ getValue }) => {
                      const name = getValue();
                      return (
                        <div className="flex items-center gap-2">
                          {/* <Avatar className="flex-shrink-0" text={name} image={row.original.menteeProfile.profile_image} /> */}
                          <Typography fontWeight="semibold">{name}</Typography>
                        </div>
                      );
                    }
                  }),
                  helper.accessor((row) => `${row.user_email}`, {
                    header: "User email",
                    sortingFn: "text",
                    enableSorting: false,
                    cell: ({ getValue }) => {
                      const email = getValue();
                      return <Typography fontWeight="semibold">{email}</Typography>;
                    }
                  }),
                  helper.accessor((row) => `${row.expert_first_name} ${row.expert_last_name}`, {
                    header: "Expert",
                    sortingFn: "text",
                    enableSorting: false,
                    cell: ({ getValue }) => {
                      const name = getValue();
                      return (
                        <div className="flex items-center gap-2">
                          {/* <Avatar className="flex-shrink-0" text={name} image={row.original.mentorProfile.profile_image} /> */}
                          <Typography fontWeight="semibold">{name}</Typography>
                        </div>
                      );
                    }
                  }),
                  helper.accessor((row) => `${row.expert_email}`, {
                    header: "Expert email",
                    sortingFn: "text",
                    enableSorting: false,
                    cell: ({ getValue }) => {
                      const email = getValue();
                      return <Typography fontWeight="semibold">{email}</Typography>;
                    }
                  }),
                  helper.accessor("status", {
                    header: "Status",
                    enableSorting: false,
                    cell: ({ getValue }) => {
                      const name = getValue();
                      return (
                        <Chip shape="rounded">
                          {capitalizeFirstLetter(
                            name === "cancelled"
                              ? "Ended relationship"
                              : name === "approved"
                              ? "Paired"
                              : name === "pending"
                              ? "Pending request"
                              : name
                          )}
                        </Chip>
                      );
                    }
                  })
                ]}
              />
            )}
          </div>
        </div>
      </Box>
      <Popover
        animationType="fade"
        parentRef={popoverRefEl}
        offset={{ bottom: 10, left: 0 }}
        popoverProps={{
          // style: { maxWidth: 500 },
          variant: "elevated",
          elevation: 6
        }}
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <AttributeSelector<PairingRecord>
          attributeList={[
            {
              accessorKey: "user_email",
              label: "Email"
            },
            {
              accessorKey: "user_first_name",
              label: "First name"
            },
            {
              accessorKey: "user_last_name",
              label: "Last Name"
            },
            {
              accessorKey: "user_department",
              label: "Department"
            },
            {
              accessorKey: "user_entity",
              label: "Entity"
            },
            {
              accessorKey: "user_management_level",
              label: "Management Level"
            },
            {
              accessorKey: "user_position",
              label: "Position"
            }
          ]}
          isLoading={isExportLoading}
          onChange={() => {
            // setExportAttribute(attributes);
          }}
          onExportCSV={onExportCSV}
        />
      </Popover>
    </div>
  );
};

export default PairingReportPage;
