import { useTranslation } from "react-i18next";
import { CustomBar } from "../Chart/CustomBar";

export const MultiSkillBar = ({ scoreList = [], name }) => {
  const { t } = useTranslation(["assessments"]);
  const MAX_SCORE = 5;
  const percentageScoreList = scoreList.map(
    (score) => (score.toFixed(1) / MAX_SCORE) * 100
  ); // Scale score to percentage value out of 5

  return (
    <label className="grid gap-4 p-4 sm:grid-cols-2">
      {/* Skill Name */}
      <div className="flex items-center font-medium">{t(name)}</div>
      <div className="grid gap-4">
        {scoreList.map((score, i) => (
          <div className="flex flex-row items-center w-full" key={i}>
            <CustomBar
              Width={percentageScoreList[i]}
              classes="h-10"
              backgroundColor={"var(--color-body-fill-green)"}
            />
            <div className="flex flex-row items-center ml-4">
              <div className="w-10 whitespace-nowrap">
                <span className="text-xl font-bold text-skin-base">
                  {score.toFixed(1)}
                </span>
                <span className="text-skin-muted">/5</span>
              </div>
              <div className="ml-2 text-xs whitespace-nowrap sm:hidden">
                Score
              </div>
            </div>
          </div>
        ))}
      </div>
    </label>
  );
};
