import { Input } from "knack-ui";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import SVG from "../SVG/SVG";

interface IProps extends Omit<ComponentPropsWithoutRef<"input">, "onChange"> {
  onChange: (v: string) => void;
}

const SearchInput = forwardRef<HTMLInputElement, IProps>(
  ({ onChange, value, placeholder }, ref) => {
    return (
      <div className="relative flex flex-row items-center justify-between w-full sm:max-w-screen-md">
        <Input
          ref={ref}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          inputPrefix={<SVG name="Search" />}
          value={value}
          className="w-full min-w-full rounded-md bg-paper"
          placeholder={placeholder}
          inputSuffix={
            value ? (
              <SVG
                className="cursor-pointer"
                name="Close"
                onClick={() => {
                  onChange("");
                }}
              />
            ) : (
              <></>
            )
          }
        />
      </div>
    );
  }
);

export default SearchInput;
