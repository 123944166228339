import { Box, Chip, Stack, Typography } from "knack-ui";
import styled from "styled-components";

import { IUserProfile } from "../../features/User/UserTypes";
import { normalizeLinkedinLink } from "../../lib/utils";
import KnackImage from "../KnackImage/KnackImage";
import SVG from "../SVG/SVG";
import { IProgramInvite } from "../../features/Programs/ProgramTypes";

interface IProps {
  profile: IUserProfile | null;
  renderCTA?: () => void;
  listSkills?: string[];
  programInvite?: IProgramInvite;
}

const UserProfileCardExpanded = ({ profile, renderCTA, listSkills, programInvite }: IProps) => {
  if (!profile) return null;
  return (
    <div
      style={{ gridTemplateRows: "auto 1fr auto" }}
      onClick={(e) => e.stopPropagation()}
      className="grid overflow-hidden rounded-xl dialog-max-h"
    >
      <div className="flex items-start gap-6 p-8 text-primary bg-primary">
        <div className="flex-shrink-0 w-20 h-20 overflow-hidden border-2 border-white rounded-full">
          <KnackImage src={profile.profile_image} alt={profile.first_name} className="object-cover w-full h-full" />
        </div>
        <div className="grid gap-2">
          <Typography variant="h5" fontWeight="semibold">
            {profile.first_name} {profile.last_name}
          </Typography>

          {/* <Stack alignItems="center"> */}
          {profile.tagline && <Typography variant="subtitle2">{profile.tagline}</Typography>}
          {/* </Stack> */}
          <div className="grid gap-4" style={{ gridTemplateColumns: "auto 1fr" }}>
            {profile.job_function && (
              <Stack alignItems="center" gap={2}>
                <SVG className="flex-shrink-0" name="Work" />
                <Typography variant="subtitle2">{profile.job_function}</Typography>
              </Stack>
            )}
            {profile.position && (
              <Stack alignItems="center" gap={2}>
                <SVG className="flex-shrink-0" name="Work" />
                <Typography clamp={1} variant="subtitle2">
                  {profile.position}
                </Typography>
              </Stack>
            )}
          </div>
          <div className="grid gap-4" style={{ gridTemplateColumns: "auto 1fr" }}>
            {profile.entity && (
              <Stack alignItems="center" gap={2}>
                <SVG name="Department" />
                <Typography variant="subtitle2">{profile.entity}</Typography>
              </Stack>
            )}
            {profile.user?.email && (
              <Stack alignItems="center" gap={2}>
                <SVG name="Email" />
                <Typography variant="subtitle2">{profile.user?.email}</Typography>
              </Stack>
            )}
          </div>
          {profile.linkedin_profile_url && (
            <Stack alignItems="center" gap={2}>
              <SVG name="LinkedIn" />
              <Typography
                as="a"
                href={normalizeLinkedinLink(profile.linkedin_profile_url)}
                target="_blank"
                rel="noreferrer noopener"
                variant="subtitle2"
                className="underline cursor-pointer"
              >
                View Linkedin profile
              </Typography>
            </Stack>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 p-8 overflow-hidden bg-paper dialog-content-max-h scrollhost2">
        {profile.about && (
          <div>
            <Typography className="mb-4" fontWeight="bold" as="h6">
              About
            </Typography>
            <Box variant="outlined" className="p-4">
              <Typography variant="subtitle2">{profile.about}</Typography>
            </Box>
          </div>
        )}
        {profile.experience && profile.experience?.length > 0 && (
          <div>
            <Typography className="mb-4" fontWeight="bold" as="h6">
              Experience
            </Typography>
            <div className="flex flex-wrap-gap-4">
              <Box variant="outlined" className="p-4">
                <Typography variant="subtitle2">{profile.about}</Typography>
              </Box>
            </div>
          </div>
        )}
        {profile.spoken_languages && profile.spoken_languages?.length > 0 && (
          <div>
            <Typography className="mb-4" fontWeight="bold" as="h6">
              Languages
            </Typography>
            <div className="flex flex-wrap gap-4">
              {profile.spoken_languages.map((lang) => (
                <div key={lang} className="p-3 bg-gray-100 rounded-lg ">
                  <Typography variant="subtitle2">{lang}</Typography>
                </div>
              ))}
            </div>
          </div>
        )}
        {listSkills && listSkills.length > 0 && (
          <div className="">
            <Typography className="mb-4" fontWeight="bold" as="h6">
              Topic interest
            </Typography>
            <SkillsList>
              {listSkills.map((s) => (
                <Chip style={{ width: "fit-content", boxDecorationBreak: "clone" }} as="li" variant="primary" shape="rounded" key={s}>
                  {s}
                </Chip>
              ))}
            </SkillsList>
          </div>
        )}

        {programInvite && programInvite?.questionsForExperts?.length > 0 && (
          <div className="">
            <Typography className="mb-4" fontWeight="bold" as="h6">
              Expertise Details
            </Typography>
            <Stack direction="column">
              <Stack direction="column" gap={4} className="overflow-y-auto scrollhost2">
                {programInvite?.questionsForExperts.map((question, index) => (
                  <Stack key={index} direction="column" gap={2}>
                    <Typography fontWeight="bold" className="text-lg">
                      {question.question}
                    </Typography>
                    <Typography>{question.response}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </div>
        )}
      </div>
      {renderCTA?.()}
    </div>
  );
};

export default UserProfileCardExpanded;
const SkillsList = styled.ul`
  overflow-x: auto;
  padding: 24px;
  margin: -24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
`;
