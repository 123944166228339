import { useEffect, useState } from "react";
import { CustomBar } from "../Chart/CustomBar";
import { CustomCircularChart } from "../Chart/CustomCircularChart";

export const Feedback360SkillsBreackdownCollapsiblePanel = ({ SectionName, SectionDescription, id, scores, expanded }) => {
  const MAX_ACHIVEABLE_SCORE = 5;
  const localOpenState = localStorage.getItem(SectionName);
  const [open, setOpen] = useState(localOpenState ? JSON.parse(localOpenState) : false);
  const labelDataset_Scores = scores.map((dataset) => dataset.value); // Dataset Scores only
  const labelDataset_Percentages = labelDataset_Scores.map((score) => Math.round((score / MAX_ACHIVEABLE_SCORE) * 100)); // Scale Scores to percentage of Total

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  return (
    <div className="">
      {/* RESPONSIVE HEADER */}
      <div
        onClick={() => {
          localStorage.setItem(SectionName, !open);
          setOpen(!open);
        }}
      >
        {/* Mobile Header */}
        <div className="sm:hidden">
          <div className="flex flex-row items-center justify-between">
            {/* Percentage Score */}
            <div className="flex flex-row items-center">
              {/* Chart */}
              <CustomCircularChart classes="h-12 w-12 mr-4" scorePercentages={labelDataset_Percentages} />
              {/* Title */}
              <div className="text-lg font-bold">{SectionName}</div>
            </div>

            {/* Droplet Arrow */}
            <div className={`translate transform delay-300 duration-300 ${open ? "" : "-rotate-90"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="#ddd">
                <path
                  id="Icon_ionic-md-arrow-dropdown"
                  data-name="Icon ionic-md-arrow-dropdown"
                  d="M9,13.5l9,9,9-9Z"
                  transform="translate(-9 -13.5)"
                />
              </svg>
            </div>
          </div>

          {/* Section Description */}
          <div className={`text-skin-muted my-2 ${open ? "" : "clampText"}`}>{SectionDescription}</div>
        </div>

        {/* Large Screen Header */}
        <div className="flex-row items-center justify-between hidden sm:flex">
          {/* Header */}
          <div>
            {/* Title */}
            <div className={`font-bold transition-all duration-150 delay-150 ${open ? "text-2xl" : "text-xl"}`}>{SectionName}</div>
            {/* Section Description */}
            <div className={`text-skin-muted my-2 max-w-xl ${open ? "" : "clampText"}`}>{SectionDescription}</div>
          </div>

          <div className="flex flex-row items-center">
            {/* Chart */}
            <CustomCircularChart
              classes="h-24 w-24 mr-2 transition-all delay-200 duration-350"
              scorePercentages={labelDataset_Percentages}
            />
            {/* Droplet Arrow */}
            <div className={`translate transform delay-300 duration-300 ${open ? "" : "-rotate-90"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-3" viewBox="0 0 18 9" fill="#ddd">
                <path
                  id="Icon_ionic-md-arrow-dropdown"
                  data-name="Icon ionic-md-arrow-dropdown"
                  d="M9,13.5l9,9,9-9Z"
                  transform="translate(-9 -13.5)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Mentrics */}
      <div className={`transition	duration-500 ease-in-out ${open ? "h-auto opacity-100" : "h-0 opacity-10 overflow-hidden"}`}>
        {/* Metrics Header */}
        <div className={`hidden sm:grid gap-4 bg-gray-100 rounded-lg px-4 py-2 my-4 font-medium sm:grid-cols-2`}>
          <div>Skill</div>
          <div>Score</div>
        </div>
        {/* Metrics Content */}
        <div>
          {/* Scores */}
          {scores.map((reviewer, i) => (
            <label key={i} className={`grid gap-4 p-4 sm:grid-cols-2`}>
              {/* Skill Name */}
              <div className="font-medium capitalize">{reviewer.label}</div>
              <div className="grid gap-4">
                <div className="flex flex-row items-center w-full">
                  <CustomBar
                    Width={Math.round((reviewer.value / MAX_ACHIVEABLE_SCORE) * 100)}
                    classes="h-10"
                    backgroundColor={"var(--color-body-fill-green)"}
                  />
                  <div className="flex flex-row items-center w-32 ml-4">
                    <div className="whitespace-nowrap">
                      <span className="text-xl font-bold text-skin-base">{Math.round(reviewer.value)}</span>
                      <span className="text-skin-muted">/5</span>
                    </div>
                    <div className="ml-2 text-xs whitespace-nowrap">{reviewer.label}</div>
                  </div>
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
