import { useRef } from "react";
import { StaticDialog } from "react-st-modal";
import { useState } from "react";
import { AdminServices } from "../../../../services/dashboard/adminDashboard.services";
import { Success } from "../../../../components/ToastNotification/Success";

export const CreateAssessmentFramework = ({
  companyId,
  setOpen,
  isOpen,
  allCollections = [],
  callBack,
  collectionName
}) => {
  const [framework, setFramework] = useState({
    company: companyId,
    custom_level_two: collectionName,
    custom_level_two_definition: ""
  });

  const custom_level_twoRef = useRef();
  const custom_level_two_definitionRef = useRef();

  function willCreateFramework() {
    if (
      framework.company !== "" &&
      framework.custom_level_two !== "" &&
      framework.custom_level_two_definition !== ""
    ) {
      AdminServices.createAssessmentCollection(framework)
        .then((res) => {
          if (res.data.status) {
            callBack();
            setOpen(false);
            Success("Success! We have added to your collection");
          }
        })
        .catch((e) => console.log(e));
    }
  }

  return (
    <div className="text-skin-base">
      <StaticDialog
        className="SessionRatingModal CreateUserModal scrollhost"
        isOpen={isOpen}
        onAfterClose={() => {
          setOpen(false);
        }}
      >
        <div className="p-4">
          <div className="text-xl font-bold">Create Collection</div>

          {/* level_two default collection */}
          {/* <div className="mt-4">
                        <div className="text-sm font-medium">Select Collection</div>
                        <div className="text-xs font-medium text-red-400">*Select a collection you want to replace</div>
                        <div className="mt-1">
                            <Select
                                onChange={(e) => {
                                    if (e) { setFramework((p) => ({ ...p, level_two: e.key })) }
                                }}
                                className="rounded-lg"
                                classNamePrefix=""
                                isClearable={true}
                                isSearchable={true}
                                options={allCollections}
                                getOptionLabel={(option) => option.key}
                                getOptionValue={(option) => option.key}
                            />
                        </div>
                    </div> */}

          {/* custom_level_two name */}
          <div className="mt-4">
            <label>
              <div className="mb-2 text-sm font-medium">Name</div>
              <input
                placeholder="Collection Name"
                ref={custom_level_twoRef}
                defaultValue={framework.custom_level_two}
                onChange={(e) =>
                  setFramework((p) => ({
                    ...p,
                    custom_level_two: e.target.value
                  }))
                }
                className={`rounded-lg`}
              />
              {allCollections.includes(framework.custom_level_two) && (
                <div className="mt-1 text-xs font-medium text-red-400">
                  *A collection with the same already exist
                </div>
              )}
            </label>
          </div>

          {/* custom_level_two definition */}
          <div className="mt-4">
            <label>
              <div className="mb-2 text-sm font-medium">Description</div>
              <textarea
                placeholder="Collection Description"
                ref={custom_level_two_definitionRef}
                defaultValue={framework.custom_level_two_definition}
                onChange={(e) =>
                  setFramework((p) => ({
                    ...p,
                    custom_level_two_definition: e.target.value
                  }))
                }
                className={`rounded-lg w-full`}
              />
            </label>
          </div>

          {/* CTA */}
          <div className="flex items-center justify-end mt-16">
            <div
              className={`font-semibold text-base p-2  px-4 rounded-lg ${
                framework.company !== "" &&
                framework.custom_level_two !== "" &&
                framework.custom_level_two_definition !== "" &&
                !allCollections.includes(framework.custom_level_two)
                  ? "bg-skin-fill text-white cursor-pointer"
                  : "bg-gray-300"
              }`}
              onClick={() =>
                framework.company !== "" &&
                framework.custom_level_two !== "" &&
                framework.level_two !== "" &&
                framework.custom_level_two_definition !== "" &&
                !allCollections.includes(framework.custom_level_two)
                  ? willCreateFramework()
                  : null
              }
            >
              Create
            </div>
            <div
              className="p-2 px-4 text-base font-semibold cursor-pointer"
              onClick={() => setOpen()}
            >
              Cancel
            </div>
          </div>
        </div>
      </StaticDialog>
    </div>
  );
};
