import { Typography } from "knack-ui";
import { useCallback, useEffect, useState } from "react";
import { HRCoachServices } from "../../../services/dashboard/hrCoachDashboard.services";
import { DefaultProfileCard } from "../DefaultProfileCard";

const UserDrawerHeader = ({ user, admin }) => {
  const [coachingProgress, setCoachingProgress] = useState([]);

  const getCoachingProgress = useCallback(async () => {
    if (!user) return;
    try {
      const res = await HRCoachServices.getEmployeeCoachingProgress();
      if (res.data.status) {
        const { data } = res.data;
        const getUserCoachingProgress = data.filter(
          (f) => f?.profile?._id === user?.user?._id
        );
        setCoachingProgress(getUserCoachingProgress[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  useEffect(() => {
    getCoachingProgress();
  }, [getCoachingProgress, user]);
  return (
    <div>
      <DefaultProfileCard
        isKnackCoach={user?.user?.account_type === "Coach"}
        firstName={user?.first_name}
        lastName={user?.last_name}
        tagline={user?.tagline}
        position={user?.user?.position}
        email={user?.user?.email}
        rating={user?.profile_type === "MentorProfile" && user?.rating}
        phoneNumber={user?.whatsapp_number}
        profile_type={user?.profile_type}
        imageUrl={user?.user?.profile_image}
        totalSessions={coachingProgress?.completed}
        subscription={
          user?.user?.subscription === "Hi-Potential"
            ? "Individual Plan"
            : user?.user?.subscription === "Team"
            ? "Group Plan"
            : ""
        }
      />
      {admin && (
        <>
          <Typography variant="subtitle2" color="warning" className="mx-4 mb-2">
            UserId : {user?.user?._id}
          </Typography>
          <Typography variant="subtitle2" color="warning" className="mx-4 mb-2">
            Organization Id : {user?.user?.organization?._id}
          </Typography>
          <Typography variant="subtitle2" color="warning" className="mx-4 mb-2">
            Organization Name : {user?.user?.organization?.name}
          </Typography>
        </>
      )}
    </div>
  );
};

export default UserDrawerHeader;
