import { UseQueryOptions } from "react-query";
import {
  sessionQueryKeys,
  useGetTopSessionsTopics,
  UseGetTopSessionsTopicsProps
} from "../sessionsQueries";
import { ITopSessionTopicKey } from "../SessionsTypes";

interface IProps<SelectData = ITopSessionTopicKey[]>
  extends UseGetTopSessionsTopicsProps {
  select?: UseQueryOptions<
    ITopSessionTopicKey[],
    Error,
    SelectData,
    ReturnType<typeof sessionQueryKeys["topTopics"]>
  >["select"];
  render: (data: SelectData) => JSX.Element;
}

function TopSessionsTopicsWrapper<SelectData = ITopSessionTopicKey[]>({
  platform,
  render,
  coachingType,
  select
}: IProps<SelectData>) {
  const { data: topics } = useGetTopSessionsTopics(
    {
      platform,
      coachingType
    },
    {
      select
    }
  );
  return <>{render(topics as SelectData)}</>;
}

export default TopSessionsTopicsWrapper;
