import { useEffect } from "react";
import { useHistory } from "react-router";
import AppLayout from "../components/Layout/AppLayout";
import { userQueryKeys, useUser } from "../features/User/userQueries";
import NoCalendarView from "../features/Calendar/ui/NoCalendarView";
import { useGetSchedulerId } from "../features/Calendar/calendarQueries";
import { useUpdateProfile } from "../features/User/userMutations";
import { queryClient } from "../lib/appConfig";

export const CronofyConnectCalendar = () => {
  const { data: user } = useUser();
  const { data: scheduler_id } = useGetSchedulerId(user?._id);
  const { mutateAsync: updateProfile } = useUpdateProfile();

  const history = useHistory();

  useEffect(() => {
    if (scheduler_id) {
      updateProfile({ scheduler_id })
        .then((res) => {
          queryClient.invalidateQueries(userQueryKeys.all);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // check if calendar is already linked
    if (user && user.profile.scheduler_id) {
      history.push("/");
    } else {
    }
  }, [user, scheduler_id]);

  return (
    <>
      <AppLayout>
        <NoCalendarView />
      </AppLayout>
    </>
  );
};
