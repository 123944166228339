import { Avatar, Button, Typography } from "knack-ui";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { KnackSpinner } from "../../../components/Asset/KnackSpinner";
import EmptyState from "../../../components/EmptyState/EmptyState";
import SVG from "../../../components/SVG/SVG";
import Table from "../../../components/Table";
import { useGetUnpaired } from "../hooks/PairingReport.queries";
import TitledDisplayBox from "../../../components/TitledDisplayBox/TitledDisplayBox";
import { PairingReportsQuery } from '../../AnalyticsReports/analyticsReportsTypes';
interface IProps {
  query: PairingReportsQuery;
}

const UnpairedTables = ({ query }: IProps) => {
  const { data: unpairedData, isFetching } = useGetUnpaired({ query });
  const history = useHistory();
  return (
    <Grid>
      <TitledDisplayBox title="Unpaired users">
        <TableContainer>
          {unpairedData?.unpairedUsers.length === 0 && <EmptyState iconSize={70} title="No unpaired users found" icon="UsersEmptyState" />}
          {isFetching && <KnackSpinner />}
          {unpairedData && unpairedData.unpairedUsers?.length > 0 && (
            <Table
              manualPagination={false}
              data={unpairedData.unpairedUsers}
              columns={(helper) => [
                helper.accessor((row) => `${row.user_first_name} ${row.user_last_name}`, {
                  header: "User",
                  sortingFn: "text",
                  enableSorting: false,
                  cell: ({ row, getValue }) => {
                    const name = getValue();
                    return (
                      <div className="flex items-center gap-2">
                        <Avatar className="flex-shrink-0" text={name} image={row.original.user_profile_image} />
                        <div>
                          <Typography fontWeight="semibold">{name}</Typography>
                          <Typography>{row.original.email}</Typography>
                        </div>
                      </div>
                    );
                  }
                }),
                helper.accessor((row) => `${row.program_name}`, {
                  header: "Program",
                  sortingFn: "text",
                  enableSorting: false,
                  cell: ({ row, getValue }) => {
                    const name = getValue();
                    return (
                      <div className="flex items-center gap-2">
                        <Typography clamp={1} fontWeight="semibold">
                          {name}
                        </Typography>
                        <Button
                          className="flex-shrink-0"
                          iconOnly
                          onClick={(e) => history.push(`/programs/${row.original.program_id}/participants`)}
                          kind="ghost"
                        >
                          <SVG size={12} name="FiExternalLink" />
                        </Button>
                      </div>
                    );
                  }
                })
                // helper.accessor((row) => row.user, {
                //   header: "Position",
                //   sortingFn: "text",
                //   enableSorting: false,
                //   cell: ({ row, getValue }) => {
                //     const name = getValue();
                //     return (
                //       <div className="flex items-center gap-2">
                //         <Typography clamp={1} fontWeight="semibold">
                //           {name}
                //         </Typography>
                //       </div>
                //     );
                //   }
                // })
              ]}
            />
          )}
        </TableContainer>
      </TitledDisplayBox>
      <TitledDisplayBox title="Unpaired experts">
        <TableContainer>
          {unpairedData?.unpairedExperts.length === 0 && (
            <EmptyState iconSize={70} title="No unpaired experts found" icon="UsersEmptyState" />
          )}
          {isFetching && <KnackSpinner />}
          {unpairedData && unpairedData?.unpairedExperts.length > 0 && (
            <Table
              manualPagination={false}
              data={unpairedData.unpairedExperts}
              columns={(helper) => [
                helper.accessor((row) => `${row.user_first_name} ${row.user_last_name}`, {
                  header: "Expert",
                  sortingFn: "text",
                  enableSorting: false,
                  cell: ({ row, getValue }) => {
                    const name = getValue();
                    return (
                      <div className="flex items-center gap-2">
                        <Avatar className="flex-shrink-0" text={name} image={row.original.user_profile_image} />
                        <div>
                          <Typography fontWeight="semibold">{name}</Typography>
                          <Typography>{row.original.email}</Typography>
                        </div>
                      </div>
                    );
                  }
                }),
                helper.accessor((row) => `${row.program_name}`, {
                  header: "Program",
                  sortingFn: "text",
                  enableSorting: false,
                  cell: ({ row, getValue }) => {
                    const name = getValue();
                    return (
                      <div className="flex items-center gap-2">
                        <Typography clamp={1} fontWeight="semibold">
                          {name}
                        </Typography>
                        <Button
                          className="flex-shrink-0"
                          iconOnly
                          onClick={() => history.push(`/programs/${row.original.program_id}`)}
                          kind="ghost"
                        >
                          <SVG size={12} name="FiExternalLink" />
                        </Button>
                      </div>
                    );
                  }
                })
              ]}
            />
          )}
        </TableContainer>
      </TitledDisplayBox>
    </Grid>
  );
};

export default UnpairedTables;
const Grid = styled.div`
  display: grid;
  gap: 16px;

  position: relative;
  grid-template-columns: 1fr;
  @media ${(p) => p.theme.queries.tabletAndUp} {
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  }
  @media ${(p) => p.theme.queries.laptopAndUp} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const TableContainer = styled.div`
  height: 100%;
  margin: -16px;
`;
