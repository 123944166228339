import { useState, useEffect } from "react";
import { MetricCard } from "../../../../components/Cards/MetricCard";
import { SessionCompletedChart } from "./SessionCompleteChart";
import { SessionCompletedList } from "./SessionCompletedList";
import dashboard, { getExternalCoaching } from "../../../../services/dashboard/hrDashboard.services";
import { PROGRAM_APPLICATION_TYPES } from "../../../../services/utils/app.constant";
import { TopTopics } from "./TopTopics";
import { useGetProgramUtilization } from "../../../../features/Programs/programQueries";
import SatisfactionScore from "./SatisfactionScore";
import {
  useGetAnalyticsSessionsSatisfactionScores,
  useGetAnalyticsTopTopics
} from "../../../../features/AnalyticsReports/analyticsReportQueries";

export const Analytics = ({ Program }) => {
  const query = { program: [Program._id], status: ["Completed"] };
  const { data: topicsData } = useGetAnalyticsTopTopics(query, {
    enabled: Boolean(Program)
  });

  const { data: utilization } = useGetProgramUtilization({
    programId: Program._id
  });

  const [metrics, setMetrics] = useState([
    {
      title: "Registered mentees",
      value: 0
    },
    {
      title: "Registered mentors",
      value: 0
    },
    {
      title: "Sessions completed",
      value: 0
    }
  ]);

  const [coachingMetrics, setCoachingMetrics] = useState([
    {
      title: "Registered coachees",
      value: 0
    },
    {
      title: "Registered coaches",
      value: 0
    },
    {
      title: "Sessions completed",
      value: 0
    }
  ]);

  const [knackCoachingMetrics, setKnackCoachingMetrics] = useState([
    {
      title: "Registered coachees",
      value: 0
    },
    {
      title: "Sessions completed",
      value: 0
    }
  ]);

  useEffect(() => {
    getMentees();
    getMentors();
    getSessionActivities();
  }, [setMetrics, setCoachingMetrics, setKnackCoachingMetrics]);

  const getMentees = () => {
    if (Program && Program._id) {
      const inviteType = Program.program_type === "Mentoring" ? PROGRAM_APPLICATION_TYPES.MENTEE : PROGRAM_APPLICATION_TYPES.COACHEE;
      dashboard
        .getProgramInvitations(Program._id, inviteType)
        .then((res) => {
          if (res.data.status) {
            const { data } = res.data;
            const registered = data.filter((user) => user.confirmed === true).map((m) => ({ ...m, registered: true }));
            const _metrics = [...metrics];
            const _coachingMetrics = [...coachingMetrics];
            const _knackCoachingMetrics = [...knackCoachingMetrics];
            _metrics[0].value = registered.length;
            _coachingMetrics[0].value = registered.length;
            _knackCoachingMetrics[0].value = registered.length;
            setMetrics(_metrics);
            setCoachingMetrics(_coachingMetrics);
            setKnackCoachingMetrics(_knackCoachingMetrics);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dashboard
        .getMentees()
        .then((res) => {
          if (res.data.status) {
            const { data } = res.data;
            const registered = data.filter((a) => a.bio_data != null);
            const _metrics = [...metrics];
            _metrics[0].value = registered.length;
            setMetrics(_metrics);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const getMentors = () => {
    if (Program.program_type === "External Coaching") {
      getExternalCoaching().then((res) => {
        if (res && res.data.status) {
          const { data } = res.data;
          const _metrics = [...knackCoachingMetrics];
          _metrics[0].value = data.length;
          setMetrics(_metrics);
        }
      });
    } else {
      if (Program && Program._id) {
        const inviteType = Program.program_type === "Mentoring" ? PROGRAM_APPLICATION_TYPES.MENTOR : PROGRAM_APPLICATION_TYPES.COACH;
        dashboard
          .getProgramInvitations(Program._id, inviteType)
          .then((res) => {
            if (res.data.status) {
              const { data } = res.data;
              const registered = data.filter((user) => user.confirmed === true).map((m) => ({ ...m, registered: true }));
              const _metrics = [...metrics];
              const _coachingMetrics = [...coachingMetrics];
              _metrics[1].value = registered.length;
              _coachingMetrics[1].value = registered.length;
              setMetrics(_metrics);
              setCoachingMetrics(_coachingMetrics);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        dashboard
          .getMentors()
          .then((res) => {
            if (res.data.status) {
              const { data } = res.data;
              const registered = data.filter((a) => a.bio_data != null);
              const _metrics = [...metrics];
              _metrics[1].value = registered.length;
              setMetrics(_metrics);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const getSessionActivities = () => {
    if (Program && Program._id) {
      dashboard
        .getProgramSessionCount(Program._id)
        .then((res) => {
          if (res?.data?.status) {
            const { data } = res?.data;
            const _metrics = [...metrics];
            const _coachingMetrics = [...coachingMetrics];
            const _knackCoachingMetrics = [...knackCoachingMetrics];
            _metrics[2].value = data.totalCompleted;
            _coachingMetrics[2].value = data.totalCompleted;
            _knackCoachingMetrics[1].value = data.totalCompleted;
            setMetrics(_metrics);
            setCoachingMetrics(_coachingMetrics);
            setKnackCoachingMetrics(_knackCoachingMetrics);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dashboard
        .getSessionActivities()
        .then((res) => {
          if (res.data.status) {
            const { data } = res.data;
            const _metrics = [...metrics];
            _metrics[2].value = data.completedSessions;
            setMetrics(_metrics);
            setCoachingMetrics(_metrics);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const { data: satisfactionScores } = useGetAnalyticsSessionsSatisfactionScores(query, {
    suspense: true,
    keepPreviousData: true
  });

  return (
    <main className="">
      {/* metrics */}
      <div>
        <div>
          {Program?.program_type === "Coaching" ? (
            Program?.coach_type === "own_coach" ? (
              <div className="grid grid-flow-row gap-4 md:grid-cols-5">
                {coachingMetrics.map((m, i) => (
                  <MetricCard key={i} title={m.title} value={m.value} />
                ))}
                <MetricCard title="Utilization" value={`${utilization.connections}/${utilization.totalCapacity}`} />
                <MetricCard title="Unpaired Coachees" value={`${utilization.unpairedUsers}`} />
              </div>
            ) : (
              <div className="grid grid-flow-row gap-4 md:grid-cols-2">
                {knackCoachingMetrics.map((m, i) => (
                  <MetricCard key={i} title={m.title} value={m.value} />
                ))}
              </div>
            )
          ) : (
            <div className="grid grid-flow-row gap-4 md:grid-cols-5">
              {metrics.map((m, i) => (
                <MetricCard key={i} title={m.title} value={m.value} />
              ))}
              <MetricCard title="Utilization" value={`${utilization.connections}/${utilization.totalCapacity}`} />
              <MetricCard title="Unpaired Mentees" value={`${utilization.unpairedUsers}`} />
            </div>
          )}
        </div>
      </div>
      <div className="grid gap-4 py-4 lg:grid-cols-2">
        {/* chart */}
        <SessionCompletedChart Program={Program} />
        {/* table */}
        <SessionCompletedList Program={Program} />
        <SatisfactionScore satisfactionScores={satisfactionScores} />
        <TopTopics topTopics={topicsData?.topTopics} program_type={Program.program_type} />
      </div>
    </main>
  );
};
